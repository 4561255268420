export const humanGenes = [
{value: '5_8S_rRNA', id: '5_8S_rRNA'},
{value: '5S_rRNA', id: '5S_rRNA'},
{value: '7SK', id: '7SK'},
{value: 'A1BG', id: 'A1BG'},
{value: 'A1BG-AS1', id: 'A1BG-AS1'},
{value: 'A1CF', id: 'A1CF'},
{value: 'A2M', id: 'A2M'},
{value: 'A2M-AS1', id: 'A2M-AS1'},
{value: 'A2ML1', id: 'A2ML1'},
{value: 'A2ML1-AS1', id: 'A2ML1-AS1'},
{value: 'A2MP1', id: 'A2MP1'},
{value: 'A3GALT2', id: 'A3GALT2'},
{value: 'A4GALT', id: 'A4GALT'},
{value: 'A4GNT', id: 'A4GNT'},
{value: 'AAAS', id: 'AAAS'},
{value: 'AACS', id: 'AACS'},
{value: 'AACSP1', id: 'AACSP1'},
{value: 'AADAC', id: 'AADAC'},
{value: 'AADACL2', id: 'AADACL2'},
{value: 'AADACL2-AS1', id: 'AADACL2-AS1'},
{value: 'AADACL3', id: 'AADACL3'},
{value: 'AADACL4', id: 'AADACL4'},
{value: 'AADACP1', id: 'AADACP1'},
{value: 'AADAT', id: 'AADAT'},
{value: 'AAGAB', id: 'AAGAB'},
{value: 'AAK1', id: 'AAK1'},
{value: 'AAMDC', id: 'AAMDC'},
{value: 'AAMP', id: 'AAMP'},
{value: 'AANAT', id: 'AANAT'},
{value: 'AAR2', id: 'AAR2'},
{value: 'AARD', id: 'AARD'},
{value: 'AARS1', id: 'AARS1'},
{value: 'AARS1P1', id: 'AARS1P1'},
{value: 'AARS2', id: 'AARS2'},
{value: 'AARSD1', id: 'AARSD1'},
{value: 'AARSD1P1', id: 'AARSD1P1'},
{value: 'AASDH', id: 'AASDH'},
{value: 'AASDHPPT', id: 'AASDHPPT'},
{value: 'AASS', id: 'AASS'},
{value: 'AATBC', id: 'AATBC'},
{value: 'AATF', id: 'AATF'},
{value: 'AATK', id: 'AATK'},
{value: 'ABALON', id: 'ABALON'},
{value: 'ABAT', id: 'ABAT'},
{value: 'ABCA1', id: 'ABCA1'},
{value: 'ABCA10', id: 'ABCA10'},
{value: 'ABCA11P', id: 'ABCA11P'},
{value: 'ABCA12', id: 'ABCA12'},
{value: 'ABCA13', id: 'ABCA13'},
{value: 'ABCA17P', id: 'ABCA17P'},
{value: 'ABCA2', id: 'ABCA2'},
{value: 'ABCA3', id: 'ABCA3'},
{value: 'ABCA4', id: 'ABCA4'},
{value: 'ABCA5', id: 'ABCA5'},
{value: 'ABCA6', id: 'ABCA6'},
{value: 'ABCA7', id: 'ABCA7'},
{value: 'ABCA8', id: 'ABCA8'},
{value: 'ABCA9', id: 'ABCA9'},
{value: 'ABCA9-AS1', id: 'ABCA9-AS1'},
{value: 'ABCB1', id: 'ABCB1'},
{value: 'ABCB10', id: 'ABCB10'},
{value: 'ABCB10P1', id: 'ABCB10P1'},
{value: 'ABCB10P3', id: 'ABCB10P3'},
{value: 'ABCB10P4', id: 'ABCB10P4'},
{value: 'ABCB11', id: 'ABCB11'},
{value: 'ABCB4', id: 'ABCB4'},
{value: 'ABCB5', id: 'ABCB5'},
{value: 'ABCB6', id: 'ABCB6'},
{value: 'ABCB7', id: 'ABCB7'},
{value: 'ABCB8', id: 'ABCB8'},
{value: 'ABCB9', id: 'ABCB9'},
{value: 'ABCC1', id: 'ABCC1'},
{value: 'ABCC10', id: 'ABCC10'},
{value: 'ABCC11', id: 'ABCC11'},
{value: 'ABCC12', id: 'ABCC12'},
{value: 'ABCC13', id: 'ABCC13'},
{value: 'ABCC2', id: 'ABCC2'},
{value: 'ABCC3', id: 'ABCC3'},
{value: 'ABCC4', id: 'ABCC4'},
{value: 'ABCC5', id: 'ABCC5'},
{value: 'ABCC5-AS1', id: 'ABCC5-AS1'},
{value: 'ABCC6', id: 'ABCC6'},
{value: 'ABCC6P1', id: 'ABCC6P1'},
{value: 'ABCC6P2', id: 'ABCC6P2'},
{value: 'ABCC8', id: 'ABCC8'},
{value: 'ABCC9', id: 'ABCC9'},
{value: 'ABCD1', id: 'ABCD1'},
{value: 'ABCD1P2', id: 'ABCD1P2'},
{value: 'ABCD1P4', id: 'ABCD1P4'},
{value: 'ABCD1P5', id: 'ABCD1P5'},
{value: 'ABCD2', id: 'ABCD2'},
{value: 'ABCD3', id: 'ABCD3'},
{value: 'ABCD4', id: 'ABCD4'},
{value: 'ABCE1', id: 'ABCE1'},
{value: 'ABCF1', id: 'ABCF1'},
{value: 'ABCF2', id: 'ABCF2'},
{value: 'ABCF2-H2BE1', id: 'ABCF2-H2BE1'},
{value: 'ABCF2P1', id: 'ABCF2P1'},
{value: 'ABCF2P2', id: 'ABCF2P2'},
{value: 'ABCF3', id: 'ABCF3'},
{value: 'ABCG1', id: 'ABCG1'},
{value: 'ABCG2', id: 'ABCG2'},
{value: 'ABCG4', id: 'ABCG4'},
{value: 'ABCG5', id: 'ABCG5'},
{value: 'ABCG8', id: 'ABCG8'},
{value: 'ABHD1', id: 'ABHD1'},
{value: 'ABHD10', id: 'ABHD10'},
{value: 'ABHD11', id: 'ABHD11'},
{value: 'ABHD11-AS1', id: 'ABHD11-AS1'},
{value: 'ABHD12', id: 'ABHD12'},
{value: 'ABHD12B', id: 'ABHD12B'},
{value: 'ABHD13', id: 'ABHD13'},
{value: 'ABHD14A', id: 'ABHD14A'},
{value: 'ABHD14A-ACY1', id: 'ABHD14A-ACY1'},
{value: 'ABHD14B', id: 'ABHD14B'},
{value: 'ABHD15', id: 'ABHD15'},
{value: 'ABHD15-AS1', id: 'ABHD15-AS1'},
{value: 'ABHD16A', id: 'ABHD16A'},
{value: 'ABHD16B', id: 'ABHD16B'},
{value: 'ABHD17A', id: 'ABHD17A'},
{value: 'ABHD17AP1', id: 'ABHD17AP1'},
{value: 'ABHD17AP3', id: 'ABHD17AP3'},
{value: 'ABHD17AP4', id: 'ABHD17AP4'},
{value: 'ABHD17AP5', id: 'ABHD17AP5'},
{value: 'ABHD17AP6', id: 'ABHD17AP6'},
{value: 'ABHD17AP8', id: 'ABHD17AP8'},
{value: 'ABHD17B', id: 'ABHD17B'},
{value: 'ABHD17C', id: 'ABHD17C'},
{value: 'ABHD18', id: 'ABHD18'},
{value: 'ABHD2', id: 'ABHD2'},
{value: 'ABHD3', id: 'ABHD3'},
{value: 'ABHD4', id: 'ABHD4'},
{value: 'ABHD5', id: 'ABHD5'},
{value: 'ABHD6', id: 'ABHD6'},
{value: 'ABHD8', id: 'ABHD8'},
{value: 'ABI1', id: 'ABI1'},
{value: 'ABI1P1', id: 'ABI1P1'},
{value: 'ABI2', id: 'ABI2'},
{value: 'ABI3', id: 'ABI3'},
{value: 'ABI3BP', id: 'ABI3BP'},
{value: 'ABITRAM', id: 'ABITRAM'},
{value: 'ABITRAMP1', id: 'ABITRAMP1'},
{value: 'ABL1', id: 'ABL1'},
{value: 'ABL2', id: 'ABL2'},
{value: 'ABLIM1', id: 'ABLIM1'},
{value: 'ABLIM2', id: 'ABLIM2'},
{value: 'ABLIM3', id: 'ABLIM3'},
{value: 'ABO', id: 'ABO'},
{value: 'ABR', id: 'ABR'},
{value: 'ABRA', id: 'ABRA'},
{value: 'ABRACL', id: 'ABRACL'},
{value: 'ABRAXAS1', id: 'ABRAXAS1'},
{value: 'ABRAXAS2', id: 'ABRAXAS2'},
{value: 'ABT1', id: 'ABT1'},
{value: 'ABT1P1', id: 'ABT1P1'},
{value: 'ABTB1', id: 'ABTB1'},
{value: 'ABTB2', id: 'ABTB2'},
{value: 'ACAA1', id: 'ACAA1'},
{value: 'ACAA2', id: 'ACAA2'},
{value: 'ACACA', id: 'ACACA'},
{value: 'ACACB', id: 'ACACB'},
{value: 'ACAD10', id: 'ACAD10'},
{value: 'ACAD11', id: 'ACAD11'},
{value: 'ACAD8', id: 'ACAD8'},
{value: 'ACAD9', id: 'ACAD9'},
{value: 'ACADL', id: 'ACADL'},
{value: 'ACADM', id: 'ACADM'},
{value: 'ACADS', id: 'ACADS'},
{value: 'ACADSB', id: 'ACADSB'},
{value: 'ACADVL', id: 'ACADVL'},
{value: 'ACAN', id: 'ACAN'},
{value: 'ACAP1', id: 'ACAP1'},
{value: 'ACAP2', id: 'ACAP2'},
{value: 'ACAP3', id: 'ACAP3'},
{value: 'ACAT1', id: 'ACAT1'},
{value: 'ACAT2', id: 'ACAT2'},
{value: 'ACBD3', id: 'ACBD3'},
{value: 'ACBD3-AS1', id: 'ACBD3-AS1'},
{value: 'ACBD4', id: 'ACBD4'},
{value: 'ACBD5', id: 'ACBD5'},
{value: 'ACBD6', id: 'ACBD6'},
{value: 'ACBD7', id: 'ACBD7'},
{value: 'ACCS', id: 'ACCS'},
{value: 'ACCSL', id: 'ACCSL'},
{value: 'ACD', id: 'ACD'},
{value: 'ACE', id: 'ACE'},
{value: 'ACE2', id: 'ACE2'},
{value: 'ACE3P', id: 'ACE3P'},
{value: 'ACER1', id: 'ACER1'},
{value: 'ACER2', id: 'ACER2'},
{value: 'ACER2P1', id: 'ACER2P1'},
{value: 'ACER3', id: 'ACER3'},
{value: 'ACHE', id: 'ACHE'},
{value: 'ACIN1', id: 'ACIN1'},
{value: 'ACKR1', id: 'ACKR1'},
{value: 'ACKR2', id: 'ACKR2'},
{value: 'ACKR3', id: 'ACKR3'},
{value: 'ACKR4', id: 'ACKR4'},
{value: 'ACLY', id: 'ACLY'},
{value: 'ACNATP', id: 'ACNATP'},
{value: 'ACO1', id: 'ACO1'},
{value: 'ACO2', id: 'ACO2'},
{value: 'ACOD1', id: 'ACOD1'},
{value: 'ACOT1', id: 'ACOT1'},
{value: 'ACOT11', id: 'ACOT11'},
{value: 'ACOT12', id: 'ACOT12'},
{value: 'ACOT13', id: 'ACOT13'},
{value: 'ACOT2', id: 'ACOT2'},
{value: 'ACOT4', id: 'ACOT4'},
{value: 'ACOT6', id: 'ACOT6'},
{value: 'ACOT7', id: 'ACOT7'},
{value: 'ACOT8', id: 'ACOT8'},
{value: 'ACOT9', id: 'ACOT9'},
{value: 'ACOX1', id: 'ACOX1'},
{value: 'ACOX2', id: 'ACOX2'},
{value: 'ACOX3', id: 'ACOX3'},
{value: 'ACOXL', id: 'ACOXL'},
{value: 'ACOXL-AS1', id: 'ACOXL-AS1'},
{value: 'ACP1', id: 'ACP1'},
{value: 'ACP2', id: 'ACP2'},
{value: 'ACP3', id: 'ACP3'},
{value: 'ACP4', id: 'ACP4'},
{value: 'ACP5', id: 'ACP5'},
{value: 'ACP6', id: 'ACP6'},
{value: 'ACP7', id: 'ACP7'},
{value: 'ACR', id: 'ACR'},
{value: 'ACRBP', id: 'ACRBP'},
{value: 'ACRP1', id: 'ACRP1'},
{value: 'ACRV1', id: 'ACRV1'},
{value: 'ACSBG1', id: 'ACSBG1'},
{value: 'ACSF2', id: 'ACSF2'},
{value: 'ACSF3', id: 'ACSF3'},
{value: 'ACSL1', id: 'ACSL1'},
{value: 'ACSL3', id: 'ACSL3'},
{value: 'ACSL4', id: 'ACSL4'},
{value: 'ACSL5', id: 'ACSL5'},
{value: 'ACSL6', id: 'ACSL6'},
{value: 'ACSL6-AS1', id: 'ACSL6-AS1'},
{value: 'ACSM1', id: 'ACSM1'},
{value: 'ACSM2A', id: 'ACSM2A'},
{value: 'ACSM2B', id: 'ACSM2B'},
{value: 'ACSM3', id: 'ACSM3'},
{value: 'ACSM4', id: 'ACSM4'},
{value: 'ACSM5', id: 'ACSM5'},
{value: 'ACSM5P1', id: 'ACSM5P1'},
{value: 'ACSM6', id: 'ACSM6'},
{value: 'ACSS1', id: 'ACSS1'},
{value: 'ACSS2', id: 'ACSS2'},
{value: 'ACSS3', id: 'ACSS3'},
{value: 'ACTA1', id: 'ACTA1'},
{value: 'ACTA2', id: 'ACTA2'},
{value: 'ACTA2-AS1', id: 'ACTA2-AS1'},
{value: 'ACTB', id: 'ACTB'},
{value: 'ACTBL2', id: 'ACTBL2'},
{value: 'ACTBP11', id: 'ACTBP11'},
{value: 'ACTBP12', id: 'ACTBP12'},
{value: 'ACTBP14', id: 'ACTBP14'},
{value: 'ACTBP15', id: 'ACTBP15'},
{value: 'ACTBP2', id: 'ACTBP2'},
{value: 'ACTBP4', id: 'ACTBP4'},
{value: 'ACTBP6', id: 'ACTBP6'},
{value: 'ACTBP7', id: 'ACTBP7'},
{value: 'ACTBP8', id: 'ACTBP8'},
{value: 'ACTBP9', id: 'ACTBP9'},
{value: 'ACTC1', id: 'ACTC1'},
{value: 'ACTE1P', id: 'ACTE1P'},
{value: 'ACTG1', id: 'ACTG1'},
{value: 'ACTG1P1', id: 'ACTG1P1'},
{value: 'ACTG1P10', id: 'ACTG1P10'},
{value: 'ACTG1P12', id: 'ACTG1P12'},
{value: 'ACTG1P13', id: 'ACTG1P13'},
{value: 'ACTG1P14', id: 'ACTG1P14'},
{value: 'ACTG1P15', id: 'ACTG1P15'},
{value: 'ACTG1P16', id: 'ACTG1P16'},
{value: 'ACTG1P17', id: 'ACTG1P17'},
{value: 'ACTG1P18', id: 'ACTG1P18'},
{value: 'ACTG1P19', id: 'ACTG1P19'},
{value: 'ACTG1P21', id: 'ACTG1P21'},
{value: 'ACTG1P22', id: 'ACTG1P22'},
{value: 'ACTG1P23', id: 'ACTG1P23'},
{value: 'ACTG1P24', id: 'ACTG1P24'},
{value: 'ACTG1P25', id: 'ACTG1P25'},
{value: 'ACTG1P3', id: 'ACTG1P3'},
{value: 'ACTG1P4', id: 'ACTG1P4'},
{value: 'ACTG1P9', id: 'ACTG1P9'},
{value: 'ACTG2', id: 'ACTG2'},
{value: 'ACTL10', id: 'ACTL10'},
{value: 'ACTL11P', id: 'ACTL11P'},
{value: 'ACTL6A', id: 'ACTL6A'},
{value: 'ACTL6B', id: 'ACTL6B'},
{value: 'ACTL7A', id: 'ACTL7A'},
{value: 'ACTL7B', id: 'ACTL7B'},
{value: 'ACTL8', id: 'ACTL8'},
{value: 'ACTL9', id: 'ACTL9'},
{value: 'ACTN1', id: 'ACTN1'},
{value: 'ACTN1-DT', id: 'ACTN1-DT'},
{value: 'ACTN2', id: 'ACTN2'},
{value: 'ACTN3', id: 'ACTN3'},
{value: 'ACTN4', id: 'ACTN4'},
{value: 'ACTN4P1', id: 'ACTN4P1'},
{value: 'ACTN4P2', id: 'ACTN4P2'},
{value: 'ACTP1', id: 'ACTP1'},
{value: 'ACTR10', id: 'ACTR10'},
{value: 'ACTR1A', id: 'ACTR1A'},
{value: 'ACTR1B', id: 'ACTR1B'},
{value: 'ACTR2', id: 'ACTR2'},
{value: 'ACTR3', id: 'ACTR3'},
{value: 'ACTR3-AS1', id: 'ACTR3-AS1'},
{value: 'ACTR3B', id: 'ACTR3B'},
{value: 'ACTR3BP2', id: 'ACTR3BP2'},
{value: 'ACTR3BP5', id: 'ACTR3BP5'},
{value: 'ACTR3C', id: 'ACTR3C'},
{value: 'ACTR3P3', id: 'ACTR3P3'},
{value: 'ACTR5', id: 'ACTR5'},
{value: 'ACTR6', id: 'ACTR6'},
{value: 'ACTR8', id: 'ACTR8'},
{value: 'ACTRT2', id: 'ACTRT2'},
{value: 'ACTRT3', id: 'ACTRT3'},
{value: 'ACVR1', id: 'ACVR1'},
{value: 'ACVR1B', id: 'ACVR1B'},
{value: 'ACVR1C', id: 'ACVR1C'},
{value: 'ACVR2A', id: 'ACVR2A'},
{value: 'ACVR2B', id: 'ACVR2B'},
{value: 'ACVR2B-AS1', id: 'ACVR2B-AS1'},
{value: 'ACVRL1', id: 'ACVRL1'},
{value: 'ACY1', id: 'ACY1'},
{value: 'ACY3', id: 'ACY3'},
{value: 'ACYP1', id: 'ACYP1'},
{value: 'ACYP2', id: 'ACYP2'},
{value: 'ADA', id: 'ADA'},
{value: 'ADA2', id: 'ADA2'},
{value: 'ADAD1', id: 'ADAD1'},
{value: 'ADAD2', id: 'ADAD2'},
{value: 'ADAL', id: 'ADAL'},
{value: 'ADAM10', id: 'ADAM10'},
{value: 'ADAM11', id: 'ADAM11'},
{value: 'ADAM12', id: 'ADAM12'},
{value: 'ADAM15', id: 'ADAM15'},
{value: 'ADAM17', id: 'ADAM17'},
{value: 'ADAM18', id: 'ADAM18'},
{value: 'ADAM19', id: 'ADAM19'},
{value: 'ADAM1A', id: 'ADAM1A'},
{value: 'ADAM1B', id: 'ADAM1B'},
{value: 'ADAM2', id: 'ADAM2'},
{value: 'ADAM20', id: 'ADAM20'},
{value: 'ADAM20P1', id: 'ADAM20P1'},
{value: 'ADAM20P2', id: 'ADAM20P2'},
{value: 'ADAM20P3', id: 'ADAM20P3'},
{value: 'ADAM21', id: 'ADAM21'},
{value: 'ADAM21P1', id: 'ADAM21P1'},
{value: 'ADAM22', id: 'ADAM22'},
{value: 'ADAM23', id: 'ADAM23'},
{value: 'ADAM24P', id: 'ADAM24P'},
{value: 'ADAM28', id: 'ADAM28'},
{value: 'ADAM29', id: 'ADAM29'},
{value: 'ADAM30', id: 'ADAM30'},
{value: 'ADAM32', id: 'ADAM32'},
{value: 'ADAM33', id: 'ADAM33'},
{value: 'ADAM3A', id: 'ADAM3A'},
{value: 'ADAM5', id: 'ADAM5'},
{value: 'ADAM6', id: 'ADAM6'},
{value: 'ADAM8', id: 'ADAM8'},
{value: 'ADAM9', id: 'ADAM9'},
{value: 'ADAMDEC1', id: 'ADAMDEC1'},
{value: 'ADAMTS1', id: 'ADAMTS1'},
{value: 'ADAMTS10', id: 'ADAMTS10'},
{value: 'ADAMTS12', id: 'ADAMTS12'},
{value: 'ADAMTS13', id: 'ADAMTS13'},
{value: 'ADAMTS14', id: 'ADAMTS14'},
{value: 'ADAMTS15', id: 'ADAMTS15'},
{value: 'ADAMTS16', id: 'ADAMTS16'},
{value: 'ADAMTS16-DT', id: 'ADAMTS16-DT'},
{value: 'ADAMTS17', id: 'ADAMTS17'},
{value: 'ADAMTS18', id: 'ADAMTS18'},
{value: 'ADAMTS19', id: 'ADAMTS19'},
{value: 'ADAMTS19-AS1', id: 'ADAMTS19-AS1'},
{value: 'ADAMTS2', id: 'ADAMTS2'},
{value: 'ADAMTS20', id: 'ADAMTS20'},
{value: 'ADAMTS3', id: 'ADAMTS3'},
{value: 'ADAMTS4', id: 'ADAMTS4'},
{value: 'ADAMTS5', id: 'ADAMTS5'},
{value: 'ADAMTS6', id: 'ADAMTS6'},
{value: 'ADAMTS7', id: 'ADAMTS7'},
{value: 'ADAMTS7P1', id: 'ADAMTS7P1'},
{value: 'ADAMTS7P3', id: 'ADAMTS7P3'},
{value: 'ADAMTS7P4', id: 'ADAMTS7P4'},
{value: 'ADAMTS8', id: 'ADAMTS8'},
{value: 'ADAMTS9', id: 'ADAMTS9'},
{value: 'ADAMTS9-AS1', id: 'ADAMTS9-AS1'},
{value: 'ADAMTS9-AS2', id: 'ADAMTS9-AS2'},
{value: 'ADAMTSL1', id: 'ADAMTSL1'},
{value: 'ADAMTSL2', id: 'ADAMTSL2'},
{value: 'ADAMTSL3', id: 'ADAMTSL3'},
{value: 'ADAMTSL4', id: 'ADAMTSL4'},
{value: 'ADAMTSL4-AS1', id: 'ADAMTSL4-AS1'},
{value: 'ADAMTSL4-AS2', id: 'ADAMTSL4-AS2'},
{value: 'ADAMTSL5', id: 'ADAMTSL5'},
{value: 'ADAP1', id: 'ADAP1'},
{value: 'ADAP2', id: 'ADAP2'},
{value: 'ADAR', id: 'ADAR'},
{value: 'ADARB1', id: 'ADARB1'},
{value: 'ADARB2', id: 'ADARB2'},
{value: 'ADARB2-AS1', id: 'ADARB2-AS1'},
{value: 'ADAT1', id: 'ADAT1'},
{value: 'ADAT2', id: 'ADAT2'},
{value: 'ADAT3', id: 'ADAT3'},
{value: 'ADCK1', id: 'ADCK1'},
{value: 'ADCK2', id: 'ADCK2'},
{value: 'ADCK5', id: 'ADCK5'},
{value: 'ADCY1', id: 'ADCY1'},
{value: 'ADCY10', id: 'ADCY10'},
{value: 'ADCY10P1', id: 'ADCY10P1'},
{value: 'ADCY2', id: 'ADCY2'},
{value: 'ADCY3', id: 'ADCY3'},
{value: 'ADCY4', id: 'ADCY4'},
{value: 'ADCY5', id: 'ADCY5'},
{value: 'ADCY6', id: 'ADCY6'},
{value: 'ADCY6-DT', id: 'ADCY6-DT'},
{value: 'ADCY7', id: 'ADCY7'},
{value: 'ADCY8', id: 'ADCY8'},
{value: 'ADCY9', id: 'ADCY9'},
{value: 'ADCYAP1', id: 'ADCYAP1'},
{value: 'ADCYAP1R1', id: 'ADCYAP1R1'},
{value: 'ADD1', id: 'ADD1'},
{value: 'ADD2', id: 'ADD2'},
{value: 'ADD3', id: 'ADD3'},
{value: 'ADD3-AS1', id: 'ADD3-AS1'},
{value: 'ADGB', id: 'ADGB'},
{value: 'ADGRA1', id: 'ADGRA1'},
{value: 'ADGRA1-AS1', id: 'ADGRA1-AS1'},
{value: 'ADGRA2', id: 'ADGRA2'},
{value: 'ADGRA3', id: 'ADGRA3'},
{value: 'ADGRB1', id: 'ADGRB1'},
{value: 'ADGRB2', id: 'ADGRB2'},
{value: 'ADGRB3', id: 'ADGRB3'},
{value: 'ADGRD1', id: 'ADGRD1'},
{value: 'ADGRD1-AS1', id: 'ADGRD1-AS1'},
{value: 'ADGRD2', id: 'ADGRD2'},
{value: 'ADGRE1', id: 'ADGRE1'},
{value: 'ADGRE2', id: 'ADGRE2'},
{value: 'ADGRE3', id: 'ADGRE3'},
{value: 'ADGRE4P', id: 'ADGRE4P'},
{value: 'ADGRE5', id: 'ADGRE5'},
{value: 'ADGRF1', id: 'ADGRF1'},
{value: 'ADGRF2', id: 'ADGRF2'},
{value: 'ADGRF3', id: 'ADGRF3'},
{value: 'ADGRF4', id: 'ADGRF4'},
{value: 'ADGRF5', id: 'ADGRF5'},
{value: 'ADGRF5-AS1', id: 'ADGRF5-AS1'},
{value: 'ADGRF5P1', id: 'ADGRF5P1'},
{value: 'ADGRF5P2', id: 'ADGRF5P2'},
{value: 'ADGRG1', id: 'ADGRG1'},
{value: 'ADGRG2', id: 'ADGRG2'},
{value: 'ADGRG3', id: 'ADGRG3'},
{value: 'ADGRG4', id: 'ADGRG4'},
{value: 'ADGRG5', id: 'ADGRG5'},
{value: 'ADGRG6', id: 'ADGRG6'},
{value: 'ADGRG7', id: 'ADGRG7'},
{value: 'ADGRL1', id: 'ADGRL1'},
{value: 'ADGRL1-AS1', id: 'ADGRL1-AS1'},
{value: 'ADGRL2', id: 'ADGRL2'},
{value: 'ADGRL3', id: 'ADGRL3'},
{value: 'ADGRL3-AS1', id: 'ADGRL3-AS1'},
{value: 'ADGRL4', id: 'ADGRL4'},
{value: 'ADGRV1', id: 'ADGRV1'},
{value: 'ADH1A', id: 'ADH1A'},
{value: 'ADH1B', id: 'ADH1B'},
{value: 'ADH1C', id: 'ADH1C'},
{value: 'ADH4', id: 'ADH4'},
{value: 'ADH5', id: 'ADH5'},
{value: 'ADH5P2', id: 'ADH5P2'},
{value: 'ADH5P3', id: 'ADH5P3'},
{value: 'ADH5P4', id: 'ADH5P4'},
{value: 'ADH5P5', id: 'ADH5P5'},
{value: 'ADH7', id: 'ADH7'},
{value: 'ADHFE1', id: 'ADHFE1'},
{value: 'ADI1', id: 'ADI1'},
{value: 'ADI1P1', id: 'ADI1P1'},
{value: 'ADI1P2', id: 'ADI1P2'},
{value: 'ADI1P3', id: 'ADI1P3'},
{value: 'ADIG', id: 'ADIG'},
{value: 'ADIPOQ', id: 'ADIPOQ'},
{value: 'ADIPOQ-AS1', id: 'ADIPOQ-AS1'},
{value: 'ADIPOR1', id: 'ADIPOR1'},
{value: 'ADIPOR1P1', id: 'ADIPOR1P1'},
{value: 'ADIPOR1P2', id: 'ADIPOR1P2'},
{value: 'ADIPOR2', id: 'ADIPOR2'},
{value: 'ADIRF', id: 'ADIRF'},
{value: 'ADIRF-AS1', id: 'ADIRF-AS1'},
{value: 'ADK', id: 'ADK'},
{value: 'ADM', id: 'ADM'},
{value: 'ADM-DT', id: 'ADM-DT'},
{value: 'ADM2', id: 'ADM2'},
{value: 'ADM5', id: 'ADM5'},
{value: 'ADNP', id: 'ADNP'},
{value: 'ADNP-AS1', id: 'ADNP-AS1'},
{value: 'ADNP2', id: 'ADNP2'},
{value: 'ADO', id: 'ADO'},
{value: 'ADORA1', id: 'ADORA1'},
{value: 'ADORA2A', id: 'ADORA2A'},
{value: 'ADORA2A-AS1', id: 'ADORA2A-AS1'},
{value: 'ADORA2B', id: 'ADORA2B'},
{value: 'ADORA2BP1', id: 'ADORA2BP1'},
{value: 'ADORA3', id: 'ADORA3'},
{value: 'ADPGK', id: 'ADPGK'},
{value: 'ADPGK-AS1', id: 'ADPGK-AS1'},
{value: 'ADPRH', id: 'ADPRH'},
{value: 'ADPRHL1', id: 'ADPRHL1'},
{value: 'ADPRM', id: 'ADPRM'},
{value: 'ADPRS', id: 'ADPRS'},
{value: 'ADRA1A', id: 'ADRA1A'},
{value: 'ADRA1B', id: 'ADRA1B'},
{value: 'ADRA1D', id: 'ADRA1D'},
{value: 'ADRA2A', id: 'ADRA2A'},
{value: 'ADRA2B', id: 'ADRA2B'},
{value: 'ADRA2C', id: 'ADRA2C'},
{value: 'ADRB1', id: 'ADRB1'},
{value: 'ADRB2', id: 'ADRB2'},
{value: 'ADRB3', id: 'ADRB3'},
{value: 'ADRM1', id: 'ADRM1'},
{value: 'ADSL', id: 'ADSL'},
{value: 'ADSS1', id: 'ADSS1'},
{value: 'ADSS2', id: 'ADSS2'},
{value: 'ADTRP', id: 'ADTRP'},
{value: 'AEBP1', id: 'AEBP1'},
{value: 'AEBP2', id: 'AEBP2'},
{value: 'AEN', id: 'AEN'},
{value: 'AFAP1', id: 'AFAP1'},
{value: 'AFAP1-AS1', id: 'AFAP1-AS1'},
{value: 'AFAP1L1', id: 'AFAP1L1'},
{value: 'AFAP1L2', id: 'AFAP1L2'},
{value: 'AFDN', id: 'AFDN'},
{value: 'AFDN-DT', id: 'AFDN-DT'},
{value: 'AFF1', id: 'AFF1'},
{value: 'AFF2', id: 'AFF2'},
{value: 'AFF3', id: 'AFF3'},
{value: 'AFF4', id: 'AFF4'},
{value: 'AFF4-DT', id: 'AFF4-DT'},
{value: 'AFG1L', id: 'AFG1L'},
{value: 'AFG3L1P', id: 'AFG3L1P'},
{value: 'AFG3L2', id: 'AFG3L2'},
{value: 'AFG3L2P1', id: 'AFG3L2P1'},
{value: 'AFM', id: 'AFM'},
{value: 'AFMID', id: 'AFMID'},
{value: 'AFP', id: 'AFP'},
{value: 'AFTPH', id: 'AFTPH'},
{value: 'AFTPH-DT', id: 'AFTPH-DT'},
{value: 'AGA', id: 'AGA'},
{value: 'AGA-DT', id: 'AGA-DT'},
{value: 'AGAP1', id: 'AGAP1'},
{value: 'AGAP1-IT1', id: 'AGAP1-IT1'},
{value: 'AGAP10P', id: 'AGAP10P'},
{value: 'AGAP11', id: 'AGAP11'},
{value: 'AGAP12P', id: 'AGAP12P'},
{value: 'AGAP13P', id: 'AGAP13P'},
{value: 'AGAP14P', id: 'AGAP14P'},
{value: 'AGAP2', id: 'AGAP2'},
{value: 'AGAP2-AS1', id: 'AGAP2-AS1'},
{value: 'AGAP3', id: 'AGAP3'},
{value: 'AGAP4', id: 'AGAP4'},
{value: 'AGAP5', id: 'AGAP5'},
{value: 'AGAP6', id: 'AGAP6'},
{value: 'AGAP7P', id: 'AGAP7P'},
{value: 'AGAP9', id: 'AGAP9'},
{value: 'AGBL1', id: 'AGBL1'},
{value: 'AGBL1-AS1', id: 'AGBL1-AS1'},
{value: 'AGBL2', id: 'AGBL2'},
{value: 'AGBL3', id: 'AGBL3'},
{value: 'AGBL4', id: 'AGBL4'},
{value: 'AGBL4-IT1', id: 'AGBL4-IT1'},
{value: 'AGBL5', id: 'AGBL5'},
{value: 'AGBL5-AS1', id: 'AGBL5-AS1'},
{value: 'AGER', id: 'AGER'},
{value: 'AGFG1', id: 'AGFG1'},
{value: 'AGFG2', id: 'AGFG2'},
{value: 'AGGF1', id: 'AGGF1'},
{value: 'AGGF1P1', id: 'AGGF1P1'},
{value: 'AGGF1P10', id: 'AGGF1P10'},
{value: 'AGGF1P2', id: 'AGGF1P2'},
{value: 'AGGF1P4', id: 'AGGF1P4'},
{value: 'AGGF1P5', id: 'AGGF1P5'},
{value: 'AGGF1P6', id: 'AGGF1P6'},
{value: 'AGGF1P7', id: 'AGGF1P7'},
{value: 'AGGF1P8', id: 'AGGF1P8'},
{value: 'AGGF1P9', id: 'AGGF1P9'},
{value: 'AGK', id: 'AGK'},
{value: 'AGK-DT', id: 'AGK-DT'},
{value: 'AGL', id: 'AGL'},
{value: 'AGMAT', id: 'AGMAT'},
{value: 'AGMO', id: 'AGMO'},
{value: 'AGO1', id: 'AGO1'},
{value: 'AGO2', id: 'AGO2'},
{value: 'AGO3', id: 'AGO3'},
{value: 'AGO4', id: 'AGO4'},
{value: 'AGPAT1', id: 'AGPAT1'},
{value: 'AGPAT2', id: 'AGPAT2'},
{value: 'AGPAT3', id: 'AGPAT3'},
{value: 'AGPAT4', id: 'AGPAT4'},
{value: 'AGPAT5', id: 'AGPAT5'},
{value: 'AGPS', id: 'AGPS'},
{value: 'AGR2', id: 'AGR2'},
{value: 'AGR3', id: 'AGR3'},
{value: 'AGRN', id: 'AGRN'},
{value: 'AGRP', id: 'AGRP'},
{value: 'AGT', id: 'AGT'},
{value: 'AGTPBP1', id: 'AGTPBP1'},
{value: 'AGTR1', id: 'AGTR1'},
{value: 'AGTRAP', id: 'AGTRAP'},
{value: 'AGXT', id: 'AGXT'},
{value: 'AGXT2', id: 'AGXT2'},
{value: 'AHCTF1', id: 'AHCTF1'},
{value: 'AHCTF1P1', id: 'AHCTF1P1'},
{value: 'AHCY', id: 'AHCY'},
{value: 'AHCYL1', id: 'AHCYL1'},
{value: 'AHCYL2', id: 'AHCYL2'},
{value: 'AHCYP2', id: 'AHCYP2'},
{value: 'AHCYP3', id: 'AHCYP3'},
{value: 'AHCYP4', id: 'AHCYP4'},
{value: 'AHCYP5', id: 'AHCYP5'},
{value: 'AHCYP7', id: 'AHCYP7'},
{value: 'AHDC1', id: 'AHDC1'},
{value: 'AHI1', id: 'AHI1'},
{value: 'AHNAK', id: 'AHNAK'},
{value: 'AHNAK2', id: 'AHNAK2'},
{value: 'AHR', id: 'AHR'},
{value: 'AHRR', id: 'AHRR'},
{value: 'AHSA1', id: 'AHSA1'},
{value: 'AHSA2P', id: 'AHSA2P'},
{value: 'AHSG', id: 'AHSG'},
{value: 'AHSP', id: 'AHSP'},
{value: 'AICDA', id: 'AICDA'},
{value: 'AIDA', id: 'AIDA'},
{value: 'AIDAP1', id: 'AIDAP1'},
{value: 'AIDAP2', id: 'AIDAP2'},
{value: 'AIDAP3', id: 'AIDAP3'},
{value: 'AIF1', id: 'AIF1'},
{value: 'AIF1L', id: 'AIF1L'},
{value: 'AIFM1', id: 'AIFM1'},
{value: 'AIFM1P1', id: 'AIFM1P1'},
{value: 'AIFM2', id: 'AIFM2'},
{value: 'AIFM3', id: 'AIFM3'},
{value: 'AIG1', id: 'AIG1'},
{value: 'AIG1P1', id: 'AIG1P1'},
{value: 'AIM2', id: 'AIM2'},
{value: 'AIMP1', id: 'AIMP1'},
{value: 'AIMP1P2', id: 'AIMP1P2'},
{value: 'AIMP2', id: 'AIMP2'},
{value: 'AIP', id: 'AIP'},
{value: 'AIPL1', id: 'AIPL1'},
{value: 'AIRE', id: 'AIRE'},
{value: 'AIRN', id: 'AIRN'},
{value: 'AJAP1', id: 'AJAP1'},
{value: 'AJM1', id: 'AJM1'},
{value: 'AJUBA', id: 'AJUBA'},
{value: 'AJUBA-DT', id: 'AJUBA-DT'},
{value: 'AK1', id: 'AK1'},
{value: 'AK2', id: 'AK2'},
{value: 'AK2P2', id: 'AK2P2'},
{value: 'AK3', id: 'AK3'},
{value: 'AK3P2', id: 'AK3P2'},
{value: 'AK3P3', id: 'AK3P3'},
{value: 'AK3P4', id: 'AK3P4'},
{value: 'AK3P5', id: 'AK3P5'},
{value: 'AK4', id: 'AK4'},
{value: 'AK4P1', id: 'AK4P1'},
{value: 'AK4P2', id: 'AK4P2'},
{value: 'AK4P3', id: 'AK4P3'},
{value: 'AK4P4', id: 'AK4P4'},
{value: 'AK4P5', id: 'AK4P5'},
{value: 'AK4P6', id: 'AK4P6'},
{value: 'AK5', id: 'AK5'},
{value: 'AK6', id: 'AK6'},
{value: 'AK6P1', id: 'AK6P1'},
{value: 'AK6P2', id: 'AK6P2'},
{value: 'AK7', id: 'AK7'},
{value: 'AK8', id: 'AK8'},
{value: 'AK9', id: 'AK9'},
{value: 'AKAIN1', id: 'AKAIN1'},
{value: 'AKAP1', id: 'AKAP1'},
{value: 'AKAP1-DT', id: 'AKAP1-DT'},
{value: 'AKAP10', id: 'AKAP10'},
{value: 'AKAP11', id: 'AKAP11'},
{value: 'AKAP12', id: 'AKAP12'},
{value: 'AKAP13', id: 'AKAP13'},
{value: 'AKAP17A', id: 'AKAP17A'},
{value: 'AKAP3', id: 'AKAP3'},
{value: 'AKAP5', id: 'AKAP5'},
{value: 'AKAP6', id: 'AKAP6'},
{value: 'AKAP7', id: 'AKAP7'},
{value: 'AKAP8', id: 'AKAP8'},
{value: 'AKAP8L', id: 'AKAP8L'},
{value: 'AKAP8P1', id: 'AKAP8P1'},
{value: 'AKAP9', id: 'AKAP9'},
{value: 'AKIP1', id: 'AKIP1'},
{value: 'AKIRIN1', id: 'AKIRIN1'},
{value: 'AKIRIN1P1', id: 'AKIRIN1P1'},
{value: 'AKIRIN2', id: 'AKIRIN2'},
{value: 'AKNA', id: 'AKNA'},
{value: 'AKNAD1', id: 'AKNAD1'},
{value: 'AKR1A1', id: 'AKR1A1'},
{value: 'AKR1B1', id: 'AKR1B1'},
{value: 'AKR1B10', id: 'AKR1B10'},
{value: 'AKR1B10P2', id: 'AKR1B10P2'},
{value: 'AKR1B15', id: 'AKR1B15'},
{value: 'AKR1B1P1', id: 'AKR1B1P1'},
{value: 'AKR1B1P2', id: 'AKR1B1P2'},
{value: 'AKR1B1P3', id: 'AKR1B1P3'},
{value: 'AKR1B1P4', id: 'AKR1B1P4'},
{value: 'AKR1B1P5', id: 'AKR1B1P5'},
{value: 'AKR1B1P6', id: 'AKR1B1P6'},
{value: 'AKR1B1P7', id: 'AKR1B1P7'},
{value: 'AKR1C1', id: 'AKR1C1'},
{value: 'AKR1C2', id: 'AKR1C2'},
{value: 'AKR1C3', id: 'AKR1C3'},
{value: 'AKR1C4', id: 'AKR1C4'},
{value: 'AKR1C5P', id: 'AKR1C5P'},
{value: 'AKR1C6P', id: 'AKR1C6P'},
{value: 'AKR1C7P', id: 'AKR1C7P'},
{value: 'AKR1C8P', id: 'AKR1C8P'},
{value: 'AKR1D1', id: 'AKR1D1'},
{value: 'AKR1D1P1', id: 'AKR1D1P1'},
{value: 'AKR1E2', id: 'AKR1E2'},
{value: 'AKR7A2', id: 'AKR7A2'},
{value: 'AKR7A2P1', id: 'AKR7A2P1'},
{value: 'AKR7A3', id: 'AKR7A3'},
{value: 'AKR7L', id: 'AKR7L'},
{value: 'AKT1', id: 'AKT1'},
{value: 'AKT1S1', id: 'AKT1S1'},
{value: 'AKT2', id: 'AKT2'},
{value: 'AKT3', id: 'AKT3'},
{value: 'AKTIP', id: 'AKTIP'},
{value: 'ALAD', id: 'ALAD'},
{value: 'ALAS1', id: 'ALAS1'},
{value: 'ALAS2', id: 'ALAS2'},
{value: 'ALB', id: 'ALB'},
{value: 'ALCAM', id: 'ALCAM'},
{value: 'ALDH16A1', id: 'ALDH16A1'},
{value: 'ALDH18A1', id: 'ALDH18A1'},
{value: 'ALDH1A1', id: 'ALDH1A1'},
{value: 'ALDH1A2', id: 'ALDH1A2'},
{value: 'ALDH1A2-AS1', id: 'ALDH1A2-AS1'},
{value: 'ALDH1A3', id: 'ALDH1A3'},
{value: 'ALDH1A3-AS1', id: 'ALDH1A3-AS1'},
{value: 'ALDH1B1', id: 'ALDH1B1'},
{value: 'ALDH1L1', id: 'ALDH1L1'},
{value: 'ALDH1L1-AS1', id: 'ALDH1L1-AS1'},
{value: 'ALDH1L1-AS2', id: 'ALDH1L1-AS2'},
{value: 'ALDH1L2', id: 'ALDH1L2'},
{value: 'ALDH2', id: 'ALDH2'},
{value: 'ALDH3A1', id: 'ALDH3A1'},
{value: 'ALDH3A2', id: 'ALDH3A2'},
{value: 'ALDH3B1', id: 'ALDH3B1'},
{value: 'ALDH3B2', id: 'ALDH3B2'},
{value: 'ALDH4A1', id: 'ALDH4A1'},
{value: 'ALDH5A1', id: 'ALDH5A1'},
{value: 'ALDH6A1', id: 'ALDH6A1'},
{value: 'ALDH7A1', id: 'ALDH7A1'},
{value: 'ALDH7A1P1', id: 'ALDH7A1P1'},
{value: 'ALDH7A1P3', id: 'ALDH7A1P3'},
{value: 'ALDH7A1P4', id: 'ALDH7A1P4'},
{value: 'ALDH8A1', id: 'ALDH8A1'},
{value: 'ALDH9A1', id: 'ALDH9A1'},
{value: 'ALDOA', id: 'ALDOA'},
{value: 'ALDOAP1', id: 'ALDOAP1'},
{value: 'ALDOAP2', id: 'ALDOAP2'},
{value: 'ALDOB', id: 'ALDOB'},
{value: 'ALDOC', id: 'ALDOC'},
{value: 'ALG1', id: 'ALG1'},
{value: 'ALG10', id: 'ALG10'},
{value: 'ALG10B', id: 'ALG10B'},
{value: 'ALG11', id: 'ALG11'},
{value: 'ALG12', id: 'ALG12'},
{value: 'ALG13', id: 'ALG13'},
{value: 'ALG14', id: 'ALG14'},
{value: 'ALG14-AS1', id: 'ALG14-AS1'},
{value: 'ALG1L', id: 'ALG1L'},
{value: 'ALG1L10P', id: 'ALG1L10P'},
{value: 'ALG1L13P', id: 'ALG1L13P'},
{value: 'ALG1L15P', id: 'ALG1L15P'},
{value: 'ALG1L2', id: 'ALG1L2'},
{value: 'ALG1L3P', id: 'ALG1L3P'},
{value: 'ALG1L5P', id: 'ALG1L5P'},
{value: 'ALG1L6P', id: 'ALG1L6P'},
{value: 'ALG1L8P', id: 'ALG1L8P'},
{value: 'ALG1L9P', id: 'ALG1L9P'},
{value: 'ALG2', id: 'ALG2'},
{value: 'ALG3', id: 'ALG3'},
{value: 'ALG3P1', id: 'ALG3P1'},
{value: 'ALG5', id: 'ALG5'},
{value: 'ALG6', id: 'ALG6'},
{value: 'ALG8', id: 'ALG8'},
{value: 'ALG9', id: 'ALG9'},
{value: 'ALK', id: 'ALK'},
{value: 'ALKAL1', id: 'ALKAL1'},
{value: 'ALKAL2', id: 'ALKAL2'},
{value: 'ALKBH1', id: 'ALKBH1'},
{value: 'ALKBH2', id: 'ALKBH2'},
{value: 'ALKBH3', id: 'ALKBH3'},
{value: 'ALKBH3-AS1', id: 'ALKBH3-AS1'},
{value: 'ALKBH4', id: 'ALKBH4'},
{value: 'ALKBH5', id: 'ALKBH5'},
{value: 'ALKBH6', id: 'ALKBH6'},
{value: 'ALKBH7', id: 'ALKBH7'},
{value: 'ALKBH8', id: 'ALKBH8'},
{value: 'ALLC', id: 'ALLC'},
{value: 'ALMS1', id: 'ALMS1'},
{value: 'ALMS1-IT1', id: 'ALMS1-IT1'},
{value: 'ALMS1P1', id: 'ALMS1P1'},
{value: 'ALOX12', id: 'ALOX12'},
{value: 'ALOX12-AS1', id: 'ALOX12-AS1'},
{value: 'ALOX12B', id: 'ALOX12B'},
{value: 'ALOX12P1', id: 'ALOX12P1'},
{value: 'ALOX12P2', id: 'ALOX12P2'},
{value: 'ALOX15', id: 'ALOX15'},
{value: 'ALOX15B', id: 'ALOX15B'},
{value: 'ALOX15P1', id: 'ALOX15P1'},
{value: 'ALOX15P2', id: 'ALOX15P2'},
{value: 'ALOX5', id: 'ALOX5'},
{value: 'ALOX5AP', id: 'ALOX5AP'},
{value: 'ALOXE3', id: 'ALOXE3'},
{value: 'ALOXE3P1', id: 'ALOXE3P1'},
{value: 'ALPG', id: 'ALPG'},
{value: 'ALPI', id: 'ALPI'},
{value: 'ALPK1', id: 'ALPK1'},
{value: 'ALPK2', id: 'ALPK2'},
{value: 'ALPK3', id: 'ALPK3'},
{value: 'ALPL', id: 'ALPL'},
{value: 'ALPP', id: 'ALPP'},
{value: 'ALS2', id: 'ALS2'},
{value: 'ALS2CL', id: 'ALS2CL'},
{value: 'ALX1', id: 'ALX1'},
{value: 'ALX3', id: 'ALX3'},
{value: 'ALX4', id: 'ALX4'},
{value: 'ALYREF', id: 'ALYREF'},
{value: 'AMACR', id: 'AMACR'},
{value: 'AMBN', id: 'AMBN'},
{value: 'AMBP', id: 'AMBP'},
{value: 'AMBRA1', id: 'AMBRA1'},
{value: 'AMD1', id: 'AMD1'},
{value: 'AMD1P1', id: 'AMD1P1'},
{value: 'AMD1P3', id: 'AMD1P3'},
{value: 'AMD1P4', id: 'AMD1P4'},
{value: 'AMDHD1', id: 'AMDHD1'},
{value: 'AMDHD2', id: 'AMDHD2'},
{value: 'AMER1', id: 'AMER1'},
{value: 'AMER2', id: 'AMER2'},
{value: 'AMER3', id: 'AMER3'},
{value: 'AMFR', id: 'AMFR'},
{value: 'AMH', id: 'AMH'},
{value: 'AMHR2', id: 'AMHR2'},
{value: 'AMIGO1', id: 'AMIGO1'},
{value: 'AMIGO2', id: 'AMIGO2'},
{value: 'AMIGO3', id: 'AMIGO3'},
{value: 'AMMECR1', id: 'AMMECR1'},
{value: 'AMMECR1L', id: 'AMMECR1L'},
{value: 'AMMECR1LP1', id: 'AMMECR1LP1'},
{value: 'AMN', id: 'AMN'},
{value: 'AMN1', id: 'AMN1'},
{value: 'AMOT', id: 'AMOT'},
{value: 'AMOTL1', id: 'AMOTL1'},
{value: 'AMOTL2', id: 'AMOTL2'},
{value: 'AMPD1', id: 'AMPD1'},
{value: 'AMPD2', id: 'AMPD2'},
{value: 'AMPD3', id: 'AMPD3'},
{value: 'AMPH', id: 'AMPH'},
{value: 'AMT', id: 'AMT'},
{value: 'AMTN', id: 'AMTN'},
{value: 'AMY1A', id: 'AMY1A'},
{value: 'AMY1B', id: 'AMY1B'},
{value: 'AMY1C', id: 'AMY1C'},
{value: 'AMY2A', id: 'AMY2A'},
{value: 'AMY2B', id: 'AMY2B'},
{value: 'AMYP1', id: 'AMYP1'},
{value: 'AMZ1', id: 'AMZ1'},
{value: 'AMZ2', id: 'AMZ2'},
{value: 'AMZ2P1', id: 'AMZ2P1'},
{value: 'AMZ2P2', id: 'AMZ2P2'},
{value: 'ANAPC1', id: 'ANAPC1'},
{value: 'ANAPC10', id: 'ANAPC10'},
{value: 'ANAPC11', id: 'ANAPC11'},
{value: 'ANAPC13', id: 'ANAPC13'},
{value: 'ANAPC15', id: 'ANAPC15'},
{value: 'ANAPC15P2', id: 'ANAPC15P2'},
{value: 'ANAPC16', id: 'ANAPC16'},
{value: 'ANAPC1P1', id: 'ANAPC1P1'},
{value: 'ANAPC1P2', id: 'ANAPC1P2'},
{value: 'ANAPC1P4', id: 'ANAPC1P4'},
{value: 'ANAPC1P5', id: 'ANAPC1P5'},
{value: 'ANAPC1P6', id: 'ANAPC1P6'},
{value: 'ANAPC2', id: 'ANAPC2'},
{value: 'ANAPC4', id: 'ANAPC4'},
{value: 'ANAPC5', id: 'ANAPC5'},
{value: 'ANAPC7', id: 'ANAPC7'},
{value: 'ANG', id: 'ANG'},
{value: 'ANGEL1', id: 'ANGEL1'},
{value: 'ANGEL2', id: 'ANGEL2'},
{value: 'ANGPT1', id: 'ANGPT1'},
{value: 'ANGPT2', id: 'ANGPT2'},
{value: 'ANGPT4', id: 'ANGPT4'},
{value: 'ANGPTL1', id: 'ANGPTL1'},
{value: 'ANGPTL2', id: 'ANGPTL2'},
{value: 'ANGPTL3', id: 'ANGPTL3'},
{value: 'ANGPTL4', id: 'ANGPTL4'},
{value: 'ANGPTL5', id: 'ANGPTL5'},
{value: 'ANGPTL6', id: 'ANGPTL6'},
{value: 'ANGPTL7', id: 'ANGPTL7'},
{value: 'ANGPTL8', id: 'ANGPTL8'},
{value: 'ANHX', id: 'ANHX'},
{value: 'ANK1', id: 'ANK1'},
{value: 'ANK2', id: 'ANK2'},
{value: 'ANK2-AS1', id: 'ANK2-AS1'},
{value: 'ANK3', id: 'ANK3'},
{value: 'ANK3-DT', id: 'ANK3-DT'},
{value: 'ANKAR', id: 'ANKAR'},
{value: 'ANKDD1A', id: 'ANKDD1A'},
{value: 'ANKDD1B', id: 'ANKDD1B'},
{value: 'ANKEF1', id: 'ANKEF1'},
{value: 'ANKFN1', id: 'ANKFN1'},
{value: 'ANKFY1', id: 'ANKFY1'},
{value: 'ANKH', id: 'ANKH'},
{value: 'ANKHD1', id: 'ANKHD1'},
{value: 'ANKHD1-DT', id: 'ANKHD1-DT'},
{value: 'ANKHD1-EIF4EBP3', id: 'ANKHD1-EIF4EBP3'},
{value: 'ANKIB1', id: 'ANKIB1'},
{value: 'ANKK1', id: 'ANKK1'},
{value: 'ANKLE1', id: 'ANKLE1'},
{value: 'ANKLE2', id: 'ANKLE2'},
{value: 'ANKMY1', id: 'ANKMY1'},
{value: 'ANKMY2', id: 'ANKMY2'},
{value: 'ANKRA2', id: 'ANKRA2'},
{value: 'ANKRD1', id: 'ANKRD1'},
{value: 'ANKRD10', id: 'ANKRD10'},
{value: 'ANKRD10-IT1', id: 'ANKRD10-IT1'},
{value: 'ANKRD11', id: 'ANKRD11'},
{value: 'ANKRD11P1', id: 'ANKRD11P1'},
{value: 'ANKRD11P2', id: 'ANKRD11P2'},
{value: 'ANKRD12', id: 'ANKRD12'},
{value: 'ANKRD13A', id: 'ANKRD13A'},
{value: 'ANKRD13B', id: 'ANKRD13B'},
{value: 'ANKRD13C', id: 'ANKRD13C'},
{value: 'ANKRD13D', id: 'ANKRD13D'},
{value: 'ANKRD16', id: 'ANKRD16'},
{value: 'ANKRD17', id: 'ANKRD17'},
{value: 'ANKRD17-DT', id: 'ANKRD17-DT'},
{value: 'ANKRD18A', id: 'ANKRD18A'},
{value: 'ANKRD18B', id: 'ANKRD18B'},
{value: 'ANKRD18CP', id: 'ANKRD18CP'},
{value: 'ANKRD18DP', id: 'ANKRD18DP'},
{value: 'ANKRD18EP', id: 'ANKRD18EP'},
{value: 'ANKRD19P', id: 'ANKRD19P'},
{value: 'ANKRD2', id: 'ANKRD2'},
{value: 'ANKRD20A1', id: 'ANKRD20A1'},
{value: 'ANKRD20A10P', id: 'ANKRD20A10P'},
{value: 'ANKRD20A11P', id: 'ANKRD20A11P'},
{value: 'ANKRD20A17P', id: 'ANKRD20A17P'},
{value: 'ANKRD20A18P', id: 'ANKRD20A18P'},
{value: 'ANKRD20A19P', id: 'ANKRD20A19P'},
{value: 'ANKRD20A21P', id: 'ANKRD20A21P'},
{value: 'ANKRD20A2P', id: 'ANKRD20A2P'},
{value: 'ANKRD20A3P', id: 'ANKRD20A3P'},
{value: 'ANKRD20A4P', id: 'ANKRD20A4P'},
{value: 'ANKRD20A5P', id: 'ANKRD20A5P'},
{value: 'ANKRD20A7P', id: 'ANKRD20A7P'},
{value: 'ANKRD20A8P', id: 'ANKRD20A8P'},
{value: 'ANKRD20A9P', id: 'ANKRD20A9P'},
{value: 'ANKRD22', id: 'ANKRD22'},
{value: 'ANKRD23', id: 'ANKRD23'},
{value: 'ANKRD24', id: 'ANKRD24'},
{value: 'ANKRD26', id: 'ANKRD26'},
{value: 'ANKRD26P1', id: 'ANKRD26P1'},
{value: 'ANKRD26P2', id: 'ANKRD26P2'},
{value: 'ANKRD26P3', id: 'ANKRD26P3'},
{value: 'ANKRD26P4', id: 'ANKRD26P4'},
{value: 'ANKRD27', id: 'ANKRD27'},
{value: 'ANKRD28', id: 'ANKRD28'},
{value: 'ANKRD29', id: 'ANKRD29'},
{value: 'ANKRD30A', id: 'ANKRD30A'},
{value: 'ANKRD30B', id: 'ANKRD30B'},
{value: 'ANKRD30BL', id: 'ANKRD30BL'},
{value: 'ANKRD30BP1', id: 'ANKRD30BP1'},
{value: 'ANKRD30BP2', id: 'ANKRD30BP2'},
{value: 'ANKRD31', id: 'ANKRD31'},
{value: 'ANKRD33', id: 'ANKRD33'},
{value: 'ANKRD33B', id: 'ANKRD33B'},
{value: 'ANKRD33B-AS1', id: 'ANKRD33B-AS1'},
{value: 'ANKRD34A', id: 'ANKRD34A'},
{value: 'ANKRD34B', id: 'ANKRD34B'},
{value: 'ANKRD34C', id: 'ANKRD34C'},
{value: 'ANKRD34C-AS1', id: 'ANKRD34C-AS1'},
{value: 'ANKRD35', id: 'ANKRD35'},
{value: 'ANKRD36', id: 'ANKRD36'},
{value: 'ANKRD36B', id: 'ANKRD36B'},
{value: 'ANKRD36BP1', id: 'ANKRD36BP1'},
{value: 'ANKRD36BP2', id: 'ANKRD36BP2'},
{value: 'ANKRD36C', id: 'ANKRD36C'},
{value: 'ANKRD37', id: 'ANKRD37'},
{value: 'ANKRD39', id: 'ANKRD39'},
{value: 'ANKRD40', id: 'ANKRD40'},
{value: 'ANKRD40CL', id: 'ANKRD40CL'},
{value: 'ANKRD42', id: 'ANKRD42'},
{value: 'ANKRD42-DT', id: 'ANKRD42-DT'},
{value: 'ANKRD44', id: 'ANKRD44'},
{value: 'ANKRD44-AS1', id: 'ANKRD44-AS1'},
{value: 'ANKRD44-IT1', id: 'ANKRD44-IT1'},
{value: 'ANKRD45', id: 'ANKRD45'},
{value: 'ANKRD46', id: 'ANKRD46'},
{value: 'ANKRD49', id: 'ANKRD49'},
{value: 'ANKRD49P1', id: 'ANKRD49P1'},
{value: 'ANKRD49P2', id: 'ANKRD49P2'},
{value: 'ANKRD49P3', id: 'ANKRD49P3'},
{value: 'ANKRD50', id: 'ANKRD50'},
{value: 'ANKRD52', id: 'ANKRD52'},
{value: 'ANKRD53', id: 'ANKRD53'},
{value: 'ANKRD54', id: 'ANKRD54'},
{value: 'ANKRD54P1', id: 'ANKRD54P1'},
{value: 'ANKRD55', id: 'ANKRD55'},
{value: 'ANKRD6', id: 'ANKRD6'},
{value: 'ANKRD60', id: 'ANKRD60'},
{value: 'ANKRD61', id: 'ANKRD61'},
{value: 'ANKRD62', id: 'ANKRD62'},
{value: 'ANKRD63', id: 'ANKRD63'},
{value: 'ANKRD65', id: 'ANKRD65'},
{value: 'ANKRD66', id: 'ANKRD66'},
{value: 'ANKRD7', id: 'ANKRD7'},
{value: 'ANKRD9', id: 'ANKRD9'},
{value: 'ANKS1A', id: 'ANKS1A'},
{value: 'ANKS1B', id: 'ANKS1B'},
{value: 'ANKS3', id: 'ANKS3'},
{value: 'ANKS4B', id: 'ANKS4B'},
{value: 'ANKS6', id: 'ANKS6'},
{value: 'ANKUB1', id: 'ANKUB1'},
{value: 'ANKZF1', id: 'ANKZF1'},
{value: 'ANLN', id: 'ANLN'},
{value: 'ANO1', id: 'ANO1'},
{value: 'ANO1-AS1', id: 'ANO1-AS1'},
{value: 'ANO10', id: 'ANO10'},
{value: 'ANO2', id: 'ANO2'},
{value: 'ANO3', id: 'ANO3'},
{value: 'ANO4', id: 'ANO4'},
{value: 'ANO5', id: 'ANO5'},
{value: 'ANO6', id: 'ANO6'},
{value: 'ANO7', id: 'ANO7'},
{value: 'ANO7L1', id: 'ANO7L1'},
{value: 'ANO8', id: 'ANO8'},
{value: 'ANO9', id: 'ANO9'},
{value: 'ANOS1', id: 'ANOS1'},
{value: 'ANOS2P', id: 'ANOS2P'},
{value: 'ANP32A', id: 'ANP32A'},
{value: 'ANP32AP1', id: 'ANP32AP1'},
{value: 'ANP32B', id: 'ANP32B'},
{value: 'ANP32BP1', id: 'ANP32BP1'},
{value: 'ANP32BP2', id: 'ANP32BP2'},
{value: 'ANP32D', id: 'ANP32D'},
{value: 'ANP32E', id: 'ANP32E'},
{value: 'ANPEP', id: 'ANPEP'},
{value: 'ANTKMT', id: 'ANTKMT'},
{value: 'ANTXR1', id: 'ANTXR1'},
{value: 'ANTXR2', id: 'ANTXR2'},
{value: 'ANTXRL', id: 'ANTXRL'},
{value: 'ANTXRLP1', id: 'ANTXRLP1'},
{value: 'ANXA1', id: 'ANXA1'},
{value: 'ANXA10', id: 'ANXA10'},
{value: 'ANXA11', id: 'ANXA11'},
{value: 'ANXA13', id: 'ANXA13'},
{value: 'ANXA2', id: 'ANXA2'},
{value: 'ANXA2P1', id: 'ANXA2P1'},
{value: 'ANXA2P2', id: 'ANXA2P2'},
{value: 'ANXA2P3', id: 'ANXA2P3'},
{value: 'ANXA2R', id: 'ANXA2R'},
{value: 'ANXA3', id: 'ANXA3'},
{value: 'ANXA4', id: 'ANXA4'},
{value: 'ANXA5', id: 'ANXA5'},
{value: 'ANXA6', id: 'ANXA6'},
{value: 'ANXA7', id: 'ANXA7'},
{value: 'ANXA8', id: 'ANXA8'},
{value: 'ANXA8L1', id: 'ANXA8L1'},
{value: 'ANXA9', id: 'ANXA9'},
{value: 'AOAH', id: 'AOAH'},
{value: 'AOAH-IT1', id: 'AOAH-IT1'},
{value: 'AOC1', id: 'AOC1'},
{value: 'AOC2', id: 'AOC2'},
{value: 'AOC3', id: 'AOC3'},
{value: 'AOC4P', id: 'AOC4P'},
{value: 'AOPEP', id: 'AOPEP'},
{value: 'AOX1', id: 'AOX1'},
{value: 'AOX2P', id: 'AOX2P'},
{value: 'AOX3P', id: 'AOX3P'},
{value: 'AOX3P-AOX2P', id: 'AOX3P-AOX2P'},
{value: 'AP1AR', id: 'AP1AR'},
{value: 'AP1AR-DT', id: 'AP1AR-DT'},
{value: 'AP1B1', id: 'AP1B1'},
{value: 'AP1B1P1', id: 'AP1B1P1'},
{value: 'AP1B1P2', id: 'AP1B1P2'},
{value: 'AP1G1', id: 'AP1G1'},
{value: 'AP1G2', id: 'AP1G2'},
{value: 'AP1G2-AS1', id: 'AP1G2-AS1'},
{value: 'AP1M1', id: 'AP1M1'},
{value: 'AP1M2', id: 'AP1M2'},
{value: 'AP1S1', id: 'AP1S1'},
{value: 'AP1S2', id: 'AP1S2'},
{value: 'AP1S2P1', id: 'AP1S2P1'},
{value: 'AP1S3', id: 'AP1S3'},
{value: 'AP2A1', id: 'AP2A1'},
{value: 'AP2A2', id: 'AP2A2'},
{value: 'AP2B1', id: 'AP2B1'},
{value: 'AP2M1', id: 'AP2M1'},
{value: 'AP2S1', id: 'AP2S1'},
{value: 'AP3B1', id: 'AP3B1'},
{value: 'AP3B2', id: 'AP3B2'},
{value: 'AP3D1', id: 'AP3D1'},
{value: 'AP3M1', id: 'AP3M1'},
{value: 'AP3M2', id: 'AP3M2'},
{value: 'AP3S1', id: 'AP3S1'},
{value: 'AP3S2', id: 'AP3S2'},
{value: 'AP4B1', id: 'AP4B1'},
{value: 'AP4B1-AS1', id: 'AP4B1-AS1'},
{value: 'AP4E1', id: 'AP4E1'},
{value: 'AP4M1', id: 'AP4M1'},
{value: 'AP4S1', id: 'AP4S1'},
{value: 'AP5B1', id: 'AP5B1'},
{value: 'AP5M1', id: 'AP5M1'},
{value: 'AP5S1', id: 'AP5S1'},
{value: 'AP5Z1', id: 'AP5Z1'},
{value: 'APAF1', id: 'APAF1'},
{value: 'APBA1', id: 'APBA1'},
{value: 'APBA2', id: 'APBA2'},
{value: 'APBA3', id: 'APBA3'},
{value: 'APBB1', id: 'APBB1'},
{value: 'APBB1IP', id: 'APBB1IP'},
{value: 'APBB2', id: 'APBB2'},
{value: 'APBB3', id: 'APBB3'},
{value: 'APC', id: 'APC'},
{value: 'APC2', id: 'APC2'},
{value: 'APCDD1', id: 'APCDD1'},
{value: 'APCDD1L', id: 'APCDD1L'},
{value: 'APCDD1L-DT', id: 'APCDD1L-DT'},
{value: 'APCS', id: 'APCS'},
{value: 'APEH', id: 'APEH'},
{value: 'APELA', id: 'APELA'},
{value: 'APEX1', id: 'APEX1'},
{value: 'APEX2', id: 'APEX2'},
{value: 'APH1A', id: 'APH1A'},
{value: 'APH1B', id: 'APH1B'},
{value: 'API5', id: 'API5'},
{value: 'API5P2', id: 'API5P2'},
{value: 'APIP', id: 'APIP'},
{value: 'APLF', id: 'APLF'},
{value: 'APLN', id: 'APLN'},
{value: 'APLNR', id: 'APLNR'},
{value: 'APLP1', id: 'APLP1'},
{value: 'APLP2', id: 'APLP2'},
{value: 'APMAP', id: 'APMAP'},
{value: 'APOA1', id: 'APOA1'},
{value: 'APOA1-AS', id: 'APOA1-AS'},
{value: 'APOA2', id: 'APOA2'},
{value: 'APOA4', id: 'APOA4'},
{value: 'APOA5', id: 'APOA5'},
{value: 'APOB', id: 'APOB'},
{value: 'APOBEC1', id: 'APOBEC1'},
{value: 'APOBEC2', id: 'APOBEC2'},
{value: 'APOBEC3A', id: 'APOBEC3A'},
{value: 'APOBEC3AP1', id: 'APOBEC3AP1'},
{value: 'APOBEC3B', id: 'APOBEC3B'},
{value: 'APOBEC3B-AS1', id: 'APOBEC3B-AS1'},
{value: 'APOBEC3C', id: 'APOBEC3C'},
{value: 'APOBEC3D', id: 'APOBEC3D'},
{value: 'APOBEC3F', id: 'APOBEC3F'},
{value: 'APOBEC3G', id: 'APOBEC3G'},
{value: 'APOBEC3H', id: 'APOBEC3H'},
{value: 'APOBEC4', id: 'APOBEC4'},
{value: 'APOBR', id: 'APOBR'},
{value: 'APOC1', id: 'APOC1'},
{value: 'APOC1P1', id: 'APOC1P1'},
{value: 'APOC2', id: 'APOC2'},
{value: 'APOC3', id: 'APOC3'},
{value: 'APOC4', id: 'APOC4'},
{value: 'APOC4-APOC2', id: 'APOC4-APOC2'},
{value: 'APOD', id: 'APOD'},
{value: 'APOE', id: 'APOE'},
{value: 'APOF', id: 'APOF'},
{value: 'APOH', id: 'APOH'},
{value: 'APOL1', id: 'APOL1'},
{value: 'APOL2', id: 'APOL2'},
{value: 'APOL3', id: 'APOL3'},
{value: 'APOL4', id: 'APOL4'},
{value: 'APOL5', id: 'APOL5'},
{value: 'APOL6', id: 'APOL6'},
{value: 'APOLD1', id: 'APOLD1'},
{value: 'APOM', id: 'APOM'},
{value: 'APONP', id: 'APONP'},
{value: 'APOO', id: 'APOO'},
{value: 'APOOL', id: 'APOOL'},
{value: 'APOOP1', id: 'APOOP1'},
{value: 'APOOP2', id: 'APOOP2'},
{value: 'APOOP3', id: 'APOOP3'},
{value: 'APOOP4', id: 'APOOP4'},
{value: 'APOOP5', id: 'APOOP5'},
{value: 'APP', id: 'APP'},
{value: 'APP-DT', id: 'APP-DT'},
{value: 'APPBP2', id: 'APPBP2'},
{value: 'APPBP2-DT', id: 'APPBP2-DT'},
{value: 'APPL1', id: 'APPL1'},
{value: 'APPL2', id: 'APPL2'},
{value: 'APRT', id: 'APRT'},
{value: 'APTR', id: 'APTR'},
{value: 'APTX', id: 'APTX'},
{value: 'AQP1', id: 'AQP1'},
{value: 'AQP10', id: 'AQP10'},
{value: 'AQP11', id: 'AQP11'},
{value: 'AQP12A', id: 'AQP12A'},
{value: 'AQP12B', id: 'AQP12B'},
{value: 'AQP2', id: 'AQP2'},
{value: 'AQP3', id: 'AQP3'},
{value: 'AQP4', id: 'AQP4'},
{value: 'AQP4-AS1', id: 'AQP4-AS1'},
{value: 'AQP5', id: 'AQP5'},
{value: 'AQP5-AS1', id: 'AQP5-AS1'},
{value: 'AQP6', id: 'AQP6'},
{value: 'AQP7', id: 'AQP7'},
{value: 'AQP7P1', id: 'AQP7P1'},
{value: 'AQP7P2', id: 'AQP7P2'},
{value: 'AQP7P3', id: 'AQP7P3'},
{value: 'AQP7P4', id: 'AQP7P4'},
{value: 'AQP7P5', id: 'AQP7P5'},
{value: 'AQP8', id: 'AQP8'},
{value: 'AQP9', id: 'AQP9'},
{value: 'AQR', id: 'AQR'},
{value: 'AR', id: 'AR'},
{value: 'ARAF', id: 'ARAF'},
{value: 'ARAFP1', id: 'ARAFP1'},
{value: 'ARAFP2', id: 'ARAFP2'},
{value: 'ARAFP3', id: 'ARAFP3'},
{value: 'ARAP1', id: 'ARAP1'},
{value: 'ARAP1-AS1', id: 'ARAP1-AS1'},
{value: 'ARAP1-AS2', id: 'ARAP1-AS2'},
{value: 'ARAP2', id: 'ARAP2'},
{value: 'ARAP3', id: 'ARAP3'},
{value: 'ARC', id: 'ARC'},
{value: 'ARCN1', id: 'ARCN1'},
{value: 'AREG', id: 'AREG'},
{value: 'AREL1', id: 'AREL1'},
{value: 'ARF1', id: 'ARF1'},
{value: 'ARF1P1', id: 'ARF1P1'},
{value: 'ARF1P2', id: 'ARF1P2'},
{value: 'ARF1P3', id: 'ARF1P3'},
{value: 'ARF3', id: 'ARF3'},
{value: 'ARF4', id: 'ARF4'},
{value: 'ARF4-AS1', id: 'ARF4-AS1'},
{value: 'ARF4P1', id: 'ARF4P1'},
{value: 'ARF4P2', id: 'ARF4P2'},
{value: 'ARF4P3', id: 'ARF4P3'},
{value: 'ARF4P4', id: 'ARF4P4'},
{value: 'ARF5', id: 'ARF5'},
{value: 'ARF6', id: 'ARF6'},
{value: 'ARFGAP1', id: 'ARFGAP1'},
{value: 'ARFGAP2', id: 'ARFGAP2'},
{value: 'ARFGAP3', id: 'ARFGAP3'},
{value: 'ARFGEF1', id: 'ARFGEF1'},
{value: 'ARFGEF1-DT', id: 'ARFGEF1-DT'},
{value: 'ARFGEF2', id: 'ARFGEF2'},
{value: 'ARFGEF3', id: 'ARFGEF3'},
{value: 'ARFIP1', id: 'ARFIP1'},
{value: 'ARFIP2', id: 'ARFIP2'},
{value: 'ARFRP1', id: 'ARFRP1'},
{value: 'ARG1', id: 'ARG1'},
{value: 'ARG2', id: 'ARG2'},
{value: 'ARGFX', id: 'ARGFX'},
{value: 'ARGFXP1', id: 'ARGFXP1'},
{value: 'ARGFXP2', id: 'ARGFXP2'},
{value: 'ARGLU1', id: 'ARGLU1'},
{value: 'ARHGAP1', id: 'ARHGAP1'},
{value: 'ARHGAP10', id: 'ARHGAP10'},
{value: 'ARHGAP11A', id: 'ARHGAP11A'},
{value: 'ARHGAP11A-SCG5', id: 'ARHGAP11A-SCG5'},
{value: 'ARHGAP11B', id: 'ARHGAP11B'},
{value: 'ARHGAP11B-DT', id: 'ARHGAP11B-DT'},
{value: 'ARHGAP12', id: 'ARHGAP12'},
{value: 'ARHGAP15', id: 'ARHGAP15'},
{value: 'ARHGAP15-AS1', id: 'ARHGAP15-AS1'},
{value: 'ARHGAP16P', id: 'ARHGAP16P'},
{value: 'ARHGAP17', id: 'ARHGAP17'},
{value: 'ARHGAP18', id: 'ARHGAP18'},
{value: 'ARHGAP19', id: 'ARHGAP19'},
{value: 'ARHGAP19-SLIT1', id: 'ARHGAP19-SLIT1'},
{value: 'ARHGAP20', id: 'ARHGAP20'},
{value: 'ARHGAP21', id: 'ARHGAP21'},
{value: 'ARHGAP22', id: 'ARHGAP22'},
{value: 'ARHGAP22-IT1', id: 'ARHGAP22-IT1'},
{value: 'ARHGAP23', id: 'ARHGAP23'},
{value: 'ARHGAP23P1', id: 'ARHGAP23P1'},
{value: 'ARHGAP24', id: 'ARHGAP24'},
{value: 'ARHGAP25', id: 'ARHGAP25'},
{value: 'ARHGAP26', id: 'ARHGAP26'},
{value: 'ARHGAP26-IT1', id: 'ARHGAP26-IT1'},
{value: 'ARHGAP27', id: 'ARHGAP27'},
{value: 'ARHGAP27P1-BPTFP1-KPNA2P3', id: 'ARHGAP27P1-BPTFP1-KPNA2P3'},
{value: 'ARHGAP27P2', id: 'ARHGAP27P2'},
{value: 'ARHGAP28', id: 'ARHGAP28'},
{value: 'ARHGAP28-AS1', id: 'ARHGAP28-AS1'},
{value: 'ARHGAP29', id: 'ARHGAP29'},
{value: 'ARHGAP29-AS1', id: 'ARHGAP29-AS1'},
{value: 'ARHGAP30', id: 'ARHGAP30'},
{value: 'ARHGAP31', id: 'ARHGAP31'},
{value: 'ARHGAP31-AS1', id: 'ARHGAP31-AS1'},
{value: 'ARHGAP32', id: 'ARHGAP32'},
{value: 'ARHGAP33', id: 'ARHGAP33'},
{value: 'ARHGAP35', id: 'ARHGAP35'},
{value: 'ARHGAP36', id: 'ARHGAP36'},
{value: 'ARHGAP39', id: 'ARHGAP39'},
{value: 'ARHGAP4', id: 'ARHGAP4'},
{value: 'ARHGAP40', id: 'ARHGAP40'},
{value: 'ARHGAP42', id: 'ARHGAP42'},
{value: 'ARHGAP42-AS1', id: 'ARHGAP42-AS1'},
{value: 'ARHGAP42P2', id: 'ARHGAP42P2'},
{value: 'ARHGAP42P3', id: 'ARHGAP42P3'},
{value: 'ARHGAP42P4', id: 'ARHGAP42P4'},
{value: 'ARHGAP42P5', id: 'ARHGAP42P5'},
{value: 'ARHGAP44', id: 'ARHGAP44'},
{value: 'ARHGAP44-AS1', id: 'ARHGAP44-AS1'},
{value: 'ARHGAP45', id: 'ARHGAP45'},
{value: 'ARHGAP5', id: 'ARHGAP5'},
{value: 'ARHGAP5-AS1', id: 'ARHGAP5-AS1'},
{value: 'ARHGAP6', id: 'ARHGAP6'},
{value: 'ARHGAP8', id: 'ARHGAP8'},
{value: 'ARHGAP9', id: 'ARHGAP9'},
{value: 'ARHGDIA', id: 'ARHGDIA'},
{value: 'ARHGDIB', id: 'ARHGDIB'},
{value: 'ARHGDIG', id: 'ARHGDIG'},
{value: 'ARHGEF1', id: 'ARHGEF1'},
{value: 'ARHGEF10', id: 'ARHGEF10'},
{value: 'ARHGEF10L', id: 'ARHGEF10L'},
{value: 'ARHGEF11', id: 'ARHGEF11'},
{value: 'ARHGEF12', id: 'ARHGEF12'},
{value: 'ARHGEF15', id: 'ARHGEF15'},
{value: 'ARHGEF16', id: 'ARHGEF16'},
{value: 'ARHGEF17', id: 'ARHGEF17'},
{value: 'ARHGEF17-AS1', id: 'ARHGEF17-AS1'},
{value: 'ARHGEF18', id: 'ARHGEF18'},
{value: 'ARHGEF18-AS1', id: 'ARHGEF18-AS1'},
{value: 'ARHGEF19', id: 'ARHGEF19'},
{value: 'ARHGEF19-AS1', id: 'ARHGEF19-AS1'},
{value: 'ARHGEF2', id: 'ARHGEF2'},
{value: 'ARHGEF2-AS2', id: 'ARHGEF2-AS2'},
{value: 'ARHGEF25', id: 'ARHGEF25'},
{value: 'ARHGEF26', id: 'ARHGEF26'},
{value: 'ARHGEF26-AS1', id: 'ARHGEF26-AS1'},
{value: 'ARHGEF28', id: 'ARHGEF28'},
{value: 'ARHGEF3', id: 'ARHGEF3'},
{value: 'ARHGEF3-AS1', id: 'ARHGEF3-AS1'},
{value: 'ARHGEF33', id: 'ARHGEF33'},
{value: 'ARHGEF34P', id: 'ARHGEF34P'},
{value: 'ARHGEF35', id: 'ARHGEF35'},
{value: 'ARHGEF35-AS1', id: 'ARHGEF35-AS1'},
{value: 'ARHGEF37', id: 'ARHGEF37'},
{value: 'ARHGEF38', id: 'ARHGEF38'},
{value: 'ARHGEF39', id: 'ARHGEF39'},
{value: 'ARHGEF4', id: 'ARHGEF4'},
{value: 'ARHGEF40', id: 'ARHGEF40'},
{value: 'ARHGEF5', id: 'ARHGEF5'},
{value: 'ARHGEF6', id: 'ARHGEF6'},
{value: 'ARHGEF7', id: 'ARHGEF7'},
{value: 'ARHGEF7-AS2', id: 'ARHGEF7-AS2'},
{value: 'ARHGEF7-IT1', id: 'ARHGEF7-IT1'},
{value: 'ARHGEF9', id: 'ARHGEF9'},
{value: 'ARID1A', id: 'ARID1A'},
{value: 'ARID1B', id: 'ARID1B'},
{value: 'ARID2', id: 'ARID2'},
{value: 'ARID3A', id: 'ARID3A'},
{value: 'ARID3B', id: 'ARID3B'},
{value: 'ARID3BP1', id: 'ARID3BP1'},
{value: 'ARID3C', id: 'ARID3C'},
{value: 'ARID4A', id: 'ARID4A'},
{value: 'ARID4B', id: 'ARID4B'},
{value: 'ARID5A', id: 'ARID5A'},
{value: 'ARID5B', id: 'ARID5B'},
{value: 'ARIH1', id: 'ARIH1'},
{value: 'ARIH2', id: 'ARIH2'},
{value: 'ARIH2OS', id: 'ARIH2OS'},
{value: 'ARIH2P1', id: 'ARIH2P1'},
{value: 'ARL1', id: 'ARL1'},
{value: 'ARL10', id: 'ARL10'},
{value: 'ARL11', id: 'ARL11'},
{value: 'ARL13B', id: 'ARL13B'},
{value: 'ARL14', id: 'ARL14'},
{value: 'ARL14EP', id: 'ARL14EP'},
{value: 'ARL14EP-DT', id: 'ARL14EP-DT'},
{value: 'ARL14EPL', id: 'ARL14EPL'},
{value: 'ARL15', id: 'ARL15'},
{value: 'ARL16', id: 'ARL16'},
{value: 'ARL17A', id: 'ARL17A'},
{value: 'ARL17B', id: 'ARL17B'},
{value: 'ARL2', id: 'ARL2'},
{value: 'ARL2-SNX15', id: 'ARL2-SNX15'},
{value: 'ARL2BP', id: 'ARL2BP'},
{value: 'ARL2BPP1', id: 'ARL2BPP1'},
{value: 'ARL2BPP10', id: 'ARL2BPP10'},
{value: 'ARL2BPP2', id: 'ARL2BPP2'},
{value: 'ARL2BPP3', id: 'ARL2BPP3'},
{value: 'ARL2BPP4', id: 'ARL2BPP4'},
{value: 'ARL2BPP6', id: 'ARL2BPP6'},
{value: 'ARL2BPP7', id: 'ARL2BPP7'},
{value: 'ARL2BPP8', id: 'ARL2BPP8'},
{value: 'ARL3', id: 'ARL3'},
{value: 'ARL4A', id: 'ARL4A'},
{value: 'ARL4AP1', id: 'ARL4AP1'},
{value: 'ARL4AP2', id: 'ARL4AP2'},
{value: 'ARL4C', id: 'ARL4C'},
{value: 'ARL4D', id: 'ARL4D'},
{value: 'ARL5A', id: 'ARL5A'},
{value: 'ARL5AP2', id: 'ARL5AP2'},
{value: 'ARL5AP4', id: 'ARL5AP4'},
{value: 'ARL5B', id: 'ARL5B'},
{value: 'ARL5C', id: 'ARL5C'},
{value: 'ARL6', id: 'ARL6'},
{value: 'ARL6IP1', id: 'ARL6IP1'},
{value: 'ARL6IP1P1', id: 'ARL6IP1P1'},
{value: 'ARL6IP1P2', id: 'ARL6IP1P2'},
{value: 'ARL6IP1P3', id: 'ARL6IP1P3'},
{value: 'ARL6IP4', id: 'ARL6IP4'},
{value: 'ARL6IP5', id: 'ARL6IP5'},
{value: 'ARL6IP6', id: 'ARL6IP6'},
{value: 'ARL8A', id: 'ARL8A'},
{value: 'ARL8B', id: 'ARL8B'},
{value: 'ARL9', id: 'ARL9'},
{value: 'ARLNC1', id: 'ARLNC1'},
{value: 'ARMC1', id: 'ARMC1'},
{value: 'ARMC10', id: 'ARMC10'},
{value: 'ARMC10P1', id: 'ARMC10P1'},
{value: 'ARMC12', id: 'ARMC12'},
{value: 'ARMC2', id: 'ARMC2'},
{value: 'ARMC2-AS1', id: 'ARMC2-AS1'},
{value: 'ARMC3', id: 'ARMC3'},
{value: 'ARMC5', id: 'ARMC5'},
{value: 'ARMC6', id: 'ARMC6'},
{value: 'ARMC7', id: 'ARMC7'},
{value: 'ARMC8', id: 'ARMC8'},
{value: 'ARMC9', id: 'ARMC9'},
{value: 'ARMCX1', id: 'ARMCX1'},
{value: 'ARMCX2', id: 'ARMCX2'},
{value: 'ARMCX3', id: 'ARMCX3'},
{value: 'ARMCX4', id: 'ARMCX4'},
{value: 'ARMCX5', id: 'ARMCX5'},
{value: 'ARMCX5-GPRASP2', id: 'ARMCX5-GPRASP2'},
{value: 'ARMCX6', id: 'ARMCX6'},
{value: 'ARMCX7P', id: 'ARMCX7P'},
{value: 'ARMH1', id: 'ARMH1'},
{value: 'ARMH2', id: 'ARMH2'},
{value: 'ARMH3', id: 'ARMH3'},
{value: 'ARMH4', id: 'ARMH4'},
{value: 'ARMS2', id: 'ARMS2'},
{value: 'ARMT1', id: 'ARMT1'},
{value: 'ARNILA', id: 'ARNILA'},
{value: 'ARNT', id: 'ARNT'},
{value: 'ARNT2', id: 'ARNT2'},
{value: 'ARNTL', id: 'ARNTL'},
{value: 'ARNTL2', id: 'ARNTL2'},
{value: 'ARNTL2-AS1', id: 'ARNTL2-AS1'},
{value: 'ARPC1A', id: 'ARPC1A'},
{value: 'ARPC1B', id: 'ARPC1B'},
{value: 'ARPC1BP1', id: 'ARPC1BP1'},
{value: 'ARPC2', id: 'ARPC2'},
{value: 'ARPC3', id: 'ARPC3'},
{value: 'ARPC3P1', id: 'ARPC3P1'},
{value: 'ARPC3P2', id: 'ARPC3P2'},
{value: 'ARPC3P3', id: 'ARPC3P3'},
{value: 'ARPC3P4', id: 'ARPC3P4'},
{value: 'ARPC3P5', id: 'ARPC3P5'},
{value: 'ARPC4', id: 'ARPC4'},
{value: 'ARPC4-TTLL3', id: 'ARPC4-TTLL3'},
{value: 'ARPC5', id: 'ARPC5'},
{value: 'ARPC5L', id: 'ARPC5L'},
{value: 'ARPIN', id: 'ARPIN'},
{value: 'ARPIN-AP3S2', id: 'ARPIN-AP3S2'},
{value: 'ARPP19', id: 'ARPP19'},
{value: 'ARPP19P1', id: 'ARPP19P1'},
{value: 'ARPP19P2', id: 'ARPP19P2'},
{value: 'ARPP21', id: 'ARPP21'},
{value: 'ARPP21-AS1', id: 'ARPP21-AS1'},
{value: 'ARR3', id: 'ARR3'},
{value: 'ARRB1', id: 'ARRB1'},
{value: 'ARRB2', id: 'ARRB2'},
{value: 'ARRDC1', id: 'ARRDC1'},
{value: 'ARRDC1-AS1', id: 'ARRDC1-AS1'},
{value: 'ARRDC2', id: 'ARRDC2'},
{value: 'ARRDC3', id: 'ARRDC3'},
{value: 'ARRDC3-AS1', id: 'ARRDC3-AS1'},
{value: 'ARRDC4', id: 'ARRDC4'},
{value: 'ARRDC5', id: 'ARRDC5'},
{value: 'ARSA', id: 'ARSA'},
{value: 'ARSB', id: 'ARSB'},
{value: 'ARSD', id: 'ARSD'},
{value: 'ARSD-AS1', id: 'ARSD-AS1'},
{value: 'ARSG', id: 'ARSG'},
{value: 'ARSI', id: 'ARSI'},
{value: 'ARSJ', id: 'ARSJ'},
{value: 'ARSK', id: 'ARSK'},
{value: 'ART1', id: 'ART1'},
{value: 'ART2BP', id: 'ART2BP'},
{value: 'ART2P', id: 'ART2P'},
{value: 'ART3', id: 'ART3'},
{value: 'ART4', id: 'ART4'},
{value: 'ART5', id: 'ART5'},
{value: 'ARTN', id: 'ARTN'},
{value: 'ARV1', id: 'ARV1'},
{value: 'ARVCF', id: 'ARVCF'},
{value: 'ARX', id: 'ARX'},
{value: 'AS3MT', id: 'AS3MT'},
{value: 'ASAH1', id: 'ASAH1'},
{value: 'ASAH2', id: 'ASAH2'},
{value: 'ASAH2B', id: 'ASAH2B'},
{value: 'ASAP1', id: 'ASAP1'},
{value: 'ASAP1-IT2', id: 'ASAP1-IT2'},
{value: 'ASAP2', id: 'ASAP2'},
{value: 'ASAP3', id: 'ASAP3'},
{value: 'ASB1', id: 'ASB1'},
{value: 'ASB10', id: 'ASB10'},
{value: 'ASB11', id: 'ASB11'},
{value: 'ASB12', id: 'ASB12'},
{value: 'ASB13', id: 'ASB13'},
{value: 'ASB14', id: 'ASB14'},
{value: 'ASB15', id: 'ASB15'},
{value: 'ASB15-AS1', id: 'ASB15-AS1'},
{value: 'ASB16', id: 'ASB16'},
{value: 'ASB16-AS1', id: 'ASB16-AS1'},
{value: 'ASB18', id: 'ASB18'},
{value: 'ASB2', id: 'ASB2'},
{value: 'ASB3', id: 'ASB3'},
{value: 'ASB4', id: 'ASB4'},
{value: 'ASB5', id: 'ASB5'},
{value: 'ASB6', id: 'ASB6'},
{value: 'ASB7', id: 'ASB7'},
{value: 'ASB8', id: 'ASB8'},
{value: 'ASB9', id: 'ASB9'},
{value: 'ASB9P1', id: 'ASB9P1'},
{value: 'ASCC1', id: 'ASCC1'},
{value: 'ASCC2', id: 'ASCC2'},
{value: 'ASCC3', id: 'ASCC3'},
{value: 'ASCL1', id: 'ASCL1'},
{value: 'ASCL2', id: 'ASCL2'},
{value: 'ASCL3', id: 'ASCL3'},
{value: 'ASCL4', id: 'ASCL4'},
{value: 'ASCL5', id: 'ASCL5'},
{value: 'ASDURF', id: 'ASDURF'},
{value: 'ASF1A', id: 'ASF1A'},
{value: 'ASF1B', id: 'ASF1B'},
{value: 'ASGR1', id: 'ASGR1'},
{value: 'ASGR2', id: 'ASGR2'},
{value: 'ASH1L', id: 'ASH1L'},
{value: 'ASH1L-AS1', id: 'ASH1L-AS1'},
{value: 'ASH1L-IT1', id: 'ASH1L-IT1'},
{value: 'ASH2L', id: 'ASH2L'},
{value: 'ASH2LP1', id: 'ASH2LP1'},
{value: 'ASH2LP2', id: 'ASH2LP2'},
{value: 'ASH2LP3', id: 'ASH2LP3'},
{value: 'ASIC1', id: 'ASIC1'},
{value: 'ASIC2', id: 'ASIC2'},
{value: 'ASIC3', id: 'ASIC3'},
{value: 'ASIC4', id: 'ASIC4'},
{value: 'ASIC4-AS1', id: 'ASIC4-AS1'},
{value: 'ASIC5', id: 'ASIC5'},
{value: 'ASIP', id: 'ASIP'},
{value: 'ASL', id: 'ASL'},
{value: 'ASLP1', id: 'ASLP1'},
{value: 'ASMTL', id: 'ASMTL'},
{value: 'ASMTL-AS1', id: 'ASMTL-AS1'},
{value: 'ASNS', id: 'ASNS'},
{value: 'ASNSD1', id: 'ASNSD1'},
{value: 'ASNSP1', id: 'ASNSP1'},
{value: 'ASNSP3', id: 'ASNSP3'},
{value: 'ASNSP4', id: 'ASNSP4'},
{value: 'ASPA', id: 'ASPA'},
{value: 'ASPDH', id: 'ASPDH'},
{value: 'ASPG', id: 'ASPG'},
{value: 'ASPH', id: 'ASPH'},
{value: 'ASPHD1', id: 'ASPHD1'},
{value: 'ASPHD2', id: 'ASPHD2'},
{value: 'ASPM', id: 'ASPM'},
{value: 'ASPN', id: 'ASPN'},
{value: 'ASPRV1', id: 'ASPRV1'},
{value: 'ASPSCR1', id: 'ASPSCR1'},
{value: 'ASRGL1', id: 'ASRGL1'},
{value: 'ASS1', id: 'ASS1'},
{value: 'ASS1P1', id: 'ASS1P1'},
{value: 'ASS1P10', id: 'ASS1P10'},
{value: 'ASS1P11', id: 'ASS1P11'},
{value: 'ASS1P13', id: 'ASS1P13'},
{value: 'ASS1P14', id: 'ASS1P14'},
{value: 'ASS1P2', id: 'ASS1P2'},
{value: 'ASS1P3', id: 'ASS1P3'},
{value: 'ASS1P7', id: 'ASS1P7'},
{value: 'ASS1P9', id: 'ASS1P9'},
{value: 'ASTE1', id: 'ASTE1'},
{value: 'ASTL', id: 'ASTL'},
{value: 'ASTN1', id: 'ASTN1'},
{value: 'ASTN2', id: 'ASTN2'},
{value: 'ASXL1', id: 'ASXL1'},
{value: 'ASXL2', id: 'ASXL2'},
{value: 'ASXL3', id: 'ASXL3'},
{value: 'ASXL3-DT', id: 'ASXL3-DT'},
{value: 'ATAD1', id: 'ATAD1'},
{value: 'ATAD2', id: 'ATAD2'},
{value: 'ATAD2B', id: 'ATAD2B'},
{value: 'ATAD3A', id: 'ATAD3A'},
{value: 'ATAD3B', id: 'ATAD3B'},
{value: 'ATAD3C', id: 'ATAD3C'},
{value: 'ATAD5', id: 'ATAD5'},
{value: 'ATAT1', id: 'ATAT1'},
{value: 'ATCAY', id: 'ATCAY'},
{value: 'ATE1', id: 'ATE1'},
{value: 'ATE1-AS1', id: 'ATE1-AS1'},
{value: 'ATF1', id: 'ATF1'},
{value: 'ATF1P1', id: 'ATF1P1'},
{value: 'ATF2', id: 'ATF2'},
{value: 'ATF3', id: 'ATF3'},
{value: 'ATF4', id: 'ATF4'},
{value: 'ATF4P3', id: 'ATF4P3'},
{value: 'ATF4P4', id: 'ATF4P4'},
{value: 'ATF5', id: 'ATF5'},
{value: 'ATF6', id: 'ATF6'},
{value: 'ATF6B', id: 'ATF6B'},
{value: 'ATF7', id: 'ATF7'},
{value: 'ATF7-NPFF', id: 'ATF7-NPFF'},
{value: 'ATF7IP', id: 'ATF7IP'},
{value: 'ATF7IP2', id: 'ATF7IP2'},
{value: 'ATG10', id: 'ATG10'},
{value: 'ATG101', id: 'ATG101'},
{value: 'ATG12', id: 'ATG12'},
{value: 'ATG12P2', id: 'ATG12P2'},
{value: 'ATG13', id: 'ATG13'},
{value: 'ATG14', id: 'ATG14'},
{value: 'ATG16L1', id: 'ATG16L1'},
{value: 'ATG16L2', id: 'ATG16L2'},
{value: 'ATG2A', id: 'ATG2A'},
{value: 'ATG2B', id: 'ATG2B'},
{value: 'ATG3', id: 'ATG3'},
{value: 'ATG3P1', id: 'ATG3P1'},
{value: 'ATG4A', id: 'ATG4A'},
{value: 'ATG4B', id: 'ATG4B'},
{value: 'ATG4C', id: 'ATG4C'},
{value: 'ATG4D', id: 'ATG4D'},
{value: 'ATG5', id: 'ATG5'},
{value: 'ATG7', id: 'ATG7'},
{value: 'ATG9A', id: 'ATG9A'},
{value: 'ATG9B', id: 'ATG9B'},
{value: 'ATIC', id: 'ATIC'},
{value: 'ATL1', id: 'ATL1'},
{value: 'ATL2', id: 'ATL2'},
{value: 'ATL3', id: 'ATL3'},
{value: 'ATM', id: 'ATM'},
{value: 'ATMIN', id: 'ATMIN'},
{value: 'ATN1', id: 'ATN1'},
{value: 'ATOH1', id: 'ATOH1'},
{value: 'ATOH7', id: 'ATOH7'},
{value: 'ATOH8', id: 'ATOH8'},
{value: 'ATOX1', id: 'ATOX1'},
{value: 'ATOX1-AS1', id: 'ATOX1-AS1'},
{value: 'ATP10A', id: 'ATP10A'},
{value: 'ATP10A-DT', id: 'ATP10A-DT'},
{value: 'ATP10B', id: 'ATP10B'},
{value: 'ATP10D', id: 'ATP10D'},
{value: 'ATP11A', id: 'ATP11A'},
{value: 'ATP11A-AS1', id: 'ATP11A-AS1'},
{value: 'ATP11AUN', id: 'ATP11AUN'},
{value: 'ATP11B', id: 'ATP11B'},
{value: 'ATP11C', id: 'ATP11C'},
{value: 'ATP12A', id: 'ATP12A'},
{value: 'ATP13A1', id: 'ATP13A1'},
{value: 'ATP13A2', id: 'ATP13A2'},
{value: 'ATP13A3', id: 'ATP13A3'},
{value: 'ATP13A4', id: 'ATP13A4'},
{value: 'ATP13A4-AS1', id: 'ATP13A4-AS1'},
{value: 'ATP13A5', id: 'ATP13A5'},
{value: 'ATP13A5-AS1', id: 'ATP13A5-AS1'},
{value: 'ATP1A1', id: 'ATP1A1'},
{value: 'ATP1A1-AS1', id: 'ATP1A1-AS1'},
{value: 'ATP1A2', id: 'ATP1A2'},
{value: 'ATP1A3', id: 'ATP1A3'},
{value: 'ATP1A4', id: 'ATP1A4'},
{value: 'ATP1B1', id: 'ATP1B1'},
{value: 'ATP1B2', id: 'ATP1B2'},
{value: 'ATP1B3', id: 'ATP1B3'},
{value: 'ATP1B3-AS1', id: 'ATP1B3-AS1'},
{value: 'ATP1B3P1', id: 'ATP1B3P1'},
{value: 'ATP1B4', id: 'ATP1B4'},
{value: 'ATP23', id: 'ATP23'},
{value: 'ATP2A1', id: 'ATP2A1'},
{value: 'ATP2A1-AS1', id: 'ATP2A1-AS1'},
{value: 'ATP2A2', id: 'ATP2A2'},
{value: 'ATP2A3', id: 'ATP2A3'},
{value: 'ATP2B1', id: 'ATP2B1'},
{value: 'ATP2B1-AS1', id: 'ATP2B1-AS1'},
{value: 'ATP2B2', id: 'ATP2B2'},
{value: 'ATP2B2-IT1', id: 'ATP2B2-IT1'},
{value: 'ATP2B2-IT2', id: 'ATP2B2-IT2'},
{value: 'ATP2B3', id: 'ATP2B3'},
{value: 'ATP2B4', id: 'ATP2B4'},
{value: 'ATP2C1', id: 'ATP2C1'},
{value: 'ATP2C2', id: 'ATP2C2'},
{value: 'ATP2C2-AS1', id: 'ATP2C2-AS1'},
{value: 'ATP4A', id: 'ATP4A'},
{value: 'ATP4B', id: 'ATP4B'},
{value: 'ATP5F1A', id: 'ATP5F1A'},
{value: 'ATP5F1AP1', id: 'ATP5F1AP1'},
{value: 'ATP5F1AP10', id: 'ATP5F1AP10'},
{value: 'ATP5F1AP2', id: 'ATP5F1AP2'},
{value: 'ATP5F1AP3', id: 'ATP5F1AP3'},
{value: 'ATP5F1AP4', id: 'ATP5F1AP4'},
{value: 'ATP5F1AP7', id: 'ATP5F1AP7'},
{value: 'ATP5F1AP8', id: 'ATP5F1AP8'},
{value: 'ATP5F1B', id: 'ATP5F1B'},
{value: 'ATP5F1BP1', id: 'ATP5F1BP1'},
{value: 'ATP5F1C', id: 'ATP5F1C'},
{value: 'ATP5F1CP1', id: 'ATP5F1CP1'},
{value: 'ATP5F1D', id: 'ATP5F1D'},
{value: 'ATP5F1E', id: 'ATP5F1E'},
{value: 'ATP5F1EP1', id: 'ATP5F1EP1'},
{value: 'ATP5F1EP2', id: 'ATP5F1EP2'},
{value: 'ATP5IF1', id: 'ATP5IF1'},
{value: 'ATP5MC1', id: 'ATP5MC1'},
{value: 'ATP5MC1P1', id: 'ATP5MC1P1'},
{value: 'ATP5MC1P2', id: 'ATP5MC1P2'},
{value: 'ATP5MC1P3', id: 'ATP5MC1P3'},
{value: 'ATP5MC1P4', id: 'ATP5MC1P4'},
{value: 'ATP5MC1P6', id: 'ATP5MC1P6'},
{value: 'ATP5MC1P7', id: 'ATP5MC1P7'},
{value: 'ATP5MC1P8', id: 'ATP5MC1P8'},
{value: 'ATP5MC2', id: 'ATP5MC2'},
{value: 'ATP5MC2P1', id: 'ATP5MC2P1'},
{value: 'ATP5MC2P2', id: 'ATP5MC2P2'},
{value: 'ATP5MC2P3', id: 'ATP5MC2P3'},
{value: 'ATP5MC3', id: 'ATP5MC3'},
{value: 'ATP5ME', id: 'ATP5ME'},
{value: 'ATP5MF', id: 'ATP5MF'},
{value: 'ATP5MF-PTCD1', id: 'ATP5MF-PTCD1'},
{value: 'ATP5MFP1', id: 'ATP5MFP1'},
{value: 'ATP5MFP3', id: 'ATP5MFP3'},
{value: 'ATP5MFP4', id: 'ATP5MFP4'},
{value: 'ATP5MFP5', id: 'ATP5MFP5'},
{value: 'ATP5MG', id: 'ATP5MG'},
{value: 'ATP5MGP1', id: 'ATP5MGP1'},
{value: 'ATP5MGP2', id: 'ATP5MGP2'},
{value: 'ATP5MGP3', id: 'ATP5MGP3'},
{value: 'ATP5MGP5', id: 'ATP5MGP5'},
{value: 'ATP5MGP7', id: 'ATP5MGP7'},
{value: 'ATP5MJ', id: 'ATP5MJ'},
{value: 'ATP5MK', id: 'ATP5MK'},
{value: 'ATP5PB', id: 'ATP5PB'},
{value: 'ATP5PBP2', id: 'ATP5PBP2'},
{value: 'ATP5PBP3', id: 'ATP5PBP3'},
{value: 'ATP5PBP4', id: 'ATP5PBP4'},
{value: 'ATP5PBP5', id: 'ATP5PBP5'},
{value: 'ATP5PBP7', id: 'ATP5PBP7'},
{value: 'ATP5PD', id: 'ATP5PD'},
{value: 'ATP5PDP1', id: 'ATP5PDP1'},
{value: 'ATP5PDP2', id: 'ATP5PDP2'},
{value: 'ATP5PDP3', id: 'ATP5PDP3'},
{value: 'ATP5PDP4', id: 'ATP5PDP4'},
{value: 'ATP5PF', id: 'ATP5PF'},
{value: 'ATP5PO', id: 'ATP5PO'},
{value: 'ATP5POP1', id: 'ATP5POP1'},
{value: 'ATP6AP1', id: 'ATP6AP1'},
{value: 'ATP6AP1L', id: 'ATP6AP1L'},
{value: 'ATP6AP2', id: 'ATP6AP2'},
{value: 'ATP6V0A1', id: 'ATP6V0A1'},
{value: 'ATP6V0A2', id: 'ATP6V0A2'},
{value: 'ATP6V0A4', id: 'ATP6V0A4'},
{value: 'ATP6V0B', id: 'ATP6V0B'},
{value: 'ATP6V0C', id: 'ATP6V0C'},
{value: 'ATP6V0CP1', id: 'ATP6V0CP1'},
{value: 'ATP6V0CP2', id: 'ATP6V0CP2'},
{value: 'ATP6V0CP3', id: 'ATP6V0CP3'},
{value: 'ATP6V0CP4', id: 'ATP6V0CP4'},
{value: 'ATP6V0D1', id: 'ATP6V0D1'},
{value: 'ATP6V0D1-DT', id: 'ATP6V0D1-DT'},
{value: 'ATP6V0D2', id: 'ATP6V0D2'},
{value: 'ATP6V0E1', id: 'ATP6V0E1'},
{value: 'ATP6V0E1P1', id: 'ATP6V0E1P1'},
{value: 'ATP6V0E1P2', id: 'ATP6V0E1P2'},
{value: 'ATP6V0E1P4', id: 'ATP6V0E1P4'},
{value: 'ATP6V0E2', id: 'ATP6V0E2'},
{value: 'ATP6V0E2-AS1', id: 'ATP6V0E2-AS1'},
{value: 'ATP6V1A', id: 'ATP6V1A'},
{value: 'ATP6V1B1', id: 'ATP6V1B1'},
{value: 'ATP6V1B1-AS1', id: 'ATP6V1B1-AS1'},
{value: 'ATP6V1B2', id: 'ATP6V1B2'},
{value: 'ATP6V1C1', id: 'ATP6V1C1'},
{value: 'ATP6V1C2', id: 'ATP6V1C2'},
{value: 'ATP6V1D', id: 'ATP6V1D'},
{value: 'ATP6V1E1', id: 'ATP6V1E1'},
{value: 'ATP6V1E1P1', id: 'ATP6V1E1P1'},
{value: 'ATP6V1E2', id: 'ATP6V1E2'},
{value: 'ATP6V1F', id: 'ATP6V1F'},
{value: 'ATP6V1FNB', id: 'ATP6V1FNB'},
{value: 'ATP6V1G1', id: 'ATP6V1G1'},
{value: 'ATP6V1G1P1', id: 'ATP6V1G1P1'},
{value: 'ATP6V1G1P2', id: 'ATP6V1G1P2'},
{value: 'ATP6V1G1P3', id: 'ATP6V1G1P3'},
{value: 'ATP6V1G1P5', id: 'ATP6V1G1P5'},
{value: 'ATP6V1G1P6', id: 'ATP6V1G1P6'},
{value: 'ATP6V1G1P7', id: 'ATP6V1G1P7'},
{value: 'ATP6V1G2', id: 'ATP6V1G2'},
{value: 'ATP6V1G2-DDX39B', id: 'ATP6V1G2-DDX39B'},
{value: 'ATP6V1G3', id: 'ATP6V1G3'},
{value: 'ATP6V1H', id: 'ATP6V1H'},
{value: 'ATP7A', id: 'ATP7A'},
{value: 'ATP7B', id: 'ATP7B'},
{value: 'ATP7BP1', id: 'ATP7BP1'},
{value: 'ATP8A1', id: 'ATP8A1'},
{value: 'ATP8A1-DT', id: 'ATP8A1-DT'},
{value: 'ATP8A2', id: 'ATP8A2'},
{value: 'ATP8A2P2', id: 'ATP8A2P2'},
{value: 'ATP8A2P3', id: 'ATP8A2P3'},
{value: 'ATP8B1', id: 'ATP8B1'},
{value: 'ATP8B2', id: 'ATP8B2'},
{value: 'ATP8B3', id: 'ATP8B3'},
{value: 'ATP8B4', id: 'ATP8B4'},
{value: 'ATP8B5P', id: 'ATP8B5P'},
{value: 'ATP9A', id: 'ATP9A'},
{value: 'ATP9B', id: 'ATP9B'},
{value: 'ATPAF1', id: 'ATPAF1'},
{value: 'ATPAF2', id: 'ATPAF2'},
{value: 'ATPSCKMT', id: 'ATPSCKMT'},
{value: 'ATR', id: 'ATR'},
{value: 'ATRAID', id: 'ATRAID'},
{value: 'ATRIP', id: 'ATRIP'},
{value: 'ATRN', id: 'ATRN'},
{value: 'ATRNL1', id: 'ATRNL1'},
{value: 'ATRX', id: 'ATRX'},
{value: 'ATXN1', id: 'ATXN1'},
{value: 'ATXN1-AS1', id: 'ATXN1-AS1'},
{value: 'ATXN10', id: 'ATXN10'},
{value: 'ATXN1L', id: 'ATXN1L'},
{value: 'ATXN2', id: 'ATXN2'},
{value: 'ATXN2-AS', id: 'ATXN2-AS'},
{value: 'ATXN2L', id: 'ATXN2L'},
{value: 'ATXN3', id: 'ATXN3'},
{value: 'ATXN7', id: 'ATXN7'},
{value: 'ATXN7L1', id: 'ATXN7L1'},
{value: 'ATXN7L2', id: 'ATXN7L2'},
{value: 'ATXN7L3', id: 'ATXN7L3'},
{value: 'ATXN7L3-AS1', id: 'ATXN7L3-AS1'},
{value: 'ATXN7L3B', id: 'ATXN7L3B'},
{value: 'ATXN8OS', id: 'ATXN8OS'},
{value: 'AUH', id: 'AUH'},
{value: 'AUNIP', id: 'AUNIP'},
{value: 'AUP1', id: 'AUP1'},
{value: 'AURKA', id: 'AURKA'},
{value: 'AURKAIP1', id: 'AURKAIP1'},
{value: 'AURKAP1', id: 'AURKAP1'},
{value: 'AURKB', id: 'AURKB'},
{value: 'AURKBP1', id: 'AURKBP1'},
{value: 'AURKC', id: 'AURKC'},
{value: 'AUTS2', id: 'AUTS2'},
{value: 'AVEN', id: 'AVEN'},
{value: 'AVIL', id: 'AVIL'},
{value: 'AVL9', id: 'AVL9'},
{value: 'AVP', id: 'AVP'},
{value: 'AVPI1', id: 'AVPI1'},
{value: 'AVPR1A', id: 'AVPR1A'},
{value: 'AVPR1B', id: 'AVPR1B'},
{value: 'AVPR2', id: 'AVPR2'},
{value: 'AXDND1', id: 'AXDND1'},
{value: 'AXIN1', id: 'AXIN1'},
{value: 'AXIN2', id: 'AXIN2'},
{value: 'AXL', id: 'AXL'},
{value: 'AZGP1', id: 'AZGP1'},
{value: 'AZGP1P1', id: 'AZGP1P1'},
{value: 'AZGP1P2', id: 'AZGP1P2'},
{value: 'AZI2', id: 'AZI2'},
{value: 'AZIN1', id: 'AZIN1'},
{value: 'AZIN2', id: 'AZIN2'},
{value: 'AZU1', id: 'AZU1'},
{value: 'AZU1P1', id: 'AZU1P1'},
{value: 'B2M', id: 'B2M'},
{value: 'B3GALNT1', id: 'B3GALNT1'},
{value: 'B3GALNT1P1', id: 'B3GALNT1P1'},
{value: 'B3GALNT2', id: 'B3GALNT2'},
{value: 'B3GALNT2P1', id: 'B3GALNT2P1'},
{value: 'B3GALT1', id: 'B3GALT1'},
{value: 'B3GALT1-AS1', id: 'B3GALT1-AS1'},
{value: 'B3GALT2', id: 'B3GALT2'},
{value: 'B3GALT4', id: 'B3GALT4'},
{value: 'B3GALT5', id: 'B3GALT5'},
{value: 'B3GALT5-AS1', id: 'B3GALT5-AS1'},
{value: 'B3GALT6', id: 'B3GALT6'},
{value: 'B3GALT9', id: 'B3GALT9'},
{value: 'B3GAT1', id: 'B3GAT1'},
{value: 'B3GAT1-DT', id: 'B3GAT1-DT'},
{value: 'B3GAT2', id: 'B3GAT2'},
{value: 'B3GAT3', id: 'B3GAT3'},
{value: 'B3GAT3P1', id: 'B3GAT3P1'},
{value: 'B3GLCT', id: 'B3GLCT'},
{value: 'B3GNT2', id: 'B3GNT2'},
{value: 'B3GNT3', id: 'B3GNT3'},
{value: 'B3GNT4', id: 'B3GNT4'},
{value: 'B3GNT5', id: 'B3GNT5'},
{value: 'B3GNT6', id: 'B3GNT6'},
{value: 'B3GNT7', id: 'B3GNT7'},
{value: 'B3GNT8', id: 'B3GNT8'},
{value: 'B3GNT9', id: 'B3GNT9'},
{value: 'B3GNTL1', id: 'B3GNTL1'},
{value: 'B3GNTL1P2', id: 'B3GNTL1P2'},
{value: 'B4GALNT1', id: 'B4GALNT1'},
{value: 'B4GALNT2', id: 'B4GALNT2'},
{value: 'B4GALNT2P1', id: 'B4GALNT2P1'},
{value: 'B4GALNT3', id: 'B4GALNT3'},
{value: 'B4GALNT4', id: 'B4GALNT4'},
{value: 'B4GALT1', id: 'B4GALT1'},
{value: 'B4GALT1-AS1', id: 'B4GALT1-AS1'},
{value: 'B4GALT2', id: 'B4GALT2'},
{value: 'B4GALT3', id: 'B4GALT3'},
{value: 'B4GALT4', id: 'B4GALT4'},
{value: 'B4GALT4-AS1', id: 'B4GALT4-AS1'},
{value: 'B4GALT5', id: 'B4GALT5'},
{value: 'B4GALT6', id: 'B4GALT6'},
{value: 'B4GALT7', id: 'B4GALT7'},
{value: 'B4GAT1', id: 'B4GAT1'},
{value: 'B9D1', id: 'B9D1'},
{value: 'B9D2', id: 'B9D2'},
{value: 'BAALC', id: 'BAALC'},
{value: 'BAALC-AS1', id: 'BAALC-AS1'},
{value: 'BAALC-AS2', id: 'BAALC-AS2'},
{value: 'BAAT', id: 'BAAT'},
{value: 'BABAM1', id: 'BABAM1'},
{value: 'BABAM2', id: 'BABAM2'},
{value: 'BACE1', id: 'BACE1'},
{value: 'BACE1-AS', id: 'BACE1-AS'},
{value: 'BACE2', id: 'BACE2'},
{value: 'BACE2-IT1', id: 'BACE2-IT1'},
{value: 'BACH1', id: 'BACH1'},
{value: 'BACH1-AS1', id: 'BACH1-AS1'},
{value: 'BACH1-IT1', id: 'BACH1-IT1'},
{value: 'BACH1-IT2', id: 'BACH1-IT2'},
{value: 'BACH1-IT3', id: 'BACH1-IT3'},
{value: 'BACH2', id: 'BACH2'},
{value: 'BAD', id: 'BAD'},
{value: 'BAG1', id: 'BAG1'},
{value: 'BAG2', id: 'BAG2'},
{value: 'BAG3', id: 'BAG3'},
{value: 'BAG4', id: 'BAG4'},
{value: 'BAG5', id: 'BAG5'},
{value: 'BAG6', id: 'BAG6'},
{value: 'BAGE2', id: 'BAGE2'},
{value: 'BAHCC1', id: 'BAHCC1'},
{value: 'BAHD1', id: 'BAHD1'},
{value: 'BAIAP2', id: 'BAIAP2'},
{value: 'BAIAP2-DT', id: 'BAIAP2-DT'},
{value: 'BAIAP2L1', id: 'BAIAP2L1'},
{value: 'BAIAP2L2', id: 'BAIAP2L2'},
{value: 'BAIAP3', id: 'BAIAP3'},
{value: 'BAK1', id: 'BAK1'},
{value: 'BAK1P1', id: 'BAK1P1'},
{value: 'BAK1P2', id: 'BAK1P2'},
{value: 'BAMBI', id: 'BAMBI'},
{value: 'BANCR', id: 'BANCR'},
{value: 'BANF1', id: 'BANF1'},
{value: 'BANF1P3', id: 'BANF1P3'},
{value: 'BANF1P5', id: 'BANF1P5'},
{value: 'BANF2', id: 'BANF2'},
{value: 'BANK1', id: 'BANK1'},
{value: 'BANP', id: 'BANP'},
{value: 'BAP1', id: 'BAP1'},
{value: 'BARD1', id: 'BARD1'},
{value: 'BARHL1', id: 'BARHL1'},
{value: 'BARHL2', id: 'BARHL2'},
{value: 'BARX1', id: 'BARX1'},
{value: 'BARX1-DT', id: 'BARX1-DT'},
{value: 'BARX2', id: 'BARX2'},
{value: 'BASP1', id: 'BASP1'},
{value: 'BASP1-AS1', id: 'BASP1-AS1'},
{value: 'BASP1P1', id: 'BASP1P1'},
{value: 'BATF', id: 'BATF'},
{value: 'BATF2', id: 'BATF2'},
{value: 'BATF3', id: 'BATF3'},
{value: 'BAX', id: 'BAX'},
{value: 'BAZ1A', id: 'BAZ1A'},
{value: 'BAZ1A-AS1', id: 'BAZ1A-AS1'},
{value: 'BAZ1B', id: 'BAZ1B'},
{value: 'BAZ2A', id: 'BAZ2A'},
{value: 'BAZ2B', id: 'BAZ2B'},
{value: 'BBC3', id: 'BBC3'},
{value: 'BBIP1', id: 'BBIP1'},
{value: 'BBLN', id: 'BBLN'},
{value: 'BBOF1', id: 'BBOF1'},
{value: 'BBOX1', id: 'BBOX1'},
{value: 'BBOX1-AS1', id: 'BBOX1-AS1'},
{value: 'BBS1', id: 'BBS1'},
{value: 'BBS10', id: 'BBS10'},
{value: 'BBS12', id: 'BBS12'},
{value: 'BBS2', id: 'BBS2'},
{value: 'BBS4', id: 'BBS4'},
{value: 'BBS5', id: 'BBS5'},
{value: 'BBS7', id: 'BBS7'},
{value: 'BBS7-DT', id: 'BBS7-DT'},
{value: 'BBS9', id: 'BBS9'},
{value: 'BBX', id: 'BBX'},
{value: 'BCAM', id: 'BCAM'},
{value: 'BCAN', id: 'BCAN'},
{value: 'BCAN-AS1', id: 'BCAN-AS1'},
{value: 'BCAP29', id: 'BCAP29'},
{value: 'BCAP31', id: 'BCAP31'},
{value: 'BCAP31P1', id: 'BCAP31P1'},
{value: 'BCAP31P2', id: 'BCAP31P2'},
{value: 'BCAR1', id: 'BCAR1'},
{value: 'BCAR1P1', id: 'BCAR1P1'},
{value: 'BCAR1P2', id: 'BCAR1P2'},
{value: 'BCAR3', id: 'BCAR3'},
{value: 'BCAR3-AS1', id: 'BCAR3-AS1'},
{value: 'BCAR4', id: 'BCAR4'},
{value: 'BCAS1', id: 'BCAS1'},
{value: 'BCAS2', id: 'BCAS2'},
{value: 'BCAS2P1', id: 'BCAS2P1'},
{value: 'BCAS2P2', id: 'BCAS2P2'},
{value: 'BCAS2P3', id: 'BCAS2P3'},
{value: 'BCAS3', id: 'BCAS3'},
{value: 'BCAS4', id: 'BCAS4'},
{value: 'BCAT1', id: 'BCAT1'},
{value: 'BCAT2', id: 'BCAT2'},
{value: 'BCCIP', id: 'BCCIP'},
{value: 'BCDIN3D', id: 'BCDIN3D'},
{value: 'BCDIN3D-AS1', id: 'BCDIN3D-AS1'},
{value: 'BCHE', id: 'BCHE'},
{value: 'BCKDHA', id: 'BCKDHA'},
{value: 'BCKDHB', id: 'BCKDHB'},
{value: 'BCKDK', id: 'BCKDK'},
{value: 'BCL10', id: 'BCL10'},
{value: 'BCL11A', id: 'BCL11A'},
{value: 'BCL11B', id: 'BCL11B'},
{value: 'BCL2', id: 'BCL2'},
{value: 'BCL2A1', id: 'BCL2A1'},
{value: 'BCL2L1', id: 'BCL2L1'},
{value: 'BCL2L10', id: 'BCL2L10'},
{value: 'BCL2L11', id: 'BCL2L11'},
{value: 'BCL2L12', id: 'BCL2L12'},
{value: 'BCL2L12P1', id: 'BCL2L12P1'},
{value: 'BCL2L13', id: 'BCL2L13'},
{value: 'BCL2L14', id: 'BCL2L14'},
{value: 'BCL2L15', id: 'BCL2L15'},
{value: 'BCL2L2', id: 'BCL2L2'},
{value: 'BCL2L2-PABPN1', id: 'BCL2L2-PABPN1'},
{value: 'BCL3', id: 'BCL3'},
{value: 'BCL6', id: 'BCL6'},
{value: 'BCL6B', id: 'BCL6B'},
{value: 'BCL7A', id: 'BCL7A'},
{value: 'BCL7B', id: 'BCL7B'},
{value: 'BCL7C', id: 'BCL7C'},
{value: 'BCL9', id: 'BCL9'},
{value: 'BCL9L', id: 'BCL9L'},
{value: 'BCL9P1', id: 'BCL9P1'},
{value: 'BCLAF1', id: 'BCLAF1'},
{value: 'BCLAF1P1', id: 'BCLAF1P1'},
{value: 'BCLAF1P2', id: 'BCLAF1P2'},
{value: 'BCLAF3', id: 'BCLAF3'},
{value: 'BCO1', id: 'BCO1'},
{value: 'BCO2', id: 'BCO2'},
{value: 'BCOR', id: 'BCOR'},
{value: 'BCORL1', id: 'BCORL1'},
{value: 'BCORP1', id: 'BCORP1'},
{value: 'BCR', id: 'BCR'},
{value: 'BCRP2', id: 'BCRP2'},
{value: 'BCRP3', id: 'BCRP3'},
{value: 'BCRP4', id: 'BCRP4'},
{value: 'BCRP6', id: 'BCRP6'},
{value: 'BCRP7', id: 'BCRP7'},
{value: 'BCRP8', id: 'BCRP8'},
{value: 'BCRP9', id: 'BCRP9'},
{value: 'BCS1L', id: 'BCS1L'},
{value: 'BCYRN1', id: 'BCYRN1'},
{value: 'BDH1', id: 'BDH1'},
{value: 'BDH2', id: 'BDH2'},
{value: 'BDH2P1', id: 'BDH2P1'},
{value: 'BDKRB1', id: 'BDKRB1'},
{value: 'BDKRB2', id: 'BDKRB2'},
{value: 'BDNF', id: 'BDNF'},
{value: 'BDNF-AS', id: 'BDNF-AS'},
{value: 'BDP1', id: 'BDP1'},
{value: 'BEAN1', id: 'BEAN1'},
{value: 'BEAN1-AS1', id: 'BEAN1-AS1'},
{value: 'BECN1', id: 'BECN1'},
{value: 'BECN1P2', id: 'BECN1P2'},
{value: 'BECN2', id: 'BECN2'},
{value: 'BEGAIN', id: 'BEGAIN'},
{value: 'BEND2', id: 'BEND2'},
{value: 'BEND3', id: 'BEND3'},
{value: 'BEND3P1', id: 'BEND3P1'},
{value: 'BEND3P2', id: 'BEND3P2'},
{value: 'BEND3P3', id: 'BEND3P3'},
{value: 'BEND4', id: 'BEND4'},
{value: 'BEND5', id: 'BEND5'},
{value: 'BEND6', id: 'BEND6'},
{value: 'BEND7', id: 'BEND7'},
{value: 'BEST1', id: 'BEST1'},
{value: 'BEST2', id: 'BEST2'},
{value: 'BEST3', id: 'BEST3'},
{value: 'BEST4', id: 'BEST4'},
{value: 'BET1', id: 'BET1'},
{value: 'BET1-AS1', id: 'BET1-AS1'},
{value: 'BET1L', id: 'BET1L'},
{value: 'BET1P1', id: 'BET1P1'},
{value: 'BEX1', id: 'BEX1'},
{value: 'BEX2', id: 'BEX2'},
{value: 'BEX3', id: 'BEX3'},
{value: 'BEX4', id: 'BEX4'},
{value: 'BEX5', id: 'BEX5'},
{value: 'BFAR', id: 'BFAR'},
{value: 'BFSP1', id: 'BFSP1'},
{value: 'BFSP2', id: 'BFSP2'},
{value: 'BFSP2-AS1', id: 'BFSP2-AS1'},
{value: 'BGLAP', id: 'BGLAP'},
{value: 'BGN', id: 'BGN'},
{value: 'BHLHA15', id: 'BHLHA15'},
{value: 'BHLHA9', id: 'BHLHA9'},
{value: 'BHLHB9', id: 'BHLHB9'},
{value: 'BHLHB9P1', id: 'BHLHB9P1'},
{value: 'BHLHE22', id: 'BHLHE22'},
{value: 'BHLHE23', id: 'BHLHE23'},
{value: 'BHLHE40', id: 'BHLHE40'},
{value: 'BHLHE40-AS1', id: 'BHLHE40-AS1'},
{value: 'BHLHE41', id: 'BHLHE41'},
{value: 'BHMT', id: 'BHMT'},
{value: 'BHMT2', id: 'BHMT2'},
{value: 'BICC1', id: 'BICC1'},
{value: 'BICD1', id: 'BICD1'},
{value: 'BICD1-AS1', id: 'BICD1-AS1'},
{value: 'BICD1P1', id: 'BICD1P1'},
{value: 'BICD2', id: 'BICD2'},
{value: 'BICDL1', id: 'BICDL1'},
{value: 'BICDL2', id: 'BICDL2'},
{value: 'BICRA', id: 'BICRA'},
{value: 'BICRA-AS1', id: 'BICRA-AS1'},
{value: 'BICRAL', id: 'BICRAL'},
{value: 'BID', id: 'BID'},
{value: 'BIK', id: 'BIK'},
{value: 'BIN1', id: 'BIN1'},
{value: 'BIN2', id: 'BIN2'},
{value: 'BIN2P1', id: 'BIN2P1'},
{value: 'BIN2P2', id: 'BIN2P2'},
{value: 'BIN3', id: 'BIN3'},
{value: 'BIRC2', id: 'BIRC2'},
{value: 'BIRC3', id: 'BIRC3'},
{value: 'BIRC5', id: 'BIRC5'},
{value: 'BIRC6', id: 'BIRC6'},
{value: 'BIRC7', id: 'BIRC7'},
{value: 'BIRC8', id: 'BIRC8'},
{value: 'BISPR', id: 'BISPR'},
{value: 'BIVM', id: 'BIVM'},
{value: 'BIVM-ERCC5', id: 'BIVM-ERCC5'},
{value: 'BLACAT1', id: 'BLACAT1'},
{value: 'BLACE', id: 'BLACE'},
{value: 'BLCAP', id: 'BLCAP'},
{value: 'BLK', id: 'BLK'},
{value: 'BLM', id: 'BLM'},
{value: 'BLMH', id: 'BLMH'},
{value: 'BLNK', id: 'BLNK'},
{value: 'BLOC1S1', id: 'BLOC1S1'},
{value: 'BLOC1S2', id: 'BLOC1S2'},
{value: 'BLOC1S3', id: 'BLOC1S3'},
{value: 'BLOC1S4', id: 'BLOC1S4'},
{value: 'BLOC1S5', id: 'BLOC1S5'},
{value: 'BLOC1S5-TXNDC5', id: 'BLOC1S5-TXNDC5'},
{value: 'BLOC1S6', id: 'BLOC1S6'},
{value: 'BLVRA', id: 'BLVRA'},
{value: 'BLVRB', id: 'BLVRB'},
{value: 'BLZF1', id: 'BLZF1'},
{value: 'BLZF2P', id: 'BLZF2P'},
{value: 'BMERB1', id: 'BMERB1'},
{value: 'BMF', id: 'BMF'},
{value: 'BMF-AS1', id: 'BMF-AS1'},
{value: 'BMI1', id: 'BMI1'},
{value: 'BMP1', id: 'BMP1'},
{value: 'BMP10', id: 'BMP10'},
{value: 'BMP2', id: 'BMP2'},
{value: 'BMP2K', id: 'BMP2K'},
{value: 'BMP2K-DT', id: 'BMP2K-DT'},
{value: 'BMP3', id: 'BMP3'},
{value: 'BMP4', id: 'BMP4'},
{value: 'BMP5', id: 'BMP5'},
{value: 'BMP6', id: 'BMP6'},
{value: 'BMP6P1', id: 'BMP6P1'},
{value: 'BMP7', id: 'BMP7'},
{value: 'BMP7-AS1', id: 'BMP7-AS1'},
{value: 'BMP8A', id: 'BMP8A'},
{value: 'BMP8B', id: 'BMP8B'},
{value: 'BMPER', id: 'BMPER'},
{value: 'BMPR1A', id: 'BMPR1A'},
{value: 'BMPR1AP2', id: 'BMPR1AP2'},
{value: 'BMPR1B', id: 'BMPR1B'},
{value: 'BMPR1B-DT', id: 'BMPR1B-DT'},
{value: 'BMPR2', id: 'BMPR2'},
{value: 'BMS1', id: 'BMS1'},
{value: 'BMS1P1', id: 'BMS1P1'},
{value: 'BMS1P10', id: 'BMS1P10'},
{value: 'BMS1P11', id: 'BMS1P11'},
{value: 'BMS1P12', id: 'BMS1P12'},
{value: 'BMS1P14', id: 'BMS1P14'},
{value: 'BMS1P15', id: 'BMS1P15'},
{value: 'BMS1P16', id: 'BMS1P16'},
{value: 'BMS1P17', id: 'BMS1P17'},
{value: 'BMS1P19', id: 'BMS1P19'},
{value: 'BMS1P2', id: 'BMS1P2'},
{value: 'BMS1P22', id: 'BMS1P22'},
{value: 'BMS1P23', id: 'BMS1P23'},
{value: 'BMS1P4', id: 'BMS1P4'},
{value: 'BMS1P4-AGAP5', id: 'BMS1P4-AGAP5'},
{value: 'BMS1P8', id: 'BMS1P8'},
{value: 'BMT2', id: 'BMT2'},
{value: 'BMX', id: 'BMX'},
{value: 'BNC1', id: 'BNC1'},
{value: 'BNC2', id: 'BNC2'},
{value: 'BNC2-AS1', id: 'BNC2-AS1'},
{value: 'BNIP1', id: 'BNIP1'},
{value: 'BNIP2', id: 'BNIP2'},
{value: 'BNIP3', id: 'BNIP3'},
{value: 'BNIP3L', id: 'BNIP3L'},
{value: 'BNIP3P1', id: 'BNIP3P1'},
{value: 'BNIP3P11', id: 'BNIP3P11'},
{value: 'BNIP3P13', id: 'BNIP3P13'},
{value: 'BNIP3P2', id: 'BNIP3P2'},
{value: 'BNIP3P24', id: 'BNIP3P24'},
{value: 'BNIP3P25', id: 'BNIP3P25'},
{value: 'BNIP3P26', id: 'BNIP3P26'},
{value: 'BNIP3P29', id: 'BNIP3P29'},
{value: 'BNIP3P3', id: 'BNIP3P3'},
{value: 'BNIP3P31', id: 'BNIP3P31'},
{value: 'BNIP3P34', id: 'BNIP3P34'},
{value: 'BNIP3P36', id: 'BNIP3P36'},
{value: 'BNIP3P37', id: 'BNIP3P37'},
{value: 'BNIP3P39', id: 'BNIP3P39'},
{value: 'BNIP3P4', id: 'BNIP3P4'},
{value: 'BNIP3P41', id: 'BNIP3P41'},
{value: 'BNIP3P42', id: 'BNIP3P42'},
{value: 'BNIP3P5', id: 'BNIP3P5'},
{value: 'BNIP3P6', id: 'BNIP3P6'},
{value: 'BNIP3P8', id: 'BNIP3P8'},
{value: 'BNIP3P9', id: 'BNIP3P9'},
{value: 'BNIP5', id: 'BNIP5'},
{value: 'BNIPL', id: 'BNIPL'},
{value: 'BOC', id: 'BOC'},
{value: 'BOD1', id: 'BOD1'},
{value: 'BOD1L1', id: 'BOD1L1'},
{value: 'BOD1L2', id: 'BOD1L2'},
{value: 'BOD1P1', id: 'BOD1P1'},
{value: 'BOD1P2', id: 'BOD1P2'},
{value: 'BOK', id: 'BOK'},
{value: 'BOK-AS1', id: 'BOK-AS1'},
{value: 'BOLA1', id: 'BOLA1'},
{value: 'BOLA2', id: 'BOLA2'},
{value: 'BOLA2-SMG1P6', id: 'BOLA2-SMG1P6'},
{value: 'BOLA2B', id: 'BOLA2B'},
{value: 'BOLA2P1', id: 'BOLA2P1'},
{value: 'BOLA2P2', id: 'BOLA2P2'},
{value: 'BOLA2P3', id: 'BOLA2P3'},
{value: 'BOLA3', id: 'BOLA3'},
{value: 'BOLA3-AS1', id: 'BOLA3-AS1'},
{value: 'BOLA3P1', id: 'BOLA3P1'},
{value: 'BOLA3P2', id: 'BOLA3P2'},
{value: 'BOLA3P3', id: 'BOLA3P3'},
{value: 'BOLL', id: 'BOLL'},
{value: 'BOP1', id: 'BOP1'},
{value: 'BORA', id: 'BORA'},
{value: 'BORCS5', id: 'BORCS5'},
{value: 'BORCS6', id: 'BORCS6'},
{value: 'BORCS7', id: 'BORCS7'},
{value: 'BORCS7-ASMT', id: 'BORCS7-ASMT'},
{value: 'BORCS8', id: 'BORCS8'},
{value: 'BORCS8-MEF2B', id: 'BORCS8-MEF2B'},
{value: 'BORCS8P1', id: 'BORCS8P1'},
{value: 'BPGM', id: 'BPGM'},
{value: 'BPHL', id: 'BPHL'},
{value: 'BPI', id: 'BPI'},
{value: 'BPIFA1', id: 'BPIFA1'},
{value: 'BPIFA2', id: 'BPIFA2'},
{value: 'BPIFA3', id: 'BPIFA3'},
{value: 'BPIFA4P', id: 'BPIFA4P'},
{value: 'BPIFB1', id: 'BPIFB1'},
{value: 'BPIFB2', id: 'BPIFB2'},
{value: 'BPIFB3', id: 'BPIFB3'},
{value: 'BPIFB4', id: 'BPIFB4'},
{value: 'BPIFB5P', id: 'BPIFB5P'},
{value: 'BPIFB6', id: 'BPIFB6'},
{value: 'BPIFB9P', id: 'BPIFB9P'},
{value: 'BPIFC', id: 'BPIFC'},
{value: 'BPNT1', id: 'BPNT1'},
{value: 'BPNT2', id: 'BPNT2'},
{value: 'BPNT2P1', id: 'BPNT2P1'},
{value: 'BPTF', id: 'BPTF'},
{value: 'BPTFP1', id: 'BPTFP1'},
{value: 'BRAF', id: 'BRAF'},
{value: 'BRAP', id: 'BRAP'},
{value: 'BRAT1', id: 'BRAT1'},
{value: 'BRCA1', id: 'BRCA1'},
{value: 'BRCA2', id: 'BRCA2'},
{value: 'BRCC3', id: 'BRCC3'},
{value: 'BRD1', id: 'BRD1'},
{value: 'BRD2', id: 'BRD2'},
{value: 'BRD3', id: 'BRD3'},
{value: 'BRD3OS', id: 'BRD3OS'},
{value: 'BRD4', id: 'BRD4'},
{value: 'BRD7', id: 'BRD7'},
{value: 'BRD7P1', id: 'BRD7P1'},
{value: 'BRD7P2', id: 'BRD7P2'},
{value: 'BRD7P3', id: 'BRD7P3'},
{value: 'BRD7P5', id: 'BRD7P5'},
{value: 'BRD7P6', id: 'BRD7P6'},
{value: 'BRD8', id: 'BRD8'},
{value: 'BRD9', id: 'BRD9'},
{value: 'BRD9P1', id: 'BRD9P1'},
{value: 'BRDT', id: 'BRDT'},
{value: 'BRF1', id: 'BRF1'},
{value: 'BRF2', id: 'BRF2'},
{value: 'BRI3', id: 'BRI3'},
{value: 'BRI3BP', id: 'BRI3BP'},
{value: 'BRI3BPP1', id: 'BRI3BPP1'},
{value: 'BRI3P1', id: 'BRI3P1'},
{value: 'BRI3P2', id: 'BRI3P2'},
{value: 'BRI3P3', id: 'BRI3P3'},
{value: 'BRICD5', id: 'BRICD5'},
{value: 'BRINP1', id: 'BRINP1'},
{value: 'BRINP2', id: 'BRINP2'},
{value: 'BRINP3', id: 'BRINP3'},
{value: 'BRIP1', id: 'BRIP1'},
{value: 'BRIX1', id: 'BRIX1'},
{value: 'BRK1', id: 'BRK1'},
{value: 'BRK1P2', id: 'BRK1P2'},
{value: 'BRME1', id: 'BRME1'},
{value: 'BRMS1', id: 'BRMS1'},
{value: 'BRMS1L', id: 'BRMS1L'},
{value: 'BROX', id: 'BROX'},
{value: 'BRPF1', id: 'BRPF1'},
{value: 'BRPF3', id: 'BRPF3'},
{value: 'BRPF3-AS1', id: 'BRPF3-AS1'},
{value: 'BRSK1', id: 'BRSK1'},
{value: 'BRSK2', id: 'BRSK2'},
{value: 'BRWD1', id: 'BRWD1'},
{value: 'BRWD1-AS1', id: 'BRWD1-AS1'},
{value: 'BRWD1-AS2', id: 'BRWD1-AS2'},
{value: 'BRWD1P1', id: 'BRWD1P1'},
{value: 'BRWD1P2', id: 'BRWD1P2'},
{value: 'BRWD1P3', id: 'BRWD1P3'},
{value: 'BRWD3', id: 'BRWD3'},
{value: 'BSCL2', id: 'BSCL2'},
{value: 'BSDC1', id: 'BSDC1'},
{value: 'BSG', id: 'BSG'},
{value: 'BSG-AS1', id: 'BSG-AS1'},
{value: 'BSN', id: 'BSN'},
{value: 'BSN-DT', id: 'BSN-DT'},
{value: 'BSND', id: 'BSND'},
{value: 'BSNDP3', id: 'BSNDP3'},
{value: 'BSPH1', id: 'BSPH1'},
{value: 'BSPRY', id: 'BSPRY'},
{value: 'BST1', id: 'BST1'},
{value: 'BST2', id: 'BST2'},
{value: 'BSX', id: 'BSX'},
{value: 'BTAF1', id: 'BTAF1'},
{value: 'BTBD1', id: 'BTBD1'},
{value: 'BTBD10', id: 'BTBD10'},
{value: 'BTBD10P1', id: 'BTBD10P1'},
{value: 'BTBD11', id: 'BTBD11'},
{value: 'BTBD16', id: 'BTBD16'},
{value: 'BTBD17', id: 'BTBD17'},
{value: 'BTBD18', id: 'BTBD18'},
{value: 'BTBD19', id: 'BTBD19'},
{value: 'BTBD2', id: 'BTBD2'},
{value: 'BTBD3', id: 'BTBD3'},
{value: 'BTBD6', id: 'BTBD6'},
{value: 'BTBD6P1', id: 'BTBD6P1'},
{value: 'BTBD7', id: 'BTBD7'},
{value: 'BTBD7P1', id: 'BTBD7P1'},
{value: 'BTBD7P2', id: 'BTBD7P2'},
{value: 'BTBD8', id: 'BTBD8'},
{value: 'BTBD9', id: 'BTBD9'},
{value: 'BTC', id: 'BTC'},
{value: 'BTD', id: 'BTD'},
{value: 'BTF3', id: 'BTF3'},
{value: 'BTF3-DT', id: 'BTF3-DT'},
{value: 'BTF3L4', id: 'BTF3L4'},
{value: 'BTF3L4P1', id: 'BTF3L4P1'},
{value: 'BTF3L4P2', id: 'BTF3L4P2'},
{value: 'BTF3L4P3', id: 'BTF3L4P3'},
{value: 'BTF3L4P4', id: 'BTF3L4P4'},
{value: 'BTF3P1', id: 'BTF3P1'},
{value: 'BTF3P10', id: 'BTF3P10'},
{value: 'BTF3P11', id: 'BTF3P11'},
{value: 'BTF3P13', id: 'BTF3P13'},
{value: 'BTF3P14', id: 'BTF3P14'},
{value: 'BTF3P15', id: 'BTF3P15'},
{value: 'BTF3P2', id: 'BTF3P2'},
{value: 'BTF3P4', id: 'BTF3P4'},
{value: 'BTF3P5', id: 'BTF3P5'},
{value: 'BTF3P9', id: 'BTF3P9'},
{value: 'BTG1', id: 'BTG1'},
{value: 'BTG1-DT', id: 'BTG1-DT'},
{value: 'BTG1P1', id: 'BTG1P1'},
{value: 'BTG2', id: 'BTG2'},
{value: 'BTG3', id: 'BTG3'},
{value: 'BTG3-AS1', id: 'BTG3-AS1'},
{value: 'BTG4', id: 'BTG4'},
{value: 'BTK', id: 'BTK'},
{value: 'BTLA', id: 'BTLA'},
{value: 'BTN1A1', id: 'BTN1A1'},
{value: 'BTN1A1P1', id: 'BTN1A1P1'},
{value: 'BTN2A1', id: 'BTN2A1'},
{value: 'BTN2A2', id: 'BTN2A2'},
{value: 'BTN2A3P', id: 'BTN2A3P'},
{value: 'BTN3A1', id: 'BTN3A1'},
{value: 'BTN3A2', id: 'BTN3A2'},
{value: 'BTN3A3', id: 'BTN3A3'},
{value: 'BTNL10', id: 'BTNL10'},
{value: 'BTNL2', id: 'BTNL2'},
{value: 'BTNL3', id: 'BTNL3'},
{value: 'BTNL8', id: 'BTNL8'},
{value: 'BTNL9', id: 'BTNL9'},
{value: 'BTRC', id: 'BTRC'},
{value: 'BUB1', id: 'BUB1'},
{value: 'BUB1B', id: 'BUB1B'},
{value: 'BUB1P1', id: 'BUB1P1'},
{value: 'BUB3', id: 'BUB3'},
{value: 'BUB3P1', id: 'BUB3P1'},
{value: 'BUD13', id: 'BUD13'},
{value: 'BUD13P1', id: 'BUD13P1'},
{value: 'BUD23', id: 'BUD23'},
{value: 'BUD31', id: 'BUD31'},
{value: 'BVES', id: 'BVES'},
{value: 'BVES-AS1', id: 'BVES-AS1'},
{value: 'BYSL', id: 'BYSL'},
{value: 'BZW1', id: 'BZW1'},
{value: 'BZW1-AS1', id: 'BZW1-AS1'},
{value: 'BZW1P1', id: 'BZW1P1'},
{value: 'BZW1P2', id: 'BZW1P2'},
{value: 'BZW2', id: 'BZW2'},
{value: 'C10orf105', id: 'C10orf105'},
{value: 'C10orf120', id: 'C10orf120'},
{value: 'C10orf143', id: 'C10orf143'},
{value: 'C10orf53', id: 'C10orf53'},
{value: 'C10orf55', id: 'C10orf55'},
{value: 'C10orf62', id: 'C10orf62'},
{value: 'C10orf67', id: 'C10orf67'},
{value: 'C10orf71', id: 'C10orf71'},
{value: 'C10orf71-AS1', id: 'C10orf71-AS1'},
{value: 'C10orf82', id: 'C10orf82'},
{value: 'C10orf88', id: 'C10orf88'},
{value: 'C10orf88B', id: 'C10orf88B'},
{value: 'C10orf90', id: 'C10orf90'},
{value: 'C10orf95', id: 'C10orf95'},
{value: 'C10orf95-AS1', id: 'C10orf95-AS1'},
{value: 'C10orf99', id: 'C10orf99'},
{value: 'C11orf1', id: 'C11orf1'},
{value: 'C11orf16', id: 'C11orf16'},
{value: 'C11orf21', id: 'C11orf21'},
{value: 'C11orf24', id: 'C11orf24'},
{value: 'C11orf40', id: 'C11orf40'},
{value: 'C11orf42', id: 'C11orf42'},
{value: 'C11orf45', id: 'C11orf45'},
{value: 'C11orf49', id: 'C11orf49'},
{value: 'C11orf52', id: 'C11orf52'},
{value: 'C11orf53', id: 'C11orf53'},
{value: 'C11orf54', id: 'C11orf54'},
{value: 'C11orf58', id: 'C11orf58'},
{value: 'C11orf65', id: 'C11orf65'},
{value: 'C11orf68', id: 'C11orf68'},
{value: 'C11orf71', id: 'C11orf71'},
{value: 'C11orf80', id: 'C11orf80'},
{value: 'C11orf86', id: 'C11orf86'},
{value: 'C11orf87', id: 'C11orf87'},
{value: 'C11orf91', id: 'C11orf91'},
{value: 'C11orf94', id: 'C11orf94'},
{value: 'C11orf96', id: 'C11orf96'},
{value: 'C11orf97', id: 'C11orf97'},
{value: 'C11orf98', id: 'C11orf98'},
{value: 'C11orf98P1', id: 'C11orf98P1'},
{value: 'C12orf29', id: 'C12orf29'},
{value: 'C12orf4', id: 'C12orf4'},
{value: 'C12orf40', id: 'C12orf40'},
{value: 'C12orf42', id: 'C12orf42'},
{value: 'C12orf43', id: 'C12orf43'},
{value: 'C12orf50', id: 'C12orf50'},
{value: 'C12orf54', id: 'C12orf54'},
{value: 'C12orf56', id: 'C12orf56'},
{value: 'C12orf57', id: 'C12orf57'},
{value: 'C12orf60', id: 'C12orf60'},
{value: 'C12orf71', id: 'C12orf71'},
{value: 'C12orf73', id: 'C12orf73'},
{value: 'C12orf75', id: 'C12orf75'},
{value: 'C12orf76', id: 'C12orf76'},
{value: 'C13orf42', id: 'C13orf42'},
{value: 'C13orf46', id: 'C13orf46'},
{value: 'C14orf119', id: 'C14orf119'},
{value: 'C14orf132', id: 'C14orf132'},
{value: 'C14orf178', id: 'C14orf178'},
{value: 'C14orf180', id: 'C14orf180'},
{value: 'C14orf28', id: 'C14orf28'},
{value: 'C14orf39', id: 'C14orf39'},
{value: 'C14orf93', id: 'C14orf93'},
{value: 'C15orf32', id: 'C15orf32'},
{value: 'C15orf39', id: 'C15orf39'},
{value: 'C15orf40', id: 'C15orf40'},
{value: 'C15orf48', id: 'C15orf48'},
{value: 'C15orf61', id: 'C15orf61'},
{value: 'C15orf62', id: 'C15orf62'},
{value: 'C15orf65', id: 'C15orf65'},
{value: 'C16orf46', id: 'C16orf46'},
{value: 'C16orf54', id: 'C16orf54'},
{value: 'C16orf72', id: 'C16orf72'},
{value: 'C16orf74', id: 'C16orf74'},
{value: 'C16orf78', id: 'C16orf78'},
{value: 'C16orf82', id: 'C16orf82'},
{value: 'C16orf86', id: 'C16orf86'},
{value: 'C16orf87', id: 'C16orf87'},
{value: 'C16orf89', id: 'C16orf89'},
{value: 'C16orf90', id: 'C16orf90'},
{value: 'C16orf91', id: 'C16orf91'},
{value: 'C16orf92', id: 'C16orf92'},
{value: 'C16orf95', id: 'C16orf95'},
{value: 'C16orf95-DT', id: 'C16orf95-DT'},
{value: 'C16orf96', id: 'C16orf96'},
{value: 'C17orf100', id: 'C17orf100'},
{value: 'C17orf107', id: 'C17orf107'},
{value: 'C17orf113', id: 'C17orf113'},
{value: 'C17orf114', id: 'C17orf114'},
{value: 'C17orf49', id: 'C17orf49'},
{value: 'C17orf58', id: 'C17orf58'},
{value: 'C17orf64', id: 'C17orf64'},
{value: 'C17orf67', id: 'C17orf67'},
{value: 'C17orf75', id: 'C17orf75'},
{value: 'C17orf80', id: 'C17orf80'},
{value: 'C17orf97', id: 'C17orf97'},
{value: 'C17orf98', id: 'C17orf98'},
{value: 'C17orf99', id: 'C17orf99'},
{value: 'C18orf15', id: 'C18orf15'},
{value: 'C18orf21', id: 'C18orf21'},
{value: 'C18orf25', id: 'C18orf25'},
{value: 'C18orf32', id: 'C18orf32'},
{value: 'C18orf54', id: 'C18orf54'},
{value: 'C18orf63', id: 'C18orf63'},
{value: 'C19orf12', id: 'C19orf12'},
{value: 'C19orf18', id: 'C19orf18'},
{value: 'C19orf25', id: 'C19orf25'},
{value: 'C19orf33', id: 'C19orf33'},
{value: 'C19orf38', id: 'C19orf38'},
{value: 'C19orf44', id: 'C19orf44'},
{value: 'C19orf47', id: 'C19orf47'},
{value: 'C19orf48', id: 'C19orf48'},
{value: 'C19orf53', id: 'C19orf53'},
{value: 'C19orf54', id: 'C19orf54'},
{value: 'C19orf67', id: 'C19orf67'},
{value: 'C19orf71', id: 'C19orf71'},
{value: 'C19orf73', id: 'C19orf73'},
{value: 'C19orf81', id: 'C19orf81'},
{value: 'C19orf84', id: 'C19orf84'},
{value: 'C19orf85', id: 'C19orf85'},
{value: 'C1D', id: 'C1D'},
{value: 'C1DP1', id: 'C1DP1'},
{value: 'C1DP2', id: 'C1DP2'},
{value: 'C1DP3', id: 'C1DP3'},
{value: 'C1DP4', id: 'C1DP4'},
{value: 'C1DP5', id: 'C1DP5'},
{value: 'C1GALT1', id: 'C1GALT1'},
{value: 'C1GALT1C1', id: 'C1GALT1C1'},
{value: 'C1GALT1C1L', id: 'C1GALT1C1L'},
{value: 'C1GALT1P1', id: 'C1GALT1P1'},
{value: 'C1GALT1P3', id: 'C1GALT1P3'},
{value: 'C1orf100', id: 'C1orf100'},
{value: 'C1orf105', id: 'C1orf105'},
{value: 'C1orf109', id: 'C1orf109'},
{value: 'C1orf112', id: 'C1orf112'},
{value: 'C1orf115', id: 'C1orf115'},
{value: 'C1orf116', id: 'C1orf116'},
{value: 'C1orf122', id: 'C1orf122'},
{value: 'C1orf127', id: 'C1orf127'},
{value: 'C1orf131', id: 'C1orf131'},
{value: 'C1orf141', id: 'C1orf141'},
{value: 'C1orf146', id: 'C1orf146'},
{value: 'C1orf147', id: 'C1orf147'},
{value: 'C1orf158', id: 'C1orf158'},
{value: 'C1orf159', id: 'C1orf159'},
{value: 'C1orf162', id: 'C1orf162'},
{value: 'C1orf167', id: 'C1orf167'},
{value: 'C1orf167-AS1', id: 'C1orf167-AS1'},
{value: 'C1orf174', id: 'C1orf174'},
{value: 'C1orf185', id: 'C1orf185'},
{value: 'C1orf189', id: 'C1orf189'},
{value: 'C1orf194', id: 'C1orf194'},
{value: 'C1orf198', id: 'C1orf198'},
{value: 'C1orf21', id: 'C1orf21'},
{value: 'C1orf21-DT', id: 'C1orf21-DT'},
{value: 'C1orf210', id: 'C1orf210'},
{value: 'C1orf216', id: 'C1orf216'},
{value: 'C1orf220', id: 'C1orf220'},
{value: 'C1orf226', id: 'C1orf226'},
{value: 'C1orf232', id: 'C1orf232'},
{value: 'C1orf35', id: 'C1orf35'},
{value: 'C1orf43', id: 'C1orf43'},
{value: 'C1orf50', id: 'C1orf50'},
{value: 'C1orf52', id: 'C1orf52'},
{value: 'C1orf53', id: 'C1orf53'},
{value: 'C1orf54', id: 'C1orf54'},
{value: 'C1orf56', id: 'C1orf56'},
{value: 'C1orf68', id: 'C1orf68'},
{value: 'C1orf74', id: 'C1orf74'},
{value: 'C1orf87', id: 'C1orf87'},
{value: 'C1orf94', id: 'C1orf94'},
{value: 'C1QA', id: 'C1QA'},
{value: 'C1QB', id: 'C1QB'},
{value: 'C1QBP', id: 'C1QBP'},
{value: 'C1QBPP1', id: 'C1QBPP1'},
{value: 'C1QBPP2', id: 'C1QBPP2'},
{value: 'C1QC', id: 'C1QC'},
{value: 'C1QL1', id: 'C1QL1'},
{value: 'C1QL1P1', id: 'C1QL1P1'},
{value: 'C1QL2', id: 'C1QL2'},
{value: 'C1QL3', id: 'C1QL3'},
{value: 'C1QL4', id: 'C1QL4'},
{value: 'C1QTNF1', id: 'C1QTNF1'},
{value: 'C1QTNF1-AS1', id: 'C1QTNF1-AS1'},
{value: 'C1QTNF12', id: 'C1QTNF12'},
{value: 'C1QTNF2', id: 'C1QTNF2'},
{value: 'C1QTNF3', id: 'C1QTNF3'},
{value: 'C1QTNF3-AMACR', id: 'C1QTNF3-AMACR'},
{value: 'C1QTNF4', id: 'C1QTNF4'},
{value: 'C1QTNF5', id: 'C1QTNF5'},
{value: 'C1QTNF6', id: 'C1QTNF6'},
{value: 'C1QTNF7', id: 'C1QTNF7'},
{value: 'C1QTNF7-AS1', id: 'C1QTNF7-AS1'},
{value: 'C1QTNF8', id: 'C1QTNF8'},
{value: 'C1QTNF9', id: 'C1QTNF9'},
{value: 'C1QTNF9B', id: 'C1QTNF9B'},
{value: 'C1R', id: 'C1R'},
{value: 'C1RL', id: 'C1RL'},
{value: 'C1RL-AS1', id: 'C1RL-AS1'},
{value: 'C1S', id: 'C1S'},
{value: 'C2', id: 'C2'},
{value: 'C20orf173', id: 'C20orf173'},
{value: 'C20orf181', id: 'C20orf181'},
{value: 'C20orf202', id: 'C20orf202'},
{value: 'C20orf203', id: 'C20orf203'},
{value: 'C20orf204', id: 'C20orf204'},
{value: 'C20orf27', id: 'C20orf27'},
{value: 'C20orf85', id: 'C20orf85'},
{value: 'C20orf96', id: 'C20orf96'},
{value: 'C21orf58', id: 'C21orf58'},
{value: 'C21orf62', id: 'C21orf62'},
{value: 'C21orf62-AS1', id: 'C21orf62-AS1'},
{value: 'C21orf91', id: 'C21orf91'},
{value: 'C21orf91-OT1', id: 'C21orf91-OT1'},
{value: 'C22orf15', id: 'C22orf15'},
{value: 'C22orf23', id: 'C22orf23'},
{value: 'C22orf31', id: 'C22orf31'},
{value: 'C22orf39', id: 'C22orf39'},
{value: 'C22orf42', id: 'C22orf42'},
{value: 'C22orf46', id: 'C22orf46'},
{value: 'C2CD2', id: 'C2CD2'},
{value: 'C2CD2L', id: 'C2CD2L'},
{value: 'C2CD3', id: 'C2CD3'},
{value: 'C2CD4A', id: 'C2CD4A'},
{value: 'C2CD4B', id: 'C2CD4B'},
{value: 'C2CD4C', id: 'C2CD4C'},
{value: 'C2CD4D', id: 'C2CD4D'},
{value: 'C2CD4D-AS1', id: 'C2CD4D-AS1'},
{value: 'C2CD5', id: 'C2CD5'},
{value: 'C2CD6', id: 'C2CD6'},
{value: 'C2orf15', id: 'C2orf15'},
{value: 'C2orf16', id: 'C2orf16'},
{value: 'C2orf27A', id: 'C2orf27A'},
{value: 'C2orf42', id: 'C2orf42'},
{value: 'C2orf49', id: 'C2orf49'},
{value: 'C2orf49-DT', id: 'C2orf49-DT'},
{value: 'C2orf50', id: 'C2orf50'},
{value: 'C2orf66', id: 'C2orf66'},
{value: 'C2orf68', id: 'C2orf68'},
{value: 'C2orf69', id: 'C2orf69'},
{value: 'C2orf69P1', id: 'C2orf69P1'},
{value: 'C2orf69P2', id: 'C2orf69P2'},
{value: 'C2orf69P3', id: 'C2orf69P3'},
{value: 'C2orf69P4', id: 'C2orf69P4'},
{value: 'C2orf72', id: 'C2orf72'},
{value: 'C2orf73', id: 'C2orf73'},
{value: 'C2orf74', id: 'C2orf74'},
{value: 'C2orf76', id: 'C2orf76'},
{value: 'C2orf78', id: 'C2orf78'},
{value: 'C2orf80', id: 'C2orf80'},
{value: 'C2orf81', id: 'C2orf81'},
{value: 'C2orf83', id: 'C2orf83'},
{value: 'C2orf88', id: 'C2orf88'},
{value: 'C2orf92', id: 'C2orf92'},
{value: 'C3', id: 'C3'},
{value: 'C3AR1', id: 'C3AR1'},
{value: 'C3orf14', id: 'C3orf14'},
{value: 'C3orf18', id: 'C3orf18'},
{value: 'C3orf20', id: 'C3orf20'},
{value: 'C3orf22', id: 'C3orf22'},
{value: 'C3orf33', id: 'C3orf33'},
{value: 'C3orf35', id: 'C3orf35'},
{value: 'C3orf36', id: 'C3orf36'},
{value: 'C3orf38', id: 'C3orf38'},
{value: 'C3orf49', id: 'C3orf49'},
{value: 'C3orf52', id: 'C3orf52'},
{value: 'C3orf56', id: 'C3orf56'},
{value: 'C3orf62', id: 'C3orf62'},
{value: 'C3orf70', id: 'C3orf70'},
{value: 'C3orf80', id: 'C3orf80'},
{value: 'C3orf84', id: 'C3orf84'},
{value: 'C3orf85', id: 'C3orf85'},
{value: 'C3orf86', id: 'C3orf86'},
{value: 'C3P1', id: 'C3P1'},
{value: 'C4A', id: 'C4A'},
{value: 'C4A-AS1', id: 'C4A-AS1'},
{value: 'C4B', id: 'C4B'},
{value: 'C4B_2', id: 'C4B_2'},
{value: 'C4B-AS1', id: 'C4B-AS1'},
{value: 'C4BPA', id: 'C4BPA'},
{value: 'C4BPAP1', id: 'C4BPAP1'},
{value: 'C4BPAP2', id: 'C4BPAP2'},
{value: 'C4BPB', id: 'C4BPB'},
{value: 'C4orf17', id: 'C4orf17'},
{value: 'C4orf19', id: 'C4orf19'},
{value: 'C4orf3', id: 'C4orf3'},
{value: 'C4orf33', id: 'C4orf33'},
{value: 'C4orf36', id: 'C4orf36'},
{value: 'C4orf45', id: 'C4orf45'},
{value: 'C4orf46', id: 'C4orf46'},
{value: 'C4orf47', id: 'C4orf47'},
{value: 'C4orf48', id: 'C4orf48'},
{value: 'C4orf50', id: 'C4orf50'},
{value: 'C4orf51', id: 'C4orf51'},
{value: 'C4orf54', id: 'C4orf54'},
{value: 'C5', id: 'C5'},
{value: 'C5AR1', id: 'C5AR1'},
{value: 'C5AR2', id: 'C5AR2'},
{value: 'C5orf15', id: 'C5orf15'},
{value: 'C5orf22', id: 'C5orf22'},
{value: 'C5orf24', id: 'C5orf24'},
{value: 'C5orf34', id: 'C5orf34'},
{value: 'C5orf34-AS1', id: 'C5orf34-AS1'},
{value: 'C5orf38', id: 'C5orf38'},
{value: 'C5orf46', id: 'C5orf46'},
{value: 'C5orf47', id: 'C5orf47'},
{value: 'C5orf49', id: 'C5orf49'},
{value: 'C5orf51', id: 'C5orf51'},
{value: 'C5orf52', id: 'C5orf52'},
{value: 'C5orf58', id: 'C5orf58'},
{value: 'C5orf60', id: 'C5orf60'},
{value: 'C5orf63', id: 'C5orf63'},
{value: 'C5orf64', id: 'C5orf64'},
{value: 'C5orf67', id: 'C5orf67'},
{value: 'C6', id: 'C6'},
{value: 'C6orf118', id: 'C6orf118'},
{value: 'C6orf120', id: 'C6orf120'},
{value: 'C6orf132', id: 'C6orf132'},
{value: 'C6orf136', id: 'C6orf136'},
{value: 'C6orf141', id: 'C6orf141'},
{value: 'C6orf15', id: 'C6orf15'},
{value: 'C6orf163', id: 'C6orf163'},
{value: 'C6orf201', id: 'C6orf201'},
{value: 'C6orf223', id: 'C6orf223'},
{value: 'C6orf226', id: 'C6orf226'},
{value: 'C6orf47', id: 'C6orf47'},
{value: 'C6orf47-AS1', id: 'C6orf47-AS1'},
{value: 'C6orf52', id: 'C6orf52'},
{value: 'C6orf58', id: 'C6orf58'},
{value: 'C6orf62', id: 'C6orf62'},
{value: 'C6orf89', id: 'C6orf89'},
{value: 'C7', id: 'C7'},
{value: 'C7orf25', id: 'C7orf25'},
{value: 'C7orf26', id: 'C7orf26'},
{value: 'C7orf31', id: 'C7orf31'},
{value: 'C7orf33', id: 'C7orf33'},
{value: 'C7orf50', id: 'C7orf50'},
{value: 'C7orf57', id: 'C7orf57'},
{value: 'C7orf61', id: 'C7orf61'},
{value: 'C7orf77', id: 'C7orf77'},
{value: 'C8A', id: 'C8A'},
{value: 'C8B', id: 'C8B'},
{value: 'C8G', id: 'C8G'},
{value: 'C8orf17', id: 'C8orf17'},
{value: 'C8orf33', id: 'C8orf33'},
{value: 'C8orf34', id: 'C8orf34'},
{value: 'C8orf34-AS1', id: 'C8orf34-AS1'},
{value: 'C8orf44', id: 'C8orf44'},
{value: 'C8orf44-SGK3', id: 'C8orf44-SGK3'},
{value: 'C8orf48', id: 'C8orf48'},
{value: 'C8orf58', id: 'C8orf58'},
{value: 'C8orf74', id: 'C8orf74'},
{value: 'C8orf76', id: 'C8orf76'},
{value: 'C8orf82', id: 'C8orf82'},
{value: 'C8orf86', id: 'C8orf86'},
{value: 'C8orf88', id: 'C8orf88'},
{value: 'C8orf89', id: 'C8orf89'},
{value: 'C9', id: 'C9'},
{value: 'C9orf116', id: 'C9orf116'},
{value: 'C9orf129', id: 'C9orf129'},
{value: 'C9orf131', id: 'C9orf131'},
{value: 'C9orf135', id: 'C9orf135'},
{value: 'C9orf135-DT', id: 'C9orf135-DT'},
{value: 'C9orf152', id: 'C9orf152'},
{value: 'C9orf153', id: 'C9orf153'},
{value: 'C9orf163', id: 'C9orf163'},
{value: 'C9orf24', id: 'C9orf24'},
{value: 'C9orf40', id: 'C9orf40'},
{value: 'C9orf43', id: 'C9orf43'},
{value: 'C9orf50', id: 'C9orf50'},
{value: 'C9orf57', id: 'C9orf57'},
{value: 'C9orf64', id: 'C9orf64'},
{value: 'C9orf72', id: 'C9orf72'},
{value: 'C9orf78', id: 'C9orf78'},
{value: 'C9orf85', id: 'C9orf85'},
{value: 'C9orf92', id: 'C9orf92'},
{value: 'CA1', id: 'CA1'},
{value: 'CA10', id: 'CA10'},
{value: 'CA11', id: 'CA11'},
{value: 'CA12', id: 'CA12'},
{value: 'CA13', id: 'CA13'},
{value: 'CA14', id: 'CA14'},
{value: 'CA15P1', id: 'CA15P1'},
{value: 'CA15P2', id: 'CA15P2'},
{value: 'CA2', id: 'CA2'},
{value: 'CA3', id: 'CA3'},
{value: 'CA3-AS1', id: 'CA3-AS1'},
{value: 'CA4', id: 'CA4'},
{value: 'CA5A', id: 'CA5A'},
{value: 'CA5AP1', id: 'CA5AP1'},
{value: 'CA5B', id: 'CA5B'},
{value: 'CA5BP1', id: 'CA5BP1'},
{value: 'CA6', id: 'CA6'},
{value: 'CA7', id: 'CA7'},
{value: 'CA8', id: 'CA8'},
{value: 'CA9', id: 'CA9'},
{value: 'CAAP1', id: 'CAAP1'},
{value: 'CAB39', id: 'CAB39'},
{value: 'CAB39L', id: 'CAB39L'},
{value: 'CABCOCO1', id: 'CABCOCO1'},
{value: 'CABIN1', id: 'CABIN1'},
{value: 'CABLES1', id: 'CABLES1'},
{value: 'CABLES2', id: 'CABLES2'},
{value: 'CABP1', id: 'CABP1'},
{value: 'CABP1-DT', id: 'CABP1-DT'},
{value: 'CABP2', id: 'CABP2'},
{value: 'CABP4', id: 'CABP4'},
{value: 'CABP5', id: 'CABP5'},
{value: 'CABP7', id: 'CABP7'},
{value: 'CABS1', id: 'CABS1'},
{value: 'CABYR', id: 'CABYR'},
{value: 'CACFD1', id: 'CACFD1'},
{value: 'CACHD1', id: 'CACHD1'},
{value: 'CACNA1A', id: 'CACNA1A'},
{value: 'CACNA1B', id: 'CACNA1B'},
{value: 'CACNA1C', id: 'CACNA1C'},
{value: 'CACNA1C-AS1', id: 'CACNA1C-AS1'},
{value: 'CACNA1C-AS2', id: 'CACNA1C-AS2'},
{value: 'CACNA1D', id: 'CACNA1D'},
{value: 'CACNA1E', id: 'CACNA1E'},
{value: 'CACNA1F', id: 'CACNA1F'},
{value: 'CACNA1G', id: 'CACNA1G'},
{value: 'CACNA1G-AS1', id: 'CACNA1G-AS1'},
{value: 'CACNA1H', id: 'CACNA1H'},
{value: 'CACNA1I', id: 'CACNA1I'},
{value: 'CACNA1S', id: 'CACNA1S'},
{value: 'CACNA2D1', id: 'CACNA2D1'},
{value: 'CACNA2D1-AS1', id: 'CACNA2D1-AS1'},
{value: 'CACNA2D2', id: 'CACNA2D2'},
{value: 'CACNA2D3', id: 'CACNA2D3'},
{value: 'CACNA2D3-AS1', id: 'CACNA2D3-AS1'},
{value: 'CACNA2D4', id: 'CACNA2D4'},
{value: 'CACNB1', id: 'CACNB1'},
{value: 'CACNB2', id: 'CACNB2'},
{value: 'CACNB3', id: 'CACNB3'},
{value: 'CACNB4', id: 'CACNB4'},
{value: 'CACNG1', id: 'CACNG1'},
{value: 'CACNG2', id: 'CACNG2'},
{value: 'CACNG3', id: 'CACNG3'},
{value: 'CACNG4', id: 'CACNG4'},
{value: 'CACNG5', id: 'CACNG5'},
{value: 'CACNG6', id: 'CACNG6'},
{value: 'CACNG7', id: 'CACNG7'},
{value: 'CACNG8', id: 'CACNG8'},
{value: 'CACTIN', id: 'CACTIN'},
{value: 'CACTIN-AS1', id: 'CACTIN-AS1'},
{value: 'CACUL1', id: 'CACUL1'},
{value: 'CACYBP', id: 'CACYBP'},
{value: 'CACYBPP1', id: 'CACYBPP1'},
{value: 'CACYBPP2', id: 'CACYBPP2'},
{value: 'CACYBPP3', id: 'CACYBPP3'},
{value: 'CAD', id: 'CAD'},
{value: 'CADM1', id: 'CADM1'},
{value: 'CADM2', id: 'CADM2'},
{value: 'CADM2-AS1', id: 'CADM2-AS1'},
{value: 'CADM3', id: 'CADM3'},
{value: 'CADM3-AS1', id: 'CADM3-AS1'},
{value: 'CADM4', id: 'CADM4'},
{value: 'CADPS', id: 'CADPS'},
{value: 'CADPS2', id: 'CADPS2'},
{value: 'CAGE1', id: 'CAGE1'},
{value: 'CAHM', id: 'CAHM'},
{value: 'CALB1', id: 'CALB1'},
{value: 'CALB2', id: 'CALB2'},
{value: 'CALCA', id: 'CALCA'},
{value: 'CALCB', id: 'CALCB'},
{value: 'CALCOCO1', id: 'CALCOCO1'},
{value: 'CALCOCO2', id: 'CALCOCO2'},
{value: 'CALCP', id: 'CALCP'},
{value: 'CALCR', id: 'CALCR'},
{value: 'CALCRL', id: 'CALCRL'},
{value: 'CALD1', id: 'CALD1'},
{value: 'CALHM1', id: 'CALHM1'},
{value: 'CALHM2', id: 'CALHM2'},
{value: 'CALHM3', id: 'CALHM3'},
{value: 'CALHM4', id: 'CALHM4'},
{value: 'CALHM5', id: 'CALHM5'},
{value: 'CALHM6', id: 'CALHM6'},
{value: 'CALHM6-AS1', id: 'CALHM6-AS1'},
{value: 'CALM1', id: 'CALM1'},
{value: 'CALM1P2', id: 'CALM1P2'},
{value: 'CALM2', id: 'CALM2'},
{value: 'CALM2P1', id: 'CALM2P1'},
{value: 'CALM2P2', id: 'CALM2P2'},
{value: 'CALM2P4', id: 'CALM2P4'},
{value: 'CALM3', id: 'CALM3'},
{value: 'CALML3', id: 'CALML3'},
{value: 'CALML3-AS1', id: 'CALML3-AS1'},
{value: 'CALML4', id: 'CALML4'},
{value: 'CALML5', id: 'CALML5'},
{value: 'CALML6', id: 'CALML6'},
{value: 'CALN1', id: 'CALN1'},
{value: 'CALR', id: 'CALR'},
{value: 'CALR3', id: 'CALR3'},
{value: 'CALR4P', id: 'CALR4P'},
{value: 'CALU', id: 'CALU'},
{value: 'CALY', id: 'CALY'},
{value: 'CAMK1', id: 'CAMK1'},
{value: 'CAMK1D', id: 'CAMK1D'},
{value: 'CAMK1G', id: 'CAMK1G'},
{value: 'CAMK2A', id: 'CAMK2A'},
{value: 'CAMK2B', id: 'CAMK2B'},
{value: 'CAMK2D', id: 'CAMK2D'},
{value: 'CAMK2G', id: 'CAMK2G'},
{value: 'CAMK2N1', id: 'CAMK2N1'},
{value: 'CAMK2N2', id: 'CAMK2N2'},
{value: 'CAMK4', id: 'CAMK4'},
{value: 'CAMKK1', id: 'CAMKK1'},
{value: 'CAMKK2', id: 'CAMKK2'},
{value: 'CAMKMT', id: 'CAMKMT'},
{value: 'CAMKV', id: 'CAMKV'},
{value: 'CAMLG', id: 'CAMLG'},
{value: 'CAMP', id: 'CAMP'},
{value: 'CAMSAP1', id: 'CAMSAP1'},
{value: 'CAMSAP2', id: 'CAMSAP2'},
{value: 'CAMSAP3', id: 'CAMSAP3'},
{value: 'CAMTA1', id: 'CAMTA1'},
{value: 'CAMTA1-AS1', id: 'CAMTA1-AS1'},
{value: 'CAMTA1-AS2', id: 'CAMTA1-AS2'},
{value: 'CAMTA1-DT', id: 'CAMTA1-DT'},
{value: 'CAMTA1-IT1', id: 'CAMTA1-IT1'},
{value: 'CAMTA2', id: 'CAMTA2'},
{value: 'CAMTA2-AS1', id: 'CAMTA2-AS1'},
{value: 'CAND1', id: 'CAND1'},
{value: 'CAND2', id: 'CAND2'},
{value: 'CANT1', id: 'CANT1'},
{value: 'CANX', id: 'CANX'},
{value: 'CAP1', id: 'CAP1'},
{value: 'CAP1P1', id: 'CAP1P1'},
{value: 'CAP1P2', id: 'CAP1P2'},
{value: 'CAP2', id: 'CAP2'},
{value: 'CAP2P1', id: 'CAP2P1'},
{value: 'CAPG', id: 'CAPG'},
{value: 'CAPN1', id: 'CAPN1'},
{value: 'CAPN10', id: 'CAPN10'},
{value: 'CAPN10-DT', id: 'CAPN10-DT'},
{value: 'CAPN11', id: 'CAPN11'},
{value: 'CAPN12', id: 'CAPN12'},
{value: 'CAPN13', id: 'CAPN13'},
{value: 'CAPN14', id: 'CAPN14'},
{value: 'CAPN15', id: 'CAPN15'},
{value: 'CAPN2', id: 'CAPN2'},
{value: 'CAPN3', id: 'CAPN3'},
{value: 'CAPN5', id: 'CAPN5'},
{value: 'CAPN6', id: 'CAPN6'},
{value: 'CAPN7', id: 'CAPN7'},
{value: 'CAPN8', id: 'CAPN8'},
{value: 'CAPN9', id: 'CAPN9'},
{value: 'CAPNS1', id: 'CAPNS1'},
{value: 'CAPNS1P1', id: 'CAPNS1P1'},
{value: 'CAPNS2', id: 'CAPNS2'},
{value: 'CAPRIN1', id: 'CAPRIN1'},
{value: 'CAPRIN2', id: 'CAPRIN2'},
{value: 'CAPS', id: 'CAPS'},
{value: 'CAPS2', id: 'CAPS2'},
{value: 'CAPS2-AS1', id: 'CAPS2-AS1'},
{value: 'CAPSL', id: 'CAPSL'},
{value: 'CAPZA1', id: 'CAPZA1'},
{value: 'CAPZA1P4', id: 'CAPZA1P4'},
{value: 'CAPZA2', id: 'CAPZA2'},
{value: 'CAPZA3', id: 'CAPZA3'},
{value: 'CAPZB', id: 'CAPZB'},
{value: 'CAPZBP1', id: 'CAPZBP1'},
{value: 'CARD10', id: 'CARD10'},
{value: 'CARD11', id: 'CARD11'},
{value: 'CARD11-AS1', id: 'CARD11-AS1'},
{value: 'CARD14', id: 'CARD14'},
{value: 'CARD16', id: 'CARD16'},
{value: 'CARD17', id: 'CARD17'},
{value: 'CARD18', id: 'CARD18'},
{value: 'CARD19', id: 'CARD19'},
{value: 'CARD6', id: 'CARD6'},
{value: 'CARD8', id: 'CARD8'},
{value: 'CARD8-AS1', id: 'CARD8-AS1'},
{value: 'CARD9', id: 'CARD9'},
{value: 'CARF', id: 'CARF'},
{value: 'CARHSP1', id: 'CARHSP1'},
{value: 'CARHSP1-DT', id: 'CARHSP1-DT'},
{value: 'CARM1', id: 'CARM1'},
{value: 'CARM1P1', id: 'CARM1P1'},
{value: 'CARMAL', id: 'CARMAL'},
{value: 'CARMIL1', id: 'CARMIL1'},
{value: 'CARMIL2', id: 'CARMIL2'},
{value: 'CARMIL3', id: 'CARMIL3'},
{value: 'CARMN', id: 'CARMN'},
{value: 'CARNMT1', id: 'CARNMT1'},
{value: 'CARNMT1-AS1', id: 'CARNMT1-AS1'},
{value: 'CARNS1', id: 'CARNS1'},
{value: 'CARS1', id: 'CARS1'},
{value: 'CARS1-AS1', id: 'CARS1-AS1'},
{value: 'CARS1P1', id: 'CARS1P1'},
{value: 'CARS1P2', id: 'CARS1P2'},
{value: 'CARS2', id: 'CARS2'},
{value: 'CARTPT', id: 'CARTPT'},
{value: 'CASC11', id: 'CASC11'},
{value: 'CASC15', id: 'CASC15'},
{value: 'CASC16', id: 'CASC16'},
{value: 'CASC17', id: 'CASC17'},
{value: 'CASC18', id: 'CASC18'},
{value: 'CASC19', id: 'CASC19'},
{value: 'CASC2', id: 'CASC2'},
{value: 'CASC20', id: 'CASC20'},
{value: 'CASC22', id: 'CASC22'},
{value: 'CASC23', id: 'CASC23'},
{value: 'CASC3', id: 'CASC3'},
{value: 'CASC6', id: 'CASC6'},
{value: 'CASC8', id: 'CASC8'},
{value: 'CASC9', id: 'CASC9'},
{value: 'CASD1', id: 'CASD1'},
{value: 'CASK', id: 'CASK'},
{value: 'CASKIN1', id: 'CASKIN1'},
{value: 'CASKIN2', id: 'CASKIN2'},
{value: 'CASP1', id: 'CASP1'},
{value: 'CASP10', id: 'CASP10'},
{value: 'CASP12', id: 'CASP12'},
{value: 'CASP14', id: 'CASP14'},
{value: 'CASP16P', id: 'CASP16P'},
{value: 'CASP1P1', id: 'CASP1P1'},
{value: 'CASP1P2', id: 'CASP1P2'},
{value: 'CASP2', id: 'CASP2'},
{value: 'CASP3', id: 'CASP3'},
{value: 'CASP3P1', id: 'CASP3P1'},
{value: 'CASP4', id: 'CASP4'},
{value: 'CASP4LP', id: 'CASP4LP'},
{value: 'CASP5', id: 'CASP5'},
{value: 'CASP6', id: 'CASP6'},
{value: 'CASP7', id: 'CASP7'},
{value: 'CASP8', id: 'CASP8'},
{value: 'CASP8AP2', id: 'CASP8AP2'},
{value: 'CASP9', id: 'CASP9'},
{value: 'CASQ1', id: 'CASQ1'},
{value: 'CASQ2', id: 'CASQ2'},
{value: 'CASR', id: 'CASR'},
{value: 'CASS4', id: 'CASS4'},
{value: 'CAST', id: 'CAST'},
{value: 'CASTOR1', id: 'CASTOR1'},
{value: 'CASTOR2', id: 'CASTOR2'},
{value: 'CASTOR3', id: 'CASTOR3'},
{value: 'CASZ1', id: 'CASZ1'},
{value: 'CAT', id: 'CAT'},
{value: 'CATIP', id: 'CATIP'},
{value: 'CATIP-AS1', id: 'CATIP-AS1'},
{value: 'CATIP-AS2', id: 'CATIP-AS2'},
{value: 'CATSPER1', id: 'CATSPER1'},
{value: 'CATSPER2', id: 'CATSPER2'},
{value: 'CATSPER2P1', id: 'CATSPER2P1'},
{value: 'CATSPER3', id: 'CATSPER3'},
{value: 'CATSPER4', id: 'CATSPER4'},
{value: 'CATSPERB', id: 'CATSPERB'},
{value: 'CATSPERD', id: 'CATSPERD'},
{value: 'CATSPERE', id: 'CATSPERE'},
{value: 'CATSPERG', id: 'CATSPERG'},
{value: 'CATSPERZ', id: 'CATSPERZ'},
{value: 'CAV1', id: 'CAV1'},
{value: 'CAV2', id: 'CAV2'},
{value: 'CAV3', id: 'CAV3'},
{value: 'CAVIN1', id: 'CAVIN1'},
{value: 'CAVIN2', id: 'CAVIN2'},
{value: 'CAVIN2-AS1', id: 'CAVIN2-AS1'},
{value: 'CAVIN3', id: 'CAVIN3'},
{value: 'CAVIN4', id: 'CAVIN4'},
{value: 'CBARP', id: 'CBARP'},
{value: 'CBARP-DT', id: 'CBARP-DT'},
{value: 'CBFA2T2', id: 'CBFA2T2'},
{value: 'CBFA2T3', id: 'CBFA2T3'},
{value: 'CBFB', id: 'CBFB'},
{value: 'CBL', id: 'CBL'},
{value: 'CBLB', id: 'CBLB'},
{value: 'CBLC', id: 'CBLC'},
{value: 'CBLIF', id: 'CBLIF'},
{value: 'CBLL1', id: 'CBLL1'},
{value: 'CBLL1-AS1', id: 'CBLL1-AS1'},
{value: 'CBLN1', id: 'CBLN1'},
{value: 'CBLN2', id: 'CBLN2'},
{value: 'CBLN3', id: 'CBLN3'},
{value: 'CBLN4', id: 'CBLN4'},
{value: 'CBR1', id: 'CBR1'},
{value: 'CBR3', id: 'CBR3'},
{value: 'CBR3-AS1', id: 'CBR3-AS1'},
{value: 'CBR4', id: 'CBR4'},
{value: 'CBR4-DT', id: 'CBR4-DT'},
{value: 'CBS', id: 'CBS'},
{value: 'CBSL', id: 'CBSL'},
{value: 'CBWD1', id: 'CBWD1'},
{value: 'CBWD2', id: 'CBWD2'},
{value: 'CBWD3', id: 'CBWD3'},
{value: 'CBWD4P', id: 'CBWD4P'},
{value: 'CBWD5', id: 'CBWD5'},
{value: 'CBWD6', id: 'CBWD6'},
{value: 'CBX1', id: 'CBX1'},
{value: 'CBX1P3', id: 'CBX1P3'},
{value: 'CBX1P5', id: 'CBX1P5'},
{value: 'CBX2', id: 'CBX2'},
{value: 'CBX3', id: 'CBX3'},
{value: 'CBX3P2', id: 'CBX3P2'},
{value: 'CBX3P4', id: 'CBX3P4'},
{value: 'CBX3P5', id: 'CBX3P5'},
{value: 'CBX3P6', id: 'CBX3P6'},
{value: 'CBX3P8', id: 'CBX3P8'},
{value: 'CBX4', id: 'CBX4'},
{value: 'CBX5', id: 'CBX5'},
{value: 'CBX6', id: 'CBX6'},
{value: 'CBX7', id: 'CBX7'},
{value: 'CBX8', id: 'CBX8'},
{value: 'CBY1', id: 'CBY1'},
{value: 'CBY1P1', id: 'CBY1P1'},
{value: 'CBY2', id: 'CBY2'},
{value: 'CBY3', id: 'CBY3'},
{value: 'CC2D1A', id: 'CC2D1A'},
{value: 'CC2D1B', id: 'CC2D1B'},
{value: 'CC2D2A', id: 'CC2D2A'},
{value: 'CC2D2B', id: 'CC2D2B'},
{value: 'CCAR1', id: 'CCAR1'},
{value: 'CCAR2', id: 'CCAR2'},
{value: 'CCBE1', id: 'CCBE1'},
{value: 'CCDC102A', id: 'CCDC102A'},
{value: 'CCDC102B', id: 'CCDC102B'},
{value: 'CCDC103', id: 'CCDC103'},
{value: 'CCDC105', id: 'CCDC105'},
{value: 'CCDC106', id: 'CCDC106'},
{value: 'CCDC107', id: 'CCDC107'},
{value: 'CCDC110', id: 'CCDC110'},
{value: 'CCDC112', id: 'CCDC112'},
{value: 'CCDC113', id: 'CCDC113'},
{value: 'CCDC115', id: 'CCDC115'},
{value: 'CCDC116', id: 'CCDC116'},
{value: 'CCDC117', id: 'CCDC117'},
{value: 'CCDC12', id: 'CCDC12'},
{value: 'CCDC120', id: 'CCDC120'},
{value: 'CCDC121', id: 'CCDC121'},
{value: 'CCDC122', id: 'CCDC122'},
{value: 'CCDC124', id: 'CCDC124'},
{value: 'CCDC125', id: 'CCDC125'},
{value: 'CCDC126', id: 'CCDC126'},
{value: 'CCDC127', id: 'CCDC127'},
{value: 'CCDC13', id: 'CCDC13'},
{value: 'CCDC13-AS1', id: 'CCDC13-AS1'},
{value: 'CCDC134', id: 'CCDC134'},
{value: 'CCDC136', id: 'CCDC136'},
{value: 'CCDC137', id: 'CCDC137'},
{value: 'CCDC137P', id: 'CCDC137P'},
{value: 'CCDC138', id: 'CCDC138'},
{value: 'CCDC14', id: 'CCDC14'},
{value: 'CCDC140', id: 'CCDC140'},
{value: 'CCDC141', id: 'CCDC141'},
{value: 'CCDC142', id: 'CCDC142'},
{value: 'CCDC144A', id: 'CCDC144A'},
{value: 'CCDC144B', id: 'CCDC144B'},
{value: 'CCDC144CP', id: 'CCDC144CP'},
{value: 'CCDC144NL', id: 'CCDC144NL'},
{value: 'CCDC144NL-AS1', id: 'CCDC144NL-AS1'},
{value: 'CCDC146', id: 'CCDC146'},
{value: 'CCDC148', id: 'CCDC148'},
{value: 'CCDC148-AS1', id: 'CCDC148-AS1'},
{value: 'CCDC149', id: 'CCDC149'},
{value: 'CCDC15', id: 'CCDC15'},
{value: 'CCDC15-DT', id: 'CCDC15-DT'},
{value: 'CCDC150', id: 'CCDC150'},
{value: 'CCDC152', id: 'CCDC152'},
{value: 'CCDC153', id: 'CCDC153'},
{value: 'CCDC154', id: 'CCDC154'},
{value: 'CCDC157', id: 'CCDC157'},
{value: 'CCDC158', id: 'CCDC158'},
{value: 'CCDC159', id: 'CCDC159'},
{value: 'CCDC160', id: 'CCDC160'},
{value: 'CCDC162P', id: 'CCDC162P'},
{value: 'CCDC163', id: 'CCDC163'},
{value: 'CCDC167', id: 'CCDC167'},
{value: 'CCDC168', id: 'CCDC168'},
{value: 'CCDC169', id: 'CCDC169'},
{value: 'CCDC169-SOHLH2', id: 'CCDC169-SOHLH2'},
{value: 'CCDC17', id: 'CCDC17'},
{value: 'CCDC170', id: 'CCDC170'},
{value: 'CCDC171', id: 'CCDC171'},
{value: 'CCDC172', id: 'CCDC172'},
{value: 'CCDC173', id: 'CCDC173'},
{value: 'CCDC174', id: 'CCDC174'},
{value: 'CCDC175', id: 'CCDC175'},
{value: 'CCDC177', id: 'CCDC177'},
{value: 'CCDC178', id: 'CCDC178'},
{value: 'CCDC179', id: 'CCDC179'},
{value: 'CCDC18', id: 'CCDC18'},
{value: 'CCDC18-AS1', id: 'CCDC18-AS1'},
{value: 'CCDC180', id: 'CCDC180'},
{value: 'CCDC181', id: 'CCDC181'},
{value: 'CCDC182', id: 'CCDC182'},
{value: 'CCDC183', id: 'CCDC183'},
{value: 'CCDC183-AS1', id: 'CCDC183-AS1'},
{value: 'CCDC184', id: 'CCDC184'},
{value: 'CCDC185', id: 'CCDC185'},
{value: 'CCDC186', id: 'CCDC186'},
{value: 'CCDC187', id: 'CCDC187'},
{value: 'CCDC188', id: 'CCDC188'},
{value: 'CCDC189', id: 'CCDC189'},
{value: 'CCDC190', id: 'CCDC190'},
{value: 'CCDC191', id: 'CCDC191'},
{value: 'CCDC192', id: 'CCDC192'},
{value: 'CCDC194', id: 'CCDC194'},
{value: 'CCDC195', id: 'CCDC195'},
{value: 'CCDC196', id: 'CCDC196'},
{value: 'CCDC197', id: 'CCDC197'},
{value: 'CCDC198', id: 'CCDC198'},
{value: 'CCDC200', id: 'CCDC200'},
{value: 'CCDC201', id: 'CCDC201'},
{value: 'CCDC22', id: 'CCDC22'},
{value: 'CCDC24', id: 'CCDC24'},
{value: 'CCDC25', id: 'CCDC25'},
{value: 'CCDC26', id: 'CCDC26'},
{value: 'CCDC27', id: 'CCDC27'},
{value: 'CCDC28A', id: 'CCDC28A'},
{value: 'CCDC28A-AS1', id: 'CCDC28A-AS1'},
{value: 'CCDC28B', id: 'CCDC28B'},
{value: 'CCDC3', id: 'CCDC3'},
{value: 'CCDC30', id: 'CCDC30'},
{value: 'CCDC32', id: 'CCDC32'},
{value: 'CCDC33', id: 'CCDC33'},
{value: 'CCDC34', id: 'CCDC34'},
{value: 'CCDC38', id: 'CCDC38'},
{value: 'CCDC39', id: 'CCDC39'},
{value: 'CCDC39-AS1', id: 'CCDC39-AS1'},
{value: 'CCDC40', id: 'CCDC40'},
{value: 'CCDC42', id: 'CCDC42'},
{value: 'CCDC43', id: 'CCDC43'},
{value: 'CCDC47', id: 'CCDC47'},
{value: 'CCDC50', id: 'CCDC50'},
{value: 'CCDC51', id: 'CCDC51'},
{value: 'CCDC57', id: 'CCDC57'},
{value: 'CCDC59', id: 'CCDC59'},
{value: 'CCDC6', id: 'CCDC6'},
{value: 'CCDC60', id: 'CCDC60'},
{value: 'CCDC61', id: 'CCDC61'},
{value: 'CCDC62', id: 'CCDC62'},
{value: 'CCDC63', id: 'CCDC63'},
{value: 'CCDC65', id: 'CCDC65'},
{value: 'CCDC66', id: 'CCDC66'},
{value: 'CCDC68', id: 'CCDC68'},
{value: 'CCDC69', id: 'CCDC69'},
{value: 'CCDC7', id: 'CCDC7'},
{value: 'CCDC70', id: 'CCDC70'},
{value: 'CCDC71', id: 'CCDC71'},
{value: 'CCDC71L', id: 'CCDC71L'},
{value: 'CCDC73', id: 'CCDC73'},
{value: 'CCDC74A', id: 'CCDC74A'},
{value: 'CCDC74B', id: 'CCDC74B'},
{value: 'CCDC74BP1', id: 'CCDC74BP1'},
{value: 'CCDC77', id: 'CCDC77'},
{value: 'CCDC78', id: 'CCDC78'},
{value: 'CCDC8', id: 'CCDC8'},
{value: 'CCDC80', id: 'CCDC80'},
{value: 'CCDC81', id: 'CCDC81'},
{value: 'CCDC82', id: 'CCDC82'},
{value: 'CCDC83', id: 'CCDC83'},
{value: 'CCDC85A', id: 'CCDC85A'},
{value: 'CCDC85B', id: 'CCDC85B'},
{value: 'CCDC85C', id: 'CCDC85C'},
{value: 'CCDC86', id: 'CCDC86'},
{value: 'CCDC87', id: 'CCDC87'},
{value: 'CCDC88A', id: 'CCDC88A'},
{value: 'CCDC88B', id: 'CCDC88B'},
{value: 'CCDC88C', id: 'CCDC88C'},
{value: 'CCDC88C-DT', id: 'CCDC88C-DT'},
{value: 'CCDC89', id: 'CCDC89'},
{value: 'CCDC9', id: 'CCDC9'},
{value: 'CCDC90B', id: 'CCDC90B'},
{value: 'CCDC90B-AS1', id: 'CCDC90B-AS1'},
{value: 'CCDC91', id: 'CCDC91'},
{value: 'CCDC92', id: 'CCDC92'},
{value: 'CCDC92B', id: 'CCDC92B'},
{value: 'CCDC93', id: 'CCDC93'},
{value: 'CCDC96', id: 'CCDC96'},
{value: 'CCDC97', id: 'CCDC97'},
{value: 'CCDC9B', id: 'CCDC9B'},
{value: 'CCER1', id: 'CCER1'},
{value: 'CCER2', id: 'CCER2'},
{value: 'CCHCR1', id: 'CCHCR1'},
{value: 'CCIN', id: 'CCIN'},
{value: 'CCK', id: 'CCK'},
{value: 'CCKAR', id: 'CCKAR'},
{value: 'CCKBR', id: 'CCKBR'},
{value: 'CCL1', id: 'CCL1'},
{value: 'CCL11', id: 'CCL11'},
{value: 'CCL13', id: 'CCL13'},
{value: 'CCL14', id: 'CCL14'},
{value: 'CCL15', id: 'CCL15'},
{value: 'CCL15-CCL14', id: 'CCL15-CCL14'},
{value: 'CCL16', id: 'CCL16'},
{value: 'CCL17', id: 'CCL17'},
{value: 'CCL18', id: 'CCL18'},
{value: 'CCL19', id: 'CCL19'},
{value: 'CCL2', id: 'CCL2'},
{value: 'CCL20', id: 'CCL20'},
{value: 'CCL21', id: 'CCL21'},
{value: 'CCL22', id: 'CCL22'},
{value: 'CCL23', id: 'CCL23'},
{value: 'CCL24', id: 'CCL24'},
{value: 'CCL25', id: 'CCL25'},
{value: 'CCL26', id: 'CCL26'},
{value: 'CCL27', id: 'CCL27'},
{value: 'CCL28', id: 'CCL28'},
{value: 'CCL3', id: 'CCL3'},
{value: 'CCL3-AS1', id: 'CCL3-AS1'},
{value: 'CCL3L3', id: 'CCL3L3'},
{value: 'CCL4', id: 'CCL4'},
{value: 'CCL4L2', id: 'CCL4L2'},
{value: 'CCL5', id: 'CCL5'},
{value: 'CCL7', id: 'CCL7'},
{value: 'CCL8', id: 'CCL8'},
{value: 'CCM2', id: 'CCM2'},
{value: 'CCM2L', id: 'CCM2L'},
{value: 'CCN1', id: 'CCN1'},
{value: 'CCN2', id: 'CCN2'},
{value: 'CCN3', id: 'CCN3'},
{value: 'CCN4', id: 'CCN4'},
{value: 'CCN5', id: 'CCN5'},
{value: 'CCN6', id: 'CCN6'},
{value: 'CCNA1', id: 'CCNA1'},
{value: 'CCNA2', id: 'CCNA2'},
{value: 'CCNB1', id: 'CCNB1'},
{value: 'CCNB1IP1', id: 'CCNB1IP1'},
{value: 'CCNB1IP1P1', id: 'CCNB1IP1P1'},
{value: 'CCNB1IP1P2', id: 'CCNB1IP1P2'},
{value: 'CCNB2', id: 'CCNB2'},
{value: 'CCNB3', id: 'CCNB3'},
{value: 'CCNB3P1', id: 'CCNB3P1'},
{value: 'CCNC', id: 'CCNC'},
{value: 'CCND1', id: 'CCND1'},
{value: 'CCND2', id: 'CCND2'},
{value: 'CCND2-AS1', id: 'CCND2-AS1'},
{value: 'CCND2P1', id: 'CCND2P1'},
{value: 'CCND3', id: 'CCND3'},
{value: 'CCND3P1', id: 'CCND3P1'},
{value: 'CCND3P2', id: 'CCND3P2'},
{value: 'CCNDBP1', id: 'CCNDBP1'},
{value: 'CCNE1', id: 'CCNE1'},
{value: 'CCNE2', id: 'CCNE2'},
{value: 'CCNF', id: 'CCNF'},
{value: 'CCNG1', id: 'CCNG1'},
{value: 'CCNG1P1', id: 'CCNG1P1'},
{value: 'CCNG2', id: 'CCNG2'},
{value: 'CCNG2P1', id: 'CCNG2P1'},
{value: 'CCNH', id: 'CCNH'},
{value: 'CCNI', id: 'CCNI'},
{value: 'CCNI2', id: 'CCNI2'},
{value: 'CCNJ', id: 'CCNJ'},
{value: 'CCNJL', id: 'CCNJL'},
{value: 'CCNJP1', id: 'CCNJP1'},
{value: 'CCNJP2', id: 'CCNJP2'},
{value: 'CCNK', id: 'CCNK'},
{value: 'CCNL1', id: 'CCNL1'},
{value: 'CCNL2', id: 'CCNL2'},
{value: 'CCNL2P1', id: 'CCNL2P1'},
{value: 'CCNO', id: 'CCNO'},
{value: 'CCNO-DT', id: 'CCNO-DT'},
{value: 'CCNP', id: 'CCNP'},
{value: 'CCNQ', id: 'CCNQ'},
{value: 'CCNQP1', id: 'CCNQP1'},
{value: 'CCNQP3', id: 'CCNQP3'},
{value: 'CCNT1', id: 'CCNT1'},
{value: 'CCNT2', id: 'CCNT2'},
{value: 'CCNT2-AS1', id: 'CCNT2-AS1'},
{value: 'CCNT2P1', id: 'CCNT2P1'},
{value: 'CCNY', id: 'CCNY'},
{value: 'CCNY-AS1', id: 'CCNY-AS1'},
{value: 'CCNYL1', id: 'CCNYL1'},
{value: 'CCNYL2', id: 'CCNYL2'},
{value: 'CCNYL3', id: 'CCNYL3'},
{value: 'CCNYL4', id: 'CCNYL4'},
{value: 'CCNYL6', id: 'CCNYL6'},
{value: 'CCP110', id: 'CCP110'},
{value: 'CCPG1', id: 'CCPG1'},
{value: 'CCR1', id: 'CCR1'},
{value: 'CCR10', id: 'CCR10'},
{value: 'CCR12P', id: 'CCR12P'},
{value: 'CCR2', id: 'CCR2'},
{value: 'CCR3', id: 'CCR3'},
{value: 'CCR4', id: 'CCR4'},
{value: 'CCR5', id: 'CCR5'},
{value: 'CCR5AS', id: 'CCR5AS'},
{value: 'CCR6', id: 'CCR6'},
{value: 'CCR7', id: 'CCR7'},
{value: 'CCR8', id: 'CCR8'},
{value: 'CCR9', id: 'CCR9'},
{value: 'CCRL2', id: 'CCRL2'},
{value: 'CCS', id: 'CCS'},
{value: 'CCSAP', id: 'CCSAP'},
{value: 'CCSER1', id: 'CCSER1'},
{value: 'CCSER2', id: 'CCSER2'},
{value: 'CCT2', id: 'CCT2'},
{value: 'CCT3', id: 'CCT3'},
{value: 'CCT4', id: 'CCT4'},
{value: 'CCT4P1', id: 'CCT4P1'},
{value: 'CCT5', id: 'CCT5'},
{value: 'CCT5P1', id: 'CCT5P1'},
{value: 'CCT5P2', id: 'CCT5P2'},
{value: 'CCT6A', id: 'CCT6A'},
{value: 'CCT6B', id: 'CCT6B'},
{value: 'CCT6P1', id: 'CCT6P1'},
{value: 'CCT6P2', id: 'CCT6P2'},
{value: 'CCT6P3', id: 'CCT6P3'},
{value: 'CCT6P4', id: 'CCT6P4'},
{value: 'CCT7', id: 'CCT7'},
{value: 'CCT7P2', id: 'CCT7P2'},
{value: 'CCT8', id: 'CCT8'},
{value: 'CCT8L1P', id: 'CCT8L1P'},
{value: 'CCT8L2', id: 'CCT8L2'},
{value: 'CCT8P1', id: 'CCT8P1'},
{value: 'CCZ1', id: 'CCZ1'},
{value: 'CCZ1B', id: 'CCZ1B'},
{value: 'CD101', id: 'CD101'},
{value: 'CD109', id: 'CD109'},
{value: 'CD14', id: 'CD14'},
{value: 'CD151', id: 'CD151'},
{value: 'CD160', id: 'CD160'},
{value: 'CD163', id: 'CD163'},
{value: 'CD163L1', id: 'CD163L1'},
{value: 'CD164', id: 'CD164'},
{value: 'CD164L2', id: 'CD164L2'},
{value: 'CD177', id: 'CD177'},
{value: 'CD177P1', id: 'CD177P1'},
{value: 'CD180', id: 'CD180'},
{value: 'CD19', id: 'CD19'},
{value: 'CD1A', id: 'CD1A'},
{value: 'CD1B', id: 'CD1B'},
{value: 'CD1C', id: 'CD1C'},
{value: 'CD1D', id: 'CD1D'},
{value: 'CD1E', id: 'CD1E'},
{value: 'CD2', id: 'CD2'},
{value: 'CD200', id: 'CD200'},
{value: 'CD200R1', id: 'CD200R1'},
{value: 'CD200R1L', id: 'CD200R1L'},
{value: 'CD200R1L-AS1', id: 'CD200R1L-AS1'},
{value: 'CD207', id: 'CD207'},
{value: 'CD209', id: 'CD209'},
{value: 'CD22', id: 'CD22'},
{value: 'CD226', id: 'CD226'},
{value: 'CD24', id: 'CD24'},
{value: 'CD244', id: 'CD244'},
{value: 'CD247', id: 'CD247'},
{value: 'CD248', id: 'CD248'},
{value: 'CD24P1', id: 'CD24P1'},
{value: 'CD24P2', id: 'CD24P2'},
{value: 'CD27', id: 'CD27'},
{value: 'CD27-AS1', id: 'CD27-AS1'},
{value: 'CD274', id: 'CD274'},
{value: 'CD276', id: 'CD276'},
{value: 'CD28', id: 'CD28'},
{value: 'CD2AP', id: 'CD2AP'},
{value: 'CD2AP-DT', id: 'CD2AP-DT'},
{value: 'CD2BP2', id: 'CD2BP2'},
{value: 'CD2BP2-DT', id: 'CD2BP2-DT'},
{value: 'CD300A', id: 'CD300A'},
{value: 'CD300C', id: 'CD300C'},
{value: 'CD300E', id: 'CD300E'},
{value: 'CD300H', id: 'CD300H'},
{value: 'CD300LB', id: 'CD300LB'},
{value: 'CD300LD', id: 'CD300LD'},
{value: 'CD300LD-AS1', id: 'CD300LD-AS1'},
{value: 'CD300LF', id: 'CD300LF'},
{value: 'CD300LG', id: 'CD300LG'},
{value: 'CD302', id: 'CD302'},
{value: 'CD320', id: 'CD320'},
{value: 'CD33', id: 'CD33'},
{value: 'CD34', id: 'CD34'},
{value: 'CD36', id: 'CD36'},
{value: 'CD37', id: 'CD37'},
{value: 'CD38', id: 'CD38'},
{value: 'CD3D', id: 'CD3D'},
{value: 'CD3E', id: 'CD3E'},
{value: 'CD3G', id: 'CD3G'},
{value: 'CD4', id: 'CD4'},
{value: 'CD40', id: 'CD40'},
{value: 'CD40LG', id: 'CD40LG'},
{value: 'CD44', id: 'CD44'},
{value: 'CD44-AS1', id: 'CD44-AS1'},
{value: 'CD46', id: 'CD46'},
{value: 'CD47', id: 'CD47'},
{value: 'CD48', id: 'CD48'},
{value: 'CD5', id: 'CD5'},
{value: 'CD52', id: 'CD52'},
{value: 'CD53', id: 'CD53'},
{value: 'CD55', id: 'CD55'},
{value: 'CD58', id: 'CD58'},
{value: 'CD59', id: 'CD59'},
{value: 'CD5L', id: 'CD5L'},
{value: 'CD6', id: 'CD6'},
{value: 'CD63', id: 'CD63'},
{value: 'CD63-AS1', id: 'CD63-AS1'},
{value: 'CD68', id: 'CD68'},
{value: 'CD69', id: 'CD69'},
{value: 'CD7', id: 'CD7'},
{value: 'CD70', id: 'CD70'},
{value: 'CD72', id: 'CD72'},
{value: 'CD74', id: 'CD74'},
{value: 'CD79A', id: 'CD79A'},
{value: 'CD79B', id: 'CD79B'},
{value: 'CD80', id: 'CD80'},
{value: 'CD81', id: 'CD81'},
{value: 'CD81-AS1', id: 'CD81-AS1'},
{value: 'CD82', id: 'CD82'},
{value: 'CD83', id: 'CD83'},
{value: 'CD83P1', id: 'CD83P1'},
{value: 'CD84', id: 'CD84'},
{value: 'CD86', id: 'CD86'},
{value: 'CD8A', id: 'CD8A'},
{value: 'CD8B', id: 'CD8B'},
{value: 'CD8B2', id: 'CD8B2'},
{value: 'CD9', id: 'CD9'},
{value: 'CD93', id: 'CD93'},
{value: 'CD96', id: 'CD96'},
{value: 'CD99', id: 'CD99'},
{value: 'CD99L2', id: 'CD99L2'},
{value: 'CD99P1', id: 'CD99P1'},
{value: 'CDA', id: 'CDA'},
{value: 'CDADC1', id: 'CDADC1'},
{value: 'CDAN1', id: 'CDAN1'},
{value: 'CDC123', id: 'CDC123'},
{value: 'CDC14A', id: 'CDC14A'},
{value: 'CDC14B', id: 'CDC14B'},
{value: 'CDC14C', id: 'CDC14C'},
{value: 'CDC16', id: 'CDC16'},
{value: 'CDC20', id: 'CDC20'},
{value: 'CDC20B', id: 'CDC20B'},
{value: 'CDC20P1', id: 'CDC20P1'},
{value: 'CDC23', id: 'CDC23'},
{value: 'CDC25A', id: 'CDC25A'},
{value: 'CDC25B', id: 'CDC25B'},
{value: 'CDC25C', id: 'CDC25C'},
{value: 'CDC26', id: 'CDC26'},
{value: 'CDC27', id: 'CDC27'},
{value: 'CDC27P1', id: 'CDC27P1'},
{value: 'CDC27P3', id: 'CDC27P3'},
{value: 'CDC34', id: 'CDC34'},
{value: 'CDC37', id: 'CDC37'},
{value: 'CDC37L1', id: 'CDC37L1'},
{value: 'CDC37L1-DT', id: 'CDC37L1-DT'},
{value: 'CDC37P1', id: 'CDC37P1'},
{value: 'CDC37P2', id: 'CDC37P2'},
{value: 'CDC40', id: 'CDC40'},
{value: 'CDC42', id: 'CDC42'},
{value: 'CDC42-AS1', id: 'CDC42-AS1'},
{value: 'CDC42BPA', id: 'CDC42BPA'},
{value: 'CDC42BPB', id: 'CDC42BPB'},
{value: 'CDC42BPG', id: 'CDC42BPG'},
{value: 'CDC42EP1', id: 'CDC42EP1'},
{value: 'CDC42EP2', id: 'CDC42EP2'},
{value: 'CDC42EP3', id: 'CDC42EP3'},
{value: 'CDC42EP4', id: 'CDC42EP4'},
{value: 'CDC42EP5', id: 'CDC42EP5'},
{value: 'CDC42P2', id: 'CDC42P2'},
{value: 'CDC42P3', id: 'CDC42P3'},
{value: 'CDC42P4', id: 'CDC42P4'},
{value: 'CDC42P6', id: 'CDC42P6'},
{value: 'CDC42SE1', id: 'CDC42SE1'},
{value: 'CDC42SE2', id: 'CDC42SE2'},
{value: 'CDC45', id: 'CDC45'},
{value: 'CDC5L', id: 'CDC5L'},
{value: 'CDC6', id: 'CDC6'},
{value: 'CDC7', id: 'CDC7'},
{value: 'CDC73', id: 'CDC73'},
{value: 'CDCA2', id: 'CDCA2'},
{value: 'CDCA3', id: 'CDCA3'},
{value: 'CDCA4', id: 'CDCA4'},
{value: 'CDCA4P1', id: 'CDCA4P1'},
{value: 'CDCA4P2', id: 'CDCA4P2'},
{value: 'CDCA4P4', id: 'CDCA4P4'},
{value: 'CDCA5', id: 'CDCA5'},
{value: 'CDCA7', id: 'CDCA7'},
{value: 'CDCA7L', id: 'CDCA7L'},
{value: 'CDCA8', id: 'CDCA8'},
{value: 'CDCP1', id: 'CDCP1'},
{value: 'CDCP2', id: 'CDCP2'},
{value: 'CDH1', id: 'CDH1'},
{value: 'CDH10', id: 'CDH10'},
{value: 'CDH11', id: 'CDH11'},
{value: 'CDH12', id: 'CDH12'},
{value: 'CDH12P1', id: 'CDH12P1'},
{value: 'CDH12P2', id: 'CDH12P2'},
{value: 'CDH12P3', id: 'CDH12P3'},
{value: 'CDH13', id: 'CDH13'},
{value: 'CDH15', id: 'CDH15'},
{value: 'CDH16', id: 'CDH16'},
{value: 'CDH17', id: 'CDH17'},
{value: 'CDH18', id: 'CDH18'},
{value: 'CDH19', id: 'CDH19'},
{value: 'CDH2', id: 'CDH2'},
{value: 'CDH20', id: 'CDH20'},
{value: 'CDH22', id: 'CDH22'},
{value: 'CDH23', id: 'CDH23'},
{value: 'CDH24', id: 'CDH24'},
{value: 'CDH26', id: 'CDH26'},
{value: 'CDH3', id: 'CDH3'},
{value: 'CDH4', id: 'CDH4'},
{value: 'CDH5', id: 'CDH5'},
{value: 'CDH6', id: 'CDH6'},
{value: 'CDH7', id: 'CDH7'},
{value: 'CDH8', id: 'CDH8'},
{value: 'CDH8-AS1', id: 'CDH8-AS1'},
{value: 'CDH9', id: 'CDH9'},
{value: 'CDHR1', id: 'CDHR1'},
{value: 'CDHR2', id: 'CDHR2'},
{value: 'CDHR3', id: 'CDHR3'},
{value: 'CDHR4', id: 'CDHR4'},
{value: 'CDHR5', id: 'CDHR5'},
{value: 'CDIN1', id: 'CDIN1'},
{value: 'CDIP1', id: 'CDIP1'},
{value: 'CDIPT', id: 'CDIPT'},
{value: 'CDIPTOSP', id: 'CDIPTOSP'},
{value: 'CDK1', id: 'CDK1'},
{value: 'CDK10', id: 'CDK10'},
{value: 'CDK11A', id: 'CDK11A'},
{value: 'CDK11B', id: 'CDK11B'},
{value: 'CDK12', id: 'CDK12'},
{value: 'CDK13', id: 'CDK13'},
{value: 'CDK13-DT', id: 'CDK13-DT'},
{value: 'CDK14', id: 'CDK14'},
{value: 'CDK15', id: 'CDK15'},
{value: 'CDK16', id: 'CDK16'},
{value: 'CDK17', id: 'CDK17'},
{value: 'CDK18', id: 'CDK18'},
{value: 'CDK19', id: 'CDK19'},
{value: 'CDK2', id: 'CDK2'},
{value: 'CDK20', id: 'CDK20'},
{value: 'CDK2AP1', id: 'CDK2AP1'},
{value: 'CDK2AP2', id: 'CDK2AP2'},
{value: 'CDK2AP2P1', id: 'CDK2AP2P1'},
{value: 'CDK2AP2P2', id: 'CDK2AP2P2'},
{value: 'CDK2AP2P3', id: 'CDK2AP2P3'},
{value: 'CDK3', id: 'CDK3'},
{value: 'CDK4', id: 'CDK4'},
{value: 'CDK4P1', id: 'CDK4P1'},
{value: 'CDK5', id: 'CDK5'},
{value: 'CDK5P1', id: 'CDK5P1'},
{value: 'CDK5R1', id: 'CDK5R1'},
{value: 'CDK5R2', id: 'CDK5R2'},
{value: 'CDK5RAP1', id: 'CDK5RAP1'},
{value: 'CDK5RAP2', id: 'CDK5RAP2'},
{value: 'CDK5RAP3', id: 'CDK5RAP3'},
{value: 'CDK6', id: 'CDK6'},
{value: 'CDK6-AS1', id: 'CDK6-AS1'},
{value: 'CDK7', id: 'CDK7'},
{value: 'CDK7P1', id: 'CDK7P1'},
{value: 'CDK8', id: 'CDK8'},
{value: 'CDK8P1', id: 'CDK8P1'},
{value: 'CDK8P2', id: 'CDK8P2'},
{value: 'CDK9', id: 'CDK9'},
{value: 'CDKAL1', id: 'CDKAL1'},
{value: 'CDKL1', id: 'CDKL1'},
{value: 'CDKL2', id: 'CDKL2'},
{value: 'CDKL3', id: 'CDKL3'},
{value: 'CDKL4', id: 'CDKL4'},
{value: 'CDKL5', id: 'CDKL5'},
{value: 'CDKN1A', id: 'CDKN1A'},
{value: 'CDKN1B', id: 'CDKN1B'},
{value: 'CDKN1C', id: 'CDKN1C'},
{value: 'CDKN2A', id: 'CDKN2A'},
{value: 'CDKN2AIP', id: 'CDKN2AIP'},
{value: 'CDKN2AIPNL', id: 'CDKN2AIPNL'},
{value: 'CDKN2AIPNLP1', id: 'CDKN2AIPNLP1'},
{value: 'CDKN2AIPNLP2', id: 'CDKN2AIPNLP2'},
{value: 'CDKN2AIPNLP3', id: 'CDKN2AIPNLP3'},
{value: 'CDKN2B', id: 'CDKN2B'},
{value: 'CDKN2B-AS1', id: 'CDKN2B-AS1'},
{value: 'CDKN2C', id: 'CDKN2C'},
{value: 'CDKN2D', id: 'CDKN2D'},
{value: 'CDKN3', id: 'CDKN3'},
{value: 'CDNF', id: 'CDNF'},
{value: 'CDO1', id: 'CDO1'},
{value: 'CDON', id: 'CDON'},
{value: 'CDPF1', id: 'CDPF1'},
{value: 'CDPF1P1', id: 'CDPF1P1'},
{value: 'CDR1', id: 'CDR1'},
{value: 'CDR2', id: 'CDR2'},
{value: 'CDR2-DT', id: 'CDR2-DT'},
{value: 'CDR2L', id: 'CDR2L'},
{value: 'CDRT1', id: 'CDRT1'},
{value: 'CDRT15', id: 'CDRT15'},
{value: 'CDRT15L2', id: 'CDRT15L2'},
{value: 'CDRT15P1', id: 'CDRT15P1'},
{value: 'CDRT15P11', id: 'CDRT15P11'},
{value: 'CDRT15P12', id: 'CDRT15P12'},
{value: 'CDRT15P2', id: 'CDRT15P2'},
{value: 'CDRT15P3', id: 'CDRT15P3'},
{value: 'CDRT15P4', id: 'CDRT15P4'},
{value: 'CDRT15P5', id: 'CDRT15P5'},
{value: 'CDRT15P6', id: 'CDRT15P6'},
{value: 'CDRT15P9', id: 'CDRT15P9'},
{value: 'CDRT4', id: 'CDRT4'},
{value: 'CDRT7', id: 'CDRT7'},
{value: 'CDRT8', id: 'CDRT8'},
{value: 'CDS1', id: 'CDS1'},
{value: 'CDS2', id: 'CDS2'},
{value: 'CDSN', id: 'CDSN'},
{value: 'CDT1', id: 'CDT1'},
{value: 'CDV3', id: 'CDV3'},
{value: 'CDV3P1', id: 'CDV3P1'},
{value: 'CDX1', id: 'CDX1'},
{value: 'CDX2', id: 'CDX2'},
{value: 'CDYL', id: 'CDYL'},
{value: 'CDYL-AS1', id: 'CDYL-AS1'},
{value: 'CDYL2', id: 'CDYL2'},
{value: 'CEACAM1', id: 'CEACAM1'},
{value: 'CEACAM16-AS1', id: 'CEACAM16-AS1'},
{value: 'CEACAM18', id: 'CEACAM18'},
{value: 'CEACAM19', id: 'CEACAM19'},
{value: 'CEACAM20', id: 'CEACAM20'},
{value: 'CEACAM21', id: 'CEACAM21'},
{value: 'CEACAM22P', id: 'CEACAM22P'},
{value: 'CEACAM3', id: 'CEACAM3'},
{value: 'CEACAM4', id: 'CEACAM4'},
{value: 'CEACAM5', id: 'CEACAM5'},
{value: 'CEACAM6', id: 'CEACAM6'},
{value: 'CEACAM7', id: 'CEACAM7'},
{value: 'CEACAM8', id: 'CEACAM8'},
{value: 'CEACAMP1', id: 'CEACAMP1'},
{value: 'CEACAMP11', id: 'CEACAMP11'},
{value: 'CEACAMP3', id: 'CEACAMP3'},
{value: 'CEACAMP4', id: 'CEACAMP4'},
{value: 'CEACAMP5', id: 'CEACAMP5'},
{value: 'CEACAMP7', id: 'CEACAMP7'},
{value: 'CEBPA', id: 'CEBPA'},
{value: 'CEBPA-DT', id: 'CEBPA-DT'},
{value: 'CEBPB', id: 'CEBPB'},
{value: 'CEBPB-AS1', id: 'CEBPB-AS1'},
{value: 'CEBPD', id: 'CEBPD'},
{value: 'CEBPE', id: 'CEBPE'},
{value: 'CEBPG', id: 'CEBPG'},
{value: 'CEBPZ', id: 'CEBPZ'},
{value: 'CEBPZOS', id: 'CEBPZOS'},
{value: 'CECR2', id: 'CECR2'},
{value: 'CECR3', id: 'CECR3'},
{value: 'CECR7', id: 'CECR7'},
{value: 'CECR9', id: 'CECR9'},
{value: 'CEL', id: 'CEL'},
{value: 'CELA1', id: 'CELA1'},
{value: 'CELA2A', id: 'CELA2A'},
{value: 'CELA2B', id: 'CELA2B'},
{value: 'CELA3A', id: 'CELA3A'},
{value: 'CELA3B', id: 'CELA3B'},
{value: 'CELF1', id: 'CELF1'},
{value: 'CELF2', id: 'CELF2'},
{value: 'CELF2-AS1', id: 'CELF2-AS1'},
{value: 'CELF2-AS2', id: 'CELF2-AS2'},
{value: 'CELF2-DT', id: 'CELF2-DT'},
{value: 'CELF3', id: 'CELF3'},
{value: 'CELF4', id: 'CELF4'},
{value: 'CELF5', id: 'CELF5'},
{value: 'CELF6', id: 'CELF6'},
{value: 'CELP', id: 'CELP'},
{value: 'CELSR1', id: 'CELSR1'},
{value: 'CELSR2', id: 'CELSR2'},
{value: 'CELSR3', id: 'CELSR3'},
{value: 'CEMIP', id: 'CEMIP'},
{value: 'CEMIP2', id: 'CEMIP2'},
{value: 'CEMP1', id: 'CEMP1'},
{value: 'CENATAC', id: 'CENATAC'},
{value: 'CENATAC-DT', id: 'CENATAC-DT'},
{value: 'CEND1', id: 'CEND1'},
{value: 'CEND1P1', id: 'CEND1P1'},
{value: 'CENPA', id: 'CENPA'},
{value: 'CENPB', id: 'CENPB'},
{value: 'CENPBD1', id: 'CENPBD1'},
{value: 'CENPBD1P1', id: 'CENPBD1P1'},
{value: 'CENPC', id: 'CENPC'},
{value: 'CENPCP1', id: 'CENPCP1'},
{value: 'CENPE', id: 'CENPE'},
{value: 'CENPF', id: 'CENPF'},
{value: 'CENPH', id: 'CENPH'},
{value: 'CENPI', id: 'CENPI'},
{value: 'CENPIP1', id: 'CENPIP1'},
{value: 'CENPJ', id: 'CENPJ'},
{value: 'CENPK', id: 'CENPK'},
{value: 'CENPL', id: 'CENPL'},
{value: 'CENPM', id: 'CENPM'},
{value: 'CENPN', id: 'CENPN'},
{value: 'CENPN-AS1', id: 'CENPN-AS1'},
{value: 'CENPNP1', id: 'CENPNP1'},
{value: 'CENPO', id: 'CENPO'},
{value: 'CENPP', id: 'CENPP'},
{value: 'CENPQ', id: 'CENPQ'},
{value: 'CENPS', id: 'CENPS'},
{value: 'CENPS-CORT', id: 'CENPS-CORT'},
{value: 'CENPT', id: 'CENPT'},
{value: 'CENPU', id: 'CENPU'},
{value: 'CENPUP1', id: 'CENPUP1'},
{value: 'CENPUP2', id: 'CENPUP2'},
{value: 'CENPV', id: 'CENPV'},
{value: 'CENPVL1', id: 'CENPVL1'},
{value: 'CENPW', id: 'CENPW'},
{value: 'CENPX', id: 'CENPX'},
{value: 'CEP104', id: 'CEP104'},
{value: 'CEP112', id: 'CEP112'},
{value: 'CEP120', id: 'CEP120'},
{value: 'CEP126', id: 'CEP126'},
{value: 'CEP128', id: 'CEP128'},
{value: 'CEP131', id: 'CEP131'},
{value: 'CEP135', id: 'CEP135'},
{value: 'CEP152', id: 'CEP152'},
{value: 'CEP162', id: 'CEP162'},
{value: 'CEP164', id: 'CEP164'},
{value: 'CEP164P1', id: 'CEP164P1'},
{value: 'CEP170', id: 'CEP170'},
{value: 'CEP170B', id: 'CEP170B'},
{value: 'CEP170P1', id: 'CEP170P1'},
{value: 'CEP19', id: 'CEP19'},
{value: 'CEP192', id: 'CEP192'},
{value: 'CEP192-DT', id: 'CEP192-DT'},
{value: 'CEP19P1', id: 'CEP19P1'},
{value: 'CEP20', id: 'CEP20'},
{value: 'CEP250', id: 'CEP250'},
{value: 'CEP250-AS1', id: 'CEP250-AS1'},
{value: 'CEP290', id: 'CEP290'},
{value: 'CEP295', id: 'CEP295'},
{value: 'CEP295NL', id: 'CEP295NL'},
{value: 'CEP350', id: 'CEP350'},
{value: 'CEP41', id: 'CEP41'},
{value: 'CEP43', id: 'CEP43'},
{value: 'CEP44', id: 'CEP44'},
{value: 'CEP55', id: 'CEP55'},
{value: 'CEP57', id: 'CEP57'},
{value: 'CEP57L1', id: 'CEP57L1'},
{value: 'CEP57L1P1', id: 'CEP57L1P1'},
{value: 'CEP63', id: 'CEP63'},
{value: 'CEP68', id: 'CEP68'},
{value: 'CEP70', id: 'CEP70'},
{value: 'CEP72', id: 'CEP72'},
{value: 'CEP72-DT', id: 'CEP72-DT'},
{value: 'CEP76', id: 'CEP76'},
{value: 'CEP78', id: 'CEP78'},
{value: 'CEP83', id: 'CEP83'},
{value: 'CEP83-DT', id: 'CEP83-DT'},
{value: 'CEP85', id: 'CEP85'},
{value: 'CEP85L', id: 'CEP85L'},
{value: 'CEP89', id: 'CEP89'},
{value: 'CEP95', id: 'CEP95'},
{value: 'CEP97', id: 'CEP97'},
{value: 'CEPT1', id: 'CEPT1'},
{value: 'CER1', id: 'CER1'},
{value: 'CERCAM', id: 'CERCAM'},
{value: 'CERK', id: 'CERK'},
{value: 'CERKL', id: 'CERKL'},
{value: 'CERNA1', id: 'CERNA1'},
{value: 'CERNA2', id: 'CERNA2'},
{value: 'CERNA3', id: 'CERNA3'},
{value: 'CEROX1', id: 'CEROX1'},
{value: 'CERS1', id: 'CERS1'},
{value: 'CERS2', id: 'CERS2'},
{value: 'CERS3', id: 'CERS3'},
{value: 'CERS3-AS1', id: 'CERS3-AS1'},
{value: 'CERS4', id: 'CERS4'},
{value: 'CERS5', id: 'CERS5'},
{value: 'CERS6', id: 'CERS6'},
{value: 'CERS6-AS1', id: 'CERS6-AS1'},
{value: 'CERT1', id: 'CERT1'},
{value: 'CES1', id: 'CES1'},
{value: 'CES1P1', id: 'CES1P1'},
{value: 'CES1P2', id: 'CES1P2'},
{value: 'CES2', id: 'CES2'},
{value: 'CES3', id: 'CES3'},
{value: 'CES4A', id: 'CES4A'},
{value: 'CES5A', id: 'CES5A'},
{value: 'CES5AP1', id: 'CES5AP1'},
{value: 'CETN1', id: 'CETN1'},
{value: 'CETN2', id: 'CETN2'},
{value: 'CETN3', id: 'CETN3'},
{value: 'CETN4P', id: 'CETN4P'},
{value: 'CETP', id: 'CETP'},
{value: 'CFAP100', id: 'CFAP100'},
{value: 'CFAP100-DT', id: 'CFAP100-DT'},
{value: 'CFAP126', id: 'CFAP126'},
{value: 'CFAP157', id: 'CFAP157'},
{value: 'CFAP161', id: 'CFAP161'},
{value: 'CFAP20', id: 'CFAP20'},
{value: 'CFAP206', id: 'CFAP206'},
{value: 'CFAP20DC', id: 'CFAP20DC'},
{value: 'CFAP221', id: 'CFAP221'},
{value: 'CFAP251', id: 'CFAP251'},
{value: 'CFAP298', id: 'CFAP298'},
{value: 'CFAP298-TCP10L', id: 'CFAP298-TCP10L'},
{value: 'CFAP299', id: 'CFAP299'},
{value: 'CFAP300', id: 'CFAP300'},
{value: 'CFAP36', id: 'CFAP36'},
{value: 'CFAP410', id: 'CFAP410'},
{value: 'CFAP418', id: 'CFAP418'},
{value: 'CFAP418-AS1', id: 'CFAP418-AS1'},
{value: 'CFAP43', id: 'CFAP43'},
{value: 'CFAP44', id: 'CFAP44'},
{value: 'CFAP44-AS1', id: 'CFAP44-AS1'},
{value: 'CFAP45', id: 'CFAP45'},
{value: 'CFAP46', id: 'CFAP46'},
{value: 'CFAP52', id: 'CFAP52'},
{value: 'CFAP53', id: 'CFAP53'},
{value: 'CFAP53P1', id: 'CFAP53P1'},
{value: 'CFAP54', id: 'CFAP54'},
{value: 'CFAP57', id: 'CFAP57'},
{value: 'CFAP58', id: 'CFAP58'},
{value: 'CFAP58-DT', id: 'CFAP58-DT'},
{value: 'CFAP61', id: 'CFAP61'},
{value: 'CFAP61-AS1', id: 'CFAP61-AS1'},
{value: 'CFAP65', id: 'CFAP65'},
{value: 'CFAP69', id: 'CFAP69'},
{value: 'CFAP70', id: 'CFAP70'},
{value: 'CFAP73', id: 'CFAP73'},
{value: 'CFAP74', id: 'CFAP74'},
{value: 'CFAP77', id: 'CFAP77'},
{value: 'CFAP91', id: 'CFAP91'},
{value: 'CFAP92', id: 'CFAP92'},
{value: 'CFAP97', id: 'CFAP97'},
{value: 'CFAP97D1', id: 'CFAP97D1'},
{value: 'CFAP97D2', id: 'CFAP97D2'},
{value: 'CFAP99', id: 'CFAP99'},
{value: 'CFB', id: 'CFB'},
{value: 'CFC1', id: 'CFC1'},
{value: 'CFC1B', id: 'CFC1B'},
{value: 'CFD', id: 'CFD'},
{value: 'CFDP1', id: 'CFDP1'},
{value: 'CFH', id: 'CFH'},
{value: 'CFHR1', id: 'CFHR1'},
{value: 'CFHR2', id: 'CFHR2'},
{value: 'CFHR3', id: 'CFHR3'},
{value: 'CFHR4', id: 'CFHR4'},
{value: 'CFHR5', id: 'CFHR5'},
{value: 'CFI', id: 'CFI'},
{value: 'CFL1', id: 'CFL1'},
{value: 'CFL1P1', id: 'CFL1P1'},
{value: 'CFL1P2', id: 'CFL1P2'},
{value: 'CFL1P3', id: 'CFL1P3'},
{value: 'CFL1P4', id: 'CFL1P4'},
{value: 'CFL1P5', id: 'CFL1P5'},
{value: 'CFL1P6', id: 'CFL1P6'},
{value: 'CFL1P7', id: 'CFL1P7'},
{value: 'CFL1P8', id: 'CFL1P8'},
{value: 'CFL2', id: 'CFL2'},
{value: 'CFLAR', id: 'CFLAR'},
{value: 'CFLAR-AS1', id: 'CFLAR-AS1'},
{value: 'CFP', id: 'CFP'},
{value: 'CFTR', id: 'CFTR'},
{value: 'CFTRP2', id: 'CFTRP2'},
{value: 'CFTRP3', id: 'CFTRP3'},
{value: 'CGA', id: 'CGA'},
{value: 'CGAS', id: 'CGAS'},
{value: 'CGB1', id: 'CGB1'},
{value: 'CGB2', id: 'CGB2'},
{value: 'CGB3', id: 'CGB3'},
{value: 'CGB5', id: 'CGB5'},
{value: 'CGB7', id: 'CGB7'},
{value: 'CGB8', id: 'CGB8'},
{value: 'CGGBP1', id: 'CGGBP1'},
{value: 'CGN', id: 'CGN'},
{value: 'CGNL1', id: 'CGNL1'},
{value: 'CGREF1', id: 'CGREF1'},
{value: 'CGRRF1', id: 'CGRRF1'},
{value: 'CH17-340M24.3', id: 'CH17-340M24.3'},
{value: 'CH25H', id: 'CH25H'},
{value: 'CH507-42P11.6', id: 'CH507-42P11.6'},
{value: 'CHAC1', id: 'CHAC1'},
{value: 'CHAC2', id: 'CHAC2'},
{value: 'CHAD', id: 'CHAD'},
{value: 'CHADL', id: 'CHADL'},
{value: 'CHAF1A', id: 'CHAF1A'},
{value: 'CHAF1B', id: 'CHAF1B'},
{value: 'CHAMP1', id: 'CHAMP1'},
{value: 'CHASERR', id: 'CHASERR'},
{value: 'CHAT', id: 'CHAT'},
{value: 'CHCHD1', id: 'CHCHD1'},
{value: 'CHCHD10', id: 'CHCHD10'},
{value: 'CHCHD2', id: 'CHCHD2'},
{value: 'CHCHD2P2', id: 'CHCHD2P2'},
{value: 'CHCHD2P3', id: 'CHCHD2P3'},
{value: 'CHCHD2P5', id: 'CHCHD2P5'},
{value: 'CHCHD2P6', id: 'CHCHD2P6'},
{value: 'CHCHD2P7', id: 'CHCHD2P7'},
{value: 'CHCHD2P8', id: 'CHCHD2P8'},
{value: 'CHCHD2P9', id: 'CHCHD2P9'},
{value: 'CHCHD3', id: 'CHCHD3'},
{value: 'CHCHD3P1', id: 'CHCHD3P1'},
{value: 'CHCHD3P2', id: 'CHCHD3P2'},
{value: 'CHCHD3P3', id: 'CHCHD3P3'},
{value: 'CHCHD4', id: 'CHCHD4'},
{value: 'CHCHD4P2', id: 'CHCHD4P2'},
{value: 'CHCHD4P3', id: 'CHCHD4P3'},
{value: 'CHCHD4P4', id: 'CHCHD4P4'},
{value: 'CHCHD4P5', id: 'CHCHD4P5'},
{value: 'CHCHD5', id: 'CHCHD5'},
{value: 'CHCHD6', id: 'CHCHD6'},
{value: 'CHCHD7', id: 'CHCHD7'},
{value: 'CHD1', id: 'CHD1'},
{value: 'CHD1-DT', id: 'CHD1-DT'},
{value: 'CHD1L', id: 'CHD1L'},
{value: 'CHD2', id: 'CHD2'},
{value: 'CHD3', id: 'CHD3'},
{value: 'CHD4', id: 'CHD4'},
{value: 'CHD5', id: 'CHD5'},
{value: 'CHD6', id: 'CHD6'},
{value: 'CHD7', id: 'CHD7'},
{value: 'CHD8', id: 'CHD8'},
{value: 'CHD9', id: 'CHD9'},
{value: 'CHDH', id: 'CHDH'},
{value: 'CHEK1', id: 'CHEK1'},
{value: 'CHEK2', id: 'CHEK2'},
{value: 'CHEK2P2', id: 'CHEK2P2'},
{value: 'CHEK2P3', id: 'CHEK2P3'},
{value: 'CHEK2P5', id: 'CHEK2P5'},
{value: 'CHERP', id: 'CHERP'},
{value: 'CHFR', id: 'CHFR'},
{value: 'CHGA', id: 'CHGA'},
{value: 'CHGB', id: 'CHGB'},
{value: 'CHI3L1', id: 'CHI3L1'},
{value: 'CHI3L2', id: 'CHI3L2'},
{value: 'CHIA', id: 'CHIA'},
{value: 'CHIAP1', id: 'CHIAP1'},
{value: 'CHIAP2', id: 'CHIAP2'},
{value: 'CHIAP3', id: 'CHIAP3'},
{value: 'CHIC1', id: 'CHIC1'},
{value: 'CHIC2', id: 'CHIC2'},
{value: 'CHID1', id: 'CHID1'},
{value: 'CHIT1', id: 'CHIT1'},
{value: 'CHKA', id: 'CHKA'},
{value: 'CHKA-DT', id: 'CHKA-DT'},
{value: 'CHKB', id: 'CHKB'},
{value: 'CHKB-CPT1B', id: 'CHKB-CPT1B'},
{value: 'CHKB-DT', id: 'CHKB-DT'},
{value: 'CHL1', id: 'CHL1'},
{value: 'CHL1-AS1', id: 'CHL1-AS1'},
{value: 'CHL1-AS2', id: 'CHL1-AS2'},
{value: 'CHM', id: 'CHM'},
{value: 'CHML', id: 'CHML'},
{value: 'CHMP1A', id: 'CHMP1A'},
{value: 'CHMP1B', id: 'CHMP1B'},
{value: 'CHMP1B2P', id: 'CHMP1B2P'},
{value: 'CHMP2A', id: 'CHMP2A'},
{value: 'CHMP2B', id: 'CHMP2B'},
{value: 'CHMP3', id: 'CHMP3'},
{value: 'CHMP4A', id: 'CHMP4A'},
{value: 'CHMP4AP1', id: 'CHMP4AP1'},
{value: 'CHMP4B', id: 'CHMP4B'},
{value: 'CHMP4BP1', id: 'CHMP4BP1'},
{value: 'CHMP4C', id: 'CHMP4C'},
{value: 'CHMP5', id: 'CHMP5'},
{value: 'CHMP6', id: 'CHMP6'},
{value: 'CHMP7', id: 'CHMP7'},
{value: 'CHN1', id: 'CHN1'},
{value: 'CHN2', id: 'CHN2'},
{value: 'CHODL', id: 'CHODL'},
{value: 'CHODL-AS1', id: 'CHODL-AS1'},
{value: 'CHORDC1', id: 'CHORDC1'},
{value: 'CHORDC1P1', id: 'CHORDC1P1'},
{value: 'CHORDC1P3', id: 'CHORDC1P3'},
{value: 'CHORDC1P4', id: 'CHORDC1P4'},
{value: 'CHORDC1P5', id: 'CHORDC1P5'},
{value: 'CHP1', id: 'CHP1'},
{value: 'CHP1P1', id: 'CHP1P1'},
{value: 'CHP1P2', id: 'CHP1P2'},
{value: 'CHP2', id: 'CHP2'},
{value: 'CHPF', id: 'CHPF'},
{value: 'CHPF2', id: 'CHPF2'},
{value: 'CHPT1', id: 'CHPT1'},
{value: 'CHRAC1', id: 'CHRAC1'},
{value: 'CHRD', id: 'CHRD'},
{value: 'CHRDL1', id: 'CHRDL1'},
{value: 'CHRDL2', id: 'CHRDL2'},
{value: 'CHRFAM7A', id: 'CHRFAM7A'},
{value: 'CHRM1', id: 'CHRM1'},
{value: 'CHRM2', id: 'CHRM2'},
{value: 'CHRM3', id: 'CHRM3'},
{value: 'CHRM3-AS1', id: 'CHRM3-AS1'},
{value: 'CHRM3-AS2', id: 'CHRM3-AS2'},
{value: 'CHRM4', id: 'CHRM4'},
{value: 'CHRM5', id: 'CHRM5'},
{value: 'CHRNA1', id: 'CHRNA1'},
{value: 'CHRNA10', id: 'CHRNA10'},
{value: 'CHRNA2', id: 'CHRNA2'},
{value: 'CHRNA3', id: 'CHRNA3'},
{value: 'CHRNA4', id: 'CHRNA4'},
{value: 'CHRNA5', id: 'CHRNA5'},
{value: 'CHRNA6', id: 'CHRNA6'},
{value: 'CHRNA7', id: 'CHRNA7'},
{value: 'CHRNA9', id: 'CHRNA9'},
{value: 'CHRNB1', id: 'CHRNB1'},
{value: 'CHRNB2', id: 'CHRNB2'},
{value: 'CHRNB3', id: 'CHRNB3'},
{value: 'CHRNB4', id: 'CHRNB4'},
{value: 'CHRND', id: 'CHRND'},
{value: 'CHRNE', id: 'CHRNE'},
{value: 'CHRNG', id: 'CHRNG'},
{value: 'CHROMR', id: 'CHROMR'},
{value: 'CHST1', id: 'CHST1'},
{value: 'CHST10', id: 'CHST10'},
{value: 'CHST11', id: 'CHST11'},
{value: 'CHST12', id: 'CHST12'},
{value: 'CHST13', id: 'CHST13'},
{value: 'CHST14', id: 'CHST14'},
{value: 'CHST15', id: 'CHST15'},
{value: 'CHST2', id: 'CHST2'},
{value: 'CHST3', id: 'CHST3'},
{value: 'CHST5', id: 'CHST5'},
{value: 'CHST6', id: 'CHST6'},
{value: 'CHST7', id: 'CHST7'},
{value: 'CHST8', id: 'CHST8'},
{value: 'CHST9', id: 'CHST9'},
{value: 'CHSY1', id: 'CHSY1'},
{value: 'CHSY3', id: 'CHSY3'},
{value: 'CHTF18', id: 'CHTF18'},
{value: 'CHTF8', id: 'CHTF8'},
{value: 'CHTOP', id: 'CHTOP'},
{value: 'CHUK', id: 'CHUK'},
{value: 'CHURC1', id: 'CHURC1'},
{value: 'CHURC1-FNTB', id: 'CHURC1-FNTB'},
{value: 'CIAO1', id: 'CIAO1'},
{value: 'CIAO2A', id: 'CIAO2A'},
{value: 'CIAO2AP1', id: 'CIAO2AP1'},
{value: 'CIAO2AP2', id: 'CIAO2AP2'},
{value: 'CIAO2B', id: 'CIAO2B'},
{value: 'CIAO3', id: 'CIAO3'},
{value: 'CIAPIN1', id: 'CIAPIN1'},
{value: 'CIART', id: 'CIART'},
{value: 'CIB1', id: 'CIB1'},
{value: 'CIB2', id: 'CIB2'},
{value: 'CIB3', id: 'CIB3'},
{value: 'CIB4', id: 'CIB4'},
{value: 'CIBAR1', id: 'CIBAR1'},
{value: 'CIBAR1-DT', id: 'CIBAR1-DT'},
{value: 'CIBAR1P1', id: 'CIBAR1P1'},
{value: 'CIBAR1P2', id: 'CIBAR1P2'},
{value: 'CIBAR2', id: 'CIBAR2'},
{value: 'CIC', id: 'CIC'},
{value: 'CICP11', id: 'CICP11'},
{value: 'CICP12', id: 'CICP12'},
{value: 'CICP13', id: 'CICP13'},
{value: 'CICP14', id: 'CICP14'},
{value: 'CICP15', id: 'CICP15'},
{value: 'CICP16', id: 'CICP16'},
{value: 'CICP17', id: 'CICP17'},
{value: 'CICP18', id: 'CICP18'},
{value: 'CICP19', id: 'CICP19'},
{value: 'CICP20', id: 'CICP20'},
{value: 'CICP22', id: 'CICP22'},
{value: 'CICP23', id: 'CICP23'},
{value: 'CICP24', id: 'CICP24'},
{value: 'CICP25', id: 'CICP25'},
{value: 'CICP26', id: 'CICP26'},
{value: 'CICP27', id: 'CICP27'},
{value: 'CICP28', id: 'CICP28'},
{value: 'CICP3', id: 'CICP3'},
{value: 'CICP4', id: 'CICP4'},
{value: 'CICP5', id: 'CICP5'},
{value: 'CICP6', id: 'CICP6'},
{value: 'CICP8', id: 'CICP8'},
{value: 'CICP9', id: 'CICP9'},
{value: 'CIDEA', id: 'CIDEA'},
{value: 'CIDEB', id: 'CIDEB'},
{value: 'CIDEC', id: 'CIDEC'},
{value: 'CIDECP1', id: 'CIDECP1'},
{value: 'CIITA', id: 'CIITA'},
{value: 'CILK1', id: 'CILK1'},
{value: 'CILP', id: 'CILP'},
{value: 'CILP2', id: 'CILP2'},
{value: 'CINP', id: 'CINP'},
{value: 'CIP2A', id: 'CIP2A'},
{value: 'CIPC', id: 'CIPC'},
{value: 'CIR1', id: 'CIR1'},
{value: 'CIR1P1', id: 'CIR1P1'},
{value: 'CIR1P2', id: 'CIR1P2'},
{value: 'CIR1P3', id: 'CIR1P3'},
{value: 'CIRBP', id: 'CIRBP'},
{value: 'CIRBP-AS1', id: 'CIRBP-AS1'},
{value: 'CISD1', id: 'CISD1'},
{value: 'CISD1P1', id: 'CISD1P1'},
{value: 'CISD2', id: 'CISD2'},
{value: 'CISD3', id: 'CISD3'},
{value: 'CISH', id: 'CISH'},
{value: 'CISTR', id: 'CISTR'},
{value: 'CIT', id: 'CIT'},
{value: 'CITED2', id: 'CITED2'},
{value: 'CITED4', id: 'CITED4'},
{value: 'CIZ1', id: 'CIZ1'},
{value: 'CKAP2', id: 'CKAP2'},
{value: 'CKAP2L', id: 'CKAP2L'},
{value: 'CKAP2LP1', id: 'CKAP2LP1'},
{value: 'CKAP4', id: 'CKAP4'},
{value: 'CKAP5', id: 'CKAP5'},
{value: 'CKB', id: 'CKB'},
{value: 'CKBP1', id: 'CKBP1'},
{value: 'CKLF', id: 'CKLF'},
{value: 'CKLF-CMTM1', id: 'CKLF-CMTM1'},
{value: 'CKM', id: 'CKM'},
{value: 'CKMT1A', id: 'CKMT1A'},
{value: 'CKMT1B', id: 'CKMT1B'},
{value: 'CKMT2', id: 'CKMT2'},
{value: 'CKMT2-AS1', id: 'CKMT2-AS1'},
{value: 'CKS1B', id: 'CKS1B'},
{value: 'CKS2', id: 'CKS2'},
{value: 'CLASP1', id: 'CLASP1'},
{value: 'CLASP1-AS1', id: 'CLASP1-AS1'},
{value: 'CLASP2', id: 'CLASP2'},
{value: 'CLASRP', id: 'CLASRP'},
{value: 'CLBA1', id: 'CLBA1'},
{value: 'CLC', id: 'CLC'},
{value: 'CLCA1', id: 'CLCA1'},
{value: 'CLCA2', id: 'CLCA2'},
{value: 'CLCA4', id: 'CLCA4'},
{value: 'CLCA4-AS1', id: 'CLCA4-AS1'},
{value: 'CLCC1', id: 'CLCC1'},
{value: 'CLCF1', id: 'CLCF1'},
{value: 'CLCN1', id: 'CLCN1'},
{value: 'CLCN2', id: 'CLCN2'},
{value: 'CLCN3', id: 'CLCN3'},
{value: 'CLCN3P1', id: 'CLCN3P1'},
{value: 'CLCN4', id: 'CLCN4'},
{value: 'CLCN5', id: 'CLCN5'},
{value: 'CLCN6', id: 'CLCN6'},
{value: 'CLCN7', id: 'CLCN7'},
{value: 'CLCNKA', id: 'CLCNKA'},
{value: 'CLCNKB', id: 'CLCNKB'},
{value: 'CLCP1', id: 'CLCP1'},
{value: 'CLCP2', id: 'CLCP2'},
{value: 'CLDN1', id: 'CLDN1'},
{value: 'CLDN10', id: 'CLDN10'},
{value: 'CLDN10-AS1', id: 'CLDN10-AS1'},
{value: 'CLDN11', id: 'CLDN11'},
{value: 'CLDN12', id: 'CLDN12'},
{value: 'CLDN14', id: 'CLDN14'},
{value: 'CLDN15', id: 'CLDN15'},
{value: 'CLDN16', id: 'CLDN16'},
{value: 'CLDN17', id: 'CLDN17'},
{value: 'CLDN18', id: 'CLDN18'},
{value: 'CLDN19', id: 'CLDN19'},
{value: 'CLDN2', id: 'CLDN2'},
{value: 'CLDN20', id: 'CLDN20'},
{value: 'CLDN22', id: 'CLDN22'},
{value: 'CLDN23', id: 'CLDN23'},
{value: 'CLDN24', id: 'CLDN24'},
{value: 'CLDN25', id: 'CLDN25'},
{value: 'CLDN3', id: 'CLDN3'},
{value: 'CLDN4', id: 'CLDN4'},
{value: 'CLDN5', id: 'CLDN5'},
{value: 'CLDN6', id: 'CLDN6'},
{value: 'CLDN7', id: 'CLDN7'},
{value: 'CLDN8', id: 'CLDN8'},
{value: 'CLDN9', id: 'CLDN9'},
{value: 'CLDND1', id: 'CLDND1'},
{value: 'CLDND2', id: 'CLDND2'},
{value: 'CLEC10A', id: 'CLEC10A'},
{value: 'CLEC11A', id: 'CLEC11A'},
{value: 'CLEC12A', id: 'CLEC12A'},
{value: 'CLEC12A-AS1', id: 'CLEC12A-AS1'},
{value: 'CLEC12B', id: 'CLEC12B'},
{value: 'CLEC14A', id: 'CLEC14A'},
{value: 'CLEC16A', id: 'CLEC16A'},
{value: 'CLEC17A', id: 'CLEC17A'},
{value: 'CLEC18A', id: 'CLEC18A'},
{value: 'CLEC18B', id: 'CLEC18B'},
{value: 'CLEC18C', id: 'CLEC18C'},
{value: 'CLEC19A', id: 'CLEC19A'},
{value: 'CLEC1A', id: 'CLEC1A'},
{value: 'CLEC1B', id: 'CLEC1B'},
{value: 'CLEC20A', id: 'CLEC20A'},
{value: 'CLEC2A', id: 'CLEC2A'},
{value: 'CLEC2B', id: 'CLEC2B'},
{value: 'CLEC2D', id: 'CLEC2D'},
{value: 'CLEC2L', id: 'CLEC2L'},
{value: 'CLEC3A', id: 'CLEC3A'},
{value: 'CLEC3B', id: 'CLEC3B'},
{value: 'CLEC4A', id: 'CLEC4A'},
{value: 'CLEC4C', id: 'CLEC4C'},
{value: 'CLEC4D', id: 'CLEC4D'},
{value: 'CLEC4E', id: 'CLEC4E'},
{value: 'CLEC4F', id: 'CLEC4F'},
{value: 'CLEC4G', id: 'CLEC4G'},
{value: 'CLEC4GP1', id: 'CLEC4GP1'},
{value: 'CLEC4M', id: 'CLEC4M'},
{value: 'CLEC4OP', id: 'CLEC4OP'},
{value: 'CLEC5A', id: 'CLEC5A'},
{value: 'CLEC6A', id: 'CLEC6A'},
{value: 'CLEC7A', id: 'CLEC7A'},
{value: 'CLEC9A', id: 'CLEC9A'},
{value: 'CLECL1', id: 'CLECL1'},
{value: 'CLGN', id: 'CLGN'},
{value: 'CLHC1', id: 'CLHC1'},
{value: 'CLIC1', id: 'CLIC1'},
{value: 'CLIC1P1', id: 'CLIC1P1'},
{value: 'CLIC2', id: 'CLIC2'},
{value: 'CLIC3', id: 'CLIC3'},
{value: 'CLIC4', id: 'CLIC4'},
{value: 'CLIC4P2', id: 'CLIC4P2'},
{value: 'CLIC5', id: 'CLIC5'},
{value: 'CLIC6', id: 'CLIC6'},
{value: 'CLINT1', id: 'CLINT1'},
{value: 'CLIP1', id: 'CLIP1'},
{value: 'CLIP1-AS1', id: 'CLIP1-AS1'},
{value: 'CLIP2', id: 'CLIP2'},
{value: 'CLIP3', id: 'CLIP3'},
{value: 'CLIP4', id: 'CLIP4'},
{value: 'CLK1', id: 'CLK1'},
{value: 'CLK2', id: 'CLK2'},
{value: 'CLK2P1', id: 'CLK2P1'},
{value: 'CLK3', id: 'CLK3'},
{value: 'CLK3P2', id: 'CLK3P2'},
{value: 'CLK4', id: 'CLK4'},
{value: 'CLLU1', id: 'CLLU1'},
{value: 'CLLU1-AS1', id: 'CLLU1-AS1'},
{value: 'CLMAT3', id: 'CLMAT3'},
{value: 'CLMN', id: 'CLMN'},
{value: 'CLMP', id: 'CLMP'},
{value: 'CLN3', id: 'CLN3'},
{value: 'CLN5', id: 'CLN5'},
{value: 'CLN6', id: 'CLN6'},
{value: 'CLN8', id: 'CLN8'},
{value: 'CLN8-AS1', id: 'CLN8-AS1'},
{value: 'CLNK', id: 'CLNK'},
{value: 'CLNS1A', id: 'CLNS1A'},
{value: 'CLNS1AP1', id: 'CLNS1AP1'},
{value: 'CLOCK', id: 'CLOCK'},
{value: 'CLP1', id: 'CLP1'},
{value: 'CLPB', id: 'CLPB'},
{value: 'CLPP', id: 'CLPP'},
{value: 'CLPS', id: 'CLPS'},
{value: 'CLPSL1', id: 'CLPSL1'},
{value: 'CLPSL2', id: 'CLPSL2'},
{value: 'CLPTM1', id: 'CLPTM1'},
{value: 'CLPTM1L', id: 'CLPTM1L'},
{value: 'CLPTM1LP1', id: 'CLPTM1LP1'},
{value: 'CLPX', id: 'CLPX'},
{value: 'CLRN1-AS1', id: 'CLRN1-AS1'},
{value: 'CLRN2', id: 'CLRN2'},
{value: 'CLRN3', id: 'CLRN3'},
{value: 'CLSPN', id: 'CLSPN'},
{value: 'CLSTN1', id: 'CLSTN1'},
{value: 'CLSTN2', id: 'CLSTN2'},
{value: 'CLSTN3', id: 'CLSTN3'},
{value: 'CLTA', id: 'CLTA'},
{value: 'CLTB', id: 'CLTB'},
{value: 'CLTC', id: 'CLTC'},
{value: 'CLTCL1', id: 'CLTCL1'},
{value: 'CLU', id: 'CLU'},
{value: 'CLUAP1', id: 'CLUAP1'},
{value: 'CLUH', id: 'CLUH'},
{value: 'CLUHP3', id: 'CLUHP3'},
{value: 'CLUHP4', id: 'CLUHP4'},
{value: 'CLUHP5', id: 'CLUHP5'},
{value: 'CLUHP6', id: 'CLUHP6'},
{value: 'CLUHP8', id: 'CLUHP8'},
{value: 'CLUL1', id: 'CLUL1'},
{value: 'CLVS1', id: 'CLVS1'},
{value: 'CLVS2', id: 'CLVS2'},
{value: 'CLYBL', id: 'CLYBL'},
{value: 'CLYBL-AS2', id: 'CLYBL-AS2'},
{value: 'CMA1', id: 'CMA1'},
{value: 'CMAHP', id: 'CMAHP'},
{value: 'CMAS', id: 'CMAS'},
{value: 'CMBL', id: 'CMBL'},
{value: 'CMC1', id: 'CMC1'},
{value: 'CMC2', id: 'CMC2'},
{value: 'CMC4', id: 'CMC4'},
{value: 'CMIP', id: 'CMIP'},
{value: 'CMKLR1', id: 'CMKLR1'},
{value: 'CMKLR2', id: 'CMKLR2'},
{value: 'CMPK1', id: 'CMPK1'},
{value: 'CMPK2', id: 'CMPK2'},
{value: 'CMSS1', id: 'CMSS1'},
{value: 'CMTM1', id: 'CMTM1'},
{value: 'CMTM2', id: 'CMTM2'},
{value: 'CMTM3', id: 'CMTM3'},
{value: 'CMTM4', id: 'CMTM4'},
{value: 'CMTM5', id: 'CMTM5'},
{value: 'CMTM6', id: 'CMTM6'},
{value: 'CMTM7', id: 'CMTM7'},
{value: 'CMTM8', id: 'CMTM8'},
{value: 'CMTR1', id: 'CMTR1'},
{value: 'CMTR2', id: 'CMTR2'},
{value: 'CMYA5', id: 'CMYA5'},
{value: 'CNBD1', id: 'CNBD1'},
{value: 'CNBD2', id: 'CNBD2'},
{value: 'CNBP', id: 'CNBP'},
{value: 'CNDP1', id: 'CNDP1'},
{value: 'CNDP2', id: 'CNDP2'},
{value: 'CNEP1R1', id: 'CNEP1R1'},
{value: 'CNFN', id: 'CNFN'},
{value: 'CNGA1', id: 'CNGA1'},
{value: 'CNGA3', id: 'CNGA3'},
{value: 'CNGA4', id: 'CNGA4'},
{value: 'CNGB1', id: 'CNGB1'},
{value: 'CNGB3', id: 'CNGB3'},
{value: 'CNIH1', id: 'CNIH1'},
{value: 'CNIH2', id: 'CNIH2'},
{value: 'CNIH3', id: 'CNIH3'},
{value: 'CNIH3-AS1', id: 'CNIH3-AS1'},
{value: 'CNIH3-AS2', id: 'CNIH3-AS2'},
{value: 'CNIH4', id: 'CNIH4'},
{value: 'CNIPF', id: 'CNIPF'},
{value: 'CNKSR1', id: 'CNKSR1'},
{value: 'CNKSR2', id: 'CNKSR2'},
{value: 'CNKSR3', id: 'CNKSR3'},
{value: 'CNMD', id: 'CNMD'},
{value: 'CNN1', id: 'CNN1'},
{value: 'CNN2', id: 'CNN2'},
{value: 'CNN2P1', id: 'CNN2P1'},
{value: 'CNN2P10', id: 'CNN2P10'},
{value: 'CNN2P11', id: 'CNN2P11'},
{value: 'CNN2P12', id: 'CNN2P12'},
{value: 'CNN2P3', id: 'CNN2P3'},
{value: 'CNN2P4', id: 'CNN2P4'},
{value: 'CNN2P6', id: 'CNN2P6'},
{value: 'CNN2P7', id: 'CNN2P7'},
{value: 'CNN2P8', id: 'CNN2P8'},
{value: 'CNN2P9', id: 'CNN2P9'},
{value: 'CNN3', id: 'CNN3'},
{value: 'CNN3-DT', id: 'CNN3-DT'},
{value: 'CNNM1', id: 'CNNM1'},
{value: 'CNNM2', id: 'CNNM2'},
{value: 'CNNM3', id: 'CNNM3'},
{value: 'CNNM3-DT', id: 'CNNM3-DT'},
{value: 'CNNM4', id: 'CNNM4'},
{value: 'CNOT1', id: 'CNOT1'},
{value: 'CNOT10', id: 'CNOT10'},
{value: 'CNOT11', id: 'CNOT11'},
{value: 'CNOT2', id: 'CNOT2'},
{value: 'CNOT3', id: 'CNOT3'},
{value: 'CNOT4', id: 'CNOT4'},
{value: 'CNOT4P1', id: 'CNOT4P1'},
{value: 'CNOT6', id: 'CNOT6'},
{value: 'CNOT6L', id: 'CNOT6L'},
{value: 'CNOT6LP1', id: 'CNOT6LP1'},
{value: 'CNOT7', id: 'CNOT7'},
{value: 'CNOT7P2', id: 'CNOT7P2'},
{value: 'CNOT8', id: 'CNOT8'},
{value: 'CNOT9', id: 'CNOT9'},
{value: 'CNP', id: 'CNP'},
{value: 'CNPPD1', id: 'CNPPD1'},
{value: 'CNPY1', id: 'CNPY1'},
{value: 'CNPY2', id: 'CNPY2'},
{value: 'CNPY2-AS1', id: 'CNPY2-AS1'},
{value: 'CNPY3', id: 'CNPY3'},
{value: 'CNPY4', id: 'CNPY4'},
{value: 'CNR1', id: 'CNR1'},
{value: 'CNR2', id: 'CNR2'},
{value: 'CNRIP1', id: 'CNRIP1'},
{value: 'CNST', id: 'CNST'},
{value: 'CNTD1', id: 'CNTD1'},
{value: 'CNTF', id: 'CNTF'},
{value: 'CNTFR', id: 'CNTFR'},
{value: 'CNTFR-AS1', id: 'CNTFR-AS1'},
{value: 'CNTLN', id: 'CNTLN'},
{value: 'CNTN1', id: 'CNTN1'},
{value: 'CNTN2', id: 'CNTN2'},
{value: 'CNTN3', id: 'CNTN3'},
{value: 'CNTN4', id: 'CNTN4'},
{value: 'CNTN4-AS1', id: 'CNTN4-AS1'},
{value: 'CNTN5', id: 'CNTN5'},
{value: 'CNTN6', id: 'CNTN6'},
{value: 'CNTNAP1', id: 'CNTNAP1'},
{value: 'CNTNAP2', id: 'CNTNAP2'},
{value: 'CNTNAP3', id: 'CNTNAP3'},
{value: 'CNTNAP3B', id: 'CNTNAP3B'},
{value: 'CNTNAP3C', id: 'CNTNAP3C'},
{value: 'CNTNAP3P1', id: 'CNTNAP3P1'},
{value: 'CNTNAP3P2', id: 'CNTNAP3P2'},
{value: 'CNTNAP3P5', id: 'CNTNAP3P5'},
{value: 'CNTNAP4', id: 'CNTNAP4'},
{value: 'CNTNAP5', id: 'CNTNAP5'},
{value: 'CNTRL', id: 'CNTRL'},
{value: 'CNTROB', id: 'CNTROB'},
{value: 'COA1', id: 'COA1'},
{value: 'COA3', id: 'COA3'},
{value: 'COA4', id: 'COA4'},
{value: 'COA5', id: 'COA5'},
{value: 'COA6', id: 'COA6'},
{value: 'COA6-AS1', id: 'COA6-AS1'},
{value: 'COA7', id: 'COA7'},
{value: 'COA8', id: 'COA8'},
{value: 'COASY', id: 'COASY'},
{value: 'COBL', id: 'COBL'},
{value: 'COBLL1', id: 'COBLL1'},
{value: 'COCH', id: 'COCH'},
{value: 'COG1', id: 'COG1'},
{value: 'COG2', id: 'COG2'},
{value: 'COG3', id: 'COG3'},
{value: 'COG4', id: 'COG4'},
{value: 'COG5', id: 'COG5'},
{value: 'COG6', id: 'COG6'},
{value: 'COG7', id: 'COG7'},
{value: 'COG8', id: 'COG8'},
{value: 'COIL', id: 'COIL'},
{value: 'COILP1', id: 'COILP1'},
{value: 'COL10A1', id: 'COL10A1'},
{value: 'COL11A1', id: 'COL11A1'},
{value: 'COL11A2', id: 'COL11A2'},
{value: 'COL11A2P1', id: 'COL11A2P1'},
{value: 'COL12A1', id: 'COL12A1'},
{value: 'COL13A1', id: 'COL13A1'},
{value: 'COL14A1', id: 'COL14A1'},
{value: 'COL15A1', id: 'COL15A1'},
{value: 'COL16A1', id: 'COL16A1'},
{value: 'COL17A1', id: 'COL17A1'},
{value: 'COL18A1', id: 'COL18A1'},
{value: 'COL18A1-AS1', id: 'COL18A1-AS1'},
{value: 'COL18A1-AS2', id: 'COL18A1-AS2'},
{value: 'COL19A1', id: 'COL19A1'},
{value: 'COL1A1', id: 'COL1A1'},
{value: 'COL1A2', id: 'COL1A2'},
{value: 'COL20A1', id: 'COL20A1'},
{value: 'COL21A1', id: 'COL21A1'},
{value: 'COL22A1', id: 'COL22A1'},
{value: 'COL23A1', id: 'COL23A1'},
{value: 'COL24A1', id: 'COL24A1'},
{value: 'COL25A1', id: 'COL25A1'},
{value: 'COL25A1-DT', id: 'COL25A1-DT'},
{value: 'COL26A1', id: 'COL26A1'},
{value: 'COL27A1', id: 'COL27A1'},
{value: 'COL28A1', id: 'COL28A1'},
{value: 'COL2A1', id: 'COL2A1'},
{value: 'COL3A1', id: 'COL3A1'},
{value: 'COL4A1', id: 'COL4A1'},
{value: 'COL4A2', id: 'COL4A2'},
{value: 'COL4A2-AS1', id: 'COL4A2-AS1'},
{value: 'COL4A2-AS2', id: 'COL4A2-AS2'},
{value: 'COL4A3', id: 'COL4A3'},
{value: 'COL4A4', id: 'COL4A4'},
{value: 'COL4A5', id: 'COL4A5'},
{value: 'COL4A6', id: 'COL4A6'},
{value: 'COL5A1', id: 'COL5A1'},
{value: 'COL5A1-AS1', id: 'COL5A1-AS1'},
{value: 'COL5A2', id: 'COL5A2'},
{value: 'COL5A3', id: 'COL5A3'},
{value: 'COL6A1', id: 'COL6A1'},
{value: 'COL6A2', id: 'COL6A2'},
{value: 'COL6A3', id: 'COL6A3'},
{value: 'COL6A4P1', id: 'COL6A4P1'},
{value: 'COL6A4P2', id: 'COL6A4P2'},
{value: 'COL6A5', id: 'COL6A5'},
{value: 'COL6A6', id: 'COL6A6'},
{value: 'COL7A1', id: 'COL7A1'},
{value: 'COL8A1', id: 'COL8A1'},
{value: 'COL8A2', id: 'COL8A2'},
{value: 'COL9A1', id: 'COL9A1'},
{value: 'COL9A2', id: 'COL9A2'},
{value: 'COL9A3', id: 'COL9A3'},
{value: 'COLCA1', id: 'COLCA1'},
{value: 'COLCA2', id: 'COLCA2'},
{value: 'COLEC10', id: 'COLEC10'},
{value: 'COLEC11', id: 'COLEC11'},
{value: 'COLEC12', id: 'COLEC12'},
{value: 'COLGALT1', id: 'COLGALT1'},
{value: 'COLGALT2', id: 'COLGALT2'},
{value: 'COLQ', id: 'COLQ'},
{value: 'COMETT', id: 'COMETT'},
{value: 'COMMD1', id: 'COMMD1'},
{value: 'COMMD10', id: 'COMMD10'},
{value: 'COMMD2', id: 'COMMD2'},
{value: 'COMMD3', id: 'COMMD3'},
{value: 'COMMD3-BMI1', id: 'COMMD3-BMI1'},
{value: 'COMMD4', id: 'COMMD4'},
{value: 'COMMD4P1', id: 'COMMD4P1'},
{value: 'COMMD4P2', id: 'COMMD4P2'},
{value: 'COMMD5', id: 'COMMD5'},
{value: 'COMMD6', id: 'COMMD6'},
{value: 'COMMD7', id: 'COMMD7'},
{value: 'COMMD8', id: 'COMMD8'},
{value: 'COMMD9', id: 'COMMD9'},
{value: 'COMP', id: 'COMP'},
{value: 'COMT', id: 'COMT'},
{value: 'COMTD1', id: 'COMTD1'},
{value: 'COP1', id: 'COP1'},
{value: 'COP1P1', id: 'COP1P1'},
{value: 'COPA', id: 'COPA'},
{value: 'COPB1', id: 'COPB1'},
{value: 'COPB2', id: 'COPB2'},
{value: 'COPB2-DT', id: 'COPB2-DT'},
{value: 'COPDA1', id: 'COPDA1'},
{value: 'COPE', id: 'COPE'},
{value: 'COPG1', id: 'COPG1'},
{value: 'COPG2', id: 'COPG2'},
{value: 'COPG2IT1', id: 'COPG2IT1'},
{value: 'COPRS', id: 'COPRS'},
{value: 'COPRSP1', id: 'COPRSP1'},
{value: 'COPS2', id: 'COPS2'},
{value: 'COPS3', id: 'COPS3'},
{value: 'COPS4', id: 'COPS4'},
{value: 'COPS5', id: 'COPS5'},
{value: 'COPS5P1', id: 'COPS5P1'},
{value: 'COPS5P2', id: 'COPS5P2'},
{value: 'COPS6', id: 'COPS6'},
{value: 'COPS7A', id: 'COPS7A'},
{value: 'COPS7B', id: 'COPS7B'},
{value: 'COPS8', id: 'COPS8'},
{value: 'COPS8P3', id: 'COPS8P3'},
{value: 'COPS9', id: 'COPS9'},
{value: 'COPZ1', id: 'COPZ1'},
{value: 'COPZ2', id: 'COPZ2'},
{value: 'COQ10A', id: 'COQ10A'},
{value: 'COQ10B', id: 'COQ10B'},
{value: 'COQ10BP2', id: 'COQ10BP2'},
{value: 'COQ2', id: 'COQ2'},
{value: 'COQ3', id: 'COQ3'},
{value: 'COQ4', id: 'COQ4'},
{value: 'COQ5', id: 'COQ5'},
{value: 'COQ6', id: 'COQ6'},
{value: 'COQ7', id: 'COQ7'},
{value: 'COQ7-DT', id: 'COQ7-DT'},
{value: 'COQ8A', id: 'COQ8A'},
{value: 'COQ8B', id: 'COQ8B'},
{value: 'COQ9', id: 'COQ9'},
{value: 'CORIN', id: 'CORIN'},
{value: 'CORO1A', id: 'CORO1A'},
{value: 'CORO1A-AS1', id: 'CORO1A-AS1'},
{value: 'CORO1B', id: 'CORO1B'},
{value: 'CORO1C', id: 'CORO1C'},
{value: 'CORO2A', id: 'CORO2A'},
{value: 'CORO2B', id: 'CORO2B'},
{value: 'CORO6', id: 'CORO6'},
{value: 'CORO7', id: 'CORO7'},
{value: 'CORO7-PAM16', id: 'CORO7-PAM16'},
{value: 'CORT', id: 'CORT'},
{value: 'COSMOC', id: 'COSMOC'},
{value: 'COTL1', id: 'COTL1'},
{value: 'COTL1P1', id: 'COTL1P1'},
{value: 'COTL1P2', id: 'COTL1P2'},
{value: 'COX10', id: 'COX10'},
{value: 'COX10-AS1', id: 'COX10-AS1'},
{value: 'COX11', id: 'COX11'},
{value: 'COX11P1', id: 'COX11P1'},
{value: 'COX14', id: 'COX14'},
{value: 'COX15', id: 'COX15'},
{value: 'COX16', id: 'COX16'},
{value: 'COX17', id: 'COX17'},
{value: 'COX17P1', id: 'COX17P1'},
{value: 'COX18', id: 'COX18'},
{value: 'COX19', id: 'COX19'},
{value: 'COX20', id: 'COX20'},
{value: 'COX20P2', id: 'COX20P2'},
{value: 'COX4I1', id: 'COX4I1'},
{value: 'COX4I1P1', id: 'COX4I1P1'},
{value: 'COX4I1P2', id: 'COX4I1P2'},
{value: 'COX4I2', id: 'COX4I2'},
{value: 'COX5A', id: 'COX5A'},
{value: 'COX5AP1', id: 'COX5AP1'},
{value: 'COX5AP2', id: 'COX5AP2'},
{value: 'COX5B', id: 'COX5B'},
{value: 'COX5BP1', id: 'COX5BP1'},
{value: 'COX5BP3', id: 'COX5BP3'},
{value: 'COX5BP4', id: 'COX5BP4'},
{value: 'COX5BP7', id: 'COX5BP7'},
{value: 'COX5BP8', id: 'COX5BP8'},
{value: 'COX6A1', id: 'COX6A1'},
{value: 'COX6A1P2', id: 'COX6A1P2'},
{value: 'COX6A1P3', id: 'COX6A1P3'},
{value: 'COX6A2', id: 'COX6A2'},
{value: 'COX6B1', id: 'COX6B1'},
{value: 'COX6B1P1', id: 'COX6B1P1'},
{value: 'COX6B1P2', id: 'COX6B1P2'},
{value: 'COX6B1P6', id: 'COX6B1P6'},
{value: 'COX6B1P7', id: 'COX6B1P7'},
{value: 'COX6B2', id: 'COX6B2'},
{value: 'COX6C', id: 'COX6C'},
{value: 'COX6CP11', id: 'COX6CP11'},
{value: 'COX6CP14', id: 'COX6CP14'},
{value: 'COX6CP2', id: 'COX6CP2'},
{value: 'COX6CP4', id: 'COX6CP4'},
{value: 'COX6CP6', id: 'COX6CP6'},
{value: 'COX6CP7', id: 'COX6CP7'},
{value: 'COX7A1', id: 'COX7A1'},
{value: 'COX7A2', id: 'COX7A2'},
{value: 'COX7A2L', id: 'COX7A2L'},
{value: 'COX7A2P2', id: 'COX7A2P2'},
{value: 'COX7B', id: 'COX7B'},
{value: 'COX7B2', id: 'COX7B2'},
{value: 'COX7C', id: 'COX7C'},
{value: 'COX8A', id: 'COX8A'},
{value: 'COX8C', id: 'COX8C'},
{value: 'CP', id: 'CP'},
{value: 'CPA1', id: 'CPA1'},
{value: 'CPA2', id: 'CPA2'},
{value: 'CPA3', id: 'CPA3'},
{value: 'CPA4', id: 'CPA4'},
{value: 'CPA5', id: 'CPA5'},
{value: 'CPA6', id: 'CPA6'},
{value: 'CPAMD8', id: 'CPAMD8'},
{value: 'CPB1', id: 'CPB1'},
{value: 'CPB2', id: 'CPB2'},
{value: 'CPB2-AS1', id: 'CPB2-AS1'},
{value: 'CPD', id: 'CPD'},
{value: 'CPDP1', id: 'CPDP1'},
{value: 'CPE', id: 'CPE'},
{value: 'CPEB1', id: 'CPEB1'},
{value: 'CPEB1-AS1', id: 'CPEB1-AS1'},
{value: 'CPEB2', id: 'CPEB2'},
{value: 'CPEB2-DT', id: 'CPEB2-DT'},
{value: 'CPEB3', id: 'CPEB3'},
{value: 'CPEB4', id: 'CPEB4'},
{value: 'CPED1', id: 'CPED1'},
{value: 'CPHL1P', id: 'CPHL1P'},
{value: 'CPHXL', id: 'CPHXL'},
{value: 'CPLANE1', id: 'CPLANE1'},
{value: 'CPLANE2', id: 'CPLANE2'},
{value: 'CPLX1', id: 'CPLX1'},
{value: 'CPLX2', id: 'CPLX2'},
{value: 'CPLX3', id: 'CPLX3'},
{value: 'CPLX4', id: 'CPLX4'},
{value: 'CPM', id: 'CPM'},
{value: 'CPN1', id: 'CPN1'},
{value: 'CPN2', id: 'CPN2'},
{value: 'CPNE1', id: 'CPNE1'},
{value: 'CPNE2', id: 'CPNE2'},
{value: 'CPNE2-DT', id: 'CPNE2-DT'},
{value: 'CPNE3', id: 'CPNE3'},
{value: 'CPNE4', id: 'CPNE4'},
{value: 'CPNE5', id: 'CPNE5'},
{value: 'CPNE6', id: 'CPNE6'},
{value: 'CPNE7', id: 'CPNE7'},
{value: 'CPNE8', id: 'CPNE8'},
{value: 'CPNE8-AS1', id: 'CPNE8-AS1'},
{value: 'CPNE9', id: 'CPNE9'},
{value: 'CPO', id: 'CPO'},
{value: 'CPOX', id: 'CPOX'},
{value: 'CPPED1', id: 'CPPED1'},
{value: 'CPQ', id: 'CPQ'},
{value: 'CPS1', id: 'CPS1'},
{value: 'CPS1-IT1', id: 'CPS1-IT1'},
{value: 'CPSF1', id: 'CPSF1'},
{value: 'CPSF1P1', id: 'CPSF1P1'},
{value: 'CPSF2', id: 'CPSF2'},
{value: 'CPSF3', id: 'CPSF3'},
{value: 'CPSF4', id: 'CPSF4'},
{value: 'CPSF4L', id: 'CPSF4L'},
{value: 'CPSF6', id: 'CPSF6'},
{value: 'CPSF7', id: 'CPSF7'},
{value: 'CPT1A', id: 'CPT1A'},
{value: 'CPT1B', id: 'CPT1B'},
{value: 'CPT1C', id: 'CPT1C'},
{value: 'CPT2', id: 'CPT2'},
{value: 'CPTP', id: 'CPTP'},
{value: 'CPVL', id: 'CPVL'},
{value: 'CPXM1', id: 'CPXM1'},
{value: 'CPXM2', id: 'CPXM2'},
{value: 'CPZ', id: 'CPZ'},
{value: 'CR1', id: 'CR1'},
{value: 'CR1L', id: 'CR1L'},
{value: 'CR2', id: 'CR2'},
{value: 'CRABP1', id: 'CRABP1'},
{value: 'CRABP2', id: 'CRABP2'},
{value: 'CRACD', id: 'CRACD'},
{value: 'CRACDL', id: 'CRACDL'},
{value: 'CRACR2A', id: 'CRACR2A'},
{value: 'CRACR2B', id: 'CRACR2B'},
{value: 'CRADD', id: 'CRADD'},
{value: 'CRAMP1', id: 'CRAMP1'},
{value: 'CRAT', id: 'CRAT'},
{value: 'CRAT37', id: 'CRAT37'},
{value: 'CRB1', id: 'CRB1'},
{value: 'CRB2', id: 'CRB2'},
{value: 'CRB3', id: 'CRB3'},
{value: 'CRB3P1', id: 'CRB3P1'},
{value: 'CRBN', id: 'CRBN'},
{value: 'CRCP', id: 'CRCP'},
{value: 'CRCT1', id: 'CRCT1'},
{value: 'CREB1', id: 'CREB1'},
{value: 'CREB3', id: 'CREB3'},
{value: 'CREB3L1', id: 'CREB3L1'},
{value: 'CREB3L2', id: 'CREB3L2'},
{value: 'CREB3L2-AS1', id: 'CREB3L2-AS1'},
{value: 'CREB3L3', id: 'CREB3L3'},
{value: 'CREB3L4', id: 'CREB3L4'},
{value: 'CREB5', id: 'CREB5'},
{value: 'CREBBP', id: 'CREBBP'},
{value: 'CREBL2', id: 'CREBL2'},
{value: 'CREBRF', id: 'CREBRF'},
{value: 'CREBZF', id: 'CREBZF'},
{value: 'CREG1', id: 'CREG1'},
{value: 'CREG2', id: 'CREG2'},
{value: 'CRELD1', id: 'CRELD1'},
{value: 'CRELD2', id: 'CRELD2'},
{value: 'CREM', id: 'CREM'},
{value: 'CRH', id: 'CRH'},
{value: 'CRHBP', id: 'CRHBP'},
{value: 'CRHR1', id: 'CRHR1'},
{value: 'CRHR2', id: 'CRHR2'},
{value: 'CRIM1', id: 'CRIM1'},
{value: 'CRIM1-DT', id: 'CRIM1-DT'},
{value: 'CRIP1', id: 'CRIP1'},
{value: 'CRIP1P1', id: 'CRIP1P1'},
{value: 'CRIP1P2', id: 'CRIP1P2'},
{value: 'CRIP1P3', id: 'CRIP1P3'},
{value: 'CRIP1P4', id: 'CRIP1P4'},
{value: 'CRIP2', id: 'CRIP2'},
{value: 'CRIP3', id: 'CRIP3'},
{value: 'CRIPT', id: 'CRIPT'},
{value: 'CRISP1', id: 'CRISP1'},
{value: 'CRISP2', id: 'CRISP2'},
{value: 'CRISP3', id: 'CRISP3'},
{value: 'CRISPLD1', id: 'CRISPLD1'},
{value: 'CRISPLD2', id: 'CRISPLD2'},
{value: 'CRK', id: 'CRK'},
{value: 'CRKL', id: 'CRKL'},
{value: 'CRLF1', id: 'CRLF1'},
{value: 'CRLF3', id: 'CRLF3'},
{value: 'CRLF3P1', id: 'CRLF3P1'},
{value: 'CRLF3P2', id: 'CRLF3P2'},
{value: 'CRLF3P3', id: 'CRLF3P3'},
{value: 'CRLS1', id: 'CRLS1'},
{value: 'CRMP1', id: 'CRMP1'},
{value: 'CRNDE', id: 'CRNDE'},
{value: 'CRNKL1', id: 'CRNKL1'},
{value: 'CRNN', id: 'CRNN'},
{value: 'CROCC', id: 'CROCC'},
{value: 'CROCC2', id: 'CROCC2'},
{value: 'CROCCP2', id: 'CROCCP2'},
{value: 'CROCCP3', id: 'CROCCP3'},
{value: 'CROCCP5', id: 'CROCCP5'},
{value: 'CROT', id: 'CROT'},
{value: 'CRP', id: 'CRP'},
{value: 'CRPP1', id: 'CRPP1'},
{value: 'CRPPA', id: 'CRPPA'},
{value: 'CRPPA-AS1', id: 'CRPPA-AS1'},
{value: 'CRTAC1', id: 'CRTAC1'},
{value: 'CRTAM', id: 'CRTAM'},
{value: 'CRTAP', id: 'CRTAP'},
{value: 'CRTC1', id: 'CRTC1'},
{value: 'CRTC1P1', id: 'CRTC1P1'},
{value: 'CRTC2', id: 'CRTC2'},
{value: 'CRTC3', id: 'CRTC3'},
{value: 'CRTC3-AS1', id: 'CRTC3-AS1'},
{value: 'CRX', id: 'CRX'},
{value: 'CRY1', id: 'CRY1'},
{value: 'CRY2', id: 'CRY2'},
{value: 'CRYAA', id: 'CRYAA'},
{value: 'CRYAA2', id: 'CRYAA2'},
{value: 'CRYAB', id: 'CRYAB'},
{value: 'CRYBA1', id: 'CRYBA1'},
{value: 'CRYBA2', id: 'CRYBA2'},
{value: 'CRYBA4', id: 'CRYBA4'},
{value: 'CRYBB1', id: 'CRYBB1'},
{value: 'CRYBB2', id: 'CRYBB2'},
{value: 'CRYBB2P1', id: 'CRYBB2P1'},
{value: 'CRYBB3', id: 'CRYBB3'},
{value: 'CRYBG1', id: 'CRYBG1'},
{value: 'CRYBG2', id: 'CRYBG2'},
{value: 'CRYBG3', id: 'CRYBG3'},
{value: 'CRYGA', id: 'CRYGA'},
{value: 'CRYGB', id: 'CRYGB'},
{value: 'CRYGC', id: 'CRYGC'},
{value: 'CRYGD', id: 'CRYGD'},
{value: 'CRYGEP', id: 'CRYGEP'},
{value: 'CRYGFP', id: 'CRYGFP'},
{value: 'CRYGN', id: 'CRYGN'},
{value: 'CRYGS', id: 'CRYGS'},
{value: 'CRYL1', id: 'CRYL1'},
{value: 'CRYM', id: 'CRYM'},
{value: 'CRYM-AS1', id: 'CRYM-AS1'},
{value: 'CRYZ', id: 'CRYZ'},
{value: 'CRYZL1', id: 'CRYZL1'},
{value: 'CRYZL2P', id: 'CRYZL2P'},
{value: 'CRYZL2P-SEC16B', id: 'CRYZL2P-SEC16B'},
{value: 'CRYZP1', id: 'CRYZP1'},
{value: 'CRYZP2', id: 'CRYZP2'},
{value: 'CS', id: 'CS'},
{value: 'CSAD', id: 'CSAD'},
{value: 'CSDC2', id: 'CSDC2'},
{value: 'CSDE1', id: 'CSDE1'},
{value: 'CSE1L', id: 'CSE1L'},
{value: 'CSE1L-AS1', id: 'CSE1L-AS1'},
{value: 'CSF1', id: 'CSF1'},
{value: 'CSF1R', id: 'CSF1R'},
{value: 'CSF2', id: 'CSF2'},
{value: 'CSF2RA', id: 'CSF2RA'},
{value: 'CSF2RB', id: 'CSF2RB'},
{value: 'CSF2RBP1', id: 'CSF2RBP1'},
{value: 'CSF3', id: 'CSF3'},
{value: 'CSF3R', id: 'CSF3R'},
{value: 'CSGALNACT1', id: 'CSGALNACT1'},
{value: 'CSGALNACT2', id: 'CSGALNACT2'},
{value: 'CSGALNACT2-DT', id: 'CSGALNACT2-DT'},
{value: 'CSH1', id: 'CSH1'},
{value: 'CSH2', id: 'CSH2'},
{value: 'CSHL1', id: 'CSHL1'},
{value: 'CSK', id: 'CSK'},
{value: 'CSKMT', id: 'CSKMT'},
{value: 'CSMD1', id: 'CSMD1'},
{value: 'CSMD2', id: 'CSMD2'},
{value: 'CSMD3', id: 'CSMD3'},
{value: 'CSN1S1', id: 'CSN1S1'},
{value: 'CSN1S2AP', id: 'CSN1S2AP'},
{value: 'CSN2', id: 'CSN2'},
{value: 'CSN3', id: 'CSN3'},
{value: 'CSNK1A1', id: 'CSNK1A1'},
{value: 'CSNK1A1L', id: 'CSNK1A1L'},
{value: 'CSNK1A1P1', id: 'CSNK1A1P1'},
{value: 'CSNK1A1P3', id: 'CSNK1A1P3'},
{value: 'CSNK1D', id: 'CSNK1D'},
{value: 'CSNK1E', id: 'CSNK1E'},
{value: 'CSNK1G1', id: 'CSNK1G1'},
{value: 'CSNK1G2', id: 'CSNK1G2'},
{value: 'CSNK1G2-AS1', id: 'CSNK1G2-AS1'},
{value: 'CSNK1G2P1', id: 'CSNK1G2P1'},
{value: 'CSNK1G3', id: 'CSNK1G3'},
{value: 'CSNK2A1', id: 'CSNK2A1'},
{value: 'CSNK2A2', id: 'CSNK2A2'},
{value: 'CSNK2A3', id: 'CSNK2A3'},
{value: 'CSNK2B', id: 'CSNK2B'},
{value: 'CSNKA2IP', id: 'CSNKA2IP'},
{value: 'CSP2', id: 'CSP2'},
{value: 'CSPG4', id: 'CSPG4'},
{value: 'CSPG4BP', id: 'CSPG4BP'},
{value: 'CSPG4P10', id: 'CSPG4P10'},
{value: 'CSPG4P11', id: 'CSPG4P11'},
{value: 'CSPG4P12', id: 'CSPG4P12'},
{value: 'CSPG4P13', id: 'CSPG4P13'},
{value: 'CSPG4P5', id: 'CSPG4P5'},
{value: 'CSPG5', id: 'CSPG5'},
{value: 'CSPP1', id: 'CSPP1'},
{value: 'CSRNP1', id: 'CSRNP1'},
{value: 'CSRNP2', id: 'CSRNP2'},
{value: 'CSRNP3', id: 'CSRNP3'},
{value: 'CSRP1', id: 'CSRP1'},
{value: 'CSRP2', id: 'CSRP2'},
{value: 'CSRP3', id: 'CSRP3'},
{value: 'CSRP3-AS1', id: 'CSRP3-AS1'},
{value: 'CST1', id: 'CST1'},
{value: 'CST11', id: 'CST11'},
{value: 'CST12P', id: 'CST12P'},
{value: 'CST13P', id: 'CST13P'},
{value: 'CST2', id: 'CST2'},
{value: 'CST2P1', id: 'CST2P1'},
{value: 'CST3', id: 'CST3'},
{value: 'CST4', id: 'CST4'},
{value: 'CST5', id: 'CST5'},
{value: 'CST6', id: 'CST6'},
{value: 'CST7', id: 'CST7'},
{value: 'CST8', id: 'CST8'},
{value: 'CST9', id: 'CST9'},
{value: 'CST9L', id: 'CST9L'},
{value: 'CST9LP1', id: 'CST9LP1'},
{value: 'CST9LP2', id: 'CST9LP2'},
{value: 'CSTA', id: 'CSTA'},
{value: 'CSTB', id: 'CSTB'},
{value: 'CSTF1', id: 'CSTF1'},
{value: 'CSTF2', id: 'CSTF2'},
{value: 'CSTF2T', id: 'CSTF2T'},
{value: 'CSTF3', id: 'CSTF3'},
{value: 'CSTF3-DT', id: 'CSTF3-DT'},
{value: 'CSTL1', id: 'CSTL1'},
{value: 'CSTP1', id: 'CSTP1'},
{value: 'CSTP2', id: 'CSTP2'},
{value: 'CT62', id: 'CT62'},
{value: 'CT66', id: 'CT66'},
{value: 'CT69', id: 'CT69'},
{value: 'CT70', id: 'CT70'},
{value: 'CT75', id: 'CT75'},
{value: 'CTAGE1', id: 'CTAGE1'},
{value: 'CTAGE10P', id: 'CTAGE10P'},
{value: 'CTAGE11P', id: 'CTAGE11P'},
{value: 'CTAGE12P', id: 'CTAGE12P'},
{value: 'CTAGE13P', id: 'CTAGE13P'},
{value: 'CTAGE14P', id: 'CTAGE14P'},
{value: 'CTAGE15', id: 'CTAGE15'},
{value: 'CTAGE16P', id: 'CTAGE16P'},
{value: 'CTAGE3P', id: 'CTAGE3P'},
{value: 'CTAGE4', id: 'CTAGE4'},
{value: 'CTAGE6', id: 'CTAGE6'},
{value: 'CTAGE7P', id: 'CTAGE7P'},
{value: 'CTAGE8', id: 'CTAGE8'},
{value: 'CTB-178M22.2', id: 'CTB-178M22.2'},
{value: 'CTBP1', id: 'CTBP1'},
{value: 'CTBP1-AS', id: 'CTBP1-AS'},
{value: 'CTBP1-DT', id: 'CTBP1-DT'},
{value: 'CTBP2', id: 'CTBP2'},
{value: 'CTBP2P10', id: 'CTBP2P10'},
{value: 'CTBP2P2', id: 'CTBP2P2'},
{value: 'CTBP2P3', id: 'CTBP2P3'},
{value: 'CTBP2P4', id: 'CTBP2P4'},
{value: 'CTBP2P5', id: 'CTBP2P5'},
{value: 'CTBP2P6', id: 'CTBP2P6'},
{value: 'CTBP2P7', id: 'CTBP2P7'},
{value: 'CTBP2P8', id: 'CTBP2P8'},
{value: 'CTBP2P9', id: 'CTBP2P9'},
{value: 'CTBS', id: 'CTBS'},
{value: 'CTC-338M12.4', id: 'CTC-338M12.4'},
{value: 'CTC1', id: 'CTC1'},
{value: 'CTCF', id: 'CTCF'},
{value: 'CTCF-DT', id: 'CTCF-DT'},
{value: 'CTCFL', id: 'CTCFL'},
{value: 'CTD-2194D22.4', id: 'CTD-2194D22.4'},
{value: 'CTD-2350J17.1', id: 'CTD-2350J17.1'},
{value: 'CTD-3080P12.3', id: 'CTD-3080P12.3'},
{value: 'CTDNEP1', id: 'CTDNEP1'},
{value: 'CTDP1', id: 'CTDP1'},
{value: 'CTDSP1', id: 'CTDSP1'},
{value: 'CTDSP2', id: 'CTDSP2'},
{value: 'CTDSPL', id: 'CTDSPL'},
{value: 'CTDSPL2', id: 'CTDSPL2'},
{value: 'CTDSPL2-DT', id: 'CTDSPL2-DT'},
{value: 'CTF1', id: 'CTF1'},
{value: 'CTF2P', id: 'CTF2P'},
{value: 'CTH', id: 'CTH'},
{value: 'CTHRC1', id: 'CTHRC1'},
{value: 'CTIF', id: 'CTIF'},
{value: 'CTLA4', id: 'CTLA4'},
{value: 'CTNNA1', id: 'CTNNA1'},
{value: 'CTNNA1-AS1', id: 'CTNNA1-AS1'},
{value: 'CTNNA1P1', id: 'CTNNA1P1'},
{value: 'CTNNA2', id: 'CTNNA2'},
{value: 'CTNNA3', id: 'CTNNA3'},
{value: 'CTNNAL1', id: 'CTNNAL1'},
{value: 'CTNNB1', id: 'CTNNB1'},
{value: 'CTNNBIP1', id: 'CTNNBIP1'},
{value: 'CTNNBL1', id: 'CTNNBL1'},
{value: 'CTNND1', id: 'CTNND1'},
{value: 'CTNND2', id: 'CTNND2'},
{value: 'CTNS', id: 'CTNS'},
{value: 'CTPS1', id: 'CTPS1'},
{value: 'CTPS2', id: 'CTPS2'},
{value: 'CTR9', id: 'CTR9'},
{value: 'CTRB1', id: 'CTRB1'},
{value: 'CTRB2', id: 'CTRB2'},
{value: 'CTRC', id: 'CTRC'},
{value: 'CTRL', id: 'CTRL'},
{value: 'CTSA', id: 'CTSA'},
{value: 'CTSB', id: 'CTSB'},
{value: 'CTSC', id: 'CTSC'},
{value: 'CTSD', id: 'CTSD'},
{value: 'CTSE', id: 'CTSE'},
{value: 'CTSF', id: 'CTSF'},
{value: 'CTSG', id: 'CTSG'},
{value: 'CTSH', id: 'CTSH'},
{value: 'CTSK', id: 'CTSK'},
{value: 'CTSL', id: 'CTSL'},
{value: 'CTSL3P', id: 'CTSL3P'},
{value: 'CTSLP1', id: 'CTSLP1'},
{value: 'CTSLP2', id: 'CTSLP2'},
{value: 'CTSLP3', id: 'CTSLP3'},
{value: 'CTSLP4', id: 'CTSLP4'},
{value: 'CTSLP6', id: 'CTSLP6'},
{value: 'CTSLP8', id: 'CTSLP8'},
{value: 'CTSO', id: 'CTSO'},
{value: 'CTSS', id: 'CTSS'},
{value: 'CTSV', id: 'CTSV'},
{value: 'CTSW', id: 'CTSW'},
{value: 'CTSZ', id: 'CTSZ'},
{value: 'CTTN', id: 'CTTN'},
{value: 'CTTN-DT', id: 'CTTN-DT'},
{value: 'CTTNBP2', id: 'CTTNBP2'},
{value: 'CTTNBP2NL', id: 'CTTNBP2NL'},
{value: 'CTU1', id: 'CTU1'},
{value: 'CTU2', id: 'CTU2'},
{value: 'CTXN1', id: 'CTXN1'},
{value: 'CTXN2', id: 'CTXN2'},
{value: 'CTXN2-AS1', id: 'CTXN2-AS1'},
{value: 'CTXN3', id: 'CTXN3'},
{value: 'CTXND1', id: 'CTXND1'},
{value: 'CTXND2', id: 'CTXND2'},
{value: 'CUBN', id: 'CUBN'},
{value: 'CUBNP1', id: 'CUBNP1'},
{value: 'CUBNP2', id: 'CUBNP2'},
{value: 'CUBNP3', id: 'CUBNP3'},
{value: 'CUEDC1', id: 'CUEDC1'},
{value: 'CUEDC2', id: 'CUEDC2'},
{value: 'CUL1', id: 'CUL1'},
{value: 'CUL1P1', id: 'CUL1P1'},
{value: 'CUL2', id: 'CUL2'},
{value: 'CUL3', id: 'CUL3'},
{value: 'CUL4A', id: 'CUL4A'},
{value: 'CUL4AP1', id: 'CUL4AP1'},
{value: 'CUL4B', id: 'CUL4B'},
{value: 'CUL5', id: 'CUL5'},
{value: 'CUL7', id: 'CUL7'},
{value: 'CUL9', id: 'CUL9'},
{value: 'CUPIN1P', id: 'CUPIN1P'},
{value: 'CUTA', id: 'CUTA'},
{value: 'CUTALP', id: 'CUTALP'},
{value: 'CUTC', id: 'CUTC'},
{value: 'CUX1', id: 'CUX1'},
{value: 'CUX2', id: 'CUX2'},
{value: 'CUX2P1', id: 'CUX2P1'},
{value: 'CUZD1', id: 'CUZD1'},
{value: 'CWC15', id: 'CWC15'},
{value: 'CWC22', id: 'CWC22'},
{value: 'CWC25', id: 'CWC25'},
{value: 'CWC27', id: 'CWC27'},
{value: 'CWF19L1', id: 'CWF19L1'},
{value: 'CWF19L2', id: 'CWF19L2'},
{value: 'CWH43', id: 'CWH43'},
{value: 'CX3CL1', id: 'CX3CL1'},
{value: 'CX3CR1', id: 'CX3CR1'},
{value: 'CXADR', id: 'CXADR'},
{value: 'CXADRP3', id: 'CXADRP3'},
{value: 'CXCL1', id: 'CXCL1'},
{value: 'CXCL10', id: 'CXCL10'},
{value: 'CXCL11', id: 'CXCL11'},
{value: 'CXCL12', id: 'CXCL12'},
{value: 'CXCL13', id: 'CXCL13'},
{value: 'CXCL14', id: 'CXCL14'},
{value: 'CXCL16', id: 'CXCL16'},
{value: 'CXCL17', id: 'CXCL17'},
{value: 'CXCL1P1', id: 'CXCL1P1'},
{value: 'CXCL2', id: 'CXCL2'},
{value: 'CXCL3', id: 'CXCL3'},
{value: 'CXCL5', id: 'CXCL5'},
{value: 'CXCL6', id: 'CXCL6'},
{value: 'CXCL8', id: 'CXCL8'},
{value: 'CXCL9', id: 'CXCL9'},
{value: 'CXCR1', id: 'CXCR1'},
{value: 'CXCR2', id: 'CXCR2'},
{value: 'CXCR2P1', id: 'CXCR2P1'},
{value: 'CXCR3', id: 'CXCR3'},
{value: 'CXCR4', id: 'CXCR4'},
{value: 'CXCR5', id: 'CXCR5'},
{value: 'CXCR6', id: 'CXCR6'},
{value: 'CXorf38', id: 'CXorf38'},
{value: 'CXorf65', id: 'CXorf65'},
{value: 'CXXC1', id: 'CXXC1'},
{value: 'CXXC4', id: 'CXXC4'},
{value: 'CXXC4-AS1', id: 'CXXC4-AS1'},
{value: 'CXXC5', id: 'CXXC5'},
{value: 'CXXC5-AS1', id: 'CXXC5-AS1'},
{value: 'CYB561', id: 'CYB561'},
{value: 'CYB561A3', id: 'CYB561A3'},
{value: 'CYB561D1', id: 'CYB561D1'},
{value: 'CYB561D2', id: 'CYB561D2'},
{value: 'CYB5A', id: 'CYB5A'},
{value: 'CYB5AP2', id: 'CYB5AP2'},
{value: 'CYB5AP3', id: 'CYB5AP3'},
{value: 'CYB5AP4', id: 'CYB5AP4'},
{value: 'CYB5AP5', id: 'CYB5AP5'},
{value: 'CYB5B', id: 'CYB5B'},
{value: 'CYB5D1', id: 'CYB5D1'},
{value: 'CYB5D2', id: 'CYB5D2'},
{value: 'CYB5R1', id: 'CYB5R1'},
{value: 'CYB5R2', id: 'CYB5R2'},
{value: 'CYB5R3', id: 'CYB5R3'},
{value: 'CYB5R4', id: 'CYB5R4'},
{value: 'CYB5RL', id: 'CYB5RL'},
{value: 'CYBA', id: 'CYBA'},
{value: 'CYBB', id: 'CYBB'},
{value: 'CYBC1', id: 'CYBC1'},
{value: 'CYBRD1', id: 'CYBRD1'},
{value: 'CYC1', id: 'CYC1'},
{value: 'CYCS', id: 'CYCS'},
{value: 'CYCSP10', id: 'CYCSP10'},
{value: 'CYCSP17', id: 'CYCSP17'},
{value: 'CYCSP19', id: 'CYCSP19'},
{value: 'CYCSP2', id: 'CYCSP2'},
{value: 'CYCSP20', id: 'CYCSP20'},
{value: 'CYCSP22', id: 'CYCSP22'},
{value: 'CYCSP23', id: 'CYCSP23'},
{value: 'CYCSP26', id: 'CYCSP26'},
{value: 'CYCSP28', id: 'CYCSP28'},
{value: 'CYCSP33', id: 'CYCSP33'},
{value: 'CYCSP35', id: 'CYCSP35'},
{value: 'CYCSP39', id: 'CYCSP39'},
{value: 'CYCSP40', id: 'CYCSP40'},
{value: 'CYCSP41', id: 'CYCSP41'},
{value: 'CYCSP42', id: 'CYCSP42'},
{value: 'CYCSP51', id: 'CYCSP51'},
{value: 'CYCSP53', id: 'CYCSP53'},
{value: 'CYCSP55', id: 'CYCSP55'},
{value: 'CYCSP6', id: 'CYCSP6'},
{value: 'CYCSP7', id: 'CYCSP7'},
{value: 'CYCSP8', id: 'CYCSP8'},
{value: 'CYFIP1', id: 'CYFIP1'},
{value: 'CYFIP2', id: 'CYFIP2'},
{value: 'CYGB', id: 'CYGB'},
{value: 'CYHR1', id: 'CYHR1'},
{value: 'CYLC2', id: 'CYLC2'},
{value: 'CYLD', id: 'CYLD'},
{value: 'CYLD-AS1', id: 'CYLD-AS1'},
{value: 'CYMP', id: 'CYMP'},
{value: 'CYMP-AS1', id: 'CYMP-AS1'},
{value: 'CYP11A1', id: 'CYP11A1'},
{value: 'CYP11B1', id: 'CYP11B1'},
{value: 'CYP11B2', id: 'CYP11B2'},
{value: 'CYP17A1', id: 'CYP17A1'},
{value: 'CYP19A1', id: 'CYP19A1'},
{value: 'CYP1A1', id: 'CYP1A1'},
{value: 'CYP1A2', id: 'CYP1A2'},
{value: 'CYP1B1', id: 'CYP1B1'},
{value: 'CYP1B1-AS1', id: 'CYP1B1-AS1'},
{value: 'CYP20A1', id: 'CYP20A1'},
{value: 'CYP21A1P', id: 'CYP21A1P'},
{value: 'CYP21A2', id: 'CYP21A2'},
{value: 'CYP24A1', id: 'CYP24A1'},
{value: 'CYP26A1', id: 'CYP26A1'},
{value: 'CYP26B1', id: 'CYP26B1'},
{value: 'CYP26C1', id: 'CYP26C1'},
{value: 'CYP27A1', id: 'CYP27A1'},
{value: 'CYP27B1', id: 'CYP27B1'},
{value: 'CYP27C1', id: 'CYP27C1'},
{value: 'CYP2A13', id: 'CYP2A13'},
{value: 'CYP2A6', id: 'CYP2A6'},
{value: 'CYP2A7P1', id: 'CYP2A7P1'},
{value: 'CYP2AB1P', id: 'CYP2AB1P'},
{value: 'CYP2AC1P', id: 'CYP2AC1P'},
{value: 'CYP2B6', id: 'CYP2B6'},
{value: 'CYP2B7P', id: 'CYP2B7P'},
{value: 'CYP2C18', id: 'CYP2C18'},
{value: 'CYP2C19', id: 'CYP2C19'},
{value: 'CYP2C23P', id: 'CYP2C23P'},
{value: 'CYP2C56P', id: 'CYP2C56P'},
{value: 'CYP2C58P', id: 'CYP2C58P'},
{value: 'CYP2C59P', id: 'CYP2C59P'},
{value: 'CYP2C60P', id: 'CYP2C60P'},
{value: 'CYP2C61P', id: 'CYP2C61P'},
{value: 'CYP2C8', id: 'CYP2C8'},
{value: 'CYP2C9', id: 'CYP2C9'},
{value: 'CYP2D6', id: 'CYP2D6'},
{value: 'CYP2D7', id: 'CYP2D7'},
{value: 'CYP2D8P', id: 'CYP2D8P'},
{value: 'CYP2E1', id: 'CYP2E1'},
{value: 'CYP2F1', id: 'CYP2F1'},
{value: 'CYP2F2P', id: 'CYP2F2P'},
{value: 'CYP2G1P', id: 'CYP2G1P'},
{value: 'CYP2G2P', id: 'CYP2G2P'},
{value: 'CYP2J2', id: 'CYP2J2'},
{value: 'CYP2R1', id: 'CYP2R1'},
{value: 'CYP2S1', id: 'CYP2S1'},
{value: 'CYP2T1P', id: 'CYP2T1P'},
{value: 'CYP2T3P', id: 'CYP2T3P'},
{value: 'CYP2U1', id: 'CYP2U1'},
{value: 'CYP2U1-AS1', id: 'CYP2U1-AS1'},
{value: 'CYP2W1', id: 'CYP2W1'},
{value: 'CYP39A1', id: 'CYP39A1'},
{value: 'CYP3A137P', id: 'CYP3A137P'},
{value: 'CYP3A4', id: 'CYP3A4'},
{value: 'CYP3A43', id: 'CYP3A43'},
{value: 'CYP3A5', id: 'CYP3A5'},
{value: 'CYP3A52P', id: 'CYP3A52P'},
{value: 'CYP3A54P', id: 'CYP3A54P'},
{value: 'CYP3A7', id: 'CYP3A7'},
{value: 'CYP3A7-CYP3A51P', id: 'CYP3A7-CYP3A51P'},
{value: 'CYP46A1', id: 'CYP46A1'},
{value: 'CYP46A4P', id: 'CYP46A4P'},
{value: 'CYP4A11', id: 'CYP4A11'},
{value: 'CYP4A22', id: 'CYP4A22'},
{value: 'CYP4A22-AS1', id: 'CYP4A22-AS1'},
{value: 'CYP4A26P', id: 'CYP4A26P'},
{value: 'CYP4A27P', id: 'CYP4A27P'},
{value: 'CYP4A43P', id: 'CYP4A43P'},
{value: 'CYP4A44P', id: 'CYP4A44P'},
{value: 'CYP4B1', id: 'CYP4B1'},
{value: 'CYP4F10P', id: 'CYP4F10P'},
{value: 'CYP4F11', id: 'CYP4F11'},
{value: 'CYP4F12', id: 'CYP4F12'},
{value: 'CYP4F2', id: 'CYP4F2'},
{value: 'CYP4F22', id: 'CYP4F22'},
{value: 'CYP4F23P', id: 'CYP4F23P'},
{value: 'CYP4F24P', id: 'CYP4F24P'},
{value: 'CYP4F25P', id: 'CYP4F25P'},
{value: 'CYP4F26P', id: 'CYP4F26P'},
{value: 'CYP4F29P', id: 'CYP4F29P'},
{value: 'CYP4F3', id: 'CYP4F3'},
{value: 'CYP4F30P', id: 'CYP4F30P'},
{value: 'CYP4F32P', id: 'CYP4F32P'},
{value: 'CYP4F34P', id: 'CYP4F34P'},
{value: 'CYP4F35P', id: 'CYP4F35P'},
{value: 'CYP4F36P', id: 'CYP4F36P'},
{value: 'CYP4F44P', id: 'CYP4F44P'},
{value: 'CYP4F60P', id: 'CYP4F60P'},
{value: 'CYP4F62P', id: 'CYP4F62P'},
{value: 'CYP4F8', id: 'CYP4F8'},
{value: 'CYP4F9P', id: 'CYP4F9P'},
{value: 'CYP4V2', id: 'CYP4V2'},
{value: 'CYP4X1', id: 'CYP4X1'},
{value: 'CYP4Z1', id: 'CYP4Z1'},
{value: 'CYP4Z2P', id: 'CYP4Z2P'},
{value: 'CYP51A1', id: 'CYP51A1'},
{value: 'CYP51A1-AS1', id: 'CYP51A1-AS1'},
{value: 'CYP51A1P1', id: 'CYP51A1P1'},
{value: 'CYP51A1P2', id: 'CYP51A1P2'},
{value: 'CYP7A1', id: 'CYP7A1'},
{value: 'CYP7B1', id: 'CYP7B1'},
{value: 'CYP8B1', id: 'CYP8B1'},
{value: 'CYREN', id: 'CYREN'},
{value: 'CYRIA', id: 'CYRIA'},
{value: 'CYRIB', id: 'CYRIB'},
{value: 'CYS1', id: 'CYS1'},
{value: 'CYSLTR1', id: 'CYSLTR1'},
{value: 'CYSLTR2', id: 'CYSLTR2'},
{value: 'CYSRT1', id: 'CYSRT1'},
{value: 'CYSTM1', id: 'CYSTM1'},
{value: 'CYTH1', id: 'CYTH1'},
{value: 'CYTH2', id: 'CYTH2'},
{value: 'CYTH3', id: 'CYTH3'},
{value: 'CYTH4', id: 'CYTH4'},
{value: 'CYTIP', id: 'CYTIP'},
{value: 'CYTL1', id: 'CYTL1'},
{value: 'CYTOR', id: 'CYTOR'},
{value: 'CYYR1', id: 'CYYR1'},
{value: 'CYYR1-AS1', id: 'CYYR1-AS1'},
{value: 'CZIB', id: 'CZIB'},
{value: 'CZIB-DT', id: 'CZIB-DT'},
{value: 'D21S2088E', id: 'D21S2088E'},
{value: 'D2HGDH', id: 'D2HGDH'},
{value: 'DAAM1', id: 'DAAM1'},
{value: 'DAAM2', id: 'DAAM2'},
{value: 'DAAM2-AS1', id: 'DAAM2-AS1'},
{value: 'DAB1', id: 'DAB1'},
{value: 'DAB2', id: 'DAB2'},
{value: 'DAB2IP', id: 'DAB2IP'},
{value: 'DACH1', id: 'DACH1'},
{value: 'DACT1', id: 'DACT1'},
{value: 'DACT2', id: 'DACT2'},
{value: 'DACT3', id: 'DACT3'},
{value: 'DACT3-AS1', id: 'DACT3-AS1'},
{value: 'DAD1', id: 'DAD1'},
{value: 'DAG1', id: 'DAG1'},
{value: 'DAGLA', id: 'DAGLA'},
{value: 'DAGLB', id: 'DAGLB'},
{value: 'DALRD3', id: 'DALRD3'},
{value: 'DANCR', id: 'DANCR'},
{value: 'DAND5', id: 'DAND5'},
{value: 'DAO', id: 'DAO'},
{value: 'DAOA-AS1', id: 'DAOA-AS1'},
{value: 'DAP', id: 'DAP'},
{value: 'DAP-DT', id: 'DAP-DT'},
{value: 'DAP3', id: 'DAP3'},
{value: 'DAP3P1', id: 'DAP3P1'},
{value: 'DAP3P2', id: 'DAP3P2'},
{value: 'DAPK1', id: 'DAPK1'},
{value: 'DAPK2', id: 'DAPK2'},
{value: 'DAPK3', id: 'DAPK3'},
{value: 'DAPL1', id: 'DAPL1'},
{value: 'DAPP1', id: 'DAPP1'},
{value: 'DARS1', id: 'DARS1'},
{value: 'DARS1-AS1', id: 'DARS1-AS1'},
{value: 'DARS2', id: 'DARS2'},
{value: 'DAW1', id: 'DAW1'},
{value: 'DAXX', id: 'DAXX'},
{value: 'DAZAP1', id: 'DAZAP1'},
{value: 'DAZAP2', id: 'DAZAP2'},
{value: 'DAZAP2P1', id: 'DAZAP2P1'},
{value: 'DAZL', id: 'DAZL'},
{value: 'DBET', id: 'DBET'},
{value: 'DBF4', id: 'DBF4'},
{value: 'DBF4B', id: 'DBF4B'},
{value: 'DBF4P1', id: 'DBF4P1'},
{value: 'DBF4P3', id: 'DBF4P3'},
{value: 'DBH', id: 'DBH'},
{value: 'DBH-AS1', id: 'DBH-AS1'},
{value: 'DBI', id: 'DBI'},
{value: 'DBIL5P', id: 'DBIL5P'},
{value: 'DBIL5P2', id: 'DBIL5P2'},
{value: 'DBIP1', id: 'DBIP1'},
{value: 'DBIP2', id: 'DBIP2'},
{value: 'DBN1', id: 'DBN1'},
{value: 'DBNDD1', id: 'DBNDD1'},
{value: 'DBNDD2', id: 'DBNDD2'},
{value: 'DBNL', id: 'DBNL'},
{value: 'DBP', id: 'DBP'},
{value: 'DBR1', id: 'DBR1'},
{value: 'DBT', id: 'DBT'},
{value: 'DBX1', id: 'DBX1'},
{value: 'DBX2', id: 'DBX2'},
{value: 'DBX2-AS1', id: 'DBX2-AS1'},
{value: 'DCAF1', id: 'DCAF1'},
{value: 'DCAF10', id: 'DCAF10'},
{value: 'DCAF11', id: 'DCAF11'},
{value: 'DCAF12', id: 'DCAF12'},
{value: 'DCAF12L2', id: 'DCAF12L2'},
{value: 'DCAF13', id: 'DCAF13'},
{value: 'DCAF13P1', id: 'DCAF13P1'},
{value: 'DCAF13P3', id: 'DCAF13P3'},
{value: 'DCAF15', id: 'DCAF15'},
{value: 'DCAF16', id: 'DCAF16'},
{value: 'DCAF17', id: 'DCAF17'},
{value: 'DCAF4', id: 'DCAF4'},
{value: 'DCAF4L1', id: 'DCAF4L1'},
{value: 'DCAF4L2', id: 'DCAF4L2'},
{value: 'DCAF5', id: 'DCAF5'},
{value: 'DCAF6', id: 'DCAF6'},
{value: 'DCAF7', id: 'DCAF7'},
{value: 'DCAF8', id: 'DCAF8'},
{value: 'DCAKD', id: 'DCAKD'},
{value: 'DCANP1', id: 'DCANP1'},
{value: 'DCBLD1', id: 'DCBLD1'},
{value: 'DCBLD2', id: 'DCBLD2'},
{value: 'DCC', id: 'DCC'},
{value: 'DCD', id: 'DCD'},
{value: 'DCDC1', id: 'DCDC1'},
{value: 'DCDC2', id: 'DCDC2'},
{value: 'DCDC2B', id: 'DCDC2B'},
{value: 'DCDC2C', id: 'DCDC2C'},
{value: 'DCHS1', id: 'DCHS1'},
{value: 'DCHS1-AS1', id: 'DCHS1-AS1'},
{value: 'DCHS2', id: 'DCHS2'},
{value: 'DCK', id: 'DCK'},
{value: 'DCLK1', id: 'DCLK1'},
{value: 'DCLK2', id: 'DCLK2'},
{value: 'DCLK3', id: 'DCLK3'},
{value: 'DCLRE1A', id: 'DCLRE1A'},
{value: 'DCLRE1B', id: 'DCLRE1B'},
{value: 'DCLRE1C', id: 'DCLRE1C'},
{value: 'DCLRE1CP1', id: 'DCLRE1CP1'},
{value: 'DCN', id: 'DCN'},
{value: 'DCP1A', id: 'DCP1A'},
{value: 'DCP1B', id: 'DCP1B'},
{value: 'DCP2', id: 'DCP2'},
{value: 'DCPS', id: 'DCPS'},
{value: 'DCST1', id: 'DCST1'},
{value: 'DCST1-AS1', id: 'DCST1-AS1'},
{value: 'DCST2', id: 'DCST2'},
{value: 'DCSTAMP', id: 'DCSTAMP'},
{value: 'DCT', id: 'DCT'},
{value: 'DCTD', id: 'DCTD'},
{value: 'DCTN1', id: 'DCTN1'},
{value: 'DCTN1-AS1', id: 'DCTN1-AS1'},
{value: 'DCTN2', id: 'DCTN2'},
{value: 'DCTN3', id: 'DCTN3'},
{value: 'DCTN4', id: 'DCTN4'},
{value: 'DCTN5', id: 'DCTN5'},
{value: 'DCTN6', id: 'DCTN6'},
{value: 'DCTN6-DT', id: 'DCTN6-DT'},
{value: 'DCTPP1', id: 'DCTPP1'},
{value: 'DCUN1D1', id: 'DCUN1D1'},
{value: 'DCUN1D2', id: 'DCUN1D2'},
{value: 'DCUN1D2-AS', id: 'DCUN1D2-AS'},
{value: 'DCUN1D3', id: 'DCUN1D3'},
{value: 'DCUN1D4', id: 'DCUN1D4'},
{value: 'DCUN1D5', id: 'DCUN1D5'},
{value: 'DCX', id: 'DCX'},
{value: 'DCXR', id: 'DCXR'},
{value: 'DCXR-DT', id: 'DCXR-DT'},
{value: 'DDA1', id: 'DDA1'},
{value: 'DDAH1', id: 'DDAH1'},
{value: 'DDAH2', id: 'DDAH2'},
{value: 'DDB1', id: 'DDB1'},
{value: 'DDB2', id: 'DDB2'},
{value: 'DDC', id: 'DDC'},
{value: 'DDC-AS1', id: 'DDC-AS1'},
{value: 'DDHD1', id: 'DDHD1'},
{value: 'DDHD1-DT', id: 'DDHD1-DT'},
{value: 'DDHD2', id: 'DDHD2'},
{value: 'DDI1', id: 'DDI1'},
{value: 'DDI2', id: 'DDI2'},
{value: 'DDIAS', id: 'DDIAS'},
{value: 'DDIT3', id: 'DDIT3'},
{value: 'DDIT4', id: 'DDIT4'},
{value: 'DDIT4L', id: 'DDIT4L'},
{value: 'DDIT4L-AS1', id: 'DDIT4L-AS1'},
{value: 'DDN', id: 'DDN'},
{value: 'DDN-AS1', id: 'DDN-AS1'},
{value: 'DDO', id: 'DDO'},
{value: 'DDOST', id: 'DDOST'},
{value: 'DDR1', id: 'DDR1'},
{value: 'DDR1-DT', id: 'DDR1-DT'},
{value: 'DDR2', id: 'DDR2'},
{value: 'DDRGK1', id: 'DDRGK1'},
{value: 'DDT', id: 'DDT'},
{value: 'DDTL', id: 'DDTL'},
{value: 'DDTP1', id: 'DDTP1'},
{value: 'DDX1', id: 'DDX1'},
{value: 'DDX10', id: 'DDX10'},
{value: 'DDX10P1', id: 'DDX10P1'},
{value: 'DDX10P2', id: 'DDX10P2'},
{value: 'DDX11', id: 'DDX11'},
{value: 'DDX11-AS1', id: 'DDX11-AS1'},
{value: 'DDX11L1', id: 'DDX11L1'},
{value: 'DDX11L10', id: 'DDX11L10'},
{value: 'DDX11L16', id: 'DDX11L16'},
{value: 'DDX11L17', id: 'DDX11L17'},
{value: 'DDX11L2', id: 'DDX11L2'},
{value: 'DDX11L5', id: 'DDX11L5'},
{value: 'DDX11L8', id: 'DDX11L8'},
{value: 'DDX11L9', id: 'DDX11L9'},
{value: 'DDX12P', id: 'DDX12P'},
{value: 'DDX17', id: 'DDX17'},
{value: 'DDX18', id: 'DDX18'},
{value: 'DDX18P1', id: 'DDX18P1'},
{value: 'DDX18P3', id: 'DDX18P3'},
{value: 'DDX18P4', id: 'DDX18P4'},
{value: 'DDX18P6', id: 'DDX18P6'},
{value: 'DDX19A', id: 'DDX19A'},
{value: 'DDX19A-DT', id: 'DDX19A-DT'},
{value: 'DDX19B', id: 'DDX19B'},
{value: 'DDX20', id: 'DDX20'},
{value: 'DDX21', id: 'DDX21'},
{value: 'DDX23', id: 'DDX23'},
{value: 'DDX24', id: 'DDX24'},
{value: 'DDX25', id: 'DDX25'},
{value: 'DDX27', id: 'DDX27'},
{value: 'DDX28', id: 'DDX28'},
{value: 'DDX31', id: 'DDX31'},
{value: 'DDX39A', id: 'DDX39A'},
{value: 'DDX39AP1', id: 'DDX39AP1'},
{value: 'DDX39B', id: 'DDX39B'},
{value: 'DDX39B-AS1', id: 'DDX39B-AS1'},
{value: 'DDX39BP1', id: 'DDX39BP1'},
{value: 'DDX3ILA1', id: 'DDX3ILA1'},
{value: 'DDX3P3', id: 'DDX3P3'},
{value: 'DDX3X', id: 'DDX3X'},
{value: 'DDX3Y', id: 'DDX3Y'},
{value: 'DDX4', id: 'DDX4'},
{value: 'DDX41', id: 'DDX41'},
{value: 'DDX42', id: 'DDX42'},
{value: 'DDX43', id: 'DDX43'},
{value: 'DDX43P2', id: 'DDX43P2'},
{value: 'DDX43P3', id: 'DDX43P3'},
{value: 'DDX46', id: 'DDX46'},
{value: 'DDX47', id: 'DDX47'},
{value: 'DDX49', id: 'DDX49'},
{value: 'DDX5', id: 'DDX5'},
{value: 'DDX50', id: 'DDX50'},
{value: 'DDX50P1', id: 'DDX50P1'},
{value: 'DDX51', id: 'DDX51'},
{value: 'DDX52', id: 'DDX52'},
{value: 'DDX54', id: 'DDX54'},
{value: 'DDX55', id: 'DDX55'},
{value: 'DDX55P1', id: 'DDX55P1'},
{value: 'DDX56', id: 'DDX56'},
{value: 'DDX58', id: 'DDX58'},
{value: 'DDX59', id: 'DDX59'},
{value: 'DDX59-AS1', id: 'DDX59-AS1'},
{value: 'DDX6', id: 'DDX6'},
{value: 'DDX60', id: 'DDX60'},
{value: 'DDX60L', id: 'DDX60L'},
{value: 'DDX6P1', id: 'DDX6P1'},
{value: 'DDX6P2', id: 'DDX6P2'},
{value: 'DEAF1', id: 'DEAF1'},
{value: 'DECR1', id: 'DECR1'},
{value: 'DECR2', id: 'DECR2'},
{value: 'DEDD', id: 'DEDD'},
{value: 'DEDD2', id: 'DEDD2'},
{value: 'DEF6', id: 'DEF6'},
{value: 'DEF8', id: 'DEF8'},
{value: 'DEFA1', id: 'DEFA1'},
{value: 'DEFA10P', id: 'DEFA10P'},
{value: 'DEFA11P', id: 'DEFA11P'},
{value: 'DEFA1B', id: 'DEFA1B'},
{value: 'DEFA3', id: 'DEFA3'},
{value: 'DEFA4', id: 'DEFA4'},
{value: 'DEFA5', id: 'DEFA5'},
{value: 'DEFA6', id: 'DEFA6'},
{value: 'DEFA7P', id: 'DEFA7P'},
{value: 'DEFA8P', id: 'DEFA8P'},
{value: 'DEFA9P', id: 'DEFA9P'},
{value: 'DEFB1', id: 'DEFB1'},
{value: 'DEFB103A', id: 'DEFB103A'},
{value: 'DEFB103B', id: 'DEFB103B'},
{value: 'DEFB104A', id: 'DEFB104A'},
{value: 'DEFB104B', id: 'DEFB104B'},
{value: 'DEFB105A', id: 'DEFB105A'},
{value: 'DEFB105B', id: 'DEFB105B'},
{value: 'DEFB106A', id: 'DEFB106A'},
{value: 'DEFB106B', id: 'DEFB106B'},
{value: 'DEFB107A', id: 'DEFB107A'},
{value: 'DEFB107B', id: 'DEFB107B'},
{value: 'DEFB108B', id: 'DEFB108B'},
{value: 'DEFB108F', id: 'DEFB108F'},
{value: 'DEFB109A', id: 'DEFB109A'},
{value: 'DEFB110', id: 'DEFB110'},
{value: 'DEFB112', id: 'DEFB112'},
{value: 'DEFB113', id: 'DEFB113'},
{value: 'DEFB114', id: 'DEFB114'},
{value: 'DEFB115', id: 'DEFB115'},
{value: 'DEFB116', id: 'DEFB116'},
{value: 'DEFB117', id: 'DEFB117'},
{value: 'DEFB118', id: 'DEFB118'},
{value: 'DEFB119', id: 'DEFB119'},
{value: 'DEFB121', id: 'DEFB121'},
{value: 'DEFB122', id: 'DEFB122'},
{value: 'DEFB123', id: 'DEFB123'},
{value: 'DEFB124', id: 'DEFB124'},
{value: 'DEFB125', id: 'DEFB125'},
{value: 'DEFB126', id: 'DEFB126'},
{value: 'DEFB127', id: 'DEFB127'},
{value: 'DEFB128', id: 'DEFB128'},
{value: 'DEFB129', id: 'DEFB129'},
{value: 'DEFB130A', id: 'DEFB130A'},
{value: 'DEFB130B', id: 'DEFB130B'},
{value: 'DEFB130C', id: 'DEFB130C'},
{value: 'DEFB130D', id: 'DEFB130D'},
{value: 'DEFB131A', id: 'DEFB131A'},
{value: 'DEFB131E', id: 'DEFB131E'},
{value: 'DEFB132', id: 'DEFB132'},
{value: 'DEFB133', id: 'DEFB133'},
{value: 'DEFB134', id: 'DEFB134'},
{value: 'DEFB135', id: 'DEFB135'},
{value: 'DEFB136', id: 'DEFB136'},
{value: 'DEFB4A', id: 'DEFB4A'},
{value: 'DEFB4B', id: 'DEFB4B'},
{value: 'DEFT1P', id: 'DEFT1P'},
{value: 'DEFT1P2', id: 'DEFT1P2'},
{value: 'DEGS1', id: 'DEGS1'},
{value: 'DEGS2', id: 'DEGS2'},
{value: 'DEK', id: 'DEK'},
{value: 'DELE1', id: 'DELE1'},
{value: 'DELEC1', id: 'DELEC1'},
{value: 'DENND10', id: 'DENND10'},
{value: 'DENND10P1', id: 'DENND10P1'},
{value: 'DENND11', id: 'DENND11'},
{value: 'DENND1A', id: 'DENND1A'},
{value: 'DENND1B', id: 'DENND1B'},
{value: 'DENND1C', id: 'DENND1C'},
{value: 'DENND2A', id: 'DENND2A'},
{value: 'DENND2B', id: 'DENND2B'},
{value: 'DENND2C', id: 'DENND2C'},
{value: 'DENND2D', id: 'DENND2D'},
{value: 'DENND3', id: 'DENND3'},
{value: 'DENND3-AS1', id: 'DENND3-AS1'},
{value: 'DENND4A', id: 'DENND4A'},
{value: 'DENND4B', id: 'DENND4B'},
{value: 'DENND4C', id: 'DENND4C'},
{value: 'DENND5A', id: 'DENND5A'},
{value: 'DENND5B', id: 'DENND5B'},
{value: 'DENND5B-AS1', id: 'DENND5B-AS1'},
{value: 'DENND6A', id: 'DENND6A'},
{value: 'DENND6A-DT', id: 'DENND6A-DT'},
{value: 'DENND6B', id: 'DENND6B'},
{value: 'DENR', id: 'DENR'},
{value: 'DEPDC1', id: 'DEPDC1'},
{value: 'DEPDC1-AS1', id: 'DEPDC1-AS1'},
{value: 'DEPDC1B', id: 'DEPDC1B'},
{value: 'DEPDC1P1', id: 'DEPDC1P1'},
{value: 'DEPDC1P2', id: 'DEPDC1P2'},
{value: 'DEPDC4', id: 'DEPDC4'},
{value: 'DEPDC5', id: 'DEPDC5'},
{value: 'DEPDC7', id: 'DEPDC7'},
{value: 'DEPP1', id: 'DEPP1'},
{value: 'DEPTOR', id: 'DEPTOR'},
{value: 'DERA', id: 'DERA'},
{value: 'DERL1', id: 'DERL1'},
{value: 'DERL2', id: 'DERL2'},
{value: 'DERL3', id: 'DERL3'},
{value: 'DERPC', id: 'DERPC'},
{value: 'DES', id: 'DES'},
{value: 'DESI1', id: 'DESI1'},
{value: 'DESI2', id: 'DESI2'},
{value: 'DET1', id: 'DET1'},
{value: 'DEUP1', id: 'DEUP1'},
{value: 'DEXI', id: 'DEXI'},
{value: 'DFFA', id: 'DFFA'},
{value: 'DFFB', id: 'DFFB'},
{value: 'DFFBP1', id: 'DFFBP1'},
{value: 'DGAT1', id: 'DGAT1'},
{value: 'DGAT2', id: 'DGAT2'},
{value: 'DGAT2-DT', id: 'DGAT2-DT'},
{value: 'DGAT2L7P', id: 'DGAT2L7P'},
{value: 'DGCR11', id: 'DGCR11'},
{value: 'DGCR2', id: 'DGCR2'},
{value: 'DGCR5', id: 'DGCR5'},
{value: 'DGCR6', id: 'DGCR6'},
{value: 'DGCR6L', id: 'DGCR6L'},
{value: 'DGCR8', id: 'DGCR8'},
{value: 'DGKA', id: 'DGKA'},
{value: 'DGKB', id: 'DGKB'},
{value: 'DGKD', id: 'DGKD'},
{value: 'DGKE', id: 'DGKE'},
{value: 'DGKG', id: 'DGKG'},
{value: 'DGKH', id: 'DGKH'},
{value: 'DGKI', id: 'DGKI'},
{value: 'DGKK', id: 'DGKK'},
{value: 'DGKQ', id: 'DGKQ'},
{value: 'DGKZ', id: 'DGKZ'},
{value: 'DGKZP1', id: 'DGKZP1'},
{value: 'DGLUCY', id: 'DGLUCY'},
{value: 'DGUOK', id: 'DGUOK'},
{value: 'DGUOK-AS1', id: 'DGUOK-AS1'},
{value: 'DHCR24', id: 'DHCR24'},
{value: 'DHCR7', id: 'DHCR7'},
{value: 'DHDDS', id: 'DHDDS'},
{value: 'DHDDS-AS1', id: 'DHDDS-AS1'},
{value: 'DHDH', id: 'DHDH'},
{value: 'DHFR', id: 'DHFR'},
{value: 'DHFR2', id: 'DHFR2'},
{value: 'DHFRP1', id: 'DHFRP1'},
{value: 'DHFRP2', id: 'DHFRP2'},
{value: 'DHFRP3', id: 'DHFRP3'},
{value: 'DHFRP5', id: 'DHFRP5'},
{value: 'DHH', id: 'DHH'},
{value: 'DHODH', id: 'DHODH'},
{value: 'DHPS', id: 'DHPS'},
{value: 'DHRS1', id: 'DHRS1'},
{value: 'DHRS11', id: 'DHRS11'},
{value: 'DHRS12', id: 'DHRS12'},
{value: 'DHRS13', id: 'DHRS13'},
{value: 'DHRS2', id: 'DHRS2'},
{value: 'DHRS3', id: 'DHRS3'},
{value: 'DHRS4', id: 'DHRS4'},
{value: 'DHRS4-AS1', id: 'DHRS4-AS1'},
{value: 'DHRS4L1', id: 'DHRS4L1'},
{value: 'DHRS4L2', id: 'DHRS4L2'},
{value: 'DHRS7', id: 'DHRS7'},
{value: 'DHRS7B', id: 'DHRS7B'},
{value: 'DHRS7C', id: 'DHRS7C'},
{value: 'DHRS9', id: 'DHRS9'},
{value: 'DHRSX', id: 'DHRSX'},
{value: 'DHTKD1', id: 'DHTKD1'},
{value: 'DHX15', id: 'DHX15'},
{value: 'DHX16', id: 'DHX16'},
{value: 'DHX29', id: 'DHX29'},
{value: 'DHX30', id: 'DHX30'},
{value: 'DHX32', id: 'DHX32'},
{value: 'DHX33', id: 'DHX33'},
{value: 'DHX33-DT', id: 'DHX33-DT'},
{value: 'DHX34', id: 'DHX34'},
{value: 'DHX35', id: 'DHX35'},
{value: 'DHX35-DT', id: 'DHX35-DT'},
{value: 'DHX36', id: 'DHX36'},
{value: 'DHX37', id: 'DHX37'},
{value: 'DHX38', id: 'DHX38'},
{value: 'DHX40', id: 'DHX40'},
{value: 'DHX40P1', id: 'DHX40P1'},
{value: 'DHX57', id: 'DHX57'},
{value: 'DHX58', id: 'DHX58'},
{value: 'DHX8', id: 'DHX8'},
{value: 'DHX9', id: 'DHX9'},
{value: 'DHX9P1', id: 'DHX9P1'},
{value: 'DIABLO', id: 'DIABLO'},
{value: 'DIAPH1', id: 'DIAPH1'},
{value: 'DIAPH1-AS1', id: 'DIAPH1-AS1'},
{value: 'DIAPH2', id: 'DIAPH2'},
{value: 'DIAPH3', id: 'DIAPH3'},
{value: 'DIAPH3-AS1', id: 'DIAPH3-AS1'},
{value: 'DIAPH3-AS2', id: 'DIAPH3-AS2'},
{value: 'DICER1', id: 'DICER1'},
{value: 'DICER1-AS1', id: 'DICER1-AS1'},
{value: 'DIDO1', id: 'DIDO1'},
{value: 'DIMT1', id: 'DIMT1'},
{value: 'DINOL', id: 'DINOL'},
{value: 'DIO1', id: 'DIO1'},
{value: 'DIO2', id: 'DIO2'},
{value: 'DIO2-AS1', id: 'DIO2-AS1'},
{value: 'DIO3', id: 'DIO3'},
{value: 'DIO3OS', id: 'DIO3OS'},
{value: 'DIP2A', id: 'DIP2A'},
{value: 'DIP2B', id: 'DIP2B'},
{value: 'DIP2C', id: 'DIP2C'},
{value: 'DIP2C-AS1', id: 'DIP2C-AS1'},
{value: 'DIPK1A', id: 'DIPK1A'},
{value: 'DIPK1B', id: 'DIPK1B'},
{value: 'DIPK1C', id: 'DIPK1C'},
{value: 'DIPK2A', id: 'DIPK2A'},
{value: 'DIPK2B', id: 'DIPK2B'},
{value: 'DIRAS1', id: 'DIRAS1'},
{value: 'DIRAS2', id: 'DIRAS2'},
{value: 'DIRAS3', id: 'DIRAS3'},
{value: 'DIRC3', id: 'DIRC3'},
{value: 'DIRC3-AS1', id: 'DIRC3-AS1'},
{value: 'DIS3', id: 'DIS3'},
{value: 'DIS3L', id: 'DIS3L'},
{value: 'DIS3L-AS1', id: 'DIS3L-AS1'},
{value: 'DIS3L2', id: 'DIS3L2'},
{value: 'DIS3L2P1', id: 'DIS3L2P1'},
{value: 'DISC1', id: 'DISC1'},
{value: 'DISC1-IT1', id: 'DISC1-IT1'},
{value: 'DISC1FP1', id: 'DISC1FP1'},
{value: 'DISP1', id: 'DISP1'},
{value: 'DISP2', id: 'DISP2'},
{value: 'DISP3', id: 'DISP3'},
{value: 'DIXDC1', id: 'DIXDC1'},
{value: 'DKC1', id: 'DKC1'},
{value: 'DKFZP434A062', id: 'DKFZP434A062'},
{value: 'DKK1', id: 'DKK1'},
{value: 'DKK2', id: 'DKK2'},
{value: 'DKK3', id: 'DKK3'},
{value: 'DKK4', id: 'DKK4'},
{value: 'DKKL1', id: 'DKKL1'},
{value: 'DKKL1P1', id: 'DKKL1P1'},
{value: 'DLAT', id: 'DLAT'},
{value: 'DLC1', id: 'DLC1'},
{value: 'DLD', id: 'DLD'},
{value: 'DLEC1', id: 'DLEC1'},
{value: 'DLEC1P1', id: 'DLEC1P1'},
{value: 'DLEU1', id: 'DLEU1'},
{value: 'DLEU2', id: 'DLEU2'},
{value: 'DLEU2L', id: 'DLEU2L'},
{value: 'DLEU7', id: 'DLEU7'},
{value: 'DLG1', id: 'DLG1'},
{value: 'DLG1-AS1', id: 'DLG1-AS1'},
{value: 'DLG2', id: 'DLG2'},
{value: 'DLG3', id: 'DLG3'},
{value: 'DLG4', id: 'DLG4'},
{value: 'DLG5', id: 'DLG5'},
{value: 'DLG5-AS1', id: 'DLG5-AS1'},
{value: 'DLGAP1', id: 'DLGAP1'},
{value: 'DLGAP1-AS1', id: 'DLGAP1-AS1'},
{value: 'DLGAP1-AS2', id: 'DLGAP1-AS2'},
{value: 'DLGAP1-AS3', id: 'DLGAP1-AS3'},
{value: 'DLGAP1-AS5', id: 'DLGAP1-AS5'},
{value: 'DLGAP2', id: 'DLGAP2'},
{value: 'DLGAP3', id: 'DLGAP3'},
{value: 'DLGAP4', id: 'DLGAP4'},
{value: 'DLGAP4-AS1', id: 'DLGAP4-AS1'},
{value: 'DLGAP5', id: 'DLGAP5'},
{value: 'DLK1', id: 'DLK1'},
{value: 'DLK2', id: 'DLK2'},
{value: 'DLL1', id: 'DLL1'},
{value: 'DLL3', id: 'DLL3'},
{value: 'DLL4', id: 'DLL4'},
{value: 'DLST', id: 'DLST'},
{value: 'DLSTP1', id: 'DLSTP1'},
{value: 'DLX1', id: 'DLX1'},
{value: 'DLX2', id: 'DLX2'},
{value: 'DLX2-DT', id: 'DLX2-DT'},
{value: 'DLX3', id: 'DLX3'},
{value: 'DLX4', id: 'DLX4'},
{value: 'DLX5', id: 'DLX5'},
{value: 'DLX6', id: 'DLX6'},
{value: 'DLX6-AS1', id: 'DLX6-AS1'},
{value: 'DM1-AS', id: 'DM1-AS'},
{value: 'DMAC1', id: 'DMAC1'},
{value: 'DMAC1P1', id: 'DMAC1P1'},
{value: 'DMAC2', id: 'DMAC2'},
{value: 'DMAC2L', id: 'DMAC2L'},
{value: 'DMAP1', id: 'DMAP1'},
{value: 'DMBT1', id: 'DMBT1'},
{value: 'DMBT1L1', id: 'DMBT1L1'},
{value: 'DMBX1', id: 'DMBX1'},
{value: 'DMC1', id: 'DMC1'},
{value: 'DMD', id: 'DMD'},
{value: 'DMGDH', id: 'DMGDH'},
{value: 'DMKN', id: 'DMKN'},
{value: 'DMPK', id: 'DMPK'},
{value: 'DMRT1', id: 'DMRT1'},
{value: 'DMRT2', id: 'DMRT2'},
{value: 'DMRT3', id: 'DMRT3'},
{value: 'DMRTA1', id: 'DMRTA1'},
{value: 'DMRTA2', id: 'DMRTA2'},
{value: 'DMRTB1', id: 'DMRTB1'},
{value: 'DMRTC1', id: 'DMRTC1'},
{value: 'DMRTC1B', id: 'DMRTC1B'},
{value: 'DMRTC2', id: 'DMRTC2'},
{value: 'DMTF1', id: 'DMTF1'},
{value: 'DMTN', id: 'DMTN'},
{value: 'DMWD', id: 'DMWD'},
{value: 'DMXL1', id: 'DMXL1'},
{value: 'DMXL1-DT', id: 'DMXL1-DT'},
{value: 'DMXL2', id: 'DMXL2'},
{value: 'DNA2', id: 'DNA2'},
{value: 'DNAAF1', id: 'DNAAF1'},
{value: 'DNAAF10', id: 'DNAAF10'},
{value: 'DNAAF11', id: 'DNAAF11'},
{value: 'DNAAF11P1', id: 'DNAAF11P1'},
{value: 'DNAAF2', id: 'DNAAF2'},
{value: 'DNAAF3', id: 'DNAAF3'},
{value: 'DNAAF3-AS1', id: 'DNAAF3-AS1'},
{value: 'DNAAF4', id: 'DNAAF4'},
{value: 'DNAAF4-CCPG1', id: 'DNAAF4-CCPG1'},
{value: 'DNAAF5', id: 'DNAAF5'},
{value: 'DNAAF8', id: 'DNAAF8'},
{value: 'DNAAF9', id: 'DNAAF9'},
{value: 'DNAH1', id: 'DNAH1'},
{value: 'DNAH10', id: 'DNAH10'},
{value: 'DNAH10OS', id: 'DNAH10OS'},
{value: 'DNAH11', id: 'DNAH11'},
{value: 'DNAH12', id: 'DNAH12'},
{value: 'DNAH14', id: 'DNAH14'},
{value: 'DNAH17', id: 'DNAH17'},
{value: 'DNAH17-AS1', id: 'DNAH17-AS1'},
{value: 'DNAH2', id: 'DNAH2'},
{value: 'DNAH3', id: 'DNAH3'},
{value: 'DNAH5', id: 'DNAH5'},
{value: 'DNAH6', id: 'DNAH6'},
{value: 'DNAH7', id: 'DNAH7'},
{value: 'DNAH8', id: 'DNAH8'},
{value: 'DNAH8-AS1', id: 'DNAH8-AS1'},
{value: 'DNAH9', id: 'DNAH9'},
{value: 'DNAI1', id: 'DNAI1'},
{value: 'DNAI2', id: 'DNAI2'},
{value: 'DNAI3', id: 'DNAI3'},
{value: 'DNAI4', id: 'DNAI4'},
{value: 'DNAI7', id: 'DNAI7'},
{value: 'DNAJA1', id: 'DNAJA1'},
{value: 'DNAJA1P1', id: 'DNAJA1P1'},
{value: 'DNAJA1P2', id: 'DNAJA1P2'},
{value: 'DNAJA1P4', id: 'DNAJA1P4'},
{value: 'DNAJA1P6', id: 'DNAJA1P6'},
{value: 'DNAJA2', id: 'DNAJA2'},
{value: 'DNAJA2-DT', id: 'DNAJA2-DT'},
{value: 'DNAJA3', id: 'DNAJA3'},
{value: 'DNAJA4', id: 'DNAJA4'},
{value: 'DNAJA4-DT', id: 'DNAJA4-DT'},
{value: 'DNAJB1', id: 'DNAJB1'},
{value: 'DNAJB11', id: 'DNAJB11'},
{value: 'DNAJB12', id: 'DNAJB12'},
{value: 'DNAJB12P1', id: 'DNAJB12P1'},
{value: 'DNAJB13', id: 'DNAJB13'},
{value: 'DNAJB14', id: 'DNAJB14'},
{value: 'DNAJB1P1', id: 'DNAJB1P1'},
{value: 'DNAJB2', id: 'DNAJB2'},
{value: 'DNAJB3', id: 'DNAJB3'},
{value: 'DNAJB4', id: 'DNAJB4'},
{value: 'DNAJB5', id: 'DNAJB5'},
{value: 'DNAJB5-DT', id: 'DNAJB5-DT'},
{value: 'DNAJB5P1', id: 'DNAJB5P1'},
{value: 'DNAJB6', id: 'DNAJB6'},
{value: 'DNAJB6P1', id: 'DNAJB6P1'},
{value: 'DNAJB6P3', id: 'DNAJB6P3'},
{value: 'DNAJB6P4', id: 'DNAJB6P4'},
{value: 'DNAJB6P5', id: 'DNAJB6P5'},
{value: 'DNAJB6P7', id: 'DNAJB6P7'},
{value: 'DNAJB6P8', id: 'DNAJB6P8'},
{value: 'DNAJB7', id: 'DNAJB7'},
{value: 'DNAJB8', id: 'DNAJB8'},
{value: 'DNAJB8-AS1', id: 'DNAJB8-AS1'},
{value: 'DNAJB9', id: 'DNAJB9'},
{value: 'DNAJC1', id: 'DNAJC1'},
{value: 'DNAJC10', id: 'DNAJC10'},
{value: 'DNAJC11', id: 'DNAJC11'},
{value: 'DNAJC12', id: 'DNAJC12'},
{value: 'DNAJC13', id: 'DNAJC13'},
{value: 'DNAJC14', id: 'DNAJC14'},
{value: 'DNAJC15', id: 'DNAJC15'},
{value: 'DNAJC16', id: 'DNAJC16'},
{value: 'DNAJC17', id: 'DNAJC17'},
{value: 'DNAJC17P1', id: 'DNAJC17P1'},
{value: 'DNAJC18', id: 'DNAJC18'},
{value: 'DNAJC19', id: 'DNAJC19'},
{value: 'DNAJC19P1', id: 'DNAJC19P1'},
{value: 'DNAJC19P2', id: 'DNAJC19P2'},
{value: 'DNAJC19P3', id: 'DNAJC19P3'},
{value: 'DNAJC19P5', id: 'DNAJC19P5'},
{value: 'DNAJC19P6', id: 'DNAJC19P6'},
{value: 'DNAJC19P7', id: 'DNAJC19P7'},
{value: 'DNAJC19P8', id: 'DNAJC19P8'},
{value: 'DNAJC19P9', id: 'DNAJC19P9'},
{value: 'DNAJC2', id: 'DNAJC2'},
{value: 'DNAJC21', id: 'DNAJC21'},
{value: 'DNAJC22', id: 'DNAJC22'},
{value: 'DNAJC24', id: 'DNAJC24'},
{value: 'DNAJC25', id: 'DNAJC25'},
{value: 'DNAJC25-GNG10', id: 'DNAJC25-GNG10'},
{value: 'DNAJC27', id: 'DNAJC27'},
{value: 'DNAJC27-AS1', id: 'DNAJC27-AS1'},
{value: 'DNAJC28', id: 'DNAJC28'},
{value: 'DNAJC3', id: 'DNAJC3'},
{value: 'DNAJC3-DT', id: 'DNAJC3-DT'},
{value: 'DNAJC30', id: 'DNAJC30'},
{value: 'DNAJC4', id: 'DNAJC4'},
{value: 'DNAJC5', id: 'DNAJC5'},
{value: 'DNAJC5B', id: 'DNAJC5B'},
{value: 'DNAJC5G', id: 'DNAJC5G'},
{value: 'DNAJC6', id: 'DNAJC6'},
{value: 'DNAJC7', id: 'DNAJC7'},
{value: 'DNAJC8', id: 'DNAJC8'},
{value: 'DNAJC8P1', id: 'DNAJC8P1'},
{value: 'DNAJC9', id: 'DNAJC9'},
{value: 'DNAJC9-AS1', id: 'DNAJC9-AS1'},
{value: 'DNAL1', id: 'DNAL1'},
{value: 'DNAL4', id: 'DNAL4'},
{value: 'DNALI1', id: 'DNALI1'},
{value: 'DNASE1', id: 'DNASE1'},
{value: 'DNASE1L1', id: 'DNASE1L1'},
{value: 'DNASE1L2', id: 'DNASE1L2'},
{value: 'DNASE1L3', id: 'DNASE1L3'},
{value: 'DNASE2', id: 'DNASE2'},
{value: 'DNASE2B', id: 'DNASE2B'},
{value: 'DND1', id: 'DND1'},
{value: 'DND1P1', id: 'DND1P1'},
{value: 'DNER', id: 'DNER'},
{value: 'DNHD1', id: 'DNHD1'},
{value: 'DNLZ', id: 'DNLZ'},
{value: 'DNM1', id: 'DNM1'},
{value: 'DNM1L', id: 'DNM1L'},
{value: 'DNM1P28', id: 'DNM1P28'},
{value: 'DNM1P30', id: 'DNM1P30'},
{value: 'DNM1P31', id: 'DNM1P31'},
{value: 'DNM1P32', id: 'DNM1P32'},
{value: 'DNM1P33', id: 'DNM1P33'},
{value: 'DNM1P34', id: 'DNM1P34'},
{value: 'DNM1P35', id: 'DNM1P35'},
{value: 'DNM1P38', id: 'DNM1P38'},
{value: 'DNM1P41', id: 'DNM1P41'},
{value: 'DNM1P46', id: 'DNM1P46'},
{value: 'DNM1P47', id: 'DNM1P47'},
{value: 'DNM1P49', id: 'DNM1P49'},
{value: 'DNM1P5', id: 'DNM1P5'},
{value: 'DNM1P50', id: 'DNM1P50'},
{value: 'DNM1P51', id: 'DNM1P51'},
{value: 'DNM2', id: 'DNM2'},
{value: 'DNM3', id: 'DNM3'},
{value: 'DNM3OS', id: 'DNM3OS'},
{value: 'DNMBP', id: 'DNMBP'},
{value: 'DNMBP-AS1', id: 'DNMBP-AS1'},
{value: 'DNMT1', id: 'DNMT1'},
{value: 'DNMT3A', id: 'DNMT3A'},
{value: 'DNMT3AP1', id: 'DNMT3AP1'},
{value: 'DNMT3B', id: 'DNMT3B'},
{value: 'DNMT3L', id: 'DNMT3L'},
{value: 'DNPEP', id: 'DNPEP'},
{value: 'DNPH1', id: 'DNPH1'},
{value: 'DNTT', id: 'DNTT'},
{value: 'DNTTIP1', id: 'DNTTIP1'},
{value: 'DNTTIP2', id: 'DNTTIP2'},
{value: 'DOC2A', id: 'DOC2A'},
{value: 'DOC2B', id: 'DOC2B'},
{value: 'DOC2GP', id: 'DOC2GP'},
{value: 'DOCK1', id: 'DOCK1'},
{value: 'DOCK10', id: 'DOCK10'},
{value: 'DOCK11', id: 'DOCK11'},
{value: 'DOCK11P1', id: 'DOCK11P1'},
{value: 'DOCK2', id: 'DOCK2'},
{value: 'DOCK3', id: 'DOCK3'},
{value: 'DOCK4', id: 'DOCK4'},
{value: 'DOCK5', id: 'DOCK5'},
{value: 'DOCK6', id: 'DOCK6'},
{value: 'DOCK7', id: 'DOCK7'},
{value: 'DOCK8', id: 'DOCK8'},
{value: 'DOCK8-AS1', id: 'DOCK8-AS1'},
{value: 'DOCK9', id: 'DOCK9'},
{value: 'DOCK9-AS1', id: 'DOCK9-AS1'},
{value: 'DOCK9-DT', id: 'DOCK9-DT'},
{value: 'DOHH', id: 'DOHH'},
{value: 'DOK1', id: 'DOK1'},
{value: 'DOK2', id: 'DOK2'},
{value: 'DOK3', id: 'DOK3'},
{value: 'DOK4', id: 'DOK4'},
{value: 'DOK5', id: 'DOK5'},
{value: 'DOK6', id: 'DOK6'},
{value: 'DOK7', id: 'DOK7'},
{value: 'DOLK', id: 'DOLK'},
{value: 'DOLPP1', id: 'DOLPP1'},
{value: 'DONSON', id: 'DONSON'},
{value: 'DOP1A', id: 'DOP1A'},
{value: 'DOP1B', id: 'DOP1B'},
{value: 'DOT1L', id: 'DOT1L'},
{value: 'DPAGT1', id: 'DPAGT1'},
{value: 'DPCD', id: 'DPCD'},
{value: 'DPEP1', id: 'DPEP1'},
{value: 'DPEP2', id: 'DPEP2'},
{value: 'DPEP2NB', id: 'DPEP2NB'},
{value: 'DPEP3', id: 'DPEP3'},
{value: 'DPF1', id: 'DPF1'},
{value: 'DPF2', id: 'DPF2'},
{value: 'DPF3', id: 'DPF3'},
{value: 'DPH1', id: 'DPH1'},
{value: 'DPH2', id: 'DPH2'},
{value: 'DPH3', id: 'DPH3'},
{value: 'DPH5', id: 'DPH5'},
{value: 'DPH6', id: 'DPH6'},
{value: 'DPH6-DT', id: 'DPH6-DT'},
{value: 'DPH7', id: 'DPH7'},
{value: 'DPM1', id: 'DPM1'},
{value: 'DPM2', id: 'DPM2'},
{value: 'DPM3', id: 'DPM3'},
{value: 'DPP10', id: 'DPP10'},
{value: 'DPP10-AS1', id: 'DPP10-AS1'},
{value: 'DPP10-AS3', id: 'DPP10-AS3'},
{value: 'DPP3', id: 'DPP3'},
{value: 'DPP3-DT', id: 'DPP3-DT'},
{value: 'DPP3P1', id: 'DPP3P1'},
{value: 'DPP3P2', id: 'DPP3P2'},
{value: 'DPP4', id: 'DPP4'},
{value: 'DPP4-DT', id: 'DPP4-DT'},
{value: 'DPP6', id: 'DPP6'},
{value: 'DPP7', id: 'DPP7'},
{value: 'DPP8', id: 'DPP8'},
{value: 'DPP9', id: 'DPP9'},
{value: 'DPP9-AS1', id: 'DPP9-AS1'},
{value: 'DPPA2', id: 'DPPA2'},
{value: 'DPPA2P2', id: 'DPPA2P2'},
{value: 'DPPA2P4', id: 'DPPA2P4'},
{value: 'DPPA3', id: 'DPPA3'},
{value: 'DPPA3P3', id: 'DPPA3P3'},
{value: 'DPPA4', id: 'DPPA4'},
{value: 'DPPA5', id: 'DPPA5'},
{value: 'DPPA5P1', id: 'DPPA5P1'},
{value: 'DPPA5P4', id: 'DPPA5P4'},
{value: 'DPRX', id: 'DPRX'},
{value: 'DPRXP1', id: 'DPRXP1'},
{value: 'DPRXP2', id: 'DPRXP2'},
{value: 'DPRXP4', id: 'DPRXP4'},
{value: 'DPT', id: 'DPT'},
{value: 'DPY19L1', id: 'DPY19L1'},
{value: 'DPY19L1P1', id: 'DPY19L1P1'},
{value: 'DPY19L1P2', id: 'DPY19L1P2'},
{value: 'DPY19L2', id: 'DPY19L2'},
{value: 'DPY19L2P1', id: 'DPY19L2P1'},
{value: 'DPY19L2P2', id: 'DPY19L2P2'},
{value: 'DPY19L2P3', id: 'DPY19L2P3'},
{value: 'DPY19L2P4', id: 'DPY19L2P4'},
{value: 'DPY19L2P5', id: 'DPY19L2P5'},
{value: 'DPY19L3', id: 'DPY19L3'},
{value: 'DPY19L3-DT', id: 'DPY19L3-DT'},
{value: 'DPY19L4', id: 'DPY19L4'},
{value: 'DPY19L4P1', id: 'DPY19L4P1'},
{value: 'DPY19L4P2', id: 'DPY19L4P2'},
{value: 'DPY30', id: 'DPY30'},
{value: 'DPYD', id: 'DPYD'},
{value: 'DPYD-AS1', id: 'DPYD-AS1'},
{value: 'DPYD-AS2', id: 'DPYD-AS2'},
{value: 'DPYD-IT1', id: 'DPYD-IT1'},
{value: 'DPYS', id: 'DPYS'},
{value: 'DPYSL2', id: 'DPYSL2'},
{value: 'DPYSL3', id: 'DPYSL3'},
{value: 'DPYSL4', id: 'DPYSL4'},
{value: 'DPYSL5', id: 'DPYSL5'},
{value: 'DQX1', id: 'DQX1'},
{value: 'DR1', id: 'DR1'},
{value: 'DRAIC', id: 'DRAIC'},
{value: 'DRAM1', id: 'DRAM1'},
{value: 'DRAM2', id: 'DRAM2'},
{value: 'DRAP1', id: 'DRAP1'},
{value: 'DRAXIN', id: 'DRAXIN'},
{value: 'DRC1', id: 'DRC1'},
{value: 'DRC3', id: 'DRC3'},
{value: 'DRC7', id: 'DRC7'},
{value: 'DRD1', id: 'DRD1'},
{value: 'DRD2', id: 'DRD2'},
{value: 'DRD3', id: 'DRD3'},
{value: 'DRD4', id: 'DRD4'},
{value: 'DRD5P1', id: 'DRD5P1'},
{value: 'DRG1', id: 'DRG1'},
{value: 'DRG1P1', id: 'DRG1P1'},
{value: 'DRG2', id: 'DRG2'},
{value: 'DRGX', id: 'DRGX'},
{value: 'DRICH1', id: 'DRICH1'},
{value: 'DROSHA', id: 'DROSHA'},
{value: 'DSC1', id: 'DSC1'},
{value: 'DSC2', id: 'DSC2'},
{value: 'DSC3', id: 'DSC3'},
{value: 'DSCAM', id: 'DSCAM'},
{value: 'DSCAML1', id: 'DSCAML1'},
{value: 'DSCAS', id: 'DSCAS'},
{value: 'DSCC1', id: 'DSCC1'},
{value: 'DSCR8', id: 'DSCR8'},
{value: 'DSCR9', id: 'DSCR9'},
{value: 'DSE', id: 'DSE'},
{value: 'DSEL', id: 'DSEL'},
{value: 'DSEL-AS1', id: 'DSEL-AS1'},
{value: 'DSG1', id: 'DSG1'},
{value: 'DSG1-AS1', id: 'DSG1-AS1'},
{value: 'DSG2', id: 'DSG2'},
{value: 'DSG2-AS1', id: 'DSG2-AS1'},
{value: 'DSG3', id: 'DSG3'},
{value: 'DSG4', id: 'DSG4'},
{value: 'DSN1', id: 'DSN1'},
{value: 'DSP', id: 'DSP'},
{value: 'DST', id: 'DST'},
{value: 'DST-AS1', id: 'DST-AS1'},
{value: 'DSTN', id: 'DSTN'},
{value: 'DSTNP1', id: 'DSTNP1'},
{value: 'DSTNP2', id: 'DSTNP2'},
{value: 'DSTNP3', id: 'DSTNP3'},
{value: 'DSTNP4', id: 'DSTNP4'},
{value: 'DSTNP5', id: 'DSTNP5'},
{value: 'DSTYK', id: 'DSTYK'},
{value: 'DTD1', id: 'DTD1'},
{value: 'DTD1-AS1', id: 'DTD1-AS1'},
{value: 'DTD2', id: 'DTD2'},
{value: 'DTHD1', id: 'DTHD1'},
{value: 'DTL', id: 'DTL'},
{value: 'DTNA', id: 'DTNA'},
{value: 'DTNB', id: 'DTNB'},
{value: 'DTNB-AS1', id: 'DTNB-AS1'},
{value: 'DTNBP1', id: 'DTNBP1'},
{value: 'DTWD1', id: 'DTWD1'},
{value: 'DTWD2', id: 'DTWD2'},
{value: 'DTX1', id: 'DTX1'},
{value: 'DTX2', id: 'DTX2'},
{value: 'DTX2P1', id: 'DTX2P1'},
{value: 'DTX2P1-UPK3BP1-PMS2P11', id: 'DTX2P1-UPK3BP1-PMS2P11'},
{value: 'DTX3', id: 'DTX3'},
{value: 'DTX3L', id: 'DTX3L'},
{value: 'DTX4', id: 'DTX4'},
{value: 'DTYMK', id: 'DTYMK'},
{value: 'DUBR', id: 'DUBR'},
{value: 'DUOX1', id: 'DUOX1'},
{value: 'DUOX2', id: 'DUOX2'},
{value: 'DUOXA1', id: 'DUOXA1'},
{value: 'DUOXA2', id: 'DUOXA2'},
{value: 'DUS1L', id: 'DUS1L'},
{value: 'DUS2', id: 'DUS2'},
{value: 'DUS3L', id: 'DUS3L'},
{value: 'DUS4L', id: 'DUS4L'},
{value: 'DUS4L-BCAP29', id: 'DUS4L-BCAP29'},
{value: 'DUSP1', id: 'DUSP1'},
{value: 'DUSP10', id: 'DUSP10'},
{value: 'DUSP11', id: 'DUSP11'},
{value: 'DUSP12', id: 'DUSP12'},
{value: 'DUSP13', id: 'DUSP13'},
{value: 'DUSP14', id: 'DUSP14'},
{value: 'DUSP15', id: 'DUSP15'},
{value: 'DUSP16', id: 'DUSP16'},
{value: 'DUSP18', id: 'DUSP18'},
{value: 'DUSP19', id: 'DUSP19'},
{value: 'DUSP2', id: 'DUSP2'},
{value: 'DUSP22', id: 'DUSP22'},
{value: 'DUSP23', id: 'DUSP23'},
{value: 'DUSP26', id: 'DUSP26'},
{value: 'DUSP28', id: 'DUSP28'},
{value: 'DUSP29', id: 'DUSP29'},
{value: 'DUSP3', id: 'DUSP3'},
{value: 'DUSP4', id: 'DUSP4'},
{value: 'DUSP5', id: 'DUSP5'},
{value: 'DUSP5-DT', id: 'DUSP5-DT'},
{value: 'DUSP5P1', id: 'DUSP5P1'},
{value: 'DUSP5P2', id: 'DUSP5P2'},
{value: 'DUSP6', id: 'DUSP6'},
{value: 'DUSP7', id: 'DUSP7'},
{value: 'DUSP8', id: 'DUSP8'},
{value: 'DUSP8P1', id: 'DUSP8P1'},
{value: 'DUSP8P5', id: 'DUSP8P5'},
{value: 'DUSP9', id: 'DUSP9'},
{value: 'DUT', id: 'DUT'},
{value: 'DUT-AS1', id: 'DUT-AS1'},
{value: 'DUTP1', id: 'DUTP1'},
{value: 'DUTP2', id: 'DUTP2'},
{value: 'DUTP3', id: 'DUTP3'},
{value: 'DUTP5', id: 'DUTP5'},
{value: 'DUTP6', id: 'DUTP6'},
{value: 'DUTP7', id: 'DUTP7'},
{value: 'DUTP8', id: 'DUTP8'},
{value: 'DUX4', id: 'DUX4'},
{value: 'DUX4L13', id: 'DUX4L13'},
{value: 'DUX4L15', id: 'DUX4L15'},
{value: 'DUX4L2', id: 'DUX4L2'},
{value: 'DUX4L20', id: 'DUX4L20'},
{value: 'DUX4L25', id: 'DUX4L25'},
{value: 'DUX4L26', id: 'DUX4L26'},
{value: 'DUX4L27', id: 'DUX4L27'},
{value: 'DUX4L28', id: 'DUX4L28'},
{value: 'DUX4L32', id: 'DUX4L32'},
{value: 'DUX4L33', id: 'DUX4L33'},
{value: 'DUX4L34', id: 'DUX4L34'},
{value: 'DUX4L35', id: 'DUX4L35'},
{value: 'DUX4L37', id: 'DUX4L37'},
{value: 'DUX4L4', id: 'DUX4L4'},
{value: 'DUX4L45', id: 'DUX4L45'},
{value: 'DUX4L47', id: 'DUX4L47'},
{value: 'DUX4L50', id: 'DUX4L50'},
{value: 'DUX4L51', id: 'DUX4L51'},
{value: 'DUX4L52', id: 'DUX4L52'},
{value: 'DUX4L7', id: 'DUX4L7'},
{value: 'DUX4L8', id: 'DUX4L8'},
{value: 'DUX4L9', id: 'DUX4L9'},
{value: 'DUXA', id: 'DUXA'},
{value: 'DUXAP10', id: 'DUXAP10'},
{value: 'DUXAP11', id: 'DUXAP11'},
{value: 'DUXAP2', id: 'DUXAP2'},
{value: 'DUXAP3', id: 'DUXAP3'},
{value: 'DUXAP5', id: 'DUXAP5'},
{value: 'DUXAP8', id: 'DUXAP8'},
{value: 'DUXAP9', id: 'DUXAP9'},
{value: 'DUXB', id: 'DUXB'},
{value: 'DVL1', id: 'DVL1'},
{value: 'DVL2', id: 'DVL2'},
{value: 'DVL3', id: 'DVL3'},
{value: 'DXO', id: 'DXO'},
{value: 'DYDC1', id: 'DYDC1'},
{value: 'DYDC2', id: 'DYDC2'},
{value: 'DYM', id: 'DYM'},
{value: 'DYNAP', id: 'DYNAP'},
{value: 'DYNC1H1', id: 'DYNC1H1'},
{value: 'DYNC1I1', id: 'DYNC1I1'},
{value: 'DYNC1I2', id: 'DYNC1I2'},
{value: 'DYNC1I2P1', id: 'DYNC1I2P1'},
{value: 'DYNC1LI1', id: 'DYNC1LI1'},
{value: 'DYNC1LI2', id: 'DYNC1LI2'},
{value: 'DYNC1LI2-DT', id: 'DYNC1LI2-DT'},
{value: 'DYNC2H1', id: 'DYNC2H1'},
{value: 'DYNC2I1', id: 'DYNC2I1'},
{value: 'DYNC2I2', id: 'DYNC2I2'},
{value: 'DYNC2LI1', id: 'DYNC2LI1'},
{value: 'DYNLL1', id: 'DYNLL1'},
{value: 'DYNLL1P1', id: 'DYNLL1P1'},
{value: 'DYNLL1P3', id: 'DYNLL1P3'},
{value: 'DYNLL1P4', id: 'DYNLL1P4'},
{value: 'DYNLL1P5', id: 'DYNLL1P5'},
{value: 'DYNLL1P6', id: 'DYNLL1P6'},
{value: 'DYNLL1P7', id: 'DYNLL1P7'},
{value: 'DYNLL2', id: 'DYNLL2'},
{value: 'DYNLL2-DT', id: 'DYNLL2-DT'},
{value: 'DYNLRB1', id: 'DYNLRB1'},
{value: 'DYNLRB2', id: 'DYNLRB2'},
{value: 'DYNLRB2-AS1', id: 'DYNLRB2-AS1'},
{value: 'DYNLT1', id: 'DYNLT1'},
{value: 'DYNLT2', id: 'DYNLT2'},
{value: 'DYNLT2B', id: 'DYNLT2B'},
{value: 'DYNLT3', id: 'DYNLT3'},
{value: 'DYNLT3P1', id: 'DYNLT3P1'},
{value: 'DYNLT3P2', id: 'DYNLT3P2'},
{value: 'DYNLT4', id: 'DYNLT4'},
{value: 'DYNLT5', id: 'DYNLT5'},
{value: 'DYRK1A', id: 'DYRK1A'},
{value: 'DYRK1B', id: 'DYRK1B'},
{value: 'DYRK2', id: 'DYRK2'},
{value: 'DYRK3', id: 'DYRK3'},
{value: 'DYRK3-AS1', id: 'DYRK3-AS1'},
{value: 'DYRK4', id: 'DYRK4'},
{value: 'DYSF', id: 'DYSF'},
{value: 'DYTN', id: 'DYTN'},
{value: 'DZANK1', id: 'DZANK1'},
{value: 'DZIP1', id: 'DZIP1'},
{value: 'DZIP1L', id: 'DZIP1L'},
{value: 'DZIP3', id: 'DZIP3'},
{value: 'E2F1', id: 'E2F1'},
{value: 'E2F2', id: 'E2F2'},
{value: 'E2F3', id: 'E2F3'},
{value: 'E2F3-IT1', id: 'E2F3-IT1'},
{value: 'E2F3P1', id: 'E2F3P1'},
{value: 'E2F3P2', id: 'E2F3P2'},
{value: 'E2F4', id: 'E2F4'},
{value: 'E2F4P1', id: 'E2F4P1'},
{value: 'E2F5', id: 'E2F5'},
{value: 'E2F5-DT', id: 'E2F5-DT'},
{value: 'E2F6', id: 'E2F6'},
{value: 'E2F6P2', id: 'E2F6P2'},
{value: 'E2F7', id: 'E2F7'},
{value: 'E2F8', id: 'E2F8'},
{value: 'E4F1', id: 'E4F1'},
{value: 'EAF1', id: 'EAF1'},
{value: 'EAF1-AS1', id: 'EAF1-AS1'},
{value: 'EAF2', id: 'EAF2'},
{value: 'EAPP', id: 'EAPP'},
{value: 'EARS2', id: 'EARS2'},
{value: 'EBAG9', id: 'EBAG9'},
{value: 'EBAG9P1', id: 'EBAG9P1'},
{value: 'EBF1', id: 'EBF1'},
{value: 'EBF2', id: 'EBF2'},
{value: 'EBF3', id: 'EBF3'},
{value: 'EBF4', id: 'EBF4'},
{value: 'EBI3', id: 'EBI3'},
{value: 'EBLN1', id: 'EBLN1'},
{value: 'EBLN2', id: 'EBLN2'},
{value: 'EBLN3P', id: 'EBLN3P'},
{value: 'EBNA1BP2', id: 'EBNA1BP2'},
{value: 'EBP', id: 'EBP'},
{value: 'EBPL', id: 'EBPL'},
{value: 'ECD', id: 'ECD'},
{value: 'ECE1', id: 'ECE1'},
{value: 'ECE1-AS1', id: 'ECE1-AS1'},
{value: 'ECE2', id: 'ECE2'},
{value: 'ECEL1', id: 'ECEL1'},
{value: 'ECEL1P1', id: 'ECEL1P1'},
{value: 'ECEL1P2', id: 'ECEL1P2'},
{value: 'ECEL1P3', id: 'ECEL1P3'},
{value: 'ECH1', id: 'ECH1'},
{value: 'ECHDC1', id: 'ECHDC1'},
{value: 'ECHDC2', id: 'ECHDC2'},
{value: 'ECHDC3', id: 'ECHDC3'},
{value: 'ECHS1', id: 'ECHS1'},
{value: 'ECI1', id: 'ECI1'},
{value: 'ECI2', id: 'ECI2'},
{value: 'ECI2-DT', id: 'ECI2-DT'},
{value: 'ECM1', id: 'ECM1'},
{value: 'ECM1P1', id: 'ECM1P1'},
{value: 'ECM1P2', id: 'ECM1P2'},
{value: 'ECM2', id: 'ECM2'},
{value: 'ECPAS', id: 'ECPAS'},
{value: 'ECRG4', id: 'ECRG4'},
{value: 'ECSCR', id: 'ECSCR'},
{value: 'ECSIT', id: 'ECSIT'},
{value: 'ECT2', id: 'ECT2'},
{value: 'ECT2L', id: 'ECT2L'},
{value: 'EDA', id: 'EDA'},
{value: 'EDA2R', id: 'EDA2R'},
{value: 'EDAR', id: 'EDAR'},
{value: 'EDARADD', id: 'EDARADD'},
{value: 'EDC3', id: 'EDC3'},
{value: 'EDC4', id: 'EDC4'},
{value: 'EDDM13', id: 'EDDM13'},
{value: 'EDDM3A', id: 'EDDM3A'},
{value: 'EDDM3B', id: 'EDDM3B'},
{value: 'EDDM3CP', id: 'EDDM3CP'},
{value: 'EDDM3DP', id: 'EDDM3DP'},
{value: 'EDEM1', id: 'EDEM1'},
{value: 'EDEM2', id: 'EDEM2'},
{value: 'EDEM3', id: 'EDEM3'},
{value: 'EDF1', id: 'EDF1'},
{value: 'EDIL3', id: 'EDIL3'},
{value: 'EDIL3-DT', id: 'EDIL3-DT'},
{value: 'EDN1', id: 'EDN1'},
{value: 'EDN2', id: 'EDN2'},
{value: 'EDN3', id: 'EDN3'},
{value: 'EDNRA', id: 'EDNRA'},
{value: 'EDNRB', id: 'EDNRB'},
{value: 'EDNRB-AS1', id: 'EDNRB-AS1'},
{value: 'EDRF1', id: 'EDRF1'},
{value: 'EDRF1-AS1', id: 'EDRF1-AS1'},
{value: 'EDRF1-DT', id: 'EDRF1-DT'},
{value: 'EEA1', id: 'EEA1'},
{value: 'EED', id: 'EED'},
{value: 'EEF1A1', id: 'EEF1A1'},
{value: 'EEF1A1P1', id: 'EEF1A1P1'},
{value: 'EEF1A1P10', id: 'EEF1A1P10'},
{value: 'EEF1A1P11', id: 'EEF1A1P11'},
{value: 'EEF1A1P12', id: 'EEF1A1P12'},
{value: 'EEF1A1P13', id: 'EEF1A1P13'},
{value: 'EEF1A1P14', id: 'EEF1A1P14'},
{value: 'EEF1A1P16', id: 'EEF1A1P16'},
{value: 'EEF1A1P17', id: 'EEF1A1P17'},
{value: 'EEF1A1P19', id: 'EEF1A1P19'},
{value: 'EEF1A1P2', id: 'EEF1A1P2'},
{value: 'EEF1A1P20', id: 'EEF1A1P20'},
{value: 'EEF1A1P21', id: 'EEF1A1P21'},
{value: 'EEF1A1P22', id: 'EEF1A1P22'},
{value: 'EEF1A1P25', id: 'EEF1A1P25'},
{value: 'EEF1A1P26', id: 'EEF1A1P26'},
{value: 'EEF1A1P27', id: 'EEF1A1P27'},
{value: 'EEF1A1P3', id: 'EEF1A1P3'},
{value: 'EEF1A1P33', id: 'EEF1A1P33'},
{value: 'EEF1A1P34', id: 'EEF1A1P34'},
{value: 'EEF1A1P36', id: 'EEF1A1P36'},
{value: 'EEF1A1P38', id: 'EEF1A1P38'},
{value: 'EEF1A1P4', id: 'EEF1A1P4'},
{value: 'EEF1A1P42', id: 'EEF1A1P42'},
{value: 'EEF1A1P5', id: 'EEF1A1P5'},
{value: 'EEF1A1P6', id: 'EEF1A1P6'},
{value: 'EEF1A1P7', id: 'EEF1A1P7'},
{value: 'EEF1A1P8', id: 'EEF1A1P8'},
{value: 'EEF1A1P9', id: 'EEF1A1P9'},
{value: 'EEF1A2', id: 'EEF1A2'},
{value: 'EEF1AKMT1', id: 'EEF1AKMT1'},
{value: 'EEF1AKMT2', id: 'EEF1AKMT2'},
{value: 'EEF1AKMT3', id: 'EEF1AKMT3'},
{value: 'EEF1AKMT4', id: 'EEF1AKMT4'},
{value: 'EEF1AKMT4-ECE2', id: 'EEF1AKMT4-ECE2'},
{value: 'EEF1B2', id: 'EEF1B2'},
{value: 'EEF1B2P2', id: 'EEF1B2P2'},
{value: 'EEF1B2P3', id: 'EEF1B2P3'},
{value: 'EEF1B2P4', id: 'EEF1B2P4'},
{value: 'EEF1B2P5', id: 'EEF1B2P5'},
{value: 'EEF1B2P6', id: 'EEF1B2P6'},
{value: 'EEF1B2P7', id: 'EEF1B2P7'},
{value: 'EEF1B2P8', id: 'EEF1B2P8'},
{value: 'EEF1D', id: 'EEF1D'},
{value: 'EEF1DP1', id: 'EEF1DP1'},
{value: 'EEF1DP2', id: 'EEF1DP2'},
{value: 'EEF1DP3', id: 'EEF1DP3'},
{value: 'EEF1DP4', id: 'EEF1DP4'},
{value: 'EEF1DP5', id: 'EEF1DP5'},
{value: 'EEF1DP6', id: 'EEF1DP6'},
{value: 'EEF1DP7', id: 'EEF1DP7'},
{value: 'EEF1DP8', id: 'EEF1DP8'},
{value: 'EEF1E1', id: 'EEF1E1'},
{value: 'EEF1E1-BLOC1S5', id: 'EEF1E1-BLOC1S5'},
{value: 'EEF1E1P1', id: 'EEF1E1P1'},
{value: 'EEF1G', id: 'EEF1G'},
{value: 'EEF1GP1', id: 'EEF1GP1'},
{value: 'EEF1GP2', id: 'EEF1GP2'},
{value: 'EEF1GP3', id: 'EEF1GP3'},
{value: 'EEF1GP4', id: 'EEF1GP4'},
{value: 'EEF1GP6', id: 'EEF1GP6'},
{value: 'EEF1GP7', id: 'EEF1GP7'},
{value: 'EEF1GP8', id: 'EEF1GP8'},
{value: 'EEF2', id: 'EEF2'},
{value: 'EEF2K', id: 'EEF2K'},
{value: 'EEF2KMT', id: 'EEF2KMT'},
{value: 'EEFSEC', id: 'EEFSEC'},
{value: 'EEPD1', id: 'EEPD1'},
{value: 'EFCAB1', id: 'EFCAB1'},
{value: 'EFCAB10', id: 'EFCAB10'},
{value: 'EFCAB11', id: 'EFCAB11'},
{value: 'EFCAB12', id: 'EFCAB12'},
{value: 'EFCAB13', id: 'EFCAB13'},
{value: 'EFCAB13-DT', id: 'EFCAB13-DT'},
{value: 'EFCAB14', id: 'EFCAB14'},
{value: 'EFCAB14-AS1', id: 'EFCAB14-AS1'},
{value: 'EFCAB14P1', id: 'EFCAB14P1'},
{value: 'EFCAB15P', id: 'EFCAB15P'},
{value: 'EFCAB2', id: 'EFCAB2'},
{value: 'EFCAB3', id: 'EFCAB3'},
{value: 'EFCAB5', id: 'EFCAB5'},
{value: 'EFCAB6', id: 'EFCAB6'},
{value: 'EFCAB6-AS1', id: 'EFCAB6-AS1'},
{value: 'EFCAB6-DT', id: 'EFCAB6-DT'},
{value: 'EFCAB7', id: 'EFCAB7'},
{value: 'EFCAB8', id: 'EFCAB8'},
{value: 'EFCAB9', id: 'EFCAB9'},
{value: 'EFCC1', id: 'EFCC1'},
{value: 'EFEMP1', id: 'EFEMP1'},
{value: 'EFEMP2', id: 'EFEMP2'},
{value: 'EFHB', id: 'EFHB'},
{value: 'EFHC1', id: 'EFHC1'},
{value: 'EFHC2', id: 'EFHC2'},
{value: 'EFHD1', id: 'EFHD1'},
{value: 'EFHD2', id: 'EFHD2'},
{value: 'EFL1', id: 'EFL1'},
{value: 'EFL1P1', id: 'EFL1P1'},
{value: 'EFL1P2', id: 'EFL1P2'},
{value: 'EFNA1', id: 'EFNA1'},
{value: 'EFNA2', id: 'EFNA2'},
{value: 'EFNA3', id: 'EFNA3'},
{value: 'EFNA4', id: 'EFNA4'},
{value: 'EFNA5', id: 'EFNA5'},
{value: 'EFNB1', id: 'EFNB1'},
{value: 'EFNB2', id: 'EFNB2'},
{value: 'EFNB3', id: 'EFNB3'},
{value: 'EFR3A', id: 'EFR3A'},
{value: 'EFR3B', id: 'EFR3B'},
{value: 'EFS', id: 'EFS'},
{value: 'EFTUD2', id: 'EFTUD2'},
{value: 'EGF', id: 'EGF'},
{value: 'EGFEM1P', id: 'EGFEM1P'},
{value: 'EGFL6', id: 'EGFL6'},
{value: 'EGFL7', id: 'EGFL7'},
{value: 'EGFL8', id: 'EGFL8'},
{value: 'EGFLAM', id: 'EGFLAM'},
{value: 'EGFLAM-AS1', id: 'EGFLAM-AS1'},
{value: 'EGFLAM-AS2', id: 'EGFLAM-AS2'},
{value: 'EGFLAM-AS4', id: 'EGFLAM-AS4'},
{value: 'EGFR', id: 'EGFR'},
{value: 'EGFR-AS1', id: 'EGFR-AS1'},
{value: 'EGILA', id: 'EGILA'},
{value: 'EGLN1', id: 'EGLN1'},
{value: 'EGLN1P1', id: 'EGLN1P1'},
{value: 'EGLN2', id: 'EGLN2'},
{value: 'EGLN3', id: 'EGLN3'},
{value: 'EGLN3-AS1', id: 'EGLN3-AS1'},
{value: 'EGLN3P1', id: 'EGLN3P1'},
{value: 'EGOT', id: 'EGOT'},
{value: 'EGR1', id: 'EGR1'},
{value: 'EGR2', id: 'EGR2'},
{value: 'EGR3', id: 'EGR3'},
{value: 'EGR4', id: 'EGR4'},
{value: 'EHBP1', id: 'EHBP1'},
{value: 'EHBP1L1', id: 'EHBP1L1'},
{value: 'EHD1', id: 'EHD1'},
{value: 'EHD2', id: 'EHD2'},
{value: 'EHD3', id: 'EHD3'},
{value: 'EHD4', id: 'EHD4'},
{value: 'EHD4-AS1', id: 'EHD4-AS1'},
{value: 'EHF', id: 'EHF'},
{value: 'EHHADH', id: 'EHHADH'},
{value: 'EHHADH-AS1', id: 'EHHADH-AS1'},
{value: 'EHMT1', id: 'EHMT1'},
{value: 'EHMT2', id: 'EHMT2'},
{value: 'EHMT2-AS1', id: 'EHMT2-AS1'},
{value: 'EI24', id: 'EI24'},
{value: 'EI24P1', id: 'EI24P1'},
{value: 'EI24P2', id: 'EI24P2'},
{value: 'EI24P3', id: 'EI24P3'},
{value: 'EI24P4', id: 'EI24P4'},
{value: 'EID1', id: 'EID1'},
{value: 'EID2', id: 'EID2'},
{value: 'EID2B', id: 'EID2B'},
{value: 'EID3', id: 'EID3'},
{value: 'EIF1', id: 'EIF1'},
{value: 'EIF1AD', id: 'EIF1AD'},
{value: 'EIF1AX', id: 'EIF1AX'},
{value: 'EIF1AXP1', id: 'EIF1AXP1'},
{value: 'EIF1AY', id: 'EIF1AY'},
{value: 'EIF1B', id: 'EIF1B'},
{value: 'EIF1B-AS1', id: 'EIF1B-AS1'},
{value: 'EIF1P1', id: 'EIF1P1'},
{value: 'EIF1P2', id: 'EIF1P2'},
{value: 'EIF1P3', id: 'EIF1P3'},
{value: 'EIF1P5', id: 'EIF1P5'},
{value: 'EIF2A', id: 'EIF2A'},
{value: 'EIF2AK1', id: 'EIF2AK1'},
{value: 'EIF2AK2', id: 'EIF2AK2'},
{value: 'EIF2AK3', id: 'EIF2AK3'},
{value: 'EIF2AK3-DT', id: 'EIF2AK3-DT'},
{value: 'EIF2AK4', id: 'EIF2AK4'},
{value: 'EIF2AP4', id: 'EIF2AP4'},
{value: 'EIF2B1', id: 'EIF2B1'},
{value: 'EIF2B2', id: 'EIF2B2'},
{value: 'EIF2B3', id: 'EIF2B3'},
{value: 'EIF2B4', id: 'EIF2B4'},
{value: 'EIF2B5', id: 'EIF2B5'},
{value: 'EIF2B5-DT', id: 'EIF2B5-DT'},
{value: 'EIF2D', id: 'EIF2D'},
{value: 'EIF2S1', id: 'EIF2S1'},
{value: 'EIF2S2', id: 'EIF2S2'},
{value: 'EIF2S2P3', id: 'EIF2S2P3'},
{value: 'EIF2S2P4', id: 'EIF2S2P4'},
{value: 'EIF2S2P5', id: 'EIF2S2P5'},
{value: 'EIF2S2P7', id: 'EIF2S2P7'},
{value: 'EIF2S3', id: 'EIF2S3'},
{value: 'EIF2S3B', id: 'EIF2S3B'},
{value: 'EIF3A', id: 'EIF3A'},
{value: 'EIF3B', id: 'EIF3B'},
{value: 'EIF3C', id: 'EIF3C'},
{value: 'EIF3CL', id: 'EIF3CL'},
{value: 'EIF3D', id: 'EIF3D'},
{value: 'EIF3E', id: 'EIF3E'},
{value: 'EIF3EP1', id: 'EIF3EP1'},
{value: 'EIF3EP2', id: 'EIF3EP2'},
{value: 'EIF3F', id: 'EIF3F'},
{value: 'EIF3FP1', id: 'EIF3FP1'},
{value: 'EIF3FP3', id: 'EIF3FP3'},
{value: 'EIF3G', id: 'EIF3G'},
{value: 'EIF3H', id: 'EIF3H'},
{value: 'EIF3I', id: 'EIF3I'},
{value: 'EIF3IP1', id: 'EIF3IP1'},
{value: 'EIF3J', id: 'EIF3J'},
{value: 'EIF3J-DT', id: 'EIF3J-DT'},
{value: 'EIF3K', id: 'EIF3K'},
{value: 'EIF3KP1', id: 'EIF3KP1'},
{value: 'EIF3KP3', id: 'EIF3KP3'},
{value: 'EIF3L', id: 'EIF3L'},
{value: 'EIF3LP2', id: 'EIF3LP2'},
{value: 'EIF3LP3', id: 'EIF3LP3'},
{value: 'EIF3M', id: 'EIF3M'},
{value: 'EIF4A1', id: 'EIF4A1'},
{value: 'EIF4A1P1', id: 'EIF4A1P1'},
{value: 'EIF4A1P10', id: 'EIF4A1P10'},
{value: 'EIF4A1P12', id: 'EIF4A1P12'},
{value: 'EIF4A1P13', id: 'EIF4A1P13'},
{value: 'EIF4A1P2', id: 'EIF4A1P2'},
{value: 'EIF4A1P3', id: 'EIF4A1P3'},
{value: 'EIF4A1P4', id: 'EIF4A1P4'},
{value: 'EIF4A1P5', id: 'EIF4A1P5'},
{value: 'EIF4A1P7', id: 'EIF4A1P7'},
{value: 'EIF4A1P9', id: 'EIF4A1P9'},
{value: 'EIF4A2', id: 'EIF4A2'},
{value: 'EIF4A2P3', id: 'EIF4A2P3'},
{value: 'EIF4A3', id: 'EIF4A3'},
{value: 'EIF4A3P1', id: 'EIF4A3P1'},
{value: 'EIF4B', id: 'EIF4B'},
{value: 'EIF4BP1', id: 'EIF4BP1'},
{value: 'EIF4BP3', id: 'EIF4BP3'},
{value: 'EIF4BP4', id: 'EIF4BP4'},
{value: 'EIF4BP6', id: 'EIF4BP6'},
{value: 'EIF4BP7', id: 'EIF4BP7'},
{value: 'EIF4BP8', id: 'EIF4BP8'},
{value: 'EIF4E', id: 'EIF4E'},
{value: 'EIF4E1B', id: 'EIF4E1B'},
{value: 'EIF4E2', id: 'EIF4E2'},
{value: 'EIF4E3', id: 'EIF4E3'},
{value: 'EIF4EBP1', id: 'EIF4EBP1'},
{value: 'EIF4EBP1P1', id: 'EIF4EBP1P1'},
{value: 'EIF4EBP2', id: 'EIF4EBP2'},
{value: 'EIF4EBP2P1', id: 'EIF4EBP2P1'},
{value: 'EIF4EBP2P2', id: 'EIF4EBP2P2'},
{value: 'EIF4EBP2P3', id: 'EIF4EBP2P3'},
{value: 'EIF4EBP3', id: 'EIF4EBP3'},
{value: 'EIF4ENIF1', id: 'EIF4ENIF1'},
{value: 'EIF4EP1', id: 'EIF4EP1'},
{value: 'EIF4EP2', id: 'EIF4EP2'},
{value: 'EIF4EP3', id: 'EIF4EP3'},
{value: 'EIF4EP4', id: 'EIF4EP4'},
{value: 'EIF4G1', id: 'EIF4G1'},
{value: 'EIF4G2', id: 'EIF4G2'},
{value: 'EIF4G3', id: 'EIF4G3'},
{value: 'EIF4H', id: 'EIF4H'},
{value: 'EIF4HP1', id: 'EIF4HP1'},
{value: 'EIF4HP2', id: 'EIF4HP2'},
{value: 'EIF5', id: 'EIF5'},
{value: 'EIF5A', id: 'EIF5A'},
{value: 'EIF5A2', id: 'EIF5A2'},
{value: 'EIF5A2P1', id: 'EIF5A2P1'},
{value: 'EIF5AL1', id: 'EIF5AL1'},
{value: 'EIF5AP4', id: 'EIF5AP4'},
{value: 'EIF5B', id: 'EIF5B'},
{value: 'EIF6', id: 'EIF6'},
{value: 'EIPR1', id: 'EIPR1'},
{value: 'ELAC1', id: 'ELAC1'},
{value: 'ELAC2', id: 'ELAC2'},
{value: 'ELANE', id: 'ELANE'},
{value: 'ELAPOR1', id: 'ELAPOR1'},
{value: 'ELAPOR2', id: 'ELAPOR2'},
{value: 'ELAVL1', id: 'ELAVL1'},
{value: 'ELAVL2', id: 'ELAVL2'},
{value: 'ELAVL3', id: 'ELAVL3'},
{value: 'ELAVL4', id: 'ELAVL4'},
{value: 'ELAVL4-AS1', id: 'ELAVL4-AS1'},
{value: 'ELDR', id: 'ELDR'},
{value: 'ELF1', id: 'ELF1'},
{value: 'ELF2', id: 'ELF2'},
{value: 'ELF2P3', id: 'ELF2P3'},
{value: 'ELF2P4', id: 'ELF2P4'},
{value: 'ELF3', id: 'ELF3'},
{value: 'ELF3-AS1', id: 'ELF3-AS1'},
{value: 'ELF4', id: 'ELF4'},
{value: 'ELF5', id: 'ELF5'},
{value: 'ELFN1', id: 'ELFN1'},
{value: 'ELFN1-AS1', id: 'ELFN1-AS1'},
{value: 'ELFN2', id: 'ELFN2'},
{value: 'ELK1', id: 'ELK1'},
{value: 'ELK1P1', id: 'ELK1P1'},
{value: 'ELK2AP', id: 'ELK2AP'},
{value: 'ELK2BP', id: 'ELK2BP'},
{value: 'ELK3', id: 'ELK3'},
{value: 'ELK4', id: 'ELK4'},
{value: 'ELL', id: 'ELL'},
{value: 'ELL2', id: 'ELL2'},
{value: 'ELL2P1', id: 'ELL2P1'},
{value: 'ELL2P2', id: 'ELL2P2'},
{value: 'ELL2P3', id: 'ELL2P3'},
{value: 'ELL3', id: 'ELL3'},
{value: 'ELMO1', id: 'ELMO1'},
{value: 'ELMO1-AS1', id: 'ELMO1-AS1'},
{value: 'ELMO2', id: 'ELMO2'},
{value: 'ELMO2P1', id: 'ELMO2P1'},
{value: 'ELMO3', id: 'ELMO3'},
{value: 'ELMOD1', id: 'ELMOD1'},
{value: 'ELMOD2', id: 'ELMOD2'},
{value: 'ELMOD3', id: 'ELMOD3'},
{value: 'ELN', id: 'ELN'},
{value: 'ELN-AS1', id: 'ELN-AS1'},
{value: 'ELOA', id: 'ELOA'},
{value: 'ELOA-AS1', id: 'ELOA-AS1'},
{value: 'ELOA2', id: 'ELOA2'},
{value: 'ELOAP1', id: 'ELOAP1'},
{value: 'ELOB', id: 'ELOB'},
{value: 'ELOBP1', id: 'ELOBP1'},
{value: 'ELOBP2', id: 'ELOBP2'},
{value: 'ELOBP3', id: 'ELOBP3'},
{value: 'ELOBP4', id: 'ELOBP4'},
{value: 'ELOC', id: 'ELOC'},
{value: 'ELOCP18', id: 'ELOCP18'},
{value: 'ELOCP19', id: 'ELOCP19'},
{value: 'ELOCP2', id: 'ELOCP2'},
{value: 'ELOCP20', id: 'ELOCP20'},
{value: 'ELOCP27', id: 'ELOCP27'},
{value: 'ELOCP3', id: 'ELOCP3'},
{value: 'ELOCP30', id: 'ELOCP30'},
{value: 'ELOF1', id: 'ELOF1'},
{value: 'ELOVL1', id: 'ELOVL1'},
{value: 'ELOVL2', id: 'ELOVL2'},
{value: 'ELOVL2-AS1', id: 'ELOVL2-AS1'},
{value: 'ELOVL3', id: 'ELOVL3'},
{value: 'ELOVL4', id: 'ELOVL4'},
{value: 'ELOVL5', id: 'ELOVL5'},
{value: 'ELOVL6', id: 'ELOVL6'},
{value: 'ELOVL7', id: 'ELOVL7'},
{value: 'ELP1', id: 'ELP1'},
{value: 'ELP2', id: 'ELP2'},
{value: 'ELP3', id: 'ELP3'},
{value: 'ELP4', id: 'ELP4'},
{value: 'ELP5', id: 'ELP5'},
{value: 'ELP6', id: 'ELP6'},
{value: 'ELSPBP1', id: 'ELSPBP1'},
{value: 'EMB', id: 'EMB'},
{value: 'EMBP1', id: 'EMBP1'},
{value: 'EMC1', id: 'EMC1'},
{value: 'EMC1-AS1', id: 'EMC1-AS1'},
{value: 'EMC10', id: 'EMC10'},
{value: 'EMC2', id: 'EMC2'},
{value: 'EMC3', id: 'EMC3'},
{value: 'EMC3-AS1', id: 'EMC3-AS1'},
{value: 'EMC4', id: 'EMC4'},
{value: 'EMC6', id: 'EMC6'},
{value: 'EMC7', id: 'EMC7'},
{value: 'EMC8', id: 'EMC8'},
{value: 'EMC9', id: 'EMC9'},
{value: 'EMCN', id: 'EMCN'},
{value: 'EMD', id: 'EMD'},
{value: 'EME1', id: 'EME1'},
{value: 'EME2', id: 'EME2'},
{value: 'EMG1', id: 'EMG1'},
{value: 'EMID1', id: 'EMID1'},
{value: 'EMILIN1', id: 'EMILIN1'},
{value: 'EMILIN2', id: 'EMILIN2'},
{value: 'EMILIN3', id: 'EMILIN3'},
{value: 'EML1', id: 'EML1'},
{value: 'EML2', id: 'EML2'},
{value: 'EML2-AS1', id: 'EML2-AS1'},
{value: 'EML3', id: 'EML3'},
{value: 'EML4', id: 'EML4'},
{value: 'EML4-AS1', id: 'EML4-AS1'},
{value: 'EML5', id: 'EML5'},
{value: 'EML6', id: 'EML6'},
{value: 'EML6-AS1', id: 'EML6-AS1'},
{value: 'EMP1', id: 'EMP1'},
{value: 'EMP2', id: 'EMP2'},
{value: 'EMP2P1', id: 'EMP2P1'},
{value: 'EMP3', id: 'EMP3'},
{value: 'EMSLR', id: 'EMSLR'},
{value: 'EMSY', id: 'EMSY'},
{value: 'EMSY-DT', id: 'EMSY-DT'},
{value: 'EMX1', id: 'EMX1'},
{value: 'EMX2', id: 'EMX2'},
{value: 'EMX2OS', id: 'EMX2OS'},
{value: 'EN1', id: 'EN1'},
{value: 'EN2', id: 'EN2'},
{value: 'ENAH', id: 'ENAH'},
{value: 'ENAM', id: 'ENAM'},
{value: 'ENC1', id: 'ENC1'},
{value: 'ENDOD1', id: 'ENDOD1'},
{value: 'ENDOG', id: 'ENDOG'},
{value: 'ENDOU', id: 'ENDOU'},
{value: 'ENDOV', id: 'ENDOV'},
{value: 'ENG', id: 'ENG'},
{value: 'ENGASE', id: 'ENGASE'},
{value: 'ENHO', id: 'ENHO'},
{value: 'ENKD1', id: 'ENKD1'},
{value: 'ENKUR', id: 'ENKUR'},
{value: 'ENO1', id: 'ENO1'},
{value: 'ENO1-AS1', id: 'ENO1-AS1'},
{value: 'ENO1P1', id: 'ENO1P1'},
{value: 'ENO1P3', id: 'ENO1P3'},
{value: 'ENO1P4', id: 'ENO1P4'},
{value: 'ENO2', id: 'ENO2'},
{value: 'ENO3', id: 'ENO3'},
{value: 'ENO4', id: 'ENO4'},
{value: 'ENOPH1', id: 'ENOPH1'},
{value: 'ENOSF1', id: 'ENOSF1'},
{value: 'ENOX1', id: 'ENOX1'},
{value: 'ENOX2', id: 'ENOX2'},
{value: 'ENPEP', id: 'ENPEP'},
{value: 'ENPP1', id: 'ENPP1'},
{value: 'ENPP2', id: 'ENPP2'},
{value: 'ENPP3', id: 'ENPP3'},
{value: 'ENPP4', id: 'ENPP4'},
{value: 'ENPP5', id: 'ENPP5'},
{value: 'ENPP6', id: 'ENPP6'},
{value: 'ENPP7', id: 'ENPP7'},
{value: 'ENPP7P12', id: 'ENPP7P12'},
{value: 'ENPP7P13', id: 'ENPP7P13'},
{value: 'ENPP7P14', id: 'ENPP7P14'},
{value: 'ENPP7P2', id: 'ENPP7P2'},
{value: 'ENPP7P6', id: 'ENPP7P6'},
{value: 'ENPP7P7', id: 'ENPP7P7'},
{value: 'ENPP7P9', id: 'ENPP7P9'},
{value: 'ENSA', id: 'ENSA'},
{value: 'ENSAP1', id: 'ENSAP1'},
{value: 'ENSAP2', id: 'ENSAP2'},
{value: 'ENSAP3', id: 'ENSAP3'},
{value: 'ENTHD1', id: 'ENTHD1'},
{value: 'ENTPD1', id: 'ENTPD1'},
{value: 'ENTPD1-AS1', id: 'ENTPD1-AS1'},
{value: 'ENTPD2', id: 'ENTPD2'},
{value: 'ENTPD3', id: 'ENTPD3'},
{value: 'ENTPD3-AS1', id: 'ENTPD3-AS1'},
{value: 'ENTPD4', id: 'ENTPD4'},
{value: 'ENTPD4-DT', id: 'ENTPD4-DT'},
{value: 'ENTPD5', id: 'ENTPD5'},
{value: 'ENTPD6', id: 'ENTPD6'},
{value: 'ENTPD7', id: 'ENTPD7'},
{value: 'ENTPD8', id: 'ENTPD8'},
{value: 'ENTR1', id: 'ENTR1'},
{value: 'ENTR1P1', id: 'ENTR1P1'},
{value: 'ENTR1P2', id: 'ENTR1P2'},
{value: 'ENY2', id: 'ENY2'},
{value: 'EOGT', id: 'EOGT'},
{value: 'EOLA1', id: 'EOLA1'},
{value: 'EOLA2', id: 'EOLA2'},
{value: 'EOMES', id: 'EOMES'},
{value: 'EP300', id: 'EP300'},
{value: 'EP300-AS1', id: 'EP300-AS1'},
{value: 'EP400', id: 'EP400'},
{value: 'EP400P1', id: 'EP400P1'},
{value: 'EPAS1', id: 'EPAS1'},
{value: 'EPB41', id: 'EPB41'},
{value: 'EPB41L1', id: 'EPB41L1'},
{value: 'EPB41L2', id: 'EPB41L2'},
{value: 'EPB41L3', id: 'EPB41L3'},
{value: 'EPB41L4A', id: 'EPB41L4A'},
{value: 'EPB41L4A-AS1', id: 'EPB41L4A-AS1'},
{value: 'EPB41L4A-DT', id: 'EPB41L4A-DT'},
{value: 'EPB41L4B', id: 'EPB41L4B'},
{value: 'EPB41L5', id: 'EPB41L5'},
{value: 'EPB42', id: 'EPB42'},
{value: 'EPC1', id: 'EPC1'},
{value: 'EPC2', id: 'EPC2'},
{value: 'EPCAM', id: 'EPCAM'},
{value: 'EPCAM-DT', id: 'EPCAM-DT'},
{value: 'EPDR1', id: 'EPDR1'},
{value: 'EPG5', id: 'EPG5'},
{value: 'EPGN', id: 'EPGN'},
{value: 'EPHA1', id: 'EPHA1'},
{value: 'EPHA1-AS1', id: 'EPHA1-AS1'},
{value: 'EPHA10', id: 'EPHA10'},
{value: 'EPHA2', id: 'EPHA2'},
{value: 'EPHA3', id: 'EPHA3'},
{value: 'EPHA4', id: 'EPHA4'},
{value: 'EPHA5', id: 'EPHA5'},
{value: 'EPHA5-AS1', id: 'EPHA5-AS1'},
{value: 'EPHA6', id: 'EPHA6'},
{value: 'EPHA7', id: 'EPHA7'},
{value: 'EPHA8', id: 'EPHA8'},
{value: 'EPHB1', id: 'EPHB1'},
{value: 'EPHB2', id: 'EPHB2'},
{value: 'EPHB3', id: 'EPHB3'},
{value: 'EPHB4', id: 'EPHB4'},
{value: 'EPHB6', id: 'EPHB6'},
{value: 'EPHX1', id: 'EPHX1'},
{value: 'EPHX2', id: 'EPHX2'},
{value: 'EPHX3', id: 'EPHX3'},
{value: 'EPHX4', id: 'EPHX4'},
{value: 'EPIST', id: 'EPIST'},
{value: 'EPM2A', id: 'EPM2A'},
{value: 'EPM2AIP1', id: 'EPM2AIP1'},
{value: 'EPN1', id: 'EPN1'},
{value: 'EPN2', id: 'EPN2'},
{value: 'EPN2-AS1', id: 'EPN2-AS1'},
{value: 'EPN3', id: 'EPN3'},
{value: 'EPO', id: 'EPO'},
{value: 'EPOP', id: 'EPOP'},
{value: 'EPOR', id: 'EPOR'},
{value: 'EPPIN', id: 'EPPIN'},
{value: 'EPPIN-WFDC6', id: 'EPPIN-WFDC6'},
{value: 'EPPK1', id: 'EPPK1'},
{value: 'EPRS1', id: 'EPRS1'},
{value: 'EPS15', id: 'EPS15'},
{value: 'EPS15-AS1', id: 'EPS15-AS1'},
{value: 'EPS15L1', id: 'EPS15L1'},
{value: 'EPS15P1', id: 'EPS15P1'},
{value: 'EPS8', id: 'EPS8'},
{value: 'EPS8L1', id: 'EPS8L1'},
{value: 'EPS8L2', id: 'EPS8L2'},
{value: 'EPS8L3', id: 'EPS8L3'},
{value: 'EPSTI1', id: 'EPSTI1'},
{value: 'EPX', id: 'EPX'},
{value: 'EPYC', id: 'EPYC'},
{value: 'EQTN', id: 'EQTN'},
{value: 'ERAL1', id: 'ERAL1'},
{value: 'ERAP1', id: 'ERAP1'},
{value: 'ERAP2', id: 'ERAP2'},
{value: 'ERBB2', id: 'ERBB2'},
{value: 'ERBB3', id: 'ERBB3'},
{value: 'ERBB4', id: 'ERBB4'},
{value: 'ERBIN', id: 'ERBIN'},
{value: 'ERBIN-DT', id: 'ERBIN-DT'},
{value: 'ERC1', id: 'ERC1'},
{value: 'ERC2', id: 'ERC2'},
{value: 'ERC2-IT1', id: 'ERC2-IT1'},
{value: 'ERCC1', id: 'ERCC1'},
{value: 'ERCC2', id: 'ERCC2'},
{value: 'ERCC3', id: 'ERCC3'},
{value: 'ERCC4', id: 'ERCC4'},
{value: 'ERCC5', id: 'ERCC5'},
{value: 'ERCC6', id: 'ERCC6'},
{value: 'ERCC6L', id: 'ERCC6L'},
{value: 'ERCC6L2', id: 'ERCC6L2'},
{value: 'ERCC8', id: 'ERCC8'},
{value: 'EREG', id: 'EREG'},
{value: 'ERF', id: 'ERF'},
{value: 'ERFE', id: 'ERFE'},
{value: 'ERFL', id: 'ERFL'},
{value: 'ERG', id: 'ERG'},
{value: 'ERG28', id: 'ERG28'},
{value: 'ERGIC1', id: 'ERGIC1'},
{value: 'ERGIC2', id: 'ERGIC2'},
{value: 'ERGIC3', id: 'ERGIC3'},
{value: 'ERH', id: 'ERH'},
{value: 'ERHP1', id: 'ERHP1'},
{value: 'ERHP2', id: 'ERHP2'},
{value: 'ERI1', id: 'ERI1'},
{value: 'ERI2', id: 'ERI2'},
{value: 'ERI3', id: 'ERI3'},
{value: 'ERICD', id: 'ERICD'},
{value: 'ERICH1', id: 'ERICH1'},
{value: 'ERICH2', id: 'ERICH2'},
{value: 'ERICH3', id: 'ERICH3'},
{value: 'ERICH3-AS1', id: 'ERICH3-AS1'},
{value: 'ERICH4', id: 'ERICH4'},
{value: 'ERICH5', id: 'ERICH5'},
{value: 'ERICH6', id: 'ERICH6'},
{value: 'ERICH6-AS1', id: 'ERICH6-AS1'},
{value: 'ERICH6B', id: 'ERICH6B'},
{value: 'ERLEC1', id: 'ERLEC1'},
{value: 'ERLIN1', id: 'ERLIN1'},
{value: 'ERLIN2', id: 'ERLIN2'},
{value: 'ERMAP', id: 'ERMAP'},
{value: 'ERMARD', id: 'ERMARD'},
{value: 'ERMN', id: 'ERMN'},
{value: 'ERMP1', id: 'ERMP1'},
{value: 'ERN1', id: 'ERN1'},
{value: 'ERN2', id: 'ERN2'},
{value: 'ERO1A', id: 'ERO1A'},
{value: 'ERO1B', id: 'ERO1B'},
{value: 'ERP27', id: 'ERP27'},
{value: 'ERP29', id: 'ERP29'},
{value: 'ERP29P1', id: 'ERP29P1'},
{value: 'ERP44', id: 'ERP44'},
{value: 'ERRFI1', id: 'ERRFI1'},
{value: 'ERV3-1', id: 'ERV3-1'},
{value: 'ERVE-1', id: 'ERVE-1'},
{value: 'ERVFRD-1', id: 'ERVFRD-1'},
{value: 'ERVH48-1', id: 'ERVH48-1'},
{value: 'ERVK-28', id: 'ERVK-28'},
{value: 'ERVK13-1', id: 'ERVK13-1'},
{value: 'ERVK3-1', id: 'ERVK3-1'},
{value: 'ERVK9-11', id: 'ERVK9-11'},
{value: 'ERVMER34-1', id: 'ERVMER34-1'},
{value: 'ERVV-1', id: 'ERVV-1'},
{value: 'ERVV-2', id: 'ERVV-2'},
{value: 'ERVW-1', id: 'ERVW-1'},
{value: 'ESAM', id: 'ESAM'},
{value: 'ESAM-AS1', id: 'ESAM-AS1'},
{value: 'ESCO1', id: 'ESCO1'},
{value: 'ESCO2', id: 'ESCO2'},
{value: 'ESD', id: 'ESD'},
{value: 'ESF1', id: 'ESF1'},
{value: 'ESM1', id: 'ESM1'},
{value: 'ESPL1', id: 'ESPL1'},
{value: 'ESPN', id: 'ESPN'},
{value: 'ESPNL', id: 'ESPNL'},
{value: 'ESPNP', id: 'ESPNP'},
{value: 'ESR1', id: 'ESR1'},
{value: 'ESR2', id: 'ESR2'},
{value: 'ESRG', id: 'ESRG'},
{value: 'ESRP1', id: 'ESRP1'},
{value: 'ESRP2', id: 'ESRP2'},
{value: 'ESRRA', id: 'ESRRA'},
{value: 'ESRRAP1', id: 'ESRRAP1'},
{value: 'ESRRAP2', id: 'ESRRAP2'},
{value: 'ESRRB', id: 'ESRRB'},
{value: 'ESRRG', id: 'ESRRG'},
{value: 'ESS2', id: 'ESS2'},
{value: 'ESYT1', id: 'ESYT1'},
{value: 'ESYT2', id: 'ESYT2'},
{value: 'ESYT3', id: 'ESYT3'},
{value: 'ETAA1', id: 'ETAA1'},
{value: 'ETF1', id: 'ETF1'},
{value: 'ETF1P2', id: 'ETF1P2'},
{value: 'ETFA', id: 'ETFA'},
{value: 'ETFB', id: 'ETFB'},
{value: 'ETFBKMT', id: 'ETFBKMT'},
{value: 'ETFDH', id: 'ETFDH'},
{value: 'ETFRF1', id: 'ETFRF1'},
{value: 'ETHE1', id: 'ETHE1'},
{value: 'ETNK1', id: 'ETNK1'},
{value: 'ETNK1-DT', id: 'ETNK1-DT'},
{value: 'ETNK2', id: 'ETNK2'},
{value: 'ETNPPL', id: 'ETNPPL'},
{value: 'ETS1', id: 'ETS1'},
{value: 'ETS2', id: 'ETS2'},
{value: 'ETV1', id: 'ETV1'},
{value: 'ETV2', id: 'ETV2'},
{value: 'ETV3', id: 'ETV3'},
{value: 'ETV3L', id: 'ETV3L'},
{value: 'ETV4', id: 'ETV4'},
{value: 'ETV5', id: 'ETV5'},
{value: 'ETV5-AS1', id: 'ETV5-AS1'},
{value: 'ETV6', id: 'ETV6'},
{value: 'ETV7', id: 'ETV7'},
{value: 'ETV7-AS1', id: 'ETV7-AS1'},
{value: 'EVA1A', id: 'EVA1A'},
{value: 'EVA1B', id: 'EVA1B'},
{value: 'EVA1C', id: 'EVA1C'},
{value: 'EVC', id: 'EVC'},
{value: 'EVC2', id: 'EVC2'},
{value: 'EVI2A', id: 'EVI2A'},
{value: 'EVI2B', id: 'EVI2B'},
{value: 'EVI5', id: 'EVI5'},
{value: 'EVI5L', id: 'EVI5L'},
{value: 'EVL', id: 'EVL'},
{value: 'EVPL', id: 'EVPL'},
{value: 'EVPLL', id: 'EVPLL'},
{value: 'EVX1', id: 'EVX1'},
{value: 'EVX1-AS', id: 'EVX1-AS'},
{value: 'EVX2', id: 'EVX2'},
{value: 'EWSAT1', id: 'EWSAT1'},
{value: 'EWSR1', id: 'EWSR1'},
{value: 'EXD1', id: 'EXD1'},
{value: 'EXD2', id: 'EXD2'},
{value: 'EXD3', id: 'EXD3'},
{value: 'EXO1', id: 'EXO1'},
{value: 'EXO5', id: 'EXO5'},
{value: 'EXOC1', id: 'EXOC1'},
{value: 'EXOC1L', id: 'EXOC1L'},
{value: 'EXOC2', id: 'EXOC2'},
{value: 'EXOC3', id: 'EXOC3'},
{value: 'EXOC3-AS1', id: 'EXOC3-AS1'},
{value: 'EXOC3L1', id: 'EXOC3L1'},
{value: 'EXOC3L2', id: 'EXOC3L2'},
{value: 'EXOC3L4', id: 'EXOC3L4'},
{value: 'EXOC4', id: 'EXOC4'},
{value: 'EXOC5', id: 'EXOC5'},
{value: 'EXOC5P1', id: 'EXOC5P1'},
{value: 'EXOC6', id: 'EXOC6'},
{value: 'EXOC6B', id: 'EXOC6B'},
{value: 'EXOC7', id: 'EXOC7'},
{value: 'EXOC7P1', id: 'EXOC7P1'},
{value: 'EXOC8', id: 'EXOC8'},
{value: 'EXOG', id: 'EXOG'},
{value: 'EXOGP1', id: 'EXOGP1'},
{value: 'EXOSC1', id: 'EXOSC1'},
{value: 'EXOSC10', id: 'EXOSC10'},
{value: 'EXOSC10-AS1', id: 'EXOSC10-AS1'},
{value: 'EXOSC2', id: 'EXOSC2'},
{value: 'EXOSC3', id: 'EXOSC3'},
{value: 'EXOSC3P1', id: 'EXOSC3P1'},
{value: 'EXOSC3P2', id: 'EXOSC3P2'},
{value: 'EXOSC4', id: 'EXOSC4'},
{value: 'EXOSC5', id: 'EXOSC5'},
{value: 'EXOSC6', id: 'EXOSC6'},
{value: 'EXOSC7', id: 'EXOSC7'},
{value: 'EXOSC8', id: 'EXOSC8'},
{value: 'EXOSC9', id: 'EXOSC9'},
{value: 'EXPH5', id: 'EXPH5'},
{value: 'EXT1', id: 'EXT1'},
{value: 'EXT2', id: 'EXT2'},
{value: 'EXTL1', id: 'EXTL1'},
{value: 'EXTL2', id: 'EXTL2'},
{value: 'EXTL2P1', id: 'EXTL2P1'},
{value: 'EXTL3', id: 'EXTL3'},
{value: 'EXTL3-AS1', id: 'EXTL3-AS1'},
{value: 'EYA1', id: 'EYA1'},
{value: 'EYA2', id: 'EYA2'},
{value: 'EYA3', id: 'EYA3'},
{value: 'EYA4', id: 'EYA4'},
{value: 'EYS', id: 'EYS'},
{value: 'EZH1', id: 'EZH1'},
{value: 'EZH2', id: 'EZH2'},
{value: 'EZHIP', id: 'EZHIP'},
{value: 'EZR', id: 'EZR'},
{value: 'EZR-AS1', id: 'EZR-AS1'},
{value: 'F10', id: 'F10'},
{value: 'F11', id: 'F11'},
{value: 'F11-AS1', id: 'F11-AS1'},
{value: 'F11R', id: 'F11R'},
{value: 'F12', id: 'F12'},
{value: 'F13A1', id: 'F13A1'},
{value: 'F13B', id: 'F13B'},
{value: 'F2', id: 'F2'},
{value: 'F2R', id: 'F2R'},
{value: 'F2RL1', id: 'F2RL1'},
{value: 'F2RL2', id: 'F2RL2'},
{value: 'F2RL3', id: 'F2RL3'},
{value: 'F3', id: 'F3'},
{value: 'F5', id: 'F5'},
{value: 'F7', id: 'F7'},
{value: 'F8', id: 'F8'},
{value: 'F8A1', id: 'F8A1'},
{value: 'F8A2', id: 'F8A2'},
{value: 'F8A3', id: 'F8A3'},
{value: 'F9', id: 'F9'},
{value: 'FA2H', id: 'FA2H'},
{value: 'FAAH', id: 'FAAH'},
{value: 'FAAH2', id: 'FAAH2'},
{value: 'FAAHP1', id: 'FAAHP1'},
{value: 'FAAP100', id: 'FAAP100'},
{value: 'FAAP20', id: 'FAAP20'},
{value: 'FAAP24', id: 'FAAP24'},
{value: 'FABP1', id: 'FABP1'},
{value: 'FABP12', id: 'FABP12'},
{value: 'FABP2', id: 'FABP2'},
{value: 'FABP3', id: 'FABP3'},
{value: 'FABP3P2', id: 'FABP3P2'},
{value: 'FABP4', id: 'FABP4'},
{value: 'FABP5', id: 'FABP5'},
{value: 'FABP5P1', id: 'FABP5P1'},
{value: 'FABP5P10', id: 'FABP5P10'},
{value: 'FABP5P12', id: 'FABP5P12'},
{value: 'FABP5P3', id: 'FABP5P3'},
{value: 'FABP5P6', id: 'FABP5P6'},
{value: 'FABP5P7', id: 'FABP5P7'},
{value: 'FABP6', id: 'FABP6'},
{value: 'FABP7', id: 'FABP7'},
{value: 'FABP9', id: 'FABP9'},
{value: 'FADD', id: 'FADD'},
{value: 'FADS1', id: 'FADS1'},
{value: 'FADS2', id: 'FADS2'},
{value: 'FADS2B', id: 'FADS2B'},
{value: 'FADS3', id: 'FADS3'},
{value: 'FADS6', id: 'FADS6'},
{value: 'FAF1', id: 'FAF1'},
{value: 'FAF2', id: 'FAF2'},
{value: 'FAF2P1', id: 'FAF2P1'},
{value: 'FAH', id: 'FAH'},
{value: 'FAHD1', id: 'FAHD1'},
{value: 'FAHD2A', id: 'FAHD2A'},
{value: 'FAHD2B', id: 'FAHD2B'},
{value: 'FAHD2CP', id: 'FAHD2CP'},
{value: 'FAHD2P1', id: 'FAHD2P1'},
{value: 'FAIM', id: 'FAIM'},
{value: 'FAIM2', id: 'FAIM2'},
{value: 'FALEC', id: 'FALEC'},
{value: 'FAM102A', id: 'FAM102A'},
{value: 'FAM102B', id: 'FAM102B'},
{value: 'FAM104A', id: 'FAM104A'},
{value: 'FAM104B', id: 'FAM104B'},
{value: 'FAM106A', id: 'FAM106A'},
{value: 'FAM106C', id: 'FAM106C'},
{value: 'FAM107A', id: 'FAM107A'},
{value: 'FAM107B', id: 'FAM107B'},
{value: 'FAM110A', id: 'FAM110A'},
{value: 'FAM110B', id: 'FAM110B'},
{value: 'FAM110C', id: 'FAM110C'},
{value: 'FAM110D', id: 'FAM110D'},
{value: 'FAM111A', id: 'FAM111A'},
{value: 'FAM111A-DT', id: 'FAM111A-DT'},
{value: 'FAM111B', id: 'FAM111B'},
{value: 'FAM114A1', id: 'FAM114A1'},
{value: 'FAM114A2', id: 'FAM114A2'},
{value: 'FAM117A', id: 'FAM117A'},
{value: 'FAM117B', id: 'FAM117B'},
{value: 'FAM118A', id: 'FAM118A'},
{value: 'FAM118B', id: 'FAM118B'},
{value: 'FAM120A', id: 'FAM120A'},
{value: 'FAM120AOS', id: 'FAM120AOS'},
{value: 'FAM120B', id: 'FAM120B'},
{value: 'FAM120C', id: 'FAM120C'},
{value: 'FAM124A', id: 'FAM124A'},
{value: 'FAM124B', id: 'FAM124B'},
{value: 'FAM126A', id: 'FAM126A'},
{value: 'FAM126B', id: 'FAM126B'},
{value: 'FAM131A', id: 'FAM131A'},
{value: 'FAM131B', id: 'FAM131B'},
{value: 'FAM131B-AS1', id: 'FAM131B-AS1'},
{value: 'FAM131C', id: 'FAM131C'},
{value: 'FAM133A', id: 'FAM133A'},
{value: 'FAM133B', id: 'FAM133B'},
{value: 'FAM133CP', id: 'FAM133CP'},
{value: 'FAM133DP', id: 'FAM133DP'},
{value: 'FAM133GP', id: 'FAM133GP'},
{value: 'FAM135A', id: 'FAM135A'},
{value: 'FAM135A-AS1', id: 'FAM135A-AS1'},
{value: 'FAM135B', id: 'FAM135B'},
{value: 'FAM136A', id: 'FAM136A'},
{value: 'FAM136BP', id: 'FAM136BP'},
{value: 'FAM136CP', id: 'FAM136CP'},
{value: 'FAM136DP', id: 'FAM136DP'},
{value: 'FAM136EP', id: 'FAM136EP'},
{value: 'FAM136FP', id: 'FAM136FP'},
{value: 'FAM138A', id: 'FAM138A'},
{value: 'FAM138B', id: 'FAM138B'},
{value: 'FAM138D', id: 'FAM138D'},
{value: 'FAM138E', id: 'FAM138E'},
{value: 'FAM138F', id: 'FAM138F'},
{value: 'FAM13A', id: 'FAM13A'},
{value: 'FAM13A-AS1', id: 'FAM13A-AS1'},
{value: 'FAM13B', id: 'FAM13B'},
{value: 'FAM13B-AS1', id: 'FAM13B-AS1'},
{value: 'FAM13C', id: 'FAM13C'},
{value: 'FAM149A', id: 'FAM149A'},
{value: 'FAM149B1', id: 'FAM149B1'},
{value: 'FAM149B1P1', id: 'FAM149B1P1'},
{value: 'FAM151A', id: 'FAM151A'},
{value: 'FAM151B', id: 'FAM151B'},
{value: 'FAM151B-DT', id: 'FAM151B-DT'},
{value: 'FAM153A', id: 'FAM153A'},
{value: 'FAM153B', id: 'FAM153B'},
{value: 'FAM153CP', id: 'FAM153CP'},
{value: 'FAM153DP', id: 'FAM153DP'},
{value: 'FAM156A', id: 'FAM156A'},
{value: 'FAM156B', id: 'FAM156B'},
{value: 'FAM157A', id: 'FAM157A'},
{value: 'FAM157B', id: 'FAM157B'},
{value: 'FAM157C', id: 'FAM157C'},
{value: 'FAM161A', id: 'FAM161A'},
{value: 'FAM161B', id: 'FAM161B'},
{value: 'FAM162A', id: 'FAM162A'},
{value: 'FAM162B', id: 'FAM162B'},
{value: 'FAM163A', id: 'FAM163A'},
{value: 'FAM163B', id: 'FAM163B'},
{value: 'FAM166A', id: 'FAM166A'},
{value: 'FAM166B', id: 'FAM166B'},
{value: 'FAM166C', id: 'FAM166C'},
{value: 'FAM167A', id: 'FAM167A'},
{value: 'FAM167A-AS1', id: 'FAM167A-AS1'},
{value: 'FAM167B', id: 'FAM167B'},
{value: 'FAM168A', id: 'FAM168A'},
{value: 'FAM168B', id: 'FAM168B'},
{value: 'FAM169A', id: 'FAM169A'},
{value: 'FAM169B', id: 'FAM169B'},
{value: 'FAM170A', id: 'FAM170A'},
{value: 'FAM170B', id: 'FAM170B'},
{value: 'FAM170B-AS1', id: 'FAM170B-AS1'},
{value: 'FAM171A1', id: 'FAM171A1'},
{value: 'FAM171A2', id: 'FAM171A2'},
{value: 'FAM171B', id: 'FAM171B'},
{value: 'FAM172A', id: 'FAM172A'},
{value: 'FAM172BP', id: 'FAM172BP'},
{value: 'FAM174A', id: 'FAM174A'},
{value: 'FAM174A-DT', id: 'FAM174A-DT'},
{value: 'FAM174B', id: 'FAM174B'},
{value: 'FAM174C', id: 'FAM174C'},
{value: 'FAM177A1', id: 'FAM177A1'},
{value: 'FAM177B', id: 'FAM177B'},
{value: 'FAM178B', id: 'FAM178B'},
{value: 'FAM180A', id: 'FAM180A'},
{value: 'FAM180B', id: 'FAM180B'},
{value: 'FAM181A', id: 'FAM181A'},
{value: 'FAM181A-AS1', id: 'FAM181A-AS1'},
{value: 'FAM181B', id: 'FAM181B'},
{value: 'FAM182A', id: 'FAM182A'},
{value: 'FAM182B', id: 'FAM182B'},
{value: 'FAM183A', id: 'FAM183A'},
{value: 'FAM183BP', id: 'FAM183BP'},
{value: 'FAM183DP', id: 'FAM183DP'},
{value: 'FAM184A', id: 'FAM184A'},
{value: 'FAM184B', id: 'FAM184B'},
{value: 'FAM185A', id: 'FAM185A'},
{value: 'FAM185BP', id: 'FAM185BP'},
{value: 'FAM186A', id: 'FAM186A'},
{value: 'FAM186B', id: 'FAM186B'},
{value: 'FAM187A', id: 'FAM187A'},
{value: 'FAM187B', id: 'FAM187B'},
{value: 'FAM187B2P', id: 'FAM187B2P'},
{value: 'FAM189A1', id: 'FAM189A1'},
{value: 'FAM189A2', id: 'FAM189A2'},
{value: 'FAM189B', id: 'FAM189B'},
{value: 'FAM192BP', id: 'FAM192BP'},
{value: 'FAM193A', id: 'FAM193A'},
{value: 'FAM193B', id: 'FAM193B'},
{value: 'FAM193B-DT', id: 'FAM193B-DT'},
{value: 'FAM199X', id: 'FAM199X'},
{value: 'FAM200A', id: 'FAM200A'},
{value: 'FAM200B', id: 'FAM200B'},
{value: 'FAM201A', id: 'FAM201A'},
{value: 'FAM201B', id: 'FAM201B'},
{value: 'FAM204A', id: 'FAM204A'},
{value: 'FAM205A', id: 'FAM205A'},
{value: 'FAM205BP', id: 'FAM205BP'},
{value: 'FAM205C', id: 'FAM205C'},
{value: 'FAM207BP', id: 'FAM207BP'},
{value: 'FAM207CP', id: 'FAM207CP'},
{value: 'FAM209A', id: 'FAM209A'},
{value: 'FAM209B', id: 'FAM209B'},
{value: 'FAM20A', id: 'FAM20A'},
{value: 'FAM20B', id: 'FAM20B'},
{value: 'FAM20C', id: 'FAM20C'},
{value: 'FAM210A', id: 'FAM210A'},
{value: 'FAM210B', id: 'FAM210B'},
{value: 'FAM210CP', id: 'FAM210CP'},
{value: 'FAM214A', id: 'FAM214A'},
{value: 'FAM214B', id: 'FAM214B'},
{value: 'FAM214BP1', id: 'FAM214BP1'},
{value: 'FAM215A', id: 'FAM215A'},
{value: 'FAM215B', id: 'FAM215B'},
{value: 'FAM216A', id: 'FAM216A'},
{value: 'FAM216B', id: 'FAM216B'},
{value: 'FAM217A', id: 'FAM217A'},
{value: 'FAM217B', id: 'FAM217B'},
{value: 'FAM218A', id: 'FAM218A'},
{value: 'FAM218BP', id: 'FAM218BP'},
{value: 'FAM219A', id: 'FAM219A'},
{value: 'FAM219B', id: 'FAM219B'},
{value: 'FAM21EP', id: 'FAM21EP'},
{value: 'FAM21FP', id: 'FAM21FP'},
{value: 'FAM220A', id: 'FAM220A'},
{value: 'FAM220CP', id: 'FAM220CP'},
{value: 'FAM221A', id: 'FAM221A'},
{value: 'FAM221B', id: 'FAM221B'},
{value: 'FAM222A', id: 'FAM222A'},
{value: 'FAM222A-AS1', id: 'FAM222A-AS1'},
{value: 'FAM222B', id: 'FAM222B'},
{value: 'FAM223A', id: 'FAM223A'},
{value: 'FAM223B', id: 'FAM223B'},
{value: 'FAM225A', id: 'FAM225A'},
{value: 'FAM225B', id: 'FAM225B'},
{value: 'FAM226B', id: 'FAM226B'},
{value: 'FAM227A', id: 'FAM227A'},
{value: 'FAM227B', id: 'FAM227B'},
{value: 'FAM228A', id: 'FAM228A'},
{value: 'FAM228B', id: 'FAM228B'},
{value: 'FAM229A', id: 'FAM229A'},
{value: 'FAM229B', id: 'FAM229B'},
{value: 'FAM230B', id: 'FAM230B'},
{value: 'FAM230C', id: 'FAM230C'},
{value: 'FAM230D', id: 'FAM230D'},
{value: 'FAM230E', id: 'FAM230E'},
{value: 'FAM230F', id: 'FAM230F'},
{value: 'FAM230G', id: 'FAM230G'},
{value: 'FAM230I', id: 'FAM230I'},
{value: 'FAM230J', id: 'FAM230J'},
{value: 'FAM234A', id: 'FAM234A'},
{value: 'FAM234B', id: 'FAM234B'},
{value: 'FAM237A', id: 'FAM237A'},
{value: 'FAM237B', id: 'FAM237B'},
{value: 'FAM238A', id: 'FAM238A'},
{value: 'FAM238B', id: 'FAM238B'},
{value: 'FAM238C', id: 'FAM238C'},
{value: 'FAM240A', id: 'FAM240A'},
{value: 'FAM240B', id: 'FAM240B'},
{value: 'FAM240C', id: 'FAM240C'},
{value: 'FAM241A', id: 'FAM241A'},
{value: 'FAM241B', id: 'FAM241B'},
{value: 'FAM242A', id: 'FAM242A'},
{value: 'FAM242C', id: 'FAM242C'},
{value: 'FAM242F', id: 'FAM242F'},
{value: 'FAM243A', id: 'FAM243A'},
{value: 'FAM243B', id: 'FAM243B'},
{value: 'FAM245A', id: 'FAM245A'},
{value: 'FAM245B', id: 'FAM245B'},
{value: 'FAM246A', id: 'FAM246A'},
{value: 'FAM246B', id: 'FAM246B'},
{value: 'FAM246C', id: 'FAM246C'},
{value: 'FAM247A', id: 'FAM247A'},
{value: 'FAM24A', id: 'FAM24A'},
{value: 'FAM24B', id: 'FAM24B'},
{value: 'FAM25BP', id: 'FAM25BP'},
{value: 'FAM25C', id: 'FAM25C'},
{value: 'FAM25E', id: 'FAM25E'},
{value: 'FAM25G', id: 'FAM25G'},
{value: 'FAM27B', id: 'FAM27B'},
{value: 'FAM27C', id: 'FAM27C'},
{value: 'FAM27E3', id: 'FAM27E3'},
{value: 'FAM27E5', id: 'FAM27E5'},
{value: 'FAM30A', id: 'FAM30A'},
{value: 'FAM30B', id: 'FAM30B'},
{value: 'FAM30C', id: 'FAM30C'},
{value: 'FAM32A', id: 'FAM32A'},
{value: 'FAM32CP', id: 'FAM32CP'},
{value: 'FAM32DP', id: 'FAM32DP'},
{value: 'FAM32EP', id: 'FAM32EP'},
{value: 'FAM3A', id: 'FAM3A'},
{value: 'FAM3B', id: 'FAM3B'},
{value: 'FAM3C', id: 'FAM3C'},
{value: 'FAM3C2P', id: 'FAM3C2P'},
{value: 'FAM3D', id: 'FAM3D'},
{value: 'FAM3D-AS1', id: 'FAM3D-AS1'},
{value: 'FAM41C', id: 'FAM41C'},
{value: 'FAM43A', id: 'FAM43A'},
{value: 'FAM43B', id: 'FAM43B'},
{value: 'FAM47E', id: 'FAM47E'},
{value: 'FAM47E-STBD1', id: 'FAM47E-STBD1'},
{value: 'FAM50A', id: 'FAM50A'},
{value: 'FAM50B', id: 'FAM50B'},
{value: 'FAM53A', id: 'FAM53A'},
{value: 'FAM53B', id: 'FAM53B'},
{value: 'FAM53B-AS1', id: 'FAM53B-AS1'},
{value: 'FAM53C', id: 'FAM53C'},
{value: 'FAM66A', id: 'FAM66A'},
{value: 'FAM66B', id: 'FAM66B'},
{value: 'FAM66C', id: 'FAM66C'},
{value: 'FAM66D', id: 'FAM66D'},
{value: 'FAM66E', id: 'FAM66E'},
{value: 'FAM71A', id: 'FAM71A'},
{value: 'FAM71B', id: 'FAM71B'},
{value: 'FAM71BP1', id: 'FAM71BP1'},
{value: 'FAM71C', id: 'FAM71C'},
{value: 'FAM71D', id: 'FAM71D'},
{value: 'FAM71E1', id: 'FAM71E1'},
{value: 'FAM71E2', id: 'FAM71E2'},
{value: 'FAM71F1', id: 'FAM71F1'},
{value: 'FAM71F2', id: 'FAM71F2'},
{value: 'FAM72A', id: 'FAM72A'},
{value: 'FAM72B', id: 'FAM72B'},
{value: 'FAM72C', id: 'FAM72C'},
{value: 'FAM72D', id: 'FAM72D'},
{value: 'FAM76A', id: 'FAM76A'},
{value: 'FAM76B', id: 'FAM76B'},
{value: 'FAM78A', id: 'FAM78A'},
{value: 'FAM78B', id: 'FAM78B'},
{value: 'FAM78B-AS1', id: 'FAM78B-AS1'},
{value: 'FAM81A', id: 'FAM81A'},
{value: 'FAM81B', id: 'FAM81B'},
{value: 'FAM83A', id: 'FAM83A'},
{value: 'FAM83B', id: 'FAM83B'},
{value: 'FAM83C', id: 'FAM83C'},
{value: 'FAM83D', id: 'FAM83D'},
{value: 'FAM83E', id: 'FAM83E'},
{value: 'FAM83F', id: 'FAM83F'},
{value: 'FAM83G', id: 'FAM83G'},
{value: 'FAM83H', id: 'FAM83H'},
{value: 'FAM85B', id: 'FAM85B'},
{value: 'FAM86B1', id: 'FAM86B1'},
{value: 'FAM86B2', id: 'FAM86B2'},
{value: 'FAM86B3P', id: 'FAM86B3P'},
{value: 'FAM86C1P', id: 'FAM86C1P'},
{value: 'FAM86C2P', id: 'FAM86C2P'},
{value: 'FAM86DP', id: 'FAM86DP'},
{value: 'FAM86EP', id: 'FAM86EP'},
{value: 'FAM86FP', id: 'FAM86FP'},
{value: 'FAM86GP', id: 'FAM86GP'},
{value: 'FAM86HP', id: 'FAM86HP'},
{value: 'FAM86JP', id: 'FAM86JP'},
{value: 'FAM86KP', id: 'FAM86KP'},
{value: 'FAM86LP', id: 'FAM86LP'},
{value: 'FAM86MP', id: 'FAM86MP'},
{value: 'FAM87A', id: 'FAM87A'},
{value: 'FAM87B', id: 'FAM87B'},
{value: 'FAM89A', id: 'FAM89A'},
{value: 'FAM89B', id: 'FAM89B'},
{value: 'FAM8A1', id: 'FAM8A1'},
{value: 'FAM8A2P', id: 'FAM8A2P'},
{value: 'FAM8A3P', id: 'FAM8A3P'},
{value: 'FAM8A6P', id: 'FAM8A6P'},
{value: 'FAM90A1', id: 'FAM90A1'},
{value: 'FAM90A10P', id: 'FAM90A10P'},
{value: 'FAM90A12P', id: 'FAM90A12P'},
{value: 'FAM90A14P', id: 'FAM90A14P'},
{value: 'FAM90A15P', id: 'FAM90A15P'},
{value: 'FAM90A23P', id: 'FAM90A23P'},
{value: 'FAM90A24P', id: 'FAM90A24P'},
{value: 'FAM90A27P', id: 'FAM90A27P'},
{value: 'FAM90A28P', id: 'FAM90A28P'},
{value: 'FAM91A1', id: 'FAM91A1'},
{value: 'FAM91A2P', id: 'FAM91A2P'},
{value: 'FAM91A3P', id: 'FAM91A3P'},
{value: 'FAM95B1', id: 'FAM95B1'},
{value: 'FAM95C', id: 'FAM95C'},
{value: 'FAM98A', id: 'FAM98A'},
{value: 'FAM98B', id: 'FAM98B'},
{value: 'FAM98C', id: 'FAM98C'},
{value: 'FAM99A', id: 'FAM99A'},
{value: 'FAM99B', id: 'FAM99B'},
{value: 'FAM9C', id: 'FAM9C'},
{value: 'FAN1', id: 'FAN1'},
{value: 'FANCA', id: 'FANCA'},
{value: 'FANCB', id: 'FANCB'},
{value: 'FANCC', id: 'FANCC'},
{value: 'FANCD2', id: 'FANCD2'},
{value: 'FANCD2OS', id: 'FANCD2OS'},
{value: 'FANCD2P2', id: 'FANCD2P2'},
{value: 'FANCE', id: 'FANCE'},
{value: 'FANCF', id: 'FANCF'},
{value: 'FANCG', id: 'FANCG'},
{value: 'FANCI', id: 'FANCI'},
{value: 'FANCL', id: 'FANCL'},
{value: 'FANCM', id: 'FANCM'},
{value: 'FANK1', id: 'FANK1'},
{value: 'FANK1-AS1', id: 'FANK1-AS1'},
{value: 'FAP', id: 'FAP'},
{value: 'FAR1', id: 'FAR1'},
{value: 'FAR1-IT1', id: 'FAR1-IT1'},
{value: 'FAR1P1', id: 'FAR1P1'},
{value: 'FAR2', id: 'FAR2'},
{value: 'FAR2P1', id: 'FAR2P1'},
{value: 'FAR2P2', id: 'FAR2P2'},
{value: 'FAR2P3', id: 'FAR2P3'},
{value: 'FARP1', id: 'FARP1'},
{value: 'FARP2', id: 'FARP2'},
{value: 'FARS2', id: 'FARS2'},
{value: 'FARSA', id: 'FARSA'},
{value: 'FARSA-AS1', id: 'FARSA-AS1'},
{value: 'FARSB', id: 'FARSB'},
{value: 'FARSBP1', id: 'FARSBP1'},
{value: 'FAS', id: 'FAS'},
{value: 'FASLG', id: 'FASLG'},
{value: 'FASN', id: 'FASN'},
{value: 'FASTK', id: 'FASTK'},
{value: 'FASTKD1', id: 'FASTKD1'},
{value: 'FASTKD2', id: 'FASTKD2'},
{value: 'FASTKD3', id: 'FASTKD3'},
{value: 'FASTKD5', id: 'FASTKD5'},
{value: 'FAT1', id: 'FAT1'},
{value: 'FAT2', id: 'FAT2'},
{value: 'FAT3', id: 'FAT3'},
{value: 'FAT4', id: 'FAT4'},
{value: 'FATE1', id: 'FATE1'},
{value: 'FAU', id: 'FAU'},
{value: 'FAUP1', id: 'FAUP1'},
{value: 'FAUP2', id: 'FAUP2'},
{value: 'FAXC', id: 'FAXC'},
{value: 'FAXDC2', id: 'FAXDC2'},
{value: 'FBF1', id: 'FBF1'},
{value: 'FBH1', id: 'FBH1'},
{value: 'FBL', id: 'FBL'},
{value: 'FBLIM1', id: 'FBLIM1'},
{value: 'FBLIM1P2', id: 'FBLIM1P2'},
{value: 'FBLL1', id: 'FBLL1'},
{value: 'FBLN1', id: 'FBLN1'},
{value: 'FBLN2', id: 'FBLN2'},
{value: 'FBLN5', id: 'FBLN5'},
{value: 'FBLN7', id: 'FBLN7'},
{value: 'FBN1', id: 'FBN1'},
{value: 'FBN1-DT', id: 'FBN1-DT'},
{value: 'FBN2', id: 'FBN2'},
{value: 'FBN3', id: 'FBN3'},
{value: 'FBP1', id: 'FBP1'},
{value: 'FBP2', id: 'FBP2'},
{value: 'FBP2P1', id: 'FBP2P1'},
{value: 'FBRS', id: 'FBRS'},
{value: 'FBRSL1', id: 'FBRSL1'},
{value: 'FBXL12', id: 'FBXL12'},
{value: 'FBXL12P1', id: 'FBXL12P1'},
{value: 'FBXL13', id: 'FBXL13'},
{value: 'FBXL14', id: 'FBXL14'},
{value: 'FBXL15', id: 'FBXL15'},
{value: 'FBXL16', id: 'FBXL16'},
{value: 'FBXL17', id: 'FBXL17'},
{value: 'FBXL18', id: 'FBXL18'},
{value: 'FBXL19', id: 'FBXL19'},
{value: 'FBXL19-AS1', id: 'FBXL19-AS1'},
{value: 'FBXL2', id: 'FBXL2'},
{value: 'FBXL20', id: 'FBXL20'},
{value: 'FBXL21P', id: 'FBXL21P'},
{value: 'FBXL22', id: 'FBXL22'},
{value: 'FBXL3', id: 'FBXL3'},
{value: 'FBXL4', id: 'FBXL4'},
{value: 'FBXL5', id: 'FBXL5'},
{value: 'FBXL6', id: 'FBXL6'},
{value: 'FBXL7', id: 'FBXL7'},
{value: 'FBXL8', id: 'FBXL8'},
{value: 'FBXO10', id: 'FBXO10'},
{value: 'FBXO11', id: 'FBXO11'},
{value: 'FBXO15', id: 'FBXO15'},
{value: 'FBXO16', id: 'FBXO16'},
{value: 'FBXO17', id: 'FBXO17'},
{value: 'FBXO2', id: 'FBXO2'},
{value: 'FBXO21', id: 'FBXO21'},
{value: 'FBXO22', id: 'FBXO22'},
{value: 'FBXO24', id: 'FBXO24'},
{value: 'FBXO25', id: 'FBXO25'},
{value: 'FBXO27', id: 'FBXO27'},
{value: 'FBXO28', id: 'FBXO28'},
{value: 'FBXO3', id: 'FBXO3'},
{value: 'FBXO3-DT', id: 'FBXO3-DT'},
{value: 'FBXO30', id: 'FBXO30'},
{value: 'FBXO30-DT', id: 'FBXO30-DT'},
{value: 'FBXO31', id: 'FBXO31'},
{value: 'FBXO32', id: 'FBXO32'},
{value: 'FBXO33', id: 'FBXO33'},
{value: 'FBXO34', id: 'FBXO34'},
{value: 'FBXO34-AS1', id: 'FBXO34-AS1'},
{value: 'FBXO36', id: 'FBXO36'},
{value: 'FBXO36-IT1', id: 'FBXO36-IT1'},
{value: 'FBXO36P1', id: 'FBXO36P1'},
{value: 'FBXO38', id: 'FBXO38'},
{value: 'FBXO38-DT', id: 'FBXO38-DT'},
{value: 'FBXO39', id: 'FBXO39'},
{value: 'FBXO4', id: 'FBXO4'},
{value: 'FBXO40', id: 'FBXO40'},
{value: 'FBXO41', id: 'FBXO41'},
{value: 'FBXO42', id: 'FBXO42'},
{value: 'FBXO43', id: 'FBXO43'},
{value: 'FBXO44', id: 'FBXO44'},
{value: 'FBXO45', id: 'FBXO45'},
{value: 'FBXO46', id: 'FBXO46'},
{value: 'FBXO47', id: 'FBXO47'},
{value: 'FBXO48', id: 'FBXO48'},
{value: 'FBXO5', id: 'FBXO5'},
{value: 'FBXO6', id: 'FBXO6'},
{value: 'FBXO7', id: 'FBXO7'},
{value: 'FBXO8', id: 'FBXO8'},
{value: 'FBXO9', id: 'FBXO9'},
{value: 'FBXW10', id: 'FBXW10'},
{value: 'FBXW11', id: 'FBXW11'},
{value: 'FBXW11P1', id: 'FBXW11P1'},
{value: 'FBXW12', id: 'FBXW12'},
{value: 'FBXW2', id: 'FBXW2'},
{value: 'FBXW4', id: 'FBXW4'},
{value: 'FBXW4P1', id: 'FBXW4P1'},
{value: 'FBXW5', id: 'FBXW5'},
{value: 'FBXW7', id: 'FBXW7'},
{value: 'FBXW8', id: 'FBXW8'},
{value: 'FBXW9', id: 'FBXW9'},
{value: 'FCAMR', id: 'FCAMR'},
{value: 'FCAR', id: 'FCAR'},
{value: 'FCER1A', id: 'FCER1A'},
{value: 'FCER1G', id: 'FCER1G'},
{value: 'FCER2', id: 'FCER2'},
{value: 'FCF1', id: 'FCF1'},
{value: 'FCF1P2', id: 'FCF1P2'},
{value: 'FCF1P5', id: 'FCF1P5'},
{value: 'FCF1P6', id: 'FCF1P6'},
{value: 'FCF1P7', id: 'FCF1P7'},
{value: 'FCGBP', id: 'FCGBP'},
{value: 'FCGR1A', id: 'FCGR1A'},
{value: 'FCGR1B', id: 'FCGR1B'},
{value: 'FCGR1CP', id: 'FCGR1CP'},
{value: 'FCGR2A', id: 'FCGR2A'},
{value: 'FCGR2B', id: 'FCGR2B'},
{value: 'FCGR2C', id: 'FCGR2C'},
{value: 'FCGR3A', id: 'FCGR3A'},
{value: 'FCGR3B', id: 'FCGR3B'},
{value: 'FCGRT', id: 'FCGRT'},
{value: 'FCHO1', id: 'FCHO1'},
{value: 'FCHO2', id: 'FCHO2'},
{value: 'FCHO2-DT', id: 'FCHO2-DT'},
{value: 'FCHSD1', id: 'FCHSD1'},
{value: 'FCHSD2', id: 'FCHSD2'},
{value: 'FCMR', id: 'FCMR'},
{value: 'FCN1', id: 'FCN1'},
{value: 'FCN2', id: 'FCN2'},
{value: 'FCN3', id: 'FCN3'},
{value: 'FCRL1', id: 'FCRL1'},
{value: 'FCRL2', id: 'FCRL2'},
{value: 'FCRL3', id: 'FCRL3'},
{value: 'FCRL4', id: 'FCRL4'},
{value: 'FCRL5', id: 'FCRL5'},
{value: 'FCRL6', id: 'FCRL6'},
{value: 'FCRLA', id: 'FCRLA'},
{value: 'FCRLB', id: 'FCRLB'},
{value: 'FCSK', id: 'FCSK'},
{value: 'FDCSP', id: 'FDCSP'},
{value: 'FDFT1', id: 'FDFT1'},
{value: 'FDPS', id: 'FDPS'},
{value: 'FDPSP2', id: 'FDPSP2'},
{value: 'FDPSP4', id: 'FDPSP4'},
{value: 'FDPSP6', id: 'FDPSP6'},
{value: 'FDPSP7', id: 'FDPSP7'},
{value: 'FDPSP8', id: 'FDPSP8'},
{value: 'FDX1', id: 'FDX1'},
{value: 'FDX2', id: 'FDX2'},
{value: 'FDXACB1', id: 'FDXACB1'},
{value: 'FDXR', id: 'FDXR'},
{value: 'FECH', id: 'FECH'},
{value: 'FECHP1', id: 'FECHP1'},
{value: 'FEM1A', id: 'FEM1A'},
{value: 'FEM1AP2', id: 'FEM1AP2'},
{value: 'FEM1AP3', id: 'FEM1AP3'},
{value: 'FEM1AP4', id: 'FEM1AP4'},
{value: 'FEM1B', id: 'FEM1B'},
{value: 'FEM1C', id: 'FEM1C'},
{value: 'FEN1', id: 'FEN1'},
{value: 'FEN1P1', id: 'FEN1P1'},
{value: 'FENDRR', id: 'FENDRR'},
{value: 'FER', id: 'FER'},
{value: 'FER1L4', id: 'FER1L4'},
{value: 'FER1L5', id: 'FER1L5'},
{value: 'FER1L6', id: 'FER1L6'},
{value: 'FER1L6-AS2', id: 'FER1L6-AS2'},
{value: 'FERD3L', id: 'FERD3L'},
{value: 'FERMT1', id: 'FERMT1'},
{value: 'FERMT2', id: 'FERMT2'},
{value: 'FERMT3', id: 'FERMT3'},
{value: 'FERP1', id: 'FERP1'},
{value: 'FES', id: 'FES'},
{value: 'FETUB', id: 'FETUB'},
{value: 'FEV', id: 'FEV'},
{value: 'FEZ1', id: 'FEZ1'},
{value: 'FEZ2', id: 'FEZ2'},
{value: 'FEZF1', id: 'FEZF1'},
{value: 'FEZF1-AS1', id: 'FEZF1-AS1'},
{value: 'FEZF2', id: 'FEZF2'},
{value: 'FFAR1', id: 'FFAR1'},
{value: 'FFAR2', id: 'FFAR2'},
{value: 'FFAR3', id: 'FFAR3'},
{value: 'FFAR4', id: 'FFAR4'},
{value: 'FGA', id: 'FGA'},
{value: 'FGB', id: 'FGB'},
{value: 'FGD1', id: 'FGD1'},
{value: 'FGD2', id: 'FGD2'},
{value: 'FGD3', id: 'FGD3'},
{value: 'FGD4', id: 'FGD4'},
{value: 'FGD5', id: 'FGD5'},
{value: 'FGD5-AS1', id: 'FGD5-AS1'},
{value: 'FGD5P1', id: 'FGD5P1'},
{value: 'FGD6', id: 'FGD6'},
{value: 'FGF1', id: 'FGF1'},
{value: 'FGF10', id: 'FGF10'},
{value: 'FGF10-AS1', id: 'FGF10-AS1'},
{value: 'FGF11', id: 'FGF11'},
{value: 'FGF12', id: 'FGF12'},
{value: 'FGF12-AS2', id: 'FGF12-AS2'},
{value: 'FGF13', id: 'FGF13'},
{value: 'FGF13-AS1', id: 'FGF13-AS1'},
{value: 'FGF14', id: 'FGF14'},
{value: 'FGF14-AS1', id: 'FGF14-AS1'},
{value: 'FGF14-AS2', id: 'FGF14-AS2'},
{value: 'FGF14-IT1', id: 'FGF14-IT1'},
{value: 'FGF16', id: 'FGF16'},
{value: 'FGF17', id: 'FGF17'},
{value: 'FGF18', id: 'FGF18'},
{value: 'FGF19', id: 'FGF19'},
{value: 'FGF2', id: 'FGF2'},
{value: 'FGF20', id: 'FGF20'},
{value: 'FGF21', id: 'FGF21'},
{value: 'FGF22', id: 'FGF22'},
{value: 'FGF23', id: 'FGF23'},
{value: 'FGF3', id: 'FGF3'},
{value: 'FGF4', id: 'FGF4'},
{value: 'FGF5', id: 'FGF5'},
{value: 'FGF6', id: 'FGF6'},
{value: 'FGF7', id: 'FGF7'},
{value: 'FGF7P2', id: 'FGF7P2'},
{value: 'FGF7P3', id: 'FGF7P3'},
{value: 'FGF7P4', id: 'FGF7P4'},
{value: 'FGF7P5', id: 'FGF7P5'},
{value: 'FGF7P6', id: 'FGF7P6'},
{value: 'FGF8', id: 'FGF8'},
{value: 'FGF9', id: 'FGF9'},
{value: 'FGFBP1', id: 'FGFBP1'},
{value: 'FGFBP2', id: 'FGFBP2'},
{value: 'FGFBP3', id: 'FGFBP3'},
{value: 'FGFR1', id: 'FGFR1'},
{value: 'FGFR1OP2', id: 'FGFR1OP2'},
{value: 'FGFR1OP2P1', id: 'FGFR1OP2P1'},
{value: 'FGFR2', id: 'FGFR2'},
{value: 'FGFR3', id: 'FGFR3'},
{value: 'FGFR3P1', id: 'FGFR3P1'},
{value: 'FGFR3P5', id: 'FGFR3P5'},
{value: 'FGFR3P6', id: 'FGFR3P6'},
{value: 'FGFR4', id: 'FGFR4'},
{value: 'FGFRL1', id: 'FGFRL1'},
{value: 'FGG', id: 'FGG'},
{value: 'FGGY', id: 'FGGY'},
{value: 'FGGY-DT', id: 'FGGY-DT'},
{value: 'FGL1', id: 'FGL1'},
{value: 'FGL2', id: 'FGL2'},
{value: 'FGR', id: 'FGR'},
{value: 'FH', id: 'FH'},
{value: 'FHAD1', id: 'FHAD1'},
{value: 'FHDC1', id: 'FHDC1'},
{value: 'FHIP1A', id: 'FHIP1A'},
{value: 'FHIP1A-DT', id: 'FHIP1A-DT'},
{value: 'FHIP1B', id: 'FHIP1B'},
{value: 'FHIP2A', id: 'FHIP2A'},
{value: 'FHIP2B', id: 'FHIP2B'},
{value: 'FHIT', id: 'FHIT'},
{value: 'FHL1', id: 'FHL1'},
{value: 'FHL2', id: 'FHL2'},
{value: 'FHL3', id: 'FHL3'},
{value: 'FHL5', id: 'FHL5'},
{value: 'FHOD1', id: 'FHOD1'},
{value: 'FHOD3', id: 'FHOD3'},
{value: 'FHP1', id: 'FHP1'},
{value: 'FIBCD1', id: 'FIBCD1'},
{value: 'FIBIN', id: 'FIBIN'},
{value: 'FIBP', id: 'FIBP'},
{value: 'FICD', id: 'FICD'},
{value: 'FIG4', id: 'FIG4'},
{value: 'FIGLA', id: 'FIGLA'},
{value: 'FIGN', id: 'FIGN'},
{value: 'FIGNL1', id: 'FIGNL1'},
{value: 'FIGNL2', id: 'FIGNL2'},
{value: 'FIGNL2-DT', id: 'FIGNL2-DT'},
{value: 'FILIP1', id: 'FILIP1'},
{value: 'FILIP1L', id: 'FILIP1L'},
{value: 'FILNC1', id: 'FILNC1'},
{value: 'FIP1L1', id: 'FIP1L1'},
{value: 'FIRRE', id: 'FIRRE'},
{value: 'FIS1', id: 'FIS1'},
{value: 'FITM1', id: 'FITM1'},
{value: 'FITM2', id: 'FITM2'},
{value: 'FIZ1', id: 'FIZ1'},
{value: 'FJX1', id: 'FJX1'},
{value: 'FKBP10', id: 'FKBP10'},
{value: 'FKBP11', id: 'FKBP11'},
{value: 'FKBP14', id: 'FKBP14'},
{value: 'FKBP14-AS1', id: 'FKBP14-AS1'},
{value: 'FKBP15', id: 'FKBP15'},
{value: 'FKBP1A', id: 'FKBP1A'},
{value: 'FKBP1AP1', id: 'FKBP1AP1'},
{value: 'FKBP1AP2', id: 'FKBP1AP2'},
{value: 'FKBP1AP3', id: 'FKBP1AP3'},
{value: 'FKBP1AP4', id: 'FKBP1AP4'},
{value: 'FKBP1B', id: 'FKBP1B'},
{value: 'FKBP1BP1', id: 'FKBP1BP1'},
{value: 'FKBP1C', id: 'FKBP1C'},
{value: 'FKBP2', id: 'FKBP2'},
{value: 'FKBP3', id: 'FKBP3'},
{value: 'FKBP4', id: 'FKBP4'},
{value: 'FKBP4P1', id: 'FKBP4P1'},
{value: 'FKBP4P2', id: 'FKBP4P2'},
{value: 'FKBP4P6', id: 'FKBP4P6'},
{value: 'FKBP4P7', id: 'FKBP4P7'},
{value: 'FKBP4P8', id: 'FKBP4P8'},
{value: 'FKBP5', id: 'FKBP5'},
{value: 'FKBP6', id: 'FKBP6'},
{value: 'FKBP7', id: 'FKBP7'},
{value: 'FKBP8', id: 'FKBP8'},
{value: 'FKBP9', id: 'FKBP9'},
{value: 'FKBP9P1', id: 'FKBP9P1'},
{value: 'FKBPL', id: 'FKBPL'},
{value: 'FKRP', id: 'FKRP'},
{value: 'FKTN', id: 'FKTN'},
{value: 'FLACC1', id: 'FLACC1'},
{value: 'FLAD1', id: 'FLAD1'},
{value: 'FLCN', id: 'FLCN'},
{value: 'FLG', id: 'FLG'},
{value: 'FLG-AS1', id: 'FLG-AS1'},
{value: 'FLI1', id: 'FLI1'},
{value: 'FLII', id: 'FLII'},
{value: 'FLJ12825', id: 'FLJ12825'},
{value: 'FLJ13224', id: 'FLJ13224'},
{value: 'FLJ16779', id: 'FLJ16779'},
{value: 'FLJ20021', id: 'FLJ20021'},
{value: 'FLJ30679', id: 'FLJ30679'},
{value: 'FLJ31104', id: 'FLJ31104'},
{value: 'FLJ31356', id: 'FLJ31356'},
{value: 'FLJ36000', id: 'FLJ36000'},
{value: 'FLJ37453', id: 'FLJ37453'},
{value: 'FLJ38576', id: 'FLJ38576'},
{value: 'FLJ40194', id: 'FLJ40194'},
{value: 'FLJ40288', id: 'FLJ40288'},
{value: 'FLJ42393', id: 'FLJ42393'},
{value: 'FLJ42969', id: 'FLJ42969'},
{value: 'FLJ43315', id: 'FLJ43315'},
{value: 'FLJ46284', id: 'FLJ46284'},
{value: 'FLNA', id: 'FLNA'},
{value: 'FLNB', id: 'FLNB'},
{value: 'FLNB-AS1', id: 'FLNB-AS1'},
{value: 'FLNC', id: 'FLNC'},
{value: 'FLNC-AS1', id: 'FLNC-AS1'},
{value: 'FLOT1', id: 'FLOT1'},
{value: 'FLOT2', id: 'FLOT2'},
{value: 'FLRT1', id: 'FLRT1'},
{value: 'FLRT2', id: 'FLRT2'},
{value: 'FLRT3', id: 'FLRT3'},
{value: 'FLT1', id: 'FLT1'},
{value: 'FLT1P1', id: 'FLT1P1'},
{value: 'FLT3', id: 'FLT3'},
{value: 'FLT3LG', id: 'FLT3LG'},
{value: 'FLT4', id: 'FLT4'},
{value: 'FLVCR1', id: 'FLVCR1'},
{value: 'FLVCR1-DT', id: 'FLVCR1-DT'},
{value: 'FLVCR2', id: 'FLVCR2'},
{value: 'FLYWCH1', id: 'FLYWCH1'},
{value: 'FLYWCH1P1', id: 'FLYWCH1P1'},
{value: 'FLYWCH2', id: 'FLYWCH2'},
{value: 'FMC1', id: 'FMC1'},
{value: 'FMC1-LUC7L2', id: 'FMC1-LUC7L2'},
{value: 'FMN1', id: 'FMN1'},
{value: 'FMN2', id: 'FMN2'},
{value: 'FMNL1', id: 'FMNL1'},
{value: 'FMNL1-DT', id: 'FMNL1-DT'},
{value: 'FMNL2', id: 'FMNL2'},
{value: 'FMNL3', id: 'FMNL3'},
{value: 'FMO1', id: 'FMO1'},
{value: 'FMO10P', id: 'FMO10P'},
{value: 'FMO11P', id: 'FMO11P'},
{value: 'FMO2', id: 'FMO2'},
{value: 'FMO3', id: 'FMO3'},
{value: 'FMO4', id: 'FMO4'},
{value: 'FMO5', id: 'FMO5'},
{value: 'FMO7P', id: 'FMO7P'},
{value: 'FMO8P', id: 'FMO8P'},
{value: 'FMO9P', id: 'FMO9P'},
{value: 'FMOD', id: 'FMOD'},
{value: 'FMR1', id: 'FMR1'},
{value: 'FN1', id: 'FN1'},
{value: 'FN3K', id: 'FN3K'},
{value: 'FN3KRP', id: 'FN3KRP'},
{value: 'FNBP1', id: 'FNBP1'},
{value: 'FNBP1L', id: 'FNBP1L'},
{value: 'FNBP1P1', id: 'FNBP1P1'},
{value: 'FNBP4', id: 'FNBP4'},
{value: 'FNDC1', id: 'FNDC1'},
{value: 'FNDC1-IT1', id: 'FNDC1-IT1'},
{value: 'FNDC10', id: 'FNDC10'},
{value: 'FNDC11', id: 'FNDC11'},
{value: 'FNDC3A', id: 'FNDC3A'},
{value: 'FNDC3B', id: 'FNDC3B'},
{value: 'FNDC4', id: 'FNDC4'},
{value: 'FNDC5', id: 'FNDC5'},
{value: 'FNDC7', id: 'FNDC7'},
{value: 'FNDC8', id: 'FNDC8'},
{value: 'FNDC9', id: 'FNDC9'},
{value: 'FNIP1', id: 'FNIP1'},
{value: 'FNIP2', id: 'FNIP2'},
{value: 'FNTA', id: 'FNTA'},
{value: 'FNTAP1', id: 'FNTAP1'},
{value: 'FNTAP2', id: 'FNTAP2'},
{value: 'FNTB', id: 'FNTB'},
{value: 'FOCAD', id: 'FOCAD'},
{value: 'FOCAD-AS1', id: 'FOCAD-AS1'},
{value: 'FOLH1', id: 'FOLH1'},
{value: 'FOLH1B', id: 'FOLH1B'},
{value: 'FOLR1', id: 'FOLR1'},
{value: 'FOLR1P1', id: 'FOLR1P1'},
{value: 'FOLR2', id: 'FOLR2'},
{value: 'FOLR3', id: 'FOLR3'},
{value: 'FOS', id: 'FOS'},
{value: 'FOSB', id: 'FOSB'},
{value: 'FOSL1', id: 'FOSL1'},
{value: 'FOSL1P1', id: 'FOSL1P1'},
{value: 'FOSL2', id: 'FOSL2'},
{value: 'FOXA1', id: 'FOXA1'},
{value: 'FOXA2', id: 'FOXA2'},
{value: 'FOXA3', id: 'FOXA3'},
{value: 'FOXB1', id: 'FOXB1'},
{value: 'FOXB2', id: 'FOXB2'},
{value: 'FOXC1', id: 'FOXC1'},
{value: 'FOXC2', id: 'FOXC2'},
{value: 'FOXC2-AS1', id: 'FOXC2-AS1'},
{value: 'FOXCUT', id: 'FOXCUT'},
{value: 'FOXD1', id: 'FOXD1'},
{value: 'FOXD1-AS1', id: 'FOXD1-AS1'},
{value: 'FOXD2', id: 'FOXD2'},
{value: 'FOXD2-AS1', id: 'FOXD2-AS1'},
{value: 'FOXD3', id: 'FOXD3'},
{value: 'FOXD3-AS1', id: 'FOXD3-AS1'},
{value: 'FOXD4', id: 'FOXD4'},
{value: 'FOXD4L1', id: 'FOXD4L1'},
{value: 'FOXD4L3', id: 'FOXD4L3'},
{value: 'FOXD4L4', id: 'FOXD4L4'},
{value: 'FOXD4L5', id: 'FOXD4L5'},
{value: 'FOXE1', id: 'FOXE1'},
{value: 'FOXE3', id: 'FOXE3'},
{value: 'FOXF1', id: 'FOXF1'},
{value: 'FOXF2', id: 'FOXF2'},
{value: 'FOXF2-DT', id: 'FOXF2-DT'},
{value: 'FOXG1', id: 'FOXG1'},
{value: 'FOXG1-AS1', id: 'FOXG1-AS1'},
{value: 'FOXH1', id: 'FOXH1'},
{value: 'FOXI1', id: 'FOXI1'},
{value: 'FOXI2', id: 'FOXI2'},
{value: 'FOXI3', id: 'FOXI3'},
{value: 'FOXJ1', id: 'FOXJ1'},
{value: 'FOXJ2', id: 'FOXJ2'},
{value: 'FOXJ3', id: 'FOXJ3'},
{value: 'FOXK1', id: 'FOXK1'},
{value: 'FOXK2', id: 'FOXK2'},
{value: 'FOXL1', id: 'FOXL1'},
{value: 'FOXL2', id: 'FOXL2'},
{value: 'FOXL2NB', id: 'FOXL2NB'},
{value: 'FOXL3', id: 'FOXL3'},
{value: 'FOXM1', id: 'FOXM1'},
{value: 'FOXN2', id: 'FOXN2'},
{value: 'FOXN3', id: 'FOXN3'},
{value: 'FOXN3-AS1', id: 'FOXN3-AS1'},
{value: 'FOXN3-AS2', id: 'FOXN3-AS2'},
{value: 'FOXN4', id: 'FOXN4'},
{value: 'FOXO1', id: 'FOXO1'},
{value: 'FOXO1B', id: 'FOXO1B'},
{value: 'FOXO3', id: 'FOXO3'},
{value: 'FOXO3B', id: 'FOXO3B'},
{value: 'FOXO4', id: 'FOXO4'},
{value: 'FOXO6', id: 'FOXO6'},
{value: 'FOXO6-AS1', id: 'FOXO6-AS1'},
{value: 'FOXP1', id: 'FOXP1'},
{value: 'FOXP1-IT1', id: 'FOXP1-IT1'},
{value: 'FOXP2', id: 'FOXP2'},
{value: 'FOXP3', id: 'FOXP3'},
{value: 'FOXP4', id: 'FOXP4'},
{value: 'FOXP4-AS1', id: 'FOXP4-AS1'},
{value: 'FOXQ1', id: 'FOXQ1'},
{value: 'FOXR1', id: 'FOXR1'},
{value: 'FOXRED1', id: 'FOXRED1'},
{value: 'FOXRED2', id: 'FOXRED2'},
{value: 'FOXS1', id: 'FOXS1'},
{value: 'FPGS', id: 'FPGS'},
{value: 'FPGT', id: 'FPGT'},
{value: 'FPGT-TNNI3K', id: 'FPGT-TNNI3K'},
{value: 'FPR1', id: 'FPR1'},
{value: 'FPR2', id: 'FPR2'},
{value: 'FPR3', id: 'FPR3'},
{value: 'FRA10AC1', id: 'FRA10AC1'},
{value: 'FRAS1', id: 'FRAS1'},
{value: 'FRAT1', id: 'FRAT1'},
{value: 'FRAT2', id: 'FRAT2'},
{value: 'FREM1', id: 'FREM1'},
{value: 'FREM2', id: 'FREM2'},
{value: 'FREM3', id: 'FREM3'},
{value: 'FRG1', id: 'FRG1'},
{value: 'FRG1-DT', id: 'FRG1-DT'},
{value: 'FRG1BP', id: 'FRG1BP'},
{value: 'FRG1CP', id: 'FRG1CP'},
{value: 'FRG1DP', id: 'FRG1DP'},
{value: 'FRG1EP', id: 'FRG1EP'},
{value: 'FRG1FP', id: 'FRG1FP'},
{value: 'FRG1GP', id: 'FRG1GP'},
{value: 'FRG1HP', id: 'FRG1HP'},
{value: 'FRG1JP', id: 'FRG1JP'},
{value: 'FRG2', id: 'FRG2'},
{value: 'FRG2B', id: 'FRG2B'},
{value: 'FRG2DP', id: 'FRG2DP'},
{value: 'FRG2EP', id: 'FRG2EP'},
{value: 'FRG2FP', id: 'FRG2FP'},
{value: 'FRG2GP', id: 'FRG2GP'},
{value: 'FRG2HP', id: 'FRG2HP'},
{value: 'FRG2IP', id: 'FRG2IP'},
{value: 'FRG2KP', id: 'FRG2KP'},
{value: 'FRG2LP', id: 'FRG2LP'},
{value: 'FRG2MP', id: 'FRG2MP'},
{value: 'FRGCA', id: 'FRGCA'},
{value: 'FRK', id: 'FRK'},
{value: 'FRMD1', id: 'FRMD1'},
{value: 'FRMD3', id: 'FRMD3'},
{value: 'FRMD4A', id: 'FRMD4A'},
{value: 'FRMD4B', id: 'FRMD4B'},
{value: 'FRMD5', id: 'FRMD5'},
{value: 'FRMD6', id: 'FRMD6'},
{value: 'FRMD6-AS1', id: 'FRMD6-AS1'},
{value: 'FRMD6-AS2', id: 'FRMD6-AS2'},
{value: 'FRMD8', id: 'FRMD8'},
{value: 'FRMPD1', id: 'FRMPD1'},
{value: 'FRMPD2', id: 'FRMPD2'},
{value: 'FRMPD2B', id: 'FRMPD2B'},
{value: 'FRMPD3', id: 'FRMPD3'},
{value: 'FRMPD4', id: 'FRMPD4'},
{value: 'FRRS1', id: 'FRRS1'},
{value: 'FRRS1L', id: 'FRRS1L'},
{value: 'FRS2', id: 'FRS2'},
{value: 'FRS3', id: 'FRS3'},
{value: 'FRY', id: 'FRY'},
{value: 'FRY-AS1', id: 'FRY-AS1'},
{value: 'FRYL', id: 'FRYL'},
{value: 'FRZB', id: 'FRZB'},
{value: 'FSBP', id: 'FSBP'},
{value: 'FSCB', id: 'FSCB'},
{value: 'FSCN1', id: 'FSCN1'},
{value: 'FSCN1P1', id: 'FSCN1P1'},
{value: 'FSCN2', id: 'FSCN2'},
{value: 'FSCN3', id: 'FSCN3'},
{value: 'FSD1', id: 'FSD1'},
{value: 'FSD1L', id: 'FSD1L'},
{value: 'FSD2', id: 'FSD2'},
{value: 'FSHB', id: 'FSHB'},
{value: 'FSHR', id: 'FSHR'},
{value: 'FSIP1', id: 'FSIP1'},
{value: 'FSIP2', id: 'FSIP2'},
{value: 'FSIP2-AS1', id: 'FSIP2-AS1'},
{value: 'FST', id: 'FST'},
{value: 'FSTL1', id: 'FSTL1'},
{value: 'FSTL3', id: 'FSTL3'},
{value: 'FSTL4', id: 'FSTL4'},
{value: 'FSTL5', id: 'FSTL5'},
{value: 'FTCD', id: 'FTCD'},
{value: 'FTCD-AS1', id: 'FTCD-AS1'},
{value: 'FTCDNL1', id: 'FTCDNL1'},
{value: 'FTH1', id: 'FTH1'},
{value: 'FTH1P1', id: 'FTH1P1'},
{value: 'FTH1P10', id: 'FTH1P10'},
{value: 'FTH1P11', id: 'FTH1P11'},
{value: 'FTH1P12', id: 'FTH1P12'},
{value: 'FTH1P13', id: 'FTH1P13'},
{value: 'FTH1P15', id: 'FTH1P15'},
{value: 'FTH1P16', id: 'FTH1P16'},
{value: 'FTH1P19', id: 'FTH1P19'},
{value: 'FTH1P2', id: 'FTH1P2'},
{value: 'FTH1P20', id: 'FTH1P20'},
{value: 'FTH1P21', id: 'FTH1P21'},
{value: 'FTH1P22', id: 'FTH1P22'},
{value: 'FTH1P23', id: 'FTH1P23'},
{value: 'FTH1P25', id: 'FTH1P25'},
{value: 'FTH1P3', id: 'FTH1P3'},
{value: 'FTH1P4', id: 'FTH1P4'},
{value: 'FTH1P5', id: 'FTH1P5'},
{value: 'FTH1P6', id: 'FTH1P6'},
{value: 'FTH1P7', id: 'FTH1P7'},
{value: 'FTH1P8', id: 'FTH1P8'},
{value: 'FTH1P9', id: 'FTH1P9'},
{value: 'FTL', id: 'FTL'},
{value: 'FTLP1', id: 'FTLP1'},
{value: 'FTLP10', id: 'FTLP10'},
{value: 'FTLP12', id: 'FTLP12'},
{value: 'FTLP13', id: 'FTLP13'},
{value: 'FTLP14', id: 'FTLP14'},
{value: 'FTLP17', id: 'FTLP17'},
{value: 'FTLP18', id: 'FTLP18'},
{value: 'FTLP19', id: 'FTLP19'},
{value: 'FTLP3', id: 'FTLP3'},
{value: 'FTLP4', id: 'FTLP4'},
{value: 'FTLP5', id: 'FTLP5'},
{value: 'FTLP6', id: 'FTLP6'},
{value: 'FTLP8', id: 'FTLP8'},
{value: 'FTMT', id: 'FTMT'},
{value: 'FTO', id: 'FTO'},
{value: 'FTOP1', id: 'FTOP1'},
{value: 'FTSJ1', id: 'FTSJ1'},
{value: 'FTSJ3', id: 'FTSJ3'},
{value: 'FTX', id: 'FTX'},
{value: 'FUBP1', id: 'FUBP1'},
{value: 'FUBP3', id: 'FUBP3'},
{value: 'FUCA1', id: 'FUCA1'},
{value: 'FUCA2', id: 'FUCA2'},
{value: 'FUNDC1', id: 'FUNDC1'},
{value: 'FUNDC2', id: 'FUNDC2'},
{value: 'FUNDC2P1', id: 'FUNDC2P1'},
{value: 'FUNDC2P2', id: 'FUNDC2P2'},
{value: 'FUNDC2P4', id: 'FUNDC2P4'},
{value: 'FUOM', id: 'FUOM'},
{value: 'FURIN', id: 'FURIN'},
{value: 'FUS', id: 'FUS'},
{value: 'FUT1', id: 'FUT1'},
{value: 'FUT10', id: 'FUT10'},
{value: 'FUT11', id: 'FUT11'},
{value: 'FUT2', id: 'FUT2'},
{value: 'FUT3', id: 'FUT3'},
{value: 'FUT4', id: 'FUT4'},
{value: 'FUT5', id: 'FUT5'},
{value: 'FUT6', id: 'FUT6'},
{value: 'FUT7', id: 'FUT7'},
{value: 'FUT8', id: 'FUT8'},
{value: 'FUT8-AS1', id: 'FUT8-AS1'},
{value: 'FUT9', id: 'FUT9'},
{value: 'FUZ', id: 'FUZ'},
{value: 'FXN', id: 'FXN'},
{value: 'FXNP1', id: 'FXNP1'},
{value: 'FXNP2', id: 'FXNP2'},
{value: 'FXR1', id: 'FXR1'},
{value: 'FXR2', id: 'FXR2'},
{value: 'FXYD1', id: 'FXYD1'},
{value: 'FXYD2', id: 'FXYD2'},
{value: 'FXYD3', id: 'FXYD3'},
{value: 'FXYD4', id: 'FXYD4'},
{value: 'FXYD5', id: 'FXYD5'},
{value: 'FXYD6', id: 'FXYD6'},
{value: 'FXYD6-FXYD2', id: 'FXYD6-FXYD2'},
{value: 'FXYD6P1', id: 'FXYD6P1'},
{value: 'FXYD6P2', id: 'FXYD6P2'},
{value: 'FXYD7', id: 'FXYD7'},
{value: 'FYB1', id: 'FYB1'},
{value: 'FYB2', id: 'FYB2'},
{value: 'FYCO1', id: 'FYCO1'},
{value: 'FYN', id: 'FYN'},
{value: 'FYTTD1', id: 'FYTTD1'},
{value: 'FZD1', id: 'FZD1'},
{value: 'FZD10', id: 'FZD10'},
{value: 'FZD10-AS1', id: 'FZD10-AS1'},
{value: 'FZD2', id: 'FZD2'},
{value: 'FZD3', id: 'FZD3'},
{value: 'FZD4', id: 'FZD4'},
{value: 'FZD4-DT', id: 'FZD4-DT'},
{value: 'FZD5', id: 'FZD5'},
{value: 'FZD6', id: 'FZD6'},
{value: 'FZD7', id: 'FZD7'},
{value: 'FZD8', id: 'FZD8'},
{value: 'FZD9', id: 'FZD9'},
{value: 'FZR1', id: 'FZR1'},
{value: 'G0S2', id: 'G0S2'},
{value: 'G2E3', id: 'G2E3'},
{value: 'G2E3-AS1', id: 'G2E3-AS1'},
{value: 'G3BP1', id: 'G3BP1'},
{value: 'G3BP2', id: 'G3BP2'},
{value: 'G6PC1', id: 'G6PC1'},
{value: 'G6PC3', id: 'G6PC3'},
{value: 'G6PD', id: 'G6PD'},
{value: 'GAA', id: 'GAA'},
{value: 'GAB1', id: 'GAB1'},
{value: 'GAB2', id: 'GAB2'},
{value: 'GAB3', id: 'GAB3'},
{value: 'GAB4', id: 'GAB4'},
{value: 'GABARAP', id: 'GABARAP'},
{value: 'GABARAPL1', id: 'GABARAPL1'},
{value: 'GABARAPL2', id: 'GABARAPL2'},
{value: 'GABARAPL3', id: 'GABARAPL3'},
{value: 'GABBR1', id: 'GABBR1'},
{value: 'GABBR2', id: 'GABBR2'},
{value: 'GABPA', id: 'GABPA'},
{value: 'GABPAP', id: 'GABPAP'},
{value: 'GABPB1', id: 'GABPB1'},
{value: 'GABPB1-AS1', id: 'GABPB1-AS1'},
{value: 'GABPB1-IT1', id: 'GABPB1-IT1'},
{value: 'GABPB2', id: 'GABPB2'},
{value: 'GABRA1', id: 'GABRA1'},
{value: 'GABRA2', id: 'GABRA2'},
{value: 'GABRA4', id: 'GABRA4'},
{value: 'GABRA5', id: 'GABRA5'},
{value: 'GABRA6', id: 'GABRA6'},
{value: 'GABRB1', id: 'GABRB1'},
{value: 'GABRB2', id: 'GABRB2'},
{value: 'GABRB3', id: 'GABRB3'},
{value: 'GABRD', id: 'GABRD'},
{value: 'GABRE', id: 'GABRE'},
{value: 'GABRG1', id: 'GABRG1'},
{value: 'GABRG2', id: 'GABRG2'},
{value: 'GABRG3', id: 'GABRG3'},
{value: 'GABRP', id: 'GABRP'},
{value: 'GABRR1', id: 'GABRR1'},
{value: 'GABRR2', id: 'GABRR2'},
{value: 'GABRR3', id: 'GABRR3'},
{value: 'GACAT1', id: 'GACAT1'},
{value: 'GACAT2', id: 'GACAT2'},
{value: 'GACAT3', id: 'GACAT3'},
{value: 'GAD1', id: 'GAD1'},
{value: 'GAD2', id: 'GAD2'},
{value: 'GADD45A', id: 'GADD45A'},
{value: 'GADD45B', id: 'GADD45B'},
{value: 'GADD45G', id: 'GADD45G'},
{value: 'GADD45GIP1', id: 'GADD45GIP1'},
{value: 'GADL1', id: 'GADL1'},
{value: 'GAK', id: 'GAK'},
{value: 'GAL', id: 'GAL'},
{value: 'GAL3ST1', id: 'GAL3ST1'},
{value: 'GAL3ST2', id: 'GAL3ST2'},
{value: 'GAL3ST3', id: 'GAL3ST3'},
{value: 'GAL3ST4', id: 'GAL3ST4'},
{value: 'GALC', id: 'GALC'},
{value: 'GALE', id: 'GALE'},
{value: 'GALK1', id: 'GALK1'},
{value: 'GALK2', id: 'GALK2'},
{value: 'GALM', id: 'GALM'},
{value: 'GALNS', id: 'GALNS'},
{value: 'GALNT1', id: 'GALNT1'},
{value: 'GALNT10', id: 'GALNT10'},
{value: 'GALNT11', id: 'GALNT11'},
{value: 'GALNT12', id: 'GALNT12'},
{value: 'GALNT13', id: 'GALNT13'},
{value: 'GALNT14', id: 'GALNT14'},
{value: 'GALNT15', id: 'GALNT15'},
{value: 'GALNT16', id: 'GALNT16'},
{value: 'GALNT16-AS1', id: 'GALNT16-AS1'},
{value: 'GALNT17', id: 'GALNT17'},
{value: 'GALNT18', id: 'GALNT18'},
{value: 'GALNT2', id: 'GALNT2'},
{value: 'GALNT3', id: 'GALNT3'},
{value: 'GALNT4', id: 'GALNT4'},
{value: 'GALNT5', id: 'GALNT5'},
{value: 'GALNT6', id: 'GALNT6'},
{value: 'GALNT7', id: 'GALNT7'},
{value: 'GALNT8', id: 'GALNT8'},
{value: 'GALNT9', id: 'GALNT9'},
{value: 'GALNTL5', id: 'GALNTL5'},
{value: 'GALNTL6', id: 'GALNTL6'},
{value: 'GALP', id: 'GALP'},
{value: 'GALR1', id: 'GALR1'},
{value: 'GALR3', id: 'GALR3'},
{value: 'GALT', id: 'GALT'},
{value: 'GAMT', id: 'GAMT'},
{value: 'GAMTP2', id: 'GAMTP2'},
{value: 'GAN', id: 'GAN'},
{value: 'GANAB', id: 'GANAB'},
{value: 'GANC', id: 'GANC'},
{value: 'GAP43', id: 'GAP43'},
{value: 'GAPDH', id: 'GAPDH'},
{value: 'GAPDH-DT', id: 'GAPDH-DT'},
{value: 'GAPDHP1', id: 'GAPDHP1'},
{value: 'GAPDHP14', id: 'GAPDHP14'},
{value: 'GAPDHP16', id: 'GAPDHP16'},
{value: 'GAPDHP2', id: 'GAPDHP2'},
{value: 'GAPDHP20', id: 'GAPDHP20'},
{value: 'GAPDHP21', id: 'GAPDHP21'},
{value: 'GAPDHP23', id: 'GAPDHP23'},
{value: 'GAPDHP24', id: 'GAPDHP24'},
{value: 'GAPDHP25', id: 'GAPDHP25'},
{value: 'GAPDHP26', id: 'GAPDHP26'},
{value: 'GAPDHP27', id: 'GAPDHP27'},
{value: 'GAPDHP28', id: 'GAPDHP28'},
{value: 'GAPDHP29', id: 'GAPDHP29'},
{value: 'GAPDHP30', id: 'GAPDHP30'},
{value: 'GAPDHP31', id: 'GAPDHP31'},
{value: 'GAPDHP32', id: 'GAPDHP32'},
{value: 'GAPDHP33', id: 'GAPDHP33'},
{value: 'GAPDHP34', id: 'GAPDHP34'},
{value: 'GAPDHP35', id: 'GAPDHP35'},
{value: 'GAPDHP36', id: 'GAPDHP36'},
{value: 'GAPDHP37', id: 'GAPDHP37'},
{value: 'GAPDHP39', id: 'GAPDHP39'},
{value: 'GAPDHP40', id: 'GAPDHP40'},
{value: 'GAPDHP41', id: 'GAPDHP41'},
{value: 'GAPDHP42', id: 'GAPDHP42'},
{value: 'GAPDHP43', id: 'GAPDHP43'},
{value: 'GAPDHP45', id: 'GAPDHP45'},
{value: 'GAPDHP46', id: 'GAPDHP46'},
{value: 'GAPDHP48', id: 'GAPDHP48'},
{value: 'GAPDHP49', id: 'GAPDHP49'},
{value: 'GAPDHP50', id: 'GAPDHP50'},
{value: 'GAPDHP51', id: 'GAPDHP51'},
{value: 'GAPDHP52', id: 'GAPDHP52'},
{value: 'GAPDHP53', id: 'GAPDHP53'},
{value: 'GAPDHP54', id: 'GAPDHP54'},
{value: 'GAPDHP56', id: 'GAPDHP56'},
{value: 'GAPDHP57', id: 'GAPDHP57'},
{value: 'GAPDHP58', id: 'GAPDHP58'},
{value: 'GAPDHP60', id: 'GAPDHP60'},
{value: 'GAPDHP61', id: 'GAPDHP61'},
{value: 'GAPDHP62', id: 'GAPDHP62'},
{value: 'GAPDHP63', id: 'GAPDHP63'},
{value: 'GAPDHP64', id: 'GAPDHP64'},
{value: 'GAPDHP65', id: 'GAPDHP65'},
{value: 'GAPDHP68', id: 'GAPDHP68'},
{value: 'GAPDHP70', id: 'GAPDHP70'},
{value: 'GAPDHP71', id: 'GAPDHP71'},
{value: 'GAPDHP72', id: 'GAPDHP72'},
{value: 'GAPDHP74', id: 'GAPDHP74'},
{value: 'GAPDHP75', id: 'GAPDHP75'},
{value: 'GAPDHS', id: 'GAPDHS'},
{value: 'GAPLINC', id: 'GAPLINC'},
{value: 'GAPT', id: 'GAPT'},
{value: 'GAPVD1', id: 'GAPVD1'},
{value: 'GAR1', id: 'GAR1'},
{value: 'GAR1-DT', id: 'GAR1-DT'},
{value: 'GAREM1', id: 'GAREM1'},
{value: 'GAREM2', id: 'GAREM2'},
{value: 'GARNL3', id: 'GARNL3'},
{value: 'GARRE1', id: 'GARRE1'},
{value: 'GARS1', id: 'GARS1'},
{value: 'GARS1-DT', id: 'GARS1-DT'},
{value: 'GART', id: 'GART'},
{value: 'GAS1', id: 'GAS1'},
{value: 'GAS1RR', id: 'GAS1RR'},
{value: 'GAS2', id: 'GAS2'},
{value: 'GAS2L1', id: 'GAS2L1'},
{value: 'GAS2L1P1', id: 'GAS2L1P1'},
{value: 'GAS2L2', id: 'GAS2L2'},
{value: 'GAS2L3', id: 'GAS2L3'},
{value: 'GAS5', id: 'GAS5'},
{value: 'GAS5-AS1', id: 'GAS5-AS1'},
{value: 'GAS6', id: 'GAS6'},
{value: 'GAS6-AS1', id: 'GAS6-AS1'},
{value: 'GAS6-DT', id: 'GAS6-DT'},
{value: 'GAS7', id: 'GAS7'},
{value: 'GAS8', id: 'GAS8'},
{value: 'GASAL1', id: 'GASAL1'},
{value: 'GASK1A', id: 'GASK1A'},
{value: 'GASK1B', id: 'GASK1B'},
{value: 'GASK1B-AS1', id: 'GASK1B-AS1'},
{value: 'GAST', id: 'GAST'},
{value: 'GATA1', id: 'GATA1'},
{value: 'GATA2', id: 'GATA2'},
{value: 'GATA2-AS1', id: 'GATA2-AS1'},
{value: 'GATA3', id: 'GATA3'},
{value: 'GATA3-AS1', id: 'GATA3-AS1'},
{value: 'GATA4', id: 'GATA4'},
{value: 'GATA5', id: 'GATA5'},
{value: 'GATA6', id: 'GATA6'},
{value: 'GATA6-AS1', id: 'GATA6-AS1'},
{value: 'GATAD1', id: 'GATAD1'},
{value: 'GATAD2A', id: 'GATAD2A'},
{value: 'GATAD2B', id: 'GATAD2B'},
{value: 'GATB', id: 'GATB'},
{value: 'GATC', id: 'GATC'},
{value: 'GATD1', id: 'GATD1'},
{value: 'GATD3A', id: 'GATD3A'},
{value: 'GATD3B', id: 'GATD3B'},
{value: 'GATM', id: 'GATM'},
{value: 'GBA', id: 'GBA'},
{value: 'GBA2', id: 'GBA2'},
{value: 'GBA3', id: 'GBA3'},
{value: 'GBAP1', id: 'GBAP1'},
{value: 'GBE1', id: 'GBE1'},
{value: 'GBF1', id: 'GBF1'},
{value: 'GBGT1', id: 'GBGT1'},
{value: 'GBP1', id: 'GBP1'},
{value: 'GBP1P1', id: 'GBP1P1'},
{value: 'GBP2', id: 'GBP2'},
{value: 'GBP3', id: 'GBP3'},
{value: 'GBP4', id: 'GBP4'},
{value: 'GBP5', id: 'GBP5'},
{value: 'GBP6', id: 'GBP6'},
{value: 'GBP7', id: 'GBP7'},
{value: 'GBX1', id: 'GBX1'},
{value: 'GBX2', id: 'GBX2'},
{value: 'GC', id: 'GC'},
{value: 'GCA', id: 'GCA'},
{value: 'GCAT', id: 'GCAT'},
{value: 'GCATP1', id: 'GCATP1'},
{value: 'GCAWKR', id: 'GCAWKR'},
{value: 'GCC1', id: 'GCC1'},
{value: 'GCC2', id: 'GCC2'},
{value: 'GCC2-AS1', id: 'GCC2-AS1'},
{value: 'GCDH', id: 'GCDH'},
{value: 'GCFC2', id: 'GCFC2'},
{value: 'GCG', id: 'GCG'},
{value: 'GCGR', id: 'GCGR'},
{value: 'GCH1', id: 'GCH1'},
{value: 'GCHFR', id: 'GCHFR'},
{value: 'GCK', id: 'GCK'},
{value: 'GCKR', id: 'GCKR'},
{value: 'GCLC', id: 'GCLC'},
{value: 'GCLM', id: 'GCLM'},
{value: 'GCM1', id: 'GCM1'},
{value: 'GCM2', id: 'GCM2'},
{value: 'GCN1', id: 'GCN1'},
{value: 'GCNA', id: 'GCNA'},
{value: 'GCNAP1', id: 'GCNAP1'},
{value: 'GCNT1', id: 'GCNT1'},
{value: 'GCNT1P4', id: 'GCNT1P4'},
{value: 'GCNT1P5', id: 'GCNT1P5'},
{value: 'GCNT2', id: 'GCNT2'},
{value: 'GCNT2P1', id: 'GCNT2P1'},
{value: 'GCNT3', id: 'GCNT3'},
{value: 'GCNT4', id: 'GCNT4'},
{value: 'GCNT7', id: 'GCNT7'},
{value: 'GCOM1', id: 'GCOM1'},
{value: 'GCSAM', id: 'GCSAM'},
{value: 'GCSAML', id: 'GCSAML'},
{value: 'GCSH', id: 'GCSH'},
{value: 'GCSHP1', id: 'GCSHP1'},
{value: 'GCSHP2', id: 'GCSHP2'},
{value: 'GCSHP3', id: 'GCSHP3'},
{value: 'GCSHP5', id: 'GCSHP5'},
{value: 'GCSIR', id: 'GCSIR'},
{value: 'GDA', id: 'GDA'},
{value: 'GDAP1', id: 'GDAP1'},
{value: 'GDAP1L1', id: 'GDAP1L1'},
{value: 'GDAP2', id: 'GDAP2'},
{value: 'GDE1', id: 'GDE1'},
{value: 'GDF1', id: 'GDF1'},
{value: 'GDF10', id: 'GDF10'},
{value: 'GDF11', id: 'GDF11'},
{value: 'GDF15', id: 'GDF15'},
{value: 'GDF2', id: 'GDF2'},
{value: 'GDF3', id: 'GDF3'},
{value: 'GDF5', id: 'GDF5'},
{value: 'GDF5-AS1', id: 'GDF5-AS1'},
{value: 'GDF6', id: 'GDF6'},
{value: 'GDF7', id: 'GDF7'},
{value: 'GDF9', id: 'GDF9'},
{value: 'GDI1', id: 'GDI1'},
{value: 'GDI2', id: 'GDI2'},
{value: 'GDI2P1', id: 'GDI2P1'},
{value: 'GDNF', id: 'GDNF'},
{value: 'GDNF-AS1', id: 'GDNF-AS1'},
{value: 'GDPD1', id: 'GDPD1'},
{value: 'GDPD3', id: 'GDPD3'},
{value: 'GDPD4', id: 'GDPD4'},
{value: 'GDPD5', id: 'GDPD5'},
{value: 'GDPGP1', id: 'GDPGP1'},
{value: 'GEM', id: 'GEM'},
{value: 'GEMIN2', id: 'GEMIN2'},
{value: 'GEMIN2P1', id: 'GEMIN2P1'},
{value: 'GEMIN2P2', id: 'GEMIN2P2'},
{value: 'GEMIN4', id: 'GEMIN4'},
{value: 'GEMIN5', id: 'GEMIN5'},
{value: 'GEMIN6', id: 'GEMIN6'},
{value: 'GEMIN7', id: 'GEMIN7'},
{value: 'GEMIN7-AS1', id: 'GEMIN7-AS1'},
{value: 'GEMIN8', id: 'GEMIN8'},
{value: 'GEMIN8P4', id: 'GEMIN8P4'},
{value: 'GEN1', id: 'GEN1'},
{value: 'GET1', id: 'GET1'},
{value: 'GET1-SH3BGR', id: 'GET1-SH3BGR'},
{value: 'GET1P1', id: 'GET1P1'},
{value: 'GET3', id: 'GET3'},
{value: 'GET4', id: 'GET4'},
{value: 'GFAP', id: 'GFAP'},
{value: 'GFER', id: 'GFER'},
{value: 'GFI1', id: 'GFI1'},
{value: 'GFI1B', id: 'GFI1B'},
{value: 'GFM1', id: 'GFM1'},
{value: 'GFM2', id: 'GFM2'},
{value: 'GFOD1', id: 'GFOD1'},
{value: 'GFOD1-AS1', id: 'GFOD1-AS1'},
{value: 'GFOD2', id: 'GFOD2'},
{value: 'GFPT1', id: 'GFPT1'},
{value: 'GFPT2', id: 'GFPT2'},
{value: 'GFRA1', id: 'GFRA1'},
{value: 'GFRA2', id: 'GFRA2'},
{value: 'GFRA3', id: 'GFRA3'},
{value: 'GFRA4', id: 'GFRA4'},
{value: 'GFRAL', id: 'GFRAL'},
{value: 'GFUS', id: 'GFUS'},
{value: 'GFY', id: 'GFY'},
{value: 'GGA1', id: 'GGA1'},
{value: 'GGA2', id: 'GGA2'},
{value: 'GGA3', id: 'GGA3'},
{value: 'GGACT', id: 'GGACT'},
{value: 'GGCT', id: 'GGCT'},
{value: 'GGCTP1', id: 'GGCTP1'},
{value: 'GGCTP2', id: 'GGCTP2'},
{value: 'GGCTP3', id: 'GGCTP3'},
{value: 'GGCX', id: 'GGCX'},
{value: 'GGH', id: 'GGH'},
{value: 'GGN', id: 'GGN'},
{value: 'GGNBP1', id: 'GGNBP1'},
{value: 'GGNBP2', id: 'GGNBP2'},
{value: 'GGPS1', id: 'GGPS1'},
{value: 'GGT1', id: 'GGT1'},
{value: 'GGT4P', id: 'GGT4P'},
{value: 'GGT5', id: 'GGT5'},
{value: 'GGT6', id: 'GGT6'},
{value: 'GGT7', id: 'GGT7'},
{value: 'GGT8P', id: 'GGT8P'},
{value: 'GGTA1', id: 'GGTA1'},
{value: 'GGTA2P', id: 'GGTA2P'},
{value: 'GGTLC1', id: 'GGTLC1'},
{value: 'GGTLC2', id: 'GGTLC2'},
{value: 'GGTLC3', id: 'GGTLC3'},
{value: 'GGTLC4P', id: 'GGTLC4P'},
{value: 'GH1', id: 'GH1'},
{value: 'GH2', id: 'GH2'},
{value: 'GHDC', id: 'GHDC'},
{value: 'GHET1', id: 'GHET1'},
{value: 'GHITM', id: 'GHITM'},
{value: 'GHR', id: 'GHR'},
{value: 'GHRH', id: 'GHRH'},
{value: 'GHRHR', id: 'GHRHR'},
{value: 'GHRL', id: 'GHRL'},
{value: 'GHRLOS', id: 'GHRLOS'},
{value: 'GHSR', id: 'GHSR'},
{value: 'GID4', id: 'GID4'},
{value: 'GID8', id: 'GID8'},
{value: 'GIGYF1', id: 'GIGYF1'},
{value: 'GIGYF2', id: 'GIGYF2'},
{value: 'GIHCG', id: 'GIHCG'},
{value: 'GIMAP1', id: 'GIMAP1'},
{value: 'GIMAP1-GIMAP5', id: 'GIMAP1-GIMAP5'},
{value: 'GIMAP2', id: 'GIMAP2'},
{value: 'GIMAP3P', id: 'GIMAP3P'},
{value: 'GIMAP4', id: 'GIMAP4'},
{value: 'GIMAP5', id: 'GIMAP5'},
{value: 'GIMAP6', id: 'GIMAP6'},
{value: 'GIMAP7', id: 'GIMAP7'},
{value: 'GIMAP8', id: 'GIMAP8'},
{value: 'GIMD1', id: 'GIMD1'},
{value: 'GIN1', id: 'GIN1'},
{value: 'GINM1', id: 'GINM1'},
{value: 'GINS1', id: 'GINS1'},
{value: 'GINS2', id: 'GINS2'},
{value: 'GINS3', id: 'GINS3'},
{value: 'GINS4', id: 'GINS4'},
{value: 'GIP', id: 'GIP'},
{value: 'GIPC1', id: 'GIPC1'},
{value: 'GIPC2', id: 'GIPC2'},
{value: 'GIPC3', id: 'GIPC3'},
{value: 'GIPR', id: 'GIPR'},
{value: 'GIT1', id: 'GIT1'},
{value: 'GIT2', id: 'GIT2'},
{value: 'GJA1', id: 'GJA1'},
{value: 'GJA10', id: 'GJA10'},
{value: 'GJA3', id: 'GJA3'},
{value: 'GJA4', id: 'GJA4'},
{value: 'GJA5', id: 'GJA5'},
{value: 'GJA8', id: 'GJA8'},
{value: 'GJB2', id: 'GJB2'},
{value: 'GJB3', id: 'GJB3'},
{value: 'GJB5', id: 'GJB5'},
{value: 'GJB6', id: 'GJB6'},
{value: 'GJB7', id: 'GJB7'},
{value: 'GJC1', id: 'GJC1'},
{value: 'GJC2', id: 'GJC2'},
{value: 'GJC3', id: 'GJC3'},
{value: 'GJD2', id: 'GJD2'},
{value: 'GJD2-DT', id: 'GJD2-DT'},
{value: 'GJD3', id: 'GJD3'},
{value: 'GJD4', id: 'GJD4'},
{value: 'GK', id: 'GK'},
{value: 'GK-AS1', id: 'GK-AS1'},
{value: 'GK-IT1', id: 'GK-IT1'},
{value: 'GK2', id: 'GK2'},
{value: 'GK3P', id: 'GK3P'},
{value: 'GK4P', id: 'GK4P'},
{value: 'GK5', id: 'GK5'},
{value: 'GKAP1', id: 'GKAP1'},
{value: 'GKN1', id: 'GKN1'},
{value: 'GKN2', id: 'GKN2'},
{value: 'GKN3P', id: 'GKN3P'},
{value: 'GLA', id: 'GLA'},
{value: 'GLB1', id: 'GLB1'},
{value: 'GLB1L', id: 'GLB1L'},
{value: 'GLB1L2', id: 'GLB1L2'},
{value: 'GLB1L3', id: 'GLB1L3'},
{value: 'GLCCI1', id: 'GLCCI1'},
{value: 'GLCCI1-DT', id: 'GLCCI1-DT'},
{value: 'GLCE', id: 'GLCE'},
{value: 'GLDC', id: 'GLDC'},
{value: 'GLDCP1', id: 'GLDCP1'},
{value: 'GLDN', id: 'GLDN'},
{value: 'GLE1', id: 'GLE1'},
{value: 'GLG1', id: 'GLG1'},
{value: 'GLI1', id: 'GLI1'},
{value: 'GLI2', id: 'GLI2'},
{value: 'GLI3', id: 'GLI3'},
{value: 'GLI4', id: 'GLI4'},
{value: 'GLIDR', id: 'GLIDR'},
{value: 'GLIPR1', id: 'GLIPR1'},
{value: 'GLIPR1L2', id: 'GLIPR1L2'},
{value: 'GLIPR2', id: 'GLIPR2'},
{value: 'GLIS1', id: 'GLIS1'},
{value: 'GLIS2', id: 'GLIS2'},
{value: 'GLIS2-AS1', id: 'GLIS2-AS1'},
{value: 'GLIS3', id: 'GLIS3'},
{value: 'GLMN', id: 'GLMN'},
{value: 'GLMP', id: 'GLMP'},
{value: 'GLO1', id: 'GLO1'},
{value: 'GLOD4', id: 'GLOD4'},
{value: 'GLP1R', id: 'GLP1R'},
{value: 'GLP2R', id: 'GLP2R'},
{value: 'GLRA1', id: 'GLRA1'},
{value: 'GLRA3', id: 'GLRA3'},
{value: 'GLRB', id: 'GLRB'},
{value: 'GLRX', id: 'GLRX'},
{value: 'GLRX2', id: 'GLRX2'},
{value: 'GLRX3', id: 'GLRX3'},
{value: 'GLRX3P2', id: 'GLRX3P2'},
{value: 'GLRX5', id: 'GLRX5'},
{value: 'GLRX5P3', id: 'GLRX5P3'},
{value: 'GLRXP1', id: 'GLRXP1'},
{value: 'GLRXP3', id: 'GLRXP3'},
{value: 'GLS', id: 'GLS'},
{value: 'GLS2', id: 'GLS2'},
{value: 'GLT1D1', id: 'GLT1D1'},
{value: 'GLT6D1', id: 'GLT6D1'},
{value: 'GLT8D1', id: 'GLT8D1'},
{value: 'GLT8D2', id: 'GLT8D2'},
{value: 'GLTP', id: 'GLTP'},
{value: 'GLTPD2', id: 'GLTPD2'},
{value: 'GLTPP1', id: 'GLTPP1'},
{value: 'GLUD1', id: 'GLUD1'},
{value: 'GLUD1P2', id: 'GLUD1P2'},
{value: 'GLUD1P3', id: 'GLUD1P3'},
{value: 'GLUD1P4', id: 'GLUD1P4'},
{value: 'GLUD2', id: 'GLUD2'},
{value: 'GLUL', id: 'GLUL'},
{value: 'GLULP3', id: 'GLULP3'},
{value: 'GLULP4', id: 'GLULP4'},
{value: 'GLULP5', id: 'GLULP5'},
{value: 'GLULP6', id: 'GLULP6'},
{value: 'GLYAT', id: 'GLYAT'},
{value: 'GLYATL1', id: 'GLYATL1'},
{value: 'GLYATL1B', id: 'GLYATL1B'},
{value: 'GLYATL1P2', id: 'GLYATL1P2'},
{value: 'GLYATL2', id: 'GLYATL2'},
{value: 'GLYATL3', id: 'GLYATL3'},
{value: 'GLYCAM1', id: 'GLYCAM1'},
{value: 'GLYCTK', id: 'GLYCTK'},
{value: 'GLYCTK-AS1', id: 'GLYCTK-AS1'},
{value: 'GLYR1', id: 'GLYR1'},
{value: 'GM2A', id: 'GM2A'},
{value: 'GM2AP1', id: 'GM2AP1'},
{value: 'GM2AP2', id: 'GM2AP2'},
{value: 'GMCL1', id: 'GMCL1'},
{value: 'GMCL1P2', id: 'GMCL1P2'},
{value: 'GMCL2', id: 'GMCL2'},
{value: 'GMDS', id: 'GMDS'},
{value: 'GMDS-DT', id: 'GMDS-DT'},
{value: 'GMEB1', id: 'GMEB1'},
{value: 'GMEB2', id: 'GMEB2'},
{value: 'GMFB', id: 'GMFB'},
{value: 'GMFBP1', id: 'GMFBP1'},
{value: 'GMFG', id: 'GMFG'},
{value: 'GMIP', id: 'GMIP'},
{value: 'GML', id: 'GML'},
{value: 'GMNC', id: 'GMNC'},
{value: 'GMNN', id: 'GMNN'},
{value: 'GMPPA', id: 'GMPPA'},
{value: 'GMPPB', id: 'GMPPB'},
{value: 'GMPR', id: 'GMPR'},
{value: 'GMPR2', id: 'GMPR2'},
{value: 'GMPS', id: 'GMPS'},
{value: 'GMPSP1', id: 'GMPSP1'},
{value: 'GNA11', id: 'GNA11'},
{value: 'GNA12', id: 'GNA12'},
{value: 'GNA13', id: 'GNA13'},
{value: 'GNA13P1', id: 'GNA13P1'},
{value: 'GNA14', id: 'GNA14'},
{value: 'GNA15', id: 'GNA15'},
{value: 'GNA15-DT', id: 'GNA15-DT'},
{value: 'GNAI1', id: 'GNAI1'},
{value: 'GNAI2', id: 'GNAI2'},
{value: 'GNAI2P1', id: 'GNAI2P1'},
{value: 'GNAI2P2', id: 'GNAI2P2'},
{value: 'GNAI3', id: 'GNAI3'},
{value: 'GNAL', id: 'GNAL'},
{value: 'GNAO1', id: 'GNAO1'},
{value: 'GNAO1-AS1', id: 'GNAO1-AS1'},
{value: 'GNAO1-DT', id: 'GNAO1-DT'},
{value: 'GNAQ', id: 'GNAQ'},
{value: 'GNAQP1', id: 'GNAQP1'},
{value: 'GNAS', id: 'GNAS'},
{value: 'GNAS-AS1', id: 'GNAS-AS1'},
{value: 'GNAT1', id: 'GNAT1'},
{value: 'GNAT2', id: 'GNAT2'},
{value: 'GNAZ', id: 'GNAZ'},
{value: 'GNB1', id: 'GNB1'},
{value: 'GNB1L', id: 'GNB1L'},
{value: 'GNB2', id: 'GNB2'},
{value: 'GNB3', id: 'GNB3'},
{value: 'GNB4', id: 'GNB4'},
{value: 'GNB5', id: 'GNB5'},
{value: 'GNE', id: 'GNE'},
{value: 'GNG10', id: 'GNG10'},
{value: 'GNG10P1', id: 'GNG10P1'},
{value: 'GNG11', id: 'GNG11'},
{value: 'GNG12', id: 'GNG12'},
{value: 'GNG12-AS1', id: 'GNG12-AS1'},
{value: 'GNG13', id: 'GNG13'},
{value: 'GNG14', id: 'GNG14'},
{value: 'GNG2', id: 'GNG2'},
{value: 'GNG3', id: 'GNG3'},
{value: 'GNG4', id: 'GNG4'},
{value: 'GNG5', id: 'GNG5'},
{value: 'GNG5P5', id: 'GNG5P5'},
{value: 'GNG7', id: 'GNG7'},
{value: 'GNG8', id: 'GNG8'},
{value: 'GNGT1', id: 'GNGT1'},
{value: 'GNGT2', id: 'GNGT2'},
{value: 'GNL1', id: 'GNL1'},
{value: 'GNL2', id: 'GNL2'},
{value: 'GNL3', id: 'GNL3'},
{value: 'GNL3L', id: 'GNL3L'},
{value: 'GNLY', id: 'GNLY'},
{value: 'GNMT', id: 'GNMT'},
{value: 'GNPAT', id: 'GNPAT'},
{value: 'GNPATP', id: 'GNPATP'},
{value: 'GNPDA1', id: 'GNPDA1'},
{value: 'GNPDA2', id: 'GNPDA2'},
{value: 'GNPNAT1', id: 'GNPNAT1'},
{value: 'GNPTAB', id: 'GNPTAB'},
{value: 'GNPTG', id: 'GNPTG'},
{value: 'GNRH1', id: 'GNRH1'},
{value: 'GNRH2', id: 'GNRH2'},
{value: 'GNRHR2', id: 'GNRHR2'},
{value: 'GNRHR2P1', id: 'GNRHR2P1'},
{value: 'GNS', id: 'GNS'},
{value: 'GOLGA1', id: 'GOLGA1'},
{value: 'GOLGA2', id: 'GOLGA2'},
{value: 'GOLGA2P1', id: 'GOLGA2P1'},
{value: 'GOLGA2P10', id: 'GOLGA2P10'},
{value: 'GOLGA2P11', id: 'GOLGA2P11'},
{value: 'GOLGA2P4', id: 'GOLGA2P4'},
{value: 'GOLGA2P5', id: 'GOLGA2P5'},
{value: 'GOLGA2P6', id: 'GOLGA2P6'},
{value: 'GOLGA2P7', id: 'GOLGA2P7'},
{value: 'GOLGA2P9', id: 'GOLGA2P9'},
{value: 'GOLGA3', id: 'GOLGA3'},
{value: 'GOLGA4', id: 'GOLGA4'},
{value: 'GOLGA4-AS1', id: 'GOLGA4-AS1'},
{value: 'GOLGA5', id: 'GOLGA5'},
{value: 'GOLGA5P1', id: 'GOLGA5P1'},
{value: 'GOLGA6A', id: 'GOLGA6A'},
{value: 'GOLGA6B', id: 'GOLGA6B'},
{value: 'GOLGA6C', id: 'GOLGA6C'},
{value: 'GOLGA6D', id: 'GOLGA6D'},
{value: 'GOLGA6L10', id: 'GOLGA6L10'},
{value: 'GOLGA6L17P', id: 'GOLGA6L17P'},
{value: 'GOLGA6L2', id: 'GOLGA6L2'},
{value: 'GOLGA6L3', id: 'GOLGA6L3'},
{value: 'GOLGA6L4', id: 'GOLGA6L4'},
{value: 'GOLGA6L5P', id: 'GOLGA6L5P'},
{value: 'GOLGA6L6', id: 'GOLGA6L6'},
{value: 'GOLGA6L7', id: 'GOLGA6L7'},
{value: 'GOLGA6L9', id: 'GOLGA6L9'},
{value: 'GOLGA7', id: 'GOLGA7'},
{value: 'GOLGA7B', id: 'GOLGA7B'},
{value: 'GOLGA8A', id: 'GOLGA8A'},
{value: 'GOLGA8B', id: 'GOLGA8B'},
{value: 'GOLGA8G', id: 'GOLGA8G'},
{value: 'GOLGA8H', id: 'GOLGA8H'},
{value: 'GOLGA8IP', id: 'GOLGA8IP'},
{value: 'GOLGA8J', id: 'GOLGA8J'},
{value: 'GOLGA8K', id: 'GOLGA8K'},
{value: 'GOLGA8M', id: 'GOLGA8M'},
{value: 'GOLGA8N', id: 'GOLGA8N'},
{value: 'GOLGA8O', id: 'GOLGA8O'},
{value: 'GOLGA8Q', id: 'GOLGA8Q'},
{value: 'GOLGA8R', id: 'GOLGA8R'},
{value: 'GOLGA8T', id: 'GOLGA8T'},
{value: 'GOLGA8UP', id: 'GOLGA8UP'},
{value: 'GOLGB1', id: 'GOLGB1'},
{value: 'GOLIM4', id: 'GOLIM4'},
{value: 'GOLM1', id: 'GOLM1'},
{value: 'GOLM2', id: 'GOLM2'},
{value: 'GOLM2P1', id: 'GOLM2P1'},
{value: 'GOLPH3', id: 'GOLPH3'},
{value: 'GOLPH3-DT', id: 'GOLPH3-DT'},
{value: 'GOLPH3L', id: 'GOLPH3L'},
{value: 'GOLT1A', id: 'GOLT1A'},
{value: 'GOLT1B', id: 'GOLT1B'},
{value: 'GON4L', id: 'GON4L'},
{value: 'GON7', id: 'GON7'},
{value: 'GOPC', id: 'GOPC'},
{value: 'GORAB', id: 'GORAB'},
{value: 'GORAB-AS1', id: 'GORAB-AS1'},
{value: 'GORASP1', id: 'GORASP1'},
{value: 'GORASP2', id: 'GORASP2'},
{value: 'GOSR1', id: 'GOSR1'},
{value: 'GOSR2', id: 'GOSR2'},
{value: 'GOSR2-DT', id: 'GOSR2-DT'},
{value: 'GOT1', id: 'GOT1'},
{value: 'GOT1L1', id: 'GOT1L1'},
{value: 'GOT2', id: 'GOT2'},
{value: 'GOT2P1', id: 'GOT2P1'},
{value: 'GOT2P2', id: 'GOT2P2'},
{value: 'GOT2P3', id: 'GOT2P3'},
{value: 'GP1BA', id: 'GP1BA'},
{value: 'GP1BB', id: 'GP1BB'},
{value: 'GP2', id: 'GP2'},
{value: 'GP5', id: 'GP5'},
{value: 'GP6', id: 'GP6'},
{value: 'GP6-AS1', id: 'GP6-AS1'},
{value: 'GP9', id: 'GP9'},
{value: 'GPA33', id: 'GPA33'},
{value: 'GPAA1', id: 'GPAA1'},
{value: 'GPAA1P2', id: 'GPAA1P2'},
{value: 'GPALPP1', id: 'GPALPP1'},
{value: 'GPAM', id: 'GPAM'},
{value: 'GPANK1', id: 'GPANK1'},
{value: 'GPAT2', id: 'GPAT2'},
{value: 'GPAT2P1', id: 'GPAT2P1'},
{value: 'GPAT2P2', id: 'GPAT2P2'},
{value: 'GPAT3', id: 'GPAT3'},
{value: 'GPAT4', id: 'GPAT4'},
{value: 'GPAT4-AS1', id: 'GPAT4-AS1'},
{value: 'GPATCH1', id: 'GPATCH1'},
{value: 'GPATCH11', id: 'GPATCH11'},
{value: 'GPATCH11P1', id: 'GPATCH11P1'},
{value: 'GPATCH2', id: 'GPATCH2'},
{value: 'GPATCH2L', id: 'GPATCH2L'},
{value: 'GPATCH3', id: 'GPATCH3'},
{value: 'GPATCH4', id: 'GPATCH4'},
{value: 'GPATCH8', id: 'GPATCH8'},
{value: 'GPBAR1', id: 'GPBAR1'},
{value: 'GPBP1', id: 'GPBP1'},
{value: 'GPBP1L1', id: 'GPBP1L1'},
{value: 'GPC1', id: 'GPC1'},
{value: 'GPC2', id: 'GPC2'},
{value: 'GPC3', id: 'GPC3'},
{value: 'GPC4', id: 'GPC4'},
{value: 'GPC5', id: 'GPC5'},
{value: 'GPC5-AS2', id: 'GPC5-AS2'},
{value: 'GPC6', id: 'GPC6'},
{value: 'GPC6-AS1', id: 'GPC6-AS1'},
{value: 'GPCPD1', id: 'GPCPD1'},
{value: 'GPD1', id: 'GPD1'},
{value: 'GPD1L', id: 'GPD1L'},
{value: 'GPD2', id: 'GPD2'},
{value: 'GPER1', id: 'GPER1'},
{value: 'GPHA2', id: 'GPHA2'},
{value: 'GPHB5', id: 'GPHB5'},
{value: 'GPHN', id: 'GPHN'},
{value: 'GPI', id: 'GPI'},
{value: 'GPIHBP1', id: 'GPIHBP1'},
{value: 'GPKOW', id: 'GPKOW'},
{value: 'GPLD1', id: 'GPLD1'},
{value: 'GPM6A', id: 'GPM6A'},
{value: 'GPM6B', id: 'GPM6B'},
{value: 'GPM6BP3', id: 'GPM6BP3'},
{value: 'GPN1', id: 'GPN1'},
{value: 'GPN2', id: 'GPN2'},
{value: 'GPN3', id: 'GPN3'},
{value: 'GPN3P1', id: 'GPN3P1'},
{value: 'GPNMB', id: 'GPNMB'},
{value: 'GPR1-AS', id: 'GPR1-AS'},
{value: 'GPR107', id: 'GPR107'},
{value: 'GPR108', id: 'GPR108'},
{value: 'GPR12', id: 'GPR12'},
{value: 'GPR132', id: 'GPR132'},
{value: 'GPR135', id: 'GPR135'},
{value: 'GPR137', id: 'GPR137'},
{value: 'GPR137B', id: 'GPR137B'},
{value: 'GPR137C', id: 'GPR137C'},
{value: 'GPR139', id: 'GPR139'},
{value: 'GPR141', id: 'GPR141'},
{value: 'GPR142', id: 'GPR142'},
{value: 'GPR146', id: 'GPR146'},
{value: 'GPR148', id: 'GPR148'},
{value: 'GPR149', id: 'GPR149'},
{value: 'GPR15', id: 'GPR15'},
{value: 'GPR150', id: 'GPR150'},
{value: 'GPR151', id: 'GPR151'},
{value: 'GPR152', id: 'GPR152'},
{value: 'GPR153', id: 'GPR153'},
{value: 'GPR155', id: 'GPR155'},
{value: 'GPR156', id: 'GPR156'},
{value: 'GPR157', id: 'GPR157'},
{value: 'GPR158', id: 'GPR158'},
{value: 'GPR158-AS1', id: 'GPR158-AS1'},
{value: 'GPR160', id: 'GPR160'},
{value: 'GPR160P1', id: 'GPR160P1'},
{value: 'GPR160P2', id: 'GPR160P2'},
{value: 'GPR161', id: 'GPR161'},
{value: 'GPR162', id: 'GPR162'},
{value: 'GPR17', id: 'GPR17'},
{value: 'GPR171', id: 'GPR171'},
{value: 'GPR173', id: 'GPR173'},
{value: 'GPR174', id: 'GPR174'},
{value: 'GPR176', id: 'GPR176'},
{value: 'GPR176-DT', id: 'GPR176-DT'},
{value: 'GPR179', id: 'GPR179'},
{value: 'GPR18', id: 'GPR18'},
{value: 'GPR180', id: 'GPR180'},
{value: 'GPR182', id: 'GPR182'},
{value: 'GPR183', id: 'GPR183'},
{value: 'GPR19', id: 'GPR19'},
{value: 'GPR199P', id: 'GPR199P'},
{value: 'GPR20', id: 'GPR20'},
{value: 'GPR21', id: 'GPR21'},
{value: 'GPR25', id: 'GPR25'},
{value: 'GPR26', id: 'GPR26'},
{value: 'GPR27', id: 'GPR27'},
{value: 'GPR3', id: 'GPR3'},
{value: 'GPR31', id: 'GPR31'},
{value: 'GPR32', id: 'GPR32'},
{value: 'GPR32P1', id: 'GPR32P1'},
{value: 'GPR33', id: 'GPR33'},
{value: 'GPR34', id: 'GPR34'},
{value: 'GPR35', id: 'GPR35'},
{value: 'GPR37', id: 'GPR37'},
{value: 'GPR37L1', id: 'GPR37L1'},
{value: 'GPR39', id: 'GPR39'},
{value: 'GPR4', id: 'GPR4'},
{value: 'GPR42', id: 'GPR42'},
{value: 'GPR45', id: 'GPR45'},
{value: 'GPR50', id: 'GPR50'},
{value: 'GPR52', id: 'GPR52'},
{value: 'GPR53P', id: 'GPR53P'},
{value: 'GPR55', id: 'GPR55'},
{value: 'GPR6', id: 'GPR6'},
{value: 'GPR61', id: 'GPR61'},
{value: 'GPR62', id: 'GPR62'},
{value: 'GPR63', id: 'GPR63'},
{value: 'GPR65', id: 'GPR65'},
{value: 'GPR68', id: 'GPR68'},
{value: 'GPR75', id: 'GPR75'},
{value: 'GPR78', id: 'GPR78'},
{value: 'GPR79', id: 'GPR79'},
{value: 'GPR82', id: 'GPR82'},
{value: 'GPR83', id: 'GPR83'},
{value: 'GPR84', id: 'GPR84'},
{value: 'GPR85', id: 'GPR85'},
{value: 'GPR88', id: 'GPR88'},
{value: 'GPR89A', id: 'GPR89A'},
{value: 'GPR89B', id: 'GPR89B'},
{value: 'GPR89P', id: 'GPR89P'},
{value: 'GPRACR', id: 'GPRACR'},
{value: 'GPRASP1', id: 'GPRASP1'},
{value: 'GPRASP2', id: 'GPRASP2'},
{value: 'GPRC5A', id: 'GPRC5A'},
{value: 'GPRC5B', id: 'GPRC5B'},
{value: 'GPRC5C', id: 'GPRC5C'},
{value: 'GPRC5D', id: 'GPRC5D'},
{value: 'GPRC5D-AS1', id: 'GPRC5D-AS1'},
{value: 'GPRC6A', id: 'GPRC6A'},
{value: 'GPRIN1', id: 'GPRIN1'},
{value: 'GPRIN2', id: 'GPRIN2'},
{value: 'GPRIN3', id: 'GPRIN3'},
{value: 'GPS1', id: 'GPS1'},
{value: 'GPS2', id: 'GPS2'},
{value: 'GPS2P1', id: 'GPS2P1'},
{value: 'GPS2P2', id: 'GPS2P2'},
{value: 'GPSM1', id: 'GPSM1'},
{value: 'GPSM2', id: 'GPSM2'},
{value: 'GPSM3', id: 'GPSM3'},
{value: 'GPT', id: 'GPT'},
{value: 'GPT2', id: 'GPT2'},
{value: 'GPTC4', id: 'GPTC4'},
{value: 'GPX1', id: 'GPX1'},
{value: 'GPX1P1', id: 'GPX1P1'},
{value: 'GPX1P2', id: 'GPX1P2'},
{value: 'GPX2', id: 'GPX2'},
{value: 'GPX3', id: 'GPX3'},
{value: 'GPX4', id: 'GPX4'},
{value: 'GPX5', id: 'GPX5'},
{value: 'GPX6', id: 'GPX6'},
{value: 'GPX7', id: 'GPX7'},
{value: 'GPX8', id: 'GPX8'},
{value: 'GRAMD1A', id: 'GRAMD1A'},
{value: 'GRAMD1A-AS1', id: 'GRAMD1A-AS1'},
{value: 'GRAMD1B', id: 'GRAMD1B'},
{value: 'GRAMD1C', id: 'GRAMD1C'},
{value: 'GRAMD2A', id: 'GRAMD2A'},
{value: 'GRAMD2B', id: 'GRAMD2B'},
{value: 'GRAMD4', id: 'GRAMD4'},
{value: 'GRAMD4P5', id: 'GRAMD4P5'},
{value: 'GRAMD4P7', id: 'GRAMD4P7'},
{value: 'GRAP', id: 'GRAP'},
{value: 'GRAP2', id: 'GRAP2'},
{value: 'GRAPL', id: 'GRAPL'},
{value: 'GRAPLDR', id: 'GRAPLDR'},
{value: 'GRASLND', id: 'GRASLND'},
{value: 'GRB10', id: 'GRB10'},
{value: 'GRB14', id: 'GRB14'},
{value: 'GRB2', id: 'GRB2'},
{value: 'GRB7', id: 'GRB7'},
{value: 'GREB1', id: 'GREB1'},
{value: 'GREB1L', id: 'GREB1L'},
{value: 'GREB1L-DT', id: 'GREB1L-DT'},
{value: 'GREM1', id: 'GREM1'},
{value: 'GREM1-AS1', id: 'GREM1-AS1'},
{value: 'GREM2', id: 'GREM2'},
{value: 'GREP1', id: 'GREP1'},
{value: 'GRHL1', id: 'GRHL1'},
{value: 'GRHL2', id: 'GRHL2'},
{value: 'GRHL3', id: 'GRHL3'},
{value: 'GRHL3-AS1', id: 'GRHL3-AS1'},
{value: 'GRHPR', id: 'GRHPR'},
{value: 'GRIA1', id: 'GRIA1'},
{value: 'GRIA2', id: 'GRIA2'},
{value: 'GRIA3', id: 'GRIA3'},
{value: 'GRIA4', id: 'GRIA4'},
{value: 'GRID1', id: 'GRID1'},
{value: 'GRID1-AS1', id: 'GRID1-AS1'},
{value: 'GRID2', id: 'GRID2'},
{value: 'GRID2IP', id: 'GRID2IP'},
{value: 'GRIFIN', id: 'GRIFIN'},
{value: 'GRIK1', id: 'GRIK1'},
{value: 'GRIK2', id: 'GRIK2'},
{value: 'GRIK3', id: 'GRIK3'},
{value: 'GRIK4', id: 'GRIK4'},
{value: 'GRIK5', id: 'GRIK5'},
{value: 'GRIN1', id: 'GRIN1'},
{value: 'GRIN2A', id: 'GRIN2A'},
{value: 'GRIN2B', id: 'GRIN2B'},
{value: 'GRIN2C', id: 'GRIN2C'},
{value: 'GRIN2D', id: 'GRIN2D'},
{value: 'GRIN3A', id: 'GRIN3A'},
{value: 'GRIN3B', id: 'GRIN3B'},
{value: 'GRINA', id: 'GRINA'},
{value: 'GRIP1', id: 'GRIP1'},
{value: 'GRIP2', id: 'GRIP2'},
{value: 'GRIPAP1', id: 'GRIPAP1'},
{value: 'GRK1', id: 'GRK1'},
{value: 'GRK2', id: 'GRK2'},
{value: 'GRK3', id: 'GRK3'},
{value: 'GRK4', id: 'GRK4'},
{value: 'GRK5', id: 'GRK5'},
{value: 'GRK6', id: 'GRK6'},
{value: 'GRK6P1', id: 'GRK6P1'},
{value: 'GRK7', id: 'GRK7'},
{value: 'GRM1', id: 'GRM1'},
{value: 'GRM2', id: 'GRM2'},
{value: 'GRM3', id: 'GRM3'},
{value: 'GRM3-AS1', id: 'GRM3-AS1'},
{value: 'GRM4', id: 'GRM4'},
{value: 'GRM5', id: 'GRM5'},
{value: 'GRM5-AS1', id: 'GRM5-AS1'},
{value: 'GRM5P1', id: 'GRM5P1'},
{value: 'GRM6', id: 'GRM6'},
{value: 'GRM7', id: 'GRM7'},
{value: 'GRM7-AS3', id: 'GRM7-AS3'},
{value: 'GRM8', id: 'GRM8'},
{value: 'GRN', id: 'GRN'},
{value: 'GRP', id: 'GRP'},
{value: 'GRPEL1', id: 'GRPEL1'},
{value: 'GRPEL2', id: 'GRPEL2'},
{value: 'GRPEL2-AS1', id: 'GRPEL2-AS1'},
{value: 'GRPEL2P1', id: 'GRPEL2P1'},
{value: 'GRPEL2P3', id: 'GRPEL2P3'},
{value: 'GRSF1', id: 'GRSF1'},
{value: 'GRTP1', id: 'GRTP1'},
{value: 'GRTP1-AS1', id: 'GRTP1-AS1'},
{value: 'GRWD1', id: 'GRWD1'},
{value: 'GRXCR1', id: 'GRXCR1'},
{value: 'GRXCR2', id: 'GRXCR2'},
{value: 'GS1-124K5.4', id: 'GS1-124K5.4'},
{value: 'GS1-24F4.2', id: 'GS1-24F4.2'},
{value: 'GS1-279B7.1', id: 'GS1-279B7.1'},
{value: 'GSAP', id: 'GSAP'},
{value: 'GSC', id: 'GSC'},
{value: 'GSC2', id: 'GSC2'},
{value: 'GSDMA', id: 'GSDMA'},
{value: 'GSDMB', id: 'GSDMB'},
{value: 'GSDMC', id: 'GSDMC'},
{value: 'GSDMD', id: 'GSDMD'},
{value: 'GSDME', id: 'GSDME'},
{value: 'GSE1', id: 'GSE1'},
{value: 'GSEC', id: 'GSEC'},
{value: 'GSG1', id: 'GSG1'},
{value: 'GSG1L', id: 'GSG1L'},
{value: 'GSG1L2', id: 'GSG1L2'},
{value: 'GSK3A', id: 'GSK3A'},
{value: 'GSK3B', id: 'GSK3B'},
{value: 'GSKIP', id: 'GSKIP'},
{value: 'GSN', id: 'GSN'},
{value: 'GSN-AS1', id: 'GSN-AS1'},
{value: 'GSPT1', id: 'GSPT1'},
{value: 'GSPT2', id: 'GSPT2'},
{value: 'GSR', id: 'GSR'},
{value: 'GSS', id: 'GSS'},
{value: 'GSTA1', id: 'GSTA1'},
{value: 'GSTA10P', id: 'GSTA10P'},
{value: 'GSTA11P', id: 'GSTA11P'},
{value: 'GSTA12P', id: 'GSTA12P'},
{value: 'GSTA2', id: 'GSTA2'},
{value: 'GSTA3', id: 'GSTA3'},
{value: 'GSTA4', id: 'GSTA4'},
{value: 'GSTA5', id: 'GSTA5'},
{value: 'GSTA6P', id: 'GSTA6P'},
{value: 'GSTA7P', id: 'GSTA7P'},
{value: 'GSTA8P', id: 'GSTA8P'},
{value: 'GSTA9P', id: 'GSTA9P'},
{value: 'GSTCD', id: 'GSTCD'},
{value: 'GSTK1', id: 'GSTK1'},
{value: 'GSTM1', id: 'GSTM1'},
{value: 'GSTM2', id: 'GSTM2'},
{value: 'GSTM2P1', id: 'GSTM2P1'},
{value: 'GSTM3', id: 'GSTM3'},
{value: 'GSTM3P1', id: 'GSTM3P1'},
{value: 'GSTM3P2', id: 'GSTM3P2'},
{value: 'GSTM4', id: 'GSTM4'},
{value: 'GSTM5', id: 'GSTM5'},
{value: 'GSTM5P1', id: 'GSTM5P1'},
{value: 'GSTO1', id: 'GSTO1'},
{value: 'GSTO2', id: 'GSTO2'},
{value: 'GSTO3P', id: 'GSTO3P'},
{value: 'GSTP1', id: 'GSTP1'},
{value: 'GSTP1P1', id: 'GSTP1P1'},
{value: 'GSTT1', id: 'GSTT1'},
{value: 'GSTT2', id: 'GSTT2'},
{value: 'GSTT2B', id: 'GSTT2B'},
{value: 'GSTT4', id: 'GSTT4'},
{value: 'GSTZ1', id: 'GSTZ1'},
{value: 'GSX1', id: 'GSX1'},
{value: 'GSX2', id: 'GSX2'},
{value: 'GTDC1', id: 'GTDC1'},
{value: 'GTF2A1', id: 'GTF2A1'},
{value: 'GTF2A1L', id: 'GTF2A1L'},
{value: 'GTF2A2', id: 'GTF2A2'},
{value: 'GTF2B', id: 'GTF2B'},
{value: 'GTF2E1', id: 'GTF2E1'},
{value: 'GTF2E2', id: 'GTF2E2'},
{value: 'GTF2F1', id: 'GTF2F1'},
{value: 'GTF2F2', id: 'GTF2F2'},
{value: 'GTF2F2P2', id: 'GTF2F2P2'},
{value: 'GTF2H1', id: 'GTF2H1'},
{value: 'GTF2H2', id: 'GTF2H2'},
{value: 'GTF2H2B', id: 'GTF2H2B'},
{value: 'GTF2H2C', id: 'GTF2H2C'},
{value: 'GTF2H3', id: 'GTF2H3'},
{value: 'GTF2H4', id: 'GTF2H4'},
{value: 'GTF2H5', id: 'GTF2H5'},
{value: 'GTF2I', id: 'GTF2I'},
{value: 'GTF2I-AS1', id: 'GTF2I-AS1'},
{value: 'GTF2IP1', id: 'GTF2IP1'},
{value: 'GTF2IP11', id: 'GTF2IP11'},
{value: 'GTF2IP12', id: 'GTF2IP12'},
{value: 'GTF2IP13', id: 'GTF2IP13'},
{value: 'GTF2IP14', id: 'GTF2IP14'},
{value: 'GTF2IP2', id: 'GTF2IP2'},
{value: 'GTF2IP20', id: 'GTF2IP20'},
{value: 'GTF2IP22', id: 'GTF2IP22'},
{value: 'GTF2IP23', id: 'GTF2IP23'},
{value: 'GTF2IP4', id: 'GTF2IP4'},
{value: 'GTF2IP5', id: 'GTF2IP5'},
{value: 'GTF2IP6', id: 'GTF2IP6'},
{value: 'GTF2IP7', id: 'GTF2IP7'},
{value: 'GTF2IP8', id: 'GTF2IP8'},
{value: 'GTF2IRD1', id: 'GTF2IRD1'},
{value: 'GTF2IRD1P1', id: 'GTF2IRD1P1'},
{value: 'GTF2IRD2', id: 'GTF2IRD2'},
{value: 'GTF2IRD2B', id: 'GTF2IRD2B'},
{value: 'GTF2IRD2P1', id: 'GTF2IRD2P1'},
{value: 'GTF3A', id: 'GTF3A'},
{value: 'GTF3AP1', id: 'GTF3AP1'},
{value: 'GTF3AP2', id: 'GTF3AP2'},
{value: 'GTF3AP5', id: 'GTF3AP5'},
{value: 'GTF3AP6', id: 'GTF3AP6'},
{value: 'GTF3C1', id: 'GTF3C1'},
{value: 'GTF3C2', id: 'GTF3C2'},
{value: 'GTF3C2-AS1', id: 'GTF3C2-AS1'},
{value: 'GTF3C3', id: 'GTF3C3'},
{value: 'GTF3C4', id: 'GTF3C4'},
{value: 'GTF3C5', id: 'GTF3C5'},
{value: 'GTF3C6', id: 'GTF3C6'},
{value: 'GTPBP1', id: 'GTPBP1'},
{value: 'GTPBP10', id: 'GTPBP10'},
{value: 'GTPBP2', id: 'GTPBP2'},
{value: 'GTPBP3', id: 'GTPBP3'},
{value: 'GTPBP4', id: 'GTPBP4'},
{value: 'GTPBP6', id: 'GTPBP6'},
{value: 'GTPBP8', id: 'GTPBP8'},
{value: 'GTSCR1', id: 'GTSCR1'},
{value: 'GTSE1', id: 'GTSE1'},
{value: 'GTSE1-DT', id: 'GTSE1-DT'},
{value: 'GTSF1', id: 'GTSF1'},
{value: 'GTSF1L', id: 'GTSF1L'},
{value: 'GUCA1A', id: 'GUCA1A'},
{value: 'GUCA1B', id: 'GUCA1B'},
{value: 'GUCA1C', id: 'GUCA1C'},
{value: 'GUCA2A', id: 'GUCA2A'},
{value: 'GUCA2B', id: 'GUCA2B'},
{value: 'GUCD1', id: 'GUCD1'},
{value: 'GUCY1A1', id: 'GUCY1A1'},
{value: 'GUCY1A2', id: 'GUCY1A2'},
{value: 'GUCY1B1', id: 'GUCY1B1'},
{value: 'GUCY1B2', id: 'GUCY1B2'},
{value: 'GUCY2C', id: 'GUCY2C'},
{value: 'GUCY2D', id: 'GUCY2D'},
{value: 'GUCY2EP', id: 'GUCY2EP'},
{value: 'GUCY2GP', id: 'GUCY2GP'},
{value: 'GUF1', id: 'GUF1'},
{value: 'GUK1', id: 'GUK1'},
{value: 'GULOP', id: 'GULOP'},
{value: 'GULP1', id: 'GULP1'},
{value: 'GUSB', id: 'GUSB'},
{value: 'GUSBP1', id: 'GUSBP1'},
{value: 'GUSBP10', id: 'GUSBP10'},
{value: 'GUSBP11', id: 'GUSBP11'},
{value: 'GUSBP12', id: 'GUSBP12'},
{value: 'GUSBP13', id: 'GUSBP13'},
{value: 'GUSBP14', id: 'GUSBP14'},
{value: 'GUSBP15', id: 'GUSBP15'},
{value: 'GUSBP16', id: 'GUSBP16'},
{value: 'GUSBP2', id: 'GUSBP2'},
{value: 'GUSBP3', id: 'GUSBP3'},
{value: 'GUSBP4', id: 'GUSBP4'},
{value: 'GUSBP5', id: 'GUSBP5'},
{value: 'GUSBP6', id: 'GUSBP6'},
{value: 'GUSBP7', id: 'GUSBP7'},
{value: 'GUSBP8', id: 'GUSBP8'},
{value: 'GUSBP9', id: 'GUSBP9'},
{value: 'GVINP1', id: 'GVINP1'},
{value: 'GVINP2', id: 'GVINP2'},
{value: 'GVQW3', id: 'GVQW3'},
{value: 'GXYLT1', id: 'GXYLT1'},
{value: 'GXYLT1P1', id: 'GXYLT1P1'},
{value: 'GXYLT1P2', id: 'GXYLT1P2'},
{value: 'GXYLT1P4', id: 'GXYLT1P4'},
{value: 'GXYLT1P6', id: 'GXYLT1P6'},
{value: 'GXYLT1P7', id: 'GXYLT1P7'},
{value: 'GXYLT2', id: 'GXYLT2'},
{value: 'GYG1', id: 'GYG1'},
{value: 'GYG1P1', id: 'GYG1P1'},
{value: 'GYG1P2', id: 'GYG1P2'},
{value: 'GYG1P3', id: 'GYG1P3'},
{value: 'GYG2', id: 'GYG2'},
{value: 'GYG2P1', id: 'GYG2P1'},
{value: 'GYPA', id: 'GYPA'},
{value: 'GYPB', id: 'GYPB'},
{value: 'GYPC', id: 'GYPC'},
{value: 'GYPE', id: 'GYPE'},
{value: 'GYS1', id: 'GYS1'},
{value: 'GYS2', id: 'GYS2'},
{value: 'GZF1', id: 'GZF1'},
{value: 'GZMA', id: 'GZMA'},
{value: 'GZMAP1', id: 'GZMAP1'},
{value: 'GZMB', id: 'GZMB'},
{value: 'GZMH', id: 'GZMH'},
{value: 'GZMK', id: 'GZMK'},
{value: 'GZMM', id: 'GZMM'},
{value: 'H1-0', id: 'H1-0'},
{value: 'H1-1', id: 'H1-1'},
{value: 'H1-10', id: 'H1-10'},
{value: 'H1-10-AS1', id: 'H1-10-AS1'},
{value: 'H1-12P', id: 'H1-12P'},
{value: 'H1-2', id: 'H1-2'},
{value: 'H1-3', id: 'H1-3'},
{value: 'H1-4', id: 'H1-4'},
{value: 'H1-5', id: 'H1-5'},
{value: 'H1-6', id: 'H1-6'},
{value: 'H1-7', id: 'H1-7'},
{value: 'H1-8', id: 'H1-8'},
{value: 'H1-9P', id: 'H1-9P'},
{value: 'H19', id: 'H19'},
{value: 'H2AB1', id: 'H2AB1'},
{value: 'H2AB2', id: 'H2AB2'},
{value: 'H2AB3', id: 'H2AB3'},
{value: 'H2AC1', id: 'H2AC1'},
{value: 'H2AC10P', id: 'H2AC10P'},
{value: 'H2AC11', id: 'H2AC11'},
{value: 'H2AC12', id: 'H2AC12'},
{value: 'H2AC13', id: 'H2AC13'},
{value: 'H2AC14', id: 'H2AC14'},
{value: 'H2AC15', id: 'H2AC15'},
{value: 'H2AC16', id: 'H2AC16'},
{value: 'H2AC17', id: 'H2AC17'},
{value: 'H2AC18', id: 'H2AC18'},
{value: 'H2AC19', id: 'H2AC19'},
{value: 'H2AC20', id: 'H2AC20'},
{value: 'H2AC21', id: 'H2AC21'},
{value: 'H2AC2P', id: 'H2AC2P'},
{value: 'H2AC3P', id: 'H2AC3P'},
{value: 'H2AC4', id: 'H2AC4'},
{value: 'H2AC5P', id: 'H2AC5P'},
{value: 'H2AC6', id: 'H2AC6'},
{value: 'H2AC7', id: 'H2AC7'},
{value: 'H2AC8', id: 'H2AC8'},
{value: 'H2AC9P', id: 'H2AC9P'},
{value: 'H2ACP1', id: 'H2ACP1'},
{value: 'H2AJ', id: 'H2AJ'},
{value: 'H2AW', id: 'H2AW'},
{value: 'H2AX', id: 'H2AX'},
{value: 'H2AZ1', id: 'H2AZ1'},
{value: 'H2AZ1-DT', id: 'H2AZ1-DT'},
{value: 'H2AZ2', id: 'H2AZ2'},
{value: 'H2AZ2P1', id: 'H2AZ2P1'},
{value: 'H2AZP2', id: 'H2AZP2'},
{value: 'H2AZP3', id: 'H2AZP3'},
{value: 'H2AZP5', id: 'H2AZP5'},
{value: 'H2AZP6', id: 'H2AZP6'},
{value: 'H2AZP7', id: 'H2AZP7'},
{value: 'H2BC1', id: 'H2BC1'},
{value: 'H2BC10', id: 'H2BC10'},
{value: 'H2BC11', id: 'H2BC11'},
{value: 'H2BC12', id: 'H2BC12'},
{value: 'H2BC13', id: 'H2BC13'},
{value: 'H2BC14', id: 'H2BC14'},
{value: 'H2BC15', id: 'H2BC15'},
{value: 'H2BC16P', id: 'H2BC16P'},
{value: 'H2BC17', id: 'H2BC17'},
{value: 'H2BC18', id: 'H2BC18'},
{value: 'H2BC19P', id: 'H2BC19P'},
{value: 'H2BC20P', id: 'H2BC20P'},
{value: 'H2BC21', id: 'H2BC21'},
{value: 'H2BC2P', id: 'H2BC2P'},
{value: 'H2BC3', id: 'H2BC3'},
{value: 'H2BC4', id: 'H2BC4'},
{value: 'H2BC5', id: 'H2BC5'},
{value: 'H2BC6', id: 'H2BC6'},
{value: 'H2BC7', id: 'H2BC7'},
{value: 'H2BC8', id: 'H2BC8'},
{value: 'H2BC9', id: 'H2BC9'},
{value: 'H2BE1', id: 'H2BE1'},
{value: 'H2BL1P', id: 'H2BL1P'},
{value: 'H2BP1', id: 'H2BP1'},
{value: 'H2BP2', id: 'H2BP2'},
{value: 'H2BP3', id: 'H2BP3'},
{value: 'H2BP5', id: 'H2BP5'},
{value: 'H2BP6', id: 'H2BP6'},
{value: 'H2BS1', id: 'H2BS1'},
{value: 'H2BU1', id: 'H2BU1'},
{value: 'H2BU2P', id: 'H2BU2P'},
{value: 'H3-2', id: 'H3-2'},
{value: 'H3-3A', id: 'H3-3A'},
{value: 'H3-3A-DT', id: 'H3-3A-DT'},
{value: 'H3-3B', id: 'H3-3B'},
{value: 'H3-4', id: 'H3-4'},
{value: 'H3-5', id: 'H3-5'},
{value: 'H3C1', id: 'H3C1'},
{value: 'H3C10', id: 'H3C10'},
{value: 'H3C11', id: 'H3C11'},
{value: 'H3C12', id: 'H3C12'},
{value: 'H3C13', id: 'H3C13'},
{value: 'H3C14', id: 'H3C14'},
{value: 'H3C15', id: 'H3C15'},
{value: 'H3C2', id: 'H3C2'},
{value: 'H3C3', id: 'H3C3'},
{value: 'H3C4', id: 'H3C4'},
{value: 'H3C5P', id: 'H3C5P'},
{value: 'H3C6', id: 'H3C6'},
{value: 'H3C7', id: 'H3C7'},
{value: 'H3C8', id: 'H3C8'},
{value: 'H3C9P', id: 'H3C9P'},
{value: 'H3P1', id: 'H3P1'},
{value: 'H3P10', id: 'H3P10'},
{value: 'H3P11', id: 'H3P11'},
{value: 'H3P12', id: 'H3P12'},
{value: 'H3P15', id: 'H3P15'},
{value: 'H3P16', id: 'H3P16'},
{value: 'H3P17', id: 'H3P17'},
{value: 'H3P18', id: 'H3P18'},
{value: 'H3P19', id: 'H3P19'},
{value: 'H3P2', id: 'H3P2'},
{value: 'H3P20', id: 'H3P20'},
{value: 'H3P21', id: 'H3P21'},
{value: 'H3P22', id: 'H3P22'},
{value: 'H3P24', id: 'H3P24'},
{value: 'H3P25', id: 'H3P25'},
{value: 'H3P26', id: 'H3P26'},
{value: 'H3P27', id: 'H3P27'},
{value: 'H3P28', id: 'H3P28'},
{value: 'H3P29', id: 'H3P29'},
{value: 'H3P3', id: 'H3P3'},
{value: 'H3P30', id: 'H3P30'},
{value: 'H3P31', id: 'H3P31'},
{value: 'H3P33', id: 'H3P33'},
{value: 'H3P34', id: 'H3P34'},
{value: 'H3P35', id: 'H3P35'},
{value: 'H3P37', id: 'H3P37'},
{value: 'H3P38', id: 'H3P38'},
{value: 'H3P39', id: 'H3P39'},
{value: 'H3P40', id: 'H3P40'},
{value: 'H3P41', id: 'H3P41'},
{value: 'H3P42', id: 'H3P42'},
{value: 'H3P47', id: 'H3P47'},
{value: 'H3P5', id: 'H3P5'},
{value: 'H3P6', id: 'H3P6'},
{value: 'H3P9', id: 'H3P9'},
{value: 'H3Y1', id: 'H3Y1'},
{value: 'H3Y2', id: 'H3Y2'},
{value: 'H4-16', id: 'H4-16'},
{value: 'H4C1', id: 'H4C1'},
{value: 'H4C10P', id: 'H4C10P'},
{value: 'H4C11', id: 'H4C11'},
{value: 'H4C12', id: 'H4C12'},
{value: 'H4C13', id: 'H4C13'},
{value: 'H4C14', id: 'H4C14'},
{value: 'H4C15', id: 'H4C15'},
{value: 'H4C2', id: 'H4C2'},
{value: 'H4C3', id: 'H4C3'},
{value: 'H4C4', id: 'H4C4'},
{value: 'H4C5', id: 'H4C5'},
{value: 'H4C6', id: 'H4C6'},
{value: 'H4C7', id: 'H4C7'},
{value: 'H4C8', id: 'H4C8'},
{value: 'H4C9', id: 'H4C9'},
{value: 'H6PD', id: 'H6PD'},
{value: 'HAAO', id: 'HAAO'},
{value: 'HABP2', id: 'HABP2'},
{value: 'HABP4', id: 'HABP4'},
{value: 'HACD1', id: 'HACD1'},
{value: 'HACD2', id: 'HACD2'},
{value: 'HACD3', id: 'HACD3'},
{value: 'HACD4', id: 'HACD4'},
{value: 'HACE1', id: 'HACE1'},
{value: 'HACL1', id: 'HACL1'},
{value: 'HADH', id: 'HADH'},
{value: 'HADHA', id: 'HADHA'},
{value: 'HADHAP2', id: 'HADHAP2'},
{value: 'HADHB', id: 'HADHB'},
{value: 'HAGH', id: 'HAGH'},
{value: 'HAGHL', id: 'HAGHL'},
{value: 'HAGLR', id: 'HAGLR'},
{value: 'HAGLROS', id: 'HAGLROS'},
{value: 'HAL', id: 'HAL'},
{value: 'HAMP', id: 'HAMP'},
{value: 'HAND1', id: 'HAND1'},
{value: 'HAND2', id: 'HAND2'},
{value: 'HAND2-AS1', id: 'HAND2-AS1'},
{value: 'HAO1', id: 'HAO1'},
{value: 'HAO2', id: 'HAO2'},
{value: 'HAP1', id: 'HAP1'},
{value: 'HAPLN1', id: 'HAPLN1'},
{value: 'HAPLN2', id: 'HAPLN2'},
{value: 'HAPLN3', id: 'HAPLN3'},
{value: 'HAPLN4', id: 'HAPLN4'},
{value: 'HAR1A', id: 'HAR1A'},
{value: 'HAR1B', id: 'HAR1B'},
{value: 'HARBI1', id: 'HARBI1'},
{value: 'HARS1', id: 'HARS1'},
{value: 'HARS2', id: 'HARS2'},
{value: 'HAS1', id: 'HAS1'},
{value: 'HAS2', id: 'HAS2'},
{value: 'HAS2-AS1', id: 'HAS2-AS1'},
{value: 'HAS3', id: 'HAS3'},
{value: 'HASPIN', id: 'HASPIN'},
{value: 'HAT1', id: 'HAT1'},
{value: 'HAUS1', id: 'HAUS1'},
{value: 'HAUS1P1', id: 'HAUS1P1'},
{value: 'HAUS2', id: 'HAUS2'},
{value: 'HAUS3', id: 'HAUS3'},
{value: 'HAUS4', id: 'HAUS4'},
{value: 'HAUS4P1', id: 'HAUS4P1'},
{value: 'HAUS5', id: 'HAUS5'},
{value: 'HAUS5-DT', id: 'HAUS5-DT'},
{value: 'HAUS6', id: 'HAUS6'},
{value: 'HAUS6P1', id: 'HAUS6P1'},
{value: 'HAUS6P2', id: 'HAUS6P2'},
{value: 'HAUS6P3', id: 'HAUS6P3'},
{value: 'HAUS7', id: 'HAUS7'},
{value: 'HAUS8', id: 'HAUS8'},
{value: 'HAVCR1', id: 'HAVCR1'},
{value: 'HAVCR2', id: 'HAVCR2'},
{value: 'HAX1', id: 'HAX1'},
{value: 'HBA1', id: 'HBA1'},
{value: 'HBA2', id: 'HBA2'},
{value: 'HBAP1', id: 'HBAP1'},
{value: 'HBB', id: 'HBB'},
{value: 'HBBP1', id: 'HBBP1'},
{value: 'HBD', id: 'HBD'},
{value: 'HBE1', id: 'HBE1'},
{value: 'HBEGF', id: 'HBEGF'},
{value: 'HBG1', id: 'HBG1'},
{value: 'HBG2', id: 'HBG2'},
{value: 'HBM', id: 'HBM'},
{value: 'HBP1', id: 'HBP1'},
{value: 'HBQ1', id: 'HBQ1'},
{value: 'HBS1L', id: 'HBS1L'},
{value: 'HBZ', id: 'HBZ'},
{value: 'HBZP1', id: 'HBZP1'},
{value: 'HCAR1', id: 'HCAR1'},
{value: 'HCAR2', id: 'HCAR2'},
{value: 'HCAR3', id: 'HCAR3'},
{value: 'HCCAT5', id: 'HCCAT5'},
{value: 'HCCS', id: 'HCCS'},
{value: 'HCFC1', id: 'HCFC1'},
{value: 'HCFC1R1', id: 'HCFC1R1'},
{value: 'HCFC2', id: 'HCFC2'},
{value: 'HCFC2P1', id: 'HCFC2P1'},
{value: 'HCG11', id: 'HCG11'},
{value: 'HCG14', id: 'HCG14'},
{value: 'HCG15', id: 'HCG15'},
{value: 'HCG17', id: 'HCG17'},
{value: 'HCG18', id: 'HCG18'},
{value: 'HCG20', id: 'HCG20'},
{value: 'HCG21', id: 'HCG21'},
{value: 'HCG22', id: 'HCG22'},
{value: 'HCG24', id: 'HCG24'},
{value: 'HCG25', id: 'HCG25'},
{value: 'HCG27', id: 'HCG27'},
{value: 'HCG4', id: 'HCG4'},
{value: 'HCG4B', id: 'HCG4B'},
{value: 'HCG4P8', id: 'HCG4P8'},
{value: 'HCG9', id: 'HCG9'},
{value: 'HCK', id: 'HCK'},
{value: 'HCLS1', id: 'HCLS1'},
{value: 'HCN1', id: 'HCN1'},
{value: 'HCN2', id: 'HCN2'},
{value: 'HCN3', id: 'HCN3'},
{value: 'HCN4', id: 'HCN4'},
{value: 'HCP5', id: 'HCP5'},
{value: 'HCP5B', id: 'HCP5B'},
{value: 'HCRT', id: 'HCRT'},
{value: 'HCRTR1', id: 'HCRTR1'},
{value: 'HCRTR2', id: 'HCRTR2'},
{value: 'HCST', id: 'HCST'},
{value: 'HDAC1', id: 'HDAC1'},
{value: 'HDAC10', id: 'HDAC10'},
{value: 'HDAC11', id: 'HDAC11'},
{value: 'HDAC11-AS1', id: 'HDAC11-AS1'},
{value: 'HDAC1P2', id: 'HDAC1P2'},
{value: 'HDAC2', id: 'HDAC2'},
{value: 'HDAC2-AS2', id: 'HDAC2-AS2'},
{value: 'HDAC3', id: 'HDAC3'},
{value: 'HDAC4', id: 'HDAC4'},
{value: 'HDAC4-AS1', id: 'HDAC4-AS1'},
{value: 'HDAC5', id: 'HDAC5'},
{value: 'HDAC6', id: 'HDAC6'},
{value: 'HDAC7', id: 'HDAC7'},
{value: 'HDAC8', id: 'HDAC8'},
{value: 'HDAC9', id: 'HDAC9'},
{value: 'HDC', id: 'HDC'},
{value: 'HDDC2', id: 'HDDC2'},
{value: 'HDDC3', id: 'HDDC3'},
{value: 'HDGF', id: 'HDGF'},
{value: 'HDGFL1', id: 'HDGFL1'},
{value: 'HDGFL2', id: 'HDGFL2'},
{value: 'HDGFL3', id: 'HDGFL3'},
{value: 'HDHD2', id: 'HDHD2'},
{value: 'HDHD3', id: 'HDHD3'},
{value: 'HDHD5', id: 'HDHD5'},
{value: 'HDHD5-AS1', id: 'HDHD5-AS1'},
{value: 'HDLBP', id: 'HDLBP'},
{value: 'HDX', id: 'HDX'},
{value: 'HEATR1', id: 'HEATR1'},
{value: 'HEATR3', id: 'HEATR3'},
{value: 'HEATR3-AS1', id: 'HEATR3-AS1'},
{value: 'HEATR4', id: 'HEATR4'},
{value: 'HEATR5A', id: 'HEATR5A'},
{value: 'HEATR5A-DT', id: 'HEATR5A-DT'},
{value: 'HEATR5B', id: 'HEATR5B'},
{value: 'HEATR6', id: 'HEATR6'},
{value: 'HEATR6-DT', id: 'HEATR6-DT'},
{value: 'HEATR9', id: 'HEATR9'},
{value: 'HEBP1', id: 'HEBP1'},
{value: 'HEBP2', id: 'HEBP2'},
{value: 'HEBP2P1', id: 'HEBP2P1'},
{value: 'HECA', id: 'HECA'},
{value: 'HECTD1', id: 'HECTD1'},
{value: 'HECTD2', id: 'HECTD2'},
{value: 'HECTD3', id: 'HECTD3'},
{value: 'HECTD4', id: 'HECTD4'},
{value: 'HECW1', id: 'HECW1'},
{value: 'HECW2', id: 'HECW2'},
{value: 'HECW2-AS1', id: 'HECW2-AS1'},
{value: 'HEG1', id: 'HEG1'},
{value: 'HEIH', id: 'HEIH'},
{value: 'HELB', id: 'HELB'},
{value: 'HELLPAR', id: 'HELLPAR'},
{value: 'HELLS', id: 'HELLS'},
{value: 'HELQ', id: 'HELQ'},
{value: 'HELT', id: 'HELT'},
{value: 'HELZ', id: 'HELZ'},
{value: 'HELZ-AS1', id: 'HELZ-AS1'},
{value: 'HELZ2', id: 'HELZ2'},
{value: 'HEMGN', id: 'HEMGN'},
{value: 'HEMK1', id: 'HEMK1'},
{value: 'HENMT1', id: 'HENMT1'},
{value: 'HEPACAM', id: 'HEPACAM'},
{value: 'HEPACAM2', id: 'HEPACAM2'},
{value: 'HEPH', id: 'HEPH'},
{value: 'HEPHL1', id: 'HEPHL1'},
{value: 'HEPN1', id: 'HEPN1'},
{value: 'HERC1', id: 'HERC1'},
{value: 'HERC2', id: 'HERC2'},
{value: 'HERC2P10', id: 'HERC2P10'},
{value: 'HERC2P2', id: 'HERC2P2'},
{value: 'HERC2P3', id: 'HERC2P3'},
{value: 'HERC2P4', id: 'HERC2P4'},
{value: 'HERC2P5', id: 'HERC2P5'},
{value: 'HERC2P6', id: 'HERC2P6'},
{value: 'HERC2P7', id: 'HERC2P7'},
{value: 'HERC2P8', id: 'HERC2P8'},
{value: 'HERC2P9', id: 'HERC2P9'},
{value: 'HERC3', id: 'HERC3'},
{value: 'HERC4', id: 'HERC4'},
{value: 'HERC5', id: 'HERC5'},
{value: 'HERC6', id: 'HERC6'},
{value: 'HERPUD1', id: 'HERPUD1'},
{value: 'HERPUD2', id: 'HERPUD2'},
{value: 'HERPUD2-AS1', id: 'HERPUD2-AS1'},
{value: 'HES1', id: 'HES1'},
{value: 'HES2', id: 'HES2'},
{value: 'HES3', id: 'HES3'},
{value: 'HES4', id: 'HES4'},
{value: 'HES5', id: 'HES5'},
{value: 'HES6', id: 'HES6'},
{value: 'HES7', id: 'HES7'},
{value: 'HESX1', id: 'HESX1'},
{value: 'HEXA', id: 'HEXA'},
{value: 'HEXA-AS1', id: 'HEXA-AS1'},
{value: 'HEXB', id: 'HEXB'},
{value: 'HEXD', id: 'HEXD'},
{value: 'HEXD-IT1', id: 'HEXD-IT1'},
{value: 'HEXIM1', id: 'HEXIM1'},
{value: 'HEXIM2', id: 'HEXIM2'},
{value: 'HEY1', id: 'HEY1'},
{value: 'HEY2', id: 'HEY2'},
{value: 'HEYL', id: 'HEYL'},
{value: 'HFE', id: 'HFE'},
{value: 'HFM1', id: 'HFM1'},
{value: 'HGD', id: 'HGD'},
{value: 'HGF', id: 'HGF'},
{value: 'HGFAC', id: 'HGFAC'},
{value: 'HGH1', id: 'HGH1'},
{value: 'HGS', id: 'HGS'},
{value: 'HGSNAT', id: 'HGSNAT'},
{value: 'HHAT', id: 'HHAT'},
{value: 'HHATL', id: 'HHATL'},
{value: 'HHATL-AS1', id: 'HHATL-AS1'},
{value: 'HHEX', id: 'HHEX'},
{value: 'HHIP', id: 'HHIP'},
{value: 'HHIP-AS1', id: 'HHIP-AS1'},
{value: 'HHIPL1', id: 'HHIPL1'},
{value: 'HHIPL2', id: 'HHIPL2'},
{value: 'HHLA1', id: 'HHLA1'},
{value: 'HHLA2', id: 'HHLA2'},
{value: 'HHLA3', id: 'HHLA3'},
{value: 'HIBADH', id: 'HIBADH'},
{value: 'HIBCH', id: 'HIBCH'},
{value: 'HIC1', id: 'HIC1'},
{value: 'HIC2', id: 'HIC2'},
{value: 'HID1', id: 'HID1'},
{value: 'HID1-AS1', id: 'HID1-AS1'},
{value: 'HIF1A', id: 'HIF1A'},
{value: 'HIF1A-AS1', id: 'HIF1A-AS1'},
{value: 'HIF1A-AS3', id: 'HIF1A-AS3'},
{value: 'HIF1AN', id: 'HIF1AN'},
{value: 'HIF3A', id: 'HIF3A'},
{value: 'HIGD1A', id: 'HIGD1A'},
{value: 'HIGD1AP1', id: 'HIGD1AP1'},
{value: 'HIGD1AP10', id: 'HIGD1AP10'},
{value: 'HIGD1AP12', id: 'HIGD1AP12'},
{value: 'HIGD1AP18', id: 'HIGD1AP18'},
{value: 'HIGD1AP2', id: 'HIGD1AP2'},
{value: 'HIGD1AP3', id: 'HIGD1AP3'},
{value: 'HIGD1AP4', id: 'HIGD1AP4'},
{value: 'HIGD1AP5', id: 'HIGD1AP5'},
{value: 'HIGD1B', id: 'HIGD1B'},
{value: 'HIGD1C', id: 'HIGD1C'},
{value: 'HIGD2A', id: 'HIGD2A'},
{value: 'HIGD2AP1', id: 'HIGD2AP1'},
{value: 'HIGD2AP2', id: 'HIGD2AP2'},
{value: 'HIGD2B', id: 'HIGD2B'},
{value: 'HIKESHI', id: 'HIKESHI'},
{value: 'HIKESHIP3', id: 'HIKESHIP3'},
{value: 'HILPDA', id: 'HILPDA'},
{value: 'HINFP', id: 'HINFP'},
{value: 'HINT1', id: 'HINT1'},
{value: 'HINT1P1', id: 'HINT1P1'},
{value: 'HINT2', id: 'HINT2'},
{value: 'HINT2P1', id: 'HINT2P1'},
{value: 'HINT3', id: 'HINT3'},
{value: 'HIP1', id: 'HIP1'},
{value: 'HIP1R', id: 'HIP1R'},
{value: 'HIPK1', id: 'HIPK1'},
{value: 'HIPK1-AS1', id: 'HIPK1-AS1'},
{value: 'HIPK2', id: 'HIPK2'},
{value: 'HIPK3', id: 'HIPK3'},
{value: 'HIPK4', id: 'HIPK4'},
{value: 'HIRA', id: 'HIRA'},
{value: 'HIRAP1', id: 'HIRAP1'},
{value: 'HIRIP3', id: 'HIRIP3'},
{value: 'HIVEP1', id: 'HIVEP1'},
{value: 'HIVEP2', id: 'HIVEP2'},
{value: 'HIVEP3', id: 'HIVEP3'},
{value: 'HJURP', id: 'HJURP'},
{value: 'HJV', id: 'HJV'},
{value: 'HK1', id: 'HK1'},
{value: 'HK2', id: 'HK2'},
{value: 'HK2-DT', id: 'HK2-DT'},
{value: 'HK2P1', id: 'HK2P1'},
{value: 'HK3', id: 'HK3'},
{value: 'HKDC1', id: 'HKDC1'},
{value: 'HLA-A', id: 'HLA-A'},
{value: 'HLA-B', id: 'HLA-B'},
{value: 'HLA-C', id: 'HLA-C'},
{value: 'HLA-DMA', id: 'HLA-DMA'},
{value: 'HLA-DMB', id: 'HLA-DMB'},
{value: 'HLA-DOA', id: 'HLA-DOA'},
{value: 'HLA-DOB', id: 'HLA-DOB'},
{value: 'HLA-DPA1', id: 'HLA-DPA1'},
{value: 'HLA-DPA2', id: 'HLA-DPA2'},
{value: 'HLA-DPA3', id: 'HLA-DPA3'},
{value: 'HLA-DPB1', id: 'HLA-DPB1'},
{value: 'HLA-DPB2', id: 'HLA-DPB2'},
{value: 'HLA-DQA1', id: 'HLA-DQA1'},
{value: 'HLA-DQA2', id: 'HLA-DQA2'},
{value: 'HLA-DQB1', id: 'HLA-DQB1'},
{value: 'HLA-DQB1-AS1', id: 'HLA-DQB1-AS1'},
{value: 'HLA-DQB2', id: 'HLA-DQB2'},
{value: 'HLA-DQB3', id: 'HLA-DQB3'},
{value: 'HLA-DRA', id: 'HLA-DRA'},
{value: 'HLA-DRB1', id: 'HLA-DRB1'},
{value: 'HLA-DRB3', id: 'HLA-DRB3'},
{value: 'HLA-DRB4', id: 'HLA-DRB4'},
{value: 'HLA-DRB5', id: 'HLA-DRB5'},
{value: 'HLA-DRB6', id: 'HLA-DRB6'},
{value: 'HLA-DRB9', id: 'HLA-DRB9'},
{value: 'HLA-E', id: 'HLA-E'},
{value: 'HLA-F', id: 'HLA-F'},
{value: 'HLA-F-AS1', id: 'HLA-F-AS1'},
{value: 'HLA-G', id: 'HLA-G'},
{value: 'HLA-H', id: 'HLA-H'},
{value: 'HLA-J', id: 'HLA-J'},
{value: 'HLA-K', id: 'HLA-K'},
{value: 'HLA-L', id: 'HLA-L'},
{value: 'HLA-N', id: 'HLA-N'},
{value: 'HLA-P', id: 'HLA-P'},
{value: 'HLA-S', id: 'HLA-S'},
{value: 'HLA-T', id: 'HLA-T'},
{value: 'HLA-U', id: 'HLA-U'},
{value: 'HLA-V', id: 'HLA-V'},
{value: 'HLA-W', id: 'HLA-W'},
{value: 'HLA-Z', id: 'HLA-Z'},
{value: 'HLCS', id: 'HLCS'},
{value: 'HLCS-IT1', id: 'HLCS-IT1'},
{value: 'HLF', id: 'HLF'},
{value: 'HLFP1', id: 'HLFP1'},
{value: 'HLTF', id: 'HLTF'},
{value: 'HLTF-AS1', id: 'HLTF-AS1'},
{value: 'HLX', id: 'HLX'},
{value: 'HLX-AS1', id: 'HLX-AS1'},
{value: 'HM13', id: 'HM13'},
{value: 'HM13-AS1', id: 'HM13-AS1'},
{value: 'HM13-IT1', id: 'HM13-IT1'},
{value: 'HMBOX1', id: 'HMBOX1'},
{value: 'HMBS', id: 'HMBS'},
{value: 'HMCES', id: 'HMCES'},
{value: 'HMCN1', id: 'HMCN1'},
{value: 'HMCN2', id: 'HMCN2'},
{value: 'HMG20A', id: 'HMG20A'},
{value: 'HMG20B', id: 'HMG20B'},
{value: 'HMGA1', id: 'HMGA1'},
{value: 'HMGA1P2', id: 'HMGA1P2'},
{value: 'HMGA1P3', id: 'HMGA1P3'},
{value: 'HMGA1P4', id: 'HMGA1P4'},
{value: 'HMGA1P5', id: 'HMGA1P5'},
{value: 'HMGA1P6', id: 'HMGA1P6'},
{value: 'HMGA1P7', id: 'HMGA1P7'},
{value: 'HMGA1P8', id: 'HMGA1P8'},
{value: 'HMGA2', id: 'HMGA2'},
{value: 'HMGA2-AS1', id: 'HMGA2-AS1'},
{value: 'HMGB1', id: 'HMGB1'},
{value: 'HMGB1P1', id: 'HMGB1P1'},
{value: 'HMGB1P10', id: 'HMGB1P10'},
{value: 'HMGB1P11', id: 'HMGB1P11'},
{value: 'HMGB1P13', id: 'HMGB1P13'},
{value: 'HMGB1P18', id: 'HMGB1P18'},
{value: 'HMGB1P20', id: 'HMGB1P20'},
{value: 'HMGB1P24', id: 'HMGB1P24'},
{value: 'HMGB1P25', id: 'HMGB1P25'},
{value: 'HMGB1P26', id: 'HMGB1P26'},
{value: 'HMGB1P27', id: 'HMGB1P27'},
{value: 'HMGB1P28', id: 'HMGB1P28'},
{value: 'HMGB1P29', id: 'HMGB1P29'},
{value: 'HMGB1P3', id: 'HMGB1P3'},
{value: 'HMGB1P35', id: 'HMGB1P35'},
{value: 'HMGB1P36', id: 'HMGB1P36'},
{value: 'HMGB1P37', id: 'HMGB1P37'},
{value: 'HMGB1P38', id: 'HMGB1P38'},
{value: 'HMGB1P40', id: 'HMGB1P40'},
{value: 'HMGB1P42', id: 'HMGB1P42'},
{value: 'HMGB1P45', id: 'HMGB1P45'},
{value: 'HMGB1P46', id: 'HMGB1P46'},
{value: 'HMGB1P47', id: 'HMGB1P47'},
{value: 'HMGB1P48', id: 'HMGB1P48'},
{value: 'HMGB1P5', id: 'HMGB1P5'},
{value: 'HMGB1P50', id: 'HMGB1P50'},
{value: 'HMGB1P6', id: 'HMGB1P6'},
{value: 'HMGB1P7', id: 'HMGB1P7'},
{value: 'HMGB1P8', id: 'HMGB1P8'},
{value: 'HMGB1P9', id: 'HMGB1P9'},
{value: 'HMGB2', id: 'HMGB2'},
{value: 'HMGB2P1', id: 'HMGB2P1'},
{value: 'HMGB3', id: 'HMGB3'},
{value: 'HMGB3P1', id: 'HMGB3P1'},
{value: 'HMGB3P10', id: 'HMGB3P10'},
{value: 'HMGB3P12', id: 'HMGB3P12'},
{value: 'HMGB3P13', id: 'HMGB3P13'},
{value: 'HMGB3P14', id: 'HMGB3P14'},
{value: 'HMGB3P15', id: 'HMGB3P15'},
{value: 'HMGB3P16', id: 'HMGB3P16'},
{value: 'HMGB3P17', id: 'HMGB3P17'},
{value: 'HMGB3P18', id: 'HMGB3P18'},
{value: 'HMGB3P19', id: 'HMGB3P19'},
{value: 'HMGB3P22', id: 'HMGB3P22'},
{value: 'HMGB3P23', id: 'HMGB3P23'},
{value: 'HMGB3P24', id: 'HMGB3P24'},
{value: 'HMGB3P26', id: 'HMGB3P26'},
{value: 'HMGB3P27', id: 'HMGB3P27'},
{value: 'HMGB3P3', id: 'HMGB3P3'},
{value: 'HMGB3P32', id: 'HMGB3P32'},
{value: 'HMGB3P4', id: 'HMGB3P4'},
{value: 'HMGB3P5', id: 'HMGB3P5'},
{value: 'HMGB3P6', id: 'HMGB3P6'},
{value: 'HMGB3P7', id: 'HMGB3P7'},
{value: 'HMGB3P8', id: 'HMGB3P8'},
{value: 'HMGCL', id: 'HMGCL'},
{value: 'HMGCLL1', id: 'HMGCLL1'},
{value: 'HMGCR', id: 'HMGCR'},
{value: 'HMGCS1', id: 'HMGCS1'},
{value: 'HMGCS2', id: 'HMGCS2'},
{value: 'HMGN1', id: 'HMGN1'},
{value: 'HMGN1P1', id: 'HMGN1P1'},
{value: 'HMGN1P10', id: 'HMGN1P10'},
{value: 'HMGN1P11', id: 'HMGN1P11'},
{value: 'HMGN1P15', id: 'HMGN1P15'},
{value: 'HMGN1P17', id: 'HMGN1P17'},
{value: 'HMGN1P18', id: 'HMGN1P18'},
{value: 'HMGN1P19', id: 'HMGN1P19'},
{value: 'HMGN1P2', id: 'HMGN1P2'},
{value: 'HMGN1P20', id: 'HMGN1P20'},
{value: 'HMGN1P24', id: 'HMGN1P24'},
{value: 'HMGN1P26', id: 'HMGN1P26'},
{value: 'HMGN1P28', id: 'HMGN1P28'},
{value: 'HMGN1P3', id: 'HMGN1P3'},
{value: 'HMGN1P30', id: 'HMGN1P30'},
{value: 'HMGN1P31', id: 'HMGN1P31'},
{value: 'HMGN1P32', id: 'HMGN1P32'},
{value: 'HMGN1P4', id: 'HMGN1P4'},
{value: 'HMGN1P5', id: 'HMGN1P5'},
{value: 'HMGN1P6', id: 'HMGN1P6'},
{value: 'HMGN1P7', id: 'HMGN1P7'},
{value: 'HMGN1P9', id: 'HMGN1P9'},
{value: 'HMGN2', id: 'HMGN2'},
{value: 'HMGN2P11', id: 'HMGN2P11'},
{value: 'HMGN2P13', id: 'HMGN2P13'},
{value: 'HMGN2P15', id: 'HMGN2P15'},
{value: 'HMGN2P16', id: 'HMGN2P16'},
{value: 'HMGN2P17', id: 'HMGN2P17'},
{value: 'HMGN2P18', id: 'HMGN2P18'},
{value: 'HMGN2P19', id: 'HMGN2P19'},
{value: 'HMGN2P20', id: 'HMGN2P20'},
{value: 'HMGN2P21', id: 'HMGN2P21'},
{value: 'HMGN2P22', id: 'HMGN2P22'},
{value: 'HMGN2P23', id: 'HMGN2P23'},
{value: 'HMGN2P24', id: 'HMGN2P24'},
{value: 'HMGN2P25', id: 'HMGN2P25'},
{value: 'HMGN2P26', id: 'HMGN2P26'},
{value: 'HMGN2P3', id: 'HMGN2P3'},
{value: 'HMGN2P30', id: 'HMGN2P30'},
{value: 'HMGN2P31', id: 'HMGN2P31'},
{value: 'HMGN2P34', id: 'HMGN2P34'},
{value: 'HMGN2P35', id: 'HMGN2P35'},
{value: 'HMGN2P36', id: 'HMGN2P36'},
{value: 'HMGN2P39', id: 'HMGN2P39'},
{value: 'HMGN2P41', id: 'HMGN2P41'},
{value: 'HMGN2P46', id: 'HMGN2P46'},
{value: 'HMGN2P47', id: 'HMGN2P47'},
{value: 'HMGN2P5', id: 'HMGN2P5'},
{value: 'HMGN2P6', id: 'HMGN2P6'},
{value: 'HMGN2P7', id: 'HMGN2P7'},
{value: 'HMGN2P8', id: 'HMGN2P8'},
{value: 'HMGN3', id: 'HMGN3'},
{value: 'HMGN3-AS1', id: 'HMGN3-AS1'},
{value: 'HMGN3P1', id: 'HMGN3P1'},
{value: 'HMGN4', id: 'HMGN4'},
{value: 'HMGN5', id: 'HMGN5'},
{value: 'HMGXB3', id: 'HMGXB3'},
{value: 'HMGXB4', id: 'HMGXB4'},
{value: 'HMHB1', id: 'HMHB1'},
{value: 'HMMR', id: 'HMMR'},
{value: 'HMMR-AS1', id: 'HMMR-AS1'},
{value: 'HMOX1', id: 'HMOX1'},
{value: 'HMOX2', id: 'HMOX2'},
{value: 'HMSD', id: 'HMSD'},
{value: 'HMX1', id: 'HMX1'},
{value: 'HMX2', id: 'HMX2'},
{value: 'HMX3', id: 'HMX3'},
{value: 'HNF1A', id: 'HNF1A'},
{value: 'HNF1A-AS1', id: 'HNF1A-AS1'},
{value: 'HNF1B', id: 'HNF1B'},
{value: 'HNF4A', id: 'HNF4A'},
{value: 'HNF4G', id: 'HNF4G'},
{value: 'HNF4GP1', id: 'HNF4GP1'},
{value: 'HNMT', id: 'HNMT'},
{value: 'HNRNPA0', id: 'HNRNPA0'},
{value: 'HNRNPA1', id: 'HNRNPA1'},
{value: 'HNRNPA1L2', id: 'HNRNPA1L2'},
{value: 'HNRNPA1P1', id: 'HNRNPA1P1'},
{value: 'HNRNPA1P10', id: 'HNRNPA1P10'},
{value: 'HNRNPA1P11', id: 'HNRNPA1P11'},
{value: 'HNRNPA1P12', id: 'HNRNPA1P12'},
{value: 'HNRNPA1P13', id: 'HNRNPA1P13'},
{value: 'HNRNPA1P16', id: 'HNRNPA1P16'},
{value: 'HNRNPA1P18', id: 'HNRNPA1P18'},
{value: 'HNRNPA1P19', id: 'HNRNPA1P19'},
{value: 'HNRNPA1P21', id: 'HNRNPA1P21'},
{value: 'HNRNPA1P23', id: 'HNRNPA1P23'},
{value: 'HNRNPA1P3', id: 'HNRNPA1P3'},
{value: 'HNRNPA1P30', id: 'HNRNPA1P30'},
{value: 'HNRNPA1P31', id: 'HNRNPA1P31'},
{value: 'HNRNPA1P32', id: 'HNRNPA1P32'},
{value: 'HNRNPA1P34', id: 'HNRNPA1P34'},
{value: 'HNRNPA1P35', id: 'HNRNPA1P35'},
{value: 'HNRNPA1P39', id: 'HNRNPA1P39'},
{value: 'HNRNPA1P41', id: 'HNRNPA1P41'},
{value: 'HNRNPA1P43', id: 'HNRNPA1P43'},
{value: 'HNRNPA1P47', id: 'HNRNPA1P47'},
{value: 'HNRNPA1P48', id: 'HNRNPA1P48'},
{value: 'HNRNPA1P49', id: 'HNRNPA1P49'},
{value: 'HNRNPA1P51', id: 'HNRNPA1P51'},
{value: 'HNRNPA1P52', id: 'HNRNPA1P52'},
{value: 'HNRNPA1P55', id: 'HNRNPA1P55'},
{value: 'HNRNPA1P56', id: 'HNRNPA1P56'},
{value: 'HNRNPA1P57', id: 'HNRNPA1P57'},
{value: 'HNRNPA1P58', id: 'HNRNPA1P58'},
{value: 'HNRNPA1P59', id: 'HNRNPA1P59'},
{value: 'HNRNPA1P60', id: 'HNRNPA1P60'},
{value: 'HNRNPA1P61', id: 'HNRNPA1P61'},
{value: 'HNRNPA1P63', id: 'HNRNPA1P63'},
{value: 'HNRNPA1P64', id: 'HNRNPA1P64'},
{value: 'HNRNPA1P65', id: 'HNRNPA1P65'},
{value: 'HNRNPA1P66', id: 'HNRNPA1P66'},
{value: 'HNRNPA1P67', id: 'HNRNPA1P67'},
{value: 'HNRNPA1P68', id: 'HNRNPA1P68'},
{value: 'HNRNPA1P69', id: 'HNRNPA1P69'},
{value: 'HNRNPA1P7', id: 'HNRNPA1P7'},
{value: 'HNRNPA1P70', id: 'HNRNPA1P70'},
{value: 'HNRNPA1P71', id: 'HNRNPA1P71'},
{value: 'HNRNPA1P73', id: 'HNRNPA1P73'},
{value: 'HNRNPA1P74', id: 'HNRNPA1P74'},
{value: 'HNRNPA1P76', id: 'HNRNPA1P76'},
{value: 'HNRNPA1P8', id: 'HNRNPA1P8'},
{value: 'HNRNPA1P9', id: 'HNRNPA1P9'},
{value: 'HNRNPA2B1', id: 'HNRNPA2B1'},
{value: 'HNRNPA3', id: 'HNRNPA3'},
{value: 'HNRNPA3P1', id: 'HNRNPA3P1'},
{value: 'HNRNPA3P12', id: 'HNRNPA3P12'},
{value: 'HNRNPA3P13', id: 'HNRNPA3P13'},
{value: 'HNRNPA3P15', id: 'HNRNPA3P15'},
{value: 'HNRNPA3P16', id: 'HNRNPA3P16'},
{value: 'HNRNPA3P2', id: 'HNRNPA3P2'},
{value: 'HNRNPA3P4', id: 'HNRNPA3P4'},
{value: 'HNRNPA3P5', id: 'HNRNPA3P5'},
{value: 'HNRNPA3P6', id: 'HNRNPA3P6'},
{value: 'HNRNPA3P8', id: 'HNRNPA3P8'},
{value: 'HNRNPA3P9', id: 'HNRNPA3P9'},
{value: 'HNRNPAB', id: 'HNRNPAB'},
{value: 'HNRNPABP1', id: 'HNRNPABP1'},
{value: 'HNRNPC', id: 'HNRNPC'},
{value: 'HNRNPCL1', id: 'HNRNPCL1'},
{value: 'HNRNPCL2', id: 'HNRNPCL2'},
{value: 'HNRNPCL4', id: 'HNRNPCL4'},
{value: 'HNRNPCP1', id: 'HNRNPCP1'},
{value: 'HNRNPCP2', id: 'HNRNPCP2'},
{value: 'HNRNPCP4', id: 'HNRNPCP4'},
{value: 'HNRNPCP7', id: 'HNRNPCP7'},
{value: 'HNRNPCP8', id: 'HNRNPCP8'},
{value: 'HNRNPCP9', id: 'HNRNPCP9'},
{value: 'HNRNPD', id: 'HNRNPD'},
{value: 'HNRNPD-DT', id: 'HNRNPD-DT'},
{value: 'HNRNPDL', id: 'HNRNPDL'},
{value: 'HNRNPF', id: 'HNRNPF'},
{value: 'HNRNPFP1', id: 'HNRNPFP1'},
{value: 'HNRNPH1', id: 'HNRNPH1'},
{value: 'HNRNPH1P1', id: 'HNRNPH1P1'},
{value: 'HNRNPH1P3', id: 'HNRNPH1P3'},
{value: 'HNRNPH2', id: 'HNRNPH2'},
{value: 'HNRNPH3', id: 'HNRNPH3'},
{value: 'HNRNPK', id: 'HNRNPK'},
{value: 'HNRNPKP1', id: 'HNRNPKP1'},
{value: 'HNRNPKP2', id: 'HNRNPKP2'},
{value: 'HNRNPKP3', id: 'HNRNPKP3'},
{value: 'HNRNPKP4', id: 'HNRNPKP4'},
{value: 'HNRNPKP5', id: 'HNRNPKP5'},
{value: 'HNRNPL', id: 'HNRNPL'},
{value: 'HNRNPLL', id: 'HNRNPLL'},
{value: 'HNRNPLP1', id: 'HNRNPLP1'},
{value: 'HNRNPLP2', id: 'HNRNPLP2'},
{value: 'HNRNPM', id: 'HNRNPM'},
{value: 'HNRNPMP1', id: 'HNRNPMP1'},
{value: 'HNRNPMP2', id: 'HNRNPMP2'},
{value: 'HNRNPR', id: 'HNRNPR'},
{value: 'HNRNPRP1', id: 'HNRNPRP1'},
{value: 'HNRNPRP2', id: 'HNRNPRP2'},
{value: 'HNRNPU', id: 'HNRNPU'},
{value: 'HNRNPUL1', id: 'HNRNPUL1'},
{value: 'HNRNPUL2', id: 'HNRNPUL2'},
{value: 'HNRNPUL2-BSCL2', id: 'HNRNPUL2-BSCL2'},
{value: 'HNRNPUP1', id: 'HNRNPUP1'},
{value: 'HOATZ', id: 'HOATZ'},
{value: 'HOGA1', id: 'HOGA1'},
{value: 'HOMER1', id: 'HOMER1'},
{value: 'HOMER2', id: 'HOMER2'},
{value: 'HOMER2P1', id: 'HOMER2P1'},
{value: 'HOMER2P2', id: 'HOMER2P2'},
{value: 'HOMER3', id: 'HOMER3'},
{value: 'HOMER3-AS1', id: 'HOMER3-AS1'},
{value: 'HOMEZ', id: 'HOMEZ'},
{value: 'HOOK1', id: 'HOOK1'},
{value: 'HOOK2', id: 'HOOK2'},
{value: 'HOOK3', id: 'HOOK3'},
{value: 'HOPX', id: 'HOPX'},
{value: 'HORMAD1', id: 'HORMAD1'},
{value: 'HORMAD2', id: 'HORMAD2'},
{value: 'HORMAD2-AS1', id: 'HORMAD2-AS1'},
{value: 'HOTAIR', id: 'HOTAIR'},
{value: 'HOTAIRM1', id: 'HOTAIRM1'},
{value: 'HOTTIP', id: 'HOTTIP'},
{value: 'HOXA-AS2', id: 'HOXA-AS2'},
{value: 'HOXA-AS3', id: 'HOXA-AS3'},
{value: 'HOXA1', id: 'HOXA1'},
{value: 'HOXA10', id: 'HOXA10'},
{value: 'HOXA10-AS', id: 'HOXA10-AS'},
{value: 'HOXA11', id: 'HOXA11'},
{value: 'HOXA11-AS', id: 'HOXA11-AS'},
{value: 'HOXA13', id: 'HOXA13'},
{value: 'HOXA2', id: 'HOXA2'},
{value: 'HOXA3', id: 'HOXA3'},
{value: 'HOXA4', id: 'HOXA4'},
{value: 'HOXA5', id: 'HOXA5'},
{value: 'HOXA6', id: 'HOXA6'},
{value: 'HOXA7', id: 'HOXA7'},
{value: 'HOXA9', id: 'HOXA9'},
{value: 'HOXB-AS1', id: 'HOXB-AS1'},
{value: 'HOXB-AS2', id: 'HOXB-AS2'},
{value: 'HOXB-AS3', id: 'HOXB-AS3'},
{value: 'HOXB-AS4', id: 'HOXB-AS4'},
{value: 'HOXB1', id: 'HOXB1'},
{value: 'HOXB13', id: 'HOXB13'},
{value: 'HOXB2', id: 'HOXB2'},
{value: 'HOXB3', id: 'HOXB3'},
{value: 'HOXB4', id: 'HOXB4'},
{value: 'HOXB5', id: 'HOXB5'},
{value: 'HOXB6', id: 'HOXB6'},
{value: 'HOXB7', id: 'HOXB7'},
{value: 'HOXB8', id: 'HOXB8'},
{value: 'HOXB9', id: 'HOXB9'},
{value: 'HOXC-AS1', id: 'HOXC-AS1'},
{value: 'HOXC-AS2', id: 'HOXC-AS2'},
{value: 'HOXC-AS3', id: 'HOXC-AS3'},
{value: 'HOXC10', id: 'HOXC10'},
{value: 'HOXC11', id: 'HOXC11'},
{value: 'HOXC12', id: 'HOXC12'},
{value: 'HOXC13', id: 'HOXC13'},
{value: 'HOXC13-AS', id: 'HOXC13-AS'},
{value: 'HOXC4', id: 'HOXC4'},
{value: 'HOXC5', id: 'HOXC5'},
{value: 'HOXC6', id: 'HOXC6'},
{value: 'HOXC8', id: 'HOXC8'},
{value: 'HOXC9', id: 'HOXC9'},
{value: 'HOXD-AS2', id: 'HOXD-AS2'},
{value: 'HOXD1', id: 'HOXD1'},
{value: 'HOXD10', id: 'HOXD10'},
{value: 'HOXD11', id: 'HOXD11'},
{value: 'HOXD12', id: 'HOXD12'},
{value: 'HOXD13', id: 'HOXD13'},
{value: 'HOXD3', id: 'HOXD3'},
{value: 'HOXD4', id: 'HOXD4'},
{value: 'HOXD8', id: 'HOXD8'},
{value: 'HOXD9', id: 'HOXD9'},
{value: 'HP', id: 'HP'},
{value: 'HP1BP3', id: 'HP1BP3'},
{value: 'HPAT5', id: 'HPAT5'},
{value: 'HPCA', id: 'HPCA'},
{value: 'HPCAL1', id: 'HPCAL1'},
{value: 'HPCAL4', id: 'HPCAL4'},
{value: 'HPD', id: 'HPD'},
{value: 'HPDL', id: 'HPDL'},
{value: 'HPF1', id: 'HPF1'},
{value: 'HPGD', id: 'HPGD'},
{value: 'HPGDS', id: 'HPGDS'},
{value: 'HPN', id: 'HPN'},
{value: 'HPN-AS1', id: 'HPN-AS1'},
{value: 'HPR', id: 'HPR'},
{value: 'HPRT1', id: 'HPRT1'},
{value: 'HPRT1P1', id: 'HPRT1P1'},
{value: 'HPRT1P2', id: 'HPRT1P2'},
{value: 'HPRT1P3', id: 'HPRT1P3'},
{value: 'HPS1', id: 'HPS1'},
{value: 'HPS3', id: 'HPS3'},
{value: 'HPS4', id: 'HPS4'},
{value: 'HPS5', id: 'HPS5'},
{value: 'HPS6', id: 'HPS6'},
{value: 'HPSE', id: 'HPSE'},
{value: 'HPSE2', id: 'HPSE2'},
{value: 'HPX', id: 'HPX'},
{value: 'HPYR1', id: 'HPYR1'},
{value: 'HR', id: 'HR'},
{value: 'HRAS', id: 'HRAS'},
{value: 'HRAT17', id: 'HRAT17'},
{value: 'HRAT92', id: 'HRAT92'},
{value: 'HRC', id: 'HRC'},
{value: 'HRCT1', id: 'HRCT1'},
{value: 'HRG', id: 'HRG'},
{value: 'HRH1', id: 'HRH1'},
{value: 'HRH2', id: 'HRH2'},
{value: 'HRH3', id: 'HRH3'},
{value: 'HRH4', id: 'HRH4'},
{value: 'HRK', id: 'HRK'},
{value: 'HRNR', id: 'HRNR'},
{value: 'HROB', id: 'HROB'},
{value: 'HRURF', id: 'HRURF'},
{value: 'HS1BP3', id: 'HS1BP3'},
{value: 'HS2ST1', id: 'HS2ST1'},
{value: 'HS3ST1', id: 'HS3ST1'},
{value: 'HS3ST2', id: 'HS3ST2'},
{value: 'HS3ST3A1', id: 'HS3ST3A1'},
{value: 'HS3ST3B1', id: 'HS3ST3B1'},
{value: 'HS3ST4', id: 'HS3ST4'},
{value: 'HS3ST5', id: 'HS3ST5'},
{value: 'HS3ST6', id: 'HS3ST6'},
{value: 'HS6ST1', id: 'HS6ST1'},
{value: 'HS6ST1P1', id: 'HS6ST1P1'},
{value: 'HS6ST2', id: 'HS6ST2'},
{value: 'HS6ST3', id: 'HS6ST3'},
{value: 'HSBP1', id: 'HSBP1'},
{value: 'HSBP1L1', id: 'HSBP1L1'},
{value: 'HSBP1P2', id: 'HSBP1P2'},
{value: 'HSCB', id: 'HSCB'},
{value: 'HSD11B1', id: 'HSD11B1'},
{value: 'HSD11B1-AS1', id: 'HSD11B1-AS1'},
{value: 'HSD11B1L', id: 'HSD11B1L'},
{value: 'HSD11B2', id: 'HSD11B2'},
{value: 'HSD17B1', id: 'HSD17B1'},
{value: 'HSD17B1-AS1', id: 'HSD17B1-AS1'},
{value: 'HSD17B10', id: 'HSD17B10'},
{value: 'HSD17B11', id: 'HSD17B11'},
{value: 'HSD17B12', id: 'HSD17B12'},
{value: 'HSD17B13', id: 'HSD17B13'},
{value: 'HSD17B14', id: 'HSD17B14'},
{value: 'HSD17B1P1', id: 'HSD17B1P1'},
{value: 'HSD17B2', id: 'HSD17B2'},
{value: 'HSD17B3', id: 'HSD17B3'},
{value: 'HSD17B3-AS1', id: 'HSD17B3-AS1'},
{value: 'HSD17B4', id: 'HSD17B4'},
{value: 'HSD17B6', id: 'HSD17B6'},
{value: 'HSD17B7', id: 'HSD17B7'},
{value: 'HSD17B7P1', id: 'HSD17B7P1'},
{value: 'HSD17B7P2', id: 'HSD17B7P2'},
{value: 'HSD17B8', id: 'HSD17B8'},
{value: 'HSD3B1', id: 'HSD3B1'},
{value: 'HSD3B2', id: 'HSD3B2'},
{value: 'HSD3B7', id: 'HSD3B7'},
{value: 'HSD3BP1', id: 'HSD3BP1'},
{value: 'HSD3BP2', id: 'HSD3BP2'},
{value: 'HSD3BP3', id: 'HSD3BP3'},
{value: 'HSD3BP4', id: 'HSD3BP4'},
{value: 'HSD3BP5', id: 'HSD3BP5'},
{value: 'HSDL1', id: 'HSDL1'},
{value: 'HSDL2', id: 'HSDL2'},
{value: 'HSDL2-AS1', id: 'HSDL2-AS1'},
{value: 'HSF1', id: 'HSF1'},
{value: 'HSF2', id: 'HSF2'},
{value: 'HSF2BP', id: 'HSF2BP'},
{value: 'HSF4', id: 'HSF4'},
{value: 'HSF5', id: 'HSF5'},
{value: 'HSFX1', id: 'HSFX1'},
{value: 'HSFX2', id: 'HSFX2'},
{value: 'HSFY1P1', id: 'HSFY1P1'},
{value: 'HSH2D', id: 'HSH2D'},
{value: 'HSP90AA1', id: 'HSP90AA1'},
{value: 'HSP90AA2P', id: 'HSP90AA2P'},
{value: 'HSP90AA3P', id: 'HSP90AA3P'},
{value: 'HSP90AA4P', id: 'HSP90AA4P'},
{value: 'HSP90AA5P', id: 'HSP90AA5P'},
{value: 'HSP90AA6P', id: 'HSP90AA6P'},
{value: 'HSP90AB1', id: 'HSP90AB1'},
{value: 'HSP90AB2P', id: 'HSP90AB2P'},
{value: 'HSP90AB3P', id: 'HSP90AB3P'},
{value: 'HSP90AB4P', id: 'HSP90AB4P'},
{value: 'HSP90AB6P', id: 'HSP90AB6P'},
{value: 'HSP90AB7P', id: 'HSP90AB7P'},
{value: 'HSP90B1', id: 'HSP90B1'},
{value: 'HSP90B2P', id: 'HSP90B2P'},
{value: 'HSP90B3P', id: 'HSP90B3P'},
{value: 'HSPA12A', id: 'HSPA12A'},
{value: 'HSPA12B', id: 'HSPA12B'},
{value: 'HSPA13', id: 'HSPA13'},
{value: 'HSPA14', id: 'HSPA14'},
{value: 'HSPA1A', id: 'HSPA1A'},
{value: 'HSPA1B', id: 'HSPA1B'},
{value: 'HSPA1L', id: 'HSPA1L'},
{value: 'HSPA2', id: 'HSPA2'},
{value: 'HSPA2-AS1', id: 'HSPA2-AS1'},
{value: 'HSPA4', id: 'HSPA4'},
{value: 'HSPA4L', id: 'HSPA4L'},
{value: 'HSPA5', id: 'HSPA5'},
{value: 'HSPA5P1', id: 'HSPA5P1'},
{value: 'HSPA6', id: 'HSPA6'},
{value: 'HSPA7', id: 'HSPA7'},
{value: 'HSPA8', id: 'HSPA8'},
{value: 'HSPA8P11', id: 'HSPA8P11'},
{value: 'HSPA8P13', id: 'HSPA8P13'},
{value: 'HSPA8P15', id: 'HSPA8P15'},
{value: 'HSPA8P17', id: 'HSPA8P17'},
{value: 'HSPA8P18', id: 'HSPA8P18'},
{value: 'HSPA8P4', id: 'HSPA8P4'},
{value: 'HSPA8P5', id: 'HSPA8P5'},
{value: 'HSPA8P6', id: 'HSPA8P6'},
{value: 'HSPA8P8', id: 'HSPA8P8'},
{value: 'HSPA8P9', id: 'HSPA8P9'},
{value: 'HSPA9', id: 'HSPA9'},
{value: 'HSPA9P1', id: 'HSPA9P1'},
{value: 'HSPB1', id: 'HSPB1'},
{value: 'HSPB11', id: 'HSPB11'},
{value: 'HSPB1P1', id: 'HSPB1P1'},
{value: 'HSPB2', id: 'HSPB2'},
{value: 'HSPB2-C11orf52', id: 'HSPB2-C11orf52'},
{value: 'HSPB3', id: 'HSPB3'},
{value: 'HSPB6', id: 'HSPB6'},
{value: 'HSPB7', id: 'HSPB7'},
{value: 'HSPB8', id: 'HSPB8'},
{value: 'HSPB9', id: 'HSPB9'},
{value: 'HSPBAP1', id: 'HSPBAP1'},
{value: 'HSPBP1', id: 'HSPBP1'},
{value: 'HSPD1', id: 'HSPD1'},
{value: 'HSPD1P10', id: 'HSPD1P10'},
{value: 'HSPD1P11', id: 'HSPD1P11'},
{value: 'HSPD1P12', id: 'HSPD1P12'},
{value: 'HSPD1P13', id: 'HSPD1P13'},
{value: 'HSPD1P14', id: 'HSPD1P14'},
{value: 'HSPD1P15', id: 'HSPD1P15'},
{value: 'HSPD1P16', id: 'HSPD1P16'},
{value: 'HSPD1P18', id: 'HSPD1P18'},
{value: 'HSPD1P2', id: 'HSPD1P2'},
{value: 'HSPD1P3', id: 'HSPD1P3'},
{value: 'HSPD1P4', id: 'HSPD1P4'},
{value: 'HSPD1P5', id: 'HSPD1P5'},
{value: 'HSPD1P6', id: 'HSPD1P6'},
{value: 'HSPD1P7', id: 'HSPD1P7'},
{value: 'HSPD1P8', id: 'HSPD1P8'},
{value: 'HSPE1', id: 'HSPE1'},
{value: 'HSPE1-MOB4', id: 'HSPE1-MOB4'},
{value: 'HSPE1P1', id: 'HSPE1P1'},
{value: 'HSPE1P10', id: 'HSPE1P10'},
{value: 'HSPE1P12', id: 'HSPE1P12'},
{value: 'HSPE1P14', id: 'HSPE1P14'},
{value: 'HSPE1P16', id: 'HSPE1P16'},
{value: 'HSPE1P18', id: 'HSPE1P18'},
{value: 'HSPE1P19', id: 'HSPE1P19'},
{value: 'HSPE1P20', id: 'HSPE1P20'},
{value: 'HSPE1P21', id: 'HSPE1P21'},
{value: 'HSPE1P23', id: 'HSPE1P23'},
{value: 'HSPE1P24', id: 'HSPE1P24'},
{value: 'HSPE1P3', id: 'HSPE1P3'},
{value: 'HSPE1P4', id: 'HSPE1P4'},
{value: 'HSPE1P6', id: 'HSPE1P6'},
{value: 'HSPE1P7', id: 'HSPE1P7'},
{value: 'HSPE1P9', id: 'HSPE1P9'},
{value: 'HSPG2', id: 'HSPG2'},
{value: 'HSPH1', id: 'HSPH1'},
{value: 'HTATIP2', id: 'HTATIP2'},
{value: 'HTATSF1', id: 'HTATSF1'},
{value: 'HTATSF1P1', id: 'HTATSF1P1'},
{value: 'HTATSF1P2', id: 'HTATSF1P2'},
{value: 'HTD2', id: 'HTD2'},
{value: 'HTN1', id: 'HTN1'},
{value: 'HTN3', id: 'HTN3'},
{value: 'HTR1A', id: 'HTR1A'},
{value: 'HTR1B', id: 'HTR1B'},
{value: 'HTR1D', id: 'HTR1D'},
{value: 'HTR1DP1', id: 'HTR1DP1'},
{value: 'HTR1E', id: 'HTR1E'},
{value: 'HTR1F', id: 'HTR1F'},
{value: 'HTR2A', id: 'HTR2A'},
{value: 'HTR2A-AS1', id: 'HTR2A-AS1'},
{value: 'HTR2B', id: 'HTR2B'},
{value: 'HTR3A', id: 'HTR3A'},
{value: 'HTR3B', id: 'HTR3B'},
{value: 'HTR3C', id: 'HTR3C'},
{value: 'HTR3C2P', id: 'HTR3C2P'},
{value: 'HTR3D', id: 'HTR3D'},
{value: 'HTR3E', id: 'HTR3E'},
{value: 'HTR3E-AS1', id: 'HTR3E-AS1'},
{value: 'HTR4', id: 'HTR4'},
{value: 'HTR5A', id: 'HTR5A'},
{value: 'HTR5A-AS1', id: 'HTR5A-AS1'},
{value: 'HTR5BP', id: 'HTR5BP'},
{value: 'HTR6', id: 'HTR6'},
{value: 'HTR7', id: 'HTR7'},
{value: 'HTR7P1', id: 'HTR7P1'},
{value: 'HTRA1', id: 'HTRA1'},
{value: 'HTRA2', id: 'HTRA2'},
{value: 'HTRA3', id: 'HTRA3'},
{value: 'HTRA4', id: 'HTRA4'},
{value: 'HTT', id: 'HTT'},
{value: 'HTT-AS', id: 'HTT-AS'},
{value: 'HULC', id: 'HULC'},
{value: 'HUNK', id: 'HUNK'},
{value: 'HUNK-AS1', id: 'HUNK-AS1'},
{value: 'HUS1', id: 'HUS1'},
{value: 'HUS1B', id: 'HUS1B'},
{value: 'HUWE1', id: 'HUWE1'},
{value: 'HVCN1', id: 'HVCN1'},
{value: 'HYAL1', id: 'HYAL1'},
{value: 'HYAL2', id: 'HYAL2'},
{value: 'HYAL3', id: 'HYAL3'},
{value: 'HYAL4', id: 'HYAL4'},
{value: 'HYAL6P', id: 'HYAL6P'},
{value: 'HYDIN', id: 'HYDIN'},
{value: 'HYDIN2', id: 'HYDIN2'},
{value: 'HYI', id: 'HYI'},
{value: 'HYI-AS1', id: 'HYI-AS1'},
{value: 'HYKK', id: 'HYKK'},
{value: 'HYLS1', id: 'HYLS1'},
{value: 'HYMAI', id: 'HYMAI'},
{value: 'HYOU1', id: 'HYOU1'},
{value: 'HYPK', id: 'HYPK'},
{value: 'IAH1', id: 'IAH1'},
{value: 'IAPP', id: 'IAPP'},
{value: 'IARS1', id: 'IARS1'},
{value: 'IARS2', id: 'IARS2'},
{value: 'IARS2P1', id: 'IARS2P1'},
{value: 'IATPR', id: 'IATPR'},
{value: 'IBA57', id: 'IBA57'},
{value: 'IBA57-DT', id: 'IBA57-DT'},
{value: 'IBSP', id: 'IBSP'},
{value: 'IBTK', id: 'IBTK'},
{value: 'ICA1', id: 'ICA1'},
{value: 'ICA1L', id: 'ICA1L'},
{value: 'ICAM1', id: 'ICAM1'},
{value: 'ICAM2', id: 'ICAM2'},
{value: 'ICAM3', id: 'ICAM3'},
{value: 'ICAM4', id: 'ICAM4'},
{value: 'ICAM5', id: 'ICAM5'},
{value: 'ICE1', id: 'ICE1'},
{value: 'ICE2', id: 'ICE2'},
{value: 'ICE2P1', id: 'ICE2P1'},
{value: 'ICE2P2', id: 'ICE2P2'},
{value: 'ICMT', id: 'ICMT'},
{value: 'ICOS', id: 'ICOS'},
{value: 'ICOSLG', id: 'ICOSLG'},
{value: 'ID1', id: 'ID1'},
{value: 'ID2', id: 'ID2'},
{value: 'ID2-AS1', id: 'ID2-AS1'},
{value: 'ID2B', id: 'ID2B'},
{value: 'ID3', id: 'ID3'},
{value: 'ID4', id: 'ID4'},
{value: 'IDE', id: 'IDE'},
{value: 'IDH1', id: 'IDH1'},
{value: 'IDH1-AS1', id: 'IDH1-AS1'},
{value: 'IDH2', id: 'IDH2'},
{value: 'IDH2-DT', id: 'IDH2-DT'},
{value: 'IDH3A', id: 'IDH3A'},
{value: 'IDH3B', id: 'IDH3B'},
{value: 'IDH3G', id: 'IDH3G'},
{value: 'IDI1', id: 'IDI1'},
{value: 'IDI1P1', id: 'IDI1P1'},
{value: 'IDI1P2', id: 'IDI1P2'},
{value: 'IDI1P3', id: 'IDI1P3'},
{value: 'IDI2', id: 'IDI2'},
{value: 'IDI2-AS1', id: 'IDI2-AS1'},
{value: 'IDNK', id: 'IDNK'},
{value: 'IDO1', id: 'IDO1'},
{value: 'IDO2', id: 'IDO2'},
{value: 'IDS', id: 'IDS'},
{value: 'IDSP1', id: 'IDSP1'},
{value: 'IDUA', id: 'IDUA'},
{value: 'IER2', id: 'IER2'},
{value: 'IER3', id: 'IER3'},
{value: 'IER3-AS1', id: 'IER3-AS1'},
{value: 'IER3IP1', id: 'IER3IP1'},
{value: 'IER5', id: 'IER5'},
{value: 'IER5L', id: 'IER5L'},
{value: 'IFFO1', id: 'IFFO1'},
{value: 'IFFO2', id: 'IFFO2'},
{value: 'IFI16', id: 'IFI16'},
{value: 'IFI27', id: 'IFI27'},
{value: 'IFI27L1', id: 'IFI27L1'},
{value: 'IFI27L2', id: 'IFI27L2'},
{value: 'IFI30', id: 'IFI30'},
{value: 'IFI35', id: 'IFI35'},
{value: 'IFI44', id: 'IFI44'},
{value: 'IFI44L', id: 'IFI44L'},
{value: 'IFI6', id: 'IFI6'},
{value: 'IFIH1', id: 'IFIH1'},
{value: 'IFIT1', id: 'IFIT1'},
{value: 'IFIT1B', id: 'IFIT1B'},
{value: 'IFIT1P1', id: 'IFIT1P1'},
{value: 'IFIT2', id: 'IFIT2'},
{value: 'IFIT3', id: 'IFIT3'},
{value: 'IFIT5', id: 'IFIT5'},
{value: 'IFITM1', id: 'IFITM1'},
{value: 'IFITM10', id: 'IFITM10'},
{value: 'IFITM2', id: 'IFITM2'},
{value: 'IFITM3', id: 'IFITM3'},
{value: 'IFITM3P1', id: 'IFITM3P1'},
{value: 'IFITM3P2', id: 'IFITM3P2'},
{value: 'IFITM3P3', id: 'IFITM3P3'},
{value: 'IFITM3P4', id: 'IFITM3P4'},
{value: 'IFITM3P5', id: 'IFITM3P5'},
{value: 'IFITM3P6', id: 'IFITM3P6'},
{value: 'IFITM3P7', id: 'IFITM3P7'},
{value: 'IFITM3P9', id: 'IFITM3P9'},
{value: 'IFITM5', id: 'IFITM5'},
{value: 'IFITM8P', id: 'IFITM8P'},
{value: 'IFNA1', id: 'IFNA1'},
{value: 'IFNA10', id: 'IFNA10'},
{value: 'IFNA12P', id: 'IFNA12P'},
{value: 'IFNA13', id: 'IFNA13'},
{value: 'IFNA14', id: 'IFNA14'},
{value: 'IFNA16', id: 'IFNA16'},
{value: 'IFNA17', id: 'IFNA17'},
{value: 'IFNA2', id: 'IFNA2'},
{value: 'IFNA20P', id: 'IFNA20P'},
{value: 'IFNA21', id: 'IFNA21'},
{value: 'IFNA22P', id: 'IFNA22P'},
{value: 'IFNA4', id: 'IFNA4'},
{value: 'IFNA5', id: 'IFNA5'},
{value: 'IFNA6', id: 'IFNA6'},
{value: 'IFNA7', id: 'IFNA7'},
{value: 'IFNA8', id: 'IFNA8'},
{value: 'IFNAR1', id: 'IFNAR1'},
{value: 'IFNAR2', id: 'IFNAR2'},
{value: 'IFNB1', id: 'IFNB1'},
{value: 'IFNG', id: 'IFNG'},
{value: 'IFNG-AS1', id: 'IFNG-AS1'},
{value: 'IFNGR1', id: 'IFNGR1'},
{value: 'IFNGR2', id: 'IFNGR2'},
{value: 'IFNL1', id: 'IFNL1'},
{value: 'IFNL2', id: 'IFNL2'},
{value: 'IFNL3', id: 'IFNL3'},
{value: 'IFNL3P1', id: 'IFNL3P1'},
{value: 'IFNL4', id: 'IFNL4'},
{value: 'IFNL4P1', id: 'IFNL4P1'},
{value: 'IFNLR1', id: 'IFNLR1'},
{value: 'IFNNP1', id: 'IFNNP1'},
{value: 'IFNW1', id: 'IFNW1'},
{value: 'IFNWP15', id: 'IFNWP15'},
{value: 'IFNWP18', id: 'IFNWP18'},
{value: 'IFNWP19', id: 'IFNWP19'},
{value: 'IFNWP2', id: 'IFNWP2'},
{value: 'IFNWP4', id: 'IFNWP4'},
{value: 'IFNWP5', id: 'IFNWP5'},
{value: 'IFNWP9', id: 'IFNWP9'},
{value: 'IFRD1', id: 'IFRD1'},
{value: 'IFRD2', id: 'IFRD2'},
{value: 'IFT122', id: 'IFT122'},
{value: 'IFT140', id: 'IFT140'},
{value: 'IFT172', id: 'IFT172'},
{value: 'IFT20', id: 'IFT20'},
{value: 'IFT22', id: 'IFT22'},
{value: 'IFT27', id: 'IFT27'},
{value: 'IFT43', id: 'IFT43'},
{value: 'IFT46', id: 'IFT46'},
{value: 'IFT52', id: 'IFT52'},
{value: 'IFT57', id: 'IFT57'},
{value: 'IFT74', id: 'IFT74'},
{value: 'IFT74-AS1', id: 'IFT74-AS1'},
{value: 'IFT80', id: 'IFT80'},
{value: 'IFT81', id: 'IFT81'},
{value: 'IFT88', id: 'IFT88'},
{value: 'IFTAP', id: 'IFTAP'},
{value: 'IGBP1', id: 'IGBP1'},
{value: 'IGBP1P1', id: 'IGBP1P1'},
{value: 'IGBP1P2', id: 'IGBP1P2'},
{value: 'IGBP1P3', id: 'IGBP1P3'},
{value: 'IGBP1P4', id: 'IGBP1P4'},
{value: 'IGBP1P5', id: 'IGBP1P5'},
{value: 'IGDCC3', id: 'IGDCC3'},
{value: 'IGDCC4', id: 'IGDCC4'},
{value: 'IGF1', id: 'IGF1'},
{value: 'IGF1R', id: 'IGF1R'},
{value: 'IGF2', id: 'IGF2'},
{value: 'IGF2-AS', id: 'IGF2-AS'},
{value: 'IGF2BP1', id: 'IGF2BP1'},
{value: 'IGF2BP2', id: 'IGF2BP2'},
{value: 'IGF2BP3', id: 'IGF2BP3'},
{value: 'IGF2R', id: 'IGF2R'},
{value: 'IGFALS', id: 'IGFALS'},
{value: 'IGFBP1', id: 'IGFBP1'},
{value: 'IGFBP2', id: 'IGFBP2'},
{value: 'IGFBP3', id: 'IGFBP3'},
{value: 'IGFBP4', id: 'IGFBP4'},
{value: 'IGFBP5', id: 'IGFBP5'},
{value: 'IGFBP6', id: 'IGFBP6'},
{value: 'IGFBP7', id: 'IGFBP7'},
{value: 'IGFBP7-AS1', id: 'IGFBP7-AS1'},
{value: 'IGFBPL1', id: 'IGFBPL1'},
{value: 'IGFL1', id: 'IGFL1'},
{value: 'IGFL1P1', id: 'IGFL1P1'},
{value: 'IGFL1P2', id: 'IGFL1P2'},
{value: 'IGFL2', id: 'IGFL2'},
{value: 'IGFL2-AS1', id: 'IGFL2-AS1'},
{value: 'IGFL3', id: 'IGFL3'},
{value: 'IGFL4', id: 'IGFL4'},
{value: 'IGFLR1', id: 'IGFLR1'},
{value: 'IGFN1', id: 'IGFN1'},
{value: 'IGHA1', id: 'IGHA1'},
{value: 'IGHA2', id: 'IGHA2'},
{value: 'IGHD', id: 'IGHD'},
{value: 'IGHD1-1', id: 'IGHD1-1'},
{value: 'IGHD1-14', id: 'IGHD1-14'},
{value: 'IGHD1-20', id: 'IGHD1-20'},
{value: 'IGHD1-7', id: 'IGHD1-7'},
{value: 'IGHD1OR15-1A', id: 'IGHD1OR15-1A'},
{value: 'IGHD1OR15-1B', id: 'IGHD1OR15-1B'},
{value: 'IGHD3-10', id: 'IGHD3-10'},
{value: 'IGHD3OR15-3A', id: 'IGHD3OR15-3A'},
{value: 'IGHD4OR15-4A', id: 'IGHD4OR15-4A'},
{value: 'IGHD4OR15-4B', id: 'IGHD4OR15-4B'},
{value: 'IGHD5OR15-5A', id: 'IGHD5OR15-5A'},
{value: 'IGHD5OR15-5B', id: 'IGHD5OR15-5B'},
{value: 'IGHE', id: 'IGHE'},
{value: 'IGHEP1', id: 'IGHEP1'},
{value: 'IGHEP2', id: 'IGHEP2'},
{value: 'IGHG1', id: 'IGHG1'},
{value: 'IGHG2', id: 'IGHG2'},
{value: 'IGHG3', id: 'IGHG3'},
{value: 'IGHG4', id: 'IGHG4'},
{value: 'IGHGP', id: 'IGHGP'},
{value: 'IGHJ1P', id: 'IGHJ1P'},
{value: 'IGHJ2', id: 'IGHJ2'},
{value: 'IGHJ3', id: 'IGHJ3'},
{value: 'IGHJ5', id: 'IGHJ5'},
{value: 'IGHJ6', id: 'IGHJ6'},
{value: 'IGHM', id: 'IGHM'},
{value: 'IGHMBP2', id: 'IGHMBP2'},
{value: 'IGHV1-14', id: 'IGHV1-14'},
{value: 'IGHV1-17', id: 'IGHV1-17'},
{value: 'IGHV1-18', id: 'IGHV1-18'},
{value: 'IGHV1-2', id: 'IGHV1-2'},
{value: 'IGHV1-24', id: 'IGHV1-24'},
{value: 'IGHV1-3', id: 'IGHV1-3'},
{value: 'IGHV1-45', id: 'IGHV1-45'},
{value: 'IGHV1-46', id: 'IGHV1-46'},
{value: 'IGHV1-58', id: 'IGHV1-58'},
{value: 'IGHV1-67', id: 'IGHV1-67'},
{value: 'IGHV1-68', id: 'IGHV1-68'},
{value: 'IGHV1-69', id: 'IGHV1-69'},
{value: 'IGHV1-69-2', id: 'IGHV1-69-2'},
{value: 'IGHV1-69D', id: 'IGHV1-69D'},
{value: 'IGHV1OR15-1', id: 'IGHV1OR15-1'},
{value: 'IGHV1OR15-2', id: 'IGHV1OR15-2'},
{value: 'IGHV1OR15-3', id: 'IGHV1OR15-3'},
{value: 'IGHV1OR15-4', id: 'IGHV1OR15-4'},
{value: 'IGHV1OR15-6', id: 'IGHV1OR15-6'},
{value: 'IGHV1OR15-9', id: 'IGHV1OR15-9'},
{value: 'IGHV1OR16-1', id: 'IGHV1OR16-1'},
{value: 'IGHV1OR16-2', id: 'IGHV1OR16-2'},
{value: 'IGHV1OR21-1', id: 'IGHV1OR21-1'},
{value: 'IGHV2-26', id: 'IGHV2-26'},
{value: 'IGHV2-5', id: 'IGHV2-5'},
{value: 'IGHV2-70', id: 'IGHV2-70'},
{value: 'IGHV2-70D', id: 'IGHV2-70D'},
{value: 'IGHV2OR16-5', id: 'IGHV2OR16-5'},
{value: 'IGHV3-11', id: 'IGHV3-11'},
{value: 'IGHV3-13', id: 'IGHV3-13'},
{value: 'IGHV3-15', id: 'IGHV3-15'},
{value: 'IGHV3-16', id: 'IGHV3-16'},
{value: 'IGHV3-19', id: 'IGHV3-19'},
{value: 'IGHV3-20', id: 'IGHV3-20'},
{value: 'IGHV3-21', id: 'IGHV3-21'},
{value: 'IGHV3-22', id: 'IGHV3-22'},
{value: 'IGHV3-23', id: 'IGHV3-23'},
{value: 'IGHV3-25', id: 'IGHV3-25'},
{value: 'IGHV3-29', id: 'IGHV3-29'},
{value: 'IGHV3-30', id: 'IGHV3-30'},
{value: 'IGHV3-30-2', id: 'IGHV3-30-2'},
{value: 'IGHV3-32', id: 'IGHV3-32'},
{value: 'IGHV3-33', id: 'IGHV3-33'},
{value: 'IGHV3-33-2', id: 'IGHV3-33-2'},
{value: 'IGHV3-35', id: 'IGHV3-35'},
{value: 'IGHV3-36', id: 'IGHV3-36'},
{value: 'IGHV3-37', id: 'IGHV3-37'},
{value: 'IGHV3-38', id: 'IGHV3-38'},
{value: 'IGHV3-41', id: 'IGHV3-41'},
{value: 'IGHV3-42', id: 'IGHV3-42'},
{value: 'IGHV3-43', id: 'IGHV3-43'},
{value: 'IGHV3-47', id: 'IGHV3-47'},
{value: 'IGHV3-48', id: 'IGHV3-48'},
{value: 'IGHV3-49', id: 'IGHV3-49'},
{value: 'IGHV3-50', id: 'IGHV3-50'},
{value: 'IGHV3-52', id: 'IGHV3-52'},
{value: 'IGHV3-53', id: 'IGHV3-53'},
{value: 'IGHV3-54', id: 'IGHV3-54'},
{value: 'IGHV3-57', id: 'IGHV3-57'},
{value: 'IGHV3-6', id: 'IGHV3-6'},
{value: 'IGHV3-60', id: 'IGHV3-60'},
{value: 'IGHV3-62', id: 'IGHV3-62'},
{value: 'IGHV3-63', id: 'IGHV3-63'},
{value: 'IGHV3-64', id: 'IGHV3-64'},
{value: 'IGHV3-64D', id: 'IGHV3-64D'},
{value: 'IGHV3-65', id: 'IGHV3-65'},
{value: 'IGHV3-66', id: 'IGHV3-66'},
{value: 'IGHV3-69-1', id: 'IGHV3-69-1'},
{value: 'IGHV3-7', id: 'IGHV3-7'},
{value: 'IGHV3-71', id: 'IGHV3-71'},
{value: 'IGHV3-72', id: 'IGHV3-72'},
{value: 'IGHV3-73', id: 'IGHV3-73'},
{value: 'IGHV3-74', id: 'IGHV3-74'},
{value: 'IGHV3-75', id: 'IGHV3-75'},
{value: 'IGHV3-76', id: 'IGHV3-76'},
{value: 'IGHV3-79', id: 'IGHV3-79'},
{value: 'IGHV3OR15-7', id: 'IGHV3OR15-7'},
{value: 'IGHV3OR16-11', id: 'IGHV3OR16-11'},
{value: 'IGHV3OR16-12', id: 'IGHV3OR16-12'},
{value: 'IGHV3OR16-13', id: 'IGHV3OR16-13'},
{value: 'IGHV3OR16-15', id: 'IGHV3OR16-15'},
{value: 'IGHV3OR16-16', id: 'IGHV3OR16-16'},
{value: 'IGHV3OR16-17', id: 'IGHV3OR16-17'},
{value: 'IGHV3OR16-6', id: 'IGHV3OR16-6'},
{value: 'IGHV4-28', id: 'IGHV4-28'},
{value: 'IGHV4-31', id: 'IGHV4-31'},
{value: 'IGHV4-34', id: 'IGHV4-34'},
{value: 'IGHV4-39', id: 'IGHV4-39'},
{value: 'IGHV4-4', id: 'IGHV4-4'},
{value: 'IGHV4-55', id: 'IGHV4-55'},
{value: 'IGHV4-59', id: 'IGHV4-59'},
{value: 'IGHV4-61', id: 'IGHV4-61'},
{value: 'IGHV4-80', id: 'IGHV4-80'},
{value: 'IGHV4OR15-8', id: 'IGHV4OR15-8'},
{value: 'IGHV5-10-1', id: 'IGHV5-10-1'},
{value: 'IGHV5-51', id: 'IGHV5-51'},
{value: 'IGHV5-78', id: 'IGHV5-78'},
{value: 'IGHV6-1', id: 'IGHV6-1'},
{value: 'IGHV7-27', id: 'IGHV7-27'},
{value: 'IGHV7-34-1', id: 'IGHV7-34-1'},
{value: 'IGHV7-4-1', id: 'IGHV7-4-1'},
{value: 'IGHV7-40', id: 'IGHV7-40'},
{value: 'IGHV7-56', id: 'IGHV7-56'},
{value: 'IGHV7-81', id: 'IGHV7-81'},
{value: 'IGHV8-51-1', id: 'IGHV8-51-1'},
{value: 'IGHVII-1-1', id: 'IGHVII-1-1'},
{value: 'IGHVII-22-1', id: 'IGHVII-22-1'},
{value: 'IGHVII-26-2', id: 'IGHVII-26-2'},
{value: 'IGHVII-28-1', id: 'IGHVII-28-1'},
{value: 'IGHVII-30-1', id: 'IGHVII-30-1'},
{value: 'IGHVII-30-21', id: 'IGHVII-30-21'},
{value: 'IGHVII-33-1', id: 'IGHVII-33-1'},
{value: 'IGHVII-40-1', id: 'IGHVII-40-1'},
{value: 'IGHVII-43-1', id: 'IGHVII-43-1'},
{value: 'IGHVII-44-2', id: 'IGHVII-44-2'},
{value: 'IGHVII-46-1', id: 'IGHVII-46-1'},
{value: 'IGHVII-49-1', id: 'IGHVII-49-1'},
{value: 'IGHVII-51-2', id: 'IGHVII-51-2'},
{value: 'IGHVII-53-1', id: 'IGHVII-53-1'},
{value: 'IGHVII-60-1', id: 'IGHVII-60-1'},
{value: 'IGHVII-62-1', id: 'IGHVII-62-1'},
{value: 'IGHVII-65-1', id: 'IGHVII-65-1'},
{value: 'IGHVII-67-1', id: 'IGHVII-67-1'},
{value: 'IGHVII-74-1', id: 'IGHVII-74-1'},
{value: 'IGHVII-78-1', id: 'IGHVII-78-1'},
{value: 'IGHVIII-11-1', id: 'IGHVIII-11-1'},
{value: 'IGHVIII-13-1', id: 'IGHVIII-13-1'},
{value: 'IGHVIII-2-1', id: 'IGHVIII-2-1'},
{value: 'IGHVIII-22-2', id: 'IGHVIII-22-2'},
{value: 'IGHVIII-25-1', id: 'IGHVIII-25-1'},
{value: 'IGHVIII-26-1', id: 'IGHVIII-26-1'},
{value: 'IGHVIII-38-1', id: 'IGHVIII-38-1'},
{value: 'IGHVIII-44', id: 'IGHVIII-44'},
{value: 'IGHVIII-47-1', id: 'IGHVIII-47-1'},
{value: 'IGHVIII-5-1', id: 'IGHVIII-5-1'},
{value: 'IGHVIII-5-2', id: 'IGHVIII-5-2'},
{value: 'IGHVIII-67-3', id: 'IGHVIII-67-3'},
{value: 'IGHVIII-67-4', id: 'IGHVIII-67-4'},
{value: 'IGHVIII-76-1', id: 'IGHVIII-76-1'},
{value: 'IGHVIII-82', id: 'IGHVIII-82'},
{value: 'IGHVIV-44-1', id: 'IGHVIV-44-1'},
{value: 'IGIP', id: 'IGIP'},
{value: 'IGKC', id: 'IGKC'},
{value: 'IGKJ1', id: 'IGKJ1'},
{value: 'IGKJ2', id: 'IGKJ2'},
{value: 'IGKV1-12', id: 'IGKV1-12'},
{value: 'IGKV1-13', id: 'IGKV1-13'},
{value: 'IGKV1-16', id: 'IGKV1-16'},
{value: 'IGKV1-17', id: 'IGKV1-17'},
{value: 'IGKV1-22', id: 'IGKV1-22'},
{value: 'IGKV1-27', id: 'IGKV1-27'},
{value: 'IGKV1-32', id: 'IGKV1-32'},
{value: 'IGKV1-33', id: 'IGKV1-33'},
{value: 'IGKV1-35', id: 'IGKV1-35'},
{value: 'IGKV1-37', id: 'IGKV1-37'},
{value: 'IGKV1-39', id: 'IGKV1-39'},
{value: 'IGKV1-5', id: 'IGKV1-5'},
{value: 'IGKV1-6', id: 'IGKV1-6'},
{value: 'IGKV1-8', id: 'IGKV1-8'},
{value: 'IGKV1-9', id: 'IGKV1-9'},
{value: 'IGKV1D-12', id: 'IGKV1D-12'},
{value: 'IGKV1D-13', id: 'IGKV1D-13'},
{value: 'IGKV1D-16', id: 'IGKV1D-16'},
{value: 'IGKV1D-17', id: 'IGKV1D-17'},
{value: 'IGKV1D-22', id: 'IGKV1D-22'},
{value: 'IGKV1D-27', id: 'IGKV1D-27'},
{value: 'IGKV1D-32', id: 'IGKV1D-32'},
{value: 'IGKV1D-33', id: 'IGKV1D-33'},
{value: 'IGKV1D-35', id: 'IGKV1D-35'},
{value: 'IGKV1D-37', id: 'IGKV1D-37'},
{value: 'IGKV1D-39', id: 'IGKV1D-39'},
{value: 'IGKV1D-42', id: 'IGKV1D-42'},
{value: 'IGKV1D-43', id: 'IGKV1D-43'},
{value: 'IGKV1D-8', id: 'IGKV1D-8'},
{value: 'IGKV1OR-2', id: 'IGKV1OR-2'},
{value: 'IGKV1OR1-1', id: 'IGKV1OR1-1'},
{value: 'IGKV1OR10-1', id: 'IGKV1OR10-1'},
{value: 'IGKV1OR2-108', id: 'IGKV1OR2-108'},
{value: 'IGKV1OR2-11', id: 'IGKV1OR2-11'},
{value: 'IGKV1OR2-118', id: 'IGKV1OR2-118'},
{value: 'IGKV1OR2-2', id: 'IGKV1OR2-2'},
{value: 'IGKV1OR2-3', id: 'IGKV1OR2-3'},
{value: 'IGKV1OR2-6', id: 'IGKV1OR2-6'},
{value: 'IGKV1OR2-9', id: 'IGKV1OR2-9'},
{value: 'IGKV1OR22-1', id: 'IGKV1OR22-1'},
{value: 'IGKV1OR22-5', id: 'IGKV1OR22-5'},
{value: 'IGKV2-10', id: 'IGKV2-10'},
{value: 'IGKV2-14', id: 'IGKV2-14'},
{value: 'IGKV2-18', id: 'IGKV2-18'},
{value: 'IGKV2-19', id: 'IGKV2-19'},
{value: 'IGKV2-23', id: 'IGKV2-23'},
{value: 'IGKV2-24', id: 'IGKV2-24'},
{value: 'IGKV2-26', id: 'IGKV2-26'},
{value: 'IGKV2-28', id: 'IGKV2-28'},
{value: 'IGKV2-29', id: 'IGKV2-29'},
{value: 'IGKV2-30', id: 'IGKV2-30'},
{value: 'IGKV2-36', id: 'IGKV2-36'},
{value: 'IGKV2-38', id: 'IGKV2-38'},
{value: 'IGKV2-4', id: 'IGKV2-4'},
{value: 'IGKV2-40', id: 'IGKV2-40'},
{value: 'IGKV2D-10', id: 'IGKV2D-10'},
{value: 'IGKV2D-14', id: 'IGKV2D-14'},
{value: 'IGKV2D-18', id: 'IGKV2D-18'},
{value: 'IGKV2D-19', id: 'IGKV2D-19'},
{value: 'IGKV2D-23', id: 'IGKV2D-23'},
{value: 'IGKV2D-24', id: 'IGKV2D-24'},
{value: 'IGKV2D-26', id: 'IGKV2D-26'},
{value: 'IGKV2D-28', id: 'IGKV2D-28'},
{value: 'IGKV2D-29', id: 'IGKV2D-29'},
{value: 'IGKV2D-30', id: 'IGKV2D-30'},
{value: 'IGKV2D-36', id: 'IGKV2D-36'},
{value: 'IGKV2D-38', id: 'IGKV2D-38'},
{value: 'IGKV2D-40', id: 'IGKV2D-40'},
{value: 'IGKV2OR2-1', id: 'IGKV2OR2-1'},
{value: 'IGKV2OR2-10', id: 'IGKV2OR2-10'},
{value: 'IGKV2OR2-2', id: 'IGKV2OR2-2'},
{value: 'IGKV2OR2-7', id: 'IGKV2OR2-7'},
{value: 'IGKV2OR2-7D', id: 'IGKV2OR2-7D'},
{value: 'IGKV2OR2-8', id: 'IGKV2OR2-8'},
{value: 'IGKV2OR22-3', id: 'IGKV2OR22-3'},
{value: 'IGKV2OR22-4', id: 'IGKV2OR22-4'},
{value: 'IGKV3-11', id: 'IGKV3-11'},
{value: 'IGKV3-15', id: 'IGKV3-15'},
{value: 'IGKV3-20', id: 'IGKV3-20'},
{value: 'IGKV3-25', id: 'IGKV3-25'},
{value: 'IGKV3-31', id: 'IGKV3-31'},
{value: 'IGKV3-34', id: 'IGKV3-34'},
{value: 'IGKV3-7', id: 'IGKV3-7'},
{value: 'IGKV3D-11', id: 'IGKV3D-11'},
{value: 'IGKV3D-15', id: 'IGKV3D-15'},
{value: 'IGKV3D-20', id: 'IGKV3D-20'},
{value: 'IGKV3D-25', id: 'IGKV3D-25'},
{value: 'IGKV3D-31', id: 'IGKV3D-31'},
{value: 'IGKV3D-34', id: 'IGKV3D-34'},
{value: 'IGKV3D-7', id: 'IGKV3D-7'},
{value: 'IGKV3OR2-5', id: 'IGKV3OR2-5'},
{value: 'IGKV3OR22-2', id: 'IGKV3OR22-2'},
{value: 'IGKV4-1', id: 'IGKV4-1'},
{value: 'IGKV5-2', id: 'IGKV5-2'},
{value: 'IGKV6-21', id: 'IGKV6-21'},
{value: 'IGKV6D-21', id: 'IGKV6D-21'},
{value: 'IGKV6D-41', id: 'IGKV6D-41'},
{value: 'IGKV7-3', id: 'IGKV7-3'},
{value: 'IGLC1', id: 'IGLC1'},
{value: 'IGLC2', id: 'IGLC2'},
{value: 'IGLC3', id: 'IGLC3'},
{value: 'IGLC4', id: 'IGLC4'},
{value: 'IGLC5', id: 'IGLC5'},
{value: 'IGLC6', id: 'IGLC6'},
{value: 'IGLC7', id: 'IGLC7'},
{value: 'IGLCOR22-1', id: 'IGLCOR22-1'},
{value: 'IGLCOR22-2', id: 'IGLCOR22-2'},
{value: 'IGLJ1', id: 'IGLJ1'},
{value: 'IGLJ2', id: 'IGLJ2'},
{value: 'IGLJ3', id: 'IGLJ3'},
{value: 'IGLJ4', id: 'IGLJ4'},
{value: 'IGLJ5', id: 'IGLJ5'},
{value: 'IGLJ6', id: 'IGLJ6'},
{value: 'IGLJ7', id: 'IGLJ7'},
{value: 'IGLJCOR18', id: 'IGLJCOR18'},
{value: 'IGLL1', id: 'IGLL1'},
{value: 'IGLL3P', id: 'IGLL3P'},
{value: 'IGLL4P', id: 'IGLL4P'},
{value: 'IGLL5', id: 'IGLL5'},
{value: 'IGLON5', id: 'IGLON5'},
{value: 'IGLV1-36', id: 'IGLV1-36'},
{value: 'IGLV1-40', id: 'IGLV1-40'},
{value: 'IGLV1-41', id: 'IGLV1-41'},
{value: 'IGLV1-44', id: 'IGLV1-44'},
{value: 'IGLV1-47', id: 'IGLV1-47'},
{value: 'IGLV1-50', id: 'IGLV1-50'},
{value: 'IGLV1-51', id: 'IGLV1-51'},
{value: 'IGLV1-62', id: 'IGLV1-62'},
{value: 'IGLV10-54', id: 'IGLV10-54'},
{value: 'IGLV10-67', id: 'IGLV10-67'},
{value: 'IGLV11-55', id: 'IGLV11-55'},
{value: 'IGLV2-11', id: 'IGLV2-11'},
{value: 'IGLV2-14', id: 'IGLV2-14'},
{value: 'IGLV2-18', id: 'IGLV2-18'},
{value: 'IGLV2-23', id: 'IGLV2-23'},
{value: 'IGLV2-28', id: 'IGLV2-28'},
{value: 'IGLV2-33', id: 'IGLV2-33'},
{value: 'IGLV2-34', id: 'IGLV2-34'},
{value: 'IGLV2-5', id: 'IGLV2-5'},
{value: 'IGLV2-8', id: 'IGLV2-8'},
{value: 'IGLV3-1', id: 'IGLV3-1'},
{value: 'IGLV3-10', id: 'IGLV3-10'},
{value: 'IGLV3-12', id: 'IGLV3-12'},
{value: 'IGLV3-13', id: 'IGLV3-13'},
{value: 'IGLV3-15', id: 'IGLV3-15'},
{value: 'IGLV3-16', id: 'IGLV3-16'},
{value: 'IGLV3-17', id: 'IGLV3-17'},
{value: 'IGLV3-19', id: 'IGLV3-19'},
{value: 'IGLV3-2', id: 'IGLV3-2'},
{value: 'IGLV3-21', id: 'IGLV3-21'},
{value: 'IGLV3-22', id: 'IGLV3-22'},
{value: 'IGLV3-24', id: 'IGLV3-24'},
{value: 'IGLV3-25', id: 'IGLV3-25'},
{value: 'IGLV3-26', id: 'IGLV3-26'},
{value: 'IGLV3-27', id: 'IGLV3-27'},
{value: 'IGLV3-29', id: 'IGLV3-29'},
{value: 'IGLV3-30', id: 'IGLV3-30'},
{value: 'IGLV3-31', id: 'IGLV3-31'},
{value: 'IGLV3-32', id: 'IGLV3-32'},
{value: 'IGLV3-4', id: 'IGLV3-4'},
{value: 'IGLV3-6', id: 'IGLV3-6'},
{value: 'IGLV3-7', id: 'IGLV3-7'},
{value: 'IGLV3-9', id: 'IGLV3-9'},
{value: 'IGLV4-3', id: 'IGLV4-3'},
{value: 'IGLV4-60', id: 'IGLV4-60'},
{value: 'IGLV4-69', id: 'IGLV4-69'},
{value: 'IGLV5-37', id: 'IGLV5-37'},
{value: 'IGLV5-45', id: 'IGLV5-45'},
{value: 'IGLV5-48', id: 'IGLV5-48'},
{value: 'IGLV5-52', id: 'IGLV5-52'},
{value: 'IGLV6-57', id: 'IGLV6-57'},
{value: 'IGLV7-35', id: 'IGLV7-35'},
{value: 'IGLV7-43', id: 'IGLV7-43'},
{value: 'IGLV7-46', id: 'IGLV7-46'},
{value: 'IGLV8-61', id: 'IGLV8-61'},
{value: 'IGLV8OR8-1', id: 'IGLV8OR8-1'},
{value: 'IGLV9-49', id: 'IGLV9-49'},
{value: 'IGLVI-20', id: 'IGLVI-20'},
{value: 'IGLVI-38', id: 'IGLVI-38'},
{value: 'IGLVI-42', id: 'IGLVI-42'},
{value: 'IGLVI-56', id: 'IGLVI-56'},
{value: 'IGLVI-63', id: 'IGLVI-63'},
{value: 'IGLVI-68', id: 'IGLVI-68'},
{value: 'IGLVI-70', id: 'IGLVI-70'},
{value: 'IGLVIV-53', id: 'IGLVIV-53'},
{value: 'IGLVIV-59', id: 'IGLVIV-59'},
{value: 'IGLVIV-64', id: 'IGLVIV-64'},
{value: 'IGLVIV-65', id: 'IGLVIV-65'},
{value: 'IGLVIV-66-1', id: 'IGLVIV-66-1'},
{value: 'IGLVIVOR22-1', id: 'IGLVIVOR22-1'},
{value: 'IGLVV-58', id: 'IGLVV-58'},
{value: 'IGLVV-66', id: 'IGLVV-66'},
{value: 'IGLVVI-22-1', id: 'IGLVVI-22-1'},
{value: 'IGLVVI-25-1', id: 'IGLVVI-25-1'},
{value: 'IGLVVII-41-1', id: 'IGLVVII-41-1'},
{value: 'IGSF1', id: 'IGSF1'},
{value: 'IGSF10', id: 'IGSF10'},
{value: 'IGSF11', id: 'IGSF11'},
{value: 'IGSF11-AS1', id: 'IGSF11-AS1'},
{value: 'IGSF21', id: 'IGSF21'},
{value: 'IGSF21-AS1', id: 'IGSF21-AS1'},
{value: 'IGSF22', id: 'IGSF22'},
{value: 'IGSF22-AS1', id: 'IGSF22-AS1'},
{value: 'IGSF23', id: 'IGSF23'},
{value: 'IGSF3', id: 'IGSF3'},
{value: 'IGSF5', id: 'IGSF5'},
{value: 'IGSF6', id: 'IGSF6'},
{value: 'IGSF8', id: 'IGSF8'},
{value: 'IGSF9', id: 'IGSF9'},
{value: 'IGSF9B', id: 'IGSF9B'},
{value: 'IHH', id: 'IHH'},
{value: 'IHO1', id: 'IHO1'},
{value: 'IK', id: 'IK'},
{value: 'IKBIP', id: 'IKBIP'},
{value: 'IKBKB', id: 'IKBKB'},
{value: 'IKBKB-DT', id: 'IKBKB-DT'},
{value: 'IKBKE', id: 'IKBKE'},
{value: 'IKBKG', id: 'IKBKG'},
{value: 'IKBKGP1', id: 'IKBKGP1'},
{value: 'IKZF1', id: 'IKZF1'},
{value: 'IKZF2', id: 'IKZF2'},
{value: 'IKZF3', id: 'IKZF3'},
{value: 'IKZF4', id: 'IKZF4'},
{value: 'IKZF5', id: 'IKZF5'},
{value: 'IL10', id: 'IL10'},
{value: 'IL10RA', id: 'IL10RA'},
{value: 'IL10RB', id: 'IL10RB'},
{value: 'IL10RB-DT', id: 'IL10RB-DT'},
{value: 'IL11', id: 'IL11'},
{value: 'IL11RA', id: 'IL11RA'},
{value: 'IL12A', id: 'IL12A'},
{value: 'IL12A-AS1', id: 'IL12A-AS1'},
{value: 'IL12B', id: 'IL12B'},
{value: 'IL12RB1', id: 'IL12RB1'},
{value: 'IL12RB2', id: 'IL12RB2'},
{value: 'IL13', id: 'IL13'},
{value: 'IL13RA1', id: 'IL13RA1'},
{value: 'IL15', id: 'IL15'},
{value: 'IL15RA', id: 'IL15RA'},
{value: 'IL16', id: 'IL16'},
{value: 'IL17A', id: 'IL17A'},
{value: 'IL17B', id: 'IL17B'},
{value: 'IL17C', id: 'IL17C'},
{value: 'IL17D', id: 'IL17D'},
{value: 'IL17F', id: 'IL17F'},
{value: 'IL17RA', id: 'IL17RA'},
{value: 'IL17RB', id: 'IL17RB'},
{value: 'IL17RC', id: 'IL17RC'},
{value: 'IL17RD', id: 'IL17RD'},
{value: 'IL17RE', id: 'IL17RE'},
{value: 'IL17REL', id: 'IL17REL'},
{value: 'IL18', id: 'IL18'},
{value: 'IL18BP', id: 'IL18BP'},
{value: 'IL18R1', id: 'IL18R1'},
{value: 'IL18RAP', id: 'IL18RAP'},
{value: 'IL19', id: 'IL19'},
{value: 'IL1A', id: 'IL1A'},
{value: 'IL1B', id: 'IL1B'},
{value: 'IL1F10', id: 'IL1F10'},
{value: 'IL1R1', id: 'IL1R1'},
{value: 'IL1R1-AS1', id: 'IL1R1-AS1'},
{value: 'IL1R2', id: 'IL1R2'},
{value: 'IL1RAP', id: 'IL1RAP'},
{value: 'IL1RL1', id: 'IL1RL1'},
{value: 'IL1RL2', id: 'IL1RL2'},
{value: 'IL1RN', id: 'IL1RN'},
{value: 'IL2', id: 'IL2'},
{value: 'IL20', id: 'IL20'},
{value: 'IL20RA', id: 'IL20RA'},
{value: 'IL20RB', id: 'IL20RB'},
{value: 'IL20RB-AS1', id: 'IL20RB-AS1'},
{value: 'IL21', id: 'IL21'},
{value: 'IL21-AS1', id: 'IL21-AS1'},
{value: 'IL21R', id: 'IL21R'},
{value: 'IL21R-AS1', id: 'IL21R-AS1'},
{value: 'IL22', id: 'IL22'},
{value: 'IL22RA1', id: 'IL22RA1'},
{value: 'IL22RA2', id: 'IL22RA2'},
{value: 'IL23A', id: 'IL23A'},
{value: 'IL23R', id: 'IL23R'},
{value: 'IL24', id: 'IL24'},
{value: 'IL25', id: 'IL25'},
{value: 'IL26', id: 'IL26'},
{value: 'IL27', id: 'IL27'},
{value: 'IL27RA', id: 'IL27RA'},
{value: 'IL2RA', id: 'IL2RA'},
{value: 'IL2RB', id: 'IL2RB'},
{value: 'IL2RG', id: 'IL2RG'},
{value: 'IL3', id: 'IL3'},
{value: 'IL31', id: 'IL31'},
{value: 'IL31RA', id: 'IL31RA'},
{value: 'IL32', id: 'IL32'},
{value: 'IL33', id: 'IL33'},
{value: 'IL34', id: 'IL34'},
{value: 'IL36A', id: 'IL36A'},
{value: 'IL36B', id: 'IL36B'},
{value: 'IL36G', id: 'IL36G'},
{value: 'IL36RN', id: 'IL36RN'},
{value: 'IL37', id: 'IL37'},
{value: 'IL3RA', id: 'IL3RA'},
{value: 'IL4', id: 'IL4'},
{value: 'IL4I1', id: 'IL4I1'},
{value: 'IL4R', id: 'IL4R'},
{value: 'IL5', id: 'IL5'},
{value: 'IL5RA', id: 'IL5RA'},
{value: 'IL6', id: 'IL6'},
{value: 'IL6-AS1', id: 'IL6-AS1'},
{value: 'IL6R', id: 'IL6R'},
{value: 'IL6R-AS1', id: 'IL6R-AS1'},
{value: 'IL6RP1', id: 'IL6RP1'},
{value: 'IL6ST', id: 'IL6ST'},
{value: 'IL6STP1', id: 'IL6STP1'},
{value: 'IL7', id: 'IL7'},
{value: 'IL7R', id: 'IL7R'},
{value: 'IL9', id: 'IL9'},
{value: 'IL9R', id: 'IL9R'},
{value: 'IL9RP1', id: 'IL9RP1'},
{value: 'IL9RP2', id: 'IL9RP2'},
{value: 'IL9RP3', id: 'IL9RP3'},
{value: 'IL9RP4', id: 'IL9RP4'},
{value: 'ILDR1', id: 'ILDR1'},
{value: 'ILDR2', id: 'ILDR2'},
{value: 'ILF2', id: 'ILF2'},
{value: 'ILF2P1', id: 'ILF2P1'},
{value: 'ILF3', id: 'ILF3'},
{value: 'ILF3-DT', id: 'ILF3-DT'},
{value: 'ILK', id: 'ILK'},
{value: 'ILKAP', id: 'ILKAP'},
{value: 'ILRUN', id: 'ILRUN'},
{value: 'ILRUN-AS1', id: 'ILRUN-AS1'},
{value: 'ILRUNP1', id: 'ILRUNP1'},
{value: 'ILVBL', id: 'ILVBL'},
{value: 'ILVBL-AS1', id: 'ILVBL-AS1'},
{value: 'IMMP1L', id: 'IMMP1L'},
{value: 'IMMP1LP2', id: 'IMMP1LP2'},
{value: 'IMMP1LP3', id: 'IMMP1LP3'},
{value: 'IMMP2L', id: 'IMMP2L'},
{value: 'IMMT', id: 'IMMT'},
{value: 'IMMTP1', id: 'IMMTP1'},
{value: 'IMP3', id: 'IMP3'},
{value: 'IMP3P1', id: 'IMP3P1'},
{value: 'IMP3P2', id: 'IMP3P2'},
{value: 'IMP4', id: 'IMP4'},
{value: 'IMPA1', id: 'IMPA1'},
{value: 'IMPA1P1', id: 'IMPA1P1'},
{value: 'IMPA2', id: 'IMPA2'},
{value: 'IMPACT', id: 'IMPACT'},
{value: 'IMPDH1', id: 'IMPDH1'},
{value: 'IMPDH1P10', id: 'IMPDH1P10'},
{value: 'IMPDH1P3', id: 'IMPDH1P3'},
{value: 'IMPDH1P5', id: 'IMPDH1P5'},
{value: 'IMPDH1P7', id: 'IMPDH1P7'},
{value: 'IMPDH1P8', id: 'IMPDH1P8'},
{value: 'IMPDH1P9', id: 'IMPDH1P9'},
{value: 'IMPDH2', id: 'IMPDH2'},
{value: 'IMPG1', id: 'IMPG1'},
{value: 'IMPG2', id: 'IMPG2'},
{value: 'INA', id: 'INA'},
{value: 'INAFM1', id: 'INAFM1'},
{value: 'INAFM2', id: 'INAFM2'},
{value: 'INAVA', id: 'INAVA'},
{value: 'INCA1', id: 'INCA1'},
{value: 'INCENP', id: 'INCENP'},
{value: 'INE1', id: 'INE1'},
{value: 'INE2', id: 'INE2'},
{value: 'INF2', id: 'INF2'},
{value: 'ING1', id: 'ING1'},
{value: 'ING2', id: 'ING2'},
{value: 'ING3', id: 'ING3'},
{value: 'ING4', id: 'ING4'},
{value: 'ING5', id: 'ING5'},
{value: 'INGX', id: 'INGX'},
{value: 'INHA', id: 'INHA'},
{value: 'INHBA', id: 'INHBA'},
{value: 'INHBA-AS1', id: 'INHBA-AS1'},
{value: 'INHBB', id: 'INHBB'},
{value: 'INHBC', id: 'INHBC'},
{value: 'INHBE', id: 'INHBE'},
{value: 'INHCAP', id: 'INHCAP'},
{value: 'INIP', id: 'INIP'},
{value: 'INKA1', id: 'INKA1'},
{value: 'INKA2', id: 'INKA2'},
{value: 'INKA2-AS1', id: 'INKA2-AS1'},
{value: 'INMT', id: 'INMT'},
{value: 'INMT-MINDY4', id: 'INMT-MINDY4'},
{value: 'INO80', id: 'INO80'},
{value: 'INO80B', id: 'INO80B'},
{value: 'INO80B-WBP1', id: 'INO80B-WBP1'},
{value: 'INO80C', id: 'INO80C'},
{value: 'INO80D', id: 'INO80D'},
{value: 'INO80E', id: 'INO80E'},
{value: 'INPP1', id: 'INPP1'},
{value: 'INPP4A', id: 'INPP4A'},
{value: 'INPP4B', id: 'INPP4B'},
{value: 'INPP5A', id: 'INPP5A'},
{value: 'INPP5B', id: 'INPP5B'},
{value: 'INPP5D', id: 'INPP5D'},
{value: 'INPP5E', id: 'INPP5E'},
{value: 'INPP5F', id: 'INPP5F'},
{value: 'INPP5J', id: 'INPP5J'},
{value: 'INPP5K', id: 'INPP5K'},
{value: 'INPPL1', id: 'INPPL1'},
{value: 'INS', id: 'INS'},
{value: 'INS-IGF2', id: 'INS-IGF2'},
{value: 'INSC', id: 'INSC'},
{value: 'INSIG1', id: 'INSIG1'},
{value: 'INSIG1-DT', id: 'INSIG1-DT'},
{value: 'INSIG2', id: 'INSIG2'},
{value: 'INSL3', id: 'INSL3'},
{value: 'INSL4', id: 'INSL4'},
{value: 'INSL5', id: 'INSL5'},
{value: 'INSL6', id: 'INSL6'},
{value: 'INSM1', id: 'INSM1'},
{value: 'INSM2', id: 'INSM2'},
{value: 'INSR', id: 'INSR'},
{value: 'INSRR', id: 'INSRR'},
{value: 'INSYN1', id: 'INSYN1'},
{value: 'INSYN1-AS1', id: 'INSYN1-AS1'},
{value: 'INSYN2A', id: 'INSYN2A'},
{value: 'INSYN2B', id: 'INSYN2B'},
{value: 'INTS1', id: 'INTS1'},
{value: 'INTS10', id: 'INTS10'},
{value: 'INTS11', id: 'INTS11'},
{value: 'INTS12', id: 'INTS12'},
{value: 'INTS13', id: 'INTS13'},
{value: 'INTS14', id: 'INTS14'},
{value: 'INTS2', id: 'INTS2'},
{value: 'INTS3', id: 'INTS3'},
{value: 'INTS4', id: 'INTS4'},
{value: 'INTS4P1', id: 'INTS4P1'},
{value: 'INTS4P2', id: 'INTS4P2'},
{value: 'INTS5', id: 'INTS5'},
{value: 'INTS6', id: 'INTS6'},
{value: 'INTS6-AS1', id: 'INTS6-AS1'},
{value: 'INTS6L', id: 'INTS6L'},
{value: 'INTS6P1', id: 'INTS6P1'},
{value: 'INTS7', id: 'INTS7'},
{value: 'INTS8', id: 'INTS8'},
{value: 'INTS9', id: 'INTS9'},
{value: 'INTS9-AS1', id: 'INTS9-AS1'},
{value: 'INTU', id: 'INTU'},
{value: 'INVS', id: 'INVS'},
{value: 'IP6K1', id: 'IP6K1'},
{value: 'IP6K2', id: 'IP6K2'},
{value: 'IP6K3', id: 'IP6K3'},
{value: 'IPCEF1', id: 'IPCEF1'},
{value: 'IPMK', id: 'IPMK'},
{value: 'IPMKP1', id: 'IPMKP1'},
{value: 'IPO11', id: 'IPO11'},
{value: 'IPO13', id: 'IPO13'},
{value: 'IPO4', id: 'IPO4'},
{value: 'IPO5', id: 'IPO5'},
{value: 'IPO5P1', id: 'IPO5P1'},
{value: 'IPO7', id: 'IPO7'},
{value: 'IPO8', id: 'IPO8'},
{value: 'IPO8P1', id: 'IPO8P1'},
{value: 'IPO9', id: 'IPO9'},
{value: 'IPO9-AS1', id: 'IPO9-AS1'},
{value: 'IPP', id: 'IPP'},
{value: 'IPPK', id: 'IPPK'},
{value: 'IPPKP1', id: 'IPPKP1'},
{value: 'IQANK1', id: 'IQANK1'},
{value: 'IQCA1', id: 'IQCA1'},
{value: 'IQCA1L', id: 'IQCA1L'},
{value: 'IQCB1', id: 'IQCB1'},
{value: 'IQCB2P', id: 'IQCB2P'},
{value: 'IQCC', id: 'IQCC'},
{value: 'IQCD', id: 'IQCD'},
{value: 'IQCE', id: 'IQCE'},
{value: 'IQCF1', id: 'IQCF1'},
{value: 'IQCF2', id: 'IQCF2'},
{value: 'IQCF3', id: 'IQCF3'},
{value: 'IQCF5', id: 'IQCF5'},
{value: 'IQCF5-AS1', id: 'IQCF5-AS1'},
{value: 'IQCF6', id: 'IQCF6'},
{value: 'IQCG', id: 'IQCG'},
{value: 'IQCH', id: 'IQCH'},
{value: 'IQCH-AS1', id: 'IQCH-AS1'},
{value: 'IQCJ-SCHIP1', id: 'IQCJ-SCHIP1'},
{value: 'IQCJ-SCHIP1-AS1', id: 'IQCJ-SCHIP1-AS1'},
{value: 'IQCK', id: 'IQCK'},
{value: 'IQCM', id: 'IQCM'},
{value: 'IQCN', id: 'IQCN'},
{value: 'IQGAP1', id: 'IQGAP1'},
{value: 'IQGAP2', id: 'IQGAP2'},
{value: 'IQGAP3', id: 'IQGAP3'},
{value: 'IQSEC1', id: 'IQSEC1'},
{value: 'IQSEC2', id: 'IQSEC2'},
{value: 'IQSEC3', id: 'IQSEC3'},
{value: 'IQSEC3P1', id: 'IQSEC3P1'},
{value: 'IQUB', id: 'IQUB'},
{value: 'IRAG1', id: 'IRAG1'},
{value: 'IRAG1-AS1', id: 'IRAG1-AS1'},
{value: 'IRAG2', id: 'IRAG2'},
{value: 'IRAIN', id: 'IRAIN'},
{value: 'IRAK1', id: 'IRAK1'},
{value: 'IRAK1BP1', id: 'IRAK1BP1'},
{value: 'IRAK2', id: 'IRAK2'},
{value: 'IRAK3', id: 'IRAK3'},
{value: 'IRAK4', id: 'IRAK4'},
{value: 'IREB2', id: 'IREB2'},
{value: 'IRF1', id: 'IRF1'},
{value: 'IRF1-AS1', id: 'IRF1-AS1'},
{value: 'IRF2', id: 'IRF2'},
{value: 'IRF2-DT', id: 'IRF2-DT'},
{value: 'IRF2BP1', id: 'IRF2BP1'},
{value: 'IRF2BP2', id: 'IRF2BP2'},
{value: 'IRF2BPL', id: 'IRF2BPL'},
{value: 'IRF3', id: 'IRF3'},
{value: 'IRF4', id: 'IRF4'},
{value: 'IRF5', id: 'IRF5'},
{value: 'IRF6', id: 'IRF6'},
{value: 'IRF7', id: 'IRF7'},
{value: 'IRF8', id: 'IRF8'},
{value: 'IRF9', id: 'IRF9'},
{value: 'IRGC', id: 'IRGC'},
{value: 'IRGM', id: 'IRGM'},
{value: 'IRGQ', id: 'IRGQ'},
{value: 'IRS1', id: 'IRS1'},
{value: 'IRS2', id: 'IRS2'},
{value: 'IRS3P', id: 'IRS3P'},
{value: 'IRX1', id: 'IRX1'},
{value: 'IRX1P1', id: 'IRX1P1'},
{value: 'IRX2', id: 'IRX2'},
{value: 'IRX3', id: 'IRX3'},
{value: 'IRX4', id: 'IRX4'},
{value: 'IRX4-AS1', id: 'IRX4-AS1'},
{value: 'IRX5', id: 'IRX5'},
{value: 'IRX6', id: 'IRX6'},
{value: 'ISCA1', id: 'ISCA1'},
{value: 'ISCA1P1', id: 'ISCA1P1'},
{value: 'ISCA1P2', id: 'ISCA1P2'},
{value: 'ISCA1P3', id: 'ISCA1P3'},
{value: 'ISCA1P4', id: 'ISCA1P4'},
{value: 'ISCA1P6', id: 'ISCA1P6'},
{value: 'ISCA2', id: 'ISCA2'},
{value: 'ISCA2P1', id: 'ISCA2P1'},
{value: 'ISCU', id: 'ISCU'},
{value: 'ISG15', id: 'ISG15'},
{value: 'ISG20', id: 'ISG20'},
{value: 'ISG20L2', id: 'ISG20L2'},
{value: 'ISL1', id: 'ISL1'},
{value: 'ISL1-DT', id: 'ISL1-DT'},
{value: 'ISL2', id: 'ISL2'},
{value: 'ISLR', id: 'ISLR'},
{value: 'ISLR2', id: 'ISLR2'},
{value: 'ISM1', id: 'ISM1'},
{value: 'ISM1-AS1', id: 'ISM1-AS1'},
{value: 'ISM2', id: 'ISM2'},
{value: 'ISOC1', id: 'ISOC1'},
{value: 'ISOC2', id: 'ISOC2'},
{value: 'IST1', id: 'IST1'},
{value: 'ISX', id: 'ISX'},
{value: 'ISY1', id: 'ISY1'},
{value: 'ISY1-RAB43', id: 'ISY1-RAB43'},
{value: 'ISYNA1', id: 'ISYNA1'},
{value: 'ITCH', id: 'ITCH'},
{value: 'ITFG1', id: 'ITFG1'},
{value: 'ITFG1-AS1', id: 'ITFG1-AS1'},
{value: 'ITFG2', id: 'ITFG2'},
{value: 'ITFG2-AS1', id: 'ITFG2-AS1'},
{value: 'ITGA1', id: 'ITGA1'},
{value: 'ITGA10', id: 'ITGA10'},
{value: 'ITGA11', id: 'ITGA11'},
{value: 'ITGA2', id: 'ITGA2'},
{value: 'ITGA2-AS1', id: 'ITGA2-AS1'},
{value: 'ITGA2B', id: 'ITGA2B'},
{value: 'ITGA3', id: 'ITGA3'},
{value: 'ITGA4', id: 'ITGA4'},
{value: 'ITGA5', id: 'ITGA5'},
{value: 'ITGA6', id: 'ITGA6'},
{value: 'ITGA6-AS1', id: 'ITGA6-AS1'},
{value: 'ITGA7', id: 'ITGA7'},
{value: 'ITGA8', id: 'ITGA8'},
{value: 'ITGA9', id: 'ITGA9'},
{value: 'ITGA9-AS1', id: 'ITGA9-AS1'},
{value: 'ITGAD', id: 'ITGAD'},
{value: 'ITGAE', id: 'ITGAE'},
{value: 'ITGAL', id: 'ITGAL'},
{value: 'ITGAM', id: 'ITGAM'},
{value: 'ITGAV', id: 'ITGAV'},
{value: 'ITGAX', id: 'ITGAX'},
{value: 'ITGB1', id: 'ITGB1'},
{value: 'ITGB1-DT', id: 'ITGB1-DT'},
{value: 'ITGB1BP1', id: 'ITGB1BP1'},
{value: 'ITGB1BP2', id: 'ITGB1BP2'},
{value: 'ITGB1P1', id: 'ITGB1P1'},
{value: 'ITGB2', id: 'ITGB2'},
{value: 'ITGB2-AS1', id: 'ITGB2-AS1'},
{value: 'ITGB3', id: 'ITGB3'},
{value: 'ITGB3BP', id: 'ITGB3BP'},
{value: 'ITGB4', id: 'ITGB4'},
{value: 'ITGB5', id: 'ITGB5'},
{value: 'ITGB5-AS1', id: 'ITGB5-AS1'},
{value: 'ITGB6', id: 'ITGB6'},
{value: 'ITGB7', id: 'ITGB7'},
{value: 'ITGB8', id: 'ITGB8'},
{value: 'ITGB8-AS1', id: 'ITGB8-AS1'},
{value: 'ITGBL1', id: 'ITGBL1'},
{value: 'ITIH1', id: 'ITIH1'},
{value: 'ITIH2', id: 'ITIH2'},
{value: 'ITIH3', id: 'ITIH3'},
{value: 'ITIH4', id: 'ITIH4'},
{value: 'ITIH4-AS1', id: 'ITIH4-AS1'},
{value: 'ITIH5', id: 'ITIH5'},
{value: 'ITIH6', id: 'ITIH6'},
{value: 'ITK', id: 'ITK'},
{value: 'ITLN1', id: 'ITLN1'},
{value: 'ITLN2', id: 'ITLN2'},
{value: 'ITM2A', id: 'ITM2A'},
{value: 'ITM2B', id: 'ITM2B'},
{value: 'ITM2BP1', id: 'ITM2BP1'},
{value: 'ITM2C', id: 'ITM2C'},
{value: 'ITPA', id: 'ITPA'},
{value: 'ITPK1', id: 'ITPK1'},
{value: 'ITPK1-AS1', id: 'ITPK1-AS1'},
{value: 'ITPK1P1', id: 'ITPK1P1'},
{value: 'ITPKA', id: 'ITPKA'},
{value: 'ITPKB', id: 'ITPKB'},
{value: 'ITPKB-AS1', id: 'ITPKB-AS1'},
{value: 'ITPKB-IT1', id: 'ITPKB-IT1'},
{value: 'ITPKC', id: 'ITPKC'},
{value: 'ITPR1', id: 'ITPR1'},
{value: 'ITPR1-DT', id: 'ITPR1-DT'},
{value: 'ITPR2', id: 'ITPR2'},
{value: 'ITPR3', id: 'ITPR3'},
{value: 'ITPRID1', id: 'ITPRID1'},
{value: 'ITPRID2', id: 'ITPRID2'},
{value: 'ITPRID2-DT', id: 'ITPRID2-DT'},
{value: 'ITPRIP', id: 'ITPRIP'},
{value: 'ITPRIP-AS1', id: 'ITPRIP-AS1'},
{value: 'ITPRIPL1', id: 'ITPRIPL1'},
{value: 'ITPRIPL2', id: 'ITPRIPL2'},
{value: 'ITSN1', id: 'ITSN1'},
{value: 'ITSN2', id: 'ITSN2'},
{value: 'IVD', id: 'IVD'},
{value: 'IVL', id: 'IVL'},
{value: 'IVNS1ABP', id: 'IVNS1ABP'},
{value: 'IWS1', id: 'IWS1'},
{value: 'IYD', id: 'IYD'},
{value: 'IZUMO1', id: 'IZUMO1'},
{value: 'IZUMO1R', id: 'IZUMO1R'},
{value: 'IZUMO2', id: 'IZUMO2'},
{value: 'IZUMO3', id: 'IZUMO3'},
{value: 'IZUMO4', id: 'IZUMO4'},
{value: 'JADE1', id: 'JADE1'},
{value: 'JADE2', id: 'JADE2'},
{value: 'JADE3', id: 'JADE3'},
{value: 'JADRR', id: 'JADRR'},
{value: 'JAG1', id: 'JAG1'},
{value: 'JAG2', id: 'JAG2'},
{value: 'JAGN1', id: 'JAGN1'},
{value: 'JAK1', id: 'JAK1'},
{value: 'JAK2', id: 'JAK2'},
{value: 'JAK3', id: 'JAK3'},
{value: 'JAKMIP1', id: 'JAKMIP1'},
{value: 'JAKMIP1-DT', id: 'JAKMIP1-DT'},
{value: 'JAKMIP2', id: 'JAKMIP2'},
{value: 'JAKMIP2-AS1', id: 'JAKMIP2-AS1'},
{value: 'JAKMIP3', id: 'JAKMIP3'},
{value: 'JAM2', id: 'JAM2'},
{value: 'JAM3', id: 'JAM3'},
{value: 'JAML', id: 'JAML'},
{value: 'JARID2', id: 'JARID2'},
{value: 'JARID2-AS1', id: 'JARID2-AS1'},
{value: 'JARID2-DT', id: 'JARID2-DT'},
{value: 'JAZF1', id: 'JAZF1'},
{value: 'JAZF1-AS1', id: 'JAZF1-AS1'},
{value: 'JCAD', id: 'JCAD'},
{value: 'JCADP1', id: 'JCADP1'},
{value: 'JCHAIN', id: 'JCHAIN'},
{value: 'JCHAINP1', id: 'JCHAINP1'},
{value: 'JDP2', id: 'JDP2'},
{value: 'JDP2-AS1', id: 'JDP2-AS1'},
{value: 'JHY', id: 'JHY'},
{value: 'JKAMP', id: 'JKAMP'},
{value: 'JKAMPP1', id: 'JKAMPP1'},
{value: 'JMJD1C', id: 'JMJD1C'},
{value: 'JMJD1C-AS1', id: 'JMJD1C-AS1'},
{value: 'JMJD4', id: 'JMJD4'},
{value: 'JMJD6', id: 'JMJD6'},
{value: 'JMJD7', id: 'JMJD7'},
{value: 'JMJD7-PLA2G4B', id: 'JMJD7-PLA2G4B'},
{value: 'JMJD8', id: 'JMJD8'},
{value: 'JMY', id: 'JMY'},
{value: 'JOSD1', id: 'JOSD1'},
{value: 'JOSD2', id: 'JOSD2'},
{value: 'JPH1', id: 'JPH1'},
{value: 'JPH2', id: 'JPH2'},
{value: 'JPH3', id: 'JPH3'},
{value: 'JPH4', id: 'JPH4'},
{value: 'JPT1', id: 'JPT1'},
{value: 'JPT1P1', id: 'JPT1P1'},
{value: 'JPT2', id: 'JPT2'},
{value: 'JPX', id: 'JPX'},
{value: 'JRK', id: 'JRK'},
{value: 'JRKL', id: 'JRKL'},
{value: 'JSRP1', id: 'JSRP1'},
{value: 'JTB', id: 'JTB'},
{value: 'JTBP1', id: 'JTBP1'},
{value: 'JUN', id: 'JUN'},
{value: 'JUNB', id: 'JUNB'},
{value: 'JUND', id: 'JUND'},
{value: 'JUP', id: 'JUP'},
{value: 'KAAG1', id: 'KAAG1'},
{value: 'KALRN', id: 'KALRN'},
{value: 'KANK1', id: 'KANK1'},
{value: 'KANK1P1', id: 'KANK1P1'},
{value: 'KANK2', id: 'KANK2'},
{value: 'KANK3', id: 'KANK3'},
{value: 'KANK4', id: 'KANK4'},
{value: 'KANSL1', id: 'KANSL1'},
{value: 'KANSL1-AS1', id: 'KANSL1-AS1'},
{value: 'KANSL1L', id: 'KANSL1L'},
{value: 'KANSL1L-AS1', id: 'KANSL1L-AS1'},
{value: 'KANSL2', id: 'KANSL2'},
{value: 'KANSL3', id: 'KANSL3'},
{value: 'KANTR', id: 'KANTR'},
{value: 'KARS1', id: 'KARS1'},
{value: 'KARS1P1', id: 'KARS1P1'},
{value: 'KARS1P2', id: 'KARS1P2'},
{value: 'KASH5', id: 'KASH5'},
{value: 'KAT14', id: 'KAT14'},
{value: 'KAT2A', id: 'KAT2A'},
{value: 'KAT2B', id: 'KAT2B'},
{value: 'KAT5', id: 'KAT5'},
{value: 'KAT6A', id: 'KAT6A'},
{value: 'KAT6B', id: 'KAT6B'},
{value: 'KAT7', id: 'KAT7'},
{value: 'KAT8', id: 'KAT8'},
{value: 'KATNA1', id: 'KATNA1'},
{value: 'KATNAL1', id: 'KATNAL1'},
{value: 'KATNAL2', id: 'KATNAL2'},
{value: 'KATNB1', id: 'KATNB1'},
{value: 'KATNBL1', id: 'KATNBL1'},
{value: 'KATNBL1P1', id: 'KATNBL1P1'},
{value: 'KATNBL1P4', id: 'KATNBL1P4'},
{value: 'KATNBL1P6', id: 'KATNBL1P6'},
{value: 'KATNIP', id: 'KATNIP'},
{value: 'KAZALD1', id: 'KAZALD1'},
{value: 'KAZN', id: 'KAZN'},
{value: 'KBTBD11', id: 'KBTBD11'},
{value: 'KBTBD11-AS1', id: 'KBTBD11-AS1'},
{value: 'KBTBD11-OT1', id: 'KBTBD11-OT1'},
{value: 'KBTBD12', id: 'KBTBD12'},
{value: 'KBTBD13', id: 'KBTBD13'},
{value: 'KBTBD2', id: 'KBTBD2'},
{value: 'KBTBD3', id: 'KBTBD3'},
{value: 'KBTBD4', id: 'KBTBD4'},
{value: 'KBTBD6', id: 'KBTBD6'},
{value: 'KBTBD7', id: 'KBTBD7'},
{value: 'KBTBD8', id: 'KBTBD8'},
{value: 'KC6', id: 'KC6'},
{value: 'KCCAT198', id: 'KCCAT198'},
{value: 'KCMF1', id: 'KCMF1'},
{value: 'KCNA1', id: 'KCNA1'},
{value: 'KCNA10', id: 'KCNA10'},
{value: 'KCNA2', id: 'KCNA2'},
{value: 'KCNA3', id: 'KCNA3'},
{value: 'KCNA4', id: 'KCNA4'},
{value: 'KCNA5', id: 'KCNA5'},
{value: 'KCNA6', id: 'KCNA6'},
{value: 'KCNA7', id: 'KCNA7'},
{value: 'KCNAB1', id: 'KCNAB1'},
{value: 'KCNAB1-AS1', id: 'KCNAB1-AS1'},
{value: 'KCNAB1-AS2', id: 'KCNAB1-AS2'},
{value: 'KCNAB2', id: 'KCNAB2'},
{value: 'KCNAB3', id: 'KCNAB3'},
{value: 'KCNB1', id: 'KCNB1'},
{value: 'KCNB2', id: 'KCNB2'},
{value: 'KCNC1', id: 'KCNC1'},
{value: 'KCNC2', id: 'KCNC2'},
{value: 'KCNC3', id: 'KCNC3'},
{value: 'KCNC4', id: 'KCNC4'},
{value: 'KCND1', id: 'KCND1'},
{value: 'KCND2', id: 'KCND2'},
{value: 'KCND3', id: 'KCND3'},
{value: 'KCND3-IT1', id: 'KCND3-IT1'},
{value: 'KCNE1', id: 'KCNE1'},
{value: 'KCNE1B', id: 'KCNE1B'},
{value: 'KCNE3', id: 'KCNE3'},
{value: 'KCNE4', id: 'KCNE4'},
{value: 'KCNE5', id: 'KCNE5'},
{value: 'KCNF1', id: 'KCNF1'},
{value: 'KCNG1', id: 'KCNG1'},
{value: 'KCNG2', id: 'KCNG2'},
{value: 'KCNG3', id: 'KCNG3'},
{value: 'KCNG4', id: 'KCNG4'},
{value: 'KCNH1', id: 'KCNH1'},
{value: 'KCNH1-IT1', id: 'KCNH1-IT1'},
{value: 'KCNH2', id: 'KCNH2'},
{value: 'KCNH3', id: 'KCNH3'},
{value: 'KCNH4', id: 'KCNH4'},
{value: 'KCNH5', id: 'KCNH5'},
{value: 'KCNH6', id: 'KCNH6'},
{value: 'KCNH7', id: 'KCNH7'},
{value: 'KCNH7-AS1', id: 'KCNH7-AS1'},
{value: 'KCNH8', id: 'KCNH8'},
{value: 'KCNIP1', id: 'KCNIP1'},
{value: 'KCNIP1-AS1', id: 'KCNIP1-AS1'},
{value: 'KCNIP1-OT1', id: 'KCNIP1-OT1'},
{value: 'KCNIP2', id: 'KCNIP2'},
{value: 'KCNIP2-AS1', id: 'KCNIP2-AS1'},
{value: 'KCNIP3', id: 'KCNIP3'},
{value: 'KCNIP4', id: 'KCNIP4'},
{value: 'KCNJ1', id: 'KCNJ1'},
{value: 'KCNJ10', id: 'KCNJ10'},
{value: 'KCNJ11', id: 'KCNJ11'},
{value: 'KCNJ12', id: 'KCNJ12'},
{value: 'KCNJ13', id: 'KCNJ13'},
{value: 'KCNJ14', id: 'KCNJ14'},
{value: 'KCNJ15', id: 'KCNJ15'},
{value: 'KCNJ16', id: 'KCNJ16'},
{value: 'KCNJ18', id: 'KCNJ18'},
{value: 'KCNJ2', id: 'KCNJ2'},
{value: 'KCNJ2-AS1', id: 'KCNJ2-AS1'},
{value: 'KCNJ3', id: 'KCNJ3'},
{value: 'KCNJ4', id: 'KCNJ4'},
{value: 'KCNJ5', id: 'KCNJ5'},
{value: 'KCNJ6', id: 'KCNJ6'},
{value: 'KCNJ8', id: 'KCNJ8'},
{value: 'KCNJ9', id: 'KCNJ9'},
{value: 'KCNK1', id: 'KCNK1'},
{value: 'KCNK10', id: 'KCNK10'},
{value: 'KCNK12', id: 'KCNK12'},
{value: 'KCNK13', id: 'KCNK13'},
{value: 'KCNK15', id: 'KCNK15'},
{value: 'KCNK15-AS1', id: 'KCNK15-AS1'},
{value: 'KCNK16', id: 'KCNK16'},
{value: 'KCNK17', id: 'KCNK17'},
{value: 'KCNK18', id: 'KCNK18'},
{value: 'KCNK2', id: 'KCNK2'},
{value: 'KCNK3', id: 'KCNK3'},
{value: 'KCNK4', id: 'KCNK4'},
{value: 'KCNK4-TEX40', id: 'KCNK4-TEX40'},
{value: 'KCNK5', id: 'KCNK5'},
{value: 'KCNK6', id: 'KCNK6'},
{value: 'KCNK7', id: 'KCNK7'},
{value: 'KCNK9', id: 'KCNK9'},
{value: 'KCNMA1', id: 'KCNMA1'},
{value: 'KCNMA1-AS1', id: 'KCNMA1-AS1'},
{value: 'KCNMA1-AS2', id: 'KCNMA1-AS2'},
{value: 'KCNMA1-AS3', id: 'KCNMA1-AS3'},
{value: 'KCNMB1', id: 'KCNMB1'},
{value: 'KCNMB2', id: 'KCNMB2'},
{value: 'KCNMB2-AS1', id: 'KCNMB2-AS1'},
{value: 'KCNMB3', id: 'KCNMB3'},
{value: 'KCNMB3P1', id: 'KCNMB3P1'},
{value: 'KCNMB4', id: 'KCNMB4'},
{value: 'KCNN1', id: 'KCNN1'},
{value: 'KCNN2', id: 'KCNN2'},
{value: 'KCNN3', id: 'KCNN3'},
{value: 'KCNN4', id: 'KCNN4'},
{value: 'KCNQ1', id: 'KCNQ1'},
{value: 'KCNQ1-AS1', id: 'KCNQ1-AS1'},
{value: 'KCNQ1DN', id: 'KCNQ1DN'},
{value: 'KCNQ1OT1', id: 'KCNQ1OT1'},
{value: 'KCNQ2', id: 'KCNQ2'},
{value: 'KCNQ3', id: 'KCNQ3'},
{value: 'KCNQ4', id: 'KCNQ4'},
{value: 'KCNQ5', id: 'KCNQ5'},
{value: 'KCNQ5-AS1', id: 'KCNQ5-AS1'},
{value: 'KCNQ5-DT', id: 'KCNQ5-DT'},
{value: 'KCNRG', id: 'KCNRG'},
{value: 'KCNS1', id: 'KCNS1'},
{value: 'KCNS2', id: 'KCNS2'},
{value: 'KCNS3', id: 'KCNS3'},
{value: 'KCNT1', id: 'KCNT1'},
{value: 'KCNT2', id: 'KCNT2'},
{value: 'KCNU1', id: 'KCNU1'},
{value: 'KCNV1', id: 'KCNV1'},
{value: 'KCNV2', id: 'KCNV2'},
{value: 'KCP', id: 'KCP'},
{value: 'KCTD1', id: 'KCTD1'},
{value: 'KCTD10', id: 'KCTD10'},
{value: 'KCTD11', id: 'KCTD11'},
{value: 'KCTD12', id: 'KCTD12'},
{value: 'KCTD13', id: 'KCTD13'},
{value: 'KCTD13-DT', id: 'KCTD13-DT'},
{value: 'KCTD14', id: 'KCTD14'},
{value: 'KCTD15', id: 'KCTD15'},
{value: 'KCTD16', id: 'KCTD16'},
{value: 'KCTD17', id: 'KCTD17'},
{value: 'KCTD18', id: 'KCTD18'},
{value: 'KCTD19', id: 'KCTD19'},
{value: 'KCTD2', id: 'KCTD2'},
{value: 'KCTD20', id: 'KCTD20'},
{value: 'KCTD21', id: 'KCTD21'},
{value: 'KCTD21-AS1', id: 'KCTD21-AS1'},
{value: 'KCTD3', id: 'KCTD3'},
{value: 'KCTD5', id: 'KCTD5'},
{value: 'KCTD5P1', id: 'KCTD5P1'},
{value: 'KCTD6', id: 'KCTD6'},
{value: 'KCTD7', id: 'KCTD7'},
{value: 'KCTD8', id: 'KCTD8'},
{value: 'KCTD9', id: 'KCTD9'},
{value: 'KCTD9P1', id: 'KCTD9P1'},
{value: 'KCTD9P3', id: 'KCTD9P3'},
{value: 'KCTD9P5', id: 'KCTD9P5'},
{value: 'KCTD9P6', id: 'KCTD9P6'},
{value: 'KDELR1', id: 'KDELR1'},
{value: 'KDELR2', id: 'KDELR2'},
{value: 'KDELR3', id: 'KDELR3'},
{value: 'KDF1', id: 'KDF1'},
{value: 'KDM1A', id: 'KDM1A'},
{value: 'KDM1B', id: 'KDM1B'},
{value: 'KDM2A', id: 'KDM2A'},
{value: 'KDM2B', id: 'KDM2B'},
{value: 'KDM2B-DT', id: 'KDM2B-DT'},
{value: 'KDM3A', id: 'KDM3A'},
{value: 'KDM3AP1', id: 'KDM3AP1'},
{value: 'KDM3B', id: 'KDM3B'},
{value: 'KDM4A', id: 'KDM4A'},
{value: 'KDM4A-AS1', id: 'KDM4A-AS1'},
{value: 'KDM4B', id: 'KDM4B'},
{value: 'KDM4C', id: 'KDM4C'},
{value: 'KDM4D', id: 'KDM4D'},
{value: 'KDM4E', id: 'KDM4E'},
{value: 'KDM4F', id: 'KDM4F'},
{value: 'KDM5A', id: 'KDM5A'},
{value: 'KDM5B', id: 'KDM5B'},
{value: 'KDM5C', id: 'KDM5C'},
{value: 'KDM5D', id: 'KDM5D'},
{value: 'KDM6A', id: 'KDM6A'},
{value: 'KDM6B', id: 'KDM6B'},
{value: 'KDM7A', id: 'KDM7A'},
{value: 'KDM7A-DT', id: 'KDM7A-DT'},
{value: 'KDM8', id: 'KDM8'},
{value: 'KDR', id: 'KDR'},
{value: 'KDSR', id: 'KDSR'},
{value: 'KDSR-DT', id: 'KDSR-DT'},
{value: 'KEAP1', id: 'KEAP1'},
{value: 'KEL', id: 'KEL'},
{value: 'KERA', id: 'KERA'},
{value: 'KHDC1', id: 'KHDC1'},
{value: 'KHDC1-AS1', id: 'KHDC1-AS1'},
{value: 'KHDC1L', id: 'KHDC1L'},
{value: 'KHDC1P1', id: 'KHDC1P1'},
{value: 'KHDC3L', id: 'KHDC3L'},
{value: 'KHDC4', id: 'KHDC4'},
{value: 'KHDRBS1', id: 'KHDRBS1'},
{value: 'KHDRBS2', id: 'KHDRBS2'},
{value: 'KHDRBS2-OT1', id: 'KHDRBS2-OT1'},
{value: 'KHDRBS3', id: 'KHDRBS3'},
{value: 'KHK', id: 'KHK'},
{value: 'KHNYN', id: 'KHNYN'},
{value: 'KHSRP', id: 'KHSRP'},
{value: 'KHSRPP1', id: 'KHSRPP1'},
{value: 'KIAA0040', id: 'KIAA0040'},
{value: 'KIAA0087', id: 'KIAA0087'},
{value: 'KIAA0100', id: 'KIAA0100'},
{value: 'KIAA0232', id: 'KIAA0232'},
{value: 'KIAA0319', id: 'KIAA0319'},
{value: 'KIAA0319L', id: 'KIAA0319L'},
{value: 'KIAA0408', id: 'KIAA0408'},
{value: 'KIAA0513', id: 'KIAA0513'},
{value: 'KIAA0586', id: 'KIAA0586'},
{value: 'KIAA0753', id: 'KIAA0753'},
{value: 'KIAA0825', id: 'KIAA0825'},
{value: 'KIAA0895', id: 'KIAA0895'},
{value: 'KIAA0895L', id: 'KIAA0895L'},
{value: 'KIAA0895LP1', id: 'KIAA0895LP1'},
{value: 'KIAA0930', id: 'KIAA0930'},
{value: 'KIAA1109', id: 'KIAA1109'},
{value: 'KIAA1143', id: 'KIAA1143'},
{value: 'KIAA1143P1', id: 'KIAA1143P1'},
{value: 'KIAA1143P2', id: 'KIAA1143P2'},
{value: 'KIAA1191', id: 'KIAA1191'},
{value: 'KIAA1217', id: 'KIAA1217'},
{value: 'KIAA1328', id: 'KIAA1328'},
{value: 'KIAA1522', id: 'KIAA1522'},
{value: 'KIAA1549', id: 'KIAA1549'},
{value: 'KIAA1549L', id: 'KIAA1549L'},
{value: 'KIAA1586', id: 'KIAA1586'},
{value: 'KIAA1614', id: 'KIAA1614'},
{value: 'KIAA1614-AS1', id: 'KIAA1614-AS1'},
{value: 'KIAA1671', id: 'KIAA1671'},
{value: 'KIAA1671-AS1', id: 'KIAA1671-AS1'},
{value: 'KIAA1755', id: 'KIAA1755'},
{value: 'KIAA1958', id: 'KIAA1958'},
{value: 'KIAA2012', id: 'KIAA2012'},
{value: 'KIAA2013', id: 'KIAA2013'},
{value: 'KIAA2026', id: 'KIAA2026'},
{value: 'KICS2', id: 'KICS2'},
{value: 'KIDINS220', id: 'KIDINS220'},
{value: 'KIF11', id: 'KIF11'},
{value: 'KIF12', id: 'KIF12'},
{value: 'KIF13A', id: 'KIF13A'},
{value: 'KIF13B', id: 'KIF13B'},
{value: 'KIF14', id: 'KIF14'},
{value: 'KIF15', id: 'KIF15'},
{value: 'KIF16B', id: 'KIF16B'},
{value: 'KIF17', id: 'KIF17'},
{value: 'KIF18A', id: 'KIF18A'},
{value: 'KIF18B', id: 'KIF18B'},
{value: 'KIF18B-DT', id: 'KIF18B-DT'},
{value: 'KIF18BP1', id: 'KIF18BP1'},
{value: 'KIF19', id: 'KIF19'},
{value: 'KIF19BP', id: 'KIF19BP'},
{value: 'KIF1A', id: 'KIF1A'},
{value: 'KIF1B', id: 'KIF1B'},
{value: 'KIF1C', id: 'KIF1C'},
{value: 'KIF1C-AS1', id: 'KIF1C-AS1'},
{value: 'KIF20A', id: 'KIF20A'},
{value: 'KIF20B', id: 'KIF20B'},
{value: 'KIF21A', id: 'KIF21A'},
{value: 'KIF21B', id: 'KIF21B'},
{value: 'KIF22', id: 'KIF22'},
{value: 'KIF23', id: 'KIF23'},
{value: 'KIF23-AS1', id: 'KIF23-AS1'},
{value: 'KIF24', id: 'KIF24'},
{value: 'KIF25', id: 'KIF25'},
{value: 'KIF25-AS1', id: 'KIF25-AS1'},
{value: 'KIF26A', id: 'KIF26A'},
{value: 'KIF26A-DT', id: 'KIF26A-DT'},
{value: 'KIF26B', id: 'KIF26B'},
{value: 'KIF26B-AS1', id: 'KIF26B-AS1'},
{value: 'KIF27', id: 'KIF27'},
{value: 'KIF28P', id: 'KIF28P'},
{value: 'KIF2A', id: 'KIF2A'},
{value: 'KIF2B', id: 'KIF2B'},
{value: 'KIF2C', id: 'KIF2C'},
{value: 'KIF3A', id: 'KIF3A'},
{value: 'KIF3AP1', id: 'KIF3AP1'},
{value: 'KIF3B', id: 'KIF3B'},
{value: 'KIF3C', id: 'KIF3C'},
{value: 'KIF4A', id: 'KIF4A'},
{value: 'KIF4B', id: 'KIF4B'},
{value: 'KIF5A', id: 'KIF5A'},
{value: 'KIF5B', id: 'KIF5B'},
{value: 'KIF5C', id: 'KIF5C'},
{value: 'KIF5C-AS1', id: 'KIF5C-AS1'},
{value: 'KIF6', id: 'KIF6'},
{value: 'KIF7', id: 'KIF7'},
{value: 'KIF9', id: 'KIF9'},
{value: 'KIF9-AS1', id: 'KIF9-AS1'},
{value: 'KIFAP3', id: 'KIFAP3'},
{value: 'KIFBP', id: 'KIFBP'},
{value: 'KIFC1', id: 'KIFC1'},
{value: 'KIFC2', id: 'KIFC2'},
{value: 'KIFC3', id: 'KIFC3'},
{value: 'KIN', id: 'KIN'},
{value: 'KIR2DL1', id: 'KIR2DL1'},
{value: 'KIR2DL3', id: 'KIR2DL3'},
{value: 'KIR2DL4', id: 'KIR2DL4'},
{value: 'KIR2DP1', id: 'KIR2DP1'},
{value: 'KIR2DS4', id: 'KIR2DS4'},
{value: 'KIR3DL1', id: 'KIR3DL1'},
{value: 'KIR3DL2', id: 'KIR3DL2'},
{value: 'KIR3DL3', id: 'KIR3DL3'},
{value: 'KIR3DX1', id: 'KIR3DX1'},
{value: 'KIRREL1', id: 'KIRREL1'},
{value: 'KIRREL1-IT1', id: 'KIRREL1-IT1'},
{value: 'KIRREL2', id: 'KIRREL2'},
{value: 'KIRREL3', id: 'KIRREL3'},
{value: 'KIRREL3-AS1', id: 'KIRREL3-AS1'},
{value: 'KIRREL3-AS3', id: 'KIRREL3-AS3'},
{value: 'KISS1', id: 'KISS1'},
{value: 'KISS1R', id: 'KISS1R'},
{value: 'KIT', id: 'KIT'},
{value: 'KITLG', id: 'KITLG'},
{value: 'KIZ', id: 'KIZ'},
{value: 'KIZ-AS1', id: 'KIZ-AS1'},
{value: 'KL', id: 'KL'},
{value: 'KLB', id: 'KLB'},
{value: 'KLC1', id: 'KLC1'},
{value: 'KLC2', id: 'KLC2'},
{value: 'KLC2-AS2', id: 'KLC2-AS2'},
{value: 'KLC3', id: 'KLC3'},
{value: 'KLC4', id: 'KLC4'},
{value: 'KLC4-AS1', id: 'KLC4-AS1'},
{value: 'KLF1', id: 'KLF1'},
{value: 'KLF10', id: 'KLF10'},
{value: 'KLF11', id: 'KLF11'},
{value: 'KLF12', id: 'KLF12'},
{value: 'KLF13', id: 'KLF13'},
{value: 'KLF14', id: 'KLF14'},
{value: 'KLF15', id: 'KLF15'},
{value: 'KLF16', id: 'KLF16'},
{value: 'KLF17', id: 'KLF17'},
{value: 'KLF17P1', id: 'KLF17P1'},
{value: 'KLF18', id: 'KLF18'},
{value: 'KLF2', id: 'KLF2'},
{value: 'KLF2-DT', id: 'KLF2-DT'},
{value: 'KLF2P1', id: 'KLF2P1'},
{value: 'KLF2P2', id: 'KLF2P2'},
{value: 'KLF2P3', id: 'KLF2P3'},
{value: 'KLF2P4', id: 'KLF2P4'},
{value: 'KLF3', id: 'KLF3'},
{value: 'KLF3-AS1', id: 'KLF3-AS1'},
{value: 'KLF3P1', id: 'KLF3P1'},
{value: 'KLF3P2', id: 'KLF3P2'},
{value: 'KLF4', id: 'KLF4'},
{value: 'KLF4P1', id: 'KLF4P1'},
{value: 'KLF5', id: 'KLF5'},
{value: 'KLF6', id: 'KLF6'},
{value: 'KLF7', id: 'KLF7'},
{value: 'KLF7-IT1', id: 'KLF7-IT1'},
{value: 'KLF8', id: 'KLF8'},
{value: 'KLF8P1', id: 'KLF8P1'},
{value: 'KLF9', id: 'KLF9'},
{value: 'KLHDC1', id: 'KLHDC1'},
{value: 'KLHDC10', id: 'KLHDC10'},
{value: 'KLHDC2', id: 'KLHDC2'},
{value: 'KLHDC3', id: 'KLHDC3'},
{value: 'KLHDC4', id: 'KLHDC4'},
{value: 'KLHDC7A', id: 'KLHDC7A'},
{value: 'KLHDC7B', id: 'KLHDC7B'},
{value: 'KLHDC7B-DT', id: 'KLHDC7B-DT'},
{value: 'KLHDC8A', id: 'KLHDC8A'},
{value: 'KLHDC8B', id: 'KLHDC8B'},
{value: 'KLHDC9', id: 'KLHDC9'},
{value: 'KLHL1', id: 'KLHL1'},
{value: 'KLHL10', id: 'KLHL10'},
{value: 'KLHL11', id: 'KLHL11'},
{value: 'KLHL12', id: 'KLHL12'},
{value: 'KLHL13', id: 'KLHL13'},
{value: 'KLHL14', id: 'KLHL14'},
{value: 'KLHL15', id: 'KLHL15'},
{value: 'KLHL17', id: 'KLHL17'},
{value: 'KLHL18', id: 'KLHL18'},
{value: 'KLHL2', id: 'KLHL2'},
{value: 'KLHL20', id: 'KLHL20'},
{value: 'KLHL21', id: 'KLHL21'},
{value: 'KLHL22', id: 'KLHL22'},
{value: 'KLHL23', id: 'KLHL23'},
{value: 'KLHL24', id: 'KLHL24'},
{value: 'KLHL25', id: 'KLHL25'},
{value: 'KLHL26', id: 'KLHL26'},
{value: 'KLHL28', id: 'KLHL28'},
{value: 'KLHL29', id: 'KLHL29'},
{value: 'KLHL2P1', id: 'KLHL2P1'},
{value: 'KLHL3', id: 'KLHL3'},
{value: 'KLHL30', id: 'KLHL30'},
{value: 'KLHL30-AS1', id: 'KLHL30-AS1'},
{value: 'KLHL31', id: 'KLHL31'},
{value: 'KLHL32', id: 'KLHL32'},
{value: 'KLHL33', id: 'KLHL33'},
{value: 'KLHL34', id: 'KLHL34'},
{value: 'KLHL35', id: 'KLHL35'},
{value: 'KLHL36', id: 'KLHL36'},
{value: 'KLHL38', id: 'KLHL38'},
{value: 'KLHL4', id: 'KLHL4'},
{value: 'KLHL40', id: 'KLHL40'},
{value: 'KLHL41', id: 'KLHL41'},
{value: 'KLHL42', id: 'KLHL42'},
{value: 'KLHL5', id: 'KLHL5'},
{value: 'KLHL6', id: 'KLHL6'},
{value: 'KLHL7', id: 'KLHL7'},
{value: 'KLHL7-DT', id: 'KLHL7-DT'},
{value: 'KLHL8', id: 'KLHL8'},
{value: 'KLHL9', id: 'KLHL9'},
{value: 'KLK1', id: 'KLK1'},
{value: 'KLK10', id: 'KLK10'},
{value: 'KLK11', id: 'KLK11'},
{value: 'KLK12', id: 'KLK12'},
{value: 'KLK13', id: 'KLK13'},
{value: 'KLK14', id: 'KLK14'},
{value: 'KLK15', id: 'KLK15'},
{value: 'KLK2', id: 'KLK2'},
{value: 'KLK3', id: 'KLK3'},
{value: 'KLK4', id: 'KLK4'},
{value: 'KLK5', id: 'KLK5'},
{value: 'KLK6', id: 'KLK6'},
{value: 'KLK7', id: 'KLK7'},
{value: 'KLK8', id: 'KLK8'},
{value: 'KLKB1', id: 'KLKB1'},
{value: 'KLKP1', id: 'KLKP1'},
{value: 'KLLN', id: 'KLLN'},
{value: 'KLRA1P', id: 'KLRA1P'},
{value: 'KLRB1', id: 'KLRB1'},
{value: 'KLRC1', id: 'KLRC1'},
{value: 'KLRC2', id: 'KLRC2'},
{value: 'KLRC3', id: 'KLRC3'},
{value: 'KLRC4', id: 'KLRC4'},
{value: 'KLRC4-KLRK1', id: 'KLRC4-KLRK1'},
{value: 'KLRD1', id: 'KLRD1'},
{value: 'KLRF1', id: 'KLRF1'},
{value: 'KLRF2', id: 'KLRF2'},
{value: 'KLRG1', id: 'KLRG1'},
{value: 'KLRG2', id: 'KLRG2'},
{value: 'KLRK1', id: 'KLRK1'},
{value: 'KLRK1-AS1', id: 'KLRK1-AS1'},
{value: 'KMO', id: 'KMO'},
{value: 'KMT2A', id: 'KMT2A'},
{value: 'KMT2B', id: 'KMT2B'},
{value: 'KMT2C', id: 'KMT2C'},
{value: 'KMT2D', id: 'KMT2D'},
{value: 'KMT2E', id: 'KMT2E'},
{value: 'KMT2E-AS1', id: 'KMT2E-AS1'},
{value: 'KMT5A', id: 'KMT5A'},
{value: 'KMT5AP1', id: 'KMT5AP1'},
{value: 'KMT5AP2', id: 'KMT5AP2'},
{value: 'KMT5AP3', id: 'KMT5AP3'},
{value: 'KMT5B', id: 'KMT5B'},
{value: 'KMT5C', id: 'KMT5C'},
{value: 'KNCN', id: 'KNCN'},
{value: 'KNDC1', id: 'KNDC1'},
{value: 'KNG1', id: 'KNG1'},
{value: 'KNL1', id: 'KNL1'},
{value: 'KNOP1', id: 'KNOP1'},
{value: 'KNOP1P1', id: 'KNOP1P1'},
{value: 'KNOP1P2', id: 'KNOP1P2'},
{value: 'KNOP1P3', id: 'KNOP1P3'},
{value: 'KNSTRN', id: 'KNSTRN'},
{value: 'KNTC1', id: 'KNTC1'},
{value: 'KPNA1', id: 'KPNA1'},
{value: 'KPNA2', id: 'KPNA2'},
{value: 'KPNA2P1', id: 'KPNA2P1'},
{value: 'KPNA2P2', id: 'KPNA2P2'},
{value: 'KPNA2P3', id: 'KPNA2P3'},
{value: 'KPNA3', id: 'KPNA3'},
{value: 'KPNA4', id: 'KPNA4'},
{value: 'KPNA5', id: 'KPNA5'},
{value: 'KPNA6', id: 'KPNA6'},
{value: 'KPNA7', id: 'KPNA7'},
{value: 'KPNB1', id: 'KPNB1'},
{value: 'KPNB1-DT', id: 'KPNB1-DT'},
{value: 'KPRP', id: 'KPRP'},
{value: 'KPTN', id: 'KPTN'},
{value: 'KRAS', id: 'KRAS'},
{value: 'KRASP1', id: 'KRASP1'},
{value: 'KRBA1', id: 'KRBA1'},
{value: 'KRBA2', id: 'KRBA2'},
{value: 'KRBOX1', id: 'KRBOX1'},
{value: 'KRBOX1-AS1', id: 'KRBOX1-AS1'},
{value: 'KRBOX4', id: 'KRBOX4'},
{value: 'KRCC1', id: 'KRCC1'},
{value: 'KREMEN1', id: 'KREMEN1'},
{value: 'KREMEN2', id: 'KREMEN2'},
{value: 'KRI1', id: 'KRI1'},
{value: 'KRIT1', id: 'KRIT1'},
{value: 'KRR1', id: 'KRR1'},
{value: 'KRT1', id: 'KRT1'},
{value: 'KRT10', id: 'KRT10'},
{value: 'KRT10-AS1', id: 'KRT10-AS1'},
{value: 'KRT12', id: 'KRT12'},
{value: 'KRT125P', id: 'KRT125P'},
{value: 'KRT126P', id: 'KRT126P'},
{value: 'KRT127P', id: 'KRT127P'},
{value: 'KRT128P', id: 'KRT128P'},
{value: 'KRT13', id: 'KRT13'},
{value: 'KRT14', id: 'KRT14'},
{value: 'KRT15', id: 'KRT15'},
{value: 'KRT16', id: 'KRT16'},
{value: 'KRT16P1', id: 'KRT16P1'},
{value: 'KRT16P2', id: 'KRT16P2'},
{value: 'KRT16P3', id: 'KRT16P3'},
{value: 'KRT16P4', id: 'KRT16P4'},
{value: 'KRT16P5', id: 'KRT16P5'},
{value: 'KRT16P6', id: 'KRT16P6'},
{value: 'KRT17', id: 'KRT17'},
{value: 'KRT17P1', id: 'KRT17P1'},
{value: 'KRT17P2', id: 'KRT17P2'},
{value: 'KRT17P3', id: 'KRT17P3'},
{value: 'KRT17P4', id: 'KRT17P4'},
{value: 'KRT17P5', id: 'KRT17P5'},
{value: 'KRT17P6', id: 'KRT17P6'},
{value: 'KRT17P7', id: 'KRT17P7'},
{value: 'KRT17P8', id: 'KRT17P8'},
{value: 'KRT18', id: 'KRT18'},
{value: 'KRT18P1', id: 'KRT18P1'},
{value: 'KRT18P12', id: 'KRT18P12'},
{value: 'KRT18P13', id: 'KRT18P13'},
{value: 'KRT18P14', id: 'KRT18P14'},
{value: 'KRT18P15', id: 'KRT18P15'},
{value: 'KRT18P16', id: 'KRT18P16'},
{value: 'KRT18P17', id: 'KRT18P17'},
{value: 'KRT18P19', id: 'KRT18P19'},
{value: 'KRT18P2', id: 'KRT18P2'},
{value: 'KRT18P20', id: 'KRT18P20'},
{value: 'KRT18P21', id: 'KRT18P21'},
{value: 'KRT18P22', id: 'KRT18P22'},
{value: 'KRT18P23', id: 'KRT18P23'},
{value: 'KRT18P24', id: 'KRT18P24'},
{value: 'KRT18P25', id: 'KRT18P25'},
{value: 'KRT18P26', id: 'KRT18P26'},
{value: 'KRT18P27', id: 'KRT18P27'},
{value: 'KRT18P28', id: 'KRT18P28'},
{value: 'KRT18P29', id: 'KRT18P29'},
{value: 'KRT18P3', id: 'KRT18P3'},
{value: 'KRT18P31', id: 'KRT18P31'},
{value: 'KRT18P32', id: 'KRT18P32'},
{value: 'KRT18P33', id: 'KRT18P33'},
{value: 'KRT18P34', id: 'KRT18P34'},
{value: 'KRT18P35', id: 'KRT18P35'},
{value: 'KRT18P36', id: 'KRT18P36'},
{value: 'KRT18P39', id: 'KRT18P39'},
{value: 'KRT18P4', id: 'KRT18P4'},
{value: 'KRT18P40', id: 'KRT18P40'},
{value: 'KRT18P41', id: 'KRT18P41'},
{value: 'KRT18P42', id: 'KRT18P42'},
{value: 'KRT18P43', id: 'KRT18P43'},
{value: 'KRT18P45', id: 'KRT18P45'},
{value: 'KRT18P46', id: 'KRT18P46'},
{value: 'KRT18P47', id: 'KRT18P47'},
{value: 'KRT18P5', id: 'KRT18P5'},
{value: 'KRT18P50', id: 'KRT18P50'},
{value: 'KRT18P52', id: 'KRT18P52'},
{value: 'KRT18P54', id: 'KRT18P54'},
{value: 'KRT18P55', id: 'KRT18P55'},
{value: 'KRT18P56', id: 'KRT18P56'},
{value: 'KRT18P57', id: 'KRT18P57'},
{value: 'KRT18P58', id: 'KRT18P58'},
{value: 'KRT18P59', id: 'KRT18P59'},
{value: 'KRT18P6', id: 'KRT18P6'},
{value: 'KRT18P60', id: 'KRT18P60'},
{value: 'KRT18P61', id: 'KRT18P61'},
{value: 'KRT18P62', id: 'KRT18P62'},
{value: 'KRT18P63', id: 'KRT18P63'},
{value: 'KRT18P64', id: 'KRT18P64'},
{value: 'KRT18P65', id: 'KRT18P65'},
{value: 'KRT18P66', id: 'KRT18P66'},
{value: 'KRT18P67', id: 'KRT18P67'},
{value: 'KRT18P7', id: 'KRT18P7'},
{value: 'KRT18P8', id: 'KRT18P8'},
{value: 'KRT18P9', id: 'KRT18P9'},
{value: 'KRT19', id: 'KRT19'},
{value: 'KRT19P1', id: 'KRT19P1'},
{value: 'KRT19P2', id: 'KRT19P2'},
{value: 'KRT19P3', id: 'KRT19P3'},
{value: 'KRT19P4', id: 'KRT19P4'},
{value: 'KRT19P6', id: 'KRT19P6'},
{value: 'KRT2', id: 'KRT2'},
{value: 'KRT20', id: 'KRT20'},
{value: 'KRT222', id: 'KRT222'},
{value: 'KRT223P', id: 'KRT223P'},
{value: 'KRT224P', id: 'KRT224P'},
{value: 'KRT23', id: 'KRT23'},
{value: 'KRT24', id: 'KRT24'},
{value: 'KRT25', id: 'KRT25'},
{value: 'KRT26', id: 'KRT26'},
{value: 'KRT27', id: 'KRT27'},
{value: 'KRT28', id: 'KRT28'},
{value: 'KRT3', id: 'KRT3'},
{value: 'KRT31', id: 'KRT31'},
{value: 'KRT32', id: 'KRT32'},
{value: 'KRT33A', id: 'KRT33A'},
{value: 'KRT33B', id: 'KRT33B'},
{value: 'KRT34', id: 'KRT34'},
{value: 'KRT35', id: 'KRT35'},
{value: 'KRT36', id: 'KRT36'},
{value: 'KRT37', id: 'KRT37'},
{value: 'KRT38', id: 'KRT38'},
{value: 'KRT39', id: 'KRT39'},
{value: 'KRT4', id: 'KRT4'},
{value: 'KRT40', id: 'KRT40'},
{value: 'KRT42P', id: 'KRT42P'},
{value: 'KRT43P', id: 'KRT43P'},
{value: 'KRT5', id: 'KRT5'},
{value: 'KRT6A', id: 'KRT6A'},
{value: 'KRT6B', id: 'KRT6B'},
{value: 'KRT6C', id: 'KRT6C'},
{value: 'KRT7', id: 'KRT7'},
{value: 'KRT7-AS', id: 'KRT7-AS'},
{value: 'KRT71', id: 'KRT71'},
{value: 'KRT72', id: 'KRT72'},
{value: 'KRT73', id: 'KRT73'},
{value: 'KRT73-AS1', id: 'KRT73-AS1'},
{value: 'KRT74', id: 'KRT74'},
{value: 'KRT75', id: 'KRT75'},
{value: 'KRT76', id: 'KRT76'},
{value: 'KRT77', id: 'KRT77'},
{value: 'KRT78', id: 'KRT78'},
{value: 'KRT79', id: 'KRT79'},
{value: 'KRT8', id: 'KRT8'},
{value: 'KRT80', id: 'KRT80'},
{value: 'KRT81', id: 'KRT81'},
{value: 'KRT82', id: 'KRT82'},
{value: 'KRT83', id: 'KRT83'},
{value: 'KRT84', id: 'KRT84'},
{value: 'KRT85', id: 'KRT85'},
{value: 'KRT86', id: 'KRT86'},
{value: 'KRT87P', id: 'KRT87P'},
{value: 'KRT89P', id: 'KRT89P'},
{value: 'KRT8P1', id: 'KRT8P1'},
{value: 'KRT8P10', id: 'KRT8P10'},
{value: 'KRT8P11', id: 'KRT8P11'},
{value: 'KRT8P12', id: 'KRT8P12'},
{value: 'KRT8P13', id: 'KRT8P13'},
{value: 'KRT8P15', id: 'KRT8P15'},
{value: 'KRT8P18', id: 'KRT8P18'},
{value: 'KRT8P2', id: 'KRT8P2'},
{value: 'KRT8P20', id: 'KRT8P20'},
{value: 'KRT8P21', id: 'KRT8P21'},
{value: 'KRT8P23', id: 'KRT8P23'},
{value: 'KRT8P24', id: 'KRT8P24'},
{value: 'KRT8P25', id: 'KRT8P25'},
{value: 'KRT8P26', id: 'KRT8P26'},
{value: 'KRT8P28', id: 'KRT8P28'},
{value: 'KRT8P29', id: 'KRT8P29'},
{value: 'KRT8P30', id: 'KRT8P30'},
{value: 'KRT8P31', id: 'KRT8P31'},
{value: 'KRT8P32', id: 'KRT8P32'},
{value: 'KRT8P33', id: 'KRT8P33'},
{value: 'KRT8P34', id: 'KRT8P34'},
{value: 'KRT8P35', id: 'KRT8P35'},
{value: 'KRT8P36', id: 'KRT8P36'},
{value: 'KRT8P37', id: 'KRT8P37'},
{value: 'KRT8P39', id: 'KRT8P39'},
{value: 'KRT8P4', id: 'KRT8P4'},
{value: 'KRT8P41', id: 'KRT8P41'},
{value: 'KRT8P44', id: 'KRT8P44'},
{value: 'KRT8P45', id: 'KRT8P45'},
{value: 'KRT8P46', id: 'KRT8P46'},
{value: 'KRT8P49', id: 'KRT8P49'},
{value: 'KRT8P51', id: 'KRT8P51'},
{value: 'KRT8P52', id: 'KRT8P52'},
{value: 'KRT8P7', id: 'KRT8P7'},
{value: 'KRT9', id: 'KRT9'},
{value: 'KRTAP1-1', id: 'KRTAP1-1'},
{value: 'KRTAP1-3', id: 'KRTAP1-3'},
{value: 'KRTAP1-4', id: 'KRTAP1-4'},
{value: 'KRTAP1-5', id: 'KRTAP1-5'},
{value: 'KRTAP10-1', id: 'KRTAP10-1'},
{value: 'KRTAP10-10', id: 'KRTAP10-10'},
{value: 'KRTAP10-12', id: 'KRTAP10-12'},
{value: 'KRTAP10-2', id: 'KRTAP10-2'},
{value: 'KRTAP10-3', id: 'KRTAP10-3'},
{value: 'KRTAP10-4', id: 'KRTAP10-4'},
{value: 'KRTAP10-5', id: 'KRTAP10-5'},
{value: 'KRTAP10-6', id: 'KRTAP10-6'},
{value: 'KRTAP10-7', id: 'KRTAP10-7'},
{value: 'KRTAP10-8', id: 'KRTAP10-8'},
{value: 'KRTAP10-9', id: 'KRTAP10-9'},
{value: 'KRTAP11-1', id: 'KRTAP11-1'},
{value: 'KRTAP12-1', id: 'KRTAP12-1'},
{value: 'KRTAP12-2', id: 'KRTAP12-2'},
{value: 'KRTAP12-3', id: 'KRTAP12-3'},
{value: 'KRTAP12-4', id: 'KRTAP12-4'},
{value: 'KRTAP12-6P', id: 'KRTAP12-6P'},
{value: 'KRTAP13-1', id: 'KRTAP13-1'},
{value: 'KRTAP13-2', id: 'KRTAP13-2'},
{value: 'KRTAP13-3', id: 'KRTAP13-3'},
{value: 'KRTAP13-4', id: 'KRTAP13-4'},
{value: 'KRTAP13-5P', id: 'KRTAP13-5P'},
{value: 'KRTAP13-6P', id: 'KRTAP13-6P'},
{value: 'KRTAP15-1', id: 'KRTAP15-1'},
{value: 'KRTAP16-1', id: 'KRTAP16-1'},
{value: 'KRTAP17-1', id: 'KRTAP17-1'},
{value: 'KRTAP19-1', id: 'KRTAP19-1'},
{value: 'KRTAP19-10P', id: 'KRTAP19-10P'},
{value: 'KRTAP19-2', id: 'KRTAP19-2'},
{value: 'KRTAP19-3', id: 'KRTAP19-3'},
{value: 'KRTAP19-4', id: 'KRTAP19-4'},
{value: 'KRTAP19-5', id: 'KRTAP19-5'},
{value: 'KRTAP19-6', id: 'KRTAP19-6'},
{value: 'KRTAP19-7', id: 'KRTAP19-7'},
{value: 'KRTAP19-8', id: 'KRTAP19-8'},
{value: 'KRTAP19-9P', id: 'KRTAP19-9P'},
{value: 'KRTAP2-1', id: 'KRTAP2-1'},
{value: 'KRTAP2-2', id: 'KRTAP2-2'},
{value: 'KRTAP2-3', id: 'KRTAP2-3'},
{value: 'KRTAP2-4', id: 'KRTAP2-4'},
{value: 'KRTAP2-5P', id: 'KRTAP2-5P'},
{value: 'KRTAP20-1', id: 'KRTAP20-1'},
{value: 'KRTAP20-2', id: 'KRTAP20-2'},
{value: 'KRTAP20-3', id: 'KRTAP20-3'},
{value: 'KRTAP20-4', id: 'KRTAP20-4'},
{value: 'KRTAP21-1', id: 'KRTAP21-1'},
{value: 'KRTAP21-2', id: 'KRTAP21-2'},
{value: 'KRTAP21-3', id: 'KRTAP21-3'},
{value: 'KRTAP21-4P', id: 'KRTAP21-4P'},
{value: 'KRTAP22-1', id: 'KRTAP22-1'},
{value: 'KRTAP23-1', id: 'KRTAP23-1'},
{value: 'KRTAP24-1', id: 'KRTAP24-1'},
{value: 'KRTAP25-1', id: 'KRTAP25-1'},
{value: 'KRTAP26-1', id: 'KRTAP26-1'},
{value: 'KRTAP27-1', id: 'KRTAP27-1'},
{value: 'KRTAP29-1', id: 'KRTAP29-1'},
{value: 'KRTAP3-1', id: 'KRTAP3-1'},
{value: 'KRTAP3-2', id: 'KRTAP3-2'},
{value: 'KRTAP3-3', id: 'KRTAP3-3'},
{value: 'KRTAP3-4P', id: 'KRTAP3-4P'},
{value: 'KRTAP4-1', id: 'KRTAP4-1'},
{value: 'KRTAP4-11', id: 'KRTAP4-11'},
{value: 'KRTAP4-12', id: 'KRTAP4-12'},
{value: 'KRTAP4-16', id: 'KRTAP4-16'},
{value: 'KRTAP4-17P', id: 'KRTAP4-17P'},
{value: 'KRTAP4-2', id: 'KRTAP4-2'},
{value: 'KRTAP4-3', id: 'KRTAP4-3'},
{value: 'KRTAP4-4', id: 'KRTAP4-4'},
{value: 'KRTAP4-5', id: 'KRTAP4-5'},
{value: 'KRTAP4-6', id: 'KRTAP4-6'},
{value: 'KRTAP4-7', id: 'KRTAP4-7'},
{value: 'KRTAP4-8', id: 'KRTAP4-8'},
{value: 'KRTAP4-9', id: 'KRTAP4-9'},
{value: 'KRTAP5-1', id: 'KRTAP5-1'},
{value: 'KRTAP5-11', id: 'KRTAP5-11'},
{value: 'KRTAP5-14P', id: 'KRTAP5-14P'},
{value: 'KRTAP5-2', id: 'KRTAP5-2'},
{value: 'KRTAP5-3', id: 'KRTAP5-3'},
{value: 'KRTAP5-4', id: 'KRTAP5-4'},
{value: 'KRTAP5-5', id: 'KRTAP5-5'},
{value: 'KRTAP5-6', id: 'KRTAP5-6'},
{value: 'KRTAP5-7', id: 'KRTAP5-7'},
{value: 'KRTAP5-8', id: 'KRTAP5-8'},
{value: 'KRTAP5-9', id: 'KRTAP5-9'},
{value: 'KRTAP5-AS1', id: 'KRTAP5-AS1'},
{value: 'KRTAP6-1', id: 'KRTAP6-1'},
{value: 'KRTAP6-2', id: 'KRTAP6-2'},
{value: 'KRTAP6-3', id: 'KRTAP6-3'},
{value: 'KRTAP7-1', id: 'KRTAP7-1'},
{value: 'KRTAP8-1', id: 'KRTAP8-1'},
{value: 'KRTAP8-2P', id: 'KRTAP8-2P'},
{value: 'KRTAP8-3P', id: 'KRTAP8-3P'},
{value: 'KRTAP9-1', id: 'KRTAP9-1'},
{value: 'KRTAP9-10P', id: 'KRTAP9-10P'},
{value: 'KRTAP9-12P', id: 'KRTAP9-12P'},
{value: 'KRTAP9-2', id: 'KRTAP9-2'},
{value: 'KRTAP9-3', id: 'KRTAP9-3'},
{value: 'KRTAP9-4', id: 'KRTAP9-4'},
{value: 'KRTAP9-6', id: 'KRTAP9-6'},
{value: 'KRTAP9-7', id: 'KRTAP9-7'},
{value: 'KRTAP9-8', id: 'KRTAP9-8'},
{value: 'KRTAP9-9', id: 'KRTAP9-9'},
{value: 'KRTCAP2', id: 'KRTCAP2'},
{value: 'KRTCAP2P1', id: 'KRTCAP2P1'},
{value: 'KRTCAP3', id: 'KRTCAP3'},
{value: 'KRTDAP', id: 'KRTDAP'},
{value: 'KSR1', id: 'KSR1'},
{value: 'KSR1P1', id: 'KSR1P1'},
{value: 'KSR2', id: 'KSR2'},
{value: 'KTI12', id: 'KTI12'},
{value: 'KTN1', id: 'KTN1'},
{value: 'KTN1-AS1', id: 'KTN1-AS1'},
{value: 'KU-MEL-3', id: 'KU-MEL-3'},
{value: 'KXD1', id: 'KXD1'},
{value: 'KY', id: 'KY'},
{value: 'KYAT1', id: 'KYAT1'},
{value: 'KYAT3', id: 'KYAT3'},
{value: 'KYNU', id: 'KYNU'},
{value: 'KYNUP2', id: 'KYNUP2'},
{value: 'L1CAM', id: 'L1CAM'},
{value: 'L1TD1', id: 'L1TD1'},
{value: 'L2HGDH', id: 'L2HGDH'},
{value: 'L3HYPDH', id: 'L3HYPDH'},
{value: 'L3MBTL1', id: 'L3MBTL1'},
{value: 'L3MBTL2', id: 'L3MBTL2'},
{value: 'L3MBTL2-AS1', id: 'L3MBTL2-AS1'},
{value: 'L3MBTL3', id: 'L3MBTL3'},
{value: 'L3MBTL4', id: 'L3MBTL4'},
{value: 'L3MBTL4-AS1', id: 'L3MBTL4-AS1'},
{value: 'LACC1', id: 'LACC1'},
{value: 'LACRT', id: 'LACRT'},
{value: 'LACTB', id: 'LACTB'},
{value: 'LACTB2', id: 'LACTB2'},
{value: 'LACTB2-AS1', id: 'LACTB2-AS1'},
{value: 'LACTBL1', id: 'LACTBL1'},
{value: 'LAD1', id: 'LAD1'},
{value: 'LAG3', id: 'LAG3'},
{value: 'LAGE3', id: 'LAGE3'},
{value: 'LAIR1', id: 'LAIR1'},
{value: 'LAIR2', id: 'LAIR2'},
{value: 'LALBA', id: 'LALBA'},
{value: 'LAMA1', id: 'LAMA1'},
{value: 'LAMA2', id: 'LAMA2'},
{value: 'LAMA3', id: 'LAMA3'},
{value: 'LAMA4', id: 'LAMA4'},
{value: 'LAMA4-AS1', id: 'LAMA4-AS1'},
{value: 'LAMA5', id: 'LAMA5'},
{value: 'LAMA5-AS1', id: 'LAMA5-AS1'},
{value: 'LAMB1', id: 'LAMB1'},
{value: 'LAMB2', id: 'LAMB2'},
{value: 'LAMB2P1', id: 'LAMB2P1'},
{value: 'LAMB3', id: 'LAMB3'},
{value: 'LAMB4', id: 'LAMB4'},
{value: 'LAMC1', id: 'LAMC1'},
{value: 'LAMC1-AS1', id: 'LAMC1-AS1'},
{value: 'LAMC2', id: 'LAMC2'},
{value: 'LAMC3', id: 'LAMC3'},
{value: 'LAMP1', id: 'LAMP1'},
{value: 'LAMP2', id: 'LAMP2'},
{value: 'LAMP3', id: 'LAMP3'},
{value: 'LAMP5', id: 'LAMP5'},
{value: 'LAMP5-AS1', id: 'LAMP5-AS1'},
{value: 'LAMTOR1', id: 'LAMTOR1'},
{value: 'LAMTOR2', id: 'LAMTOR2'},
{value: 'LAMTOR3', id: 'LAMTOR3'},
{value: 'LAMTOR3P1', id: 'LAMTOR3P1'},
{value: 'LAMTOR4', id: 'LAMTOR4'},
{value: 'LAMTOR5', id: 'LAMTOR5'},
{value: 'LAMTOR5-AS1', id: 'LAMTOR5-AS1'},
{value: 'LAMTOR5P1', id: 'LAMTOR5P1'},
{value: 'LANCL1', id: 'LANCL1'},
{value: 'LANCL1-AS1', id: 'LANCL1-AS1'},
{value: 'LANCL2', id: 'LANCL2'},
{value: 'LANCL3', id: 'LANCL3'},
{value: 'LAP3', id: 'LAP3'},
{value: 'LAP3P1', id: 'LAP3P1'},
{value: 'LAP3P2', id: 'LAP3P2'},
{value: 'LAPTM4A', id: 'LAPTM4A'},
{value: 'LAPTM4A-DT', id: 'LAPTM4A-DT'},
{value: 'LAPTM4B', id: 'LAPTM4B'},
{value: 'LAPTM4BP2', id: 'LAPTM4BP2'},
{value: 'LAPTM5', id: 'LAPTM5'},
{value: 'LARGE-AS1', id: 'LARGE-AS1'},
{value: 'LARGE-IT1', id: 'LARGE-IT1'},
{value: 'LARGE1', id: 'LARGE1'},
{value: 'LARGE2', id: 'LARGE2'},
{value: 'LARP1', id: 'LARP1'},
{value: 'LARP1B', id: 'LARP1B'},
{value: 'LARP1BP1', id: 'LARP1BP1'},
{value: 'LARP1BP2', id: 'LARP1BP2'},
{value: 'LARP4', id: 'LARP4'},
{value: 'LARP4B', id: 'LARP4B'},
{value: 'LARP6', id: 'LARP6'},
{value: 'LARP7', id: 'LARP7'},
{value: 'LARP7P1', id: 'LARP7P1'},
{value: 'LARP7P3', id: 'LARP7P3'},
{value: 'LARP7P4', id: 'LARP7P4'},
{value: 'LARS1', id: 'LARS1'},
{value: 'LARS2', id: 'LARS2'},
{value: 'LARS2-AS1', id: 'LARS2-AS1'},
{value: 'LAS1L', id: 'LAS1L'},
{value: 'LASP1', id: 'LASP1'},
{value: 'LASTR', id: 'LASTR'},
{value: 'LAT', id: 'LAT'},
{value: 'LAT2', id: 'LAT2'},
{value: 'LATS1', id: 'LATS1'},
{value: 'LATS2', id: 'LATS2'},
{value: 'LATS2-AS1', id: 'LATS2-AS1'},
{value: 'LAX1', id: 'LAX1'},
{value: 'LAYN', id: 'LAYN'},
{value: 'LBH', id: 'LBH'},
{value: 'LBHD1', id: 'LBHD1'},
{value: 'LBHD2', id: 'LBHD2'},
{value: 'LBP', id: 'LBP'},
{value: 'LBR', id: 'LBR'},
{value: 'LBX1', id: 'LBX1'},
{value: 'LBX1-AS1', id: 'LBX1-AS1'},
{value: 'LBX2', id: 'LBX2'},
{value: 'LBX2-AS1', id: 'LBX2-AS1'},
{value: 'LCA5', id: 'LCA5'},
{value: 'LCA5L', id: 'LCA5L'},
{value: 'LCAL1', id: 'LCAL1'},
{value: 'LCAT', id: 'LCAT'},
{value: 'LCE1A', id: 'LCE1A'},
{value: 'LCE1B', id: 'LCE1B'},
{value: 'LCE1C', id: 'LCE1C'},
{value: 'LCE1D', id: 'LCE1D'},
{value: 'LCE1E', id: 'LCE1E'},
{value: 'LCE1F', id: 'LCE1F'},
{value: 'LCE2A', id: 'LCE2A'},
{value: 'LCE2B', id: 'LCE2B'},
{value: 'LCE2C', id: 'LCE2C'},
{value: 'LCE2D', id: 'LCE2D'},
{value: 'LCE3A', id: 'LCE3A'},
{value: 'LCE3B', id: 'LCE3B'},
{value: 'LCE3C', id: 'LCE3C'},
{value: 'LCE3D', id: 'LCE3D'},
{value: 'LCE3E', id: 'LCE3E'},
{value: 'LCE4A', id: 'LCE4A'},
{value: 'LCE5A', id: 'LCE5A'},
{value: 'LCE6A', id: 'LCE6A'},
{value: 'LCEP1', id: 'LCEP1'},
{value: 'LCEP2', id: 'LCEP2'},
{value: 'LCEP3', id: 'LCEP3'},
{value: 'LCEP4', id: 'LCEP4'},
{value: 'LCK', id: 'LCK'},
{value: 'LCLAT1', id: 'LCLAT1'},
{value: 'LCMT1', id: 'LCMT1'},
{value: 'LCMT1-AS1', id: 'LCMT1-AS1'},
{value: 'LCMT1-AS2', id: 'LCMT1-AS2'},
{value: 'LCMT2', id: 'LCMT2'},
{value: 'LCN1', id: 'LCN1'},
{value: 'LCN10', id: 'LCN10'},
{value: 'LCN12', id: 'LCN12'},
{value: 'LCN15', id: 'LCN15'},
{value: 'LCN1P1', id: 'LCN1P1'},
{value: 'LCN1P2', id: 'LCN1P2'},
{value: 'LCN2', id: 'LCN2'},
{value: 'LCN6', id: 'LCN6'},
{value: 'LCN8', id: 'LCN8'},
{value: 'LCN9', id: 'LCN9'},
{value: 'LCNL1', id: 'LCNL1'},
{value: 'LCOR', id: 'LCOR'},
{value: 'LCORL', id: 'LCORL'},
{value: 'LCP1', id: 'LCP1'},
{value: 'LCP2', id: 'LCP2'},
{value: 'LCT', id: 'LCT'},
{value: 'LCT-AS1', id: 'LCT-AS1'},
{value: 'LCTL', id: 'LCTL'},
{value: 'LDAF1', id: 'LDAF1'},
{value: 'LDAH', id: 'LDAH'},
{value: 'LDB1', id: 'LDB1'},
{value: 'LDB2', id: 'LDB2'},
{value: 'LDB3', id: 'LDB3'},
{value: 'LDC1P', id: 'LDC1P'},
{value: 'LDHA', id: 'LDHA'},
{value: 'LDHAL6A', id: 'LDHAL6A'},
{value: 'LDHAL6B', id: 'LDHAL6B'},
{value: 'LDHAL6CP', id: 'LDHAL6CP'},
{value: 'LDHAL6EP', id: 'LDHAL6EP'},
{value: 'LDHAL6FP', id: 'LDHAL6FP'},
{value: 'LDHAP1', id: 'LDHAP1'},
{value: 'LDHAP2', id: 'LDHAP2'},
{value: 'LDHAP3', id: 'LDHAP3'},
{value: 'LDHAP4', id: 'LDHAP4'},
{value: 'LDHB', id: 'LDHB'},
{value: 'LDHBP1', id: 'LDHBP1'},
{value: 'LDHBP3', id: 'LDHBP3'},
{value: 'LDHC', id: 'LDHC'},
{value: 'LDHD', id: 'LDHD'},
{value: 'LDLR', id: 'LDLR'},
{value: 'LDLRAD1', id: 'LDLRAD1'},
{value: 'LDLRAD2', id: 'LDLRAD2'},
{value: 'LDLRAD3', id: 'LDLRAD3'},
{value: 'LDLRAD4', id: 'LDLRAD4'},
{value: 'LDLRAD4-AS1', id: 'LDLRAD4-AS1'},
{value: 'LDLRAP1', id: 'LDLRAP1'},
{value: 'LDOC1', id: 'LDOC1'},
{value: 'LEAP2', id: 'LEAP2'},
{value: 'LECT2', id: 'LECT2'},
{value: 'LEF1', id: 'LEF1'},
{value: 'LEF1-AS1', id: 'LEF1-AS1'},
{value: 'LEFTY1', id: 'LEFTY1'},
{value: 'LEFTY2', id: 'LEFTY2'},
{value: 'LEKR1', id: 'LEKR1'},
{value: 'LELP1', id: 'LELP1'},
{value: 'LEMD1', id: 'LEMD1'},
{value: 'LEMD1-AS1', id: 'LEMD1-AS1'},
{value: 'LEMD1-DT', id: 'LEMD1-DT'},
{value: 'LEMD2', id: 'LEMD2'},
{value: 'LEMD3', id: 'LEMD3'},
{value: 'LENEP', id: 'LENEP'},
{value: 'LENG1', id: 'LENG1'},
{value: 'LENG8', id: 'LENG8'},
{value: 'LENG8-AS1', id: 'LENG8-AS1'},
{value: 'LENG9', id: 'LENG9'},
{value: 'LEO1', id: 'LEO1'},
{value: 'LEP', id: 'LEP'},
{value: 'LEPR', id: 'LEPR'},
{value: 'LEPROT', id: 'LEPROT'},
{value: 'LEPROTL1', id: 'LEPROTL1'},
{value: 'LERFS', id: 'LERFS'},
{value: 'LETM1', id: 'LETM1'},
{value: 'LETM1P2', id: 'LETM1P2'},
{value: 'LETM2', id: 'LETM2'},
{value: 'LETMD1', id: 'LETMD1'},
{value: 'LETR1', id: 'LETR1'},
{value: 'LEUTX', id: 'LEUTX'},
{value: 'LEXM', id: 'LEXM'},
{value: 'LFNG', id: 'LFNG'},
{value: 'LGALS1', id: 'LGALS1'},
{value: 'LGALS12', id: 'LGALS12'},
{value: 'LGALS13', id: 'LGALS13'},
{value: 'LGALS14', id: 'LGALS14'},
{value: 'LGALS16', id: 'LGALS16'},
{value: 'LGALS17A', id: 'LGALS17A'},
{value: 'LGALS2', id: 'LGALS2'},
{value: 'LGALS3', id: 'LGALS3'},
{value: 'LGALS3BP', id: 'LGALS3BP'},
{value: 'LGALS4', id: 'LGALS4'},
{value: 'LGALS7', id: 'LGALS7'},
{value: 'LGALS7B', id: 'LGALS7B'},
{value: 'LGALS8', id: 'LGALS8'},
{value: 'LGALS8-AS1', id: 'LGALS8-AS1'},
{value: 'LGALS9', id: 'LGALS9'},
{value: 'LGALS9B', id: 'LGALS9B'},
{value: 'LGALS9C', id: 'LGALS9C'},
{value: 'LGALS9DP', id: 'LGALS9DP'},
{value: 'LGALSL', id: 'LGALSL'},
{value: 'LGALSL-DT', id: 'LGALSL-DT'},
{value: 'LGI1', id: 'LGI1'},
{value: 'LGI2', id: 'LGI2'},
{value: 'LGI3', id: 'LGI3'},
{value: 'LGI4', id: 'LGI4'},
{value: 'LGMN', id: 'LGMN'},
{value: 'LGMNP1', id: 'LGMNP1'},
{value: 'LGR4', id: 'LGR4'},
{value: 'LGR4-AS1', id: 'LGR4-AS1'},
{value: 'LGR5', id: 'LGR5'},
{value: 'LGR6', id: 'LGR6'},
{value: 'LGSN', id: 'LGSN'},
{value: 'LHB', id: 'LHB'},
{value: 'LHCGR', id: 'LHCGR'},
{value: 'LHFPL2', id: 'LHFPL2'},
{value: 'LHFPL3', id: 'LHFPL3'},
{value: 'LHFPL3-AS2', id: 'LHFPL3-AS2'},
{value: 'LHFPL4', id: 'LHFPL4'},
{value: 'LHFPL5', id: 'LHFPL5'},
{value: 'LHFPL6', id: 'LHFPL6'},
{value: 'LHPP', id: 'LHPP'},
{value: 'LHX1', id: 'LHX1'},
{value: 'LHX1-DT', id: 'LHX1-DT'},
{value: 'LHX2', id: 'LHX2'},
{value: 'LHX3', id: 'LHX3'},
{value: 'LHX4', id: 'LHX4'},
{value: 'LHX5', id: 'LHX5'},
{value: 'LHX5-AS1', id: 'LHX5-AS1'},
{value: 'LHX6', id: 'LHX6'},
{value: 'LHX8', id: 'LHX8'},
{value: 'LHX9', id: 'LHX9'},
{value: 'LIAS', id: 'LIAS'},
{value: 'LIF', id: 'LIF'},
{value: 'LIF-AS1', id: 'LIF-AS1'},
{value: 'LIF-AS2', id: 'LIF-AS2'},
{value: 'LIFR', id: 'LIFR'},
{value: 'LIFR-AS1', id: 'LIFR-AS1'},
{value: 'LIG1', id: 'LIG1'},
{value: 'LIG3', id: 'LIG3'},
{value: 'LIG4', id: 'LIG4'},
{value: 'LILRA1', id: 'LILRA1'},
{value: 'LILRA2', id: 'LILRA2'},
{value: 'LILRA4', id: 'LILRA4'},
{value: 'LILRA5', id: 'LILRA5'},
{value: 'LILRA6', id: 'LILRA6'},
{value: 'LILRB1', id: 'LILRB1'},
{value: 'LILRB1-AS1', id: 'LILRB1-AS1'},
{value: 'LILRB2', id: 'LILRB2'},
{value: 'LILRB3', id: 'LILRB3'},
{value: 'LILRB4', id: 'LILRB4'},
{value: 'LILRB5', id: 'LILRB5'},
{value: 'LILRP1', id: 'LILRP1'},
{value: 'LILRP2', id: 'LILRP2'},
{value: 'LIM2', id: 'LIM2'},
{value: 'LIMA1', id: 'LIMA1'},
{value: 'LIMCH1', id: 'LIMCH1'},
{value: 'LIMD1', id: 'LIMD1'},
{value: 'LIMD1-AS1', id: 'LIMD1-AS1'},
{value: 'LIMD2', id: 'LIMD2'},
{value: 'LIME1', id: 'LIME1'},
{value: 'LIMK1', id: 'LIMK1'},
{value: 'LIMK2', id: 'LIMK2'},
{value: 'LIMS1', id: 'LIMS1'},
{value: 'LIMS1-AS1', id: 'LIMS1-AS1'},
{value: 'LIMS2', id: 'LIMS2'},
{value: 'LIMS3', id: 'LIMS3'},
{value: 'LIMS4', id: 'LIMS4'},
{value: 'LIN28A', id: 'LIN28A'},
{value: 'LIN28AP1', id: 'LIN28AP1'},
{value: 'LIN28AP2', id: 'LIN28AP2'},
{value: 'LIN28B', id: 'LIN28B'},
{value: 'LIN28B-AS1', id: 'LIN28B-AS1'},
{value: 'LIN37', id: 'LIN37'},
{value: 'LIN52', id: 'LIN52'},
{value: 'LIN54', id: 'LIN54'},
{value: 'LIN7A', id: 'LIN7A'},
{value: 'LIN7B', id: 'LIN7B'},
{value: 'LIN7C', id: 'LIN7C'},
{value: 'LIN9', id: 'LIN9'},
{value: 'LINC-PINT', id: 'LINC-PINT'},
{value: 'LINC-ROR', id: 'LINC-ROR'},
{value: 'LINC00028', id: 'LINC00028'},
{value: 'LINC00032', id: 'LINC00032'},
{value: 'LINC00051', id: 'LINC00051'},
{value: 'LINC00092', id: 'LINC00092'},
{value: 'LINC00106', id: 'LINC00106'},
{value: 'LINC00111', id: 'LINC00111'},
{value: 'LINC00112', id: 'LINC00112'},
{value: 'LINC00113', id: 'LINC00113'},
{value: 'LINC00114', id: 'LINC00114'},
{value: 'LINC00115', id: 'LINC00115'},
{value: 'LINC00158', id: 'LINC00158'},
{value: 'LINC00159', id: 'LINC00159'},
{value: 'LINC00160', id: 'LINC00160'},
{value: 'LINC00163', id: 'LINC00163'},
{value: 'LINC00165', id: 'LINC00165'},
{value: 'LINC00167', id: 'LINC00167'},
{value: 'LINC00173', id: 'LINC00173'},
{value: 'LINC00174', id: 'LINC00174'},
{value: 'LINC00184', id: 'LINC00184'},
{value: 'LINC00189', id: 'LINC00189'},
{value: 'LINC00200', id: 'LINC00200'},
{value: 'LINC00205', id: 'LINC00205'},
{value: 'LINC00207', id: 'LINC00207'},
{value: 'LINC00208', id: 'LINC00208'},
{value: 'LINC00210', id: 'LINC00210'},
{value: 'LINC00211', id: 'LINC00211'},
{value: 'LINC00216', id: 'LINC00216'},
{value: 'LINC00221', id: 'LINC00221'},
{value: 'LINC00222', id: 'LINC00222'},
{value: 'LINC00226', id: 'LINC00226'},
{value: 'LINC00229', id: 'LINC00229'},
{value: 'LINC00235', id: 'LINC00235'},
{value: 'LINC00237', id: 'LINC00237'},
{value: 'LINC00239', id: 'LINC00239'},
{value: 'LINC00240', id: 'LINC00240'},
{value: 'LINC00242', id: 'LINC00242'},
{value: 'LINC00243', id: 'LINC00243'},
{value: 'LINC00251', id: 'LINC00251'},
{value: 'LINC00254', id: 'LINC00254'},
{value: 'LINC00261', id: 'LINC00261'},
{value: 'LINC00265', id: 'LINC00265'},
{value: 'LINC00266-1', id: 'LINC00266-1'},
{value: 'LINC00271', id: 'LINC00271'},
{value: 'LINC00272', id: 'LINC00272'},
{value: 'LINC00273', id: 'LINC00273'},
{value: 'LINC00276', id: 'LINC00276'},
{value: 'LINC00278', id: 'LINC00278'},
{value: 'LINC00290', id: 'LINC00290'},
{value: 'LINC00293', id: 'LINC00293'},
{value: 'LINC00294', id: 'LINC00294'},
{value: 'LINC00297', id: 'LINC00297'},
{value: 'LINC00298', id: 'LINC00298'},
{value: 'LINC00299', id: 'LINC00299'},
{value: 'LINC00301', id: 'LINC00301'},
{value: 'LINC00303', id: 'LINC00303'},
{value: 'LINC00304', id: 'LINC00304'},
{value: 'LINC00305', id: 'LINC00305'},
{value: 'LINC00307', id: 'LINC00307'},
{value: 'LINC00308', id: 'LINC00308'},
{value: 'LINC00310', id: 'LINC00310'},
{value: 'LINC00313', id: 'LINC00313'},
{value: 'LINC00314', id: 'LINC00314'},
{value: 'LINC00315', id: 'LINC00315'},
{value: 'LINC00316', id: 'LINC00316'},
{value: 'LINC00317', id: 'LINC00317'},
{value: 'LINC00319', id: 'LINC00319'},
{value: 'LINC00320', id: 'LINC00320'},
{value: 'LINC00322', id: 'LINC00322'},
{value: 'LINC00323', id: 'LINC00323'},
{value: 'LINC00324', id: 'LINC00324'},
{value: 'LINC00326', id: 'LINC00326'},
{value: 'LINC00327', id: 'LINC00327'},
{value: 'LINC00330', id: 'LINC00330'},
{value: 'LINC00331', id: 'LINC00331'},
{value: 'LINC00332', id: 'LINC00332'},
{value: 'LINC00333', id: 'LINC00333'},
{value: 'LINC00334', id: 'LINC00334'},
{value: 'LINC00336', id: 'LINC00336'},
{value: 'LINC00337', id: 'LINC00337'},
{value: 'LINC00339', id: 'LINC00339'},
{value: 'LINC00342', id: 'LINC00342'},
{value: 'LINC00343', id: 'LINC00343'},
{value: 'LINC00345', id: 'LINC00345'},
{value: 'LINC00347', id: 'LINC00347'},
{value: 'LINC00348', id: 'LINC00348'},
{value: 'LINC00349', id: 'LINC00349'},
{value: 'LINC00350', id: 'LINC00350'},
{value: 'LINC00351', id: 'LINC00351'},
{value: 'LINC00352', id: 'LINC00352'},
{value: 'LINC00354', id: 'LINC00354'},
{value: 'LINC00355', id: 'LINC00355'},
{value: 'LINC00358', id: 'LINC00358'},
{value: 'LINC00362', id: 'LINC00362'},
{value: 'LINC00363', id: 'LINC00363'},
{value: 'LINC00364', id: 'LINC00364'},
{value: 'LINC00365', id: 'LINC00365'},
{value: 'LINC00366', id: 'LINC00366'},
{value: 'LINC00368', id: 'LINC00368'},
{value: 'LINC00370', id: 'LINC00370'},
{value: 'LINC00373', id: 'LINC00373'},
{value: 'LINC00374', id: 'LINC00374'},
{value: 'LINC00375', id: 'LINC00375'},
{value: 'LINC00376', id: 'LINC00376'},
{value: 'LINC00377', id: 'LINC00377'},
{value: 'LINC00378', id: 'LINC00378'},
{value: 'LINC00379', id: 'LINC00379'},
{value: 'LINC00380', id: 'LINC00380'},
{value: 'LINC00381', id: 'LINC00381'},
{value: 'LINC00382', id: 'LINC00382'},
{value: 'LINC00383', id: 'LINC00383'},
{value: 'LINC00384', id: 'LINC00384'},
{value: 'LINC00385', id: 'LINC00385'},
{value: 'LINC00387', id: 'LINC00387'},
{value: 'LINC00388', id: 'LINC00388'},
{value: 'LINC00390', id: 'LINC00390'},
{value: 'LINC00391', id: 'LINC00391'},
{value: 'LINC00393', id: 'LINC00393'},
{value: 'LINC00395', id: 'LINC00395'},
{value: 'LINC00397', id: 'LINC00397'},
{value: 'LINC00398', id: 'LINC00398'},
{value: 'LINC00399', id: 'LINC00399'},
{value: 'LINC00400', id: 'LINC00400'},
{value: 'LINC00402', id: 'LINC00402'},
{value: 'LINC00404', id: 'LINC00404'},
{value: 'LINC00407', id: 'LINC00407'},
{value: 'LINC00408', id: 'LINC00408'},
{value: 'LINC00410', id: 'LINC00410'},
{value: 'LINC00415', id: 'LINC00415'},
{value: 'LINC00421', id: 'LINC00421'},
{value: 'LINC00423', id: 'LINC00423'},
{value: 'LINC00424', id: 'LINC00424'},
{value: 'LINC00426', id: 'LINC00426'},
{value: 'LINC00427', id: 'LINC00427'},
{value: 'LINC00428', id: 'LINC00428'},
{value: 'LINC00430', id: 'LINC00430'},
{value: 'LINC00431', id: 'LINC00431'},
{value: 'LINC00433', id: 'LINC00433'},
{value: 'LINC00434', id: 'LINC00434'},
{value: 'LINC00437', id: 'LINC00437'},
{value: 'LINC00440', id: 'LINC00440'},
{value: 'LINC00443', id: 'LINC00443'},
{value: 'LINC00445', id: 'LINC00445'},
{value: 'LINC00448', id: 'LINC00448'},
{value: 'LINC00449', id: 'LINC00449'},
{value: 'LINC00452', id: 'LINC00452'},
{value: 'LINC00454', id: 'LINC00454'},
{value: 'LINC00456', id: 'LINC00456'},
{value: 'LINC00457', id: 'LINC00457'},
{value: 'LINC00458', id: 'LINC00458'},
{value: 'LINC00460', id: 'LINC00460'},
{value: 'LINC00461', id: 'LINC00461'},
{value: 'LINC00462', id: 'LINC00462'},
{value: 'LINC00466', id: 'LINC00466'},
{value: 'LINC00467', id: 'LINC00467'},
{value: 'LINC00469', id: 'LINC00469'},
{value: 'LINC00470', id: 'LINC00470'},
{value: 'LINC00471', id: 'LINC00471'},
{value: 'LINC00472', id: 'LINC00472'},
{value: 'LINC00474', id: 'LINC00474'},
{value: 'LINC00475', id: 'LINC00475'},
{value: 'LINC00476', id: 'LINC00476'},
{value: 'LINC00477', id: 'LINC00477'},
{value: 'LINC00479', id: 'LINC00479'},
{value: 'LINC00482', id: 'LINC00482'},
{value: 'LINC00484', id: 'LINC00484'},
{value: 'LINC00485', id: 'LINC00485'},
{value: 'LINC00486', id: 'LINC00486'},
{value: 'LINC00487', id: 'LINC00487'},
{value: 'LINC00488', id: 'LINC00488'},
{value: 'LINC00489', id: 'LINC00489'},
{value: 'LINC00491', id: 'LINC00491'},
{value: 'LINC00492', id: 'LINC00492'},
{value: 'LINC00494', id: 'LINC00494'},
{value: 'LINC00498', id: 'LINC00498'},
{value: 'LINC00499', id: 'LINC00499'},
{value: 'LINC00501', id: 'LINC00501'},
{value: 'LINC00502', id: 'LINC00502'},
{value: 'LINC00504', id: 'LINC00504'},
{value: 'LINC00506', id: 'LINC00506'},
{value: 'LINC00507', id: 'LINC00507'},
{value: 'LINC00508', id: 'LINC00508'},
{value: 'LINC00511', id: 'LINC00511'},
{value: 'LINC00513', id: 'LINC00513'},
{value: 'LINC00517', id: 'LINC00517'},
{value: 'LINC00518', id: 'LINC00518'},
{value: 'LINC00519', id: 'LINC00519'},
{value: 'LINC00520', id: 'LINC00520'},
{value: 'LINC00523', id: 'LINC00523'},
{value: 'LINC00524', id: 'LINC00524'},
{value: 'LINC00525', id: 'LINC00525'},
{value: 'LINC00526', id: 'LINC00526'},
{value: 'LINC00528', id: 'LINC00528'},
{value: 'LINC00529', id: 'LINC00529'},
{value: 'LINC00533', id: 'LINC00533'},
{value: 'LINC00534', id: 'LINC00534'},
{value: 'LINC00536', id: 'LINC00536'},
{value: 'LINC00539', id: 'LINC00539'},
{value: 'LINC00540', id: 'LINC00540'},
{value: 'LINC00543', id: 'LINC00543'},
{value: 'LINC00544', id: 'LINC00544'},
{value: 'LINC00545', id: 'LINC00545'},
{value: 'LINC00547', id: 'LINC00547'},
{value: 'LINC00550', id: 'LINC00550'},
{value: 'LINC00551', id: 'LINC00551'},
{value: 'LINC00552', id: 'LINC00552'},
{value: 'LINC00554', id: 'LINC00554'},
{value: 'LINC00555', id: 'LINC00555'},
{value: 'LINC00556', id: 'LINC00556'},
{value: 'LINC00557', id: 'LINC00557'},
{value: 'LINC00558', id: 'LINC00558'},
{value: 'LINC00559', id: 'LINC00559'},
{value: 'LINC00560', id: 'LINC00560'},
{value: 'LINC00561', id: 'LINC00561'},
{value: 'LINC00562', id: 'LINC00562'},
{value: 'LINC00563', id: 'LINC00563'},
{value: 'LINC00564', id: 'LINC00564'},
{value: 'LINC00565', id: 'LINC00565'},
{value: 'LINC00566', id: 'LINC00566'},
{value: 'LINC00567', id: 'LINC00567'},
{value: 'LINC00570', id: 'LINC00570'},
{value: 'LINC00571', id: 'LINC00571'},
{value: 'LINC00572', id: 'LINC00572'},
{value: 'LINC00574', id: 'LINC00574'},
{value: 'LINC00575', id: 'LINC00575'},
{value: 'LINC00578', id: 'LINC00578'},
{value: 'LINC00581', id: 'LINC00581'},
{value: 'LINC00582', id: 'LINC00582'},
{value: 'LINC00583', id: 'LINC00583'},
{value: 'LINC00587', id: 'LINC00587'},
{value: 'LINC00588', id: 'LINC00588'},
{value: 'LINC00589', id: 'LINC00589'},
{value: 'LINC00592', id: 'LINC00592'},
{value: 'LINC00596', id: 'LINC00596'},
{value: 'LINC00598', id: 'LINC00598'},
{value: 'LINC00601', id: 'LINC00601'},
{value: 'LINC00602', id: 'LINC00602'},
{value: 'LINC00603', id: 'LINC00603'},
{value: 'LINC00604', id: 'LINC00604'},
{value: 'LINC00605', id: 'LINC00605'},
{value: 'LINC00606', id: 'LINC00606'},
{value: 'LINC00607', id: 'LINC00607'},
{value: 'LINC00608', id: 'LINC00608'},
{value: 'LINC00609', id: 'LINC00609'},
{value: 'LINC00612', id: 'LINC00612'},
{value: 'LINC00613', id: 'LINC00613'},
{value: 'LINC00615', id: 'LINC00615'},
{value: 'LINC00616', id: 'LINC00616'},
{value: 'LINC00620', id: 'LINC00620'},
{value: 'LINC00621', id: 'LINC00621'},
{value: 'LINC00622', id: 'LINC00622'},
{value: 'LINC00623', id: 'LINC00623'},
{value: 'LINC00624', id: 'LINC00624'},
{value: 'LINC00626', id: 'LINC00626'},
{value: 'LINC00628', id: 'LINC00628'},
{value: 'LINC00629', id: 'LINC00629'},
{value: 'LINC00630', id: 'LINC00630'},
{value: 'LINC00632', id: 'LINC00632'},
{value: 'LINC00634', id: 'LINC00634'},
{value: 'LINC00635', id: 'LINC00635'},
{value: 'LINC00636', id: 'LINC00636'},
{value: 'LINC00638', id: 'LINC00638'},
{value: 'LINC00639', id: 'LINC00639'},
{value: 'LINC00640', id: 'LINC00640'},
{value: 'LINC00641', id: 'LINC00641'},
{value: 'LINC00642', id: 'LINC00642'},
{value: 'LINC00643', id: 'LINC00643'},
{value: 'LINC00644', id: 'LINC00644'},
{value: 'LINC00645', id: 'LINC00645'},
{value: 'LINC00648', id: 'LINC00648'},
{value: 'LINC00649', id: 'LINC00649'},
{value: 'LINC00652', id: 'LINC00652'},
{value: 'LINC00653', id: 'LINC00653'},
{value: 'LINC00654', id: 'LINC00654'},
{value: 'LINC00656', id: 'LINC00656'},
{value: 'LINC00658', id: 'LINC00658'},
{value: 'LINC00659', id: 'LINC00659'},
{value: 'LINC00661', id: 'LINC00661'},
{value: 'LINC00662', id: 'LINC00662'},
{value: 'LINC00663', id: 'LINC00663'},
{value: 'LINC00664', id: 'LINC00664'},
{value: 'LINC00665', id: 'LINC00665'},
{value: 'LINC00667', id: 'LINC00667'},
{value: 'LINC00668', id: 'LINC00668'},
{value: 'LINC00670', id: 'LINC00670'},
{value: 'LINC00671', id: 'LINC00671'},
{value: 'LINC00672', id: 'LINC00672'},
{value: 'LINC00674', id: 'LINC00674'},
{value: 'LINC00676', id: 'LINC00676'},
{value: 'LINC00677', id: 'LINC00677'},
{value: 'LINC00680', id: 'LINC00680'},
{value: 'LINC00681', id: 'LINC00681'},
{value: 'LINC00682', id: 'LINC00682'},
{value: 'LINC00683', id: 'LINC00683'},
{value: 'LINC00685', id: 'LINC00685'},
{value: 'LINC00686', id: 'LINC00686'},
{value: 'LINC00687', id: 'LINC00687'},
{value: 'LINC00689', id: 'LINC00689'},
{value: 'LINC00690', id: 'LINC00690'},
{value: 'LINC00691', id: 'LINC00691'},
{value: 'LINC00692', id: 'LINC00692'},
{value: 'LINC00698', id: 'LINC00698'},
{value: 'LINC00700', id: 'LINC00700'},
{value: 'LINC00701', id: 'LINC00701'},
{value: 'LINC00702', id: 'LINC00702'},
{value: 'LINC00703', id: 'LINC00703'},
{value: 'LINC00705', id: 'LINC00705'},
{value: 'LINC00706', id: 'LINC00706'},
{value: 'LINC00707', id: 'LINC00707'},
{value: 'LINC00708', id: 'LINC00708'},
{value: 'LINC00709', id: 'LINC00709'},
{value: 'LINC00824', id: 'LINC00824'},
{value: 'LINC00836', id: 'LINC00836'},
{value: 'LINC00838', id: 'LINC00838'},
{value: 'LINC00839', id: 'LINC00839'},
{value: 'LINC00840', id: 'LINC00840'},
{value: 'LINC00841', id: 'LINC00841'},
{value: 'LINC00842', id: 'LINC00842'},
{value: 'LINC00844', id: 'LINC00844'},
{value: 'LINC00845', id: 'LINC00845'},
{value: 'LINC00847', id: 'LINC00847'},
{value: 'LINC00851', id: 'LINC00851'},
{value: 'LINC00852', id: 'LINC00852'},
{value: 'LINC00853', id: 'LINC00853'},
{value: 'LINC00857', id: 'LINC00857'},
{value: 'LINC00858', id: 'LINC00858'},
{value: 'LINC00861', id: 'LINC00861'},
{value: 'LINC00862', id: 'LINC00862'},
{value: 'LINC00863', id: 'LINC00863'},
{value: 'LINC00865', id: 'LINC00865'},
{value: 'LINC00866', id: 'LINC00866'},
{value: 'LINC00867', id: 'LINC00867'},
{value: 'LINC00868', id: 'LINC00868'},
{value: 'LINC00869', id: 'LINC00869'},
{value: 'LINC00871', id: 'LINC00871'},
{value: 'LINC00877', id: 'LINC00877'},
{value: 'LINC00879', id: 'LINC00879'},
{value: 'LINC00880', id: 'LINC00880'},
{value: 'LINC00881', id: 'LINC00881'},
{value: 'LINC00882', id: 'LINC00882'},
{value: 'LINC00884', id: 'LINC00884'},
{value: 'LINC00885', id: 'LINC00885'},
{value: 'LINC00886', id: 'LINC00886'},
{value: 'LINC00887', id: 'LINC00887'},
{value: 'LINC00888', id: 'LINC00888'},
{value: 'LINC00891', id: 'LINC00891'},
{value: 'LINC00892', id: 'LINC00892'},
{value: 'LINC00893', id: 'LINC00893'},
{value: 'LINC00894', id: 'LINC00894'},
{value: 'LINC00895', id: 'LINC00895'},
{value: 'LINC00896', id: 'LINC00896'},
{value: 'LINC00898', id: 'LINC00898'},
{value: 'LINC00899', id: 'LINC00899'},
{value: 'LINC00900', id: 'LINC00900'},
{value: 'LINC00904', id: 'LINC00904'},
{value: 'LINC00905', id: 'LINC00905'},
{value: 'LINC00906', id: 'LINC00906'},
{value: 'LINC00907', id: 'LINC00907'},
{value: 'LINC00910', id: 'LINC00910'},
{value: 'LINC00917', id: 'LINC00917'},
{value: 'LINC00919', id: 'LINC00919'},
{value: 'LINC00920', id: 'LINC00920'},
{value: 'LINC00921', id: 'LINC00921'},
{value: 'LINC00922', id: 'LINC00922'},
{value: 'LINC00923', id: 'LINC00923'},
{value: 'LINC00924', id: 'LINC00924'},
{value: 'LINC00926', id: 'LINC00926'},
{value: 'LINC00927', id: 'LINC00927'},
{value: 'LINC00928', id: 'LINC00928'},
{value: 'LINC00929', id: 'LINC00929'},
{value: 'LINC00930', id: 'LINC00930'},
{value: 'LINC00933', id: 'LINC00933'},
{value: 'LINC00934', id: 'LINC00934'},
{value: 'LINC00937', id: 'LINC00937'},
{value: 'LINC00939', id: 'LINC00939'},
{value: 'LINC00940', id: 'LINC00940'},
{value: 'LINC00942', id: 'LINC00942'},
{value: 'LINC00943', id: 'LINC00943'},
{value: 'LINC00944', id: 'LINC00944'},
{value: 'LINC00951', id: 'LINC00951'},
{value: 'LINC00954', id: 'LINC00954'},
{value: 'LINC00955', id: 'LINC00955'},
{value: 'LINC00957', id: 'LINC00957'},
{value: 'LINC00958', id: 'LINC00958'},
{value: 'LINC00960', id: 'LINC00960'},
{value: 'LINC00963', id: 'LINC00963'},
{value: 'LINC00964', id: 'LINC00964'},
{value: 'LINC00967', id: 'LINC00967'},
{value: 'LINC00968', id: 'LINC00968'},
{value: 'LINC00970', id: 'LINC00970'},
{value: 'LINC00971', id: 'LINC00971'},
{value: 'LINC00972', id: 'LINC00972'},
{value: 'LINC00974', id: 'LINC00974'},
{value: 'LINC00987', id: 'LINC00987'},
{value: 'LINC00989', id: 'LINC00989'},
{value: 'LINC00992', id: 'LINC00992'},
{value: 'LINC00993', id: 'LINC00993'},
{value: 'LINC00996', id: 'LINC00996'},
{value: 'LINC00997', id: 'LINC00997'},
{value: 'LINC01001', id: 'LINC01001'},
{value: 'LINC01002', id: 'LINC01002'},
{value: 'LINC01003', id: 'LINC01003'},
{value: 'LINC01004', id: 'LINC01004'},
{value: 'LINC01005', id: 'LINC01005'},
{value: 'LINC01006', id: 'LINC01006'},
{value: 'LINC01007', id: 'LINC01007'},
{value: 'LINC01010', id: 'LINC01010'},
{value: 'LINC01011', id: 'LINC01011'},
{value: 'LINC01012', id: 'LINC01012'},
{value: 'LINC01013', id: 'LINC01013'},
{value: 'LINC01014', id: 'LINC01014'},
{value: 'LINC01015', id: 'LINC01015'},
{value: 'LINC01016', id: 'LINC01016'},
{value: 'LINC01017', id: 'LINC01017'},
{value: 'LINC01018', id: 'LINC01018'},
{value: 'LINC01019', id: 'LINC01019'},
{value: 'LINC01020', id: 'LINC01020'},
{value: 'LINC01022', id: 'LINC01022'},
{value: 'LINC01023', id: 'LINC01023'},
{value: 'LINC01029', id: 'LINC01029'},
{value: 'LINC01031', id: 'LINC01031'},
{value: 'LINC01033', id: 'LINC01033'},
{value: 'LINC01034', id: 'LINC01034'},
{value: 'LINC01035', id: 'LINC01035'},
{value: 'LINC01036', id: 'LINC01036'},
{value: 'LINC01038', id: 'LINC01038'},
{value: 'LINC01039', id: 'LINC01039'},
{value: 'LINC01043', id: 'LINC01043'},
{value: 'LINC01044', id: 'LINC01044'},
{value: 'LINC01046', id: 'LINC01046'},
{value: 'LINC01048', id: 'LINC01048'},
{value: 'LINC01049', id: 'LINC01049'},
{value: 'LINC01050', id: 'LINC01050'},
{value: 'LINC01052', id: 'LINC01052'},
{value: 'LINC01053', id: 'LINC01053'},
{value: 'LINC01054', id: 'LINC01054'},
{value: 'LINC01055', id: 'LINC01055'},
{value: 'LINC01058', id: 'LINC01058'},
{value: 'LINC01060', id: 'LINC01060'},
{value: 'LINC01063', id: 'LINC01063'},
{value: 'LINC01065', id: 'LINC01065'},
{value: 'LINC01066', id: 'LINC01066'},
{value: 'LINC01067', id: 'LINC01067'},
{value: 'LINC01068', id: 'LINC01068'},
{value: 'LINC01070', id: 'LINC01070'},
{value: 'LINC01072', id: 'LINC01072'},
{value: 'LINC01074', id: 'LINC01074'},
{value: 'LINC01075', id: 'LINC01075'},
{value: 'LINC01076', id: 'LINC01076'},
{value: 'LINC01078', id: 'LINC01078'},
{value: 'LINC01079', id: 'LINC01079'},
{value: 'LINC01080', id: 'LINC01080'},
{value: 'LINC01081', id: 'LINC01081'},
{value: 'LINC01082', id: 'LINC01082'},
{value: 'LINC01085', id: 'LINC01085'},
{value: 'LINC01087', id: 'LINC01087'},
{value: 'LINC01088', id: 'LINC01088'},
{value: 'LINC01089', id: 'LINC01089'},
{value: 'LINC01090', id: 'LINC01090'},
{value: 'LINC01091', id: 'LINC01091'},
{value: 'LINC01093', id: 'LINC01093'},
{value: 'LINC01094', id: 'LINC01094'},
{value: 'LINC01095', id: 'LINC01095'},
{value: 'LINC01096', id: 'LINC01096'},
{value: 'LINC01097', id: 'LINC01097'},
{value: 'LINC01098', id: 'LINC01098'},
{value: 'LINC01100', id: 'LINC01100'},
{value: 'LINC01101', id: 'LINC01101'},
{value: 'LINC01102', id: 'LINC01102'},
{value: 'LINC01103', id: 'LINC01103'},
{value: 'LINC01104', id: 'LINC01104'},
{value: 'LINC01106', id: 'LINC01106'},
{value: 'LINC01107', id: 'LINC01107'},
{value: 'LINC01108', id: 'LINC01108'},
{value: 'LINC01109', id: 'LINC01109'},
{value: 'LINC01111', id: 'LINC01111'},
{value: 'LINC01114', id: 'LINC01114'},
{value: 'LINC01115', id: 'LINC01115'},
{value: 'LINC01116', id: 'LINC01116'},
{value: 'LINC01117', id: 'LINC01117'},
{value: 'LINC01118', id: 'LINC01118'},
{value: 'LINC01119', id: 'LINC01119'},
{value: 'LINC01120', id: 'LINC01120'},
{value: 'LINC01121', id: 'LINC01121'},
{value: 'LINC01122', id: 'LINC01122'},
{value: 'LINC01123', id: 'LINC01123'},
{value: 'LINC01124', id: 'LINC01124'},
{value: 'LINC01126', id: 'LINC01126'},
{value: 'LINC01127', id: 'LINC01127'},
{value: 'LINC01128', id: 'LINC01128'},
{value: 'LINC01132', id: 'LINC01132'},
{value: 'LINC01133', id: 'LINC01133'},
{value: 'LINC01134', id: 'LINC01134'},
{value: 'LINC01135', id: 'LINC01135'},
{value: 'LINC01136', id: 'LINC01136'},
{value: 'LINC01137', id: 'LINC01137'},
{value: 'LINC01138', id: 'LINC01138'},
{value: 'LINC01139', id: 'LINC01139'},
{value: 'LINC01140', id: 'LINC01140'},
{value: 'LINC01141', id: 'LINC01141'},
{value: 'LINC01142', id: 'LINC01142'},
{value: 'LINC01143', id: 'LINC01143'},
{value: 'LINC01144', id: 'LINC01144'},
{value: 'LINC01145', id: 'LINC01145'},
{value: 'LINC01146', id: 'LINC01146'},
{value: 'LINC01147', id: 'LINC01147'},
{value: 'LINC01148', id: 'LINC01148'},
{value: 'LINC01149', id: 'LINC01149'},
{value: 'LINC01150', id: 'LINC01150'},
{value: 'LINC01151', id: 'LINC01151'},
{value: 'LINC01152', id: 'LINC01152'},
{value: 'LINC01153', id: 'LINC01153'},
{value: 'LINC01154', id: 'LINC01154'},
{value: 'LINC01160', id: 'LINC01160'},
{value: 'LINC01162', id: 'LINC01162'},
{value: 'LINC01163', id: 'LINC01163'},
{value: 'LINC01164', id: 'LINC01164'},
{value: 'LINC01165', id: 'LINC01165'},
{value: 'LINC01166', id: 'LINC01166'},
{value: 'LINC01167', id: 'LINC01167'},
{value: 'LINC01168', id: 'LINC01168'},
{value: 'LINC01169', id: 'LINC01169'},
{value: 'LINC01170', id: 'LINC01170'},
{value: 'LINC01173', id: 'LINC01173'},
{value: 'LINC01176', id: 'LINC01176'},
{value: 'LINC01177', id: 'LINC01177'},
{value: 'LINC01179', id: 'LINC01179'},
{value: 'LINC01181', id: 'LINC01181'},
{value: 'LINC01182', id: 'LINC01182'},
{value: 'LINC01184', id: 'LINC01184'},
{value: 'LINC01185', id: 'LINC01185'},
{value: 'LINC01186', id: 'LINC01186'},
{value: 'LINC01187', id: 'LINC01187'},
{value: 'LINC01189', id: 'LINC01189'},
{value: 'LINC01191', id: 'LINC01191'},
{value: 'LINC01192', id: 'LINC01192'},
{value: 'LINC01193', id: 'LINC01193'},
{value: 'LINC01194', id: 'LINC01194'},
{value: 'LINC01198', id: 'LINC01198'},
{value: 'LINC01202', id: 'LINC01202'},
{value: 'LINC01205', id: 'LINC01205'},
{value: 'LINC01206', id: 'LINC01206'},
{value: 'LINC01208', id: 'LINC01208'},
{value: 'LINC01210', id: 'LINC01210'},
{value: 'LINC01213', id: 'LINC01213'},
{value: 'LINC01214', id: 'LINC01214'},
{value: 'LINC01215', id: 'LINC01215'},
{value: 'LINC01217', id: 'LINC01217'},
{value: 'LINC01219', id: 'LINC01219'},
{value: 'LINC01220', id: 'LINC01220'},
{value: 'LINC01221', id: 'LINC01221'},
{value: 'LINC01222', id: 'LINC01222'},
{value: 'LINC01224', id: 'LINC01224'},
{value: 'LINC01226', id: 'LINC01226'},
{value: 'LINC01227', id: 'LINC01227'},
{value: 'LINC01228', id: 'LINC01228'},
{value: 'LINC01229', id: 'LINC01229'},
{value: 'LINC01230', id: 'LINC01230'},
{value: 'LINC01231', id: 'LINC01231'},
{value: 'LINC01232', id: 'LINC01232'},
{value: 'LINC01233', id: 'LINC01233'},
{value: 'LINC01234', id: 'LINC01234'},
{value: 'LINC01235', id: 'LINC01235'},
{value: 'LINC01237', id: 'LINC01237'},
{value: 'LINC01238', id: 'LINC01238'},
{value: 'LINC01239', id: 'LINC01239'},
{value: 'LINC01241', id: 'LINC01241'},
{value: 'LINC01242', id: 'LINC01242'},
{value: 'LINC01243', id: 'LINC01243'},
{value: 'LINC01247', id: 'LINC01247'},
{value: 'LINC01248', id: 'LINC01248'},
{value: 'LINC01249', id: 'LINC01249'},
{value: 'LINC01250', id: 'LINC01250'},
{value: 'LINC01251', id: 'LINC01251'},
{value: 'LINC01252', id: 'LINC01252'},
{value: 'LINC01254', id: 'LINC01254'},
{value: 'LINC01255', id: 'LINC01255'},
{value: 'LINC01256', id: 'LINC01256'},
{value: 'LINC01257', id: 'LINC01257'},
{value: 'LINC01258', id: 'LINC01258'},
{value: 'LINC01259', id: 'LINC01259'},
{value: 'LINC01260', id: 'LINC01260'},
{value: 'LINC01262', id: 'LINC01262'},
{value: 'LINC01264', id: 'LINC01264'},
{value: 'LINC01266', id: 'LINC01266'},
{value: 'LINC01267', id: 'LINC01267'},
{value: 'LINC01269', id: 'LINC01269'},
{value: 'LINC01270', id: 'LINC01270'},
{value: 'LINC01271', id: 'LINC01271'},
{value: 'LINC01273', id: 'LINC01273'},
{value: 'LINC01275', id: 'LINC01275'},
{value: 'LINC01276', id: 'LINC01276'},
{value: 'LINC01277', id: 'LINC01277'},
{value: 'LINC01278', id: 'LINC01278'},
{value: 'LINC01280', id: 'LINC01280'},
{value: 'LINC01284', id: 'LINC01284'},
{value: 'LINC01285', id: 'LINC01285'},
{value: 'LINC01287', id: 'LINC01287'},
{value: 'LINC01288', id: 'LINC01288'},
{value: 'LINC01290', id: 'LINC01290'},
{value: 'LINC01291', id: 'LINC01291'},
{value: 'LINC01293', id: 'LINC01293'},
{value: 'LINC01297', id: 'LINC01297'},
{value: 'LINC01299', id: 'LINC01299'},
{value: 'LINC01300', id: 'LINC01300'},
{value: 'LINC01301', id: 'LINC01301'},
{value: 'LINC01303', id: 'LINC01303'},
{value: 'LINC01304', id: 'LINC01304'},
{value: 'LINC01305', id: 'LINC01305'},
{value: 'LINC01307', id: 'LINC01307'},
{value: 'LINC01309', id: 'LINC01309'},
{value: 'LINC01310', id: 'LINC01310'},
{value: 'LINC01311', id: 'LINC01311'},
{value: 'LINC01315', id: 'LINC01315'},
{value: 'LINC01320', id: 'LINC01320'},
{value: 'LINC01322', id: 'LINC01322'},
{value: 'LINC01323', id: 'LINC01323'},
{value: 'LINC01324', id: 'LINC01324'},
{value: 'LINC01326', id: 'LINC01326'},
{value: 'LINC01327', id: 'LINC01327'},
{value: 'LINC01331', id: 'LINC01331'},
{value: 'LINC01332', id: 'LINC01332'},
{value: 'LINC01337', id: 'LINC01337'},
{value: 'LINC01338', id: 'LINC01338'},
{value: 'LINC01339', id: 'LINC01339'},
{value: 'LINC01340', id: 'LINC01340'},
{value: 'LINC01341', id: 'LINC01341'},
{value: 'LINC01342', id: 'LINC01342'},
{value: 'LINC01343', id: 'LINC01343'},
{value: 'LINC01344', id: 'LINC01344'},
{value: 'LINC01345', id: 'LINC01345'},
{value: 'LINC01346', id: 'LINC01346'},
{value: 'LINC01347', id: 'LINC01347'},
{value: 'LINC01348', id: 'LINC01348'},
{value: 'LINC01349', id: 'LINC01349'},
{value: 'LINC01350', id: 'LINC01350'},
{value: 'LINC01351', id: 'LINC01351'},
{value: 'LINC01353', id: 'LINC01353'},
{value: 'LINC01354', id: 'LINC01354'},
{value: 'LINC01355', id: 'LINC01355'},
{value: 'LINC01356', id: 'LINC01356'},
{value: 'LINC01357', id: 'LINC01357'},
{value: 'LINC01358', id: 'LINC01358'},
{value: 'LINC01359', id: 'LINC01359'},
{value: 'LINC01360', id: 'LINC01360'},
{value: 'LINC01361', id: 'LINC01361'},
{value: 'LINC01362', id: 'LINC01362'},
{value: 'LINC01363', id: 'LINC01363'},
{value: 'LINC01364', id: 'LINC01364'},
{value: 'LINC01365', id: 'LINC01365'},
{value: 'LINC01366', id: 'LINC01366'},
{value: 'LINC01370', id: 'LINC01370'},
{value: 'LINC01374', id: 'LINC01374'},
{value: 'LINC01376', id: 'LINC01376'},
{value: 'LINC01377', id: 'LINC01377'},
{value: 'LINC01378', id: 'LINC01378'},
{value: 'LINC01381', id: 'LINC01381'},
{value: 'LINC01385', id: 'LINC01385'},
{value: 'LINC01386', id: 'LINC01386'},
{value: 'LINC01387', id: 'LINC01387'},
{value: 'LINC01388', id: 'LINC01388'},
{value: 'LINC01389', id: 'LINC01389'},
{value: 'LINC01391', id: 'LINC01391'},
{value: 'LINC01392', id: 'LINC01392'},
{value: 'LINC01393', id: 'LINC01393'},
{value: 'LINC01394', id: 'LINC01394'},
{value: 'LINC01395', id: 'LINC01395'},
{value: 'LINC01396', id: 'LINC01396'},
{value: 'LINC01397', id: 'LINC01397'},
{value: 'LINC01399', id: 'LINC01399'},
{value: 'LINC01400', id: 'LINC01400'},
{value: 'LINC01405', id: 'LINC01405'},
{value: 'LINC01409', id: 'LINC01409'},
{value: 'LINC01410', id: 'LINC01410'},
{value: 'LINC01411', id: 'LINC01411'},
{value: 'LINC01412', id: 'LINC01412'},
{value: 'LINC01413', id: 'LINC01413'},
{value: 'LINC01414', id: 'LINC01414'},
{value: 'LINC01415', id: 'LINC01415'},
{value: 'LINC01416', id: 'LINC01416'},
{value: 'LINC01419', id: 'LINC01419'},
{value: 'LINC01422', id: 'LINC01422'},
{value: 'LINC01423', id: 'LINC01423'},
{value: 'LINC01424', id: 'LINC01424'},
{value: 'LINC01425', id: 'LINC01425'},
{value: 'LINC01426', id: 'LINC01426'},
{value: 'LINC01427', id: 'LINC01427'},
{value: 'LINC01428', id: 'LINC01428'},
{value: 'LINC01429', id: 'LINC01429'},
{value: 'LINC01431', id: 'LINC01431'},
{value: 'LINC01432', id: 'LINC01432'},
{value: 'LINC01433', id: 'LINC01433'},
{value: 'LINC01435', id: 'LINC01435'},
{value: 'LINC01436', id: 'LINC01436'},
{value: 'LINC01438', id: 'LINC01438'},
{value: 'LINC01440', id: 'LINC01440'},
{value: 'LINC01443', id: 'LINC01443'},
{value: 'LINC01444', id: 'LINC01444'},
{value: 'LINC01445', id: 'LINC01445'},
{value: 'LINC01446', id: 'LINC01446'},
{value: 'LINC01447', id: 'LINC01447'},
{value: 'LINC01448', id: 'LINC01448'},
{value: 'LINC01449', id: 'LINC01449'},
{value: 'LINC01450', id: 'LINC01450'},
{value: 'LINC01451', id: 'LINC01451'},
{value: 'LINC01455', id: 'LINC01455'},
{value: 'LINC01460', id: 'LINC01460'},
{value: 'LINC01465', id: 'LINC01465'},
{value: 'LINC01467', id: 'LINC01467'},
{value: 'LINC01470', id: 'LINC01470'},
{value: 'LINC01471', id: 'LINC01471'},
{value: 'LINC01473', id: 'LINC01473'},
{value: 'LINC01474', id: 'LINC01474'},
{value: 'LINC01475', id: 'LINC01475'},
{value: 'LINC01476', id: 'LINC01476'},
{value: 'LINC01477', id: 'LINC01477'},
{value: 'LINC01478', id: 'LINC01478'},
{value: 'LINC01479', id: 'LINC01479'},
{value: 'LINC01480', id: 'LINC01480'},
{value: 'LINC01482', id: 'LINC01482'},
{value: 'LINC01483', id: 'LINC01483'},
{value: 'LINC01484', id: 'LINC01484'},
{value: 'LINC01485', id: 'LINC01485'},
{value: 'LINC01487', id: 'LINC01487'},
{value: 'LINC01488', id: 'LINC01488'},
{value: 'LINC01489', id: 'LINC01489'},
{value: 'LINC01490', id: 'LINC01490'},
{value: 'LINC01491', id: 'LINC01491'},
{value: 'LINC01492', id: 'LINC01492'},
{value: 'LINC01493', id: 'LINC01493'},
{value: 'LINC01494', id: 'LINC01494'},
{value: 'LINC01495', id: 'LINC01495'},
{value: 'LINC01497', id: 'LINC01497'},
{value: 'LINC01498', id: 'LINC01498'},
{value: 'LINC01499', id: 'LINC01499'},
{value: 'LINC01500', id: 'LINC01500'},
{value: 'LINC01501', id: 'LINC01501'},
{value: 'LINC01502', id: 'LINC01502'},
{value: 'LINC01503', id: 'LINC01503'},
{value: 'LINC01504', id: 'LINC01504'},
{value: 'LINC01505', id: 'LINC01505'},
{value: 'LINC01506', id: 'LINC01506'},
{value: 'LINC01507', id: 'LINC01507'},
{value: 'LINC01508', id: 'LINC01508'},
{value: 'LINC01509', id: 'LINC01509'},
{value: 'LINC01511', id: 'LINC01511'},
{value: 'LINC01512', id: 'LINC01512'},
{value: 'LINC01513', id: 'LINC01513'},
{value: 'LINC01514', id: 'LINC01514'},
{value: 'LINC01515', id: 'LINC01515'},
{value: 'LINC01516', id: 'LINC01516'},
{value: 'LINC01517', id: 'LINC01517'},
{value: 'LINC01518', id: 'LINC01518'},
{value: 'LINC01519', id: 'LINC01519'},
{value: 'LINC01520', id: 'LINC01520'},
{value: 'LINC01521', id: 'LINC01521'},
{value: 'LINC01522', id: 'LINC01522'},
{value: 'LINC01523', id: 'LINC01523'},
{value: 'LINC01524', id: 'LINC01524'},
{value: 'LINC01525', id: 'LINC01525'},
{value: 'LINC01526', id: 'LINC01526'},
{value: 'LINC01527', id: 'LINC01527'},
{value: 'LINC01529', id: 'LINC01529'},
{value: 'LINC01530', id: 'LINC01530'},
{value: 'LINC01531', id: 'LINC01531'},
{value: 'LINC01532', id: 'LINC01532'},
{value: 'LINC01533', id: 'LINC01533'},
{value: 'LINC01534', id: 'LINC01534'},
{value: 'LINC01535', id: 'LINC01535'},
{value: 'LINC01537', id: 'LINC01537'},
{value: 'LINC01538', id: 'LINC01538'},
{value: 'LINC01541', id: 'LINC01541'},
{value: 'LINC01543', id: 'LINC01543'},
{value: 'LINC01544', id: 'LINC01544'},
{value: 'LINC01547', id: 'LINC01547'},
{value: 'LINC01548', id: 'LINC01548'},
{value: 'LINC01549', id: 'LINC01549'},
{value: 'LINC01550', id: 'LINC01550'},
{value: 'LINC01551', id: 'LINC01551'},
{value: 'LINC01553', id: 'LINC01553'},
{value: 'LINC01554', id: 'LINC01554'},
{value: 'LINC01555', id: 'LINC01555'},
{value: 'LINC01556', id: 'LINC01556'},
{value: 'LINC01558', id: 'LINC01558'},
{value: 'LINC01559', id: 'LINC01559'},
{value: 'LINC01560', id: 'LINC01560'},
{value: 'LINC01561', id: 'LINC01561'},
{value: 'LINC01562', id: 'LINC01562'},
{value: 'LINC01563', id: 'LINC01563'},
{value: 'LINC01564', id: 'LINC01564'},
{value: 'LINC01565', id: 'LINC01565'},
{value: 'LINC01566', id: 'LINC01566'},
{value: 'LINC01567', id: 'LINC01567'},
{value: 'LINC01569', id: 'LINC01569'},
{value: 'LINC01570', id: 'LINC01570'},
{value: 'LINC01571', id: 'LINC01571'},
{value: 'LINC01572', id: 'LINC01572'},
{value: 'LINC01574', id: 'LINC01574'},
{value: 'LINC01579', id: 'LINC01579'},
{value: 'LINC01581', id: 'LINC01581'},
{value: 'LINC01583', id: 'LINC01583'},
{value: 'LINC01584', id: 'LINC01584'},
{value: 'LINC01585', id: 'LINC01585'},
{value: 'LINC01586', id: 'LINC01586'},
{value: 'LINC01587', id: 'LINC01587'},
{value: 'LINC01588', id: 'LINC01588'},
{value: 'LINC01589', id: 'LINC01589'},
{value: 'LINC01591', id: 'LINC01591'},
{value: 'LINC01592', id: 'LINC01592'},
{value: 'LINC01593', id: 'LINC01593'},
{value: 'LINC01594', id: 'LINC01594'},
{value: 'LINC01595', id: 'LINC01595'},
{value: 'LINC01597', id: 'LINC01597'},
{value: 'LINC01600', id: 'LINC01600'},
{value: 'LINC01602', id: 'LINC01602'},
{value: 'LINC01603', id: 'LINC01603'},
{value: 'LINC01605', id: 'LINC01605'},
{value: 'LINC01606', id: 'LINC01606'},
{value: 'LINC01607', id: 'LINC01607'},
{value: 'LINC01608', id: 'LINC01608'},
{value: 'LINC01609', id: 'LINC01609'},
{value: 'LINC01611', id: 'LINC01611'},
{value: 'LINC01612', id: 'LINC01612'},
{value: 'LINC01613', id: 'LINC01613'},
{value: 'LINC01615', id: 'LINC01615'},
{value: 'LINC01618', id: 'LINC01618'},
{value: 'LINC01619', id: 'LINC01619'},
{value: 'LINC01620', id: 'LINC01620'},
{value: 'LINC01621', id: 'LINC01621'},
{value: 'LINC01622', id: 'LINC01622'},
{value: 'LINC01623', id: 'LINC01623'},
{value: 'LINC01624', id: 'LINC01624'},
{value: 'LINC01625', id: 'LINC01625'},
{value: 'LINC01626', id: 'LINC01626'},
{value: 'LINC01627', id: 'LINC01627'},
{value: 'LINC01628', id: 'LINC01628'},
{value: 'LINC01629', id: 'LINC01629'},
{value: 'LINC01630', id: 'LINC01630'},
{value: 'LINC01632', id: 'LINC01632'},
{value: 'LINC01633', id: 'LINC01633'},
{value: 'LINC01634', id: 'LINC01634'},
{value: 'LINC01637', id: 'LINC01637'},
{value: 'LINC01638', id: 'LINC01638'},
{value: 'LINC01639', id: 'LINC01639'},
{value: 'LINC01640', id: 'LINC01640'},
{value: 'LINC01641', id: 'LINC01641'},
{value: 'LINC01643', id: 'LINC01643'},
{value: 'LINC01644', id: 'LINC01644'},
{value: 'LINC01645', id: 'LINC01645'},
{value: 'LINC01646', id: 'LINC01646'},
{value: 'LINC01647', id: 'LINC01647'},
{value: 'LINC01648', id: 'LINC01648'},
{value: 'LINC01649', id: 'LINC01649'},
{value: 'LINC01650', id: 'LINC01650'},
{value: 'LINC01651', id: 'LINC01651'},
{value: 'LINC01653', id: 'LINC01653'},
{value: 'LINC01654', id: 'LINC01654'},
{value: 'LINC01655', id: 'LINC01655'},
{value: 'LINC01656', id: 'LINC01656'},
{value: 'LINC01657', id: 'LINC01657'},
{value: 'LINC01659', id: 'LINC01659'},
{value: 'LINC01661', id: 'LINC01661'},
{value: 'LINC01664', id: 'LINC01664'},
{value: 'LINC01665', id: 'LINC01665'},
{value: 'LINC01666', id: 'LINC01666'},
{value: 'LINC01667', id: 'LINC01667'},
{value: 'LINC01668', id: 'LINC01668'},
{value: 'LINC01669', id: 'LINC01669'},
{value: 'LINC01671', id: 'LINC01671'},
{value: 'LINC01672', id: 'LINC01672'},
{value: 'LINC01673', id: 'LINC01673'},
{value: 'LINC01674', id: 'LINC01674'},
{value: 'LINC01675', id: 'LINC01675'},
{value: 'LINC01676', id: 'LINC01676'},
{value: 'LINC01677', id: 'LINC01677'},
{value: 'LINC01678', id: 'LINC01678'},
{value: 'LINC01679', id: 'LINC01679'},
{value: 'LINC01680', id: 'LINC01680'},
{value: 'LINC01681', id: 'LINC01681'},
{value: 'LINC01682', id: 'LINC01682'},
{value: 'LINC01683', id: 'LINC01683'},
{value: 'LINC01684', id: 'LINC01684'},
{value: 'LINC01685', id: 'LINC01685'},
{value: 'LINC01686', id: 'LINC01686'},
{value: 'LINC01687', id: 'LINC01687'},
{value: 'LINC01688', id: 'LINC01688'},
{value: 'LINC01689', id: 'LINC01689'},
{value: 'LINC01690', id: 'LINC01690'},
{value: 'LINC01691', id: 'LINC01691'},
{value: 'LINC01692', id: 'LINC01692'},
{value: 'LINC01693', id: 'LINC01693'},
{value: 'LINC01694', id: 'LINC01694'},
{value: 'LINC01695', id: 'LINC01695'},
{value: 'LINC01696', id: 'LINC01696'},
{value: 'LINC01697', id: 'LINC01697'},
{value: 'LINC01698', id: 'LINC01698'},
{value: 'LINC01699', id: 'LINC01699'},
{value: 'LINC01700', id: 'LINC01700'},
{value: 'LINC01701', id: 'LINC01701'},
{value: 'LINC01702', id: 'LINC01702'},
{value: 'LINC01703', id: 'LINC01703'},
{value: 'LINC01704', id: 'LINC01704'},
{value: 'LINC01705', id: 'LINC01705'},
{value: 'LINC01706', id: 'LINC01706'},
{value: 'LINC01707', id: 'LINC01707'},
{value: 'LINC01708', id: 'LINC01708'},
{value: 'LINC01709', id: 'LINC01709'},
{value: 'LINC01710', id: 'LINC01710'},
{value: 'LINC01711', id: 'LINC01711'},
{value: 'LINC01712', id: 'LINC01712'},
{value: 'LINC01713', id: 'LINC01713'},
{value: 'LINC01714', id: 'LINC01714'},
{value: 'LINC01715', id: 'LINC01715'},
{value: 'LINC01716', id: 'LINC01716'},
{value: 'LINC01717', id: 'LINC01717'},
{value: 'LINC01718', id: 'LINC01718'},
{value: 'LINC01719', id: 'LINC01719'},
{value: 'LINC01720', id: 'LINC01720'},
{value: 'LINC01721', id: 'LINC01721'},
{value: 'LINC01722', id: 'LINC01722'},
{value: 'LINC01723', id: 'LINC01723'},
{value: 'LINC01724', id: 'LINC01724'},
{value: 'LINC01725', id: 'LINC01725'},
{value: 'LINC01726', id: 'LINC01726'},
{value: 'LINC01727', id: 'LINC01727'},
{value: 'LINC01728', id: 'LINC01728'},
{value: 'LINC01729', id: 'LINC01729'},
{value: 'LINC01730', id: 'LINC01730'},
{value: 'LINC01731', id: 'LINC01731'},
{value: 'LINC01732', id: 'LINC01732'},
{value: 'LINC01733', id: 'LINC01733'},
{value: 'LINC01734', id: 'LINC01734'},
{value: 'LINC01735', id: 'LINC01735'},
{value: 'LINC01736', id: 'LINC01736'},
{value: 'LINC01738', id: 'LINC01738'},
{value: 'LINC01739', id: 'LINC01739'},
{value: 'LINC01740', id: 'LINC01740'},
{value: 'LINC01741', id: 'LINC01741'},
{value: 'LINC01742', id: 'LINC01742'},
{value: 'LINC01743', id: 'LINC01743'},
{value: 'LINC01744', id: 'LINC01744'},
{value: 'LINC01745', id: 'LINC01745'},
{value: 'LINC01746', id: 'LINC01746'},
{value: 'LINC01747', id: 'LINC01747'},
{value: 'LINC01748', id: 'LINC01748'},
{value: 'LINC01749', id: 'LINC01749'},
{value: 'LINC01750', id: 'LINC01750'},
{value: 'LINC01751', id: 'LINC01751'},
{value: 'LINC01752', id: 'LINC01752'},
{value: 'LINC01753', id: 'LINC01753'},
{value: 'LINC01754', id: 'LINC01754'},
{value: 'LINC01756', id: 'LINC01756'},
{value: 'LINC01757', id: 'LINC01757'},
{value: 'LINC01758', id: 'LINC01758'},
{value: 'LINC01760', id: 'LINC01760'},
{value: 'LINC01761', id: 'LINC01761'},
{value: 'LINC01762', id: 'LINC01762'},
{value: 'LINC01763', id: 'LINC01763'},
{value: 'LINC01765', id: 'LINC01765'},
{value: 'LINC01766', id: 'LINC01766'},
{value: 'LINC01767', id: 'LINC01767'},
{value: 'LINC01768', id: 'LINC01768'},
{value: 'LINC01770', id: 'LINC01770'},
{value: 'LINC01771', id: 'LINC01771'},
{value: 'LINC01772', id: 'LINC01772'},
{value: 'LINC01774', id: 'LINC01774'},
{value: 'LINC01775', id: 'LINC01775'},
{value: 'LINC01776', id: 'LINC01776'},
{value: 'LINC01777', id: 'LINC01777'},
{value: 'LINC01778', id: 'LINC01778'},
{value: 'LINC01779', id: 'LINC01779'},
{value: 'LINC01780', id: 'LINC01780'},
{value: 'LINC01781', id: 'LINC01781'},
{value: 'LINC01782', id: 'LINC01782'},
{value: 'LINC01783', id: 'LINC01783'},
{value: 'LINC01784', id: 'LINC01784'},
{value: 'LINC01785', id: 'LINC01785'},
{value: 'LINC01786', id: 'LINC01786'},
{value: 'LINC01787', id: 'LINC01787'},
{value: 'LINC01788', id: 'LINC01788'},
{value: 'LINC01789', id: 'LINC01789'},
{value: 'LINC01790', id: 'LINC01790'},
{value: 'LINC01791', id: 'LINC01791'},
{value: 'LINC01793', id: 'LINC01793'},
{value: 'LINC01794', id: 'LINC01794'},
{value: 'LINC01795', id: 'LINC01795'},
{value: 'LINC01796', id: 'LINC01796'},
{value: 'LINC01797', id: 'LINC01797'},
{value: 'LINC01798', id: 'LINC01798'},
{value: 'LINC01799', id: 'LINC01799'},
{value: 'LINC01800', id: 'LINC01800'},
{value: 'LINC01801', id: 'LINC01801'},
{value: 'LINC01803', id: 'LINC01803'},
{value: 'LINC01804', id: 'LINC01804'},
{value: 'LINC01805', id: 'LINC01805'},
{value: 'LINC01806', id: 'LINC01806'},
{value: 'LINC01807', id: 'LINC01807'},
{value: 'LINC01808', id: 'LINC01808'},
{value: 'LINC01809', id: 'LINC01809'},
{value: 'LINC01810', id: 'LINC01810'},
{value: 'LINC01811', id: 'LINC01811'},
{value: 'LINC01812', id: 'LINC01812'},
{value: 'LINC01814', id: 'LINC01814'},
{value: 'LINC01815', id: 'LINC01815'},
{value: 'LINC01816', id: 'LINC01816'},
{value: 'LINC01817', id: 'LINC01817'},
{value: 'LINC01818', id: 'LINC01818'},
{value: 'LINC01819', id: 'LINC01819'},
{value: 'LINC01820', id: 'LINC01820'},
{value: 'LINC01821', id: 'LINC01821'},
{value: 'LINC01822', id: 'LINC01822'},
{value: 'LINC01823', id: 'LINC01823'},
{value: 'LINC01824', id: 'LINC01824'},
{value: 'LINC01825', id: 'LINC01825'},
{value: 'LINC01826', id: 'LINC01826'},
{value: 'LINC01827', id: 'LINC01827'},
{value: 'LINC01828', id: 'LINC01828'},
{value: 'LINC01829', id: 'LINC01829'},
{value: 'LINC01831', id: 'LINC01831'},
{value: 'LINC01832', id: 'LINC01832'},
{value: 'LINC01833', id: 'LINC01833'},
{value: 'LINC01834', id: 'LINC01834'},
{value: 'LINC01836', id: 'LINC01836'},
{value: 'LINC01837', id: 'LINC01837'},
{value: 'LINC01838', id: 'LINC01838'},
{value: 'LINC01839', id: 'LINC01839'},
{value: 'LINC01840', id: 'LINC01840'},
{value: 'LINC01841', id: 'LINC01841'},
{value: 'LINC01842', id: 'LINC01842'},
{value: 'LINC01843', id: 'LINC01843'},
{value: 'LINC01844', id: 'LINC01844'},
{value: 'LINC01846', id: 'LINC01846'},
{value: 'LINC01847', id: 'LINC01847'},
{value: 'LINC01848', id: 'LINC01848'},
{value: 'LINC01849', id: 'LINC01849'},
{value: 'LINC01850', id: 'LINC01850'},
{value: 'LINC01852', id: 'LINC01852'},
{value: 'LINC01853', id: 'LINC01853'},
{value: 'LINC01854', id: 'LINC01854'},
{value: 'LINC01855', id: 'LINC01855'},
{value: 'LINC01856', id: 'LINC01856'},
{value: 'LINC01857', id: 'LINC01857'},
{value: 'LINC01858', id: 'LINC01858'},
{value: 'LINC01859', id: 'LINC01859'},
{value: 'LINC01861', id: 'LINC01861'},
{value: 'LINC01863', id: 'LINC01863'},
{value: 'LINC01864', id: 'LINC01864'},
{value: 'LINC01865', id: 'LINC01865'},
{value: 'LINC01866', id: 'LINC01866'},
{value: 'LINC01867', id: 'LINC01867'},
{value: 'LINC01868', id: 'LINC01868'},
{value: 'LINC01869', id: 'LINC01869'},
{value: 'LINC01870', id: 'LINC01870'},
{value: 'LINC01871', id: 'LINC01871'},
{value: 'LINC01872', id: 'LINC01872'},
{value: 'LINC01873', id: 'LINC01873'},
{value: 'LINC01874', id: 'LINC01874'},
{value: 'LINC01875', id: 'LINC01875'},
{value: 'LINC01876', id: 'LINC01876'},
{value: 'LINC01877', id: 'LINC01877'},
{value: 'LINC01879', id: 'LINC01879'},
{value: 'LINC01880', id: 'LINC01880'},
{value: 'LINC01881', id: 'LINC01881'},
{value: 'LINC01882', id: 'LINC01882'},
{value: 'LINC01883', id: 'LINC01883'},
{value: 'LINC01884', id: 'LINC01884'},
{value: 'LINC01885', id: 'LINC01885'},
{value: 'LINC01886', id: 'LINC01886'},
{value: 'LINC01887', id: 'LINC01887'},
{value: 'LINC01888', id: 'LINC01888'},
{value: 'LINC01889', id: 'LINC01889'},
{value: 'LINC01890', id: 'LINC01890'},
{value: 'LINC01891', id: 'LINC01891'},
{value: 'LINC01892', id: 'LINC01892'},
{value: 'LINC01893', id: 'LINC01893'},
{value: 'LINC01894', id: 'LINC01894'},
{value: 'LINC01895', id: 'LINC01895'},
{value: 'LINC01896', id: 'LINC01896'},
{value: 'LINC01897', id: 'LINC01897'},
{value: 'LINC01898', id: 'LINC01898'},
{value: 'LINC01899', id: 'LINC01899'},
{value: 'LINC01900', id: 'LINC01900'},
{value: 'LINC01901', id: 'LINC01901'},
{value: 'LINC01902', id: 'LINC01902'},
{value: 'LINC01904', id: 'LINC01904'},
{value: 'LINC01906', id: 'LINC01906'},
{value: 'LINC01907', id: 'LINC01907'},
{value: 'LINC01908', id: 'LINC01908'},
{value: 'LINC01909', id: 'LINC01909'},
{value: 'LINC01910', id: 'LINC01910'},
{value: 'LINC01911', id: 'LINC01911'},
{value: 'LINC01912', id: 'LINC01912'},
{value: 'LINC01913', id: 'LINC01913'},
{value: 'LINC01914', id: 'LINC01914'},
{value: 'LINC01915', id: 'LINC01915'},
{value: 'LINC01916', id: 'LINC01916'},
{value: 'LINC01917', id: 'LINC01917'},
{value: 'LINC01918', id: 'LINC01918'},
{value: 'LINC01919', id: 'LINC01919'},
{value: 'LINC01920', id: 'LINC01920'},
{value: 'LINC01922', id: 'LINC01922'},
{value: 'LINC01923', id: 'LINC01923'},
{value: 'LINC01924', id: 'LINC01924'},
{value: 'LINC01925', id: 'LINC01925'},
{value: 'LINC01926', id: 'LINC01926'},
{value: 'LINC01928', id: 'LINC01928'},
{value: 'LINC01929', id: 'LINC01929'},
{value: 'LINC01931', id: 'LINC01931'},
{value: 'LINC01932', id: 'LINC01932'},
{value: 'LINC01933', id: 'LINC01933'},
{value: 'LINC01934', id: 'LINC01934'},
{value: 'LINC01936', id: 'LINC01936'},
{value: 'LINC01937', id: 'LINC01937'},
{value: 'LINC01938', id: 'LINC01938'},
{value: 'LINC01939', id: 'LINC01939'},
{value: 'LINC01940', id: 'LINC01940'},
{value: 'LINC01941', id: 'LINC01941'},
{value: 'LINC01942', id: 'LINC01942'},
{value: 'LINC01943', id: 'LINC01943'},
{value: 'LINC01944', id: 'LINC01944'},
{value: 'LINC01945', id: 'LINC01945'},
{value: 'LINC01946', id: 'LINC01946'},
{value: 'LINC01947', id: 'LINC01947'},
{value: 'LINC01948', id: 'LINC01948'},
{value: 'LINC01949', id: 'LINC01949'},
{value: 'LINC01950', id: 'LINC01950'},
{value: 'LINC01951', id: 'LINC01951'},
{value: 'LINC01952', id: 'LINC01952'},
{value: 'LINC01953', id: 'LINC01953'},
{value: 'LINC01954', id: 'LINC01954'},
{value: 'LINC01955', id: 'LINC01955'},
{value: 'LINC01956', id: 'LINC01956'},
{value: 'LINC01958', id: 'LINC01958'},
{value: 'LINC01960', id: 'LINC01960'},
{value: 'LINC01961', id: 'LINC01961'},
{value: 'LINC01962', id: 'LINC01962'},
{value: 'LINC01963', id: 'LINC01963'},
{value: 'LINC01964', id: 'LINC01964'},
{value: 'LINC01965', id: 'LINC01965'},
{value: 'LINC01967', id: 'LINC01967'},
{value: 'LINC01968', id: 'LINC01968'},
{value: 'LINC01970', id: 'LINC01970'},
{value: 'LINC01971', id: 'LINC01971'},
{value: 'LINC01972', id: 'LINC01972'},
{value: 'LINC01973', id: 'LINC01973'},
{value: 'LINC01974', id: 'LINC01974'},
{value: 'LINC01975', id: 'LINC01975'},
{value: 'LINC01976', id: 'LINC01976'},
{value: 'LINC01977', id: 'LINC01977'},
{value: 'LINC01978', id: 'LINC01978'},
{value: 'LINC01979', id: 'LINC01979'},
{value: 'LINC01980', id: 'LINC01980'},
{value: 'LINC01982', id: 'LINC01982'},
{value: 'LINC01983', id: 'LINC01983'},
{value: 'LINC01985', id: 'LINC01985'},
{value: 'LINC01986', id: 'LINC01986'},
{value: 'LINC01987', id: 'LINC01987'},
{value: 'LINC01988', id: 'LINC01988'},
{value: 'LINC01989', id: 'LINC01989'},
{value: 'LINC01990', id: 'LINC01990'},
{value: 'LINC01991', id: 'LINC01991'},
{value: 'LINC01992', id: 'LINC01992'},
{value: 'LINC01993', id: 'LINC01993'},
{value: 'LINC01994', id: 'LINC01994'},
{value: 'LINC01995', id: 'LINC01995'},
{value: 'LINC01996', id: 'LINC01996'},
{value: 'LINC01997', id: 'LINC01997'},
{value: 'LINC01998', id: 'LINC01998'},
{value: 'LINC01999', id: 'LINC01999'},
{value: 'LINC02000', id: 'LINC02000'},
{value: 'LINC02002', id: 'LINC02002'},
{value: 'LINC02003', id: 'LINC02003'},
{value: 'LINC02004', id: 'LINC02004'},
{value: 'LINC02005', id: 'LINC02005'},
{value: 'LINC02006', id: 'LINC02006'},
{value: 'LINC02008', id: 'LINC02008'},
{value: 'LINC02009', id: 'LINC02009'},
{value: 'LINC02010', id: 'LINC02010'},
{value: 'LINC02011', id: 'LINC02011'},
{value: 'LINC02012', id: 'LINC02012'},
{value: 'LINC02013', id: 'LINC02013'},
{value: 'LINC02014', id: 'LINC02014'},
{value: 'LINC02015', id: 'LINC02015'},
{value: 'LINC02016', id: 'LINC02016'},
{value: 'LINC02017', id: 'LINC02017'},
{value: 'LINC02018', id: 'LINC02018'},
{value: 'LINC02019', id: 'LINC02019'},
{value: 'LINC02020', id: 'LINC02020'},
{value: 'LINC02021', id: 'LINC02021'},
{value: 'LINC02022', id: 'LINC02022'},
{value: 'LINC02023', id: 'LINC02023'},
{value: 'LINC02025', id: 'LINC02025'},
{value: 'LINC02026', id: 'LINC02026'},
{value: 'LINC02027', id: 'LINC02027'},
{value: 'LINC02028', id: 'LINC02028'},
{value: 'LINC02029', id: 'LINC02029'},
{value: 'LINC02030', id: 'LINC02030'},
{value: 'LINC02031', id: 'LINC02031'},
{value: 'LINC02032', id: 'LINC02032'},
{value: 'LINC02033', id: 'LINC02033'},
{value: 'LINC02034', id: 'LINC02034'},
{value: 'LINC02035', id: 'LINC02035'},
{value: 'LINC02036', id: 'LINC02036'},
{value: 'LINC02037', id: 'LINC02037'},
{value: 'LINC02038', id: 'LINC02038'},
{value: 'LINC02040', id: 'LINC02040'},
{value: 'LINC02041', id: 'LINC02041'},
{value: 'LINC02042', id: 'LINC02042'},
{value: 'LINC02043', id: 'LINC02043'},
{value: 'LINC02044', id: 'LINC02044'},
{value: 'LINC02045', id: 'LINC02045'},
{value: 'LINC02046', id: 'LINC02046'},
{value: 'LINC02047', id: 'LINC02047'},
{value: 'LINC02048', id: 'LINC02048'},
{value: 'LINC02050', id: 'LINC02050'},
{value: 'LINC02051', id: 'LINC02051'},
{value: 'LINC02052', id: 'LINC02052'},
{value: 'LINC02053', id: 'LINC02053'},
{value: 'LINC02054', id: 'LINC02054'},
{value: 'LINC02055', id: 'LINC02055'},
{value: 'LINC02056', id: 'LINC02056'},
{value: 'LINC02057', id: 'LINC02057'},
{value: 'LINC02058', id: 'LINC02058'},
{value: 'LINC02059', id: 'LINC02059'},
{value: 'LINC02061', id: 'LINC02061'},
{value: 'LINC02063', id: 'LINC02063'},
{value: 'LINC02064', id: 'LINC02064'},
{value: 'LINC02065', id: 'LINC02065'},
{value: 'LINC02066', id: 'LINC02066'},
{value: 'LINC02067', id: 'LINC02067'},
{value: 'LINC02068', id: 'LINC02068'},
{value: 'LINC02069', id: 'LINC02069'},
{value: 'LINC02070', id: 'LINC02070'},
{value: 'LINC02071', id: 'LINC02071'},
{value: 'LINC02073', id: 'LINC02073'},
{value: 'LINC02074', id: 'LINC02074'},
{value: 'LINC02075', id: 'LINC02075'},
{value: 'LINC02076', id: 'LINC02076'},
{value: 'LINC02077', id: 'LINC02077'},
{value: 'LINC02078', id: 'LINC02078'},
{value: 'LINC02079', id: 'LINC02079'},
{value: 'LINC02080', id: 'LINC02080'},
{value: 'LINC02082', id: 'LINC02082'},
{value: 'LINC02084', id: 'LINC02084'},
{value: 'LINC02085', id: 'LINC02085'},
{value: 'LINC02086', id: 'LINC02086'},
{value: 'LINC02087', id: 'LINC02087'},
{value: 'LINC02088', id: 'LINC02088'},
{value: 'LINC02089', id: 'LINC02089'},
{value: 'LINC02090', id: 'LINC02090'},
{value: 'LINC02091', id: 'LINC02091'},
{value: 'LINC02092', id: 'LINC02092'},
{value: 'LINC02093', id: 'LINC02093'},
{value: 'LINC02094', id: 'LINC02094'},
{value: 'LINC02096', id: 'LINC02096'},
{value: 'LINC02097', id: 'LINC02097'},
{value: 'LINC02098', id: 'LINC02098'},
{value: 'LINC02099', id: 'LINC02099'},
{value: 'LINC02100', id: 'LINC02100'},
{value: 'LINC02101', id: 'LINC02101'},
{value: 'LINC02102', id: 'LINC02102'},
{value: 'LINC02103', id: 'LINC02103'},
{value: 'LINC02104', id: 'LINC02104'},
{value: 'LINC02105', id: 'LINC02105'},
{value: 'LINC02106', id: 'LINC02106'},
{value: 'LINC02108', id: 'LINC02108'},
{value: 'LINC02109', id: 'LINC02109'},
{value: 'LINC02111', id: 'LINC02111'},
{value: 'LINC02112', id: 'LINC02112'},
{value: 'LINC02113', id: 'LINC02113'},
{value: 'LINC02115', id: 'LINC02115'},
{value: 'LINC02116', id: 'LINC02116'},
{value: 'LINC02118', id: 'LINC02118'},
{value: 'LINC02119', id: 'LINC02119'},
{value: 'LINC02121', id: 'LINC02121'},
{value: 'LINC02122', id: 'LINC02122'},
{value: 'LINC02123', id: 'LINC02123'},
{value: 'LINC02124', id: 'LINC02124'},
{value: 'LINC02125', id: 'LINC02125'},
{value: 'LINC02126', id: 'LINC02126'},
{value: 'LINC02127', id: 'LINC02127'},
{value: 'LINC02128', id: 'LINC02128'},
{value: 'LINC02129', id: 'LINC02129'},
{value: 'LINC02130', id: 'LINC02130'},
{value: 'LINC02131', id: 'LINC02131'},
{value: 'LINC02132', id: 'LINC02132'},
{value: 'LINC02133', id: 'LINC02133'},
{value: 'LINC02134', id: 'LINC02134'},
{value: 'LINC02135', id: 'LINC02135'},
{value: 'LINC02136', id: 'LINC02136'},
{value: 'LINC02137', id: 'LINC02137'},
{value: 'LINC02138', id: 'LINC02138'},
{value: 'LINC02139', id: 'LINC02139'},
{value: 'LINC02140', id: 'LINC02140'},
{value: 'LINC02141', id: 'LINC02141'},
{value: 'LINC02142', id: 'LINC02142'},
{value: 'LINC02144', id: 'LINC02144'},
{value: 'LINC02145', id: 'LINC02145'},
{value: 'LINC02146', id: 'LINC02146'},
{value: 'LINC02147', id: 'LINC02147'},
{value: 'LINC02149', id: 'LINC02149'},
{value: 'LINC02150', id: 'LINC02150'},
{value: 'LINC02151', id: 'LINC02151'},
{value: 'LINC02152', id: 'LINC02152'},
{value: 'LINC02153', id: 'LINC02153'},
{value: 'LINC02156', id: 'LINC02156'},
{value: 'LINC02158', id: 'LINC02158'},
{value: 'LINC02159', id: 'LINC02159'},
{value: 'LINC02160', id: 'LINC02160'},
{value: 'LINC02161', id: 'LINC02161'},
{value: 'LINC02162', id: 'LINC02162'},
{value: 'LINC02163', id: 'LINC02163'},
{value: 'LINC02164', id: 'LINC02164'},
{value: 'LINC02165', id: 'LINC02165'},
{value: 'LINC02166', id: 'LINC02166'},
{value: 'LINC02167', id: 'LINC02167'},
{value: 'LINC02168', id: 'LINC02168'},
{value: 'LINC02169', id: 'LINC02169'},
{value: 'LINC02171', id: 'LINC02171'},
{value: 'LINC02172', id: 'LINC02172'},
{value: 'LINC02173', id: 'LINC02173'},
{value: 'LINC02174', id: 'LINC02174'},
{value: 'LINC02175', id: 'LINC02175'},
{value: 'LINC02176', id: 'LINC02176'},
{value: 'LINC02177', id: 'LINC02177'},
{value: 'LINC02178', id: 'LINC02178'},
{value: 'LINC02179', id: 'LINC02179'},
{value: 'LINC02180', id: 'LINC02180'},
{value: 'LINC02181', id: 'LINC02181'},
{value: 'LINC02182', id: 'LINC02182'},
{value: 'LINC02183', id: 'LINC02183'},
{value: 'LINC02184', id: 'LINC02184'},
{value: 'LINC02185', id: 'LINC02185'},
{value: 'LINC02186', id: 'LINC02186'},
{value: 'LINC02188', id: 'LINC02188'},
{value: 'LINC02190', id: 'LINC02190'},
{value: 'LINC02191', id: 'LINC02191'},
{value: 'LINC02192', id: 'LINC02192'},
{value: 'LINC02193', id: 'LINC02193'},
{value: 'LINC02194', id: 'LINC02194'},
{value: 'LINC02195', id: 'LINC02195'},
{value: 'LINC02196', id: 'LINC02196'},
{value: 'LINC02197', id: 'LINC02197'},
{value: 'LINC02199', id: 'LINC02199'},
{value: 'LINC02200', id: 'LINC02200'},
{value: 'LINC02201', id: 'LINC02201'},
{value: 'LINC02202', id: 'LINC02202'},
{value: 'LINC02203', id: 'LINC02203'},
{value: 'LINC02204', id: 'LINC02204'},
{value: 'LINC02205', id: 'LINC02205'},
{value: 'LINC02206', id: 'LINC02206'},
{value: 'LINC02207', id: 'LINC02207'},
{value: 'LINC02208', id: 'LINC02208'},
{value: 'LINC02209', id: 'LINC02209'},
{value: 'LINC02210', id: 'LINC02210'},
{value: 'LINC02210-CRHR1', id: 'LINC02210-CRHR1'},
{value: 'LINC02211', id: 'LINC02211'},
{value: 'LINC02212', id: 'LINC02212'},
{value: 'LINC02213', id: 'LINC02213'},
{value: 'LINC02214', id: 'LINC02214'},
{value: 'LINC02216', id: 'LINC02216'},
{value: 'LINC02217', id: 'LINC02217'},
{value: 'LINC02218', id: 'LINC02218'},
{value: 'LINC02219', id: 'LINC02219'},
{value: 'LINC02220', id: 'LINC02220'},
{value: 'LINC02222', id: 'LINC02222'},
{value: 'LINC02223', id: 'LINC02223'},
{value: 'LINC02224', id: 'LINC02224'},
{value: 'LINC02225', id: 'LINC02225'},
{value: 'LINC02226', id: 'LINC02226'},
{value: 'LINC02227', id: 'LINC02227'},
{value: 'LINC02228', id: 'LINC02228'},
{value: 'LINC02229', id: 'LINC02229'},
{value: 'LINC02230', id: 'LINC02230'},
{value: 'LINC02231', id: 'LINC02231'},
{value: 'LINC02232', id: 'LINC02232'},
{value: 'LINC02233', id: 'LINC02233'},
{value: 'LINC02234', id: 'LINC02234'},
{value: 'LINC02235', id: 'LINC02235'},
{value: 'LINC02236', id: 'LINC02236'},
{value: 'LINC02237', id: 'LINC02237'},
{value: 'LINC02238', id: 'LINC02238'},
{value: 'LINC02240', id: 'LINC02240'},
{value: 'LINC02241', id: 'LINC02241'},
{value: 'LINC02244', id: 'LINC02244'},
{value: 'LINC02245', id: 'LINC02245'},
{value: 'LINC02246', id: 'LINC02246'},
{value: 'LINC02247', id: 'LINC02247'},
{value: 'LINC02248', id: 'LINC02248'},
{value: 'LINC02249', id: 'LINC02249'},
{value: 'LINC02250', id: 'LINC02250'},
{value: 'LINC02251', id: 'LINC02251'},
{value: 'LINC02252', id: 'LINC02252'},
{value: 'LINC02253', id: 'LINC02253'},
{value: 'LINC02254', id: 'LINC02254'},
{value: 'LINC02255', id: 'LINC02255'},
{value: 'LINC02256', id: 'LINC02256'},
{value: 'LINC02257', id: 'LINC02257'},
{value: 'LINC02258', id: 'LINC02258'},
{value: 'LINC02259', id: 'LINC02259'},
{value: 'LINC02260', id: 'LINC02260'},
{value: 'LINC02261', id: 'LINC02261'},
{value: 'LINC02262', id: 'LINC02262'},
{value: 'LINC02263', id: 'LINC02263'},
{value: 'LINC02264', id: 'LINC02264'},
{value: 'LINC02265', id: 'LINC02265'},
{value: 'LINC02266', id: 'LINC02266'},
{value: 'LINC02267', id: 'LINC02267'},
{value: 'LINC02268', id: 'LINC02268'},
{value: 'LINC02269', id: 'LINC02269'},
{value: 'LINC02270', id: 'LINC02270'},
{value: 'LINC02271', id: 'LINC02271'},
{value: 'LINC02272', id: 'LINC02272'},
{value: 'LINC02273', id: 'LINC02273'},
{value: 'LINC02274', id: 'LINC02274'},
{value: 'LINC02275', id: 'LINC02275'},
{value: 'LINC02276', id: 'LINC02276'},
{value: 'LINC02277', id: 'LINC02277'},
{value: 'LINC02278', id: 'LINC02278'},
{value: 'LINC02279', id: 'LINC02279'},
{value: 'LINC02280', id: 'LINC02280'},
{value: 'LINC02281', id: 'LINC02281'},
{value: 'LINC02282', id: 'LINC02282'},
{value: 'LINC02283', id: 'LINC02283'},
{value: 'LINC02284', id: 'LINC02284'},
{value: 'LINC02285', id: 'LINC02285'},
{value: 'LINC02286', id: 'LINC02286'},
{value: 'LINC02287', id: 'LINC02287'},
{value: 'LINC02288', id: 'LINC02288'},
{value: 'LINC02289', id: 'LINC02289'},
{value: 'LINC02290', id: 'LINC02290'},
{value: 'LINC02291', id: 'LINC02291'},
{value: 'LINC02292', id: 'LINC02292'},
{value: 'LINC02295', id: 'LINC02295'},
{value: 'LINC02296', id: 'LINC02296'},
{value: 'LINC02297', id: 'LINC02297'},
{value: 'LINC02298', id: 'LINC02298'},
{value: 'LINC02299', id: 'LINC02299'},
{value: 'LINC02300', id: 'LINC02300'},
{value: 'LINC02301', id: 'LINC02301'},
{value: 'LINC02302', id: 'LINC02302'},
{value: 'LINC02303', id: 'LINC02303'},
{value: 'LINC02304', id: 'LINC02304'},
{value: 'LINC02305', id: 'LINC02305'},
{value: 'LINC02306', id: 'LINC02306'},
{value: 'LINC02307', id: 'LINC02307'},
{value: 'LINC02308', id: 'LINC02308'},
{value: 'LINC02309', id: 'LINC02309'},
{value: 'LINC02310', id: 'LINC02310'},
{value: 'LINC02311', id: 'LINC02311'},
{value: 'LINC02312', id: 'LINC02312'},
{value: 'LINC02313', id: 'LINC02313'},
{value: 'LINC02314', id: 'LINC02314'},
{value: 'LINC02315', id: 'LINC02315'},
{value: 'LINC02316', id: 'LINC02316'},
{value: 'LINC02317', id: 'LINC02317'},
{value: 'LINC02318', id: 'LINC02318'},
{value: 'LINC02319', id: 'LINC02319'},
{value: 'LINC02320', id: 'LINC02320'},
{value: 'LINC02321', id: 'LINC02321'},
{value: 'LINC02322', id: 'LINC02322'},
{value: 'LINC02323', id: 'LINC02323'},
{value: 'LINC02324', id: 'LINC02324'},
{value: 'LINC02325', id: 'LINC02325'},
{value: 'LINC02326', id: 'LINC02326'},
{value: 'LINC02327', id: 'LINC02327'},
{value: 'LINC02328', id: 'LINC02328'},
{value: 'LINC02329', id: 'LINC02329'},
{value: 'LINC02330', id: 'LINC02330'},
{value: 'LINC02332', id: 'LINC02332'},
{value: 'LINC02333', id: 'LINC02333'},
{value: 'LINC02334', id: 'LINC02334'},
{value: 'LINC02335', id: 'LINC02335'},
{value: 'LINC02336', id: 'LINC02336'},
{value: 'LINC02337', id: 'LINC02337'},
{value: 'LINC02338', id: 'LINC02338'},
{value: 'LINC02339', id: 'LINC02339'},
{value: 'LINC02340', id: 'LINC02340'},
{value: 'LINC02341', id: 'LINC02341'},
{value: 'LINC02342', id: 'LINC02342'},
{value: 'LINC02343', id: 'LINC02343'},
{value: 'LINC02344', id: 'LINC02344'},
{value: 'LINC02345', id: 'LINC02345'},
{value: 'LINC02346', id: 'LINC02346'},
{value: 'LINC02347', id: 'LINC02347'},
{value: 'LINC02348', id: 'LINC02348'},
{value: 'LINC02349', id: 'LINC02349'},
{value: 'LINC02351', id: 'LINC02351'},
{value: 'LINC02352', id: 'LINC02352'},
{value: 'LINC02353', id: 'LINC02353'},
{value: 'LINC02354', id: 'LINC02354'},
{value: 'LINC02355', id: 'LINC02355'},
{value: 'LINC02356', id: 'LINC02356'},
{value: 'LINC02357', id: 'LINC02357'},
{value: 'LINC02358', id: 'LINC02358'},
{value: 'LINC02359', id: 'LINC02359'},
{value: 'LINC02360', id: 'LINC02360'},
{value: 'LINC02361', id: 'LINC02361'},
{value: 'LINC02362', id: 'LINC02362'},
{value: 'LINC02363', id: 'LINC02363'},
{value: 'LINC02364', id: 'LINC02364'},
{value: 'LINC02365', id: 'LINC02365'},
{value: 'LINC02366', id: 'LINC02366'},
{value: 'LINC02367', id: 'LINC02367'},
{value: 'LINC02368', id: 'LINC02368'},
{value: 'LINC02369', id: 'LINC02369'},
{value: 'LINC02370', id: 'LINC02370'},
{value: 'LINC02371', id: 'LINC02371'},
{value: 'LINC02372', id: 'LINC02372'},
{value: 'LINC02373', id: 'LINC02373'},
{value: 'LINC02374', id: 'LINC02374'},
{value: 'LINC02375', id: 'LINC02375'},
{value: 'LINC02376', id: 'LINC02376'},
{value: 'LINC02377', id: 'LINC02377'},
{value: 'LINC02379', id: 'LINC02379'},
{value: 'LINC02380', id: 'LINC02380'},
{value: 'LINC02381', id: 'LINC02381'},
{value: 'LINC02382', id: 'LINC02382'},
{value: 'LINC02383', id: 'LINC02383'},
{value: 'LINC02384', id: 'LINC02384'},
{value: 'LINC02385', id: 'LINC02385'},
{value: 'LINC02386', id: 'LINC02386'},
{value: 'LINC02387', id: 'LINC02387'},
{value: 'LINC02388', id: 'LINC02388'},
{value: 'LINC02389', id: 'LINC02389'},
{value: 'LINC02390', id: 'LINC02390'},
{value: 'LINC02391', id: 'LINC02391'},
{value: 'LINC02392', id: 'LINC02392'},
{value: 'LINC02393', id: 'LINC02393'},
{value: 'LINC02394', id: 'LINC02394'},
{value: 'LINC02395', id: 'LINC02395'},
{value: 'LINC02397', id: 'LINC02397'},
{value: 'LINC02398', id: 'LINC02398'},
{value: 'LINC02400', id: 'LINC02400'},
{value: 'LINC02401', id: 'LINC02401'},
{value: 'LINC02402', id: 'LINC02402'},
{value: 'LINC02403', id: 'LINC02403'},
{value: 'LINC02404', id: 'LINC02404'},
{value: 'LINC02405', id: 'LINC02405'},
{value: 'LINC02406', id: 'LINC02406'},
{value: 'LINC02408', id: 'LINC02408'},
{value: 'LINC02409', id: 'LINC02409'},
{value: 'LINC02411', id: 'LINC02411'},
{value: 'LINC02412', id: 'LINC02412'},
{value: 'LINC02413', id: 'LINC02413'},
{value: 'LINC02414', id: 'LINC02414'},
{value: 'LINC02415', id: 'LINC02415'},
{value: 'LINC02416', id: 'LINC02416'},
{value: 'LINC02417', id: 'LINC02417'},
{value: 'LINC02419', id: 'LINC02419'},
{value: 'LINC02420', id: 'LINC02420'},
{value: 'LINC02421', id: 'LINC02421'},
{value: 'LINC02422', id: 'LINC02422'},
{value: 'LINC02423', id: 'LINC02423'},
{value: 'LINC02424', id: 'LINC02424'},
{value: 'LINC02425', id: 'LINC02425'},
{value: 'LINC02426', id: 'LINC02426'},
{value: 'LINC02427', id: 'LINC02427'},
{value: 'LINC02428', id: 'LINC02428'},
{value: 'LINC02429', id: 'LINC02429'},
{value: 'LINC02430', id: 'LINC02430'},
{value: 'LINC02431', id: 'LINC02431'},
{value: 'LINC02432', id: 'LINC02432'},
{value: 'LINC02433', id: 'LINC02433'},
{value: 'LINC02434', id: 'LINC02434'},
{value: 'LINC02435', id: 'LINC02435'},
{value: 'LINC02436', id: 'LINC02436'},
{value: 'LINC02437', id: 'LINC02437'},
{value: 'LINC02438', id: 'LINC02438'},
{value: 'LINC02439', id: 'LINC02439'},
{value: 'LINC02440', id: 'LINC02440'},
{value: 'LINC02441', id: 'LINC02441'},
{value: 'LINC02442', id: 'LINC02442'},
{value: 'LINC02443', id: 'LINC02443'},
{value: 'LINC02444', id: 'LINC02444'},
{value: 'LINC02445', id: 'LINC02445'},
{value: 'LINC02446', id: 'LINC02446'},
{value: 'LINC02447', id: 'LINC02447'},
{value: 'LINC02448', id: 'LINC02448'},
{value: 'LINC02449', id: 'LINC02449'},
{value: 'LINC02450', id: 'LINC02450'},
{value: 'LINC02451', id: 'LINC02451'},
{value: 'LINC02452', id: 'LINC02452'},
{value: 'LINC02453', id: 'LINC02453'},
{value: 'LINC02454', id: 'LINC02454'},
{value: 'LINC02455', id: 'LINC02455'},
{value: 'LINC02456', id: 'LINC02456'},
{value: 'LINC02457', id: 'LINC02457'},
{value: 'LINC02458', id: 'LINC02458'},
{value: 'LINC02459', id: 'LINC02459'},
{value: 'LINC02460', id: 'LINC02460'},
{value: 'LINC02461', id: 'LINC02461'},
{value: 'LINC02462', id: 'LINC02462'},
{value: 'LINC02463', id: 'LINC02463'},
{value: 'LINC02464', id: 'LINC02464'},
{value: 'LINC02465', id: 'LINC02465'},
{value: 'LINC02466', id: 'LINC02466'},
{value: 'LINC02468', id: 'LINC02468'},
{value: 'LINC02469', id: 'LINC02469'},
{value: 'LINC02470', id: 'LINC02470'},
{value: 'LINC02471', id: 'LINC02471'},
{value: 'LINC02472', id: 'LINC02472'},
{value: 'LINC02473', id: 'LINC02473'},
{value: 'LINC02474', id: 'LINC02474'},
{value: 'LINC02475', id: 'LINC02475'},
{value: 'LINC02476', id: 'LINC02476'},
{value: 'LINC02477', id: 'LINC02477'},
{value: 'LINC02478', id: 'LINC02478'},
{value: 'LINC02479', id: 'LINC02479'},
{value: 'LINC02480', id: 'LINC02480'},
{value: 'LINC02481', id: 'LINC02481'},
{value: 'LINC02482', id: 'LINC02482'},
{value: 'LINC02483', id: 'LINC02483'},
{value: 'LINC02484', id: 'LINC02484'},
{value: 'LINC02485', id: 'LINC02485'},
{value: 'LINC02487', id: 'LINC02487'},
{value: 'LINC02488', id: 'LINC02488'},
{value: 'LINC02489', id: 'LINC02489'},
{value: 'LINC02490', id: 'LINC02490'},
{value: 'LINC02492', id: 'LINC02492'},
{value: 'LINC02493', id: 'LINC02493'},
{value: 'LINC02494', id: 'LINC02494'},
{value: 'LINC02496', id: 'LINC02496'},
{value: 'LINC02497', id: 'LINC02497'},
{value: 'LINC02498', id: 'LINC02498'},
{value: 'LINC02499', id: 'LINC02499'},
{value: 'LINC02500', id: 'LINC02500'},
{value: 'LINC02501', id: 'LINC02501'},
{value: 'LINC02502', id: 'LINC02502'},
{value: 'LINC02503', id: 'LINC02503'},
{value: 'LINC02504', id: 'LINC02504'},
{value: 'LINC02505', id: 'LINC02505'},
{value: 'LINC02506', id: 'LINC02506'},
{value: 'LINC02507', id: 'LINC02507'},
{value: 'LINC02508', id: 'LINC02508'},
{value: 'LINC02509', id: 'LINC02509'},
{value: 'LINC02511', id: 'LINC02511'},
{value: 'LINC02512', id: 'LINC02512'},
{value: 'LINC02513', id: 'LINC02513'},
{value: 'LINC02514', id: 'LINC02514'},
{value: 'LINC02515', id: 'LINC02515'},
{value: 'LINC02516', id: 'LINC02516'},
{value: 'LINC02517', id: 'LINC02517'},
{value: 'LINC02518', id: 'LINC02518'},
{value: 'LINC02519', id: 'LINC02519'},
{value: 'LINC02520', id: 'LINC02520'},
{value: 'LINC02521', id: 'LINC02521'},
{value: 'LINC02522', id: 'LINC02522'},
{value: 'LINC02523', id: 'LINC02523'},
{value: 'LINC02525', id: 'LINC02525'},
{value: 'LINC02526', id: 'LINC02526'},
{value: 'LINC02527', id: 'LINC02527'},
{value: 'LINC02528', id: 'LINC02528'},
{value: 'LINC02529', id: 'LINC02529'},
{value: 'LINC02530', id: 'LINC02530'},
{value: 'LINC02531', id: 'LINC02531'},
{value: 'LINC02532', id: 'LINC02532'},
{value: 'LINC02534', id: 'LINC02534'},
{value: 'LINC02535', id: 'LINC02535'},
{value: 'LINC02536', id: 'LINC02536'},
{value: 'LINC02537', id: 'LINC02537'},
{value: 'LINC02538', id: 'LINC02538'},
{value: 'LINC02539', id: 'LINC02539'},
{value: 'LINC02540', id: 'LINC02540'},
{value: 'LINC02541', id: 'LINC02541'},
{value: 'LINC02542', id: 'LINC02542'},
{value: 'LINC02543', id: 'LINC02543'},
{value: 'LINC02544', id: 'LINC02544'},
{value: 'LINC02547', id: 'LINC02547'},
{value: 'LINC02548', id: 'LINC02548'},
{value: 'LINC02549', id: 'LINC02549'},
{value: 'LINC02550', id: 'LINC02550'},
{value: 'LINC02551', id: 'LINC02551'},
{value: 'LINC02552', id: 'LINC02552'},
{value: 'LINC02553', id: 'LINC02553'},
{value: 'LINC02554', id: 'LINC02554'},
{value: 'LINC02555', id: 'LINC02555'},
{value: 'LINC02556', id: 'LINC02556'},
{value: 'LINC02557', id: 'LINC02557'},
{value: 'LINC02558', id: 'LINC02558'},
{value: 'LINC02559', id: 'LINC02559'},
{value: 'LINC02560', id: 'LINC02560'},
{value: 'LINC02561', id: 'LINC02561'},
{value: 'LINC02562', id: 'LINC02562'},
{value: 'LINC02563', id: 'LINC02563'},
{value: 'LINC02564', id: 'LINC02564'},
{value: 'LINC02565', id: 'LINC02565'},
{value: 'LINC02567', id: 'LINC02567'},
{value: 'LINC02568', id: 'LINC02568'},
{value: 'LINC02569', id: 'LINC02569'},
{value: 'LINC02570', id: 'LINC02570'},
{value: 'LINC02571', id: 'LINC02571'},
{value: 'LINC02572', id: 'LINC02572'},
{value: 'LINC02573', id: 'LINC02573'},
{value: 'LINC02574', id: 'LINC02574'},
{value: 'LINC02575', id: 'LINC02575'},
{value: 'LINC02576', id: 'LINC02576'},
{value: 'LINC02577', id: 'LINC02577'},
{value: 'LINC02578', id: 'LINC02578'},
{value: 'LINC02579', id: 'LINC02579'},
{value: 'LINC02580', id: 'LINC02580'},
{value: 'LINC02582', id: 'LINC02582'},
{value: 'LINC02583', id: 'LINC02583'},
{value: 'LINC02585', id: 'LINC02585'},
{value: 'LINC02586', id: 'LINC02586'},
{value: 'LINC02587', id: 'LINC02587'},
{value: 'LINC02590', id: 'LINC02590'},
{value: 'LINC02591', id: 'LINC02591'},
{value: 'LINC02593', id: 'LINC02593'},
{value: 'LINC02594', id: 'LINC02594'},
{value: 'LINC02596', id: 'LINC02596'},
{value: 'LINC02598', id: 'LINC02598'},
{value: 'LINC02599', id: 'LINC02599'},
{value: 'LINC02600', id: 'LINC02600'},
{value: 'LINC02603', id: 'LINC02603'},
{value: 'LINC02604', id: 'LINC02604'},
{value: 'LINC02605', id: 'LINC02605'},
{value: 'LINC02606', id: 'LINC02606'},
{value: 'LINC02607', id: 'LINC02607'},
{value: 'LINC02608', id: 'LINC02608'},
{value: 'LINC02609', id: 'LINC02609'},
{value: 'LINC02610', id: 'LINC02610'},
{value: 'LINC02611', id: 'LINC02611'},
{value: 'LINC02612', id: 'LINC02612'},
{value: 'LINC02613', id: 'LINC02613'},
{value: 'LINC02614', id: 'LINC02614'},
{value: 'LINC02615', id: 'LINC02615'},
{value: 'LINC02616', id: 'LINC02616'},
{value: 'LINC02617', id: 'LINC02617'},
{value: 'LINC02618', id: 'LINC02618'},
{value: 'LINC02619', id: 'LINC02619'},
{value: 'LINC02620', id: 'LINC02620'},
{value: 'LINC02621', id: 'LINC02621'},
{value: 'LINC02623', id: 'LINC02623'},
{value: 'LINC02624', id: 'LINC02624'},
{value: 'LINC02625', id: 'LINC02625'},
{value: 'LINC02626', id: 'LINC02626'},
{value: 'LINC02627', id: 'LINC02627'},
{value: 'LINC02628', id: 'LINC02628'},
{value: 'LINC02629', id: 'LINC02629'},
{value: 'LINC02630', id: 'LINC02630'},
{value: 'LINC02631', id: 'LINC02631'},
{value: 'LINC02632', id: 'LINC02632'},
{value: 'LINC02633', id: 'LINC02633'},
{value: 'LINC02634', id: 'LINC02634'},
{value: 'LINC02635', id: 'LINC02635'},
{value: 'LINC02636', id: 'LINC02636'},
{value: 'LINC02639', id: 'LINC02639'},
{value: 'LINC02640', id: 'LINC02640'},
{value: 'LINC02641', id: 'LINC02641'},
{value: 'LINC02642', id: 'LINC02642'},
{value: 'LINC02643', id: 'LINC02643'},
{value: 'LINC02644', id: 'LINC02644'},
{value: 'LINC02645', id: 'LINC02645'},
{value: 'LINC02646', id: 'LINC02646'},
{value: 'LINC02647', id: 'LINC02647'},
{value: 'LINC02648', id: 'LINC02648'},
{value: 'LINC02649', id: 'LINC02649'},
{value: 'LINC02650', id: 'LINC02650'},
{value: 'LINC02651', id: 'LINC02651'},
{value: 'LINC02652', id: 'LINC02652'},
{value: 'LINC02653', id: 'LINC02653'},
{value: 'LINC02654', id: 'LINC02654'},
{value: 'LINC02655', id: 'LINC02655'},
{value: 'LINC02656', id: 'LINC02656'},
{value: 'LINC02658', id: 'LINC02658'},
{value: 'LINC02659', id: 'LINC02659'},
{value: 'LINC02660', id: 'LINC02660'},
{value: 'LINC02661', id: 'LINC02661'},
{value: 'LINC02662', id: 'LINC02662'},
{value: 'LINC02663', id: 'LINC02663'},
{value: 'LINC02664', id: 'LINC02664'},
{value: 'LINC02666', id: 'LINC02666'},
{value: 'LINC02667', id: 'LINC02667'},
{value: 'LINC02668', id: 'LINC02668'},
{value: 'LINC02669', id: 'LINC02669'},
{value: 'LINC02670', id: 'LINC02670'},
{value: 'LINC02671', id: 'LINC02671'},
{value: 'LINC02672', id: 'LINC02672'},
{value: 'LINC02673', id: 'LINC02673'},
{value: 'LINC02674', id: 'LINC02674'},
{value: 'LINC02675', id: 'LINC02675'},
{value: 'LINC02676', id: 'LINC02676'},
{value: 'LINC02679', id: 'LINC02679'},
{value: 'LINC02680', id: 'LINC02680'},
{value: 'LINC02681', id: 'LINC02681'},
{value: 'LINC02682', id: 'LINC02682'},
{value: 'LINC02683', id: 'LINC02683'},
{value: 'LINC02684', id: 'LINC02684'},
{value: 'LINC02685', id: 'LINC02685'},
{value: 'LINC02686', id: 'LINC02686'},
{value: 'LINC02687', id: 'LINC02687'},
{value: 'LINC02688', id: 'LINC02688'},
{value: 'LINC02689', id: 'LINC02689'},
{value: 'LINC02690', id: 'LINC02690'},
{value: 'LINC02691', id: 'LINC02691'},
{value: 'LINC02692', id: 'LINC02692'},
{value: 'LINC02693', id: 'LINC02693'},
{value: 'LINC02694', id: 'LINC02694'},
{value: 'LINC02695', id: 'LINC02695'},
{value: 'LINC02696', id: 'LINC02696'},
{value: 'LINC02697', id: 'LINC02697'},
{value: 'LINC02698', id: 'LINC02698'},
{value: 'LINC02699', id: 'LINC02699'},
{value: 'LINC02701', id: 'LINC02701'},
{value: 'LINC02702', id: 'LINC02702'},
{value: 'LINC02703', id: 'LINC02703'},
{value: 'LINC02704', id: 'LINC02704'},
{value: 'LINC02705', id: 'LINC02705'},
{value: 'LINC02706', id: 'LINC02706'},
{value: 'LINC02707', id: 'LINC02707'},
{value: 'LINC02708', id: 'LINC02708'},
{value: 'LINC02709', id: 'LINC02709'},
{value: 'LINC02710', id: 'LINC02710'},
{value: 'LINC02711', id: 'LINC02711'},
{value: 'LINC02712', id: 'LINC02712'},
{value: 'LINC02713', id: 'LINC02713'},
{value: 'LINC02714', id: 'LINC02714'},
{value: 'LINC02715', id: 'LINC02715'},
{value: 'LINC02716', id: 'LINC02716'},
{value: 'LINC02717', id: 'LINC02717'},
{value: 'LINC02718', id: 'LINC02718'},
{value: 'LINC02719', id: 'LINC02719'},
{value: 'LINC02720', id: 'LINC02720'},
{value: 'LINC02721', id: 'LINC02721'},
{value: 'LINC02722', id: 'LINC02722'},
{value: 'LINC02723', id: 'LINC02723'},
{value: 'LINC02724', id: 'LINC02724'},
{value: 'LINC02725', id: 'LINC02725'},
{value: 'LINC02726', id: 'LINC02726'},
{value: 'LINC02727', id: 'LINC02727'},
{value: 'LINC02728', id: 'LINC02728'},
{value: 'LINC02729', id: 'LINC02729'},
{value: 'LINC02730', id: 'LINC02730'},
{value: 'LINC02731', id: 'LINC02731'},
{value: 'LINC02732', id: 'LINC02732'},
{value: 'LINC02733', id: 'LINC02733'},
{value: 'LINC02734', id: 'LINC02734'},
{value: 'LINC02735', id: 'LINC02735'},
{value: 'LINC02736', id: 'LINC02736'},
{value: 'LINC02737', id: 'LINC02737'},
{value: 'LINC02739', id: 'LINC02739'},
{value: 'LINC02740', id: 'LINC02740'},
{value: 'LINC02741', id: 'LINC02741'},
{value: 'LINC02742', id: 'LINC02742'},
{value: 'LINC02743', id: 'LINC02743'},
{value: 'LINC02745', id: 'LINC02745'},
{value: 'LINC02746', id: 'LINC02746'},
{value: 'LINC02747', id: 'LINC02747'},
{value: 'LINC02748', id: 'LINC02748'},
{value: 'LINC02749', id: 'LINC02749'},
{value: 'LINC02750', id: 'LINC02750'},
{value: 'LINC02751', id: 'LINC02751'},
{value: 'LINC02752', id: 'LINC02752'},
{value: 'LINC02753', id: 'LINC02753'},
{value: 'LINC02754', id: 'LINC02754'},
{value: 'LINC02755', id: 'LINC02755'},
{value: 'LINC02756', id: 'LINC02756'},
{value: 'LINC02757', id: 'LINC02757'},
{value: 'LINC02758', id: 'LINC02758'},
{value: 'LINC02759', id: 'LINC02759'},
{value: 'LINC02760', id: 'LINC02760'},
{value: 'LINC02761', id: 'LINC02761'},
{value: 'LINC02762', id: 'LINC02762'},
{value: 'LINC02763', id: 'LINC02763'},
{value: 'LINC02764', id: 'LINC02764'},
{value: 'LINC02765', id: 'LINC02765'},
{value: 'LINC02766', id: 'LINC02766'},
{value: 'LINC02767', id: 'LINC02767'},
{value: 'LINC02768', id: 'LINC02768'},
{value: 'LINC02769', id: 'LINC02769'},
{value: 'LINC02770', id: 'LINC02770'},
{value: 'LINC02771', id: 'LINC02771'},
{value: 'LINC02772', id: 'LINC02772'},
{value: 'LINC02773', id: 'LINC02773'},
{value: 'LINC02774', id: 'LINC02774'},
{value: 'LINC02775', id: 'LINC02775'},
{value: 'LINC02776', id: 'LINC02776'},
{value: 'LINC02777', id: 'LINC02777'},
{value: 'LINC02778', id: 'LINC02778'},
{value: 'LINC02779', id: 'LINC02779'},
{value: 'LINC02780', id: 'LINC02780'},
{value: 'LINC02781', id: 'LINC02781'},
{value: 'LINC02782', id: 'LINC02782'},
{value: 'LINC02783', id: 'LINC02783'},
{value: 'LINC02784', id: 'LINC02784'},
{value: 'LINC02785', id: 'LINC02785'},
{value: 'LINC02786', id: 'LINC02786'},
{value: 'LINC02787', id: 'LINC02787'},
{value: 'LINC02788', id: 'LINC02788'},
{value: 'LINC02789', id: 'LINC02789'},
{value: 'LINC02790', id: 'LINC02790'},
{value: 'LINC02791', id: 'LINC02791'},
{value: 'LINC02792', id: 'LINC02792'},
{value: 'LINC02793', id: 'LINC02793'},
{value: 'LINC02794', id: 'LINC02794'},
{value: 'LINC02795', id: 'LINC02795'},
{value: 'LINC02798', id: 'LINC02798'},
{value: 'LINC02799', id: 'LINC02799'},
{value: 'LINC02801', id: 'LINC02801'},
{value: 'LINC02802', id: 'LINC02802'},
{value: 'LINC02803', id: 'LINC02803'},
{value: 'LINC02804', id: 'LINC02804'},
{value: 'LINC02805', id: 'LINC02805'},
{value: 'LINC02806', id: 'LINC02806'},
{value: 'LINC02808', id: 'LINC02808'},
{value: 'LINC02809', id: 'LINC02809'},
{value: 'LINC02810', id: 'LINC02810'},
{value: 'LINC02811', id: 'LINC02811'},
{value: 'LINC02812', id: 'LINC02812'},
{value: 'LINC02813', id: 'LINC02813'},
{value: 'LINC02814', id: 'LINC02814'},
{value: 'LINC02815', id: 'LINC02815'},
{value: 'LINC02816', id: 'LINC02816'},
{value: 'LINC02817', id: 'LINC02817'},
{value: 'LINC02818', id: 'LINC02818'},
{value: 'LINC02819', id: 'LINC02819'},
{value: 'LINC02820', id: 'LINC02820'},
{value: 'LINC02821', id: 'LINC02821'},
{value: 'LINC02822', id: 'LINC02822'},
{value: 'LINC02823', id: 'LINC02823'},
{value: 'LINC02824', id: 'LINC02824'},
{value: 'LINC02826', id: 'LINC02826'},
{value: 'LINC02828', id: 'LINC02828'},
{value: 'LINC02829', id: 'LINC02829'},
{value: 'LINC02831', id: 'LINC02831'},
{value: 'LINC02832', id: 'LINC02832'},
{value: 'LINC02833', id: 'LINC02833'},
{value: 'LINC02834', id: 'LINC02834'},
{value: 'LINC02835', id: 'LINC02835'},
{value: 'LINC02836', id: 'LINC02836'},
{value: 'LINC02837', id: 'LINC02837'},
{value: 'LINC02838', id: 'LINC02838'},
{value: 'LINC02839', id: 'LINC02839'},
{value: 'LINC02840', id: 'LINC02840'},
{value: 'LINC02841', id: 'LINC02841'},
{value: 'LINC02842', id: 'LINC02842'},
{value: 'LINC02843', id: 'LINC02843'},
{value: 'LINC02844', id: 'LINC02844'},
{value: 'LINC02845', id: 'LINC02845'},
{value: 'LINC02846', id: 'LINC02846'},
{value: 'LINC02848', id: 'LINC02848'},
{value: 'LINC02850', id: 'LINC02850'},
{value: 'LINC02851', id: 'LINC02851'},
{value: 'LINC02852', id: 'LINC02852'},
{value: 'LINC02853', id: 'LINC02853'},
{value: 'LINC02854', id: 'LINC02854'},
{value: 'LINC02856', id: 'LINC02856'},
{value: 'LINC02857', id: 'LINC02857'},
{value: 'LINC02858', id: 'LINC02858'},
{value: 'LINC02860', id: 'LINC02860'},
{value: 'LINC02861', id: 'LINC02861'},
{value: 'LINC02863', id: 'LINC02863'},
{value: 'LINC02864', id: 'LINC02864'},
{value: 'LINC02865', id: 'LINC02865'},
{value: 'LINC02866', id: 'LINC02866'},
{value: 'LINC02868', id: 'LINC02868'},
{value: 'LINC02869', id: 'LINC02869'},
{value: 'LINC02870', id: 'LINC02870'},
{value: 'LINC02871', id: 'LINC02871'},
{value: 'LINC02872', id: 'LINC02872'},
{value: 'LINC02873', id: 'LINC02873'},
{value: 'LINC02874', id: 'LINC02874'},
{value: 'LINC02875', id: 'LINC02875'},
{value: 'LINC02876', id: 'LINC02876'},
{value: 'LINC02878', id: 'LINC02878'},
{value: 'LINC02880', id: 'LINC02880'},
{value: 'LINC02881', id: 'LINC02881'},
{value: 'LINC02882', id: 'LINC02882'},
{value: 'LINC02883', id: 'LINC02883'},
{value: 'LINC02884', id: 'LINC02884'},
{value: 'LINC02885', id: 'LINC02885'},
{value: 'LINC02886', id: 'LINC02886'},
{value: 'LINC02887', id: 'LINC02887'},
{value: 'LINC02889', id: 'LINC02889'},
{value: 'LINC02890', id: 'LINC02890'},
{value: 'LINC02891', id: 'LINC02891'},
{value: 'LINC02893', id: 'LINC02893'},
{value: 'LINC02894', id: 'LINC02894'},
{value: 'LINC02895', id: 'LINC02895'},
{value: 'LINC02897', id: 'LINC02897'},
{value: 'LINC02898', id: 'LINC02898'},
{value: 'LINC02899', id: 'LINC02899'},
{value: 'LINC02901', id: 'LINC02901'},
{value: 'LINC02902', id: 'LINC02902'},
{value: 'LINC02903', id: 'LINC02903'},
{value: 'LINC02904', id: 'LINC02904'},
{value: 'LINC02906', id: 'LINC02906'},
{value: 'LINC02907', id: 'LINC02907'},
{value: 'LINC02908', id: 'LINC02908'},
{value: 'LINC02909', id: 'LINC02909'},
{value: 'LINC02910', id: 'LINC02910'},
{value: 'LINC02911', id: 'LINC02911'},
{value: 'LINC02912', id: 'LINC02912'},
{value: 'LINC02913', id: 'LINC02913'},
{value: 'LINC02914', id: 'LINC02914'},
{value: 'LINC02915', id: 'LINC02915'},
{value: 'LINC02916', id: 'LINC02916'},
{value: 'LINCADL', id: 'LINCADL'},
{value: 'LINCMD1', id: 'LINCMD1'},
{value: 'LINCR-0001', id: 'LINCR-0001'},
{value: 'LINCR-0003', id: 'LINCR-0003'},
{value: 'LINGO1', id: 'LINGO1'},
{value: 'LINGO1-AS1', id: 'LINGO1-AS1'},
{value: 'LINGO2', id: 'LINGO2'},
{value: 'LINGO3', id: 'LINGO3'},
{value: 'LINGO4', id: 'LINGO4'},
{value: 'LINP1', id: 'LINP1'},
{value: 'LINS1', id: 'LINS1'},
{value: 'LIPA', id: 'LIPA'},
{value: 'LIPC', id: 'LIPC'},
{value: 'LIPE', id: 'LIPE'},
{value: 'LIPE-AS1', id: 'LIPE-AS1'},
{value: 'LIPF', id: 'LIPF'},
{value: 'LIPG', id: 'LIPG'},
{value: 'LIPH', id: 'LIPH'},
{value: 'LIPI', id: 'LIPI'},
{value: 'LIPJ', id: 'LIPJ'},
{value: 'LIPK', id: 'LIPK'},
{value: 'LIPM', id: 'LIPM'},
{value: 'LIPN', id: 'LIPN'},
{value: 'LIPT1', id: 'LIPT1'},
{value: 'LIPT2', id: 'LIPT2'},
{value: 'LITAF', id: 'LITAF'},
{value: 'LITAFD', id: 'LITAFD'},
{value: 'LIVAR', id: 'LIVAR'},
{value: 'LIX1', id: 'LIX1'},
{value: 'LIX1-AS1', id: 'LIX1-AS1'},
{value: 'LIX1L', id: 'LIX1L'},
{value: 'LIX1L-AS1', id: 'LIX1L-AS1'},
{value: 'LKAAEAR1', id: 'LKAAEAR1'},
{value: 'LL22NC01-81G9.3', id: 'LL22NC01-81G9.3'},
{value: 'LL22NC03-63E9.3', id: 'LL22NC03-63E9.3'},
{value: 'LLCFC1', id: 'LLCFC1'},
{value: 'LLGL1', id: 'LLGL1'},
{value: 'LLGL2', id: 'LLGL2'},
{value: 'LLPH', id: 'LLPH'},
{value: 'LLPH-DT', id: 'LLPH-DT'},
{value: 'LLPHP1', id: 'LLPHP1'},
{value: 'LLPHP2', id: 'LLPHP2'},
{value: 'LLPHP3', id: 'LLPHP3'},
{value: 'LMAN1', id: 'LMAN1'},
{value: 'LMAN1L', id: 'LMAN1L'},
{value: 'LMAN2', id: 'LMAN2'},
{value: 'LMAN2L', id: 'LMAN2L'},
{value: 'LMBR1', id: 'LMBR1'},
{value: 'LMBR1L', id: 'LMBR1L'},
{value: 'LMBRD1', id: 'LMBRD1'},
{value: 'LMBRD2', id: 'LMBRD2'},
{value: 'LMCD1', id: 'LMCD1'},
{value: 'LMCD1-AS1', id: 'LMCD1-AS1'},
{value: 'LMF1', id: 'LMF1'},
{value: 'LMF1-AS1', id: 'LMF1-AS1'},
{value: 'LMF2', id: 'LMF2'},
{value: 'LMLN', id: 'LMLN'},
{value: 'LMLN-AS1', id: 'LMLN-AS1'},
{value: 'LMLN2', id: 'LMLN2'},
{value: 'LMNA', id: 'LMNA'},
{value: 'LMNB1', id: 'LMNB1'},
{value: 'LMNB1-DT', id: 'LMNB1-DT'},
{value: 'LMNB2', id: 'LMNB2'},
{value: 'LMNTD1', id: 'LMNTD1'},
{value: 'LMNTD2', id: 'LMNTD2'},
{value: 'LMNTD2-AS1', id: 'LMNTD2-AS1'},
{value: 'LMO1', id: 'LMO1'},
{value: 'LMO2', id: 'LMO2'},
{value: 'LMO3', id: 'LMO3'},
{value: 'LMO4', id: 'LMO4'},
{value: 'LMO7', id: 'LMO7'},
{value: 'LMO7-AS1', id: 'LMO7-AS1'},
{value: 'LMO7DN', id: 'LMO7DN'},
{value: 'LMO7DN-IT1', id: 'LMO7DN-IT1'},
{value: 'LMOD1', id: 'LMOD1'},
{value: 'LMOD2', id: 'LMOD2'},
{value: 'LMOD3', id: 'LMOD3'},
{value: 'LMTK2', id: 'LMTK2'},
{value: 'LMTK3', id: 'LMTK3'},
{value: 'LMX1A', id: 'LMX1A'},
{value: 'LMX1A-AS2', id: 'LMX1A-AS2'},
{value: 'LMX1B', id: 'LMX1B'},
{value: 'LNC-LBCS', id: 'LNC-LBCS'},
{value: 'LNCAROD', id: 'LNCAROD'},
{value: 'LNCARSR', id: 'LNCARSR'},
{value: 'LNCDAT', id: 'LNCDAT'},
{value: 'LNCNEF', id: 'LNCNEF'},
{value: 'LNCOC1', id: 'LNCOC1'},
{value: 'LNCOG', id: 'LNCOG'},
{value: 'LNCPRESS1', id: 'LNCPRESS1'},
{value: 'LNCPRESS2', id: 'LNCPRESS2'},
{value: 'LNCTAM34A', id: 'LNCTAM34A'},
{value: 'LNP1', id: 'LNP1'},
{value: 'LNPEP', id: 'LNPEP'},
{value: 'LNPK', id: 'LNPK'},
{value: 'LNX1', id: 'LNX1'},
{value: 'LNX1-AS1', id: 'LNX1-AS1'},
{value: 'LNX1-AS2', id: 'LNX1-AS2'},
{value: 'LNX2', id: 'LNX2'},
{value: 'LOH12CR2', id: 'LOH12CR2'},
{value: 'LONP1', id: 'LONP1'},
{value: 'LONP2', id: 'LONP2'},
{value: 'LONRF1', id: 'LONRF1'},
{value: 'LONRF2', id: 'LONRF2'},
{value: 'LONRF2P1', id: 'LONRF2P1'},
{value: 'LONRF2P2', id: 'LONRF2P2'},
{value: 'LONRF2P3', id: 'LONRF2P3'},
{value: 'LONRF2P4', id: 'LONRF2P4'},
{value: 'LONRF2P5', id: 'LONRF2P5'},
{value: 'LONRF3', id: 'LONRF3'},
{value: 'LORICRIN', id: 'LORICRIN'},
{value: 'LOX', id: 'LOX'},
{value: 'LOXHD1', id: 'LOXHD1'},
{value: 'LOXL1', id: 'LOXL1'},
{value: 'LOXL1-AS1', id: 'LOXL1-AS1'},
{value: 'LOXL2', id: 'LOXL2'},
{value: 'LOXL3', id: 'LOXL3'},
{value: 'LOXL4', id: 'LOXL4'},
{value: 'LPA', id: 'LPA'},
{value: 'LPAL2', id: 'LPAL2'},
{value: 'LPAR1', id: 'LPAR1'},
{value: 'LPAR2', id: 'LPAR2'},
{value: 'LPAR3', id: 'LPAR3'},
{value: 'LPAR4', id: 'LPAR4'},
{value: 'LPAR5', id: 'LPAR5'},
{value: 'LPAR6', id: 'LPAR6'},
{value: 'LPCAT1', id: 'LPCAT1'},
{value: 'LPCAT2', id: 'LPCAT2'},
{value: 'LPCAT2BP', id: 'LPCAT2BP'},
{value: 'LPCAT3', id: 'LPCAT3'},
{value: 'LPCAT4', id: 'LPCAT4'},
{value: 'LPGAT1', id: 'LPGAT1'},
{value: 'LPGAT1-AS1', id: 'LPGAT1-AS1'},
{value: 'LPIN1', id: 'LPIN1'},
{value: 'LPIN2', id: 'LPIN2'},
{value: 'LPIN3', id: 'LPIN3'},
{value: 'LPL', id: 'LPL'},
{value: 'LPO', id: 'LPO'},
{value: 'LPP', id: 'LPP'},
{value: 'LPP-AS1', id: 'LPP-AS1'},
{value: 'LPP-AS2', id: 'LPP-AS2'},
{value: 'LPXN', id: 'LPXN'},
{value: 'LRAT', id: 'LRAT'},
{value: 'LRATD1', id: 'LRATD1'},
{value: 'LRATD2', id: 'LRATD2'},
{value: 'LRBA', id: 'LRBA'},
{value: 'LRCH1', id: 'LRCH1'},
{value: 'LRCH2', id: 'LRCH2'},
{value: 'LRCH3', id: 'LRCH3'},
{value: 'LRCH4', id: 'LRCH4'},
{value: 'LRCOL1', id: 'LRCOL1'},
{value: 'LRFN1', id: 'LRFN1'},
{value: 'LRFN2', id: 'LRFN2'},
{value: 'LRFN3', id: 'LRFN3'},
{value: 'LRFN4', id: 'LRFN4'},
{value: 'LRFN5', id: 'LRFN5'},
{value: 'LRFN5-DT', id: 'LRFN5-DT'},
{value: 'LRG1', id: 'LRG1'},
{value: 'LRGUK', id: 'LRGUK'},
{value: 'LRIF1', id: 'LRIF1'},
{value: 'LRIG1', id: 'LRIG1'},
{value: 'LRIG2', id: 'LRIG2'},
{value: 'LRIG2-DT', id: 'LRIG2-DT'},
{value: 'LRIG3', id: 'LRIG3'},
{value: 'LRIG3-DT', id: 'LRIG3-DT'},
{value: 'LRIT1', id: 'LRIT1'},
{value: 'LRIT2', id: 'LRIT2'},
{value: 'LRIT3', id: 'LRIT3'},
{value: 'LRMDA', id: 'LRMDA'},
{value: 'LRP1', id: 'LRP1'},
{value: 'LRP1-AS', id: 'LRP1-AS'},
{value: 'LRP10', id: 'LRP10'},
{value: 'LRP11', id: 'LRP11'},
{value: 'LRP12', id: 'LRP12'},
{value: 'LRP1B', id: 'LRP1B'},
{value: 'LRP2', id: 'LRP2'},
{value: 'LRP2BP', id: 'LRP2BP'},
{value: 'LRP3', id: 'LRP3'},
{value: 'LRP4', id: 'LRP4'},
{value: 'LRP4-AS1', id: 'LRP4-AS1'},
{value: 'LRP5', id: 'LRP5'},
{value: 'LRP5L', id: 'LRP5L'},
{value: 'LRP6', id: 'LRP6'},
{value: 'LRP8', id: 'LRP8'},
{value: 'LRPAP1', id: 'LRPAP1'},
{value: 'LRPPRC', id: 'LRPPRC'},
{value: 'LRR1', id: 'LRR1'},
{value: 'LRRC1', id: 'LRRC1'},
{value: 'LRRC10', id: 'LRRC10'},
{value: 'LRRC10B', id: 'LRRC10B'},
{value: 'LRRC14', id: 'LRRC14'},
{value: 'LRRC14B', id: 'LRRC14B'},
{value: 'LRRC15', id: 'LRRC15'},
{value: 'LRRC17', id: 'LRRC17'},
{value: 'LRRC18', id: 'LRRC18'},
{value: 'LRRC2', id: 'LRRC2'},
{value: 'LRRC2-AS1', id: 'LRRC2-AS1'},
{value: 'LRRC20', id: 'LRRC20'},
{value: 'LRRC23', id: 'LRRC23'},
{value: 'LRRC24', id: 'LRRC24'},
{value: 'LRRC25', id: 'LRRC25'},
{value: 'LRRC26', id: 'LRRC26'},
{value: 'LRRC27', id: 'LRRC27'},
{value: 'LRRC28', id: 'LRRC28'},
{value: 'LRRC29', id: 'LRRC29'},
{value: 'LRRC2P1', id: 'LRRC2P1'},
{value: 'LRRC3', id: 'LRRC3'},
{value: 'LRRC3-DT', id: 'LRRC3-DT'},
{value: 'LRRC30', id: 'LRRC30'},
{value: 'LRRC31', id: 'LRRC31'},
{value: 'LRRC32', id: 'LRRC32'},
{value: 'LRRC34', id: 'LRRC34'},
{value: 'LRRC34P2', id: 'LRRC34P2'},
{value: 'LRRC36', id: 'LRRC36'},
{value: 'LRRC37A', id: 'LRRC37A'},
{value: 'LRRC37A11P', id: 'LRRC37A11P'},
{value: 'LRRC37A12P', id: 'LRRC37A12P'},
{value: 'LRRC37A13P', id: 'LRRC37A13P'},
{value: 'LRRC37A15P', id: 'LRRC37A15P'},
{value: 'LRRC37A16P', id: 'LRRC37A16P'},
{value: 'LRRC37A17P', id: 'LRRC37A17P'},
{value: 'LRRC37A2', id: 'LRRC37A2'},
{value: 'LRRC37A3', id: 'LRRC37A3'},
{value: 'LRRC37A4P', id: 'LRRC37A4P'},
{value: 'LRRC37A5P', id: 'LRRC37A5P'},
{value: 'LRRC37A6P', id: 'LRRC37A6P'},
{value: 'LRRC37A7P', id: 'LRRC37A7P'},
{value: 'LRRC37A9P', id: 'LRRC37A9P'},
{value: 'LRRC37B', id: 'LRRC37B'},
{value: 'LRRC37BP1', id: 'LRRC37BP1'},
{value: 'LRRC38', id: 'LRRC38'},
{value: 'LRRC39', id: 'LRRC39'},
{value: 'LRRC3B', id: 'LRRC3B'},
{value: 'LRRC3C', id: 'LRRC3C'},
{value: 'LRRC4', id: 'LRRC4'},
{value: 'LRRC40', id: 'LRRC40'},
{value: 'LRRC41', id: 'LRRC41'},
{value: 'LRRC42', id: 'LRRC42'},
{value: 'LRRC43', id: 'LRRC43'},
{value: 'LRRC45', id: 'LRRC45'},
{value: 'LRRC46', id: 'LRRC46'},
{value: 'LRRC47', id: 'LRRC47'},
{value: 'LRRC49', id: 'LRRC49'},
{value: 'LRRC4B', id: 'LRRC4B'},
{value: 'LRRC4C', id: 'LRRC4C'},
{value: 'LRRC51', id: 'LRRC51'},
{value: 'LRRC52', id: 'LRRC52'},
{value: 'LRRC52-AS1', id: 'LRRC52-AS1'},
{value: 'LRRC53', id: 'LRRC53'},
{value: 'LRRC55', id: 'LRRC55'},
{value: 'LRRC56', id: 'LRRC56'},
{value: 'LRRC57', id: 'LRRC57'},
{value: 'LRRC57P1', id: 'LRRC57P1'},
{value: 'LRRC58', id: 'LRRC58'},
{value: 'LRRC59', id: 'LRRC59'},
{value: 'LRRC61', id: 'LRRC61'},
{value: 'LRRC63', id: 'LRRC63'},
{value: 'LRRC66', id: 'LRRC66'},
{value: 'LRRC69', id: 'LRRC69'},
{value: 'LRRC7', id: 'LRRC7'},
{value: 'LRRC70', id: 'LRRC70'},
{value: 'LRRC71', id: 'LRRC71'},
{value: 'LRRC72', id: 'LRRC72'},
{value: 'LRRC73', id: 'LRRC73'},
{value: 'LRRC74A', id: 'LRRC74A'},
{value: 'LRRC74B', id: 'LRRC74B'},
{value: 'LRRC75A', id: 'LRRC75A'},
{value: 'LRRC75B', id: 'LRRC75B'},
{value: 'LRRC77P', id: 'LRRC77P'},
{value: 'LRRC8A', id: 'LRRC8A'},
{value: 'LRRC8B', id: 'LRRC8B'},
{value: 'LRRC8C', id: 'LRRC8C'},
{value: 'LRRC8C-DT', id: 'LRRC8C-DT'},
{value: 'LRRC8D', id: 'LRRC8D'},
{value: 'LRRC8D-DT', id: 'LRRC8D-DT'},
{value: 'LRRC8E', id: 'LRRC8E'},
{value: 'LRRC9', id: 'LRRC9'},
{value: 'LRRCC1', id: 'LRRCC1'},
{value: 'LRRFIP1', id: 'LRRFIP1'},
{value: 'LRRFIP1P1', id: 'LRRFIP1P1'},
{value: 'LRRFIP2', id: 'LRRFIP2'},
{value: 'LRRIQ1', id: 'LRRIQ1'},
{value: 'LRRIQ3', id: 'LRRIQ3'},
{value: 'LRRIQ4', id: 'LRRIQ4'},
{value: 'LRRK1', id: 'LRRK1'},
{value: 'LRRK2', id: 'LRRK2'},
{value: 'LRRK2-DT', id: 'LRRK2-DT'},
{value: 'LRRN1', id: 'LRRN1'},
{value: 'LRRN2', id: 'LRRN2'},
{value: 'LRRN3', id: 'LRRN3'},
{value: 'LRRN4', id: 'LRRN4'},
{value: 'LRRN4CL', id: 'LRRN4CL'},
{value: 'LRRTM1', id: 'LRRTM1'},
{value: 'LRRTM2', id: 'LRRTM2'},
{value: 'LRRTM3', id: 'LRRTM3'},
{value: 'LRRTM4', id: 'LRRTM4'},
{value: 'LRSAM1', id: 'LRSAM1'},
{value: 'LRTM1', id: 'LRTM1'},
{value: 'LRTM2', id: 'LRTM2'},
{value: 'LRTOMT', id: 'LRTOMT'},
{value: 'LRWD1', id: 'LRWD1'},
{value: 'LSAMP', id: 'LSAMP'},
{value: 'LSAMP-AS1', id: 'LSAMP-AS1'},
{value: 'LSG1', id: 'LSG1'},
{value: 'LSINCT5', id: 'LSINCT5'},
{value: 'LSM1', id: 'LSM1'},
{value: 'LSM10', id: 'LSM10'},
{value: 'LSM11', id: 'LSM11'},
{value: 'LSM12', id: 'LSM12'},
{value: 'LSM12P1', id: 'LSM12P1'},
{value: 'LSM14A', id: 'LSM14A'},
{value: 'LSM14B', id: 'LSM14B'},
{value: 'LSM1P1', id: 'LSM1P1'},
{value: 'LSM1P2', id: 'LSM1P2'},
{value: 'LSM2', id: 'LSM2'},
{value: 'LSM3', id: 'LSM3'},
{value: 'LSM3P3', id: 'LSM3P3'},
{value: 'LSM4', id: 'LSM4'},
{value: 'LSM5', id: 'LSM5'},
{value: 'LSM6', id: 'LSM6'},
{value: 'LSM6P2', id: 'LSM6P2'},
{value: 'LSM7', id: 'LSM7'},
{value: 'LSM8', id: 'LSM8'},
{value: 'LSMEM1', id: 'LSMEM1'},
{value: 'LSMEM2', id: 'LSMEM2'},
{value: 'LSP1', id: 'LSP1'},
{value: 'LSP1P1', id: 'LSP1P1'},
{value: 'LSP1P2', id: 'LSP1P2'},
{value: 'LSP1P3', id: 'LSP1P3'},
{value: 'LSP1P4', id: 'LSP1P4'},
{value: 'LSP1P5', id: 'LSP1P5'},
{value: 'LSR', id: 'LSR'},
{value: 'LSS', id: 'LSS'},
{value: 'LST1', id: 'LST1'},
{value: 'LTA', id: 'LTA'},
{value: 'LTA4H', id: 'LTA4H'},
{value: 'LTB', id: 'LTB'},
{value: 'LTB4R', id: 'LTB4R'},
{value: 'LTB4R2', id: 'LTB4R2'},
{value: 'LTBP1', id: 'LTBP1'},
{value: 'LTBP2', id: 'LTBP2'},
{value: 'LTBP3', id: 'LTBP3'},
{value: 'LTBP4', id: 'LTBP4'},
{value: 'LTBR', id: 'LTBR'},
{value: 'LTC4S', id: 'LTC4S'},
{value: 'LTF', id: 'LTF'},
{value: 'LTK', id: 'LTK'},
{value: 'LTN1', id: 'LTN1'},
{value: 'LTO1', id: 'LTO1'},
{value: 'LTO1P1', id: 'LTO1P1'},
{value: 'LTV1', id: 'LTV1'},
{value: 'LTV1P1', id: 'LTV1P1'},
{value: 'LUARIS', id: 'LUARIS'},
{value: 'LUC7L', id: 'LUC7L'},
{value: 'LUC7L2', id: 'LUC7L2'},
{value: 'LUC7L3', id: 'LUC7L3'},
{value: 'LUCAT1', id: 'LUCAT1'},
{value: 'LUM', id: 'LUM'},
{value: 'LUNAR1', id: 'LUNAR1'},
{value: 'LURAP1', id: 'LURAP1'},
{value: 'LURAP1L', id: 'LURAP1L'},
{value: 'LURAP1L-AS1', id: 'LURAP1L-AS1'},
{value: 'LUZP1', id: 'LUZP1'},
{value: 'LUZP2', id: 'LUZP2'},
{value: 'LV539', id: 'LV539'},
{value: 'LVRN', id: 'LVRN'},
{value: 'LXN', id: 'LXN'},
{value: 'LY6D', id: 'LY6D'},
{value: 'LY6E', id: 'LY6E'},
{value: 'LY6E-DT', id: 'LY6E-DT'},
{value: 'LY6G5B', id: 'LY6G5B'},
{value: 'LY6G5C', id: 'LY6G5C'},
{value: 'LY6G6C', id: 'LY6G6C'},
{value: 'LY6G6D', id: 'LY6G6D'},
{value: 'LY6G6E', id: 'LY6G6E'},
{value: 'LY6G6F', id: 'LY6G6F'},
{value: 'LY6G6F-LY6G6D', id: 'LY6G6F-LY6G6D'},
{value: 'LY6H', id: 'LY6H'},
{value: 'LY6K', id: 'LY6K'},
{value: 'LY6L', id: 'LY6L'},
{value: 'LY75', id: 'LY75'},
{value: 'LY75-CD302', id: 'LY75-CD302'},
{value: 'LY86', id: 'LY86'},
{value: 'LY86-AS1', id: 'LY86-AS1'},
{value: 'LY9', id: 'LY9'},
{value: 'LY96', id: 'LY96'},
{value: 'LYAR', id: 'LYAR'},
{value: 'LYARP1', id: 'LYARP1'},
{value: 'LYG1', id: 'LYG1'},
{value: 'LYL1', id: 'LYL1'},
{value: 'LYN', id: 'LYN'},
{value: 'LYNX1', id: 'LYNX1'},
{value: 'LYNX1-SLURP2', id: 'LYNX1-SLURP2'},
{value: 'LYPD1', id: 'LYPD1'},
{value: 'LYPD2', id: 'LYPD2'},
{value: 'LYPD3', id: 'LYPD3'},
{value: 'LYPD4', id: 'LYPD4'},
{value: 'LYPD5', id: 'LYPD5'},
{value: 'LYPD6', id: 'LYPD6'},
{value: 'LYPD6B', id: 'LYPD6B'},
{value: 'LYPD8', id: 'LYPD8'},
{value: 'LYPD9P', id: 'LYPD9P'},
{value: 'LYPLA1', id: 'LYPLA1'},
{value: 'LYPLA1P1', id: 'LYPLA1P1'},
{value: 'LYPLA1P3', id: 'LYPLA1P3'},
{value: 'LYPLA2', id: 'LYPLA2'},
{value: 'LYPLA2P1', id: 'LYPLA2P1'},
{value: 'LYPLA2P2', id: 'LYPLA2P2'},
{value: 'LYPLA2P3', id: 'LYPLA2P3'},
{value: 'LYPLAL1', id: 'LYPLAL1'},
{value: 'LYPLAL1-AS1', id: 'LYPLAL1-AS1'},
{value: 'LYPLAL1-DT', id: 'LYPLAL1-DT'},
{value: 'LYRM1', id: 'LYRM1'},
{value: 'LYRM2', id: 'LYRM2'},
{value: 'LYRM4', id: 'LYRM4'},
{value: 'LYRM4-AS1', id: 'LYRM4-AS1'},
{value: 'LYRM7', id: 'LYRM7'},
{value: 'LYRM9', id: 'LYRM9'},
{value: 'LYSMD1', id: 'LYSMD1'},
{value: 'LYSMD2', id: 'LYSMD2'},
{value: 'LYSMD3', id: 'LYSMD3'},
{value: 'LYSMD4', id: 'LYSMD4'},
{value: 'LYST', id: 'LYST'},
{value: 'LYST-AS1', id: 'LYST-AS1'},
{value: 'LYVE1', id: 'LYVE1'},
{value: 'LYZ', id: 'LYZ'},
{value: 'LYZL1', id: 'LYZL1'},
{value: 'LYZL2', id: 'LYZL2'},
{value: 'LYZL4', id: 'LYZL4'},
{value: 'LYZL6', id: 'LYZL6'},
{value: 'LZIC', id: 'LZIC'},
{value: 'LZTFL1', id: 'LZTFL1'},
{value: 'LZTR1', id: 'LZTR1'},
{value: 'LZTS1', id: 'LZTS1'},
{value: 'LZTS2', id: 'LZTS2'},
{value: 'LZTS3', id: 'LZTS3'},
{value: 'M1AP', id: 'M1AP'},
{value: 'M6PR', id: 'M6PR'},
{value: 'MAB21L1', id: 'MAB21L1'},
{value: 'MAB21L3', id: 'MAB21L3'},
{value: 'MAB21L4', id: 'MAB21L4'},
{value: 'MACC1', id: 'MACC1'},
{value: 'MACF1', id: 'MACF1'},
{value: 'MACIR', id: 'MACIR'},
{value: 'MACO1', id: 'MACO1'},
{value: 'MACORIS', id: 'MACORIS'},
{value: 'MACROD1', id: 'MACROD1'},
{value: 'MACROD2', id: 'MACROD2'},
{value: 'MACROD2-AS1', id: 'MACROD2-AS1'},
{value: 'MACROD2-IT1', id: 'MACROD2-IT1'},
{value: 'MACROH2A1', id: 'MACROH2A1'},
{value: 'MACROH2A2', id: 'MACROH2A2'},
{value: 'MAD1L1', id: 'MAD1L1'},
{value: 'MAD2L1', id: 'MAD2L1'},
{value: 'MAD2L1-DT', id: 'MAD2L1-DT'},
{value: 'MAD2L1BP', id: 'MAD2L1BP'},
{value: 'MAD2L1P1', id: 'MAD2L1P1'},
{value: 'MAD2L2', id: 'MAD2L2'},
{value: 'MADCAM1', id: 'MADCAM1'},
{value: 'MADCAM1-AS1', id: 'MADCAM1-AS1'},
{value: 'MADD', id: 'MADD'},
{value: 'MADD-AS1', id: 'MADD-AS1'},
{value: 'MAEA', id: 'MAEA'},
{value: 'MAEL', id: 'MAEL'},
{value: 'MAF', id: 'MAF'},
{value: 'MAF1', id: 'MAF1'},
{value: 'MAFA', id: 'MAFA'},
{value: 'MAFA-AS1', id: 'MAFA-AS1'},
{value: 'MAFB', id: 'MAFB'},
{value: 'MAFF', id: 'MAFF'},
{value: 'MAFG', id: 'MAFG'},
{value: 'MAFG-DT', id: 'MAFG-DT'},
{value: 'MAFIP', id: 'MAFIP'},
{value: 'MAFK', id: 'MAFK'},
{value: 'MAFTRR', id: 'MAFTRR'},
{value: 'MAG', id: 'MAG'},
{value: 'MAGED1', id: 'MAGED1'},
{value: 'MAGED2', id: 'MAGED2'},
{value: 'MAGED4', id: 'MAGED4'},
{value: 'MAGED4B', id: 'MAGED4B'},
{value: 'MAGEE1', id: 'MAGEE1'},
{value: 'MAGEF1', id: 'MAGEF1'},
{value: 'MAGEH1', id: 'MAGEH1'},
{value: 'MAGEL2', id: 'MAGEL2'},
{value: 'MAGI1', id: 'MAGI1'},
{value: 'MAGI1-IT1', id: 'MAGI1-IT1'},
{value: 'MAGI2', id: 'MAGI2'},
{value: 'MAGI2-AS3', id: 'MAGI2-AS3'},
{value: 'MAGI3', id: 'MAGI3'},
{value: 'MAGIX', id: 'MAGIX'},
{value: 'MAGOH', id: 'MAGOH'},
{value: 'MAGOH2P', id: 'MAGOH2P'},
{value: 'MAGOH3P', id: 'MAGOH3P'},
{value: 'MAGOHB', id: 'MAGOHB'},
{value: 'MAGT1', id: 'MAGT1'},
{value: 'MAILR', id: 'MAILR'},
{value: 'MAIP1', id: 'MAIP1'},
{value: 'MAJIN', id: 'MAJIN'},
{value: 'MAK', id: 'MAK'},
{value: 'MAK16', id: 'MAK16'},
{value: 'MAL', id: 'MAL'},
{value: 'MAL2', id: 'MAL2'},
{value: 'MAL2-AS1', id: 'MAL2-AS1'},
{value: 'MALAT1', id: 'MALAT1'},
{value: 'MALINC1', id: 'MALINC1'},
{value: 'MALL', id: 'MALL'},
{value: 'MALLP2', id: 'MALLP2'},
{value: 'MALRD1', id: 'MALRD1'},
{value: 'MALSU1', id: 'MALSU1'},
{value: 'MALT1', id: 'MALT1'},
{value: 'MALT1-AS1', id: 'MALT1-AS1'},
{value: 'MAMDC2', id: 'MAMDC2'},
{value: 'MAMDC2-AS1', id: 'MAMDC2-AS1'},
{value: 'MAMDC4', id: 'MAMDC4'},
{value: 'MAML1', id: 'MAML1'},
{value: 'MAML2', id: 'MAML2'},
{value: 'MAML3', id: 'MAML3'},
{value: 'MAMLD1', id: 'MAMLD1'},
{value: 'MAMSTR', id: 'MAMSTR'},
{value: 'MAN1A1', id: 'MAN1A1'},
{value: 'MAN1A2', id: 'MAN1A2'},
{value: 'MAN1A2P1', id: 'MAN1A2P1'},
{value: 'MAN1B1', id: 'MAN1B1'},
{value: 'MAN1B1-DT', id: 'MAN1B1-DT'},
{value: 'MAN1C1', id: 'MAN1C1'},
{value: 'MAN2A1', id: 'MAN2A1'},
{value: 'MAN2A1-DT', id: 'MAN2A1-DT'},
{value: 'MAN2A2', id: 'MAN2A2'},
{value: 'MAN2B1', id: 'MAN2B1'},
{value: 'MAN2B2', id: 'MAN2B2'},
{value: 'MAN2C1', id: 'MAN2C1'},
{value: 'MANBA', id: 'MANBA'},
{value: 'MANBAL', id: 'MANBAL'},
{value: 'MANCR', id: 'MANCR'},
{value: 'MANEA', id: 'MANEA'},
{value: 'MANEA-DT', id: 'MANEA-DT'},
{value: 'MANEAL', id: 'MANEAL'},
{value: 'MANEALP1', id: 'MANEALP1'},
{value: 'MANF', id: 'MANF'},
{value: 'MANSC1', id: 'MANSC1'},
{value: 'MANSC4', id: 'MANSC4'},
{value: 'MAOA', id: 'MAOA'},
{value: 'MAOB', id: 'MAOB'},
{value: 'MAP10', id: 'MAP10'},
{value: 'MAP1A', id: 'MAP1A'},
{value: 'MAP1B', id: 'MAP1B'},
{value: 'MAP1LC3A', id: 'MAP1LC3A'},
{value: 'MAP1LC3B', id: 'MAP1LC3B'},
{value: 'MAP1LC3B2', id: 'MAP1LC3B2'},
{value: 'MAP1LC3BP1', id: 'MAP1LC3BP1'},
{value: 'MAP1LC3C', id: 'MAP1LC3C'},
{value: 'MAP1S', id: 'MAP1S'},
{value: 'MAP2', id: 'MAP2'},
{value: 'MAP2K1', id: 'MAP2K1'},
{value: 'MAP2K1P1', id: 'MAP2K1P1'},
{value: 'MAP2K2', id: 'MAP2K2'},
{value: 'MAP2K3', id: 'MAP2K3'},
{value: 'MAP2K4', id: 'MAP2K4'},
{value: 'MAP2K4P1', id: 'MAP2K4P1'},
{value: 'MAP2K5', id: 'MAP2K5'},
{value: 'MAP2K5-DT', id: 'MAP2K5-DT'},
{value: 'MAP2K6', id: 'MAP2K6'},
{value: 'MAP2K7', id: 'MAP2K7'},
{value: 'MAP3K1', id: 'MAP3K1'},
{value: 'MAP3K10', id: 'MAP3K10'},
{value: 'MAP3K11', id: 'MAP3K11'},
{value: 'MAP3K12', id: 'MAP3K12'},
{value: 'MAP3K13', id: 'MAP3K13'},
{value: 'MAP3K14', id: 'MAP3K14'},
{value: 'MAP3K14-AS1', id: 'MAP3K14-AS1'},
{value: 'MAP3K15', id: 'MAP3K15'},
{value: 'MAP3K19', id: 'MAP3K19'},
{value: 'MAP3K2', id: 'MAP3K2'},
{value: 'MAP3K2-DT', id: 'MAP3K2-DT'},
{value: 'MAP3K20', id: 'MAP3K20'},
{value: 'MAP3K20-AS1', id: 'MAP3K20-AS1'},
{value: 'MAP3K21', id: 'MAP3K21'},
{value: 'MAP3K3', id: 'MAP3K3'},
{value: 'MAP3K4', id: 'MAP3K4'},
{value: 'MAP3K4-AS1', id: 'MAP3K4-AS1'},
{value: 'MAP3K5', id: 'MAP3K5'},
{value: 'MAP3K5-AS1', id: 'MAP3K5-AS1'},
{value: 'MAP3K6', id: 'MAP3K6'},
{value: 'MAP3K7', id: 'MAP3K7'},
{value: 'MAP3K7CL', id: 'MAP3K7CL'},
{value: 'MAP3K8', id: 'MAP3K8'},
{value: 'MAP3K9', id: 'MAP3K9'},
{value: 'MAP3K9-DT', id: 'MAP3K9-DT'},
{value: 'MAP4', id: 'MAP4'},
{value: 'MAP4K1', id: 'MAP4K1'},
{value: 'MAP4K1-AS1', id: 'MAP4K1-AS1'},
{value: 'MAP4K2', id: 'MAP4K2'},
{value: 'MAP4K3', id: 'MAP4K3'},
{value: 'MAP4K3-DT', id: 'MAP4K3-DT'},
{value: 'MAP4K4', id: 'MAP4K4'},
{value: 'MAP4K5', id: 'MAP4K5'},
{value: 'MAP6', id: 'MAP6'},
{value: 'MAP6D1', id: 'MAP6D1'},
{value: 'MAP7', id: 'MAP7'},
{value: 'MAP7D1', id: 'MAP7D1'},
{value: 'MAP7D2', id: 'MAP7D2'},
{value: 'MAP7D3', id: 'MAP7D3'},
{value: 'MAP9', id: 'MAP9'},
{value: 'MAPK1', id: 'MAPK1'},
{value: 'MAPK10', id: 'MAPK10'},
{value: 'MAPK10-AS1', id: 'MAPK10-AS1'},
{value: 'MAPK11', id: 'MAPK11'},
{value: 'MAPK12', id: 'MAPK12'},
{value: 'MAPK13', id: 'MAPK13'},
{value: 'MAPK14', id: 'MAPK14'},
{value: 'MAPK15', id: 'MAPK15'},
{value: 'MAPK1IP1L', id: 'MAPK1IP1L'},
{value: 'MAPK3', id: 'MAPK3'},
{value: 'MAPK4', id: 'MAPK4'},
{value: 'MAPK6', id: 'MAPK6'},
{value: 'MAPK6-DT', id: 'MAPK6-DT'},
{value: 'MAPK6P1', id: 'MAPK6P1'},
{value: 'MAPK6P2', id: 'MAPK6P2'},
{value: 'MAPK6P4', id: 'MAPK6P4'},
{value: 'MAPK6P6', id: 'MAPK6P6'},
{value: 'MAPK7', id: 'MAPK7'},
{value: 'MAPK8', id: 'MAPK8'},
{value: 'MAPK8IP1', id: 'MAPK8IP1'},
{value: 'MAPK8IP1P1', id: 'MAPK8IP1P1'},
{value: 'MAPK8IP1P2', id: 'MAPK8IP1P2'},
{value: 'MAPK8IP2', id: 'MAPK8IP2'},
{value: 'MAPK8IP3', id: 'MAPK8IP3'},
{value: 'MAPK9', id: 'MAPK9'},
{value: 'MAPKAP1', id: 'MAPKAP1'},
{value: 'MAPKAPK2', id: 'MAPKAPK2'},
{value: 'MAPKAPK3', id: 'MAPKAPK3'},
{value: 'MAPKAPK5', id: 'MAPKAPK5'},
{value: 'MAPKAPK5-AS1', id: 'MAPKAPK5-AS1'},
{value: 'MAPKAPK5P1', id: 'MAPKAPK5P1'},
{value: 'MAPKBP1', id: 'MAPKBP1'},
{value: 'MAPRE1', id: 'MAPRE1'},
{value: 'MAPRE1P1', id: 'MAPRE1P1'},
{value: 'MAPRE1P2', id: 'MAPRE1P2'},
{value: 'MAPRE2', id: 'MAPRE2'},
{value: 'MAPRE3', id: 'MAPRE3'},
{value: 'MAPT', id: 'MAPT'},
{value: 'MAPT-AS1', id: 'MAPT-AS1'},
{value: 'MARCHF1', id: 'MARCHF1'},
{value: 'MARCHF10', id: 'MARCHF10'},
{value: 'MARCHF10-DT', id: 'MARCHF10-DT'},
{value: 'MARCHF11', id: 'MARCHF11'},
{value: 'MARCHF11-DT', id: 'MARCHF11-DT'},
{value: 'MARCHF2', id: 'MARCHF2'},
{value: 'MARCHF3', id: 'MARCHF3'},
{value: 'MARCHF4', id: 'MARCHF4'},
{value: 'MARCHF5', id: 'MARCHF5'},
{value: 'MARCHF6', id: 'MARCHF6'},
{value: 'MARCHF7', id: 'MARCHF7'},
{value: 'MARCHF8', id: 'MARCHF8'},
{value: 'MARCHF9', id: 'MARCHF9'},
{value: 'MARCKS', id: 'MARCKS'},
{value: 'MARCKSL1', id: 'MARCKSL1'},
{value: 'MARCKSL1P1', id: 'MARCKSL1P1'},
{value: 'MARCKSL1P2', id: 'MARCKSL1P2'},
{value: 'MARCO', id: 'MARCO'},
{value: 'MARF1', id: 'MARF1'},
{value: 'MARK1', id: 'MARK1'},
{value: 'MARK2', id: 'MARK2'},
{value: 'MARK2P10', id: 'MARK2P10'},
{value: 'MARK2P11', id: 'MARK2P11'},
{value: 'MARK2P12', id: 'MARK2P12'},
{value: 'MARK2P13', id: 'MARK2P13'},
{value: 'MARK2P14', id: 'MARK2P14'},
{value: 'MARK2P15', id: 'MARK2P15'},
{value: 'MARK2P16', id: 'MARK2P16'},
{value: 'MARK2P17', id: 'MARK2P17'},
{value: 'MARK2P4', id: 'MARK2P4'},
{value: 'MARK2P5', id: 'MARK2P5'},
{value: 'MARK2P6', id: 'MARK2P6'},
{value: 'MARK2P8', id: 'MARK2P8'},
{value: 'MARK2P9', id: 'MARK2P9'},
{value: 'MARK3', id: 'MARK3'},
{value: 'MARK3P3', id: 'MARK3P3'},
{value: 'MARK4', id: 'MARK4'},
{value: 'MARS1', id: 'MARS1'},
{value: 'MARS2', id: 'MARS2'},
{value: 'MARVELD1', id: 'MARVELD1'},
{value: 'MARVELD2', id: 'MARVELD2'},
{value: 'MARVELD3', id: 'MARVELD3'},
{value: 'MAS1', id: 'MAS1'},
{value: 'MAS1L', id: 'MAS1L'},
{value: 'MAS1LP1', id: 'MAS1LP1'},
{value: 'MASP1', id: 'MASP1'},
{value: 'MASP2', id: 'MASP2'},
{value: 'MAST1', id: 'MAST1'},
{value: 'MAST2', id: 'MAST2'},
{value: 'MAST3', id: 'MAST3'},
{value: 'MAST3-AS1', id: 'MAST3-AS1'},
{value: 'MAST4', id: 'MAST4'},
{value: 'MAST4-AS1', id: 'MAST4-AS1'},
{value: 'MASTL', id: 'MASTL'},
{value: 'MAT1A', id: 'MAT1A'},
{value: 'MAT2A', id: 'MAT2A'},
{value: 'MAT2B', id: 'MAT2B'},
{value: 'MATK', id: 'MATK'},
{value: 'MATN1', id: 'MATN1'},
{value: 'MATN1-AS1', id: 'MATN1-AS1'},
{value: 'MATN2', id: 'MATN2'},
{value: 'MATN3', id: 'MATN3'},
{value: 'MATN4', id: 'MATN4'},
{value: 'MATR3', id: 'MATR3'},
{value: 'MAU2', id: 'MAU2'},
{value: 'MAVS', id: 'MAVS'},
{value: 'MAX', id: 'MAX'},
{value: 'MAZ', id: 'MAZ'},
{value: 'MB', id: 'MB'},
{value: 'MB21D2', id: 'MB21D2'},
{value: 'MBD1', id: 'MBD1'},
{value: 'MBD2', id: 'MBD2'},
{value: 'MBD3', id: 'MBD3'},
{value: 'MBD3L1', id: 'MBD3L1'},
{value: 'MBD3L2', id: 'MBD3L2'},
{value: 'MBD3L2B', id: 'MBD3L2B'},
{value: 'MBD3L3', id: 'MBD3L3'},
{value: 'MBD3L4', id: 'MBD3L4'},
{value: 'MBD3L5', id: 'MBD3L5'},
{value: 'MBD4', id: 'MBD4'},
{value: 'MBD5', id: 'MBD5'},
{value: 'MBD6', id: 'MBD6'},
{value: 'MBIP', id: 'MBIP'},
{value: 'MBL1P', id: 'MBL1P'},
{value: 'MBL2', id: 'MBL2'},
{value: 'MBL3P', id: 'MBL3P'},
{value: 'MBLAC1', id: 'MBLAC1'},
{value: 'MBLAC2', id: 'MBLAC2'},
{value: 'MBNL1', id: 'MBNL1'},
{value: 'MBNL1-AS1', id: 'MBNL1-AS1'},
{value: 'MBNL2', id: 'MBNL2'},
{value: 'MBNL3', id: 'MBNL3'},
{value: 'MBOAT1', id: 'MBOAT1'},
{value: 'MBOAT2', id: 'MBOAT2'},
{value: 'MBOAT4', id: 'MBOAT4'},
{value: 'MBOAT7', id: 'MBOAT7'},
{value: 'MBP', id: 'MBP'},
{value: 'MBTD1', id: 'MBTD1'},
{value: 'MBTPS1', id: 'MBTPS1'},
{value: 'MBTPS1-DT', id: 'MBTPS1-DT'},
{value: 'MBTPS2', id: 'MBTPS2'},
{value: 'MC1R', id: 'MC1R'},
{value: 'MC2R', id: 'MC2R'},
{value: 'MC3R', id: 'MC3R'},
{value: 'MC4R', id: 'MC4R'},
{value: 'MC5R', id: 'MC5R'},
{value: 'MCAM', id: 'MCAM'},
{value: 'MCAT', id: 'MCAT'},
{value: 'MCC', id: 'MCC'},
{value: 'MCCC1', id: 'MCCC1'},
{value: 'MCCC1-AS1', id: 'MCCC1-AS1'},
{value: 'MCCC2', id: 'MCCC2'},
{value: 'MCCD1P1', id: 'MCCD1P1'},
{value: 'MCCD1P2', id: 'MCCD1P2'},
{value: 'MCEE', id: 'MCEE'},
{value: 'MCEMP1', id: 'MCEMP1'},
{value: 'MCF2', id: 'MCF2'},
{value: 'MCF2L', id: 'MCF2L'},
{value: 'MCF2L-AS1', id: 'MCF2L-AS1'},
{value: 'MCF2L2', id: 'MCF2L2'},
{value: 'MCFD2', id: 'MCFD2'},
{value: 'MCFD2P1', id: 'MCFD2P1'},
{value: 'MCHR1', id: 'MCHR1'},
{value: 'MCHR2', id: 'MCHR2'},
{value: 'MCHR2-AS1', id: 'MCHR2-AS1'},
{value: 'MCIDAS', id: 'MCIDAS'},
{value: 'MCL1', id: 'MCL1'},
{value: 'MCM10', id: 'MCM10'},
{value: 'MCM2', id: 'MCM2'},
{value: 'MCM3', id: 'MCM3'},
{value: 'MCM3AP', id: 'MCM3AP'},
{value: 'MCM3AP-AS1', id: 'MCM3AP-AS1'},
{value: 'MCM4', id: 'MCM4'},
{value: 'MCM5', id: 'MCM5'},
{value: 'MCM6', id: 'MCM6'},
{value: 'MCM7', id: 'MCM7'},
{value: 'MCM8', id: 'MCM8'},
{value: 'MCM8-AS1', id: 'MCM8-AS1'},
{value: 'MCM9', id: 'MCM9'},
{value: 'MCMBP', id: 'MCMBP'},
{value: 'MCMDC2', id: 'MCMDC2'},
{value: 'MCOLN1', id: 'MCOLN1'},
{value: 'MCOLN2', id: 'MCOLN2'},
{value: 'MCOLN3', id: 'MCOLN3'},
{value: 'MCPH1', id: 'MCPH1'},
{value: 'MCPH1-AS1', id: 'MCPH1-AS1'},
{value: 'MCPH1-DT', id: 'MCPH1-DT'},
{value: 'MCRIP1', id: 'MCRIP1'},
{value: 'MCRIP2', id: 'MCRIP2'},
{value: 'MCRIP2P2', id: 'MCRIP2P2'},
{value: 'MCRS1', id: 'MCRS1'},
{value: 'MCTP1', id: 'MCTP1'},
{value: 'MCTP2', id: 'MCTP2'},
{value: 'MCTS1', id: 'MCTS1'},
{value: 'MCTS2P', id: 'MCTS2P'},
{value: 'MCU', id: 'MCU'},
{value: 'MCUB', id: 'MCUB'},
{value: 'MCUR1', id: 'MCUR1'},
{value: 'MCUR1P2', id: 'MCUR1P2'},
{value: 'MDC1', id: 'MDC1'},
{value: 'MDC1-AS1', id: 'MDC1-AS1'},
{value: 'MDFI', id: 'MDFI'},
{value: 'MDFIC', id: 'MDFIC'},
{value: 'MDFIC2', id: 'MDFIC2'},
{value: 'MDGA1', id: 'MDGA1'},
{value: 'MDGA2', id: 'MDGA2'},
{value: 'MDH1', id: 'MDH1'},
{value: 'MDH1B', id: 'MDH1B'},
{value: 'MDH1P2', id: 'MDH1P2'},
{value: 'MDH2', id: 'MDH2'},
{value: 'MDK', id: 'MDK'},
{value: 'MDM1', id: 'MDM1'},
{value: 'MDM2', id: 'MDM2'},
{value: 'MDM4', id: 'MDM4'},
{value: 'MDN1', id: 'MDN1'},
{value: 'MDP1', id: 'MDP1'},
{value: 'MDS2', id: 'MDS2'},
{value: 'ME1', id: 'ME1'},
{value: 'ME2', id: 'ME2'},
{value: 'ME2P1', id: 'ME2P1'},
{value: 'ME3', id: 'ME3'},
{value: 'MEA1', id: 'MEA1'},
{value: 'MEAF6', id: 'MEAF6'},
{value: 'MEAF6P1', id: 'MEAF6P1'},
{value: 'MEAK7', id: 'MEAK7'},
{value: 'MECOM', id: 'MECOM'},
{value: 'MECP2', id: 'MECP2'},
{value: 'MECR', id: 'MECR'},
{value: 'MED1', id: 'MED1'},
{value: 'MED10', id: 'MED10'},
{value: 'MED11', id: 'MED11'},
{value: 'MED12', id: 'MED12'},
{value: 'MED12L', id: 'MED12L'},
{value: 'MED13', id: 'MED13'},
{value: 'MED13L', id: 'MED13L'},
{value: 'MED14', id: 'MED14'},
{value: 'MED14OS', id: 'MED14OS'},
{value: 'MED15', id: 'MED15'},
{value: 'MED15P1', id: 'MED15P1'},
{value: 'MED15P3', id: 'MED15P3'},
{value: 'MED15P4', id: 'MED15P4'},
{value: 'MED15P5', id: 'MED15P5'},
{value: 'MED15P6', id: 'MED15P6'},
{value: 'MED15P7', id: 'MED15P7'},
{value: 'MED15P8', id: 'MED15P8'},
{value: 'MED15P9', id: 'MED15P9'},
{value: 'MED16', id: 'MED16'},
{value: 'MED17', id: 'MED17'},
{value: 'MED18', id: 'MED18'},
{value: 'MED19', id: 'MED19'},
{value: 'MED20', id: 'MED20'},
{value: 'MED21', id: 'MED21'},
{value: 'MED22', id: 'MED22'},
{value: 'MED23', id: 'MED23'},
{value: 'MED24', id: 'MED24'},
{value: 'MED25', id: 'MED25'},
{value: 'MED26', id: 'MED26'},
{value: 'MED27', id: 'MED27'},
{value: 'MED28', id: 'MED28'},
{value: 'MED28-DT', id: 'MED28-DT'},
{value: 'MED28P2', id: 'MED28P2'},
{value: 'MED28P5', id: 'MED28P5'},
{value: 'MED28P7', id: 'MED28P7'},
{value: 'MED29', id: 'MED29'},
{value: 'MED30', id: 'MED30'},
{value: 'MED31', id: 'MED31'},
{value: 'MED4', id: 'MED4'},
{value: 'MED4-AS1', id: 'MED4-AS1'},
{value: 'MED6', id: 'MED6'},
{value: 'MED6P1', id: 'MED6P1'},
{value: 'MED7', id: 'MED7'},
{value: 'MED8', id: 'MED8'},
{value: 'MED8-AS1', id: 'MED8-AS1'},
{value: 'MED9', id: 'MED9'},
{value: 'MEDAG', id: 'MEDAG'},
{value: 'MEF2A', id: 'MEF2A'},
{value: 'MEF2B', id: 'MEF2B'},
{value: 'MEF2C', id: 'MEF2C'},
{value: 'MEF2C-AS1', id: 'MEF2C-AS1'},
{value: 'MEF2C-AS2', id: 'MEF2C-AS2'},
{value: 'MEF2D', id: 'MEF2D'},
{value: 'MEFV', id: 'MEFV'},
{value: 'MEG3', id: 'MEG3'},
{value: 'MEG8', id: 'MEG8'},
{value: 'MEG9', id: 'MEG9'},
{value: 'MEGF10', id: 'MEGF10'},
{value: 'MEGF11', id: 'MEGF11'},
{value: 'MEGF6', id: 'MEGF6'},
{value: 'MEGF8', id: 'MEGF8'},
{value: 'MEGF9', id: 'MEGF9'},
{value: 'MEI1', id: 'MEI1'},
{value: 'MEI4', id: 'MEI4'},
{value: 'MEIG1', id: 'MEIG1'},
{value: 'MEIKIN', id: 'MEIKIN'},
{value: 'MEIOB', id: 'MEIOB'},
{value: 'MEIOC', id: 'MEIOC'},
{value: 'MEIOSIN', id: 'MEIOSIN'},
{value: 'MEIS1', id: 'MEIS1'},
{value: 'MEIS1-AS2', id: 'MEIS1-AS2'},
{value: 'MEIS1-AS3', id: 'MEIS1-AS3'},
{value: 'MEIS2', id: 'MEIS2'},
{value: 'MEIS3', id: 'MEIS3'},
{value: 'MEIS3P1', id: 'MEIS3P1'},
{value: 'MEIS3P2', id: 'MEIS3P2'},
{value: 'MELK', id: 'MELK'},
{value: 'MELTF', id: 'MELTF'},
{value: 'MELTF-AS1', id: 'MELTF-AS1'},
{value: 'MEMO1', id: 'MEMO1'},
{value: 'MEMO1P1', id: 'MEMO1P1'},
{value: 'MEMO1P2', id: 'MEMO1P2'},
{value: 'MEMO1P5', id: 'MEMO1P5'},
{value: 'MEN1', id: 'MEN1'},
{value: 'MEOX1', id: 'MEOX1'},
{value: 'MEOX2', id: 'MEOX2'},
{value: 'MEP1A', id: 'MEP1A'},
{value: 'MEP1AP1', id: 'MEP1AP1'},
{value: 'MEP1AP4', id: 'MEP1AP4'},
{value: 'MEP1B', id: 'MEP1B'},
{value: 'MEPCE', id: 'MEPCE'},
{value: 'MEPE', id: 'MEPE'},
{value: 'MERTK', id: 'MERTK'},
{value: 'MESD', id: 'MESD'},
{value: 'MESP1', id: 'MESP1'},
{value: 'MESP2', id: 'MESP2'},
{value: 'MEST', id: 'MEST'},
{value: 'MESTIT1', id: 'MESTIT1'},
{value: 'MESTP1', id: 'MESTP1'},
{value: 'MESTP2', id: 'MESTP2'},
{value: 'MESTP3', id: 'MESTP3'},
{value: 'MET', id: 'MET'},
{value: 'METAP1', id: 'METAP1'},
{value: 'METAP1D', id: 'METAP1D'},
{value: 'METAP2', id: 'METAP2'},
{value: 'METAP2P1', id: 'METAP2P1'},
{value: 'Metazoa_SRP', id: 'Metazoa_SRP'},
{value: 'METRN', id: 'METRN'},
{value: 'METRNL', id: 'METRNL'},
{value: 'METTL1', id: 'METTL1'},
{value: 'METTL13', id: 'METTL13'},
{value: 'METTL14', id: 'METTL14'},
{value: 'METTL14-DT', id: 'METTL14-DT'},
{value: 'METTL15', id: 'METTL15'},
{value: 'METTL15P1', id: 'METTL15P1'},
{value: 'METTL16', id: 'METTL16'},
{value: 'METTL17', id: 'METTL17'},
{value: 'METTL18', id: 'METTL18'},
{value: 'METTL21A', id: 'METTL21A'},
{value: 'METTL21AP1', id: 'METTL21AP1'},
{value: 'METTL21C', id: 'METTL21C'},
{value: 'METTL21EP', id: 'METTL21EP'},
{value: 'METTL22', id: 'METTL22'},
{value: 'METTL23', id: 'METTL23'},
{value: 'METTL24', id: 'METTL24'},
{value: 'METTL25', id: 'METTL25'},
{value: 'METTL25B', id: 'METTL25B'},
{value: 'METTL26', id: 'METTL26'},
{value: 'METTL27', id: 'METTL27'},
{value: 'METTL2A', id: 'METTL2A'},
{value: 'METTL2B', id: 'METTL2B'},
{value: 'METTL3', id: 'METTL3'},
{value: 'METTL4', id: 'METTL4'},
{value: 'METTL5', id: 'METTL5'},
{value: 'METTL6', id: 'METTL6'},
{value: 'METTL7A', id: 'METTL7A'},
{value: 'METTL7AP1', id: 'METTL7AP1'},
{value: 'METTL7B', id: 'METTL7B'},
{value: 'METTL8', id: 'METTL8'},
{value: 'METTL9', id: 'METTL9'},
{value: 'MEX3A', id: 'MEX3A'},
{value: 'MEX3B', id: 'MEX3B'},
{value: 'MEX3C', id: 'MEX3C'},
{value: 'MEX3D', id: 'MEX3D'},
{value: 'MFAP1', id: 'MFAP1'},
{value: 'MFAP1P1', id: 'MFAP1P1'},
{value: 'MFAP2', id: 'MFAP2'},
{value: 'MFAP3', id: 'MFAP3'},
{value: 'MFAP3L', id: 'MFAP3L'},
{value: 'MFAP4', id: 'MFAP4'},
{value: 'MFAP5', id: 'MFAP5'},
{value: 'MFF', id: 'MFF'},
{value: 'MFF-DT', id: 'MFF-DT'},
{value: 'MFFP2', id: 'MFFP2'},
{value: 'MFGE8', id: 'MFGE8'},
{value: 'MFHAS1', id: 'MFHAS1'},
{value: 'MFN1', id: 'MFN1'},
{value: 'MFN2', id: 'MFN2'},
{value: 'MFNG', id: 'MFNG'},
{value: 'MFRP', id: 'MFRP'},
{value: 'MFSD1', id: 'MFSD1'},
{value: 'MFSD10', id: 'MFSD10'},
{value: 'MFSD11', id: 'MFSD11'},
{value: 'MFSD12', id: 'MFSD12'},
{value: 'MFSD13A', id: 'MFSD13A'},
{value: 'MFSD13B', id: 'MFSD13B'},
{value: 'MFSD14A', id: 'MFSD14A'},
{value: 'MFSD14B', id: 'MFSD14B'},
{value: 'MFSD14C', id: 'MFSD14C'},
{value: 'MFSD1P1', id: 'MFSD1P1'},
{value: 'MFSD2A', id: 'MFSD2A'},
{value: 'MFSD2B', id: 'MFSD2B'},
{value: 'MFSD3', id: 'MFSD3'},
{value: 'MFSD4A', id: 'MFSD4A'},
{value: 'MFSD4B', id: 'MFSD4B'},
{value: 'MFSD4BP1', id: 'MFSD4BP1'},
{value: 'MFSD5', id: 'MFSD5'},
{value: 'MFSD6', id: 'MFSD6'},
{value: 'MFSD6L', id: 'MFSD6L'},
{value: 'MFSD8', id: 'MFSD8'},
{value: 'MFSD9', id: 'MFSD9'},
{value: 'MGA', id: 'MGA'},
{value: 'MGAM', id: 'MGAM'},
{value: 'MGAM2', id: 'MGAM2'},
{value: 'MGARP', id: 'MGARP'},
{value: 'MGAT1', id: 'MGAT1'},
{value: 'MGAT2', id: 'MGAT2'},
{value: 'MGAT3', id: 'MGAT3'},
{value: 'MGAT4A', id: 'MGAT4A'},
{value: 'MGAT4B', id: 'MGAT4B'},
{value: 'MGAT4C', id: 'MGAT4C'},
{value: 'MGAT4D', id: 'MGAT4D'},
{value: 'MGAT4EP', id: 'MGAT4EP'},
{value: 'MGAT4FP', id: 'MGAT4FP'},
{value: 'MGAT5', id: 'MGAT5'},
{value: 'MGAT5B', id: 'MGAT5B'},
{value: 'MGC15885', id: 'MGC15885'},
{value: 'MGC16275', id: 'MGC16275'},
{value: 'MGC27382', id: 'MGC27382'},
{value: 'MGC32805', id: 'MGC32805'},
{value: 'MGC4859', id: 'MGC4859'},
{value: 'MGLL', id: 'MGLL'},
{value: 'MGME1', id: 'MGME1'},
{value: 'MGMT', id: 'MGMT'},
{value: 'MGP', id: 'MGP'},
{value: 'MGRN1', id: 'MGRN1'},
{value: 'MGST1', id: 'MGST1'},
{value: 'MGST2', id: 'MGST2'},
{value: 'MGST3', id: 'MGST3'},
{value: 'MHENCR', id: 'MHENCR'},
{value: 'MIA', id: 'MIA'},
{value: 'MIA-RAB4B', id: 'MIA-RAB4B'},
{value: 'MIA2', id: 'MIA2'},
{value: 'MIA2-AS1', id: 'MIA2-AS1'},
{value: 'MIA3', id: 'MIA3'},
{value: 'MIAT', id: 'MIAT'},
{value: 'MIATNB', id: 'MIATNB'},
{value: 'MIB1', id: 'MIB1'},
{value: 'MIB2', id: 'MIB2'},
{value: 'MICA', id: 'MICA'},
{value: 'MICAL1', id: 'MICAL1'},
{value: 'MICAL2', id: 'MICAL2'},
{value: 'MICAL3', id: 'MICAL3'},
{value: 'MICALL1', id: 'MICALL1'},
{value: 'MICALL2', id: 'MICALL2'},
{value: 'MICB', id: 'MICB'},
{value: 'MICB-DT', id: 'MICB-DT'},
{value: 'MICC', id: 'MICC'},
{value: 'MICD', id: 'MICD'},
{value: 'MICE', id: 'MICE'},
{value: 'MICG', id: 'MICG'},
{value: 'MICOS10', id: 'MICOS10'},
{value: 'MICOS10-NBL1', id: 'MICOS10-NBL1'},
{value: 'MICOS10P2', id: 'MICOS10P2'},
{value: 'MICOS10P3', id: 'MICOS10P3'},
{value: 'MICOS13', id: 'MICOS13'},
{value: 'MICU1', id: 'MICU1'},
{value: 'MICU2', id: 'MICU2'},
{value: 'MICU3', id: 'MICU3'},
{value: 'MID1', id: 'MID1'},
{value: 'MID1IP1', id: 'MID1IP1'},
{value: 'MID2', id: 'MID2'},
{value: 'MIDEAS', id: 'MIDEAS'},
{value: 'MIDN', id: 'MIDN'},
{value: 'MIDUO', id: 'MIDUO'},
{value: 'MIEF1', id: 'MIEF1'},
{value: 'MIEF2', id: 'MIEF2'},
{value: 'MIEN1', id: 'MIEN1'},
{value: 'MIER1', id: 'MIER1'},
{value: 'MIER2', id: 'MIER2'},
{value: 'MIER3', id: 'MIER3'},
{value: 'MIF', id: 'MIF'},
{value: 'MIF-AS1', id: 'MIF-AS1'},
{value: 'MIF4GD', id: 'MIF4GD'},
{value: 'MIF4GD-DT', id: 'MIF4GD-DT'},
{value: 'MIGA1', id: 'MIGA1'},
{value: 'MIGA2', id: 'MIGA2'},
{value: 'MIIP', id: 'MIIP'},
{value: 'MILR1', id: 'MILR1'},
{value: 'MIMT1', id: 'MIMT1'},
{value: 'MINAR1', id: 'MINAR1'},
{value: 'MINAR2', id: 'MINAR2'},
{value: 'MINCR', id: 'MINCR'},
{value: 'MINDY1', id: 'MINDY1'},
{value: 'MINDY2', id: 'MINDY2'},
{value: 'MINDY3', id: 'MINDY3'},
{value: 'MINDY4', id: 'MINDY4'},
{value: 'MINK1', id: 'MINK1'},
{value: 'MINPP1', id: 'MINPP1'},
{value: 'MIOS', id: 'MIOS'},
{value: 'MIOS-DT', id: 'MIOS-DT'},
{value: 'MIOX', id: 'MIOX'},
{value: 'MIOXP1', id: 'MIOXP1'},
{value: 'MIP', id: 'MIP'},
{value: 'MIPEP', id: 'MIPEP'},
{value: 'MIPEPP2', id: 'MIPEPP2'},
{value: 'MIPEPP3', id: 'MIPEPP3'},
{value: 'MIPOL1', id: 'MIPOL1'},
{value: 'MIR1-1', id: 'MIR1-1'},
{value: 'MIR1-1HG', id: 'MIR1-1HG'},
{value: 'MIR1-1HG-AS1', id: 'MIR1-1HG-AS1'},
{value: 'MIR100HG', id: 'MIR100HG'},
{value: 'MIR101-1', id: 'MIR101-1'},
{value: 'MIR103A2', id: 'MIR103A2'},
{value: 'MIR107', id: 'MIR107'},
{value: 'MIR10A', id: 'MIR10A'},
{value: 'MIR1178', id: 'MIR1178'},
{value: 'MIR1179', id: 'MIR1179'},
{value: 'MIR1180', id: 'MIR1180'},
{value: 'MIR1181', id: 'MIR1181'},
{value: 'MIR1182', id: 'MIR1182'},
{value: 'MIR1185-1', id: 'MIR1185-1'},
{value: 'MIR1185-2', id: 'MIR1185-2'},
{value: 'MIR1199', id: 'MIR1199'},
{value: 'MIR1200', id: 'MIR1200'},
{value: 'MIR1202', id: 'MIR1202'},
{value: 'MIR1203', id: 'MIR1203'},
{value: 'MIR1205', id: 'MIR1205'},
{value: 'MIR1207', id: 'MIR1207'},
{value: 'MIR1208', id: 'MIR1208'},
{value: 'MIR1224', id: 'MIR1224'},
{value: 'MIR1225', id: 'MIR1225'},
{value: 'MIR1226', id: 'MIR1226'},
{value: 'MIR122HG', id: 'MIR122HG'},
{value: 'MIR1231', id: 'MIR1231'},
{value: 'MIR1233-1', id: 'MIR1233-1'},
{value: 'MIR1233-2', id: 'MIR1233-2'},
{value: 'MIR1237', id: 'MIR1237'},
{value: 'MIR1238', id: 'MIR1238'},
{value: 'MIR124-1', id: 'MIR124-1'},
{value: 'MIR124-1HG', id: 'MIR124-1HG'},
{value: 'MIR124-2', id: 'MIR124-2'},
{value: 'MIR124-2HG', id: 'MIR124-2HG'},
{value: 'MIR124-3', id: 'MIR124-3'},
{value: 'MIR1244-1', id: 'MIR1244-1'},
{value: 'MIR1244-3', id: 'MIR1244-3'},
{value: 'MIR1247', id: 'MIR1247'},
{value: 'MIR1249', id: 'MIR1249'},
{value: 'MIR1255B1', id: 'MIR1255B1'},
{value: 'MIR1257', id: 'MIR1257'},
{value: 'MIR1258', id: 'MIR1258'},
{value: 'MIR125B2', id: 'MIR125B2'},
{value: 'MIR1260A', id: 'MIR1260A'},
{value: 'MIR1260B', id: 'MIR1260B'},
{value: 'MIR1261', id: 'MIR1261'},
{value: 'MIR1263', id: 'MIR1263'},
{value: 'MIR1267', id: 'MIR1267'},
{value: 'MIR1268A', id: 'MIR1268A'},
{value: 'MIR1269A', id: 'MIR1269A'},
{value: 'MIR1269B', id: 'MIR1269B'},
{value: 'MIR127', id: 'MIR127'},
{value: 'MIR1272', id: 'MIR1272'},
{value: 'MIR1273C', id: 'MIR1273C'},
{value: 'MIR1275', id: 'MIR1275'},
{value: 'MIR1279', id: 'MIR1279'},
{value: 'MIR128-2', id: 'MIR128-2'},
{value: 'MIR1281', id: 'MIR1281'},
{value: 'MIR1283-1', id: 'MIR1283-1'},
{value: 'MIR1283-2', id: 'MIR1283-2'},
{value: 'MIR1286', id: 'MIR1286'},
{value: 'MIR1287', id: 'MIR1287'},
{value: 'MIR129-1', id: 'MIR129-1'},
{value: 'MIR129-2', id: 'MIR129-2'},
{value: 'MIR1297', id: 'MIR1297'},
{value: 'MIR1299', id: 'MIR1299'},
{value: 'MIR1301', id: 'MIR1301'},
{value: 'MIR1302-3', id: 'MIR1302-3'},
{value: 'MIR1302-7', id: 'MIR1302-7'},
{value: 'MIR1302-9HG', id: 'MIR1302-9HG'},
{value: 'MIR1306', id: 'MIR1306'},
{value: 'MIR130A', id: 'MIR130A'},
{value: 'MIR132', id: 'MIR132'},
{value: 'MIR1322', id: 'MIR1322'},
{value: 'MIR1323', id: 'MIR1323'},
{value: 'MIR133A1', id: 'MIR133A1'},
{value: 'MIR133A1HG', id: 'MIR133A1HG'},
{value: 'MIR134', id: 'MIR134'},
{value: 'MIR1343', id: 'MIR1343'},
{value: 'MIR135B', id: 'MIR135B'},
{value: 'MIR137HG', id: 'MIR137HG'},
{value: 'MIR138-1', id: 'MIR138-1'},
{value: 'MIR138-2', id: 'MIR138-2'},
{value: 'MIR140', id: 'MIR140'},
{value: 'MIR143', id: 'MIR143'},
{value: 'MIR145', id: 'MIR145'},
{value: 'MIR1469', id: 'MIR1469'},
{value: 'MIR146A', id: 'MIR146A'},
{value: 'MIR146B', id: 'MIR146B'},
{value: 'MIR1470', id: 'MIR1470'},
{value: 'MIR1471', id: 'MIR1471'},
{value: 'MIR147A', id: 'MIR147A'},
{value: 'MIR148A', id: 'MIR148A'},
{value: 'MIR149', id: 'MIR149'},
{value: 'MIR150', id: 'MIR150'},
{value: 'MIR151A', id: 'MIR151A'},
{value: 'MIR152', id: 'MIR152'},
{value: 'MIR153-1', id: 'MIR153-1'},
{value: 'MIR1538', id: 'MIR1538'},
{value: 'MIR154', id: 'MIR154'},
{value: 'MIR155', id: 'MIR155'},
{value: 'MIR155HG', id: 'MIR155HG'},
{value: 'MIR17HG', id: 'MIR17HG'},
{value: 'MIR181A1HG', id: 'MIR181A1HG'},
{value: 'MIR181A2HG', id: 'MIR181A2HG'},
{value: 'MIR181B1', id: 'MIR181B1'},
{value: 'MIR182', id: 'MIR182'},
{value: 'MIR1825', id: 'MIR1825'},
{value: 'MIR1827', id: 'MIR1827'},
{value: 'MIR183', id: 'MIR183'},
{value: 'MIR185', id: 'MIR185'},
{value: 'MIR187', id: 'MIR187'},
{value: 'MIR1908', id: 'MIR1908'},
{value: 'MIR1910', id: 'MIR1910'},
{value: 'MIR1913', id: 'MIR1913'},
{value: 'MIR1914', id: 'MIR1914'},
{value: 'MIR1915', id: 'MIR1915'},
{value: 'MIR1915HG', id: 'MIR1915HG'},
{value: 'MIR193A', id: 'MIR193A'},
{value: 'MIR193BHG', id: 'MIR193BHG'},
{value: 'MIR194-2HG', id: 'MIR194-2HG'},
{value: 'MIR196B', id: 'MIR196B'},
{value: 'MIR197', id: 'MIR197'},
{value: 'MIR1973', id: 'MIR1973'},
{value: 'MIR1976', id: 'MIR1976'},
{value: 'MIR199A1', id: 'MIR199A1'},
{value: 'MIR199A2', id: 'MIR199A2'},
{value: 'MIR199B', id: 'MIR199B'},
{value: 'MIR200A', id: 'MIR200A'},
{value: 'MIR200B', id: 'MIR200B'},
{value: 'MIR200CHG', id: 'MIR200CHG'},
{value: 'MIR202', id: 'MIR202'},
{value: 'MIR202HG', id: 'MIR202HG'},
{value: 'MIR203A', id: 'MIR203A'},
{value: 'MIR203B', id: 'MIR203B'},
{value: 'MIR204', id: 'MIR204'},
{value: 'MIR205', id: 'MIR205'},
{value: 'MIR2052HG', id: 'MIR2052HG'},
{value: 'MIR2054', id: 'MIR2054'},
{value: 'MIR205HG', id: 'MIR205HG'},
{value: 'MIR206', id: 'MIR206'},
{value: 'MIR208B', id: 'MIR208B'},
{value: 'MIR21', id: 'MIR21'},
{value: 'MIR210', id: 'MIR210'},
{value: 'MIR210HG', id: 'MIR210HG'},
{value: 'MIR2110', id: 'MIR2110'},
{value: 'MIR2116', id: 'MIR2116'},
{value: 'MIR2117HG', id: 'MIR2117HG'},
{value: 'MIR212', id: 'MIR212'},
{value: 'MIR218-2', id: 'MIR218-2'},
{value: 'MIR219A1', id: 'MIR219A1'},
{value: 'MIR219B', id: 'MIR219B'},
{value: 'MIR222HG', id: 'MIR222HG'},
{value: 'MIR223HG', id: 'MIR223HG'},
{value: 'MIR2277', id: 'MIR2277'},
{value: 'MIR2278', id: 'MIR2278'},
{value: 'MIR22HG', id: 'MIR22HG'},
{value: 'MIR2355', id: 'MIR2355'},
{value: 'MIR23A', id: 'MIR23A'},
{value: 'MIR23AHG', id: 'MIR23AHG'},
{value: 'MIR23B', id: 'MIR23B'},
{value: 'MIR24-1', id: 'MIR24-1'},
{value: 'MIR24-2', id: 'MIR24-2'},
{value: 'MIR2467', id: 'MIR2467'},
{value: 'MIR25', id: 'MIR25'},
{value: 'MIR2682', id: 'MIR2682'},
{value: 'MIR26A1', id: 'MIR26A1'},
{value: 'MIR26A2', id: 'MIR26A2'},
{value: 'MIR26B', id: 'MIR26B'},
{value: 'MIR27B', id: 'MIR27B'},
{value: 'MIR296', id: 'MIR296'},
{value: 'MIR297', id: 'MIR297'},
{value: 'MIR29B1', id: 'MIR29B1'},
{value: 'MIR29B2CHG', id: 'MIR29B2CHG'},
{value: 'MIR29C', id: 'MIR29C'},
{value: 'MIR300', id: 'MIR300'},
{value: 'MIR302CHG', id: 'MIR302CHG'},
{value: 'MIR302E', id: 'MIR302E'},
{value: 'MIR302F', id: 'MIR302F'},
{value: 'MIR3074', id: 'MIR3074'},
{value: 'MIR30A', id: 'MIR30A'},
{value: 'MIR30B', id: 'MIR30B'},
{value: 'MIR30C1', id: 'MIR30C1'},
{value: 'MIR30C2', id: 'MIR30C2'},
{value: 'MIR30D', id: 'MIR30D'},
{value: 'MIR3124', id: 'MIR3124'},
{value: 'MIR3125', id: 'MIR3125'},
{value: 'MIR3130-2', id: 'MIR3130-2'},
{value: 'MIR3131', id: 'MIR3131'},
{value: 'MIR3135B', id: 'MIR3135B'},
{value: 'MIR3137', id: 'MIR3137'},
{value: 'MIR3138', id: 'MIR3138'},
{value: 'MIR3141', id: 'MIR3141'},
{value: 'MIR3142HG', id: 'MIR3142HG'},
{value: 'MIR3143', id: 'MIR3143'},
{value: 'MIR3144', id: 'MIR3144'},
{value: 'MIR3146', id: 'MIR3146'},
{value: 'MIR3148', id: 'MIR3148'},
{value: 'MIR3149', id: 'MIR3149'},
{value: 'MIR3150A', id: 'MIR3150A'},
{value: 'MIR3150BHG', id: 'MIR3150BHG'},
{value: 'MIR3154', id: 'MIR3154'},
{value: 'MIR3155B', id: 'MIR3155B'},
{value: 'MIR3156-3', id: 'MIR3156-3'},
{value: 'MIR3159', id: 'MIR3159'},
{value: 'MIR3161', id: 'MIR3161'},
{value: 'MIR3163', id: 'MIR3163'},
{value: 'MIR3166', id: 'MIR3166'},
{value: 'MIR3168', id: 'MIR3168'},
{value: 'MIR3169', id: 'MIR3169'},
{value: 'MIR3170', id: 'MIR3170'},
{value: 'MIR3175', id: 'MIR3175'},
{value: 'MIR3177', id: 'MIR3177'},
{value: 'MIR3178', id: 'MIR3178'},
{value: 'MIR3179-1', id: 'MIR3179-1'},
{value: 'MIR3179-2', id: 'MIR3179-2'},
{value: 'MIR3179-3', id: 'MIR3179-3'},
{value: 'MIR3179-4', id: 'MIR3179-4'},
{value: 'MIR3180-1', id: 'MIR3180-1'},
{value: 'MIR3180-2', id: 'MIR3180-2'},
{value: 'MIR3180-4', id: 'MIR3180-4'},
{value: 'MIR3180-5', id: 'MIR3180-5'},
{value: 'MIR3181', id: 'MIR3181'},
{value: 'MIR3182', id: 'MIR3182'},
{value: 'MIR3183', id: 'MIR3183'},
{value: 'MIR3185', id: 'MIR3185'},
{value: 'MIR3187', id: 'MIR3187'},
{value: 'MIR3188', id: 'MIR3188'},
{value: 'MIR3189', id: 'MIR3189'},
{value: 'MIR3190', id: 'MIR3190'},
{value: 'MIR3193', id: 'MIR3193'},
{value: 'MIR3194', id: 'MIR3194'},
{value: 'MIR3196', id: 'MIR3196'},
{value: 'MIR3197', id: 'MIR3197'},
{value: 'MIR3198-1', id: 'MIR3198-1'},
{value: 'MIR3198-2', id: 'MIR3198-2'},
{value: 'MIR31HG', id: 'MIR31HG'},
{value: 'MIR3200', id: 'MIR3200'},
{value: 'MIR3201', id: 'MIR3201'},
{value: 'MIR320A', id: 'MIR320A'},
{value: 'MIR320B1', id: 'MIR320B1'},
{value: 'MIR320D1', id: 'MIR320D1'},
{value: 'MIR323B', id: 'MIR323B'},
{value: 'MIR326', id: 'MIR326'},
{value: 'MIR328', id: 'MIR328'},
{value: 'MIR330', id: 'MIR330'},
{value: 'MIR331', id: 'MIR331'},
{value: 'MIR345', id: 'MIR345'},
{value: 'MIR346', id: 'MIR346'},
{value: 'MIR34A', id: 'MIR34A'},
{value: 'MIR34AHG', id: 'MIR34AHG'},
{value: 'MIR34B', id: 'MIR34B'},
{value: 'MIR3529', id: 'MIR3529'},
{value: 'MIR3591', id: 'MIR3591'},
{value: 'MIR3605', id: 'MIR3605'},
{value: 'MIR3606', id: 'MIR3606'},
{value: 'MIR3610', id: 'MIR3610'},
{value: 'MIR3613', id: 'MIR3613'},
{value: 'MIR3614', id: 'MIR3614'},
{value: 'MIR3615', id: 'MIR3615'},
{value: 'MIR3616', id: 'MIR3616'},
{value: 'MIR3620', id: 'MIR3620'},
{value: 'MIR3621', id: 'MIR3621'},
{value: 'MIR3622A', id: 'MIR3622A'},
{value: 'MIR3622B', id: 'MIR3622B'},
{value: 'MIR3646', id: 'MIR3646'},
{value: 'MIR3648-1', id: 'MIR3648-1'},
{value: 'MIR3648-2', id: 'MIR3648-2'},
{value: 'MIR3649', id: 'MIR3649'},
{value: 'MIR3654', id: 'MIR3654'},
{value: 'MIR3658', id: 'MIR3658'},
{value: 'MIR3659HG', id: 'MIR3659HG'},
{value: 'MIR365A', id: 'MIR365A'},
{value: 'MIR365B', id: 'MIR365B'},
{value: 'MIR3660', id: 'MIR3660'},
{value: 'MIR3661', id: 'MIR3661'},
{value: 'MIR3663', id: 'MIR3663'},
{value: 'MIR3663HG', id: 'MIR3663HG'},
{value: 'MIR3664', id: 'MIR3664'},
{value: 'MIR3666', id: 'MIR3666'},
{value: 'MIR3667HG', id: 'MIR3667HG'},
{value: 'MIR3677HG', id: 'MIR3677HG'},
{value: 'MIR3678', id: 'MIR3678'},
{value: 'MIR3680-1', id: 'MIR3680-1'},
{value: 'MIR3681', id: 'MIR3681'},
{value: 'MIR3681HG', id: 'MIR3681HG'},
{value: 'MIR3685', id: 'MIR3685'},
{value: 'MIR3686', id: 'MIR3686'},
{value: 'MIR3689C', id: 'MIR3689C'},
{value: 'MIR3689F', id: 'MIR3689F'},
{value: 'MIR369', id: 'MIR369'},
{value: 'MIR3692', id: 'MIR3692'},
{value: 'MIR370', id: 'MIR370'},
{value: 'MIR3714', id: 'MIR3714'},
{value: 'MIR371A', id: 'MIR371A'},
{value: 'MIR375', id: 'MIR375'},
{value: 'MIR376A2', id: 'MIR376A2'},
{value: 'MIR377', id: 'MIR377'},
{value: 'MIR378A', id: 'MIR378A'},
{value: 'MIR378C', id: 'MIR378C'},
{value: 'MIR378D2', id: 'MIR378D2'},
{value: 'MIR378D2HG', id: 'MIR378D2HG'},
{value: 'MIR378E', id: 'MIR378E'},
{value: 'MIR378F', id: 'MIR378F'},
{value: 'MIR378G', id: 'MIR378G'},
{value: 'MIR378H', id: 'MIR378H'},
{value: 'MIR378I', id: 'MIR378I'},
{value: 'MIR378J', id: 'MIR378J'},
{value: 'MIR380', id: 'MIR380'},
{value: 'MIR381HG', id: 'MIR381HG'},
{value: 'MIR382', id: 'MIR382'},
{value: 'MIR3907', id: 'MIR3907'},
{value: 'MIR3908', id: 'MIR3908'},
{value: 'MIR3909', id: 'MIR3909'},
{value: 'MIR3912', id: 'MIR3912'},
{value: 'MIR3913-1', id: 'MIR3913-1'},
{value: 'MIR3913-2', id: 'MIR3913-2'},
{value: 'MIR3917', id: 'MIR3917'},
{value: 'MIR3920', id: 'MIR3920'},
{value: 'MIR3921', id: 'MIR3921'},
{value: 'MIR3924', id: 'MIR3924'},
{value: 'MIR3925', id: 'MIR3925'},
{value: 'MIR3928', id: 'MIR3928'},
{value: 'MIR3929', id: 'MIR3929'},
{value: 'MIR3934', id: 'MIR3934'},
{value: 'MIR3936HG', id: 'MIR3936HG'},
{value: 'MIR3939', id: 'MIR3939'},
{value: 'MIR3940', id: 'MIR3940'},
{value: 'MIR3941', id: 'MIR3941'},
{value: 'MIR3943', id: 'MIR3943'},
{value: 'MIR3945', id: 'MIR3945'},
{value: 'MIR3945HG', id: 'MIR3945HG'},
{value: 'MIR3972', id: 'MIR3972'},
{value: 'MIR3973', id: 'MIR3973'},
{value: 'MIR3976HG', id: 'MIR3976HG'},
{value: 'MIR409', id: 'MIR409'},
{value: 'MIR410', id: 'MIR410'},
{value: 'MIR422A', id: 'MIR422A'},
{value: 'MIR423', id: 'MIR423'},
{value: 'MIR4252', id: 'MIR4252'},
{value: 'MIR4253', id: 'MIR4253'},
{value: 'MIR4254', id: 'MIR4254'},
{value: 'MIR4255', id: 'MIR4255'},
{value: 'MIR4256', id: 'MIR4256'},
{value: 'MIR4257', id: 'MIR4257'},
{value: 'MIR4258', id: 'MIR4258'},
{value: 'MIR4259', id: 'MIR4259'},
{value: 'MIR4261', id: 'MIR4261'},
{value: 'MIR4262', id: 'MIR4262'},
{value: 'MIR4263', id: 'MIR4263'},
{value: 'MIR4265', id: 'MIR4265'},
{value: 'MIR4266', id: 'MIR4266'},
{value: 'MIR4267', id: 'MIR4267'},
{value: 'MIR4269', id: 'MIR4269'},
{value: 'MIR4270', id: 'MIR4270'},
{value: 'MIR4272', id: 'MIR4272'},
{value: 'MIR4273', id: 'MIR4273'},
{value: 'MIR4274', id: 'MIR4274'},
{value: 'MIR4275', id: 'MIR4275'},
{value: 'MIR4276', id: 'MIR4276'},
{value: 'MIR4277', id: 'MIR4277'},
{value: 'MIR4278', id: 'MIR4278'},
{value: 'MIR4279', id: 'MIR4279'},
{value: 'MIR4280', id: 'MIR4280'},
{value: 'MIR4280HG', id: 'MIR4280HG'},
{value: 'MIR4282', id: 'MIR4282'},
{value: 'MIR4283-1', id: 'MIR4283-1'},
{value: 'MIR4283-2', id: 'MIR4283-2'},
{value: 'MIR4285', id: 'MIR4285'},
{value: 'MIR4287', id: 'MIR4287'},
{value: 'MIR4288', id: 'MIR4288'},
{value: 'MIR4289', id: 'MIR4289'},
{value: 'MIR429', id: 'MIR429'},
{value: 'MIR4290', id: 'MIR4290'},
{value: 'MIR4290HG', id: 'MIR4290HG'},
{value: 'MIR4291', id: 'MIR4291'},
{value: 'MIR4292', id: 'MIR4292'},
{value: 'MIR4294', id: 'MIR4294'},
{value: 'MIR4296', id: 'MIR4296'},
{value: 'MIR4297', id: 'MIR4297'},
{value: 'MIR4299', id: 'MIR4299'},
{value: 'MIR4300HG', id: 'MIR4300HG'},
{value: 'MIR4302', id: 'MIR4302'},
{value: 'MIR4303', id: 'MIR4303'},
{value: 'MIR4304', id: 'MIR4304'},
{value: 'MIR4306', id: 'MIR4306'},
{value: 'MIR4309', id: 'MIR4309'},
{value: 'MIR431', id: 'MIR431'},
{value: 'MIR4312', id: 'MIR4312'},
{value: 'MIR4313', id: 'MIR4313'},
{value: 'MIR4314', id: 'MIR4314'},
{value: 'MIR4315-1', id: 'MIR4315-1'},
{value: 'MIR4315-2', id: 'MIR4315-2'},
{value: 'MIR4316', id: 'MIR4316'},
{value: 'MIR4318', id: 'MIR4318'},
{value: 'MIR4319', id: 'MIR4319'},
{value: 'MIR432', id: 'MIR432'},
{value: 'MIR4320', id: 'MIR4320'},
{value: 'MIR4322', id: 'MIR4322'},
{value: 'MIR4324', id: 'MIR4324'},
{value: 'MIR4325', id: 'MIR4325'},
{value: 'MIR4327', id: 'MIR4327'},
{value: 'MIR4418', id: 'MIR4418'},
{value: 'MIR4421', id: 'MIR4421'},
{value: 'MIR4422HG', id: 'MIR4422HG'},
{value: 'MIR4423', id: 'MIR4423'},
{value: 'MIR4424', id: 'MIR4424'},
{value: 'MIR4425', id: 'MIR4425'},
{value: 'MIR4427', id: 'MIR4427'},
{value: 'MIR4428', id: 'MIR4428'},
{value: 'MIR4429', id: 'MIR4429'},
{value: 'MIR4430', id: 'MIR4430'},
{value: 'MIR4431', id: 'MIR4431'},
{value: 'MIR4432', id: 'MIR4432'},
{value: 'MIR4432HG', id: 'MIR4432HG'},
{value: 'MIR4433A', id: 'MIR4433A'},
{value: 'MIR4435-1', id: 'MIR4435-1'},
{value: 'MIR4435-2', id: 'MIR4435-2'},
{value: 'MIR4435-2HG', id: 'MIR4435-2HG'},
{value: 'MIR4436A', id: 'MIR4436A'},
{value: 'MIR4436B1', id: 'MIR4436B1'},
{value: 'MIR4436B2', id: 'MIR4436B2'},
{value: 'MIR4437', id: 'MIR4437'},
{value: 'MIR4440', id: 'MIR4440'},
{value: 'MIR4442', id: 'MIR4442'},
{value: 'MIR4443', id: 'MIR4443'},
{value: 'MIR4444-2', id: 'MIR4444-2'},
{value: 'MIR4446', id: 'MIR4446'},
{value: 'MIR4448', id: 'MIR4448'},
{value: 'MIR4449', id: 'MIR4449'},
{value: 'MIR4450', id: 'MIR4450'},
{value: 'MIR4452', id: 'MIR4452'},
{value: 'MIR4453', id: 'MIR4453'},
{value: 'MIR4453HG', id: 'MIR4453HG'},
{value: 'MIR4454', id: 'MIR4454'},
{value: 'MIR4456', id: 'MIR4456'},
{value: 'MIR4457', id: 'MIR4457'},
{value: 'MIR4458', id: 'MIR4458'},
{value: 'MIR4458HG', id: 'MIR4458HG'},
{value: 'MIR4460', id: 'MIR4460'},
{value: 'MIR4462', id: 'MIR4462'},
{value: 'MIR4464', id: 'MIR4464'},
{value: 'MIR4465', id: 'MIR4465'},
{value: 'MIR4468', id: 'MIR4468'},
{value: 'MIR4469', id: 'MIR4469'},
{value: 'MIR4470', id: 'MIR4470'},
{value: 'MIR4471', id: 'MIR4471'},
{value: 'MIR4472-2', id: 'MIR4472-2'},
{value: 'MIR4473', id: 'MIR4473'},
{value: 'MIR4475', id: 'MIR4475'},
{value: 'MIR4476', id: 'MIR4476'},
{value: 'MIR4477A', id: 'MIR4477A'},
{value: 'MIR4477B', id: 'MIR4477B'},
{value: 'MIR4478', id: 'MIR4478'},
{value: 'MIR4479', id: 'MIR4479'},
{value: 'MIR4480', id: 'MIR4480'},
{value: 'MIR4482', id: 'MIR4482'},
{value: 'MIR4483', id: 'MIR4483'},
{value: 'MIR4486', id: 'MIR4486'},
{value: 'MIR4487', id: 'MIR4487'},
{value: 'MIR4488', id: 'MIR4488'},
{value: 'MIR4489', id: 'MIR4489'},
{value: 'MIR4491', id: 'MIR4491'},
{value: 'MIR4492', id: 'MIR4492'},
{value: 'MIR4493', id: 'MIR4493'},
{value: 'MIR4494', id: 'MIR4494'},
{value: 'MIR4495', id: 'MIR4495'},
{value: 'MIR4496', id: 'MIR4496'},
{value: 'MIR4497', id: 'MIR4497'},
{value: 'MIR4499', id: 'MIR4499'},
{value: 'MIR449C', id: 'MIR449C'},
{value: 'MIR4500HG', id: 'MIR4500HG'},
{value: 'MIR4502', id: 'MIR4502'},
{value: 'MIR4505', id: 'MIR4505'},
{value: 'MIR4507', id: 'MIR4507'},
{value: 'MIR4508', id: 'MIR4508'},
{value: 'MIR4509-1', id: 'MIR4509-1'},
{value: 'MIR4509-2', id: 'MIR4509-2'},
{value: 'MIR4509-3', id: 'MIR4509-3'},
{value: 'MIR4512', id: 'MIR4512'},
{value: 'MIR4513', id: 'MIR4513'},
{value: 'MIR4514', id: 'MIR4514'},
{value: 'MIR4519', id: 'MIR4519'},
{value: 'MIR451A', id: 'MIR451A'},
{value: 'MIR4521', id: 'MIR4521'},
{value: 'MIR4522', id: 'MIR4522'},
{value: 'MIR4523', id: 'MIR4523'},
{value: 'MIR4524B', id: 'MIR4524B'},
{value: 'MIR4525', id: 'MIR4525'},
{value: 'MIR4526', id: 'MIR4526'},
{value: 'MIR4527HG', id: 'MIR4527HG'},
{value: 'MIR4530', id: 'MIR4530'},
{value: 'MIR4531', id: 'MIR4531'},
{value: 'MIR4533', id: 'MIR4533'},
{value: 'MIR4535', id: 'MIR4535'},
{value: 'MIR4539', id: 'MIR4539'},
{value: 'MIR4540', id: 'MIR4540'},
{value: 'MIR455', id: 'MIR455'},
{value: 'MIR4632', id: 'MIR4632'},
{value: 'MIR4633', id: 'MIR4633'},
{value: 'MIR4635', id: 'MIR4635'},
{value: 'MIR4638', id: 'MIR4638'},
{value: 'MIR4639', id: 'MIR4639'},
{value: 'MIR4641', id: 'MIR4641'},
{value: 'MIR4642', id: 'MIR4642'},
{value: 'MIR4643', id: 'MIR4643'},
{value: 'MIR4645', id: 'MIR4645'},
{value: 'MIR4648', id: 'MIR4648'},
{value: 'MIR4651', id: 'MIR4651'},
{value: 'MIR4654', id: 'MIR4654'},
{value: 'MIR4657', id: 'MIR4657'},
{value: 'MIR4658', id: 'MIR4658'},
{value: 'MIR466', id: 'MIR466'},
{value: 'MIR4662A', id: 'MIR4662A'},
{value: 'MIR4663', id: 'MIR4663'},
{value: 'MIR4664', id: 'MIR4664'},
{value: 'MIR4665', id: 'MIR4665'},
{value: 'MIR4668', id: 'MIR4668'},
{value: 'MIR4669', id: 'MIR4669'},
{value: 'MIR4670', id: 'MIR4670'},
{value: 'MIR4671', id: 'MIR4671'},
{value: 'MIR4672', id: 'MIR4672'},
{value: 'MIR4674', id: 'MIR4674'},
{value: 'MIR4675', id: 'MIR4675'},
{value: 'MIR4676', id: 'MIR4676'},
{value: 'MIR4678', id: 'MIR4678'},
{value: 'MIR4679-1', id: 'MIR4679-1'},
{value: 'MIR4679-2', id: 'MIR4679-2'},
{value: 'MIR4681', id: 'MIR4681'},
{value: 'MIR4682', id: 'MIR4682'},
{value: 'MIR4685', id: 'MIR4685'},
{value: 'MIR4686', id: 'MIR4686'},
{value: 'MIR4687', id: 'MIR4687'},
{value: 'MIR4689', id: 'MIR4689'},
{value: 'MIR4691', id: 'MIR4691'},
{value: 'MIR4693', id: 'MIR4693'},
{value: 'MIR4695', id: 'MIR4695'},
{value: 'MIR4697', id: 'MIR4697'},
{value: 'MIR4703', id: 'MIR4703'},
{value: 'MIR4704', id: 'MIR4704'},
{value: 'MIR4706', id: 'MIR4706'},
{value: 'MIR4707', id: 'MIR4707'},
{value: 'MIR4708', id: 'MIR4708'},
{value: 'MIR4709', id: 'MIR4709'},
{value: 'MIR4710', id: 'MIR4710'},
{value: 'MIR4711', id: 'MIR4711'},
{value: 'MIR4712', id: 'MIR4712'},
{value: 'MIR4713HG', id: 'MIR4713HG'},
{value: 'MIR4717', id: 'MIR4717'},
{value: 'MIR4720', id: 'MIR4720'},
{value: 'MIR4722', id: 'MIR4722'},
{value: 'MIR4724', id: 'MIR4724'},
{value: 'MIR4726', id: 'MIR4726'},
{value: 'MIR4727', id: 'MIR4727'},
{value: 'MIR4729', id: 'MIR4729'},
{value: 'MIR4731', id: 'MIR4731'},
{value: 'MIR4732', id: 'MIR4732'},
{value: 'MIR4733HG', id: 'MIR4733HG'},
{value: 'MIR4734', id: 'MIR4734'},
{value: 'MIR4736', id: 'MIR4736'},
{value: 'MIR4737', id: 'MIR4737'},
{value: 'MIR4738', id: 'MIR4738'},
{value: 'MIR4739', id: 'MIR4739'},
{value: 'MIR4740', id: 'MIR4740'},
{value: 'MIR4741', id: 'MIR4741'},
{value: 'MIR4742', id: 'MIR4742'},
{value: 'MIR4745', id: 'MIR4745'},
{value: 'MIR4747', id: 'MIR4747'},
{value: 'MIR4748', id: 'MIR4748'},
{value: 'MIR4750', id: 'MIR4750'},
{value: 'MIR4752', id: 'MIR4752'},
{value: 'MIR4755', id: 'MIR4755'},
{value: 'MIR4757', id: 'MIR4757'},
{value: 'MIR4758', id: 'MIR4758'},
{value: 'MIR4762', id: 'MIR4762'},
{value: 'MIR4764', id: 'MIR4764'},
{value: 'MIR4771-1', id: 'MIR4771-1'},
{value: 'MIR4771-2', id: 'MIR4771-2'},
{value: 'MIR4774', id: 'MIR4774'},
{value: 'MIR4777', id: 'MIR4777'},
{value: 'MIR4778', id: 'MIR4778'},
{value: 'MIR4780', id: 'MIR4780'},
{value: 'MIR4781', id: 'MIR4781'},
{value: 'MIR4783', id: 'MIR4783'},
{value: 'MIR4785', id: 'MIR4785'},
{value: 'MIR4786', id: 'MIR4786'},
{value: 'MIR4789', id: 'MIR4789'},
{value: 'MIR4790', id: 'MIR4790'},
{value: 'MIR4795', id: 'MIR4795'},
{value: 'MIR4799', id: 'MIR4799'},
{value: 'MIR4801', id: 'MIR4801'},
{value: 'MIR4802', id: 'MIR4802'},
{value: 'MIR4803', id: 'MIR4803'},
{value: 'MIR483', id: 'MIR483'},
{value: 'MIR486-2', id: 'MIR486-2'},
{value: 'MIR487A', id: 'MIR487A'},
{value: 'MIR490', id: 'MIR490'},
{value: 'MIR492', id: 'MIR492'},
{value: 'MIR493', id: 'MIR493'},
{value: 'MIR496', id: 'MIR496'},
{value: 'MIR497HG', id: 'MIR497HG'},
{value: 'MIR498', id: 'MIR498'},
{value: 'MIR499A', id: 'MIR499A'},
{value: 'MIR5000', id: 'MIR5000'},
{value: 'MIR5007', id: 'MIR5007'},
{value: 'MIR5008', id: 'MIR5008'},
{value: 'MIR5010', id: 'MIR5010'},
{value: 'MIR5011', id: 'MIR5011'},
{value: 'MIR503HG', id: 'MIR503HG'},
{value: 'MIR5087', id: 'MIR5087'},
{value: 'MIR5091', id: 'MIR5091'},
{value: 'MIR5093', id: 'MIR5093'},
{value: 'MIR5094', id: 'MIR5094'},
{value: 'MIR5100', id: 'MIR5100'},
{value: 'MIR512-1', id: 'MIR512-1'},
{value: 'MIR512-2', id: 'MIR512-2'},
{value: 'MIR515-1', id: 'MIR515-1'},
{value: 'MIR515-2', id: 'MIR515-2'},
{value: 'MIR516A1', id: 'MIR516A1'},
{value: 'MIR516A2', id: 'MIR516A2'},
{value: 'MIR516B1', id: 'MIR516B1'},
{value: 'MIR516B2', id: 'MIR516B2'},
{value: 'MIR517A', id: 'MIR517A'},
{value: 'MIR517B', id: 'MIR517B'},
{value: 'MIR517C', id: 'MIR517C'},
{value: 'MIR5186', id: 'MIR5186'},
{value: 'MIR5188', id: 'MIR5188'},
{value: 'MIR5189', id: 'MIR5189'},
{value: 'MIR518A1', id: 'MIR518A1'},
{value: 'MIR518A2', id: 'MIR518A2'},
{value: 'MIR518B', id: 'MIR518B'},
{value: 'MIR518C', id: 'MIR518C'},
{value: 'MIR518D', id: 'MIR518D'},
{value: 'MIR518E', id: 'MIR518E'},
{value: 'MIR5191', id: 'MIR5191'},
{value: 'MIR5192', id: 'MIR5192'},
{value: 'MIR5194', id: 'MIR5194'},
{value: 'MIR5195', id: 'MIR5195'},
{value: 'MIR5196', id: 'MIR5196'},
{value: 'MIR5197', id: 'MIR5197'},
{value: 'MIR519A1', id: 'MIR519A1'},
{value: 'MIR519B', id: 'MIR519B'},
{value: 'MIR519C', id: 'MIR519C'},
{value: 'MIR519E', id: 'MIR519E'},
{value: 'MIR520A', id: 'MIR520A'},
{value: 'MIR520B', id: 'MIR520B'},
{value: 'MIR520C', id: 'MIR520C'},
{value: 'MIR520D', id: 'MIR520D'},
{value: 'MIR520E', id: 'MIR520E'},
{value: 'MIR520F', id: 'MIR520F'},
{value: 'MIR520G', id: 'MIR520G'},
{value: 'MIR521-1', id: 'MIR521-1'},
{value: 'MIR521-2', id: 'MIR521-2'},
{value: 'MIR522', id: 'MIR522'},
{value: 'MIR524', id: 'MIR524'},
{value: 'MIR525', id: 'MIR525'},
{value: 'MIR526A1', id: 'MIR526A1'},
{value: 'MIR526A2', id: 'MIR526A2'},
{value: 'MIR526B', id: 'MIR526B'},
{value: 'MIR527', id: 'MIR527'},
{value: 'MIR541', id: 'MIR541'},
{value: 'MIR548A1', id: 'MIR548A1'},
{value: 'MIR548A1HG', id: 'MIR548A1HG'},
{value: 'MIR548A2', id: 'MIR548A2'},
{value: 'MIR548A3', id: 'MIR548A3'},
{value: 'MIR548AB', id: 'MIR548AB'},
{value: 'MIR548AD', id: 'MIR548AD'},
{value: 'MIR548AE1', id: 'MIR548AE1'},
{value: 'MIR548AE2', id: 'MIR548AE2'},
{value: 'MIR548AG1', id: 'MIR548AG1'},
{value: 'MIR548AG2', id: 'MIR548AG2'},
{value: 'MIR548AI', id: 'MIR548AI'},
{value: 'MIR548AJ1', id: 'MIR548AJ1'},
{value: 'MIR548AO', id: 'MIR548AO'},
{value: 'MIR548AP', id: 'MIR548AP'},
{value: 'MIR548AS', id: 'MIR548AS'},
{value: 'MIR548AU', id: 'MIR548AU'},
{value: 'MIR548AY', id: 'MIR548AY'},
{value: 'MIR548AZ', id: 'MIR548AZ'},
{value: 'MIR548E', id: 'MIR548E'},
{value: 'MIR548F3', id: 'MIR548F3'},
{value: 'MIR548G', id: 'MIR548G'},
{value: 'MIR548H3', id: 'MIR548H3'},
{value: 'MIR548H4', id: 'MIR548H4'},
{value: 'MIR548H5', id: 'MIR548H5'},
{value: 'MIR548I2', id: 'MIR548I2'},
{value: 'MIR548J', id: 'MIR548J'},
{value: 'MIR548L', id: 'MIR548L'},
{value: 'MIR548N', id: 'MIR548N'},
{value: 'MIR548P', id: 'MIR548P'},
{value: 'MIR548X2', id: 'MIR548X2'},
{value: 'MIR548XHG', id: 'MIR548XHG'},
{value: 'MIR548Y', id: 'MIR548Y'},
{value: 'MIR551A', id: 'MIR551A'},
{value: 'MIR552', id: 'MIR552'},
{value: 'MIR553', id: 'MIR553'},
{value: 'MIR557', id: 'MIR557'},
{value: 'MIR5571', id: 'MIR5571'},
{value: 'MIR5572', id: 'MIR5572'},
{value: 'MIR5579', id: 'MIR5579'},
{value: 'MIR5580', id: 'MIR5580'},
{value: 'MIR5582', id: 'MIR5582'},
{value: 'MIR5585', id: 'MIR5585'},
{value: 'MIR5587', id: 'MIR5587'},
{value: 'MIR5589', id: 'MIR5589'},
{value: 'MIR564', id: 'MIR564'},
{value: 'MIR568', id: 'MIR568'},
{value: 'MIR5680', id: 'MIR5680'},
{value: 'MIR5681A', id: 'MIR5681A'},
{value: 'MIR5681B', id: 'MIR5681B'},
{value: 'MIR5682', id: 'MIR5682'},
{value: 'MIR5683', id: 'MIR5683'},
{value: 'MIR5685', id: 'MIR5685'},
{value: 'MIR5689HG', id: 'MIR5689HG'},
{value: 'MIR5691', id: 'MIR5691'},
{value: 'MIR5692A2', id: 'MIR5692A2'},
{value: 'MIR5692B', id: 'MIR5692B'},
{value: 'MIR5693', id: 'MIR5693'},
{value: 'MIR5694', id: 'MIR5694'},
{value: 'MIR5695', id: 'MIR5695'},
{value: 'MIR5696', id: 'MIR5696'},
{value: 'MIR5698', id: 'MIR5698'},
{value: 'MIR5700', id: 'MIR5700'},
{value: 'MIR5701-1', id: 'MIR5701-1'},
{value: 'MIR5701-2', id: 'MIR5701-2'},
{value: 'MIR5701-3', id: 'MIR5701-3'},
{value: 'MIR5702', id: 'MIR5702'},
{value: 'MIR5703', id: 'MIR5703'},
{value: 'MIR5705', id: 'MIR5705'},
{value: 'MIR5707', id: 'MIR5707'},
{value: 'MIR5708', id: 'MIR5708'},
{value: 'MIR572', id: 'MIR572'},
{value: 'MIR573', id: 'MIR573'},
{value: 'MIR574', id: 'MIR574'},
{value: 'MIR575', id: 'MIR575'},
{value: 'MIR577', id: 'MIR577'},
{value: 'MIR583', id: 'MIR583'},
{value: 'MIR583HG', id: 'MIR583HG'},
{value: 'MIR584', id: 'MIR584'},
{value: 'MIR585', id: 'MIR585'},
{value: 'MIR587', id: 'MIR587'},
{value: 'MIR589', id: 'MIR589'},
{value: 'MIR593', id: 'MIR593'},
{value: 'MIR597', id: 'MIR597'},
{value: 'MIR600', id: 'MIR600'},
{value: 'MIR600HG', id: 'MIR600HG'},
{value: 'MIR601', id: 'MIR601'},
{value: 'MIR602', id: 'MIR602'},
{value: 'MIR604', id: 'MIR604'},
{value: 'MIR6068', id: 'MIR6068'},
{value: 'MIR6069', id: 'MIR6069'},
{value: 'MIR607', id: 'MIR607'},
{value: 'MIR6070', id: 'MIR6070'},
{value: 'MIR6071', id: 'MIR6071'},
{value: 'MIR6072', id: 'MIR6072'},
{value: 'MIR6073', id: 'MIR6073'},
{value: 'MIR6074', id: 'MIR6074'},
{value: 'MIR6077', id: 'MIR6077'},
{value: 'MIR6078', id: 'MIR6078'},
{value: 'MIR6079', id: 'MIR6079'},
{value: 'MIR6080', id: 'MIR6080'},
{value: 'MIR6081', id: 'MIR6081'},
{value: 'MIR6082', id: 'MIR6082'},
{value: 'MIR6085', id: 'MIR6085'},
{value: 'MIR609', id: 'MIR609'},
{value: 'MIR6090', id: 'MIR6090'},
{value: 'MIR611', id: 'MIR611'},
{value: 'MIR612', id: 'MIR612'},
{value: 'MIR6124', id: 'MIR6124'},
{value: 'MIR6126', id: 'MIR6126'},
{value: 'MIR6127', id: 'MIR6127'},
{value: 'MIR6131', id: 'MIR6131'},
{value: 'MIR6132', id: 'MIR6132'},
{value: 'MIR6133', id: 'MIR6133'},
{value: 'MIR614', id: 'MIR614'},
{value: 'MIR615', id: 'MIR615'},
{value: 'MIR617', id: 'MIR617'},
{value: 'MIR621', id: 'MIR621'},
{value: 'MIR622', id: 'MIR622'},
{value: 'MIR623', id: 'MIR623'},
{value: 'MIR625', id: 'MIR625'},
{value: 'MIR628', id: 'MIR628'},
{value: 'MIR629', id: 'MIR629'},
{value: 'MIR631', id: 'MIR631'},
{value: 'MIR632', id: 'MIR632'},
{value: 'MIR633', id: 'MIR633'},
{value: 'MIR636', id: 'MIR636'},
{value: 'MIR637', id: 'MIR637'},
{value: 'MIR638', id: 'MIR638'},
{value: 'MIR639', id: 'MIR639'},
{value: 'MIR640', id: 'MIR640'},
{value: 'MIR646', id: 'MIR646'},
{value: 'MIR646HG', id: 'MIR646HG'},
{value: 'MIR647', id: 'MIR647'},
{value: 'MIR648', id: 'MIR648'},
{value: 'MIR6499', id: 'MIR6499'},
{value: 'MIR650', id: 'MIR650'},
{value: 'MIR6500', id: 'MIR6500'},
{value: 'MIR6501', id: 'MIR6501'},
{value: 'MIR6505', id: 'MIR6505'},
{value: 'MIR6507', id: 'MIR6507'},
{value: 'MIR6510', id: 'MIR6510'},
{value: 'MIR653', id: 'MIR653'},
{value: 'MIR654', id: 'MIR654'},
{value: 'MIR656', id: 'MIR656'},
{value: 'MIR657', id: 'MIR657'},
{value: 'MIR658', id: 'MIR658'},
{value: 'MIR661', id: 'MIR661'},
{value: 'MIR662', id: 'MIR662'},
{value: 'MIR663A', id: 'MIR663A'},
{value: 'MIR663AHG', id: 'MIR663AHG'},
{value: 'MIR663B', id: 'MIR663B'},
{value: 'MIR665', id: 'MIR665'},
{value: 'MIR668', id: 'MIR668'},
{value: 'MIR670HG', id: 'MIR670HG'},
{value: 'MIR671', id: 'MIR671'},
{value: 'MIR6715B', id: 'MIR6715B'},
{value: 'MIR6716', id: 'MIR6716'},
{value: 'MIR6717', id: 'MIR6717'},
{value: 'MIR6718', id: 'MIR6718'},
{value: 'MIR6719', id: 'MIR6719'},
{value: 'MIR6720', id: 'MIR6720'},
{value: 'MIR6722', id: 'MIR6722'},
{value: 'MIR6724-1', id: 'MIR6724-1'},
{value: 'MIR6724-2', id: 'MIR6724-2'},
{value: 'MIR6724-3', id: 'MIR6724-3'},
{value: 'MIR6728', id: 'MIR6728'},
{value: 'MIR6729', id: 'MIR6729'},
{value: 'MIR6730', id: 'MIR6730'},
{value: 'MIR6731', id: 'MIR6731'},
{value: 'MIR6732', id: 'MIR6732'},
{value: 'MIR6733', id: 'MIR6733'},
{value: 'MIR6735', id: 'MIR6735'},
{value: 'MIR6738', id: 'MIR6738'},
{value: 'MIR6742', id: 'MIR6742'},
{value: 'MIR6744', id: 'MIR6744'},
{value: 'MIR6746', id: 'MIR6746'},
{value: 'MIR6749', id: 'MIR6749'},
{value: 'MIR675', id: 'MIR675'},
{value: 'MIR6752', id: 'MIR6752'},
{value: 'MIR6754', id: 'MIR6754'},
{value: 'MIR6758', id: 'MIR6758'},
{value: 'MIR6760', id: 'MIR6760'},
{value: 'MIR6761', id: 'MIR6761'},
{value: 'MIR6762', id: 'MIR6762'},
{value: 'MIR6763', id: 'MIR6763'},
{value: 'MIR6765', id: 'MIR6765'},
{value: 'MIR6766', id: 'MIR6766'},
{value: 'MIR6767', id: 'MIR6767'},
{value: 'MIR6769A', id: 'MIR6769A'},
{value: 'MIR6772', id: 'MIR6772'},
{value: 'MIR6775', id: 'MIR6775'},
{value: 'MIR6776', id: 'MIR6776'},
{value: 'MIR6777', id: 'MIR6777'},
{value: 'MIR6778', id: 'MIR6778'},
{value: 'MIR6779', id: 'MIR6779'},
{value: 'MIR6780A', id: 'MIR6780A'},
{value: 'MIR6780B', id: 'MIR6780B'},
{value: 'MIR6781', id: 'MIR6781'},
{value: 'MIR6783', id: 'MIR6783'},
{value: 'MIR6785', id: 'MIR6785'},
{value: 'MIR6787', id: 'MIR6787'},
{value: 'MIR6789', id: 'MIR6789'},
{value: 'MIR6790', id: 'MIR6790'},
{value: 'MIR6791', id: 'MIR6791'},
{value: 'MIR6792', id: 'MIR6792'},
{value: 'MIR6793', id: 'MIR6793'},
{value: 'MIR6794', id: 'MIR6794'},
{value: 'MIR6797', id: 'MIR6797'},
{value: 'MIR6798', id: 'MIR6798'},
{value: 'MIR6799', id: 'MIR6799'},
{value: 'MIR6801', id: 'MIR6801'},
{value: 'MIR6802', id: 'MIR6802'},
{value: 'MIR6804', id: 'MIR6804'},
{value: 'MIR6806', id: 'MIR6806'},
{value: 'MIR6809', id: 'MIR6809'},
{value: 'MIR6810', id: 'MIR6810'},
{value: 'MIR6811', id: 'MIR6811'},
{value: 'MIR6812', id: 'MIR6812'},
{value: 'MIR6813', id: 'MIR6813'},
{value: 'MIR6814', id: 'MIR6814'},
{value: 'MIR6815', id: 'MIR6815'},
{value: 'MIR6817', id: 'MIR6817'},
{value: 'MIR6818', id: 'MIR6818'},
{value: 'MIR6819', id: 'MIR6819'},
{value: 'MIR6821', id: 'MIR6821'},
{value: 'MIR6822', id: 'MIR6822'},
{value: 'MIR6825', id: 'MIR6825'},
{value: 'MIR6826', id: 'MIR6826'},
{value: 'MIR6829', id: 'MIR6829'},
{value: 'MIR6831', id: 'MIR6831'},
{value: 'MIR6832', id: 'MIR6832'},
{value: 'MIR6836', id: 'MIR6836'},
{value: 'MIR6837', id: 'MIR6837'},
{value: 'MIR6838', id: 'MIR6838'},
{value: 'MIR6840', id: 'MIR6840'},
{value: 'MIR6841', id: 'MIR6841'},
{value: 'MIR6842', id: 'MIR6842'},
{value: 'MIR6843', id: 'MIR6843'},
{value: 'MIR6849', id: 'MIR6849'},
{value: 'MIR6850', id: 'MIR6850'},
{value: 'MIR6851', id: 'MIR6851'},
{value: 'MIR6852', id: 'MIR6852'},
{value: 'MIR6853', id: 'MIR6853'},
{value: 'MIR6855', id: 'MIR6855'},
{value: 'MIR6856', id: 'MIR6856'},
{value: 'MIR6860', id: 'MIR6860'},
{value: 'MIR6861', id: 'MIR6861'},
{value: 'MIR6862-1', id: 'MIR6862-1'},
{value: 'MIR6867', id: 'MIR6867'},
{value: 'MIR6869', id: 'MIR6869'},
{value: 'MIR6872', id: 'MIR6872'},
{value: 'MIR6876', id: 'MIR6876'},
{value: 'MIR6880', id: 'MIR6880'},
{value: 'MIR6881', id: 'MIR6881'},
{value: 'MIR6882', id: 'MIR6882'},
{value: 'MIR6883', id: 'MIR6883'},
{value: 'MIR6884', id: 'MIR6884'},
{value: 'MIR6886', id: 'MIR6886'},
{value: 'MIR6888', id: 'MIR6888'},
{value: 'MIR6890', id: 'MIR6890'},
{value: 'MIR6891', id: 'MIR6891'},
{value: 'MIR6892', id: 'MIR6892'},
{value: 'MIR7-1', id: 'MIR7-1'},
{value: 'MIR7-2', id: 'MIR7-2'},
{value: 'MIR7-3', id: 'MIR7-3'},
{value: 'MIR7-3HG', id: 'MIR7-3HG'},
{value: 'MIR708', id: 'MIR708'},
{value: 'MIR7106', id: 'MIR7106'},
{value: 'MIR7107', id: 'MIR7107'},
{value: 'MIR7108', id: 'MIR7108'},
{value: 'MIR7110', id: 'MIR7110'},
{value: 'MIR7111', id: 'MIR7111'},
{value: 'MIR7112', id: 'MIR7112'},
{value: 'MIR7114', id: 'MIR7114'},
{value: 'MIR7150', id: 'MIR7150'},
{value: 'MIR7151', id: 'MIR7151'},
{value: 'MIR7152', id: 'MIR7152'},
{value: 'MIR7153', id: 'MIR7153'},
{value: 'MIR7154', id: 'MIR7154'},
{value: 'MIR7155', id: 'MIR7155'},
{value: 'MIR7156', id: 'MIR7156'},
{value: 'MIR7157', id: 'MIR7157'},
{value: 'MIR7158', id: 'MIR7158'},
{value: 'MIR7159', id: 'MIR7159'},
{value: 'MIR7160', id: 'MIR7160'},
{value: 'MIR7161', id: 'MIR7161'},
{value: 'MIR7162', id: 'MIR7162'},
{value: 'MIR744', id: 'MIR744'},
{value: 'MIR7515HG', id: 'MIR7515HG'},
{value: 'MIR759', id: 'MIR759'},
{value: 'MIR760', id: 'MIR760'},
{value: 'MIR762', id: 'MIR762'},
{value: 'MIR762HG', id: 'MIR762HG'},
{value: 'MIR765', id: 'MIR765'},
{value: 'MIR770', id: 'MIR770'},
{value: 'MIR7704', id: 'MIR7704'},
{value: 'MIR7843', id: 'MIR7843'},
{value: 'MIR7844', id: 'MIR7844'},
{value: 'MIR7846', id: 'MIR7846'},
{value: 'MIR7847', id: 'MIR7847'},
{value: 'MIR7848', id: 'MIR7848'},
{value: 'MIR7852', id: 'MIR7852'},
{value: 'MIR7854', id: 'MIR7854'},
{value: 'MIR7855', id: 'MIR7855'},
{value: 'MIR7856', id: 'MIR7856'},
{value: 'MIR7973-2', id: 'MIR7973-2'},
{value: 'MIR7974', id: 'MIR7974'},
{value: 'MIR7975', id: 'MIR7975'},
{value: 'MIR7977', id: 'MIR7977'},
{value: 'MIR802', id: 'MIR802'},
{value: 'MIR8052', id: 'MIR8052'},
{value: 'MIR8054', id: 'MIR8054'},
{value: 'MIR8055', id: 'MIR8055'},
{value: 'MIR8056', id: 'MIR8056'},
{value: 'MIR8057', id: 'MIR8057'},
{value: 'MIR8058', id: 'MIR8058'},
{value: 'MIR8059', id: 'MIR8059'},
{value: 'MIR8060', id: 'MIR8060'},
{value: 'MIR8062', id: 'MIR8062'},
{value: 'MIR8065', id: 'MIR8065'},
{value: 'MIR8067', id: 'MIR8067'},
{value: 'MIR8070', id: 'MIR8070'},
{value: 'MIR8071-1', id: 'MIR8071-1'},
{value: 'MIR8071-2', id: 'MIR8071-2'},
{value: 'MIR8072', id: 'MIR8072'},
{value: 'MIR8073', id: 'MIR8073'},
{value: 'MIR8074', id: 'MIR8074'},
{value: 'MIR8075', id: 'MIR8075'},
{value: 'MIR8077', id: 'MIR8077'},
{value: 'MIR8078', id: 'MIR8078'},
{value: 'MIR8080', id: 'MIR8080'},
{value: 'MIR8083', id: 'MIR8083'},
{value: 'MIR8084', id: 'MIR8084'},
{value: 'MIR8085', id: 'MIR8085'},
{value: 'MIR8086', id: 'MIR8086'},
{value: 'MIR8089', id: 'MIR8089'},
{value: 'MIR873', id: 'MIR873'},
{value: 'MIR874', id: 'MIR874'},
{value: 'MIR876', id: 'MIR876'},
{value: 'MIR877', id: 'MIR877'},
{value: 'MIR887', id: 'MIR887'},
{value: 'MIR9-1', id: 'MIR9-1'},
{value: 'MIR9-1HG', id: 'MIR9-1HG'},
{value: 'MIR9-2', id: 'MIR9-2'},
{value: 'MIR9-3', id: 'MIR9-3'},
{value: 'MIR9-3HG', id: 'MIR9-3HG'},
{value: 'MIR920', id: 'MIR920'},
{value: 'MIR921', id: 'MIR921'},
{value: 'MIR924HG', id: 'MIR924HG'},
{value: 'MIR92A1', id: 'MIR92A1'},
{value: 'MIR933', id: 'MIR933'},
{value: 'MIR935', id: 'MIR935'},
{value: 'MIR936', id: 'MIR936'},
{value: 'MIR938', id: 'MIR938'},
{value: 'MIR941-1', id: 'MIR941-1'},
{value: 'MIR942', id: 'MIR942'},
{value: 'MIR943', id: 'MIR943'},
{value: 'MIR944', id: 'MIR944'},
{value: 'MIR9500', id: 'MIR9500'},
{value: 'MIR96', id: 'MIR96'},
{value: 'MIR99AHG', id: 'MIR99AHG'},
{value: 'MIRLET7A1HG', id: 'MIRLET7A1HG'},
{value: 'MIRLET7B', id: 'MIRLET7B'},
{value: 'MIRLET7BHG', id: 'MIRLET7BHG'},
{value: 'MIRLET7C', id: 'MIRLET7C'},
{value: 'MIRLET7G', id: 'MIRLET7G'},
{value: 'MIRLET7I', id: 'MIRLET7I'},
{value: 'MIRLET7IHG', id: 'MIRLET7IHG'},
{value: 'MIS12', id: 'MIS12'},
{value: 'MIS18A', id: 'MIS18A'},
{value: 'MIS18A-AS1', id: 'MIS18A-AS1'},
{value: 'MIS18BP1', id: 'MIS18BP1'},
{value: 'MISP', id: 'MISP'},
{value: 'MISP3', id: 'MISP3'},
{value: 'MITD1', id: 'MITD1'},
{value: 'MITF', id: 'MITF'},
{value: 'MIX23', id: 'MIX23'},
{value: 'MIX23P2', id: 'MIX23P2'},
{value: 'MIXL1', id: 'MIXL1'},
{value: 'MKI67', id: 'MKI67'},
{value: 'MKKS', id: 'MKKS'},
{value: 'MKLN1', id: 'MKLN1'},
{value: 'MKLN1-AS', id: 'MKLN1-AS'},
{value: 'MKNK1', id: 'MKNK1'},
{value: 'MKNK1-AS1', id: 'MKNK1-AS1'},
{value: 'MKNK2', id: 'MKNK2'},
{value: 'MKNK2P1', id: 'MKNK2P1'},
{value: 'MKRN1', id: 'MKRN1'},
{value: 'MKRN2', id: 'MKRN2'},
{value: 'MKRN2OS', id: 'MKRN2OS'},
{value: 'MKRN3', id: 'MKRN3'},
{value: 'MKRN4P', id: 'MKRN4P'},
{value: 'MKRN6P', id: 'MKRN6P'},
{value: 'MKRN8P', id: 'MKRN8P'},
{value: 'MKRN9P', id: 'MKRN9P'},
{value: 'MKS1', id: 'MKS1'},
{value: 'MKX', id: 'MKX'},
{value: 'MKX-AS1', id: 'MKX-AS1'},
{value: 'MLANA', id: 'MLANA'},
{value: 'MLC1', id: 'MLC1'},
{value: 'MLEC', id: 'MLEC'},
{value: 'MLECP1', id: 'MLECP1'},
{value: 'MLF1', id: 'MLF1'},
{value: 'MLF2', id: 'MLF2'},
{value: 'MLH1', id: 'MLH1'},
{value: 'MLH3', id: 'MLH3'},
{value: 'MLIP', id: 'MLIP'},
{value: 'MLIP-AS1', id: 'MLIP-AS1'},
{value: 'MLKL', id: 'MLKL'},
{value: 'MLLT1', id: 'MLLT1'},
{value: 'MLLT10', id: 'MLLT10'},
{value: 'MLLT10P1', id: 'MLLT10P1'},
{value: 'MLLT10P2', id: 'MLLT10P2'},
{value: 'MLLT11', id: 'MLLT11'},
{value: 'MLLT3', id: 'MLLT3'},
{value: 'MLLT6', id: 'MLLT6'},
{value: 'MLN', id: 'MLN'},
{value: 'MLNR', id: 'MLNR'},
{value: 'MLPH', id: 'MLPH'},
{value: 'MLST8', id: 'MLST8'},
{value: 'MLX', id: 'MLX'},
{value: 'MLXIP', id: 'MLXIP'},
{value: 'MLXIPL', id: 'MLXIPL'},
{value: 'MLXP1', id: 'MLXP1'},
{value: 'MLYCD', id: 'MLYCD'},
{value: 'MMAA', id: 'MMAA'},
{value: 'MMAB', id: 'MMAB'},
{value: 'MMACHC', id: 'MMACHC'},
{value: 'MMADHC', id: 'MMADHC'},
{value: 'MMADHC-DT', id: 'MMADHC-DT'},
{value: 'MMD', id: 'MMD'},
{value: 'MMD2', id: 'MMD2'},
{value: 'MME', id: 'MME'},
{value: 'MME-AS1', id: 'MME-AS1'},
{value: 'MMEL1', id: 'MMEL1'},
{value: 'MMEL1-AS1', id: 'MMEL1-AS1'},
{value: 'MMGT1', id: 'MMGT1'},
{value: 'MMP1', id: 'MMP1'},
{value: 'MMP10', id: 'MMP10'},
{value: 'MMP11', id: 'MMP11'},
{value: 'MMP12', id: 'MMP12'},
{value: 'MMP13', id: 'MMP13'},
{value: 'MMP14', id: 'MMP14'},
{value: 'MMP15', id: 'MMP15'},
{value: 'MMP16', id: 'MMP16'},
{value: 'MMP17', id: 'MMP17'},
{value: 'MMP19', id: 'MMP19'},
{value: 'MMP2', id: 'MMP2'},
{value: 'MMP2-AS1', id: 'MMP2-AS1'},
{value: 'MMP20', id: 'MMP20'},
{value: 'MMP21', id: 'MMP21'},
{value: 'MMP23A', id: 'MMP23A'},
{value: 'MMP23B', id: 'MMP23B'},
{value: 'MMP24', id: 'MMP24'},
{value: 'MMP24OS', id: 'MMP24OS'},
{value: 'MMP25', id: 'MMP25'},
{value: 'MMP25-AS1', id: 'MMP25-AS1'},
{value: 'MMP26', id: 'MMP26'},
{value: 'MMP27', id: 'MMP27'},
{value: 'MMP28', id: 'MMP28'},
{value: 'MMP3', id: 'MMP3'},
{value: 'MMP7', id: 'MMP7'},
{value: 'MMP8', id: 'MMP8'},
{value: 'MMP9', id: 'MMP9'},
{value: 'MMRN1', id: 'MMRN1'},
{value: 'MMRN2', id: 'MMRN2'},
{value: 'MMS19', id: 'MMS19'},
{value: 'MMS22L', id: 'MMS22L'},
{value: 'MMUT', id: 'MMUT'},
{value: 'MN1', id: 'MN1'},
{value: 'MNAT1', id: 'MNAT1'},
{value: 'MND1', id: 'MND1'},
{value: 'MNDA', id: 'MNDA'},
{value: 'MNS1', id: 'MNS1'},
{value: 'MNT', id: 'MNT'},
{value: 'MNX1', id: 'MNX1'},
{value: 'MNX1-AS1', id: 'MNX1-AS1'},
{value: 'MNX1-AS2', id: 'MNX1-AS2'},
{value: 'MOAP1', id: 'MOAP1'},
{value: 'MOB1A', id: 'MOB1A'},
{value: 'MOB1AP1', id: 'MOB1AP1'},
{value: 'MOB1B', id: 'MOB1B'},
{value: 'MOB2', id: 'MOB2'},
{value: 'MOB3A', id: 'MOB3A'},
{value: 'MOB3B', id: 'MOB3B'},
{value: 'MOB3C', id: 'MOB3C'},
{value: 'MOB4', id: 'MOB4'},
{value: 'MOB4P1', id: 'MOB4P1'},
{value: 'MOBP', id: 'MOBP'},
{value: 'MOCOS', id: 'MOCOS'},
{value: 'MOCS1', id: 'MOCS1'},
{value: 'MOCS1P1', id: 'MOCS1P1'},
{value: 'MOCS2', id: 'MOCS2'},
{value: 'MOCS2-DT', id: 'MOCS2-DT'},
{value: 'MOCS3', id: 'MOCS3'},
{value: 'MOG', id: 'MOG'},
{value: 'MOGAT1', id: 'MOGAT1'},
{value: 'MOGAT2', id: 'MOGAT2'},
{value: 'MOGAT3', id: 'MOGAT3'},
{value: 'MOGS', id: 'MOGS'},
{value: 'MOK', id: 'MOK'},
{value: 'MON1A', id: 'MON1A'},
{value: 'MON1B', id: 'MON1B'},
{value: 'MON2', id: 'MON2'},
{value: 'MORC1', id: 'MORC1'},
{value: 'MORC1-AS1', id: 'MORC1-AS1'},
{value: 'MORC2', id: 'MORC2'},
{value: 'MORC2-AS1', id: 'MORC2-AS1'},
{value: 'MORC3', id: 'MORC3'},
{value: 'MORC4', id: 'MORC4'},
{value: 'MORF4L1', id: 'MORF4L1'},
{value: 'MORF4L1P1', id: 'MORF4L1P1'},
{value: 'MORF4L1P3', id: 'MORF4L1P3'},
{value: 'MORF4L2', id: 'MORF4L2'},
{value: 'MORF4L2-AS1', id: 'MORF4L2-AS1'},
{value: 'MORN1', id: 'MORN1'},
{value: 'MORN2', id: 'MORN2'},
{value: 'MORN3', id: 'MORN3'},
{value: 'MORN4', id: 'MORN4'},
{value: 'MORN5', id: 'MORN5'},
{value: 'MOS', id: 'MOS'},
{value: 'MOSMO', id: 'MOSMO'},
{value: 'MOSPD1', id: 'MOSPD1'},
{value: 'MOSPD2', id: 'MOSPD2'},
{value: 'MOSPD3', id: 'MOSPD3'},
{value: 'MOV10', id: 'MOV10'},
{value: 'MOV10L1', id: 'MOV10L1'},
{value: 'MOXD1', id: 'MOXD1'},
{value: 'MOXD2P', id: 'MOXD2P'},
{value: 'MPC1', id: 'MPC1'},
{value: 'MPC1-DT', id: 'MPC1-DT'},
{value: 'MPC2', id: 'MPC2'},
{value: 'MPDU1', id: 'MPDU1'},
{value: 'MPDZ', id: 'MPDZ'},
{value: 'MPEG1', id: 'MPEG1'},
{value: 'MPG', id: 'MPG'},
{value: 'MPHOSPH10', id: 'MPHOSPH10'},
{value: 'MPHOSPH6', id: 'MPHOSPH6'},
{value: 'MPHOSPH6-DT', id: 'MPHOSPH6-DT'},
{value: 'MPHOSPH6P1', id: 'MPHOSPH6P1'},
{value: 'MPHOSPH8', id: 'MPHOSPH8'},
{value: 'MPHOSPH9', id: 'MPHOSPH9'},
{value: 'MPI', id: 'MPI'},
{value: 'MPIG6B', id: 'MPIG6B'},
{value: 'MPL', id: 'MPL'},
{value: 'MPLKIP', id: 'MPLKIP'},
{value: 'MPND', id: 'MPND'},
{value: 'MPO', id: 'MPO'},
{value: 'MPP1', id: 'MPP1'},
{value: 'MPP2', id: 'MPP2'},
{value: 'MPP3', id: 'MPP3'},
{value: 'MPP4', id: 'MPP4'},
{value: 'MPP7', id: 'MPP7'},
{value: 'MPP7-DT', id: 'MPP7-DT'},
{value: 'MPPE1', id: 'MPPE1'},
{value: 'MPPE1P1', id: 'MPPE1P1'},
{value: 'MPPED1', id: 'MPPED1'},
{value: 'MPPED2', id: 'MPPED2'},
{value: 'MPPED2-AS1', id: 'MPPED2-AS1'},
{value: 'MPRIP', id: 'MPRIP'},
{value: 'MPRIPP1', id: 'MPRIPP1'},
{value: 'MPST', id: 'MPST'},
{value: 'MPTX1', id: 'MPTX1'},
{value: 'MPV17', id: 'MPV17'},
{value: 'MPV17L', id: 'MPV17L'},
{value: 'MPV17L2', id: 'MPV17L2'},
{value: 'MPZ', id: 'MPZ'},
{value: 'MPZL1', id: 'MPZL1'},
{value: 'MPZL2', id: 'MPZL2'},
{value: 'MPZL3', id: 'MPZL3'},
{value: 'MR1', id: 'MR1'},
{value: 'MRAP', id: 'MRAP'},
{value: 'MRAP-AS1', id: 'MRAP-AS1'},
{value: 'MRAP2', id: 'MRAP2'},
{value: 'MRAS', id: 'MRAS'},
{value: 'MRC1', id: 'MRC1'},
{value: 'MRC2', id: 'MRC2'},
{value: 'MRE11', id: 'MRE11'},
{value: 'MREG', id: 'MREG'},
{value: 'MREGP1', id: 'MREGP1'},
{value: 'MRFAP1', id: 'MRFAP1'},
{value: 'MRFAP1L1', id: 'MRFAP1L1'},
{value: 'MRGBP', id: 'MRGBP'},
{value: 'MRGPRD', id: 'MRGPRD'},
{value: 'MRGPRE', id: 'MRGPRE'},
{value: 'MRGPRF', id: 'MRGPRF'},
{value: 'MRGPRF-AS1', id: 'MRGPRF-AS1'},
{value: 'MRGPRG', id: 'MRGPRG'},
{value: 'MRGPRG-AS1', id: 'MRGPRG-AS1'},
{value: 'MRGPRX1', id: 'MRGPRX1'},
{value: 'MRGPRX10P', id: 'MRGPRX10P'},
{value: 'MRGPRX11P', id: 'MRGPRX11P'},
{value: 'MRGPRX13P', id: 'MRGPRX13P'},
{value: 'MRGPRX2', id: 'MRGPRX2'},
{value: 'MRGPRX3', id: 'MRGPRX3'},
{value: 'MRGPRX5P', id: 'MRGPRX5P'},
{value: 'MRGPRX6P', id: 'MRGPRX6P'},
{value: 'MRGPRX7P', id: 'MRGPRX7P'},
{value: 'MRGPRX8P', id: 'MRGPRX8P'},
{value: 'MRGPRX9P', id: 'MRGPRX9P'},
{value: 'MRI1', id: 'MRI1'},
{value: 'MRLN', id: 'MRLN'},
{value: 'MRM1', id: 'MRM1'},
{value: 'MRM2', id: 'MRM2'},
{value: 'MRM3', id: 'MRM3'},
{value: 'MRM3P1', id: 'MRM3P1'},
{value: 'MRM3P2', id: 'MRM3P2'},
{value: 'MRNIP', id: 'MRNIP'},
{value: 'MRO', id: 'MRO'},
{value: 'MROCKI', id: 'MROCKI'},
{value: 'MROH1', id: 'MROH1'},
{value: 'MROH2A', id: 'MROH2A'},
{value: 'MROH2B', id: 'MROH2B'},
{value: 'MROH3P', id: 'MROH3P'},
{value: 'MROH4P', id: 'MROH4P'},
{value: 'MROH5', id: 'MROH5'},
{value: 'MROH6', id: 'MROH6'},
{value: 'MROH7', id: 'MROH7'},
{value: 'MROH7-TTC4', id: 'MROH7-TTC4'},
{value: 'MROH8', id: 'MROH8'},
{value: 'MRPL1', id: 'MRPL1'},
{value: 'MRPL10', id: 'MRPL10'},
{value: 'MRPL11', id: 'MRPL11'},
{value: 'MRPL12', id: 'MRPL12'},
{value: 'MRPL13', id: 'MRPL13'},
{value: 'MRPL14', id: 'MRPL14'},
{value: 'MRPL15', id: 'MRPL15'},
{value: 'MRPL15P1', id: 'MRPL15P1'},
{value: 'MRPL16', id: 'MRPL16'},
{value: 'MRPL17', id: 'MRPL17'},
{value: 'MRPL18', id: 'MRPL18'},
{value: 'MRPL19', id: 'MRPL19'},
{value: 'MRPL2', id: 'MRPL2'},
{value: 'MRPL20', id: 'MRPL20'},
{value: 'MRPL20-AS1', id: 'MRPL20-AS1'},
{value: 'MRPL20-DT', id: 'MRPL20-DT'},
{value: 'MRPL20P1', id: 'MRPL20P1'},
{value: 'MRPL21', id: 'MRPL21'},
{value: 'MRPL22', id: 'MRPL22'},
{value: 'MRPL23', id: 'MRPL23'},
{value: 'MRPL23-AS1', id: 'MRPL23-AS1'},
{value: 'MRPL24', id: 'MRPL24'},
{value: 'MRPL27', id: 'MRPL27'},
{value: 'MRPL28', id: 'MRPL28'},
{value: 'MRPL2P1', id: 'MRPL2P1'},
{value: 'MRPL3', id: 'MRPL3'},
{value: 'MRPL30', id: 'MRPL30'},
{value: 'MRPL30P1', id: 'MRPL30P1'},
{value: 'MRPL32', id: 'MRPL32'},
{value: 'MRPL33', id: 'MRPL33'},
{value: 'MRPL34', id: 'MRPL34'},
{value: 'MRPL35', id: 'MRPL35'},
{value: 'MRPL36', id: 'MRPL36'},
{value: 'MRPL36P1', id: 'MRPL36P1'},
{value: 'MRPL37', id: 'MRPL37'},
{value: 'MRPL37P1', id: 'MRPL37P1'},
{value: 'MRPL38', id: 'MRPL38'},
{value: 'MRPL39', id: 'MRPL39'},
{value: 'MRPL3P1', id: 'MRPL3P1'},
{value: 'MRPL4', id: 'MRPL4'},
{value: 'MRPL40', id: 'MRPL40'},
{value: 'MRPL40P1', id: 'MRPL40P1'},
{value: 'MRPL41', id: 'MRPL41'},
{value: 'MRPL42', id: 'MRPL42'},
{value: 'MRPL42P2', id: 'MRPL42P2'},
{value: 'MRPL42P3', id: 'MRPL42P3'},
{value: 'MRPL42P6', id: 'MRPL42P6'},
{value: 'MRPL43', id: 'MRPL43'},
{value: 'MRPL44', id: 'MRPL44'},
{value: 'MRPL45', id: 'MRPL45'},
{value: 'MRPL45P1', id: 'MRPL45P1'},
{value: 'MRPL45P2', id: 'MRPL45P2'},
{value: 'MRPL46', id: 'MRPL46'},
{value: 'MRPL47', id: 'MRPL47'},
{value: 'MRPL48', id: 'MRPL48'},
{value: 'MRPL48P1', id: 'MRPL48P1'},
{value: 'MRPL49', id: 'MRPL49'},
{value: 'MRPL49P1', id: 'MRPL49P1'},
{value: 'MRPL50', id: 'MRPL50'},
{value: 'MRPL50P1', id: 'MRPL50P1'},
{value: 'MRPL50P2', id: 'MRPL50P2'},
{value: 'MRPL50P4', id: 'MRPL50P4'},
{value: 'MRPL51', id: 'MRPL51'},
{value: 'MRPL52', id: 'MRPL52'},
{value: 'MRPL53', id: 'MRPL53'},
{value: 'MRPL53P1', id: 'MRPL53P1'},
{value: 'MRPL54', id: 'MRPL54'},
{value: 'MRPL55', id: 'MRPL55'},
{value: 'MRPL57', id: 'MRPL57'},
{value: 'MRPL57P1', id: 'MRPL57P1'},
{value: 'MRPL57P3', id: 'MRPL57P3'},
{value: 'MRPL57P6', id: 'MRPL57P6'},
{value: 'MRPL57P7', id: 'MRPL57P7'},
{value: 'MRPL57P8', id: 'MRPL57P8'},
{value: 'MRPL58', id: 'MRPL58'},
{value: 'MRPL9', id: 'MRPL9'},
{value: 'MRPL9P1', id: 'MRPL9P1'},
{value: 'MRPS10', id: 'MRPS10'},
{value: 'MRPS11', id: 'MRPS11'},
{value: 'MRPS11P1', id: 'MRPS11P1'},
{value: 'MRPS12', id: 'MRPS12'},
{value: 'MRPS14', id: 'MRPS14'},
{value: 'MRPS15', id: 'MRPS15'},
{value: 'MRPS15P1', id: 'MRPS15P1'},
{value: 'MRPS16', id: 'MRPS16'},
{value: 'MRPS16P1', id: 'MRPS16P1'},
{value: 'MRPS16P2', id: 'MRPS16P2'},
{value: 'MRPS16P3', id: 'MRPS16P3'},
{value: 'MRPS17', id: 'MRPS17'},
{value: 'MRPS17P1', id: 'MRPS17P1'},
{value: 'MRPS17P3', id: 'MRPS17P3'},
{value: 'MRPS18A', id: 'MRPS18A'},
{value: 'MRPS18AP1', id: 'MRPS18AP1'},
{value: 'MRPS18B', id: 'MRPS18B'},
{value: 'MRPS18BP2', id: 'MRPS18BP2'},
{value: 'MRPS18C', id: 'MRPS18C'},
{value: 'MRPS18CP4', id: 'MRPS18CP4'},
{value: 'MRPS18CP6', id: 'MRPS18CP6'},
{value: 'MRPS2', id: 'MRPS2'},
{value: 'MRPS21', id: 'MRPS21'},
{value: 'MRPS21P1', id: 'MRPS21P1'},
{value: 'MRPS21P2', id: 'MRPS21P2'},
{value: 'MRPS21P3', id: 'MRPS21P3'},
{value: 'MRPS21P5', id: 'MRPS21P5'},
{value: 'MRPS21P6', id: 'MRPS21P6'},
{value: 'MRPS21P8', id: 'MRPS21P8'},
{value: 'MRPS21P9', id: 'MRPS21P9'},
{value: 'MRPS22', id: 'MRPS22'},
{value: 'MRPS23', id: 'MRPS23'},
{value: 'MRPS24', id: 'MRPS24'},
{value: 'MRPS25', id: 'MRPS25'},
{value: 'MRPS26', id: 'MRPS26'},
{value: 'MRPS27', id: 'MRPS27'},
{value: 'MRPS28', id: 'MRPS28'},
{value: 'MRPS30', id: 'MRPS30'},
{value: 'MRPS30-DT', id: 'MRPS30-DT'},
{value: 'MRPS31', id: 'MRPS31'},
{value: 'MRPS31P1', id: 'MRPS31P1'},
{value: 'MRPS31P2', id: 'MRPS31P2'},
{value: 'MRPS31P4', id: 'MRPS31P4'},
{value: 'MRPS31P5', id: 'MRPS31P5'},
{value: 'MRPS33', id: 'MRPS33'},
{value: 'MRPS33P2', id: 'MRPS33P2'},
{value: 'MRPS33P3', id: 'MRPS33P3'},
{value: 'MRPS34', id: 'MRPS34'},
{value: 'MRPS35', id: 'MRPS35'},
{value: 'MRPS35-DT', id: 'MRPS35-DT'},
{value: 'MRPS35P2', id: 'MRPS35P2'},
{value: 'MRPS35P3', id: 'MRPS35P3'},
{value: 'MRPS36', id: 'MRPS36'},
{value: 'MRPS36P1', id: 'MRPS36P1'},
{value: 'MRPS36P2', id: 'MRPS36P2'},
{value: 'MRPS36P5', id: 'MRPS36P5'},
{value: 'MRPS5', id: 'MRPS5'},
{value: 'MRPS5P3', id: 'MRPS5P3'},
{value: 'MRPS5P4', id: 'MRPS5P4'},
{value: 'MRPS6', id: 'MRPS6'},
{value: 'MRPS6P2', id: 'MRPS6P2'},
{value: 'MRPS6P4', id: 'MRPS6P4'},
{value: 'MRPS7', id: 'MRPS7'},
{value: 'MRPS9', id: 'MRPS9'},
{value: 'MRPS9-AS1', id: 'MRPS9-AS1'},
{value: 'MRPS9-AS2', id: 'MRPS9-AS2'},
{value: 'MRRF', id: 'MRRF'},
{value: 'MRS2', id: 'MRS2'},
{value: 'MRS2P1', id: 'MRS2P1'},
{value: 'MRS2P2', id: 'MRS2P2'},
{value: 'MRTFA', id: 'MRTFA'},
{value: 'MRTFA-AS1', id: 'MRTFA-AS1'},
{value: 'MRTFB', id: 'MRTFB'},
{value: 'MRTO4', id: 'MRTO4'},
{value: 'MS4A1', id: 'MS4A1'},
{value: 'MS4A10', id: 'MS4A10'},
{value: 'MS4A12', id: 'MS4A12'},
{value: 'MS4A13', id: 'MS4A13'},
{value: 'MS4A14', id: 'MS4A14'},
{value: 'MS4A15', id: 'MS4A15'},
{value: 'MS4A18', id: 'MS4A18'},
{value: 'MS4A19P', id: 'MS4A19P'},
{value: 'MS4A2', id: 'MS4A2'},
{value: 'MS4A3', id: 'MS4A3'},
{value: 'MS4A4A', id: 'MS4A4A'},
{value: 'MS4A4E', id: 'MS4A4E'},
{value: 'MS4A5', id: 'MS4A5'},
{value: 'MS4A6A', id: 'MS4A6A'},
{value: 'MS4A6E', id: 'MS4A6E'},
{value: 'MS4A7', id: 'MS4A7'},
{value: 'MS4A8', id: 'MS4A8'},
{value: 'MSANTD1', id: 'MSANTD1'},
{value: 'MSANTD2', id: 'MSANTD2'},
{value: 'MSANTD2P1', id: 'MSANTD2P1'},
{value: 'MSANTD3', id: 'MSANTD3'},
{value: 'MSANTD3-TMEFF1', id: 'MSANTD3-TMEFF1'},
{value: 'MSANTD4', id: 'MSANTD4'},
{value: 'MSC', id: 'MSC'},
{value: 'MSC-AS1', id: 'MSC-AS1'},
{value: 'MSGN1', id: 'MSGN1'},
{value: 'MSH2', id: 'MSH2'},
{value: 'MSH3', id: 'MSH3'},
{value: 'MSH4', id: 'MSH4'},
{value: 'MSH5', id: 'MSH5'},
{value: 'MSH5-SAPCD1', id: 'MSH5-SAPCD1'},
{value: 'MSH6', id: 'MSH6'},
{value: 'MSI1', id: 'MSI1'},
{value: 'MSI2', id: 'MSI2'},
{value: 'MSL1', id: 'MSL1'},
{value: 'MSL2', id: 'MSL2'},
{value: 'MSL3', id: 'MSL3'},
{value: 'MSL3P1', id: 'MSL3P1'},
{value: 'MSLN', id: 'MSLN'},
{value: 'MSLNL', id: 'MSLNL'},
{value: 'MSMB', id: 'MSMB'},
{value: 'MSMO1', id: 'MSMO1'},
{value: 'MSMP', id: 'MSMP'},
{value: 'MSN', id: 'MSN'},
{value: 'MSNP1', id: 'MSNP1'},
{value: 'MSR1', id: 'MSR1'},
{value: 'MSRA', id: 'MSRA'},
{value: 'MSRA-DT', id: 'MSRA-DT'},
{value: 'MSRB1', id: 'MSRB1'},
{value: 'MSRB1P1', id: 'MSRB1P1'},
{value: 'MSRB2', id: 'MSRB2'},
{value: 'MSRB3', id: 'MSRB3'},
{value: 'MSS51', id: 'MSS51'},
{value: 'MST1', id: 'MST1'},
{value: 'MST1L', id: 'MST1L'},
{value: 'MST1P2', id: 'MST1P2'},
{value: 'MST1R', id: 'MST1R'},
{value: 'MSTN', id: 'MSTN'},
{value: 'MSTO1', id: 'MSTO1'},
{value: 'MSTO2P', id: 'MSTO2P'},
{value: 'MSX1', id: 'MSX1'},
{value: 'MSX2', id: 'MSX2'},
{value: 'MSX2P1', id: 'MSX2P1'},
{value: 'MT-ATP6', id: 'MT-ATP6'},
{value: 'MT-ATP8', id: 'MT-ATP8'},
{value: 'MT-CO1', id: 'MT-CO1'},
{value: 'MT-CO2', id: 'MT-CO2'},
{value: 'MT-CO3', id: 'MT-CO3'},
{value: 'MT-CYB', id: 'MT-CYB'},
{value: 'MT-ND1', id: 'MT-ND1'},
{value: 'MT-ND2', id: 'MT-ND2'},
{value: 'MT-ND3', id: 'MT-ND3'},
{value: 'MT-ND4', id: 'MT-ND4'},
{value: 'MT-ND4L', id: 'MT-ND4L'},
{value: 'MT-ND5', id: 'MT-ND5'},
{value: 'MT-ND6', id: 'MT-ND6'},
{value: 'MT-RNR1', id: 'MT-RNR1'},
{value: 'MT-RNR2', id: 'MT-RNR2'},
{value: 'MT-TA', id: 'MT-TA'},
{value: 'MT-TC', id: 'MT-TC'},
{value: 'MT-TE', id: 'MT-TE'},
{value: 'MT-TF', id: 'MT-TF'},
{value: 'MT-TL1', id: 'MT-TL1'},
{value: 'MT-TM', id: 'MT-TM'},
{value: 'MT-TN', id: 'MT-TN'},
{value: 'MT-TQ', id: 'MT-TQ'},
{value: 'MT-TR', id: 'MT-TR'},
{value: 'MT-TS1', id: 'MT-TS1'},
{value: 'MT-TV', id: 'MT-TV'},
{value: 'MT-TY', id: 'MT-TY'},
{value: 'MT1A', id: 'MT1A'},
{value: 'MT1B', id: 'MT1B'},
{value: 'MT1CP', id: 'MT1CP'},
{value: 'MT1DP', id: 'MT1DP'},
{value: 'MT1E', id: 'MT1E'},
{value: 'MT1F', id: 'MT1F'},
{value: 'MT1G', id: 'MT1G'},
{value: 'MT1H', id: 'MT1H'},
{value: 'MT1HL1', id: 'MT1HL1'},
{value: 'MT1IP', id: 'MT1IP'},
{value: 'MT1JP', id: 'MT1JP'},
{value: 'MT1L', id: 'MT1L'},
{value: 'MT1M', id: 'MT1M'},
{value: 'MT1P1', id: 'MT1P1'},
{value: 'MT1X', id: 'MT1X'},
{value: 'MT1XP1', id: 'MT1XP1'},
{value: 'MT2A', id: 'MT2A'},
{value: 'MT2P1', id: 'MT2P1'},
{value: 'MT3', id: 'MT3'},
{value: 'MT4', id: 'MT4'},
{value: 'MTA1', id: 'MTA1'},
{value: 'MTA2', id: 'MTA2'},
{value: 'MTA3', id: 'MTA3'},
{value: 'MTAP', id: 'MTAP'},
{value: 'MTAPP1', id: 'MTAPP1'},
{value: 'MTAPP2', id: 'MTAPP2'},
{value: 'MTARC1', id: 'MTARC1'},
{value: 'MTARC2', id: 'MTARC2'},
{value: 'MTATP6P1', id: 'MTATP6P1'},
{value: 'MTATP6P10', id: 'MTATP6P10'},
{value: 'MTATP6P11', id: 'MTATP6P11'},
{value: 'MTATP6P13', id: 'MTATP6P13'},
{value: 'MTATP6P14', id: 'MTATP6P14'},
{value: 'MTATP6P17', id: 'MTATP6P17'},
{value: 'MTATP6P2', id: 'MTATP6P2'},
{value: 'MTATP6P20', id: 'MTATP6P20'},
{value: 'MTATP6P21', id: 'MTATP6P21'},
{value: 'MTATP6P23', id: 'MTATP6P23'},
{value: 'MTATP6P24', id: 'MTATP6P24'},
{value: 'MTATP6P25', id: 'MTATP6P25'},
{value: 'MTATP6P26', id: 'MTATP6P26'},
{value: 'MTATP6P3', id: 'MTATP6P3'},
{value: 'MTATP6P30', id: 'MTATP6P30'},
{value: 'MTATP6P4', id: 'MTATP6P4'},
{value: 'MTATP6P5', id: 'MTATP6P5'},
{value: 'MTATP8P1', id: 'MTATP8P1'},
{value: 'MTATP8P2', id: 'MTATP8P2'},
{value: 'MTBP', id: 'MTBP'},
{value: 'MTCH1', id: 'MTCH1'},
{value: 'MTCH2', id: 'MTCH2'},
{value: 'MTCL1', id: 'MTCL1'},
{value: 'MTCL1P1', id: 'MTCL1P1'},
{value: 'MTCO1P10', id: 'MTCO1P10'},
{value: 'MTCO1P11', id: 'MTCO1P11'},
{value: 'MTCO1P12', id: 'MTCO1P12'},
{value: 'MTCO1P14', id: 'MTCO1P14'},
{value: 'MTCO1P17', id: 'MTCO1P17'},
{value: 'MTCO1P2', id: 'MTCO1P2'},
{value: 'MTCO1P20', id: 'MTCO1P20'},
{value: 'MTCO1P22', id: 'MTCO1P22'},
{value: 'MTCO1P23', id: 'MTCO1P23'},
{value: 'MTCO1P24', id: 'MTCO1P24'},
{value: 'MTCO1P29', id: 'MTCO1P29'},
{value: 'MTCO1P3', id: 'MTCO1P3'},
{value: 'MTCO1P30', id: 'MTCO1P30'},
{value: 'MTCO1P38', id: 'MTCO1P38'},
{value: 'MTCO1P40', id: 'MTCO1P40'},
{value: 'MTCO1P42', id: 'MTCO1P42'},
{value: 'MTCO1P43', id: 'MTCO1P43'},
{value: 'MTCO1P44', id: 'MTCO1P44'},
{value: 'MTCO1P45', id: 'MTCO1P45'},
{value: 'MTCO1P47', id: 'MTCO1P47'},
{value: 'MTCO1P49', id: 'MTCO1P49'},
{value: 'MTCO1P51', id: 'MTCO1P51'},
{value: 'MTCO1P54', id: 'MTCO1P54'},
{value: 'MTCO1P55', id: 'MTCO1P55'},
{value: 'MTCO1P56', id: 'MTCO1P56'},
{value: 'MTCO1P57', id: 'MTCO1P57'},
{value: 'MTCO1P6', id: 'MTCO1P6'},
{value: 'MTCO1P9', id: 'MTCO1P9'},
{value: 'MTCO2P1', id: 'MTCO2P1'},
{value: 'MTCO2P11', id: 'MTCO2P11'},
{value: 'MTCO2P12', id: 'MTCO2P12'},
{value: 'MTCO2P15', id: 'MTCO2P15'},
{value: 'MTCO2P18', id: 'MTCO2P18'},
{value: 'MTCO2P2', id: 'MTCO2P2'},
{value: 'MTCO2P22', id: 'MTCO2P22'},
{value: 'MTCO2P24', id: 'MTCO2P24'},
{value: 'MTCO2P29', id: 'MTCO2P29'},
{value: 'MTCO2P3', id: 'MTCO2P3'},
{value: 'MTCO2P31', id: 'MTCO2P31'},
{value: 'MTCO2P4', id: 'MTCO2P4'},
{value: 'MTCO2P5', id: 'MTCO2P5'},
{value: 'MTCO2P6', id: 'MTCO2P6'},
{value: 'MTCO2P9', id: 'MTCO2P9'},
{value: 'MTCO3P1', id: 'MTCO3P1'},
{value: 'MTCO3P11', id: 'MTCO3P11'},
{value: 'MTCO3P12', id: 'MTCO3P12'},
{value: 'MTCO3P13', id: 'MTCO3P13'},
{value: 'MTCO3P15', id: 'MTCO3P15'},
{value: 'MTCO3P21', id: 'MTCO3P21'},
{value: 'MTCO3P22', id: 'MTCO3P22'},
{value: 'MTCO3P23', id: 'MTCO3P23'},
{value: 'MTCO3P24', id: 'MTCO3P24'},
{value: 'MTCO3P27', id: 'MTCO3P27'},
{value: 'MTCO3P28', id: 'MTCO3P28'},
{value: 'MTCO3P30', id: 'MTCO3P30'},
{value: 'MTCO3P31', id: 'MTCO3P31'},
{value: 'MTCO3P35', id: 'MTCO3P35'},
{value: 'MTCO3P38', id: 'MTCO3P38'},
{value: 'MTCO3P40', id: 'MTCO3P40'},
{value: 'MTCO3P41', id: 'MTCO3P41'},
{value: 'MTCO3P43', id: 'MTCO3P43'},
{value: 'MTCO3P44', id: 'MTCO3P44'},
{value: 'MTCO3P46', id: 'MTCO3P46'},
{value: 'MTCO3P5', id: 'MTCO3P5'},
{value: 'MTCO3P7', id: 'MTCO3P7'},
{value: 'MTCO3P9', id: 'MTCO3P9'},
{value: 'MTCP1', id: 'MTCP1'},
{value: 'MTCYBP10', id: 'MTCYBP10'},
{value: 'MTCYBP11', id: 'MTCYBP11'},
{value: 'MTCYBP13', id: 'MTCYBP13'},
{value: 'MTCYBP14', id: 'MTCYBP14'},
{value: 'MTCYBP15', id: 'MTCYBP15'},
{value: 'MTCYBP16', id: 'MTCYBP16'},
{value: 'MTCYBP17', id: 'MTCYBP17'},
{value: 'MTCYBP18', id: 'MTCYBP18'},
{value: 'MTCYBP19', id: 'MTCYBP19'},
{value: 'MTCYBP21', id: 'MTCYBP21'},
{value: 'MTCYBP22', id: 'MTCYBP22'},
{value: 'MTCYBP23', id: 'MTCYBP23'},
{value: 'MTCYBP27', id: 'MTCYBP27'},
{value: 'MTCYBP3', id: 'MTCYBP3'},
{value: 'MTCYBP33', id: 'MTCYBP33'},
{value: 'MTCYBP34', id: 'MTCYBP34'},
{value: 'MTCYBP35', id: 'MTCYBP35'},
{value: 'MTCYBP36', id: 'MTCYBP36'},
{value: 'MTCYBP37', id: 'MTCYBP37'},
{value: 'MTCYBP39', id: 'MTCYBP39'},
{value: 'MTCYBP4', id: 'MTCYBP4'},
{value: 'MTCYBP40', id: 'MTCYBP40'},
{value: 'MTCYBP41', id: 'MTCYBP41'},
{value: 'MTCYBP42', id: 'MTCYBP42'},
{value: 'MTCYBP44', id: 'MTCYBP44'},
{value: 'MTCYBP45', id: 'MTCYBP45'},
{value: 'MTCYBP7', id: 'MTCYBP7'},
{value: 'MTCYBP8', id: 'MTCYBP8'},
{value: 'MTCYBP9', id: 'MTCYBP9'},
{value: 'MTDH', id: 'MTDH'},
{value: 'MTDHP1', id: 'MTDHP1'},
{value: 'MTDHP2', id: 'MTDHP2'},
{value: 'MTDHP3', id: 'MTDHP3'},
{value: 'MTDHP4', id: 'MTDHP4'},
{value: 'MTDHP5', id: 'MTDHP5'},
{value: 'MTERF1', id: 'MTERF1'},
{value: 'MTERF2', id: 'MTERF2'},
{value: 'MTERF3', id: 'MTERF3'},
{value: 'MTERF4', id: 'MTERF4'},
{value: 'MTF1', id: 'MTF1'},
{value: 'MTF2', id: 'MTF2'},
{value: 'MTFMT', id: 'MTFMT'},
{value: 'MTFP1', id: 'MTFP1'},
{value: 'MTFR1', id: 'MTFR1'},
{value: 'MTFR1L', id: 'MTFR1L'},
{value: 'MTFR2', id: 'MTFR2'},
{value: 'MTFR2P2', id: 'MTFR2P2'},
{value: 'MTG1', id: 'MTG1'},
{value: 'MTG2', id: 'MTG2'},
{value: 'MTHFD1', id: 'MTHFD1'},
{value: 'MTHFD1L', id: 'MTHFD1L'},
{value: 'MTHFD2', id: 'MTHFD2'},
{value: 'MTHFD2L', id: 'MTHFD2L'},
{value: 'MTHFD2P1', id: 'MTHFD2P1'},
{value: 'MTHFD2P2', id: 'MTHFD2P2'},
{value: 'MTHFD2P3', id: 'MTHFD2P3'},
{value: 'MTHFD2P4', id: 'MTHFD2P4'},
{value: 'MTHFD2P5', id: 'MTHFD2P5'},
{value: 'MTHFD2P6', id: 'MTHFD2P6'},
{value: 'MTHFD2P7', id: 'MTHFD2P7'},
{value: 'MTHFR', id: 'MTHFR'},
{value: 'MTHFS', id: 'MTHFS'},
{value: 'MTHFSD', id: 'MTHFSD'},
{value: 'MTIF2', id: 'MTIF2'},
{value: 'MTIF2P1', id: 'MTIF2P1'},
{value: 'MTIF3', id: 'MTIF3'},
{value: 'MTL3P', id: 'MTL3P'},
{value: 'MTLN', id: 'MTLN'},
{value: 'MTM1', id: 'MTM1'},
{value: 'MTMR1', id: 'MTMR1'},
{value: 'MTMR10', id: 'MTMR10'},
{value: 'MTMR11', id: 'MTMR11'},
{value: 'MTMR12', id: 'MTMR12'},
{value: 'MTMR14', id: 'MTMR14'},
{value: 'MTMR2', id: 'MTMR2'},
{value: 'MTMR3', id: 'MTMR3'},
{value: 'MTMR4', id: 'MTMR4'},
{value: 'MTMR6', id: 'MTMR6'},
{value: 'MTMR7', id: 'MTMR7'},
{value: 'MTMR8', id: 'MTMR8'},
{value: 'MTMR9', id: 'MTMR9'},
{value: 'MTMR9LP', id: 'MTMR9LP'},
{value: 'MTND1P10', id: 'MTND1P10'},
{value: 'MTND1P11', id: 'MTND1P11'},
{value: 'MTND1P18', id: 'MTND1P18'},
{value: 'MTND1P20', id: 'MTND1P20'},
{value: 'MTND1P21', id: 'MTND1P21'},
{value: 'MTND1P22', id: 'MTND1P22'},
{value: 'MTND1P23', id: 'MTND1P23'},
{value: 'MTND1P24', id: 'MTND1P24'},
{value: 'MTND1P26', id: 'MTND1P26'},
{value: 'MTND1P27', id: 'MTND1P27'},
{value: 'MTND1P28', id: 'MTND1P28'},
{value: 'MTND1P29', id: 'MTND1P29'},
{value: 'MTND1P35', id: 'MTND1P35'},
{value: 'MTND1P4', id: 'MTND1P4'},
{value: 'MTND1P5', id: 'MTND1P5'},
{value: 'MTND2P11', id: 'MTND2P11'},
{value: 'MTND2P17', id: 'MTND2P17'},
{value: 'MTND2P19', id: 'MTND2P19'},
{value: 'MTND2P20', id: 'MTND2P20'},
{value: 'MTND2P28', id: 'MTND2P28'},
{value: 'MTND2P30', id: 'MTND2P30'},
{value: 'MTND2P31', id: 'MTND2P31'},
{value: 'MTND2P33', id: 'MTND2P33'},
{value: 'MTND2P6', id: 'MTND2P6'},
{value: 'MTND2P7', id: 'MTND2P7'},
{value: 'MTND2P8', id: 'MTND2P8'},
{value: 'MTND2P9', id: 'MTND2P9'},
{value: 'MTND3P1', id: 'MTND3P1'},
{value: 'MTND3P12', id: 'MTND3P12'},
{value: 'MTND3P15', id: 'MTND3P15'},
{value: 'MTND3P19', id: 'MTND3P19'},
{value: 'MTND3P22', id: 'MTND3P22'},
{value: 'MTND3P4', id: 'MTND3P4'},
{value: 'MTND3P9', id: 'MTND3P9'},
{value: 'MTND4LP12', id: 'MTND4LP12'},
{value: 'MTND4LP18', id: 'MTND4LP18'},
{value: 'MTND4LP19', id: 'MTND4LP19'},
{value: 'MTND4LP25', id: 'MTND4LP25'},
{value: 'MTND4LP26', id: 'MTND4LP26'},
{value: 'MTND4LP30', id: 'MTND4LP30'},
{value: 'MTND4P1', id: 'MTND4P1'},
{value: 'MTND4P12', id: 'MTND4P12'},
{value: 'MTND4P13', id: 'MTND4P13'},
{value: 'MTND4P14', id: 'MTND4P14'},
{value: 'MTND4P17', id: 'MTND4P17'},
{value: 'MTND4P21', id: 'MTND4P21'},
{value: 'MTND4P22', id: 'MTND4P22'},
{value: 'MTND4P23', id: 'MTND4P23'},
{value: 'MTND4P25', id: 'MTND4P25'},
{value: 'MTND4P26', id: 'MTND4P26'},
{value: 'MTND4P28', id: 'MTND4P28'},
{value: 'MTND4P33', id: 'MTND4P33'},
{value: 'MTND4P35', id: 'MTND4P35'},
{value: 'MTND4P4', id: 'MTND4P4'},
{value: 'MTND4P5', id: 'MTND4P5'},
{value: 'MTND4P6', id: 'MTND4P6'},
{value: 'MTND4P8', id: 'MTND4P8'},
{value: 'MTND4P9', id: 'MTND4P9'},
{value: 'MTND5P1', id: 'MTND5P1'},
{value: 'MTND5P10', id: 'MTND5P10'},
{value: 'MTND5P11', id: 'MTND5P11'},
{value: 'MTND5P12', id: 'MTND5P12'},
{value: 'MTND5P13', id: 'MTND5P13'},
{value: 'MTND5P14', id: 'MTND5P14'},
{value: 'MTND5P15', id: 'MTND5P15'},
{value: 'MTND5P17', id: 'MTND5P17'},
{value: 'MTND5P18', id: 'MTND5P18'},
{value: 'MTND5P2', id: 'MTND5P2'},
{value: 'MTND5P21', id: 'MTND5P21'},
{value: 'MTND5P23', id: 'MTND5P23'},
{value: 'MTND5P24', id: 'MTND5P24'},
{value: 'MTND5P27', id: 'MTND5P27'},
{value: 'MTND5P28', id: 'MTND5P28'},
{value: 'MTND5P3', id: 'MTND5P3'},
{value: 'MTND5P32', id: 'MTND5P32'},
{value: 'MTND5P34', id: 'MTND5P34'},
{value: 'MTND5P4', id: 'MTND5P4'},
{value: 'MTND5P40', id: 'MTND5P40'},
{value: 'MTND5P41', id: 'MTND5P41'},
{value: 'MTND5P42', id: 'MTND5P42'},
{value: 'MTND5P6', id: 'MTND5P6'},
{value: 'MTND6P11', id: 'MTND6P11'},
{value: 'MTND6P14', id: 'MTND6P14'},
{value: 'MTND6P19', id: 'MTND6P19'},
{value: 'MTND6P2', id: 'MTND6P2'},
{value: 'MTND6P22', id: 'MTND6P22'},
{value: 'MTND6P25', id: 'MTND6P25'},
{value: 'MTND6P3', id: 'MTND6P3'},
{value: 'MTND6P35', id: 'MTND6P35'},
{value: 'MTND6P4', id: 'MTND6P4'},
{value: 'MTND6P5', id: 'MTND6P5'},
{value: 'MTNR1A', id: 'MTNR1A'},
{value: 'MTNR1B', id: 'MTNR1B'},
{value: 'MTO1', id: 'MTO1'},
{value: 'MTOR', id: 'MTOR'},
{value: 'MTOR-AS1', id: 'MTOR-AS1'},
{value: 'MTPAP', id: 'MTPAP'},
{value: 'MTPN', id: 'MTPN'},
{value: 'MTR', id: 'MTR'},
{value: 'MTRES1', id: 'MTRES1'},
{value: 'MTRES1P1', id: 'MTRES1P1'},
{value: 'MTREX', id: 'MTREX'},
{value: 'MTRF1', id: 'MTRF1'},
{value: 'MTRF1L', id: 'MTRF1L'},
{value: 'MTRF1LP1', id: 'MTRF1LP1'},
{value: 'MTRF1LP2', id: 'MTRF1LP2'},
{value: 'MTRFR', id: 'MTRFR'},
{value: 'MTRNR2L1', id: 'MTRNR2L1'},
{value: 'MTRNR2L11', id: 'MTRNR2L11'},
{value: 'MTRNR2L12', id: 'MTRNR2L12'},
{value: 'MTRNR2L13', id: 'MTRNR2L13'},
{value: 'MTRNR2L5', id: 'MTRNR2L5'},
{value: 'MTRNR2L6', id: 'MTRNR2L6'},
{value: 'MTRNR2L7', id: 'MTRNR2L7'},
{value: 'MTRNR2L8', id: 'MTRNR2L8'},
{value: 'MTRNR2L9', id: 'MTRNR2L9'},
{value: 'MTRR', id: 'MTRR'},
{value: 'MTSS1', id: 'MTSS1'},
{value: 'MTSS2', id: 'MTSS2'},
{value: 'MTTP', id: 'MTTP'},
{value: 'MTURN', id: 'MTURN'},
{value: 'MTUS1', id: 'MTUS1'},
{value: 'MTUS1-DT', id: 'MTUS1-DT'},
{value: 'MTUS2', id: 'MTUS2'},
{value: 'MTUS2-AS1', id: 'MTUS2-AS1'},
{value: 'MTX1', id: 'MTX1'},
{value: 'MTX1P1', id: 'MTX1P1'},
{value: 'MTX2', id: 'MTX2'},
{value: 'MTX3', id: 'MTX3'},
{value: 'MUC1', id: 'MUC1'},
{value: 'MUC12', id: 'MUC12'},
{value: 'MUC12-AS1', id: 'MUC12-AS1'},
{value: 'MUC13', id: 'MUC13'},
{value: 'MUC15', id: 'MUC15'},
{value: 'MUC16', id: 'MUC16'},
{value: 'MUC17', id: 'MUC17'},
{value: 'MUC19', id: 'MUC19'},
{value: 'MUC2', id: 'MUC2'},
{value: 'MUC20', id: 'MUC20'},
{value: 'MUC20-OT1', id: 'MUC20-OT1'},
{value: 'MUC20P1', id: 'MUC20P1'},
{value: 'MUC21', id: 'MUC21'},
{value: 'MUC22', id: 'MUC22'},
{value: 'MUC3A', id: 'MUC3A'},
{value: 'MUC4', id: 'MUC4'},
{value: 'MUC5AC', id: 'MUC5AC'},
{value: 'MUC5B', id: 'MUC5B'},
{value: 'MUC6', id: 'MUC6'},
{value: 'MUC7', id: 'MUC7'},
{value: 'MUCL1', id: 'MUCL1'},
{value: 'MUCL3', id: 'MUCL3'},
{value: 'MUL1', id: 'MUL1'},
{value: 'MUPP', id: 'MUPP'},
{value: 'MUS81', id: 'MUS81'},
{value: 'MUSK', id: 'MUSK'},
{value: 'MUSTN1', id: 'MUSTN1'},
{value: 'MUTYH', id: 'MUTYH'},
{value: 'MVB12A', id: 'MVB12A'},
{value: 'MVB12B', id: 'MVB12B'},
{value: 'MVD', id: 'MVD'},
{value: 'MVK', id: 'MVK'},
{value: 'MVP', id: 'MVP'},
{value: 'MX1', id: 'MX1'},
{value: 'MX2', id: 'MX2'},
{value: 'MXD1', id: 'MXD1'},
{value: 'MXD3', id: 'MXD3'},
{value: 'MXD4', id: 'MXD4'},
{value: 'MXI1', id: 'MXI1'},
{value: 'MXRA5', id: 'MXRA5'},
{value: 'MXRA5Y', id: 'MXRA5Y'},
{value: 'MXRA7', id: 'MXRA7'},
{value: 'MXRA7P1', id: 'MXRA7P1'},
{value: 'MXRA8', id: 'MXRA8'},
{value: 'MYADM', id: 'MYADM'},
{value: 'MYADM-AS1', id: 'MYADM-AS1'},
{value: 'MYADML', id: 'MYADML'},
{value: 'MYADML2', id: 'MYADML2'},
{value: 'MYB', id: 'MYB'},
{value: 'MYBBP1A', id: 'MYBBP1A'},
{value: 'MYBL1', id: 'MYBL1'},
{value: 'MYBL2', id: 'MYBL2'},
{value: 'MYBPC1', id: 'MYBPC1'},
{value: 'MYBPC2', id: 'MYBPC2'},
{value: 'MYBPC3', id: 'MYBPC3'},
{value: 'MYBPH', id: 'MYBPH'},
{value: 'MYBPHL', id: 'MYBPHL'},
{value: 'MYC', id: 'MYC'},
{value: 'MYCBP', id: 'MYCBP'},
{value: 'MYCBP2', id: 'MYCBP2'},
{value: 'MYCBP2-AS1', id: 'MYCBP2-AS1'},
{value: 'MYCBPAP', id: 'MYCBPAP'},
{value: 'MYCL', id: 'MYCL'},
{value: 'MYCL-AS1', id: 'MYCL-AS1'},
{value: 'MYCN', id: 'MYCN'},
{value: 'MYCNOS', id: 'MYCNOS'},
{value: 'MYCNUT', id: 'MYCNUT'},
{value: 'MYCT1', id: 'MYCT1'},
{value: 'MYD88', id: 'MYD88'},
{value: 'MYDGF', id: 'MYDGF'},
{value: 'MYEF2', id: 'MYEF2'},
{value: 'MYEOV', id: 'MYEOV'},
{value: 'MYF5', id: 'MYF5'},
{value: 'MYF6', id: 'MYF6'},
{value: 'MYG1', id: 'MYG1'},
{value: 'MYH1', id: 'MYH1'},
{value: 'MYH10', id: 'MYH10'},
{value: 'MYH11', id: 'MYH11'},
{value: 'MYH13', id: 'MYH13'},
{value: 'MYH14', id: 'MYH14'},
{value: 'MYH15', id: 'MYH15'},
{value: 'MYH16', id: 'MYH16'},
{value: 'MYH2', id: 'MYH2'},
{value: 'MYH3', id: 'MYH3'},
{value: 'MYH4', id: 'MYH4'},
{value: 'MYH6', id: 'MYH6'},
{value: 'MYH7', id: 'MYH7'},
{value: 'MYH7B', id: 'MYH7B'},
{value: 'MYH8', id: 'MYH8'},
{value: 'MYH9', id: 'MYH9'},
{value: 'MYHAS', id: 'MYHAS'},
{value: 'MYL1', id: 'MYL1'},
{value: 'MYL10', id: 'MYL10'},
{value: 'MYL12-AS1', id: 'MYL12-AS1'},
{value: 'MYL12A', id: 'MYL12A'},
{value: 'MYL12AP1', id: 'MYL12AP1'},
{value: 'MYL12B', id: 'MYL12B'},
{value: 'MYL12BP1', id: 'MYL12BP1'},
{value: 'MYL12BP2', id: 'MYL12BP2'},
{value: 'MYL12BP3', id: 'MYL12BP3'},
{value: 'MYL2', id: 'MYL2'},
{value: 'MYL3', id: 'MYL3'},
{value: 'MYL4', id: 'MYL4'},
{value: 'MYL5', id: 'MYL5'},
{value: 'MYL6', id: 'MYL6'},
{value: 'MYL6B', id: 'MYL6B'},
{value: 'MYL6B-AS1', id: 'MYL6B-AS1'},
{value: 'MYL6P1', id: 'MYL6P1'},
{value: 'MYL6P2', id: 'MYL6P2'},
{value: 'MYL6P3', id: 'MYL6P3'},
{value: 'MYL6P4', id: 'MYL6P4'},
{value: 'MYL6P5', id: 'MYL6P5'},
{value: 'MYL7', id: 'MYL7'},
{value: 'MYL9', id: 'MYL9'},
{value: 'MYLIP', id: 'MYLIP'},
{value: 'MYLK', id: 'MYLK'},
{value: 'MYLK-AS1', id: 'MYLK-AS1'},
{value: 'MYLK2', id: 'MYLK2'},
{value: 'MYLK3', id: 'MYLK3'},
{value: 'MYLK4', id: 'MYLK4'},
{value: 'MYLKP1', id: 'MYLKP1'},
{value: 'MYLPF', id: 'MYLPF'},
{value: 'MYMK', id: 'MYMK'},
{value: 'MYMX', id: 'MYMX'},
{value: 'MYNN', id: 'MYNN'},
{value: 'MYO10', id: 'MYO10'},
{value: 'MYO15A', id: 'MYO15A'},
{value: 'MYO15B', id: 'MYO15B'},
{value: 'MYO16', id: 'MYO16'},
{value: 'MYO16-AS1', id: 'MYO16-AS1'},
{value: 'MYO18A', id: 'MYO18A'},
{value: 'MYO18B', id: 'MYO18B'},
{value: 'MYO19', id: 'MYO19'},
{value: 'MYO1A', id: 'MYO1A'},
{value: 'MYO1B', id: 'MYO1B'},
{value: 'MYO1C', id: 'MYO1C'},
{value: 'MYO1D', id: 'MYO1D'},
{value: 'MYO1E', id: 'MYO1E'},
{value: 'MYO1F', id: 'MYO1F'},
{value: 'MYO1G', id: 'MYO1G'},
{value: 'MYO1H', id: 'MYO1H'},
{value: 'MYO3A', id: 'MYO3A'},
{value: 'MYO3B', id: 'MYO3B'},
{value: 'MYO3B-AS1', id: 'MYO3B-AS1'},
{value: 'MYO5A', id: 'MYO5A'},
{value: 'MYO5B', id: 'MYO5B'},
{value: 'MYO5BP1', id: 'MYO5BP1'},
{value: 'MYO5BP2', id: 'MYO5BP2'},
{value: 'MYO5BP3', id: 'MYO5BP3'},
{value: 'MYO5C', id: 'MYO5C'},
{value: 'MYO6', id: 'MYO6'},
{value: 'MYO7A', id: 'MYO7A'},
{value: 'MYO7B', id: 'MYO7B'},
{value: 'MYO9A', id: 'MYO9A'},
{value: 'MYO9B', id: 'MYO9B'},
{value: 'MYOC', id: 'MYOC'},
{value: 'MYOCD', id: 'MYOCD'},
{value: 'MYOCOS', id: 'MYOCOS'},
{value: 'MYOD1', id: 'MYOD1'},
{value: 'MYOF', id: 'MYOF'},
{value: 'MYOG', id: 'MYOG'},
{value: 'MYOM1', id: 'MYOM1'},
{value: 'MYOM2', id: 'MYOM2'},
{value: 'MYOM3', id: 'MYOM3'},
{value: 'MYOM3-AS1', id: 'MYOM3-AS1'},
{value: 'MYORG', id: 'MYORG'},
{value: 'MYOSLID', id: 'MYOSLID'},
{value: 'MYOT', id: 'MYOT'},
{value: 'MYOZ1', id: 'MYOZ1'},
{value: 'MYOZ2', id: 'MYOZ2'},
{value: 'MYOZ3', id: 'MYOZ3'},
{value: 'MYOZ3-AS1', id: 'MYOZ3-AS1'},
{value: 'MYPN', id: 'MYPN'},
{value: 'MYPOP', id: 'MYPOP'},
{value: 'MYRF', id: 'MYRF'},
{value: 'MYRF-AS1', id: 'MYRF-AS1'},
{value: 'MYRFL', id: 'MYRFL'},
{value: 'MYRIP', id: 'MYRIP'},
{value: 'MYSM1', id: 'MYSM1'},
{value: 'MYT1', id: 'MYT1'},
{value: 'MYT1L', id: 'MYT1L'},
{value: 'MYZAP', id: 'MYZAP'},
{value: 'MZB1', id: 'MZB1'},
{value: 'MZF1', id: 'MZF1'},
{value: 'MZF1-AS1', id: 'MZF1-AS1'},
{value: 'MZT1', id: 'MZT1'},
{value: 'MZT1P2', id: 'MZT1P2'},
{value: 'MZT2A', id: 'MZT2A'},
{value: 'MZT2B', id: 'MZT2B'},
{value: 'N4BP1', id: 'N4BP1'},
{value: 'N4BP2', id: 'N4BP2'},
{value: 'N4BP2L1', id: 'N4BP2L1'},
{value: 'N4BP2L2', id: 'N4BP2L2'},
{value: 'N4BP2L2-IT2', id: 'N4BP2L2-IT2'},
{value: 'N4BP3', id: 'N4BP3'},
{value: 'N6AMT1', id: 'N6AMT1'},
{value: 'NAA10', id: 'NAA10'},
{value: 'NAA11', id: 'NAA11'},
{value: 'NAA15', id: 'NAA15'},
{value: 'NAA16', id: 'NAA16'},
{value: 'NAA20', id: 'NAA20'},
{value: 'NAA25', id: 'NAA25'},
{value: 'NAA30', id: 'NAA30'},
{value: 'NAA35', id: 'NAA35'},
{value: 'NAA38', id: 'NAA38'},
{value: 'NAA40', id: 'NAA40'},
{value: 'NAA50', id: 'NAA50'},
{value: 'NAA60', id: 'NAA60'},
{value: 'NAA80', id: 'NAA80'},
{value: 'NAAA', id: 'NAAA'},
{value: 'NAALAD2', id: 'NAALAD2'},
{value: 'NAALADL1', id: 'NAALADL1'},
{value: 'NAALADL2', id: 'NAALADL2'},
{value: 'NAALADL2-AS1', id: 'NAALADL2-AS1'},
{value: 'NAB1', id: 'NAB1'},
{value: 'NAB2', id: 'NAB2'},
{value: 'NABP1', id: 'NABP1'},
{value: 'NABP1-OT1', id: 'NABP1-OT1'},
{value: 'NABP2', id: 'NABP2'},
{value: 'NACA', id: 'NACA'},
{value: 'NACA2', id: 'NACA2'},
{value: 'NACA3P', id: 'NACA3P'},
{value: 'NACA4P', id: 'NACA4P'},
{value: 'NACAD', id: 'NACAD'},
{value: 'NACAP2', id: 'NACAP2'},
{value: 'NACAP5', id: 'NACAP5'},
{value: 'NACAP6', id: 'NACAP6'},
{value: 'NACAP7', id: 'NACAP7'},
{value: 'NACC1', id: 'NACC1'},
{value: 'NACC2', id: 'NACC2'},
{value: 'NADK', id: 'NADK'},
{value: 'NADK2', id: 'NADK2'},
{value: 'NADSYN1', id: 'NADSYN1'},
{value: 'NAE1', id: 'NAE1'},
{value: 'NAF1', id: 'NAF1'},
{value: 'NAGA', id: 'NAGA'},
{value: 'NAGK', id: 'NAGK'},
{value: 'NAGLU', id: 'NAGLU'},
{value: 'NAGPA', id: 'NAGPA'},
{value: 'NAGS', id: 'NAGS'},
{value: 'NAIF1', id: 'NAIF1'},
{value: 'NAIP', id: 'NAIP'},
{value: 'NAIPP1', id: 'NAIPP1'},
{value: 'NAIPP2', id: 'NAIPP2'},
{value: 'NAIPP3', id: 'NAIPP3'},
{value: 'NAIPP4', id: 'NAIPP4'},
{value: 'NALCN', id: 'NALCN'},
{value: 'NALCN-AS1', id: 'NALCN-AS1'},
{value: 'NALF1', id: 'NALF1'},
{value: 'NALF2', id: 'NALF2'},
{value: 'NALT1', id: 'NALT1'},
{value: 'NAMA', id: 'NAMA'},
{value: 'NAMPT', id: 'NAMPT'},
{value: 'NAMPTP1', id: 'NAMPTP1'},
{value: 'NAMPTP2', id: 'NAMPTP2'},
{value: 'NANOG', id: 'NANOG'},
{value: 'NANOGNB', id: 'NANOGNB'},
{value: 'NANOGNBP1', id: 'NANOGNBP1'},
{value: 'NANOGP1', id: 'NANOGP1'},
{value: 'NANOGP3', id: 'NANOGP3'},
{value: 'NANOGP4', id: 'NANOGP4'},
{value: 'NANOGP6', id: 'NANOGP6'},
{value: 'NANOGP8', id: 'NANOGP8'},
{value: 'NANOS1', id: 'NANOS1'},
{value: 'NANOS2', id: 'NANOS2'},
{value: 'NANOS3', id: 'NANOS3'},
{value: 'NANP', id: 'NANP'},
{value: 'NANS', id: 'NANS'},
{value: 'NAP1L1', id: 'NAP1L1'},
{value: 'NAP1L1P1', id: 'NAP1L1P1'},
{value: 'NAP1L1P3', id: 'NAP1L1P3'},
{value: 'NAP1L2', id: 'NAP1L2'},
{value: 'NAP1L3', id: 'NAP1L3'},
{value: 'NAP1L4', id: 'NAP1L4'},
{value: 'NAP1L4P1', id: 'NAP1L4P1'},
{value: 'NAP1L4P3', id: 'NAP1L4P3'},
{value: 'NAP1L5', id: 'NAP1L5'},
{value: 'NAPA', id: 'NAPA'},
{value: 'NAPA-AS1', id: 'NAPA-AS1'},
{value: 'NAPB', id: 'NAPB'},
{value: 'NAPEPLD', id: 'NAPEPLD'},
{value: 'NAPG', id: 'NAPG'},
{value: 'NAPGP1', id: 'NAPGP1'},
{value: 'NAPGP2', id: 'NAPGP2'},
{value: 'NAPRT', id: 'NAPRT'},
{value: 'NAPSA', id: 'NAPSA'},
{value: 'NAPSB', id: 'NAPSB'},
{value: 'NARF', id: 'NARF'},
{value: 'NARF-AS1', id: 'NARF-AS1'},
{value: 'NARF-AS2', id: 'NARF-AS2'},
{value: 'NARF-IT1', id: 'NARF-IT1'},
{value: 'NARS1', id: 'NARS1'},
{value: 'NARS1P2', id: 'NARS1P2'},
{value: 'NARS2', id: 'NARS2'},
{value: 'NASP', id: 'NASP'},
{value: 'NASPP1', id: 'NASPP1'},
{value: 'NAT1', id: 'NAT1'},
{value: 'NAT10', id: 'NAT10'},
{value: 'NAT14', id: 'NAT14'},
{value: 'NAT16', id: 'NAT16'},
{value: 'NAT2', id: 'NAT2'},
{value: 'NAT8', id: 'NAT8'},
{value: 'NAT8B', id: 'NAT8B'},
{value: 'NAT8L', id: 'NAT8L'},
{value: 'NAT9', id: 'NAT9'},
{value: 'NATD1', id: 'NATD1'},
{value: 'NATP', id: 'NATP'},
{value: 'NAV1', id: 'NAV1'},
{value: 'NAV2', id: 'NAV2'},
{value: 'NAV2-AS1', id: 'NAV2-AS1'},
{value: 'NAV2-AS2', id: 'NAV2-AS2'},
{value: 'NAV2-AS3', id: 'NAV2-AS3'},
{value: 'NAV2-AS6', id: 'NAV2-AS6'},
{value: 'NAV2-IT1', id: 'NAV2-IT1'},
{value: 'NAV3', id: 'NAV3'},
{value: 'NAXD', id: 'NAXD'},
{value: 'NAXE', id: 'NAXE'},
{value: 'NBAS', id: 'NBAS'},
{value: 'NBAT1', id: 'NBAT1'},
{value: 'NBDY', id: 'NBDY'},
{value: 'NBEA', id: 'NBEA'},
{value: 'NBEAL1', id: 'NBEAL1'},
{value: 'NBEAL2', id: 'NBEAL2'},
{value: 'NBEAP1', id: 'NBEAP1'},
{value: 'NBEAP3', id: 'NBEAP3'},
{value: 'NBEAP4', id: 'NBEAP4'},
{value: 'NBEAP6', id: 'NBEAP6'},
{value: 'NBL1', id: 'NBL1'},
{value: 'NBN', id: 'NBN'},
{value: 'NBPF1', id: 'NBPF1'},
{value: 'NBPF10', id: 'NBPF10'},
{value: 'NBPF11', id: 'NBPF11'},
{value: 'NBPF12', id: 'NBPF12'},
{value: 'NBPF13P', id: 'NBPF13P'},
{value: 'NBPF14', id: 'NBPF14'},
{value: 'NBPF15', id: 'NBPF15'},
{value: 'NBPF17P', id: 'NBPF17P'},
{value: 'NBPF19', id: 'NBPF19'},
{value: 'NBPF20', id: 'NBPF20'},
{value: 'NBPF21P', id: 'NBPF21P'},
{value: 'NBPF22P', id: 'NBPF22P'},
{value: 'NBPF25P', id: 'NBPF25P'},
{value: 'NBPF26', id: 'NBPF26'},
{value: 'NBPF2P', id: 'NBPF2P'},
{value: 'NBPF3', id: 'NBPF3'},
{value: 'NBPF4', id: 'NBPF4'},
{value: 'NBPF5P', id: 'NBPF5P'},
{value: 'NBPF6', id: 'NBPF6'},
{value: 'NBPF7P', id: 'NBPF7P'},
{value: 'NBPF8', id: 'NBPF8'},
{value: 'NBPF9', id: 'NBPF9'},
{value: 'NBR1', id: 'NBR1'},
{value: 'NBR2', id: 'NBR2'},
{value: 'NCALD', id: 'NCALD'},
{value: 'NCAM1', id: 'NCAM1'},
{value: 'NCAM1-AS1', id: 'NCAM1-AS1'},
{value: 'NCAM2', id: 'NCAM2'},
{value: 'NCAN', id: 'NCAN'},
{value: 'NCAPD2', id: 'NCAPD2'},
{value: 'NCAPD3', id: 'NCAPD3'},
{value: 'NCAPG', id: 'NCAPG'},
{value: 'NCAPG2', id: 'NCAPG2'},
{value: 'NCAPGP2', id: 'NCAPGP2'},
{value: 'NCAPH', id: 'NCAPH'},
{value: 'NCAPH2', id: 'NCAPH2'},
{value: 'NCBP1', id: 'NCBP1'},
{value: 'NCBP2', id: 'NCBP2'},
{value: 'NCBP2AS2', id: 'NCBP2AS2'},
{value: 'NCBP3', id: 'NCBP3'},
{value: 'NCCRP1', id: 'NCCRP1'},
{value: 'NCDN', id: 'NCDN'},
{value: 'NCEH1', id: 'NCEH1'},
{value: 'NCF1', id: 'NCF1'},
{value: 'NCF1B', id: 'NCF1B'},
{value: 'NCF1C', id: 'NCF1C'},
{value: 'NCF2', id: 'NCF2'},
{value: 'NCF4', id: 'NCF4'},
{value: 'NCF4-AS1', id: 'NCF4-AS1'},
{value: 'NCK1', id: 'NCK1'},
{value: 'NCK1-DT', id: 'NCK1-DT'},
{value: 'NCK2', id: 'NCK2'},
{value: 'NCKAP1', id: 'NCKAP1'},
{value: 'NCKAP1L', id: 'NCKAP1L'},
{value: 'NCKAP5', id: 'NCKAP5'},
{value: 'NCKAP5-AS1', id: 'NCKAP5-AS1'},
{value: 'NCKAP5-AS2', id: 'NCKAP5-AS2'},
{value: 'NCKAP5L', id: 'NCKAP5L'},
{value: 'NCKIPSD', id: 'NCKIPSD'},
{value: 'NCL', id: 'NCL'},
{value: 'NCLN', id: 'NCLN'},
{value: 'NCLP1', id: 'NCLP1'},
{value: 'NCMAP', id: 'NCMAP'},
{value: 'NCMAP-DT', id: 'NCMAP-DT'},
{value: 'NCOA1', id: 'NCOA1'},
{value: 'NCOA2', id: 'NCOA2'},
{value: 'NCOA3', id: 'NCOA3'},
{value: 'NCOA4', id: 'NCOA4'},
{value: 'NCOA4P1', id: 'NCOA4P1'},
{value: 'NCOA4P2', id: 'NCOA4P2'},
{value: 'NCOA4P3', id: 'NCOA4P3'},
{value: 'NCOA4P4', id: 'NCOA4P4'},
{value: 'NCOA5', id: 'NCOA5'},
{value: 'NCOA5LP', id: 'NCOA5LP'},
{value: 'NCOA6', id: 'NCOA6'},
{value: 'NCOA7', id: 'NCOA7'},
{value: 'NCOA7-AS1', id: 'NCOA7-AS1'},
{value: 'NCOR1', id: 'NCOR1'},
{value: 'NCOR1P1', id: 'NCOR1P1'},
{value: 'NCOR1P3', id: 'NCOR1P3'},
{value: 'NCOR1P4', id: 'NCOR1P4'},
{value: 'NCOR2', id: 'NCOR2'},
{value: 'NCR1', id: 'NCR1'},
{value: 'NCR2', id: 'NCR2'},
{value: 'NCR3', id: 'NCR3'},
{value: 'NCR3LG1', id: 'NCR3LG1'},
{value: 'NCRNA00250', id: 'NCRNA00250'},
{value: 'NCS1', id: 'NCS1'},
{value: 'NCSTN', id: 'NCSTN'},
{value: 'NCSTNP1', id: 'NCSTNP1'},
{value: 'NDC1', id: 'NDC1'},
{value: 'NDC80', id: 'NDC80'},
{value: 'NDE1', id: 'NDE1'},
{value: 'NDE1P2', id: 'NDE1P2'},
{value: 'NDEL1', id: 'NDEL1'},
{value: 'NDFIP1', id: 'NDFIP1'},
{value: 'NDFIP1P1', id: 'NDFIP1P1'},
{value: 'NDFIP2', id: 'NDFIP2'},
{value: 'NDFIP2-AS1', id: 'NDFIP2-AS1'},
{value: 'NDN', id: 'NDN'},
{value: 'NDNF', id: 'NDNF'},
{value: 'NDNF-AS1', id: 'NDNF-AS1'},
{value: 'NDOR1', id: 'NDOR1'},
{value: 'NDRG1', id: 'NDRG1'},
{value: 'NDRG2', id: 'NDRG2'},
{value: 'NDRG3', id: 'NDRG3'},
{value: 'NDRG4', id: 'NDRG4'},
{value: 'NDST1', id: 'NDST1'},
{value: 'NDST1-AS1', id: 'NDST1-AS1'},
{value: 'NDST2', id: 'NDST2'},
{value: 'NDST3', id: 'NDST3'},
{value: 'NDST4', id: 'NDST4'},
{value: 'NDUFA1', id: 'NDUFA1'},
{value: 'NDUFA10', id: 'NDUFA10'},
{value: 'NDUFA11', id: 'NDUFA11'},
{value: 'NDUFA12', id: 'NDUFA12'},
{value: 'NDUFA12P1', id: 'NDUFA12P1'},
{value: 'NDUFA13', id: 'NDUFA13'},
{value: 'NDUFA2', id: 'NDUFA2'},
{value: 'NDUFA3', id: 'NDUFA3'},
{value: 'NDUFA3P2', id: 'NDUFA3P2'},
{value: 'NDUFA3P3', id: 'NDUFA3P3'},
{value: 'NDUFA3P4', id: 'NDUFA3P4'},
{value: 'NDUFA3P6', id: 'NDUFA3P6'},
{value: 'NDUFA4', id: 'NDUFA4'},
{value: 'NDUFA4L2', id: 'NDUFA4L2'},
{value: 'NDUFA4P1', id: 'NDUFA4P1'},
{value: 'NDUFA4P2', id: 'NDUFA4P2'},
{value: 'NDUFA5', id: 'NDUFA5'},
{value: 'NDUFA5P1', id: 'NDUFA5P1'},
{value: 'NDUFA5P10', id: 'NDUFA5P10'},
{value: 'NDUFA5P3', id: 'NDUFA5P3'},
{value: 'NDUFA5P4', id: 'NDUFA5P4'},
{value: 'NDUFA5P5', id: 'NDUFA5P5'},
{value: 'NDUFA5P6', id: 'NDUFA5P6'},
{value: 'NDUFA5P8', id: 'NDUFA5P8'},
{value: 'NDUFA5P9', id: 'NDUFA5P9'},
{value: 'NDUFA6', id: 'NDUFA6'},
{value: 'NDUFA6-DT', id: 'NDUFA6-DT'},
{value: 'NDUFA7', id: 'NDUFA7'},
{value: 'NDUFA8', id: 'NDUFA8'},
{value: 'NDUFA8P1', id: 'NDUFA8P1'},
{value: 'NDUFA9', id: 'NDUFA9'},
{value: 'NDUFA9P1', id: 'NDUFA9P1'},
{value: 'NDUFAB1', id: 'NDUFAB1'},
{value: 'NDUFAB1P1', id: 'NDUFAB1P1'},
{value: 'NDUFAF1', id: 'NDUFAF1'},
{value: 'NDUFAF2', id: 'NDUFAF2'},
{value: 'NDUFAF2P1', id: 'NDUFAF2P1'},
{value: 'NDUFAF2P2', id: 'NDUFAF2P2'},
{value: 'NDUFAF3', id: 'NDUFAF3'},
{value: 'NDUFAF4', id: 'NDUFAF4'},
{value: 'NDUFAF5', id: 'NDUFAF5'},
{value: 'NDUFAF6', id: 'NDUFAF6'},
{value: 'NDUFAF7', id: 'NDUFAF7'},
{value: 'NDUFAF8', id: 'NDUFAF8'},
{value: 'NDUFB1', id: 'NDUFB1'},
{value: 'NDUFB10', id: 'NDUFB10'},
{value: 'NDUFB10P1', id: 'NDUFB10P1'},
{value: 'NDUFB10P2', id: 'NDUFB10P2'},
{value: 'NDUFB11', id: 'NDUFB11'},
{value: 'NDUFB11P1', id: 'NDUFB11P1'},
{value: 'NDUFB1P2', id: 'NDUFB1P2'},
{value: 'NDUFB2', id: 'NDUFB2'},
{value: 'NDUFB2-AS1', id: 'NDUFB2-AS1'},
{value: 'NDUFB2P1', id: 'NDUFB2P1'},
{value: 'NDUFB3', id: 'NDUFB3'},
{value: 'NDUFB4', id: 'NDUFB4'},
{value: 'NDUFB4P11', id: 'NDUFB4P11'},
{value: 'NDUFB4P12', id: 'NDUFB4P12'},
{value: 'NDUFB4P2', id: 'NDUFB4P2'},
{value: 'NDUFB4P3', id: 'NDUFB4P3'},
{value: 'NDUFB4P4', id: 'NDUFB4P4'},
{value: 'NDUFB4P5', id: 'NDUFB4P5'},
{value: 'NDUFB4P6', id: 'NDUFB4P6'},
{value: 'NDUFB4P7', id: 'NDUFB4P7'},
{value: 'NDUFB4P8', id: 'NDUFB4P8'},
{value: 'NDUFB5', id: 'NDUFB5'},
{value: 'NDUFB5P1', id: 'NDUFB5P1'},
{value: 'NDUFB6', id: 'NDUFB6'},
{value: 'NDUFB7', id: 'NDUFB7'},
{value: 'NDUFB8', id: 'NDUFB8'},
{value: 'NDUFB8P1', id: 'NDUFB8P1'},
{value: 'NDUFB8P2', id: 'NDUFB8P2'},
{value: 'NDUFB9', id: 'NDUFB9'},
{value: 'NDUFB9P1', id: 'NDUFB9P1'},
{value: 'NDUFB9P2', id: 'NDUFB9P2'},
{value: 'NDUFB9P3', id: 'NDUFB9P3'},
{value: 'NDUFC1', id: 'NDUFC1'},
{value: 'NDUFC2', id: 'NDUFC2'},
{value: 'NDUFC2-KCTD14', id: 'NDUFC2-KCTD14'},
{value: 'NDUFS1', id: 'NDUFS1'},
{value: 'NDUFS2', id: 'NDUFS2'},
{value: 'NDUFS3', id: 'NDUFS3'},
{value: 'NDUFS4', id: 'NDUFS4'},
{value: 'NDUFS5', id: 'NDUFS5'},
{value: 'NDUFS5P1', id: 'NDUFS5P1'},
{value: 'NDUFS5P2', id: 'NDUFS5P2'},
{value: 'NDUFS5P3', id: 'NDUFS5P3'},
{value: 'NDUFS5P5', id: 'NDUFS5P5'},
{value: 'NDUFS5P6', id: 'NDUFS5P6'},
{value: 'NDUFS6', id: 'NDUFS6'},
{value: 'NDUFS6P1', id: 'NDUFS6P1'},
{value: 'NDUFS7', id: 'NDUFS7'},
{value: 'NDUFS8', id: 'NDUFS8'},
{value: 'NDUFV1', id: 'NDUFV1'},
{value: 'NDUFV1-DT', id: 'NDUFV1-DT'},
{value: 'NDUFV2', id: 'NDUFV2'},
{value: 'NDUFV2-AS1', id: 'NDUFV2-AS1'},
{value: 'NDUFV2P1', id: 'NDUFV2P1'},
{value: 'NDUFV3', id: 'NDUFV3'},
{value: 'NEAT1', id: 'NEAT1'},
{value: 'NEB', id: 'NEB'},
{value: 'NEBL', id: 'NEBL'},
{value: 'NEBL-AS1', id: 'NEBL-AS1'},
{value: 'NECAB1', id: 'NECAB1'},
{value: 'NECAB2', id: 'NECAB2'},
{value: 'NECAB3', id: 'NECAB3'},
{value: 'NECAP1', id: 'NECAP1'},
{value: 'NECAP1P1', id: 'NECAP1P1'},
{value: 'NECAP1P2', id: 'NECAP1P2'},
{value: 'NECAP2', id: 'NECAP2'},
{value: 'NECTIN1', id: 'NECTIN1'},
{value: 'NECTIN1-AS1', id: 'NECTIN1-AS1'},
{value: 'NECTIN1-DT', id: 'NECTIN1-DT'},
{value: 'NECTIN2', id: 'NECTIN2'},
{value: 'NECTIN3', id: 'NECTIN3'},
{value: 'NECTIN3-AS1', id: 'NECTIN3-AS1'},
{value: 'NECTIN4', id: 'NECTIN4'},
{value: 'NEDD1', id: 'NEDD1'},
{value: 'NEDD4', id: 'NEDD4'},
{value: 'NEDD4L', id: 'NEDD4L'},
{value: 'NEDD8', id: 'NEDD8'},
{value: 'NEDD8-MDP1', id: 'NEDD8-MDP1'},
{value: 'NEDD8P1', id: 'NEDD8P1'},
{value: 'NEDD9', id: 'NEDD9'},
{value: 'NEFH', id: 'NEFH'},
{value: 'NEFHP1', id: 'NEFHP1'},
{value: 'NEFL', id: 'NEFL'},
{value: 'NEFM', id: 'NEFM'},
{value: 'NEGR1', id: 'NEGR1'},
{value: 'NEIL1', id: 'NEIL1'},
{value: 'NEIL2', id: 'NEIL2'},
{value: 'NEIL3', id: 'NEIL3'},
{value: 'NEK1', id: 'NEK1'},
{value: 'NEK10', id: 'NEK10'},
{value: 'NEK11', id: 'NEK11'},
{value: 'NEK2', id: 'NEK2'},
{value: 'NEK2P1', id: 'NEK2P1'},
{value: 'NEK3', id: 'NEK3'},
{value: 'NEK4', id: 'NEK4'},
{value: 'NEK4P1', id: 'NEK4P1'},
{value: 'NEK4P2', id: 'NEK4P2'},
{value: 'NEK4P3', id: 'NEK4P3'},
{value: 'NEK5', id: 'NEK5'},
{value: 'NEK6', id: 'NEK6'},
{value: 'NEK7', id: 'NEK7'},
{value: 'NEK8', id: 'NEK8'},
{value: 'NEK9', id: 'NEK9'},
{value: 'NELFA', id: 'NELFA'},
{value: 'NELFB', id: 'NELFB'},
{value: 'NELFCD', id: 'NELFCD'},
{value: 'NELFE', id: 'NELFE'},
{value: 'NELL1', id: 'NELL1'},
{value: 'NELL2', id: 'NELL2'},
{value: 'NEMF', id: 'NEMF'},
{value: 'NEMP1', id: 'NEMP1'},
{value: 'NEMP2', id: 'NEMP2'},
{value: 'NENF', id: 'NENF'},
{value: 'NENFP2', id: 'NENFP2'},
{value: 'NENFP3', id: 'NENFP3'},
{value: 'NEO1', id: 'NEO1'},
{value: 'NEPNP', id: 'NEPNP'},
{value: 'NEPRO', id: 'NEPRO'},
{value: 'NES', id: 'NES'},
{value: 'NET1', id: 'NET1'},
{value: 'NETO1', id: 'NETO1'},
{value: 'NETO1-DT', id: 'NETO1-DT'},
{value: 'NETO2', id: 'NETO2'},
{value: 'NEU1', id: 'NEU1'},
{value: 'NEU2', id: 'NEU2'},
{value: 'NEU3', id: 'NEU3'},
{value: 'NEU4', id: 'NEU4'},
{value: 'NEURL1', id: 'NEURL1'},
{value: 'NEURL1-AS1', id: 'NEURL1-AS1'},
{value: 'NEURL1B', id: 'NEURL1B'},
{value: 'NEURL2', id: 'NEURL2'},
{value: 'NEURL3', id: 'NEURL3'},
{value: 'NEURL4', id: 'NEURL4'},
{value: 'NEUROD1', id: 'NEUROD1'},
{value: 'NEUROD2', id: 'NEUROD2'},
{value: 'NEUROD4', id: 'NEUROD4'},
{value: 'NEUROD6', id: 'NEUROD6'},
{value: 'NEUROG1', id: 'NEUROG1'},
{value: 'NEUROG2', id: 'NEUROG2'},
{value: 'NEUROG2-AS1', id: 'NEUROG2-AS1'},
{value: 'NEUROG3', id: 'NEUROG3'},
{value: 'NEXMIF', id: 'NEXMIF'},
{value: 'NEXN', id: 'NEXN'},
{value: 'NEXN-AS1', id: 'NEXN-AS1'},
{value: 'NF1', id: 'NF1'},
{value: 'NF1P1', id: 'NF1P1'},
{value: 'NF1P10', id: 'NF1P10'},
{value: 'NF1P12', id: 'NF1P12'},
{value: 'NF1P2', id: 'NF1P2'},
{value: 'NF1P3', id: 'NF1P3'},
{value: 'NF1P4', id: 'NF1P4'},
{value: 'NF1P5', id: 'NF1P5'},
{value: 'NF1P6', id: 'NF1P6'},
{value: 'NF1P7', id: 'NF1P7'},
{value: 'NF1P9', id: 'NF1P9'},
{value: 'NF2', id: 'NF2'},
{value: 'NFAM1', id: 'NFAM1'},
{value: 'NFASC', id: 'NFASC'},
{value: 'NFAT5', id: 'NFAT5'},
{value: 'NFATC1', id: 'NFATC1'},
{value: 'NFATC2', id: 'NFATC2'},
{value: 'NFATC2IP', id: 'NFATC2IP'},
{value: 'NFATC3', id: 'NFATC3'},
{value: 'NFATC4', id: 'NFATC4'},
{value: 'NFE2', id: 'NFE2'},
{value: 'NFE2L1', id: 'NFE2L1'},
{value: 'NFE2L1-DT', id: 'NFE2L1-DT'},
{value: 'NFE2L2', id: 'NFE2L2'},
{value: 'NFE2L3', id: 'NFE2L3'},
{value: 'NFE2L3P1', id: 'NFE2L3P1'},
{value: 'NFE4', id: 'NFE4'},
{value: 'NFIA', id: 'NFIA'},
{value: 'NFIA-AS1', id: 'NFIA-AS1'},
{value: 'NFIA-AS2', id: 'NFIA-AS2'},
{value: 'NFIB', id: 'NFIB'},
{value: 'NFIC', id: 'NFIC'},
{value: 'NFIL3', id: 'NFIL3'},
{value: 'NFILZ', id: 'NFILZ'},
{value: 'NFIX', id: 'NFIX'},
{value: 'NFKB1', id: 'NFKB1'},
{value: 'NFKB2', id: 'NFKB2'},
{value: 'NFKBIA', id: 'NFKBIA'},
{value: 'NFKBIB', id: 'NFKBIB'},
{value: 'NFKBID', id: 'NFKBID'},
{value: 'NFKBIE', id: 'NFKBIE'},
{value: 'NFKBIL1', id: 'NFKBIL1'},
{value: 'NFKBIZ', id: 'NFKBIZ'},
{value: 'NFRKB', id: 'NFRKB'},
{value: 'NFS1', id: 'NFS1'},
{value: 'NFU1', id: 'NFU1'},
{value: 'NFU1P1', id: 'NFU1P1'},
{value: 'NFU1P2', id: 'NFU1P2'},
{value: 'NFX1', id: 'NFX1'},
{value: 'NFXL1', id: 'NFXL1'},
{value: 'NFYA', id: 'NFYA'},
{value: 'NFYAP1', id: 'NFYAP1'},
{value: 'NFYB', id: 'NFYB'},
{value: 'NFYBP1', id: 'NFYBP1'},
{value: 'NFYC', id: 'NFYC'},
{value: 'NFYC-AS1', id: 'NFYC-AS1'},
{value: 'NFYCP2', id: 'NFYCP2'},
{value: 'NGB', id: 'NGB'},
{value: 'NGDN', id: 'NGDN'},
{value: 'NGEF', id: 'NGEF'},
{value: 'NGF', id: 'NGF'},
{value: 'NGF-AS1', id: 'NGF-AS1'},
{value: 'NGFR', id: 'NGFR'},
{value: 'NGFR-AS1', id: 'NGFR-AS1'},
{value: 'NGLY1', id: 'NGLY1'},
{value: 'NGRN', id: 'NGRN'},
{value: 'NHEG1', id: 'NHEG1'},
{value: 'NHEJ1', id: 'NHEJ1'},
{value: 'NHLH1', id: 'NHLH1'},
{value: 'NHLH2', id: 'NHLH2'},
{value: 'NHLRC1', id: 'NHLRC1'},
{value: 'NHLRC2', id: 'NHLRC2'},
{value: 'NHLRC3', id: 'NHLRC3'},
{value: 'NHLRC4', id: 'NHLRC4'},
{value: 'NHP2', id: 'NHP2'},
{value: 'NHP2P1', id: 'NHP2P1'},
{value: 'NHP2P2', id: 'NHP2P2'},
{value: 'NHS', id: 'NHS'},
{value: 'NHSL1', id: 'NHSL1'},
{value: 'NHSL2', id: 'NHSL2'},
{value: 'NIBAN1', id: 'NIBAN1'},
{value: 'NIBAN2', id: 'NIBAN2'},
{value: 'NIBAN3', id: 'NIBAN3'},
{value: 'NICN1', id: 'NICN1'},
{value: 'NID1', id: 'NID1'},
{value: 'NID2', id: 'NID2'},
{value: 'NIF3L1', id: 'NIF3L1'},
{value: 'NIFK', id: 'NIFK'},
{value: 'NIFK-AS1', id: 'NIFK-AS1'},
{value: 'NIFKP1', id: 'NIFKP1'},
{value: 'NIFKP2', id: 'NIFKP2'},
{value: 'NIFKP3', id: 'NIFKP3'},
{value: 'NIFKP4', id: 'NIFKP4'},
{value: 'NIFKP6', id: 'NIFKP6'},
{value: 'NIFKP8', id: 'NIFKP8'},
{value: 'NIFKP9', id: 'NIFKP9'},
{value: 'NIM1K', id: 'NIM1K'},
{value: 'NIN', id: 'NIN'},
{value: 'NINJ1', id: 'NINJ1'},
{value: 'NINJ2', id: 'NINJ2'},
{value: 'NINJ2-AS1', id: 'NINJ2-AS1'},
{value: 'NINL', id: 'NINL'},
{value: 'NIP7', id: 'NIP7'},
{value: 'NIP7P1', id: 'NIP7P1'},
{value: 'NIP7P2', id: 'NIP7P2'},
{value: 'NIP7P3', id: 'NIP7P3'},
{value: 'NIPA1', id: 'NIPA1'},
{value: 'NIPA2', id: 'NIPA2'},
{value: 'NIPA2P2', id: 'NIPA2P2'},
{value: 'NIPA2P3', id: 'NIPA2P3'},
{value: 'NIPA2P4', id: 'NIPA2P4'},
{value: 'NIPA2P5', id: 'NIPA2P5'},
{value: 'NIPAL1', id: 'NIPAL1'},
{value: 'NIPAL2', id: 'NIPAL2'},
{value: 'NIPAL3', id: 'NIPAL3'},
{value: 'NIPAL4', id: 'NIPAL4'},
{value: 'NIPAL4-DT', id: 'NIPAL4-DT'},
{value: 'NIPBL', id: 'NIPBL'},
{value: 'NIPBL-DT', id: 'NIPBL-DT'},
{value: 'NIPSNAP1', id: 'NIPSNAP1'},
{value: 'NIPSNAP2', id: 'NIPSNAP2'},
{value: 'NIPSNAP3A', id: 'NIPSNAP3A'},
{value: 'NIPSNAP3B', id: 'NIPSNAP3B'},
{value: 'NISCH', id: 'NISCH'},
{value: 'NIT1', id: 'NIT1'},
{value: 'NIT2', id: 'NIT2'},
{value: 'NKAIN1', id: 'NKAIN1'},
{value: 'NKAIN2', id: 'NKAIN2'},
{value: 'NKAIN3', id: 'NKAIN3'},
{value: 'NKAIN4', id: 'NKAIN4'},
{value: 'NKAP', id: 'NKAP'},
{value: 'NKAPD1', id: 'NKAPD1'},
{value: 'NKAPL', id: 'NKAPL'},
{value: 'NKAPP1', id: 'NKAPP1'},
{value: 'NKD1', id: 'NKD1'},
{value: 'NKD2', id: 'NKD2'},
{value: 'NKG7', id: 'NKG7'},
{value: 'NKILA', id: 'NKILA'},
{value: 'NKIRAS1', id: 'NKIRAS1'},
{value: 'NKIRAS2', id: 'NKIRAS2'},
{value: 'NKPD1', id: 'NKPD1'},
{value: 'NKRF', id: 'NKRF'},
{value: 'NKTR', id: 'NKTR'},
{value: 'NKX1-1', id: 'NKX1-1'},
{value: 'NKX1-2', id: 'NKX1-2'},
{value: 'NKX2-1-AS1', id: 'NKX2-1-AS1'},
{value: 'NKX2-2', id: 'NKX2-2'},
{value: 'NKX2-2-AS1', id: 'NKX2-2-AS1'},
{value: 'NKX2-3', id: 'NKX2-3'},
{value: 'NKX2-4', id: 'NKX2-4'},
{value: 'NKX2-5', id: 'NKX2-5'},
{value: 'NKX2-6', id: 'NKX2-6'},
{value: 'NKX2-8', id: 'NKX2-8'},
{value: 'NKX3-1', id: 'NKX3-1'},
{value: 'NKX3-2', id: 'NKX3-2'},
{value: 'NKX6-1', id: 'NKX6-1'},
{value: 'NKX6-2', id: 'NKX6-2'},
{value: 'NKX6-3', id: 'NKX6-3'},
{value: 'NLE1', id: 'NLE1'},
{value: 'NLGN1', id: 'NLGN1'},
{value: 'NLGN2', id: 'NLGN2'},
{value: 'NLGN3', id: 'NLGN3'},
{value: 'NLGN4X', id: 'NLGN4X'},
{value: 'NLGN4Y', id: 'NLGN4Y'},
{value: 'NLK', id: 'NLK'},
{value: 'NLN', id: 'NLN'},
{value: 'NLRC3', id: 'NLRC3'},
{value: 'NLRC4', id: 'NLRC4'},
{value: 'NLRC5', id: 'NLRC5'},
{value: 'NLRP1', id: 'NLRP1'},
{value: 'NLRP10', id: 'NLRP10'},
{value: 'NLRP11', id: 'NLRP11'},
{value: 'NLRP12', id: 'NLRP12'},
{value: 'NLRP13', id: 'NLRP13'},
{value: 'NLRP14', id: 'NLRP14'},
{value: 'NLRP2', id: 'NLRP2'},
{value: 'NLRP3', id: 'NLRP3'},
{value: 'NLRP4', id: 'NLRP4'},
{value: 'NLRP5', id: 'NLRP5'},
{value: 'NLRP6', id: 'NLRP6'},
{value: 'NLRP7', id: 'NLRP7'},
{value: 'NLRP8', id: 'NLRP8'},
{value: 'NLRP9', id: 'NLRP9'},
{value: 'NLRP9P1', id: 'NLRP9P1'},
{value: 'NLRX1', id: 'NLRX1'},
{value: 'NMB', id: 'NMB'},
{value: 'NMBR', id: 'NMBR'},
{value: 'NMBR-AS1', id: 'NMBR-AS1'},
{value: 'NMD3', id: 'NMD3'},
{value: 'NMD3P2', id: 'NMD3P2'},
{value: 'NME1', id: 'NME1'},
{value: 'NME1-NME2', id: 'NME1-NME2'},
{value: 'NME1P1', id: 'NME1P1'},
{value: 'NME2', id: 'NME2'},
{value: 'NME2P1', id: 'NME2P1'},
{value: 'NME2P3', id: 'NME2P3'},
{value: 'NME3', id: 'NME3'},
{value: 'NME4', id: 'NME4'},
{value: 'NME5', id: 'NME5'},
{value: 'NME6', id: 'NME6'},
{value: 'NME7', id: 'NME7'},
{value: 'NME8', id: 'NME8'},
{value: 'NME9', id: 'NME9'},
{value: 'NMI', id: 'NMI'},
{value: 'NMNAT1', id: 'NMNAT1'},
{value: 'NMNAT1P2', id: 'NMNAT1P2'},
{value: 'NMNAT1P3', id: 'NMNAT1P3'},
{value: 'NMNAT1P4', id: 'NMNAT1P4'},
{value: 'NMNAT1P5', id: 'NMNAT1P5'},
{value: 'NMNAT2', id: 'NMNAT2'},
{value: 'NMNAT3', id: 'NMNAT3'},
{value: 'NMRAL1', id: 'NMRAL1'},
{value: 'NMRAL2P', id: 'NMRAL2P'},
{value: 'NMRK1', id: 'NMRK1'},
{value: 'NMRK2', id: 'NMRK2'},
{value: 'NMS', id: 'NMS'},
{value: 'NMT1', id: 'NMT1'},
{value: 'NMT2', id: 'NMT2'},
{value: 'NMU', id: 'NMU'},
{value: 'NMUR1', id: 'NMUR1'},
{value: 'NNAT', id: 'NNAT'},
{value: 'NNMT', id: 'NNMT'},
{value: 'NNT', id: 'NNT'},
{value: 'NNT-AS1', id: 'NNT-AS1'},
{value: 'NOA1', id: 'NOA1'},
{value: 'NOB1', id: 'NOB1'},
{value: 'NOBOX', id: 'NOBOX'},
{value: 'NOC2L', id: 'NOC2L'},
{value: 'NOC2LP1', id: 'NOC2LP1'},
{value: 'NOC2LP2', id: 'NOC2LP2'},
{value: 'NOC3L', id: 'NOC3L'},
{value: 'NOC4L', id: 'NOC4L'},
{value: 'NOCT', id: 'NOCT'},
{value: 'NOD1', id: 'NOD1'},
{value: 'NOD2', id: 'NOD2'},
{value: 'NODAL', id: 'NODAL'},
{value: 'NOG', id: 'NOG'},
{value: 'NOL10', id: 'NOL10'},
{value: 'NOL11', id: 'NOL11'},
{value: 'NOL12', id: 'NOL12'},
{value: 'NOL3', id: 'NOL3'},
{value: 'NOL4', id: 'NOL4'},
{value: 'NOL4L', id: 'NOL4L'},
{value: 'NOL4L-DT', id: 'NOL4L-DT'},
{value: 'NOL6', id: 'NOL6'},
{value: 'NOL7', id: 'NOL7'},
{value: 'NOL8', id: 'NOL8'},
{value: 'NOL8P1', id: 'NOL8P1'},
{value: 'NOL9', id: 'NOL9'},
{value: 'NOLC1', id: 'NOLC1'},
{value: 'NOM1', id: 'NOM1'},
{value: 'NOMO1', id: 'NOMO1'},
{value: 'NOMO2', id: 'NOMO2'},
{value: 'NOMO3', id: 'NOMO3'},
{value: 'NONO', id: 'NONO'},
{value: 'NONOP2', id: 'NONOP2'},
{value: 'NOP10', id: 'NOP10'},
{value: 'NOP14', id: 'NOP14'},
{value: 'NOP14-AS1', id: 'NOP14-AS1'},
{value: 'NOP16', id: 'NOP16'},
{value: 'NOP2', id: 'NOP2'},
{value: 'NOP53', id: 'NOP53'},
{value: 'NOP53-AS1', id: 'NOP53-AS1'},
{value: 'NOP56', id: 'NOP56'},
{value: 'NOP56P1', id: 'NOP56P1'},
{value: 'NOP56P2', id: 'NOP56P2'},
{value: 'NOP56P3', id: 'NOP56P3'},
{value: 'NOP58', id: 'NOP58'},
{value: 'NOP9', id: 'NOP9'},
{value: 'NOPCHAP1', id: 'NOPCHAP1'},
{value: 'NORAD', id: 'NORAD'},
{value: 'NOS1', id: 'NOS1'},
{value: 'NOS1AP', id: 'NOS1AP'},
{value: 'NOS2', id: 'NOS2'},
{value: 'NOS2P1', id: 'NOS2P1'},
{value: 'NOS2P2', id: 'NOS2P2'},
{value: 'NOS2P3', id: 'NOS2P3'},
{value: 'NOS3', id: 'NOS3'},
{value: 'NOSIP', id: 'NOSIP'},
{value: 'NOSTRIN', id: 'NOSTRIN'},
{value: 'NOTCH1', id: 'NOTCH1'},
{value: 'NOTCH2', id: 'NOTCH2'},
{value: 'NOTCH2NLA', id: 'NOTCH2NLA'},
{value: 'NOTCH2NLB', id: 'NOTCH2NLB'},
{value: 'NOTCH2NLC', id: 'NOTCH2NLC'},
{value: 'NOTCH2NLR', id: 'NOTCH2NLR'},
{value: 'NOTCH2P1', id: 'NOTCH2P1'},
{value: 'NOTCH3', id: 'NOTCH3'},
{value: 'NOTCH4', id: 'NOTCH4'},
{value: 'NOTO', id: 'NOTO'},
{value: 'NOTUM', id: 'NOTUM'},
{value: 'NOVA1', id: 'NOVA1'},
{value: 'NOVA1-DT', id: 'NOVA1-DT'},
{value: 'NOVA2', id: 'NOVA2'},
{value: 'NOX3', id: 'NOX3'},
{value: 'NOX4', id: 'NOX4'},
{value: 'NOX5', id: 'NOX5'},
{value: 'NOXA1', id: 'NOXA1'},
{value: 'NOXO1', id: 'NOXO1'},
{value: 'NOXRED1', id: 'NOXRED1'},
{value: 'NPAP1', id: 'NPAP1'},
{value: 'NPAP1P2', id: 'NPAP1P2'},
{value: 'NPAP1P3', id: 'NPAP1P3'},
{value: 'NPAP1P4', id: 'NPAP1P4'},
{value: 'NPAP1P6', id: 'NPAP1P6'},
{value: 'NPAP1P7', id: 'NPAP1P7'},
{value: 'NPAS1', id: 'NPAS1'},
{value: 'NPAS2', id: 'NPAS2'},
{value: 'NPAS2-AS1', id: 'NPAS2-AS1'},
{value: 'NPAS3', id: 'NPAS3'},
{value: 'NPAS4', id: 'NPAS4'},
{value: 'NPAT', id: 'NPAT'},
{value: 'NPB', id: 'NPB'},
{value: 'NPBWR1', id: 'NPBWR1'},
{value: 'NPBWR2', id: 'NPBWR2'},
{value: 'NPC1', id: 'NPC1'},
{value: 'NPC1L1', id: 'NPC1L1'},
{value: 'NPC2', id: 'NPC2'},
{value: 'NPDC1', id: 'NPDC1'},
{value: 'NPEPL1', id: 'NPEPL1'},
{value: 'NPEPPS', id: 'NPEPPS'},
{value: 'NPEPPSP1', id: 'NPEPPSP1'},
{value: 'NPFF', id: 'NPFF'},
{value: 'NPFFR1', id: 'NPFFR1'},
{value: 'NPFFR2', id: 'NPFFR2'},
{value: 'NPHP1', id: 'NPHP1'},
{value: 'NPHP3', id: 'NPHP3'},
{value: 'NPHP3-ACAD11', id: 'NPHP3-ACAD11'},
{value: 'NPHP3-AS1', id: 'NPHP3-AS1'},
{value: 'NPHP4', id: 'NPHP4'},
{value: 'NPHS1', id: 'NPHS1'},
{value: 'NPHS2', id: 'NPHS2'},
{value: 'NPIPA1', id: 'NPIPA1'},
{value: 'NPIPA2', id: 'NPIPA2'},
{value: 'NPIPA3', id: 'NPIPA3'},
{value: 'NPIPA5', id: 'NPIPA5'},
{value: 'NPIPA7', id: 'NPIPA7'},
{value: 'NPIPA8', id: 'NPIPA8'},
{value: 'NPIPA9', id: 'NPIPA9'},
{value: 'NPIPB10P', id: 'NPIPB10P'},
{value: 'NPIPB11', id: 'NPIPB11'},
{value: 'NPIPB12', id: 'NPIPB12'},
{value: 'NPIPB13', id: 'NPIPB13'},
{value: 'NPIPB14P', id: 'NPIPB14P'},
{value: 'NPIPB15', id: 'NPIPB15'},
{value: 'NPIPB1P', id: 'NPIPB1P'},
{value: 'NPIPB2', id: 'NPIPB2'},
{value: 'NPIPB3', id: 'NPIPB3'},
{value: 'NPIPB4', id: 'NPIPB4'},
{value: 'NPIPB5', id: 'NPIPB5'},
{value: 'NPIPB6', id: 'NPIPB6'},
{value: 'NPIPB7', id: 'NPIPB7'},
{value: 'NPIPB8', id: 'NPIPB8'},
{value: 'NPIPB9', id: 'NPIPB9'},
{value: 'NPIPP1', id: 'NPIPP1'},
{value: 'NPL', id: 'NPL'},
{value: 'NPLOC4', id: 'NPLOC4'},
{value: 'NPLP1', id: 'NPLP1'},
{value: 'NPM1', id: 'NPM1'},
{value: 'NPM1P1', id: 'NPM1P1'},
{value: 'NPM1P10', id: 'NPM1P10'},
{value: 'NPM1P12', id: 'NPM1P12'},
{value: 'NPM1P13', id: 'NPM1P13'},
{value: 'NPM1P14', id: 'NPM1P14'},
{value: 'NPM1P17', id: 'NPM1P17'},
{value: 'NPM1P18', id: 'NPM1P18'},
{value: 'NPM1P19', id: 'NPM1P19'},
{value: 'NPM1P2', id: 'NPM1P2'},
{value: 'NPM1P20', id: 'NPM1P20'},
{value: 'NPM1P21', id: 'NPM1P21'},
{value: 'NPM1P22', id: 'NPM1P22'},
{value: 'NPM1P23', id: 'NPM1P23'},
{value: 'NPM1P25', id: 'NPM1P25'},
{value: 'NPM1P27', id: 'NPM1P27'},
{value: 'NPM1P28', id: 'NPM1P28'},
{value: 'NPM1P29', id: 'NPM1P29'},
{value: 'NPM1P31', id: 'NPM1P31'},
{value: 'NPM1P33', id: 'NPM1P33'},
{value: 'NPM1P35', id: 'NPM1P35'},
{value: 'NPM1P36', id: 'NPM1P36'},
{value: 'NPM1P37', id: 'NPM1P37'},
{value: 'NPM1P39', id: 'NPM1P39'},
{value: 'NPM1P4', id: 'NPM1P4'},
{value: 'NPM1P41', id: 'NPM1P41'},
{value: 'NPM1P42', id: 'NPM1P42'},
{value: 'NPM1P46', id: 'NPM1P46'},
{value: 'NPM1P47', id: 'NPM1P47'},
{value: 'NPM1P48', id: 'NPM1P48'},
{value: 'NPM1P51', id: 'NPM1P51'},
{value: 'NPM1P52', id: 'NPM1P52'},
{value: 'NPM1P7', id: 'NPM1P7'},
{value: 'NPM2', id: 'NPM2'},
{value: 'NPM3', id: 'NPM3'},
{value: 'NPNT', id: 'NPNT'},
{value: 'NPPA', id: 'NPPA'},
{value: 'NPPB', id: 'NPPB'},
{value: 'NPPC', id: 'NPPC'},
{value: 'NPR1', id: 'NPR1'},
{value: 'NPR2', id: 'NPR2'},
{value: 'NPR3', id: 'NPR3'},
{value: 'NPRL2', id: 'NPRL2'},
{value: 'NPRL3', id: 'NPRL3'},
{value: 'NPS', id: 'NPS'},
{value: 'NPSR1', id: 'NPSR1'},
{value: 'NPSR1-AS1', id: 'NPSR1-AS1'},
{value: 'NPTN', id: 'NPTN'},
{value: 'NPTN-IT1', id: 'NPTN-IT1'},
{value: 'NPTX1', id: 'NPTX1'},
{value: 'NPTX2', id: 'NPTX2'},
{value: 'NPTXR', id: 'NPTXR'},
{value: 'NPVF', id: 'NPVF'},
{value: 'NPW', id: 'NPW'},
{value: 'NPY', id: 'NPY'},
{value: 'NPY1R', id: 'NPY1R'},
{value: 'NPY2R', id: 'NPY2R'},
{value: 'NPY4R', id: 'NPY4R'},
{value: 'NPY4R2', id: 'NPY4R2'},
{value: 'NPY5R', id: 'NPY5R'},
{value: 'NPY6R', id: 'NPY6R'},
{value: 'NQO1', id: 'NQO1'},
{value: 'NQO1-DT', id: 'NQO1-DT'},
{value: 'NQO2', id: 'NQO2'},
{value: 'NQO2-AS1', id: 'NQO2-AS1'},
{value: 'NR0B2', id: 'NR0B2'},
{value: 'NR1D1', id: 'NR1D1'},
{value: 'NR1D2', id: 'NR1D2'},
{value: 'NR1H2', id: 'NR1H2'},
{value: 'NR1H3', id: 'NR1H3'},
{value: 'NR1H4', id: 'NR1H4'},
{value: 'NR1H5P', id: 'NR1H5P'},
{value: 'NR1I2', id: 'NR1I2'},
{value: 'NR1I3', id: 'NR1I3'},
{value: 'NR2C1', id: 'NR2C1'},
{value: 'NR2C2', id: 'NR2C2'},
{value: 'NR2C2AP', id: 'NR2C2AP'},
{value: 'NR2E1', id: 'NR2E1'},
{value: 'NR2E3', id: 'NR2E3'},
{value: 'NR2F1', id: 'NR2F1'},
{value: 'NR2F1-AS1', id: 'NR2F1-AS1'},
{value: 'NR2F2', id: 'NR2F2'},
{value: 'NR2F2-AS1', id: 'NR2F2-AS1'},
{value: 'NR2F6', id: 'NR2F6'},
{value: 'NR3C1', id: 'NR3C1'},
{value: 'NR3C2', id: 'NR3C2'},
{value: 'NR4A1', id: 'NR4A1'},
{value: 'NR4A1AS', id: 'NR4A1AS'},
{value: 'NR4A2', id: 'NR4A2'},
{value: 'NR4A3', id: 'NR4A3'},
{value: 'NR5A1', id: 'NR5A1'},
{value: 'NR5A2', id: 'NR5A2'},
{value: 'NR6A1', id: 'NR6A1'},
{value: 'NRAD1', id: 'NRAD1'},
{value: 'NRADDP', id: 'NRADDP'},
{value: 'NRAP', id: 'NRAP'},
{value: 'NRARP', id: 'NRARP'},
{value: 'NRAS', id: 'NRAS'},
{value: 'NRAV', id: 'NRAV'},
{value: 'NRBF2', id: 'NRBF2'},
{value: 'NRBF2P1', id: 'NRBF2P1'},
{value: 'NRBF2P2', id: 'NRBF2P2'},
{value: 'NRBF2P3', id: 'NRBF2P3'},
{value: 'NRBF2P4', id: 'NRBF2P4'},
{value: 'NRBF2P5', id: 'NRBF2P5'},
{value: 'NRBF2P6', id: 'NRBF2P6'},
{value: 'NRBP1', id: 'NRBP1'},
{value: 'NRBP2', id: 'NRBP2'},
{value: 'NRCAM', id: 'NRCAM'},
{value: 'NRDC', id: 'NRDC'},
{value: 'NRDE2', id: 'NRDE2'},
{value: 'NREP', id: 'NREP'},
{value: 'NREP-AS1', id: 'NREP-AS1'},
{value: 'NRF1', id: 'NRF1'},
{value: 'NRG1', id: 'NRG1'},
{value: 'NRG2', id: 'NRG2'},
{value: 'NRG3', id: 'NRG3'},
{value: 'NRG3-AS1', id: 'NRG3-AS1'},
{value: 'NRG4', id: 'NRG4'},
{value: 'NRGN', id: 'NRGN'},
{value: 'NRIP1', id: 'NRIP1'},
{value: 'NRIP2', id: 'NRIP2'},
{value: 'NRIP3', id: 'NRIP3'},
{value: 'NRIP3-DT', id: 'NRIP3-DT'},
{value: 'NRIR', id: 'NRIR'},
{value: 'NRK', id: 'NRK'},
{value: 'NRL', id: 'NRL'},
{value: 'NRM', id: 'NRM'},
{value: 'NRN1', id: 'NRN1'},
{value: 'NRN1L', id: 'NRN1L'},
{value: 'NRP1', id: 'NRP1'},
{value: 'NRP2', id: 'NRP2'},
{value: 'NRROS', id: 'NRROS'},
{value: 'NRSN1', id: 'NRSN1'},
{value: 'NRSN2', id: 'NRSN2'},
{value: 'NRSN2-AS1', id: 'NRSN2-AS1'},
{value: 'NRTN', id: 'NRTN'},
{value: 'NRXN1', id: 'NRXN1'},
{value: 'NRXN2', id: 'NRXN2'},
{value: 'NRXN3', id: 'NRXN3'},
{value: 'NSA2', id: 'NSA2'},
{value: 'NSA2P1', id: 'NSA2P1'},
{value: 'NSA2P2', id: 'NSA2P2'},
{value: 'NSA2P5', id: 'NSA2P5'},
{value: 'NSD1', id: 'NSD1'},
{value: 'NSD2', id: 'NSD2'},
{value: 'NSD3', id: 'NSD3'},
{value: 'NSDHL', id: 'NSDHL'},
{value: 'NSF', id: 'NSF'},
{value: 'NSFL1C', id: 'NSFL1C'},
{value: 'NSFP1', id: 'NSFP1'},
{value: 'NSG1', id: 'NSG1'},
{value: 'NSG2', id: 'NSG2'},
{value: 'NSL1', id: 'NSL1'},
{value: 'NSMAF', id: 'NSMAF'},
{value: 'NSMCE1', id: 'NSMCE1'},
{value: 'NSMCE1-DT', id: 'NSMCE1-DT'},
{value: 'NSMCE2', id: 'NSMCE2'},
{value: 'NSMCE3', id: 'NSMCE3'},
{value: 'NSMCE4A', id: 'NSMCE4A'},
{value: 'NSMF', id: 'NSMF'},
{value: 'NSRP1', id: 'NSRP1'},
{value: 'NSRP1P1', id: 'NSRP1P1'},
{value: 'NSUN2', id: 'NSUN2'},
{value: 'NSUN3', id: 'NSUN3'},
{value: 'NSUN4', id: 'NSUN4'},
{value: 'NSUN5', id: 'NSUN5'},
{value: 'NSUN5P1', id: 'NSUN5P1'},
{value: 'NSUN5P2', id: 'NSUN5P2'},
{value: 'NSUN6', id: 'NSUN6'},
{value: 'NSUN7', id: 'NSUN7'},
{value: 'NT5C', id: 'NT5C'},
{value: 'NT5C1A', id: 'NT5C1A'},
{value: 'NT5C1B', id: 'NT5C1B'},
{value: 'NT5C1B-RDH14', id: 'NT5C1B-RDH14'},
{value: 'NT5C2', id: 'NT5C2'},
{value: 'NT5C3A', id: 'NT5C3A'},
{value: 'NT5C3AP1', id: 'NT5C3AP1'},
{value: 'NT5C3B', id: 'NT5C3B'},
{value: 'NT5CP1', id: 'NT5CP1'},
{value: 'NT5CP2', id: 'NT5CP2'},
{value: 'NT5DC1', id: 'NT5DC1'},
{value: 'NT5DC2', id: 'NT5DC2'},
{value: 'NT5DC3', id: 'NT5DC3'},
{value: 'NT5DC4', id: 'NT5DC4'},
{value: 'NT5E', id: 'NT5E'},
{value: 'NT5ELP', id: 'NT5ELP'},
{value: 'NT5M', id: 'NT5M'},
{value: 'NTAN1', id: 'NTAN1'},
{value: 'NTAN1P1', id: 'NTAN1P1'},
{value: 'NTAN1P2', id: 'NTAN1P2'},
{value: 'NTAN1P3', id: 'NTAN1P3'},
{value: 'NTAQ1', id: 'NTAQ1'},
{value: 'NTF3', id: 'NTF3'},
{value: 'NTF4', id: 'NTF4'},
{value: 'NTF6B', id: 'NTF6B'},
{value: 'NTF6G', id: 'NTF6G'},
{value: 'NTHL1', id: 'NTHL1'},
{value: 'NTM', id: 'NTM'},
{value: 'NTM-AS1', id: 'NTM-AS1'},
{value: 'NTM-IT', id: 'NTM-IT'},
{value: 'NTMT1', id: 'NTMT1'},
{value: 'NTMT2', id: 'NTMT2'},
{value: 'NTN1', id: 'NTN1'},
{value: 'NTN3', id: 'NTN3'},
{value: 'NTN4', id: 'NTN4'},
{value: 'NTN5', id: 'NTN5'},
{value: 'NTNG1', id: 'NTNG1'},
{value: 'NTNG2', id: 'NTNG2'},
{value: 'NTPCR', id: 'NTPCR'},
{value: 'NTRK1', id: 'NTRK1'},
{value: 'NTRK2', id: 'NTRK2'},
{value: 'NTRK3', id: 'NTRK3'},
{value: 'NTRK3-AS1', id: 'NTRK3-AS1'},
{value: 'NTS', id: 'NTS'},
{value: 'NTSR1', id: 'NTSR1'},
{value: 'NTSR2', id: 'NTSR2'},
{value: 'NUAK1', id: 'NUAK1'},
{value: 'NUAK2', id: 'NUAK2'},
{value: 'NUB1', id: 'NUB1'},
{value: 'NUBP1', id: 'NUBP1'},
{value: 'NUBP2', id: 'NUBP2'},
{value: 'NUBPL', id: 'NUBPL'},
{value: 'NUBPL-DT', id: 'NUBPL-DT'},
{value: 'NUCB1', id: 'NUCB1'},
{value: 'NUCB1-AS1', id: 'NUCB1-AS1'},
{value: 'NUCB2', id: 'NUCB2'},
{value: 'NUCKS1', id: 'NUCKS1'},
{value: 'NUDC', id: 'NUDC'},
{value: 'NUDCD1', id: 'NUDCD1'},
{value: 'NUDCD2', id: 'NUDCD2'},
{value: 'NUDCD3', id: 'NUDCD3'},
{value: 'NUDCP1', id: 'NUDCP1'},
{value: 'NUDT1', id: 'NUDT1'},
{value: 'NUDT11', id: 'NUDT11'},
{value: 'NUDT12', id: 'NUDT12'},
{value: 'NUDT13', id: 'NUDT13'},
{value: 'NUDT14', id: 'NUDT14'},
{value: 'NUDT15', id: 'NUDT15'},
{value: 'NUDT16', id: 'NUDT16'},
{value: 'NUDT16-DT', id: 'NUDT16-DT'},
{value: 'NUDT16L1', id: 'NUDT16L1'},
{value: 'NUDT16L2P', id: 'NUDT16L2P'},
{value: 'NUDT17', id: 'NUDT17'},
{value: 'NUDT18', id: 'NUDT18'},
{value: 'NUDT19', id: 'NUDT19'},
{value: 'NUDT19-DT', id: 'NUDT19-DT'},
{value: 'NUDT19P3', id: 'NUDT19P3'},
{value: 'NUDT19P4', id: 'NUDT19P4'},
{value: 'NUDT2', id: 'NUDT2'},
{value: 'NUDT21', id: 'NUDT21'},
{value: 'NUDT22', id: 'NUDT22'},
{value: 'NUDT3', id: 'NUDT3'},
{value: 'NUDT4', id: 'NUDT4'},
{value: 'NUDT4B', id: 'NUDT4B'},
{value: 'NUDT4P2', id: 'NUDT4P2'},
{value: 'NUDT5', id: 'NUDT5'},
{value: 'NUDT6', id: 'NUDT6'},
{value: 'NUDT7', id: 'NUDT7'},
{value: 'NUDT8', id: 'NUDT8'},
{value: 'NUDT9', id: 'NUDT9'},
{value: 'NUDT9P1', id: 'NUDT9P1'},
{value: 'NUF2', id: 'NUF2'},
{value: 'NUFIP1', id: 'NUFIP1'},
{value: 'NUFIP1P1', id: 'NUFIP1P1'},
{value: 'NUFIP2', id: 'NUFIP2'},
{value: 'NUGGC', id: 'NUGGC'},
{value: 'NUMA1', id: 'NUMA1'},
{value: 'NUMB', id: 'NUMB'},
{value: 'NUMBL', id: 'NUMBL'},
{value: 'NUP107', id: 'NUP107'},
{value: 'NUP107-DT', id: 'NUP107-DT'},
{value: 'NUP133', id: 'NUP133'},
{value: 'NUP153', id: 'NUP153'},
{value: 'NUP153-AS1', id: 'NUP153-AS1'},
{value: 'NUP155', id: 'NUP155'},
{value: 'NUP160', id: 'NUP160'},
{value: 'NUP188', id: 'NUP188'},
{value: 'NUP205', id: 'NUP205'},
{value: 'NUP210', id: 'NUP210'},
{value: 'NUP210L', id: 'NUP210L'},
{value: 'NUP210P1', id: 'NUP210P1'},
{value: 'NUP210P2', id: 'NUP210P2'},
{value: 'NUP210P3', id: 'NUP210P3'},
{value: 'NUP214', id: 'NUP214'},
{value: 'NUP35', id: 'NUP35'},
{value: 'NUP35P2', id: 'NUP35P2'},
{value: 'NUP37', id: 'NUP37'},
{value: 'NUP42', id: 'NUP42'},
{value: 'NUP43', id: 'NUP43'},
{value: 'NUP50', id: 'NUP50'},
{value: 'NUP50-DT', id: 'NUP50-DT'},
{value: 'NUP50P1', id: 'NUP50P1'},
{value: 'NUP54', id: 'NUP54'},
{value: 'NUP58', id: 'NUP58'},
{value: 'NUP58P1', id: 'NUP58P1'},
{value: 'NUP62', id: 'NUP62'},
{value: 'NUP85', id: 'NUP85'},
{value: 'NUP88', id: 'NUP88'},
{value: 'NUP93', id: 'NUP93'},
{value: 'NUP93-DT', id: 'NUP93-DT'},
{value: 'NUP98', id: 'NUP98'},
{value: 'NUPR1', id: 'NUPR1'},
{value: 'NUPR2', id: 'NUPR2'},
{value: 'NUS1', id: 'NUS1'},
{value: 'NUS1P1', id: 'NUS1P1'},
{value: 'NUS1P2', id: 'NUS1P2'},
{value: 'NUS1P3', id: 'NUS1P3'},
{value: 'NUS1P4', id: 'NUS1P4'},
{value: 'NUSAP1', id: 'NUSAP1'},
{value: 'NUTF2', id: 'NUTF2'},
{value: 'NUTF2P2', id: 'NUTF2P2'},
{value: 'NUTF2P4', id: 'NUTF2P4'},
{value: 'NUTF2P5', id: 'NUTF2P5'},
{value: 'NUTF2P6', id: 'NUTF2P6'},
{value: 'NUTF2P8', id: 'NUTF2P8'},
{value: 'NUTM1', id: 'NUTM1'},
{value: 'NUTM2A', id: 'NUTM2A'},
{value: 'NUTM2A-AS1', id: 'NUTM2A-AS1'},
{value: 'NUTM2B', id: 'NUTM2B'},
{value: 'NUTM2B-AS1', id: 'NUTM2B-AS1'},
{value: 'NUTM2D', id: 'NUTM2D'},
{value: 'NUTM2E', id: 'NUTM2E'},
{value: 'NUTM2F', id: 'NUTM2F'},
{value: 'NUTM2G', id: 'NUTM2G'},
{value: 'NUTM2HP', id: 'NUTM2HP'},
{value: 'NVL', id: 'NVL'},
{value: 'NWD1', id: 'NWD1'},
{value: 'NWD2', id: 'NWD2'},
{value: 'NXF1', id: 'NXF1'},
{value: 'NXF3', id: 'NXF3'},
{value: 'NXN', id: 'NXN'},
{value: 'NXNL1', id: 'NXNL1'},
{value: 'NXNL2', id: 'NXNL2'},
{value: 'NXNP1', id: 'NXNP1'},
{value: 'NXPE1', id: 'NXPE1'},
{value: 'NXPE2', id: 'NXPE2'},
{value: 'NXPE2P1', id: 'NXPE2P1'},
{value: 'NXPE3', id: 'NXPE3'},
{value: 'NXPE4', id: 'NXPE4'},
{value: 'NXPH1', id: 'NXPH1'},
{value: 'NXPH2', id: 'NXPH2'},
{value: 'NXPH3', id: 'NXPH3'},
{value: 'NXPH4', id: 'NXPH4'},
{value: 'NXT1', id: 'NXT1'},
{value: 'NXT1-AS1', id: 'NXT1-AS1'},
{value: 'NXT1P1', id: 'NXT1P1'},
{value: 'NXT2', id: 'NXT2'},
{value: 'NYAP1', id: 'NYAP1'},
{value: 'NYAP2', id: 'NYAP2'},
{value: 'NYNRIN', id: 'NYNRIN'},
{value: 'OACYLP', id: 'OACYLP'},
{value: 'OAF', id: 'OAF'},
{value: 'OARD1', id: 'OARD1'},
{value: 'OAS1', id: 'OAS1'},
{value: 'OAS2', id: 'OAS2'},
{value: 'OAS3', id: 'OAS3'},
{value: 'OASL', id: 'OASL'},
{value: 'OASL2P', id: 'OASL2P'},
{value: 'OAT', id: 'OAT'},
{value: 'OAZ1', id: 'OAZ1'},
{value: 'OAZ1P1', id: 'OAZ1P1'},
{value: 'OAZ2', id: 'OAZ2'},
{value: 'OAZ3', id: 'OAZ3'},
{value: 'OBI1', id: 'OBI1'},
{value: 'OBI1-AS1', id: 'OBI1-AS1'},
{value: 'OBP2A', id: 'OBP2A'},
{value: 'OBP2B', id: 'OBP2B'},
{value: 'OBSCN', id: 'OBSCN'},
{value: 'OBSCN-AS1', id: 'OBSCN-AS1'},
{value: 'OBSL1', id: 'OBSL1'},
{value: 'OC90', id: 'OC90'},
{value: 'OCA2', id: 'OCA2'},
{value: 'OCEL1', id: 'OCEL1'},
{value: 'OCIAD1', id: 'OCIAD1'},
{value: 'OCIAD1-AS1', id: 'OCIAD1-AS1'},
{value: 'OCIAD2', id: 'OCIAD2'},
{value: 'OCLN', id: 'OCLN'},
{value: 'OCLNP1', id: 'OCLNP1'},
{value: 'OCM', id: 'OCM'},
{value: 'OCM2', id: 'OCM2'},
{value: 'OCRL', id: 'OCRL'},
{value: 'OCSTAMP', id: 'OCSTAMP'},
{value: 'ODAD1', id: 'ODAD1'},
{value: 'ODAD2', id: 'ODAD2'},
{value: 'ODAD2P1', id: 'ODAD2P1'},
{value: 'ODAD3', id: 'ODAD3'},
{value: 'ODAD4', id: 'ODAD4'},
{value: 'ODAM', id: 'ODAM'},
{value: 'ODAPH', id: 'ODAPH'},
{value: 'ODC1', id: 'ODC1'},
{value: 'ODC1-DT', id: 'ODC1-DT'},
{value: 'ODCP', id: 'ODCP'},
{value: 'ODF1', id: 'ODF1'},
{value: 'ODF2', id: 'ODF2'},
{value: 'ODF2-AS1', id: 'ODF2-AS1'},
{value: 'ODF2L', id: 'ODF2L'},
{value: 'ODF3', id: 'ODF3'},
{value: 'ODF3B', id: 'ODF3B'},
{value: 'ODF3L1', id: 'ODF3L1'},
{value: 'ODF3L2', id: 'ODF3L2'},
{value: 'ODF4', id: 'ODF4'},
{value: 'ODR4', id: 'ODR4'},
{value: 'OFCC1', id: 'OFCC1'},
{value: 'OFD1', id: 'OFD1'},
{value: 'OFD1P17', id: 'OFD1P17'},
{value: 'OGA', id: 'OGA'},
{value: 'OGDH', id: 'OGDH'},
{value: 'OGDHL', id: 'OGDHL'},
{value: 'OGFOD1', id: 'OGFOD1'},
{value: 'OGFOD1P1', id: 'OGFOD1P1'},
{value: 'OGFOD2', id: 'OGFOD2'},
{value: 'OGFOD3', id: 'OGFOD3'},
{value: 'OGFR', id: 'OGFR'},
{value: 'OGFR-AS1', id: 'OGFR-AS1'},
{value: 'OGFRL1', id: 'OGFRL1'},
{value: 'OGFRP1', id: 'OGFRP1'},
{value: 'OGG1', id: 'OGG1'},
{value: 'OGN', id: 'OGN'},
{value: 'OGT', id: 'OGT'},
{value: 'OIP5', id: 'OIP5'},
{value: 'OIP5-AS1', id: 'OIP5-AS1'},
{value: 'OIT3', id: 'OIT3'},
{value: 'OLA1', id: 'OLA1'},
{value: 'OLA1P2', id: 'OLA1P2'},
{value: 'OLA1P3', id: 'OLA1P3'},
{value: 'OLAH', id: 'OLAH'},
{value: 'OLFM1', id: 'OLFM1'},
{value: 'OLFM2', id: 'OLFM2'},
{value: 'OLFM3', id: 'OLFM3'},
{value: 'OLFM4', id: 'OLFM4'},
{value: 'OLFML1', id: 'OLFML1'},
{value: 'OLFML2A', id: 'OLFML2A'},
{value: 'OLFML2B', id: 'OLFML2B'},
{value: 'OLFML3', id: 'OLFML3'},
{value: 'OLIG1', id: 'OLIG1'},
{value: 'OLIG2', id: 'OLIG2'},
{value: 'OLIG3', id: 'OLIG3'},
{value: 'OLMALINC', id: 'OLMALINC'},
{value: 'OLR1', id: 'OLR1'},
{value: 'OMA1', id: 'OMA1'},
{value: 'OMD', id: 'OMD'},
{value: 'OMG', id: 'OMG'},
{value: 'OMP', id: 'OMP'},
{value: 'ONECUT1', id: 'ONECUT1'},
{value: 'ONECUT2', id: 'ONECUT2'},
{value: 'ONECUT3', id: 'ONECUT3'},
{value: 'OOEP', id: 'OOEP'},
{value: 'OOEP-AS1', id: 'OOEP-AS1'},
{value: 'OOSP1', id: 'OOSP1'},
{value: 'OOSP1P1', id: 'OOSP1P1'},
{value: 'OOSP2', id: 'OOSP2'},
{value: 'OOSP3', id: 'OOSP3'},
{value: 'OOSP4B', id: 'OOSP4B'},
{value: 'OPA1', id: 'OPA1'},
{value: 'OPA3', id: 'OPA3'},
{value: 'OPALIN', id: 'OPALIN'},
{value: 'OPCML', id: 'OPCML'},
{value: 'OPHN1', id: 'OPHN1'},
{value: 'OPLAH', id: 'OPLAH'},
{value: 'OPN1SW', id: 'OPN1SW'},
{value: 'OPN3', id: 'OPN3'},
{value: 'OPN4', id: 'OPN4'},
{value: 'OPN5', id: 'OPN5'},
{value: 'OPRD1', id: 'OPRD1'},
{value: 'OPRK1', id: 'OPRK1'},
{value: 'OPRL1', id: 'OPRL1'},
{value: 'OPRM1', id: 'OPRM1'},
{value: 'OPRPN', id: 'OPRPN'},
{value: 'OPTC', id: 'OPTC'},
{value: 'OPTN', id: 'OPTN'},
{value: 'OR10A2', id: 'OR10A2'},
{value: 'OR10A3', id: 'OR10A3'},
{value: 'OR10A6', id: 'OR10A6'},
{value: 'OR10A7', id: 'OR10A7'},
{value: 'OR10AA1P', id: 'OR10AA1P'},
{value: 'OR10AB1P', id: 'OR10AB1P'},
{value: 'OR10AC1', id: 'OR10AC1'},
{value: 'OR10AE3P', id: 'OR10AE3P'},
{value: 'OR10AF1P', id: 'OR10AF1P'},
{value: 'OR10AG1', id: 'OR10AG1'},
{value: 'OR10AH1P', id: 'OR10AH1P'},
{value: 'OR10B1P', id: 'OR10B1P'},
{value: 'OR10D1P', id: 'OR10D1P'},
{value: 'OR10D3', id: 'OR10D3'},
{value: 'OR10D4P', id: 'OR10D4P'},
{value: 'OR10D5P', id: 'OR10D5P'},
{value: 'OR10G1P', id: 'OR10G1P'},
{value: 'OR10G2', id: 'OR10G2'},
{value: 'OR10G3', id: 'OR10G3'},
{value: 'OR10G4', id: 'OR10G4'},
{value: 'OR10G5P', id: 'OR10G5P'},
{value: 'OR10G6', id: 'OR10G6'},
{value: 'OR10G7', id: 'OR10G7'},
{value: 'OR10G8', id: 'OR10G8'},
{value: 'OR10G9', id: 'OR10G9'},
{value: 'OR10H1', id: 'OR10H1'},
{value: 'OR10H2', id: 'OR10H2'},
{value: 'OR10H3', id: 'OR10H3'},
{value: 'OR10H4', id: 'OR10H4'},
{value: 'OR10H5', id: 'OR10H5'},
{value: 'OR10J1', id: 'OR10J1'},
{value: 'OR10J2P', id: 'OR10J2P'},
{value: 'OR10J3', id: 'OR10J3'},
{value: 'OR10J4', id: 'OR10J4'},
{value: 'OR10J6P', id: 'OR10J6P'},
{value: 'OR10J7P', id: 'OR10J7P'},
{value: 'OR10K1', id: 'OR10K1'},
{value: 'OR10K2', id: 'OR10K2'},
{value: 'OR10N1P', id: 'OR10N1P'},
{value: 'OR10P1', id: 'OR10P1'},
{value: 'OR10Q1', id: 'OR10Q1'},
{value: 'OR10Q2P', id: 'OR10Q2P'},
{value: 'OR10R1P', id: 'OR10R1P'},
{value: 'OR10R2', id: 'OR10R2'},
{value: 'OR10S1', id: 'OR10S1'},
{value: 'OR10T1P', id: 'OR10T1P'},
{value: 'OR10T2', id: 'OR10T2'},
{value: 'OR10U1P', id: 'OR10U1P'},
{value: 'OR10V1', id: 'OR10V1'},
{value: 'OR10V2P', id: 'OR10V2P'},
{value: 'OR10W1', id: 'OR10W1'},
{value: 'OR10X1', id: 'OR10X1'},
{value: 'OR10Y1P', id: 'OR10Y1P'},
{value: 'OR10Z1', id: 'OR10Z1'},
{value: 'OR11A1', id: 'OR11A1'},
{value: 'OR11G1P', id: 'OR11G1P'},
{value: 'OR11G2', id: 'OR11G2'},
{value: 'OR11H1', id: 'OR11H1'},
{value: 'OR11H4', id: 'OR11H4'},
{value: 'OR11H5P', id: 'OR11H5P'},
{value: 'OR11H6', id: 'OR11H6'},
{value: 'OR11H7', id: 'OR11H7'},
{value: 'OR11I1P', id: 'OR11I1P'},
{value: 'OR11J2P', id: 'OR11J2P'},
{value: 'OR11J6P', id: 'OR11J6P'},
{value: 'OR11J7P', id: 'OR11J7P'},
{value: 'OR11L1', id: 'OR11L1'},
{value: 'OR11M1P', id: 'OR11M1P'},
{value: 'OR11P1P', id: 'OR11P1P'},
{value: 'OR13A1', id: 'OR13A1'},
{value: 'OR13C1P', id: 'OR13C1P'},
{value: 'OR13C2', id: 'OR13C2'},
{value: 'OR13C3', id: 'OR13C3'},
{value: 'OR13C5', id: 'OR13C5'},
{value: 'OR13C8', id: 'OR13C8'},
{value: 'OR13C9', id: 'OR13C9'},
{value: 'OR13D1', id: 'OR13D1'},
{value: 'OR13D2P', id: 'OR13D2P'},
{value: 'OR13D3P', id: 'OR13D3P'},
{value: 'OR13E1P', id: 'OR13E1P'},
{value: 'OR13F1', id: 'OR13F1'},
{value: 'OR13I1P', id: 'OR13I1P'},
{value: 'OR13J1', id: 'OR13J1'},
{value: 'OR13Z3P', id: 'OR13Z3P'},
{value: 'OR14A16', id: 'OR14A16'},
{value: 'OR14C36', id: 'OR14C36'},
{value: 'OR14I1', id: 'OR14I1'},
{value: 'OR14J1', id: 'OR14J1'},
{value: 'OR1A1', id: 'OR1A1'},
{value: 'OR1A2', id: 'OR1A2'},
{value: 'OR1AB1P', id: 'OR1AB1P'},
{value: 'OR1B1', id: 'OR1B1'},
{value: 'OR1D2', id: 'OR1D2'},
{value: 'OR1D3P', id: 'OR1D3P'},
{value: 'OR1D4', id: 'OR1D4'},
{value: 'OR1D5', id: 'OR1D5'},
{value: 'OR1E1', id: 'OR1E1'},
{value: 'OR1E2', id: 'OR1E2'},
{value: 'OR1E3', id: 'OR1E3'},
{value: 'OR1F1', id: 'OR1F1'},
{value: 'OR1F2P', id: 'OR1F2P'},
{value: 'OR1G1', id: 'OR1G1'},
{value: 'OR1I1', id: 'OR1I1'},
{value: 'OR1J1', id: 'OR1J1'},
{value: 'OR1J2', id: 'OR1J2'},
{value: 'OR1J4', id: 'OR1J4'},
{value: 'OR1K1', id: 'OR1K1'},
{value: 'OR1L1', id: 'OR1L1'},
{value: 'OR1L3', id: 'OR1L3'},
{value: 'OR1L4', id: 'OR1L4'},
{value: 'OR1L6', id: 'OR1L6'},
{value: 'OR1M1', id: 'OR1M1'},
{value: 'OR1M4P', id: 'OR1M4P'},
{value: 'OR1R1P', id: 'OR1R1P'},
{value: 'OR1S1', id: 'OR1S1'},
{value: 'OR1S2', id: 'OR1S2'},
{value: 'OR1X1P', id: 'OR1X1P'},
{value: 'OR1X5P', id: 'OR1X5P'},
{value: 'OR2A1', id: 'OR2A1'},
{value: 'OR2A1-AS1', id: 'OR2A1-AS1'},
{value: 'OR2A12', id: 'OR2A12'},
{value: 'OR2A13P', id: 'OR2A13P'},
{value: 'OR2A14', id: 'OR2A14'},
{value: 'OR2A15P', id: 'OR2A15P'},
{value: 'OR2A2', id: 'OR2A2'},
{value: 'OR2A20P', id: 'OR2A20P'},
{value: 'OR2A25', id: 'OR2A25'},
{value: 'OR2A3P', id: 'OR2A3P'},
{value: 'OR2A4', id: 'OR2A4'},
{value: 'OR2A41P', id: 'OR2A41P'},
{value: 'OR2A42', id: 'OR2A42'},
{value: 'OR2A5', id: 'OR2A5'},
{value: 'OR2A7', id: 'OR2A7'},
{value: 'OR2A9P', id: 'OR2A9P'},
{value: 'OR2AD1P', id: 'OR2AD1P'},
{value: 'OR2AE1', id: 'OR2AE1'},
{value: 'OR2AG1', id: 'OR2AG1'},
{value: 'OR2AG2', id: 'OR2AG2'},
{value: 'OR2AH1P', id: 'OR2AH1P'},
{value: 'OR2AI1P', id: 'OR2AI1P'},
{value: 'OR2AJ1', id: 'OR2AJ1'},
{value: 'OR2AK2', id: 'OR2AK2'},
{value: 'OR2AM1P', id: 'OR2AM1P'},
{value: 'OR2AO1P', id: 'OR2AO1P'},
{value: 'OR2AQ1P', id: 'OR2AQ1P'},
{value: 'OR2AS1P', id: 'OR2AS1P'},
{value: 'OR2AS2P', id: 'OR2AS2P'},
{value: 'OR2AT2P', id: 'OR2AT2P'},
{value: 'OR2AT4', id: 'OR2AT4'},
{value: 'OR2B11', id: 'OR2B11'},
{value: 'OR2B2', id: 'OR2B2'},
{value: 'OR2B3', id: 'OR2B3'},
{value: 'OR2B6', id: 'OR2B6'},
{value: 'OR2BH1P', id: 'OR2BH1P'},
{value: 'OR2C1', id: 'OR2C1'},
{value: 'OR2C3', id: 'OR2C3'},
{value: 'OR2E1P', id: 'OR2E1P'},
{value: 'OR2F1', id: 'OR2F1'},
{value: 'OR2F2', id: 'OR2F2'},
{value: 'OR2G6', id: 'OR2G6'},
{value: 'OR2H1', id: 'OR2H1'},
{value: 'OR2H5P', id: 'OR2H5P'},
{value: 'OR2I1P', id: 'OR2I1P'},
{value: 'OR2J1', id: 'OR2J1'},
{value: 'OR2J2', id: 'OR2J2'},
{value: 'OR2J3', id: 'OR2J3'},
{value: 'OR2J4P', id: 'OR2J4P'},
{value: 'OR2K2', id: 'OR2K2'},
{value: 'OR2L13', id: 'OR2L13'},
{value: 'OR2L1P', id: 'OR2L1P'},
{value: 'OR2L2', id: 'OR2L2'},
{value: 'OR2L3', id: 'OR2L3'},
{value: 'OR2L5', id: 'OR2L5'},
{value: 'OR2L6P', id: 'OR2L6P'},
{value: 'OR2L8', id: 'OR2L8'},
{value: 'OR2L9P', id: 'OR2L9P'},
{value: 'OR2M1P', id: 'OR2M1P'},
{value: 'OR2M2', id: 'OR2M2'},
{value: 'OR2M3', id: 'OR2M3'},
{value: 'OR2M4', id: 'OR2M4'},
{value: 'OR2M5', id: 'OR2M5'},
{value: 'OR2M7', id: 'OR2M7'},
{value: 'OR2Q1P', id: 'OR2Q1P'},
{value: 'OR2R1P', id: 'OR2R1P'},
{value: 'OR2S1P', id: 'OR2S1P'},
{value: 'OR2S2', id: 'OR2S2'},
{value: 'OR2T1', id: 'OR2T1'},
{value: 'OR2T10', id: 'OR2T10'},
{value: 'OR2T11', id: 'OR2T11'},
{value: 'OR2T12', id: 'OR2T12'},
{value: 'OR2T2', id: 'OR2T2'},
{value: 'OR2T27', id: 'OR2T27'},
{value: 'OR2T29', id: 'OR2T29'},
{value: 'OR2T3', id: 'OR2T3'},
{value: 'OR2T32P', id: 'OR2T32P'},
{value: 'OR2T33', id: 'OR2T33'},
{value: 'OR2T34', id: 'OR2T34'},
{value: 'OR2T35', id: 'OR2T35'},
{value: 'OR2T4', id: 'OR2T4'},
{value: 'OR2T5', id: 'OR2T5'},
{value: 'OR2T6', id: 'OR2T6'},
{value: 'OR2T7', id: 'OR2T7'},
{value: 'OR2T8', id: 'OR2T8'},
{value: 'OR2V1', id: 'OR2V1'},
{value: 'OR2V2', id: 'OR2V2'},
{value: 'OR2W2P', id: 'OR2W2P'},
{value: 'OR2W3', id: 'OR2W3'},
{value: 'OR2W4P', id: 'OR2W4P'},
{value: 'OR2W5P', id: 'OR2W5P'},
{value: 'OR2W6P', id: 'OR2W6P'},
{value: 'OR2X1P', id: 'OR2X1P'},
{value: 'OR2Y1', id: 'OR2Y1'},
{value: 'OR2Z1', id: 'OR2Z1'},
{value: 'OR3A1', id: 'OR3A1'},
{value: 'OR3A2', id: 'OR3A2'},
{value: 'OR3A3', id: 'OR3A3'},
{value: 'OR4A10P', id: 'OR4A10P'},
{value: 'OR4A11P', id: 'OR4A11P'},
{value: 'OR4A13P', id: 'OR4A13P'},
{value: 'OR4A15', id: 'OR4A15'},
{value: 'OR4A16', id: 'OR4A16'},
{value: 'OR4A17P', id: 'OR4A17P'},
{value: 'OR4A18P', id: 'OR4A18P'},
{value: 'OR4A19P', id: 'OR4A19P'},
{value: 'OR4A1P', id: 'OR4A1P'},
{value: 'OR4A21P', id: 'OR4A21P'},
{value: 'OR4A2P', id: 'OR4A2P'},
{value: 'OR4A3P', id: 'OR4A3P'},
{value: 'OR4A41P', id: 'OR4A41P'},
{value: 'OR4A42P', id: 'OR4A42P'},
{value: 'OR4A43P', id: 'OR4A43P'},
{value: 'OR4A44P', id: 'OR4A44P'},
{value: 'OR4A45P', id: 'OR4A45P'},
{value: 'OR4A46P', id: 'OR4A46P'},
{value: 'OR4A47', id: 'OR4A47'},
{value: 'OR4A48P', id: 'OR4A48P'},
{value: 'OR4A49P', id: 'OR4A49P'},
{value: 'OR4A4P', id: 'OR4A4P'},
{value: 'OR4A5', id: 'OR4A5'},
{value: 'OR4A50P', id: 'OR4A50P'},
{value: 'OR4A6P', id: 'OR4A6P'},
{value: 'OR4A7P', id: 'OR4A7P'},
{value: 'OR4A8', id: 'OR4A8'},
{value: 'OR4A9P', id: 'OR4A9P'},
{value: 'OR4B1', id: 'OR4B1'},
{value: 'OR4B2P', id: 'OR4B2P'},
{value: 'OR4C10P', id: 'OR4C10P'},
{value: 'OR4C11', id: 'OR4C11'},
{value: 'OR4C12', id: 'OR4C12'},
{value: 'OR4C13', id: 'OR4C13'},
{value: 'OR4C14P', id: 'OR4C14P'},
{value: 'OR4C15', id: 'OR4C15'},
{value: 'OR4C16', id: 'OR4C16'},
{value: 'OR4C1P', id: 'OR4C1P'},
{value: 'OR4C2P', id: 'OR4C2P'},
{value: 'OR4C3', id: 'OR4C3'},
{value: 'OR4C45', id: 'OR4C45'},
{value: 'OR4C46', id: 'OR4C46'},
{value: 'OR4C48P', id: 'OR4C48P'},
{value: 'OR4C49P', id: 'OR4C49P'},
{value: 'OR4C4P', id: 'OR4C4P'},
{value: 'OR4C5', id: 'OR4C5'},
{value: 'OR4C50P', id: 'OR4C50P'},
{value: 'OR4C6', id: 'OR4C6'},
{value: 'OR4C7P', id: 'OR4C7P'},
{value: 'OR4C9P', id: 'OR4C9P'},
{value: 'OR4D1', id: 'OR4D1'},
{value: 'OR4D10', id: 'OR4D10'},
{value: 'OR4D11', id: 'OR4D11'},
{value: 'OR4D2', id: 'OR4D2'},
{value: 'OR4D5', id: 'OR4D5'},
{value: 'OR4D6', id: 'OR4D6'},
{value: 'OR4D7P', id: 'OR4D7P'},
{value: 'OR4D8P', id: 'OR4D8P'},
{value: 'OR4D9', id: 'OR4D9'},
{value: 'OR4E1', id: 'OR4E1'},
{value: 'OR4E2', id: 'OR4E2'},
{value: 'OR4F13P', id: 'OR4F13P'},
{value: 'OR4F14P', id: 'OR4F14P'},
{value: 'OR4F15', id: 'OR4F15'},
{value: 'OR4F17', id: 'OR4F17'},
{value: 'OR4F4', id: 'OR4F4'},
{value: 'OR4F5', id: 'OR4F5'},
{value: 'OR4F6', id: 'OR4F6'},
{value: 'OR4G11P', id: 'OR4G11P'},
{value: 'OR4G1P', id: 'OR4G1P'},
{value: 'OR4G2P', id: 'OR4G2P'},
{value: 'OR4G3P', id: 'OR4G3P'},
{value: 'OR4G4P', id: 'OR4G4P'},
{value: 'OR4G6P', id: 'OR4G6P'},
{value: 'OR4H12P', id: 'OR4H12P'},
{value: 'OR4K1', id: 'OR4K1'},
{value: 'OR4K11P', id: 'OR4K11P'},
{value: 'OR4K12P', id: 'OR4K12P'},
{value: 'OR4K13', id: 'OR4K13'},
{value: 'OR4K14', id: 'OR4K14'},
{value: 'OR4K15', id: 'OR4K15'},
{value: 'OR4K16P', id: 'OR4K16P'},
{value: 'OR4K17', id: 'OR4K17'},
{value: 'OR4K2', id: 'OR4K2'},
{value: 'OR4K3', id: 'OR4K3'},
{value: 'OR4K4P', id: 'OR4K4P'},
{value: 'OR4K5', id: 'OR4K5'},
{value: 'OR4K6P', id: 'OR4K6P'},
{value: 'OR4K7P', id: 'OR4K7P'},
{value: 'OR4K8P', id: 'OR4K8P'},
{value: 'OR4L1', id: 'OR4L1'},
{value: 'OR4M1', id: 'OR4M1'},
{value: 'OR4N2', id: 'OR4N2'},
{value: 'OR4N3BP', id: 'OR4N3BP'},
{value: 'OR4N3P', id: 'OR4N3P'},
{value: 'OR4N4', id: 'OR4N4'},
{value: 'OR4N4C', id: 'OR4N4C'},
{value: 'OR4N5', id: 'OR4N5'},
{value: 'OR4P1P', id: 'OR4P1P'},
{value: 'OR4P4', id: 'OR4P4'},
{value: 'OR4Q2', id: 'OR4Q2'},
{value: 'OR4Q3', id: 'OR4Q3'},
{value: 'OR4R1P', id: 'OR4R1P'},
{value: 'OR4R2P', id: 'OR4R2P'},
{value: 'OR4R3P', id: 'OR4R3P'},
{value: 'OR4S1', id: 'OR4S1'},
{value: 'OR4S2', id: 'OR4S2'},
{value: 'OR4T1P', id: 'OR4T1P'},
{value: 'OR4U1P', id: 'OR4U1P'},
{value: 'OR4V1P', id: 'OR4V1P'},
{value: 'OR4X1', id: 'OR4X1'},
{value: 'OR4X2', id: 'OR4X2'},
{value: 'OR4X7P', id: 'OR4X7P'},
{value: 'OR51A10P', id: 'OR51A10P'},
{value: 'OR51A1P', id: 'OR51A1P'},
{value: 'OR51A5P', id: 'OR51A5P'},
{value: 'OR51A9P', id: 'OR51A9P'},
{value: 'OR51B3P', id: 'OR51B3P'},
{value: 'OR51B6', id: 'OR51B6'},
{value: 'OR51B8P', id: 'OR51B8P'},
{value: 'OR51C1P', id: 'OR51C1P'},
{value: 'OR51D1', id: 'OR51D1'},
{value: 'OR51E1', id: 'OR51E1'},
{value: 'OR51E2', id: 'OR51E2'},
{value: 'OR51F5P', id: 'OR51F5P'},
{value: 'OR51G1', id: 'OR51G1'},
{value: 'OR51H1', id: 'OR51H1'},
{value: 'OR51I1', id: 'OR51I1'},
{value: 'OR51I2', id: 'OR51I2'},
{value: 'OR51K1P', id: 'OR51K1P'},
{value: 'OR51L1', id: 'OR51L1'},
{value: 'OR51M1', id: 'OR51M1'},
{value: 'OR51N1P', id: 'OR51N1P'},
{value: 'OR51P1P', id: 'OR51P1P'},
{value: 'OR51Q1', id: 'OR51Q1'},
{value: 'OR51R1P', id: 'OR51R1P'},
{value: 'OR51S1', id: 'OR51S1'},
{value: 'OR51V1', id: 'OR51V1'},
{value: 'OR52A1', id: 'OR52A1'},
{value: 'OR52A4P', id: 'OR52A4P'},
{value: 'OR52A5', id: 'OR52A5'},
{value: 'OR52B2', id: 'OR52B2'},
{value: 'OR52B3P', id: 'OR52B3P'},
{value: 'OR52B4', id: 'OR52B4'},
{value: 'OR52B5P', id: 'OR52B5P'},
{value: 'OR52B6', id: 'OR52B6'},
{value: 'OR52D1', id: 'OR52D1'},
{value: 'OR52E1', id: 'OR52E1'},
{value: 'OR52E2', id: 'OR52E2'},
{value: 'OR52E3P', id: 'OR52E3P'},
{value: 'OR52E4', id: 'OR52E4'},
{value: 'OR52E5', id: 'OR52E5'},
{value: 'OR52E6', id: 'OR52E6'},
{value: 'OR52H2P', id: 'OR52H2P'},
{value: 'OR52I1', id: 'OR52I1'},
{value: 'OR52I2', id: 'OR52I2'},
{value: 'OR52J1P', id: 'OR52J1P'},
{value: 'OR52J2P', id: 'OR52J2P'},
{value: 'OR52J3', id: 'OR52J3'},
{value: 'OR52K1', id: 'OR52K1'},
{value: 'OR52K2', id: 'OR52K2'},
{value: 'OR52K3P', id: 'OR52K3P'},
{value: 'OR52L1', id: 'OR52L1'},
{value: 'OR52M1', id: 'OR52M1'},
{value: 'OR52M2P', id: 'OR52M2P'},
{value: 'OR52N1', id: 'OR52N1'},
{value: 'OR52N2', id: 'OR52N2'},
{value: 'OR52N4', id: 'OR52N4'},
{value: 'OR52P1P', id: 'OR52P1P'},
{value: 'OR52P2P', id: 'OR52P2P'},
{value: 'OR52R1', id: 'OR52R1'},
{value: 'OR52S1P', id: 'OR52S1P'},
{value: 'OR52V1P', id: 'OR52V1P'},
{value: 'OR52W1', id: 'OR52W1'},
{value: 'OR52Y1P', id: 'OR52Y1P'},
{value: 'OR52Z1', id: 'OR52Z1'},
{value: 'OR55B1P', id: 'OR55B1P'},
{value: 'OR56A1', id: 'OR56A1'},
{value: 'OR56A3', id: 'OR56A3'},
{value: 'OR56A4', id: 'OR56A4'},
{value: 'OR56A5', id: 'OR56A5'},
{value: 'OR56B3P', id: 'OR56B3P'},
{value: 'OR56B4', id: 'OR56B4'},
{value: 'OR5A1', id: 'OR5A1'},
{value: 'OR5A2', id: 'OR5A2'},
{value: 'OR5AC1', id: 'OR5AC1'},
{value: 'OR5AC2', id: 'OR5AC2'},
{value: 'OR5AC4P', id: 'OR5AC4P'},
{value: 'OR5AH1P', id: 'OR5AH1P'},
{value: 'OR5AK1P', id: 'OR5AK1P'},
{value: 'OR5AK2', id: 'OR5AK2'},
{value: 'OR5AK3P', id: 'OR5AK3P'},
{value: 'OR5AK4P', id: 'OR5AK4P'},
{value: 'OR5AL1', id: 'OR5AL1'},
{value: 'OR5AL2P', id: 'OR5AL2P'},
{value: 'OR5AM1P', id: 'OR5AM1P'},
{value: 'OR5AN1', id: 'OR5AN1'},
{value: 'OR5AN2P', id: 'OR5AN2P'},
{value: 'OR5AO1P', id: 'OR5AO1P'},
{value: 'OR5AP1P', id: 'OR5AP1P'},
{value: 'OR5AP2', id: 'OR5AP2'},
{value: 'OR5AQ1P', id: 'OR5AQ1P'},
{value: 'OR5AR1', id: 'OR5AR1'},
{value: 'OR5AS1', id: 'OR5AS1'},
{value: 'OR5AU1', id: 'OR5AU1'},
{value: 'OR5AZ1P', id: 'OR5AZ1P'},
{value: 'OR5B10P', id: 'OR5B10P'},
{value: 'OR5B12', id: 'OR5B12'},
{value: 'OR5B15P', id: 'OR5B15P'},
{value: 'OR5B17', id: 'OR5B17'},
{value: 'OR5B19P', id: 'OR5B19P'},
{value: 'OR5B1P', id: 'OR5B1P'},
{value: 'OR5B2', id: 'OR5B2'},
{value: 'OR5B21', id: 'OR5B21'},
{value: 'OR5B3', id: 'OR5B3'},
{value: 'OR5BA1P', id: 'OR5BA1P'},
{value: 'OR5BB1P', id: 'OR5BB1P'},
{value: 'OR5BC1P', id: 'OR5BC1P'},
{value: 'OR5BD1P', id: 'OR5BD1P'},
{value: 'OR5BE1P', id: 'OR5BE1P'},
{value: 'OR5BM1P', id: 'OR5BM1P'},
{value: 'OR5BN2P', id: 'OR5BN2P'},
{value: 'OR5BP1P', id: 'OR5BP1P'},
{value: 'OR5BQ1P', id: 'OR5BQ1P'},
{value: 'OR5BR1P', id: 'OR5BR1P'},
{value: 'OR5BS1P', id: 'OR5BS1P'},
{value: 'OR5C1', id: 'OR5C1'},
{value: 'OR5D13', id: 'OR5D13'},
{value: 'OR5D14', id: 'OR5D14'},
{value: 'OR5D15P', id: 'OR5D15P'},
{value: 'OR5D16', id: 'OR5D16'},
{value: 'OR5D17P', id: 'OR5D17P'},
{value: 'OR5D18', id: 'OR5D18'},
{value: 'OR5D2P', id: 'OR5D2P'},
{value: 'OR5D3P', id: 'OR5D3P'},
{value: 'OR5E1P', id: 'OR5E1P'},
{value: 'OR5F1', id: 'OR5F1'},
{value: 'OR5F2P', id: 'OR5F2P'},
{value: 'OR5G1P', id: 'OR5G1P'},
{value: 'OR5G3', id: 'OR5G3'},
{value: 'OR5G4P', id: 'OR5G4P'},
{value: 'OR5G5P', id: 'OR5G5P'},
{value: 'OR5H14', id: 'OR5H14'},
{value: 'OR5H15', id: 'OR5H15'},
{value: 'OR5H2', id: 'OR5H2'},
{value: 'OR5H3P', id: 'OR5H3P'},
{value: 'OR5H4P', id: 'OR5H4P'},
{value: 'OR5H5P', id: 'OR5H5P'},
{value: 'OR5I1', id: 'OR5I1'},
{value: 'OR5J1P', id: 'OR5J1P'},
{value: 'OR5J2', id: 'OR5J2'},
{value: 'OR5K2', id: 'OR5K2'},
{value: 'OR5L1', id: 'OR5L1'},
{value: 'OR5L2', id: 'OR5L2'},
{value: 'OR5M1', id: 'OR5M1'},
{value: 'OR5M10', id: 'OR5M10'},
{value: 'OR5M11', id: 'OR5M11'},
{value: 'OR5M12P', id: 'OR5M12P'},
{value: 'OR5M13P', id: 'OR5M13P'},
{value: 'OR5M14P', id: 'OR5M14P'},
{value: 'OR5M2P', id: 'OR5M2P'},
{value: 'OR5M4P', id: 'OR5M4P'},
{value: 'OR5M5P', id: 'OR5M5P'},
{value: 'OR5M6P', id: 'OR5M6P'},
{value: 'OR5M7P', id: 'OR5M7P'},
{value: 'OR5M8', id: 'OR5M8'},
{value: 'OR5P1P', id: 'OR5P1P'},
{value: 'OR5S1P', id: 'OR5S1P'},
{value: 'OR5T1', id: 'OR5T1'},
{value: 'OR5T2', id: 'OR5T2'},
{value: 'OR5T3', id: 'OR5T3'},
{value: 'OR5V1', id: 'OR5V1'},
{value: 'OR5W1P', id: 'OR5W1P'},
{value: 'OR5W2', id: 'OR5W2'},
{value: 'OR6A2', id: 'OR6A2'},
{value: 'OR6B1', id: 'OR6B1'},
{value: 'OR6B2', id: 'OR6B2'},
{value: 'OR6B3', id: 'OR6B3'},
{value: 'OR6C1', id: 'OR6C1'},
{value: 'OR6C3', id: 'OR6C3'},
{value: 'OR6C6', id: 'OR6C6'},
{value: 'OR6C65', id: 'OR6C65'},
{value: 'OR6C66P', id: 'OR6C66P'},
{value: 'OR6C69P', id: 'OR6C69P'},
{value: 'OR6C71P', id: 'OR6C71P'},
{value: 'OR6C72P', id: 'OR6C72P'},
{value: 'OR6C74', id: 'OR6C74'},
{value: 'OR6C75', id: 'OR6C75'},
{value: 'OR6C76', id: 'OR6C76'},
{value: 'OR6D1P', id: 'OR6D1P'},
{value: 'OR6E1P', id: 'OR6E1P'},
{value: 'OR6J1', id: 'OR6J1'},
{value: 'OR6K1P', id: 'OR6K1P'},
{value: 'OR6K2', id: 'OR6K2'},
{value: 'OR6K3', id: 'OR6K3'},
{value: 'OR6K4P', id: 'OR6K4P'},
{value: 'OR6L1P', id: 'OR6L1P'},
{value: 'OR6L2P', id: 'OR6L2P'},
{value: 'OR6M1', id: 'OR6M1'},
{value: 'OR6M2P', id: 'OR6M2P'},
{value: 'OR6M3P', id: 'OR6M3P'},
{value: 'OR6N1', id: 'OR6N1'},
{value: 'OR6N2', id: 'OR6N2'},
{value: 'OR6P1', id: 'OR6P1'},
{value: 'OR6R1P', id: 'OR6R1P'},
{value: 'OR6R2P', id: 'OR6R2P'},
{value: 'OR6S1', id: 'OR6S1'},
{value: 'OR6T1', id: 'OR6T1'},
{value: 'OR6U2P', id: 'OR6U2P'},
{value: 'OR6V1', id: 'OR6V1'},
{value: 'OR6W1P', id: 'OR6W1P'},
{value: 'OR6X1', id: 'OR6X1'},
{value: 'OR6Y1', id: 'OR6Y1'},
{value: 'OR7A10', id: 'OR7A10'},
{value: 'OR7A11P', id: 'OR7A11P'},
{value: 'OR7A15P', id: 'OR7A15P'},
{value: 'OR7A17', id: 'OR7A17'},
{value: 'OR7A18P', id: 'OR7A18P'},
{value: 'OR7A1P', id: 'OR7A1P'},
{value: 'OR7A2P', id: 'OR7A2P'},
{value: 'OR7A3P', id: 'OR7A3P'},
{value: 'OR7A5', id: 'OR7A5'},
{value: 'OR7A8P', id: 'OR7A8P'},
{value: 'OR7C1', id: 'OR7C1'},
{value: 'OR7C2', id: 'OR7C2'},
{value: 'OR7D1P', id: 'OR7D1P'},
{value: 'OR7D2', id: 'OR7D2'},
{value: 'OR7D4', id: 'OR7D4'},
{value: 'OR7E100P', id: 'OR7E100P'},
{value: 'OR7E102P', id: 'OR7E102P'},
{value: 'OR7E104P', id: 'OR7E104P'},
{value: 'OR7E108P', id: 'OR7E108P'},
{value: 'OR7E109P', id: 'OR7E109P'},
{value: 'OR7E10P', id: 'OR7E10P'},
{value: 'OR7E110P', id: 'OR7E110P'},
{value: 'OR7E111FP', id: 'OR7E111FP'},
{value: 'OR7E111P', id: 'OR7E111P'},
{value: 'OR7E115P', id: 'OR7E115P'},
{value: 'OR7E116P', id: 'OR7E116P'},
{value: 'OR7E117P', id: 'OR7E117P'},
{value: 'OR7E11P', id: 'OR7E11P'},
{value: 'OR7E121P', id: 'OR7E121P'},
{value: 'OR7E125P', id: 'OR7E125P'},
{value: 'OR7E126P', id: 'OR7E126P'},
{value: 'OR7E128P', id: 'OR7E128P'},
{value: 'OR7E129P', id: 'OR7E129P'},
{value: 'OR7E12P', id: 'OR7E12P'},
{value: 'OR7E130P', id: 'OR7E130P'},
{value: 'OR7E136P', id: 'OR7E136P'},
{value: 'OR7E13P', id: 'OR7E13P'},
{value: 'OR7E145P', id: 'OR7E145P'},
{value: 'OR7E14P', id: 'OR7E14P'},
{value: 'OR7E156P', id: 'OR7E156P'},
{value: 'OR7E157P', id: 'OR7E157P'},
{value: 'OR7E158P', id: 'OR7E158P'},
{value: 'OR7E15P', id: 'OR7E15P'},
{value: 'OR7E161P', id: 'OR7E161P'},
{value: 'OR7E162P', id: 'OR7E162P'},
{value: 'OR7E163P', id: 'OR7E163P'},
{value: 'OR7E16P', id: 'OR7E16P'},
{value: 'OR7E18P', id: 'OR7E18P'},
{value: 'OR7E19P', id: 'OR7E19P'},
{value: 'OR7E1P', id: 'OR7E1P'},
{value: 'OR7E21P', id: 'OR7E21P'},
{value: 'OR7E22P', id: 'OR7E22P'},
{value: 'OR7E23P', id: 'OR7E23P'},
{value: 'OR7E24', id: 'OR7E24'},
{value: 'OR7E25P', id: 'OR7E25P'},
{value: 'OR7E26P', id: 'OR7E26P'},
{value: 'OR7E28P', id: 'OR7E28P'},
{value: 'OR7E29P', id: 'OR7E29P'},
{value: 'OR7E2P', id: 'OR7E2P'},
{value: 'OR7E31P', id: 'OR7E31P'},
{value: 'OR7E33P', id: 'OR7E33P'},
{value: 'OR7E35P', id: 'OR7E35P'},
{value: 'OR7E36P', id: 'OR7E36P'},
{value: 'OR7E37P', id: 'OR7E37P'},
{value: 'OR7E38P', id: 'OR7E38P'},
{value: 'OR7E39P', id: 'OR7E39P'},
{value: 'OR7E43P', id: 'OR7E43P'},
{value: 'OR7E47P', id: 'OR7E47P'},
{value: 'OR7E4P', id: 'OR7E4P'},
{value: 'OR7E53P', id: 'OR7E53P'},
{value: 'OR7E55P', id: 'OR7E55P'},
{value: 'OR7E59P', id: 'OR7E59P'},
{value: 'OR7E5P', id: 'OR7E5P'},
{value: 'OR7E66P', id: 'OR7E66P'},
{value: 'OR7E7P', id: 'OR7E7P'},
{value: 'OR7E83P', id: 'OR7E83P'},
{value: 'OR7E84P', id: 'OR7E84P'},
{value: 'OR7E85BP', id: 'OR7E85BP'},
{value: 'OR7E85P', id: 'OR7E85P'},
{value: 'OR7E86P', id: 'OR7E86P'},
{value: 'OR7E8P', id: 'OR7E8P'},
{value: 'OR7E90P', id: 'OR7E90P'},
{value: 'OR7E93P', id: 'OR7E93P'},
{value: 'OR7E94P', id: 'OR7E94P'},
{value: 'OR7E96P', id: 'OR7E96P'},
{value: 'OR7E97P', id: 'OR7E97P'},
{value: 'OR7E99P', id: 'OR7E99P'},
{value: 'OR7G1', id: 'OR7G1'},
{value: 'OR7G2', id: 'OR7G2'},
{value: 'OR7G3', id: 'OR7G3'},
{value: 'OR7H1P', id: 'OR7H1P'},
{value: 'OR7H2P', id: 'OR7H2P'},
{value: 'OR7K1P', id: 'OR7K1P'},
{value: 'OR7M1P', id: 'OR7M1P'},
{value: 'OR8A1', id: 'OR8A1'},
{value: 'OR8A2P', id: 'OR8A2P'},
{value: 'OR8A3P', id: 'OR8A3P'},
{value: 'OR8B10P', id: 'OR8B10P'},
{value: 'OR8B12', id: 'OR8B12'},
{value: 'OR8B1P', id: 'OR8B1P'},
{value: 'OR8B2', id: 'OR8B2'},
{value: 'OR8B3', id: 'OR8B3'},
{value: 'OR8B4', id: 'OR8B4'},
{value: 'OR8B5P', id: 'OR8B5P'},
{value: 'OR8B6P', id: 'OR8B6P'},
{value: 'OR8B7P', id: 'OR8B7P'},
{value: 'OR8B8', id: 'OR8B8'},
{value: 'OR8B9P', id: 'OR8B9P'},
{value: 'OR8C1P', id: 'OR8C1P'},
{value: 'OR8D1', id: 'OR8D1'},
{value: 'OR8D2', id: 'OR8D2'},
{value: 'OR8D4', id: 'OR8D4'},
{value: 'OR8F1P', id: 'OR8F1P'},
{value: 'OR8G1', id: 'OR8G1'},
{value: 'OR8G2P', id: 'OR8G2P'},
{value: 'OR8G3P', id: 'OR8G3P'},
{value: 'OR8G5', id: 'OR8G5'},
{value: 'OR8G7P', id: 'OR8G7P'},
{value: 'OR8H1', id: 'OR8H1'},
{value: 'OR8H2', id: 'OR8H2'},
{value: 'OR8H3', id: 'OR8H3'},
{value: 'OR8I1P', id: 'OR8I1P'},
{value: 'OR8I2', id: 'OR8I2'},
{value: 'OR8I4P', id: 'OR8I4P'},
{value: 'OR8J1', id: 'OR8J1'},
{value: 'OR8J2', id: 'OR8J2'},
{value: 'OR8J3', id: 'OR8J3'},
{value: 'OR8K1', id: 'OR8K1'},
{value: 'OR8K2P', id: 'OR8K2P'},
{value: 'OR8K3', id: 'OR8K3'},
{value: 'OR8K4P', id: 'OR8K4P'},
{value: 'OR8K5', id: 'OR8K5'},
{value: 'OR8Q1P', id: 'OR8Q1P'},
{value: 'OR8R1P', id: 'OR8R1P'},
{value: 'OR8S1', id: 'OR8S1'},
{value: 'OR8T1P', id: 'OR8T1P'},
{value: 'OR8U1', id: 'OR8U1'},
{value: 'OR8U3', id: 'OR8U3'},
{value: 'OR8V1P', id: 'OR8V1P'},
{value: 'OR8X1P', id: 'OR8X1P'},
{value: 'OR9A1P', id: 'OR9A1P'},
{value: 'OR9A2', id: 'OR9A2'},
{value: 'OR9A3P', id: 'OR9A3P'},
{value: 'OR9A4', id: 'OR9A4'},
{value: 'OR9G1', id: 'OR9G1'},
{value: 'OR9G2P', id: 'OR9G2P'},
{value: 'OR9G3P', id: 'OR9G3P'},
{value: 'OR9G4', id: 'OR9G4'},
{value: 'OR9H1P', id: 'OR9H1P'},
{value: 'OR9I3P', id: 'OR9I3P'},
{value: 'OR9K1P', id: 'OR9K1P'},
{value: 'OR9K2', id: 'OR9K2'},
{value: 'OR9M1P', id: 'OR9M1P'},
{value: 'OR9P1P', id: 'OR9P1P'},
{value: 'OR9Q1', id: 'OR9Q1'},
{value: 'OR9Q2', id: 'OR9Q2'},
{value: 'OR9R1P', id: 'OR9R1P'},
{value: 'OR9S24P', id: 'OR9S24P'},
{value: 'ORAI1', id: 'ORAI1'},
{value: 'ORAI2', id: 'ORAI2'},
{value: 'ORAI3', id: 'ORAI3'},
{value: 'ORC1', id: 'ORC1'},
{value: 'ORC2', id: 'ORC2'},
{value: 'ORC3', id: 'ORC3'},
{value: 'ORC4', id: 'ORC4'},
{value: 'ORC5', id: 'ORC5'},
{value: 'ORC6', id: 'ORC6'},
{value: 'ORM1', id: 'ORM1'},
{value: 'ORM2', id: 'ORM2'},
{value: 'ORMDL1', id: 'ORMDL1'},
{value: 'ORMDL2', id: 'ORMDL2'},
{value: 'ORMDL3', id: 'ORMDL3'},
{value: 'OS9', id: 'OS9'},
{value: 'OSBP', id: 'OSBP'},
{value: 'OSBP2', id: 'OSBP2'},
{value: 'OSBPL10', id: 'OSBPL10'},
{value: 'OSBPL10-AS1', id: 'OSBPL10-AS1'},
{value: 'OSBPL11', id: 'OSBPL11'},
{value: 'OSBPL1A', id: 'OSBPL1A'},
{value: 'OSBPL2', id: 'OSBPL2'},
{value: 'OSBPL3', id: 'OSBPL3'},
{value: 'OSBPL5', id: 'OSBPL5'},
{value: 'OSBPL6', id: 'OSBPL6'},
{value: 'OSBPL7', id: 'OSBPL7'},
{value: 'OSBPL8', id: 'OSBPL8'},
{value: 'OSBPL9', id: 'OSBPL9'},
{value: 'OSBPL9P1', id: 'OSBPL9P1'},
{value: 'OSBPL9P2', id: 'OSBPL9P2'},
{value: 'OSBPL9P3', id: 'OSBPL9P3'},
{value: 'OSCAR', id: 'OSCAR'},
{value: 'OSCP1', id: 'OSCP1'},
{value: 'OSER1', id: 'OSER1'},
{value: 'OSER1-DT', id: 'OSER1-DT'},
{value: 'OSGEP', id: 'OSGEP'},
{value: 'OSGEPL1', id: 'OSGEPL1'},
{value: 'OSGEPL1-AS1', id: 'OSGEPL1-AS1'},
{value: 'OSGIN1', id: 'OSGIN1'},
{value: 'OSGIN2', id: 'OSGIN2'},
{value: 'OSM', id: 'OSM'},
{value: 'OSMR', id: 'OSMR'},
{value: 'OSMR-DT', id: 'OSMR-DT'},
{value: 'OSR1', id: 'OSR1'},
{value: 'OSR2', id: 'OSR2'},
{value: 'OST4', id: 'OST4'},
{value: 'OSTC', id: 'OSTC'},
{value: 'OSTCP1', id: 'OSTCP1'},
{value: 'OSTCP2', id: 'OSTCP2'},
{value: 'OSTCP3', id: 'OSTCP3'},
{value: 'OSTCP4', id: 'OSTCP4'},
{value: 'OSTF1', id: 'OSTF1'},
{value: 'OSTF1P1', id: 'OSTF1P1'},
{value: 'OSTM1', id: 'OSTM1'},
{value: 'OSTM1-AS1', id: 'OSTM1-AS1'},
{value: 'OSTN', id: 'OSTN'},
{value: 'OTOA', id: 'OTOA'},
{value: 'OTOAP1', id: 'OTOAP1'},
{value: 'OTOF', id: 'OTOF'},
{value: 'OTOG', id: 'OTOG'},
{value: 'OTOGL', id: 'OTOGL'},
{value: 'OTOL1', id: 'OTOL1'},
{value: 'OTOP1', id: 'OTOP1'},
{value: 'OTOP2', id: 'OTOP2'},
{value: 'OTOP3', id: 'OTOP3'},
{value: 'OTOR', id: 'OTOR'},
{value: 'OTOS', id: 'OTOS'},
{value: 'OTP', id: 'OTP'},
{value: 'OTUB1', id: 'OTUB1'},
{value: 'OTUB2', id: 'OTUB2'},
{value: 'OTUD1', id: 'OTUD1'},
{value: 'OTUD3', id: 'OTUD3'},
{value: 'OTUD4', id: 'OTUD4'},
{value: 'OTUD4P1', id: 'OTUD4P1'},
{value: 'OTUD5', id: 'OTUD5'},
{value: 'OTUD6B', id: 'OTUD6B'},
{value: 'OTUD6B-AS1', id: 'OTUD6B-AS1'},
{value: 'OTUD7A', id: 'OTUD7A'},
{value: 'OTUD7B', id: 'OTUD7B'},
{value: 'OTULIN', id: 'OTULIN'},
{value: 'OTULIN-DT', id: 'OTULIN-DT'},
{value: 'OTULINL', id: 'OTULINL'},
{value: 'OTX1', id: 'OTX1'},
{value: 'OTX2', id: 'OTX2'},
{value: 'OTX2-AS1', id: 'OTX2-AS1'},
{value: 'OTX2P1', id: 'OTX2P1'},
{value: 'OTX2P2', id: 'OTX2P2'},
{value: 'OVAAL', id: 'OVAAL'},
{value: 'OVCA2', id: 'OVCA2'},
{value: 'OVCH1', id: 'OVCH1'},
{value: 'OVCH1-AS1', id: 'OVCH1-AS1'},
{value: 'OVCH2', id: 'OVCH2'},
{value: 'OVGP1', id: 'OVGP1'},
{value: 'OVOL1', id: 'OVOL1'},
{value: 'OVOL2', id: 'OVOL2'},
{value: 'OVOL3', id: 'OVOL3'},
{value: 'OXA1L', id: 'OXA1L'},
{value: 'OXA1L-DT', id: 'OXA1L-DT'},
{value: 'OXCT1', id: 'OXCT1'},
{value: 'OXCT1-AS1', id: 'OXCT1-AS1'},
{value: 'OXCT2', id: 'OXCT2'},
{value: 'OXCT2P1', id: 'OXCT2P1'},
{value: 'OXER1', id: 'OXER1'},
{value: 'OXGR1', id: 'OXGR1'},
{value: 'OXLD1', id: 'OXLD1'},
{value: 'OXNAD1', id: 'OXNAD1'},
{value: 'OXR1', id: 'OXR1'},
{value: 'OXR1-AS1', id: 'OXR1-AS1'},
{value: 'OXSM', id: 'OXSM'},
{value: 'OXSR1', id: 'OXSR1'},
{value: 'OXT', id: 'OXT'},
{value: 'OXTR', id: 'OXTR'},
{value: 'P2RX1', id: 'P2RX1'},
{value: 'P2RX2', id: 'P2RX2'},
{value: 'P2RX3', id: 'P2RX3'},
{value: 'P2RX4', id: 'P2RX4'},
{value: 'P2RX5', id: 'P2RX5'},
{value: 'P2RX5-TAX1BP3', id: 'P2RX5-TAX1BP3'},
{value: 'P2RX6', id: 'P2RX6'},
{value: 'P2RX6P', id: 'P2RX6P'},
{value: 'P2RX7', id: 'P2RX7'},
{value: 'P2RY1', id: 'P2RY1'},
{value: 'P2RY10', id: 'P2RY10'},
{value: 'P2RY11', id: 'P2RY11'},
{value: 'P2RY12', id: 'P2RY12'},
{value: 'P2RY13', id: 'P2RY13'},
{value: 'P2RY14', id: 'P2RY14'},
{value: 'P2RY2', id: 'P2RY2'},
{value: 'P2RY6', id: 'P2RY6'},
{value: 'P2RY8', id: 'P2RY8'},
{value: 'P3H1', id: 'P3H1'},
{value: 'P3H2', id: 'P3H2'},
{value: 'P3H2-AS1', id: 'P3H2-AS1'},
{value: 'P3H3', id: 'P3H3'},
{value: 'P3H4', id: 'P3H4'},
{value: 'P3R3URF-PIK3R3', id: 'P3R3URF-PIK3R3'},
{value: 'P4HA1', id: 'P4HA1'},
{value: 'P4HA2', id: 'P4HA2'},
{value: 'P4HA2-AS1', id: 'P4HA2-AS1'},
{value: 'P4HA3', id: 'P4HA3'},
{value: 'P4HA3-AS1', id: 'P4HA3-AS1'},
{value: 'P4HB', id: 'P4HB'},
{value: 'P4HTM', id: 'P4HTM'},
{value: 'PA2G4', id: 'PA2G4'},
{value: 'PA2G4P2', id: 'PA2G4P2'},
{value: 'PA2G4P3', id: 'PA2G4P3'},
{value: 'PA2G4P4', id: 'PA2G4P4'},
{value: 'PA2G4P5', id: 'PA2G4P5'},
{value: 'PA2G4P6', id: 'PA2G4P6'},
{value: 'PAAF1', id: 'PAAF1'},
{value: 'PABIR1', id: 'PABIR1'},
{value: 'PABIR2', id: 'PABIR2'},
{value: 'PABIR3', id: 'PABIR3'},
{value: 'PABPC1', id: 'PABPC1'},
{value: 'PABPC1L', id: 'PABPC1L'},
{value: 'PABPC1P10', id: 'PABPC1P10'},
{value: 'PABPC1P2', id: 'PABPC1P2'},
{value: 'PABPC1P4', id: 'PABPC1P4'},
{value: 'PABPC1P8', id: 'PABPC1P8'},
{value: 'PABPC3', id: 'PABPC3'},
{value: 'PABPC4', id: 'PABPC4'},
{value: 'PABPC4-AS1', id: 'PABPC4-AS1'},
{value: 'PABPC4L', id: 'PABPC4L'},
{value: 'PABPC5', id: 'PABPC5'},
{value: 'PABPN1', id: 'PABPN1'},
{value: 'PABPN1L', id: 'PABPN1L'},
{value: 'PABPN1P2', id: 'PABPN1P2'},
{value: 'PACC1', id: 'PACC1'},
{value: 'PACERR', id: 'PACERR'},
{value: 'PACRG', id: 'PACRG'},
{value: 'PACRG-AS1', id: 'PACRG-AS1'},
{value: 'PACRG-AS2', id: 'PACRG-AS2'},
{value: 'PACRG-AS3', id: 'PACRG-AS3'},
{value: 'PACRGL', id: 'PACRGL'},
{value: 'PACS1', id: 'PACS1'},
{value: 'PACS2', id: 'PACS2'},
{value: 'PACSIN1', id: 'PACSIN1'},
{value: 'PACSIN2', id: 'PACSIN2'},
{value: 'PACSIN3', id: 'PACSIN3'},
{value: 'PADI1', id: 'PADI1'},
{value: 'PADI2', id: 'PADI2'},
{value: 'PADI3', id: 'PADI3'},
{value: 'PADI4', id: 'PADI4'},
{value: 'PADI6', id: 'PADI6'},
{value: 'PAEP', id: 'PAEP'},
{value: 'PAEPP1', id: 'PAEPP1'},
{value: 'PAF1', id: 'PAF1'},
{value: 'PAFAH1B1', id: 'PAFAH1B1'},
{value: 'PAFAH1B2', id: 'PAFAH1B2'},
{value: 'PAFAH1B2P2', id: 'PAFAH1B2P2'},
{value: 'PAFAH1B3', id: 'PAFAH1B3'},
{value: 'PAFAH2', id: 'PAFAH2'},
{value: 'PAG1', id: 'PAG1'},
{value: 'PAGE2B', id: 'PAGE2B'},
{value: 'PAGR1', id: 'PAGR1'},
{value: 'PAH', id: 'PAH'},
{value: 'PAICS', id: 'PAICS'},
{value: 'PAICSP1', id: 'PAICSP1'},
{value: 'PAICSP2', id: 'PAICSP2'},
{value: 'PAICSP3', id: 'PAICSP3'},
{value: 'PAICSP4', id: 'PAICSP4'},
{value: 'PAICSP5', id: 'PAICSP5'},
{value: 'PAIP1', id: 'PAIP1'},
{value: 'PAIP1P1', id: 'PAIP1P1'},
{value: 'PAIP2', id: 'PAIP2'},
{value: 'PAIP2B', id: 'PAIP2B'},
{value: 'PAK1', id: 'PAK1'},
{value: 'PAK1IP1', id: 'PAK1IP1'},
{value: 'PAK2', id: 'PAK2'},
{value: 'PAK3', id: 'PAK3'},
{value: 'PAK4', id: 'PAK4'},
{value: 'PAK5', id: 'PAK5'},
{value: 'PAK6', id: 'PAK6'},
{value: 'PAK6-AS1', id: 'PAK6-AS1'},
{value: 'PALB2', id: 'PALB2'},
{value: 'PALD1', id: 'PALD1'},
{value: 'PALLD', id: 'PALLD'},
{value: 'PALM', id: 'PALM'},
{value: 'PALM2AKAP2', id: 'PALM2AKAP2'},
{value: 'PALM3', id: 'PALM3'},
{value: 'PALMD', id: 'PALMD'},
{value: 'PALS1', id: 'PALS1'},
{value: 'PALS2', id: 'PALS2'},
{value: 'PAM', id: 'PAM'},
{value: 'PAM16', id: 'PAM16'},
{value: 'PAMR1', id: 'PAMR1'},
{value: 'PAN2', id: 'PAN2'},
{value: 'PAN3', id: 'PAN3'},
{value: 'PAN3-AS1', id: 'PAN3-AS1'},
{value: 'PANCR', id: 'PANCR'},
{value: 'PANDAR', id: 'PANDAR'},
{value: 'PANK1', id: 'PANK1'},
{value: 'PANK2', id: 'PANK2'},
{value: 'PANK2-AS1', id: 'PANK2-AS1'},
{value: 'PANK3', id: 'PANK3'},
{value: 'PANK4', id: 'PANK4'},
{value: 'PANTR1', id: 'PANTR1'},
{value: 'PANX1', id: 'PANX1'},
{value: 'PANX2', id: 'PANX2'},
{value: 'PANX3', id: 'PANX3'},
{value: 'PAOX', id: 'PAOX'},
{value: 'PAPLN', id: 'PAPLN'},
{value: 'PAPLN-AS1', id: 'PAPLN-AS1'},
{value: 'PAPOLA', id: 'PAPOLA'},
{value: 'PAPOLA-DT', id: 'PAPOLA-DT'},
{value: 'PAPOLB', id: 'PAPOLB'},
{value: 'PAPOLG', id: 'PAPOLG'},
{value: 'PAPPA', id: 'PAPPA'},
{value: 'PAPPA-AS1', id: 'PAPPA-AS1'},
{value: 'PAPPA-AS2', id: 'PAPPA-AS2'},
{value: 'PAPPA2', id: 'PAPPA2'},
{value: 'PAPSS1', id: 'PAPSS1'},
{value: 'PAPSS2', id: 'PAPSS2'},
{value: 'PAQR3', id: 'PAQR3'},
{value: 'PAQR4', id: 'PAQR4'},
{value: 'PAQR5', id: 'PAQR5'},
{value: 'PAQR5-DT', id: 'PAQR5-DT'},
{value: 'PAQR6', id: 'PAQR6'},
{value: 'PAQR7', id: 'PAQR7'},
{value: 'PAQR8', id: 'PAQR8'},
{value: 'PAQR9', id: 'PAQR9'},
{value: 'PAQR9-AS1', id: 'PAQR9-AS1'},
{value: 'PARAL1', id: 'PARAL1'},
{value: 'PARD3', id: 'PARD3'},
{value: 'PARD3-AS1', id: 'PARD3-AS1'},
{value: 'PARD3B', id: 'PARD3B'},
{value: 'PARD6A', id: 'PARD6A'},
{value: 'PARD6B', id: 'PARD6B'},
{value: 'PARD6G', id: 'PARD6G'},
{value: 'PARD6G-AS1', id: 'PARD6G-AS1'},
{value: 'PARG', id: 'PARG'},
{value: 'PARGP1', id: 'PARGP1'},
{value: 'PARK7', id: 'PARK7'},
{value: 'PARL', id: 'PARL'},
{value: 'PARM1', id: 'PARM1'},
{value: 'PARM1-AS1', id: 'PARM1-AS1'},
{value: 'PARN', id: 'PARN'},
{value: 'PARP1', id: 'PARP1'},
{value: 'PARP10', id: 'PARP10'},
{value: 'PARP11', id: 'PARP11'},
{value: 'PARP11-AS1', id: 'PARP11-AS1'},
{value: 'PARP12', id: 'PARP12'},
{value: 'PARP14', id: 'PARP14'},
{value: 'PARP15', id: 'PARP15'},
{value: 'PARP16', id: 'PARP16'},
{value: 'PARP1P1', id: 'PARP1P1'},
{value: 'PARP1P2', id: 'PARP1P2'},
{value: 'PARP2', id: 'PARP2'},
{value: 'PARP3', id: 'PARP3'},
{value: 'PARP4', id: 'PARP4'},
{value: 'PARP4P2', id: 'PARP4P2'},
{value: 'PARP6', id: 'PARP6'},
{value: 'PARP8', id: 'PARP8'},
{value: 'PARP9', id: 'PARP9'},
{value: 'PARPBP', id: 'PARPBP'},
{value: 'PARS2', id: 'PARS2'},
{value: 'PART1', id: 'PART1'},
{value: 'PARTICL', id: 'PARTICL'},
{value: 'PARVA', id: 'PARVA'},
{value: 'PARVB', id: 'PARVB'},
{value: 'PARVG', id: 'PARVG'},
{value: 'PASK', id: 'PASK'},
{value: 'PATE1', id: 'PATE1'},
{value: 'PATE2', id: 'PATE2'},
{value: 'PATE3', id: 'PATE3'},
{value: 'PATE4', id: 'PATE4'},
{value: 'PATJ', id: 'PATJ'},
{value: 'PATJ-DT', id: 'PATJ-DT'},
{value: 'PATL1', id: 'PATL1'},
{value: 'PATL1-DT', id: 'PATL1-DT'},
{value: 'PATL2', id: 'PATL2'},
{value: 'PATZ1', id: 'PATZ1'},
{value: 'PAUPAR', id: 'PAUPAR'},
{value: 'PAWR', id: 'PAWR'},
{value: 'PAWRP1', id: 'PAWRP1'},
{value: 'PAX1', id: 'PAX1'},
{value: 'PAX2', id: 'PAX2'},
{value: 'PAX3', id: 'PAX3'},
{value: 'PAX4', id: 'PAX4'},
{value: 'PAX5', id: 'PAX5'},
{value: 'PAX6', id: 'PAX6'},
{value: 'PAX7', id: 'PAX7'},
{value: 'PAX8', id: 'PAX8'},
{value: 'PAX8-AS1', id: 'PAX8-AS1'},
{value: 'PAX9', id: 'PAX9'},
{value: 'PAXBP1', id: 'PAXBP1'},
{value: 'PAXBP1-AS1', id: 'PAXBP1-AS1'},
{value: 'PAXBP1P1', id: 'PAXBP1P1'},
{value: 'PAXIP1', id: 'PAXIP1'},
{value: 'PAXIP1-AS2', id: 'PAXIP1-AS2'},
{value: 'PAXIP1-DT', id: 'PAXIP1-DT'},
{value: 'PAXX', id: 'PAXX'},
{value: 'PBDC1', id: 'PBDC1'},
{value: 'PBK', id: 'PBK'},
{value: 'PBLD', id: 'PBLD'},
{value: 'PBRM1', id: 'PBRM1'},
{value: 'PBX1', id: 'PBX1'},
{value: 'PBX1-AS1', id: 'PBX1-AS1'},
{value: 'PBX2', id: 'PBX2'},
{value: 'PBX2P1', id: 'PBX2P1'},
{value: 'PBX3', id: 'PBX3'},
{value: 'PBX4', id: 'PBX4'},
{value: 'PBXIP1', id: 'PBXIP1'},
{value: 'PC', id: 'PC'},
{value: 'PCA3', id: 'PCA3'},
{value: 'PCARE', id: 'PCARE'},
{value: 'PCAT1', id: 'PCAT1'},
{value: 'PCAT14', id: 'PCAT14'},
{value: 'PCAT18', id: 'PCAT18'},
{value: 'PCAT19', id: 'PCAT19'},
{value: 'PCAT4', id: 'PCAT4'},
{value: 'PCAT5', id: 'PCAT5'},
{value: 'PCAT6', id: 'PCAT6'},
{value: 'PCAT7', id: 'PCAT7'},
{value: 'PCBD1', id: 'PCBD1'},
{value: 'PCBD2', id: 'PCBD2'},
{value: 'PCBP1', id: 'PCBP1'},
{value: 'PCBP1-AS1', id: 'PCBP1-AS1'},
{value: 'PCBP2', id: 'PCBP2'},
{value: 'PCBP2-OT1', id: 'PCBP2-OT1'},
{value: 'PCBP2P1', id: 'PCBP2P1'},
{value: 'PCBP2P2', id: 'PCBP2P2'},
{value: 'PCBP2P3', id: 'PCBP2P3'},
{value: 'PCBP3', id: 'PCBP3'},
{value: 'PCBP4', id: 'PCBP4'},
{value: 'PCCA', id: 'PCCA'},
{value: 'PCCA-DT', id: 'PCCA-DT'},
{value: 'PCCB', id: 'PCCB'},
{value: 'PCDH1', id: 'PCDH1'},
{value: 'PCDH10', id: 'PCDH10'},
{value: 'PCDH10-DT', id: 'PCDH10-DT'},
{value: 'PCDH12', id: 'PCDH12'},
{value: 'PCDH15', id: 'PCDH15'},
{value: 'PCDH17', id: 'PCDH17'},
{value: 'PCDH18', id: 'PCDH18'},
{value: 'PCDH19', id: 'PCDH19'},
{value: 'PCDH20', id: 'PCDH20'},
{value: 'PCDH7', id: 'PCDH7'},
{value: 'PCDH8', id: 'PCDH8'},
{value: 'PCDH8P1', id: 'PCDH8P1'},
{value: 'PCDH9', id: 'PCDH9'},
{value: 'PCDH9-AS1', id: 'PCDH9-AS1'},
{value: 'PCDH9-AS3', id: 'PCDH9-AS3'},
{value: 'PCDHA1', id: 'PCDHA1'},
{value: 'PCDHA10', id: 'PCDHA10'},
{value: 'PCDHA11', id: 'PCDHA11'},
{value: 'PCDHA12', id: 'PCDHA12'},
{value: 'PCDHA13', id: 'PCDHA13'},
{value: 'PCDHA2', id: 'PCDHA2'},
{value: 'PCDHA3', id: 'PCDHA3'},
{value: 'PCDHA4', id: 'PCDHA4'},
{value: 'PCDHA5', id: 'PCDHA5'},
{value: 'PCDHA6', id: 'PCDHA6'},
{value: 'PCDHA7', id: 'PCDHA7'},
{value: 'PCDHA9', id: 'PCDHA9'},
{value: 'PCDHAC1', id: 'PCDHAC1'},
{value: 'PCDHAC2', id: 'PCDHAC2'},
{value: 'PCDHB1', id: 'PCDHB1'},
{value: 'PCDHB10', id: 'PCDHB10'},
{value: 'PCDHB11', id: 'PCDHB11'},
{value: 'PCDHB12', id: 'PCDHB12'},
{value: 'PCDHB13', id: 'PCDHB13'},
{value: 'PCDHB14', id: 'PCDHB14'},
{value: 'PCDHB15', id: 'PCDHB15'},
{value: 'PCDHB16', id: 'PCDHB16'},
{value: 'PCDHB17P', id: 'PCDHB17P'},
{value: 'PCDHB18P', id: 'PCDHB18P'},
{value: 'PCDHB19P', id: 'PCDHB19P'},
{value: 'PCDHB2', id: 'PCDHB2'},
{value: 'PCDHB3', id: 'PCDHB3'},
{value: 'PCDHB4', id: 'PCDHB4'},
{value: 'PCDHB5', id: 'PCDHB5'},
{value: 'PCDHB6', id: 'PCDHB6'},
{value: 'PCDHB7', id: 'PCDHB7'},
{value: 'PCDHB8', id: 'PCDHB8'},
{value: 'PCDHB9', id: 'PCDHB9'},
{value: 'PCDHGA1', id: 'PCDHGA1'},
{value: 'PCDHGA10', id: 'PCDHGA10'},
{value: 'PCDHGA11', id: 'PCDHGA11'},
{value: 'PCDHGA12', id: 'PCDHGA12'},
{value: 'PCDHGA2', id: 'PCDHGA2'},
{value: 'PCDHGA3', id: 'PCDHGA3'},
{value: 'PCDHGA4', id: 'PCDHGA4'},
{value: 'PCDHGA5', id: 'PCDHGA5'},
{value: 'PCDHGA6', id: 'PCDHGA6'},
{value: 'PCDHGA7', id: 'PCDHGA7'},
{value: 'PCDHGA8', id: 'PCDHGA8'},
{value: 'PCDHGA9', id: 'PCDHGA9'},
{value: 'PCDHGB1', id: 'PCDHGB1'},
{value: 'PCDHGB2', id: 'PCDHGB2'},
{value: 'PCDHGB3', id: 'PCDHGB3'},
{value: 'PCDHGB4', id: 'PCDHGB4'},
{value: 'PCDHGB5', id: 'PCDHGB5'},
{value: 'PCDHGB6', id: 'PCDHGB6'},
{value: 'PCDHGB7', id: 'PCDHGB7'},
{value: 'PCDHGB9P', id: 'PCDHGB9P'},
{value: 'PCDHGC3', id: 'PCDHGC3'},
{value: 'PCDHGC4', id: 'PCDHGC4'},
{value: 'PCDHGC5', id: 'PCDHGC5'},
{value: 'PCED1A', id: 'PCED1A'},
{value: 'PCED1B', id: 'PCED1B'},
{value: 'PCED1B-AS1', id: 'PCED1B-AS1'},
{value: 'PCED1CP', id: 'PCED1CP'},
{value: 'PCF11', id: 'PCF11'},
{value: 'PCF11-AS1', id: 'PCF11-AS1'},
{value: 'PCGEM1', id: 'PCGEM1'},
{value: 'PCGF1', id: 'PCGF1'},
{value: 'PCGF2', id: 'PCGF2'},
{value: 'PCGF3', id: 'PCGF3'},
{value: 'PCGF5', id: 'PCGF5'},
{value: 'PCGF6', id: 'PCGF6'},
{value: 'PCGF7P', id: 'PCGF7P'},
{value: 'PCID2', id: 'PCID2'},
{value: 'PCIF1', id: 'PCIF1'},
{value: 'PCK1', id: 'PCK1'},
{value: 'PCK2', id: 'PCK2'},
{value: 'PCLAF', id: 'PCLAF'},
{value: 'PCLO', id: 'PCLO'},
{value: 'PCM1', id: 'PCM1'},
{value: 'PCMT1', id: 'PCMT1'},
{value: 'PCMTD1', id: 'PCMTD1'},
{value: 'PCMTD1P2', id: 'PCMTD1P2'},
{value: 'PCMTD1P7', id: 'PCMTD1P7'},
{value: 'PCMTD2', id: 'PCMTD2'},
{value: 'PCNA', id: 'PCNA'},
{value: 'PCNAP1', id: 'PCNAP1'},
{value: 'PCNAP4', id: 'PCNAP4'},
{value: 'PCNP', id: 'PCNP'},
{value: 'PCNPP1', id: 'PCNPP1'},
{value: 'PCNPP2', id: 'PCNPP2'},
{value: 'PCNPP3', id: 'PCNPP3'},
{value: 'PCNT', id: 'PCNT'},
{value: 'PCNX1', id: 'PCNX1'},
{value: 'PCNX2', id: 'PCNX2'},
{value: 'PCNX3', id: 'PCNX3'},
{value: 'PCNX4', id: 'PCNX4'},
{value: 'PCNX4-DT', id: 'PCNX4-DT'},
{value: 'PCOLCE', id: 'PCOLCE'},
{value: 'PCOLCE-AS1', id: 'PCOLCE-AS1'},
{value: 'PCOLCE2', id: 'PCOLCE2'},
{value: 'PCOTH', id: 'PCOTH'},
{value: 'PCP2', id: 'PCP2'},
{value: 'PCP4', id: 'PCP4'},
{value: 'PCP4L1', id: 'PCP4L1'},
{value: 'PCSEAT', id: 'PCSEAT'},
{value: 'PCSK1', id: 'PCSK1'},
{value: 'PCSK1N', id: 'PCSK1N'},
{value: 'PCSK2', id: 'PCSK2'},
{value: 'PCSK4', id: 'PCSK4'},
{value: 'PCSK5', id: 'PCSK5'},
{value: 'PCSK6', id: 'PCSK6'},
{value: 'PCSK6-AS1', id: 'PCSK6-AS1'},
{value: 'PCSK7', id: 'PCSK7'},
{value: 'PCSK9', id: 'PCSK9'},
{value: 'PCTP', id: 'PCTP'},
{value: 'PCYOX1', id: 'PCYOX1'},
{value: 'PCYOX1L', id: 'PCYOX1L'},
{value: 'PCYT1A', id: 'PCYT1A'},
{value: 'PCYT1B', id: 'PCYT1B'},
{value: 'PCYT2', id: 'PCYT2'},
{value: 'PDAP1', id: 'PDAP1'},
{value: 'PDC-AS1', id: 'PDC-AS1'},
{value: 'PDCD1', id: 'PDCD1'},
{value: 'PDCD10', id: 'PDCD10'},
{value: 'PDCD11', id: 'PDCD11'},
{value: 'PDCD1LG2', id: 'PDCD1LG2'},
{value: 'PDCD2', id: 'PDCD2'},
{value: 'PDCD2L', id: 'PDCD2L'},
{value: 'PDCD4', id: 'PDCD4'},
{value: 'PDCD4-AS1', id: 'PDCD4-AS1'},
{value: 'PDCD5', id: 'PDCD5'},
{value: 'PDCD5P1', id: 'PDCD5P1'},
{value: 'PDCD5P2', id: 'PDCD5P2'},
{value: 'PDCD6', id: 'PDCD6'},
{value: 'PDCD6-AHRR', id: 'PDCD6-AHRR'},
{value: 'PDCD6-DT', id: 'PDCD6-DT'},
{value: 'PDCD6IP', id: 'PDCD6IP'},
{value: 'PDCD6IP-DT', id: 'PDCD6IP-DT'},
{value: 'PDCD6IPP1', id: 'PDCD6IPP1'},
{value: 'PDCD6IPP2', id: 'PDCD6IPP2'},
{value: 'PDCD7', id: 'PDCD7'},
{value: 'PDCL', id: 'PDCL'},
{value: 'PDCL2', id: 'PDCL2'},
{value: 'PDCL2P2', id: 'PDCL2P2'},
{value: 'PDCL3', id: 'PDCL3'},
{value: 'PDCL3P4', id: 'PDCL3P4'},
{value: 'PDCL3P5', id: 'PDCL3P5'},
{value: 'PDCL3P7', id: 'PDCL3P7'},
{value: 'PDE10A', id: 'PDE10A'},
{value: 'PDE11A', id: 'PDE11A'},
{value: 'PDE12', id: 'PDE12'},
{value: 'PDE1A', id: 'PDE1A'},
{value: 'PDE1B', id: 'PDE1B'},
{value: 'PDE1C', id: 'PDE1C'},
{value: 'PDE2A', id: 'PDE2A'},
{value: 'PDE2A-AS1', id: 'PDE2A-AS1'},
{value: 'PDE2A-AS2', id: 'PDE2A-AS2'},
{value: 'PDE3A', id: 'PDE3A'},
{value: 'PDE3A-AS1', id: 'PDE3A-AS1'},
{value: 'PDE3B', id: 'PDE3B'},
{value: 'PDE4A', id: 'PDE4A'},
{value: 'PDE4B', id: 'PDE4B'},
{value: 'PDE4C', id: 'PDE4C'},
{value: 'PDE4D', id: 'PDE4D'},
{value: 'PDE4DIP', id: 'PDE4DIP'},
{value: 'PDE4DIPP1', id: 'PDE4DIPP1'},
{value: 'PDE4DIPP10', id: 'PDE4DIPP10'},
{value: 'PDE4DIPP2', id: 'PDE4DIPP2'},
{value: 'PDE4DIPP3', id: 'PDE4DIPP3'},
{value: 'PDE4DIPP4', id: 'PDE4DIPP4'},
{value: 'PDE4DIPP5', id: 'PDE4DIPP5'},
{value: 'PDE4DIPP6', id: 'PDE4DIPP6'},
{value: 'PDE4DIPP7', id: 'PDE4DIPP7'},
{value: 'PDE4DIPP8', id: 'PDE4DIPP8'},
{value: 'PDE5A', id: 'PDE5A'},
{value: 'PDE6A', id: 'PDE6A'},
{value: 'PDE6B', id: 'PDE6B'},
{value: 'PDE6B-AS1', id: 'PDE6B-AS1'},
{value: 'PDE6C', id: 'PDE6C'},
{value: 'PDE6D', id: 'PDE6D'},
{value: 'PDE6G', id: 'PDE6G'},
{value: 'PDE6H', id: 'PDE6H'},
{value: 'PDE7A', id: 'PDE7A'},
{value: 'PDE7A-DT', id: 'PDE7A-DT'},
{value: 'PDE7B', id: 'PDE7B'},
{value: 'PDE8A', id: 'PDE8A'},
{value: 'PDE8B', id: 'PDE8B'},
{value: 'PDE9A', id: 'PDE9A'},
{value: 'PDE9A-AS1', id: 'PDE9A-AS1'},
{value: 'PDF', id: 'PDF'},
{value: 'PDGFA', id: 'PDGFA'},
{value: 'PDGFB', id: 'PDGFB'},
{value: 'PDGFC', id: 'PDGFC'},
{value: 'PDGFD', id: 'PDGFD'},
{value: 'PDGFRA', id: 'PDGFRA'},
{value: 'PDGFRB', id: 'PDGFRB'},
{value: 'PDGFRL', id: 'PDGFRL'},
{value: 'PDGFRL2P', id: 'PDGFRL2P'},
{value: 'PDHA1', id: 'PDHA1'},
{value: 'PDHA1P1', id: 'PDHA1P1'},
{value: 'PDHA2', id: 'PDHA2'},
{value: 'PDHB', id: 'PDHB'},
{value: 'PDHX', id: 'PDHX'},
{value: 'PDIA2', id: 'PDIA2'},
{value: 'PDIA3', id: 'PDIA3'},
{value: 'PDIA3P1', id: 'PDIA3P1'},
{value: 'PDIA3P2', id: 'PDIA3P2'},
{value: 'PDIA4', id: 'PDIA4'},
{value: 'PDIA5', id: 'PDIA5'},
{value: 'PDIA6', id: 'PDIA6'},
{value: 'PDIK1L', id: 'PDIK1L'},
{value: 'PDILT', id: 'PDILT'},
{value: 'PDK1', id: 'PDK1'},
{value: 'PDK2', id: 'PDK2'},
{value: 'PDK3', id: 'PDK3'},
{value: 'PDK4', id: 'PDK4'},
{value: 'PDLIM1', id: 'PDLIM1'},
{value: 'PDLIM1P1', id: 'PDLIM1P1'},
{value: 'PDLIM1P2', id: 'PDLIM1P2'},
{value: 'PDLIM1P3', id: 'PDLIM1P3'},
{value: 'PDLIM1P4', id: 'PDLIM1P4'},
{value: 'PDLIM2', id: 'PDLIM2'},
{value: 'PDLIM3', id: 'PDLIM3'},
{value: 'PDLIM4', id: 'PDLIM4'},
{value: 'PDLIM5', id: 'PDLIM5'},
{value: 'PDLIM7', id: 'PDLIM7'},
{value: 'PDLIM7-AS1', id: 'PDLIM7-AS1'},
{value: 'PDP1', id: 'PDP1'},
{value: 'PDP2', id: 'PDP2'},
{value: 'PDPK1', id: 'PDPK1'},
{value: 'PDPK2P', id: 'PDPK2P'},
{value: 'PDPN', id: 'PDPN'},
{value: 'PDPR', id: 'PDPR'},
{value: 'PDPR2P', id: 'PDPR2P'},
{value: 'PDRG1', id: 'PDRG1'},
{value: 'PDS5A', id: 'PDS5A'},
{value: 'PDS5B', id: 'PDS5B'},
{value: 'PDSS1', id: 'PDSS1'},
{value: 'PDSS1P1', id: 'PDSS1P1'},
{value: 'PDSS1P2', id: 'PDSS1P2'},
{value: 'PDSS2', id: 'PDSS2'},
{value: 'PDX1', id: 'PDX1'},
{value: 'PDXDC1', id: 'PDXDC1'},
{value: 'PDXDC2P', id: 'PDXDC2P'},
{value: 'PDXDC2P-NPIPB14P', id: 'PDXDC2P-NPIPB14P'},
{value: 'PDXK', id: 'PDXK'},
{value: 'PDXP', id: 'PDXP'},
{value: 'PDXP-DT', id: 'PDXP-DT'},
{value: 'PDYN', id: 'PDYN'},
{value: 'PDYN-AS1', id: 'PDYN-AS1'},
{value: 'PDZD11', id: 'PDZD11'},
{value: 'PDZD2', id: 'PDZD2'},
{value: 'PDZD3', id: 'PDZD3'},
{value: 'PDZD4', id: 'PDZD4'},
{value: 'PDZD7', id: 'PDZD7'},
{value: 'PDZD8', id: 'PDZD8'},
{value: 'PDZD9', id: 'PDZD9'},
{value: 'PDZK1', id: 'PDZK1'},
{value: 'PDZK1IP1', id: 'PDZK1IP1'},
{value: 'PDZK1P1', id: 'PDZK1P1'},
{value: 'PDZPH1P', id: 'PDZPH1P'},
{value: 'PDZRN3', id: 'PDZRN3'},
{value: 'PDZRN3-AS1', id: 'PDZRN3-AS1'},
{value: 'PDZRN4', id: 'PDZRN4'},
{value: 'PEA15', id: 'PEA15'},
{value: 'PEAK1', id: 'PEAK1'},
{value: 'PEAK3', id: 'PEAK3'},
{value: 'PEAR1', id: 'PEAR1'},
{value: 'PEBP1', id: 'PEBP1'},
{value: 'PEBP1P1', id: 'PEBP1P1'},
{value: 'PEBP1P2', id: 'PEBP1P2'},
{value: 'PEBP1P3', id: 'PEBP1P3'},
{value: 'PEBP4', id: 'PEBP4'},
{value: 'PECAM1', id: 'PECAM1'},
{value: 'PECR', id: 'PECR'},
{value: 'PEDS1', id: 'PEDS1'},
{value: 'PEDS1-UBE2V1', id: 'PEDS1-UBE2V1'},
{value: 'PEF1', id: 'PEF1'},
{value: 'PEF1-AS1', id: 'PEF1-AS1'},
{value: 'PEG10', id: 'PEG10'},
{value: 'PEG13', id: 'PEG13'},
{value: 'PEG3', id: 'PEG3'},
{value: 'PELATON', id: 'PELATON'},
{value: 'PELI1', id: 'PELI1'},
{value: 'PELI2', id: 'PELI2'},
{value: 'PELI3', id: 'PELI3'},
{value: 'PELO', id: 'PELO'},
{value: 'PELP1', id: 'PELP1'},
{value: 'PEMT', id: 'PEMT'},
{value: 'PENK', id: 'PENK'},
{value: 'PENK-AS1', id: 'PENK-AS1'},
{value: 'PEPD', id: 'PEPD'},
{value: 'PER1', id: 'PER1'},
{value: 'PER2', id: 'PER2'},
{value: 'PER3', id: 'PER3'},
{value: 'PERCC1', id: 'PERCC1'},
{value: 'PERM1', id: 'PERM1'},
{value: 'PERP', id: 'PERP'},
{value: 'PES1', id: 'PES1'},
{value: 'PES1P1', id: 'PES1P1'},
{value: 'PES1P2', id: 'PES1P2'},
{value: 'PET100', id: 'PET100'},
{value: 'PET117', id: 'PET117'},
{value: 'PEX1', id: 'PEX1'},
{value: 'PEX10', id: 'PEX10'},
{value: 'PEX11A', id: 'PEX11A'},
{value: 'PEX11B', id: 'PEX11B'},
{value: 'PEX11G', id: 'PEX11G'},
{value: 'PEX12', id: 'PEX12'},
{value: 'PEX12P1', id: 'PEX12P1'},
{value: 'PEX13', id: 'PEX13'},
{value: 'PEX14', id: 'PEX14'},
{value: 'PEX16', id: 'PEX16'},
{value: 'PEX19', id: 'PEX19'},
{value: 'PEX2', id: 'PEX2'},
{value: 'PEX26', id: 'PEX26'},
{value: 'PEX3', id: 'PEX3'},
{value: 'PEX5', id: 'PEX5'},
{value: 'PEX5L', id: 'PEX5L'},
{value: 'PEX6', id: 'PEX6'},
{value: 'PEX7', id: 'PEX7'},
{value: 'PF4', id: 'PF4'},
{value: 'PF4V1', id: 'PF4V1'},
{value: 'PFAS', id: 'PFAS'},
{value: 'PFDN1', id: 'PFDN1'},
{value: 'PFDN2', id: 'PFDN2'},
{value: 'PFDN4', id: 'PFDN4'},
{value: 'PFDN5', id: 'PFDN5'},
{value: 'PFDN6', id: 'PFDN6'},
{value: 'PFKFB1', id: 'PFKFB1'},
{value: 'PFKFB2', id: 'PFKFB2'},
{value: 'PFKFB3', id: 'PFKFB3'},
{value: 'PFKFB4', id: 'PFKFB4'},
{value: 'PFKL', id: 'PFKL'},
{value: 'PFKM', id: 'PFKM'},
{value: 'PFKP', id: 'PFKP'},
{value: 'PFKP-DT', id: 'PFKP-DT'},
{value: 'PFN1', id: 'PFN1'},
{value: 'PFN1P1', id: 'PFN1P1'},
{value: 'PFN1P11', id: 'PFN1P11'},
{value: 'PFN1P12', id: 'PFN1P12'},
{value: 'PFN1P2', id: 'PFN1P2'},
{value: 'PFN1P3', id: 'PFN1P3'},
{value: 'PFN1P4', id: 'PFN1P4'},
{value: 'PFN1P6', id: 'PFN1P6'},
{value: 'PFN1P8', id: 'PFN1P8'},
{value: 'PFN1P9', id: 'PFN1P9'},
{value: 'PFN2', id: 'PFN2'},
{value: 'PFN3', id: 'PFN3'},
{value: 'PFN4', id: 'PFN4'},
{value: 'PGA3', id: 'PGA3'},
{value: 'PGA4', id: 'PGA4'},
{value: 'PGA5', id: 'PGA5'},
{value: 'PGAM1', id: 'PGAM1'},
{value: 'PGAM1P1', id: 'PGAM1P1'},
{value: 'PGAM1P10', id: 'PGAM1P10'},
{value: 'PGAM1P11', id: 'PGAM1P11'},
{value: 'PGAM1P12', id: 'PGAM1P12'},
{value: 'PGAM1P13', id: 'PGAM1P13'},
{value: 'PGAM1P2', id: 'PGAM1P2'},
{value: 'PGAM1P3', id: 'PGAM1P3'},
{value: 'PGAM1P4', id: 'PGAM1P4'},
{value: 'PGAM1P5', id: 'PGAM1P5'},
{value: 'PGAM1P8', id: 'PGAM1P8'},
{value: 'PGAM1P9', id: 'PGAM1P9'},
{value: 'PGAM2', id: 'PGAM2'},
{value: 'PGAM3P', id: 'PGAM3P'},
{value: 'PGAM4', id: 'PGAM4'},
{value: 'PGAM4P2', id: 'PGAM4P2'},
{value: 'PGAM5', id: 'PGAM5'},
{value: 'PGAM5P1', id: 'PGAM5P1'},
{value: 'PGAP1', id: 'PGAP1'},
{value: 'PGAP2', id: 'PGAP2'},
{value: 'PGAP3', id: 'PGAP3'},
{value: 'PGAP4', id: 'PGAP4'},
{value: 'PGAP6', id: 'PGAP6'},
{value: 'PGBD1', id: 'PGBD1'},
{value: 'PGBD2', id: 'PGBD2'},
{value: 'PGBD4', id: 'PGBD4'},
{value: 'PGBD4P1', id: 'PGBD4P1'},
{value: 'PGBD4P2', id: 'PGBD4P2'},
{value: 'PGBD4P4', id: 'PGBD4P4'},
{value: 'PGBD4P5', id: 'PGBD4P5'},
{value: 'PGBD4P8', id: 'PGBD4P8'},
{value: 'PGBD5', id: 'PGBD5'},
{value: 'PGBP', id: 'PGBP'},
{value: 'PGC', id: 'PGC'},
{value: 'PGD', id: 'PGD'},
{value: 'PGDP1', id: 'PGDP1'},
{value: 'PGF', id: 'PGF'},
{value: 'PGGHG', id: 'PGGHG'},
{value: 'PGGT1B', id: 'PGGT1B'},
{value: 'PGGT1BP1', id: 'PGGT1BP1'},
{value: 'PGGT1BP2', id: 'PGGT1BP2'},
{value: 'PGK1', id: 'PGK1'},
{value: 'PGK2', id: 'PGK2'},
{value: 'PGLS', id: 'PGLS'},
{value: 'PGLS-DT', id: 'PGLS-DT'},
{value: 'PGLYRP1', id: 'PGLYRP1'},
{value: 'PGLYRP2', id: 'PGLYRP2'},
{value: 'PGLYRP3', id: 'PGLYRP3'},
{value: 'PGLYRP4', id: 'PGLYRP4'},
{value: 'PGM1', id: 'PGM1'},
{value: 'PGM2', id: 'PGM2'},
{value: 'PGM2L1', id: 'PGM2L1'},
{value: 'PGM3', id: 'PGM3'},
{value: 'PGM5', id: 'PGM5'},
{value: 'PGM5-AS1', id: 'PGM5-AS1'},
{value: 'PGM5P2', id: 'PGM5P2'},
{value: 'PGM5P3-AS1', id: 'PGM5P3-AS1'},
{value: 'PGM5P4', id: 'PGM5P4'},
{value: 'PGM5P4-AS1', id: 'PGM5P4-AS1'},
{value: 'PGP', id: 'PGP'},
{value: 'PGPEP1', id: 'PGPEP1'},
{value: 'PGPEP1L', id: 'PGPEP1L'},
{value: 'PGR', id: 'PGR'},
{value: 'PGR-AS1', id: 'PGR-AS1'},
{value: 'PGRMC1', id: 'PGRMC1'},
{value: 'PGRMC2', id: 'PGRMC2'},
{value: 'PGS1', id: 'PGS1'},
{value: 'PHACTR1', id: 'PHACTR1'},
{value: 'PHACTR2', id: 'PHACTR2'},
{value: 'PHACTR2-AS1', id: 'PHACTR2-AS1'},
{value: 'PHACTR3', id: 'PHACTR3'},
{value: 'PHACTR3-AS1', id: 'PHACTR3-AS1'},
{value: 'PHACTR4', id: 'PHACTR4'},
{value: 'PHAF1', id: 'PHAF1'},
{value: 'PHAX', id: 'PHAX'},
{value: 'PHB', id: 'PHB'},
{value: 'PHB2', id: 'PHB2'},
{value: 'PHB2P1', id: 'PHB2P1'},
{value: 'PHBP1', id: 'PHBP1'},
{value: 'PHBP11', id: 'PHBP11'},
{value: 'PHBP12', id: 'PHBP12'},
{value: 'PHBP13', id: 'PHBP13'},
{value: 'PHBP14', id: 'PHBP14'},
{value: 'PHBP15', id: 'PHBP15'},
{value: 'PHBP17', id: 'PHBP17'},
{value: 'PHBP19', id: 'PHBP19'},
{value: 'PHBP20', id: 'PHBP20'},
{value: 'PHBP21', id: 'PHBP21'},
{value: 'PHBP4', id: 'PHBP4'},
{value: 'PHBP5', id: 'PHBP5'},
{value: 'PHBP6', id: 'PHBP6'},
{value: 'PHBP7', id: 'PHBP7'},
{value: 'PHBP8', id: 'PHBP8'},
{value: 'PHBP9', id: 'PHBP9'},
{value: 'PHC1', id: 'PHC1'},
{value: 'PHC1P1', id: 'PHC1P1'},
{value: 'PHC2', id: 'PHC2'},
{value: 'PHC2-AS1', id: 'PHC2-AS1'},
{value: 'PHC3', id: 'PHC3'},
{value: 'PHETA1', id: 'PHETA1'},
{value: 'PHETA2', id: 'PHETA2'},
{value: 'PHEX', id: 'PHEX'},
{value: 'PHF1', id: 'PHF1'},
{value: 'PHF10', id: 'PHF10'},
{value: 'PHF11', id: 'PHF11'},
{value: 'PHF12', id: 'PHF12'},
{value: 'PHF13', id: 'PHF13'},
{value: 'PHF14', id: 'PHF14'},
{value: 'PHF19', id: 'PHF19'},
{value: 'PHF2', id: 'PHF2'},
{value: 'PHF20', id: 'PHF20'},
{value: 'PHF20L1', id: 'PHF20L1'},
{value: 'PHF21A', id: 'PHF21A'},
{value: 'PHF21B', id: 'PHF21B'},
{value: 'PHF23', id: 'PHF23'},
{value: 'PHF24', id: 'PHF24'},
{value: 'PHF2P2', id: 'PHF2P2'},
{value: 'PHF3', id: 'PHF3'},
{value: 'PHF5A', id: 'PHF5A'},
{value: 'PHF6', id: 'PHF6'},
{value: 'PHF7', id: 'PHF7'},
{value: 'PHF8', id: 'PHF8'},
{value: 'PHGDH', id: 'PHGDH'},
{value: 'PHGR1', id: 'PHGR1'},
{value: 'PHIP', id: 'PHIP'},
{value: 'PHKA1', id: 'PHKA1'},
{value: 'PHKA1P1', id: 'PHKA1P1'},
{value: 'PHKA2', id: 'PHKA2'},
{value: 'PHKA2-AS1', id: 'PHKA2-AS1'},
{value: 'PHKB', id: 'PHKB'},
{value: 'PHKG1', id: 'PHKG1'},
{value: 'PHKG1P1', id: 'PHKG1P1'},
{value: 'PHKG1P2', id: 'PHKG1P2'},
{value: 'PHKG1P3', id: 'PHKG1P3'},
{value: 'PHKG1P4', id: 'PHKG1P4'},
{value: 'PHKG2', id: 'PHKG2'},
{value: 'PHLDA1', id: 'PHLDA1'},
{value: 'PHLDA1-AS1', id: 'PHLDA1-AS1'},
{value: 'PHLDA1-DT', id: 'PHLDA1-DT'},
{value: 'PHLDA2', id: 'PHLDA2'},
{value: 'PHLDA3', id: 'PHLDA3'},
{value: 'PHLDB1', id: 'PHLDB1'},
{value: 'PHLDB2', id: 'PHLDB2'},
{value: 'PHLDB3', id: 'PHLDB3'},
{value: 'PHLPP1', id: 'PHLPP1'},
{value: 'PHLPP2', id: 'PHLPP2'},
{value: 'PHOSPHO1', id: 'PHOSPHO1'},
{value: 'PHOSPHO2', id: 'PHOSPHO2'},
{value: 'PHOX2A', id: 'PHOX2A'},
{value: 'PHOX2B', id: 'PHOX2B'},
{value: 'PHOX2B-AS1', id: 'PHOX2B-AS1'},
{value: 'PHPT1', id: 'PHPT1'},
{value: 'PHRF1', id: 'PHRF1'},
{value: 'PHTF1', id: 'PHTF1'},
{value: 'PHTF2', id: 'PHTF2'},
{value: 'PHYH', id: 'PHYH'},
{value: 'PHYHD1', id: 'PHYHD1'},
{value: 'PHYHIP', id: 'PHYHIP'},
{value: 'PHYHIPL', id: 'PHYHIPL'},
{value: 'PHYKPL', id: 'PHYKPL'},
{value: 'PI15', id: 'PI15'},
{value: 'PI16', id: 'PI16'},
{value: 'PI3', id: 'PI3'},
{value: 'PI4K2A', id: 'PI4K2A'},
{value: 'PI4K2B', id: 'PI4K2B'},
{value: 'PI4KA', id: 'PI4KA'},
{value: 'PI4KAP1', id: 'PI4KAP1'},
{value: 'PI4KAP2', id: 'PI4KAP2'},
{value: 'PI4KB', id: 'PI4KB'},
{value: 'PIANP', id: 'PIANP'},
{value: 'PIAS1', id: 'PIAS1'},
{value: 'PIAS2', id: 'PIAS2'},
{value: 'PIAS3', id: 'PIAS3'},
{value: 'PIAS4', id: 'PIAS4'},
{value: 'PIBF1', id: 'PIBF1'},
{value: 'PICALM', id: 'PICALM'},
{value: 'PICART1', id: 'PICART1'},
{value: 'PICK1', id: 'PICK1'},
{value: 'PICSAR', id: 'PICSAR'},
{value: 'PID1', id: 'PID1'},
{value: 'PIDD1', id: 'PIDD1'},
{value: 'PIEZO1', id: 'PIEZO1'},
{value: 'PIEZO1P1', id: 'PIEZO1P1'},
{value: 'PIEZO1P2', id: 'PIEZO1P2'},
{value: 'PIEZO2', id: 'PIEZO2'},
{value: 'PIF1', id: 'PIF1'},
{value: 'PIFO', id: 'PIFO'},
{value: 'PIGA', id: 'PIGA'},
{value: 'PIGAP1', id: 'PIGAP1'},
{value: 'PIGB', id: 'PIGB'},
{value: 'PIGBOS1', id: 'PIGBOS1'},
{value: 'PIGC', id: 'PIGC'},
{value: 'PIGCP1', id: 'PIGCP1'},
{value: 'PIGCP2', id: 'PIGCP2'},
{value: 'PIGF', id: 'PIGF'},
{value: 'PIGG', id: 'PIGG'},
{value: 'PIGH', id: 'PIGH'},
{value: 'PIGHP1', id: 'PIGHP1'},
{value: 'PIGK', id: 'PIGK'},
{value: 'PIGL', id: 'PIGL'},
{value: 'PIGM', id: 'PIGM'},
{value: 'PIGN', id: 'PIGN'},
{value: 'PIGO', id: 'PIGO'},
{value: 'PIGP', id: 'PIGP'},
{value: 'PIGPP2', id: 'PIGPP2'},
{value: 'PIGPP3', id: 'PIGPP3'},
{value: 'PIGPP4', id: 'PIGPP4'},
{value: 'PIGQ', id: 'PIGQ'},
{value: 'PIGQP1', id: 'PIGQP1'},
{value: 'PIGR', id: 'PIGR'},
{value: 'PIGS', id: 'PIGS'},
{value: 'PIGT', id: 'PIGT'},
{value: 'PIGU', id: 'PIGU'},
{value: 'PIGV', id: 'PIGV'},
{value: 'PIGW', id: 'PIGW'},
{value: 'PIGX', id: 'PIGX'},
{value: 'PIGY', id: 'PIGY'},
{value: 'PIGY-DT', id: 'PIGY-DT'},
{value: 'PIGZ', id: 'PIGZ'},
{value: 'PIH1D1', id: 'PIH1D1'},
{value: 'PIH1D2', id: 'PIH1D2'},
{value: 'PIK3AP1', id: 'PIK3AP1'},
{value: 'PIK3C2A', id: 'PIK3C2A'},
{value: 'PIK3C2B', id: 'PIK3C2B'},
{value: 'PIK3C2G', id: 'PIK3C2G'},
{value: 'PIK3C3', id: 'PIK3C3'},
{value: 'PIK3CA', id: 'PIK3CA'},
{value: 'PIK3CB', id: 'PIK3CB'},
{value: 'PIK3CD', id: 'PIK3CD'},
{value: 'PIK3CD-AS1', id: 'PIK3CD-AS1'},
{value: 'PIK3CD-AS2', id: 'PIK3CD-AS2'},
{value: 'PIK3CDP1', id: 'PIK3CDP1'},
{value: 'PIK3CG', id: 'PIK3CG'},
{value: 'PIK3IP1', id: 'PIK3IP1'},
{value: 'PIK3IP1-DT', id: 'PIK3IP1-DT'},
{value: 'PIK3R1', id: 'PIK3R1'},
{value: 'PIK3R2', id: 'PIK3R2'},
{value: 'PIK3R3', id: 'PIK3R3'},
{value: 'PIK3R4', id: 'PIK3R4'},
{value: 'PIK3R5', id: 'PIK3R5'},
{value: 'PIK3R5-DT', id: 'PIK3R5-DT'},
{value: 'PIK3R6', id: 'PIK3R6'},
{value: 'PIKFYVE', id: 'PIKFYVE'},
{value: 'PILRA', id: 'PILRA'},
{value: 'PILRB', id: 'PILRB'},
{value: 'PIM1', id: 'PIM1'},
{value: 'PIM2', id: 'PIM2'},
{value: 'PIM3', id: 'PIM3'},
{value: 'PIMREG', id: 'PIMREG'},
{value: 'PIMREGP1', id: 'PIMREGP1'},
{value: 'PIMREGP2', id: 'PIMREGP2'},
{value: 'PIMREGP4', id: 'PIMREGP4'},
{value: 'PIN1', id: 'PIN1'},
{value: 'PIN1-DT', id: 'PIN1-DT'},
{value: 'PIN4', id: 'PIN4'},
{value: 'PINK1', id: 'PINK1'},
{value: 'PINK1-AS', id: 'PINK1-AS'},
{value: 'PINLYP', id: 'PINLYP'},
{value: 'PINX1', id: 'PINX1'},
{value: 'PINX1-DT', id: 'PINX1-DT'},
{value: 'PIP', id: 'PIP'},
{value: 'PIP4K2A', id: 'PIP4K2A'},
{value: 'PIP4K2B', id: 'PIP4K2B'},
{value: 'PIP4K2C', id: 'PIP4K2C'},
{value: 'PIP4P1', id: 'PIP4P1'},
{value: 'PIP4P2', id: 'PIP4P2'},
{value: 'PIP5K1A', id: 'PIP5K1A'},
{value: 'PIP5K1B', id: 'PIP5K1B'},
{value: 'PIP5K1C', id: 'PIP5K1C'},
{value: 'PIP5K1P1', id: 'PIP5K1P1'},
{value: 'PIP5K1P2', id: 'PIP5K1P2'},
{value: 'PIP5KL1', id: 'PIP5KL1'},
{value: 'PIPOX', id: 'PIPOX'},
{value: 'PIPSL', id: 'PIPSL'},
{value: 'PIR', id: 'PIR'},
{value: 'PIRT', id: 'PIRT'},
{value: 'PISD', id: 'PISD'},
{value: 'PITHD1', id: 'PITHD1'},
{value: 'PITPNA', id: 'PITPNA'},
{value: 'PITPNA-AS1', id: 'PITPNA-AS1'},
{value: 'PITPNB', id: 'PITPNB'},
{value: 'PITPNC1', id: 'PITPNC1'},
{value: 'PITPNM1', id: 'PITPNM1'},
{value: 'PITPNM2', id: 'PITPNM2'},
{value: 'PITPNM2-AS1', id: 'PITPNM2-AS1'},
{value: 'PITPNM3', id: 'PITPNM3'},
{value: 'PITRM1', id: 'PITRM1'},
{value: 'PITRM1-AS1', id: 'PITRM1-AS1'},
{value: 'PITX1', id: 'PITX1'},
{value: 'PITX1-AS1', id: 'PITX1-AS1'},
{value: 'PITX2', id: 'PITX2'},
{value: 'PITX3', id: 'PITX3'},
{value: 'PIWIL1', id: 'PIWIL1'},
{value: 'PIWIL2', id: 'PIWIL2'},
{value: 'PIWIL2-DT', id: 'PIWIL2-DT'},
{value: 'PIWIL3', id: 'PIWIL3'},
{value: 'PIWIL4', id: 'PIWIL4'},
{value: 'PIWIL4-AS1', id: 'PIWIL4-AS1'},
{value: 'PJA1', id: 'PJA1'},
{value: 'PJA2', id: 'PJA2'},
{value: 'PJVK', id: 'PJVK'},
{value: 'PKD1', id: 'PKD1'},
{value: 'PKD1L1', id: 'PKD1L1'},
{value: 'PKD1L2', id: 'PKD1L2'},
{value: 'PKD1L3', id: 'PKD1L3'},
{value: 'PKD1P1', id: 'PKD1P1'},
{value: 'PKD1P2', id: 'PKD1P2'},
{value: 'PKD1P3', id: 'PKD1P3'},
{value: 'PKD1P4', id: 'PKD1P4'},
{value: 'PKD1P5', id: 'PKD1P5'},
{value: 'PKD1P6', id: 'PKD1P6'},
{value: 'PKD1P6-NPIPP1', id: 'PKD1P6-NPIPP1'},
{value: 'PKD2', id: 'PKD2'},
{value: 'PKD2L1', id: 'PKD2L1'},
{value: 'PKD2L2', id: 'PKD2L2'},
{value: 'PKD2L2-DT', id: 'PKD2L2-DT'},
{value: 'PKDCC', id: 'PKDCC'},
{value: 'PKDREJ', id: 'PKDREJ'},
{value: 'PKHD1', id: 'PKHD1'},
{value: 'PKHD1L1', id: 'PKHD1L1'},
{value: 'PKIA', id: 'PKIA'},
{value: 'PKIA-AS1', id: 'PKIA-AS1'},
{value: 'PKIB', id: 'PKIB'},
{value: 'PKIG', id: 'PKIG'},
{value: 'PKLR', id: 'PKLR'},
{value: 'PKM', id: 'PKM'},
{value: 'PKMP1', id: 'PKMP1'},
{value: 'PKMP3', id: 'PKMP3'},
{value: 'PKMP4', id: 'PKMP4'},
{value: 'PKMP5', id: 'PKMP5'},
{value: 'PKMYT1', id: 'PKMYT1'},
{value: 'PKN1', id: 'PKN1'},
{value: 'PKN2', id: 'PKN2'},
{value: 'PKN2-AS1', id: 'PKN2-AS1'},
{value: 'PKN3', id: 'PKN3'},
{value: 'PKNOX1', id: 'PKNOX1'},
{value: 'PKNOX2', id: 'PKNOX2'},
{value: 'PKNOX2-DT', id: 'PKNOX2-DT'},
{value: 'PKP1', id: 'PKP1'},
{value: 'PKP2', id: 'PKP2'},
{value: 'PKP3', id: 'PKP3'},
{value: 'PKP4', id: 'PKP4'},
{value: 'PKP4-AS1', id: 'PKP4-AS1'},
{value: 'PKP4P1', id: 'PKP4P1'},
{value: 'PLA1A', id: 'PLA1A'},
{value: 'PLA2G10', id: 'PLA2G10'},
{value: 'PLA2G12A', id: 'PLA2G12A'},
{value: 'PLA2G12AP1', id: 'PLA2G12AP1'},
{value: 'PLA2G12AP2', id: 'PLA2G12AP2'},
{value: 'PLA2G12B', id: 'PLA2G12B'},
{value: 'PLA2G15', id: 'PLA2G15'},
{value: 'PLA2G1B', id: 'PLA2G1B'},
{value: 'PLA2G2A', id: 'PLA2G2A'},
{value: 'PLA2G2C', id: 'PLA2G2C'},
{value: 'PLA2G2D', id: 'PLA2G2D'},
{value: 'PLA2G2E', id: 'PLA2G2E'},
{value: 'PLA2G2F', id: 'PLA2G2F'},
{value: 'PLA2G3', id: 'PLA2G3'},
{value: 'PLA2G4A', id: 'PLA2G4A'},
{value: 'PLA2G4B', id: 'PLA2G4B'},
{value: 'PLA2G4C', id: 'PLA2G4C'},
{value: 'PLA2G4C-AS1', id: 'PLA2G4C-AS1'},
{value: 'PLA2G4D', id: 'PLA2G4D'},
{value: 'PLA2G4E', id: 'PLA2G4E'},
{value: 'PLA2G4E-AS1', id: 'PLA2G4E-AS1'},
{value: 'PLA2G4F', id: 'PLA2G4F'},
{value: 'PLA2G5', id: 'PLA2G5'},
{value: 'PLA2G6', id: 'PLA2G6'},
{value: 'PLA2G7', id: 'PLA2G7'},
{value: 'PLA2R1', id: 'PLA2R1'},
{value: 'PLAA', id: 'PLAA'},
{value: 'PLAAT1', id: 'PLAAT1'},
{value: 'PLAAT2', id: 'PLAAT2'},
{value: 'PLAAT3', id: 'PLAAT3'},
{value: 'PLAAT4', id: 'PLAAT4'},
{value: 'PLAAT5', id: 'PLAAT5'},
{value: 'PLAC4', id: 'PLAC4'},
{value: 'PLAC8', id: 'PLAC8'},
{value: 'PLAC8L1', id: 'PLAC8L1'},
{value: 'PLAC9', id: 'PLAC9'},
{value: 'PLAC9P1', id: 'PLAC9P1'},
{value: 'PLAG1', id: 'PLAG1'},
{value: 'PLAGL1', id: 'PLAGL1'},
{value: 'PLAGL2', id: 'PLAGL2'},
{value: 'PLAT', id: 'PLAT'},
{value: 'PLAU', id: 'PLAU'},
{value: 'PLAUR', id: 'PLAUR'},
{value: 'PLB1', id: 'PLB1'},
{value: 'PLBD1', id: 'PLBD1'},
{value: 'PLBD1-AS1', id: 'PLBD1-AS1'},
{value: 'PLBD2', id: 'PLBD2'},
{value: 'PLCB1', id: 'PLCB1'},
{value: 'PLCB2', id: 'PLCB2'},
{value: 'PLCB2-AS1', id: 'PLCB2-AS1'},
{value: 'PLCB3', id: 'PLCB3'},
{value: 'PLCB4', id: 'PLCB4'},
{value: 'PLCD1', id: 'PLCD1'},
{value: 'PLCD3', id: 'PLCD3'},
{value: 'PLCD4', id: 'PLCD4'},
{value: 'PLCE1', id: 'PLCE1'},
{value: 'PLCE1-AS2', id: 'PLCE1-AS2'},
{value: 'PLCG1', id: 'PLCG1'},
{value: 'PLCG1-AS1', id: 'PLCG1-AS1'},
{value: 'PLCG2', id: 'PLCG2'},
{value: 'PLCH1', id: 'PLCH1'},
{value: 'PLCH1-AS1', id: 'PLCH1-AS1'},
{value: 'PLCH2', id: 'PLCH2'},
{value: 'PLCL1', id: 'PLCL1'},
{value: 'PLCL2', id: 'PLCL2'},
{value: 'PLCL2-AS1', id: 'PLCL2-AS1'},
{value: 'PLCXD1', id: 'PLCXD1'},
{value: 'PLCXD2', id: 'PLCXD2'},
{value: 'PLCXD3', id: 'PLCXD3'},
{value: 'PLCZ1', id: 'PLCZ1'},
{value: 'PLD1', id: 'PLD1'},
{value: 'PLD2', id: 'PLD2'},
{value: 'PLD3', id: 'PLD3'},
{value: 'PLD4', id: 'PLD4'},
{value: 'PLD5', id: 'PLD5'},
{value: 'PLD5P1', id: 'PLD5P1'},
{value: 'PLD6', id: 'PLD6'},
{value: 'PLEC', id: 'PLEC'},
{value: 'PLEK', id: 'PLEK'},
{value: 'PLEK2', id: 'PLEK2'},
{value: 'PLEKHA1', id: 'PLEKHA1'},
{value: 'PLEKHA2', id: 'PLEKHA2'},
{value: 'PLEKHA3', id: 'PLEKHA3'},
{value: 'PLEKHA3P1', id: 'PLEKHA3P1'},
{value: 'PLEKHA4', id: 'PLEKHA4'},
{value: 'PLEKHA5', id: 'PLEKHA5'},
{value: 'PLEKHA6', id: 'PLEKHA6'},
{value: 'PLEKHA7', id: 'PLEKHA7'},
{value: 'PLEKHA8', id: 'PLEKHA8'},
{value: 'PLEKHA8P1', id: 'PLEKHA8P1'},
{value: 'PLEKHB1', id: 'PLEKHB1'},
{value: 'PLEKHB2', id: 'PLEKHB2'},
{value: 'PLEKHD1', id: 'PLEKHD1'},
{value: 'PLEKHF1', id: 'PLEKHF1'},
{value: 'PLEKHF2', id: 'PLEKHF2'},
{value: 'PLEKHG1', id: 'PLEKHG1'},
{value: 'PLEKHG2', id: 'PLEKHG2'},
{value: 'PLEKHG3', id: 'PLEKHG3'},
{value: 'PLEKHG4', id: 'PLEKHG4'},
{value: 'PLEKHG4B', id: 'PLEKHG4B'},
{value: 'PLEKHG5', id: 'PLEKHG5'},
{value: 'PLEKHG6', id: 'PLEKHG6'},
{value: 'PLEKHG7', id: 'PLEKHG7'},
{value: 'PLEKHH1', id: 'PLEKHH1'},
{value: 'PLEKHH2', id: 'PLEKHH2'},
{value: 'PLEKHH3', id: 'PLEKHH3'},
{value: 'PLEKHJ1', id: 'PLEKHJ1'},
{value: 'PLEKHM1', id: 'PLEKHM1'},
{value: 'PLEKHM1P1', id: 'PLEKHM1P1'},
{value: 'PLEKHM2', id: 'PLEKHM2'},
{value: 'PLEKHM3', id: 'PLEKHM3'},
{value: 'PLEKHN1', id: 'PLEKHN1'},
{value: 'PLEKHO1', id: 'PLEKHO1'},
{value: 'PLEKHO2', id: 'PLEKHO2'},
{value: 'PLEKHS1', id: 'PLEKHS1'},
{value: 'PLG', id: 'PLG'},
{value: 'PLGLA', id: 'PLGLA'},
{value: 'PLGLB1', id: 'PLGLB1'},
{value: 'PLGLB2', id: 'PLGLB2'},
{value: 'PLGRKT', id: 'PLGRKT'},
{value: 'PLIN1', id: 'PLIN1'},
{value: 'PLIN2', id: 'PLIN2'},
{value: 'PLIN3', id: 'PLIN3'},
{value: 'PLIN4', id: 'PLIN4'},
{value: 'PLIN5', id: 'PLIN5'},
{value: 'PLK1', id: 'PLK1'},
{value: 'PLK2', id: 'PLK2'},
{value: 'PLK3', id: 'PLK3'},
{value: 'PLK4', id: 'PLK4'},
{value: 'PLK5', id: 'PLK5'},
{value: 'PLLP', id: 'PLLP'},
{value: 'PLN', id: 'PLN'},
{value: 'PLOD1', id: 'PLOD1'},
{value: 'PLOD2', id: 'PLOD2'},
{value: 'PLOD3', id: 'PLOD3'},
{value: 'PLP1', id: 'PLP1'},
{value: 'PLP2', id: 'PLP2'},
{value: 'PLPBP', id: 'PLPBP'},
{value: 'PLPP1', id: 'PLPP1'},
{value: 'PLPP2', id: 'PLPP2'},
{value: 'PLPP3', id: 'PLPP3'},
{value: 'PLPP4', id: 'PLPP4'},
{value: 'PLPP5', id: 'PLPP5'},
{value: 'PLPP6', id: 'PLPP6'},
{value: 'PLPP7', id: 'PLPP7'},
{value: 'PLPPR1', id: 'PLPPR1'},
{value: 'PLPPR2', id: 'PLPPR2'},
{value: 'PLPPR3', id: 'PLPPR3'},
{value: 'PLPPR4', id: 'PLPPR4'},
{value: 'PLPPR5', id: 'PLPPR5'},
{value: 'PLRG1', id: 'PLRG1'},
{value: 'PLS1', id: 'PLS1'},
{value: 'PLS1-AS1', id: 'PLS1-AS1'},
{value: 'PLS3', id: 'PLS3'},
{value: 'PLSCR1', id: 'PLSCR1'},
{value: 'PLSCR2', id: 'PLSCR2'},
{value: 'PLSCR3', id: 'PLSCR3'},
{value: 'PLSCR4', id: 'PLSCR4'},
{value: 'PLSCR5', id: 'PLSCR5'},
{value: 'PLTP', id: 'PLTP'},
{value: 'PLUT', id: 'PLUT'},
{value: 'PLVAP', id: 'PLVAP'},
{value: 'PLXDC1', id: 'PLXDC1'},
{value: 'PLXDC2', id: 'PLXDC2'},
{value: 'PLXNA1', id: 'PLXNA1'},
{value: 'PLXNA2', id: 'PLXNA2'},
{value: 'PLXNA3', id: 'PLXNA3'},
{value: 'PLXNA4', id: 'PLXNA4'},
{value: 'PLXNB1', id: 'PLXNB1'},
{value: 'PLXNB2', id: 'PLXNB2'},
{value: 'PLXNB3', id: 'PLXNB3'},
{value: 'PLXNC1', id: 'PLXNC1'},
{value: 'PLXND1', id: 'PLXND1'},
{value: 'PM20D1', id: 'PM20D1'},
{value: 'PM20D2', id: 'PM20D2'},
{value: 'PMAIP1', id: 'PMAIP1'},
{value: 'PMCH', id: 'PMCH'},
{value: 'PMCHL1', id: 'PMCHL1'},
{value: 'PMEL', id: 'PMEL'},
{value: 'PMEPA1', id: 'PMEPA1'},
{value: 'PMF1', id: 'PMF1'},
{value: 'PMF1-BGLAP', id: 'PMF1-BGLAP'},
{value: 'PMFBP1', id: 'PMFBP1'},
{value: 'PMIS2', id: 'PMIS2'},
{value: 'PML', id: 'PML'},
{value: 'PMM1', id: 'PMM1'},
{value: 'PMM2', id: 'PMM2'},
{value: 'PMM2P1', id: 'PMM2P1'},
{value: 'PMM2P2', id: 'PMM2P2'},
{value: 'PMP2', id: 'PMP2'},
{value: 'PMP22', id: 'PMP22'},
{value: 'PMPCA', id: 'PMPCA'},
{value: 'PMPCAP1', id: 'PMPCAP1'},
{value: 'PMPCB', id: 'PMPCB'},
{value: 'PMS1', id: 'PMS1'},
{value: 'PMS2', id: 'PMS2'},
{value: 'PMS2CL', id: 'PMS2CL'},
{value: 'PMS2P1', id: 'PMS2P1'},
{value: 'PMS2P10', id: 'PMS2P10'},
{value: 'PMS2P2', id: 'PMS2P2'},
{value: 'PMS2P3', id: 'PMS2P3'},
{value: 'PMS2P4', id: 'PMS2P4'},
{value: 'PMS2P5', id: 'PMS2P5'},
{value: 'PMS2P6', id: 'PMS2P6'},
{value: 'PMS2P7', id: 'PMS2P7'},
{value: 'PMVK', id: 'PMVK'},
{value: 'PNCK', id: 'PNCK'},
{value: 'PNISR', id: 'PNISR'},
{value: 'PNKD', id: 'PNKD'},
{value: 'PNKP', id: 'PNKP'},
{value: 'PNLDC1', id: 'PNLDC1'},
{value: 'PNLIP', id: 'PNLIP'},
{value: 'PNLIPP1', id: 'PNLIPP1'},
{value: 'PNLIPRP1', id: 'PNLIPRP1'},
{value: 'PNLIPRP2', id: 'PNLIPRP2'},
{value: 'PNLIPRP3', id: 'PNLIPRP3'},
{value: 'PNMA1', id: 'PNMA1'},
{value: 'PNMA2', id: 'PNMA2'},
{value: 'PNMA3', id: 'PNMA3'},
{value: 'PNMA5', id: 'PNMA5'},
{value: 'PNMA6A', id: 'PNMA6A'},
{value: 'PNMA6B', id: 'PNMA6B'},
{value: 'PNMA8A', id: 'PNMA8A'},
{value: 'PNMA8B', id: 'PNMA8B'},
{value: 'PNMA8C', id: 'PNMA8C'},
{value: 'PNMT', id: 'PNMT'},
{value: 'PNN', id: 'PNN'},
{value: 'PNO1', id: 'PNO1'},
{value: 'PNOC', id: 'PNOC'},
{value: 'PNP', id: 'PNP'},
{value: 'PNPLA1', id: 'PNPLA1'},
{value: 'PNPLA2', id: 'PNPLA2'},
{value: 'PNPLA3', id: 'PNPLA3'},
{value: 'PNPLA4', id: 'PNPLA4'},
{value: 'PNPLA5', id: 'PNPLA5'},
{value: 'PNPLA6', id: 'PNPLA6'},
{value: 'PNPLA7', id: 'PNPLA7'},
{value: 'PNPLA8', id: 'PNPLA8'},
{value: 'PNPO', id: 'PNPO'},
{value: 'PNPP1', id: 'PNPP1'},
{value: 'PNPT1', id: 'PNPT1'},
{value: 'PNPT1P1', id: 'PNPT1P1'},
{value: 'PNPT1P2', id: 'PNPT1P2'},
{value: 'PNRC1', id: 'PNRC1'},
{value: 'PNRC1-DT', id: 'PNRC1-DT'},
{value: 'PNRC2', id: 'PNRC2'},
{value: 'PNRC2P1', id: 'PNRC2P1'},
{value: 'POC1A', id: 'POC1A'},
{value: 'POC1B', id: 'POC1B'},
{value: 'POC1B-AS1', id: 'POC1B-AS1'},
{value: 'POC1B-GALNT4', id: 'POC1B-GALNT4'},
{value: 'POC5', id: 'POC5'},
{value: 'PODN', id: 'PODN'},
{value: 'PODNL1', id: 'PODNL1'},
{value: 'PODXL', id: 'PODXL'},
{value: 'PODXL2', id: 'PODXL2'},
{value: 'POFUT1', id: 'POFUT1'},
{value: 'POFUT2', id: 'POFUT2'},
{value: 'POGK', id: 'POGK'},
{value: 'POGLUT1', id: 'POGLUT1'},
{value: 'POGLUT2', id: 'POGLUT2'},
{value: 'POGLUT2P1', id: 'POGLUT2P1'},
{value: 'POGLUT3', id: 'POGLUT3'},
{value: 'POGZ', id: 'POGZ'},
{value: 'POLA1', id: 'POLA1'},
{value: 'POLA2', id: 'POLA2'},
{value: 'POLB', id: 'POLB'},
{value: 'POLD1', id: 'POLD1'},
{value: 'POLD2', id: 'POLD2'},
{value: 'POLD2P1', id: 'POLD2P1'},
{value: 'POLD3', id: 'POLD3'},
{value: 'POLD4', id: 'POLD4'},
{value: 'POLDIP2', id: 'POLDIP2'},
{value: 'POLDIP3', id: 'POLDIP3'},
{value: 'POLE', id: 'POLE'},
{value: 'POLE2', id: 'POLE2'},
{value: 'POLE3', id: 'POLE3'},
{value: 'POLE4', id: 'POLE4'},
{value: 'POLG', id: 'POLG'},
{value: 'POLG-DT', id: 'POLG-DT'},
{value: 'POLG2', id: 'POLG2'},
{value: 'POLH', id: 'POLH'},
{value: 'POLHP1', id: 'POLHP1'},
{value: 'POLI', id: 'POLI'},
{value: 'POLK', id: 'POLK'},
{value: 'POLL', id: 'POLL'},
{value: 'POLM', id: 'POLM'},
{value: 'POLN', id: 'POLN'},
{value: 'POLQ', id: 'POLQ'},
{value: 'POLR1A', id: 'POLR1A'},
{value: 'POLR1B', id: 'POLR1B'},
{value: 'POLR1C', id: 'POLR1C'},
{value: 'POLR1D', id: 'POLR1D'},
{value: 'POLR1E', id: 'POLR1E'},
{value: 'POLR1F', id: 'POLR1F'},
{value: 'POLR1G', id: 'POLR1G'},
{value: 'POLR1H', id: 'POLR1H'},
{value: 'POLR2A', id: 'POLR2A'},
{value: 'POLR2B', id: 'POLR2B'},
{value: 'POLR2C', id: 'POLR2C'},
{value: 'POLR2CP1', id: 'POLR2CP1'},
{value: 'POLR2D', id: 'POLR2D'},
{value: 'POLR2DP1', id: 'POLR2DP1'},
{value: 'POLR2DP2', id: 'POLR2DP2'},
{value: 'POLR2E', id: 'POLR2E'},
{value: 'POLR2F', id: 'POLR2F'},
{value: 'POLR2G', id: 'POLR2G'},
{value: 'POLR2H', id: 'POLR2H'},
{value: 'POLR2I', id: 'POLR2I'},
{value: 'POLR2J', id: 'POLR2J'},
{value: 'POLR2J2', id: 'POLR2J2'},
{value: 'POLR2J3', id: 'POLR2J3'},
{value: 'POLR2J4', id: 'POLR2J4'},
{value: 'POLR2K', id: 'POLR2K'},
{value: 'POLR2KP2', id: 'POLR2KP2'},
{value: 'POLR2L', id: 'POLR2L'},
{value: 'POLR2LP1', id: 'POLR2LP1'},
{value: 'POLR2M', id: 'POLR2M'},
{value: 'POLR3A', id: 'POLR3A'},
{value: 'POLR3B', id: 'POLR3B'},
{value: 'POLR3C', id: 'POLR3C'},
{value: 'POLR3D', id: 'POLR3D'},
{value: 'POLR3DP1', id: 'POLR3DP1'},
{value: 'POLR3E', id: 'POLR3E'},
{value: 'POLR3F', id: 'POLR3F'},
{value: 'POLR3G', id: 'POLR3G'},
{value: 'POLR3GL', id: 'POLR3GL'},
{value: 'POLR3GP2', id: 'POLR3GP2'},
{value: 'POLR3H', id: 'POLR3H'},
{value: 'POLR3K', id: 'POLR3K'},
{value: 'POLR3KP1', id: 'POLR3KP1'},
{value: 'POLR3KP2', id: 'POLR3KP2'},
{value: 'POLRMT', id: 'POLRMT'},
{value: 'POLRMTP1', id: 'POLRMTP1'},
{value: 'POM121', id: 'POM121'},
{value: 'POM121B', id: 'POM121B'},
{value: 'POM121C', id: 'POM121C'},
{value: 'POM121L10P', id: 'POM121L10P'},
{value: 'POM121L12', id: 'POM121L12'},
{value: 'POM121L13P', id: 'POM121L13P'},
{value: 'POM121L1P', id: 'POM121L1P'},
{value: 'POM121L2', id: 'POM121L2'},
{value: 'POM121L3P', id: 'POM121L3P'},
{value: 'POM121L4P', id: 'POM121L4P'},
{value: 'POM121L6P', id: 'POM121L6P'},
{value: 'POM121L8P', id: 'POM121L8P'},
{value: 'POM121L9P', id: 'POM121L9P'},
{value: 'POMC', id: 'POMC'},
{value: 'POMGNT1', id: 'POMGNT1'},
{value: 'POMGNT2', id: 'POMGNT2'},
{value: 'POMK', id: 'POMK'},
{value: 'POMP', id: 'POMP'},
{value: 'POMT1', id: 'POMT1'},
{value: 'POMT2', id: 'POMT2'},
{value: 'POMZP3', id: 'POMZP3'},
{value: 'PON1', id: 'PON1'},
{value: 'PON2', id: 'PON2'},
{value: 'PON3', id: 'PON3'},
{value: 'POP1', id: 'POP1'},
{value: 'POP4', id: 'POP4'},
{value: 'POP5', id: 'POP5'},
{value: 'POP7', id: 'POP7'},
{value: 'POPDC2', id: 'POPDC2'},
{value: 'POPDC3', id: 'POPDC3'},
{value: 'POR', id: 'POR'},
{value: 'PORCN', id: 'PORCN'},
{value: 'POSTN', id: 'POSTN'},
{value: 'POT1', id: 'POT1'},
{value: 'POT1-AS1', id: 'POT1-AS1'},
{value: 'POTEA', id: 'POTEA'},
{value: 'POTEB', id: 'POTEB'},
{value: 'POTEB2', id: 'POTEB2'},
{value: 'POTEB3', id: 'POTEB3'},
{value: 'POTEC', id: 'POTEC'},
{value: 'POTED', id: 'POTED'},
{value: 'POTEE', id: 'POTEE'},
{value: 'POTEF', id: 'POTEF'},
{value: 'POTEG', id: 'POTEG'},
{value: 'POTEH', id: 'POTEH'},
{value: 'POTEI', id: 'POTEI'},
{value: 'POTEJ', id: 'POTEJ'},
{value: 'POTEKP', id: 'POTEKP'},
{value: 'POTEM', id: 'POTEM'},
{value: 'POU1F1', id: 'POU1F1'},
{value: 'POU2AF1', id: 'POU2AF1'},
{value: 'POU2F1', id: 'POU2F1'},
{value: 'POU2F1-DT', id: 'POU2F1-DT'},
{value: 'POU2F2', id: 'POU2F2'},
{value: 'POU2F2-AS1', id: 'POU2F2-AS1'},
{value: 'POU2F3', id: 'POU2F3'},
{value: 'POU3F1', id: 'POU3F1'},
{value: 'POU3F2', id: 'POU3F2'},
{value: 'POU3F3', id: 'POU3F3'},
{value: 'POU4F1', id: 'POU4F1'},
{value: 'POU4F2', id: 'POU4F2'},
{value: 'POU4F3', id: 'POU4F3'},
{value: 'POU5F1', id: 'POU5F1'},
{value: 'POU5F1B', id: 'POU5F1B'},
{value: 'POU5F1P2', id: 'POU5F1P2'},
{value: 'POU5F1P3', id: 'POU5F1P3'},
{value: 'POU5F1P4', id: 'POU5F1P4'},
{value: 'POU5F1P5', id: 'POU5F1P5'},
{value: 'POU5F1P6', id: 'POU5F1P6'},
{value: 'POU5F1P7', id: 'POU5F1P7'},
{value: 'POU5F2', id: 'POU5F2'},
{value: 'POU6F1', id: 'POU6F1'},
{value: 'POU6F2', id: 'POU6F2'},
{value: 'POU6F2-AS1', id: 'POU6F2-AS1'},
{value: 'PP12613', id: 'PP12613'},
{value: 'PP2672', id: 'PP2672'},
{value: 'PP2D1', id: 'PP2D1'},
{value: 'PP7080', id: 'PP7080'},
{value: 'PPA1', id: 'PPA1'},
{value: 'PPA2', id: 'PPA2'},
{value: 'PPAN', id: 'PPAN'},
{value: 'PPAN-P2RY11', id: 'PPAN-P2RY11'},
{value: 'PPARA', id: 'PPARA'},
{value: 'PPARD', id: 'PPARD'},
{value: 'PPARG', id: 'PPARG'},
{value: 'PPARGC1A', id: 'PPARGC1A'},
{value: 'PPARGC1B', id: 'PPARGC1B'},
{value: 'PPAT', id: 'PPAT'},
{value: 'PPATP1', id: 'PPATP1'},
{value: 'PPBP', id: 'PPBP'},
{value: 'PPBPP1', id: 'PPBPP1'},
{value: 'PPBPP2', id: 'PPBPP2'},
{value: 'PPCDC', id: 'PPCDC'},
{value: 'PPCS', id: 'PPCS'},
{value: 'PPDPF', id: 'PPDPF'},
{value: 'PPDPFL', id: 'PPDPFL'},
{value: 'PPEF1', id: 'PPEF1'},
{value: 'PPEF2', id: 'PPEF2'},
{value: 'PPFIA1', id: 'PPFIA1'},
{value: 'PPFIA1P1', id: 'PPFIA1P1'},
{value: 'PPFIA2', id: 'PPFIA2'},
{value: 'PPFIA3', id: 'PPFIA3'},
{value: 'PPFIA4', id: 'PPFIA4'},
{value: 'PPFIBP1', id: 'PPFIBP1'},
{value: 'PPFIBP2', id: 'PPFIBP2'},
{value: 'PPHLN1', id: 'PPHLN1'},
{value: 'PPIA', id: 'PPIA'},
{value: 'PPIAL4C', id: 'PPIAL4C'},
{value: 'PPIAL4D', id: 'PPIAL4D'},
{value: 'PPIAL4E', id: 'PPIAL4E'},
{value: 'PPIAL4F', id: 'PPIAL4F'},
{value: 'PPIAL4H', id: 'PPIAL4H'},
{value: 'PPIAP1', id: 'PPIAP1'},
{value: 'PPIAP10', id: 'PPIAP10'},
{value: 'PPIAP13', id: 'PPIAP13'},
{value: 'PPIAP14', id: 'PPIAP14'},
{value: 'PPIAP16', id: 'PPIAP16'},
{value: 'PPIAP17', id: 'PPIAP17'},
{value: 'PPIAP19', id: 'PPIAP19'},
{value: 'PPIAP21', id: 'PPIAP21'},
{value: 'PPIAP22', id: 'PPIAP22'},
{value: 'PPIAP23', id: 'PPIAP23'},
{value: 'PPIAP24', id: 'PPIAP24'},
{value: 'PPIAP25', id: 'PPIAP25'},
{value: 'PPIAP26', id: 'PPIAP26'},
{value: 'PPIAP27', id: 'PPIAP27'},
{value: 'PPIAP28', id: 'PPIAP28'},
{value: 'PPIAP29', id: 'PPIAP29'},
{value: 'PPIAP3', id: 'PPIAP3'},
{value: 'PPIAP31', id: 'PPIAP31'},
{value: 'PPIAP32', id: 'PPIAP32'},
{value: 'PPIAP33', id: 'PPIAP33'},
{value: 'PPIAP34', id: 'PPIAP34'},
{value: 'PPIAP37', id: 'PPIAP37'},
{value: 'PPIAP38', id: 'PPIAP38'},
{value: 'PPIAP39', id: 'PPIAP39'},
{value: 'PPIAP40', id: 'PPIAP40'},
{value: 'PPIAP41', id: 'PPIAP41'},
{value: 'PPIAP43', id: 'PPIAP43'},
{value: 'PPIAP44', id: 'PPIAP44'},
{value: 'PPIAP45', id: 'PPIAP45'},
{value: 'PPIAP46', id: 'PPIAP46'},
{value: 'PPIAP48', id: 'PPIAP48'},
{value: 'PPIAP51', id: 'PPIAP51'},
{value: 'PPIAP53', id: 'PPIAP53'},
{value: 'PPIAP54', id: 'PPIAP54'},
{value: 'PPIAP55', id: 'PPIAP55'},
{value: 'PPIAP56', id: 'PPIAP56'},
{value: 'PPIAP58', id: 'PPIAP58'},
{value: 'PPIAP59', id: 'PPIAP59'},
{value: 'PPIAP60', id: 'PPIAP60'},
{value: 'PPIAP63', id: 'PPIAP63'},
{value: 'PPIAP64', id: 'PPIAP64'},
{value: 'PPIAP65', id: 'PPIAP65'},
{value: 'PPIAP66', id: 'PPIAP66'},
{value: 'PPIAP67', id: 'PPIAP67'},
{value: 'PPIAP68', id: 'PPIAP68'},
{value: 'PPIAP7', id: 'PPIAP7'},
{value: 'PPIAP70', id: 'PPIAP70'},
{value: 'PPIAP72', id: 'PPIAP72'},
{value: 'PPIAP74', id: 'PPIAP74'},
{value: 'PPIAP76', id: 'PPIAP76'},
{value: 'PPIAP78', id: 'PPIAP78'},
{value: 'PPIAP79', id: 'PPIAP79'},
{value: 'PPIAP8', id: 'PPIAP8'},
{value: 'PPIAP80', id: 'PPIAP80'},
{value: 'PPIAP81', id: 'PPIAP81'},
{value: 'PPIAP82', id: 'PPIAP82'},
{value: 'PPIAP83', id: 'PPIAP83'},
{value: 'PPIAP84', id: 'PPIAP84'},
{value: 'PPIAP85', id: 'PPIAP85'},
{value: 'PPIAP86', id: 'PPIAP86'},
{value: 'PPIAP88', id: 'PPIAP88'},
{value: 'PPIAP9', id: 'PPIAP9'},
{value: 'PPIAP93', id: 'PPIAP93'},
{value: 'PPIB', id: 'PPIB'},
{value: 'PPIC', id: 'PPIC'},
{value: 'PPIC-AS1', id: 'PPIC-AS1'},
{value: 'PPID', id: 'PPID'},
{value: 'PPIE', id: 'PPIE'},
{value: 'PPIEL', id: 'PPIEL'},
{value: 'PPIF', id: 'PPIF'},
{value: 'PPIG', id: 'PPIG'},
{value: 'PPIH', id: 'PPIH'},
{value: 'PPIHP1', id: 'PPIHP1'},
{value: 'PPIL1', id: 'PPIL1'},
{value: 'PPIL1P1', id: 'PPIL1P1'},
{value: 'PPIL2', id: 'PPIL2'},
{value: 'PPIL3', id: 'PPIL3'},
{value: 'PPIL4', id: 'PPIL4'},
{value: 'PPIL6', id: 'PPIL6'},
{value: 'PPIP5K1', id: 'PPIP5K1'},
{value: 'PPIP5K2', id: 'PPIP5K2'},
{value: 'PPL', id: 'PPL'},
{value: 'PPM1A', id: 'PPM1A'},
{value: 'PPM1AP1', id: 'PPM1AP1'},
{value: 'PPM1B', id: 'PPM1B'},
{value: 'PPM1B-DT', id: 'PPM1B-DT'},
{value: 'PPM1D', id: 'PPM1D'},
{value: 'PPM1E', id: 'PPM1E'},
{value: 'PPM1F', id: 'PPM1F'},
{value: 'PPM1F-AS1', id: 'PPM1F-AS1'},
{value: 'PPM1G', id: 'PPM1G'},
{value: 'PPM1H', id: 'PPM1H'},
{value: 'PPM1J', id: 'PPM1J'},
{value: 'PPM1K', id: 'PPM1K'},
{value: 'PPM1K-DT', id: 'PPM1K-DT'},
{value: 'PPM1L', id: 'PPM1L'},
{value: 'PPM1L-DT', id: 'PPM1L-DT'},
{value: 'PPM1M', id: 'PPM1M'},
{value: 'PPM1N', id: 'PPM1N'},
{value: 'PPME1', id: 'PPME1'},
{value: 'PPOX', id: 'PPOX'},
{value: 'PPP1CA', id: 'PPP1CA'},
{value: 'PPP1CB', id: 'PPP1CB'},
{value: 'PPP1CC', id: 'PPP1CC'},
{value: 'PPP1R10', id: 'PPP1R10'},
{value: 'PPP1R10P1', id: 'PPP1R10P1'},
{value: 'PPP1R11', id: 'PPP1R11'},
{value: 'PPP1R11P1', id: 'PPP1R11P1'},
{value: 'PPP1R12A', id: 'PPP1R12A'},
{value: 'PPP1R12A-AS1', id: 'PPP1R12A-AS1'},
{value: 'PPP1R12A-AS2', id: 'PPP1R12A-AS2'},
{value: 'PPP1R12B', id: 'PPP1R12B'},
{value: 'PPP1R12C', id: 'PPP1R12C'},
{value: 'PPP1R13B', id: 'PPP1R13B'},
{value: 'PPP1R13B-DT', id: 'PPP1R13B-DT'},
{value: 'PPP1R13L', id: 'PPP1R13L'},
{value: 'PPP1R14A', id: 'PPP1R14A'},
{value: 'PPP1R14B', id: 'PPP1R14B'},
{value: 'PPP1R14B-AS1', id: 'PPP1R14B-AS1'},
{value: 'PPP1R14BP2', id: 'PPP1R14BP2'},
{value: 'PPP1R14BP3', id: 'PPP1R14BP3'},
{value: 'PPP1R14BP4', id: 'PPP1R14BP4'},
{value: 'PPP1R14BP5', id: 'PPP1R14BP5'},
{value: 'PPP1R14C', id: 'PPP1R14C'},
{value: 'PPP1R14D', id: 'PPP1R14D'},
{value: 'PPP1R15A', id: 'PPP1R15A'},
{value: 'PPP1R15B', id: 'PPP1R15B'},
{value: 'PPP1R16A', id: 'PPP1R16A'},
{value: 'PPP1R16B', id: 'PPP1R16B'},
{value: 'PPP1R17', id: 'PPP1R17'},
{value: 'PPP1R18', id: 'PPP1R18'},
{value: 'PPP1R1A', id: 'PPP1R1A'},
{value: 'PPP1R1AP1', id: 'PPP1R1AP1'},
{value: 'PPP1R1AP2', id: 'PPP1R1AP2'},
{value: 'PPP1R1B', id: 'PPP1R1B'},
{value: 'PPP1R1C', id: 'PPP1R1C'},
{value: 'PPP1R2', id: 'PPP1R2'},
{value: 'PPP1R21', id: 'PPP1R21'},
{value: 'PPP1R21-DT', id: 'PPP1R21-DT'},
{value: 'PPP1R26', id: 'PPP1R26'},
{value: 'PPP1R26-AS1', id: 'PPP1R26-AS1'},
{value: 'PPP1R26P1', id: 'PPP1R26P1'},
{value: 'PPP1R27', id: 'PPP1R27'},
{value: 'PPP1R2B', id: 'PPP1R2B'},
{value: 'PPP1R2P1', id: 'PPP1R2P1'},
{value: 'PPP1R2P10', id: 'PPP1R2P10'},
{value: 'PPP1R2P2', id: 'PPP1R2P2'},
{value: 'PPP1R2P4', id: 'PPP1R2P4'},
{value: 'PPP1R2P5', id: 'PPP1R2P5'},
{value: 'PPP1R2P6', id: 'PPP1R2P6'},
{value: 'PPP1R32', id: 'PPP1R32'},
{value: 'PPP1R35', id: 'PPP1R35'},
{value: 'PPP1R35-AS1', id: 'PPP1R35-AS1'},
{value: 'PPP1R36', id: 'PPP1R36'},
{value: 'PPP1R37', id: 'PPP1R37'},
{value: 'PPP1R3A', id: 'PPP1R3A'},
{value: 'PPP1R3B', id: 'PPP1R3B'},
{value: 'PPP1R3C', id: 'PPP1R3C'},
{value: 'PPP1R3D', id: 'PPP1R3D'},
{value: 'PPP1R3E', id: 'PPP1R3E'},
{value: 'PPP1R3F', id: 'PPP1R3F'},
{value: 'PPP1R3G', id: 'PPP1R3G'},
{value: 'PPP1R42', id: 'PPP1R42'},
{value: 'PPP1R7', id: 'PPP1R7'},
{value: 'PPP1R8', id: 'PPP1R8'},
{value: 'PPP1R9A', id: 'PPP1R9A'},
{value: 'PPP1R9A-AS1', id: 'PPP1R9A-AS1'},
{value: 'PPP1R9B', id: 'PPP1R9B'},
{value: 'PPP2CA', id: 'PPP2CA'},
{value: 'PPP2CB', id: 'PPP2CB'},
{value: 'PPP2R1A', id: 'PPP2R1A'},
{value: 'PPP2R1B', id: 'PPP2R1B'},
{value: 'PPP2R2A', id: 'PPP2R2A'},
{value: 'PPP2R2B', id: 'PPP2R2B'},
{value: 'PPP2R2B-IT1', id: 'PPP2R2B-IT1'},
{value: 'PPP2R2C', id: 'PPP2R2C'},
{value: 'PPP2R2D', id: 'PPP2R2D'},
{value: 'PPP2R3A', id: 'PPP2R3A'},
{value: 'PPP2R3B', id: 'PPP2R3B'},
{value: 'PPP2R3C', id: 'PPP2R3C'},
{value: 'PPP2R5A', id: 'PPP2R5A'},
{value: 'PPP2R5B', id: 'PPP2R5B'},
{value: 'PPP2R5C', id: 'PPP2R5C'},
{value: 'PPP2R5CP', id: 'PPP2R5CP'},
{value: 'PPP2R5D', id: 'PPP2R5D'},
{value: 'PPP2R5E', id: 'PPP2R5E'},
{value: 'PPP3CA', id: 'PPP3CA'},
{value: 'PPP3CB', id: 'PPP3CB'},
{value: 'PPP3CB-AS1', id: 'PPP3CB-AS1'},
{value: 'PPP3CC', id: 'PPP3CC'},
{value: 'PPP3R1', id: 'PPP3R1'},
{value: 'PPP3R2', id: 'PPP3R2'},
{value: 'PPP4C', id: 'PPP4C'},
{value: 'PPP4R1', id: 'PPP4R1'},
{value: 'PPP4R1-AS1', id: 'PPP4R1-AS1'},
{value: 'PPP4R1L', id: 'PPP4R1L'},
{value: 'PPP4R2', id: 'PPP4R2'},
{value: 'PPP4R3A', id: 'PPP4R3A'},
{value: 'PPP4R3B', id: 'PPP4R3B'},
{value: 'PPP4R3B-DT', id: 'PPP4R3B-DT'},
{value: 'PPP4R4', id: 'PPP4R4'},
{value: 'PPP5C', id: 'PPP5C'},
{value: 'PPP5D1P', id: 'PPP5D1P'},
{value: 'PPP6C', id: 'PPP6C'},
{value: 'PPP6R1', id: 'PPP6R1'},
{value: 'PPP6R2', id: 'PPP6R2'},
{value: 'PPP6R2P1', id: 'PPP6R2P1'},
{value: 'PPP6R3', id: 'PPP6R3'},
{value: 'PPRC1', id: 'PPRC1'},
{value: 'PPT1', id: 'PPT1'},
{value: 'PPT2', id: 'PPT2'},
{value: 'PPT2-EGFL8', id: 'PPT2-EGFL8'},
{value: 'PPTC7', id: 'PPTC7'},
{value: 'PPWD1', id: 'PPWD1'},
{value: 'PPY', id: 'PPY'},
{value: 'PPY2P', id: 'PPY2P'},
{value: 'PQBP1', id: 'PQBP1'},
{value: 'PRAC1', id: 'PRAC1'},
{value: 'PRAC2', id: 'PRAC2'},
{value: 'PRADC1', id: 'PRADC1'},
{value: 'PRADC1P1', id: 'PRADC1P1'},
{value: 'PRAF2', id: 'PRAF2'},
{value: 'PRAG1', id: 'PRAG1'},
{value: 'PRAL', id: 'PRAL'},
{value: 'PRAM1', id: 'PRAM1'},
{value: 'PRAME', id: 'PRAME'},
{value: 'PRAMEF1', id: 'PRAMEF1'},
{value: 'PRAMEF10', id: 'PRAMEF10'},
{value: 'PRAMEF11', id: 'PRAMEF11'},
{value: 'PRAMEF12', id: 'PRAMEF12'},
{value: 'PRAMEF13', id: 'PRAMEF13'},
{value: 'PRAMEF14', id: 'PRAMEF14'},
{value: 'PRAMEF15', id: 'PRAMEF15'},
{value: 'PRAMEF17', id: 'PRAMEF17'},
{value: 'PRAMEF18', id: 'PRAMEF18'},
{value: 'PRAMEF19', id: 'PRAMEF19'},
{value: 'PRAMEF2', id: 'PRAMEF2'},
{value: 'PRAMEF20', id: 'PRAMEF20'},
{value: 'PRAMEF25', id: 'PRAMEF25'},
{value: 'PRAMEF28P', id: 'PRAMEF28P'},
{value: 'PRAMEF29P', id: 'PRAMEF29P'},
{value: 'PRAMEF30P', id: 'PRAMEF30P'},
{value: 'PRAMEF31P', id: 'PRAMEF31P'},
{value: 'PRAMEF32P', id: 'PRAMEF32P'},
{value: 'PRAMEF33', id: 'PRAMEF33'},
{value: 'PRAMEF34P', id: 'PRAMEF34P'},
{value: 'PRAMEF35P', id: 'PRAMEF35P'},
{value: 'PRAMEF36P', id: 'PRAMEF36P'},
{value: 'PRAMEF4', id: 'PRAMEF4'},
{value: 'PRAMEF5', id: 'PRAMEF5'},
{value: 'PRAMEF6', id: 'PRAMEF6'},
{value: 'PRAMEF7', id: 'PRAMEF7'},
{value: 'PRAMEF8', id: 'PRAMEF8'},
{value: 'PRAMENP', id: 'PRAMENP'},
{value: 'PRANCR', id: 'PRANCR'},
{value: 'PRAP1', id: 'PRAP1'},
{value: 'PRB1', id: 'PRB1'},
{value: 'PRB2', id: 'PRB2'},
{value: 'PRB3', id: 'PRB3'},
{value: 'PRB4', id: 'PRB4'},
{value: 'PRC1', id: 'PRC1'},
{value: 'PRC1-AS1', id: 'PRC1-AS1'},
{value: 'PRCC', id: 'PRCC'},
{value: 'PRCD', id: 'PRCD'},
{value: 'PRCP', id: 'PRCP'},
{value: 'PRCPP1', id: 'PRCPP1'},
{value: 'PRDM1', id: 'PRDM1'},
{value: 'PRDM10', id: 'PRDM10'},
{value: 'PRDM11', id: 'PRDM11'},
{value: 'PRDM12', id: 'PRDM12'},
{value: 'PRDM13', id: 'PRDM13'},
{value: 'PRDM14', id: 'PRDM14'},
{value: 'PRDM15', id: 'PRDM15'},
{value: 'PRDM16', id: 'PRDM16'},
{value: 'PRDM16-DT', id: 'PRDM16-DT'},
{value: 'PRDM2', id: 'PRDM2'},
{value: 'PRDM4', id: 'PRDM4'},
{value: 'PRDM5', id: 'PRDM5'},
{value: 'PRDM6', id: 'PRDM6'},
{value: 'PRDM7', id: 'PRDM7'},
{value: 'PRDM8', id: 'PRDM8'},
{value: 'PRDM8-AS1', id: 'PRDM8-AS1'},
{value: 'PRDM9', id: 'PRDM9'},
{value: 'PRDX1', id: 'PRDX1'},
{value: 'PRDX1P1', id: 'PRDX1P1'},
{value: 'PRDX2', id: 'PRDX2'},
{value: 'PRDX2P1', id: 'PRDX2P1'},
{value: 'PRDX2P2', id: 'PRDX2P2'},
{value: 'PRDX2P3', id: 'PRDX2P3'},
{value: 'PRDX2P4', id: 'PRDX2P4'},
{value: 'PRDX3', id: 'PRDX3'},
{value: 'PRDX3P1', id: 'PRDX3P1'},
{value: 'PRDX3P2', id: 'PRDX3P2'},
{value: 'PRDX3P3', id: 'PRDX3P3'},
{value: 'PRDX3P4', id: 'PRDX3P4'},
{value: 'PRDX4', id: 'PRDX4'},
{value: 'PRDX4P1', id: 'PRDX4P1'},
{value: 'PRDX5', id: 'PRDX5'},
{value: 'PRDX6', id: 'PRDX6'},
{value: 'PRDX6-AS1', id: 'PRDX6-AS1'},
{value: 'PREB', id: 'PREB'},
{value: 'PRECSIT', id: 'PRECSIT'},
{value: 'PRELID1', id: 'PRELID1'},
{value: 'PRELID1P1', id: 'PRELID1P1'},
{value: 'PRELID1P2', id: 'PRELID1P2'},
{value: 'PRELID1P3', id: 'PRELID1P3'},
{value: 'PRELID1P4', id: 'PRELID1P4'},
{value: 'PRELID1P5', id: 'PRELID1P5'},
{value: 'PRELID1P6', id: 'PRELID1P6'},
{value: 'PRELID1P7', id: 'PRELID1P7'},
{value: 'PRELID2', id: 'PRELID2'},
{value: 'PRELID3A', id: 'PRELID3A'},
{value: 'PRELID3B', id: 'PRELID3B'},
{value: 'PRELID3BP1', id: 'PRELID3BP1'},
{value: 'PRELID3BP10', id: 'PRELID3BP10'},
{value: 'PRELID3BP11', id: 'PRELID3BP11'},
{value: 'PRELID3BP3', id: 'PRELID3BP3'},
{value: 'PRELID3BP4', id: 'PRELID3BP4'},
{value: 'PRELID3BP5', id: 'PRELID3BP5'},
{value: 'PRELID3BP6', id: 'PRELID3BP6'},
{value: 'PRELID3BP7', id: 'PRELID3BP7'},
{value: 'PRELP', id: 'PRELP'},
{value: 'PREP', id: 'PREP'},
{value: 'PREPL', id: 'PREPL'},
{value: 'PREX1', id: 'PREX1'},
{value: 'PREX2', id: 'PREX2'},
{value: 'PRF1', id: 'PRF1'},
{value: 'PRG2', id: 'PRG2'},
{value: 'PRG3', id: 'PRG3'},
{value: 'PRG4', id: 'PRG4'},
{value: 'PRH1', id: 'PRH1'},
{value: 'PRH2', id: 'PRH2'},
{value: 'PRICKLE1', id: 'PRICKLE1'},
{value: 'PRICKLE2', id: 'PRICKLE2'},
{value: 'PRICKLE2-AS1', id: 'PRICKLE2-AS1'},
{value: 'PRICKLE2-AS3', id: 'PRICKLE2-AS3'},
{value: 'PRICKLE2-DT', id: 'PRICKLE2-DT'},
{value: 'PRICKLE3', id: 'PRICKLE3'},
{value: 'PRICKLE4', id: 'PRICKLE4'},
{value: 'PRIM1', id: 'PRIM1'},
{value: 'PRIM2', id: 'PRIM2'},
{value: 'PRIMA1', id: 'PRIMA1'},
{value: 'PRIMPOL', id: 'PRIMPOL'},
{value: 'PRKAA1', id: 'PRKAA1'},
{value: 'PRKAA2', id: 'PRKAA2'},
{value: 'PRKAB1', id: 'PRKAB1'},
{value: 'PRKAB2', id: 'PRKAB2'},
{value: 'PRKACA', id: 'PRKACA'},
{value: 'PRKACB', id: 'PRKACB'},
{value: 'PRKACB-DT', id: 'PRKACB-DT'},
{value: 'PRKACG', id: 'PRKACG'},
{value: 'PRKAG1', id: 'PRKAG1'},
{value: 'PRKAG2', id: 'PRKAG2'},
{value: 'PRKAG2-AS1', id: 'PRKAG2-AS1'},
{value: 'PRKAG3', id: 'PRKAG3'},
{value: 'PRKAR1A', id: 'PRKAR1A'},
{value: 'PRKAR1AP1', id: 'PRKAR1AP1'},
{value: 'PRKAR1B', id: 'PRKAR1B'},
{value: 'PRKAR1B-AS1', id: 'PRKAR1B-AS1'},
{value: 'PRKAR1B-AS2', id: 'PRKAR1B-AS2'},
{value: 'PRKAR2A', id: 'PRKAR2A'},
{value: 'PRKAR2A-AS1', id: 'PRKAR2A-AS1'},
{value: 'PRKAR2B', id: 'PRKAR2B'},
{value: 'PRKCA', id: 'PRKCA'},
{value: 'PRKCA-AS1', id: 'PRKCA-AS1'},
{value: 'PRKCB', id: 'PRKCB'},
{value: 'PRKCD', id: 'PRKCD'},
{value: 'PRKCE', id: 'PRKCE'},
{value: 'PRKCG', id: 'PRKCG'},
{value: 'PRKCH', id: 'PRKCH'},
{value: 'PRKCH-AS1', id: 'PRKCH-AS1'},
{value: 'PRKCI', id: 'PRKCI'},
{value: 'PRKCQ', id: 'PRKCQ'},
{value: 'PRKCQ-AS1', id: 'PRKCQ-AS1'},
{value: 'PRKCSH', id: 'PRKCSH'},
{value: 'PRKCZ', id: 'PRKCZ'},
{value: 'PRKCZ-AS1', id: 'PRKCZ-AS1'},
{value: 'PRKD1', id: 'PRKD1'},
{value: 'PRKD2', id: 'PRKD2'},
{value: 'PRKD3', id: 'PRKD3'},
{value: 'PRKDC', id: 'PRKDC'},
{value: 'PRKG1', id: 'PRKG1'},
{value: 'PRKG1-AS1', id: 'PRKG1-AS1'},
{value: 'PRKG2', id: 'PRKG2'},
{value: 'PRKG2-AS1', id: 'PRKG2-AS1'},
{value: 'PRKN', id: 'PRKN'},
{value: 'PRKRA', id: 'PRKRA'},
{value: 'PRKRIP1', id: 'PRKRIP1'},
{value: 'PRKX', id: 'PRKX'},
{value: 'PRKXP1', id: 'PRKXP1'},
{value: 'PRKY', id: 'PRKY'},
{value: 'PRL', id: 'PRL'},
{value: 'PRLH', id: 'PRLH'},
{value: 'PRLHR', id: 'PRLHR'},
{value: 'PRLR', id: 'PRLR'},
{value: 'PRM1', id: 'PRM1'},
{value: 'PRM2', id: 'PRM2'},
{value: 'PRM3', id: 'PRM3'},
{value: 'PRMT1', id: 'PRMT1'},
{value: 'PRMT1P1', id: 'PRMT1P1'},
{value: 'PRMT2', id: 'PRMT2'},
{value: 'PRMT3', id: 'PRMT3'},
{value: 'PRMT5', id: 'PRMT5'},
{value: 'PRMT5-AS1', id: 'PRMT5-AS1'},
{value: 'PRMT5-DT', id: 'PRMT5-DT'},
{value: 'PRMT5P1', id: 'PRMT5P1'},
{value: 'PRMT6', id: 'PRMT6'},
{value: 'PRMT7', id: 'PRMT7'},
{value: 'PRMT8', id: 'PRMT8'},
{value: 'PRMT9', id: 'PRMT9'},
{value: 'PRNCR1', id: 'PRNCR1'},
{value: 'PRND', id: 'PRND'},
{value: 'PRNP', id: 'PRNP'},
{value: 'PRNT', id: 'PRNT'},
{value: 'PROB1', id: 'PROB1'},
{value: 'PROC', id: 'PROC'},
{value: 'PROCA1', id: 'PROCA1'},
{value: 'PROCR', id: 'PROCR'},
{value: 'PRODH', id: 'PRODH'},
{value: 'PRODH2', id: 'PRODH2'},
{value: 'PROK1', id: 'PROK1'},
{value: 'PROK2', id: 'PROK2'},
{value: 'PROKR1', id: 'PROKR1'},
{value: 'PROKR2', id: 'PROKR2'},
{value: 'PROM1', id: 'PROM1'},
{value: 'PROM2', id: 'PROM2'},
{value: 'PROP1', id: 'PROP1'},
{value: 'PRORP', id: 'PRORP'},
{value: 'PRORSD1P', id: 'PRORSD1P'},
{value: 'PROS1', id: 'PROS1'},
{value: 'PROS2P', id: 'PROS2P'},
{value: 'PROSER1', id: 'PROSER1'},
{value: 'PROSER2', id: 'PROSER2'},
{value: 'PROSER2-AS1', id: 'PROSER2-AS1'},
{value: 'PROSER3', id: 'PROSER3'},
{value: 'PROX1', id: 'PROX1'},
{value: 'PROX1-AS1', id: 'PROX1-AS1'},
{value: 'PROX2', id: 'PROX2'},
{value: 'PROZ', id: 'PROZ'},
{value: 'PRPF18', id: 'PRPF18'},
{value: 'PRPF19', id: 'PRPF19'},
{value: 'PRPF19-DT', id: 'PRPF19-DT'},
{value: 'PRPF19P1', id: 'PRPF19P1'},
{value: 'PRPF3', id: 'PRPF3'},
{value: 'PRPF31', id: 'PRPF31'},
{value: 'PRPF38A', id: 'PRPF38A'},
{value: 'PRPF38AP1', id: 'PRPF38AP1'},
{value: 'PRPF38AP2', id: 'PRPF38AP2'},
{value: 'PRPF38B', id: 'PRPF38B'},
{value: 'PRPF39', id: 'PRPF39'},
{value: 'PRPF39-DT', id: 'PRPF39-DT'},
{value: 'PRPF4', id: 'PRPF4'},
{value: 'PRPF40A', id: 'PRPF40A'},
{value: 'PRPF40B', id: 'PRPF40B'},
{value: 'PRPF4B', id: 'PRPF4B'},
{value: 'PRPF6', id: 'PRPF6'},
{value: 'PRPF8', id: 'PRPF8'},
{value: 'PRPH', id: 'PRPH'},
{value: 'PRPH2', id: 'PRPH2'},
{value: 'PRPS1', id: 'PRPS1'},
{value: 'PRPS1L1', id: 'PRPS1L1'},
{value: 'PRPS1P2', id: 'PRPS1P2'},
{value: 'PRPS2', id: 'PRPS2'},
{value: 'PRPSAP1', id: 'PRPSAP1'},
{value: 'PRPSAP2', id: 'PRPSAP2'},
{value: 'PRR11', id: 'PRR11'},
{value: 'PRR12', id: 'PRR12'},
{value: 'PRR13', id: 'PRR13'},
{value: 'PRR13P1', id: 'PRR13P1'},
{value: 'PRR13P3', id: 'PRR13P3'},
{value: 'PRR13P4', id: 'PRR13P4'},
{value: 'PRR13P5', id: 'PRR13P5'},
{value: 'PRR13P7', id: 'PRR13P7'},
{value: 'PRR14', id: 'PRR14'},
{value: 'PRR14L', id: 'PRR14L'},
{value: 'PRR15', id: 'PRR15'},
{value: 'PRR15L', id: 'PRR15L'},
{value: 'PRR16', id: 'PRR16'},
{value: 'PRR18', id: 'PRR18'},
{value: 'PRR19', id: 'PRR19'},
{value: 'PRR20A', id: 'PRR20A'},
{value: 'PRR20C', id: 'PRR20C'},
{value: 'PRR20FP', id: 'PRR20FP'},
{value: 'PRR20G', id: 'PRR20G'},
{value: 'PRR22', id: 'PRR22'},
{value: 'PRR23A', id: 'PRR23A'},
{value: 'PRR23B', id: 'PRR23B'},
{value: 'PRR23C', id: 'PRR23C'},
{value: 'PRR23D1', id: 'PRR23D1'},
{value: 'PRR23D2', id: 'PRR23D2'},
{value: 'PRR23D3P', id: 'PRR23D3P'},
{value: 'PRR27', id: 'PRR27'},
{value: 'PRR29', id: 'PRR29'},
{value: 'PRR29-AS1', id: 'PRR29-AS1'},
{value: 'PRR3', id: 'PRR3'},
{value: 'PRR30', id: 'PRR30'},
{value: 'PRR33', id: 'PRR33'},
{value: 'PRR34', id: 'PRR34'},
{value: 'PRR34-AS1', id: 'PRR34-AS1'},
{value: 'PRR35', id: 'PRR35'},
{value: 'PRR36', id: 'PRR36'},
{value: 'PRR4', id: 'PRR4'},
{value: 'PRR5', id: 'PRR5'},
{value: 'PRR5-ARHGAP8', id: 'PRR5-ARHGAP8'},
{value: 'PRR5L', id: 'PRR5L'},
{value: 'PRR7', id: 'PRR7'},
{value: 'PRR7-AS1', id: 'PRR7-AS1'},
{value: 'PRR9', id: 'PRR9'},
{value: 'PRRC1', id: 'PRRC1'},
{value: 'PRRC2A', id: 'PRRC2A'},
{value: 'PRRC2B', id: 'PRRC2B'},
{value: 'PRRC2C', id: 'PRRC2C'},
{value: 'PRRG1', id: 'PRRG1'},
{value: 'PRRG2', id: 'PRRG2'},
{value: 'PRRG3', id: 'PRRG3'},
{value: 'PRRG4', id: 'PRRG4'},
{value: 'PRRT1', id: 'PRRT1'},
{value: 'PRRT1B', id: 'PRRT1B'},
{value: 'PRRT2', id: 'PRRT2'},
{value: 'PRRT3', id: 'PRRT3'},
{value: 'PRRT3-AS1', id: 'PRRT3-AS1'},
{value: 'PRRT4', id: 'PRRT4'},
{value: 'PRRX1', id: 'PRRX1'},
{value: 'PRRX2', id: 'PRRX2'},
{value: 'PRRX2-AS1', id: 'PRRX2-AS1'},
{value: 'PRSS1', id: 'PRSS1'},
{value: 'PRSS12', id: 'PRSS12'},
{value: 'PRSS16', id: 'PRSS16'},
{value: 'PRSS2', id: 'PRSS2'},
{value: 'PRSS21', id: 'PRSS21'},
{value: 'PRSS22', id: 'PRSS22'},
{value: 'PRSS23', id: 'PRSS23'},
{value: 'PRSS27', id: 'PRSS27'},
{value: 'PRSS29P', id: 'PRSS29P'},
{value: 'PRSS3', id: 'PRSS3'},
{value: 'PRSS30P', id: 'PRSS30P'},
{value: 'PRSS33', id: 'PRSS33'},
{value: 'PRSS35', id: 'PRSS35'},
{value: 'PRSS36', id: 'PRSS36'},
{value: 'PRSS37', id: 'PRSS37'},
{value: 'PRSS38', id: 'PRSS38'},
{value: 'PRSS3P3', id: 'PRSS3P3'},
{value: 'PRSS3P4', id: 'PRSS3P4'},
{value: 'PRSS40A', id: 'PRSS40A'},
{value: 'PRSS40B', id: 'PRSS40B'},
{value: 'PRSS41', id: 'PRSS41'},
{value: 'PRSS42P', id: 'PRSS42P'},
{value: 'PRSS43P', id: 'PRSS43P'},
{value: 'PRSS44P', id: 'PRSS44P'},
{value: 'PRSS45P', id: 'PRSS45P'},
{value: 'PRSS46P', id: 'PRSS46P'},
{value: 'PRSS47', id: 'PRSS47'},
{value: 'PRSS48', id: 'PRSS48'},
{value: 'PRSS50', id: 'PRSS50'},
{value: 'PRSS51', id: 'PRSS51'},
{value: 'PRSS53', id: 'PRSS53'},
{value: 'PRSS54', id: 'PRSS54'},
{value: 'PRSS55', id: 'PRSS55'},
{value: 'PRSS56', id: 'PRSS56'},
{value: 'PRSS57', id: 'PRSS57'},
{value: 'PRSS58', id: 'PRSS58'},
{value: 'PRSS59P', id: 'PRSS59P'},
{value: 'PRSS8', id: 'PRSS8'},
{value: 'PRTFDC1', id: 'PRTFDC1'},
{value: 'PRTG', id: 'PRTG'},
{value: 'PRTN3', id: 'PRTN3'},
{value: 'PRUNE1', id: 'PRUNE1'},
{value: 'PRUNE2', id: 'PRUNE2'},
{value: 'PRUNEP1', id: 'PRUNEP1'},
{value: 'PRX', id: 'PRX'},
{value: 'PRXL2A', id: 'PRXL2A'},
{value: 'PRXL2AP1', id: 'PRXL2AP1'},
{value: 'PRXL2AP2', id: 'PRXL2AP2'},
{value: 'PRXL2B', id: 'PRXL2B'},
{value: 'PRXL2C', id: 'PRXL2C'},
{value: 'PSAP', id: 'PSAP'},
{value: 'PSAPL1', id: 'PSAPL1'},
{value: 'PSAT1', id: 'PSAT1'},
{value: 'PSAT1P1', id: 'PSAT1P1'},
{value: 'PSAT1P2', id: 'PSAT1P2'},
{value: 'PSAT1P3', id: 'PSAT1P3'},
{value: 'PSAT1P4', id: 'PSAT1P4'},
{value: 'PSCA', id: 'PSCA'},
{value: 'PSD', id: 'PSD'},
{value: 'PSD2', id: 'PSD2'},
{value: 'PSD2-AS1', id: 'PSD2-AS1'},
{value: 'PSD3', id: 'PSD3'},
{value: 'PSD4', id: 'PSD4'},
{value: 'PSEN1', id: 'PSEN1'},
{value: 'PSEN2', id: 'PSEN2'},
{value: 'PSENEN', id: 'PSENEN'},
{value: 'PSG1', id: 'PSG1'},
{value: 'PSG10P', id: 'PSG10P'},
{value: 'PSG3', id: 'PSG3'},
{value: 'PSG4', id: 'PSG4'},
{value: 'PSG5', id: 'PSG5'},
{value: 'PSG6', id: 'PSG6'},
{value: 'PSG7', id: 'PSG7'},
{value: 'PSG8', id: 'PSG8'},
{value: 'PSG8-AS1', id: 'PSG8-AS1'},
{value: 'PSG9', id: 'PSG9'},
{value: 'PSIP1', id: 'PSIP1'},
{value: 'PSKH1', id: 'PSKH1'},
{value: 'PSKH2', id: 'PSKH2'},
{value: 'PSLNR', id: 'PSLNR'},
{value: 'PSMA1', id: 'PSMA1'},
{value: 'PSMA2', id: 'PSMA2'},
{value: 'PSMA2P1', id: 'PSMA2P1'},
{value: 'PSMA2P2', id: 'PSMA2P2'},
{value: 'PSMA3', id: 'PSMA3'},
{value: 'PSMA3-AS1', id: 'PSMA3-AS1'},
{value: 'PSMA3P1', id: 'PSMA3P1'},
{value: 'PSMA4', id: 'PSMA4'},
{value: 'PSMA5', id: 'PSMA5'},
{value: 'PSMA6', id: 'PSMA6'},
{value: 'PSMA6P3', id: 'PSMA6P3'},
{value: 'PSMA6P4', id: 'PSMA6P4'},
{value: 'PSMA7', id: 'PSMA7'},
{value: 'PSMA8', id: 'PSMA8'},
{value: 'PSMB1', id: 'PSMB1'},
{value: 'PSMB10', id: 'PSMB10'},
{value: 'PSMB11', id: 'PSMB11'},
{value: 'PSMB2', id: 'PSMB2'},
{value: 'PSMB3', id: 'PSMB3'},
{value: 'PSMB3P2', id: 'PSMB3P2'},
{value: 'PSMB4', id: 'PSMB4'},
{value: 'PSMB5', id: 'PSMB5'},
{value: 'PSMB6', id: 'PSMB6'},
{value: 'PSMB7', id: 'PSMB7'},
{value: 'PSMB7P1', id: 'PSMB7P1'},
{value: 'PSMB8', id: 'PSMB8'},
{value: 'PSMB8-AS1', id: 'PSMB8-AS1'},
{value: 'PSMB9', id: 'PSMB9'},
{value: 'PSMC1', id: 'PSMC1'},
{value: 'PSMC1P1', id: 'PSMC1P1'},
{value: 'PSMC1P10', id: 'PSMC1P10'},
{value: 'PSMC1P11', id: 'PSMC1P11'},
{value: 'PSMC1P12', id: 'PSMC1P12'},
{value: 'PSMC1P2', id: 'PSMC1P2'},
{value: 'PSMC1P3', id: 'PSMC1P3'},
{value: 'PSMC1P4', id: 'PSMC1P4'},
{value: 'PSMC1P5', id: 'PSMC1P5'},
{value: 'PSMC1P6', id: 'PSMC1P6'},
{value: 'PSMC1P7', id: 'PSMC1P7'},
{value: 'PSMC1P8', id: 'PSMC1P8'},
{value: 'PSMC1P9', id: 'PSMC1P9'},
{value: 'PSMC2', id: 'PSMC2'},
{value: 'PSMC3', id: 'PSMC3'},
{value: 'PSMC3IP', id: 'PSMC3IP'},
{value: 'PSMC4', id: 'PSMC4'},
{value: 'PSMC5', id: 'PSMC5'},
{value: 'PSMC6', id: 'PSMC6'},
{value: 'PSMD1', id: 'PSMD1'},
{value: 'PSMD10', id: 'PSMD10'},
{value: 'PSMD10P1', id: 'PSMD10P1'},
{value: 'PSMD10P2', id: 'PSMD10P2'},
{value: 'PSMD10P3', id: 'PSMD10P3'},
{value: 'PSMD11', id: 'PSMD11'},
{value: 'PSMD12', id: 'PSMD12'},
{value: 'PSMD12P1', id: 'PSMD12P1'},
{value: 'PSMD13', id: 'PSMD13'},
{value: 'PSMD14', id: 'PSMD14'},
{value: 'PSMD14P1', id: 'PSMD14P1'},
{value: 'PSMD2', id: 'PSMD2'},
{value: 'PSMD3', id: 'PSMD3'},
{value: 'PSMD4', id: 'PSMD4'},
{value: 'PSMD4P1', id: 'PSMD4P1'},
{value: 'PSMD5', id: 'PSMD5'},
{value: 'PSMD6', id: 'PSMD6'},
{value: 'PSMD6-AS1', id: 'PSMD6-AS1'},
{value: 'PSMD6-AS2', id: 'PSMD6-AS2'},
{value: 'PSMD7', id: 'PSMD7'},
{value: 'PSMD7-DT', id: 'PSMD7-DT'},
{value: 'PSMD7P1', id: 'PSMD7P1'},
{value: 'PSMD8', id: 'PSMD8'},
{value: 'PSMD8P1', id: 'PSMD8P1'},
{value: 'PSMD9', id: 'PSMD9'},
{value: 'PSME1', id: 'PSME1'},
{value: 'PSME2', id: 'PSME2'},
{value: 'PSME2P1', id: 'PSME2P1'},
{value: 'PSME2P2', id: 'PSME2P2'},
{value: 'PSME2P4', id: 'PSME2P4'},
{value: 'PSME2P5', id: 'PSME2P5'},
{value: 'PSME2P6', id: 'PSME2P6'},
{value: 'PSME3', id: 'PSME3'},
{value: 'PSME3IP1', id: 'PSME3IP1'},
{value: 'PSME4', id: 'PSME4'},
{value: 'PSMF1', id: 'PSMF1'},
{value: 'PSMG1', id: 'PSMG1'},
{value: 'PSMG2', id: 'PSMG2'},
{value: 'PSMG3', id: 'PSMG3'},
{value: 'PSMG3-AS1', id: 'PSMG3-AS1'},
{value: 'PSMG4', id: 'PSMG4'},
{value: 'PSORS1C1', id: 'PSORS1C1'},
{value: 'PSORS1C2', id: 'PSORS1C2'},
{value: 'PSPC1', id: 'PSPC1'},
{value: 'PSPC1-AS2', id: 'PSPC1-AS2'},
{value: 'PSPC1P1', id: 'PSPC1P1'},
{value: 'PSPH', id: 'PSPH'},
{value: 'PSPHP1', id: 'PSPHP1'},
{value: 'PSPN', id: 'PSPN'},
{value: 'PSRC1', id: 'PSRC1'},
{value: 'PSTK', id: 'PSTK'},
{value: 'PSTPIP1', id: 'PSTPIP1'},
{value: 'PSTPIP2', id: 'PSTPIP2'},
{value: 'PTAFR', id: 'PTAFR'},
{value: 'PTAR1', id: 'PTAR1'},
{value: 'PTBP1', id: 'PTBP1'},
{value: 'PTBP1P', id: 'PTBP1P'},
{value: 'PTBP2', id: 'PTBP2'},
{value: 'PTBP3', id: 'PTBP3'},
{value: 'PTCD1', id: 'PTCD1'},
{value: 'PTCD2', id: 'PTCD2'},
{value: 'PTCD2P1', id: 'PTCD2P1'},
{value: 'PTCD2P2', id: 'PTCD2P2'},
{value: 'PTCD3', id: 'PTCD3'},
{value: 'PTCH1', id: 'PTCH1'},
{value: 'PTCH2', id: 'PTCH2'},
{value: 'PTCHD1', id: 'PTCHD1'},
{value: 'PTCHD3', id: 'PTCHD3'},
{value: 'PTCHD3P1', id: 'PTCHD3P1'},
{value: 'PTCHD3P2', id: 'PTCHD3P2'},
{value: 'PTCHD3P3', id: 'PTCHD3P3'},
{value: 'PTCHD4', id: 'PTCHD4'},
{value: 'PTCRA', id: 'PTCRA'},
{value: 'PTCSC1', id: 'PTCSC1'},
{value: 'PTCSC2', id: 'PTCSC2'},
{value: 'PTCSC3', id: 'PTCSC3'},
{value: 'PTDSS1', id: 'PTDSS1'},
{value: 'PTDSS2', id: 'PTDSS2'},
{value: 'PTEN', id: 'PTEN'},
{value: 'PTENP1', id: 'PTENP1'},
{value: 'PTENP1-AS', id: 'PTENP1-AS'},
{value: 'PTER', id: 'PTER'},
{value: 'PTF1A', id: 'PTF1A'},
{value: 'PTGDR', id: 'PTGDR'},
{value: 'PTGDR2', id: 'PTGDR2'},
{value: 'PTGDS', id: 'PTGDS'},
{value: 'PTGER1', id: 'PTGER1'},
{value: 'PTGER2', id: 'PTGER2'},
{value: 'PTGER3', id: 'PTGER3'},
{value: 'PTGER4', id: 'PTGER4'},
{value: 'PTGER4P2', id: 'PTGER4P2'},
{value: 'PTGER4P2-CDK2AP2P2', id: 'PTGER4P2-CDK2AP2P2'},
{value: 'PTGER4P3', id: 'PTGER4P3'},
{value: 'PTGES', id: 'PTGES'},
{value: 'PTGES2', id: 'PTGES2'},
{value: 'PTGES2-AS1', id: 'PTGES2-AS1'},
{value: 'PTGES3', id: 'PTGES3'},
{value: 'PTGES3L', id: 'PTGES3L'},
{value: 'PTGES3L-AARSD1', id: 'PTGES3L-AARSD1'},
{value: 'PTGES3P1', id: 'PTGES3P1'},
{value: 'PTGES3P2', id: 'PTGES3P2'},
{value: 'PTGES3P3', id: 'PTGES3P3'},
{value: 'PTGES3P5', id: 'PTGES3P5'},
{value: 'PTGFR', id: 'PTGFR'},
{value: 'PTGFRN', id: 'PTGFRN'},
{value: 'PTGIR', id: 'PTGIR'},
{value: 'PTGIS', id: 'PTGIS'},
{value: 'PTGR1', id: 'PTGR1'},
{value: 'PTGR2', id: 'PTGR2'},
{value: 'PTGS1', id: 'PTGS1'},
{value: 'PTGS2', id: 'PTGS2'},
{value: 'PTH', id: 'PTH'},
{value: 'PTH1R', id: 'PTH1R'},
{value: 'PTH2', id: 'PTH2'},
{value: 'PTH2R', id: 'PTH2R'},
{value: 'PTHLH', id: 'PTHLH'},
{value: 'PTK2', id: 'PTK2'},
{value: 'PTK2B', id: 'PTK2B'},
{value: 'PTK6', id: 'PTK6'},
{value: 'PTK7', id: 'PTK7'},
{value: 'PTMA', id: 'PTMA'},
{value: 'PTMAP10', id: 'PTMAP10'},
{value: 'PTMAP2', id: 'PTMAP2'},
{value: 'PTMAP4', id: 'PTMAP4'},
{value: 'PTMAP5', id: 'PTMAP5'},
{value: 'PTMAP6', id: 'PTMAP6'},
{value: 'PTMAP9', id: 'PTMAP9'},
{value: 'PTMS', id: 'PTMS'},
{value: 'PTN', id: 'PTN'},
{value: 'PTOV1', id: 'PTOV1'},
{value: 'PTOV1-AS1', id: 'PTOV1-AS1'},
{value: 'PTOV1-AS2', id: 'PTOV1-AS2'},
{value: 'PTP4A1', id: 'PTP4A1'},
{value: 'PTP4A1P3', id: 'PTP4A1P3'},
{value: 'PTP4A1P4', id: 'PTP4A1P4'},
{value: 'PTP4A1P7', id: 'PTP4A1P7'},
{value: 'PTP4A2', id: 'PTP4A2'},
{value: 'PTP4A2P1', id: 'PTP4A2P1'},
{value: 'PTP4A2P2', id: 'PTP4A2P2'},
{value: 'PTP4A3', id: 'PTP4A3'},
{value: 'PTPA', id: 'PTPA'},
{value: 'PTPDC1', id: 'PTPDC1'},
{value: 'PTPMT1', id: 'PTPMT1'},
{value: 'PTPN1', id: 'PTPN1'},
{value: 'PTPN11', id: 'PTPN11'},
{value: 'PTPN12', id: 'PTPN12'},
{value: 'PTPN13', id: 'PTPN13'},
{value: 'PTPN14', id: 'PTPN14'},
{value: 'PTPN18', id: 'PTPN18'},
{value: 'PTPN2', id: 'PTPN2'},
{value: 'PTPN20', id: 'PTPN20'},
{value: 'PTPN20CP', id: 'PTPN20CP'},
{value: 'PTPN21', id: 'PTPN21'},
{value: 'PTPN22', id: 'PTPN22'},
{value: 'PTPN23', id: 'PTPN23'},
{value: 'PTPN23-DT', id: 'PTPN23-DT'},
{value: 'PTPN3', id: 'PTPN3'},
{value: 'PTPN4', id: 'PTPN4'},
{value: 'PTPN5', id: 'PTPN5'},
{value: 'PTPN6', id: 'PTPN6'},
{value: 'PTPN7', id: 'PTPN7'},
{value: 'PTPN9', id: 'PTPN9'},
{value: 'PTPRA', id: 'PTPRA'},
{value: 'PTPRB', id: 'PTPRB'},
{value: 'PTPRC', id: 'PTPRC'},
{value: 'PTPRCAP', id: 'PTPRCAP'},
{value: 'PTPRD', id: 'PTPRD'},
{value: 'PTPRD-AS1', id: 'PTPRD-AS1'},
{value: 'PTPRD-AS2', id: 'PTPRD-AS2'},
{value: 'PTPRE', id: 'PTPRE'},
{value: 'PTPRF', id: 'PTPRF'},
{value: 'PTPRG', id: 'PTPRG'},
{value: 'PTPRG-AS1', id: 'PTPRG-AS1'},
{value: 'PTPRH', id: 'PTPRH'},
{value: 'PTPRJ', id: 'PTPRJ'},
{value: 'PTPRJ-AS1', id: 'PTPRJ-AS1'},
{value: 'PTPRK', id: 'PTPRK'},
{value: 'PTPRK-AS1', id: 'PTPRK-AS1'},
{value: 'PTPRM', id: 'PTPRM'},
{value: 'PTPRN', id: 'PTPRN'},
{value: 'PTPRN2', id: 'PTPRN2'},
{value: 'PTPRN2-AS1', id: 'PTPRN2-AS1'},
{value: 'PTPRO', id: 'PTPRO'},
{value: 'PTPRQ', id: 'PTPRQ'},
{value: 'PTPRR', id: 'PTPRR'},
{value: 'PTPRS', id: 'PTPRS'},
{value: 'PTPRT', id: 'PTPRT'},
{value: 'PTPRT-AS1', id: 'PTPRT-AS1'},
{value: 'PTPRU', id: 'PTPRU'},
{value: 'PTPRVP', id: 'PTPRVP'},
{value: 'PTPRZ1', id: 'PTPRZ1'},
{value: 'PTRH1', id: 'PTRH1'},
{value: 'PTRH2', id: 'PTRH2'},
{value: 'PTRHD1', id: 'PTRHD1'},
{value: 'PTS', id: 'PTS'},
{value: 'PTTG1', id: 'PTTG1'},
{value: 'PTTG1IP', id: 'PTTG1IP'},
{value: 'PTTG1IP2', id: 'PTTG1IP2'},
{value: 'PTTG2', id: 'PTTG2'},
{value: 'PTX3', id: 'PTX3'},
{value: 'PTX4', id: 'PTX4'},
{value: 'PUDP', id: 'PUDP'},
{value: 'PUDPP2', id: 'PUDPP2'},
{value: 'PUDPP3', id: 'PUDPP3'},
{value: 'PUF60', id: 'PUF60'},
{value: 'PUM1', id: 'PUM1'},
{value: 'PUM2', id: 'PUM2'},
{value: 'PUM3', id: 'PUM3'},
{value: 'PURA', id: 'PURA'},
{value: 'PURB', id: 'PURB'},
{value: 'PURG', id: 'PURG'},
{value: 'PURPL', id: 'PURPL'},
{value: 'PUS1', id: 'PUS1'},
{value: 'PUS10', id: 'PUS10'},
{value: 'PUS3', id: 'PUS3'},
{value: 'PUS7', id: 'PUS7'},
{value: 'PUS7L', id: 'PUS7L'},
{value: 'PUSL1', id: 'PUSL1'},
{value: 'PVALB', id: 'PVALB'},
{value: 'PVALEF', id: 'PVALEF'},
{value: 'PVR', id: 'PVR'},
{value: 'PVRIG', id: 'PVRIG'},
{value: 'PVRIG2P', id: 'PVRIG2P'},
{value: 'PVT1', id: 'PVT1'},
{value: 'PWAR5', id: 'PWAR5'},
{value: 'PWP1', id: 'PWP1'},
{value: 'PWP2', id: 'PWP2'},
{value: 'PWRN1', id: 'PWRN1'},
{value: 'PWRN4', id: 'PWRN4'},
{value: 'PWWP2A', id: 'PWWP2A'},
{value: 'PWWP2AP1', id: 'PWWP2AP1'},
{value: 'PWWP2B', id: 'PWWP2B'},
{value: 'PWWP3A', id: 'PWWP3A'},
{value: 'PWWP3B', id: 'PWWP3B'},
{value: 'PXDC1', id: 'PXDC1'},
{value: 'PXDN', id: 'PXDN'},
{value: 'PXDNL', id: 'PXDNL'},
{value: 'PXK', id: 'PXK'},
{value: 'PXMP2', id: 'PXMP2'},
{value: 'PXMP4', id: 'PXMP4'},
{value: 'PXN', id: 'PXN'},
{value: 'PXN-AS1', id: 'PXN-AS1'},
{value: 'PXT1', id: 'PXT1'},
{value: 'PXYLP1', id: 'PXYLP1'},
{value: 'PYCARD', id: 'PYCARD'},
{value: 'PYCARD-AS1', id: 'PYCARD-AS1'},
{value: 'PYCR1', id: 'PYCR1'},
{value: 'PYCR2', id: 'PYCR2'},
{value: 'PYCR3', id: 'PYCR3'},
{value: 'PYDC1', id: 'PYDC1'},
{value: 'PYDC2', id: 'PYDC2'},
{value: 'PYGB', id: 'PYGB'},
{value: 'PYGL', id: 'PYGL'},
{value: 'PYGM', id: 'PYGM'},
{value: 'PYGO1', id: 'PYGO1'},
{value: 'PYGO2', id: 'PYGO2'},
{value: 'PYHIN1', id: 'PYHIN1'},
{value: 'PYHIN5P', id: 'PYHIN5P'},
{value: 'PYM1', id: 'PYM1'},
{value: 'PYROXD1', id: 'PYROXD1'},
{value: 'PYROXD2', id: 'PYROXD2'},
{value: 'PYURF', id: 'PYURF'},
{value: 'PYY', id: 'PYY'},
{value: 'PYY2', id: 'PYY2'},
{value: 'PZP', id: 'PZP'},
{value: 'QARS1', id: 'QARS1'},
{value: 'QDPR', id: 'QDPR'},
{value: 'QKI', id: 'QKI'},
{value: 'QPCT', id: 'QPCT'},
{value: 'QPCTL', id: 'QPCTL'},
{value: 'QPRT', id: 'QPRT'},
{value: 'QRFP', id: 'QRFP'},
{value: 'QRFPR', id: 'QRFPR'},
{value: 'QRICH1', id: 'QRICH1'},
{value: 'QRICH2', id: 'QRICH2'},
{value: 'QRSL1', id: 'QRSL1'},
{value: 'QSER1', id: 'QSER1'},
{value: 'QSOX1', id: 'QSOX1'},
{value: 'QSOX2', id: 'QSOX2'},
{value: 'QTRT1', id: 'QTRT1'},
{value: 'QTRT2', id: 'QTRT2'},
{value: 'R3HCC1', id: 'R3HCC1'},
{value: 'R3HCC1L', id: 'R3HCC1L'},
{value: 'R3HDM1', id: 'R3HDM1'},
{value: 'R3HDM2', id: 'R3HDM2'},
{value: 'R3HDM2-DT', id: 'R3HDM2-DT'},
{value: 'R3HDM2P1', id: 'R3HDM2P1'},
{value: 'R3HDM2P2', id: 'R3HDM2P2'},
{value: 'R3HDM4', id: 'R3HDM4'},
{value: 'R3HDML', id: 'R3HDML'},
{value: 'RA1L3', id: 'RA1L3'},
{value: 'RAB10', id: 'RAB10'},
{value: 'RAB11A', id: 'RAB11A'},
{value: 'RAB11AP1', id: 'RAB11AP1'},
{value: 'RAB11AP2', id: 'RAB11AP2'},
{value: 'RAB11B', id: 'RAB11B'},
{value: 'RAB11B-AS1', id: 'RAB11B-AS1'},
{value: 'RAB11FIP1', id: 'RAB11FIP1'},
{value: 'RAB11FIP1P1', id: 'RAB11FIP1P1'},
{value: 'RAB11FIP2', id: 'RAB11FIP2'},
{value: 'RAB11FIP3', id: 'RAB11FIP3'},
{value: 'RAB11FIP4', id: 'RAB11FIP4'},
{value: 'RAB11FIP5', id: 'RAB11FIP5'},
{value: 'RAB12', id: 'RAB12'},
{value: 'RAB13', id: 'RAB13'},
{value: 'RAB14', id: 'RAB14'},
{value: 'RAB15', id: 'RAB15'},
{value: 'RAB17', id: 'RAB17'},
{value: 'RAB18', id: 'RAB18'},
{value: 'RAB19', id: 'RAB19'},
{value: 'RAB1A', id: 'RAB1A'},
{value: 'RAB1AP1', id: 'RAB1AP1'},
{value: 'RAB1AP2', id: 'RAB1AP2'},
{value: 'RAB1B', id: 'RAB1B'},
{value: 'RAB1C', id: 'RAB1C'},
{value: 'RAB20', id: 'RAB20'},
{value: 'RAB21', id: 'RAB21'},
{value: 'RAB22A', id: 'RAB22A'},
{value: 'RAB23', id: 'RAB23'},
{value: 'RAB24', id: 'RAB24'},
{value: 'RAB25', id: 'RAB25'},
{value: 'RAB26', id: 'RAB26'},
{value: 'RAB27A', id: 'RAB27A'},
{value: 'RAB27B', id: 'RAB27B'},
{value: 'RAB28', id: 'RAB28'},
{value: 'RAB28P1', id: 'RAB28P1'},
{value: 'RAB28P2', id: 'RAB28P2'},
{value: 'RAB28P3', id: 'RAB28P3'},
{value: 'RAB28P4', id: 'RAB28P4'},
{value: 'RAB28P5', id: 'RAB28P5'},
{value: 'RAB29', id: 'RAB29'},
{value: 'RAB2A', id: 'RAB2A'},
{value: 'RAB2B', id: 'RAB2B'},
{value: 'RAB30', id: 'RAB30'},
{value: 'RAB30-DT', id: 'RAB30-DT'},
{value: 'RAB31', id: 'RAB31'},
{value: 'RAB32', id: 'RAB32'},
{value: 'RAB33A', id: 'RAB33A'},
{value: 'RAB33B', id: 'RAB33B'},
{value: 'RAB33B-AS1', id: 'RAB33B-AS1'},
{value: 'RAB34', id: 'RAB34'},
{value: 'RAB35', id: 'RAB35'},
{value: 'RAB36', id: 'RAB36'},
{value: 'RAB37', id: 'RAB37'},
{value: 'RAB38', id: 'RAB38'},
{value: 'RAB39A', id: 'RAB39A'},
{value: 'RAB39B', id: 'RAB39B'},
{value: 'RAB3A', id: 'RAB3A'},
{value: 'RAB3B', id: 'RAB3B'},
{value: 'RAB3C', id: 'RAB3C'},
{value: 'RAB3D', id: 'RAB3D'},
{value: 'RAB3GAP1', id: 'RAB3GAP1'},
{value: 'RAB3GAP2', id: 'RAB3GAP2'},
{value: 'RAB3IL1', id: 'RAB3IL1'},
{value: 'RAB3IP', id: 'RAB3IP'},
{value: 'RAB40A', id: 'RAB40A'},
{value: 'RAB40B', id: 'RAB40B'},
{value: 'RAB40C', id: 'RAB40C'},
{value: 'RAB42', id: 'RAB42'},
{value: 'RAB42P1', id: 'RAB42P1'},
{value: 'RAB43', id: 'RAB43'},
{value: 'RAB43P1', id: 'RAB43P1'},
{value: 'RAB44', id: 'RAB44'},
{value: 'RAB4A', id: 'RAB4A'},
{value: 'RAB4B', id: 'RAB4B'},
{value: 'RAB4B-EGLN2', id: 'RAB4B-EGLN2'},
{value: 'RAB5A', id: 'RAB5A'},
{value: 'RAB5B', id: 'RAB5B'},
{value: 'RAB5C', id: 'RAB5C'},
{value: 'RAB5C-AS1', id: 'RAB5C-AS1'},
{value: 'RAB5CP1', id: 'RAB5CP1'},
{value: 'RAB5CP2', id: 'RAB5CP2'},
{value: 'RAB5IF', id: 'RAB5IF'},
{value: 'RAB6A', id: 'RAB6A'},
{value: 'RAB6B', id: 'RAB6B'},
{value: 'RAB6C', id: 'RAB6C'},
{value: 'RAB6C-AS1', id: 'RAB6C-AS1'},
{value: 'RAB6D', id: 'RAB6D'},
{value: 'RAB7A', id: 'RAB7A'},
{value: 'RAB7B', id: 'RAB7B'},
{value: 'RAB8A', id: 'RAB8A'},
{value: 'RAB8B', id: 'RAB8B'},
{value: 'RAB9A', id: 'RAB9A'},
{value: 'RAB9B', id: 'RAB9B'},
{value: 'RABAC1', id: 'RABAC1'},
{value: 'RABEP1', id: 'RABEP1'},
{value: 'RABEP2', id: 'RABEP2'},
{value: 'RABEPK', id: 'RABEPK'},
{value: 'RABEPKP1', id: 'RABEPKP1'},
{value: 'RABGAP1', id: 'RABGAP1'},
{value: 'RABGAP1L', id: 'RABGAP1L'},
{value: 'RABGAP1L-DT', id: 'RABGAP1L-DT'},
{value: 'RABGEF1', id: 'RABGEF1'},
{value: 'RABGGTA', id: 'RABGGTA'},
{value: 'RABGGTB', id: 'RABGGTB'},
{value: 'RABIF', id: 'RABIF'},
{value: 'RABL2A', id: 'RABL2A'},
{value: 'RABL2B', id: 'RABL2B'},
{value: 'RABL3', id: 'RABL3'},
{value: 'RABL6', id: 'RABL6'},
{value: 'RAC1', id: 'RAC1'},
{value: 'RAC1P2', id: 'RAC1P2'},
{value: 'RAC1P3', id: 'RAC1P3'},
{value: 'RAC1P6', id: 'RAC1P6'},
{value: 'RAC1P7', id: 'RAC1P7'},
{value: 'RAC1P8', id: 'RAC1P8'},
{value: 'RAC2', id: 'RAC2'},
{value: 'RAC3', id: 'RAC3'},
{value: 'RACGAP1', id: 'RACGAP1'},
{value: 'RACK1', id: 'RACK1'},
{value: 'RACK1P2', id: 'RACK1P2'},
{value: 'RACK1P3', id: 'RACK1P3'},
{value: 'RAD1', id: 'RAD1'},
{value: 'RAD17', id: 'RAD17'},
{value: 'RAD18', id: 'RAD18'},
{value: 'RAD1P1', id: 'RAD1P1'},
{value: 'RAD1P2', id: 'RAD1P2'},
{value: 'RAD21', id: 'RAD21'},
{value: 'RAD21-AS1', id: 'RAD21-AS1'},
{value: 'RAD21L1', id: 'RAD21L1'},
{value: 'RAD23A', id: 'RAD23A'},
{value: 'RAD23B', id: 'RAD23B'},
{value: 'RAD23BP1', id: 'RAD23BP1'},
{value: 'RAD23BP2', id: 'RAD23BP2'},
{value: 'RAD23BP3', id: 'RAD23BP3'},
{value: 'RAD50', id: 'RAD50'},
{value: 'RAD51', id: 'RAD51'},
{value: 'RAD51-AS1', id: 'RAD51-AS1'},
{value: 'RAD51AP1', id: 'RAD51AP1'},
{value: 'RAD51AP1P1', id: 'RAD51AP1P1'},
{value: 'RAD51AP2', id: 'RAD51AP2'},
{value: 'RAD51B', id: 'RAD51B'},
{value: 'RAD51C', id: 'RAD51C'},
{value: 'RAD51D', id: 'RAD51D'},
{value: 'RAD52', id: 'RAD52'},
{value: 'RAD52P1', id: 'RAD52P1'},
{value: 'RAD54B', id: 'RAD54B'},
{value: 'RAD54L', id: 'RAD54L'},
{value: 'RAD54L2', id: 'RAD54L2'},
{value: 'RAD54L2P1', id: 'RAD54L2P1'},
{value: 'RAD9A', id: 'RAD9A'},
{value: 'RAD9B', id: 'RAD9B'},
{value: 'RADIL', id: 'RADIL'},
{value: 'RADX', id: 'RADX'},
{value: 'RAE1', id: 'RAE1'},
{value: 'RAET1E', id: 'RAET1E'},
{value: 'RAET1E-AS1', id: 'RAET1E-AS1'},
{value: 'RAET1G', id: 'RAET1G'},
{value: 'RAET1K', id: 'RAET1K'},
{value: 'RAET1L', id: 'RAET1L'},
{value: 'RAET1M', id: 'RAET1M'},
{value: 'RAF1', id: 'RAF1'},
{value: 'RAF1P1', id: 'RAF1P1'},
{value: 'RAG1', id: 'RAG1'},
{value: 'RAG2', id: 'RAG2'},
{value: 'RAI1', id: 'RAI1'},
{value: 'RAI1-AS1', id: 'RAI1-AS1'},
{value: 'RAI14', id: 'RAI14'},
{value: 'RAI2', id: 'RAI2'},
{value: 'RALA', id: 'RALA'},
{value: 'RALB', id: 'RALB'},
{value: 'RALBP1', id: 'RALBP1'},
{value: 'RALBP1P1', id: 'RALBP1P1'},
{value: 'RALBP1P2', id: 'RALBP1P2'},
{value: 'RALGAPA1', id: 'RALGAPA1'},
{value: 'RALGAPA1P1', id: 'RALGAPA1P1'},
{value: 'RALGAPA2', id: 'RALGAPA2'},
{value: 'RALGAPB', id: 'RALGAPB'},
{value: 'RALGDS', id: 'RALGDS'},
{value: 'RALGPS1', id: 'RALGPS1'},
{value: 'RALGPS2', id: 'RALGPS2'},
{value: 'RALGPS2-AS1', id: 'RALGPS2-AS1'},
{value: 'RALY', id: 'RALY'},
{value: 'RALY-AS1', id: 'RALY-AS1'},
{value: 'RALYL', id: 'RALYL'},
{value: 'RAMAC', id: 'RAMAC'},
{value: 'RAMACL', id: 'RAMACL'},
{value: 'RAMP1', id: 'RAMP1'},
{value: 'RAMP2', id: 'RAMP2'},
{value: 'RAMP2-AS1', id: 'RAMP2-AS1'},
{value: 'RAMP3', id: 'RAMP3'},
{value: 'RAN', id: 'RAN'},
{value: 'RANBP1', id: 'RANBP1'},
{value: 'RANBP10', id: 'RANBP10'},
{value: 'RANBP17', id: 'RANBP17'},
{value: 'RANBP2', id: 'RANBP2'},
{value: 'RANBP20P', id: 'RANBP20P'},
{value: 'RANBP3', id: 'RANBP3'},
{value: 'RANBP3-DT', id: 'RANBP3-DT'},
{value: 'RANBP3L', id: 'RANBP3L'},
{value: 'RANBP6', id: 'RANBP6'},
{value: 'RANBP9', id: 'RANBP9'},
{value: 'RANGAP1', id: 'RANGAP1'},
{value: 'RANGRF', id: 'RANGRF'},
{value: 'RANP1', id: 'RANP1'},
{value: 'RANP4', id: 'RANP4'},
{value: 'RANP5', id: 'RANP5'},
{value: 'RANP6', id: 'RANP6'},
{value: 'RANP7', id: 'RANP7'},
{value: 'RANP9', id: 'RANP9'},
{value: 'RAP1A', id: 'RAP1A'},
{value: 'RAP1B', id: 'RAP1B'},
{value: 'RAP1BL', id: 'RAP1BL'},
{value: 'RAP1BP1', id: 'RAP1BP1'},
{value: 'RAP1BP2', id: 'RAP1BP2'},
{value: 'RAP1GAP', id: 'RAP1GAP'},
{value: 'RAP1GAP2', id: 'RAP1GAP2'},
{value: 'RAP1GDS1', id: 'RAP1GDS1'},
{value: 'RAP2A', id: 'RAP2A'},
{value: 'RAP2B', id: 'RAP2B'},
{value: 'RAP2C', id: 'RAP2C'},
{value: 'RAP2C-AS1', id: 'RAP2C-AS1'},
{value: 'RAP2CP1', id: 'RAP2CP1'},
{value: 'RAPGEF1', id: 'RAPGEF1'},
{value: 'RAPGEF2', id: 'RAPGEF2'},
{value: 'RAPGEF3', id: 'RAPGEF3'},
{value: 'RAPGEF4', id: 'RAPGEF4'},
{value: 'RAPGEF4-AS1', id: 'RAPGEF4-AS1'},
{value: 'RAPGEF5', id: 'RAPGEF5'},
{value: 'RAPGEF6', id: 'RAPGEF6'},
{value: 'RAPGEFL1', id: 'RAPGEFL1'},
{value: 'RAPH1', id: 'RAPH1'},
{value: 'RAPSN', id: 'RAPSN'},
{value: 'RARA', id: 'RARA'},
{value: 'RARA-AS1', id: 'RARA-AS1'},
{value: 'RARB', id: 'RARB'},
{value: 'RARG', id: 'RARG'},
{value: 'RARRES1', id: 'RARRES1'},
{value: 'RARRES2', id: 'RARRES2'},
{value: 'RARRES2P10', id: 'RARRES2P10'},
{value: 'RARRES2P2', id: 'RARRES2P2'},
{value: 'RARRES2P4', id: 'RARRES2P4'},
{value: 'RARRES2P5', id: 'RARRES2P5'},
{value: 'RARRES2P6', id: 'RARRES2P6'},
{value: 'RARRES2P7', id: 'RARRES2P7'},
{value: 'RARRES2P8', id: 'RARRES2P8'},
{value: 'RARRES2P9', id: 'RARRES2P9'},
{value: 'RARS1', id: 'RARS1'},
{value: 'RARS1P1', id: 'RARS1P1'},
{value: 'RARS2', id: 'RARS2'},
{value: 'RASA1', id: 'RASA1'},
{value: 'RASA2', id: 'RASA2'},
{value: 'RASA2-IT1', id: 'RASA2-IT1'},
{value: 'RASA3', id: 'RASA3'},
{value: 'RASA4', id: 'RASA4'},
{value: 'RASA4B', id: 'RASA4B'},
{value: 'RASA4CP', id: 'RASA4CP'},
{value: 'RASA4DP', id: 'RASA4DP'},
{value: 'RASAL1', id: 'RASAL1'},
{value: 'RASAL2', id: 'RASAL2'},
{value: 'RASAL2-AS1', id: 'RASAL2-AS1'},
{value: 'RASAL3', id: 'RASAL3'},
{value: 'RASD1', id: 'RASD1'},
{value: 'RASD2', id: 'RASD2'},
{value: 'RASEF', id: 'RASEF'},
{value: 'RASGEF1A', id: 'RASGEF1A'},
{value: 'RASGEF1B', id: 'RASGEF1B'},
{value: 'RASGEF1C', id: 'RASGEF1C'},
{value: 'RASGRF1', id: 'RASGRF1'},
{value: 'RASGRF2', id: 'RASGRF2'},
{value: 'RASGRF2-AS1', id: 'RASGRF2-AS1'},
{value: 'RASGRP1', id: 'RASGRP1'},
{value: 'RASGRP2', id: 'RASGRP2'},
{value: 'RASGRP3', id: 'RASGRP3'},
{value: 'RASGRP4', id: 'RASGRP4'},
{value: 'RASIP1', id: 'RASIP1'},
{value: 'RASL10A', id: 'RASL10A'},
{value: 'RASL10B', id: 'RASL10B'},
{value: 'RASL11A', id: 'RASL11A'},
{value: 'RASL11B', id: 'RASL11B'},
{value: 'RASL12', id: 'RASL12'},
{value: 'RASSF1', id: 'RASSF1'},
{value: 'RASSF1-AS1', id: 'RASSF1-AS1'},
{value: 'RASSF10', id: 'RASSF10'},
{value: 'RASSF10-DT', id: 'RASSF10-DT'},
{value: 'RASSF2', id: 'RASSF2'},
{value: 'RASSF3', id: 'RASSF3'},
{value: 'RASSF3-DT', id: 'RASSF3-DT'},
{value: 'RASSF4', id: 'RASSF4'},
{value: 'RASSF5', id: 'RASSF5'},
{value: 'RASSF6', id: 'RASSF6'},
{value: 'RASSF7', id: 'RASSF7'},
{value: 'RASSF8', id: 'RASSF8'},
{value: 'RASSF8-AS1', id: 'RASSF8-AS1'},
{value: 'RASSF9', id: 'RASSF9'},
{value: 'RAVER1', id: 'RAVER1'},
{value: 'RAVER2', id: 'RAVER2'},
{value: 'RAX', id: 'RAX'},
{value: 'RAX2', id: 'RAX2'},
{value: 'RB1', id: 'RB1'},
{value: 'RB1-DT', id: 'RB1-DT'},
{value: 'RB1CC1', id: 'RB1CC1'},
{value: 'RBAK', id: 'RBAK'},
{value: 'RBAK-RBAKDN', id: 'RBAK-RBAKDN'},
{value: 'RBAKDN', id: 'RBAKDN'},
{value: 'RBBP4', id: 'RBBP4'},
{value: 'RBBP4P1', id: 'RBBP4P1'},
{value: 'RBBP4P2', id: 'RBBP4P2'},
{value: 'RBBP4P3', id: 'RBBP4P3'},
{value: 'RBBP4P5', id: 'RBBP4P5'},
{value: 'RBBP4P6', id: 'RBBP4P6'},
{value: 'RBBP5', id: 'RBBP5'},
{value: 'RBBP6', id: 'RBBP6'},
{value: 'RBBP7', id: 'RBBP7'},
{value: 'RBBP8', id: 'RBBP8'},
{value: 'RBBP8NL', id: 'RBBP8NL'},
{value: 'RBBP8P1', id: 'RBBP8P1'},
{value: 'RBBP9', id: 'RBBP9'},
{value: 'RBCK1', id: 'RBCK1'},
{value: 'RBFA', id: 'RBFA'},
{value: 'RBFADN', id: 'RBFADN'},
{value: 'RBFOX1', id: 'RBFOX1'},
{value: 'RBFOX2', id: 'RBFOX2'},
{value: 'RBFOX3', id: 'RBFOX3'},
{value: 'RBIS', id: 'RBIS'},
{value: 'RBISP1', id: 'RBISP1'},
{value: 'RBKS', id: 'RBKS'},
{value: 'RBL1', id: 'RBL1'},
{value: 'RBL2', id: 'RBL2'},
{value: 'RBM10', id: 'RBM10'},
{value: 'RBM11', id: 'RBM11'},
{value: 'RBM11P1', id: 'RBM11P1'},
{value: 'RBM12', id: 'RBM12'},
{value: 'RBM12B', id: 'RBM12B'},
{value: 'RBM12B-AS1', id: 'RBM12B-AS1'},
{value: 'RBM12B-DT', id: 'RBM12B-DT'},
{value: 'RBM14', id: 'RBM14'},
{value: 'RBM14-RBM4', id: 'RBM14-RBM4'},
{value: 'RBM15', id: 'RBM15'},
{value: 'RBM15-AS1', id: 'RBM15-AS1'},
{value: 'RBM15B', id: 'RBM15B'},
{value: 'RBM17', id: 'RBM17'},
{value: 'RBM17P1', id: 'RBM17P1'},
{value: 'RBM17P3', id: 'RBM17P3'},
{value: 'RBM17P4', id: 'RBM17P4'},
{value: 'RBM18', id: 'RBM18'},
{value: 'RBM19', id: 'RBM19'},
{value: 'RBM20', id: 'RBM20'},
{value: 'RBM22', id: 'RBM22'},
{value: 'RBM22P1', id: 'RBM22P1'},
{value: 'RBM22P12', id: 'RBM22P12'},
{value: 'RBM22P13', id: 'RBM22P13'},
{value: 'RBM22P2', id: 'RBM22P2'},
{value: 'RBM22P3', id: 'RBM22P3'},
{value: 'RBM22P5', id: 'RBM22P5'},
{value: 'RBM23', id: 'RBM23'},
{value: 'RBM24', id: 'RBM24'},
{value: 'RBM25', id: 'RBM25'},
{value: 'RBM25-AS1', id: 'RBM25-AS1'},
{value: 'RBM26', id: 'RBM26'},
{value: 'RBM26-AS1', id: 'RBM26-AS1'},
{value: 'RBM27', id: 'RBM27'},
{value: 'RBM28', id: 'RBM28'},
{value: 'RBM3', id: 'RBM3'},
{value: 'RBM33', id: 'RBM33'},
{value: 'RBM34', id: 'RBM34'},
{value: 'RBM38', id: 'RBM38'},
{value: 'RBM38-AS1', id: 'RBM38-AS1'},
{value: 'RBM39', id: 'RBM39'},
{value: 'RBM4', id: 'RBM4'},
{value: 'RBM41', id: 'RBM41'},
{value: 'RBM42', id: 'RBM42'},
{value: 'RBM43', id: 'RBM43'},
{value: 'RBM44', id: 'RBM44'},
{value: 'RBM45', id: 'RBM45'},
{value: 'RBM46', id: 'RBM46'},
{value: 'RBM47', id: 'RBM47'},
{value: 'RBM48', id: 'RBM48'},
{value: 'RBM48P1', id: 'RBM48P1'},
{value: 'RBM4B', id: 'RBM4B'},
{value: 'RBM5', id: 'RBM5'},
{value: 'RBM5-AS1', id: 'RBM5-AS1'},
{value: 'RBM6', id: 'RBM6'},
{value: 'RBM7', id: 'RBM7'},
{value: 'RBM8A', id: 'RBM8A'},
{value: 'RBM8B', id: 'RBM8B'},
{value: 'RBMS1', id: 'RBMS1'},
{value: 'RBMS1P1', id: 'RBMS1P1'},
{value: 'RBMS2', id: 'RBMS2'},
{value: 'RBMS2P1', id: 'RBMS2P1'},
{value: 'RBMS3', id: 'RBMS3'},
{value: 'RBMS3-AS1', id: 'RBMS3-AS1'},
{value: 'RBMS3-AS2', id: 'RBMS3-AS2'},
{value: 'RBMS3-AS3', id: 'RBMS3-AS3'},
{value: 'RBMX', id: 'RBMX'},
{value: 'RBMX2', id: 'RBMX2'},
{value: 'RBMX2P1', id: 'RBMX2P1'},
{value: 'RBMX2P3', id: 'RBMX2P3'},
{value: 'RBMX2P4', id: 'RBMX2P4'},
{value: 'RBMX2P5', id: 'RBMX2P5'},
{value: 'RBMXL1', id: 'RBMXL1'},
{value: 'RBMXL2', id: 'RBMXL2'},
{value: 'RBMXP1', id: 'RBMXP1'},
{value: 'RBMXP2', id: 'RBMXP2'},
{value: 'RBMXP3', id: 'RBMXP3'},
{value: 'RBMXP4', id: 'RBMXP4'},
{value: 'RBP1', id: 'RBP1'},
{value: 'RBP2', id: 'RBP2'},
{value: 'RBP3', id: 'RBP3'},
{value: 'RBP4', id: 'RBP4'},
{value: 'RBP5', id: 'RBP5'},
{value: 'RBP7', id: 'RBP7'},
{value: 'RBPJ', id: 'RBPJ'},
{value: 'RBPJL', id: 'RBPJL'},
{value: 'RBPJP2', id: 'RBPJP2'},
{value: 'RBPJP6', id: 'RBPJP6'},
{value: 'RBPJP7', id: 'RBPJP7'},
{value: 'RBPMS', id: 'RBPMS'},
{value: 'RBPMS-AS1', id: 'RBPMS-AS1'},
{value: 'RBPMS2', id: 'RBPMS2'},
{value: 'RBPMS2P1', id: 'RBPMS2P1'},
{value: 'RBPMSLP', id: 'RBPMSLP'},
{value: 'RBSN', id: 'RBSN'},
{value: 'RBX1', id: 'RBX1'},
{value: 'RBX1P1', id: 'RBX1P1'},
{value: 'RC3H1', id: 'RC3H1'},
{value: 'RC3H2', id: 'RC3H2'},
{value: 'RCAN1', id: 'RCAN1'},
{value: 'RCAN2', id: 'RCAN2'},
{value: 'RCAN3', id: 'RCAN3'},
{value: 'RCAN3AS', id: 'RCAN3AS'},
{value: 'RCBTB1', id: 'RCBTB1'},
{value: 'RCBTB2', id: 'RCBTB2'},
{value: 'RCBTB2P1', id: 'RCBTB2P1'},
{value: 'RCC1', id: 'RCC1'},
{value: 'RCC1L', id: 'RCC1L'},
{value: 'RCC2', id: 'RCC2'},
{value: 'RCC2-AS1', id: 'RCC2-AS1'},
{value: 'RCC2P3', id: 'RCC2P3'},
{value: 'RCC2P4', id: 'RCC2P4'},
{value: 'RCC2P5', id: 'RCC2P5'},
{value: 'RCC2P6', id: 'RCC2P6'},
{value: 'RCC2P7', id: 'RCC2P7'},
{value: 'RCC2P8', id: 'RCC2P8'},
{value: 'RCCD1', id: 'RCCD1'},
{value: 'RCCD1-AS1', id: 'RCCD1-AS1'},
{value: 'RCE1', id: 'RCE1'},
{value: 'RCHY1', id: 'RCHY1'},
{value: 'RCL1', id: 'RCL1'},
{value: 'RCN1', id: 'RCN1'},
{value: 'RCN1P1', id: 'RCN1P1'},
{value: 'RCN1P2', id: 'RCN1P2'},
{value: 'RCN2', id: 'RCN2'},
{value: 'RCN3', id: 'RCN3'},
{value: 'RCOR1', id: 'RCOR1'},
{value: 'RCOR2', id: 'RCOR2'},
{value: 'RCOR3', id: 'RCOR3'},
{value: 'RCSD1', id: 'RCSD1'},
{value: 'RCVRN', id: 'RCVRN'},
{value: 'RD3', id: 'RD3'},
{value: 'RD3L', id: 'RD3L'},
{value: 'RDH10', id: 'RDH10'},
{value: 'RDH10-AS1', id: 'RDH10-AS1'},
{value: 'RDH11', id: 'RDH11'},
{value: 'RDH12', id: 'RDH12'},
{value: 'RDH13', id: 'RDH13'},
{value: 'RDH14', id: 'RDH14'},
{value: 'RDH16', id: 'RDH16'},
{value: 'RDH5', id: 'RDH5'},
{value: 'RDH8', id: 'RDH8'},
{value: 'RDM1', id: 'RDM1'},
{value: 'RDM1P2', id: 'RDM1P2'},
{value: 'RDM1P3', id: 'RDM1P3'},
{value: 'RDM1P4', id: 'RDM1P4'},
{value: 'RDM1P5', id: 'RDM1P5'},
{value: 'RDX', id: 'RDX'},
{value: 'RDXP1', id: 'RDXP1'},
{value: 'REC114', id: 'REC114'},
{value: 'REC8', id: 'REC8'},
{value: 'RECK', id: 'RECK'},
{value: 'RECQL', id: 'RECQL'},
{value: 'RECQL4', id: 'RECQL4'},
{value: 'RECQL5', id: 'RECQL5'},
{value: 'REELD1', id: 'REELD1'},
{value: 'REEP1', id: 'REEP1'},
{value: 'REEP2', id: 'REEP2'},
{value: 'REEP3', id: 'REEP3'},
{value: 'REEP4', id: 'REEP4'},
{value: 'REEP5', id: 'REEP5'},
{value: 'REEP6', id: 'REEP6'},
{value: 'REG1A', id: 'REG1A'},
{value: 'REG1B', id: 'REG1B'},
{value: 'REG1CP', id: 'REG1CP'},
{value: 'REG3A', id: 'REG3A'},
{value: 'REG3G', id: 'REG3G'},
{value: 'REG4', id: 'REG4'},
{value: 'REL', id: 'REL'},
{value: 'RELA', id: 'RELA'},
{value: 'RELA-DT', id: 'RELA-DT'},
{value: 'RELB', id: 'RELB'},
{value: 'RELCH', id: 'RELCH'},
{value: 'RELL1', id: 'RELL1'},
{value: 'RELL2', id: 'RELL2'},
{value: 'RELN', id: 'RELN'},
{value: 'RELT', id: 'RELT'},
{value: 'REM1', id: 'REM1'},
{value: 'REM2', id: 'REM2'},
{value: 'REN', id: 'REN'},
{value: 'RENBP', id: 'RENBP'},
{value: 'RENO1', id: 'RENO1'},
{value: 'REP15', id: 'REP15'},
{value: 'REPIN1', id: 'REPIN1'},
{value: 'REPIN1-AS1', id: 'REPIN1-AS1'},
{value: 'REPS1', id: 'REPS1'},
{value: 'REPS2', id: 'REPS2'},
{value: 'RER1', id: 'RER1'},
{value: 'RERE', id: 'RERE'},
{value: 'RERE-AS1', id: 'RERE-AS1'},
{value: 'RERG', id: 'RERG'},
{value: 'RERG-IT1', id: 'RERG-IT1'},
{value: 'RERGL', id: 'RERGL'},
{value: 'RESF1', id: 'RESF1'},
{value: 'RESP18', id: 'RESP18'},
{value: 'REST', id: 'REST'},
{value: 'RET', id: 'RET'},
{value: 'RETN', id: 'RETN'},
{value: 'RETNLB', id: 'RETNLB'},
{value: 'RETREG1', id: 'RETREG1'},
{value: 'RETREG1-AS1', id: 'RETREG1-AS1'},
{value: 'RETREG2', id: 'RETREG2'},
{value: 'RETREG3', id: 'RETREG3'},
{value: 'RETSAT', id: 'RETSAT'},
{value: 'REV1', id: 'REV1'},
{value: 'REV3L', id: 'REV3L'},
{value: 'REX1BD', id: 'REX1BD'},
{value: 'REXO1', id: 'REXO1'},
{value: 'REXO1L10P', id: 'REXO1L10P'},
{value: 'REXO1L1P', id: 'REXO1L1P'},
{value: 'REXO1L2P', id: 'REXO1L2P'},
{value: 'REXO1L3P', id: 'REXO1L3P'},
{value: 'REXO1L6P', id: 'REXO1L6P'},
{value: 'REXO1L8P', id: 'REXO1L8P'},
{value: 'REXO2', id: 'REXO2'},
{value: 'REXO4', id: 'REXO4'},
{value: 'REXO5', id: 'REXO5'},
{value: 'RFC1', id: 'RFC1'},
{value: 'RFC2', id: 'RFC2'},
{value: 'RFC3', id: 'RFC3'},
{value: 'RFC3P1', id: 'RFC3P1'},
{value: 'RFC4', id: 'RFC4'},
{value: 'RFC5', id: 'RFC5'},
{value: 'RFC5P1', id: 'RFC5P1'},
{value: 'RFESD', id: 'RFESD'},
{value: 'RFESDP1', id: 'RFESDP1'},
{value: 'RFFL', id: 'RFFL'},
{value: 'RFK', id: 'RFK'},
{value: 'RFKP1', id: 'RFKP1'},
{value: 'RFKP2', id: 'RFKP2'},
{value: 'RFLNA', id: 'RFLNA'},
{value: 'RFLNB', id: 'RFLNB'},
{value: 'RFNG', id: 'RFNG'},
{value: 'RFPL1S', id: 'RFPL1S'},
{value: 'RFPL2', id: 'RFPL2'},
{value: 'RFPL3', id: 'RFPL3'},
{value: 'RFPL3S', id: 'RFPL3S'},
{value: 'RFPL4A', id: 'RFPL4A'},
{value: 'RFPL4AL1', id: 'RFPL4AL1'},
{value: 'RFPL4AP1', id: 'RFPL4AP1'},
{value: 'RFPL4AP3', id: 'RFPL4AP3'},
{value: 'RFPL4AP6', id: 'RFPL4AP6'},
{value: 'RFPL4AP7', id: 'RFPL4AP7'},
{value: 'RFT1', id: 'RFT1'},
{value: 'RFTN1', id: 'RFTN1'},
{value: 'RFTN2', id: 'RFTN2'},
{value: 'RFWD3', id: 'RFWD3'},
{value: 'RFX1', id: 'RFX1'},
{value: 'RFX2', id: 'RFX2'},
{value: 'RFX3', id: 'RFX3'},
{value: 'RFX3-AS1', id: 'RFX3-AS1'},
{value: 'RFX5', id: 'RFX5'},
{value: 'RFX5-AS1', id: 'RFX5-AS1'},
{value: 'RFX6', id: 'RFX6'},
{value: 'RFX7', id: 'RFX7'},
{value: 'RFX8', id: 'RFX8'},
{value: 'RFXANK', id: 'RFXANK'},
{value: 'RFXAP', id: 'RFXAP'},
{value: 'RGCC', id: 'RGCC'},
{value: 'RGL1', id: 'RGL1'},
{value: 'RGL2', id: 'RGL2'},
{value: 'RGL3', id: 'RGL3'},
{value: 'RGL4', id: 'RGL4'},
{value: 'RGMA', id: 'RGMA'},
{value: 'RGMB', id: 'RGMB'},
{value: 'RGMB-AS1', id: 'RGMB-AS1'},
{value: 'RGN', id: 'RGN'},
{value: 'RGP1', id: 'RGP1'},
{value: 'RGPD1', id: 'RGPD1'},
{value: 'RGPD2', id: 'RGPD2'},
{value: 'RGPD3', id: 'RGPD3'},
{value: 'RGPD4', id: 'RGPD4'},
{value: 'RGPD4-AS1', id: 'RGPD4-AS1'},
{value: 'RGPD5', id: 'RGPD5'},
{value: 'RGPD6', id: 'RGPD6'},
{value: 'RGPD8', id: 'RGPD8'},
{value: 'RGR', id: 'RGR'},
{value: 'RGS1', id: 'RGS1'},
{value: 'RGS10', id: 'RGS10'},
{value: 'RGS11', id: 'RGS11'},
{value: 'RGS12', id: 'RGS12'},
{value: 'RGS13', id: 'RGS13'},
{value: 'RGS14', id: 'RGS14'},
{value: 'RGS16', id: 'RGS16'},
{value: 'RGS17', id: 'RGS17'},
{value: 'RGS17P1', id: 'RGS17P1'},
{value: 'RGS18', id: 'RGS18'},
{value: 'RGS19', id: 'RGS19'},
{value: 'RGS2', id: 'RGS2'},
{value: 'RGS20', id: 'RGS20'},
{value: 'RGS22', id: 'RGS22'},
{value: 'RGS3', id: 'RGS3'},
{value: 'RGS4', id: 'RGS4'},
{value: 'RGS5', id: 'RGS5'},
{value: 'RGS6', id: 'RGS6'},
{value: 'RGS7', id: 'RGS7'},
{value: 'RGS7BP', id: 'RGS7BP'},
{value: 'RGS8', id: 'RGS8'},
{value: 'RGS9', id: 'RGS9'},
{value: 'RGS9BP', id: 'RGS9BP'},
{value: 'RGSL1', id: 'RGSL1'},
{value: 'RHAG', id: 'RHAG'},
{value: 'RHBDD1', id: 'RHBDD1'},
{value: 'RHBDD2', id: 'RHBDD2'},
{value: 'RHBDD3', id: 'RHBDD3'},
{value: 'RHBDF1', id: 'RHBDF1'},
{value: 'RHBDF1P1', id: 'RHBDF1P1'},
{value: 'RHBDF2', id: 'RHBDF2'},
{value: 'RHBDL1', id: 'RHBDL1'},
{value: 'RHBDL2', id: 'RHBDL2'},
{value: 'RHBDL3', id: 'RHBDL3'},
{value: 'RHBG', id: 'RHBG'},
{value: 'RHCE', id: 'RHCE'},
{value: 'RHCG', id: 'RHCG'},
{value: 'RHD', id: 'RHD'},
{value: 'RHEB', id: 'RHEB'},
{value: 'RHEBL1', id: 'RHEBL1'},
{value: 'RHEBP1', id: 'RHEBP1'},
{value: 'RHEBP2', id: 'RHEBP2'},
{value: 'RHEX', id: 'RHEX'},
{value: 'RHNO1', id: 'RHNO1'},
{value: 'RHO', id: 'RHO'},
{value: 'RHOA', id: 'RHOA'},
{value: 'RHOA-IT1', id: 'RHOA-IT1'},
{value: 'RHOB', id: 'RHOB'},
{value: 'RHOBTB1', id: 'RHOBTB1'},
{value: 'RHOBTB2', id: 'RHOBTB2'},
{value: 'RHOBTB3', id: 'RHOBTB3'},
{value: 'RHOC', id: 'RHOC'},
{value: 'RHOD', id: 'RHOD'},
{value: 'RHOF', id: 'RHOF'},
{value: 'RHOG', id: 'RHOG'},
{value: 'RHOH', id: 'RHOH'},
{value: 'RHOJ', id: 'RHOJ'},
{value: 'RHOQ', id: 'RHOQ'},
{value: 'RHOQ-AS1', id: 'RHOQ-AS1'},
{value: 'RHOQP1', id: 'RHOQP1'},
{value: 'RHOQP2', id: 'RHOQP2'},
{value: 'RHOQP3', id: 'RHOQP3'},
{value: 'RHOT1', id: 'RHOT1'},
{value: 'RHOT1P1', id: 'RHOT1P1'},
{value: 'RHOT1P2', id: 'RHOT1P2'},
{value: 'RHOT1P3', id: 'RHOT1P3'},
{value: 'RHOT2', id: 'RHOT2'},
{value: 'RHOU', id: 'RHOU'},
{value: 'RHOV', id: 'RHOV'},
{value: 'RHOXF1', id: 'RHOXF1'},
{value: 'RHOXF1-AS1', id: 'RHOXF1-AS1'},
{value: 'RHOXF1P1', id: 'RHOXF1P1'},
{value: 'RHOXF1P3', id: 'RHOXF1P3'},
{value: 'RHOXF2', id: 'RHOXF2'},
{value: 'RHPN1', id: 'RHPN1'},
{value: 'RHPN1-AS1', id: 'RHPN1-AS1'},
{value: 'RHPN2', id: 'RHPN2'},
{value: 'RHPN2P1', id: 'RHPN2P1'},
{value: 'RIBC1', id: 'RIBC1'},
{value: 'RIBC2', id: 'RIBC2'},
{value: 'RIC1', id: 'RIC1'},
{value: 'RIC3', id: 'RIC3'},
{value: 'RIC3-DT', id: 'RIC3-DT'},
{value: 'RIC8A', id: 'RIC8A'},
{value: 'RIC8B', id: 'RIC8B'},
{value: 'RICTOR', id: 'RICTOR'},
{value: 'RIDA', id: 'RIDA'},
{value: 'RIF1', id: 'RIF1'},
{value: 'RIIAD1', id: 'RIIAD1'},
{value: 'RILP', id: 'RILP'},
{value: 'RILPL1', id: 'RILPL1'},
{value: 'RILPL2', id: 'RILPL2'},
{value: 'RIMBP2', id: 'RIMBP2'},
{value: 'RIMBP3', id: 'RIMBP3'},
{value: 'RIMBP3B', id: 'RIMBP3B'},
{value: 'RIMBP3C', id: 'RIMBP3C'},
{value: 'RIMKLA', id: 'RIMKLA'},
{value: 'RIMKLB', id: 'RIMKLB'},
{value: 'RIMKLBP1', id: 'RIMKLBP1'},
{value: 'RIMKLBP2', id: 'RIMKLBP2'},
{value: 'RIMS1', id: 'RIMS1'},
{value: 'RIMS2', id: 'RIMS2'},
{value: 'RIMS3', id: 'RIMS3'},
{value: 'RIMS4', id: 'RIMS4'},
{value: 'RIN1', id: 'RIN1'},
{value: 'RIN2', id: 'RIN2'},
{value: 'RIN3', id: 'RIN3'},
{value: 'RING1', id: 'RING1'},
{value: 'RINL', id: 'RINL'},
{value: 'RINT1', id: 'RINT1'},
{value: 'RIOK1', id: 'RIOK1'},
{value: 'RIOK2', id: 'RIOK2'},
{value: 'RIOK3', id: 'RIOK3'},
{value: 'RIOK3P1', id: 'RIOK3P1'},
{value: 'RIOX1', id: 'RIOX1'},
{value: 'RIOX2', id: 'RIOX2'},
{value: 'RIPK1', id: 'RIPK1'},
{value: 'RIPK2', id: 'RIPK2'},
{value: 'RIPK2-DT', id: 'RIPK2-DT'},
{value: 'RIPK3', id: 'RIPK3'},
{value: 'RIPK4', id: 'RIPK4'},
{value: 'RIPOR1', id: 'RIPOR1'},
{value: 'RIPOR2', id: 'RIPOR2'},
{value: 'RIPOR3', id: 'RIPOR3'},
{value: 'RIPOR3-AS1', id: 'RIPOR3-AS1'},
{value: 'RIPPLY2', id: 'RIPPLY2'},
{value: 'RIPPLY3', id: 'RIPPLY3'},
{value: 'RIT1', id: 'RIT1'},
{value: 'RIT2', id: 'RIT2'},
{value: 'RITA1', id: 'RITA1'},
{value: 'RLBP1', id: 'RLBP1'},
{value: 'RLF', id: 'RLF'},
{value: 'RLIM', id: 'RLIM'},
{value: 'RLIMP1', id: 'RLIMP1'},
{value: 'RLIMP2', id: 'RLIMP2'},
{value: 'RLN1', id: 'RLN1'},
{value: 'RLN2', id: 'RLN2'},
{value: 'RLN3', id: 'RLN3'},
{value: 'RMC1', id: 'RMC1'},
{value: 'RMDN1', id: 'RMDN1'},
{value: 'RMDN2', id: 'RMDN2'},
{value: 'RMDN2-AS1', id: 'RMDN2-AS1'},
{value: 'RMDN3', id: 'RMDN3'},
{value: 'RMI1', id: 'RMI1'},
{value: 'RMI2', id: 'RMI2'},
{value: 'RMND1', id: 'RMND1'},
{value: 'RMND5A', id: 'RMND5A'},
{value: 'RMND5B', id: 'RMND5B'},
{value: 'RMRP', id: 'RMRP'},
{value: 'RMRPP5', id: 'RMRPP5'},
{value: 'RMST', id: 'RMST'},
{value: 'RN7SK', id: 'RN7SK'},
{value: 'RN7SKP1', id: 'RN7SKP1'},
{value: 'RN7SKP10', id: 'RN7SKP10'},
{value: 'RN7SKP102', id: 'RN7SKP102'},
{value: 'RN7SKP104', id: 'RN7SKP104'},
{value: 'RN7SKP105', id: 'RN7SKP105'},
{value: 'RN7SKP106', id: 'RN7SKP106'},
{value: 'RN7SKP108', id: 'RN7SKP108'},
{value: 'RN7SKP11', id: 'RN7SKP11'},
{value: 'RN7SKP110', id: 'RN7SKP110'},
{value: 'RN7SKP111', id: 'RN7SKP111'},
{value: 'RN7SKP112', id: 'RN7SKP112'},
{value: 'RN7SKP113', id: 'RN7SKP113'},
{value: 'RN7SKP114', id: 'RN7SKP114'},
{value: 'RN7SKP116', id: 'RN7SKP116'},
{value: 'RN7SKP117', id: 'RN7SKP117'},
{value: 'RN7SKP118', id: 'RN7SKP118'},
{value: 'RN7SKP119', id: 'RN7SKP119'},
{value: 'RN7SKP12', id: 'RN7SKP12'},
{value: 'RN7SKP120', id: 'RN7SKP120'},
{value: 'RN7SKP121', id: 'RN7SKP121'},
{value: 'RN7SKP122', id: 'RN7SKP122'},
{value: 'RN7SKP123', id: 'RN7SKP123'},
{value: 'RN7SKP127', id: 'RN7SKP127'},
{value: 'RN7SKP129', id: 'RN7SKP129'},
{value: 'RN7SKP13', id: 'RN7SKP13'},
{value: 'RN7SKP132', id: 'RN7SKP132'},
{value: 'RN7SKP133', id: 'RN7SKP133'},
{value: 'RN7SKP134', id: 'RN7SKP134'},
{value: 'RN7SKP135', id: 'RN7SKP135'},
{value: 'RN7SKP136', id: 'RN7SKP136'},
{value: 'RN7SKP137', id: 'RN7SKP137'},
{value: 'RN7SKP14', id: 'RN7SKP14'},
{value: 'RN7SKP140', id: 'RN7SKP140'},
{value: 'RN7SKP141', id: 'RN7SKP141'},
{value: 'RN7SKP142', id: 'RN7SKP142'},
{value: 'RN7SKP143', id: 'RN7SKP143'},
{value: 'RN7SKP144', id: 'RN7SKP144'},
{value: 'RN7SKP146', id: 'RN7SKP146'},
{value: 'RN7SKP147', id: 'RN7SKP147'},
{value: 'RN7SKP148', id: 'RN7SKP148'},
{value: 'RN7SKP15', id: 'RN7SKP15'},
{value: 'RN7SKP152', id: 'RN7SKP152'},
{value: 'RN7SKP153', id: 'RN7SKP153'},
{value: 'RN7SKP154', id: 'RN7SKP154'},
{value: 'RN7SKP155', id: 'RN7SKP155'},
{value: 'RN7SKP156', id: 'RN7SKP156'},
{value: 'RN7SKP157', id: 'RN7SKP157'},
{value: 'RN7SKP159', id: 'RN7SKP159'},
{value: 'RN7SKP16', id: 'RN7SKP16'},
{value: 'RN7SKP160', id: 'RN7SKP160'},
{value: 'RN7SKP161', id: 'RN7SKP161'},
{value: 'RN7SKP164', id: 'RN7SKP164'},
{value: 'RN7SKP165', id: 'RN7SKP165'},
{value: 'RN7SKP166', id: 'RN7SKP166'},
{value: 'RN7SKP167', id: 'RN7SKP167'},
{value: 'RN7SKP168', id: 'RN7SKP168'},
{value: 'RN7SKP17', id: 'RN7SKP17'},
{value: 'RN7SKP172', id: 'RN7SKP172'},
{value: 'RN7SKP173', id: 'RN7SKP173'},
{value: 'RN7SKP174', id: 'RN7SKP174'},
{value: 'RN7SKP175', id: 'RN7SKP175'},
{value: 'RN7SKP176', id: 'RN7SKP176'},
{value: 'RN7SKP177', id: 'RN7SKP177'},
{value: 'RN7SKP178', id: 'RN7SKP178'},
{value: 'RN7SKP179', id: 'RN7SKP179'},
{value: 'RN7SKP18', id: 'RN7SKP18'},
{value: 'RN7SKP181', id: 'RN7SKP181'},
{value: 'RN7SKP182', id: 'RN7SKP182'},
{value: 'RN7SKP184', id: 'RN7SKP184'},
{value: 'RN7SKP185', id: 'RN7SKP185'},
{value: 'RN7SKP186', id: 'RN7SKP186'},
{value: 'RN7SKP188', id: 'RN7SKP188'},
{value: 'RN7SKP19', id: 'RN7SKP19'},
{value: 'RN7SKP190', id: 'RN7SKP190'},
{value: 'RN7SKP192', id: 'RN7SKP192'},
{value: 'RN7SKP195', id: 'RN7SKP195'},
{value: 'RN7SKP196', id: 'RN7SKP196'},
{value: 'RN7SKP197', id: 'RN7SKP197'},
{value: 'RN7SKP199', id: 'RN7SKP199'},
{value: 'RN7SKP202', id: 'RN7SKP202'},
{value: 'RN7SKP203', id: 'RN7SKP203'},
{value: 'RN7SKP204', id: 'RN7SKP204'},
{value: 'RN7SKP205', id: 'RN7SKP205'},
{value: 'RN7SKP206', id: 'RN7SKP206'},
{value: 'RN7SKP207', id: 'RN7SKP207'},
{value: 'RN7SKP208', id: 'RN7SKP208'},
{value: 'RN7SKP209', id: 'RN7SKP209'},
{value: 'RN7SKP21', id: 'RN7SKP21'},
{value: 'RN7SKP211', id: 'RN7SKP211'},
{value: 'RN7SKP212', id: 'RN7SKP212'},
{value: 'RN7SKP213', id: 'RN7SKP213'},
{value: 'RN7SKP214', id: 'RN7SKP214'},
{value: 'RN7SKP216', id: 'RN7SKP216'},
{value: 'RN7SKP218', id: 'RN7SKP218'},
{value: 'RN7SKP219', id: 'RN7SKP219'},
{value: 'RN7SKP222', id: 'RN7SKP222'},
{value: 'RN7SKP223', id: 'RN7SKP223'},
{value: 'RN7SKP225', id: 'RN7SKP225'},
{value: 'RN7SKP226', id: 'RN7SKP226'},
{value: 'RN7SKP227', id: 'RN7SKP227'},
{value: 'RN7SKP228', id: 'RN7SKP228'},
{value: 'RN7SKP229', id: 'RN7SKP229'},
{value: 'RN7SKP23', id: 'RN7SKP23'},
{value: 'RN7SKP231', id: 'RN7SKP231'},
{value: 'RN7SKP232', id: 'RN7SKP232'},
{value: 'RN7SKP235', id: 'RN7SKP235'},
{value: 'RN7SKP236', id: 'RN7SKP236'},
{value: 'RN7SKP237', id: 'RN7SKP237'},
{value: 'RN7SKP24', id: 'RN7SKP24'},
{value: 'RN7SKP241', id: 'RN7SKP241'},
{value: 'RN7SKP242', id: 'RN7SKP242'},
{value: 'RN7SKP243', id: 'RN7SKP243'},
{value: 'RN7SKP244', id: 'RN7SKP244'},
{value: 'RN7SKP246', id: 'RN7SKP246'},
{value: 'RN7SKP247', id: 'RN7SKP247'},
{value: 'RN7SKP249', id: 'RN7SKP249'},
{value: 'RN7SKP250', id: 'RN7SKP250'},
{value: 'RN7SKP251', id: 'RN7SKP251'},
{value: 'RN7SKP252', id: 'RN7SKP252'},
{value: 'RN7SKP253', id: 'RN7SKP253'},
{value: 'RN7SKP254', id: 'RN7SKP254'},
{value: 'RN7SKP255', id: 'RN7SKP255'},
{value: 'RN7SKP256', id: 'RN7SKP256'},
{value: 'RN7SKP257', id: 'RN7SKP257'},
{value: 'RN7SKP258', id: 'RN7SKP258'},
{value: 'RN7SKP259', id: 'RN7SKP259'},
{value: 'RN7SKP26', id: 'RN7SKP26'},
{value: 'RN7SKP260', id: 'RN7SKP260'},
{value: 'RN7SKP262', id: 'RN7SKP262'},
{value: 'RN7SKP263', id: 'RN7SKP263'},
{value: 'RN7SKP264', id: 'RN7SKP264'},
{value: 'RN7SKP265', id: 'RN7SKP265'},
{value: 'RN7SKP27', id: 'RN7SKP27'},
{value: 'RN7SKP270', id: 'RN7SKP270'},
{value: 'RN7SKP271', id: 'RN7SKP271'},
{value: 'RN7SKP272', id: 'RN7SKP272'},
{value: 'RN7SKP276', id: 'RN7SKP276'},
{value: 'RN7SKP277', id: 'RN7SKP277'},
{value: 'RN7SKP278', id: 'RN7SKP278'},
{value: 'RN7SKP279', id: 'RN7SKP279'},
{value: 'RN7SKP28', id: 'RN7SKP28'},
{value: 'RN7SKP280', id: 'RN7SKP280'},
{value: 'RN7SKP284', id: 'RN7SKP284'},
{value: 'RN7SKP286', id: 'RN7SKP286'},
{value: 'RN7SKP288', id: 'RN7SKP288'},
{value: 'RN7SKP289', id: 'RN7SKP289'},
{value: 'RN7SKP29', id: 'RN7SKP29'},
{value: 'RN7SKP293', id: 'RN7SKP293'},
{value: 'RN7SKP294', id: 'RN7SKP294'},
{value: 'RN7SKP295', id: 'RN7SKP295'},
{value: 'RN7SKP296', id: 'RN7SKP296'},
{value: 'RN7SKP297', id: 'RN7SKP297'},
{value: 'RN7SKP298', id: 'RN7SKP298'},
{value: 'RN7SKP32', id: 'RN7SKP32'},
{value: 'RN7SKP33', id: 'RN7SKP33'},
{value: 'RN7SKP35', id: 'RN7SKP35'},
{value: 'RN7SKP36', id: 'RN7SKP36'},
{value: 'RN7SKP39', id: 'RN7SKP39'},
{value: 'RN7SKP43', id: 'RN7SKP43'},
{value: 'RN7SKP44', id: 'RN7SKP44'},
{value: 'RN7SKP45', id: 'RN7SKP45'},
{value: 'RN7SKP46', id: 'RN7SKP46'},
{value: 'RN7SKP48', id: 'RN7SKP48'},
{value: 'RN7SKP49', id: 'RN7SKP49'},
{value: 'RN7SKP51', id: 'RN7SKP51'},
{value: 'RN7SKP54', id: 'RN7SKP54'},
{value: 'RN7SKP55', id: 'RN7SKP55'},
{value: 'RN7SKP57', id: 'RN7SKP57'},
{value: 'RN7SKP59', id: 'RN7SKP59'},
{value: 'RN7SKP6', id: 'RN7SKP6'},
{value: 'RN7SKP61', id: 'RN7SKP61'},
{value: 'RN7SKP62', id: 'RN7SKP62'},
{value: 'RN7SKP64', id: 'RN7SKP64'},
{value: 'RN7SKP65', id: 'RN7SKP65'},
{value: 'RN7SKP66', id: 'RN7SKP66'},
{value: 'RN7SKP68', id: 'RN7SKP68'},
{value: 'RN7SKP70', id: 'RN7SKP70'},
{value: 'RN7SKP72', id: 'RN7SKP72'},
{value: 'RN7SKP73', id: 'RN7SKP73'},
{value: 'RN7SKP75', id: 'RN7SKP75'},
{value: 'RN7SKP82', id: 'RN7SKP82'},
{value: 'RN7SKP83', id: 'RN7SKP83'},
{value: 'RN7SKP87', id: 'RN7SKP87'},
{value: 'RN7SKP91', id: 'RN7SKP91'},
{value: 'RN7SKP93', id: 'RN7SKP93'},
{value: 'RN7SKP94', id: 'RN7SKP94'},
{value: 'RN7SKP96', id: 'RN7SKP96'},
{value: 'RN7SKP98', id: 'RN7SKP98'},
{value: 'RN7SKP99', id: 'RN7SKP99'},
{value: 'RN7SL1', id: 'RN7SL1'},
{value: 'RN7SL100P', id: 'RN7SL100P'},
{value: 'RN7SL101P', id: 'RN7SL101P'},
{value: 'RN7SL104P', id: 'RN7SL104P'},
{value: 'RN7SL105P', id: 'RN7SL105P'},
{value: 'RN7SL107P', id: 'RN7SL107P'},
{value: 'RN7SL113P', id: 'RN7SL113P'},
{value: 'RN7SL116P', id: 'RN7SL116P'},
{value: 'RN7SL117P', id: 'RN7SL117P'},
{value: 'RN7SL11P', id: 'RN7SL11P'},
{value: 'RN7SL120P', id: 'RN7SL120P'},
{value: 'RN7SL122P', id: 'RN7SL122P'},
{value: 'RN7SL124P', id: 'RN7SL124P'},
{value: 'RN7SL125P', id: 'RN7SL125P'},
{value: 'RN7SL128P', id: 'RN7SL128P'},
{value: 'RN7SL130P', id: 'RN7SL130P'},
{value: 'RN7SL131P', id: 'RN7SL131P'},
{value: 'RN7SL132P', id: 'RN7SL132P'},
{value: 'RN7SL135P', id: 'RN7SL135P'},
{value: 'RN7SL138P', id: 'RN7SL138P'},
{value: 'RN7SL140P', id: 'RN7SL140P'},
{value: 'RN7SL141P', id: 'RN7SL141P'},
{value: 'RN7SL143P', id: 'RN7SL143P'},
{value: 'RN7SL147P', id: 'RN7SL147P'},
{value: 'RN7SL148P', id: 'RN7SL148P'},
{value: 'RN7SL14P', id: 'RN7SL14P'},
{value: 'RN7SL150P', id: 'RN7SL150P'},
{value: 'RN7SL151P', id: 'RN7SL151P'},
{value: 'RN7SL152P', id: 'RN7SL152P'},
{value: 'RN7SL153P', id: 'RN7SL153P'},
{value: 'RN7SL155P', id: 'RN7SL155P'},
{value: 'RN7SL156P', id: 'RN7SL156P'},
{value: 'RN7SL159P', id: 'RN7SL159P'},
{value: 'RN7SL164P', id: 'RN7SL164P'},
{value: 'RN7SL165P', id: 'RN7SL165P'},
{value: 'RN7SL167P', id: 'RN7SL167P'},
{value: 'RN7SL168P', id: 'RN7SL168P'},
{value: 'RN7SL170P', id: 'RN7SL170P'},
{value: 'RN7SL172P', id: 'RN7SL172P'},
{value: 'RN7SL176P', id: 'RN7SL176P'},
{value: 'RN7SL177P', id: 'RN7SL177P'},
{value: 'RN7SL178P', id: 'RN7SL178P'},
{value: 'RN7SL183P', id: 'RN7SL183P'},
{value: 'RN7SL184P', id: 'RN7SL184P'},
{value: 'RN7SL187P', id: 'RN7SL187P'},
{value: 'RN7SL189P', id: 'RN7SL189P'},
{value: 'RN7SL18P', id: 'RN7SL18P'},
{value: 'RN7SL197P', id: 'RN7SL197P'},
{value: 'RN7SL198P', id: 'RN7SL198P'},
{value: 'RN7SL199P', id: 'RN7SL199P'},
{value: 'RN7SL19P', id: 'RN7SL19P'},
{value: 'RN7SL2', id: 'RN7SL2'},
{value: 'RN7SL201P', id: 'RN7SL201P'},
{value: 'RN7SL205P', id: 'RN7SL205P'},
{value: 'RN7SL208P', id: 'RN7SL208P'},
{value: 'RN7SL210P', id: 'RN7SL210P'},
{value: 'RN7SL211P', id: 'RN7SL211P'},
{value: 'RN7SL215P', id: 'RN7SL215P'},
{value: 'RN7SL216P', id: 'RN7SL216P'},
{value: 'RN7SL218P', id: 'RN7SL218P'},
{value: 'RN7SL221P', id: 'RN7SL221P'},
{value: 'RN7SL222P', id: 'RN7SL222P'},
{value: 'RN7SL223P', id: 'RN7SL223P'},
{value: 'RN7SL225P', id: 'RN7SL225P'},
{value: 'RN7SL228P', id: 'RN7SL228P'},
{value: 'RN7SL229P', id: 'RN7SL229P'},
{value: 'RN7SL231P', id: 'RN7SL231P'},
{value: 'RN7SL23P', id: 'RN7SL23P'},
{value: 'RN7SL240P', id: 'RN7SL240P'},
{value: 'RN7SL244P', id: 'RN7SL244P'},
{value: 'RN7SL248P', id: 'RN7SL248P'},
{value: 'RN7SL250P', id: 'RN7SL250P'},
{value: 'RN7SL251P', id: 'RN7SL251P'},
{value: 'RN7SL252P', id: 'RN7SL252P'},
{value: 'RN7SL253P', id: 'RN7SL253P'},
{value: 'RN7SL254P', id: 'RN7SL254P'},
{value: 'RN7SL258P', id: 'RN7SL258P'},
{value: 'RN7SL259P', id: 'RN7SL259P'},
{value: 'RN7SL25P', id: 'RN7SL25P'},
{value: 'RN7SL260P', id: 'RN7SL260P'},
{value: 'RN7SL261P', id: 'RN7SL261P'},
{value: 'RN7SL269P', id: 'RN7SL269P'},
{value: 'RN7SL271P', id: 'RN7SL271P'},
{value: 'RN7SL272P', id: 'RN7SL272P'},
{value: 'RN7SL273P', id: 'RN7SL273P'},
{value: 'RN7SL278P', id: 'RN7SL278P'},
{value: 'RN7SL282P', id: 'RN7SL282P'},
{value: 'RN7SL283P', id: 'RN7SL283P'},
{value: 'RN7SL285P', id: 'RN7SL285P'},
{value: 'RN7SL28P', id: 'RN7SL28P'},
{value: 'RN7SL290P', id: 'RN7SL290P'},
{value: 'RN7SL292P', id: 'RN7SL292P'},
{value: 'RN7SL293P', id: 'RN7SL293P'},
{value: 'RN7SL294P', id: 'RN7SL294P'},
{value: 'RN7SL296P', id: 'RN7SL296P'},
{value: 'RN7SL297P', id: 'RN7SL297P'},
{value: 'RN7SL299P', id: 'RN7SL299P'},
{value: 'RN7SL3', id: 'RN7SL3'},
{value: 'RN7SL300P', id: 'RN7SL300P'},
{value: 'RN7SL301P', id: 'RN7SL301P'},
{value: 'RN7SL302P', id: 'RN7SL302P'},
{value: 'RN7SL304P', id: 'RN7SL304P'},
{value: 'RN7SL305P', id: 'RN7SL305P'},
{value: 'RN7SL306P', id: 'RN7SL306P'},
{value: 'RN7SL307P', id: 'RN7SL307P'},
{value: 'RN7SL309P', id: 'RN7SL309P'},
{value: 'RN7SL310P', id: 'RN7SL310P'},
{value: 'RN7SL311P', id: 'RN7SL311P'},
{value: 'RN7SL313P', id: 'RN7SL313P'},
{value: 'RN7SL316P', id: 'RN7SL316P'},
{value: 'RN7SL317P', id: 'RN7SL317P'},
{value: 'RN7SL318P', id: 'RN7SL318P'},
{value: 'RN7SL321P', id: 'RN7SL321P'},
{value: 'RN7SL322P', id: 'RN7SL322P'},
{value: 'RN7SL323P', id: 'RN7SL323P'},
{value: 'RN7SL328P', id: 'RN7SL328P'},
{value: 'RN7SL329P', id: 'RN7SL329P'},
{value: 'RN7SL332P', id: 'RN7SL332P'},
{value: 'RN7SL333P', id: 'RN7SL333P'},
{value: 'RN7SL334P', id: 'RN7SL334P'},
{value: 'RN7SL335P', id: 'RN7SL335P'},
{value: 'RN7SL336P', id: 'RN7SL336P'},
{value: 'RN7SL337P', id: 'RN7SL337P'},
{value: 'RN7SL338P', id: 'RN7SL338P'},
{value: 'RN7SL339P', id: 'RN7SL339P'},
{value: 'RN7SL33P', id: 'RN7SL33P'},
{value: 'RN7SL340P', id: 'RN7SL340P'},
{value: 'RN7SL341P', id: 'RN7SL341P'},
{value: 'RN7SL342P', id: 'RN7SL342P'},
{value: 'RN7SL343P', id: 'RN7SL343P'},
{value: 'RN7SL34P', id: 'RN7SL34P'},
{value: 'RN7SL350P', id: 'RN7SL350P'},
{value: 'RN7SL352P', id: 'RN7SL352P'},
{value: 'RN7SL354P', id: 'RN7SL354P'},
{value: 'RN7SL357P', id: 'RN7SL357P'},
{value: 'RN7SL359P', id: 'RN7SL359P'},
{value: 'RN7SL35P', id: 'RN7SL35P'},
{value: 'RN7SL361P', id: 'RN7SL361P'},
{value: 'RN7SL362P', id: 'RN7SL362P'},
{value: 'RN7SL363P', id: 'RN7SL363P'},
{value: 'RN7SL364P', id: 'RN7SL364P'},
{value: 'RN7SL366P', id: 'RN7SL366P'},
{value: 'RN7SL368P', id: 'RN7SL368P'},
{value: 'RN7SL371P', id: 'RN7SL371P'},
{value: 'RN7SL372P', id: 'RN7SL372P'},
{value: 'RN7SL375P', id: 'RN7SL375P'},
{value: 'RN7SL378P', id: 'RN7SL378P'},
{value: 'RN7SL380P', id: 'RN7SL380P'},
{value: 'RN7SL382P', id: 'RN7SL382P'},
{value: 'RN7SL383P', id: 'RN7SL383P'},
{value: 'RN7SL384P', id: 'RN7SL384P'},
{value: 'RN7SL385P', id: 'RN7SL385P'},
{value: 'RN7SL38P', id: 'RN7SL38P'},
{value: 'RN7SL391P', id: 'RN7SL391P'},
{value: 'RN7SL392P', id: 'RN7SL392P'},
{value: 'RN7SL393P', id: 'RN7SL393P'},
{value: 'RN7SL394P', id: 'RN7SL394P'},
{value: 'RN7SL396P', id: 'RN7SL396P'},
{value: 'RN7SL397P', id: 'RN7SL397P'},
{value: 'RN7SL398P', id: 'RN7SL398P'},
{value: 'RN7SL399P', id: 'RN7SL399P'},
{value: 'RN7SL39P', id: 'RN7SL39P'},
{value: 'RN7SL400P', id: 'RN7SL400P'},
{value: 'RN7SL401P', id: 'RN7SL401P'},
{value: 'RN7SL402P', id: 'RN7SL402P'},
{value: 'RN7SL403P', id: 'RN7SL403P'},
{value: 'RN7SL408P', id: 'RN7SL408P'},
{value: 'RN7SL40P', id: 'RN7SL40P'},
{value: 'RN7SL415P', id: 'RN7SL415P'},
{value: 'RN7SL416P', id: 'RN7SL416P'},
{value: 'RN7SL417P', id: 'RN7SL417P'},
{value: 'RN7SL419P', id: 'RN7SL419P'},
{value: 'RN7SL41P', id: 'RN7SL41P'},
{value: 'RN7SL420P', id: 'RN7SL420P'},
{value: 'RN7SL422P', id: 'RN7SL422P'},
{value: 'RN7SL423P', id: 'RN7SL423P'},
{value: 'RN7SL426P', id: 'RN7SL426P'},
{value: 'RN7SL42P', id: 'RN7SL42P'},
{value: 'RN7SL431P', id: 'RN7SL431P'},
{value: 'RN7SL432P', id: 'RN7SL432P'},
{value: 'RN7SL434P', id: 'RN7SL434P'},
{value: 'RN7SL435P', id: 'RN7SL435P'},
{value: 'RN7SL43P', id: 'RN7SL43P'},
{value: 'RN7SL442P', id: 'RN7SL442P'},
{value: 'RN7SL443P', id: 'RN7SL443P'},
{value: 'RN7SL444P', id: 'RN7SL444P'},
{value: 'RN7SL446P', id: 'RN7SL446P'},
{value: 'RN7SL447P', id: 'RN7SL447P'},
{value: 'RN7SL448P', id: 'RN7SL448P'},
{value: 'RN7SL44P', id: 'RN7SL44P'},
{value: 'RN7SL451P', id: 'RN7SL451P'},
{value: 'RN7SL452P', id: 'RN7SL452P'},
{value: 'RN7SL453P', id: 'RN7SL453P'},
{value: 'RN7SL457P', id: 'RN7SL457P'},
{value: 'RN7SL459P', id: 'RN7SL459P'},
{value: 'RN7SL461P', id: 'RN7SL461P'},
{value: 'RN7SL462P', id: 'RN7SL462P'},
{value: 'RN7SL464P', id: 'RN7SL464P'},
{value: 'RN7SL466P', id: 'RN7SL466P'},
{value: 'RN7SL468P', id: 'RN7SL468P'},
{value: 'RN7SL471P', id: 'RN7SL471P'},
{value: 'RN7SL472P', id: 'RN7SL472P'},
{value: 'RN7SL473P', id: 'RN7SL473P'},
{value: 'RN7SL475P', id: 'RN7SL475P'},
{value: 'RN7SL477P', id: 'RN7SL477P'},
{value: 'RN7SL478P', id: 'RN7SL478P'},
{value: 'RN7SL479P', id: 'RN7SL479P'},
{value: 'RN7SL480P', id: 'RN7SL480P'},
{value: 'RN7SL482P', id: 'RN7SL482P'},
{value: 'RN7SL483P', id: 'RN7SL483P'},
{value: 'RN7SL487P', id: 'RN7SL487P'},
{value: 'RN7SL489P', id: 'RN7SL489P'},
{value: 'RN7SL492P', id: 'RN7SL492P'},
{value: 'RN7SL493P', id: 'RN7SL493P'},
{value: 'RN7SL494P', id: 'RN7SL494P'},
{value: 'RN7SL495P', id: 'RN7SL495P'},
{value: 'RN7SL496P', id: 'RN7SL496P'},
{value: 'RN7SL497P', id: 'RN7SL497P'},
{value: 'RN7SL499P', id: 'RN7SL499P'},
{value: 'RN7SL4P', id: 'RN7SL4P'},
{value: 'RN7SL503P', id: 'RN7SL503P'},
{value: 'RN7SL504P', id: 'RN7SL504P'},
{value: 'RN7SL505P', id: 'RN7SL505P'},
{value: 'RN7SL507P', id: 'RN7SL507P'},
{value: 'RN7SL508P', id: 'RN7SL508P'},
{value: 'RN7SL509P', id: 'RN7SL509P'},
{value: 'RN7SL50P', id: 'RN7SL50P'},
{value: 'RN7SL513P', id: 'RN7SL513P'},
{value: 'RN7SL515P', id: 'RN7SL515P'},
{value: 'RN7SL517P', id: 'RN7SL517P'},
{value: 'RN7SL518P', id: 'RN7SL518P'},
{value: 'RN7SL519P', id: 'RN7SL519P'},
{value: 'RN7SL51P', id: 'RN7SL51P'},
{value: 'RN7SL520P', id: 'RN7SL520P'},
{value: 'RN7SL521P', id: 'RN7SL521P'},
{value: 'RN7SL523P', id: 'RN7SL523P'},
{value: 'RN7SL525P', id: 'RN7SL525P'},
{value: 'RN7SL52P', id: 'RN7SL52P'},
{value: 'RN7SL530P', id: 'RN7SL530P'},
{value: 'RN7SL536P', id: 'RN7SL536P'},
{value: 'RN7SL538P', id: 'RN7SL538P'},
{value: 'RN7SL541P', id: 'RN7SL541P'},
{value: 'RN7SL542P', id: 'RN7SL542P'},
{value: 'RN7SL543P', id: 'RN7SL543P'},
{value: 'RN7SL545P', id: 'RN7SL545P'},
{value: 'RN7SL546P', id: 'RN7SL546P'},
{value: 'RN7SL547P', id: 'RN7SL547P'},
{value: 'RN7SL549P', id: 'RN7SL549P'},
{value: 'RN7SL551P', id: 'RN7SL551P'},
{value: 'RN7SL552P', id: 'RN7SL552P'},
{value: 'RN7SL553P', id: 'RN7SL553P'},
{value: 'RN7SL554P', id: 'RN7SL554P'},
{value: 'RN7SL555P', id: 'RN7SL555P'},
{value: 'RN7SL556P', id: 'RN7SL556P'},
{value: 'RN7SL557P', id: 'RN7SL557P'},
{value: 'RN7SL558P', id: 'RN7SL558P'},
{value: 'RN7SL561P', id: 'RN7SL561P'},
{value: 'RN7SL563P', id: 'RN7SL563P'},
{value: 'RN7SL564P', id: 'RN7SL564P'},
{value: 'RN7SL568P', id: 'RN7SL568P'},
{value: 'RN7SL569P', id: 'RN7SL569P'},
{value: 'RN7SL570P', id: 'RN7SL570P'},
{value: 'RN7SL571P', id: 'RN7SL571P'},
{value: 'RN7SL574P', id: 'RN7SL574P'},
{value: 'RN7SL575P', id: 'RN7SL575P'},
{value: 'RN7SL57P', id: 'RN7SL57P'},
{value: 'RN7SL584P', id: 'RN7SL584P'},
{value: 'RN7SL585P', id: 'RN7SL585P'},
{value: 'RN7SL586P', id: 'RN7SL586P'},
{value: 'RN7SL587P', id: 'RN7SL587P'},
{value: 'RN7SL589P', id: 'RN7SL589P'},
{value: 'RN7SL58P', id: 'RN7SL58P'},
{value: 'RN7SL591P', id: 'RN7SL591P'},
{value: 'RN7SL592P', id: 'RN7SL592P'},
{value: 'RN7SL596P', id: 'RN7SL596P'},
{value: 'RN7SL597P', id: 'RN7SL597P'},
{value: 'RN7SL5P', id: 'RN7SL5P'},
{value: 'RN7SL600P', id: 'RN7SL600P'},
{value: 'RN7SL601P', id: 'RN7SL601P'},
{value: 'RN7SL604P', id: 'RN7SL604P'},
{value: 'RN7SL605P', id: 'RN7SL605P'},
{value: 'RN7SL607P', id: 'RN7SL607P'},
{value: 'RN7SL608P', id: 'RN7SL608P'},
{value: 'RN7SL60P', id: 'RN7SL60P'},
{value: 'RN7SL612P', id: 'RN7SL612P'},
{value: 'RN7SL614P', id: 'RN7SL614P'},
{value: 'RN7SL615P', id: 'RN7SL615P'},
{value: 'RN7SL616P', id: 'RN7SL616P'},
{value: 'RN7SL618P', id: 'RN7SL618P'},
{value: 'RN7SL621P', id: 'RN7SL621P'},
{value: 'RN7SL622P', id: 'RN7SL622P'},
{value: 'RN7SL623P', id: 'RN7SL623P'},
{value: 'RN7SL625P', id: 'RN7SL625P'},
{value: 'RN7SL62P', id: 'RN7SL62P'},
{value: 'RN7SL632P', id: 'RN7SL632P'},
{value: 'RN7SL633P', id: 'RN7SL633P'},
{value: 'RN7SL635P', id: 'RN7SL635P'},
{value: 'RN7SL636P', id: 'RN7SL636P'},
{value: 'RN7SL63P', id: 'RN7SL63P'},
{value: 'RN7SL643P', id: 'RN7SL643P'},
{value: 'RN7SL644P', id: 'RN7SL644P'},
{value: 'RN7SL646P', id: 'RN7SL646P'},
{value: 'RN7SL647P', id: 'RN7SL647P'},
{value: 'RN7SL648P', id: 'RN7SL648P'},
{value: 'RN7SL649P', id: 'RN7SL649P'},
{value: 'RN7SL650P', id: 'RN7SL650P'},
{value: 'RN7SL653P', id: 'RN7SL653P'},
{value: 'RN7SL654P', id: 'RN7SL654P'},
{value: 'RN7SL655P', id: 'RN7SL655P'},
{value: 'RN7SL656P', id: 'RN7SL656P'},
{value: 'RN7SL659P', id: 'RN7SL659P'},
{value: 'RN7SL663P', id: 'RN7SL663P'},
{value: 'RN7SL664P', id: 'RN7SL664P'},
{value: 'RN7SL665P', id: 'RN7SL665P'},
{value: 'RN7SL666P', id: 'RN7SL666P'},
{value: 'RN7SL668P', id: 'RN7SL668P'},
{value: 'RN7SL66P', id: 'RN7SL66P'},
{value: 'RN7SL674P', id: 'RN7SL674P'},
{value: 'RN7SL675P', id: 'RN7SL675P'},
{value: 'RN7SL679P', id: 'RN7SL679P'},
{value: 'RN7SL67P', id: 'RN7SL67P'},
{value: 'RN7SL680P', id: 'RN7SL680P'},
{value: 'RN7SL685P', id: 'RN7SL685P'},
{value: 'RN7SL689P', id: 'RN7SL689P'},
{value: 'RN7SL691P', id: 'RN7SL691P'},
{value: 'RN7SL692P', id: 'RN7SL692P'},
{value: 'RN7SL700P', id: 'RN7SL700P'},
{value: 'RN7SL703P', id: 'RN7SL703P'},
{value: 'RN7SL705P', id: 'RN7SL705P'},
{value: 'RN7SL709P', id: 'RN7SL709P'},
{value: 'RN7SL70P', id: 'RN7SL70P'},
{value: 'RN7SL711P', id: 'RN7SL711P'},
{value: 'RN7SL713P', id: 'RN7SL713P'},
{value: 'RN7SL714P', id: 'RN7SL714P'},
{value: 'RN7SL718P', id: 'RN7SL718P'},
{value: 'RN7SL719P', id: 'RN7SL719P'},
{value: 'RN7SL720P', id: 'RN7SL720P'},
{value: 'RN7SL726P', id: 'RN7SL726P'},
{value: 'RN7SL728P', id: 'RN7SL728P'},
{value: 'RN7SL72P', id: 'RN7SL72P'},
{value: 'RN7SL731P', id: 'RN7SL731P'},
{value: 'RN7SL735P', id: 'RN7SL735P'},
{value: 'RN7SL736P', id: 'RN7SL736P'},
{value: 'RN7SL737P', id: 'RN7SL737P'},
{value: 'RN7SL738P', id: 'RN7SL738P'},
{value: 'RN7SL73P', id: 'RN7SL73P'},
{value: 'RN7SL745P', id: 'RN7SL745P'},
{value: 'RN7SL748P', id: 'RN7SL748P'},
{value: 'RN7SL750P', id: 'RN7SL750P'},
{value: 'RN7SL751P', id: 'RN7SL751P'},
{value: 'RN7SL753P', id: 'RN7SL753P'},
{value: 'RN7SL757P', id: 'RN7SL757P'},
{value: 'RN7SL759P', id: 'RN7SL759P'},
{value: 'RN7SL761P', id: 'RN7SL761P'},
{value: 'RN7SL766P', id: 'RN7SL766P'},
{value: 'RN7SL767P', id: 'RN7SL767P'},
{value: 'RN7SL769P', id: 'RN7SL769P'},
{value: 'RN7SL76P', id: 'RN7SL76P'},
{value: 'RN7SL771P', id: 'RN7SL771P'},
{value: 'RN7SL776P', id: 'RN7SL776P'},
{value: 'RN7SL782P', id: 'RN7SL782P'},
{value: 'RN7SL786P', id: 'RN7SL786P'},
{value: 'RN7SL787P', id: 'RN7SL787P'},
{value: 'RN7SL788P', id: 'RN7SL788P'},
{value: 'RN7SL789P', id: 'RN7SL789P'},
{value: 'RN7SL78P', id: 'RN7SL78P'},
{value: 'RN7SL791P', id: 'RN7SL791P'},
{value: 'RN7SL793P', id: 'RN7SL793P'},
{value: 'RN7SL794P', id: 'RN7SL794P'},
{value: 'RN7SL797P', id: 'RN7SL797P'},
{value: 'RN7SL798P', id: 'RN7SL798P'},
{value: 'RN7SL7P', id: 'RN7SL7P'},
{value: 'RN7SL800P', id: 'RN7SL800P'},
{value: 'RN7SL802P', id: 'RN7SL802P'},
{value: 'RN7SL803P', id: 'RN7SL803P'},
{value: 'RN7SL804P', id: 'RN7SL804P'},
{value: 'RN7SL807P', id: 'RN7SL807P'},
{value: 'RN7SL808P', id: 'RN7SL808P'},
{value: 'RN7SL809P', id: 'RN7SL809P'},
{value: 'RN7SL811P', id: 'RN7SL811P'},
{value: 'RN7SL813P', id: 'RN7SL813P'},
{value: 'RN7SL814P', id: 'RN7SL814P'},
{value: 'RN7SL815P', id: 'RN7SL815P'},
{value: 'RN7SL820P', id: 'RN7SL820P'},
{value: 'RN7SL822P', id: 'RN7SL822P'},
{value: 'RN7SL823P', id: 'RN7SL823P'},
{value: 'RN7SL824P', id: 'RN7SL824P'},
{value: 'RN7SL825P', id: 'RN7SL825P'},
{value: 'RN7SL826P', id: 'RN7SL826P'},
{value: 'RN7SL828P', id: 'RN7SL828P'},
{value: 'RN7SL82P', id: 'RN7SL82P'},
{value: 'RN7SL831P', id: 'RN7SL831P'},
{value: 'RN7SL832P', id: 'RN7SL832P'},
{value: 'RN7SL834P', id: 'RN7SL834P'},
{value: 'RN7SL836P', id: 'RN7SL836P'},
{value: 'RN7SL837P', id: 'RN7SL837P'},
{value: 'RN7SL83P', id: 'RN7SL83P'},
{value: 'RN7SL840P', id: 'RN7SL840P'},
{value: 'RN7SL841P', id: 'RN7SL841P'},
{value: 'RN7SL842P', id: 'RN7SL842P'},
{value: 'RN7SL843P', id: 'RN7SL843P'},
{value: 'RN7SL849P', id: 'RN7SL849P'},
{value: 'RN7SL853P', id: 'RN7SL853P'},
{value: 'RN7SL854P', id: 'RN7SL854P'},
{value: 'RN7SL863P', id: 'RN7SL863P'},
{value: 'RN7SL864P', id: 'RN7SL864P'},
{value: 'RN7SL865P', id: 'RN7SL865P'},
{value: 'RN7SL868P', id: 'RN7SL868P'},
{value: 'RN7SL869P', id: 'RN7SL869P'},
{value: 'RN7SL870P', id: 'RN7SL870P'},
{value: 'RN7SL87P', id: 'RN7SL87P'},
{value: 'RN7SL88P', id: 'RN7SL88P'},
{value: 'RN7SL89P', id: 'RN7SL89P'},
{value: 'RN7SL8P', id: 'RN7SL8P'},
{value: 'RN7SL97P', id: 'RN7SL97P'},
{value: 'RNA5-8SN1', id: 'RNA5-8SN1'},
{value: 'RNA5-8SN2', id: 'RNA5-8SN2'},
{value: 'RNA5-8SN3', id: 'RNA5-8SN3'},
{value: 'RNA5-8SN4', id: 'RNA5-8SN4'},
{value: 'RNA5-8SN5', id: 'RNA5-8SN5'},
{value: 'RNA5-8SP2', id: 'RNA5-8SP2'},
{value: 'RNA5-8SP4', id: 'RNA5-8SP4'},
{value: 'RNA5-8SP5', id: 'RNA5-8SP5'},
{value: 'RNA5S12', id: 'RNA5S12'},
{value: 'RNA5S17', id: 'RNA5S17'},
{value: 'RNA5S6', id: 'RNA5S6'},
{value: 'RNA5S8', id: 'RNA5S8'},
{value: 'RNA5S9', id: 'RNA5S9'},
{value: 'RNA5SP102', id: 'RNA5SP102'},
{value: 'RNA5SP103', id: 'RNA5SP103'},
{value: 'RNA5SP106', id: 'RNA5SP106'},
{value: 'RNA5SP107', id: 'RNA5SP107'},
{value: 'RNA5SP108', id: 'RNA5SP108'},
{value: 'RNA5SP110', id: 'RNA5SP110'},
{value: 'RNA5SP113', id: 'RNA5SP113'},
{value: 'RNA5SP115', id: 'RNA5SP115'},
{value: 'RNA5SP116', id: 'RNA5SP116'},
{value: 'RNA5SP117', id: 'RNA5SP117'},
{value: 'RNA5SP118', id: 'RNA5SP118'},
{value: 'RNA5SP119', id: 'RNA5SP119'},
{value: 'RNA5SP120', id: 'RNA5SP120'},
{value: 'RNA5SP121', id: 'RNA5SP121'},
{value: 'RNA5SP122', id: 'RNA5SP122'},
{value: 'RNA5SP124', id: 'RNA5SP124'},
{value: 'RNA5SP126', id: 'RNA5SP126'},
{value: 'RNA5SP127', id: 'RNA5SP127'},
{value: 'RNA5SP128', id: 'RNA5SP128'},
{value: 'RNA5SP130', id: 'RNA5SP130'},
{value: 'RNA5SP133', id: 'RNA5SP133'},
{value: 'RNA5SP136', id: 'RNA5SP136'},
{value: 'RNA5SP137', id: 'RNA5SP137'},
{value: 'RNA5SP138', id: 'RNA5SP138'},
{value: 'RNA5SP142', id: 'RNA5SP142'},
{value: 'RNA5SP143', id: 'RNA5SP143'},
{value: 'RNA5SP144', id: 'RNA5SP144'},
{value: 'RNA5SP145', id: 'RNA5SP145'},
{value: 'RNA5SP146', id: 'RNA5SP146'},
{value: 'RNA5SP148', id: 'RNA5SP148'},
{value: 'RNA5SP149', id: 'RNA5SP149'},
{value: 'RNA5SP153', id: 'RNA5SP153'},
{value: 'RNA5SP155', id: 'RNA5SP155'},
{value: 'RNA5SP157', id: 'RNA5SP157'},
{value: 'RNA5SP158', id: 'RNA5SP158'},
{value: 'RNA5SP160', id: 'RNA5SP160'},
{value: 'RNA5SP161', id: 'RNA5SP161'},
{value: 'RNA5SP163', id: 'RNA5SP163'},
{value: 'RNA5SP164', id: 'RNA5SP164'},
{value: 'RNA5SP165', id: 'RNA5SP165'},
{value: 'RNA5SP167', id: 'RNA5SP167'},
{value: 'RNA5SP169', id: 'RNA5SP169'},
{value: 'RNA5SP170', id: 'RNA5SP170'},
{value: 'RNA5SP171', id: 'RNA5SP171'},
{value: 'RNA5SP173', id: 'RNA5SP173'},
{value: 'RNA5SP174', id: 'RNA5SP174'},
{value: 'RNA5SP175', id: 'RNA5SP175'},
{value: 'RNA5SP178', id: 'RNA5SP178'},
{value: 'RNA5SP179', id: 'RNA5SP179'},
{value: 'RNA5SP18', id: 'RNA5SP18'},
{value: 'RNA5SP181', id: 'RNA5SP181'},
{value: 'RNA5SP182', id: 'RNA5SP182'},
{value: 'RNA5SP185', id: 'RNA5SP185'},
{value: 'RNA5SP186', id: 'RNA5SP186'},
{value: 'RNA5SP188', id: 'RNA5SP188'},
{value: 'RNA5SP189', id: 'RNA5SP189'},
{value: 'RNA5SP19', id: 'RNA5SP19'},
{value: 'RNA5SP190', id: 'RNA5SP190'},
{value: 'RNA5SP192', id: 'RNA5SP192'},
{value: 'RNA5SP193', id: 'RNA5SP193'},
{value: 'RNA5SP194', id: 'RNA5SP194'},
{value: 'RNA5SP195', id: 'RNA5SP195'},
{value: 'RNA5SP197', id: 'RNA5SP197'},
{value: 'RNA5SP198', id: 'RNA5SP198'},
{value: 'RNA5SP199', id: 'RNA5SP199'},
{value: 'RNA5SP20', id: 'RNA5SP20'},
{value: 'RNA5SP200', id: 'RNA5SP200'},
{value: 'RNA5SP201', id: 'RNA5SP201'},
{value: 'RNA5SP203', id: 'RNA5SP203'},
{value: 'RNA5SP204', id: 'RNA5SP204'},
{value: 'RNA5SP207', id: 'RNA5SP207'},
{value: 'RNA5SP208', id: 'RNA5SP208'},
{value: 'RNA5SP209', id: 'RNA5SP209'},
{value: 'RNA5SP210', id: 'RNA5SP210'},
{value: 'RNA5SP213', id: 'RNA5SP213'},
{value: 'RNA5SP214', id: 'RNA5SP214'},
{value: 'RNA5SP215', id: 'RNA5SP215'},
{value: 'RNA5SP216', id: 'RNA5SP216'},
{value: 'RNA5SP217', id: 'RNA5SP217'},
{value: 'RNA5SP221', id: 'RNA5SP221'},
{value: 'RNA5SP224', id: 'RNA5SP224'},
{value: 'RNA5SP226', id: 'RNA5SP226'},
{value: 'RNA5SP228', id: 'RNA5SP228'},
{value: 'RNA5SP230', id: 'RNA5SP230'},
{value: 'RNA5SP231', id: 'RNA5SP231'},
{value: 'RNA5SP232', id: 'RNA5SP232'},
{value: 'RNA5SP234', id: 'RNA5SP234'},
{value: 'RNA5SP237', id: 'RNA5SP237'},
{value: 'RNA5SP238', id: 'RNA5SP238'},
{value: 'RNA5SP239', id: 'RNA5SP239'},
{value: 'RNA5SP24', id: 'RNA5SP24'},
{value: 'RNA5SP240', id: 'RNA5SP240'},
{value: 'RNA5SP241', id: 'RNA5SP241'},
{value: 'RNA5SP242', id: 'RNA5SP242'},
{value: 'RNA5SP243', id: 'RNA5SP243'},
{value: 'RNA5SP244', id: 'RNA5SP244'},
{value: 'RNA5SP25', id: 'RNA5SP25'},
{value: 'RNA5SP251', id: 'RNA5SP251'},
{value: 'RNA5SP253', id: 'RNA5SP253'},
{value: 'RNA5SP254', id: 'RNA5SP254'},
{value: 'RNA5SP256', id: 'RNA5SP256'},
{value: 'RNA5SP258', id: 'RNA5SP258'},
{value: 'RNA5SP261', id: 'RNA5SP261'},
{value: 'RNA5SP262', id: 'RNA5SP262'},
{value: 'RNA5SP265', id: 'RNA5SP265'},
{value: 'RNA5SP266', id: 'RNA5SP266'},
{value: 'RNA5SP267', id: 'RNA5SP267'},
{value: 'RNA5SP270', id: 'RNA5SP270'},
{value: 'RNA5SP271', id: 'RNA5SP271'},
{value: 'RNA5SP273', id: 'RNA5SP273'},
{value: 'RNA5SP276', id: 'RNA5SP276'},
{value: 'RNA5SP277', id: 'RNA5SP277'},
{value: 'RNA5SP278', id: 'RNA5SP278'},
{value: 'RNA5SP279', id: 'RNA5SP279'},
{value: 'RNA5SP280', id: 'RNA5SP280'},
{value: 'RNA5SP281', id: 'RNA5SP281'},
{value: 'RNA5SP283', id: 'RNA5SP283'},
{value: 'RNA5SP284', id: 'RNA5SP284'},
{value: 'RNA5SP285', id: 'RNA5SP285'},
{value: 'RNA5SP286', id: 'RNA5SP286'},
{value: 'RNA5SP287', id: 'RNA5SP287'},
{value: 'RNA5SP288', id: 'RNA5SP288'},
{value: 'RNA5SP290', id: 'RNA5SP290'},
{value: 'RNA5SP291', id: 'RNA5SP291'},
{value: 'RNA5SP292', id: 'RNA5SP292'},
{value: 'RNA5SP293', id: 'RNA5SP293'},
{value: 'RNA5SP296', id: 'RNA5SP296'},
{value: 'RNA5SP298', id: 'RNA5SP298'},
{value: 'RNA5SP299', id: 'RNA5SP299'},
{value: 'RNA5SP30', id: 'RNA5SP30'},
{value: 'RNA5SP306', id: 'RNA5SP306'},
{value: 'RNA5SP307', id: 'RNA5SP307'},
{value: 'RNA5SP309', id: 'RNA5SP309'},
{value: 'RNA5SP31', id: 'RNA5SP31'},
{value: 'RNA5SP318', id: 'RNA5SP318'},
{value: 'RNA5SP321', id: 'RNA5SP321'},
{value: 'RNA5SP323', id: 'RNA5SP323'},
{value: 'RNA5SP324', id: 'RNA5SP324'},
{value: 'RNA5SP325', id: 'RNA5SP325'},
{value: 'RNA5SP326', id: 'RNA5SP326'},
{value: 'RNA5SP329', id: 'RNA5SP329'},
{value: 'RNA5SP33', id: 'RNA5SP33'},
{value: 'RNA5SP331', id: 'RNA5SP331'},
{value: 'RNA5SP332', id: 'RNA5SP332'},
{value: 'RNA5SP334', id: 'RNA5SP334'},
{value: 'RNA5SP337', id: 'RNA5SP337'},
{value: 'RNA5SP339', id: 'RNA5SP339'},
{value: 'RNA5SP34', id: 'RNA5SP34'},
{value: 'RNA5SP341', id: 'RNA5SP341'},
{value: 'RNA5SP342', id: 'RNA5SP342'},
{value: 'RNA5SP344', id: 'RNA5SP344'},
{value: 'RNA5SP346', id: 'RNA5SP346'},
{value: 'RNA5SP347', id: 'RNA5SP347'},
{value: 'RNA5SP349', id: 'RNA5SP349'},
{value: 'RNA5SP350', id: 'RNA5SP350'},
{value: 'RNA5SP353', id: 'RNA5SP353'},
{value: 'RNA5SP356', id: 'RNA5SP356'},
{value: 'RNA5SP357', id: 'RNA5SP357'},
{value: 'RNA5SP358', id: 'RNA5SP358'},
{value: 'RNA5SP359', id: 'RNA5SP359'},
{value: 'RNA5SP36', id: 'RNA5SP36'},
{value: 'RNA5SP360', id: 'RNA5SP360'},
{value: 'RNA5SP361', id: 'RNA5SP361'},
{value: 'RNA5SP362', id: 'RNA5SP362'},
{value: 'RNA5SP364', id: 'RNA5SP364'},
{value: 'RNA5SP367', id: 'RNA5SP367'},
{value: 'RNA5SP369', id: 'RNA5SP369'},
{value: 'RNA5SP37', id: 'RNA5SP37'},
{value: 'RNA5SP370', id: 'RNA5SP370'},
{value: 'RNA5SP371', id: 'RNA5SP371'},
{value: 'RNA5SP373', id: 'RNA5SP373'},
{value: 'RNA5SP375', id: 'RNA5SP375'},
{value: 'RNA5SP376', id: 'RNA5SP376'},
{value: 'RNA5SP377', id: 'RNA5SP377'},
{value: 'RNA5SP378', id: 'RNA5SP378'},
{value: 'RNA5SP379', id: 'RNA5SP379'},
{value: 'RNA5SP38', id: 'RNA5SP38'},
{value: 'RNA5SP380', id: 'RNA5SP380'},
{value: 'RNA5SP382', id: 'RNA5SP382'},
{value: 'RNA5SP384', id: 'RNA5SP384'},
{value: 'RNA5SP389', id: 'RNA5SP389'},
{value: 'RNA5SP39', id: 'RNA5SP39'},
{value: 'RNA5SP390', id: 'RNA5SP390'},
{value: 'RNA5SP392', id: 'RNA5SP392'},
{value: 'RNA5SP393', id: 'RNA5SP393'},
{value: 'RNA5SP396', id: 'RNA5SP396'},
{value: 'RNA5SP397', id: 'RNA5SP397'},
{value: 'RNA5SP403', id: 'RNA5SP403'},
{value: 'RNA5SP404', id: 'RNA5SP404'},
{value: 'RNA5SP406', id: 'RNA5SP406'},
{value: 'RNA5SP408', id: 'RNA5SP408'},
{value: 'RNA5SP409', id: 'RNA5SP409'},
{value: 'RNA5SP41', id: 'RNA5SP41'},
{value: 'RNA5SP410', id: 'RNA5SP410'},
{value: 'RNA5SP413', id: 'RNA5SP413'},
{value: 'RNA5SP415', id: 'RNA5SP415'},
{value: 'RNA5SP416', id: 'RNA5SP416'},
{value: 'RNA5SP418', id: 'RNA5SP418'},
{value: 'RNA5SP419', id: 'RNA5SP419'},
{value: 'RNA5SP42', id: 'RNA5SP42'},
{value: 'RNA5SP421', id: 'RNA5SP421'},
{value: 'RNA5SP425', id: 'RNA5SP425'},
{value: 'RNA5SP427', id: 'RNA5SP427'},
{value: 'RNA5SP428', id: 'RNA5SP428'},
{value: 'RNA5SP430', id: 'RNA5SP430'},
{value: 'RNA5SP431', id: 'RNA5SP431'},
{value: 'RNA5SP432', id: 'RNA5SP432'},
{value: 'RNA5SP433', id: 'RNA5SP433'},
{value: 'RNA5SP436', id: 'RNA5SP436'},
{value: 'RNA5SP44', id: 'RNA5SP44'},
{value: 'RNA5SP441', id: 'RNA5SP441'},
{value: 'RNA5SP442', id: 'RNA5SP442'},
{value: 'RNA5SP443', id: 'RNA5SP443'},
{value: 'RNA5SP444', id: 'RNA5SP444'},
{value: 'RNA5SP446', id: 'RNA5SP446'},
{value: 'RNA5SP448', id: 'RNA5SP448'},
{value: 'RNA5SP45', id: 'RNA5SP45'},
{value: 'RNA5SP450', id: 'RNA5SP450'},
{value: 'RNA5SP451', id: 'RNA5SP451'},
{value: 'RNA5SP452', id: 'RNA5SP452'},
{value: 'RNA5SP455', id: 'RNA5SP455'},
{value: 'RNA5SP456', id: 'RNA5SP456'},
{value: 'RNA5SP458', id: 'RNA5SP458'},
{value: 'RNA5SP459', id: 'RNA5SP459'},
{value: 'RNA5SP46', id: 'RNA5SP46'},
{value: 'RNA5SP461', id: 'RNA5SP461'},
{value: 'RNA5SP470', id: 'RNA5SP470'},
{value: 'RNA5SP471', id: 'RNA5SP471'},
{value: 'RNA5SP472', id: 'RNA5SP472'},
{value: 'RNA5SP478', id: 'RNA5SP478'},
{value: 'RNA5SP479', id: 'RNA5SP479'},
{value: 'RNA5SP480', id: 'RNA5SP480'},
{value: 'RNA5SP481', id: 'RNA5SP481'},
{value: 'RNA5SP482', id: 'RNA5SP482'},
{value: 'RNA5SP484', id: 'RNA5SP484'},
{value: 'RNA5SP486', id: 'RNA5SP486'},
{value: 'RNA5SP487', id: 'RNA5SP487'},
{value: 'RNA5SP488', id: 'RNA5SP488'},
{value: 'RNA5SP489', id: 'RNA5SP489'},
{value: 'RNA5SP493', id: 'RNA5SP493'},
{value: 'RNA5SP494', id: 'RNA5SP494'},
{value: 'RNA5SP495', id: 'RNA5SP495'},
{value: 'RNA5SP50', id: 'RNA5SP50'},
{value: 'RNA5SP52', id: 'RNA5SP52'},
{value: 'RNA5SP528', id: 'RNA5SP528'},
{value: 'RNA5SP530', id: 'RNA5SP530'},
{value: 'RNA5SP531', id: 'RNA5SP531'},
{value: 'RNA5SP532', id: 'RNA5SP532'},
{value: 'RNA5SP535', id: 'RNA5SP535'},
{value: 'RNA5SP54', id: 'RNA5SP54'},
{value: 'RNA5SP56', id: 'RNA5SP56'},
{value: 'RNA5SP57', id: 'RNA5SP57'},
{value: 'RNA5SP62', id: 'RNA5SP62'},
{value: 'RNA5SP63', id: 'RNA5SP63'},
{value: 'RNA5SP64', id: 'RNA5SP64'},
{value: 'RNA5SP65', id: 'RNA5SP65'},
{value: 'RNA5SP67', id: 'RNA5SP67'},
{value: 'RNA5SP69', id: 'RNA5SP69'},
{value: 'RNA5SP72', id: 'RNA5SP72'},
{value: 'RNA5SP73', id: 'RNA5SP73'},
{value: 'RNA5SP74', id: 'RNA5SP74'},
{value: 'RNA5SP75', id: 'RNA5SP75'},
{value: 'RNA5SP77', id: 'RNA5SP77'},
{value: 'RNA5SP79', id: 'RNA5SP79'},
{value: 'RNA5SP80', id: 'RNA5SP80'},
{value: 'RNA5SP84', id: 'RNA5SP84'},
{value: 'RNA5SP86', id: 'RNA5SP86'},
{value: 'RNA5SP89', id: 'RNA5SP89'},
{value: 'RNA5SP90', id: 'RNA5SP90'},
{value: 'RNA5SP92', id: 'RNA5SP92'},
{value: 'RNA5SP93', id: 'RNA5SP93'},
{value: 'RNA5SP95', id: 'RNA5SP95'},
{value: 'RNA5SP98', id: 'RNA5SP98'},
{value: 'RNA5SP99', id: 'RNA5SP99'},
{value: 'RNASE1', id: 'RNASE1'},
{value: 'RNASE10', id: 'RNASE10'},
{value: 'RNASE11', id: 'RNASE11'},
{value: 'RNASE13', id: 'RNASE13'},
{value: 'RNASE2', id: 'RNASE2'},
{value: 'RNASE2CP', id: 'RNASE2CP'},
{value: 'RNASE3', id: 'RNASE3'},
{value: 'RNASE4', id: 'RNASE4'},
{value: 'RNASE6', id: 'RNASE6'},
{value: 'RNASE8', id: 'RNASE8'},
{value: 'RNASE9', id: 'RNASE9'},
{value: 'RNASEH1', id: 'RNASEH1'},
{value: 'RNASEH1-AS1', id: 'RNASEH1-AS1'},
{value: 'RNASEH1P2', id: 'RNASEH1P2'},
{value: 'RNASEH2A', id: 'RNASEH2A'},
{value: 'RNASEH2B', id: 'RNASEH2B'},
{value: 'RNASEH2B-AS1', id: 'RNASEH2B-AS1'},
{value: 'RNASEH2C', id: 'RNASEH2C'},
{value: 'RNASEK', id: 'RNASEK'},
{value: 'RNASEK-C17orf49', id: 'RNASEK-C17orf49'},
{value: 'RNASEL', id: 'RNASEL'},
{value: 'RNASET2', id: 'RNASET2'},
{value: 'RND1', id: 'RND1'},
{value: 'RND2', id: 'RND2'},
{value: 'RND3', id: 'RND3'},
{value: 'RNF10', id: 'RNF10'},
{value: 'RNF103', id: 'RNF103'},
{value: 'RNF103-CHMP3', id: 'RNF103-CHMP3'},
{value: 'RNF10P1', id: 'RNF10P1'},
{value: 'RNF11', id: 'RNF11'},
{value: 'RNF111', id: 'RNF111'},
{value: 'RNF112', id: 'RNF112'},
{value: 'RNF113A', id: 'RNF113A'},
{value: 'RNF113B', id: 'RNF113B'},
{value: 'RNF114', id: 'RNF114'},
{value: 'RNF115', id: 'RNF115'},
{value: 'RNF11P1', id: 'RNF11P1'},
{value: 'RNF11P2', id: 'RNF11P2'},
{value: 'RNF121', id: 'RNF121'},
{value: 'RNF122', id: 'RNF122'},
{value: 'RNF123', id: 'RNF123'},
{value: 'RNF125', id: 'RNF125'},
{value: 'RNF126', id: 'RNF126'},
{value: 'RNF126P1', id: 'RNF126P1'},
{value: 'RNF128', id: 'RNF128'},
{value: 'RNF13', id: 'RNF13'},
{value: 'RNF130', id: 'RNF130'},
{value: 'RNF135', id: 'RNF135'},
{value: 'RNF138', id: 'RNF138'},
{value: 'RNF138P2', id: 'RNF138P2'},
{value: 'RNF139', id: 'RNF139'},
{value: 'RNF139-DT', id: 'RNF139-DT'},
{value: 'RNF14', id: 'RNF14'},
{value: 'RNF141', id: 'RNF141'},
{value: 'RNF144A', id: 'RNF144A'},
{value: 'RNF144B', id: 'RNF144B'},
{value: 'RNF145', id: 'RNF145'},
{value: 'RNF146', id: 'RNF146'},
{value: 'RNF149', id: 'RNF149'},
{value: 'RNF14P1', id: 'RNF14P1'},
{value: 'RNF14P2', id: 'RNF14P2'},
{value: 'RNF14P3', id: 'RNF14P3'},
{value: 'RNF150', id: 'RNF150'},
{value: 'RNF152', id: 'RNF152'},
{value: 'RNF152P1', id: 'RNF152P1'},
{value: 'RNF157', id: 'RNF157'},
{value: 'RNF157-AS1', id: 'RNF157-AS1'},
{value: 'RNF165', id: 'RNF165'},
{value: 'RNF166', id: 'RNF166'},
{value: 'RNF167', id: 'RNF167'},
{value: 'RNF168', id: 'RNF168'},
{value: 'RNF169', id: 'RNF169'},
{value: 'RNF17', id: 'RNF17'},
{value: 'RNF170', id: 'RNF170'},
{value: 'RNF175', id: 'RNF175'},
{value: 'RNF180', id: 'RNF180'},
{value: 'RNF181', id: 'RNF181'},
{value: 'RNF182', id: 'RNF182'},
{value: 'RNF183', id: 'RNF183'},
{value: 'RNF185', id: 'RNF185'},
{value: 'RNF185-AS1', id: 'RNF185-AS1'},
{value: 'RNF186', id: 'RNF186'},
{value: 'RNF187', id: 'RNF187'},
{value: 'RNF19A', id: 'RNF19A'},
{value: 'RNF19B', id: 'RNF19B'},
{value: 'RNF2', id: 'RNF2'},
{value: 'RNF20', id: 'RNF20'},
{value: 'RNF207', id: 'RNF207'},
{value: 'RNF208', id: 'RNF208'},
{value: 'RNF212', id: 'RNF212'},
{value: 'RNF212B', id: 'RNF212B'},
{value: 'RNF213', id: 'RNF213'},
{value: 'RNF213-AS1', id: 'RNF213-AS1'},
{value: 'RNF214', id: 'RNF214'},
{value: 'RNF215', id: 'RNF215'},
{value: 'RNF216', id: 'RNF216'},
{value: 'RNF216-IT1', id: 'RNF216-IT1'},
{value: 'RNF216P1', id: 'RNF216P1'},
{value: 'RNF217', id: 'RNF217'},
{value: 'RNF217-AS1', id: 'RNF217-AS1'},
{value: 'RNF220', id: 'RNF220'},
{value: 'RNF222', id: 'RNF222'},
{value: 'RNF223', id: 'RNF223'},
{value: 'RNF224', id: 'RNF224'},
{value: 'RNF225', id: 'RNF225'},
{value: 'RNF227', id: 'RNF227'},
{value: 'RNF24', id: 'RNF24'},
{value: 'RNF25', id: 'RNF25'},
{value: 'RNF26', id: 'RNF26'},
{value: 'RNF2P1', id: 'RNF2P1'},
{value: 'RNF31', id: 'RNF31'},
{value: 'RNF32', id: 'RNF32'},
{value: 'RNF32-AS1', id: 'RNF32-AS1'},
{value: 'RNF34', id: 'RNF34'},
{value: 'RNF38', id: 'RNF38'},
{value: 'RNF39', id: 'RNF39'},
{value: 'RNF4', id: 'RNF4'},
{value: 'RNF40', id: 'RNF40'},
{value: 'RNF41', id: 'RNF41'},
{value: 'RNF43', id: 'RNF43'},
{value: 'RNF44', id: 'RNF44'},
{value: 'RNF5', id: 'RNF5'},
{value: 'RNF5P1', id: 'RNF5P1'},
{value: 'RNF6', id: 'RNF6'},
{value: 'RNF6P1', id: 'RNF6P1'},
{value: 'RNF7', id: 'RNF7'},
{value: 'RNF7P1', id: 'RNF7P1'},
{value: 'RNF8', id: 'RNF8'},
{value: 'RNFT1', id: 'RNFT1'},
{value: 'RNFT1-DT', id: 'RNFT1-DT'},
{value: 'RNFT1P2', id: 'RNFT1P2'},
{value: 'RNFT1P3', id: 'RNFT1P3'},
{value: 'RNFT2', id: 'RNFT2'},
{value: 'RNGTT', id: 'RNGTT'},
{value: 'RNH1', id: 'RNH1'},
{value: 'RNLS', id: 'RNLS'},
{value: 'RNMT', id: 'RNMT'},
{value: 'RNPC3', id: 'RNPC3'},
{value: 'RNPEP', id: 'RNPEP'},
{value: 'RNPEPL1', id: 'RNPEPL1'},
{value: 'RNPS1', id: 'RNPS1'},
{value: 'RNPS1P1', id: 'RNPS1P1'},
{value: 'RNU1-1', id: 'RNU1-1'},
{value: 'RNU1-101P', id: 'RNU1-101P'},
{value: 'RNU1-103P', id: 'RNU1-103P'},
{value: 'RNU1-104P', id: 'RNU1-104P'},
{value: 'RNU1-105P', id: 'RNU1-105P'},
{value: 'RNU1-108P', id: 'RNU1-108P'},
{value: 'RNU1-114P', id: 'RNU1-114P'},
{value: 'RNU1-119P', id: 'RNU1-119P'},
{value: 'RNU1-123P', id: 'RNU1-123P'},
{value: 'RNU1-130P', id: 'RNU1-130P'},
{value: 'RNU1-131P', id: 'RNU1-131P'},
{value: 'RNU1-132P', id: 'RNU1-132P'},
{value: 'RNU1-136P', id: 'RNU1-136P'},
{value: 'RNU1-139P', id: 'RNU1-139P'},
{value: 'RNU1-141P', id: 'RNU1-141P'},
{value: 'RNU1-143P', id: 'RNU1-143P'},
{value: 'RNU1-146P', id: 'RNU1-146P'},
{value: 'RNU1-148P', id: 'RNU1-148P'},
{value: 'RNU1-14P', id: 'RNU1-14P'},
{value: 'RNU1-150P', id: 'RNU1-150P'},
{value: 'RNU1-151P', id: 'RNU1-151P'},
{value: 'RNU1-153P', id: 'RNU1-153P'},
{value: 'RNU1-154P', id: 'RNU1-154P'},
{value: 'RNU1-155P', id: 'RNU1-155P'},
{value: 'RNU1-15P', id: 'RNU1-15P'},
{value: 'RNU1-17P', id: 'RNU1-17P'},
{value: 'RNU1-18P', id: 'RNU1-18P'},
{value: 'RNU1-19P', id: 'RNU1-19P'},
{value: 'RNU1-2', id: 'RNU1-2'},
{value: 'RNU1-21P', id: 'RNU1-21P'},
{value: 'RNU1-23P', id: 'RNU1-23P'},
{value: 'RNU1-27P', id: 'RNU1-27P'},
{value: 'RNU1-28P', id: 'RNU1-28P'},
{value: 'RNU1-29P', id: 'RNU1-29P'},
{value: 'RNU1-3', id: 'RNU1-3'},
{value: 'RNU1-31P', id: 'RNU1-31P'},
{value: 'RNU1-35P', id: 'RNU1-35P'},
{value: 'RNU1-38P', id: 'RNU1-38P'},
{value: 'RNU1-39P', id: 'RNU1-39P'},
{value: 'RNU1-4', id: 'RNU1-4'},
{value: 'RNU1-42P', id: 'RNU1-42P'},
{value: 'RNU1-43P', id: 'RNU1-43P'},
{value: 'RNU1-44P', id: 'RNU1-44P'},
{value: 'RNU1-45P', id: 'RNU1-45P'},
{value: 'RNU1-46P', id: 'RNU1-46P'},
{value: 'RNU1-47P', id: 'RNU1-47P'},
{value: 'RNU1-49P', id: 'RNU1-49P'},
{value: 'RNU1-51P', id: 'RNU1-51P'},
{value: 'RNU1-55P', id: 'RNU1-55P'},
{value: 'RNU1-58P', id: 'RNU1-58P'},
{value: 'RNU1-5P', id: 'RNU1-5P'},
{value: 'RNU1-61P', id: 'RNU1-61P'},
{value: 'RNU1-62P', id: 'RNU1-62P'},
{value: 'RNU1-63P', id: 'RNU1-63P'},
{value: 'RNU1-65P', id: 'RNU1-65P'},
{value: 'RNU1-6P', id: 'RNU1-6P'},
{value: 'RNU1-72P', id: 'RNU1-72P'},
{value: 'RNU1-74P', id: 'RNU1-74P'},
{value: 'RNU1-76P', id: 'RNU1-76P'},
{value: 'RNU1-77P', id: 'RNU1-77P'},
{value: 'RNU1-78P', id: 'RNU1-78P'},
{value: 'RNU1-80P', id: 'RNU1-80P'},
{value: 'RNU1-84P', id: 'RNU1-84P'},
{value: 'RNU1-88P', id: 'RNU1-88P'},
{value: 'RNU1-89P', id: 'RNU1-89P'},
{value: 'RNU1-94P', id: 'RNU1-94P'},
{value: 'RNU1-96P', id: 'RNU1-96P'},
{value: 'RNU1-98P', id: 'RNU1-98P'},
{value: 'RNU105B', id: 'RNU105B'},
{value: 'RNU105C', id: 'RNU105C'},
{value: 'RNU11', id: 'RNU11'},
{value: 'RNU11-3P', id: 'RNU11-3P'},
{value: 'RNU2-10P', id: 'RNU2-10P'},
{value: 'RNU2-14P', id: 'RNU2-14P'},
{value: 'RNU2-16P', id: 'RNU2-16P'},
{value: 'RNU2-17P', id: 'RNU2-17P'},
{value: 'RNU2-19P', id: 'RNU2-19P'},
{value: 'RNU2-21P', id: 'RNU2-21P'},
{value: 'RNU2-23P', id: 'RNU2-23P'},
{value: 'RNU2-25P', id: 'RNU2-25P'},
{value: 'RNU2-27P', id: 'RNU2-27P'},
{value: 'RNU2-28P', id: 'RNU2-28P'},
{value: 'RNU2-2P', id: 'RNU2-2P'},
{value: 'RNU2-32P', id: 'RNU2-32P'},
{value: 'RNU2-34P', id: 'RNU2-34P'},
{value: 'RNU2-35P', id: 'RNU2-35P'},
{value: 'RNU2-36P', id: 'RNU2-36P'},
{value: 'RNU2-37P', id: 'RNU2-37P'},
{value: 'RNU2-3P', id: 'RNU2-3P'},
{value: 'RNU2-40P', id: 'RNU2-40P'},
{value: 'RNU2-41P', id: 'RNU2-41P'},
{value: 'RNU2-43P', id: 'RNU2-43P'},
{value: 'RNU2-44P', id: 'RNU2-44P'},
{value: 'RNU2-45P', id: 'RNU2-45P'},
{value: 'RNU2-47P', id: 'RNU2-47P'},
{value: 'RNU2-48P', id: 'RNU2-48P'},
{value: 'RNU2-49P', id: 'RNU2-49P'},
{value: 'RNU2-52P', id: 'RNU2-52P'},
{value: 'RNU2-54P', id: 'RNU2-54P'},
{value: 'RNU2-55P', id: 'RNU2-55P'},
{value: 'RNU2-56P', id: 'RNU2-56P'},
{value: 'RNU2-58P', id: 'RNU2-58P'},
{value: 'RNU2-61P', id: 'RNU2-61P'},
{value: 'RNU2-62P', id: 'RNU2-62P'},
{value: 'RNU2-63P', id: 'RNU2-63P'},
{value: 'RNU2-64P', id: 'RNU2-64P'},
{value: 'RNU2-71P', id: 'RNU2-71P'},
{value: 'RNU2-72P', id: 'RNU2-72P'},
{value: 'RNU2-8P', id: 'RNU2-8P'},
{value: 'RNU2-9P', id: 'RNU2-9P'},
{value: 'RNU4-1', id: 'RNU4-1'},
{value: 'RNU4-10P', id: 'RNU4-10P'},
{value: 'RNU4-11P', id: 'RNU4-11P'},
{value: 'RNU4-12P', id: 'RNU4-12P'},
{value: 'RNU4-13P', id: 'RNU4-13P'},
{value: 'RNU4-15P', id: 'RNU4-15P'},
{value: 'RNU4-17P', id: 'RNU4-17P'},
{value: 'RNU4-2', id: 'RNU4-2'},
{value: 'RNU4-20P', id: 'RNU4-20P'},
{value: 'RNU4-23P', id: 'RNU4-23P'},
{value: 'RNU4-24P', id: 'RNU4-24P'},
{value: 'RNU4-27P', id: 'RNU4-27P'},
{value: 'RNU4-32P', id: 'RNU4-32P'},
{value: 'RNU4-35P', id: 'RNU4-35P'},
{value: 'RNU4-37P', id: 'RNU4-37P'},
{value: 'RNU4-39P', id: 'RNU4-39P'},
{value: 'RNU4-40P', id: 'RNU4-40P'},
{value: 'RNU4-41P', id: 'RNU4-41P'},
{value: 'RNU4-42P', id: 'RNU4-42P'},
{value: 'RNU4-43P', id: 'RNU4-43P'},
{value: 'RNU4-45P', id: 'RNU4-45P'},
{value: 'RNU4-49P', id: 'RNU4-49P'},
{value: 'RNU4-50P', id: 'RNU4-50P'},
{value: 'RNU4-55P', id: 'RNU4-55P'},
{value: 'RNU4-57P', id: 'RNU4-57P'},
{value: 'RNU4-58P', id: 'RNU4-58P'},
{value: 'RNU4-5P', id: 'RNU4-5P'},
{value: 'RNU4-63P', id: 'RNU4-63P'},
{value: 'RNU4-64P', id: 'RNU4-64P'},
{value: 'RNU4-65P', id: 'RNU4-65P'},
{value: 'RNU4-66P', id: 'RNU4-66P'},
{value: 'RNU4-67P', id: 'RNU4-67P'},
{value: 'RNU4-69P', id: 'RNU4-69P'},
{value: 'RNU4-70P', id: 'RNU4-70P'},
{value: 'RNU4-71P', id: 'RNU4-71P'},
{value: 'RNU4-72P', id: 'RNU4-72P'},
{value: 'RNU4-73P', id: 'RNU4-73P'},
{value: 'RNU4-74P', id: 'RNU4-74P'},
{value: 'RNU4-75P', id: 'RNU4-75P'},
{value: 'RNU4-76P', id: 'RNU4-76P'},
{value: 'RNU4-77P', id: 'RNU4-77P'},
{value: 'RNU4-78P', id: 'RNU4-78P'},
{value: 'RNU4-7P', id: 'RNU4-7P'},
{value: 'RNU4-83P', id: 'RNU4-83P'},
{value: 'RNU4-84P', id: 'RNU4-84P'},
{value: 'RNU4-92P', id: 'RNU4-92P'},
{value: 'RNU4ATAC', id: 'RNU4ATAC'},
{value: 'RNU4ATAC11P', id: 'RNU4ATAC11P'},
{value: 'RNU4ATAC12P', id: 'RNU4ATAC12P'},
{value: 'RNU4ATAC13P', id: 'RNU4ATAC13P'},
{value: 'RNU4ATAC14P', id: 'RNU4ATAC14P'},
{value: 'RNU4ATAC15P', id: 'RNU4ATAC15P'},
{value: 'RNU4ATAC16P', id: 'RNU4ATAC16P'},
{value: 'RNU4ATAC17P', id: 'RNU4ATAC17P'},
{value: 'RNU4ATAC18P', id: 'RNU4ATAC18P'},
{value: 'RNU4ATAC4P', id: 'RNU4ATAC4P'},
{value: 'RNU4ATAC6P', id: 'RNU4ATAC6P'},
{value: 'RNU4ATAC7P', id: 'RNU4ATAC7P'},
{value: 'RNU4ATAC9P', id: 'RNU4ATAC9P'},
{value: 'RNU5A-1', id: 'RNU5A-1'},
{value: 'RNU5A-2P', id: 'RNU5A-2P'},
{value: 'RNU5A-8P', id: 'RNU5A-8P'},
{value: 'RNU5B-1', id: 'RNU5B-1'},
{value: 'RNU5B-3P', id: 'RNU5B-3P'},
{value: 'RNU5B-6P', id: 'RNU5B-6P'},
{value: 'RNU5D-1', id: 'RNU5D-1'},
{value: 'RNU5E-1', id: 'RNU5E-1'},
{value: 'RNU5E-4P', id: 'RNU5E-4P'},
{value: 'RNU5E-5P', id: 'RNU5E-5P'},
{value: 'RNU5E-6P', id: 'RNU5E-6P'},
{value: 'RNU5E-7P', id: 'RNU5E-7P'},
{value: 'RNU5E-9P', id: 'RNU5E-9P'},
{value: 'RNU5F-1', id: 'RNU5F-1'},
{value: 'RNU5F-2P', id: 'RNU5F-2P'},
{value: 'RNU5F-6P', id: 'RNU5F-6P'},
{value: 'RNU5F-8P', id: 'RNU5F-8P'},
{value: 'RNU6-1', id: 'RNU6-1'},
{value: 'RNU6-1001P', id: 'RNU6-1001P'},
{value: 'RNU6-1004P', id: 'RNU6-1004P'},
{value: 'RNU6-1006P', id: 'RNU6-1006P'},
{value: 'RNU6-1007P', id: 'RNU6-1007P'},
{value: 'RNU6-1009P', id: 'RNU6-1009P'},
{value: 'RNU6-1010P', id: 'RNU6-1010P'},
{value: 'RNU6-1011P', id: 'RNU6-1011P'},
{value: 'RNU6-1012P', id: 'RNU6-1012P'},
{value: 'RNU6-1013P', id: 'RNU6-1013P'},
{value: 'RNU6-1017P', id: 'RNU6-1017P'},
{value: 'RNU6-1018P', id: 'RNU6-1018P'},
{value: 'RNU6-1023P', id: 'RNU6-1023P'},
{value: 'RNU6-1029P', id: 'RNU6-1029P'},
{value: 'RNU6-102P', id: 'RNU6-102P'},
{value: 'RNU6-1031P', id: 'RNU6-1031P'},
{value: 'RNU6-1032P', id: 'RNU6-1032P'},
{value: 'RNU6-1035P', id: 'RNU6-1035P'},
{value: 'RNU6-1036P', id: 'RNU6-1036P'},
{value: 'RNU6-1037P', id: 'RNU6-1037P'},
{value: 'RNU6-1039P', id: 'RNU6-1039P'},
{value: 'RNU6-1045P', id: 'RNU6-1045P'},
{value: 'RNU6-1047P', id: 'RNU6-1047P'},
{value: 'RNU6-1048P', id: 'RNU6-1048P'},
{value: 'RNU6-1050P', id: 'RNU6-1050P'},
{value: 'RNU6-1051P', id: 'RNU6-1051P'},
{value: 'RNU6-1052P', id: 'RNU6-1052P'},
{value: 'RNU6-1053P', id: 'RNU6-1053P'},
{value: 'RNU6-1055P', id: 'RNU6-1055P'},
{value: 'RNU6-1059P', id: 'RNU6-1059P'},
{value: 'RNU6-1060P', id: 'RNU6-1060P'},
{value: 'RNU6-1064P', id: 'RNU6-1064P'},
{value: 'RNU6-1066P', id: 'RNU6-1066P'},
{value: 'RNU6-1067P', id: 'RNU6-1067P'},
{value: 'RNU6-1068P', id: 'RNU6-1068P'},
{value: 'RNU6-1069P', id: 'RNU6-1069P'},
{value: 'RNU6-1071P', id: 'RNU6-1071P'},
{value: 'RNU6-1072P', id: 'RNU6-1072P'},
{value: 'RNU6-1073P', id: 'RNU6-1073P'},
{value: 'RNU6-1074P', id: 'RNU6-1074P'},
{value: 'RNU6-1075P', id: 'RNU6-1075P'},
{value: 'RNU6-1077P', id: 'RNU6-1077P'},
{value: 'RNU6-1080P', id: 'RNU6-1080P'},
{value: 'RNU6-1085P', id: 'RNU6-1085P'},
{value: 'RNU6-1086P', id: 'RNU6-1086P'},
{value: 'RNU6-1088P', id: 'RNU6-1088P'},
{value: 'RNU6-1090P', id: 'RNU6-1090P'},
{value: 'RNU6-1091P', id: 'RNU6-1091P'},
{value: 'RNU6-1092P', id: 'RNU6-1092P'},
{value: 'RNU6-1094P', id: 'RNU6-1094P'},
{value: 'RNU6-1095P', id: 'RNU6-1095P'},
{value: 'RNU6-1096P', id: 'RNU6-1096P'},
{value: 'RNU6-1097P', id: 'RNU6-1097P'},
{value: 'RNU6-1098P', id: 'RNU6-1098P'},
{value: 'RNU6-1101P', id: 'RNU6-1101P'},
{value: 'RNU6-1102P', id: 'RNU6-1102P'},
{value: 'RNU6-1103P', id: 'RNU6-1103P'},
{value: 'RNU6-1104P', id: 'RNU6-1104P'},
{value: 'RNU6-1106P', id: 'RNU6-1106P'},
{value: 'RNU6-1109P', id: 'RNU6-1109P'},
{value: 'RNU6-110P', id: 'RNU6-110P'},
{value: 'RNU6-1113P', id: 'RNU6-1113P'},
{value: 'RNU6-1114P', id: 'RNU6-1114P'},
{value: 'RNU6-1115P', id: 'RNU6-1115P'},
{value: 'RNU6-1116P', id: 'RNU6-1116P'},
{value: 'RNU6-1119P', id: 'RNU6-1119P'},
{value: 'RNU6-111P', id: 'RNU6-111P'},
{value: 'RNU6-1120P', id: 'RNU6-1120P'},
{value: 'RNU6-1121P', id: 'RNU6-1121P'},
{value: 'RNU6-1122P', id: 'RNU6-1122P'},
{value: 'RNU6-1125P', id: 'RNU6-1125P'},
{value: 'RNU6-1126P', id: 'RNU6-1126P'},
{value: 'RNU6-1127P', id: 'RNU6-1127P'},
{value: 'RNU6-1129P', id: 'RNU6-1129P'},
{value: 'RNU6-112P', id: 'RNU6-112P'},
{value: 'RNU6-1132P', id: 'RNU6-1132P'},
{value: 'RNU6-1133P', id: 'RNU6-1133P'},
{value: 'RNU6-1134P', id: 'RNU6-1134P'},
{value: 'RNU6-1135P', id: 'RNU6-1135P'},
{value: 'RNU6-1136P', id: 'RNU6-1136P'},
{value: 'RNU6-1137P', id: 'RNU6-1137P'},
{value: 'RNU6-113P', id: 'RNU6-113P'},
{value: 'RNU6-1140P', id: 'RNU6-1140P'},
{value: 'RNU6-1141P', id: 'RNU6-1141P'},
{value: 'RNU6-1143P', id: 'RNU6-1143P'},
{value: 'RNU6-1144P', id: 'RNU6-1144P'},
{value: 'RNU6-1145P', id: 'RNU6-1145P'},
{value: 'RNU6-1146P', id: 'RNU6-1146P'},
{value: 'RNU6-1148P', id: 'RNU6-1148P'},
{value: 'RNU6-1149P', id: 'RNU6-1149P'},
{value: 'RNU6-1150P', id: 'RNU6-1150P'},
{value: 'RNU6-1158P', id: 'RNU6-1158P'},
{value: 'RNU6-1161P', id: 'RNU6-1161P'},
{value: 'RNU6-1163P', id: 'RNU6-1163P'},
{value: 'RNU6-1168P', id: 'RNU6-1168P'},
{value: 'RNU6-1171P', id: 'RNU6-1171P'},
{value: 'RNU6-1172P', id: 'RNU6-1172P'},
{value: 'RNU6-1174P', id: 'RNU6-1174P'},
{value: 'RNU6-1177P', id: 'RNU6-1177P'},
{value: 'RNU6-1179P', id: 'RNU6-1179P'},
{value: 'RNU6-1180P', id: 'RNU6-1180P'},
{value: 'RNU6-1181P', id: 'RNU6-1181P'},
{value: 'RNU6-1183P', id: 'RNU6-1183P'},
{value: 'RNU6-1187P', id: 'RNU6-1187P'},
{value: 'RNU6-1192P', id: 'RNU6-1192P'},
{value: 'RNU6-1197P', id: 'RNU6-1197P'},
{value: 'RNU6-11P', id: 'RNU6-11P'},
{value: 'RNU6-1200P', id: 'RNU6-1200P'},
{value: 'RNU6-1205P', id: 'RNU6-1205P'},
{value: 'RNU6-1208P', id: 'RNU6-1208P'},
{value: 'RNU6-120P', id: 'RNU6-120P'},
{value: 'RNU6-1211P', id: 'RNU6-1211P'},
{value: 'RNU6-1212P', id: 'RNU6-1212P'},
{value: 'RNU6-1218P', id: 'RNU6-1218P'},
{value: 'RNU6-1219P', id: 'RNU6-1219P'},
{value: 'RNU6-121P', id: 'RNU6-121P'},
{value: 'RNU6-1224P', id: 'RNU6-1224P'},
{value: 'RNU6-1228P', id: 'RNU6-1228P'},
{value: 'RNU6-1229P', id: 'RNU6-1229P'},
{value: 'RNU6-1230P', id: 'RNU6-1230P'},
{value: 'RNU6-1232P', id: 'RNU6-1232P'},
{value: 'RNU6-1235P', id: 'RNU6-1235P'},
{value: 'RNU6-1236P', id: 'RNU6-1236P'},
{value: 'RNU6-1243P', id: 'RNU6-1243P'},
{value: 'RNU6-1244P', id: 'RNU6-1244P'},
{value: 'RNU6-1248P', id: 'RNU6-1248P'},
{value: 'RNU6-1254P', id: 'RNU6-1254P'},
{value: 'RNU6-1255P', id: 'RNU6-1255P'},
{value: 'RNU6-1256P', id: 'RNU6-1256P'},
{value: 'RNU6-1261P', id: 'RNU6-1261P'},
{value: 'RNU6-1262P', id: 'RNU6-1262P'},
{value: 'RNU6-1264P', id: 'RNU6-1264P'},
{value: 'RNU6-1265P', id: 'RNU6-1265P'},
{value: 'RNU6-1266P', id: 'RNU6-1266P'},
{value: 'RNU6-1270P', id: 'RNU6-1270P'},
{value: 'RNU6-1271P', id: 'RNU6-1271P'},
{value: 'RNU6-1272P', id: 'RNU6-1272P'},
{value: 'RNU6-1273P', id: 'RNU6-1273P'},
{value: 'RNU6-1274P', id: 'RNU6-1274P'},
{value: 'RNU6-1278P', id: 'RNU6-1278P'},
{value: 'RNU6-1280P', id: 'RNU6-1280P'},
{value: 'RNU6-1285P', id: 'RNU6-1285P'},
{value: 'RNU6-1286P', id: 'RNU6-1286P'},
{value: 'RNU6-1289P', id: 'RNU6-1289P'},
{value: 'RNU6-1290P', id: 'RNU6-1290P'},
{value: 'RNU6-1296P', id: 'RNU6-1296P'},
{value: 'RNU6-1299P', id: 'RNU6-1299P'},
{value: 'RNU6-129P', id: 'RNU6-129P'},
{value: 'RNU6-12P', id: 'RNU6-12P'},
{value: 'RNU6-1300P', id: 'RNU6-1300P'},
{value: 'RNU6-1304P', id: 'RNU6-1304P'},
{value: 'RNU6-1306P', id: 'RNU6-1306P'},
{value: 'RNU6-1307P', id: 'RNU6-1307P'},
{value: 'RNU6-1310P', id: 'RNU6-1310P'},
{value: 'RNU6-1311P', id: 'RNU6-1311P'},
{value: 'RNU6-1312P', id: 'RNU6-1312P'},
{value: 'RNU6-1313P', id: 'RNU6-1313P'},
{value: 'RNU6-1315P', id: 'RNU6-1315P'},
{value: 'RNU6-131P', id: 'RNU6-131P'},
{value: 'RNU6-1324P', id: 'RNU6-1324P'},
{value: 'RNU6-1325P', id: 'RNU6-1325P'},
{value: 'RNU6-1328P', id: 'RNU6-1328P'},
{value: 'RNU6-1329P', id: 'RNU6-1329P'},
{value: 'RNU6-1335P', id: 'RNU6-1335P'},
{value: 'RNU6-1338P', id: 'RNU6-1338P'},
{value: 'RNU6-138P', id: 'RNU6-138P'},
{value: 'RNU6-13P', id: 'RNU6-13P'},
{value: 'RNU6-140P', id: 'RNU6-140P'},
{value: 'RNU6-143P', id: 'RNU6-143P'},
{value: 'RNU6-144P', id: 'RNU6-144P'},
{value: 'RNU6-147P', id: 'RNU6-147P'},
{value: 'RNU6-14P', id: 'RNU6-14P'},
{value: 'RNU6-152P', id: 'RNU6-152P'},
{value: 'RNU6-153P', id: 'RNU6-153P'},
{value: 'RNU6-158P', id: 'RNU6-158P'},
{value: 'RNU6-15P', id: 'RNU6-15P'},
{value: 'RNU6-161P', id: 'RNU6-161P'},
{value: 'RNU6-162P', id: 'RNU6-162P'},
{value: 'RNU6-163P', id: 'RNU6-163P'},
{value: 'RNU6-164P', id: 'RNU6-164P'},
{value: 'RNU6-165P', id: 'RNU6-165P'},
{value: 'RNU6-168P', id: 'RNU6-168P'},
{value: 'RNU6-170P', id: 'RNU6-170P'},
{value: 'RNU6-171P', id: 'RNU6-171P'},
{value: 'RNU6-173P', id: 'RNU6-173P'},
{value: 'RNU6-175P', id: 'RNU6-175P'},
{value: 'RNU6-176P', id: 'RNU6-176P'},
{value: 'RNU6-17P', id: 'RNU6-17P'},
{value: 'RNU6-180P', id: 'RNU6-180P'},
{value: 'RNU6-181P', id: 'RNU6-181P'},
{value: 'RNU6-182P', id: 'RNU6-182P'},
{value: 'RNU6-183P', id: 'RNU6-183P'},
{value: 'RNU6-185P', id: 'RNU6-185P'},
{value: 'RNU6-187P', id: 'RNU6-187P'},
{value: 'RNU6-188P', id: 'RNU6-188P'},
{value: 'RNU6-189P', id: 'RNU6-189P'},
{value: 'RNU6-18P', id: 'RNU6-18P'},
{value: 'RNU6-190P', id: 'RNU6-190P'},
{value: 'RNU6-191P', id: 'RNU6-191P'},
{value: 'RNU6-192P', id: 'RNU6-192P'},
{value: 'RNU6-193P', id: 'RNU6-193P'},
{value: 'RNU6-2', id: 'RNU6-2'},
{value: 'RNU6-205P', id: 'RNU6-205P'},
{value: 'RNU6-206P', id: 'RNU6-206P'},
{value: 'RNU6-210P', id: 'RNU6-210P'},
{value: 'RNU6-211P', id: 'RNU6-211P'},
{value: 'RNU6-215P', id: 'RNU6-215P'},
{value: 'RNU6-217P', id: 'RNU6-217P'},
{value: 'RNU6-219P', id: 'RNU6-219P'},
{value: 'RNU6-21P', id: 'RNU6-21P'},
{value: 'RNU6-220P', id: 'RNU6-220P'},
{value: 'RNU6-221P', id: 'RNU6-221P'},
{value: 'RNU6-222P', id: 'RNU6-222P'},
{value: 'RNU6-224P', id: 'RNU6-224P'},
{value: 'RNU6-225P', id: 'RNU6-225P'},
{value: 'RNU6-226P', id: 'RNU6-226P'},
{value: 'RNU6-227P', id: 'RNU6-227P'},
{value: 'RNU6-231P', id: 'RNU6-231P'},
{value: 'RNU6-232P', id: 'RNU6-232P'},
{value: 'RNU6-234P', id: 'RNU6-234P'},
{value: 'RNU6-237P', id: 'RNU6-237P'},
{value: 'RNU6-239P', id: 'RNU6-239P'},
{value: 'RNU6-23P', id: 'RNU6-23P'},
{value: 'RNU6-242P', id: 'RNU6-242P'},
{value: 'RNU6-243P', id: 'RNU6-243P'},
{value: 'RNU6-246P', id: 'RNU6-246P'},
{value: 'RNU6-248P', id: 'RNU6-248P'},
{value: 'RNU6-250P', id: 'RNU6-250P'},
{value: 'RNU6-251P', id: 'RNU6-251P'},
{value: 'RNU6-252P', id: 'RNU6-252P'},
{value: 'RNU6-256P', id: 'RNU6-256P'},
{value: 'RNU6-257P', id: 'RNU6-257P'},
{value: 'RNU6-259P', id: 'RNU6-259P'},
{value: 'RNU6-260P', id: 'RNU6-260P'},
{value: 'RNU6-261P', id: 'RNU6-261P'},
{value: 'RNU6-263P', id: 'RNU6-263P'},
{value: 'RNU6-267P', id: 'RNU6-267P'},
{value: 'RNU6-271P', id: 'RNU6-271P'},
{value: 'RNU6-273P', id: 'RNU6-273P'},
{value: 'RNU6-274P', id: 'RNU6-274P'},
{value: 'RNU6-275P', id: 'RNU6-275P'},
{value: 'RNU6-276P', id: 'RNU6-276P'},
{value: 'RNU6-27P', id: 'RNU6-27P'},
{value: 'RNU6-280P', id: 'RNU6-280P'},
{value: 'RNU6-281P', id: 'RNU6-281P'},
{value: 'RNU6-284P', id: 'RNU6-284P'},
{value: 'RNU6-287P', id: 'RNU6-287P'},
{value: 'RNU6-289P', id: 'RNU6-289P'},
{value: 'RNU6-293P', id: 'RNU6-293P'},
{value: 'RNU6-294P', id: 'RNU6-294P'},
{value: 'RNU6-295P', id: 'RNU6-295P'},
{value: 'RNU6-297P', id: 'RNU6-297P'},
{value: 'RNU6-301P', id: 'RNU6-301P'},
{value: 'RNU6-304P', id: 'RNU6-304P'},
{value: 'RNU6-307P', id: 'RNU6-307P'},
{value: 'RNU6-311P', id: 'RNU6-311P'},
{value: 'RNU6-313P', id: 'RNU6-313P'},
{value: 'RNU6-317P', id: 'RNU6-317P'},
{value: 'RNU6-318P', id: 'RNU6-318P'},
{value: 'RNU6-31P', id: 'RNU6-31P'},
{value: 'RNU6-322P', id: 'RNU6-322P'},
{value: 'RNU6-325P', id: 'RNU6-325P'},
{value: 'RNU6-326P', id: 'RNU6-326P'},
{value: 'RNU6-329P', id: 'RNU6-329P'},
{value: 'RNU6-32P', id: 'RNU6-32P'},
{value: 'RNU6-331P', id: 'RNU6-331P'},
{value: 'RNU6-333P', id: 'RNU6-333P'},
{value: 'RNU6-336P', id: 'RNU6-336P'},
{value: 'RNU6-338P', id: 'RNU6-338P'},
{value: 'RNU6-340P', id: 'RNU6-340P'},
{value: 'RNU6-346P', id: 'RNU6-346P'},
{value: 'RNU6-347P', id: 'RNU6-347P'},
{value: 'RNU6-348P', id: 'RNU6-348P'},
{value: 'RNU6-349P', id: 'RNU6-349P'},
{value: 'RNU6-34P', id: 'RNU6-34P'},
{value: 'RNU6-354P', id: 'RNU6-354P'},
{value: 'RNU6-356P', id: 'RNU6-356P'},
{value: 'RNU6-35P', id: 'RNU6-35P'},
{value: 'RNU6-361P', id: 'RNU6-361P'},
{value: 'RNU6-362P', id: 'RNU6-362P'},
{value: 'RNU6-363P', id: 'RNU6-363P'},
{value: 'RNU6-364P', id: 'RNU6-364P'},
{value: 'RNU6-366P', id: 'RNU6-366P'},
{value: 'RNU6-367P', id: 'RNU6-367P'},
{value: 'RNU6-369P', id: 'RNU6-369P'},
{value: 'RNU6-36P', id: 'RNU6-36P'},
{value: 'RNU6-373P', id: 'RNU6-373P'},
{value: 'RNU6-374P', id: 'RNU6-374P'},
{value: 'RNU6-37P', id: 'RNU6-37P'},
{value: 'RNU6-380P', id: 'RNU6-380P'},
{value: 'RNU6-381P', id: 'RNU6-381P'},
{value: 'RNU6-384P', id: 'RNU6-384P'},
{value: 'RNU6-387P', id: 'RNU6-387P'},
{value: 'RNU6-388P', id: 'RNU6-388P'},
{value: 'RNU6-38P', id: 'RNU6-38P'},
{value: 'RNU6-390P', id: 'RNU6-390P'},
{value: 'RNU6-391P', id: 'RNU6-391P'},
{value: 'RNU6-392P', id: 'RNU6-392P'},
{value: 'RNU6-393P', id: 'RNU6-393P'},
{value: 'RNU6-395P', id: 'RNU6-395P'},
{value: 'RNU6-397P', id: 'RNU6-397P'},
{value: 'RNU6-398P', id: 'RNU6-398P'},
{value: 'RNU6-400P', id: 'RNU6-400P'},
{value: 'RNU6-403P', id: 'RNU6-403P'},
{value: 'RNU6-406P', id: 'RNU6-406P'},
{value: 'RNU6-408P', id: 'RNU6-408P'},
{value: 'RNU6-410P', id: 'RNU6-410P'},
{value: 'RNU6-411P', id: 'RNU6-411P'},
{value: 'RNU6-412P', id: 'RNU6-412P'},
{value: 'RNU6-413P', id: 'RNU6-413P'},
{value: 'RNU6-415P', id: 'RNU6-415P'},
{value: 'RNU6-416P', id: 'RNU6-416P'},
{value: 'RNU6-417P', id: 'RNU6-417P'},
{value: 'RNU6-41P', id: 'RNU6-41P'},
{value: 'RNU6-428P', id: 'RNU6-428P'},
{value: 'RNU6-429P', id: 'RNU6-429P'},
{value: 'RNU6-430P', id: 'RNU6-430P'},
{value: 'RNU6-432P', id: 'RNU6-432P'},
{value: 'RNU6-433P', id: 'RNU6-433P'},
{value: 'RNU6-438P', id: 'RNU6-438P'},
{value: 'RNU6-440P', id: 'RNU6-440P'},
{value: 'RNU6-449P', id: 'RNU6-449P'},
{value: 'RNU6-44P', id: 'RNU6-44P'},
{value: 'RNU6-450P', id: 'RNU6-450P'},
{value: 'RNU6-451P', id: 'RNU6-451P'},
{value: 'RNU6-456P', id: 'RNU6-456P'},
{value: 'RNU6-45P', id: 'RNU6-45P'},
{value: 'RNU6-460P', id: 'RNU6-460P'},
{value: 'RNU6-461P', id: 'RNU6-461P'},
{value: 'RNU6-463P', id: 'RNU6-463P'},
{value: 'RNU6-464P', id: 'RNU6-464P'},
{value: 'RNU6-465P', id: 'RNU6-465P'},
{value: 'RNU6-466P', id: 'RNU6-466P'},
{value: 'RNU6-470P', id: 'RNU6-470P'},
{value: 'RNU6-471P', id: 'RNU6-471P'},
{value: 'RNU6-472P', id: 'RNU6-472P'},
{value: 'RNU6-474P', id: 'RNU6-474P'},
{value: 'RNU6-475P', id: 'RNU6-475P'},
{value: 'RNU6-477P', id: 'RNU6-477P'},
{value: 'RNU6-478P', id: 'RNU6-478P'},
{value: 'RNU6-479P', id: 'RNU6-479P'},
{value: 'RNU6-480P', id: 'RNU6-480P'},
{value: 'RNU6-484P', id: 'RNU6-484P'},
{value: 'RNU6-486P', id: 'RNU6-486P'},
{value: 'RNU6-487P', id: 'RNU6-487P'},
{value: 'RNU6-488P', id: 'RNU6-488P'},
{value: 'RNU6-489P', id: 'RNU6-489P'},
{value: 'RNU6-490P', id: 'RNU6-490P'},
{value: 'RNU6-491P', id: 'RNU6-491P'},
{value: 'RNU6-492P', id: 'RNU6-492P'},
{value: 'RNU6-495P', id: 'RNU6-495P'},
{value: 'RNU6-497P', id: 'RNU6-497P'},
{value: 'RNU6-498P', id: 'RNU6-498P'},
{value: 'RNU6-499P', id: 'RNU6-499P'},
{value: 'RNU6-500P', id: 'RNU6-500P'},
{value: 'RNU6-502P', id: 'RNU6-502P'},
{value: 'RNU6-510P', id: 'RNU6-510P'},
{value: 'RNU6-519P', id: 'RNU6-519P'},
{value: 'RNU6-520P', id: 'RNU6-520P'},
{value: 'RNU6-523P', id: 'RNU6-523P'},
{value: 'RNU6-525P', id: 'RNU6-525P'},
{value: 'RNU6-528P', id: 'RNU6-528P'},
{value: 'RNU6-529P', id: 'RNU6-529P'},
{value: 'RNU6-532P', id: 'RNU6-532P'},
{value: 'RNU6-533P', id: 'RNU6-533P'},
{value: 'RNU6-535P', id: 'RNU6-535P'},
{value: 'RNU6-537P', id: 'RNU6-537P'},
{value: 'RNU6-53P', id: 'RNU6-53P'},
{value: 'RNU6-540P', id: 'RNU6-540P'},
{value: 'RNU6-541P', id: 'RNU6-541P'},
{value: 'RNU6-543P', id: 'RNU6-543P'},
{value: 'RNU6-544P', id: 'RNU6-544P'},
{value: 'RNU6-546P', id: 'RNU6-546P'},
{value: 'RNU6-54P', id: 'RNU6-54P'},
{value: 'RNU6-551P', id: 'RNU6-551P'},
{value: 'RNU6-552P', id: 'RNU6-552P'},
{value: 'RNU6-553P', id: 'RNU6-553P'},
{value: 'RNU6-554P', id: 'RNU6-554P'},
{value: 'RNU6-556P', id: 'RNU6-556P'},
{value: 'RNU6-557P', id: 'RNU6-557P'},
{value: 'RNU6-560P', id: 'RNU6-560P'},
{value: 'RNU6-563P', id: 'RNU6-563P'},
{value: 'RNU6-566P', id: 'RNU6-566P'},
{value: 'RNU6-567P', id: 'RNU6-567P'},
{value: 'RNU6-573P', id: 'RNU6-573P'},
{value: 'RNU6-576P', id: 'RNU6-576P'},
{value: 'RNU6-578P', id: 'RNU6-578P'},
{value: 'RNU6-579P', id: 'RNU6-579P'},
{value: 'RNU6-582P', id: 'RNU6-582P'},
{value: 'RNU6-583P', id: 'RNU6-583P'},
{value: 'RNU6-588P', id: 'RNU6-588P'},
{value: 'RNU6-592P', id: 'RNU6-592P'},
{value: 'RNU6-596P', id: 'RNU6-596P'},
{value: 'RNU6-598P', id: 'RNU6-598P'},
{value: 'RNU6-600P', id: 'RNU6-600P'},
{value: 'RNU6-604P', id: 'RNU6-604P'},
{value: 'RNU6-609P', id: 'RNU6-609P'},
{value: 'RNU6-611P', id: 'RNU6-611P'},
{value: 'RNU6-612P', id: 'RNU6-612P'},
{value: 'RNU6-61P', id: 'RNU6-61P'},
{value: 'RNU6-622P', id: 'RNU6-622P'},
{value: 'RNU6-623P', id: 'RNU6-623P'},
{value: 'RNU6-627P', id: 'RNU6-627P'},
{value: 'RNU6-629P', id: 'RNU6-629P'},
{value: 'RNU6-62P', id: 'RNU6-62P'},
{value: 'RNU6-632P', id: 'RNU6-632P'},
{value: 'RNU6-633P', id: 'RNU6-633P'},
{value: 'RNU6-634P', id: 'RNU6-634P'},
{value: 'RNU6-635P', id: 'RNU6-635P'},
{value: 'RNU6-637P', id: 'RNU6-637P'},
{value: 'RNU6-639P', id: 'RNU6-639P'},
{value: 'RNU6-63P', id: 'RNU6-63P'},
{value: 'RNU6-640P', id: 'RNU6-640P'},
{value: 'RNU6-643P', id: 'RNU6-643P'},
{value: 'RNU6-649P', id: 'RNU6-649P'},
{value: 'RNU6-650P', id: 'RNU6-650P'},
{value: 'RNU6-653P', id: 'RNU6-653P'},
{value: 'RNU6-654P', id: 'RNU6-654P'},
{value: 'RNU6-655P', id: 'RNU6-655P'},
{value: 'RNU6-65P', id: 'RNU6-65P'},
{value: 'RNU6-660P', id: 'RNU6-660P'},
{value: 'RNU6-662P', id: 'RNU6-662P'},
{value: 'RNU6-663P', id: 'RNU6-663P'},
{value: 'RNU6-664P', id: 'RNU6-664P'},
{value: 'RNU6-666P', id: 'RNU6-666P'},
{value: 'RNU6-667P', id: 'RNU6-667P'},
{value: 'RNU6-668P', id: 'RNU6-668P'},
{value: 'RNU6-66P', id: 'RNU6-66P'},
{value: 'RNU6-675P', id: 'RNU6-675P'},
{value: 'RNU6-679P', id: 'RNU6-679P'},
{value: 'RNU6-67P', id: 'RNU6-67P'},
{value: 'RNU6-681P', id: 'RNU6-681P'},
{value: 'RNU6-682P', id: 'RNU6-682P'},
{value: 'RNU6-684P', id: 'RNU6-684P'},
{value: 'RNU6-685P', id: 'RNU6-685P'},
{value: 'RNU6-686P', id: 'RNU6-686P'},
{value: 'RNU6-692P', id: 'RNU6-692P'},
{value: 'RNU6-693P', id: 'RNU6-693P'},
{value: 'RNU6-694P', id: 'RNU6-694P'},
{value: 'RNU6-695P', id: 'RNU6-695P'},
{value: 'RNU6-696P', id: 'RNU6-696P'},
{value: 'RNU6-698P', id: 'RNU6-698P'},
{value: 'RNU6-699P', id: 'RNU6-699P'},
{value: 'RNU6-6P', id: 'RNU6-6P'},
{value: 'RNU6-7', id: 'RNU6-7'},
{value: 'RNU6-703P', id: 'RNU6-703P'},
{value: 'RNU6-704P', id: 'RNU6-704P'},
{value: 'RNU6-706P', id: 'RNU6-706P'},
{value: 'RNU6-709P', id: 'RNU6-709P'},
{value: 'RNU6-70P', id: 'RNU6-70P'},
{value: 'RNU6-710P', id: 'RNU6-710P'},
{value: 'RNU6-712P', id: 'RNU6-712P'},
{value: 'RNU6-713P', id: 'RNU6-713P'},
{value: 'RNU6-715P', id: 'RNU6-715P'},
{value: 'RNU6-716P', id: 'RNU6-716P'},
{value: 'RNU6-718P', id: 'RNU6-718P'},
{value: 'RNU6-71P', id: 'RNU6-71P'},
{value: 'RNU6-720P', id: 'RNU6-720P'},
{value: 'RNU6-727P', id: 'RNU6-727P'},
{value: 'RNU6-728P', id: 'RNU6-728P'},
{value: 'RNU6-731P', id: 'RNU6-731P'},
{value: 'RNU6-737P', id: 'RNU6-737P'},
{value: 'RNU6-739P', id: 'RNU6-739P'},
{value: 'RNU6-73P', id: 'RNU6-73P'},
{value: 'RNU6-740P', id: 'RNU6-740P'},
{value: 'RNU6-743P', id: 'RNU6-743P'},
{value: 'RNU6-744P', id: 'RNU6-744P'},
{value: 'RNU6-745P', id: 'RNU6-745P'},
{value: 'RNU6-746P', id: 'RNU6-746P'},
{value: 'RNU6-752P', id: 'RNU6-752P'},
{value: 'RNU6-753P', id: 'RNU6-753P'},
{value: 'RNU6-754P', id: 'RNU6-754P'},
{value: 'RNU6-755P', id: 'RNU6-755P'},
{value: 'RNU6-756P', id: 'RNU6-756P'},
{value: 'RNU6-758P', id: 'RNU6-758P'},
{value: 'RNU6-75P', id: 'RNU6-75P'},
{value: 'RNU6-762P', id: 'RNU6-762P'},
{value: 'RNU6-763P', id: 'RNU6-763P'},
{value: 'RNU6-765P', id: 'RNU6-765P'},
{value: 'RNU6-768P', id: 'RNU6-768P'},
{value: 'RNU6-76P', id: 'RNU6-76P'},
{value: 'RNU6-771P', id: 'RNU6-771P'},
{value: 'RNU6-772P', id: 'RNU6-772P'},
{value: 'RNU6-775P', id: 'RNU6-775P'},
{value: 'RNU6-778P', id: 'RNU6-778P'},
{value: 'RNU6-781P', id: 'RNU6-781P'},
{value: 'RNU6-782P', id: 'RNU6-782P'},
{value: 'RNU6-783P', id: 'RNU6-783P'},
{value: 'RNU6-786P', id: 'RNU6-786P'},
{value: 'RNU6-787P', id: 'RNU6-787P'},
{value: 'RNU6-788P', id: 'RNU6-788P'},
{value: 'RNU6-792P', id: 'RNU6-792P'},
{value: 'RNU6-793P', id: 'RNU6-793P'},
{value: 'RNU6-794P', id: 'RNU6-794P'},
{value: 'RNU6-797P', id: 'RNU6-797P'},
{value: 'RNU6-8', id: 'RNU6-8'},
{value: 'RNU6-803P', id: 'RNU6-803P'},
{value: 'RNU6-804P', id: 'RNU6-804P'},
{value: 'RNU6-80P', id: 'RNU6-80P'},
{value: 'RNU6-813P', id: 'RNU6-813P'},
{value: 'RNU6-81P', id: 'RNU6-81P'},
{value: 'RNU6-826P', id: 'RNU6-826P'},
{value: 'RNU6-827P', id: 'RNU6-827P'},
{value: 'RNU6-828P', id: 'RNU6-828P'},
{value: 'RNU6-829P', id: 'RNU6-829P'},
{value: 'RNU6-82P', id: 'RNU6-82P'},
{value: 'RNU6-832P', id: 'RNU6-832P'},
{value: 'RNU6-835P', id: 'RNU6-835P'},
{value: 'RNU6-837P', id: 'RNU6-837P'},
{value: 'RNU6-838P', id: 'RNU6-838P'},
{value: 'RNU6-839P', id: 'RNU6-839P'},
{value: 'RNU6-841P', id: 'RNU6-841P'},
{value: 'RNU6-842P', id: 'RNU6-842P'},
{value: 'RNU6-845P', id: 'RNU6-845P'},
{value: 'RNU6-847P', id: 'RNU6-847P'},
{value: 'RNU6-849P', id: 'RNU6-849P'},
{value: 'RNU6-84P', id: 'RNU6-84P'},
{value: 'RNU6-857P', id: 'RNU6-857P'},
{value: 'RNU6-863P', id: 'RNU6-863P'},
{value: 'RNU6-869P', id: 'RNU6-869P'},
{value: 'RNU6-86P', id: 'RNU6-86P'},
{value: 'RNU6-871P', id: 'RNU6-871P'},
{value: 'RNU6-874P', id: 'RNU6-874P'},
{value: 'RNU6-875P', id: 'RNU6-875P'},
{value: 'RNU6-878P', id: 'RNU6-878P'},
{value: 'RNU6-880P', id: 'RNU6-880P'},
{value: 'RNU6-884P', id: 'RNU6-884P'},
{value: 'RNU6-885P', id: 'RNU6-885P'},
{value: 'RNU6-887P', id: 'RNU6-887P'},
{value: 'RNU6-889P', id: 'RNU6-889P'},
{value: 'RNU6-891P', id: 'RNU6-891P'},
{value: 'RNU6-892P', id: 'RNU6-892P'},
{value: 'RNU6-896P', id: 'RNU6-896P'},
{value: 'RNU6-9', id: 'RNU6-9'},
{value: 'RNU6-900P', id: 'RNU6-900P'},
{value: 'RNU6-903P', id: 'RNU6-903P'},
{value: 'RNU6-907P', id: 'RNU6-907P'},
{value: 'RNU6-90P', id: 'RNU6-90P'},
{value: 'RNU6-910P', id: 'RNU6-910P'},
{value: 'RNU6-911P', id: 'RNU6-911P'},
{value: 'RNU6-912P', id: 'RNU6-912P'},
{value: 'RNU6-914P', id: 'RNU6-914P'},
{value: 'RNU6-916P', id: 'RNU6-916P'},
{value: 'RNU6-917P', id: 'RNU6-917P'},
{value: 'RNU6-918P', id: 'RNU6-918P'},
{value: 'RNU6-919P', id: 'RNU6-919P'},
{value: 'RNU6-91P', id: 'RNU6-91P'},
{value: 'RNU6-921P', id: 'RNU6-921P'},
{value: 'RNU6-922P', id: 'RNU6-922P'},
{value: 'RNU6-925P', id: 'RNU6-925P'},
{value: 'RNU6-926P', id: 'RNU6-926P'},
{value: 'RNU6-927P', id: 'RNU6-927P'},
{value: 'RNU6-929P', id: 'RNU6-929P'},
{value: 'RNU6-92P', id: 'RNU6-92P'},
{value: 'RNU6-931P', id: 'RNU6-931P'},
{value: 'RNU6-938P', id: 'RNU6-938P'},
{value: 'RNU6-939P', id: 'RNU6-939P'},
{value: 'RNU6-940P', id: 'RNU6-940P'},
{value: 'RNU6-945P', id: 'RNU6-945P'},
{value: 'RNU6-947P', id: 'RNU6-947P'},
{value: 'RNU6-951P', id: 'RNU6-951P'},
{value: 'RNU6-952P', id: 'RNU6-952P'},
{value: 'RNU6-953P', id: 'RNU6-953P'},
{value: 'RNU6-955P', id: 'RNU6-955P'},
{value: 'RNU6-956P', id: 'RNU6-956P'},
{value: 'RNU6-958P', id: 'RNU6-958P'},
{value: 'RNU6-961P', id: 'RNU6-961P'},
{value: 'RNU6-964P', id: 'RNU6-964P'},
{value: 'RNU6-966P', id: 'RNU6-966P'},
{value: 'RNU6-967P', id: 'RNU6-967P'},
{value: 'RNU6-968P', id: 'RNU6-968P'},
{value: 'RNU6-970P', id: 'RNU6-970P'},
{value: 'RNU6-971P', id: 'RNU6-971P'},
{value: 'RNU6-973P', id: 'RNU6-973P'},
{value: 'RNU6-976P', id: 'RNU6-976P'},
{value: 'RNU6-97P', id: 'RNU6-97P'},
{value: 'RNU6-982P', id: 'RNU6-982P'},
{value: 'RNU6-983P', id: 'RNU6-983P'},
{value: 'RNU6-990P', id: 'RNU6-990P'},
{value: 'RNU6-991P', id: 'RNU6-991P'},
{value: 'RNU6-993P', id: 'RNU6-993P'},
{value: 'RNU6-994P', id: 'RNU6-994P'},
{value: 'RNU6-996P', id: 'RNU6-996P'},
{value: 'RNU6-99P', id: 'RNU6-99P'},
{value: 'RNU6ATAC', id: 'RNU6ATAC'},
{value: 'RNU6ATAC11P', id: 'RNU6ATAC11P'},
{value: 'RNU6ATAC12P', id: 'RNU6ATAC12P'},
{value: 'RNU6ATAC13P', id: 'RNU6ATAC13P'},
{value: 'RNU6ATAC15P', id: 'RNU6ATAC15P'},
{value: 'RNU6ATAC16P', id: 'RNU6ATAC16P'},
{value: 'RNU6ATAC17P', id: 'RNU6ATAC17P'},
{value: 'RNU6ATAC18P', id: 'RNU6ATAC18P'},
{value: 'RNU6ATAC19P', id: 'RNU6ATAC19P'},
{value: 'RNU6ATAC20P', id: 'RNU6ATAC20P'},
{value: 'RNU6ATAC21P', id: 'RNU6ATAC21P'},
{value: 'RNU6ATAC24P', id: 'RNU6ATAC24P'},
{value: 'RNU6ATAC28P', id: 'RNU6ATAC28P'},
{value: 'RNU6ATAC2P', id: 'RNU6ATAC2P'},
{value: 'RNU6ATAC32P', id: 'RNU6ATAC32P'},
{value: 'RNU6ATAC36P', id: 'RNU6ATAC36P'},
{value: 'RNU6ATAC37P', id: 'RNU6ATAC37P'},
{value: 'RNU6ATAC39P', id: 'RNU6ATAC39P'},
{value: 'RNU6ATAC3P', id: 'RNU6ATAC3P'},
{value: 'RNU6ATAC40P', id: 'RNU6ATAC40P'},
{value: 'RNU6ATAC41P', id: 'RNU6ATAC41P'},
{value: 'RNU6ATAC4P', id: 'RNU6ATAC4P'},
{value: 'RNU6ATAC5P', id: 'RNU6ATAC5P'},
{value: 'RNU6ATAC7P', id: 'RNU6ATAC7P'},
{value: 'RNU6ATAC8P', id: 'RNU6ATAC8P'},
{value: 'RNU6ATAC9P', id: 'RNU6ATAC9P'},
{value: 'RNU7-1', id: 'RNU7-1'},
{value: 'RNU7-102P', id: 'RNU7-102P'},
{value: 'RNU7-106P', id: 'RNU7-106P'},
{value: 'RNU7-113P', id: 'RNU7-113P'},
{value: 'RNU7-116P', id: 'RNU7-116P'},
{value: 'RNU7-129P', id: 'RNU7-129P'},
{value: 'RNU7-133P', id: 'RNU7-133P'},
{value: 'RNU7-134P', id: 'RNU7-134P'},
{value: 'RNU7-137P', id: 'RNU7-137P'},
{value: 'RNU7-13P', id: 'RNU7-13P'},
{value: 'RNU7-141P', id: 'RNU7-141P'},
{value: 'RNU7-144P', id: 'RNU7-144P'},
{value: 'RNU7-147P', id: 'RNU7-147P'},
{value: 'RNU7-14P', id: 'RNU7-14P'},
{value: 'RNU7-152P', id: 'RNU7-152P'},
{value: 'RNU7-154P', id: 'RNU7-154P'},
{value: 'RNU7-155P', id: 'RNU7-155P'},
{value: 'RNU7-157P', id: 'RNU7-157P'},
{value: 'RNU7-159P', id: 'RNU7-159P'},
{value: 'RNU7-160P', id: 'RNU7-160P'},
{value: 'RNU7-165P', id: 'RNU7-165P'},
{value: 'RNU7-167P', id: 'RNU7-167P'},
{value: 'RNU7-173P', id: 'RNU7-173P'},
{value: 'RNU7-179P', id: 'RNU7-179P'},
{value: 'RNU7-182P', id: 'RNU7-182P'},
{value: 'RNU7-183P', id: 'RNU7-183P'},
{value: 'RNU7-188P', id: 'RNU7-188P'},
{value: 'RNU7-190P', id: 'RNU7-190P'},
{value: 'RNU7-192P', id: 'RNU7-192P'},
{value: 'RNU7-193P', id: 'RNU7-193P'},
{value: 'RNU7-194P', id: 'RNU7-194P'},
{value: 'RNU7-20P', id: 'RNU7-20P'},
{value: 'RNU7-22P', id: 'RNU7-22P'},
{value: 'RNU7-23P', id: 'RNU7-23P'},
{value: 'RNU7-24P', id: 'RNU7-24P'},
{value: 'RNU7-25P', id: 'RNU7-25P'},
{value: 'RNU7-26P', id: 'RNU7-26P'},
{value: 'RNU7-27P', id: 'RNU7-27P'},
{value: 'RNU7-29P', id: 'RNU7-29P'},
{value: 'RNU7-2P', id: 'RNU7-2P'},
{value: 'RNU7-30P', id: 'RNU7-30P'},
{value: 'RNU7-34P', id: 'RNU7-34P'},
{value: 'RNU7-41P', id: 'RNU7-41P'},
{value: 'RNU7-47P', id: 'RNU7-47P'},
{value: 'RNU7-51P', id: 'RNU7-51P'},
{value: 'RNU7-53P', id: 'RNU7-53P'},
{value: 'RNU7-54P', id: 'RNU7-54P'},
{value: 'RNU7-55P', id: 'RNU7-55P'},
{value: 'RNU7-57P', id: 'RNU7-57P'},
{value: 'RNU7-60P', id: 'RNU7-60P'},
{value: 'RNU7-62P', id: 'RNU7-62P'},
{value: 'RNU7-65P', id: 'RNU7-65P'},
{value: 'RNU7-66P', id: 'RNU7-66P'},
{value: 'RNU7-67P', id: 'RNU7-67P'},
{value: 'RNU7-6P', id: 'RNU7-6P'},
{value: 'RNU7-73P', id: 'RNU7-73P'},
{value: 'RNU7-74P', id: 'RNU7-74P'},
{value: 'RNU7-82P', id: 'RNU7-82P'},
{value: 'RNU7-88P', id: 'RNU7-88P'},
{value: 'RNU7-92P', id: 'RNU7-92P'},
{value: 'RNU7-97P', id: 'RNU7-97P'},
{value: 'RNVU1-1', id: 'RNVU1-1'},
{value: 'RNVU1-14', id: 'RNVU1-14'},
{value: 'RNVU1-15', id: 'RNVU1-15'},
{value: 'RNVU1-17', id: 'RNVU1-17'},
{value: 'RNVU1-18', id: 'RNVU1-18'},
{value: 'RNVU1-19', id: 'RNVU1-19'},
{value: 'RNVU1-22', id: 'RNVU1-22'},
{value: 'RNVU1-23', id: 'RNVU1-23'},
{value: 'RNVU1-24', id: 'RNVU1-24'},
{value: 'RNVU1-25', id: 'RNVU1-25'},
{value: 'RNVU1-26', id: 'RNVU1-26'},
{value: 'RNVU1-27', id: 'RNVU1-27'},
{value: 'RNVU1-28', id: 'RNVU1-28'},
{value: 'RNVU1-29', id: 'RNVU1-29'},
{value: 'RNVU1-2A', id: 'RNVU1-2A'},
{value: 'RNVU1-30', id: 'RNVU1-30'},
{value: 'RNVU1-31', id: 'RNVU1-31'},
{value: 'RNVU1-34', id: 'RNVU1-34'},
{value: 'RNVU1-4', id: 'RNVU1-4'},
{value: 'RNVU1-6', id: 'RNVU1-6'},
{value: 'RNVU1-7', id: 'RNVU1-7'},
{value: 'RNVU1-8', id: 'RNVU1-8'},
{value: 'RNY1', id: 'RNY1'},
{value: 'RNY1P1', id: 'RNY1P1'},
{value: 'RNY1P11', id: 'RNY1P11'},
{value: 'RNY1P14', id: 'RNY1P14'},
{value: 'RNY1P3', id: 'RNY1P3'},
{value: 'RNY1P7', id: 'RNY1P7'},
{value: 'RNY1P8', id: 'RNY1P8'},
{value: 'RNY3', id: 'RNY3'},
{value: 'RNY3P10', id: 'RNY3P10'},
{value: 'RNY3P12', id: 'RNY3P12'},
{value: 'RNY3P2', id: 'RNY3P2'},
{value: 'RNY3P4', id: 'RNY3P4'},
{value: 'RNY3P8', id: 'RNY3P8'},
{value: 'RNY4', id: 'RNY4'},
{value: 'RNY4P16', id: 'RNY4P16'},
{value: 'RNY4P18', id: 'RNY4P18'},
{value: 'RNY4P19', id: 'RNY4P19'},
{value: 'RNY4P20', id: 'RNY4P20'},
{value: 'RNY4P24', id: 'RNY4P24'},
{value: 'RNY4P27', id: 'RNY4P27'},
{value: 'RNY4P28', id: 'RNY4P28'},
{value: 'RNY4P29', id: 'RNY4P29'},
{value: 'RNY4P30', id: 'RNY4P30'},
{value: 'RNY4P37', id: 'RNY4P37'},
{value: 'RNY4P9', id: 'RNY4P9'},
{value: 'RO60', id: 'RO60'},
{value: 'ROBO1', id: 'ROBO1'},
{value: 'ROBO2', id: 'ROBO2'},
{value: 'ROBO2P1', id: 'ROBO2P1'},
{value: 'ROBO3', id: 'ROBO3'},
{value: 'ROBO4', id: 'ROBO4'},
{value: 'ROCK1', id: 'ROCK1'},
{value: 'ROCK1P1', id: 'ROCK1P1'},
{value: 'ROCK2', id: 'ROCK2'},
{value: 'ROCR', id: 'ROCR'},
{value: 'ROGDI', id: 'ROGDI'},
{value: 'ROM1', id: 'ROM1'},
{value: 'ROMO1', id: 'ROMO1'},
{value: 'ROPN1', id: 'ROPN1'},
{value: 'ROPN1B', id: 'ROPN1B'},
{value: 'ROPN1L', id: 'ROPN1L'},
{value: 'ROPN1L-AS1', id: 'ROPN1L-AS1'},
{value: 'ROR1', id: 'ROR1'},
{value: 'ROR2', id: 'ROR2'},
{value: 'RORA', id: 'RORA'},
{value: 'RORA-AS1', id: 'RORA-AS1'},
{value: 'RORA-AS2', id: 'RORA-AS2'},
{value: 'RORB', id: 'RORB'},
{value: 'RORB-AS1', id: 'RORB-AS1'},
{value: 'RORC', id: 'RORC'},
{value: 'ROS1', id: 'ROS1'},
{value: 'RP1', id: 'RP1'},
{value: 'RP1L1', id: 'RP1L1'},
{value: 'RP2', id: 'RP2'},
{value: 'RP9', id: 'RP9'},
{value: 'RP9P', id: 'RP9P'},
{value: 'RPA1', id: 'RPA1'},
{value: 'RPA2', id: 'RPA2'},
{value: 'RPA2P1', id: 'RPA2P1'},
{value: 'RPA2P2', id: 'RPA2P2'},
{value: 'RPA2P3', id: 'RPA2P3'},
{value: 'RPA3', id: 'RPA3'},
{value: 'RPA3P1', id: 'RPA3P1'},
{value: 'RPA3P2', id: 'RPA3P2'},
{value: 'RPAIN', id: 'RPAIN'},
{value: 'RPAP1', id: 'RPAP1'},
{value: 'RPAP2', id: 'RPAP2'},
{value: 'RPAP2P1', id: 'RPAP2P1'},
{value: 'RPAP3', id: 'RPAP3'},
{value: 'RPAP3-DT', id: 'RPAP3-DT'},
{value: 'RPE', id: 'RPE'},
{value: 'RPE65', id: 'RPE65'},
{value: 'RPEP2', id: 'RPEP2'},
{value: 'RPEP4', id: 'RPEP4'},
{value: 'RPEP5', id: 'RPEP5'},
{value: 'RPEP6', id: 'RPEP6'},
{value: 'RPF1', id: 'RPF1'},
{value: 'RPF2', id: 'RPF2'},
{value: 'RPF2P1', id: 'RPF2P1'},
{value: 'RPF2P2', id: 'RPF2P2'},
{value: 'RPGR', id: 'RPGR'},
{value: 'RPGRIP1', id: 'RPGRIP1'},
{value: 'RPGRIP1L', id: 'RPGRIP1L'},
{value: 'RPH3A', id: 'RPH3A'},
{value: 'RPH3AL', id: 'RPH3AL'},
{value: 'RPH3AL-AS1', id: 'RPH3AL-AS1'},
{value: 'RPIA', id: 'RPIA'},
{value: 'RPIAP1', id: 'RPIAP1'},
{value: 'RPL10', id: 'RPL10'},
{value: 'RPL10A', id: 'RPL10A'},
{value: 'RPL10AP3', id: 'RPL10AP3'},
{value: 'RPL10AP5', id: 'RPL10AP5'},
{value: 'RPL10AP6', id: 'RPL10AP6'},
{value: 'RPL10L', id: 'RPL10L'},
{value: 'RPL10P10', id: 'RPL10P10'},
{value: 'RPL10P11', id: 'RPL10P11'},
{value: 'RPL10P14', id: 'RPL10P14'},
{value: 'RPL10P16', id: 'RPL10P16'},
{value: 'RPL10P17', id: 'RPL10P17'},
{value: 'RPL10P18', id: 'RPL10P18'},
{value: 'RPL10P19', id: 'RPL10P19'},
{value: 'RPL10P2', id: 'RPL10P2'},
{value: 'RPL10P3', id: 'RPL10P3'},
{value: 'RPL10P4', id: 'RPL10P4'},
{value: 'RPL10P5', id: 'RPL10P5'},
{value: 'RPL10P7', id: 'RPL10P7'},
{value: 'RPL10P8', id: 'RPL10P8'},
{value: 'RPL10P9', id: 'RPL10P9'},
{value: 'RPL11', id: 'RPL11'},
{value: 'RPL11P3', id: 'RPL11P3'},
{value: 'RPL11P5', id: 'RPL11P5'},
{value: 'RPL12', id: 'RPL12'},
{value: 'RPL12P10', id: 'RPL12P10'},
{value: 'RPL12P12', id: 'RPL12P12'},
{value: 'RPL12P13', id: 'RPL12P13'},
{value: 'RPL12P14', id: 'RPL12P14'},
{value: 'RPL12P16', id: 'RPL12P16'},
{value: 'RPL12P17', id: 'RPL12P17'},
{value: 'RPL12P2', id: 'RPL12P2'},
{value: 'RPL12P20', id: 'RPL12P20'},
{value: 'RPL12P22', id: 'RPL12P22'},
{value: 'RPL12P25', id: 'RPL12P25'},
{value: 'RPL12P26', id: 'RPL12P26'},
{value: 'RPL12P27', id: 'RPL12P27'},
{value: 'RPL12P28', id: 'RPL12P28'},
{value: 'RPL12P29', id: 'RPL12P29'},
{value: 'RPL12P3', id: 'RPL12P3'},
{value: 'RPL12P30', id: 'RPL12P30'},
{value: 'RPL12P31', id: 'RPL12P31'},
{value: 'RPL12P33', id: 'RPL12P33'},
{value: 'RPL12P34', id: 'RPL12P34'},
{value: 'RPL12P35', id: 'RPL12P35'},
{value: 'RPL12P36', id: 'RPL12P36'},
{value: 'RPL12P37', id: 'RPL12P37'},
{value: 'RPL12P38', id: 'RPL12P38'},
{value: 'RPL12P4', id: 'RPL12P4'},
{value: 'RPL12P40', id: 'RPL12P40'},
{value: 'RPL12P41', id: 'RPL12P41'},
{value: 'RPL12P42', id: 'RPL12P42'},
{value: 'RPL12P44', id: 'RPL12P44'},
{value: 'RPL12P45', id: 'RPL12P45'},
{value: 'RPL12P46', id: 'RPL12P46'},
{value: 'RPL12P48', id: 'RPL12P48'},
{value: 'RPL12P5', id: 'RPL12P5'},
{value: 'RPL12P6', id: 'RPL12P6'},
{value: 'RPL12P7', id: 'RPL12P7'},
{value: 'RPL12P8', id: 'RPL12P8'},
{value: 'RPL13', id: 'RPL13'},
{value: 'RPL13A', id: 'RPL13A'},
{value: 'RPL13AP', id: 'RPL13AP'},
{value: 'RPL13AP12', id: 'RPL13AP12'},
{value: 'RPL13AP17', id: 'RPL13AP17'},
{value: 'RPL13AP2', id: 'RPL13AP2'},
{value: 'RPL13AP20', id: 'RPL13AP20'},
{value: 'RPL13AP23', id: 'RPL13AP23'},
{value: 'RPL13AP25', id: 'RPL13AP25'},
{value: 'RPL13AP3', id: 'RPL13AP3'},
{value: 'RPL13AP5', id: 'RPL13AP5'},
{value: 'RPL13AP6', id: 'RPL13AP6'},
{value: 'RPL13AP7', id: 'RPL13AP7'},
{value: 'RPL13P', id: 'RPL13P'},
{value: 'RPL13P12', id: 'RPL13P12'},
{value: 'RPL13P2', id: 'RPL13P2'},
{value: 'RPL13P4', id: 'RPL13P4'},
{value: 'RPL13P5', id: 'RPL13P5'},
{value: 'RPL14', id: 'RPL14'},
{value: 'RPL14P1', id: 'RPL14P1'},
{value: 'RPL14P6', id: 'RPL14P6'},
{value: 'RPL15', id: 'RPL15'},
{value: 'RPL15P1', id: 'RPL15P1'},
{value: 'RPL15P13', id: 'RPL15P13'},
{value: 'RPL15P2', id: 'RPL15P2'},
{value: 'RPL15P20', id: 'RPL15P20'},
{value: 'RPL15P21', id: 'RPL15P21'},
{value: 'RPL15P3', id: 'RPL15P3'},
{value: 'RPL15P4', id: 'RPL15P4'},
{value: 'RPL15P5', id: 'RPL15P5'},
{value: 'RPL17', id: 'RPL17'},
{value: 'RPL17-C18orf32', id: 'RPL17-C18orf32'},
{value: 'RPL17P10', id: 'RPL17P10'},
{value: 'RPL17P12', id: 'RPL17P12'},
{value: 'RPL17P13', id: 'RPL17P13'},
{value: 'RPL17P15', id: 'RPL17P15'},
{value: 'RPL17P2', id: 'RPL17P2'},
{value: 'RPL17P20', id: 'RPL17P20'},
{value: 'RPL17P25', id: 'RPL17P25'},
{value: 'RPL17P33', id: 'RPL17P33'},
{value: 'RPL17P34', id: 'RPL17P34'},
{value: 'RPL17P36', id: 'RPL17P36'},
{value: 'RPL17P37', id: 'RPL17P37'},
{value: 'RPL17P40', id: 'RPL17P40'},
{value: 'RPL17P41', id: 'RPL17P41'},
{value: 'RPL17P44', id: 'RPL17P44'},
{value: 'RPL17P45', id: 'RPL17P45'},
{value: 'RPL17P50', id: 'RPL17P50'},
{value: 'RPL18', id: 'RPL18'},
{value: 'RPL18A', id: 'RPL18A'},
{value: 'RPL18AP17', id: 'RPL18AP17'},
{value: 'RPL18AP3', id: 'RPL18AP3'},
{value: 'RPL18AP7', id: 'RPL18AP7'},
{value: 'RPL18AP8', id: 'RPL18AP8'},
{value: 'RPL18P1', id: 'RPL18P1'},
{value: 'RPL18P10', id: 'RPL18P10'},
{value: 'RPL18P13', id: 'RPL18P13'},
{value: 'RPL19', id: 'RPL19'},
{value: 'RPL19P1', id: 'RPL19P1'},
{value: 'RPL19P11', id: 'RPL19P11'},
{value: 'RPL19P13', id: 'RPL19P13'},
{value: 'RPL19P16', id: 'RPL19P16'},
{value: 'RPL19P4', id: 'RPL19P4'},
{value: 'RPL19P5', id: 'RPL19P5'},
{value: 'RPL21', id: 'RPL21'},
{value: 'RPL21P10', id: 'RPL21P10'},
{value: 'RPL21P103', id: 'RPL21P103'},
{value: 'RPL21P107', id: 'RPL21P107'},
{value: 'RPL21P11', id: 'RPL21P11'},
{value: 'RPL21P110', id: 'RPL21P110'},
{value: 'RPL21P111', id: 'RPL21P111'},
{value: 'RPL21P112', id: 'RPL21P112'},
{value: 'RPL21P119', id: 'RPL21P119'},
{value: 'RPL21P12', id: 'RPL21P12'},
{value: 'RPL21P120', id: 'RPL21P120'},
{value: 'RPL21P123', id: 'RPL21P123'},
{value: 'RPL21P127', id: 'RPL21P127'},
{value: 'RPL21P129', id: 'RPL21P129'},
{value: 'RPL21P13', id: 'RPL21P13'},
{value: 'RPL21P131', id: 'RPL21P131'},
{value: 'RPL21P134', id: 'RPL21P134'},
{value: 'RPL21P16', id: 'RPL21P16'},
{value: 'RPL21P17', id: 'RPL21P17'},
{value: 'RPL21P18', id: 'RPL21P18'},
{value: 'RPL21P2', id: 'RPL21P2'},
{value: 'RPL21P24', id: 'RPL21P24'},
{value: 'RPL21P28', id: 'RPL21P28'},
{value: 'RPL21P30', id: 'RPL21P30'},
{value: 'RPL21P31', id: 'RPL21P31'},
{value: 'RPL21P32', id: 'RPL21P32'},
{value: 'RPL21P34', id: 'RPL21P34'},
{value: 'RPL21P35', id: 'RPL21P35'},
{value: 'RPL21P36', id: 'RPL21P36'},
{value: 'RPL21P37', id: 'RPL21P37'},
{value: 'RPL21P38', id: 'RPL21P38'},
{value: 'RPL21P39', id: 'RPL21P39'},
{value: 'RPL21P41', id: 'RPL21P41'},
{value: 'RPL21P42', id: 'RPL21P42'},
{value: 'RPL21P43', id: 'RPL21P43'},
{value: 'RPL21P44', id: 'RPL21P44'},
{value: 'RPL21P47', id: 'RPL21P47'},
{value: 'RPL21P66', id: 'RPL21P66'},
{value: 'RPL21P67', id: 'RPL21P67'},
{value: 'RPL21P75', id: 'RPL21P75'},
{value: 'RPL21P89', id: 'RPL21P89'},
{value: 'RPL21P91', id: 'RPL21P91'},
{value: 'RPL21P92', id: 'RPL21P92'},
{value: 'RPL21P93', id: 'RPL21P93'},
{value: 'RPL22', id: 'RPL22'},
{value: 'RPL22L1', id: 'RPL22L1'},
{value: 'RPL22P1', id: 'RPL22P1'},
{value: 'RPL22P10', id: 'RPL22P10'},
{value: 'RPL22P11', id: 'RPL22P11'},
{value: 'RPL22P12', id: 'RPL22P12'},
{value: 'RPL22P14', id: 'RPL22P14'},
{value: 'RPL22P18', id: 'RPL22P18'},
{value: 'RPL22P19', id: 'RPL22P19'},
{value: 'RPL22P2', id: 'RPL22P2'},
{value: 'RPL22P20', id: 'RPL22P20'},
{value: 'RPL22P4', id: 'RPL22P4'},
{value: 'RPL22P5', id: 'RPL22P5'},
{value: 'RPL22P6', id: 'RPL22P6'},
{value: 'RPL22P7', id: 'RPL22P7'},
{value: 'RPL22P8', id: 'RPL22P8'},
{value: 'RPL23', id: 'RPL23'},
{value: 'RPL23A', id: 'RPL23A'},
{value: 'RPL23AP1', id: 'RPL23AP1'},
{value: 'RPL23AP12', id: 'RPL23AP12'},
{value: 'RPL23AP18', id: 'RPL23AP18'},
{value: 'RPL23AP2', id: 'RPL23AP2'},
{value: 'RPL23AP20', id: 'RPL23AP20'},
{value: 'RPL23AP23', id: 'RPL23AP23'},
{value: 'RPL23AP24', id: 'RPL23AP24'},
{value: 'RPL23AP25', id: 'RPL23AP25'},
{value: 'RPL23AP26', id: 'RPL23AP26'},
{value: 'RPL23AP27', id: 'RPL23AP27'},
{value: 'RPL23AP28', id: 'RPL23AP28'},
{value: 'RPL23AP29', id: 'RPL23AP29'},
{value: 'RPL23AP3', id: 'RPL23AP3'},
{value: 'RPL23AP30', id: 'RPL23AP30'},
{value: 'RPL23AP31', id: 'RPL23AP31'},
{value: 'RPL23AP32', id: 'RPL23AP32'},
{value: 'RPL23AP33', id: 'RPL23AP33'},
{value: 'RPL23AP35', id: 'RPL23AP35'},
{value: 'RPL23AP37', id: 'RPL23AP37'},
{value: 'RPL23AP38', id: 'RPL23AP38'},
{value: 'RPL23AP39', id: 'RPL23AP39'},
{value: 'RPL23AP4', id: 'RPL23AP4'},
{value: 'RPL23AP41', id: 'RPL23AP41'},
{value: 'RPL23AP42', id: 'RPL23AP42'},
{value: 'RPL23AP43', id: 'RPL23AP43'},
{value: 'RPL23AP44', id: 'RPL23AP44'},
{value: 'RPL23AP46', id: 'RPL23AP46'},
{value: 'RPL23AP47', id: 'RPL23AP47'},
{value: 'RPL23AP48', id: 'RPL23AP48'},
{value: 'RPL23AP49', id: 'RPL23AP49'},
{value: 'RPL23AP53', id: 'RPL23AP53'},
{value: 'RPL23AP54', id: 'RPL23AP54'},
{value: 'RPL23AP56', id: 'RPL23AP56'},
{value: 'RPL23AP59', id: 'RPL23AP59'},
{value: 'RPL23AP60', id: 'RPL23AP60'},
{value: 'RPL23AP61', id: 'RPL23AP61'},
{value: 'RPL23AP64', id: 'RPL23AP64'},
{value: 'RPL23AP65', id: 'RPL23AP65'},
{value: 'RPL23AP66', id: 'RPL23AP66'},
{value: 'RPL23AP68', id: 'RPL23AP68'},
{value: 'RPL23AP7', id: 'RPL23AP7'},
{value: 'RPL23AP71', id: 'RPL23AP71'},
{value: 'RPL23AP73', id: 'RPL23AP73'},
{value: 'RPL23AP74', id: 'RPL23AP74'},
{value: 'RPL23AP75', id: 'RPL23AP75'},
{value: 'RPL23AP76', id: 'RPL23AP76'},
{value: 'RPL23AP78', id: 'RPL23AP78'},
{value: 'RPL23AP8', id: 'RPL23AP8'},
{value: 'RPL23AP80', id: 'RPL23AP80'},
{value: 'RPL23AP82', id: 'RPL23AP82'},
{value: 'RPL23AP87', id: 'RPL23AP87'},
{value: 'RPL23AP88', id: 'RPL23AP88'},
{value: 'RPL23AP91', id: 'RPL23AP91'},
{value: 'RPL23AP92', id: 'RPL23AP92'},
{value: 'RPL23AP93', id: 'RPL23AP93'},
{value: 'RPL23AP94', id: 'RPL23AP94'},
{value: 'RPL23AP95', id: 'RPL23AP95'},
{value: 'RPL23AP96', id: 'RPL23AP96'},
{value: 'RPL23AP97', id: 'RPL23AP97'},
{value: 'RPL23P11', id: 'RPL23P11'},
{value: 'RPL23P2', id: 'RPL23P2'},
{value: 'RPL23P4', id: 'RPL23P4'},
{value: 'RPL23P5', id: 'RPL23P5'},
{value: 'RPL23P8', id: 'RPL23P8'},
{value: 'RPL24', id: 'RPL24'},
{value: 'RPL24P2', id: 'RPL24P2'},
{value: 'RPL24P4', id: 'RPL24P4'},
{value: 'RPL24P7', id: 'RPL24P7'},
{value: 'RPL24P8', id: 'RPL24P8'},
{value: 'RPL26', id: 'RPL26'},
{value: 'RPL26L1', id: 'RPL26L1'},
{value: 'RPL26P13', id: 'RPL26P13'},
{value: 'RPL26P15', id: 'RPL26P15'},
{value: 'RPL26P19', id: 'RPL26P19'},
{value: 'RPL26P20', id: 'RPL26P20'},
{value: 'RPL26P26', id: 'RPL26P26'},
{value: 'RPL26P28', id: 'RPL26P28'},
{value: 'RPL26P29', id: 'RPL26P29'},
{value: 'RPL26P3', id: 'RPL26P3'},
{value: 'RPL26P31', id: 'RPL26P31'},
{value: 'RPL26P34', id: 'RPL26P34'},
{value: 'RPL26P36', id: 'RPL26P36'},
{value: 'RPL26P6', id: 'RPL26P6'},
{value: 'RPL27', id: 'RPL27'},
{value: 'RPL27A', id: 'RPL27A'},
{value: 'RPL27AP', id: 'RPL27AP'},
{value: 'RPL27AP3', id: 'RPL27AP3'},
{value: 'RPL27AP4', id: 'RPL27AP4'},
{value: 'RPL27AP5', id: 'RPL27AP5'},
{value: 'RPL27AP8', id: 'RPL27AP8'},
{value: 'RPL27P7', id: 'RPL27P7'},
{value: 'RPL28', id: 'RPL28'},
{value: 'RPL28P2', id: 'RPL28P2'},
{value: 'RPL29', id: 'RPL29'},
{value: 'RPL29P11', id: 'RPL29P11'},
{value: 'RPL29P12', id: 'RPL29P12'},
{value: 'RPL29P19', id: 'RPL29P19'},
{value: 'RPL29P23', id: 'RPL29P23'},
{value: 'RPL29P24', id: 'RPL29P24'},
{value: 'RPL29P28', id: 'RPL29P28'},
{value: 'RPL29P29', id: 'RPL29P29'},
{value: 'RPL29P30', id: 'RPL29P30'},
{value: 'RPL29P32', id: 'RPL29P32'},
{value: 'RPL3', id: 'RPL3'},
{value: 'RPL30', id: 'RPL30'},
{value: 'RPL30P1', id: 'RPL30P1'},
{value: 'RPL30P10', id: 'RPL30P10'},
{value: 'RPL30P11', id: 'RPL30P11'},
{value: 'RPL30P12', id: 'RPL30P12'},
{value: 'RPL30P2', id: 'RPL30P2'},
{value: 'RPL30P3', id: 'RPL30P3'},
{value: 'RPL30P4', id: 'RPL30P4'},
{value: 'RPL30P6', id: 'RPL30P6'},
{value: 'RPL30P7', id: 'RPL30P7'},
{value: 'RPL30P9', id: 'RPL30P9'},
{value: 'RPL31', id: 'RPL31'},
{value: 'RPL31P1', id: 'RPL31P1'},
{value: 'RPL31P11', id: 'RPL31P11'},
{value: 'RPL31P13', id: 'RPL31P13'},
{value: 'RPL31P15', id: 'RPL31P15'},
{value: 'RPL31P17', id: 'RPL31P17'},
{value: 'RPL31P28', id: 'RPL31P28'},
{value: 'RPL31P3', id: 'RPL31P3'},
{value: 'RPL31P30', id: 'RPL31P30'},
{value: 'RPL31P33', id: 'RPL31P33'},
{value: 'RPL31P35', id: 'RPL31P35'},
{value: 'RPL31P40', id: 'RPL31P40'},
{value: 'RPL31P41', id: 'RPL31P41'},
{value: 'RPL31P44', id: 'RPL31P44'},
{value: 'RPL31P45', id: 'RPL31P45'},
{value: 'RPL31P47', id: 'RPL31P47'},
{value: 'RPL31P53', id: 'RPL31P53'},
{value: 'RPL31P56', id: 'RPL31P56'},
{value: 'RPL31P57', id: 'RPL31P57'},
{value: 'RPL31P59', id: 'RPL31P59'},
{value: 'RPL31P60', id: 'RPL31P60'},
{value: 'RPL31P63', id: 'RPL31P63'},
{value: 'RPL31P9', id: 'RPL31P9'},
{value: 'RPL32', id: 'RPL32'},
{value: 'RPL32P12', id: 'RPL32P12'},
{value: 'RPL32P13', id: 'RPL32P13'},
{value: 'RPL32P15', id: 'RPL32P15'},
{value: 'RPL32P16', id: 'RPL32P16'},
{value: 'RPL32P17', id: 'RPL32P17'},
{value: 'RPL32P18', id: 'RPL32P18'},
{value: 'RPL32P19', id: 'RPL32P19'},
{value: 'RPL32P20', id: 'RPL32P20'},
{value: 'RPL32P21', id: 'RPL32P21'},
{value: 'RPL32P22', id: 'RPL32P22'},
{value: 'RPL32P23', id: 'RPL32P23'},
{value: 'RPL32P24', id: 'RPL32P24'},
{value: 'RPL32P25', id: 'RPL32P25'},
{value: 'RPL32P26', id: 'RPL32P26'},
{value: 'RPL32P27', id: 'RPL32P27'},
{value: 'RPL32P28', id: 'RPL32P28'},
{value: 'RPL32P29', id: 'RPL32P29'},
{value: 'RPL32P3', id: 'RPL32P3'},
{value: 'RPL32P30', id: 'RPL32P30'},
{value: 'RPL32P31', id: 'RPL32P31'},
{value: 'RPL32P32', id: 'RPL32P32'},
{value: 'RPL32P6', id: 'RPL32P6'},
{value: 'RPL32P7', id: 'RPL32P7'},
{value: 'RPL32P8', id: 'RPL32P8'},
{value: 'RPL34', id: 'RPL34'},
{value: 'RPL34-DT', id: 'RPL34-DT'},
{value: 'RPL34P1', id: 'RPL34P1'},
{value: 'RPL34P17', id: 'RPL34P17'},
{value: 'RPL34P18', id: 'RPL34P18'},
{value: 'RPL34P19', id: 'RPL34P19'},
{value: 'RPL34P20', id: 'RPL34P20'},
{value: 'RPL34P22', id: 'RPL34P22'},
{value: 'RPL34P23', id: 'RPL34P23'},
{value: 'RPL34P27', id: 'RPL34P27'},
{value: 'RPL34P29', id: 'RPL34P29'},
{value: 'RPL34P31', id: 'RPL34P31'},
{value: 'RPL34P34', id: 'RPL34P34'},
{value: 'RPL34P6', id: 'RPL34P6'},
{value: 'RPL35', id: 'RPL35'},
{value: 'RPL35A', id: 'RPL35A'},
{value: 'RPL35AP', id: 'RPL35AP'},
{value: 'RPL35AP11', id: 'RPL35AP11'},
{value: 'RPL35AP12', id: 'RPL35AP12'},
{value: 'RPL35AP15', id: 'RPL35AP15'},
{value: 'RPL35AP18', id: 'RPL35AP18'},
{value: 'RPL35AP19', id: 'RPL35AP19'},
{value: 'RPL35AP2', id: 'RPL35AP2'},
{value: 'RPL35AP21', id: 'RPL35AP21'},
{value: 'RPL35AP22', id: 'RPL35AP22'},
{value: 'RPL35AP26', id: 'RPL35AP26'},
{value: 'RPL35AP3', id: 'RPL35AP3'},
{value: 'RPL35AP30', id: 'RPL35AP30'},
{value: 'RPL35AP32', id: 'RPL35AP32'},
{value: 'RPL35AP33', id: 'RPL35AP33'},
{value: 'RPL35AP34', id: 'RPL35AP34'},
{value: 'RPL35AP35', id: 'RPL35AP35'},
{value: 'RPL35AP36', id: 'RPL35AP36'},
{value: 'RPL35AP4', id: 'RPL35AP4'},
{value: 'RPL35AP7', id: 'RPL35AP7'},
{value: 'RPL35AP8', id: 'RPL35AP8'},
{value: 'RPL35P1', id: 'RPL35P1'},
{value: 'RPL35P2', id: 'RPL35P2'},
{value: 'RPL35P3', id: 'RPL35P3'},
{value: 'RPL35P4', id: 'RPL35P4'},
{value: 'RPL35P5', id: 'RPL35P5'},
{value: 'RPL35P6', id: 'RPL35P6'},
{value: 'RPL35P8', id: 'RPL35P8'},
{value: 'RPL35P9', id: 'RPL35P9'},
{value: 'RPL36', id: 'RPL36'},
{value: 'RPL36A', id: 'RPL36A'},
{value: 'RPL36A-HNRNPH2', id: 'RPL36A-HNRNPH2'},
{value: 'RPL36AL', id: 'RPL36AL'},
{value: 'RPL36AP14', id: 'RPL36AP14'},
{value: 'RPL36AP21', id: 'RPL36AP21'},
{value: 'RPL36AP26', id: 'RPL36AP26'},
{value: 'RPL36AP29', id: 'RPL36AP29'},
{value: 'RPL36AP35', id: 'RPL36AP35'},
{value: 'RPL36AP39', id: 'RPL36AP39'},
{value: 'RPL36AP40', id: 'RPL36AP40'},
{value: 'RPL36AP45', id: 'RPL36AP45'},
{value: 'RPL36AP6', id: 'RPL36AP6'},
{value: 'RPL36P1', id: 'RPL36P1'},
{value: 'RPL36P16', id: 'RPL36P16'},
{value: 'RPL36P19', id: 'RPL36P19'},
{value: 'RPL36P20', id: 'RPL36P20'},
{value: 'RPL36P4', id: 'RPL36P4'},
{value: 'RPL37', id: 'RPL37'},
{value: 'RPL37A', id: 'RPL37A'},
{value: 'RPL37A-DT', id: 'RPL37A-DT'},
{value: 'RPL37AP1', id: 'RPL37AP1'},
{value: 'RPL37AP8', id: 'RPL37AP8'},
{value: 'RPL37P10', id: 'RPL37P10'},
{value: 'RPL37P11', id: 'RPL37P11'},
{value: 'RPL37P12', id: 'RPL37P12'},
{value: 'RPL37P15', id: 'RPL37P15'},
{value: 'RPL37P18', id: 'RPL37P18'},
{value: 'RPL37P2', id: 'RPL37P2'},
{value: 'RPL37P21', id: 'RPL37P21'},
{value: 'RPL37P23', id: 'RPL37P23'},
{value: 'RPL37P25', id: 'RPL37P25'},
{value: 'RPL37P3', id: 'RPL37P3'},
{value: 'RPL37P4', id: 'RPL37P4'},
{value: 'RPL37P6', id: 'RPL37P6'},
{value: 'RPL38', id: 'RPL38'},
{value: 'RPL38P1', id: 'RPL38P1'},
{value: 'RPL38P3', id: 'RPL38P3'},
{value: 'RPL38P6', id: 'RPL38P6'},
{value: 'RPL39', id: 'RPL39'},
{value: 'RPL39L', id: 'RPL39L'},
{value: 'RPL39P', id: 'RPL39P'},
{value: 'RPL39P16', id: 'RPL39P16'},
{value: 'RPL39P18', id: 'RPL39P18'},
{value: 'RPL39P3', id: 'RPL39P3'},
{value: 'RPL39P33', id: 'RPL39P33'},
{value: 'RPL39P38', id: 'RPL39P38'},
{value: 'RPL39P39', id: 'RPL39P39'},
{value: 'RPL39P40', id: 'RPL39P40'},
{value: 'RPL39P5', id: 'RPL39P5'},
{value: 'RPL3L', id: 'RPL3L'},
{value: 'RPL3P11', id: 'RPL3P11'},
{value: 'RPL3P13', id: 'RPL3P13'},
{value: 'RPL3P2', id: 'RPL3P2'},
{value: 'RPL3P3', id: 'RPL3P3'},
{value: 'RPL3P4', id: 'RPL3P4'},
{value: 'RPL3P5', id: 'RPL3P5'},
{value: 'RPL3P6', id: 'RPL3P6'},
{value: 'RPL3P7', id: 'RPL3P7'},
{value: 'RPL3P8', id: 'RPL3P8'},
{value: 'RPL3P9', id: 'RPL3P9'},
{value: 'RPL4', id: 'RPL4'},
{value: 'RPL41', id: 'RPL41'},
{value: 'RPL41P1', id: 'RPL41P1'},
{value: 'RPL41P2', id: 'RPL41P2'},
{value: 'RPL4P1', id: 'RPL4P1'},
{value: 'RPL4P2', id: 'RPL4P2'},
{value: 'RPL4P3', id: 'RPL4P3'},
{value: 'RPL4P4', id: 'RPL4P4'},
{value: 'RPL4P5', id: 'RPL4P5'},
{value: 'RPL4P6', id: 'RPL4P6'},
{value: 'RPL4P7', id: 'RPL4P7'},
{value: 'RPL5', id: 'RPL5'},
{value: 'RPL5P1', id: 'RPL5P1'},
{value: 'RPL5P10', id: 'RPL5P10'},
{value: 'RPL5P11', id: 'RPL5P11'},
{value: 'RPL5P12', id: 'RPL5P12'},
{value: 'RPL5P13', id: 'RPL5P13'},
{value: 'RPL5P14', id: 'RPL5P14'},
{value: 'RPL5P16', id: 'RPL5P16'},
{value: 'RPL5P17', id: 'RPL5P17'},
{value: 'RPL5P18', id: 'RPL5P18'},
{value: 'RPL5P19', id: 'RPL5P19'},
{value: 'RPL5P2', id: 'RPL5P2'},
{value: 'RPL5P20', id: 'RPL5P20'},
{value: 'RPL5P21', id: 'RPL5P21'},
{value: 'RPL5P23', id: 'RPL5P23'},
{value: 'RPL5P26', id: 'RPL5P26'},
{value: 'RPL5P28', id: 'RPL5P28'},
{value: 'RPL5P29', id: 'RPL5P29'},
{value: 'RPL5P3', id: 'RPL5P3'},
{value: 'RPL5P30', id: 'RPL5P30'},
{value: 'RPL5P31', id: 'RPL5P31'},
{value: 'RPL5P33', id: 'RPL5P33'},
{value: 'RPL5P34', id: 'RPL5P34'},
{value: 'RPL5P35', id: 'RPL5P35'},
{value: 'RPL5P4', id: 'RPL5P4'},
{value: 'RPL5P6', id: 'RPL5P6'},
{value: 'RPL5P7', id: 'RPL5P7'},
{value: 'RPL5P8', id: 'RPL5P8'},
{value: 'RPL6', id: 'RPL6'},
{value: 'RPL6P12', id: 'RPL6P12'},
{value: 'RPL6P21', id: 'RPL6P21'},
{value: 'RPL6P25', id: 'RPL6P25'},
{value: 'RPL6P27', id: 'RPL6P27'},
{value: 'RPL6P4', id: 'RPL6P4'},
{value: 'RPL6P5', id: 'RPL6P5'},
{value: 'RPL6P8', id: 'RPL6P8'},
{value: 'RPL6P9', id: 'RPL6P9'},
{value: 'RPL7', id: 'RPL7'},
{value: 'RPL7A', id: 'RPL7A'},
{value: 'RPL7AP10', id: 'RPL7AP10'},
{value: 'RPL7AP11', id: 'RPL7AP11'},
{value: 'RPL7AP14', id: 'RPL7AP14'},
{value: 'RPL7AP23', id: 'RPL7AP23'},
{value: 'RPL7AP26', id: 'RPL7AP26'},
{value: 'RPL7AP30', id: 'RPL7AP30'},
{value: 'RPL7AP33', id: 'RPL7AP33'},
{value: 'RPL7AP52', id: 'RPL7AP52'},
{value: 'RPL7AP56', id: 'RPL7AP56'},
{value: 'RPL7AP57', id: 'RPL7AP57'},
{value: 'RPL7AP6', id: 'RPL7AP6'},
{value: 'RPL7AP60', id: 'RPL7AP60'},
{value: 'RPL7AP61', id: 'RPL7AP61'},
{value: 'RPL7AP64', id: 'RPL7AP64'},
{value: 'RPL7AP66', id: 'RPL7AP66'},
{value: 'RPL7AP69', id: 'RPL7AP69'},
{value: 'RPL7AP7', id: 'RPL7AP7'},
{value: 'RPL7AP73', id: 'RPL7AP73'},
{value: 'RPL7AP8', id: 'RPL7AP8'},
{value: 'RPL7L1', id: 'RPL7L1'},
{value: 'RPL7L1P1', id: 'RPL7L1P1'},
{value: 'RPL7L1P5', id: 'RPL7L1P5'},
{value: 'RPL7L1P7', id: 'RPL7L1P7'},
{value: 'RPL7L1P8', id: 'RPL7L1P8'},
{value: 'RPL7L1P9', id: 'RPL7L1P9'},
{value: 'RPL7P1', id: 'RPL7P1'},
{value: 'RPL7P10', id: 'RPL7P10'},
{value: 'RPL7P13', id: 'RPL7P13'},
{value: 'RPL7P17', id: 'RPL7P17'},
{value: 'RPL7P18', id: 'RPL7P18'},
{value: 'RPL7P19', id: 'RPL7P19'},
{value: 'RPL7P2', id: 'RPL7P2'},
{value: 'RPL7P20', id: 'RPL7P20'},
{value: 'RPL7P21', id: 'RPL7P21'},
{value: 'RPL7P23', id: 'RPL7P23'},
{value: 'RPL7P25', id: 'RPL7P25'},
{value: 'RPL7P27', id: 'RPL7P27'},
{value: 'RPL7P3', id: 'RPL7P3'},
{value: 'RPL7P30', id: 'RPL7P30'},
{value: 'RPL7P32', id: 'RPL7P32'},
{value: 'RPL7P33', id: 'RPL7P33'},
{value: 'RPL7P34', id: 'RPL7P34'},
{value: 'RPL7P35', id: 'RPL7P35'},
{value: 'RPL7P37', id: 'RPL7P37'},
{value: 'RPL7P38', id: 'RPL7P38'},
{value: 'RPL7P4', id: 'RPL7P4'},
{value: 'RPL7P41', id: 'RPL7P41'},
{value: 'RPL7P42', id: 'RPL7P42'},
{value: 'RPL7P43', id: 'RPL7P43'},
{value: 'RPL7P44', id: 'RPL7P44'},
{value: 'RPL7P45', id: 'RPL7P45'},
{value: 'RPL7P46', id: 'RPL7P46'},
{value: 'RPL7P47', id: 'RPL7P47'},
{value: 'RPL7P48', id: 'RPL7P48'},
{value: 'RPL7P5', id: 'RPL7P5'},
{value: 'RPL7P51', id: 'RPL7P51'},
{value: 'RPL7P52', id: 'RPL7P52'},
{value: 'RPL7P59', id: 'RPL7P59'},
{value: 'RPL7P6', id: 'RPL7P6'},
{value: 'RPL7P60', id: 'RPL7P60'},
{value: 'RPL7P61', id: 'RPL7P61'},
{value: 'RPL7P8', id: 'RPL7P8'},
{value: 'RPL7P9', id: 'RPL7P9'},
{value: 'RPL8', id: 'RPL8'},
{value: 'RPL8P1', id: 'RPL8P1'},
{value: 'RPL8P2', id: 'RPL8P2'},
{value: 'RPL9', id: 'RPL9'},
{value: 'RPL9P13', id: 'RPL9P13'},
{value: 'RPL9P14', id: 'RPL9P14'},
{value: 'RPL9P15', id: 'RPL9P15'},
{value: 'RPL9P16', id: 'RPL9P16'},
{value: 'RPL9P18', id: 'RPL9P18'},
{value: 'RPL9P2', id: 'RPL9P2'},
{value: 'RPL9P21', id: 'RPL9P21'},
{value: 'RPL9P25', id: 'RPL9P25'},
{value: 'RPL9P29', id: 'RPL9P29'},
{value: 'RPL9P31', id: 'RPL9P31'},
{value: 'RPL9P33', id: 'RPL9P33'},
{value: 'RPL9P5', id: 'RPL9P5'},
{value: 'RPLP0', id: 'RPLP0'},
{value: 'RPLP0P1', id: 'RPLP0P1'},
{value: 'RPLP0P11', id: 'RPLP0P11'},
{value: 'RPLP0P2', id: 'RPLP0P2'},
{value: 'RPLP0P6', id: 'RPLP0P6'},
{value: 'RPLP0P7', id: 'RPLP0P7'},
{value: 'RPLP1', id: 'RPLP1'},
{value: 'RPLP1P10', id: 'RPLP1P10'},
{value: 'RPLP1P11', id: 'RPLP1P11'},
{value: 'RPLP1P5', id: 'RPLP1P5'},
{value: 'RPLP1P6', id: 'RPLP1P6'},
{value: 'RPLP2', id: 'RPLP2'},
{value: 'RPLP2P1', id: 'RPLP2P1'},
{value: 'RPN1', id: 'RPN1'},
{value: 'RPN2', id: 'RPN2'},
{value: 'RPP14', id: 'RPP14'},
{value: 'RPP21', id: 'RPP21'},
{value: 'RPP25', id: 'RPP25'},
{value: 'RPP25L', id: 'RPP25L'},
{value: 'RPP30', id: 'RPP30'},
{value: 'RPP38', id: 'RPP38'},
{value: 'RPP38-DT', id: 'RPP38-DT'},
{value: 'RPP40', id: 'RPP40'},
{value: 'RPP40P2', id: 'RPP40P2'},
{value: 'RPPH1', id: 'RPPH1'},
{value: 'RPPH1-2P', id: 'RPPH1-2P'},
{value: 'RPPH1-3P', id: 'RPPH1-3P'},
{value: 'RPRD1A', id: 'RPRD1A'},
{value: 'RPRD1B', id: 'RPRD1B'},
{value: 'RPRD2', id: 'RPRD2'},
{value: 'RPRM', id: 'RPRM'},
{value: 'RPRML', id: 'RPRML'},
{value: 'RPS10', id: 'RPS10'},
{value: 'RPS10-NUDT3', id: 'RPS10-NUDT3'},
{value: 'RPS10P16', id: 'RPS10P16'},
{value: 'RPS10P20', id: 'RPS10P20'},
{value: 'RPS10P21', id: 'RPS10P21'},
{value: 'RPS10P27', id: 'RPS10P27'},
{value: 'RPS10P28', id: 'RPS10P28'},
{value: 'RPS10P3', id: 'RPS10P3'},
{value: 'RPS10P5', id: 'RPS10P5'},
{value: 'RPS10P7', id: 'RPS10P7'},
{value: 'RPS11', id: 'RPS11'},
{value: 'RPS11P1', id: 'RPS11P1'},
{value: 'RPS11P5', id: 'RPS11P5'},
{value: 'RPS12', id: 'RPS12'},
{value: 'RPS12P10', id: 'RPS12P10'},
{value: 'RPS12P15', id: 'RPS12P15'},
{value: 'RPS12P16', id: 'RPS12P16'},
{value: 'RPS12P18', id: 'RPS12P18'},
{value: 'RPS12P2', id: 'RPS12P2'},
{value: 'RPS12P20', id: 'RPS12P20'},
{value: 'RPS12P21', id: 'RPS12P21'},
{value: 'RPS12P23', id: 'RPS12P23'},
{value: 'RPS12P24', id: 'RPS12P24'},
{value: 'RPS12P26', id: 'RPS12P26'},
{value: 'RPS12P27', id: 'RPS12P27'},
{value: 'RPS12P28', id: 'RPS12P28'},
{value: 'RPS12P3', id: 'RPS12P3'},
{value: 'RPS12P31', id: 'RPS12P31'},
{value: 'RPS12P4', id: 'RPS12P4'},
{value: 'RPS13', id: 'RPS13'},
{value: 'RPS13P2', id: 'RPS13P2'},
{value: 'RPS13P4', id: 'RPS13P4'},
{value: 'RPS14', id: 'RPS14'},
{value: 'RPS14P7', id: 'RPS14P7'},
{value: 'RPS14P8', id: 'RPS14P8'},
{value: 'RPS15', id: 'RPS15'},
{value: 'RPS15A', id: 'RPS15A'},
{value: 'RPS15AP1', id: 'RPS15AP1'},
{value: 'RPS15AP10', id: 'RPS15AP10'},
{value: 'RPS15AP11', id: 'RPS15AP11'},
{value: 'RPS15AP14', id: 'RPS15AP14'},
{value: 'RPS15AP16', id: 'RPS15AP16'},
{value: 'RPS15AP24', id: 'RPS15AP24'},
{value: 'RPS15AP27', id: 'RPS15AP27'},
{value: 'RPS15AP29', id: 'RPS15AP29'},
{value: 'RPS15AP30', id: 'RPS15AP30'},
{value: 'RPS15AP32', id: 'RPS15AP32'},
{value: 'RPS15AP34', id: 'RPS15AP34'},
{value: 'RPS15AP36', id: 'RPS15AP36'},
{value: 'RPS15AP38', id: 'RPS15AP38'},
{value: 'RPS15AP5', id: 'RPS15AP5'},
{value: 'RPS15AP6', id: 'RPS15AP6'},
{value: 'RPS15AP7', id: 'RPS15AP7'},
{value: 'RPS15AP9', id: 'RPS15AP9'},
{value: 'RPS15P4', id: 'RPS15P4'},
{value: 'RPS15P5', id: 'RPS15P5'},
{value: 'RPS15P9', id: 'RPS15P9'},
{value: 'RPS16', id: 'RPS16'},
{value: 'RPS16P2', id: 'RPS16P2'},
{value: 'RPS16P3', id: 'RPS16P3'},
{value: 'RPS16P8', id: 'RPS16P8'},
{value: 'RPS16P9', id: 'RPS16P9'},
{value: 'RPS17', id: 'RPS17'},
{value: 'RPS17P11', id: 'RPS17P11'},
{value: 'RPS17P2', id: 'RPS17P2'},
{value: 'RPS17P5', id: 'RPS17P5'},
{value: 'RPS17P7', id: 'RPS17P7'},
{value: 'RPS18', id: 'RPS18'},
{value: 'RPS18P1', id: 'RPS18P1'},
{value: 'RPS18P12', id: 'RPS18P12'},
{value: 'RPS18P13', id: 'RPS18P13'},
{value: 'RPS18P6', id: 'RPS18P6'},
{value: 'RPS18P9', id: 'RPS18P9'},
{value: 'RPS19', id: 'RPS19'},
{value: 'RPS19BP1', id: 'RPS19BP1'},
{value: 'RPS19P1', id: 'RPS19P1'},
{value: 'RPS19P3', id: 'RPS19P3'},
{value: 'RPS19P6', id: 'RPS19P6'},
{value: 'RPS2', id: 'RPS2'},
{value: 'RPS20', id: 'RPS20'},
{value: 'RPS20P10', id: 'RPS20P10'},
{value: 'RPS20P12', id: 'RPS20P12'},
{value: 'RPS20P13', id: 'RPS20P13'},
{value: 'RPS20P14', id: 'RPS20P14'},
{value: 'RPS20P20', id: 'RPS20P20'},
{value: 'RPS20P21', id: 'RPS20P21'},
{value: 'RPS20P22', id: 'RPS20P22'},
{value: 'RPS20P23', id: 'RPS20P23'},
{value: 'RPS20P25', id: 'RPS20P25'},
{value: 'RPS20P31', id: 'RPS20P31'},
{value: 'RPS20P32', id: 'RPS20P32'},
{value: 'RPS20P4', id: 'RPS20P4'},
{value: 'RPS21', id: 'RPS21'},
{value: 'RPS21P1', id: 'RPS21P1'},
{value: 'RPS21P2', id: 'RPS21P2'},
{value: 'RPS21P4', id: 'RPS21P4'},
{value: 'RPS21P8', id: 'RPS21P8'},
{value: 'RPS23', id: 'RPS23'},
{value: 'RPS23P1', id: 'RPS23P1'},
{value: 'RPS23P2', id: 'RPS23P2'},
{value: 'RPS23P3', id: 'RPS23P3'},
{value: 'RPS23P4', id: 'RPS23P4'},
{value: 'RPS23P5', id: 'RPS23P5'},
{value: 'RPS23P7', id: 'RPS23P7'},
{value: 'RPS23P8', id: 'RPS23P8'},
{value: 'RPS23P9', id: 'RPS23P9'},
{value: 'RPS24', id: 'RPS24'},
{value: 'RPS24P13', id: 'RPS24P13'},
{value: 'RPS24P16', id: 'RPS24P16'},
{value: 'RPS24P17', id: 'RPS24P17'},
{value: 'RPS24P18', id: 'RPS24P18'},
{value: 'RPS24P19', id: 'RPS24P19'},
{value: 'RPS24P7', id: 'RPS24P7'},
{value: 'RPS24P8', id: 'RPS24P8'},
{value: 'RPS25', id: 'RPS25'},
{value: 'RPS25P9', id: 'RPS25P9'},
{value: 'RPS26', id: 'RPS26'},
{value: 'RPS26P13', id: 'RPS26P13'},
{value: 'RPS26P19', id: 'RPS26P19'},
{value: 'RPS26P2', id: 'RPS26P2'},
{value: 'RPS26P20', id: 'RPS26P20'},
{value: 'RPS26P24', id: 'RPS26P24'},
{value: 'RPS26P3', id: 'RPS26P3'},
{value: 'RPS26P31', id: 'RPS26P31'},
{value: 'RPS26P39', id: 'RPS26P39'},
{value: 'RPS26P40', id: 'RPS26P40'},
{value: 'RPS26P41', id: 'RPS26P41'},
{value: 'RPS26P43', id: 'RPS26P43'},
{value: 'RPS26P45', id: 'RPS26P45'},
{value: 'RPS26P47', id: 'RPS26P47'},
{value: 'RPS26P49', id: 'RPS26P49'},
{value: 'RPS26P54', id: 'RPS26P54'},
{value: 'RPS26P55', id: 'RPS26P55'},
{value: 'RPS26P6', id: 'RPS26P6'},
{value: 'RPS26P8', id: 'RPS26P8'},
{value: 'RPS27', id: 'RPS27'},
{value: 'RPS27A', id: 'RPS27A'},
{value: 'RPS27AP1', id: 'RPS27AP1'},
{value: 'RPS27AP11', id: 'RPS27AP11'},
{value: 'RPS27AP14', id: 'RPS27AP14'},
{value: 'RPS27AP16', id: 'RPS27AP16'},
{value: 'RPS27AP18', id: 'RPS27AP18'},
{value: 'RPS27AP19', id: 'RPS27AP19'},
{value: 'RPS27AP5', id: 'RPS27AP5'},
{value: 'RPS27AP6', id: 'RPS27AP6'},
{value: 'RPS27L', id: 'RPS27L'},
{value: 'RPS27P10', id: 'RPS27P10'},
{value: 'RPS27P14', id: 'RPS27P14'},
{value: 'RPS27P16', id: 'RPS27P16'},
{value: 'RPS27P18', id: 'RPS27P18'},
{value: 'RPS27P20', id: 'RPS27P20'},
{value: 'RPS27P22', id: 'RPS27P22'},
{value: 'RPS27P23', id: 'RPS27P23'},
{value: 'RPS27P25', id: 'RPS27P25'},
{value: 'RPS27P5', id: 'RPS27P5'},
{value: 'RPS28', id: 'RPS28'},
{value: 'RPS28P1', id: 'RPS28P1'},
{value: 'RPS28P4', id: 'RPS28P4'},
{value: 'RPS28P7', id: 'RPS28P7'},
{value: 'RPS28P8', id: 'RPS28P8'},
{value: 'RPS29', id: 'RPS29'},
{value: 'RPS29P1', id: 'RPS29P1'},
{value: 'RPS29P10', id: 'RPS29P10'},
{value: 'RPS29P11', id: 'RPS29P11'},
{value: 'RPS29P12', id: 'RPS29P12'},
{value: 'RPS29P13', id: 'RPS29P13'},
{value: 'RPS29P15', id: 'RPS29P15'},
{value: 'RPS29P17', id: 'RPS29P17'},
{value: 'RPS29P23', id: 'RPS29P23'},
{value: 'RPS29P25', id: 'RPS29P25'},
{value: 'RPS29P26', id: 'RPS29P26'},
{value: 'RPS29P27', id: 'RPS29P27'},
{value: 'RPS29P29', id: 'RPS29P29'},
{value: 'RPS29P3', id: 'RPS29P3'},
{value: 'RPS29P30', id: 'RPS29P30'},
{value: 'RPS29P31', id: 'RPS29P31'},
{value: 'RPS29P33', id: 'RPS29P33'},
{value: 'RPS29P4', id: 'RPS29P4'},
{value: 'RPS29P5', id: 'RPS29P5'},
{value: 'RPS29P6', id: 'RPS29P6'},
{value: 'RPS29P8', id: 'RPS29P8'},
{value: 'RPS2P1', id: 'RPS2P1'},
{value: 'RPS2P15', id: 'RPS2P15'},
{value: 'RPS2P16', id: 'RPS2P16'},
{value: 'RPS2P17', id: 'RPS2P17'},
{value: 'RPS2P18', id: 'RPS2P18'},
{value: 'RPS2P2', id: 'RPS2P2'},
{value: 'RPS2P24', id: 'RPS2P24'},
{value: 'RPS2P25', id: 'RPS2P25'},
{value: 'RPS2P28', id: 'RPS2P28'},
{value: 'RPS2P32', id: 'RPS2P32'},
{value: 'RPS2P36', id: 'RPS2P36'},
{value: 'RPS2P4', id: 'RPS2P4'},
{value: 'RPS2P44', id: 'RPS2P44'},
{value: 'RPS2P45', id: 'RPS2P45'},
{value: 'RPS2P46', id: 'RPS2P46'},
{value: 'RPS2P5', id: 'RPS2P5'},
{value: 'RPS2P52', id: 'RPS2P52'},
{value: 'RPS2P55', id: 'RPS2P55'},
{value: 'RPS2P6', id: 'RPS2P6'},
{value: 'RPS2P7', id: 'RPS2P7'},
{value: 'RPS3', id: 'RPS3'},
{value: 'RPS3A', id: 'RPS3A'},
{value: 'RPS3AP1', id: 'RPS3AP1'},
{value: 'RPS3AP10', id: 'RPS3AP10'},
{value: 'RPS3AP13', id: 'RPS3AP13'},
{value: 'RPS3AP16', id: 'RPS3AP16'},
{value: 'RPS3AP17', id: 'RPS3AP17'},
{value: 'RPS3AP18', id: 'RPS3AP18'},
{value: 'RPS3AP2', id: 'RPS3AP2'},
{value: 'RPS3AP20', id: 'RPS3AP20'},
{value: 'RPS3AP21', id: 'RPS3AP21'},
{value: 'RPS3AP23', id: 'RPS3AP23'},
{value: 'RPS3AP25', id: 'RPS3AP25'},
{value: 'RPS3AP26', id: 'RPS3AP26'},
{value: 'RPS3AP3', id: 'RPS3AP3'},
{value: 'RPS3AP32', id: 'RPS3AP32'},
{value: 'RPS3AP34', id: 'RPS3AP34'},
{value: 'RPS3AP36', id: 'RPS3AP36'},
{value: 'RPS3AP38', id: 'RPS3AP38'},
{value: 'RPS3AP4', id: 'RPS3AP4'},
{value: 'RPS3AP41', id: 'RPS3AP41'},
{value: 'RPS3AP43', id: 'RPS3AP43'},
{value: 'RPS3AP44', id: 'RPS3AP44'},
{value: 'RPS3AP47', id: 'RPS3AP47'},
{value: 'RPS3AP5', id: 'RPS3AP5'},
{value: 'RPS3AP50', id: 'RPS3AP50'},
{value: 'RPS3AP52', id: 'RPS3AP52'},
{value: 'RPS3AP53', id: 'RPS3AP53'},
{value: 'RPS3AP6', id: 'RPS3AP6'},
{value: 'RPS3AP7', id: 'RPS3AP7'},
{value: 'RPS3AP9', id: 'RPS3AP9'},
{value: 'RPS3P1', id: 'RPS3P1'},
{value: 'RPS3P7', id: 'RPS3P7'},
{value: 'RPS4X', id: 'RPS4X'},
{value: 'RPS4XP1', id: 'RPS4XP1'},
{value: 'RPS4XP10', id: 'RPS4XP10'},
{value: 'RPS4XP11', id: 'RPS4XP11'},
{value: 'RPS4XP12', id: 'RPS4XP12'},
{value: 'RPS4XP13', id: 'RPS4XP13'},
{value: 'RPS4XP15', id: 'RPS4XP15'},
{value: 'RPS4XP16', id: 'RPS4XP16'},
{value: 'RPS4XP18', id: 'RPS4XP18'},
{value: 'RPS4XP19', id: 'RPS4XP19'},
{value: 'RPS4XP2', id: 'RPS4XP2'},
{value: 'RPS4XP21', id: 'RPS4XP21'},
{value: 'RPS4XP22', id: 'RPS4XP22'},
{value: 'RPS4XP23', id: 'RPS4XP23'},
{value: 'RPS4XP3', id: 'RPS4XP3'},
{value: 'RPS4XP4', id: 'RPS4XP4'},
{value: 'RPS4XP5', id: 'RPS4XP5'},
{value: 'RPS4XP6', id: 'RPS4XP6'},
{value: 'RPS4XP7', id: 'RPS4XP7'},
{value: 'RPS4XP8', id: 'RPS4XP8'},
{value: 'RPS4Y1', id: 'RPS4Y1'},
{value: 'RPS4Y2', id: 'RPS4Y2'},
{value: 'RPS5', id: 'RPS5'},
{value: 'RPS5P2', id: 'RPS5P2'},
{value: 'RPS6', id: 'RPS6'},
{value: 'RPS6KA1', id: 'RPS6KA1'},
{value: 'RPS6KA2', id: 'RPS6KA2'},
{value: 'RPS6KA2-AS1', id: 'RPS6KA2-AS1'},
{value: 'RPS6KA2-IT1', id: 'RPS6KA2-IT1'},
{value: 'RPS6KA3', id: 'RPS6KA3'},
{value: 'RPS6KA4', id: 'RPS6KA4'},
{value: 'RPS6KA5', id: 'RPS6KA5'},
{value: 'RPS6KA6', id: 'RPS6KA6'},
{value: 'RPS6KB1', id: 'RPS6KB1'},
{value: 'RPS6KB2', id: 'RPS6KB2'},
{value: 'RPS6KC1', id: 'RPS6KC1'},
{value: 'RPS6KL1', id: 'RPS6KL1'},
{value: 'RPS6P12', id: 'RPS6P12'},
{value: 'RPS6P14', id: 'RPS6P14'},
{value: 'RPS6P15', id: 'RPS6P15'},
{value: 'RPS6P20', id: 'RPS6P20'},
{value: 'RPS6P21', id: 'RPS6P21'},
{value: 'RPS6P23', id: 'RPS6P23'},
{value: 'RPS6P25', id: 'RPS6P25'},
{value: 'RPS6P3', id: 'RPS6P3'},
{value: 'RPS6P7', id: 'RPS6P7'},
{value: 'RPS7', id: 'RPS7'},
{value: 'RPS7P1', id: 'RPS7P1'},
{value: 'RPS7P10', id: 'RPS7P10'},
{value: 'RPS7P11', id: 'RPS7P11'},
{value: 'RPS7P15', id: 'RPS7P15'},
{value: 'RPS7P4', id: 'RPS7P4'},
{value: 'RPS7P5', id: 'RPS7P5'},
{value: 'RPS7P6', id: 'RPS7P6'},
{value: 'RPS7P8', id: 'RPS7P8'},
{value: 'RPS7P9', id: 'RPS7P9'},
{value: 'RPS8', id: 'RPS8'},
{value: 'RPS8P10', id: 'RPS8P10'},
{value: 'RPS8P3', id: 'RPS8P3'},
{value: 'RPS8P4', id: 'RPS8P4'},
{value: 'RPS8P6', id: 'RPS8P6'},
{value: 'RPS9', id: 'RPS9'},
{value: 'RPSA', id: 'RPSA'},
{value: 'RPSAP1', id: 'RPSAP1'},
{value: 'RPSAP10', id: 'RPSAP10'},
{value: 'RPSAP11', id: 'RPSAP11'},
{value: 'RPSAP12', id: 'RPSAP12'},
{value: 'RPSAP15', id: 'RPSAP15'},
{value: 'RPSAP17', id: 'RPSAP17'},
{value: 'RPSAP18', id: 'RPSAP18'},
{value: 'RPSAP19', id: 'RPSAP19'},
{value: 'RPSAP2', id: 'RPSAP2'},
{value: 'RPSAP22', id: 'RPSAP22'},
{value: 'RPSAP24', id: 'RPSAP24'},
{value: 'RPSAP25', id: 'RPSAP25'},
{value: 'RPSAP26', id: 'RPSAP26'},
{value: 'RPSAP27', id: 'RPSAP27'},
{value: 'RPSAP28', id: 'RPSAP28'},
{value: 'RPSAP29', id: 'RPSAP29'},
{value: 'RPSAP31', id: 'RPSAP31'},
{value: 'RPSAP34', id: 'RPSAP34'},
{value: 'RPSAP36', id: 'RPSAP36'},
{value: 'RPSAP38', id: 'RPSAP38'},
{value: 'RPSAP39', id: 'RPSAP39'},
{value: 'RPSAP4', id: 'RPSAP4'},
{value: 'RPSAP40', id: 'RPSAP40'},
{value: 'RPSAP41', id: 'RPSAP41'},
{value: 'RPSAP42', id: 'RPSAP42'},
{value: 'RPSAP44', id: 'RPSAP44'},
{value: 'RPSAP45', id: 'RPSAP45'},
{value: 'RPSAP46', id: 'RPSAP46'},
{value: 'RPSAP47', id: 'RPSAP47'},
{value: 'RPSAP48', id: 'RPSAP48'},
{value: 'RPSAP49', id: 'RPSAP49'},
{value: 'RPSAP5', id: 'RPSAP5'},
{value: 'RPSAP52', id: 'RPSAP52'},
{value: 'RPSAP53', id: 'RPSAP53'},
{value: 'RPSAP54', id: 'RPSAP54'},
{value: 'RPSAP55', id: 'RPSAP55'},
{value: 'RPSAP56', id: 'RPSAP56'},
{value: 'RPSAP58', id: 'RPSAP58'},
{value: 'RPSAP64', id: 'RPSAP64'},
{value: 'RPSAP65', id: 'RPSAP65'},
{value: 'RPSAP67', id: 'RPSAP67'},
{value: 'RPSAP68', id: 'RPSAP68'},
{value: 'RPSAP70', id: 'RPSAP70'},
{value: 'RPSAP71', id: 'RPSAP71'},
{value: 'RPSAP72', id: 'RPSAP72'},
{value: 'RPSAP73', id: 'RPSAP73'},
{value: 'RPSAP74', id: 'RPSAP74'},
{value: 'RPSAP76', id: 'RPSAP76'},
{value: 'RPSAP9', id: 'RPSAP9'},
{value: 'RPTOR', id: 'RPTOR'},
{value: 'RPUSD1', id: 'RPUSD1'},
{value: 'RPUSD2', id: 'RPUSD2'},
{value: 'RPUSD3', id: 'RPUSD3'},
{value: 'RPUSD4', id: 'RPUSD4'},
{value: 'RRAD', id: 'RRAD'},
{value: 'RRAGA', id: 'RRAGA'},
{value: 'RRAGAP1-AS1', id: 'RRAGAP1-AS1'},
{value: 'RRAGB', id: 'RRAGB'},
{value: 'RRAGC', id: 'RRAGC'},
{value: 'RRAGD', id: 'RRAGD'},
{value: 'RRAS', id: 'RRAS'},
{value: 'RRAS2', id: 'RRAS2'},
{value: 'RRBP1', id: 'RRBP1'},
{value: 'RREB1', id: 'RREB1'},
{value: 'RRH', id: 'RRH'},
{value: 'RRM1', id: 'RRM1'},
{value: 'RRM1-AS1', id: 'RRM1-AS1'},
{value: 'RRM2', id: 'RRM2'},
{value: 'RRM2B', id: 'RRM2B'},
{value: 'RRM2P2', id: 'RRM2P2'},
{value: 'RRM2P3', id: 'RRM2P3'},
{value: 'RRN3', id: 'RRN3'},
{value: 'RRN3P1', id: 'RRN3P1'},
{value: 'RRN3P2', id: 'RRN3P2'},
{value: 'RRN3P3', id: 'RRN3P3'},
{value: 'RRN3P4', id: 'RRN3P4'},
{value: 'RRP1', id: 'RRP1'},
{value: 'RRP12', id: 'RRP12'},
{value: 'RRP15', id: 'RRP15'},
{value: 'RRP1B', id: 'RRP1B'},
{value: 'RRP36', id: 'RRP36'},
{value: 'RRP7A', id: 'RRP7A'},
{value: 'RRP7BP', id: 'RRP7BP'},
{value: 'RRP8', id: 'RRP8'},
{value: 'RRP9', id: 'RRP9'},
{value: 'RRS1', id: 'RRS1'},
{value: 'RRS1-DT', id: 'RRS1-DT'},
{value: 'RSAD1', id: 'RSAD1'},
{value: 'RSAD2', id: 'RSAD2'},
{value: 'RSBN1', id: 'RSBN1'},
{value: 'RSBN1L', id: 'RSBN1L'},
{value: 'RSC1A1', id: 'RSC1A1'},
{value: 'RSF1', id: 'RSF1'},
{value: 'RSKR', id: 'RSKR'},
{value: 'RSL1D1', id: 'RSL1D1'},
{value: 'RSL1D1-DT', id: 'RSL1D1-DT'},
{value: 'RSL24D1', id: 'RSL24D1'},
{value: 'RSL24D1P1', id: 'RSL24D1P1'},
{value: 'RSL24D1P11', id: 'RSL24D1P11'},
{value: 'RSL24D1P3', id: 'RSL24D1P3'},
{value: 'RSL24D1P4', id: 'RSL24D1P4'},
{value: 'RSL24D1P5', id: 'RSL24D1P5'},
{value: 'RSL24D1P6', id: 'RSL24D1P6'},
{value: 'RSL24D1P9', id: 'RSL24D1P9'},
{value: 'RSPH1', id: 'RSPH1'},
{value: 'RSPH10B', id: 'RSPH10B'},
{value: 'RSPH10B2', id: 'RSPH10B2'},
{value: 'RSPH14', id: 'RSPH14'},
{value: 'RSPH3', id: 'RSPH3'},
{value: 'RSPH4A', id: 'RSPH4A'},
{value: 'RSPH6A', id: 'RSPH6A'},
{value: 'RSPH9', id: 'RSPH9'},
{value: 'RSPO1', id: 'RSPO1'},
{value: 'RSPO2', id: 'RSPO2'},
{value: 'RSPO3', id: 'RSPO3'},
{value: 'RSPO4', id: 'RSPO4'},
{value: 'RSPRY1', id: 'RSPRY1'},
{value: 'RSRC1', id: 'RSRC1'},
{value: 'RSRC2', id: 'RSRC2'},
{value: 'RSRP1', id: 'RSRP1'},
{value: 'RSU1', id: 'RSU1'},
{value: 'RSU1P1', id: 'RSU1P1'},
{value: 'RSU1P2', id: 'RSU1P2'},
{value: 'RSU1P3', id: 'RSU1P3'},
{value: 'RTBDN', id: 'RTBDN'},
{value: 'RTCA', id: 'RTCA'},
{value: 'RTCA-AS1', id: 'RTCA-AS1'},
{value: 'RTCB', id: 'RTCB'},
{value: 'RTEL1', id: 'RTEL1'},
{value: 'RTEL1-TNFRSF6B', id: 'RTEL1-TNFRSF6B'},
{value: 'RTEL1P1', id: 'RTEL1P1'},
{value: 'RTF1', id: 'RTF1'},
{value: 'RTF2', id: 'RTF2'},
{value: 'RTKN', id: 'RTKN'},
{value: 'RTKN2', id: 'RTKN2'},
{value: 'RTL1', id: 'RTL1'},
{value: 'RTL10', id: 'RTL10'},
{value: 'RTL5', id: 'RTL5'},
{value: 'RTL6', id: 'RTL6'},
{value: 'RTL8A', id: 'RTL8A'},
{value: 'RTL8B', id: 'RTL8B'},
{value: 'RTL8C', id: 'RTL8C'},
{value: 'RTL9', id: 'RTL9'},
{value: 'RTN1', id: 'RTN1'},
{value: 'RTN2', id: 'RTN2'},
{value: 'RTN3', id: 'RTN3'},
{value: 'RTN3P1', id: 'RTN3P1'},
{value: 'RTN4', id: 'RTN4'},
{value: 'RTN4IP1', id: 'RTN4IP1'},
{value: 'RTN4R', id: 'RTN4R'},
{value: 'RTN4RL1', id: 'RTN4RL1'},
{value: 'RTN4RL2', id: 'RTN4RL2'},
{value: 'RTP1', id: 'RTP1'},
{value: 'RTP2', id: 'RTP2'},
{value: 'RTP3', id: 'RTP3'},
{value: 'RTP4', id: 'RTP4'},
{value: 'RTP5', id: 'RTP5'},
{value: 'RTRAF', id: 'RTRAF'},
{value: 'RTRAFP2', id: 'RTRAFP2'},
{value: 'RTTN', id: 'RTTN'},
{value: 'RUBCN', id: 'RUBCN'},
{value: 'RUBCNL', id: 'RUBCNL'},
{value: 'RUFY1', id: 'RUFY1'},
{value: 'RUFY1-AS1', id: 'RUFY1-AS1'},
{value: 'RUFY2', id: 'RUFY2'},
{value: 'RUFY3', id: 'RUFY3'},
{value: 'RUFY4', id: 'RUFY4'},
{value: 'RUNDC1', id: 'RUNDC1'},
{value: 'RUNDC3A', id: 'RUNDC3A'},
{value: 'RUNDC3A-AS1', id: 'RUNDC3A-AS1'},
{value: 'RUNDC3B', id: 'RUNDC3B'},
{value: 'RUNX1', id: 'RUNX1'},
{value: 'RUNX1T1', id: 'RUNX1T1'},
{value: 'RUNX2', id: 'RUNX2'},
{value: 'RUNX2-AS1', id: 'RUNX2-AS1'},
{value: 'RUNX3', id: 'RUNX3'},
{value: 'RUSC1', id: 'RUSC1'},
{value: 'RUSC1-AS1', id: 'RUSC1-AS1'},
{value: 'RUSC2', id: 'RUSC2'},
{value: 'RUSF1', id: 'RUSF1'},
{value: 'RUVBL1', id: 'RUVBL1'},
{value: 'RUVBL1-AS1', id: 'RUVBL1-AS1'},
{value: 'RUVBL2', id: 'RUVBL2'},
{value: 'RWDD1', id: 'RWDD1'},
{value: 'RWDD2A', id: 'RWDD2A'},
{value: 'RWDD2B', id: 'RWDD2B'},
{value: 'RWDD3', id: 'RWDD3'},
{value: 'RWDD4', id: 'RWDD4'},
{value: 'RWDD4P1', id: 'RWDD4P1'},
{value: 'RWDD4P2', id: 'RWDD4P2'},
{value: 'RXFP1', id: 'RXFP1'},
{value: 'RXFP2', id: 'RXFP2'},
{value: 'RXFP3', id: 'RXFP3'},
{value: 'RXFP4', id: 'RXFP4'},
{value: 'RXRA', id: 'RXRA'},
{value: 'RXRB', id: 'RXRB'},
{value: 'RXRG', id: 'RXRG'},
{value: 'RXYLT1', id: 'RXYLT1'},
{value: 'RXYLT1-AS1', id: 'RXYLT1-AS1'},
{value: 'RYBP', id: 'RYBP'},
{value: 'RYK', id: 'RYK'},
{value: 'RYKP1', id: 'RYKP1'},
{value: 'RYR1', id: 'RYR1'},
{value: 'RYR2', id: 'RYR2'},
{value: 'RYR3', id: 'RYR3'},
{value: 'RYR3-DT', id: 'RYR3-DT'},
{value: 'S100A1', id: 'S100A1'},
{value: 'S100A10', id: 'S100A10'},
{value: 'S100A11', id: 'S100A11'},
{value: 'S100A11P2', id: 'S100A11P2'},
{value: 'S100A12', id: 'S100A12'},
{value: 'S100A13', id: 'S100A13'},
{value: 'S100A14', id: 'S100A14'},
{value: 'S100A15A', id: 'S100A15A'},
{value: 'S100A16', id: 'S100A16'},
{value: 'S100A2', id: 'S100A2'},
{value: 'S100A3', id: 'S100A3'},
{value: 'S100A4', id: 'S100A4'},
{value: 'S100A5', id: 'S100A5'},
{value: 'S100A6', id: 'S100A6'},
{value: 'S100A7', id: 'S100A7'},
{value: 'S100A7A', id: 'S100A7A'},
{value: 'S100A7L2', id: 'S100A7L2'},
{value: 'S100A7P1', id: 'S100A7P1'},
{value: 'S100A8', id: 'S100A8'},
{value: 'S100A9', id: 'S100A9'},
{value: 'S100B', id: 'S100B'},
{value: 'S100P', id: 'S100P'},
{value: 'S100PBP', id: 'S100PBP'},
{value: 'S100Z', id: 'S100Z'},
{value: 'S1PR1', id: 'S1PR1'},
{value: 'S1PR2', id: 'S1PR2'},
{value: 'S1PR3', id: 'S1PR3'},
{value: 'S1PR4', id: 'S1PR4'},
{value: 'S1PR5', id: 'S1PR5'},
{value: 'SAA1', id: 'SAA1'},
{value: 'SAA2', id: 'SAA2'},
{value: 'SAA2-SAA4', id: 'SAA2-SAA4'},
{value: 'SAA3P', id: 'SAA3P'},
{value: 'SAA4', id: 'SAA4'},
{value: 'SAAL1', id: 'SAAL1'},
{value: 'SAC3D1', id: 'SAC3D1'},
{value: 'SACM1L', id: 'SACM1L'},
{value: 'SACS', id: 'SACS'},
{value: 'SACS-AS1', id: 'SACS-AS1'},
{value: 'SAE1', id: 'SAE1'},
{value: 'SAFB', id: 'SAFB'},
{value: 'SAFB2', id: 'SAFB2'},
{value: 'SAG', id: 'SAG'},
{value: 'SALL1', id: 'SALL1'},
{value: 'SALL2', id: 'SALL2'},
{value: 'SALL3', id: 'SALL3'},
{value: 'SALL4', id: 'SALL4'},
{value: 'SALL4P5', id: 'SALL4P5'},
{value: 'SALL4P6', id: 'SALL4P6'},
{value: 'SALL4P7', id: 'SALL4P7'},
{value: 'SALRNA1', id: 'SALRNA1'},
{value: 'SALRNA2', id: 'SALRNA2'},
{value: 'SALRNA3', id: 'SALRNA3'},
{value: 'SAMD1', id: 'SAMD1'},
{value: 'SAMD10', id: 'SAMD10'},
{value: 'SAMD11', id: 'SAMD11'},
{value: 'SAMD11P1', id: 'SAMD11P1'},
{value: 'SAMD12', id: 'SAMD12'},
{value: 'SAMD12-AS1', id: 'SAMD12-AS1'},
{value: 'SAMD13', id: 'SAMD13'},
{value: 'SAMD14', id: 'SAMD14'},
{value: 'SAMD15', id: 'SAMD15'},
{value: 'SAMD3', id: 'SAMD3'},
{value: 'SAMD4A', id: 'SAMD4A'},
{value: 'SAMD4B', id: 'SAMD4B'},
{value: 'SAMD5', id: 'SAMD5'},
{value: 'SAMD7', id: 'SAMD7'},
{value: 'SAMD8', id: 'SAMD8'},
{value: 'SAMD9', id: 'SAMD9'},
{value: 'SAMD9L', id: 'SAMD9L'},
{value: 'SAMHD1', id: 'SAMHD1'},
{value: 'SAMM50', id: 'SAMM50'},
{value: 'SAMM50P1', id: 'SAMM50P1'},
{value: 'SAMMSON', id: 'SAMMSON'},
{value: 'SAMSN1', id: 'SAMSN1'},
{value: 'SAMSN1-AS1', id: 'SAMSN1-AS1'},
{value: 'SANBR', id: 'SANBR'},
{value: 'SAP130', id: 'SAP130'},
{value: 'SAP18', id: 'SAP18'},
{value: 'SAP18P1', id: 'SAP18P1'},
{value: 'SAP18P2', id: 'SAP18P2'},
{value: 'SAP25', id: 'SAP25'},
{value: 'SAP30', id: 'SAP30'},
{value: 'SAP30-DT', id: 'SAP30-DT'},
{value: 'SAP30BP', id: 'SAP30BP'},
{value: 'SAP30L', id: 'SAP30L'},
{value: 'SAP30L-AS1', id: 'SAP30L-AS1'},
{value: 'SAPCD1', id: 'SAPCD1'},
{value: 'SAPCD1-AS1', id: 'SAPCD1-AS1'},
{value: 'SAPCD2', id: 'SAPCD2'},
{value: 'SAPCD2P1', id: 'SAPCD2P1'},
{value: 'SAPCD2P2', id: 'SAPCD2P2'},
{value: 'SAPCD2P3', id: 'SAPCD2P3'},
{value: 'SAPCD2P4', id: 'SAPCD2P4'},
{value: 'SAR1A', id: 'SAR1A'},
{value: 'SAR1AP1', id: 'SAR1AP1'},
{value: 'SAR1AP2', id: 'SAR1AP2'},
{value: 'SAR1AP3', id: 'SAR1AP3'},
{value: 'SAR1B', id: 'SAR1B'},
{value: 'SARAF', id: 'SARAF'},
{value: 'SARDH', id: 'SARDH'},
{value: 'SARM1', id: 'SARM1'},
{value: 'SARNP', id: 'SARNP'},
{value: 'SARS1', id: 'SARS1'},
{value: 'SARS2', id: 'SARS2'},
{value: 'SART1', id: 'SART1'},
{value: 'SART3', id: 'SART3'},
{value: 'SASH1', id: 'SASH1'},
{value: 'SASH3', id: 'SASH3'},
{value: 'SASS6', id: 'SASS6'},
{value: 'SAT1', id: 'SAT1'},
{value: 'SAT2', id: 'SAT2'},
{value: 'SATB1', id: 'SATB1'},
{value: 'SATB1-AS1', id: 'SATB1-AS1'},
{value: 'SATB2', id: 'SATB2'},
{value: 'SATB2-AS1', id: 'SATB2-AS1'},
{value: 'SAV1', id: 'SAV1'},
{value: 'SAXO1', id: 'SAXO1'},
{value: 'SAXO2', id: 'SAXO2'},
{value: 'SAYSD1', id: 'SAYSD1'},
{value: 'SBDS', id: 'SBDS'},
{value: 'SBDSP1', id: 'SBDSP1'},
{value: 'SBF1', id: 'SBF1'},
{value: 'SBF1P1', id: 'SBF1P1'},
{value: 'SBF1P2', id: 'SBF1P2'},
{value: 'SBF2', id: 'SBF2'},
{value: 'SBF2-AS1', id: 'SBF2-AS1'},
{value: 'SBK1', id: 'SBK1'},
{value: 'SBK2', id: 'SBK2'},
{value: 'SBK3', id: 'SBK3'},
{value: 'SBNO1', id: 'SBNO1'},
{value: 'SBNO1-AS1', id: 'SBNO1-AS1'},
{value: 'SBNO2', id: 'SBNO2'},
{value: 'SBSN', id: 'SBSN'},
{value: 'SBSPON', id: 'SBSPON'},
{value: 'SC5D', id: 'SC5D'},
{value: 'SCAANT1', id: 'SCAANT1'},
{value: 'SCAF1', id: 'SCAF1'},
{value: 'SCAF11', id: 'SCAF11'},
{value: 'SCAF4', id: 'SCAF4'},
{value: 'SCAF8', id: 'SCAF8'},
{value: 'SCAI', id: 'SCAI'},
{value: 'SCAMP1', id: 'SCAMP1'},
{value: 'SCAMP1-AS1', id: 'SCAMP1-AS1'},
{value: 'SCAMP2', id: 'SCAMP2'},
{value: 'SCAMP3', id: 'SCAMP3'},
{value: 'SCAMP4', id: 'SCAMP4'},
{value: 'SCAMP5', id: 'SCAMP5'},
{value: 'SCAND1', id: 'SCAND1'},
{value: 'SCAND2P', id: 'SCAND2P'},
{value: 'SCAP', id: 'SCAP'},
{value: 'SCAPER', id: 'SCAPER'},
{value: 'SCARA3', id: 'SCARA3'},
{value: 'SCARA5', id: 'SCARA5'},
{value: 'SCARB1', id: 'SCARB1'},
{value: 'SCARB2', id: 'SCARB2'},
{value: 'SCARF1', id: 'SCARF1'},
{value: 'SCARF2', id: 'SCARF2'},
{value: 'SCARNA10', id: 'SCARNA10'},
{value: 'SCARNA11', id: 'SCARNA11'},
{value: 'SCARNA12', id: 'SCARNA12'},
{value: 'SCARNA13', id: 'SCARNA13'},
{value: 'SCARNA17', id: 'SCARNA17'},
{value: 'SCARNA2', id: 'SCARNA2'},
{value: 'SCARNA21B', id: 'SCARNA21B'},
{value: 'SCARNA22', id: 'SCARNA22'},
{value: 'SCARNA5', id: 'SCARNA5'},
{value: 'SCARNA6', id: 'SCARNA6'},
{value: 'SCARNA7', id: 'SCARNA7'},
{value: 'SCARNA9', id: 'SCARNA9'},
{value: 'SCART1', id: 'SCART1'},
{value: 'SCAT1', id: 'SCAT1'},
{value: 'SCAT2', id: 'SCAT2'},
{value: 'SCAT8', id: 'SCAT8'},
{value: 'SCCPDH', id: 'SCCPDH'},
{value: 'SCD', id: 'SCD'},
{value: 'SCD5', id: 'SCD5'},
{value: 'SCDP1', id: 'SCDP1'},
{value: 'SCEL', id: 'SCEL'},
{value: 'SCFD1', id: 'SCFD1'},
{value: 'SCFD2', id: 'SCFD2'},
{value: 'SCG2', id: 'SCG2'},
{value: 'SCG3', id: 'SCG3'},
{value: 'SCGB1A1', id: 'SCGB1A1'},
{value: 'SCGB1B2P', id: 'SCGB1B2P'},
{value: 'SCGB1C1', id: 'SCGB1C1'},
{value: 'SCGB1C2', id: 'SCGB1C2'},
{value: 'SCGB1D1', id: 'SCGB1D1'},
{value: 'SCGB1D2', id: 'SCGB1D2'},
{value: 'SCGB1D4', id: 'SCGB1D4'},
{value: 'SCGB1D5P', id: 'SCGB1D5P'},
{value: 'SCGB2A1', id: 'SCGB2A1'},
{value: 'SCGB2A2', id: 'SCGB2A2'},
{value: 'SCGB2B2', id: 'SCGB2B2'},
{value: 'SCGB3A1', id: 'SCGB3A1'},
{value: 'SCGB3A2', id: 'SCGB3A2'},
{value: 'SCGN', id: 'SCGN'},
{value: 'SCHIP1', id: 'SCHIP1'},
{value: 'SCHLAP1', id: 'SCHLAP1'},
{value: 'SCIMP', id: 'SCIMP'},
{value: 'SCIN', id: 'SCIN'},
{value: 'SCIRT', id: 'SCIRT'},
{value: 'SCLT1', id: 'SCLT1'},
{value: 'SCLY', id: 'SCLY'},
{value: 'SCMH1', id: 'SCMH1'},
{value: 'SCML1', id: 'SCML1'},
{value: 'SCML2', id: 'SCML2'},
{value: 'SCML2P1', id: 'SCML2P1'},
{value: 'SCML4', id: 'SCML4'},
{value: 'SCN10A', id: 'SCN10A'},
{value: 'SCN11A', id: 'SCN11A'},
{value: 'SCN1A', id: 'SCN1A'},
{value: 'SCN1A-AS1', id: 'SCN1A-AS1'},
{value: 'SCN1B', id: 'SCN1B'},
{value: 'SCN2A', id: 'SCN2A'},
{value: 'SCN2B', id: 'SCN2B'},
{value: 'SCN3A', id: 'SCN3A'},
{value: 'SCN3B', id: 'SCN3B'},
{value: 'SCN4A', id: 'SCN4A'},
{value: 'SCN4B', id: 'SCN4B'},
{value: 'SCN5A', id: 'SCN5A'},
{value: 'SCN7A', id: 'SCN7A'},
{value: 'SCN8A', id: 'SCN8A'},
{value: 'SCN9A', id: 'SCN9A'},
{value: 'SCNM1', id: 'SCNM1'},
{value: 'SCNN1A', id: 'SCNN1A'},
{value: 'SCNN1B', id: 'SCNN1B'},
{value: 'SCNN1D', id: 'SCNN1D'},
{value: 'SCNN1G', id: 'SCNN1G'},
{value: 'SCO1', id: 'SCO1'},
{value: 'SCO2', id: 'SCO2'},
{value: 'SCOC', id: 'SCOC'},
{value: 'SCOC-AS1', id: 'SCOC-AS1'},
{value: 'SCP2', id: 'SCP2'},
{value: 'SCP2D1', id: 'SCP2D1'},
{value: 'SCP2D1-AS1', id: 'SCP2D1-AS1'},
{value: 'SCPEP1', id: 'SCPEP1'},
{value: 'SCRG1', id: 'SCRG1'},
{value: 'SCRIB', id: 'SCRIB'},
{value: 'SCRN1', id: 'SCRN1'},
{value: 'SCRN2', id: 'SCRN2'},
{value: 'SCRN3', id: 'SCRN3'},
{value: 'SCRT1', id: 'SCRT1'},
{value: 'SCRT2', id: 'SCRT2'},
{value: 'SCT', id: 'SCT'},
{value: 'SCTR', id: 'SCTR'},
{value: 'SCTR-AS1', id: 'SCTR-AS1'},
{value: 'SCUBE1', id: 'SCUBE1'},
{value: 'SCUBE1-AS2', id: 'SCUBE1-AS2'},
{value: 'SCUBE2', id: 'SCUBE2'},
{value: 'SCUBE3', id: 'SCUBE3'},
{value: 'SCX', id: 'SCX'},
{value: 'SCYGR1', id: 'SCYGR1'},
{value: 'SCYGR10', id: 'SCYGR10'},
{value: 'SCYGR2', id: 'SCYGR2'},
{value: 'SCYGR3', id: 'SCYGR3'},
{value: 'SCYGR5', id: 'SCYGR5'},
{value: 'SCYGR6', id: 'SCYGR6'},
{value: 'SCYGR7', id: 'SCYGR7'},
{value: 'SCYGR8', id: 'SCYGR8'},
{value: 'SCYGR9', id: 'SCYGR9'},
{value: 'SCYL1', id: 'SCYL1'},
{value: 'SCYL2', id: 'SCYL2'},
{value: 'SCYL2P1', id: 'SCYL2P1'},
{value: 'SCYL3', id: 'SCYL3'},
{value: 'SDAD1', id: 'SDAD1'},
{value: 'SDAD1-AS1', id: 'SDAD1-AS1'},
{value: 'SDAD1P1', id: 'SDAD1P1'},
{value: 'SDAD1P2', id: 'SDAD1P2'},
{value: 'SDAD1P3', id: 'SDAD1P3'},
{value: 'SDC1', id: 'SDC1'},
{value: 'SDC2', id: 'SDC2'},
{value: 'SDC3', id: 'SDC3'},
{value: 'SDC4', id: 'SDC4'},
{value: 'SDCBP', id: 'SDCBP'},
{value: 'SDCBP2', id: 'SDCBP2'},
{value: 'SDCBP2-AS1', id: 'SDCBP2-AS1'},
{value: 'SDCBP2P1', id: 'SDCBP2P1'},
{value: 'SDCBPP2', id: 'SDCBPP2'},
{value: 'SDCCAG8', id: 'SDCCAG8'},
{value: 'SDE2', id: 'SDE2'},
{value: 'SDF2', id: 'SDF2'},
{value: 'SDF2L1', id: 'SDF2L1'},
{value: 'SDF4', id: 'SDF4'},
{value: 'SDHA', id: 'SDHA'},
{value: 'SDHAF1', id: 'SDHAF1'},
{value: 'SDHAF2', id: 'SDHAF2'},
{value: 'SDHAF3', id: 'SDHAF3'},
{value: 'SDHAF4', id: 'SDHAF4'},
{value: 'SDHAP1', id: 'SDHAP1'},
{value: 'SDHAP2', id: 'SDHAP2'},
{value: 'SDHAP3', id: 'SDHAP3'},
{value: 'SDHB', id: 'SDHB'},
{value: 'SDHC', id: 'SDHC'},
{value: 'SDHD', id: 'SDHD'},
{value: 'SDHDP1', id: 'SDHDP1'},
{value: 'SDHDP2', id: 'SDHDP2'},
{value: 'SDHDP3', id: 'SDHDP3'},
{value: 'SDHDP4', id: 'SDHDP4'},
{value: 'SDHDP5', id: 'SDHDP5'},
{value: 'SDHDP6', id: 'SDHDP6'},
{value: 'SDK1', id: 'SDK1'},
{value: 'SDK1-AS1', id: 'SDK1-AS1'},
{value: 'SDK2', id: 'SDK2'},
{value: 'SDR16C5', id: 'SDR16C5'},
{value: 'SDR16C6P', id: 'SDR16C6P'},
{value: 'SDR39U1', id: 'SDR39U1'},
{value: 'SDR42E1', id: 'SDR42E1'},
{value: 'SDR42E1P1', id: 'SDR42E1P1'},
{value: 'SDR42E1P2', id: 'SDR42E1P2'},
{value: 'SDR42E1P3', id: 'SDR42E1P3'},
{value: 'SDR42E1P4', id: 'SDR42E1P4'},
{value: 'SDR42E1P5', id: 'SDR42E1P5'},
{value: 'SDR42E2', id: 'SDR42E2'},
{value: 'SDR9C7', id: 'SDR9C7'},
{value: 'SDS', id: 'SDS'},
{value: 'SDSL', id: 'SDSL'},
{value: 'SEBOX', id: 'SEBOX'},
{value: 'SEC11A', id: 'SEC11A'},
{value: 'SEC11C', id: 'SEC11C'},
{value: 'SEC13', id: 'SEC13'},
{value: 'SEC13P1', id: 'SEC13P1'},
{value: 'SEC14L1', id: 'SEC14L1'},
{value: 'SEC14L1P1', id: 'SEC14L1P1'},
{value: 'SEC14L2', id: 'SEC14L2'},
{value: 'SEC14L3', id: 'SEC14L3'},
{value: 'SEC14L4', id: 'SEC14L4'},
{value: 'SEC14L5', id: 'SEC14L5'},
{value: 'SEC14L6', id: 'SEC14L6'},
{value: 'SEC16A', id: 'SEC16A'},
{value: 'SEC16B', id: 'SEC16B'},
{value: 'SEC1P', id: 'SEC1P'},
{value: 'SEC22A', id: 'SEC22A'},
{value: 'SEC22B', id: 'SEC22B'},
{value: 'SEC22B2P', id: 'SEC22B2P'},
{value: 'SEC22B3P', id: 'SEC22B3P'},
{value: 'SEC22B4P', id: 'SEC22B4P'},
{value: 'SEC22C', id: 'SEC22C'},
{value: 'SEC23A', id: 'SEC23A'},
{value: 'SEC23A-AS1', id: 'SEC23A-AS1'},
{value: 'SEC23B', id: 'SEC23B'},
{value: 'SEC23IP', id: 'SEC23IP'},
{value: 'SEC24A', id: 'SEC24A'},
{value: 'SEC24AP1', id: 'SEC24AP1'},
{value: 'SEC24B', id: 'SEC24B'},
{value: 'SEC24B-AS1', id: 'SEC24B-AS1'},
{value: 'SEC24C', id: 'SEC24C'},
{value: 'SEC24D', id: 'SEC24D'},
{value: 'SEC31A', id: 'SEC31A'},
{value: 'SEC31B', id: 'SEC31B'},
{value: 'SEC61A1', id: 'SEC61A1'},
{value: 'SEC61A2', id: 'SEC61A2'},
{value: 'SEC61B', id: 'SEC61B'},
{value: 'SEC61G', id: 'SEC61G'},
{value: 'SEC61G-DT', id: 'SEC61G-DT'},
{value: 'SEC61GP1', id: 'SEC61GP1'},
{value: 'SEC62', id: 'SEC62'},
{value: 'SEC62-AS1', id: 'SEC62-AS1'},
{value: 'SEC63', id: 'SEC63'},
{value: 'SEC63P1', id: 'SEC63P1'},
{value: 'SEC63P2', id: 'SEC63P2'},
{value: 'SECISBP2', id: 'SECISBP2'},
{value: 'SECISBP2L', id: 'SECISBP2L'},
{value: 'SECTM1', id: 'SECTM1'},
{value: 'SEH1L', id: 'SEH1L'},
{value: 'SEL1L', id: 'SEL1L'},
{value: 'SEL1L2', id: 'SEL1L2'},
{value: 'SEL1L3', id: 'SEL1L3'},
{value: 'SELE', id: 'SELE'},
{value: 'SELENBP1', id: 'SELENBP1'},
{value: 'SELENOF', id: 'SELENOF'},
{value: 'SELENOH', id: 'SELENOH'},
{value: 'SELENOI', id: 'SELENOI'},
{value: 'SELENOK', id: 'SELENOK'},
{value: 'SELENOKP3', id: 'SELENOKP3'},
{value: 'SELENOM', id: 'SELENOM'},
{value: 'SELENON', id: 'SELENON'},
{value: 'SELENOO', id: 'SELENOO'},
{value: 'SELENOOLP', id: 'SELENOOLP'},
{value: 'SELENOP', id: 'SELENOP'},
{value: 'SELENOS', id: 'SELENOS'},
{value: 'SELENOT', id: 'SELENOT'},
{value: 'SELENOTP1', id: 'SELENOTP1'},
{value: 'SELENOV', id: 'SELENOV'},
{value: 'SELENOW', id: 'SELENOW'},
{value: 'SELENOWP1', id: 'SELENOWP1'},
{value: 'SELL', id: 'SELL'},
{value: 'SELP', id: 'SELP'},
{value: 'SELPLG', id: 'SELPLG'},
{value: 'SEM1', id: 'SEM1'},
{value: 'SEM1P1', id: 'SEM1P1'},
{value: 'SEMA3A', id: 'SEMA3A'},
{value: 'SEMA3B', id: 'SEMA3B'},
{value: 'SEMA3B-AS1', id: 'SEMA3B-AS1'},
{value: 'SEMA3C', id: 'SEMA3C'},
{value: 'SEMA3D', id: 'SEMA3D'},
{value: 'SEMA3E', id: 'SEMA3E'},
{value: 'SEMA3F', id: 'SEMA3F'},
{value: 'SEMA3F-AS1', id: 'SEMA3F-AS1'},
{value: 'SEMA3G', id: 'SEMA3G'},
{value: 'SEMA4A', id: 'SEMA4A'},
{value: 'SEMA4B', id: 'SEMA4B'},
{value: 'SEMA4C', id: 'SEMA4C'},
{value: 'SEMA4D', id: 'SEMA4D'},
{value: 'SEMA4F', id: 'SEMA4F'},
{value: 'SEMA4G', id: 'SEMA4G'},
{value: 'SEMA5A', id: 'SEMA5A'},
{value: 'SEMA5B', id: 'SEMA5B'},
{value: 'SEMA6A', id: 'SEMA6A'},
{value: 'SEMA6A-AS1', id: 'SEMA6A-AS1'},
{value: 'SEMA6A-AS2', id: 'SEMA6A-AS2'},
{value: 'SEMA6B', id: 'SEMA6B'},
{value: 'SEMA6C', id: 'SEMA6C'},
{value: 'SEMA6D', id: 'SEMA6D'},
{value: 'SEMA7A', id: 'SEMA7A'},
{value: 'SEMG1', id: 'SEMG1'},
{value: 'SEMG2', id: 'SEMG2'},
{value: 'SENCR', id: 'SENCR'},
{value: 'SENP1', id: 'SENP1'},
{value: 'SENP2', id: 'SENP2'},
{value: 'SENP3', id: 'SENP3'},
{value: 'SENP3-EIF4A1', id: 'SENP3-EIF4A1'},
{value: 'SENP5', id: 'SENP5'},
{value: 'SENP6', id: 'SENP6'},
{value: 'SENP7', id: 'SENP7'},
{value: 'SENP8', id: 'SENP8'},
{value: 'SEPHS1', id: 'SEPHS1'},
{value: 'SEPHS1P1', id: 'SEPHS1P1'},
{value: 'SEPHS1P6', id: 'SEPHS1P6'},
{value: 'SEPHS1P7', id: 'SEPHS1P7'},
{value: 'SEPHS2', id: 'SEPHS2'},
{value: 'SEPHS2P1', id: 'SEPHS2P1'},
{value: 'SEPSECS', id: 'SEPSECS'},
{value: 'SEPSECS-AS1', id: 'SEPSECS-AS1'},
{value: 'SEPTIN1', id: 'SEPTIN1'},
{value: 'SEPTIN10', id: 'SEPTIN10'},
{value: 'SEPTIN11', id: 'SEPTIN11'},
{value: 'SEPTIN12', id: 'SEPTIN12'},
{value: 'SEPTIN14', id: 'SEPTIN14'},
{value: 'SEPTIN14P1', id: 'SEPTIN14P1'},
{value: 'SEPTIN14P17', id: 'SEPTIN14P17'},
{value: 'SEPTIN14P19', id: 'SEPTIN14P19'},
{value: 'SEPTIN14P21', id: 'SEPTIN14P21'},
{value: 'SEPTIN14P24', id: 'SEPTIN14P24'},
{value: 'SEPTIN14P5', id: 'SEPTIN14P5'},
{value: 'SEPTIN14P8', id: 'SEPTIN14P8'},
{value: 'SEPTIN2', id: 'SEPTIN2'},
{value: 'SEPTIN2P1', id: 'SEPTIN2P1'},
{value: 'SEPTIN3', id: 'SEPTIN3'},
{value: 'SEPTIN4', id: 'SEPTIN4'},
{value: 'SEPTIN4-AS1', id: 'SEPTIN4-AS1'},
{value: 'SEPTIN5', id: 'SEPTIN5'},
{value: 'SEPTIN6', id: 'SEPTIN6'},
{value: 'SEPTIN7', id: 'SEPTIN7'},
{value: 'SEPTIN7-DT', id: 'SEPTIN7-DT'},
{value: 'SEPTIN7P11', id: 'SEPTIN7P11'},
{value: 'SEPTIN7P13', id: 'SEPTIN7P13'},
{value: 'SEPTIN7P14', id: 'SEPTIN7P14'},
{value: 'SEPTIN7P15', id: 'SEPTIN7P15'},
{value: 'SEPTIN7P2', id: 'SEPTIN7P2'},
{value: 'SEPTIN7P3', id: 'SEPTIN7P3'},
{value: 'SEPTIN7P4', id: 'SEPTIN7P4'},
{value: 'SEPTIN7P6', id: 'SEPTIN7P6'},
{value: 'SEPTIN7P8', id: 'SEPTIN7P8'},
{value: 'SEPTIN7P9', id: 'SEPTIN7P9'},
{value: 'SEPTIN8', id: 'SEPTIN8'},
{value: 'SEPTIN9', id: 'SEPTIN9'},
{value: 'SEPTIN9-DT', id: 'SEPTIN9-DT'},
{value: 'SERAC1', id: 'SERAC1'},
{value: 'SERBP1', id: 'SERBP1'},
{value: 'SERBP1P1', id: 'SERBP1P1'},
{value: 'SERBP1P3', id: 'SERBP1P3'},
{value: 'SERBP1P5', id: 'SERBP1P5'},
{value: 'SERBP1P6', id: 'SERBP1P6'},
{value: 'SERF1A', id: 'SERF1A'},
{value: 'SERF1AP1', id: 'SERF1AP1'},
{value: 'SERF1B', id: 'SERF1B'},
{value: 'SERF2', id: 'SERF2'},
{value: 'SERGEF', id: 'SERGEF'},
{value: 'SERHL', id: 'SERHL'},
{value: 'SERHL2', id: 'SERHL2'},
{value: 'SERINC1', id: 'SERINC1'},
{value: 'SERINC2', id: 'SERINC2'},
{value: 'SERINC3', id: 'SERINC3'},
{value: 'SERINC4', id: 'SERINC4'},
{value: 'SERINC5', id: 'SERINC5'},
{value: 'SERP1', id: 'SERP1'},
{value: 'SERP2', id: 'SERP2'},
{value: 'SERPINA1', id: 'SERPINA1'},
{value: 'SERPINA10', id: 'SERPINA10'},
{value: 'SERPINA11', id: 'SERPINA11'},
{value: 'SERPINA12', id: 'SERPINA12'},
{value: 'SERPINA13P', id: 'SERPINA13P'},
{value: 'SERPINA2', id: 'SERPINA2'},
{value: 'SERPINA3', id: 'SERPINA3'},
{value: 'SERPINA4', id: 'SERPINA4'},
{value: 'SERPINA5', id: 'SERPINA5'},
{value: 'SERPINA6', id: 'SERPINA6'},
{value: 'SERPINA7', id: 'SERPINA7'},
{value: 'SERPINA9', id: 'SERPINA9'},
{value: 'SERPINB1', id: 'SERPINB1'},
{value: 'SERPINB10', id: 'SERPINB10'},
{value: 'SERPINB11', id: 'SERPINB11'},
{value: 'SERPINB12', id: 'SERPINB12'},
{value: 'SERPINB13', id: 'SERPINB13'},
{value: 'SERPINB2', id: 'SERPINB2'},
{value: 'SERPINB4', id: 'SERPINB4'},
{value: 'SERPINB5', id: 'SERPINB5'},
{value: 'SERPINB6', id: 'SERPINB6'},
{value: 'SERPINB7', id: 'SERPINB7'},
{value: 'SERPINB8', id: 'SERPINB8'},
{value: 'SERPINB8P1', id: 'SERPINB8P1'},
{value: 'SERPINB9', id: 'SERPINB9'},
{value: 'SERPINB9P1', id: 'SERPINB9P1'},
{value: 'SERPINC1', id: 'SERPINC1'},
{value: 'SERPIND1', id: 'SERPIND1'},
{value: 'SERPINE1', id: 'SERPINE1'},
{value: 'SERPINE2', id: 'SERPINE2'},
{value: 'SERPINE3', id: 'SERPINE3'},
{value: 'SERPINF1', id: 'SERPINF1'},
{value: 'SERPINF2', id: 'SERPINF2'},
{value: 'SERPING1', id: 'SERPING1'},
{value: 'SERPINH1', id: 'SERPINH1'},
{value: 'SERPINH1P1', id: 'SERPINH1P1'},
{value: 'SERPINI1', id: 'SERPINI1'},
{value: 'SERPINI2', id: 'SERPINI2'},
{value: 'SERTAD1', id: 'SERTAD1'},
{value: 'SERTAD2', id: 'SERTAD2'},
{value: 'SERTAD3', id: 'SERTAD3'},
{value: 'SERTAD3-AS1', id: 'SERTAD3-AS1'},
{value: 'SERTAD4', id: 'SERTAD4'},
{value: 'SERTAD4-AS1', id: 'SERTAD4-AS1'},
{value: 'SERTM1', id: 'SERTM1'},
{value: 'SESN1', id: 'SESN1'},
{value: 'SESN2', id: 'SESN2'},
{value: 'SESN3', id: 'SESN3'},
{value: 'SESTD1', id: 'SESTD1'},
{value: 'SET', id: 'SET'},
{value: 'SETBP1', id: 'SETBP1'},
{value: 'SETBP1-DT', id: 'SETBP1-DT'},
{value: 'SETD1A', id: 'SETD1A'},
{value: 'SETD1B', id: 'SETD1B'},
{value: 'SETD2', id: 'SETD2'},
{value: 'SETD3', id: 'SETD3'},
{value: 'SETD4', id: 'SETD4'},
{value: 'SETD4-AS1', id: 'SETD4-AS1'},
{value: 'SETD5', id: 'SETD5'},
{value: 'SETD6', id: 'SETD6'},
{value: 'SETD6P1', id: 'SETD6P1'},
{value: 'SETD7', id: 'SETD7'},
{value: 'SETD9', id: 'SETD9'},
{value: 'SETDB1', id: 'SETDB1'},
{value: 'SETDB2', id: 'SETDB2'},
{value: 'SETMAR', id: 'SETMAR'},
{value: 'SETP1', id: 'SETP1'},
{value: 'SETP14', id: 'SETP14'},
{value: 'SETP16', id: 'SETP16'},
{value: 'SETP17', id: 'SETP17'},
{value: 'SETP2', id: 'SETP2'},
{value: 'SETP20', id: 'SETP20'},
{value: 'SETP22', id: 'SETP22'},
{value: 'SETP7', id: 'SETP7'},
{value: 'SETP9', id: 'SETP9'},
{value: 'SETSIP', id: 'SETSIP'},
{value: 'SETX', id: 'SETX'},
{value: 'SEZ6', id: 'SEZ6'},
{value: 'SEZ6L', id: 'SEZ6L'},
{value: 'SEZ6L2', id: 'SEZ6L2'},
{value: 'SF1', id: 'SF1'},
{value: 'SF1-DT', id: 'SF1-DT'},
{value: 'SF3A1', id: 'SF3A1'},
{value: 'SF3A2', id: 'SF3A2'},
{value: 'SF3A3', id: 'SF3A3'},
{value: 'SF3B1', id: 'SF3B1'},
{value: 'SF3B2', id: 'SF3B2'},
{value: 'SF3B3', id: 'SF3B3'},
{value: 'SF3B4', id: 'SF3B4'},
{value: 'SF3B4P1', id: 'SF3B4P1'},
{value: 'SF3B5', id: 'SF3B5'},
{value: 'SF3B6', id: 'SF3B6'},
{value: 'SFI1', id: 'SFI1'},
{value: 'SFMBT1', id: 'SFMBT1'},
{value: 'SFMBT2', id: 'SFMBT2'},
{value: 'SFN', id: 'SFN'},
{value: 'SFPQ', id: 'SFPQ'},
{value: 'SFR1', id: 'SFR1'},
{value: 'SFRP1', id: 'SFRP1'},
{value: 'SFRP2', id: 'SFRP2'},
{value: 'SFRP4', id: 'SFRP4'},
{value: 'SFRP5', id: 'SFRP5'},
{value: 'SFSWAP', id: 'SFSWAP'},
{value: 'SFT2D1', id: 'SFT2D1'},
{value: 'SFT2D2', id: 'SFT2D2'},
{value: 'SFT2D3', id: 'SFT2D3'},
{value: 'SFTA1P', id: 'SFTA1P'},
{value: 'SFTA2', id: 'SFTA2'},
{value: 'SFTA3', id: 'SFTA3'},
{value: 'SFTPA1', id: 'SFTPA1'},
{value: 'SFTPA2', id: 'SFTPA2'},
{value: 'SFTPA3P', id: 'SFTPA3P'},
{value: 'SFTPB', id: 'SFTPB'},
{value: 'SFTPC', id: 'SFTPC'},
{value: 'SFTPD', id: 'SFTPD'},
{value: 'SFTPD-AS1', id: 'SFTPD-AS1'},
{value: 'SFXN1', id: 'SFXN1'},
{value: 'SFXN2', id: 'SFXN2'},
{value: 'SFXN3', id: 'SFXN3'},
{value: 'SFXN4', id: 'SFXN4'},
{value: 'SFXN5', id: 'SFXN5'},
{value: 'SGCA', id: 'SGCA'},
{value: 'SGCB', id: 'SGCB'},
{value: 'SGCD', id: 'SGCD'},
{value: 'SGCE', id: 'SGCE'},
{value: 'SGCG', id: 'SGCG'},
{value: 'SGCZ', id: 'SGCZ'},
{value: 'SGF29', id: 'SGF29'},
{value: 'SGIP1', id: 'SGIP1'},
{value: 'SGK1', id: 'SGK1'},
{value: 'SGK2', id: 'SGK2'},
{value: 'SGK3', id: 'SGK3'},
{value: 'SGMS1', id: 'SGMS1'},
{value: 'SGMS1-AS1', id: 'SGMS1-AS1'},
{value: 'SGMS2', id: 'SGMS2'},
{value: 'SGO1', id: 'SGO1'},
{value: 'SGO1-AS1', id: 'SGO1-AS1'},
{value: 'SGO1P1', id: 'SGO1P1'},
{value: 'SGO1P2', id: 'SGO1P2'},
{value: 'SGO2', id: 'SGO2'},
{value: 'SGPL1', id: 'SGPL1'},
{value: 'SGPP1', id: 'SGPP1'},
{value: 'SGPP2', id: 'SGPP2'},
{value: 'SGSH', id: 'SGSH'},
{value: 'SGSM1', id: 'SGSM1'},
{value: 'SGSM2', id: 'SGSM2'},
{value: 'SGSM3', id: 'SGSM3'},
{value: 'SGTA', id: 'SGTA'},
{value: 'SGTB', id: 'SGTB'},
{value: 'SH2B1', id: 'SH2B1'},
{value: 'SH2B2', id: 'SH2B2'},
{value: 'SH2B3', id: 'SH2B3'},
{value: 'SH2D1A', id: 'SH2D1A'},
{value: 'SH2D1B', id: 'SH2D1B'},
{value: 'SH2D2A', id: 'SH2D2A'},
{value: 'SH2D3A', id: 'SH2D3A'},
{value: 'SH2D3C', id: 'SH2D3C'},
{value: 'SH2D4A', id: 'SH2D4A'},
{value: 'SH2D4B', id: 'SH2D4B'},
{value: 'SH2D5', id: 'SH2D5'},
{value: 'SH2D6', id: 'SH2D6'},
{value: 'SH2D7', id: 'SH2D7'},
{value: 'SH3BGR', id: 'SH3BGR'},
{value: 'SH3BGRL', id: 'SH3BGRL'},
{value: 'SH3BGRL2', id: 'SH3BGRL2'},
{value: 'SH3BGRL3', id: 'SH3BGRL3'},
{value: 'SH3BP1', id: 'SH3BP1'},
{value: 'SH3BP2', id: 'SH3BP2'},
{value: 'SH3BP4', id: 'SH3BP4'},
{value: 'SH3BP5', id: 'SH3BP5'},
{value: 'SH3BP5-AS1', id: 'SH3BP5-AS1'},
{value: 'SH3BP5L', id: 'SH3BP5L'},
{value: 'SH3D19', id: 'SH3D19'},
{value: 'SH3D21', id: 'SH3D21'},
{value: 'SH3GL1', id: 'SH3GL1'},
{value: 'SH3GL1P1', id: 'SH3GL1P1'},
{value: 'SH3GL1P2', id: 'SH3GL1P2'},
{value: 'SH3GL1P3', id: 'SH3GL1P3'},
{value: 'SH3GL2', id: 'SH3GL2'},
{value: 'SH3GL3', id: 'SH3GL3'},
{value: 'SH3GLB1', id: 'SH3GLB1'},
{value: 'SH3GLB2', id: 'SH3GLB2'},
{value: 'SH3KBP1', id: 'SH3KBP1'},
{value: 'SH3PXD2A', id: 'SH3PXD2A'},
{value: 'SH3PXD2A-AS1', id: 'SH3PXD2A-AS1'},
{value: 'SH3PXD2B', id: 'SH3PXD2B'},
{value: 'SH3RF1', id: 'SH3RF1'},
{value: 'SH3RF2', id: 'SH3RF2'},
{value: 'SH3RF3', id: 'SH3RF3'},
{value: 'SH3RF3-AS1', id: 'SH3RF3-AS1'},
{value: 'SH3TC1', id: 'SH3TC1'},
{value: 'SH3TC2', id: 'SH3TC2'},
{value: 'SH3TC2-DT', id: 'SH3TC2-DT'},
{value: 'SH3YL1', id: 'SH3YL1'},
{value: 'SHANK1', id: 'SHANK1'},
{value: 'SHANK2', id: 'SHANK2'},
{value: 'SHANK2-AS1', id: 'SHANK2-AS1'},
{value: 'SHANK2-AS3', id: 'SHANK2-AS3'},
{value: 'SHANK3', id: 'SHANK3'},
{value: 'SHARPIN', id: 'SHARPIN'},
{value: 'SHB', id: 'SHB'},
{value: 'SHBG', id: 'SHBG'},
{value: 'SHC1', id: 'SHC1'},
{value: 'SHC1P2', id: 'SHC1P2'},
{value: 'SHC2', id: 'SHC2'},
{value: 'SHC3', id: 'SHC3'},
{value: 'SHC4', id: 'SHC4'},
{value: 'SHCBP1', id: 'SHCBP1'},
{value: 'SHCBP1L', id: 'SHCBP1L'},
{value: 'SHD', id: 'SHD'},
{value: 'SHE', id: 'SHE'},
{value: 'SHF', id: 'SHF'},
{value: 'SHFL', id: 'SHFL'},
{value: 'SHH', id: 'SHH'},
{value: 'SHISA2', id: 'SHISA2'},
{value: 'SHISA3', id: 'SHISA3'},
{value: 'SHISA4', id: 'SHISA4'},
{value: 'SHISA5', id: 'SHISA5'},
{value: 'SHISA6', id: 'SHISA6'},
{value: 'SHISA7', id: 'SHISA7'},
{value: 'SHISA8', id: 'SHISA8'},
{value: 'SHISA9', id: 'SHISA9'},
{value: 'SHISAL1', id: 'SHISAL1'},
{value: 'SHISAL2A', id: 'SHISAL2A'},
{value: 'SHISAL2B', id: 'SHISAL2B'},
{value: 'SHKBP1', id: 'SHKBP1'},
{value: 'SHLD1', id: 'SHLD1'},
{value: 'SHLD2', id: 'SHLD2'},
{value: 'SHLD2P1', id: 'SHLD2P1'},
{value: 'SHLD2P2', id: 'SHLD2P2'},
{value: 'SHLD2P3', id: 'SHLD2P3'},
{value: 'SHLD3', id: 'SHLD3'},
{value: 'SHMT1', id: 'SHMT1'},
{value: 'SHMT1P1', id: 'SHMT1P1'},
{value: 'SHMT2', id: 'SHMT2'},
{value: 'SHOC1', id: 'SHOC1'},
{value: 'SHOC2', id: 'SHOC2'},
{value: 'SHOX', id: 'SHOX'},
{value: 'SHOX2', id: 'SHOX2'},
{value: 'SHPK', id: 'SHPK'},
{value: 'SHPRH', id: 'SHPRH'},
{value: 'SHQ1', id: 'SHQ1'},
{value: 'SHQ1P1', id: 'SHQ1P1'},
{value: 'SHROOM1', id: 'SHROOM1'},
{value: 'SHROOM2', id: 'SHROOM2'},
{value: 'SHROOM3', id: 'SHROOM3'},
{value: 'SHROOM3-AS1', id: 'SHROOM3-AS1'},
{value: 'SHROOM4', id: 'SHROOM4'},
{value: 'SHTN1', id: 'SHTN1'},
{value: 'SI', id: 'SI'},
{value: 'SIAE', id: 'SIAE'},
{value: 'SIAH1', id: 'SIAH1'},
{value: 'SIAH2', id: 'SIAH2'},
{value: 'SIAH2-AS1', id: 'SIAH2-AS1'},
{value: 'SIAH3', id: 'SIAH3'},
{value: 'SIDT1', id: 'SIDT1'},
{value: 'SIDT2', id: 'SIDT2'},
{value: 'SIGIRR', id: 'SIGIRR'},
{value: 'SIGLEC1', id: 'SIGLEC1'},
{value: 'SIGLEC10', id: 'SIGLEC10'},
{value: 'SIGLEC10-AS1', id: 'SIGLEC10-AS1'},
{value: 'SIGLEC11', id: 'SIGLEC11'},
{value: 'SIGLEC12', id: 'SIGLEC12'},
{value: 'SIGLEC14', id: 'SIGLEC14'},
{value: 'SIGLEC15', id: 'SIGLEC15'},
{value: 'SIGLEC16', id: 'SIGLEC16'},
{value: 'SIGLEC17P', id: 'SIGLEC17P'},
{value: 'SIGLEC18P', id: 'SIGLEC18P'},
{value: 'SIGLEC20P', id: 'SIGLEC20P'},
{value: 'SIGLEC21P', id: 'SIGLEC21P'},
{value: 'SIGLEC22P', id: 'SIGLEC22P'},
{value: 'SIGLEC24P', id: 'SIGLEC24P'},
{value: 'SIGLEC27P', id: 'SIGLEC27P'},
{value: 'SIGLEC29P', id: 'SIGLEC29P'},
{value: 'SIGLEC30P', id: 'SIGLEC30P'},
{value: 'SIGLEC31P', id: 'SIGLEC31P'},
{value: 'SIGLEC5', id: 'SIGLEC5'},
{value: 'SIGLEC6', id: 'SIGLEC6'},
{value: 'SIGLEC7', id: 'SIGLEC7'},
{value: 'SIGLEC8', id: 'SIGLEC8'},
{value: 'SIGLEC9', id: 'SIGLEC9'},
{value: 'SIGLECL1', id: 'SIGLECL1'},
{value: 'SIGMAR1', id: 'SIGMAR1'},
{value: 'SIK1', id: 'SIK1'},
{value: 'SIK1B', id: 'SIK1B'},
{value: 'SIK2', id: 'SIK2'},
{value: 'SIK3', id: 'SIK3'},
{value: 'SIKE1', id: 'SIKE1'},
{value: 'SIL1', id: 'SIL1'},
{value: 'SILC1', id: 'SILC1'},
{value: 'SIM1', id: 'SIM1'},
{value: 'SIM1-AS1', id: 'SIM1-AS1'},
{value: 'SIM2', id: 'SIM2'},
{value: 'SIMC1', id: 'SIMC1'},
{value: 'SIN3A', id: 'SIN3A'},
{value: 'SIN3B', id: 'SIN3B'},
{value: 'SINHCAF', id: 'SINHCAF'},
{value: 'SINHCAFP1', id: 'SINHCAFP1'},
{value: 'SINHCAFP2', id: 'SINHCAFP2'},
{value: 'SINHCAFP3', id: 'SINHCAFP3'},
{value: 'SIPA1', id: 'SIPA1'},
{value: 'SIPA1L1', id: 'SIPA1L1'},
{value: 'SIPA1L1-AS1', id: 'SIPA1L1-AS1'},
{value: 'SIPA1L2', id: 'SIPA1L2'},
{value: 'SIPA1L3', id: 'SIPA1L3'},
{value: 'SIRLNT', id: 'SIRLNT'},
{value: 'SIRPA', id: 'SIRPA'},
{value: 'SIRPAP1', id: 'SIRPAP1'},
{value: 'SIRPB1', id: 'SIRPB1'},
{value: 'SIRPB2', id: 'SIRPB2'},
{value: 'SIRPB3P', id: 'SIRPB3P'},
{value: 'SIRPD', id: 'SIRPD'},
{value: 'SIRPG', id: 'SIRPG'},
{value: 'SIRPG-AS1', id: 'SIRPG-AS1'},
{value: 'SIRT1', id: 'SIRT1'},
{value: 'SIRT2', id: 'SIRT2'},
{value: 'SIRT3', id: 'SIRT3'},
{value: 'SIRT4', id: 'SIRT4'},
{value: 'SIRT5', id: 'SIRT5'},
{value: 'SIRT6', id: 'SIRT6'},
{value: 'SIRT7', id: 'SIRT7'},
{value: 'SIT1', id: 'SIT1'},
{value: 'SIVA1', id: 'SIVA1'},
{value: 'SIX1', id: 'SIX1'},
{value: 'SIX2', id: 'SIX2'},
{value: 'SIX3', id: 'SIX3'},
{value: 'SIX3-AS1', id: 'SIX3-AS1'},
{value: 'SIX4', id: 'SIX4'},
{value: 'SIX5', id: 'SIX5'},
{value: 'SIX6', id: 'SIX6'},
{value: 'SKA1', id: 'SKA1'},
{value: 'SKA2', id: 'SKA2'},
{value: 'SKA2P1', id: 'SKA2P1'},
{value: 'SKA3', id: 'SKA3'},
{value: 'SKAP1', id: 'SKAP1'},
{value: 'SKAP1-AS1', id: 'SKAP1-AS1'},
{value: 'SKAP1-AS2', id: 'SKAP1-AS2'},
{value: 'SKAP2', id: 'SKAP2'},
{value: 'SKI', id: 'SKI'},
{value: 'SKIDA1', id: 'SKIDA1'},
{value: 'SKIL', id: 'SKIL'},
{value: 'SKINT1L', id: 'SKINT1L'},
{value: 'SKIV2L', id: 'SKIV2L'},
{value: 'SKOR1', id: 'SKOR1'},
{value: 'SKOR2', id: 'SKOR2'},
{value: 'SKP1', id: 'SKP1'},
{value: 'SKP1P1', id: 'SKP1P1'},
{value: 'SKP1P2', id: 'SKP1P2'},
{value: 'SKP2', id: 'SKP2'},
{value: 'SLA', id: 'SLA'},
{value: 'SLA2', id: 'SLA2'},
{value: 'SLAIN1', id: 'SLAIN1'},
{value: 'SLAIN2', id: 'SLAIN2'},
{value: 'SLAMF1', id: 'SLAMF1'},
{value: 'SLAMF6', id: 'SLAMF6'},
{value: 'SLAMF6P1', id: 'SLAMF6P1'},
{value: 'SLAMF7', id: 'SLAMF7'},
{value: 'SLAMF8', id: 'SLAMF8'},
{value: 'SLAMF9', id: 'SLAMF9'},
{value: 'SLBP', id: 'SLBP'},
{value: 'SLC10A1', id: 'SLC10A1'},
{value: 'SLC10A2', id: 'SLC10A2'},
{value: 'SLC10A3', id: 'SLC10A3'},
{value: 'SLC10A4', id: 'SLC10A4'},
{value: 'SLC10A5', id: 'SLC10A5'},
{value: 'SLC10A5P1', id: 'SLC10A5P1'},
{value: 'SLC10A6', id: 'SLC10A6'},
{value: 'SLC10A7', id: 'SLC10A7'},
{value: 'SLC11A1', id: 'SLC11A1'},
{value: 'SLC11A2', id: 'SLC11A2'},
{value: 'SLC12A1', id: 'SLC12A1'},
{value: 'SLC12A2', id: 'SLC12A2'},
{value: 'SLC12A3', id: 'SLC12A3'},
{value: 'SLC12A4', id: 'SLC12A4'},
{value: 'SLC12A5', id: 'SLC12A5'},
{value: 'SLC12A5-AS1', id: 'SLC12A5-AS1'},
{value: 'SLC12A6', id: 'SLC12A6'},
{value: 'SLC12A7', id: 'SLC12A7'},
{value: 'SLC12A8', id: 'SLC12A8'},
{value: 'SLC12A9', id: 'SLC12A9'},
{value: 'SLC12A9-AS1', id: 'SLC12A9-AS1'},
{value: 'SLC13A1', id: 'SLC13A1'},
{value: 'SLC13A2', id: 'SLC13A2'},
{value: 'SLC13A3', id: 'SLC13A3'},
{value: 'SLC13A4', id: 'SLC13A4'},
{value: 'SLC13A5', id: 'SLC13A5'},
{value: 'SLC14A1', id: 'SLC14A1'},
{value: 'SLC14A2', id: 'SLC14A2'},
{value: 'SLC15A1', id: 'SLC15A1'},
{value: 'SLC15A2', id: 'SLC15A2'},
{value: 'SLC15A3', id: 'SLC15A3'},
{value: 'SLC15A4', id: 'SLC15A4'},
{value: 'SLC15A5', id: 'SLC15A5'},
{value: 'SLC16A1', id: 'SLC16A1'},
{value: 'SLC16A1-AS1', id: 'SLC16A1-AS1'},
{value: 'SLC16A10', id: 'SLC16A10'},
{value: 'SLC16A11', id: 'SLC16A11'},
{value: 'SLC16A12', id: 'SLC16A12'},
{value: 'SLC16A13', id: 'SLC16A13'},
{value: 'SLC16A14', id: 'SLC16A14'},
{value: 'SLC16A14P1', id: 'SLC16A14P1'},
{value: 'SLC16A1P1', id: 'SLC16A1P1'},
{value: 'SLC16A2', id: 'SLC16A2'},
{value: 'SLC16A3', id: 'SLC16A3'},
{value: 'SLC16A4', id: 'SLC16A4'},
{value: 'SLC16A5', id: 'SLC16A5'},
{value: 'SLC16A6', id: 'SLC16A6'},
{value: 'SLC16A6P1', id: 'SLC16A6P1'},
{value: 'SLC16A7', id: 'SLC16A7'},
{value: 'SLC16A8', id: 'SLC16A8'},
{value: 'SLC16A9', id: 'SLC16A9'},
{value: 'SLC17A1', id: 'SLC17A1'},
{value: 'SLC17A2', id: 'SLC17A2'},
{value: 'SLC17A3', id: 'SLC17A3'},
{value: 'SLC17A4', id: 'SLC17A4'},
{value: 'SLC17A5', id: 'SLC17A5'},
{value: 'SLC17A6', id: 'SLC17A6'},
{value: 'SLC17A6-DT', id: 'SLC17A6-DT'},
{value: 'SLC17A7', id: 'SLC17A7'},
{value: 'SLC17A8', id: 'SLC17A8'},
{value: 'SLC17A9', id: 'SLC17A9'},
{value: 'SLC18A1', id: 'SLC18A1'},
{value: 'SLC18A2', id: 'SLC18A2'},
{value: 'SLC18B1', id: 'SLC18B1'},
{value: 'SLC19A1', id: 'SLC19A1'},
{value: 'SLC19A2', id: 'SLC19A2'},
{value: 'SLC19A3', id: 'SLC19A3'},
{value: 'SLC1A1', id: 'SLC1A1'},
{value: 'SLC1A2', id: 'SLC1A2'},
{value: 'SLC1A2-AS1', id: 'SLC1A2-AS1'},
{value: 'SLC1A3', id: 'SLC1A3'},
{value: 'SLC1A4', id: 'SLC1A4'},
{value: 'SLC1A5', id: 'SLC1A5'},
{value: 'SLC1A6', id: 'SLC1A6'},
{value: 'SLC1A7', id: 'SLC1A7'},
{value: 'SLC20A1', id: 'SLC20A1'},
{value: 'SLC20A1P1', id: 'SLC20A1P1'},
{value: 'SLC20A1P2', id: 'SLC20A1P2'},
{value: 'SLC20A1P3', id: 'SLC20A1P3'},
{value: 'SLC20A2', id: 'SLC20A2'},
{value: 'SLC22A1', id: 'SLC22A1'},
{value: 'SLC22A10', id: 'SLC22A10'},
{value: 'SLC22A11', id: 'SLC22A11'},
{value: 'SLC22A12', id: 'SLC22A12'},
{value: 'SLC22A13', id: 'SLC22A13'},
{value: 'SLC22A14', id: 'SLC22A14'},
{value: 'SLC22A15', id: 'SLC22A15'},
{value: 'SLC22A16', id: 'SLC22A16'},
{value: 'SLC22A17', id: 'SLC22A17'},
{value: 'SLC22A18', id: 'SLC22A18'},
{value: 'SLC22A18AS', id: 'SLC22A18AS'},
{value: 'SLC22A2', id: 'SLC22A2'},
{value: 'SLC22A20P', id: 'SLC22A20P'},
{value: 'SLC22A23', id: 'SLC22A23'},
{value: 'SLC22A24', id: 'SLC22A24'},
{value: 'SLC22A25', id: 'SLC22A25'},
{value: 'SLC22A3', id: 'SLC22A3'},
{value: 'SLC22A31', id: 'SLC22A31'},
{value: 'SLC22A4', id: 'SLC22A4'},
{value: 'SLC22A5', id: 'SLC22A5'},
{value: 'SLC22A6', id: 'SLC22A6'},
{value: 'SLC22A7', id: 'SLC22A7'},
{value: 'SLC22A8', id: 'SLC22A8'},
{value: 'SLC22A9', id: 'SLC22A9'},
{value: 'SLC23A1', id: 'SLC23A1'},
{value: 'SLC23A2', id: 'SLC23A2'},
{value: 'SLC23A3', id: 'SLC23A3'},
{value: 'SLC23A4P', id: 'SLC23A4P'},
{value: 'SLC24A1', id: 'SLC24A1'},
{value: 'SLC24A2', id: 'SLC24A2'},
{value: 'SLC24A3', id: 'SLC24A3'},
{value: 'SLC24A3-AS1', id: 'SLC24A3-AS1'},
{value: 'SLC24A4', id: 'SLC24A4'},
{value: 'SLC24A5', id: 'SLC24A5'},
{value: 'SLC25A1', id: 'SLC25A1'},
{value: 'SLC25A10', id: 'SLC25A10'},
{value: 'SLC25A11', id: 'SLC25A11'},
{value: 'SLC25A12', id: 'SLC25A12'},
{value: 'SLC25A13', id: 'SLC25A13'},
{value: 'SLC25A14', id: 'SLC25A14'},
{value: 'SLC25A14P1', id: 'SLC25A14P1'},
{value: 'SLC25A15', id: 'SLC25A15'},
{value: 'SLC25A15P2', id: 'SLC25A15P2'},
{value: 'SLC25A15P4', id: 'SLC25A15P4'},
{value: 'SLC25A16', id: 'SLC25A16'},
{value: 'SLC25A17', id: 'SLC25A17'},
{value: 'SLC25A18', id: 'SLC25A18'},
{value: 'SLC25A18P1', id: 'SLC25A18P1'},
{value: 'SLC25A19', id: 'SLC25A19'},
{value: 'SLC25A1P1', id: 'SLC25A1P1'},
{value: 'SLC25A1P2', id: 'SLC25A1P2'},
{value: 'SLC25A1P3', id: 'SLC25A1P3'},
{value: 'SLC25A1P4', id: 'SLC25A1P4'},
{value: 'SLC25A1P5', id: 'SLC25A1P5'},
{value: 'SLC25A2', id: 'SLC25A2'},
{value: 'SLC25A20', id: 'SLC25A20'},
{value: 'SLC25A20P1', id: 'SLC25A20P1'},
{value: 'SLC25A21', id: 'SLC25A21'},
{value: 'SLC25A21-AS1', id: 'SLC25A21-AS1'},
{value: 'SLC25A22', id: 'SLC25A22'},
{value: 'SLC25A23', id: 'SLC25A23'},
{value: 'SLC25A24', id: 'SLC25A24'},
{value: 'SLC25A24P1', id: 'SLC25A24P1'},
{value: 'SLC25A24P2', id: 'SLC25A24P2'},
{value: 'SLC25A25', id: 'SLC25A25'},
{value: 'SLC25A25-AS1', id: 'SLC25A25-AS1'},
{value: 'SLC25A26', id: 'SLC25A26'},
{value: 'SLC25A27', id: 'SLC25A27'},
{value: 'SLC25A28', id: 'SLC25A28'},
{value: 'SLC25A29', id: 'SLC25A29'},
{value: 'SLC25A3', id: 'SLC25A3'},
{value: 'SLC25A30', id: 'SLC25A30'},
{value: 'SLC25A30-AS1', id: 'SLC25A30-AS1'},
{value: 'SLC25A31', id: 'SLC25A31'},
{value: 'SLC25A32', id: 'SLC25A32'},
{value: 'SLC25A33', id: 'SLC25A33'},
{value: 'SLC25A34', id: 'SLC25A34'},
{value: 'SLC25A34-AS1', id: 'SLC25A34-AS1'},
{value: 'SLC25A35', id: 'SLC25A35'},
{value: 'SLC25A36', id: 'SLC25A36'},
{value: 'SLC25A37', id: 'SLC25A37'},
{value: 'SLC25A38', id: 'SLC25A38'},
{value: 'SLC25A38P1', id: 'SLC25A38P1'},
{value: 'SLC25A39', id: 'SLC25A39'},
{value: 'SLC25A39P1', id: 'SLC25A39P1'},
{value: 'SLC25A39P2', id: 'SLC25A39P2'},
{value: 'SLC25A3P1', id: 'SLC25A3P1'},
{value: 'SLC25A3P2', id: 'SLC25A3P2'},
{value: 'SLC25A4', id: 'SLC25A4'},
{value: 'SLC25A40', id: 'SLC25A40'},
{value: 'SLC25A41', id: 'SLC25A41'},
{value: 'SLC25A42', id: 'SLC25A42'},
{value: 'SLC25A43', id: 'SLC25A43'},
{value: 'SLC25A44', id: 'SLC25A44'},
{value: 'SLC25A45', id: 'SLC25A45'},
{value: 'SLC25A46', id: 'SLC25A46'},
{value: 'SLC25A47', id: 'SLC25A47'},
{value: 'SLC25A47P1', id: 'SLC25A47P1'},
{value: 'SLC25A48', id: 'SLC25A48'},
{value: 'SLC25A48-AS1', id: 'SLC25A48-AS1'},
{value: 'SLC25A5', id: 'SLC25A5'},
{value: 'SLC25A5-AS1', id: 'SLC25A5-AS1'},
{value: 'SLC25A51', id: 'SLC25A51'},
{value: 'SLC25A51P1', id: 'SLC25A51P1'},
{value: 'SLC25A51P2', id: 'SLC25A51P2'},
{value: 'SLC25A51P3', id: 'SLC25A51P3'},
{value: 'SLC25A51P4', id: 'SLC25A51P4'},
{value: 'SLC25A52', id: 'SLC25A52'},
{value: 'SLC25A53', id: 'SLC25A53'},
{value: 'SLC25A5P1', id: 'SLC25A5P1'},
{value: 'SLC25A5P2', id: 'SLC25A5P2'},
{value: 'SLC25A5P3', id: 'SLC25A5P3'},
{value: 'SLC25A5P4', id: 'SLC25A5P4'},
{value: 'SLC25A5P5', id: 'SLC25A5P5'},
{value: 'SLC25A5P6', id: 'SLC25A5P6'},
{value: 'SLC25A5P7', id: 'SLC25A5P7'},
{value: 'SLC25A5P8', id: 'SLC25A5P8'},
{value: 'SLC25A5P9', id: 'SLC25A5P9'},
{value: 'SLC25A6', id: 'SLC25A6'},
{value: 'SLC25A6P1', id: 'SLC25A6P1'},
{value: 'SLC25A6P2', id: 'SLC25A6P2'},
{value: 'SLC25A6P3', id: 'SLC25A6P3'},
{value: 'SLC25A6P4', id: 'SLC25A6P4'},
{value: 'SLC25A6P5', id: 'SLC25A6P5'},
{value: 'SLC25A6P6', id: 'SLC25A6P6'},
{value: 'SLC26A1', id: 'SLC26A1'},
{value: 'SLC26A10', id: 'SLC26A10'},
{value: 'SLC26A11', id: 'SLC26A11'},
{value: 'SLC26A2', id: 'SLC26A2'},
{value: 'SLC26A3', id: 'SLC26A3'},
{value: 'SLC26A4', id: 'SLC26A4'},
{value: 'SLC26A4-AS1', id: 'SLC26A4-AS1'},
{value: 'SLC26A5', id: 'SLC26A5'},
{value: 'SLC26A6', id: 'SLC26A6'},
{value: 'SLC26A7', id: 'SLC26A7'},
{value: 'SLC26A8', id: 'SLC26A8'},
{value: 'SLC26A9', id: 'SLC26A9'},
{value: 'SLC27A1', id: 'SLC27A1'},
{value: 'SLC27A2', id: 'SLC27A2'},
{value: 'SLC27A3', id: 'SLC27A3'},
{value: 'SLC27A4', id: 'SLC27A4'},
{value: 'SLC27A5', id: 'SLC27A5'},
{value: 'SLC27A6', id: 'SLC27A6'},
{value: 'SLC28A1', id: 'SLC28A1'},
{value: 'SLC28A2', id: 'SLC28A2'},
{value: 'SLC28A2-AS1', id: 'SLC28A2-AS1'},
{value: 'SLC28A3', id: 'SLC28A3'},
{value: 'SLC29A1', id: 'SLC29A1'},
{value: 'SLC29A2', id: 'SLC29A2'},
{value: 'SLC29A3', id: 'SLC29A3'},
{value: 'SLC29A4', id: 'SLC29A4'},
{value: 'SLC29A4P1', id: 'SLC29A4P1'},
{value: 'SLC29A4P2', id: 'SLC29A4P2'},
{value: 'SLC2A1', id: 'SLC2A1'},
{value: 'SLC2A1-AS1', id: 'SLC2A1-AS1'},
{value: 'SLC2A10', id: 'SLC2A10'},
{value: 'SLC2A11', id: 'SLC2A11'},
{value: 'SLC2A12', id: 'SLC2A12'},
{value: 'SLC2A13', id: 'SLC2A13'},
{value: 'SLC2A13P1', id: 'SLC2A13P1'},
{value: 'SLC2A14', id: 'SLC2A14'},
{value: 'SLC2A2', id: 'SLC2A2'},
{value: 'SLC2A3', id: 'SLC2A3'},
{value: 'SLC2A3P1', id: 'SLC2A3P1'},
{value: 'SLC2A3P2', id: 'SLC2A3P2'},
{value: 'SLC2A3P4', id: 'SLC2A3P4'},
{value: 'SLC2A4', id: 'SLC2A4'},
{value: 'SLC2A4RG', id: 'SLC2A4RG'},
{value: 'SLC2A5', id: 'SLC2A5'},
{value: 'SLC2A6', id: 'SLC2A6'},
{value: 'SLC2A7', id: 'SLC2A7'},
{value: 'SLC2A8', id: 'SLC2A8'},
{value: 'SLC2A9', id: 'SLC2A9'},
{value: 'SLC2A9-AS1', id: 'SLC2A9-AS1'},
{value: 'SLC2AXP1', id: 'SLC2AXP1'},
{value: 'SLC30A1', id: 'SLC30A1'},
{value: 'SLC30A10', id: 'SLC30A10'},
{value: 'SLC30A2', id: 'SLC30A2'},
{value: 'SLC30A3', id: 'SLC30A3'},
{value: 'SLC30A4', id: 'SLC30A4'},
{value: 'SLC30A5', id: 'SLC30A5'},
{value: 'SLC30A6', id: 'SLC30A6'},
{value: 'SLC30A6-DT', id: 'SLC30A6-DT'},
{value: 'SLC30A7', id: 'SLC30A7'},
{value: 'SLC30A8', id: 'SLC30A8'},
{value: 'SLC30A9', id: 'SLC30A9'},
{value: 'SLC31A1', id: 'SLC31A1'},
{value: 'SLC31A1P1', id: 'SLC31A1P1'},
{value: 'SLC31A2', id: 'SLC31A2'},
{value: 'SLC32A1', id: 'SLC32A1'},
{value: 'SLC33A1', id: 'SLC33A1'},
{value: 'SLC34A1', id: 'SLC34A1'},
{value: 'SLC34A2', id: 'SLC34A2'},
{value: 'SLC34A3', id: 'SLC34A3'},
{value: 'SLC35A1', id: 'SLC35A1'},
{value: 'SLC35A2', id: 'SLC35A2'},
{value: 'SLC35A3', id: 'SLC35A3'},
{value: 'SLC35A4', id: 'SLC35A4'},
{value: 'SLC35A5', id: 'SLC35A5'},
{value: 'SLC35B1', id: 'SLC35B1'},
{value: 'SLC35B2', id: 'SLC35B2'},
{value: 'SLC35B3', id: 'SLC35B3'},
{value: 'SLC35B4', id: 'SLC35B4'},
{value: 'SLC35C1', id: 'SLC35C1'},
{value: 'SLC35C2', id: 'SLC35C2'},
{value: 'SLC35D1', id: 'SLC35D1'},
{value: 'SLC35D2', id: 'SLC35D2'},
{value: 'SLC35D3', id: 'SLC35D3'},
{value: 'SLC35E1', id: 'SLC35E1'},
{value: 'SLC35E1P1', id: 'SLC35E1P1'},
{value: 'SLC35E2A', id: 'SLC35E2A'},
{value: 'SLC35E2B', id: 'SLC35E2B'},
{value: 'SLC35E3', id: 'SLC35E3'},
{value: 'SLC35E4', id: 'SLC35E4'},
{value: 'SLC35F1', id: 'SLC35F1'},
{value: 'SLC35F2', id: 'SLC35F2'},
{value: 'SLC35F3', id: 'SLC35F3'},
{value: 'SLC35F3-AS1', id: 'SLC35F3-AS1'},
{value: 'SLC35F4', id: 'SLC35F4'},
{value: 'SLC35F5', id: 'SLC35F5'},
{value: 'SLC35F6', id: 'SLC35F6'},
{value: 'SLC35G1', id: 'SLC35G1'},
{value: 'SLC35G2', id: 'SLC35G2'},
{value: 'SLC35G3', id: 'SLC35G3'},
{value: 'SLC35G4', id: 'SLC35G4'},
{value: 'SLC35G5', id: 'SLC35G5'},
{value: 'SLC35G6', id: 'SLC35G6'},
{value: 'SLC36A1', id: 'SLC36A1'},
{value: 'SLC36A2', id: 'SLC36A2'},
{value: 'SLC36A3', id: 'SLC36A3'},
{value: 'SLC36A4', id: 'SLC36A4'},
{value: 'SLC37A1', id: 'SLC37A1'},
{value: 'SLC37A2', id: 'SLC37A2'},
{value: 'SLC37A3', id: 'SLC37A3'},
{value: 'SLC37A4', id: 'SLC37A4'},
{value: 'SLC38A1', id: 'SLC38A1'},
{value: 'SLC38A10', id: 'SLC38A10'},
{value: 'SLC38A11', id: 'SLC38A11'},
{value: 'SLC38A2', id: 'SLC38A2'},
{value: 'SLC38A2-AS1', id: 'SLC38A2-AS1'},
{value: 'SLC38A3', id: 'SLC38A3'},
{value: 'SLC38A4', id: 'SLC38A4'},
{value: 'SLC38A5', id: 'SLC38A5'},
{value: 'SLC38A6', id: 'SLC38A6'},
{value: 'SLC38A7', id: 'SLC38A7'},
{value: 'SLC38A8', id: 'SLC38A8'},
{value: 'SLC38A9', id: 'SLC38A9'},
{value: 'SLC39A1', id: 'SLC39A1'},
{value: 'SLC39A10', id: 'SLC39A10'},
{value: 'SLC39A11', id: 'SLC39A11'},
{value: 'SLC39A12', id: 'SLC39A12'},
{value: 'SLC39A12-AS1', id: 'SLC39A12-AS1'},
{value: 'SLC39A13', id: 'SLC39A13'},
{value: 'SLC39A14', id: 'SLC39A14'},
{value: 'SLC39A2', id: 'SLC39A2'},
{value: 'SLC39A3', id: 'SLC39A3'},
{value: 'SLC39A4', id: 'SLC39A4'},
{value: 'SLC39A5', id: 'SLC39A5'},
{value: 'SLC39A6', id: 'SLC39A6'},
{value: 'SLC39A7', id: 'SLC39A7'},
{value: 'SLC39A8', id: 'SLC39A8'},
{value: 'SLC39A9', id: 'SLC39A9'},
{value: 'SLC3A1', id: 'SLC3A1'},
{value: 'SLC3A2', id: 'SLC3A2'},
{value: 'SLC40A1', id: 'SLC40A1'},
{value: 'SLC41A1', id: 'SLC41A1'},
{value: 'SLC41A2', id: 'SLC41A2'},
{value: 'SLC41A3', id: 'SLC41A3'},
{value: 'SLC41A3-AS1', id: 'SLC41A3-AS1'},
{value: 'SLC43A1', id: 'SLC43A1'},
{value: 'SLC43A2', id: 'SLC43A2'},
{value: 'SLC43A3', id: 'SLC43A3'},
{value: 'SLC44A1', id: 'SLC44A1'},
{value: 'SLC44A2', id: 'SLC44A2'},
{value: 'SLC44A3', id: 'SLC44A3'},
{value: 'SLC44A3-AS1', id: 'SLC44A3-AS1'},
{value: 'SLC44A3P1', id: 'SLC44A3P1'},
{value: 'SLC44A4', id: 'SLC44A4'},
{value: 'SLC44A5', id: 'SLC44A5'},
{value: 'SLC45A1', id: 'SLC45A1'},
{value: 'SLC45A2', id: 'SLC45A2'},
{value: 'SLC45A3', id: 'SLC45A3'},
{value: 'SLC45A4', id: 'SLC45A4'},
{value: 'SLC46A1', id: 'SLC46A1'},
{value: 'SLC46A2', id: 'SLC46A2'},
{value: 'SLC46A3', id: 'SLC46A3'},
{value: 'SLC47A1', id: 'SLC47A1'},
{value: 'SLC47A1P1', id: 'SLC47A1P1'},
{value: 'SLC47A1P2', id: 'SLC47A1P2'},
{value: 'SLC47A2', id: 'SLC47A2'},
{value: 'SLC48A1', id: 'SLC48A1'},
{value: 'SLC49A3', id: 'SLC49A3'},
{value: 'SLC49A4', id: 'SLC49A4'},
{value: 'SLC4A1', id: 'SLC4A1'},
{value: 'SLC4A10', id: 'SLC4A10'},
{value: 'SLC4A11', id: 'SLC4A11'},
{value: 'SLC4A1AP', id: 'SLC4A1AP'},
{value: 'SLC4A1APP1', id: 'SLC4A1APP1'},
{value: 'SLC4A1APP2', id: 'SLC4A1APP2'},
{value: 'SLC4A2', id: 'SLC4A2'},
{value: 'SLC4A3', id: 'SLC4A3'},
{value: 'SLC4A4', id: 'SLC4A4'},
{value: 'SLC4A5', id: 'SLC4A5'},
{value: 'SLC4A7', id: 'SLC4A7'},
{value: 'SLC4A8', id: 'SLC4A8'},
{value: 'SLC4A8-AS1', id: 'SLC4A8-AS1'},
{value: 'SLC4A9', id: 'SLC4A9'},
{value: 'SLC50A1', id: 'SLC50A1'},
{value: 'SLC51A', id: 'SLC51A'},
{value: 'SLC51B', id: 'SLC51B'},
{value: 'SLC52A1', id: 'SLC52A1'},
{value: 'SLC52A2', id: 'SLC52A2'},
{value: 'SLC52A3', id: 'SLC52A3'},
{value: 'SLC5A1', id: 'SLC5A1'},
{value: 'SLC5A10', id: 'SLC5A10'},
{value: 'SLC5A11', id: 'SLC5A11'},
{value: 'SLC5A12', id: 'SLC5A12'},
{value: 'SLC5A2', id: 'SLC5A2'},
{value: 'SLC5A3', id: 'SLC5A3'},
{value: 'SLC5A4', id: 'SLC5A4'},
{value: 'SLC5A4-AS1', id: 'SLC5A4-AS1'},
{value: 'SLC5A4P1', id: 'SLC5A4P1'},
{value: 'SLC5A5', id: 'SLC5A5'},
{value: 'SLC5A6', id: 'SLC5A6'},
{value: 'SLC5A7', id: 'SLC5A7'},
{value: 'SLC5A8', id: 'SLC5A8'},
{value: 'SLC5A9', id: 'SLC5A9'},
{value: 'SLC66A1', id: 'SLC66A1'},
{value: 'SLC66A1L', id: 'SLC66A1L'},
{value: 'SLC66A2', id: 'SLC66A2'},
{value: 'SLC66A2P1', id: 'SLC66A2P1'},
{value: 'SLC66A3', id: 'SLC66A3'},
{value: 'SLC6A1', id: 'SLC6A1'},
{value: 'SLC6A10P', id: 'SLC6A10P'},
{value: 'SLC6A11', id: 'SLC6A11'},
{value: 'SLC6A12', id: 'SLC6A12'},
{value: 'SLC6A12-AS1', id: 'SLC6A12-AS1'},
{value: 'SLC6A13', id: 'SLC6A13'},
{value: 'SLC6A14P3', id: 'SLC6A14P3'},
{value: 'SLC6A15', id: 'SLC6A15'},
{value: 'SLC6A16', id: 'SLC6A16'},
{value: 'SLC6A17', id: 'SLC6A17'},
{value: 'SLC6A17-AS1', id: 'SLC6A17-AS1'},
{value: 'SLC6A18', id: 'SLC6A18'},
{value: 'SLC6A19', id: 'SLC6A19'},
{value: 'SLC6A2', id: 'SLC6A2'},
{value: 'SLC6A20', id: 'SLC6A20'},
{value: 'SLC6A21P', id: 'SLC6A21P'},
{value: 'SLC6A3', id: 'SLC6A3'},
{value: 'SLC6A4', id: 'SLC6A4'},
{value: 'SLC6A5', id: 'SLC6A5'},
{value: 'SLC6A6', id: 'SLC6A6'},
{value: 'SLC6A6P1', id: 'SLC6A6P1'},
{value: 'SLC6A7', id: 'SLC6A7'},
{value: 'SLC6A8', id: 'SLC6A8'},
{value: 'SLC6A9', id: 'SLC6A9'},
{value: 'SLC7A1', id: 'SLC7A1'},
{value: 'SLC7A10', id: 'SLC7A10'},
{value: 'SLC7A11', id: 'SLC7A11'},
{value: 'SLC7A11-AS1', id: 'SLC7A11-AS1'},
{value: 'SLC7A13', id: 'SLC7A13'},
{value: 'SLC7A14', id: 'SLC7A14'},
{value: 'SLC7A14-AS1', id: 'SLC7A14-AS1'},
{value: 'SLC7A15P', id: 'SLC7A15P'},
{value: 'SLC7A2', id: 'SLC7A2'},
{value: 'SLC7A4', id: 'SLC7A4'},
{value: 'SLC7A5', id: 'SLC7A5'},
{value: 'SLC7A5P1', id: 'SLC7A5P1'},
{value: 'SLC7A5P2', id: 'SLC7A5P2'},
{value: 'SLC7A6', id: 'SLC7A6'},
{value: 'SLC7A6OS', id: 'SLC7A6OS'},
{value: 'SLC7A7', id: 'SLC7A7'},
{value: 'SLC7A8', id: 'SLC7A8'},
{value: 'SLC7A9', id: 'SLC7A9'},
{value: 'SLC8A1', id: 'SLC8A1'},
{value: 'SLC8A1-AS1', id: 'SLC8A1-AS1'},
{value: 'SLC8A2', id: 'SLC8A2'},
{value: 'SLC8A3', id: 'SLC8A3'},
{value: 'SLC8B1', id: 'SLC8B1'},
{value: 'SLC9A1', id: 'SLC9A1'},
{value: 'SLC9A2', id: 'SLC9A2'},
{value: 'SLC9A3', id: 'SLC9A3'},
{value: 'SLC9A3-AS1', id: 'SLC9A3-AS1'},
{value: 'SLC9A3P1', id: 'SLC9A3P1'},
{value: 'SLC9A3P2', id: 'SLC9A3P2'},
{value: 'SLC9A3P3', id: 'SLC9A3P3'},
{value: 'SLC9A3R1', id: 'SLC9A3R1'},
{value: 'SLC9A3R1-AS1', id: 'SLC9A3R1-AS1'},
{value: 'SLC9A3R2', id: 'SLC9A3R2'},
{value: 'SLC9A4', id: 'SLC9A4'},
{value: 'SLC9A5', id: 'SLC9A5'},
{value: 'SLC9A6', id: 'SLC9A6'},
{value: 'SLC9A7', id: 'SLC9A7'},
{value: 'SLC9A7P1', id: 'SLC9A7P1'},
{value: 'SLC9A8', id: 'SLC9A8'},
{value: 'SLC9A9', id: 'SLC9A9'},
{value: 'SLC9B1', id: 'SLC9B1'},
{value: 'SLC9B1P2', id: 'SLC9B1P2'},
{value: 'SLC9B1P3', id: 'SLC9B1P3'},
{value: 'SLC9B2', id: 'SLC9B2'},
{value: 'SLC9C1', id: 'SLC9C1'},
{value: 'SLC9C2', id: 'SLC9C2'},
{value: 'SLCO1A2', id: 'SLCO1A2'},
{value: 'SLCO1B1', id: 'SLCO1B1'},
{value: 'SLCO1B3', id: 'SLCO1B3'},
{value: 'SLCO1B3-SLCO1B7', id: 'SLCO1B3-SLCO1B7'},
{value: 'SLCO1B7', id: 'SLCO1B7'},
{value: 'SLCO1C1', id: 'SLCO1C1'},
{value: 'SLCO2A1', id: 'SLCO2A1'},
{value: 'SLCO2B1', id: 'SLCO2B1'},
{value: 'SLCO3A1', id: 'SLCO3A1'},
{value: 'SLCO4A1', id: 'SLCO4A1'},
{value: 'SLCO4C1', id: 'SLCO4C1'},
{value: 'SLCO5A1', id: 'SLCO5A1'},
{value: 'SLCO5A1-AS1', id: 'SLCO5A1-AS1'},
{value: 'SLCO6A1', id: 'SLCO6A1'},
{value: 'SLED1', id: 'SLED1'},
{value: 'SLF1', id: 'SLF1'},
{value: 'SLF2', id: 'SLF2'},
{value: 'SLFN11', id: 'SLFN11'},
{value: 'SLFN12', id: 'SLFN12'},
{value: 'SLFN12L', id: 'SLFN12L'},
{value: 'SLFN13', id: 'SLFN13'},
{value: 'SLFN14', id: 'SLFN14'},
{value: 'SLFN5', id: 'SLFN5'},
{value: 'SLFNL1', id: 'SLFNL1'},
{value: 'SLFNL1-AS1', id: 'SLFNL1-AS1'},
{value: 'SLIRP', id: 'SLIRP'},
{value: 'SLIRPP2', id: 'SLIRPP2'},
{value: 'SLIT1', id: 'SLIT1'},
{value: 'SLIT1-AS1', id: 'SLIT1-AS1'},
{value: 'SLIT2', id: 'SLIT2'},
{value: 'SLIT2-IT1', id: 'SLIT2-IT1'},
{value: 'SLIT3', id: 'SLIT3'},
{value: 'SLITRK1', id: 'SLITRK1'},
{value: 'SLITRK2', id: 'SLITRK2'},
{value: 'SLITRK3', id: 'SLITRK3'},
{value: 'SLITRK4', id: 'SLITRK4'},
{value: 'SLITRK5', id: 'SLITRK5'},
{value: 'SLITRK6', id: 'SLITRK6'},
{value: 'SLK', id: 'SLK'},
{value: 'SLMAP', id: 'SLMAP'},
{value: 'SLN', id: 'SLN'},
{value: 'SLPI', id: 'SLPI'},
{value: 'SLTM', id: 'SLTM'},
{value: 'SLU7', id: 'SLU7'},
{value: 'SLURP1', id: 'SLURP1'},
{value: 'SLURP2', id: 'SLURP2'},
{value: 'SLX1A', id: 'SLX1A'},
{value: 'SLX1A-SULT1A3', id: 'SLX1A-SULT1A3'},
{value: 'SLX1B', id: 'SLX1B'},
{value: 'SLX1B-SULT1A4', id: 'SLX1B-SULT1A4'},
{value: 'SLX4', id: 'SLX4'},
{value: 'SLX4IP', id: 'SLX4IP'},
{value: 'SLX9', id: 'SLX9'},
{value: 'SMAD1', id: 'SMAD1'},
{value: 'SMAD2', id: 'SMAD2'},
{value: 'SMAD3', id: 'SMAD3'},
{value: 'SMAD4', id: 'SMAD4'},
{value: 'SMAD5', id: 'SMAD5'},
{value: 'SMAD5-AS1', id: 'SMAD5-AS1'},
{value: 'SMAD6', id: 'SMAD6'},
{value: 'SMAD7', id: 'SMAD7'},
{value: 'SMAD9', id: 'SMAD9'},
{value: 'SMAD9-IT1', id: 'SMAD9-IT1'},
{value: 'SMAGP', id: 'SMAGP'},
{value: 'SMAP1', id: 'SMAP1'},
{value: 'SMAP2', id: 'SMAP2'},
{value: 'SMARCA1', id: 'SMARCA1'},
{value: 'SMARCA2', id: 'SMARCA2'},
{value: 'SMARCA4', id: 'SMARCA4'},
{value: 'SMARCA5', id: 'SMARCA5'},
{value: 'SMARCA5-AS1', id: 'SMARCA5-AS1'},
{value: 'SMARCAD1', id: 'SMARCAD1'},
{value: 'SMARCAD1-DT', id: 'SMARCAD1-DT'},
{value: 'SMARCAL1', id: 'SMARCAL1'},
{value: 'SMARCAL1-AS1', id: 'SMARCAL1-AS1'},
{value: 'SMARCB1', id: 'SMARCB1'},
{value: 'SMARCC1', id: 'SMARCC1'},
{value: 'SMARCC2', id: 'SMARCC2'},
{value: 'SMARCD1', id: 'SMARCD1'},
{value: 'SMARCD2', id: 'SMARCD2'},
{value: 'SMARCD3', id: 'SMARCD3'},
{value: 'SMARCE1', id: 'SMARCE1'},
{value: 'SMARCE1P1', id: 'SMARCE1P1'},
{value: 'SMARCE1P2', id: 'SMARCE1P2'},
{value: 'SMARCE1P3', id: 'SMARCE1P3'},
{value: 'SMARCE1P4', id: 'SMARCE1P4'},
{value: 'SMARCE1P5', id: 'SMARCE1P5'},
{value: 'SMARCE1P6', id: 'SMARCE1P6'},
{value: 'SMARCE1P7', id: 'SMARCE1P7'},
{value: 'SMBD1P', id: 'SMBD1P'},
{value: 'SMC1A', id: 'SMC1A'},
{value: 'SMC1B', id: 'SMC1B'},
{value: 'SMC2', id: 'SMC2'},
{value: 'SMC2-DT', id: 'SMC2-DT'},
{value: 'SMC3', id: 'SMC3'},
{value: 'SMC3P1', id: 'SMC3P1'},
{value: 'SMC4', id: 'SMC4'},
{value: 'SMC4P1', id: 'SMC4P1'},
{value: 'SMC5', id: 'SMC5'},
{value: 'SMC5-DT', id: 'SMC5-DT'},
{value: 'SMC6', id: 'SMC6'},
{value: 'SMCHD1', id: 'SMCHD1'},
{value: 'SMCO1', id: 'SMCO1'},
{value: 'SMCO2', id: 'SMCO2'},
{value: 'SMCO4', id: 'SMCO4'},
{value: 'SMCO4P1', id: 'SMCO4P1'},
{value: 'SMCP', id: 'SMCP'},
{value: 'SMCR2', id: 'SMCR2'},
{value: 'SMCR5', id: 'SMCR5'},
{value: 'SMCR8', id: 'SMCR8'},
{value: 'SMDT1', id: 'SMDT1'},
{value: 'SMG1', id: 'SMG1'},
{value: 'SMG1-DT', id: 'SMG1-DT'},
{value: 'SMG1P1', id: 'SMG1P1'},
{value: 'SMG1P2', id: 'SMG1P2'},
{value: 'SMG1P3', id: 'SMG1P3'},
{value: 'SMG1P4', id: 'SMG1P4'},
{value: 'SMG1P5', id: 'SMG1P5'},
{value: 'SMG1P6', id: 'SMG1P6'},
{value: 'SMG1P7', id: 'SMG1P7'},
{value: 'SMG5', id: 'SMG5'},
{value: 'SMG6', id: 'SMG6'},
{value: 'SMG6-IT1', id: 'SMG6-IT1'},
{value: 'SMG7', id: 'SMG7'},
{value: 'SMG7-AS1', id: 'SMG7-AS1'},
{value: 'SMG8', id: 'SMG8'},
{value: 'SMG9', id: 'SMG9'},
{value: 'SMILR', id: 'SMILR'},
{value: 'SMIM1', id: 'SMIM1'},
{value: 'SMIM10', id: 'SMIM10'},
{value: 'SMIM10L1', id: 'SMIM10L1'},
{value: 'SMIM10L2A', id: 'SMIM10L2A'},
{value: 'SMIM10L2B', id: 'SMIM10L2B'},
{value: 'SMIM11A', id: 'SMIM11A'},
{value: 'SMIM11B', id: 'SMIM11B'},
{value: 'SMIM11P1', id: 'SMIM11P1'},
{value: 'SMIM12', id: 'SMIM12'},
{value: 'SMIM12P1', id: 'SMIM12P1'},
{value: 'SMIM13', id: 'SMIM13'},
{value: 'SMIM14', id: 'SMIM14'},
{value: 'SMIM14-DT', id: 'SMIM14-DT'},
{value: 'SMIM15', id: 'SMIM15'},
{value: 'SMIM15-AS1', id: 'SMIM15-AS1'},
{value: 'SMIM15P2', id: 'SMIM15P2'},
{value: 'SMIM17', id: 'SMIM17'},
{value: 'SMIM19', id: 'SMIM19'},
{value: 'SMIM2', id: 'SMIM2'},
{value: 'SMIM2-AS1', id: 'SMIM2-AS1'},
{value: 'SMIM2-IT1', id: 'SMIM2-IT1'},
{value: 'SMIM20', id: 'SMIM20'},
{value: 'SMIM21', id: 'SMIM21'},
{value: 'SMIM22', id: 'SMIM22'},
{value: 'SMIM23', id: 'SMIM23'},
{value: 'SMIM24', id: 'SMIM24'},
{value: 'SMIM26', id: 'SMIM26'},
{value: 'SMIM27', id: 'SMIM27'},
{value: 'SMIM28', id: 'SMIM28'},
{value: 'SMIM29', id: 'SMIM29'},
{value: 'SMIM3', id: 'SMIM3'},
{value: 'SMIM30', id: 'SMIM30'},
{value: 'SMIM31', id: 'SMIM31'},
{value: 'SMIM32', id: 'SMIM32'},
{value: 'SMIM33', id: 'SMIM33'},
{value: 'SMIM34A', id: 'SMIM34A'},
{value: 'SMIM34B', id: 'SMIM34B'},
{value: 'SMIM35', id: 'SMIM35'},
{value: 'SMIM36', id: 'SMIM36'},
{value: 'SMIM38', id: 'SMIM38'},
{value: 'SMIM39', id: 'SMIM39'},
{value: 'SMIM4', id: 'SMIM4'},
{value: 'SMIM40', id: 'SMIM40'},
{value: 'SMIM41', id: 'SMIM41'},
{value: 'SMIM42', id: 'SMIM42'},
{value: 'SMIM43', id: 'SMIM43'},
{value: 'SMIM5', id: 'SMIM5'},
{value: 'SMIM6', id: 'SMIM6'},
{value: 'SMIM7', id: 'SMIM7'},
{value: 'SMIM7P1', id: 'SMIM7P1'},
{value: 'SMIM8', id: 'SMIM8'},
{value: 'SMKR1', id: 'SMKR1'},
{value: 'SMLR1', id: 'SMLR1'},
{value: 'SMN1', id: 'SMN1'},
{value: 'SMN2', id: 'SMN2'},
{value: 'SMNDC1', id: 'SMNDC1'},
{value: 'SMO', id: 'SMO'},
{value: 'SMOC1', id: 'SMOC1'},
{value: 'SMOC2', id: 'SMOC2'},
{value: 'SMOX', id: 'SMOX'},
{value: 'SMPD1', id: 'SMPD1'},
{value: 'SMPD2', id: 'SMPD2'},
{value: 'SMPD3', id: 'SMPD3'},
{value: 'SMPD4', id: 'SMPD4'},
{value: 'SMPD4BP', id: 'SMPD4BP'},
{value: 'SMPD4P1', id: 'SMPD4P1'},
{value: 'SMPD4P2', id: 'SMPD4P2'},
{value: 'SMPD5', id: 'SMPD5'},
{value: 'SMPDL3A', id: 'SMPDL3A'},
{value: 'SMPDL3B', id: 'SMPDL3B'},
{value: 'SMPX', id: 'SMPX'},
{value: 'SMR3A', id: 'SMR3A'},
{value: 'SMR3B', id: 'SMR3B'},
{value: 'SMS', id: 'SMS'},
{value: 'SMTN', id: 'SMTN'},
{value: 'SMTNL1', id: 'SMTNL1'},
{value: 'SMTNL2', id: 'SMTNL2'},
{value: 'SMU1', id: 'SMU1'},
{value: 'SMU1P1', id: 'SMU1P1'},
{value: 'SMUG1', id: 'SMUG1'},
{value: 'SMUG1P1', id: 'SMUG1P1'},
{value: 'SMURF1', id: 'SMURF1'},
{value: 'SMURF2', id: 'SMURF2'},
{value: 'SMURF2P1', id: 'SMURF2P1'},
{value: 'SMYD1', id: 'SMYD1'},
{value: 'SMYD2', id: 'SMYD2'},
{value: 'SMYD3', id: 'SMYD3'},
{value: 'SMYD4', id: 'SMYD4'},
{value: 'SMYD5', id: 'SMYD5'},
{value: 'SNAI1', id: 'SNAI1'},
{value: 'SNAI1P1', id: 'SNAI1P1'},
{value: 'SNAI2', id: 'SNAI2'},
{value: 'SNAI3', id: 'SNAI3'},
{value: 'SNAI3-AS1', id: 'SNAI3-AS1'},
{value: 'SNAP23', id: 'SNAP23'},
{value: 'SNAP23P1', id: 'SNAP23P1'},
{value: 'SNAP25', id: 'SNAP25'},
{value: 'SNAP25-AS1', id: 'SNAP25-AS1'},
{value: 'SNAP29', id: 'SNAP29'},
{value: 'SNAP47', id: 'SNAP47'},
{value: 'SNAP91', id: 'SNAP91'},
{value: 'SNAPC1', id: 'SNAPC1'},
{value: 'SNAPC2', id: 'SNAPC2'},
{value: 'SNAPC3', id: 'SNAPC3'},
{value: 'SNAPC4', id: 'SNAPC4'},
{value: 'SNAPC5', id: 'SNAPC5'},
{value: 'SNAPC5P1', id: 'SNAPC5P1'},
{value: 'SNAPIN', id: 'SNAPIN'},
{value: 'SNCA', id: 'SNCA'},
{value: 'SNCA-AS1', id: 'SNCA-AS1'},
{value: 'SNCAIP', id: 'SNCAIP'},
{value: 'SNCB', id: 'SNCB'},
{value: 'SNCG', id: 'SNCG'},
{value: 'SND1', id: 'SND1'},
{value: 'SND1-IT1', id: 'SND1-IT1'},
{value: 'SNED1', id: 'SNED1'},
{value: 'SNED1-AS1', id: 'SNED1-AS1'},
{value: 'SNF8', id: 'SNF8'},
{value: 'SNF8P1', id: 'SNF8P1'},
{value: 'SNHG1', id: 'SNHG1'},
{value: 'SNHG10', id: 'SNHG10'},
{value: 'SNHG11', id: 'SNHG11'},
{value: 'SNHG12', id: 'SNHG12'},
{value: 'SNHG14', id: 'SNHG14'},
{value: 'SNHG15', id: 'SNHG15'},
{value: 'SNHG16', id: 'SNHG16'},
{value: 'SNHG17', id: 'SNHG17'},
{value: 'SNHG18', id: 'SNHG18'},
{value: 'SNHG19', id: 'SNHG19'},
{value: 'SNHG20', id: 'SNHG20'},
{value: 'SNHG21', id: 'SNHG21'},
{value: 'SNHG22', id: 'SNHG22'},
{value: 'SNHG25', id: 'SNHG25'},
{value: 'SNHG26', id: 'SNHG26'},
{value: 'SNHG27', id: 'SNHG27'},
{value: 'SNHG28', id: 'SNHG28'},
{value: 'SNHG29', id: 'SNHG29'},
{value: 'SNHG3', id: 'SNHG3'},
{value: 'SNHG30', id: 'SNHG30'},
{value: 'SNHG31', id: 'SNHG31'},
{value: 'SNHG32', id: 'SNHG32'},
{value: 'SNHG4', id: 'SNHG4'},
{value: 'SNHG5', id: 'SNHG5'},
{value: 'SNHG6', id: 'SNHG6'},
{value: 'SNHG7', id: 'SNHG7'},
{value: 'SNHG8', id: 'SNHG8'},
{value: 'SNHG9', id: 'SNHG9'},
{value: 'SNIP1', id: 'SNIP1'},
{value: 'SNN', id: 'SNN'},
{value: 'SNORA108', id: 'SNORA108'},
{value: 'SNORA10B', id: 'SNORA10B'},
{value: 'SNORA16A', id: 'SNORA16A'},
{value: 'SNORA17A', id: 'SNORA17A'},
{value: 'SNORA21', id: 'SNORA21'},
{value: 'SNORA21B', id: 'SNORA21B'},
{value: 'SNORA22C', id: 'SNORA22C'},
{value: 'SNORA24', id: 'SNORA24'},
{value: 'SNORA25B', id: 'SNORA25B'},
{value: 'SNORA27', id: 'SNORA27'},
{value: 'SNORA28', id: 'SNORA28'},
{value: 'SNORA2B', id: 'SNORA2B'},
{value: 'SNORA2C', id: 'SNORA2C'},
{value: 'SNORA30B', id: 'SNORA30B'},
{value: 'SNORA31B', id: 'SNORA31B'},
{value: 'SNORA33', id: 'SNORA33'},
{value: 'SNORA36A', id: 'SNORA36A'},
{value: 'SNORA38B', id: 'SNORA38B'},
{value: 'SNORA3C', id: 'SNORA3C'},
{value: 'SNORA41', id: 'SNORA41'},
{value: 'SNORA46', id: 'SNORA46'},
{value: 'SNORA47', id: 'SNORA47'},
{value: 'SNORA48', id: 'SNORA48'},
{value: 'SNORA49', id: 'SNORA49'},
{value: 'SNORA50B', id: 'SNORA50B'},
{value: 'SNORA50C', id: 'SNORA50C'},
{value: 'SNORA53', id: 'SNORA53'},
{value: 'SNORA54', id: 'SNORA54'},
{value: 'SNORA58B', id: 'SNORA58B'},
{value: 'SNORA59A', id: 'SNORA59A'},
{value: 'SNORA5A', id: 'SNORA5A'},
{value: 'SNORA5B', id: 'SNORA5B'},
{value: 'SNORA60', id: 'SNORA60'},
{value: 'SNORA62', id: 'SNORA62'},
{value: 'SNORA63', id: 'SNORA63'},
{value: 'SNORA63C', id: 'SNORA63C'},
{value: 'SNORA63E', id: 'SNORA63E'},
{value: 'SNORA65', id: 'SNORA65'},
{value: 'SNORA66', id: 'SNORA66'},
{value: 'SNORA68', id: 'SNORA68'},
{value: 'SNORA68B', id: 'SNORA68B'},
{value: 'SNORA70', id: 'SNORA70'},
{value: 'SNORA70D', id: 'SNORA70D'},
{value: 'SNORA70H', id: 'SNORA70H'},
{value: 'SNORA70I', id: 'SNORA70I'},
{value: 'SNORA71', id: 'SNORA71'},
{value: 'SNORA71A', id: 'SNORA71A'},
{value: 'SNORA72', id: 'SNORA72'},
{value: 'SNORA73', id: 'SNORA73'},
{value: 'SNORA73A', id: 'SNORA73A'},
{value: 'SNORA73B', id: 'SNORA73B'},
{value: 'SNORA74', id: 'SNORA74'},
{value: 'SNORA74B', id: 'SNORA74B'},
{value: 'SNORA74C-1', id: 'SNORA74C-1'},
{value: 'SNORA74C-2', id: 'SNORA74C-2'},
{value: 'SNORA75', id: 'SNORA75'},
{value: 'SNORA75B', id: 'SNORA75B'},
{value: 'SNORA77', id: 'SNORA77'},
{value: 'SNORA78', id: 'SNORA78'},
{value: 'SNORA80A', id: 'SNORA80A'},
{value: 'SNORA80B', id: 'SNORA80B'},
{value: 'SNORA80C', id: 'SNORA80C'},
{value: 'SNORA80D', id: 'SNORA80D'},
{value: 'SNORA9B', id: 'SNORA9B'},
{value: 'SNORC', id: 'SNORC'},
{value: 'SNORD10', id: 'SNORD10'},
{value: 'SNORD111B', id: 'SNORD111B'},
{value: 'SNORD114-17', id: 'SNORD114-17'},
{value: 'SNORD115', id: 'SNORD115'},
{value: 'SNORD115-1', id: 'SNORD115-1'},
{value: 'SNORD115-20', id: 'SNORD115-20'},
{value: 'SNORD115-38', id: 'SNORD115-38'},
{value: 'SNORD115-4', id: 'SNORD115-4'},
{value: 'SNORD115-43', id: 'SNORD115-43'},
{value: 'SNORD115-44', id: 'SNORD115-44'},
{value: 'SNORD115-48', id: 'SNORD115-48'},
{value: 'SNORD116', id: 'SNORD116'},
{value: 'SNORD116-22', id: 'SNORD116-22'},
{value: 'SNORD116-24', id: 'SNORD116-24'},
{value: 'SNORD116-25', id: 'SNORD116-25'},
{value: 'SNORD116-26', id: 'SNORD116-26'},
{value: 'SNORD116-28', id: 'SNORD116-28'},
{value: 'SNORD116-29', id: 'SNORD116-29'},
{value: 'SNORD118', id: 'SNORD118'},
{value: 'SNORD121B', id: 'SNORD121B'},
{value: 'SNORD123', id: 'SNORD123'},
{value: 'SNORD124', id: 'SNORD124'},
{value: 'SNORD125', id: 'SNORD125'},
{value: 'SNORD13', id: 'SNORD13'},
{value: 'SNORD138', id: 'SNORD138'},
{value: 'SNORD13P3', id: 'SNORD13P3'},
{value: 'SNORD14A', id: 'SNORD14A'},
{value: 'SNORD14E', id: 'SNORD14E'},
{value: 'SNORD15B', id: 'SNORD15B'},
{value: 'SNORD17', id: 'SNORD17'},
{value: 'SNORD1C', id: 'SNORD1C'},
{value: 'SNORD22', id: 'SNORD22'},
{value: 'SNORD23', id: 'SNORD23'},
{value: 'SNORD26', id: 'SNORD26'},
{value: 'SNORD27', id: 'SNORD27'},
{value: 'SNORD31B', id: 'SNORD31B'},
{value: 'SNORD33', id: 'SNORD33'},
{value: 'SNORD36', id: 'SNORD36'},
{value: 'SNORD39', id: 'SNORD39'},
{value: 'SNORD3A', id: 'SNORD3A'},
{value: 'SNORD3B-1', id: 'SNORD3B-1'},
{value: 'SNORD3B-2', id: 'SNORD3B-2'},
{value: 'SNORD3C', id: 'SNORD3C'},
{value: 'SNORD3D', id: 'SNORD3D'},
{value: 'SNORD3G', id: 'SNORD3G'},
{value: 'SNORD3H', id: 'SNORD3H'},
{value: 'SNORD3J', id: 'SNORD3J'},
{value: 'SNORD3P1', id: 'SNORD3P1'},
{value: 'SNORD41', id: 'SNORD41'},
{value: 'SNORD42', id: 'SNORD42'},
{value: 'SNORD42B', id: 'SNORD42B'},
{value: 'SNORD43', id: 'SNORD43'},
{value: 'SNORD49B', id: 'SNORD49B'},
{value: 'SNORD52', id: 'SNORD52'},
{value: 'SNORD54', id: 'SNORD54'},
{value: 'SNORD58B', id: 'SNORD58B'},
{value: 'SNORD63', id: 'SNORD63'},
{value: 'SNORD63B', id: 'SNORD63B'},
{value: 'SNORD65B', id: 'SNORD65B'},
{value: 'SNORD65C', id: 'SNORD65C'},
{value: 'SNORD66', id: 'SNORD66'},
{value: 'SNORD67', id: 'SNORD67'},
{value: 'SNORD68', id: 'SNORD68'},
{value: 'SNORD71', id: 'SNORD71'},
{value: 'SNORD72', id: 'SNORD72'},
{value: 'SNORD77B', id: 'SNORD77B'},
{value: 'SNORD81', id: 'SNORD81'},
{value: 'SNORD83B', id: 'SNORD83B'},
{value: 'SNORD84', id: 'SNORD84'},
{value: 'SNORD88B', id: 'SNORD88B'},
{value: 'SNORD89', id: 'SNORD89'},
{value: 'SNORD94', id: 'SNORD94'},
{value: 'SNORD95', id: 'SNORD95'},
{value: 'SNPH', id: 'SNPH'},
{value: 'SNRK', id: 'SNRK'},
{value: 'SNRK-AS1', id: 'SNRK-AS1'},
{value: 'SNRNP200', id: 'SNRNP200'},
{value: 'SNRNP25', id: 'SNRNP25'},
{value: 'SNRNP27', id: 'SNRNP27'},
{value: 'SNRNP35', id: 'SNRNP35'},
{value: 'SNRNP40', id: 'SNRNP40'},
{value: 'SNRNP48', id: 'SNRNP48'},
{value: 'SNRNP70', id: 'SNRNP70'},
{value: 'SNRPA', id: 'SNRPA'},
{value: 'SNRPA1', id: 'SNRPA1'},
{value: 'SNRPA1-DT', id: 'SNRPA1-DT'},
{value: 'SNRPA1P1', id: 'SNRPA1P1'},
{value: 'SNRPB', id: 'SNRPB'},
{value: 'SNRPB2', id: 'SNRPB2'},
{value: 'SNRPBP1', id: 'SNRPBP1'},
{value: 'SNRPC', id: 'SNRPC'},
{value: 'SNRPCP1', id: 'SNRPCP1'},
{value: 'SNRPCP18', id: 'SNRPCP18'},
{value: 'SNRPCP19', id: 'SNRPCP19'},
{value: 'SNRPCP2', id: 'SNRPCP2'},
{value: 'SNRPCP3', id: 'SNRPCP3'},
{value: 'SNRPD1', id: 'SNRPD1'},
{value: 'SNRPD2', id: 'SNRPD2'},
{value: 'SNRPD2P1', id: 'SNRPD2P1'},
{value: 'SNRPD2P2', id: 'SNRPD2P2'},
{value: 'SNRPD3', id: 'SNRPD3'},
{value: 'SNRPE', id: 'SNRPE'},
{value: 'SNRPEP10', id: 'SNRPEP10'},
{value: 'SNRPEP3', id: 'SNRPEP3'},
{value: 'SNRPEP4', id: 'SNRPEP4'},
{value: 'SNRPEP5', id: 'SNRPEP5'},
{value: 'SNRPEP6', id: 'SNRPEP6'},
{value: 'SNRPEP7', id: 'SNRPEP7'},
{value: 'SNRPEP8', id: 'SNRPEP8'},
{value: 'SNRPF', id: 'SNRPF'},
{value: 'SNRPF-DT', id: 'SNRPF-DT'},
{value: 'SNRPFP1', id: 'SNRPFP1'},
{value: 'SNRPFP3', id: 'SNRPFP3'},
{value: 'SNRPFP4', id: 'SNRPFP4'},
{value: 'SNRPG', id: 'SNRPG'},
{value: 'SNRPGP10', id: 'SNRPGP10'},
{value: 'SNRPGP12', id: 'SNRPGP12'},
{value: 'SNRPGP15', id: 'SNRPGP15'},
{value: 'SNRPGP16', id: 'SNRPGP16'},
{value: 'SNRPGP17', id: 'SNRPGP17'},
{value: 'SNRPGP19', id: 'SNRPGP19'},
{value: 'SNRPGP2', id: 'SNRPGP2'},
{value: 'SNRPGP20', id: 'SNRPGP20'},
{value: 'SNRPGP4', id: 'SNRPGP4'},
{value: 'SNRPGP6', id: 'SNRPGP6'},
{value: 'SNRPGP8', id: 'SNRPGP8'},
{value: 'SNRPN', id: 'SNRPN'},
{value: 'SNTA1', id: 'SNTA1'},
{value: 'SNTB1', id: 'SNTB1'},
{value: 'SNTB2', id: 'SNTB2'},
{value: 'SNTG1', id: 'SNTG1'},
{value: 'SNTG2', id: 'SNTG2'},
{value: 'SNTG2-AS1', id: 'SNTG2-AS1'},
{value: 'SNTN', id: 'SNTN'},
{value: 'SNU13', id: 'SNU13'},
{value: 'SNUPN', id: 'SNUPN'},
{value: 'SNURF', id: 'SNURF'},
{value: 'SNW1', id: 'SNW1'},
{value: 'SNX1', id: 'SNX1'},
{value: 'SNX10', id: 'SNX10'},
{value: 'SNX11', id: 'SNX11'},
{value: 'SNX12', id: 'SNX12'},
{value: 'SNX13', id: 'SNX13'},
{value: 'SNX14', id: 'SNX14'},
{value: 'SNX15', id: 'SNX15'},
{value: 'SNX16', id: 'SNX16'},
{value: 'SNX17', id: 'SNX17'},
{value: 'SNX18', id: 'SNX18'},
{value: 'SNX18P12', id: 'SNX18P12'},
{value: 'SNX18P13', id: 'SNX18P13'},
{value: 'SNX18P14', id: 'SNX18P14'},
{value: 'SNX18P23', id: 'SNX18P23'},
{value: 'SNX18P25', id: 'SNX18P25'},
{value: 'SNX18P27', id: 'SNX18P27'},
{value: 'SNX18P3', id: 'SNX18P3'},
{value: 'SNX18P7', id: 'SNX18P7'},
{value: 'SNX18P9', id: 'SNX18P9'},
{value: 'SNX19', id: 'SNX19'},
{value: 'SNX19P1', id: 'SNX19P1'},
{value: 'SNX19P2', id: 'SNX19P2'},
{value: 'SNX19P3', id: 'SNX19P3'},
{value: 'SNX19P4', id: 'SNX19P4'},
{value: 'SNX2', id: 'SNX2'},
{value: 'SNX20', id: 'SNX20'},
{value: 'SNX21', id: 'SNX21'},
{value: 'SNX22', id: 'SNX22'},
{value: 'SNX24', id: 'SNX24'},
{value: 'SNX25', id: 'SNX25'},
{value: 'SNX25P1', id: 'SNX25P1'},
{value: 'SNX27', id: 'SNX27'},
{value: 'SNX29', id: 'SNX29'},
{value: 'SNX29P1', id: 'SNX29P1'},
{value: 'SNX29P2', id: 'SNX29P2'},
{value: 'SNX2P2', id: 'SNX2P2'},
{value: 'SNX3', id: 'SNX3'},
{value: 'SNX30', id: 'SNX30'},
{value: 'SNX31', id: 'SNX31'},
{value: 'SNX32', id: 'SNX32'},
{value: 'SNX33', id: 'SNX33'},
{value: 'SNX4', id: 'SNX4'},
{value: 'SNX5', id: 'SNX5'},
{value: 'SNX5P1', id: 'SNX5P1'},
{value: 'SNX5P2', id: 'SNX5P2'},
{value: 'SNX6', id: 'SNX6'},
{value: 'SNX6P1', id: 'SNX6P1'},
{value: 'SNX7', id: 'SNX7'},
{value: 'SNX8', id: 'SNX8'},
{value: 'SNX9', id: 'SNX9'},
{value: 'SNX9-AS1', id: 'SNX9-AS1'},
{value: 'SOAT1', id: 'SOAT1'},
{value: 'SOAT2', id: 'SOAT2'},
{value: 'SOBP', id: 'SOBP'},
{value: 'SOCAR', id: 'SOCAR'},
{value: 'SOCS1', id: 'SOCS1'},
{value: 'SOCS2', id: 'SOCS2'},
{value: 'SOCS2-AS1', id: 'SOCS2-AS1'},
{value: 'SOCS2P1', id: 'SOCS2P1'},
{value: 'SOCS2P2', id: 'SOCS2P2'},
{value: 'SOCS3', id: 'SOCS3'},
{value: 'SOCS3-DT', id: 'SOCS3-DT'},
{value: 'SOCS4', id: 'SOCS4'},
{value: 'SOCS5', id: 'SOCS5'},
{value: 'SOCS5P1', id: 'SOCS5P1'},
{value: 'SOCS5P2', id: 'SOCS5P2'},
{value: 'SOCS5P3', id: 'SOCS5P3'},
{value: 'SOCS5P4', id: 'SOCS5P4'},
{value: 'SOCS5P5', id: 'SOCS5P5'},
{value: 'SOCS6', id: 'SOCS6'},
{value: 'SOCS7', id: 'SOCS7'},
{value: 'SOD1', id: 'SOD1'},
{value: 'SOD1P1', id: 'SOD1P1'},
{value: 'SOD1P2', id: 'SOD1P2'},
{value: 'SOD1P3', id: 'SOD1P3'},
{value: 'SOD2', id: 'SOD2'},
{value: 'SOD2-OT1', id: 'SOD2-OT1'},
{value: 'SOD3', id: 'SOD3'},
{value: 'SOGA1', id: 'SOGA1'},
{value: 'SOGA3', id: 'SOGA3'},
{value: 'SOHLH1', id: 'SOHLH1'},
{value: 'SOHLH2', id: 'SOHLH2'},
{value: 'SON', id: 'SON'},
{value: 'SORBS1', id: 'SORBS1'},
{value: 'SORBS2', id: 'SORBS2'},
{value: 'SORBS3', id: 'SORBS3'},
{value: 'SORCS1', id: 'SORCS1'},
{value: 'SORCS2', id: 'SORCS2'},
{value: 'SORCS3', id: 'SORCS3'},
{value: 'SORCS3-AS1', id: 'SORCS3-AS1'},
{value: 'SORD', id: 'SORD'},
{value: 'SORD2P', id: 'SORD2P'},
{value: 'SORL1', id: 'SORL1'},
{value: 'SORL1-AS1', id: 'SORL1-AS1'},
{value: 'SORT1', id: 'SORT1'},
{value: 'SOS1', id: 'SOS1'},
{value: 'SOS1-IT1', id: 'SOS1-IT1'},
{value: 'SOS2', id: 'SOS2'},
{value: 'SOST', id: 'SOST'},
{value: 'SOSTDC1', id: 'SOSTDC1'},
{value: 'SOWAHA', id: 'SOWAHA'},
{value: 'SOWAHB', id: 'SOWAHB'},
{value: 'SOWAHC', id: 'SOWAHC'},
{value: 'SOWAHCP5', id: 'SOWAHCP5'},
{value: 'SOWAHD', id: 'SOWAHD'},
{value: 'SOX1', id: 'SOX1'},
{value: 'SOX1-OT', id: 'SOX1-OT'},
{value: 'SOX10', id: 'SOX10'},
{value: 'SOX11', id: 'SOX11'},
{value: 'SOX12', id: 'SOX12'},
{value: 'SOX13', id: 'SOX13'},
{value: 'SOX14', id: 'SOX14'},
{value: 'SOX15', id: 'SOX15'},
{value: 'SOX17', id: 'SOX17'},
{value: 'SOX18', id: 'SOX18'},
{value: 'SOX2-OT', id: 'SOX2-OT'},
{value: 'SOX21', id: 'SOX21'},
{value: 'SOX21-AS1', id: 'SOX21-AS1'},
{value: 'SOX30', id: 'SOX30'},
{value: 'SOX4', id: 'SOX4'},
{value: 'SOX5', id: 'SOX5'},
{value: 'SOX5P1', id: 'SOX5P1'},
{value: 'SOX6', id: 'SOX6'},
{value: 'SOX7', id: 'SOX7'},
{value: 'SOX8', id: 'SOX8'},
{value: 'SOX9', id: 'SOX9'},
{value: 'SOX9-AS1', id: 'SOX9-AS1'},
{value: 'SP1', id: 'SP1'},
{value: 'SP100', id: 'SP100'},
{value: 'SP110', id: 'SP110'},
{value: 'SP140', id: 'SP140'},
{value: 'SP140L', id: 'SP140L'},
{value: 'SP2', id: 'SP2'},
{value: 'SP2-AS1', id: 'SP2-AS1'},
{value: 'SP2-DT', id: 'SP2-DT'},
{value: 'SP3', id: 'SP3'},
{value: 'SP3P', id: 'SP3P'},
{value: 'SP4', id: 'SP4'},
{value: 'SP5', id: 'SP5'},
{value: 'SP6', id: 'SP6'},
{value: 'SP7', id: 'SP7'},
{value: 'SP8', id: 'SP8'},
{value: 'SP9', id: 'SP9'},
{value: 'SPA17', id: 'SPA17'},
{value: 'SPAAR', id: 'SPAAR'},
{value: 'SPACA1', id: 'SPACA1'},
{value: 'SPACA3', id: 'SPACA3'},
{value: 'SPACA4', id: 'SPACA4'},
{value: 'SPACA6', id: 'SPACA6'},
{value: 'SPACA6P-AS', id: 'SPACA6P-AS'},
{value: 'SPACA7', id: 'SPACA7'},
{value: 'SPACA9', id: 'SPACA9'},
{value: 'SPAG1', id: 'SPAG1'},
{value: 'SPAG11A', id: 'SPAG11A'},
{value: 'SPAG11B', id: 'SPAG11B'},
{value: 'SPAG16', id: 'SPAG16'},
{value: 'SPAG16-DT', id: 'SPAG16-DT'},
{value: 'SPAG17', id: 'SPAG17'},
{value: 'SPAG4', id: 'SPAG4'},
{value: 'SPAG5', id: 'SPAG5'},
{value: 'SPAG5-AS1', id: 'SPAG5-AS1'},
{value: 'SPAG6', id: 'SPAG6'},
{value: 'SPAG7', id: 'SPAG7'},
{value: 'SPAG8', id: 'SPAG8'},
{value: 'SPAG9', id: 'SPAG9'},
{value: 'SPAM1', id: 'SPAM1'},
{value: 'SPARC', id: 'SPARC'},
{value: 'SPARCL1', id: 'SPARCL1'},
{value: 'SPART', id: 'SPART'},
{value: 'SPART-AS1', id: 'SPART-AS1'},
{value: 'SPAST', id: 'SPAST'},
{value: 'SPATA1', id: 'SPATA1'},
{value: 'SPATA12', id: 'SPATA12'},
{value: 'SPATA13', id: 'SPATA13'},
{value: 'SPATA13-AS1', id: 'SPATA13-AS1'},
{value: 'SPATA16', id: 'SPATA16'},
{value: 'SPATA17', id: 'SPATA17'},
{value: 'SPATA17-AS1', id: 'SPATA17-AS1'},
{value: 'SPATA18', id: 'SPATA18'},
{value: 'SPATA19', id: 'SPATA19'},
{value: 'SPATA2', id: 'SPATA2'},
{value: 'SPATA20', id: 'SPATA20'},
{value: 'SPATA20P1', id: 'SPATA20P1'},
{value: 'SPATA21', id: 'SPATA21'},
{value: 'SPATA22', id: 'SPATA22'},
{value: 'SPATA24', id: 'SPATA24'},
{value: 'SPATA25', id: 'SPATA25'},
{value: 'SPATA2L', id: 'SPATA2L'},
{value: 'SPATA2P1', id: 'SPATA2P1'},
{value: 'SPATA3', id: 'SPATA3'},
{value: 'SPATA3-AS1', id: 'SPATA3-AS1'},
{value: 'SPATA31C1', id: 'SPATA31C1'},
{value: 'SPATA31C2', id: 'SPATA31C2'},
{value: 'SPATA31D1', id: 'SPATA31D1'},
{value: 'SPATA31D3', id: 'SPATA31D3'},
{value: 'SPATA31D4', id: 'SPATA31D4'},
{value: 'SPATA31D5P', id: 'SPATA31D5P'},
{value: 'SPATA31E1', id: 'SPATA31E1'},
{value: 'SPATA31E2P', id: 'SPATA31E2P'},
{value: 'SPATA31E3P', id: 'SPATA31E3P'},
{value: 'SPATA32', id: 'SPATA32'},
{value: 'SPATA33', id: 'SPATA33'},
{value: 'SPATA4', id: 'SPATA4'},
{value: 'SPATA41', id: 'SPATA41'},
{value: 'SPATA42', id: 'SPATA42'},
{value: 'SPATA45', id: 'SPATA45'},
{value: 'SPATA48', id: 'SPATA48'},
{value: 'SPATA5', id: 'SPATA5'},
{value: 'SPATA5L1', id: 'SPATA5L1'},
{value: 'SPATA6', id: 'SPATA6'},
{value: 'SPATA6L', id: 'SPATA6L'},
{value: 'SPATA7', id: 'SPATA7'},
{value: 'SPATA8', id: 'SPATA8'},
{value: 'SPATA8-AS1', id: 'SPATA8-AS1'},
{value: 'SPATA9', id: 'SPATA9'},
{value: 'SPATC1', id: 'SPATC1'},
{value: 'SPATC1L', id: 'SPATC1L'},
{value: 'SPATS1', id: 'SPATS1'},
{value: 'SPATS2', id: 'SPATS2'},
{value: 'SPATS2L', id: 'SPATS2L'},
{value: 'SPC24', id: 'SPC24'},
{value: 'SPC25', id: 'SPC25'},
{value: 'SPCS1', id: 'SPCS1'},
{value: 'SPCS2', id: 'SPCS2'},
{value: 'SPCS2P2', id: 'SPCS2P2'},
{value: 'SPCS2P4', id: 'SPCS2P4'},
{value: 'SPCS3', id: 'SPCS3'},
{value: 'SPCS3-AS1', id: 'SPCS3-AS1'},
{value: 'SPDEF', id: 'SPDEF'},
{value: 'SPDL1', id: 'SPDL1'},
{value: 'SPDYA', id: 'SPDYA'},
{value: 'SPDYC', id: 'SPDYC'},
{value: 'SPDYE1', id: 'SPDYE1'},
{value: 'SPDYE10P', id: 'SPDYE10P'},
{value: 'SPDYE12P', id: 'SPDYE12P'},
{value: 'SPDYE13', id: 'SPDYE13'},
{value: 'SPDYE14', id: 'SPDYE14'},
{value: 'SPDYE16', id: 'SPDYE16'},
{value: 'SPDYE18', id: 'SPDYE18'},
{value: 'SPDYE19P', id: 'SPDYE19P'},
{value: 'SPDYE2', id: 'SPDYE2'},
{value: 'SPDYE21', id: 'SPDYE21'},
{value: 'SPDYE2B', id: 'SPDYE2B'},
{value: 'SPDYE3', id: 'SPDYE3'},
{value: 'SPDYE4', id: 'SPDYE4'},
{value: 'SPDYE5', id: 'SPDYE5'},
{value: 'SPDYE6', id: 'SPDYE6'},
{value: 'SPDYE7P', id: 'SPDYE7P'},
{value: 'SPECC1', id: 'SPECC1'},
{value: 'SPECC1-DT', id: 'SPECC1-DT'},
{value: 'SPECC1L', id: 'SPECC1L'},
{value: 'SPECC1L-ADORA2A', id: 'SPECC1L-ADORA2A'},
{value: 'SPECC1P1', id: 'SPECC1P1'},
{value: 'SPEF1', id: 'SPEF1'},
{value: 'SPEF2', id: 'SPEF2'},
{value: 'SPEG', id: 'SPEG'},
{value: 'SPEGNB', id: 'SPEGNB'},
{value: 'SPEM3', id: 'SPEM3'},
{value: 'SPEN', id: 'SPEN'},
{value: 'SPESP1', id: 'SPESP1'},
{value: 'SPG11', id: 'SPG11'},
{value: 'SPG21', id: 'SPG21'},
{value: 'SPG7', id: 'SPG7'},
{value: 'SPHK1', id: 'SPHK1'},
{value: 'SPHK2', id: 'SPHK2'},
{value: 'SPHKAP', id: 'SPHKAP'},
{value: 'SPI1', id: 'SPI1'},
{value: 'SPIB', id: 'SPIB'},
{value: 'SPIC', id: 'SPIC'},
{value: 'SPICE1', id: 'SPICE1'},
{value: 'SPIDR', id: 'SPIDR'},
{value: 'SPIN1', id: 'SPIN1'},
{value: 'SPIN2A', id: 'SPIN2A'},
{value: 'SPIN2B', id: 'SPIN2B'},
{value: 'SPIN3', id: 'SPIN3'},
{value: 'SPIN4', id: 'SPIN4'},
{value: 'SPINDOC', id: 'SPINDOC'},
{value: 'SPINK1', id: 'SPINK1'},
{value: 'SPINK14', id: 'SPINK14'},
{value: 'SPINK2', id: 'SPINK2'},
{value: 'SPINK4', id: 'SPINK4'},
{value: 'SPINK5', id: 'SPINK5'},
{value: 'SPINK6', id: 'SPINK6'},
{value: 'SPINK7', id: 'SPINK7'},
{value: 'SPINK8', id: 'SPINK8'},
{value: 'SPINT1', id: 'SPINT1'},
{value: 'SPINT1-AS1', id: 'SPINT1-AS1'},
{value: 'SPINT2', id: 'SPINT2'},
{value: 'SPINT3', id: 'SPINT3'},
{value: 'SPINT4', id: 'SPINT4'},
{value: 'SPIRE1', id: 'SPIRE1'},
{value: 'SPIRE2', id: 'SPIRE2'},
{value: 'SPN', id: 'SPN'},
{value: 'SPNS1', id: 'SPNS1'},
{value: 'SPNS2', id: 'SPNS2'},
{value: 'SPNS3', id: 'SPNS3'},
{value: 'SPO11', id: 'SPO11'},
{value: 'SPOCD1', id: 'SPOCD1'},
{value: 'SPOCK1', id: 'SPOCK1'},
{value: 'SPOCK2', id: 'SPOCK2'},
{value: 'SPOCK3', id: 'SPOCK3'},
{value: 'SPON1', id: 'SPON1'},
{value: 'SPON1-AS1', id: 'SPON1-AS1'},
{value: 'SPON2', id: 'SPON2'},
{value: 'SPOP', id: 'SPOP'},
{value: 'SPOPL', id: 'SPOPL'},
{value: 'SPOUT1', id: 'SPOUT1'},
{value: 'SPP1', id: 'SPP1'},
{value: 'SPP2', id: 'SPP2'},
{value: 'SPPL2A', id: 'SPPL2A'},
{value: 'SPPL2B', id: 'SPPL2B'},
{value: 'SPPL2C', id: 'SPPL2C'},
{value: 'SPPL3', id: 'SPPL3'},
{value: 'SPR', id: 'SPR'},
{value: 'SPRED1', id: 'SPRED1'},
{value: 'SPRED2', id: 'SPRED2'},
{value: 'SPRED3', id: 'SPRED3'},
{value: 'SPRING1', id: 'SPRING1'},
{value: 'SPRING1P1', id: 'SPRING1P1'},
{value: 'SPRN', id: 'SPRN'},
{value: 'SPRR1A', id: 'SPRR1A'},
{value: 'SPRR1B', id: 'SPRR1B'},
{value: 'SPRR2A', id: 'SPRR2A'},
{value: 'SPRR2B', id: 'SPRR2B'},
{value: 'SPRR2C', id: 'SPRR2C'},
{value: 'SPRR2D', id: 'SPRR2D'},
{value: 'SPRR2E', id: 'SPRR2E'},
{value: 'SPRR2F', id: 'SPRR2F'},
{value: 'SPRR2G', id: 'SPRR2G'},
{value: 'SPRR3', id: 'SPRR3'},
{value: 'SPRR4', id: 'SPRR4'},
{value: 'SPRR5', id: 'SPRR5'},
{value: 'SPRTN', id: 'SPRTN'},
{value: 'SPRY1', id: 'SPRY1'},
{value: 'SPRY2', id: 'SPRY2'},
{value: 'SPRY3', id: 'SPRY3'},
{value: 'SPRY4', id: 'SPRY4'},
{value: 'SPRY4-AS1', id: 'SPRY4-AS1'},
{value: 'SPRYD3', id: 'SPRYD3'},
{value: 'SPRYD4', id: 'SPRYD4'},
{value: 'SPRYD7', id: 'SPRYD7'},
{value: 'SPSB1', id: 'SPSB1'},
{value: 'SPSB2', id: 'SPSB2'},
{value: 'SPSB3', id: 'SPSB3'},
{value: 'SPSB4', id: 'SPSB4'},
{value: 'SPTA1', id: 'SPTA1'},
{value: 'SPTAN1', id: 'SPTAN1'},
{value: 'SPTB', id: 'SPTB'},
{value: 'SPTBN1', id: 'SPTBN1'},
{value: 'SPTBN2', id: 'SPTBN2'},
{value: 'SPTBN4', id: 'SPTBN4'},
{value: 'SPTBN5', id: 'SPTBN5'},
{value: 'SPTLC1', id: 'SPTLC1'},
{value: 'SPTLC1P2', id: 'SPTLC1P2'},
{value: 'SPTLC1P4', id: 'SPTLC1P4'},
{value: 'SPTLC1P5', id: 'SPTLC1P5'},
{value: 'SPTLC2', id: 'SPTLC2'},
{value: 'SPTLC3', id: 'SPTLC3'},
{value: 'SPTSSA', id: 'SPTSSA'},
{value: 'SPTSSB', id: 'SPTSSB'},
{value: 'SPTY2D1', id: 'SPTY2D1'},
{value: 'SPTY2D1OS', id: 'SPTY2D1OS'},
{value: 'SPX', id: 'SPX'},
{value: 'SPZ1', id: 'SPZ1'},
{value: 'SQLE', id: 'SQLE'},
{value: 'SQLE-DT', id: 'SQLE-DT'},
{value: 'SQOR', id: 'SQOR'},
{value: 'SQSTM1', id: 'SQSTM1'},
{value: 'SQSTM1P1', id: 'SQSTM1P1'},
{value: 'SRA1', id: 'SRA1'},
{value: 'SRARP', id: 'SRARP'},
{value: 'SRBD1', id: 'SRBD1'},
{value: 'SRC', id: 'SRC'},
{value: 'SRCAP', id: 'SRCAP'},
{value: 'SRCIN1', id: 'SRCIN1'},
{value: 'SRD5A1', id: 'SRD5A1'},
{value: 'SRD5A2', id: 'SRD5A2'},
{value: 'SRD5A3', id: 'SRD5A3'},
{value: 'SRD5A3-AS1', id: 'SRD5A3-AS1'},
{value: 'SRD5A3P1', id: 'SRD5A3P1'},
{value: 'SREBF1', id: 'SREBF1'},
{value: 'SREBF2', id: 'SREBF2'},
{value: 'SREBF2-AS1', id: 'SREBF2-AS1'},
{value: 'SREK1', id: 'SREK1'},
{value: 'SREK1IP1', id: 'SREK1IP1'},
{value: 'SREK1IP1P1', id: 'SREK1IP1P1'},
{value: 'SREK1IP1P2', id: 'SREK1IP1P2'},
{value: 'SRF', id: 'SRF'},
{value: 'SRFBP1', id: 'SRFBP1'},
{value: 'SRGAP1', id: 'SRGAP1'},
{value: 'SRGAP2', id: 'SRGAP2'},
{value: 'SRGAP2-AS1', id: 'SRGAP2-AS1'},
{value: 'SRGAP2B', id: 'SRGAP2B'},
{value: 'SRGAP2C', id: 'SRGAP2C'},
{value: 'SRGAP2D', id: 'SRGAP2D'},
{value: 'SRGAP3', id: 'SRGAP3'},
{value: 'SRGAP3-AS1', id: 'SRGAP3-AS1'},
{value: 'SRGAP3-AS2', id: 'SRGAP3-AS2'},
{value: 'SRGAP3-AS3', id: 'SRGAP3-AS3'},
{value: 'SRGAP3-AS4', id: 'SRGAP3-AS4'},
{value: 'SRGN', id: 'SRGN'},
{value: 'SRGNP1', id: 'SRGNP1'},
{value: 'SRI', id: 'SRI'},
{value: 'SRI-AS1', id: 'SRI-AS1'},
{value: 'SRIP1', id: 'SRIP1'},
{value: 'SRL', id: 'SRL'},
{value: 'SRM', id: 'SRM'},
{value: 'SRMP1', id: 'SRMP1'},
{value: 'SRMP2', id: 'SRMP2'},
{value: 'SRMP3', id: 'SRMP3'},
{value: 'SRMS', id: 'SRMS'},
{value: 'SRP14', id: 'SRP14'},
{value: 'SRP14-DT', id: 'SRP14-DT'},
{value: 'SRP14P1', id: 'SRP14P1'},
{value: 'SRP14P2', id: 'SRP14P2'},
{value: 'SRP14P3', id: 'SRP14P3'},
{value: 'SRP14P4', id: 'SRP14P4'},
{value: 'SRP19', id: 'SRP19'},
{value: 'SRP54', id: 'SRP54'},
{value: 'SRP54-AS1', id: 'SRP54-AS1'},
{value: 'SRP68', id: 'SRP68'},
{value: 'SRP68P2', id: 'SRP68P2'},
{value: 'SRP72', id: 'SRP72'},
{value: 'SRP72P1', id: 'SRP72P1'},
{value: 'SRP72P2', id: 'SRP72P2'},
{value: 'SRP9', id: 'SRP9'},
{value: 'SRP9P1', id: 'SRP9P1'},
{value: 'SRPK1', id: 'SRPK1'},
{value: 'SRPK2', id: 'SRPK2'},
{value: 'SRPK2P', id: 'SRPK2P'},
{value: 'SRPK3', id: 'SRPK3'},
{value: 'SRPRA', id: 'SRPRA'},
{value: 'SRPRB', id: 'SRPRB'},
{value: 'SRPX', id: 'SRPX'},
{value: 'SRPX2', id: 'SRPX2'},
{value: 'SRR', id: 'SRR'},
{value: 'SRRD', id: 'SRRD'},
{value: 'SRRM1', id: 'SRRM1'},
{value: 'SRRM1P2', id: 'SRRM1P2'},
{value: 'SRRM2', id: 'SRRM2'},
{value: 'SRRM2-AS1', id: 'SRRM2-AS1'},
{value: 'SRRM3', id: 'SRRM3'},
{value: 'SRRM4', id: 'SRRM4'},
{value: 'SRRM5', id: 'SRRM5'},
{value: 'SRRT', id: 'SRRT'},
{value: 'SRSF1', id: 'SRSF1'},
{value: 'SRSF10', id: 'SRSF10'},
{value: 'SRSF10P1', id: 'SRSF10P1'},
{value: 'SRSF11', id: 'SRSF11'},
{value: 'SRSF12', id: 'SRSF12'},
{value: 'SRSF1P1', id: 'SRSF1P1'},
{value: 'SRSF2', id: 'SRSF2'},
{value: 'SRSF3', id: 'SRSF3'},
{value: 'SRSF3P1', id: 'SRSF3P1'},
{value: 'SRSF3P2', id: 'SRSF3P2'},
{value: 'SRSF3P4', id: 'SRSF3P4'},
{value: 'SRSF3P5', id: 'SRSF3P5'},
{value: 'SRSF3P6', id: 'SRSF3P6'},
{value: 'SRSF4', id: 'SRSF4'},
{value: 'SRSF5', id: 'SRSF5'},
{value: 'SRSF6', id: 'SRSF6'},
{value: 'SRSF6P2', id: 'SRSF6P2'},
{value: 'SRSF7', id: 'SRSF7'},
{value: 'SRSF8', id: 'SRSF8'},
{value: 'SRSF9', id: 'SRSF9'},
{value: 'SRSF9P1', id: 'SRSF9P1'},
{value: 'SRXN1', id: 'SRXN1'},
{value: 'SS18', id: 'SS18'},
{value: 'SS18L1', id: 'SS18L1'},
{value: 'SS18L2', id: 'SS18L2'},
{value: 'SS18L2P1', id: 'SS18L2P1'},
{value: 'SSB', id: 'SSB'},
{value: 'SSBL4P', id: 'SSBL4P'},
{value: 'SSBL5P', id: 'SSBL5P'},
{value: 'SSBP1', id: 'SSBP1'},
{value: 'SSBP2', id: 'SSBP2'},
{value: 'SSBP3', id: 'SSBP3'},
{value: 'SSBP3-AS1', id: 'SSBP3-AS1'},
{value: 'SSBP3P1', id: 'SSBP3P1'},
{value: 'SSBP4', id: 'SSBP4'},
{value: 'SSC4D', id: 'SSC4D'},
{value: 'SSC5D', id: 'SSC5D'},
{value: 'SSH1', id: 'SSH1'},
{value: 'SSH2', id: 'SSH2'},
{value: 'SSH3', id: 'SSH3'},
{value: 'SSMEM1', id: 'SSMEM1'},
{value: 'SSNA1', id: 'SSNA1'},
{value: 'SSPN', id: 'SSPN'},
{value: 'SSPOP', id: 'SSPOP'},
{value: 'SSR1', id: 'SSR1'},
{value: 'SSR1P1', id: 'SSR1P1'},
{value: 'SSR1P2', id: 'SSR1P2'},
{value: 'SSR2', id: 'SSR2'},
{value: 'SSR3', id: 'SSR3'},
{value: 'SSR4', id: 'SSR4'},
{value: 'SSR4P1', id: 'SSR4P1'},
{value: 'SSRP1', id: 'SSRP1'},
{value: 'SST', id: 'SST'},
{value: 'SSTR1', id: 'SSTR1'},
{value: 'SSTR2', id: 'SSTR2'},
{value: 'SSTR3', id: 'SSTR3'},
{value: 'SSTR4', id: 'SSTR4'},
{value: 'SSTR5', id: 'SSTR5'},
{value: 'SSTR5-AS1', id: 'SSTR5-AS1'},
{value: 'SSU72', id: 'SSU72'},
{value: 'SSU72P2', id: 'SSU72P2'},
{value: 'SSU72P3', id: 'SSU72P3'},
{value: 'SSU72P4', id: 'SSU72P4'},
{value: 'SSU72P5', id: 'SSU72P5'},
{value: 'SSU72P6', id: 'SSU72P6'},
{value: 'SSU72P7', id: 'SSU72P7'},
{value: 'SSUH2', id: 'SSUH2'},
{value: 'SSX2IP', id: 'SSX2IP'},
{value: 'SSXP10', id: 'SSXP10'},
{value: 'ST13', id: 'ST13'},
{value: 'ST13P12', id: 'ST13P12'},
{value: 'ST13P15', id: 'ST13P15'},
{value: 'ST13P19', id: 'ST13P19'},
{value: 'ST13P2', id: 'ST13P2'},
{value: 'ST13P20', id: 'ST13P20'},
{value: 'ST13P21', id: 'ST13P21'},
{value: 'ST13P3', id: 'ST13P3'},
{value: 'ST13P5', id: 'ST13P5'},
{value: 'ST13P6', id: 'ST13P6'},
{value: 'ST13P7', id: 'ST13P7'},
{value: 'ST14', id: 'ST14'},
{value: 'ST18', id: 'ST18'},
{value: 'ST20', id: 'ST20'},
{value: 'ST20-AS1', id: 'ST20-AS1'},
{value: 'ST20-MTHFS', id: 'ST20-MTHFS'},
{value: 'ST3GAL1', id: 'ST3GAL1'},
{value: 'ST3GAL1-DT', id: 'ST3GAL1-DT'},
{value: 'ST3GAL2', id: 'ST3GAL2'},
{value: 'ST3GAL3', id: 'ST3GAL3'},
{value: 'ST3GAL3-AS1', id: 'ST3GAL3-AS1'},
{value: 'ST3GAL4', id: 'ST3GAL4'},
{value: 'ST3GAL5', id: 'ST3GAL5'},
{value: 'ST3GAL5-AS1', id: 'ST3GAL5-AS1'},
{value: 'ST3GAL6', id: 'ST3GAL6'},
{value: 'ST3GAL6-AS1', id: 'ST3GAL6-AS1'},
{value: 'ST6GAL1', id: 'ST6GAL1'},
{value: 'ST6GAL2', id: 'ST6GAL2'},
{value: 'ST6GAL2-IT1', id: 'ST6GAL2-IT1'},
{value: 'ST6GALNAC1', id: 'ST6GALNAC1'},
{value: 'ST6GALNAC2', id: 'ST6GALNAC2'},
{value: 'ST6GALNAC2P1', id: 'ST6GALNAC2P1'},
{value: 'ST6GALNAC3', id: 'ST6GALNAC3'},
{value: 'ST6GALNAC4', id: 'ST6GALNAC4'},
{value: 'ST6GALNAC4P1', id: 'ST6GALNAC4P1'},
{value: 'ST6GALNAC5', id: 'ST6GALNAC5'},
{value: 'ST6GALNAC6', id: 'ST6GALNAC6'},
{value: 'ST7', id: 'ST7'},
{value: 'ST7-AS1', id: 'ST7-AS1'},
{value: 'ST7-AS2', id: 'ST7-AS2'},
{value: 'ST7L', id: 'ST7L'},
{value: 'ST8SIA1', id: 'ST8SIA1'},
{value: 'ST8SIA2', id: 'ST8SIA2'},
{value: 'ST8SIA3', id: 'ST8SIA3'},
{value: 'ST8SIA4', id: 'ST8SIA4'},
{value: 'ST8SIA5', id: 'ST8SIA5'},
{value: 'ST8SIA5-DT', id: 'ST8SIA5-DT'},
{value: 'ST8SIA6', id: 'ST8SIA6'},
{value: 'ST8SIA6-AS1', id: 'ST8SIA6-AS1'},
{value: 'STAB1', id: 'STAB1'},
{value: 'STAB2', id: 'STAB2'},
{value: 'STAC', id: 'STAC'},
{value: 'STAC2', id: 'STAC2'},
{value: 'STAC3', id: 'STAC3'},
{value: 'STAG1', id: 'STAG1'},
{value: 'STAG1-DT', id: 'STAG1-DT'},
{value: 'STAG2', id: 'STAG2'},
{value: 'STAG3', id: 'STAG3'},
{value: 'STAG3L1', id: 'STAG3L1'},
{value: 'STAG3L2', id: 'STAG3L2'},
{value: 'STAG3L3', id: 'STAG3L3'},
{value: 'STAG3L4', id: 'STAG3L4'},
{value: 'STAG3L5P', id: 'STAG3L5P'},
{value: 'STAG3L5P-PVRIG2P-PILRB', id: 'STAG3L5P-PVRIG2P-PILRB'},
{value: 'STAM', id: 'STAM'},
{value: 'STAM-DT', id: 'STAM-DT'},
{value: 'STAM2', id: 'STAM2'},
{value: 'STAMBP', id: 'STAMBP'},
{value: 'STAMBPL1', id: 'STAMBPL1'},
{value: 'STAP1', id: 'STAP1'},
{value: 'STAP2', id: 'STAP2'},
{value: 'STAR', id: 'STAR'},
{value: 'STARD10', id: 'STARD10'},
{value: 'STARD13', id: 'STARD13'},
{value: 'STARD13-AS', id: 'STARD13-AS'},
{value: 'STARD13-IT1', id: 'STARD13-IT1'},
{value: 'STARD3', id: 'STARD3'},
{value: 'STARD3NL', id: 'STARD3NL'},
{value: 'STARD4', id: 'STARD4'},
{value: 'STARD4-AS1', id: 'STARD4-AS1'},
{value: 'STARD5', id: 'STARD5'},
{value: 'STARD6', id: 'STARD6'},
{value: 'STARD7', id: 'STARD7'},
{value: 'STARD7-AS1', id: 'STARD7-AS1'},
{value: 'STARD8', id: 'STARD8'},
{value: 'STARD9', id: 'STARD9'},
{value: 'STARP1', id: 'STARP1'},
{value: 'STAT1', id: 'STAT1'},
{value: 'STAT2', id: 'STAT2'},
{value: 'STAT3', id: 'STAT3'},
{value: 'STAT4', id: 'STAT4'},
{value: 'STAT5A', id: 'STAT5A'},
{value: 'STAT5B', id: 'STAT5B'},
{value: 'STAT6', id: 'STAT6'},
{value: 'STATH', id: 'STATH'},
{value: 'STAU1', id: 'STAU1'},
{value: 'STAU2', id: 'STAU2'},
{value: 'STAU2-AS1', id: 'STAU2-AS1'},
{value: 'STBD1', id: 'STBD1'},
{value: 'STC1', id: 'STC1'},
{value: 'STC2', id: 'STC2'},
{value: 'STEAP1', id: 'STEAP1'},
{value: 'STEAP1B', id: 'STEAP1B'},
{value: 'STEAP2', id: 'STEAP2'},
{value: 'STEAP2-AS1', id: 'STEAP2-AS1'},
{value: 'STEAP3', id: 'STEAP3'},
{value: 'STEAP3-AS1', id: 'STEAP3-AS1'},
{value: 'STEAP4', id: 'STEAP4'},
{value: 'STEEP1', id: 'STEEP1'},
{value: 'STIL', id: 'STIL'},
{value: 'STIM1', id: 'STIM1'},
{value: 'STIM1-AS1', id: 'STIM1-AS1'},
{value: 'STIM2', id: 'STIM2'},
{value: 'STIM2-AS1', id: 'STIM2-AS1'},
{value: 'STIMATE', id: 'STIMATE'},
{value: 'STIMATE-MUSTN1', id: 'STIMATE-MUSTN1'},
{value: 'STING1', id: 'STING1'},
{value: 'STIP1', id: 'STIP1'},
{value: 'STK10', id: 'STK10'},
{value: 'STK11', id: 'STK11'},
{value: 'STK11IP', id: 'STK11IP'},
{value: 'STK16', id: 'STK16'},
{value: 'STK16P1', id: 'STK16P1'},
{value: 'STK17A', id: 'STK17A'},
{value: 'STK17B', id: 'STK17B'},
{value: 'STK19', id: 'STK19'},
{value: 'STK19B', id: 'STK19B'},
{value: 'STK24', id: 'STK24'},
{value: 'STK24-AS1', id: 'STK24-AS1'},
{value: 'STK25', id: 'STK25'},
{value: 'STK25P1', id: 'STK25P1'},
{value: 'STK26', id: 'STK26'},
{value: 'STK3', id: 'STK3'},
{value: 'STK31', id: 'STK31'},
{value: 'STK32A', id: 'STK32A'},
{value: 'STK32A-AS1', id: 'STK32A-AS1'},
{value: 'STK32B', id: 'STK32B'},
{value: 'STK32C', id: 'STK32C'},
{value: 'STK33', id: 'STK33'},
{value: 'STK33P1', id: 'STK33P1'},
{value: 'STK35', id: 'STK35'},
{value: 'STK36', id: 'STK36'},
{value: 'STK38', id: 'STK38'},
{value: 'STK38L', id: 'STK38L'},
{value: 'STK39', id: 'STK39'},
{value: 'STK4', id: 'STK4'},
{value: 'STK4-AS1', id: 'STK4-AS1'},
{value: 'STK40', id: 'STK40'},
{value: 'STKLD1', id: 'STKLD1'},
{value: 'STMN1', id: 'STMN1'},
{value: 'STMN1P1', id: 'STMN1P1'},
{value: 'STMN1P2', id: 'STMN1P2'},
{value: 'STMN2', id: 'STMN2'},
{value: 'STMN3', id: 'STMN3'},
{value: 'STMN4', id: 'STMN4'},
{value: 'STMND1', id: 'STMND1'},
{value: 'STMP1', id: 'STMP1'},
{value: 'STN1', id: 'STN1'},
{value: 'STOM', id: 'STOM'},
{value: 'STOML1', id: 'STOML1'},
{value: 'STOML2', id: 'STOML2'},
{value: 'STOML3', id: 'STOML3'},
{value: 'STON1', id: 'STON1'},
{value: 'STON1-GTF2A1L', id: 'STON1-GTF2A1L'},
{value: 'STON2', id: 'STON2'},
{value: 'STOX1', id: 'STOX1'},
{value: 'STOX2', id: 'STOX2'},
{value: 'STPG1', id: 'STPG1'},
{value: 'STPG2', id: 'STPG2'},
{value: 'STPG3', id: 'STPG3'},
{value: 'STPG3-AS1', id: 'STPG3-AS1'},
{value: 'STPG4', id: 'STPG4'},
{value: 'STRA6', id: 'STRA6'},
{value: 'STRA8', id: 'STRA8'},
{value: 'STRADA', id: 'STRADA'},
{value: 'STRADB', id: 'STRADB'},
{value: 'STRADBP1', id: 'STRADBP1'},
{value: 'STRAP', id: 'STRAP'},
{value: 'STRBP', id: 'STRBP'},
{value: 'STRC', id: 'STRC'},
{value: 'STRCP1', id: 'STRCP1'},
{value: 'STRIP1', id: 'STRIP1'},
{value: 'STRIP2', id: 'STRIP2'},
{value: 'STRIT1', id: 'STRIT1'},
{value: 'STRN', id: 'STRN'},
{value: 'STRN3', id: 'STRN3'},
{value: 'STRN4', id: 'STRN4'},
{value: 'STS', id: 'STS'},
{value: 'STT3A', id: 'STT3A'},
{value: 'STT3B', id: 'STT3B'},
{value: 'STUB1', id: 'STUB1'},
{value: 'STUB1-DT', id: 'STUB1-DT'},
{value: 'STUM', id: 'STUM'},
{value: 'STX10', id: 'STX10'},
{value: 'STX11', id: 'STX11'},
{value: 'STX12', id: 'STX12'},
{value: 'STX16', id: 'STX16'},
{value: 'STX16-NPEPL1', id: 'STX16-NPEPL1'},
{value: 'STX17', id: 'STX17'},
{value: 'STX17-DT', id: 'STX17-DT'},
{value: 'STX18', id: 'STX18'},
{value: 'STX18-AS1', id: 'STX18-AS1'},
{value: 'STX18-IT1', id: 'STX18-IT1'},
{value: 'STX1A', id: 'STX1A'},
{value: 'STX1B', id: 'STX1B'},
{value: 'STX2', id: 'STX2'},
{value: 'STX3', id: 'STX3'},
{value: 'STX4', id: 'STX4'},
{value: 'STX5', id: 'STX5'},
{value: 'STX5-DT', id: 'STX5-DT'},
{value: 'STX6', id: 'STX6'},
{value: 'STX7', id: 'STX7'},
{value: 'STX8', id: 'STX8'},
{value: 'STXBP1', id: 'STXBP1'},
{value: 'STXBP2', id: 'STXBP2'},
{value: 'STXBP3', id: 'STXBP3'},
{value: 'STXBP4', id: 'STXBP4'},
{value: 'STXBP5', id: 'STXBP5'},
{value: 'STXBP5-AS1', id: 'STXBP5-AS1'},
{value: 'STXBP5L', id: 'STXBP5L'},
{value: 'STXBP6', id: 'STXBP6'},
{value: 'STYK1', id: 'STYK1'},
{value: 'STYX', id: 'STYX'},
{value: 'STYXL1', id: 'STYXL1'},
{value: 'STYXL2', id: 'STYXL2'},
{value: 'SUB1', id: 'SUB1'},
{value: 'SUB1P1', id: 'SUB1P1'},
{value: 'SUCLA2', id: 'SUCLA2'},
{value: 'SUCLA2-AS1', id: 'SUCLA2-AS1'},
{value: 'SUCLG1', id: 'SUCLG1'},
{value: 'SUCLG2', id: 'SUCLG2'},
{value: 'SUCLG2-AS1', id: 'SUCLG2-AS1'},
{value: 'SUCLG2P2', id: 'SUCLG2P2'},
{value: 'SUCNR1', id: 'SUCNR1'},
{value: 'SUCO', id: 'SUCO'},
{value: 'SUDS3', id: 'SUDS3'},
{value: 'SUDS3P1', id: 'SUDS3P1'},
{value: 'SUFU', id: 'SUFU'},
{value: 'SUGCT', id: 'SUGCT'},
{value: 'SUGP1', id: 'SUGP1'},
{value: 'SUGP2', id: 'SUGP2'},
{value: 'SUGT1', id: 'SUGT1'},
{value: 'SUGT1-DT', id: 'SUGT1-DT'},
{value: 'SUGT1P1', id: 'SUGT1P1'},
{value: 'SUGT1P3', id: 'SUGT1P3'},
{value: 'SUGT1P4', id: 'SUGT1P4'},
{value: 'SUGT1P4-STRA6LP', id: 'SUGT1P4-STRA6LP'},
{value: 'SUGT1P4-STRA6LP-CCDC180', id: 'SUGT1P4-STRA6LP-CCDC180'},
{value: 'SULF1', id: 'SULF1'},
{value: 'SULF2', id: 'SULF2'},
{value: 'SULT1A1', id: 'SULT1A1'},
{value: 'SULT1A2', id: 'SULT1A2'},
{value: 'SULT1A3', id: 'SULT1A3'},
{value: 'SULT1A4', id: 'SULT1A4'},
{value: 'SULT1B1', id: 'SULT1B1'},
{value: 'SULT1C2', id: 'SULT1C2'},
{value: 'SULT1C2P1', id: 'SULT1C2P1'},
{value: 'SULT1C2P2', id: 'SULT1C2P2'},
{value: 'SULT1C3', id: 'SULT1C3'},
{value: 'SULT1C4', id: 'SULT1C4'},
{value: 'SULT1D1P', id: 'SULT1D1P'},
{value: 'SULT1E1', id: 'SULT1E1'},
{value: 'SULT2A1', id: 'SULT2A1'},
{value: 'SULT2B1', id: 'SULT2B1'},
{value: 'SULT4A1', id: 'SULT4A1'},
{value: 'SULT6B1', id: 'SULT6B1'},
{value: 'SUMF1', id: 'SUMF1'},
{value: 'SUMF2', id: 'SUMF2'},
{value: 'SUMO1', id: 'SUMO1'},
{value: 'SUMO1P1', id: 'SUMO1P1'},
{value: 'SUMO1P2', id: 'SUMO1P2'},
{value: 'SUMO1P3', id: 'SUMO1P3'},
{value: 'SUMO1P4', id: 'SUMO1P4'},
{value: 'SUMO2', id: 'SUMO2'},
{value: 'SUMO2P1', id: 'SUMO2P1'},
{value: 'SUMO2P12', id: 'SUMO2P12'},
{value: 'SUMO2P13', id: 'SUMO2P13'},
{value: 'SUMO2P16', id: 'SUMO2P16'},
{value: 'SUMO2P17', id: 'SUMO2P17'},
{value: 'SUMO2P18', id: 'SUMO2P18'},
{value: 'SUMO2P20', id: 'SUMO2P20'},
{value: 'SUMO2P21', id: 'SUMO2P21'},
{value: 'SUMO2P3', id: 'SUMO2P3'},
{value: 'SUMO2P4', id: 'SUMO2P4'},
{value: 'SUMO2P6', id: 'SUMO2P6'},
{value: 'SUMO2P7', id: 'SUMO2P7'},
{value: 'SUMO3', id: 'SUMO3'},
{value: 'SUMO4', id: 'SUMO4'},
{value: 'SUN1', id: 'SUN1'},
{value: 'SUN2', id: 'SUN2'},
{value: 'SUN3', id: 'SUN3'},
{value: 'SUN5', id: 'SUN5'},
{value: 'SUNO1', id: 'SUNO1'},
{value: 'SUOX', id: 'SUOX'},
{value: 'SUPT16H', id: 'SUPT16H'},
{value: 'SUPT16HP1', id: 'SUPT16HP1'},
{value: 'SUPT20H', id: 'SUPT20H'},
{value: 'SUPT3H', id: 'SUPT3H'},
{value: 'SUPT4H1', id: 'SUPT4H1'},
{value: 'SUPT4H1P2', id: 'SUPT4H1P2'},
{value: 'SUPT5H', id: 'SUPT5H'},
{value: 'SUPT6H', id: 'SUPT6H'},
{value: 'SUPT7L', id: 'SUPT7L'},
{value: 'SUPV3L1', id: 'SUPV3L1'},
{value: 'SURF1', id: 'SURF1'},
{value: 'SURF2', id: 'SURF2'},
{value: 'SURF4', id: 'SURF4'},
{value: 'SURF6', id: 'SURF6'},
{value: 'SUSD1', id: 'SUSD1'},
{value: 'SUSD2', id: 'SUSD2'},
{value: 'SUSD2P2', id: 'SUSD2P2'},
{value: 'SUSD3', id: 'SUSD3'},
{value: 'SUSD4', id: 'SUSD4'},
{value: 'SUSD5', id: 'SUSD5'},
{value: 'SUSD6', id: 'SUSD6'},
{value: 'SUV39H1', id: 'SUV39H1'},
{value: 'SUV39H2', id: 'SUV39H2'},
{value: 'SUV39H2-DT', id: 'SUV39H2-DT'},
{value: 'SUZ12', id: 'SUZ12'},
{value: 'SUZ12P1', id: 'SUZ12P1'},
{value: 'SV2A', id: 'SV2A'},
{value: 'SV2B', id: 'SV2B'},
{value: 'SV2C', id: 'SV2C'},
{value: 'SV2C-AS1', id: 'SV2C-AS1'},
{value: 'SVBP', id: 'SVBP'},
{value: 'SVEP1', id: 'SVEP1'},
{value: 'SVIL', id: 'SVIL'},
{value: 'SVIL-AS1', id: 'SVIL-AS1'},
{value: 'SVIL2P', id: 'SVIL2P'},
{value: 'SVIP', id: 'SVIP'},
{value: 'SVOP', id: 'SVOP'},
{value: 'SVOPL', id: 'SVOPL'},
{value: 'SWAP70', id: 'SWAP70'},
{value: 'SWI5', id: 'SWI5'},
{value: 'SWSAP1', id: 'SWSAP1'},
{value: 'SWT1', id: 'SWT1'},
{value: 'SYAP1', id: 'SYAP1'},
{value: 'SYBU', id: 'SYBU'},
{value: 'SYCE1', id: 'SYCE1'},
{value: 'SYCE1L', id: 'SYCE1L'},
{value: 'SYCE2', id: 'SYCE2'},
{value: 'SYCE3', id: 'SYCE3'},
{value: 'SYCN', id: 'SYCN'},
{value: 'SYCP1', id: 'SYCP1'},
{value: 'SYCP2', id: 'SYCP2'},
{value: 'SYCP2L', id: 'SYCP2L'},
{value: 'SYCP3', id: 'SYCP3'},
{value: 'SYDE1', id: 'SYDE1'},
{value: 'SYDE2', id: 'SYDE2'},
{value: 'SYF2', id: 'SYF2'},
{value: 'SYK', id: 'SYK'},
{value: 'SYMPK', id: 'SYMPK'},
{value: 'SYN1', id: 'SYN1'},
{value: 'SYN2', id: 'SYN2'},
{value: 'SYN3', id: 'SYN3'},
{value: 'SYN3-AS1', id: 'SYN3-AS1'},
{value: 'SYNC', id: 'SYNC'},
{value: 'SYNCRIP', id: 'SYNCRIP'},
{value: 'SYNDIG1', id: 'SYNDIG1'},
{value: 'SYNDIG1L', id: 'SYNDIG1L'},
{value: 'SYNE1', id: 'SYNE1'},
{value: 'SYNE1-AS1', id: 'SYNE1-AS1'},
{value: 'SYNE2', id: 'SYNE2'},
{value: 'SYNE3', id: 'SYNE3'},
{value: 'SYNE4', id: 'SYNE4'},
{value: 'SYNGAP1', id: 'SYNGAP1'},
{value: 'SYNGAP1-AS1', id: 'SYNGAP1-AS1'},
{value: 'SYNGR1', id: 'SYNGR1'},
{value: 'SYNGR2', id: 'SYNGR2'},
{value: 'SYNGR2P1', id: 'SYNGR2P1'},
{value: 'SYNGR3', id: 'SYNGR3'},
{value: 'SYNGR4', id: 'SYNGR4'},
{value: 'SYNJ1', id: 'SYNJ1'},
{value: 'SYNJ2', id: 'SYNJ2'},
{value: 'SYNJ2BP', id: 'SYNJ2BP'},
{value: 'SYNJ2BP-COX16', id: 'SYNJ2BP-COX16'},
{value: 'SYNM', id: 'SYNM'},
{value: 'SYNM-AS1', id: 'SYNM-AS1'},
{value: 'SYNPO', id: 'SYNPO'},
{value: 'SYNPO2', id: 'SYNPO2'},
{value: 'SYNPO2L', id: 'SYNPO2L'},
{value: 'SYNPO2L-AS1', id: 'SYNPO2L-AS1'},
{value: 'SYNPR', id: 'SYNPR'},
{value: 'SYNRG', id: 'SYNRG'},
{value: 'SYP', id: 'SYP'},
{value: 'SYPL1', id: 'SYPL1'},
{value: 'SYPL1P1', id: 'SYPL1P1'},
{value: 'SYPL1P2', id: 'SYPL1P2'},
{value: 'SYPL2', id: 'SYPL2'},
{value: 'SYS1', id: 'SYS1'},
{value: 'SYS1-DBNDD2', id: 'SYS1-DBNDD2'},
{value: 'SYT1', id: 'SYT1'},
{value: 'SYT10', id: 'SYT10'},
{value: 'SYT11', id: 'SYT11'},
{value: 'SYT12', id: 'SYT12'},
{value: 'SYT13', id: 'SYT13'},
{value: 'SYT14', id: 'SYT14'},
{value: 'SYT15', id: 'SYT15'},
{value: 'SYT16', id: 'SYT16'},
{value: 'SYT17', id: 'SYT17'},
{value: 'SYT2', id: 'SYT2'},
{value: 'SYT3', id: 'SYT3'},
{value: 'SYT4', id: 'SYT4'},
{value: 'SYT5', id: 'SYT5'},
{value: 'SYT6', id: 'SYT6'},
{value: 'SYT7', id: 'SYT7'},
{value: 'SYT8', id: 'SYT8'},
{value: 'SYT9', id: 'SYT9'},
{value: 'SYTL1', id: 'SYTL1'},
{value: 'SYTL2', id: 'SYTL2'},
{value: 'SYTL3', id: 'SYTL3'},
{value: 'SYTL4', id: 'SYTL4'},
{value: 'SYVN1', id: 'SYVN1'},
{value: 'SZRD1', id: 'SZRD1'},
{value: 'SZT2', id: 'SZT2'},
{value: 'TAAR1', id: 'TAAR1'},
{value: 'TAAR2', id: 'TAAR2'},
{value: 'TAAR3P', id: 'TAAR3P'},
{value: 'TAAR4P', id: 'TAAR4P'},
{value: 'TAAR5', id: 'TAAR5'},
{value: 'TAAR6', id: 'TAAR6'},
{value: 'TAAR7P', id: 'TAAR7P'},
{value: 'TAAR8', id: 'TAAR8'},
{value: 'TAAR9', id: 'TAAR9'},
{value: 'TAB1', id: 'TAB1'},
{value: 'TAB2', id: 'TAB2'},
{value: 'TAB3', id: 'TAB3'},
{value: 'TAC1', id: 'TAC1'},
{value: 'TAC3', id: 'TAC3'},
{value: 'TAC4', id: 'TAC4'},
{value: 'TACC1', id: 'TACC1'},
{value: 'TACC1P1', id: 'TACC1P1'},
{value: 'TACC2', id: 'TACC2'},
{value: 'TACC3', id: 'TACC3'},
{value: 'TACO1', id: 'TACO1'},
{value: 'TACR1', id: 'TACR1'},
{value: 'TACR2', id: 'TACR2'},
{value: 'TACR3', id: 'TACR3'},
{value: 'TACR3-AS1', id: 'TACR3-AS1'},
{value: 'TACSTD2', id: 'TACSTD2'},
{value: 'TADA1', id: 'TADA1'},
{value: 'TADA2A', id: 'TADA2A'},
{value: 'TADA2B', id: 'TADA2B'},
{value: 'TADA3', id: 'TADA3'},
{value: 'TAF1', id: 'TAF1'},
{value: 'TAF10', id: 'TAF10'},
{value: 'TAF11', id: 'TAF11'},
{value: 'TAF11L11', id: 'TAF11L11'},
{value: 'TAF11L12', id: 'TAF11L12'},
{value: 'TAF11L14', id: 'TAF11L14'},
{value: 'TAF11L2', id: 'TAF11L2'},
{value: 'TAF12', id: 'TAF12'},
{value: 'TAF13', id: 'TAF13'},
{value: 'TAF15', id: 'TAF15'},
{value: 'TAF1A', id: 'TAF1A'},
{value: 'TAF1A-AS1', id: 'TAF1A-AS1'},
{value: 'TAF1B', id: 'TAF1B'},
{value: 'TAF1C', id: 'TAF1C'},
{value: 'TAF1D', id: 'TAF1D'},
{value: 'TAF1L', id: 'TAF1L'},
{value: 'TAF2', id: 'TAF2'},
{value: 'TAF3', id: 'TAF3'},
{value: 'TAF4', id: 'TAF4'},
{value: 'TAF4B', id: 'TAF4B'},
{value: 'TAF5', id: 'TAF5'},
{value: 'TAF5L', id: 'TAF5L'},
{value: 'TAF5LP1', id: 'TAF5LP1'},
{value: 'TAF6', id: 'TAF6'},
{value: 'TAF6L', id: 'TAF6L'},
{value: 'TAF7', id: 'TAF7'},
{value: 'TAF8', id: 'TAF8'},
{value: 'TAF9', id: 'TAF9'},
{value: 'TAF9B', id: 'TAF9B'},
{value: 'TAF9BP2', id: 'TAF9BP2'},
{value: 'TAFA1', id: 'TAFA1'},
{value: 'TAFA2', id: 'TAFA2'},
{value: 'TAFA3', id: 'TAFA3'},
{value: 'TAFA4', id: 'TAFA4'},
{value: 'TAFA5', id: 'TAFA5'},
{value: 'TAFAZZIN', id: 'TAFAZZIN'},
{value: 'TAGAP', id: 'TAGAP'},
{value: 'TAGAP-AS1', id: 'TAGAP-AS1'},
{value: 'TAGLN', id: 'TAGLN'},
{value: 'TAGLN2', id: 'TAGLN2'},
{value: 'TAGLN2P1', id: 'TAGLN2P1'},
{value: 'TAGLN3', id: 'TAGLN3'},
{value: 'TAL1', id: 'TAL1'},
{value: 'TAL2', id: 'TAL2'},
{value: 'TALDO1', id: 'TALDO1'},
{value: 'TAMALIN', id: 'TAMALIN'},
{value: 'TAMM41', id: 'TAMM41'},
{value: 'TANC1', id: 'TANC1'},
{value: 'TANC2', id: 'TANC2'},
{value: 'TANGO2', id: 'TANGO2'},
{value: 'TANGO6', id: 'TANGO6'},
{value: 'TANK', id: 'TANK'},
{value: 'TANK-AS1', id: 'TANK-AS1'},
{value: 'TAOK1', id: 'TAOK1'},
{value: 'TAOK2', id: 'TAOK2'},
{value: 'TAOK3', id: 'TAOK3'},
{value: 'TAP1', id: 'TAP1'},
{value: 'TAP2', id: 'TAP2'},
{value: 'TAPBP', id: 'TAPBP'},
{value: 'TAPBPL', id: 'TAPBPL'},
{value: 'TAPT1', id: 'TAPT1'},
{value: 'TAPT1-AS1', id: 'TAPT1-AS1'},
{value: 'TARBP1', id: 'TARBP1'},
{value: 'TARBP2', id: 'TARBP2'},
{value: 'TARDBP', id: 'TARDBP'},
{value: 'TARDBPP1', id: 'TARDBPP1'},
{value: 'TARDBPP2', id: 'TARDBPP2'},
{value: 'TARDBPP4', id: 'TARDBPP4'},
{value: 'TARID', id: 'TARID'},
{value: 'TARM1', id: 'TARM1'},
{value: 'TARS1', id: 'TARS1'},
{value: 'TARS1-DT', id: 'TARS1-DT'},
{value: 'TARS2', id: 'TARS2'},
{value: 'TARS2P1', id: 'TARS2P1'},
{value: 'TARS3', id: 'TARS3'},
{value: 'TAS1R1', id: 'TAS1R1'},
{value: 'TAS1R2', id: 'TAS1R2'},
{value: 'TAS1R3', id: 'TAS1R3'},
{value: 'TAS2R1', id: 'TAS2R1'},
{value: 'TAS2R14', id: 'TAS2R14'},
{value: 'TAS2R16', id: 'TAS2R16'},
{value: 'TAS2R20', id: 'TAS2R20'},
{value: 'TAS2R3', id: 'TAS2R3'},
{value: 'TAS2R30', id: 'TAS2R30'},
{value: 'TAS2R38', id: 'TAS2R38'},
{value: 'TAS2R39', id: 'TAS2R39'},
{value: 'TAS2R4', id: 'TAS2R4'},
{value: 'TAS2R40', id: 'TAS2R40'},
{value: 'TAS2R41', id: 'TAS2R41'},
{value: 'TAS2R42', id: 'TAS2R42'},
{value: 'TAS2R5', id: 'TAS2R5'},
{value: 'TAS2R60', id: 'TAS2R60'},
{value: 'TAS2R62P', id: 'TAS2R62P'},
{value: 'TAS2R7', id: 'TAS2R7'},
{value: 'TAS2R8', id: 'TAS2R8'},
{value: 'TAS2R9', id: 'TAS2R9'},
{value: 'TASL', id: 'TASL'},
{value: 'TASOR', id: 'TASOR'},
{value: 'TASOR2', id: 'TASOR2'},
{value: 'TASP1', id: 'TASP1'},
{value: 'TAT', id: 'TAT'},
{value: 'TAT-AS1', id: 'TAT-AS1'},
{value: 'TATDN1', id: 'TATDN1'},
{value: 'TATDN1P1', id: 'TATDN1P1'},
{value: 'TATDN2', id: 'TATDN2'},
{value: 'TATDN2P2', id: 'TATDN2P2'},
{value: 'TATDN2P3', id: 'TATDN2P3'},
{value: 'TATDN3', id: 'TATDN3'},
{value: 'TAX1BP1', id: 'TAX1BP1'},
{value: 'TAX1BP1-AS1', id: 'TAX1BP1-AS1'},
{value: 'TAX1BP3', id: 'TAX1BP3'},
{value: 'TBATA', id: 'TBATA'},
{value: 'TBC1D1', id: 'TBC1D1'},
{value: 'TBC1D10A', id: 'TBC1D10A'},
{value: 'TBC1D10B', id: 'TBC1D10B'},
{value: 'TBC1D10C', id: 'TBC1D10C'},
{value: 'TBC1D12', id: 'TBC1D12'},
{value: 'TBC1D13', id: 'TBC1D13'},
{value: 'TBC1D14', id: 'TBC1D14'},
{value: 'TBC1D15', id: 'TBC1D15'},
{value: 'TBC1D16', id: 'TBC1D16'},
{value: 'TBC1D17', id: 'TBC1D17'},
{value: 'TBC1D19', id: 'TBC1D19'},
{value: 'TBC1D2', id: 'TBC1D2'},
{value: 'TBC1D20', id: 'TBC1D20'},
{value: 'TBC1D21', id: 'TBC1D21'},
{value: 'TBC1D22A', id: 'TBC1D22A'},
{value: 'TBC1D22A-AS1', id: 'TBC1D22A-AS1'},
{value: 'TBC1D22A-DT', id: 'TBC1D22A-DT'},
{value: 'TBC1D22B', id: 'TBC1D22B'},
{value: 'TBC1D23', id: 'TBC1D23'},
{value: 'TBC1D24', id: 'TBC1D24'},
{value: 'TBC1D25', id: 'TBC1D25'},
{value: 'TBC1D26', id: 'TBC1D26'},
{value: 'TBC1D26-AS1', id: 'TBC1D26-AS1'},
{value: 'TBC1D27P', id: 'TBC1D27P'},
{value: 'TBC1D28', id: 'TBC1D28'},
{value: 'TBC1D29P', id: 'TBC1D29P'},
{value: 'TBC1D2B', id: 'TBC1D2B'},
{value: 'TBC1D3', id: 'TBC1D3'},
{value: 'TBC1D30', id: 'TBC1D30'},
{value: 'TBC1D31', id: 'TBC1D31'},
{value: 'TBC1D32', id: 'TBC1D32'},
{value: 'TBC1D3B', id: 'TBC1D3B'},
{value: 'TBC1D3C', id: 'TBC1D3C'},
{value: 'TBC1D3D', id: 'TBC1D3D'},
{value: 'TBC1D3E', id: 'TBC1D3E'},
{value: 'TBC1D3F', id: 'TBC1D3F'},
{value: 'TBC1D3G', id: 'TBC1D3G'},
{value: 'TBC1D3H', id: 'TBC1D3H'},
{value: 'TBC1D3I', id: 'TBC1D3I'},
{value: 'TBC1D3JP', id: 'TBC1D3JP'},
{value: 'TBC1D3K', id: 'TBC1D3K'},
{value: 'TBC1D3L', id: 'TBC1D3L'},
{value: 'TBC1D3P1-DHX40P1', id: 'TBC1D3P1-DHX40P1'},
{value: 'TBC1D3P2', id: 'TBC1D3P2'},
{value: 'TBC1D3P3', id: 'TBC1D3P3'},
{value: 'TBC1D3P4', id: 'TBC1D3P4'},
{value: 'TBC1D3P5', id: 'TBC1D3P5'},
{value: 'TBC1D3P6', id: 'TBC1D3P6'},
{value: 'TBC1D3P7', id: 'TBC1D3P7'},
{value: 'TBC1D4', id: 'TBC1D4'},
{value: 'TBC1D5', id: 'TBC1D5'},
{value: 'TBC1D7', id: 'TBC1D7'},
{value: 'TBC1D8', id: 'TBC1D8'},
{value: 'TBC1D8-AS1', id: 'TBC1D8-AS1'},
{value: 'TBC1D8B', id: 'TBC1D8B'},
{value: 'TBC1D9', id: 'TBC1D9'},
{value: 'TBC1D9B', id: 'TBC1D9B'},
{value: 'TBCA', id: 'TBCA'},
{value: 'TBCAP3', id: 'TBCAP3'},
{value: 'TBCB', id: 'TBCB'},
{value: 'TBCC', id: 'TBCC'},
{value: 'TBCCD1', id: 'TBCCD1'},
{value: 'TBCD', id: 'TBCD'},
{value: 'TBCE', id: 'TBCE'},
{value: 'TBCEL', id: 'TBCEL'},
{value: 'TBCEL-TECTA', id: 'TBCEL-TECTA'},
{value: 'TBCK', id: 'TBCK'},
{value: 'TBILA', id: 'TBILA'},
{value: 'TBK1', id: 'TBK1'},
{value: 'TBKBP1', id: 'TBKBP1'},
{value: 'TBL1X', id: 'TBL1X'},
{value: 'TBL1XR1', id: 'TBL1XR1'},
{value: 'TBL1XR1-AS1', id: 'TBL1XR1-AS1'},
{value: 'TBL1Y', id: 'TBL1Y'},
{value: 'TBL2', id: 'TBL2'},
{value: 'TBL3', id: 'TBL3'},
{value: 'TBP', id: 'TBP'},
{value: 'TBPL1', id: 'TBPL1'},
{value: 'TBPL2', id: 'TBPL2'},
{value: 'TBR1', id: 'TBR1'},
{value: 'TBRG1', id: 'TBRG1'},
{value: 'TBRG4', id: 'TBRG4'},
{value: 'TBX1', id: 'TBX1'},
{value: 'TBX10', id: 'TBX10'},
{value: 'TBX15', id: 'TBX15'},
{value: 'TBX18', id: 'TBX18'},
{value: 'TBX18-AS1', id: 'TBX18-AS1'},
{value: 'TBX19', id: 'TBX19'},
{value: 'TBX2', id: 'TBX2'},
{value: 'TBX2-AS1', id: 'TBX2-AS1'},
{value: 'TBX20', id: 'TBX20'},
{value: 'TBX21', id: 'TBX21'},
{value: 'TBX3', id: 'TBX3'},
{value: 'TBX3-AS1', id: 'TBX3-AS1'},
{value: 'TBX4', id: 'TBX4'},
{value: 'TBX5', id: 'TBX5'},
{value: 'TBX5-AS1', id: 'TBX5-AS1'},
{value: 'TBX6', id: 'TBX6'},
{value: 'TBXA2R', id: 'TBXA2R'},
{value: 'TBXAS1', id: 'TBXAS1'},
{value: 'TBXT', id: 'TBXT'},
{value: 'TC2N', id: 'TC2N'},
{value: 'TCAF1', id: 'TCAF1'},
{value: 'TCAF1P1', id: 'TCAF1P1'},
{value: 'TCAF2', id: 'TCAF2'},
{value: 'TCAF2C', id: 'TCAF2C'},
{value: 'TCAF2P1', id: 'TCAF2P1'},
{value: 'TCAIM', id: 'TCAIM'},
{value: 'TCAM1P', id: 'TCAM1P'},
{value: 'TCAP', id: 'TCAP'},
{value: 'TCEA1', id: 'TCEA1'},
{value: 'TCEA1P1', id: 'TCEA1P1'},
{value: 'TCEA1P2', id: 'TCEA1P2'},
{value: 'TCEA1P3', id: 'TCEA1P3'},
{value: 'TCEA2', id: 'TCEA2'},
{value: 'TCEA3', id: 'TCEA3'},
{value: 'TCEAL1', id: 'TCEAL1'},
{value: 'TCEAL2', id: 'TCEAL2'},
{value: 'TCEAL3', id: 'TCEAL3'},
{value: 'TCEAL4', id: 'TCEAL4'},
{value: 'TCEAL4P1', id: 'TCEAL4P1'},
{value: 'TCEAL7', id: 'TCEAL7'},
{value: 'TCEAL8', id: 'TCEAL8'},
{value: 'TCEAL9', id: 'TCEAL9'},
{value: 'TCEANC', id: 'TCEANC'},
{value: 'TCEANC2', id: 'TCEANC2'},
{value: 'TCERG1', id: 'TCERG1'},
{value: 'TCERG1L', id: 'TCERG1L'},
{value: 'TCERG1L-AS1', id: 'TCERG1L-AS1'},
{value: 'TCERG1P2', id: 'TCERG1P2'},
{value: 'TCF12', id: 'TCF12'},
{value: 'TCF15', id: 'TCF15'},
{value: 'TCF19', id: 'TCF19'},
{value: 'TCF20', id: 'TCF20'},
{value: 'TCF21', id: 'TCF21'},
{value: 'TCF23', id: 'TCF23'},
{value: 'TCF24', id: 'TCF24'},
{value: 'TCF25', id: 'TCF25'},
{value: 'TCF3', id: 'TCF3'},
{value: 'TCF3P1', id: 'TCF3P1'},
{value: 'TCF4', id: 'TCF4'},
{value: 'TCF4-AS1', id: 'TCF4-AS1'},
{value: 'TCF7', id: 'TCF7'},
{value: 'TCF7L1', id: 'TCF7L1'},
{value: 'TCF7L2', id: 'TCF7L2'},
{value: 'TCFL5', id: 'TCFL5'},
{value: 'TCHH', id: 'TCHH'},
{value: 'TCHHL1', id: 'TCHHL1'},
{value: 'TCHP', id: 'TCHP'},
{value: 'TCIM', id: 'TCIM'},
{value: 'TCIRG1', id: 'TCIRG1'},
{value: 'TCL1A', id: 'TCL1A'},
{value: 'TCL1B', id: 'TCL1B'},
{value: 'TCL6', id: 'TCL6'},
{value: 'TCN1', id: 'TCN1'},
{value: 'TCN2', id: 'TCN2'},
{value: 'TCOF1', id: 'TCOF1'},
{value: 'TCP1', id: 'TCP1'},
{value: 'TCP10L', id: 'TCP10L'},
{value: 'TCP10L2', id: 'TCP10L2'},
{value: 'TCP10L3', id: 'TCP10L3'},
{value: 'TCP11', id: 'TCP11'},
{value: 'TCP11L1', id: 'TCP11L1'},
{value: 'TCP11L2', id: 'TCP11L2'},
{value: 'TCP1P1', id: 'TCP1P1'},
{value: 'TCTA', id: 'TCTA'},
{value: 'TCTE1', id: 'TCTE1'},
{value: 'TCTN1', id: 'TCTN1'},
{value: 'TCTN2', id: 'TCTN2'},
{value: 'TCTN3', id: 'TCTN3'},
{value: 'TDG', id: 'TDG'},
{value: 'TDGF1', id: 'TDGF1'},
{value: 'TDGF1P2', id: 'TDGF1P2'},
{value: 'TDGF1P4', id: 'TDGF1P4'},
{value: 'TDGF1P6', id: 'TDGF1P6'},
{value: 'TDGF1P7', id: 'TDGF1P7'},
{value: 'TDGP1', id: 'TDGP1'},
{value: 'TDH', id: 'TDH'},
{value: 'TDH-AS1', id: 'TDH-AS1'},
{value: 'TDO2', id: 'TDO2'},
{value: 'TDP1', id: 'TDP1'},
{value: 'TDP2', id: 'TDP2'},
{value: 'TDRD1', id: 'TDRD1'},
{value: 'TDRD10', id: 'TDRD10'},
{value: 'TDRD12', id: 'TDRD12'},
{value: 'TDRD15', id: 'TDRD15'},
{value: 'TDRD3', id: 'TDRD3'},
{value: 'TDRD5', id: 'TDRD5'},
{value: 'TDRD6', id: 'TDRD6'},
{value: 'TDRD7', id: 'TDRD7'},
{value: 'TDRD9', id: 'TDRD9'},
{value: 'TDRG1', id: 'TDRG1'},
{value: 'TDRKH', id: 'TDRKH'},
{value: 'TDRKH-AS1', id: 'TDRKH-AS1'},
{value: 'TDRP', id: 'TDRP'},
{value: 'TEAD1', id: 'TEAD1'},
{value: 'TEAD2', id: 'TEAD2'},
{value: 'TEAD3', id: 'TEAD3'},
{value: 'TEAD4', id: 'TEAD4'},
{value: 'TEC', id: 'TEC'},
{value: 'TECPR1', id: 'TECPR1'},
{value: 'TECPR2', id: 'TECPR2'},
{value: 'TECR', id: 'TECR'},
{value: 'TECRL', id: 'TECRL'},
{value: 'TECRP1', id: 'TECRP1'},
{value: 'TECRP2', id: 'TECRP2'},
{value: 'TECTA', id: 'TECTA'},
{value: 'TECTB', id: 'TECTB'},
{value: 'TEDC1', id: 'TEDC1'},
{value: 'TEDC2', id: 'TEDC2'},
{value: 'TEDC2-AS1', id: 'TEDC2-AS1'},
{value: 'TEDDM1', id: 'TEDDM1'},
{value: 'TEDDM2P', id: 'TEDDM2P'},
{value: 'TEF', id: 'TEF'},
{value: 'TEFM', id: 'TEFM'},
{value: 'TEK', id: 'TEK'},
{value: 'TEKT1', id: 'TEKT1'},
{value: 'TEKT2', id: 'TEKT2'},
{value: 'TEKT3', id: 'TEKT3'},
{value: 'TEKT4', id: 'TEKT4'},
{value: 'TEKT4P2', id: 'TEKT4P2'},
{value: 'TEKT4P3', id: 'TEKT4P3'},
{value: 'TEKT5', id: 'TEKT5'},
{value: 'TELO2', id: 'TELO2'},
{value: 'Telomerase-vert', id: 'Telomerase-vert'},
{value: 'TEMN3-AS1', id: 'TEMN3-AS1'},
{value: 'TEN1', id: 'TEN1'},
{value: 'TEN1-CDK3', id: 'TEN1-CDK3'},
{value: 'TENM1', id: 'TENM1'},
{value: 'TENM2', id: 'TENM2'},
{value: 'TENM3', id: 'TENM3'},
{value: 'TENM3-AS1', id: 'TENM3-AS1'},
{value: 'TENM4', id: 'TENM4'},
{value: 'TENT2', id: 'TENT2'},
{value: 'TENT4A', id: 'TENT4A'},
{value: 'TENT4B', id: 'TENT4B'},
{value: 'TENT5A', id: 'TENT5A'},
{value: 'TENT5B', id: 'TENT5B'},
{value: 'TENT5C', id: 'TENT5C'},
{value: 'TEP1', id: 'TEP1'},
{value: 'TEPP', id: 'TEPP'},
{value: 'TEPSIN', id: 'TEPSIN'},
{value: 'TERB1', id: 'TERB1'},
{value: 'TERB2', id: 'TERB2'},
{value: 'TERC', id: 'TERC'},
{value: 'TERF1', id: 'TERF1'},
{value: 'TERF1P2', id: 'TERF1P2'},
{value: 'TERF1P5', id: 'TERF1P5'},
{value: 'TERF1P7', id: 'TERF1P7'},
{value: 'TERF2', id: 'TERF2'},
{value: 'TERF2IP', id: 'TERF2IP'},
{value: 'TERT', id: 'TERT'},
{value: 'TES', id: 'TES'},
{value: 'TESC', id: 'TESC'},
{value: 'TESC-AS1', id: 'TESC-AS1'},
{value: 'TESK1', id: 'TESK1'},
{value: 'TESK2', id: 'TESK2'},
{value: 'TESMIN', id: 'TESMIN'},
{value: 'TESPA1', id: 'TESPA1'},
{value: 'TET1', id: 'TET1'},
{value: 'TET1P1', id: 'TET1P1'},
{value: 'TET2', id: 'TET2'},
{value: 'TET3', id: 'TET3'},
{value: 'TEX10', id: 'TEX10'},
{value: 'TEX101', id: 'TEX101'},
{value: 'TEX12', id: 'TEX12'},
{value: 'TEX14', id: 'TEX14'},
{value: 'TEX15', id: 'TEX15'},
{value: 'TEX19', id: 'TEX19'},
{value: 'TEX2', id: 'TEX2'},
{value: 'TEX21P', id: 'TEX21P'},
{value: 'TEX22', id: 'TEX22'},
{value: 'TEX26', id: 'TEX26'},
{value: 'TEX26-AS1', id: 'TEX26-AS1'},
{value: 'TEX261', id: 'TEX261'},
{value: 'TEX264', id: 'TEX264'},
{value: 'TEX29', id: 'TEX29'},
{value: 'TEX30', id: 'TEX30'},
{value: 'TEX33', id: 'TEX33'},
{value: 'TEX35', id: 'TEX35'},
{value: 'TEX36', id: 'TEX36'},
{value: 'TEX36-AS1', id: 'TEX36-AS1'},
{value: 'TEX37', id: 'TEX37'},
{value: 'TEX38', id: 'TEX38'},
{value: 'TEX41', id: 'TEX41'},
{value: 'TEX43', id: 'TEX43'},
{value: 'TEX44', id: 'TEX44'},
{value: 'TEX45', id: 'TEX45'},
{value: 'TEX46', id: 'TEX46'},
{value: 'TEX48', id: 'TEX48'},
{value: 'TEX49', id: 'TEX49'},
{value: 'TEX51', id: 'TEX51'},
{value: 'TEX52', id: 'TEX52'},
{value: 'TEX53', id: 'TEX53'},
{value: 'TEX54', id: 'TEX54'},
{value: 'TEX55', id: 'TEX55'},
{value: 'TEX9', id: 'TEX9'},
{value: 'TF', id: 'TF'},
{value: 'TFAM', id: 'TFAM'},
{value: 'TFAMP1', id: 'TFAMP1'},
{value: 'TFAMP2', id: 'TFAMP2'},
{value: 'TFAP2A', id: 'TFAP2A'},
{value: 'TFAP2A-AS2', id: 'TFAP2A-AS2'},
{value: 'TFAP2B', id: 'TFAP2B'},
{value: 'TFAP2C', id: 'TFAP2C'},
{value: 'TFAP2D', id: 'TFAP2D'},
{value: 'TFAP2E', id: 'TFAP2E'},
{value: 'TFAP2E-AS1', id: 'TFAP2E-AS1'},
{value: 'TFAP4', id: 'TFAP4'},
{value: 'TFB1M', id: 'TFB1M'},
{value: 'TFB2M', id: 'TFB2M'},
{value: 'TFCP2', id: 'TFCP2'},
{value: 'TFCP2L1', id: 'TFCP2L1'},
{value: 'TFDP1', id: 'TFDP1'},
{value: 'TFDP1P1', id: 'TFDP1P1'},
{value: 'TFDP1P3', id: 'TFDP1P3'},
{value: 'TFDP2', id: 'TFDP2'},
{value: 'TFE3', id: 'TFE3'},
{value: 'TFEB', id: 'TFEB'},
{value: 'TFEC', id: 'TFEC'},
{value: 'TFF1', id: 'TFF1'},
{value: 'TFF2', id: 'TFF2'},
{value: 'TFF3', id: 'TFF3'},
{value: 'TFG', id: 'TFG'},
{value: 'TFGP1', id: 'TFGP1'},
{value: 'TFIP11', id: 'TFIP11'},
{value: 'TFIP11-DT', id: 'TFIP11-DT'},
{value: 'TFPI', id: 'TFPI'},
{value: 'TFPI2', id: 'TFPI2'},
{value: 'TFPT', id: 'TFPT'},
{value: 'TFR2', id: 'TFR2'},
{value: 'TFRC', id: 'TFRC'},
{value: 'TG', id: 'TG'},
{value: 'TGDS', id: 'TGDS'},
{value: 'TGFA', id: 'TGFA'},
{value: 'TGFA-IT1', id: 'TGFA-IT1'},
{value: 'TGFB1', id: 'TGFB1'},
{value: 'TGFB1I1', id: 'TGFB1I1'},
{value: 'TGFB2', id: 'TGFB2'},
{value: 'TGFB2-AS1', id: 'TGFB2-AS1'},
{value: 'TGFB2-OT1', id: 'TGFB2-OT1'},
{value: 'TGFB3', id: 'TGFB3'},
{value: 'TGFBI', id: 'TGFBI'},
{value: 'TGFBR1', id: 'TGFBR1'},
{value: 'TGFBR2', id: 'TGFBR2'},
{value: 'TGFBR3', id: 'TGFBR3'},
{value: 'TGFBR3L', id: 'TGFBR3L'},
{value: 'TGFBRAP1', id: 'TGFBRAP1'},
{value: 'TGIF1', id: 'TGIF1'},
{value: 'TGIF1P1', id: 'TGIF1P1'},
{value: 'TGIF2', id: 'TGIF2'},
{value: 'TGIF2-RAB5IF', id: 'TGIF2-RAB5IF'},
{value: 'TGIF2P1', id: 'TGIF2P1'},
{value: 'TGM1', id: 'TGM1'},
{value: 'TGM2', id: 'TGM2'},
{value: 'TGM3', id: 'TGM3'},
{value: 'TGM4', id: 'TGM4'},
{value: 'TGM5', id: 'TGM5'},
{value: 'TGM6', id: 'TGM6'},
{value: 'TGM7', id: 'TGM7'},
{value: 'TGOLN2', id: 'TGOLN2'},
{value: 'TGS1', id: 'TGS1'},
{value: 'TH', id: 'TH'},
{value: 'TH2LCRR', id: 'TH2LCRR'},
{value: 'THA1P', id: 'THA1P'},
{value: 'THADA', id: 'THADA'},
{value: 'THAP1', id: 'THAP1'},
{value: 'THAP10', id: 'THAP10'},
{value: 'THAP11', id: 'THAP11'},
{value: 'THAP12', id: 'THAP12'},
{value: 'THAP12P2', id: 'THAP12P2'},
{value: 'THAP12P3', id: 'THAP12P3'},
{value: 'THAP12P4', id: 'THAP12P4'},
{value: 'THAP12P5', id: 'THAP12P5'},
{value: 'THAP12P6', id: 'THAP12P6'},
{value: 'THAP12P7', id: 'THAP12P7'},
{value: 'THAP12P8', id: 'THAP12P8'},
{value: 'THAP12P9', id: 'THAP12P9'},
{value: 'THAP2', id: 'THAP2'},
{value: 'THAP3', id: 'THAP3'},
{value: 'THAP4', id: 'THAP4'},
{value: 'THAP5', id: 'THAP5'},
{value: 'THAP5P1', id: 'THAP5P1'},
{value: 'THAP6', id: 'THAP6'},
{value: 'THAP7', id: 'THAP7'},
{value: 'THAP7-AS1', id: 'THAP7-AS1'},
{value: 'THAP8', id: 'THAP8'},
{value: 'THAP9', id: 'THAP9'},
{value: 'THAP9-AS1', id: 'THAP9-AS1'},
{value: 'THBD', id: 'THBD'},
{value: 'THBS1', id: 'THBS1'},
{value: 'THBS1-AS1', id: 'THBS1-AS1'},
{value: 'THBS1-IT1', id: 'THBS1-IT1'},
{value: 'THBS2', id: 'THBS2'},
{value: 'THBS3', id: 'THBS3'},
{value: 'THBS3-AS1', id: 'THBS3-AS1'},
{value: 'THBS4', id: 'THBS4'},
{value: 'THBS4-AS1', id: 'THBS4-AS1'},
{value: 'THCAT155', id: 'THCAT155'},
{value: 'THEG', id: 'THEG'},
{value: 'THEGL', id: 'THEGL'},
{value: 'THEM4', id: 'THEM4'},
{value: 'THEM5', id: 'THEM5'},
{value: 'THEM6', id: 'THEM6'},
{value: 'THEM7P', id: 'THEM7P'},
{value: 'THEMIS', id: 'THEMIS'},
{value: 'THEMIS2', id: 'THEMIS2'},
{value: 'THEMIS3P', id: 'THEMIS3P'},
{value: 'THG1L', id: 'THG1L'},
{value: 'THNSL1', id: 'THNSL1'},
{value: 'THNSL2', id: 'THNSL2'},
{value: 'THOC1', id: 'THOC1'},
{value: 'THOC1-DT', id: 'THOC1-DT'},
{value: 'THOC2', id: 'THOC2'},
{value: 'THOC3', id: 'THOC3'},
{value: 'THOC5', id: 'THOC5'},
{value: 'THOC6', id: 'THOC6'},
{value: 'THOC7', id: 'THOC7'},
{value: 'THOP1', id: 'THOP1'},
{value: 'THORLNC', id: 'THORLNC'},
{value: 'THPO', id: 'THPO'},
{value: 'THRA', id: 'THRA'},
{value: 'THRAP3', id: 'THRAP3'},
{value: 'THRAP3P1', id: 'THRAP3P1'},
{value: 'THRAP3P2', id: 'THRAP3P2'},
{value: 'THRB', id: 'THRB'},
{value: 'THRB-AS1', id: 'THRB-AS1'},
{value: 'THRIL', id: 'THRIL'},
{value: 'THRSP', id: 'THRSP'},
{value: 'THSD1', id: 'THSD1'},
{value: 'THSD1P1', id: 'THSD1P1'},
{value: 'THSD4', id: 'THSD4'},
{value: 'THSD7A', id: 'THSD7A'},
{value: 'THSD7B', id: 'THSD7B'},
{value: 'THTPA', id: 'THTPA'},
{value: 'THUMPD1', id: 'THUMPD1'},
{value: 'THUMPD1P1', id: 'THUMPD1P1'},
{value: 'THUMPD2', id: 'THUMPD2'},
{value: 'THUMPD3', id: 'THUMPD3'},
{value: 'THUMPD3-AS1', id: 'THUMPD3-AS1'},
{value: 'THUMPD3P1', id: 'THUMPD3P1'},
{value: 'THY1', id: 'THY1'},
{value: 'THY1-AS1', id: 'THY1-AS1'},
{value: 'THYN1', id: 'THYN1'},
{value: 'TIA1', id: 'TIA1'},
{value: 'TIAF1', id: 'TIAF1'},
{value: 'TIAL1', id: 'TIAL1'},
{value: 'TIAM1', id: 'TIAM1'},
{value: 'TIAM2', id: 'TIAM2'},
{value: 'TICAM1', id: 'TICAM1'},
{value: 'TICAM2', id: 'TICAM2'},
{value: 'TICAM2-AS1', id: 'TICAM2-AS1'},
{value: 'TICRR', id: 'TICRR'},
{value: 'TIE1', id: 'TIE1'},
{value: 'TIFA', id: 'TIFA'},
{value: 'TIFAB', id: 'TIFAB'},
{value: 'TIGAR', id: 'TIGAR'},
{value: 'TIGD1', id: 'TIGD1'},
{value: 'TIGD2', id: 'TIGD2'},
{value: 'TIGD3', id: 'TIGD3'},
{value: 'TIGD4', id: 'TIGD4'},
{value: 'TIGD5', id: 'TIGD5'},
{value: 'TIGD6', id: 'TIGD6'},
{value: 'TIGD7', id: 'TIGD7'},
{value: 'TIGIT', id: 'TIGIT'},
{value: 'TIMD4', id: 'TIMD4'},
{value: 'TIMELESS', id: 'TIMELESS'},
{value: 'TIMM10', id: 'TIMM10'},
{value: 'TIMM10B', id: 'TIMM10B'},
{value: 'TIMM13', id: 'TIMM13'},
{value: 'TIMM17A', id: 'TIMM17A'},
{value: 'TIMM17B', id: 'TIMM17B'},
{value: 'TIMM17BP1', id: 'TIMM17BP1'},
{value: 'TIMM21', id: 'TIMM21'},
{value: 'TIMM22', id: 'TIMM22'},
{value: 'TIMM23', id: 'TIMM23'},
{value: 'TIMM23B', id: 'TIMM23B'},
{value: 'TIMM23B-AGAP6', id: 'TIMM23B-AGAP6'},
{value: 'TIMM29', id: 'TIMM29'},
{value: 'TIMM44', id: 'TIMM44'},
{value: 'TIMM50', id: 'TIMM50'},
{value: 'TIMM8A', id: 'TIMM8A'},
{value: 'TIMM8AP1', id: 'TIMM8AP1'},
{value: 'TIMM8B', id: 'TIMM8B'},
{value: 'TIMM8BP1', id: 'TIMM8BP1'},
{value: 'TIMM9', id: 'TIMM9'},
{value: 'TIMMDC1', id: 'TIMMDC1'},
{value: 'TIMMDC1-DT', id: 'TIMMDC1-DT'},
{value: 'TIMP1', id: 'TIMP1'},
{value: 'TIMP2', id: 'TIMP2'},
{value: 'TIMP3', id: 'TIMP3'},
{value: 'TIMP4', id: 'TIMP4'},
{value: 'TINAG', id: 'TINAG'},
{value: 'TINAG-AS1', id: 'TINAG-AS1'},
{value: 'TINAGL1', id: 'TINAGL1'},
{value: 'TINCR', id: 'TINCR'},
{value: 'TINF2', id: 'TINF2'},
{value: 'TIPARP', id: 'TIPARP'},
{value: 'TIPARP-AS1', id: 'TIPARP-AS1'},
{value: 'TIPIN', id: 'TIPIN'},
{value: 'TIPRL', id: 'TIPRL'},
{value: 'TIRAP', id: 'TIRAP'},
{value: 'TJAP1', id: 'TJAP1'},
{value: 'TJP1', id: 'TJP1'},
{value: 'TJP2', id: 'TJP2'},
{value: 'TJP3', id: 'TJP3'},
{value: 'TK1', id: 'TK1'},
{value: 'TK2', id: 'TK2'},
{value: 'TKFC', id: 'TKFC'},
{value: 'TKT', id: 'TKT'},
{value: 'TKTL1', id: 'TKTL1'},
{value: 'TKTL2', id: 'TKTL2'},
{value: 'TLCD1', id: 'TLCD1'},
{value: 'TLCD2', id: 'TLCD2'},
{value: 'TLCD3A', id: 'TLCD3A'},
{value: 'TLCD3B', id: 'TLCD3B'},
{value: 'TLCD4', id: 'TLCD4'},
{value: 'TLCD4-RWDD3', id: 'TLCD4-RWDD3'},
{value: 'TLCD5', id: 'TLCD5'},
{value: 'TLDC2', id: 'TLDC2'},
{value: 'TLE1', id: 'TLE1'},
{value: 'TLE1P1', id: 'TLE1P1'},
{value: 'TLE2', id: 'TLE2'},
{value: 'TLE3', id: 'TLE3'},
{value: 'TLE4', id: 'TLE4'},
{value: 'TLE5', id: 'TLE5'},
{value: 'TLE6', id: 'TLE6'},
{value: 'TLE7', id: 'TLE7'},
{value: 'TLK1', id: 'TLK1'},
{value: 'TLK1P1', id: 'TLK1P1'},
{value: 'TLK2', id: 'TLK2'},
{value: 'TLK2P1', id: 'TLK2P1'},
{value: 'TLL1', id: 'TLL1'},
{value: 'TLL2', id: 'TLL2'},
{value: 'TLN1', id: 'TLN1'},
{value: 'TLN2', id: 'TLN2'},
{value: 'TLNRD1', id: 'TLNRD1'},
{value: 'TLR1', id: 'TLR1'},
{value: 'TLR10', id: 'TLR10'},
{value: 'TLR12P', id: 'TLR12P'},
{value: 'TLR2', id: 'TLR2'},
{value: 'TLR3', id: 'TLR3'},
{value: 'TLR4', id: 'TLR4'},
{value: 'TLR5', id: 'TLR5'},
{value: 'TLR6', id: 'TLR6'},
{value: 'TLR7', id: 'TLR7'},
{value: 'TLR8', id: 'TLR8'},
{value: 'TLR9', id: 'TLR9'},
{value: 'TLX1', id: 'TLX1'},
{value: 'TLX1NB', id: 'TLX1NB'},
{value: 'TLX2', id: 'TLX2'},
{value: 'TLX3', id: 'TLX3'},
{value: 'TM2D1', id: 'TM2D1'},
{value: 'TM2D2', id: 'TM2D2'},
{value: 'TM2D3', id: 'TM2D3'},
{value: 'TM4SF1', id: 'TM4SF1'},
{value: 'TM4SF1-AS1', id: 'TM4SF1-AS1'},
{value: 'TM4SF18', id: 'TM4SF18'},
{value: 'TM4SF19', id: 'TM4SF19'},
{value: 'TM4SF19-AS1', id: 'TM4SF19-AS1'},
{value: 'TM4SF19-DYNLT2B', id: 'TM4SF19-DYNLT2B'},
{value: 'TM4SF20', id: 'TM4SF20'},
{value: 'TM4SF4', id: 'TM4SF4'},
{value: 'TM4SF5', id: 'TM4SF5'},
{value: 'TM6SF1', id: 'TM6SF1'},
{value: 'TM6SF2', id: 'TM6SF2'},
{value: 'TM7SF2', id: 'TM7SF2'},
{value: 'TM7SF3', id: 'TM7SF3'},
{value: 'TM9SF1', id: 'TM9SF1'},
{value: 'TM9SF2', id: 'TM9SF2'},
{value: 'TM9SF3', id: 'TM9SF3'},
{value: 'TM9SF4', id: 'TM9SF4'},
{value: 'TMA16', id: 'TMA16'},
{value: 'TMA16P1', id: 'TMA16P1'},
{value: 'TMA7', id: 'TMA7'},
{value: 'TMBIM1', id: 'TMBIM1'},
{value: 'TMBIM4', id: 'TMBIM4'},
{value: 'TMBIM6', id: 'TMBIM6'},
{value: 'TMBIM7P', id: 'TMBIM7P'},
{value: 'TMC1', id: 'TMC1'},
{value: 'TMC2', id: 'TMC2'},
{value: 'TMC3', id: 'TMC3'},
{value: 'TMC3-AS1', id: 'TMC3-AS1'},
{value: 'TMC4', id: 'TMC4'},
{value: 'TMC5', id: 'TMC5'},
{value: 'TMC6', id: 'TMC6'},
{value: 'TMC7', id: 'TMC7'},
{value: 'TMC8', id: 'TMC8'},
{value: 'TMCC1', id: 'TMCC1'},
{value: 'TMCC1-DT', id: 'TMCC1-DT'},
{value: 'TMCC2', id: 'TMCC2'},
{value: 'TMCC3', id: 'TMCC3'},
{value: 'TMCO1', id: 'TMCO1'},
{value: 'TMCO1-AS1', id: 'TMCO1-AS1'},
{value: 'TMCO2', id: 'TMCO2'},
{value: 'TMCO3', id: 'TMCO3'},
{value: 'TMCO4', id: 'TMCO4'},
{value: 'TMCO5A', id: 'TMCO5A'},
{value: 'TMCO5B', id: 'TMCO5B'},
{value: 'TMCO6', id: 'TMCO6'},
{value: 'TMDD1', id: 'TMDD1'},
{value: 'TMED1', id: 'TMED1'},
{value: 'TMED10', id: 'TMED10'},
{value: 'TMED10P1', id: 'TMED10P1'},
{value: 'TMED10P2', id: 'TMED10P2'},
{value: 'TMED11P', id: 'TMED11P'},
{value: 'TMED2', id: 'TMED2'},
{value: 'TMED2-DT', id: 'TMED2-DT'},
{value: 'TMED3', id: 'TMED3'},
{value: 'TMED4', id: 'TMED4'},
{value: 'TMED5', id: 'TMED5'},
{value: 'TMED6', id: 'TMED6'},
{value: 'TMED7', id: 'TMED7'},
{value: 'TMED7-TICAM2', id: 'TMED7-TICAM2'},
{value: 'TMED8', id: 'TMED8'},
{value: 'TMED9', id: 'TMED9'},
{value: 'TMEFF1', id: 'TMEFF1'},
{value: 'TMEFF2', id: 'TMEFF2'},
{value: 'TMEM100', id: 'TMEM100'},
{value: 'TMEM101', id: 'TMEM101'},
{value: 'TMEM102', id: 'TMEM102'},
{value: 'TMEM104', id: 'TMEM104'},
{value: 'TMEM105', id: 'TMEM105'},
{value: 'TMEM106A', id: 'TMEM106A'},
{value: 'TMEM106B', id: 'TMEM106B'},
{value: 'TMEM106C', id: 'TMEM106C'},
{value: 'TMEM107', id: 'TMEM107'},
{value: 'TMEM108', id: 'TMEM108'},
{value: 'TMEM108-AS1', id: 'TMEM108-AS1'},
{value: 'TMEM109', id: 'TMEM109'},
{value: 'TMEM109-DT', id: 'TMEM109-DT'},
{value: 'TMEM11', id: 'TMEM11'},
{value: 'TMEM114', id: 'TMEM114'},
{value: 'TMEM115', id: 'TMEM115'},
{value: 'TMEM116', id: 'TMEM116'},
{value: 'TMEM117', id: 'TMEM117'},
{value: 'TMEM119', id: 'TMEM119'},
{value: 'TMEM120A', id: 'TMEM120A'},
{value: 'TMEM120B', id: 'TMEM120B'},
{value: 'TMEM121', id: 'TMEM121'},
{value: 'TMEM121B', id: 'TMEM121B'},
{value: 'TMEM123', id: 'TMEM123'},
{value: 'TMEM123-DT', id: 'TMEM123-DT'},
{value: 'TMEM125', id: 'TMEM125'},
{value: 'TMEM126A', id: 'TMEM126A'},
{value: 'TMEM126B', id: 'TMEM126B'},
{value: 'TMEM127', id: 'TMEM127'},
{value: 'TMEM128', id: 'TMEM128'},
{value: 'TMEM129', id: 'TMEM129'},
{value: 'TMEM130', id: 'TMEM130'},
{value: 'TMEM131', id: 'TMEM131'},
{value: 'TMEM131L', id: 'TMEM131L'},
{value: 'TMEM132A', id: 'TMEM132A'},
{value: 'TMEM132B', id: 'TMEM132B'},
{value: 'TMEM132C', id: 'TMEM132C'},
{value: 'TMEM132D', id: 'TMEM132D'},
{value: 'TMEM132D-AS1', id: 'TMEM132D-AS1'},
{value: 'TMEM132D-AS2', id: 'TMEM132D-AS2'},
{value: 'TMEM132E', id: 'TMEM132E'},
{value: 'TMEM132E-DT', id: 'TMEM132E-DT'},
{value: 'TMEM134', id: 'TMEM134'},
{value: 'TMEM135', id: 'TMEM135'},
{value: 'TMEM138', id: 'TMEM138'},
{value: 'TMEM139', id: 'TMEM139'},
{value: 'TMEM139-AS1', id: 'TMEM139-AS1'},
{value: 'TMEM140', id: 'TMEM140'},
{value: 'TMEM141', id: 'TMEM141'},
{value: 'TMEM143', id: 'TMEM143'},
{value: 'TMEM144', id: 'TMEM144'},
{value: 'TMEM145', id: 'TMEM145'},
{value: 'TMEM147', id: 'TMEM147'},
{value: 'TMEM147-AS1', id: 'TMEM147-AS1'},
{value: 'TMEM14A', id: 'TMEM14A'},
{value: 'TMEM14B', id: 'TMEM14B'},
{value: 'TMEM14B-DT', id: 'TMEM14B-DT'},
{value: 'TMEM14C', id: 'TMEM14C'},
{value: 'TMEM14DP', id: 'TMEM14DP'},
{value: 'TMEM14EP', id: 'TMEM14EP'},
{value: 'TMEM150A', id: 'TMEM150A'},
{value: 'TMEM150B', id: 'TMEM150B'},
{value: 'TMEM150C', id: 'TMEM150C'},
{value: 'TMEM151A', id: 'TMEM151A'},
{value: 'TMEM151B', id: 'TMEM151B'},
{value: 'TMEM154', id: 'TMEM154'},
{value: 'TMEM156', id: 'TMEM156'},
{value: 'TMEM158', id: 'TMEM158'},
{value: 'TMEM160', id: 'TMEM160'},
{value: 'TMEM161A', id: 'TMEM161A'},
{value: 'TMEM161B', id: 'TMEM161B'},
{value: 'TMEM161B-DT', id: 'TMEM161B-DT'},
{value: 'TMEM163', id: 'TMEM163'},
{value: 'TMEM164', id: 'TMEM164'},
{value: 'TMEM165', id: 'TMEM165'},
{value: 'TMEM167A', id: 'TMEM167A'},
{value: 'TMEM167B', id: 'TMEM167B'},
{value: 'TMEM167B-DT', id: 'TMEM167B-DT'},
{value: 'TMEM168', id: 'TMEM168'},
{value: 'TMEM169', id: 'TMEM169'},
{value: 'TMEM17', id: 'TMEM17'},
{value: 'TMEM170A', id: 'TMEM170A'},
{value: 'TMEM170B', id: 'TMEM170B'},
{value: 'TMEM171', id: 'TMEM171'},
{value: 'TMEM174', id: 'TMEM174'},
{value: 'TMEM175', id: 'TMEM175'},
{value: 'TMEM176A', id: 'TMEM176A'},
{value: 'TMEM176B', id: 'TMEM176B'},
{value: 'TMEM177', id: 'TMEM177'},
{value: 'TMEM178A', id: 'TMEM178A'},
{value: 'TMEM178B', id: 'TMEM178B'},
{value: 'TMEM179', id: 'TMEM179'},
{value: 'TMEM179B', id: 'TMEM179B'},
{value: 'TMEM18', id: 'TMEM18'},
{value: 'TMEM18-DT', id: 'TMEM18-DT'},
{value: 'TMEM181', id: 'TMEM181'},
{value: 'TMEM182', id: 'TMEM182'},
{value: 'TMEM183A', id: 'TMEM183A'},
{value: 'TMEM183BP', id: 'TMEM183BP'},
{value: 'TMEM184A', id: 'TMEM184A'},
{value: 'TMEM184B', id: 'TMEM184B'},
{value: 'TMEM184C', id: 'TMEM184C'},
{value: 'TMEM184C-DT', id: 'TMEM184C-DT'},
{value: 'TMEM185A', id: 'TMEM185A'},
{value: 'TMEM185B', id: 'TMEM185B'},
{value: 'TMEM186', id: 'TMEM186'},
{value: 'TMEM187', id: 'TMEM187'},
{value: 'TMEM19', id: 'TMEM19'},
{value: 'TMEM190', id: 'TMEM190'},
{value: 'TMEM191A', id: 'TMEM191A'},
{value: 'TMEM191B', id: 'TMEM191B'},
{value: 'TMEM191C', id: 'TMEM191C'},
{value: 'TMEM192', id: 'TMEM192'},
{value: 'TMEM196', id: 'TMEM196'},
{value: 'TMEM198', id: 'TMEM198'},
{value: 'TMEM198B', id: 'TMEM198B'},
{value: 'TMEM199', id: 'TMEM199'},
{value: 'TMEM200A', id: 'TMEM200A'},
{value: 'TMEM200B', id: 'TMEM200B'},
{value: 'TMEM200C', id: 'TMEM200C'},
{value: 'TMEM201', id: 'TMEM201'},
{value: 'TMEM202', id: 'TMEM202'},
{value: 'TMEM202-AS1', id: 'TMEM202-AS1'},
{value: 'TMEM203', id: 'TMEM203'},
{value: 'TMEM204', id: 'TMEM204'},
{value: 'TMEM205', id: 'TMEM205'},
{value: 'TMEM207', id: 'TMEM207'},
{value: 'TMEM208', id: 'TMEM208'},
{value: 'TMEM209', id: 'TMEM209'},
{value: 'TMEM210', id: 'TMEM210'},
{value: 'TMEM211', id: 'TMEM211'},
{value: 'TMEM212', id: 'TMEM212'},
{value: 'TMEM212-AS1', id: 'TMEM212-AS1'},
{value: 'TMEM213', id: 'TMEM213'},
{value: 'TMEM214', id: 'TMEM214'},
{value: 'TMEM215', id: 'TMEM215'},
{value: 'TMEM216', id: 'TMEM216'},
{value: 'TMEM217', id: 'TMEM217'},
{value: 'TMEM218', id: 'TMEM218'},
{value: 'TMEM219', id: 'TMEM219'},
{value: 'TMEM220', id: 'TMEM220'},
{value: 'TMEM220-AS1', id: 'TMEM220-AS1'},
{value: 'TMEM221', id: 'TMEM221'},
{value: 'TMEM222', id: 'TMEM222'},
{value: 'TMEM223', id: 'TMEM223'},
{value: 'TMEM225', id: 'TMEM225'},
{value: 'TMEM225B', id: 'TMEM225B'},
{value: 'TMEM229A', id: 'TMEM229A'},
{value: 'TMEM229B', id: 'TMEM229B'},
{value: 'TMEM230', id: 'TMEM230'},
{value: 'TMEM230P2', id: 'TMEM230P2'},
{value: 'TMEM231', id: 'TMEM231'},
{value: 'TMEM231P1', id: 'TMEM231P1'},
{value: 'TMEM232', id: 'TMEM232'},
{value: 'TMEM233', id: 'TMEM233'},
{value: 'TMEM234', id: 'TMEM234'},
{value: 'TMEM235', id: 'TMEM235'},
{value: 'TMEM236', id: 'TMEM236'},
{value: 'TMEM237', id: 'TMEM237'},
{value: 'TMEM238', id: 'TMEM238'},
{value: 'TMEM238L', id: 'TMEM238L'},
{value: 'TMEM239', id: 'TMEM239'},
{value: 'TMEM240', id: 'TMEM240'},
{value: 'TMEM241', id: 'TMEM241'},
{value: 'TMEM242', id: 'TMEM242'},
{value: 'TMEM242-DT', id: 'TMEM242-DT'},
{value: 'TMEM243', id: 'TMEM243'},
{value: 'TMEM244', id: 'TMEM244'},
{value: 'TMEM245', id: 'TMEM245'},
{value: 'TMEM246-AS1', id: 'TMEM246-AS1'},
{value: 'TMEM247', id: 'TMEM247'},
{value: 'TMEM248', id: 'TMEM248'},
{value: 'TMEM248P1', id: 'TMEM248P1'},
{value: 'TMEM249', id: 'TMEM249'},
{value: 'TMEM25', id: 'TMEM25'},
{value: 'TMEM250', id: 'TMEM250'},
{value: 'TMEM251', id: 'TMEM251'},
{value: 'TMEM251P1', id: 'TMEM251P1'},
{value: 'TMEM252', id: 'TMEM252'},
{value: 'TMEM252-DT', id: 'TMEM252-DT'},
{value: 'TMEM253', id: 'TMEM253'},
{value: 'TMEM254', id: 'TMEM254'},
{value: 'TMEM254-AS1', id: 'TMEM254-AS1'},
{value: 'TMEM255A', id: 'TMEM255A'},
{value: 'TMEM255B', id: 'TMEM255B'},
{value: 'TMEM256', id: 'TMEM256'},
{value: 'TMEM256-PLSCR3', id: 'TMEM256-PLSCR3'},
{value: 'TMEM256P1', id: 'TMEM256P1'},
{value: 'TMEM256P2', id: 'TMEM256P2'},
{value: 'TMEM258', id: 'TMEM258'},
{value: 'TMEM259', id: 'TMEM259'},
{value: 'TMEM26', id: 'TMEM26'},
{value: 'TMEM26-AS1', id: 'TMEM26-AS1'},
{value: 'TMEM260', id: 'TMEM260'},
{value: 'TMEM262', id: 'TMEM262'},
{value: 'TMEM263', id: 'TMEM263'},
{value: 'TMEM263-DT', id: 'TMEM263-DT'},
{value: 'TMEM265', id: 'TMEM265'},
{value: 'TMEM266', id: 'TMEM266'},
{value: 'TMEM267', id: 'TMEM267'},
{value: 'TMEM268', id: 'TMEM268'},
{value: 'TMEM269', id: 'TMEM269'},
{value: 'TMEM270', id: 'TMEM270'},
{value: 'TMEM271', id: 'TMEM271'},
{value: 'TMEM272', id: 'TMEM272'},
{value: 'TMEM273', id: 'TMEM273'},
{value: 'TMEM274P', id: 'TMEM274P'},
{value: 'TMEM275', id: 'TMEM275'},
{value: 'TMEM30A', id: 'TMEM30A'},
{value: 'TMEM30A-DT', id: 'TMEM30A-DT'},
{value: 'TMEM30B', id: 'TMEM30B'},
{value: 'TMEM30BP1', id: 'TMEM30BP1'},
{value: 'TMEM30CP', id: 'TMEM30CP'},
{value: 'TMEM33', id: 'TMEM33'},
{value: 'TMEM35A', id: 'TMEM35A'},
{value: 'TMEM35B', id: 'TMEM35B'},
{value: 'TMEM37', id: 'TMEM37'},
{value: 'TMEM38A', id: 'TMEM38A'},
{value: 'TMEM38B', id: 'TMEM38B'},
{value: 'TMEM39A', id: 'TMEM39A'},
{value: 'TMEM39B', id: 'TMEM39B'},
{value: 'TMEM40', id: 'TMEM40'},
{value: 'TMEM41A', id: 'TMEM41A'},
{value: 'TMEM41B', id: 'TMEM41B'},
{value: 'TMEM42', id: 'TMEM42'},
{value: 'TMEM43', id: 'TMEM43'},
{value: 'TMEM44', id: 'TMEM44'},
{value: 'TMEM44-AS1', id: 'TMEM44-AS1'},
{value: 'TMEM45A', id: 'TMEM45A'},
{value: 'TMEM45B', id: 'TMEM45B'},
{value: 'TMEM47', id: 'TMEM47'},
{value: 'TMEM50A', id: 'TMEM50A'},
{value: 'TMEM50B', id: 'TMEM50B'},
{value: 'TMEM51', id: 'TMEM51'},
{value: 'TMEM51-AS1', id: 'TMEM51-AS1'},
{value: 'TMEM52', id: 'TMEM52'},
{value: 'TMEM52B', id: 'TMEM52B'},
{value: 'TMEM53', id: 'TMEM53'},
{value: 'TMEM54', id: 'TMEM54'},
{value: 'TMEM59', id: 'TMEM59'},
{value: 'TMEM59L', id: 'TMEM59L'},
{value: 'TMEM60', id: 'TMEM60'},
{value: 'TMEM61', id: 'TMEM61'},
{value: 'TMEM62', id: 'TMEM62'},
{value: 'TMEM63A', id: 'TMEM63A'},
{value: 'TMEM63B', id: 'TMEM63B'},
{value: 'TMEM63C', id: 'TMEM63C'},
{value: 'TMEM64', id: 'TMEM64'},
{value: 'TMEM65', id: 'TMEM65'},
{value: 'TMEM67', id: 'TMEM67'},
{value: 'TMEM68', id: 'TMEM68'},
{value: 'TMEM69', id: 'TMEM69'},
{value: 'TMEM70', id: 'TMEM70'},
{value: 'TMEM71', id: 'TMEM71'},
{value: 'TMEM72', id: 'TMEM72'},
{value: 'TMEM72-AS1', id: 'TMEM72-AS1'},
{value: 'TMEM74', id: 'TMEM74'},
{value: 'TMEM74B', id: 'TMEM74B'},
{value: 'TMEM78', id: 'TMEM78'},
{value: 'TMEM79', id: 'TMEM79'},
{value: 'TMEM80', id: 'TMEM80'},
{value: 'TMEM81', id: 'TMEM81'},
{value: 'TMEM82', id: 'TMEM82'},
{value: 'TMEM86A', id: 'TMEM86A'},
{value: 'TMEM86B', id: 'TMEM86B'},
{value: 'TMEM87A', id: 'TMEM87A'},
{value: 'TMEM87B', id: 'TMEM87B'},
{value: 'TMEM88', id: 'TMEM88'},
{value: 'TMEM88B', id: 'TMEM88B'},
{value: 'TMEM89', id: 'TMEM89'},
{value: 'TMEM8B', id: 'TMEM8B'},
{value: 'TMEM9', id: 'TMEM9'},
{value: 'TMEM91', id: 'TMEM91'},
{value: 'TMEM92', id: 'TMEM92'},
{value: 'TMEM92-AS1', id: 'TMEM92-AS1'},
{value: 'TMEM94', id: 'TMEM94'},
{value: 'TMEM95', id: 'TMEM95'},
{value: 'TMEM97', id: 'TMEM97'},
{value: 'TMEM97P1', id: 'TMEM97P1'},
{value: 'TMEM97P2', id: 'TMEM97P2'},
{value: 'TMEM98', id: 'TMEM98'},
{value: 'TMEM9B', id: 'TMEM9B'},
{value: 'TMEM9B-AS1', id: 'TMEM9B-AS1'},
{value: 'TMF1', id: 'TMF1'},
{value: 'TMF1P1', id: 'TMF1P1'},
{value: 'TMIE', id: 'TMIE'},
{value: 'TMIGD1', id: 'TMIGD1'},
{value: 'TMIGD2', id: 'TMIGD2'},
{value: 'TMIGD3', id: 'TMIGD3'},
{value: 'TMLHE', id: 'TMLHE'},
{value: 'TMLHE-AS1', id: 'TMLHE-AS1'},
{value: 'TMOD1', id: 'TMOD1'},
{value: 'TMOD2', id: 'TMOD2'},
{value: 'TMOD3', id: 'TMOD3'},
{value: 'TMOD4', id: 'TMOD4'},
{value: 'TMPO', id: 'TMPO'},
{value: 'TMPO-AS1', id: 'TMPO-AS1'},
{value: 'TMPOP1', id: 'TMPOP1'},
{value: 'TMPOP2', id: 'TMPOP2'},
{value: 'TMPPE', id: 'TMPPE'},
{value: 'TMPRSS11B', id: 'TMPRSS11B'},
{value: 'TMPRSS11BNL', id: 'TMPRSS11BNL'},
{value: 'TMPRSS11CP', id: 'TMPRSS11CP'},
{value: 'TMPRSS11E', id: 'TMPRSS11E'},
{value: 'TMPRSS11F', id: 'TMPRSS11F'},
{value: 'TMPRSS12', id: 'TMPRSS12'},
{value: 'TMPRSS13', id: 'TMPRSS13'},
{value: 'TMPRSS15', id: 'TMPRSS15'},
{value: 'TMPRSS2', id: 'TMPRSS2'},
{value: 'TMPRSS3', id: 'TMPRSS3'},
{value: 'TMPRSS4', id: 'TMPRSS4'},
{value: 'TMPRSS5', id: 'TMPRSS5'},
{value: 'TMPRSS6', id: 'TMPRSS6'},
{value: 'TMPRSS7', id: 'TMPRSS7'},
{value: 'TMPRSS9', id: 'TMPRSS9'},
{value: 'TMSB10', id: 'TMSB10'},
{value: 'TMSB10P1', id: 'TMSB10P1'},
{value: 'TMSB15B', id: 'TMSB15B'},
{value: 'TMSB15B-AS1', id: 'TMSB15B-AS1'},
{value: 'TMSB4X', id: 'TMSB4X'},
{value: 'TMSB4XP2', id: 'TMSB4XP2'},
{value: 'TMSB4XP3', id: 'TMSB4XP3'},
{value: 'TMSB4XP4', id: 'TMSB4XP4'},
{value: 'TMSB4XP6', id: 'TMSB4XP6'},
{value: 'TMSB4XP8', id: 'TMSB4XP8'},
{value: 'TMSB4Y', id: 'TMSB4Y'},
{value: 'TMTC1', id: 'TMTC1'},
{value: 'TMTC2', id: 'TMTC2'},
{value: 'TMTC3', id: 'TMTC3'},
{value: 'TMTC4', id: 'TMTC4'},
{value: 'TMUB1', id: 'TMUB1'},
{value: 'TMUB2', id: 'TMUB2'},
{value: 'TMX1', id: 'TMX1'},
{value: 'TMX2', id: 'TMX2'},
{value: 'TMX2P1', id: 'TMX2P1'},
{value: 'TMX3', id: 'TMX3'},
{value: 'TMX4', id: 'TMX4'},
{value: 'TNC', id: 'TNC'},
{value: 'TNF', id: 'TNF'},
{value: 'TNFAIP1', id: 'TNFAIP1'},
{value: 'TNFAIP2', id: 'TNFAIP2'},
{value: 'TNFAIP3', id: 'TNFAIP3'},
{value: 'TNFAIP6', id: 'TNFAIP6'},
{value: 'TNFAIP8', id: 'TNFAIP8'},
{value: 'TNFAIP8L1', id: 'TNFAIP8L1'},
{value: 'TNFAIP8L2', id: 'TNFAIP8L2'},
{value: 'TNFAIP8L3', id: 'TNFAIP8L3'},
{value: 'TNFRSF10A', id: 'TNFRSF10A'},
{value: 'TNFRSF10A-AS1', id: 'TNFRSF10A-AS1'},
{value: 'TNFRSF10A-DT', id: 'TNFRSF10A-DT'},
{value: 'TNFRSF10B', id: 'TNFRSF10B'},
{value: 'TNFRSF10C', id: 'TNFRSF10C'},
{value: 'TNFRSF10D', id: 'TNFRSF10D'},
{value: 'TNFRSF11A', id: 'TNFRSF11A'},
{value: 'TNFRSF11B', id: 'TNFRSF11B'},
{value: 'TNFRSF12A', id: 'TNFRSF12A'},
{value: 'TNFRSF13B', id: 'TNFRSF13B'},
{value: 'TNFRSF13C', id: 'TNFRSF13C'},
{value: 'TNFRSF14', id: 'TNFRSF14'},
{value: 'TNFRSF14-AS1', id: 'TNFRSF14-AS1'},
{value: 'TNFRSF17', id: 'TNFRSF17'},
{value: 'TNFRSF18', id: 'TNFRSF18'},
{value: 'TNFRSF19', id: 'TNFRSF19'},
{value: 'TNFRSF1A', id: 'TNFRSF1A'},
{value: 'TNFRSF1B', id: 'TNFRSF1B'},
{value: 'TNFRSF21', id: 'TNFRSF21'},
{value: 'TNFRSF25', id: 'TNFRSF25'},
{value: 'TNFRSF4', id: 'TNFRSF4'},
{value: 'TNFRSF6B', id: 'TNFRSF6B'},
{value: 'TNFRSF8', id: 'TNFRSF8'},
{value: 'TNFRSF9', id: 'TNFRSF9'},
{value: 'TNFSF10', id: 'TNFSF10'},
{value: 'TNFSF11', id: 'TNFSF11'},
{value: 'TNFSF12', id: 'TNFSF12'},
{value: 'TNFSF12-TNFSF13', id: 'TNFSF12-TNFSF13'},
{value: 'TNFSF13', id: 'TNFSF13'},
{value: 'TNFSF13B', id: 'TNFSF13B'},
{value: 'TNFSF14', id: 'TNFSF14'},
{value: 'TNFSF15', id: 'TNFSF15'},
{value: 'TNFSF18', id: 'TNFSF18'},
{value: 'TNFSF4', id: 'TNFSF4'},
{value: 'TNFSF8', id: 'TNFSF8'},
{value: 'TNFSF9', id: 'TNFSF9'},
{value: 'TNIK', id: 'TNIK'},
{value: 'TNIP1', id: 'TNIP1'},
{value: 'TNIP2', id: 'TNIP2'},
{value: 'TNIP3', id: 'TNIP3'},
{value: 'TNK1', id: 'TNK1'},
{value: 'TNK2', id: 'TNK2'},
{value: 'TNK2-AS1', id: 'TNK2-AS1'},
{value: 'TNKS', id: 'TNKS'},
{value: 'TNKS1BP1', id: 'TNKS1BP1'},
{value: 'TNKS2', id: 'TNKS2'},
{value: 'TNKS2-AS1', id: 'TNKS2-AS1'},
{value: 'TNMD', id: 'TNMD'},
{value: 'TNN', id: 'TNN'},
{value: 'TNNC1', id: 'TNNC1'},
{value: 'TNNC2', id: 'TNNC2'},
{value: 'TNNI1', id: 'TNNI1'},
{value: 'TNNI2', id: 'TNNI2'},
{value: 'TNNI3', id: 'TNNI3'},
{value: 'TNNI3K', id: 'TNNI3K'},
{value: 'TNNT1', id: 'TNNT1'},
{value: 'TNNT2', id: 'TNNT2'},
{value: 'TNNT3', id: 'TNNT3'},
{value: 'TNPO1', id: 'TNPO1'},
{value: 'TNPO1-DT', id: 'TNPO1-DT'},
{value: 'TNPO1P1', id: 'TNPO1P1'},
{value: 'TNPO1P2', id: 'TNPO1P2'},
{value: 'TNPO1P3', id: 'TNPO1P3'},
{value: 'TNPO2', id: 'TNPO2'},
{value: 'TNPO3', id: 'TNPO3'},
{value: 'TNR', id: 'TNR'},
{value: 'TNR-IT1', id: 'TNR-IT1'},
{value: 'TNRC18', id: 'TNRC18'},
{value: 'TNRC18P1', id: 'TNRC18P1'},
{value: 'TNRC18P2', id: 'TNRC18P2'},
{value: 'TNRC18P3', id: 'TNRC18P3'},
{value: 'TNRC6A', id: 'TNRC6A'},
{value: 'TNRC6B', id: 'TNRC6B'},
{value: 'TNRC6B-DT', id: 'TNRC6B-DT'},
{value: 'TNRC6C', id: 'TNRC6C'},
{value: 'TNS1', id: 'TNS1'},
{value: 'TNS1-AS1', id: 'TNS1-AS1'},
{value: 'TNS2', id: 'TNS2'},
{value: 'TNS3', id: 'TNS3'},
{value: 'TNS4', id: 'TNS4'},
{value: 'TNXA', id: 'TNXA'},
{value: 'TNXB', id: 'TNXB'},
{value: 'TOB1', id: 'TOB1'},
{value: 'TOB1-AS1', id: 'TOB1-AS1'},
{value: 'TOB2', id: 'TOB2'},
{value: 'TOB2P1', id: 'TOB2P1'},
{value: 'TOE1', id: 'TOE1'},
{value: 'TOGARAM1', id: 'TOGARAM1'},
{value: 'TOGARAM2', id: 'TOGARAM2'},
{value: 'TOLLIP', id: 'TOLLIP'},
{value: 'TOLLIP-DT', id: 'TOLLIP-DT'},
{value: 'TOM1', id: 'TOM1'},
{value: 'TOM1L1', id: 'TOM1L1'},
{value: 'TOM1L2', id: 'TOM1L2'},
{value: 'TOMM20', id: 'TOMM20'},
{value: 'TOMM20L', id: 'TOMM20L'},
{value: 'TOMM20L-DT', id: 'TOMM20L-DT'},
{value: 'TOMM20P1', id: 'TOMM20P1'},
{value: 'TOMM20P2', id: 'TOMM20P2'},
{value: 'TOMM20P3', id: 'TOMM20P3'},
{value: 'TOMM22', id: 'TOMM22'},
{value: 'TOMM22P3', id: 'TOMM22P3'},
{value: 'TOMM22P4', id: 'TOMM22P4'},
{value: 'TOMM22P5', id: 'TOMM22P5'},
{value: 'TOMM22P6', id: 'TOMM22P6'},
{value: 'TOMM34', id: 'TOMM34'},
{value: 'TOMM40', id: 'TOMM40'},
{value: 'TOMM40L', id: 'TOMM40L'},
{value: 'TOMM40P1', id: 'TOMM40P1'},
{value: 'TOMM40P2', id: 'TOMM40P2'},
{value: 'TOMM40P3', id: 'TOMM40P3'},
{value: 'TOMM40P4', id: 'TOMM40P4'},
{value: 'TOMM5', id: 'TOMM5'},
{value: 'TOMM6', id: 'TOMM6'},
{value: 'TOMM7', id: 'TOMM7'},
{value: 'TOMM70', id: 'TOMM70'},
{value: 'TONSL', id: 'TONSL'},
{value: 'TOP1', id: 'TOP1'},
{value: 'TOP1MT', id: 'TOP1MT'},
{value: 'TOP1P1', id: 'TOP1P1'},
{value: 'TOP2A', id: 'TOP2A'},
{value: 'TOP2B', id: 'TOP2B'},
{value: 'TOP3A', id: 'TOP3A'},
{value: 'TOP3B', id: 'TOP3B'},
{value: 'TOP3BP1', id: 'TOP3BP1'},
{value: 'TOPAZ1', id: 'TOPAZ1'},
{value: 'TOPBP1', id: 'TOPBP1'},
{value: 'TOPORS', id: 'TOPORS'},
{value: 'TOPORSLP', id: 'TOPORSLP'},
{value: 'TOR1A', id: 'TOR1A'},
{value: 'TOR1AIP1', id: 'TOR1AIP1'},
{value: 'TOR1AIP2', id: 'TOR1AIP2'},
{value: 'TOR1B', id: 'TOR1B'},
{value: 'TOR2A', id: 'TOR2A'},
{value: 'TOR3A', id: 'TOR3A'},
{value: 'TOR4A', id: 'TOR4A'},
{value: 'TOX', id: 'TOX'},
{value: 'TOX2', id: 'TOX2'},
{value: 'TOX3', id: 'TOX3'},
{value: 'TOX4', id: 'TOX4'},
{value: 'TOX4P1', id: 'TOX4P1'},
{value: 'TP53', id: 'TP53'},
{value: 'TP53AIP1', id: 'TP53AIP1'},
{value: 'TP53BP1', id: 'TP53BP1'},
{value: 'TP53BP2', id: 'TP53BP2'},
{value: 'TP53I11', id: 'TP53I11'},
{value: 'TP53I13', id: 'TP53I13'},
{value: 'TP53I3', id: 'TP53I3'},
{value: 'TP53INP1', id: 'TP53INP1'},
{value: 'TP53INP2', id: 'TP53INP2'},
{value: 'TP53RK', id: 'TP53RK'},
{value: 'TP53RK-DT', id: 'TP53RK-DT'},
{value: 'TP53TG1', id: 'TP53TG1'},
{value: 'TP53TG3D', id: 'TP53TG3D'},
{value: 'TP53TG3E', id: 'TP53TG3E'},
{value: 'TP53TG5', id: 'TP53TG5'},
{value: 'TP63', id: 'TP63'},
{value: 'TP73', id: 'TP73'},
{value: 'TP73-AS1', id: 'TP73-AS1'},
{value: 'TP73-AS2', id: 'TP73-AS2'},
{value: 'TP73-AS3', id: 'TP73-AS3'},
{value: 'TPBG', id: 'TPBG'},
{value: 'TPBGL', id: 'TPBGL'},
{value: 'TPBGL-AS1', id: 'TPBGL-AS1'},
{value: 'TPCN1', id: 'TPCN1'},
{value: 'TPCN2', id: 'TPCN2'},
{value: 'TPD52', id: 'TPD52'},
{value: 'TPD52L1', id: 'TPD52L1'},
{value: 'TPD52L2', id: 'TPD52L2'},
{value: 'TPD52L3', id: 'TPD52L3'},
{value: 'TPGS1', id: 'TPGS1'},
{value: 'TPGS2', id: 'TPGS2'},
{value: 'TPH1', id: 'TPH1'},
{value: 'TPH2', id: 'TPH2'},
{value: 'TPI1', id: 'TPI1'},
{value: 'TPI1P1', id: 'TPI1P1'},
{value: 'TPI1P2', id: 'TPI1P2'},
{value: 'TPI1P3', id: 'TPI1P3'},
{value: 'TPI1P4', id: 'TPI1P4'},
{value: 'TPK1', id: 'TPK1'},
{value: 'TPM1', id: 'TPM1'},
{value: 'TPM1-AS', id: 'TPM1-AS'},
{value: 'TPM2', id: 'TPM2'},
{value: 'TPM3', id: 'TPM3'},
{value: 'TPM3P2', id: 'TPM3P2'},
{value: 'TPM3P3', id: 'TPM3P3'},
{value: 'TPM3P4', id: 'TPM3P4'},
{value: 'TPM3P9', id: 'TPM3P9'},
{value: 'TPM4', id: 'TPM4'},
{value: 'TPM4P1', id: 'TPM4P1'},
{value: 'TPMT', id: 'TPMT'},
{value: 'TPMTP1', id: 'TPMTP1'},
{value: 'TPO', id: 'TPO'},
{value: 'TPP1', id: 'TPP1'},
{value: 'TPP2', id: 'TPP2'},
{value: 'TPPP', id: 'TPPP'},
{value: 'TPPP2', id: 'TPPP2'},
{value: 'TPPP3', id: 'TPPP3'},
{value: 'TPR', id: 'TPR'},
{value: 'TPRA1', id: 'TPRA1'},
{value: 'TPRG1', id: 'TPRG1'},
{value: 'TPRG1-AS1', id: 'TPRG1-AS1'},
{value: 'TPRG1-AS2', id: 'TPRG1-AS2'},
{value: 'TPRG1L', id: 'TPRG1L'},
{value: 'TPRG1LP1', id: 'TPRG1LP1'},
{value: 'TPRKB', id: 'TPRKB'},
{value: 'TPRKBP1', id: 'TPRKBP1'},
{value: 'TPRKBP2', id: 'TPRKBP2'},
{value: 'TPRN', id: 'TPRN'},
{value: 'TPRX1', id: 'TPRX1'},
{value: 'TPRX1P1', id: 'TPRX1P1'},
{value: 'TPRX2P', id: 'TPRX2P'},
{value: 'TPRXL', id: 'TPRXL'},
{value: 'TPSAB1', id: 'TPSAB1'},
{value: 'TPSB2', id: 'TPSB2'},
{value: 'TPSD1', id: 'TPSD1'},
{value: 'TPSG1', id: 'TPSG1'},
{value: 'TPSP2', id: 'TPSP2'},
{value: 'TPST1', id: 'TPST1'},
{value: 'TPST2', id: 'TPST2'},
{value: 'TPST2P1', id: 'TPST2P1'},
{value: 'TPT1', id: 'TPT1'},
{value: 'TPT1-AS1', id: 'TPT1-AS1'},
{value: 'TPT1P1', id: 'TPT1P1'},
{value: 'TPT1P10', id: 'TPT1P10'},
{value: 'TPT1P12', id: 'TPT1P12'},
{value: 'TPT1P2', id: 'TPT1P2'},
{value: 'TPT1P3', id: 'TPT1P3'},
{value: 'TPT1P4', id: 'TPT1P4'},
{value: 'TPT1P5', id: 'TPT1P5'},
{value: 'TPT1P6', id: 'TPT1P6'},
{value: 'TPT1P7', id: 'TPT1P7'},
{value: 'TPT1P9', id: 'TPT1P9'},
{value: 'TPTE', id: 'TPTE'},
{value: 'TPTE2', id: 'TPTE2'},
{value: 'TPTE2P1', id: 'TPTE2P1'},
{value: 'TPTE2P2', id: 'TPTE2P2'},
{value: 'TPTE2P5', id: 'TPTE2P5'},
{value: 'TPTE2P6', id: 'TPTE2P6'},
{value: 'TPTEP1', id: 'TPTEP1'},
{value: 'TPTEP2', id: 'TPTEP2'},
{value: 'TPTEP2-CSNK1E', id: 'TPTEP2-CSNK1E'},
{value: 'TPX2', id: 'TPX2'},
{value: 'TRA2A', id: 'TRA2A'},
{value: 'TRA2B', id: 'TRA2B'},
{value: 'TRABD', id: 'TRABD'},
{value: 'TRABD2A', id: 'TRABD2A'},
{value: 'TRABD2B', id: 'TRABD2B'},
{value: 'TRAC', id: 'TRAC'},
{value: 'TRADD', id: 'TRADD'},
{value: 'TRAF1', id: 'TRAF1'},
{value: 'TRAF2', id: 'TRAF2'},
{value: 'TRAF3', id: 'TRAF3'},
{value: 'TRAF3IP1', id: 'TRAF3IP1'},
{value: 'TRAF3IP2', id: 'TRAF3IP2'},
{value: 'TRAF3IP2-AS1', id: 'TRAF3IP2-AS1'},
{value: 'TRAF3IP3', id: 'TRAF3IP3'},
{value: 'TRAF4', id: 'TRAF4'},
{value: 'TRAF5', id: 'TRAF5'},
{value: 'TRAF6', id: 'TRAF6'},
{value: 'TRAF6P1', id: 'TRAF6P1'},
{value: 'TRAF7', id: 'TRAF7'},
{value: 'TRAFD1', id: 'TRAFD1'},
{value: 'TRAIP', id: 'TRAIP'},
{value: 'TRAJ1', id: 'TRAJ1'},
{value: 'TRAJ11', id: 'TRAJ11'},
{value: 'TRAJ12', id: 'TRAJ12'},
{value: 'TRAJ14', id: 'TRAJ14'},
{value: 'TRAJ16', id: 'TRAJ16'},
{value: 'TRAJ17', id: 'TRAJ17'},
{value: 'TRAJ18', id: 'TRAJ18'},
{value: 'TRAJ2', id: 'TRAJ2'},
{value: 'TRAJ20', id: 'TRAJ20'},
{value: 'TRAJ21', id: 'TRAJ21'},
{value: 'TRAJ22', id: 'TRAJ22'},
{value: 'TRAJ23', id: 'TRAJ23'},
{value: 'TRAJ24', id: 'TRAJ24'},
{value: 'TRAJ25', id: 'TRAJ25'},
{value: 'TRAJ26', id: 'TRAJ26'},
{value: 'TRAJ27', id: 'TRAJ27'},
{value: 'TRAJ28', id: 'TRAJ28'},
{value: 'TRAJ29', id: 'TRAJ29'},
{value: 'TRAJ3', id: 'TRAJ3'},
{value: 'TRAJ30', id: 'TRAJ30'},
{value: 'TRAJ31', id: 'TRAJ31'},
{value: 'TRAJ32', id: 'TRAJ32'},
{value: 'TRAJ33', id: 'TRAJ33'},
{value: 'TRAJ34', id: 'TRAJ34'},
{value: 'TRAJ35', id: 'TRAJ35'},
{value: 'TRAJ37', id: 'TRAJ37'},
{value: 'TRAJ38', id: 'TRAJ38'},
{value: 'TRAJ39', id: 'TRAJ39'},
{value: 'TRAJ40', id: 'TRAJ40'},
{value: 'TRAJ41', id: 'TRAJ41'},
{value: 'TRAJ42', id: 'TRAJ42'},
{value: 'TRAJ43', id: 'TRAJ43'},
{value: 'TRAJ44', id: 'TRAJ44'},
{value: 'TRAJ45', id: 'TRAJ45'},
{value: 'TRAJ46', id: 'TRAJ46'},
{value: 'TRAJ47', id: 'TRAJ47'},
{value: 'TRAJ49', id: 'TRAJ49'},
{value: 'TRAJ5', id: 'TRAJ5'},
{value: 'TRAJ50', id: 'TRAJ50'},
{value: 'TRAJ51', id: 'TRAJ51'},
{value: 'TRAJ52', id: 'TRAJ52'},
{value: 'TRAJ54', id: 'TRAJ54'},
{value: 'TRAJ57', id: 'TRAJ57'},
{value: 'TRAJ58', id: 'TRAJ58'},
{value: 'TRAJ6', id: 'TRAJ6'},
{value: 'TRAJ60', id: 'TRAJ60'},
{value: 'TRAJ7', id: 'TRAJ7'},
{value: 'TRAJ9', id: 'TRAJ9'},
{value: 'TRAK1', id: 'TRAK1'},
{value: 'TRAK2', id: 'TRAK2'},
{value: 'TRAM1', id: 'TRAM1'},
{value: 'TRAM1L1', id: 'TRAM1L1'},
{value: 'TRAM2', id: 'TRAM2'},
{value: 'TRAM2-AS1', id: 'TRAM2-AS1'},
{value: 'TRANK1', id: 'TRANK1'},
{value: 'TRAP1', id: 'TRAP1'},
{value: 'TRAPPC1', id: 'TRAPPC1'},
{value: 'TRAPPC10', id: 'TRAPPC10'},
{value: 'TRAPPC11', id: 'TRAPPC11'},
{value: 'TRAPPC12', id: 'TRAPPC12'},
{value: 'TRAPPC12-AS1', id: 'TRAPPC12-AS1'},
{value: 'TRAPPC13', id: 'TRAPPC13'},
{value: 'TRAPPC14', id: 'TRAPPC14'},
{value: 'TRAPPC2', id: 'TRAPPC2'},
{value: 'TRAPPC2B', id: 'TRAPPC2B'},
{value: 'TRAPPC2L', id: 'TRAPPC2L'},
{value: 'TRAPPC3', id: 'TRAPPC3'},
{value: 'TRAPPC3L', id: 'TRAPPC3L'},
{value: 'TRAPPC4', id: 'TRAPPC4'},
{value: 'TRAPPC5', id: 'TRAPPC5'},
{value: 'TRAPPC6A', id: 'TRAPPC6A'},
{value: 'TRAPPC6B', id: 'TRAPPC6B'},
{value: 'TRAPPC8', id: 'TRAPPC8'},
{value: 'TRAPPC9', id: 'TRAPPC9'},
{value: 'TRARG1', id: 'TRARG1'},
{value: 'TRAT1', id: 'TRAT1'},
{value: 'TRAV1-1', id: 'TRAV1-1'},
{value: 'TRAV1-2', id: 'TRAV1-2'},
{value: 'TRAV10', id: 'TRAV10'},
{value: 'TRAV11', id: 'TRAV11'},
{value: 'TRAV12-1', id: 'TRAV12-1'},
{value: 'TRAV12-2', id: 'TRAV12-2'},
{value: 'TRAV12-3', id: 'TRAV12-3'},
{value: 'TRAV13-1', id: 'TRAV13-1'},
{value: 'TRAV13-2', id: 'TRAV13-2'},
{value: 'TRAV14DV4', id: 'TRAV14DV4'},
{value: 'TRAV15', id: 'TRAV15'},
{value: 'TRAV16', id: 'TRAV16'},
{value: 'TRAV17', id: 'TRAV17'},
{value: 'TRAV18', id: 'TRAV18'},
{value: 'TRAV19', id: 'TRAV19'},
{value: 'TRAV2', id: 'TRAV2'},
{value: 'TRAV20', id: 'TRAV20'},
{value: 'TRAV21', id: 'TRAV21'},
{value: 'TRAV22', id: 'TRAV22'},
{value: 'TRAV23DV6', id: 'TRAV23DV6'},
{value: 'TRAV24', id: 'TRAV24'},
{value: 'TRAV25', id: 'TRAV25'},
{value: 'TRAV26-1', id: 'TRAV26-1'},
{value: 'TRAV26-2', id: 'TRAV26-2'},
{value: 'TRAV27', id: 'TRAV27'},
{value: 'TRAV28', id: 'TRAV28'},
{value: 'TRAV29DV5', id: 'TRAV29DV5'},
{value: 'TRAV3', id: 'TRAV3'},
{value: 'TRAV30', id: 'TRAV30'},
{value: 'TRAV31', id: 'TRAV31'},
{value: 'TRAV32', id: 'TRAV32'},
{value: 'TRAV33', id: 'TRAV33'},
{value: 'TRAV35', id: 'TRAV35'},
{value: 'TRAV36DV7', id: 'TRAV36DV7'},
{value: 'TRAV37', id: 'TRAV37'},
{value: 'TRAV38-1', id: 'TRAV38-1'},
{value: 'TRAV38-2DV8', id: 'TRAV38-2DV8'},
{value: 'TRAV39', id: 'TRAV39'},
{value: 'TRAV4', id: 'TRAV4'},
{value: 'TRAV40', id: 'TRAV40'},
{value: 'TRAV41', id: 'TRAV41'},
{value: 'TRAV5', id: 'TRAV5'},
{value: 'TRAV6', id: 'TRAV6'},
{value: 'TRAV7', id: 'TRAV7'},
{value: 'TRAV8-1', id: 'TRAV8-1'},
{value: 'TRAV8-2', id: 'TRAV8-2'},
{value: 'TRAV8-3', id: 'TRAV8-3'},
{value: 'TRAV8-4', id: 'TRAV8-4'},
{value: 'TRAV8-5', id: 'TRAV8-5'},
{value: 'TRAV8-6', id: 'TRAV8-6'},
{value: 'TRAV8-7', id: 'TRAV8-7'},
{value: 'TRAV9-1', id: 'TRAV9-1'},
{value: 'TRAV9-2', id: 'TRAV9-2'},
{value: 'TRBC1', id: 'TRBC1'},
{value: 'TRBC2', id: 'TRBC2'},
{value: 'TRBD1', id: 'TRBD1'},
{value: 'TRBJ1-1', id: 'TRBJ1-1'},
{value: 'TRBJ1-2', id: 'TRBJ1-2'},
{value: 'TRBJ1-3', id: 'TRBJ1-3'},
{value: 'TRBJ1-5', id: 'TRBJ1-5'},
{value: 'TRBJ1-6', id: 'TRBJ1-6'},
{value: 'TRBJ2-1', id: 'TRBJ2-1'},
{value: 'TRBJ2-4', id: 'TRBJ2-4'},
{value: 'TRBJ2-7', id: 'TRBJ2-7'},
{value: 'TRBV1', id: 'TRBV1'},
{value: 'TRBV10-1', id: 'TRBV10-1'},
{value: 'TRBV10-2', id: 'TRBV10-2'},
{value: 'TRBV10-3', id: 'TRBV10-3'},
{value: 'TRBV11-1', id: 'TRBV11-1'},
{value: 'TRBV11-2', id: 'TRBV11-2'},
{value: 'TRBV11-3', id: 'TRBV11-3'},
{value: 'TRBV12-1', id: 'TRBV12-1'},
{value: 'TRBV12-2', id: 'TRBV12-2'},
{value: 'TRBV12-3', id: 'TRBV12-3'},
{value: 'TRBV12-4', id: 'TRBV12-4'},
{value: 'TRBV12-5', id: 'TRBV12-5'},
{value: 'TRBV13', id: 'TRBV13'},
{value: 'TRBV14', id: 'TRBV14'},
{value: 'TRBV15', id: 'TRBV15'},
{value: 'TRBV16', id: 'TRBV16'},
{value: 'TRBV17', id: 'TRBV17'},
{value: 'TRBV18', id: 'TRBV18'},
{value: 'TRBV19', id: 'TRBV19'},
{value: 'TRBV2', id: 'TRBV2'},
{value: 'TRBV20-1', id: 'TRBV20-1'},
{value: 'TRBV20OR9-2', id: 'TRBV20OR9-2'},
{value: 'TRBV21-1', id: 'TRBV21-1'},
{value: 'TRBV21OR9-2', id: 'TRBV21OR9-2'},
{value: 'TRBV22-1', id: 'TRBV22-1'},
{value: 'TRBV22OR9-2', id: 'TRBV22OR9-2'},
{value: 'TRBV23-1', id: 'TRBV23-1'},
{value: 'TRBV23OR9-2', id: 'TRBV23OR9-2'},
{value: 'TRBV24-1', id: 'TRBV24-1'},
{value: 'TRBV24OR9-2', id: 'TRBV24OR9-2'},
{value: 'TRBV25-1', id: 'TRBV25-1'},
{value: 'TRBV25OR9-2', id: 'TRBV25OR9-2'},
{value: 'TRBV26', id: 'TRBV26'},
{value: 'TRBV26OR9-2', id: 'TRBV26OR9-2'},
{value: 'TRBV27', id: 'TRBV27'},
{value: 'TRBV28', id: 'TRBV28'},
{value: 'TRBV29-1', id: 'TRBV29-1'},
{value: 'TRBV29OR9-2', id: 'TRBV29OR9-2'},
{value: 'TRBV3-1', id: 'TRBV3-1'},
{value: 'TRBV30', id: 'TRBV30'},
{value: 'TRBV4-1', id: 'TRBV4-1'},
{value: 'TRBV4-2', id: 'TRBV4-2'},
{value: 'TRBV5-1', id: 'TRBV5-1'},
{value: 'TRBV5-2', id: 'TRBV5-2'},
{value: 'TRBV5-3', id: 'TRBV5-3'},
{value: 'TRBV5-4', id: 'TRBV5-4'},
{value: 'TRBV5-5', id: 'TRBV5-5'},
{value: 'TRBV5-6', id: 'TRBV5-6'},
{value: 'TRBV5-7', id: 'TRBV5-7'},
{value: 'TRBV6-1', id: 'TRBV6-1'},
{value: 'TRBV6-2', id: 'TRBV6-2'},
{value: 'TRBV6-4', id: 'TRBV6-4'},
{value: 'TRBV6-5', id: 'TRBV6-5'},
{value: 'TRBV6-6', id: 'TRBV6-6'},
{value: 'TRBV6-7', id: 'TRBV6-7'},
{value: 'TRBV6-8', id: 'TRBV6-8'},
{value: 'TRBV7-1', id: 'TRBV7-1'},
{value: 'TRBV7-2', id: 'TRBV7-2'},
{value: 'TRBV7-3', id: 'TRBV7-3'},
{value: 'TRBV7-4', id: 'TRBV7-4'},
{value: 'TRBV7-5', id: 'TRBV7-5'},
{value: 'TRBV7-6', id: 'TRBV7-6'},
{value: 'TRBV7-7', id: 'TRBV7-7'},
{value: 'TRBV7-9', id: 'TRBV7-9'},
{value: 'TRBV8-1', id: 'TRBV8-1'},
{value: 'TRBV8-2', id: 'TRBV8-2'},
{value: 'TRBV9', id: 'TRBV9'},
{value: 'TRBVA', id: 'TRBVA'},
{value: 'TRBVB', id: 'TRBVB'},
{value: 'TRDC', id: 'TRDC'},
{value: 'TRDD3', id: 'TRDD3'},
{value: 'TRDJ3', id: 'TRDJ3'},
{value: 'TRDMT1', id: 'TRDMT1'},
{value: 'TRDN', id: 'TRDN'},
{value: 'TRDN-AS1', id: 'TRDN-AS1'},
{value: 'TRDV1', id: 'TRDV1'},
{value: 'TRDV2', id: 'TRDV2'},
{value: 'TRDV3', id: 'TRDV3'},
{value: 'TREH', id: 'TREH'},
{value: 'TREHP1', id: 'TREHP1'},
{value: 'TREM1', id: 'TREM1'},
{value: 'TREM2', id: 'TREM2'},
{value: 'TREML1', id: 'TREML1'},
{value: 'TREML2', id: 'TREML2'},
{value: 'TREML3P', id: 'TREML3P'},
{value: 'TREML4', id: 'TREML4'},
{value: 'TREML5P', id: 'TREML5P'},
{value: 'TRERF1', id: 'TRERF1'},
{value: 'TRERNA1', id: 'TRERNA1'},
{value: 'TREX1', id: 'TREX1'},
{value: 'TREX2', id: 'TREX2'},
{value: 'TRG-AS1', id: 'TRG-AS1'},
{value: 'TRGC1', id: 'TRGC1'},
{value: 'TRGC2', id: 'TRGC2'},
{value: 'TRGJ1', id: 'TRGJ1'},
{value: 'TRGJ2', id: 'TRGJ2'},
{value: 'TRGJP', id: 'TRGJP'},
{value: 'TRGJP1', id: 'TRGJP1'},
{value: 'TRGJP2', id: 'TRGJP2'},
{value: 'TRGV1', id: 'TRGV1'},
{value: 'TRGV10', id: 'TRGV10'},
{value: 'TRGV11', id: 'TRGV11'},
{value: 'TRGV2', id: 'TRGV2'},
{value: 'TRGV3', id: 'TRGV3'},
{value: 'TRGV4', id: 'TRGV4'},
{value: 'TRGV5', id: 'TRGV5'},
{value: 'TRGV5P', id: 'TRGV5P'},
{value: 'TRGV6', id: 'TRGV6'},
{value: 'TRGV7', id: 'TRGV7'},
{value: 'TRGV8', id: 'TRGV8'},
{value: 'TRGV9', id: 'TRGV9'},
{value: 'TRGVA', id: 'TRGVA'},
{value: 'TRGVB', id: 'TRGVB'},
{value: 'TRH', id: 'TRH'},
{value: 'TRHDE', id: 'TRHDE'},
{value: 'TRHDE-AS1', id: 'TRHDE-AS1'},
{value: 'TRHR', id: 'TRHR'},
{value: 'TRIAP1', id: 'TRIAP1'},
{value: 'TRIAP1P1', id: 'TRIAP1P1'},
{value: 'TRIB1', id: 'TRIB1'},
{value: 'TRIB2', id: 'TRIB2'},
{value: 'TRIB3', id: 'TRIB3'},
{value: 'TRIL', id: 'TRIL'},
{value: 'TRIM10', id: 'TRIM10'},
{value: 'TRIM11', id: 'TRIM11'},
{value: 'TRIM13', id: 'TRIM13'},
{value: 'TRIM14', id: 'TRIM14'},
{value: 'TRIM15', id: 'TRIM15'},
{value: 'TRIM16', id: 'TRIM16'},
{value: 'TRIM16L', id: 'TRIM16L'},
{value: 'TRIM17', id: 'TRIM17'},
{value: 'TRIM2', id: 'TRIM2'},
{value: 'TRIM21', id: 'TRIM21'},
{value: 'TRIM22', id: 'TRIM22'},
{value: 'TRIM23', id: 'TRIM23'},
{value: 'TRIM24', id: 'TRIM24'},
{value: 'TRIM25', id: 'TRIM25'},
{value: 'TRIM26', id: 'TRIM26'},
{value: 'TRIM26BP', id: 'TRIM26BP'},
{value: 'TRIM27', id: 'TRIM27'},
{value: 'TRIM28', id: 'TRIM28'},
{value: 'TRIM29', id: 'TRIM29'},
{value: 'TRIM3', id: 'TRIM3'},
{value: 'TRIM31', id: 'TRIM31'},
{value: 'TRIM31-AS1', id: 'TRIM31-AS1'},
{value: 'TRIM32', id: 'TRIM32'},
{value: 'TRIM33', id: 'TRIM33'},
{value: 'TRIM34', id: 'TRIM34'},
{value: 'TRIM35', id: 'TRIM35'},
{value: 'TRIM36', id: 'TRIM36'},
{value: 'TRIM37', id: 'TRIM37'},
{value: 'TRIM38', id: 'TRIM38'},
{value: 'TRIM39', id: 'TRIM39'},
{value: 'TRIM39-RPP21', id: 'TRIM39-RPP21'},
{value: 'TRIM4', id: 'TRIM4'},
{value: 'TRIM40', id: 'TRIM40'},
{value: 'TRIM41', id: 'TRIM41'},
{value: 'TRIM42', id: 'TRIM42'},
{value: 'TRIM43', id: 'TRIM43'},
{value: 'TRIM43B', id: 'TRIM43B'},
{value: 'TRIM43CP', id: 'TRIM43CP'},
{value: 'TRIM44', id: 'TRIM44'},
{value: 'TRIM45', id: 'TRIM45'},
{value: 'TRIM46', id: 'TRIM46'},
{value: 'TRIM47', id: 'TRIM47'},
{value: 'TRIM48', id: 'TRIM48'},
{value: 'TRIM49', id: 'TRIM49'},
{value: 'TRIM49B', id: 'TRIM49B'},
{value: 'TRIM49C', id: 'TRIM49C'},
{value: 'TRIM49D1', id: 'TRIM49D1'},
{value: 'TRIM49D2', id: 'TRIM49D2'},
{value: 'TRIM5', id: 'TRIM5'},
{value: 'TRIM50', id: 'TRIM50'},
{value: 'TRIM51', id: 'TRIM51'},
{value: 'TRIM51BP', id: 'TRIM51BP'},
{value: 'TRIM51CP', id: 'TRIM51CP'},
{value: 'TRIM51EP', id: 'TRIM51EP'},
{value: 'TRIM51GP', id: 'TRIM51GP'},
{value: 'TRIM51HP', id: 'TRIM51HP'},
{value: 'TRIM51JP', id: 'TRIM51JP'},
{value: 'TRIM52', id: 'TRIM52'},
{value: 'TRIM52-AS1', id: 'TRIM52-AS1'},
{value: 'TRIM53AP', id: 'TRIM53AP'},
{value: 'TRIM53BP', id: 'TRIM53BP'},
{value: 'TRIM53CP', id: 'TRIM53CP'},
{value: 'TRIM54', id: 'TRIM54'},
{value: 'TRIM55', id: 'TRIM55'},
{value: 'TRIM56', id: 'TRIM56'},
{value: 'TRIM58', id: 'TRIM58'},
{value: 'TRIM59', id: 'TRIM59'},
{value: 'TRIM6', id: 'TRIM6'},
{value: 'TRIM6-TRIM34', id: 'TRIM6-TRIM34'},
{value: 'TRIM60', id: 'TRIM60'},
{value: 'TRIM60P13', id: 'TRIM60P13'},
{value: 'TRIM60P14', id: 'TRIM60P14'},
{value: 'TRIM60P15', id: 'TRIM60P15'},
{value: 'TRIM60P16', id: 'TRIM60P16'},
{value: 'TRIM60P18', id: 'TRIM60P18'},
{value: 'TRIM60P19', id: 'TRIM60P19'},
{value: 'TRIM61', id: 'TRIM61'},
{value: 'TRIM62', id: 'TRIM62'},
{value: 'TRIM63', id: 'TRIM63'},
{value: 'TRIM64B', id: 'TRIM64B'},
{value: 'TRIM64C', id: 'TRIM64C'},
{value: 'TRIM64DP', id: 'TRIM64DP'},
{value: 'TRIM64FP', id: 'TRIM64FP'},
{value: 'TRIM65', id: 'TRIM65'},
{value: 'TRIM66', id: 'TRIM66'},
{value: 'TRIM67', id: 'TRIM67'},
{value: 'TRIM68', id: 'TRIM68'},
{value: 'TRIM69', id: 'TRIM69'},
{value: 'TRIM7', id: 'TRIM7'},
{value: 'TRIM71', id: 'TRIM71'},
{value: 'TRIM72', id: 'TRIM72'},
{value: 'TRIM73', id: 'TRIM73'},
{value: 'TRIM74', id: 'TRIM74'},
{value: 'TRIM75P', id: 'TRIM75P'},
{value: 'TRIM77', id: 'TRIM77'},
{value: 'TRIM77BP', id: 'TRIM77BP'},
{value: 'TRIM8', id: 'TRIM8'},
{value: 'TRIM8-DT', id: 'TRIM8-DT'},
{value: 'TRIM80P', id: 'TRIM80P'},
{value: 'TRIM9', id: 'TRIM9'},
{value: 'TRIML1', id: 'TRIML1'},
{value: 'TRIML2', id: 'TRIML2'},
{value: 'TRIO', id: 'TRIO'},
{value: 'TRIOBP', id: 'TRIOBP'},
{value: 'TRIP10', id: 'TRIP10'},
{value: 'TRIP11', id: 'TRIP11'},
{value: 'TRIP12', id: 'TRIP12'},
{value: 'TRIP13', id: 'TRIP13'},
{value: 'TRIP4', id: 'TRIP4'},
{value: 'TRIP6', id: 'TRIP6'},
{value: 'TRIQK', id: 'TRIQK'},
{value: 'TRIR', id: 'TRIR'},
{value: 'TRIT1', id: 'TRIT1'},
{value: 'TRMO', id: 'TRMO'},
{value: 'TRMT1', id: 'TRMT1'},
{value: 'TRMT10A', id: 'TRMT10A'},
{value: 'TRMT10B', id: 'TRMT10B'},
{value: 'TRMT10BP1', id: 'TRMT10BP1'},
{value: 'TRMT10C', id: 'TRMT10C'},
{value: 'TRMT11', id: 'TRMT11'},
{value: 'TRMT112', id: 'TRMT112'},
{value: 'TRMT112P1', id: 'TRMT112P1'},
{value: 'TRMT112P3', id: 'TRMT112P3'},
{value: 'TRMT112P4', id: 'TRMT112P4'},
{value: 'TRMT112P5', id: 'TRMT112P5'},
{value: 'TRMT112P6', id: 'TRMT112P6'},
{value: 'TRMT112P7', id: 'TRMT112P7'},
{value: 'TRMT12', id: 'TRMT12'},
{value: 'TRMT13', id: 'TRMT13'},
{value: 'TRMT1L', id: 'TRMT1L'},
{value: 'TRMT2A', id: 'TRMT2A'},
{value: 'TRMT2B', id: 'TRMT2B'},
{value: 'TRMT44', id: 'TRMT44'},
{value: 'TRMT5', id: 'TRMT5'},
{value: 'TRMT6', id: 'TRMT6'},
{value: 'TRMT61A', id: 'TRMT61A'},
{value: 'TRMT61B', id: 'TRMT61B'},
{value: 'TRMT9B', id: 'TRMT9B'},
{value: 'TRMU', id: 'TRMU'},
{value: 'TRNAU1AP', id: 'TRNAU1AP'},
{value: 'TRNP1', id: 'TRNP1'},
{value: 'TRNT1', id: 'TRNT1'},
{value: 'TRO', id: 'TRO'},
{value: 'TROAP', id: 'TROAP'},
{value: 'TROAP-AS1', id: 'TROAP-AS1'},
{value: 'TRPA1', id: 'TRPA1'},
{value: 'TRPC1', id: 'TRPC1'},
{value: 'TRPC2', id: 'TRPC2'},
{value: 'TRPC3', id: 'TRPC3'},
{value: 'TRPC4', id: 'TRPC4'},
{value: 'TRPC4AP', id: 'TRPC4AP'},
{value: 'TRPC5', id: 'TRPC5'},
{value: 'TRPC6', id: 'TRPC6'},
{value: 'TRPC7', id: 'TRPC7'},
{value: 'TRPC7-AS1', id: 'TRPC7-AS1'},
{value: 'TRPM1', id: 'TRPM1'},
{value: 'TRPM2', id: 'TRPM2'},
{value: 'TRPM2-AS', id: 'TRPM2-AS'},
{value: 'TRPM3', id: 'TRPM3'},
{value: 'TRPM4', id: 'TRPM4'},
{value: 'TRPM5', id: 'TRPM5'},
{value: 'TRPM6', id: 'TRPM6'},
{value: 'TRPM7', id: 'TRPM7'},
{value: 'TRPM8', id: 'TRPM8'},
{value: 'TRPS1', id: 'TRPS1'},
{value: 'TRPT1', id: 'TRPT1'},
{value: 'TRPV1', id: 'TRPV1'},
{value: 'TRPV2', id: 'TRPV2'},
{value: 'TRPV3', id: 'TRPV3'},
{value: 'TRPV4', id: 'TRPV4'},
{value: 'TRPV5', id: 'TRPV5'},
{value: 'TRPV6', id: 'TRPV6'},
{value: 'TRRAP', id: 'TRRAP'},
{value: 'TRUB1', id: 'TRUB1'},
{value: 'TRUB2', id: 'TRUB2'},
{value: 'TSBP1-AS1', id: 'TSBP1-AS1'},
{value: 'TSC1', id: 'TSC1'},
{value: 'TSC2', id: 'TSC2'},
{value: 'TSC22D1', id: 'TSC22D1'},
{value: 'TSC22D1-AS1', id: 'TSC22D1-AS1'},
{value: 'TSC22D2', id: 'TSC22D2'},
{value: 'TSC22D3', id: 'TSC22D3'},
{value: 'TSC22D4', id: 'TSC22D4'},
{value: 'TSEN15', id: 'TSEN15'},
{value: 'TSEN15P1', id: 'TSEN15P1'},
{value: 'TSEN15P3', id: 'TSEN15P3'},
{value: 'TSEN2', id: 'TSEN2'},
{value: 'TSEN2P1', id: 'TSEN2P1'},
{value: 'TSEN34', id: 'TSEN34'},
{value: 'TSEN54', id: 'TSEN54'},
{value: 'TSFM', id: 'TSFM'},
{value: 'TSG101', id: 'TSG101'},
{value: 'TSGA10', id: 'TSGA10'},
{value: 'TSGA10IP', id: 'TSGA10IP'},
{value: 'TSGA13', id: 'TSGA13'},
{value: 'TSHB', id: 'TSHB'},
{value: 'TSHR', id: 'TSHR'},
{value: 'TSHZ1', id: 'TSHZ1'},
{value: 'TSHZ2', id: 'TSHZ2'},
{value: 'TSHZ3', id: 'TSHZ3'},
{value: 'TSHZ3-AS1', id: 'TSHZ3-AS1'},
{value: 'TSIX', id: 'TSIX'},
{value: 'TSKS', id: 'TSKS'},
{value: 'TSKU', id: 'TSKU'},
{value: 'TSKU-AS1', id: 'TSKU-AS1'},
{value: 'TSLP', id: 'TSLP'},
{value: 'TSN', id: 'TSN'},
{value: 'TSNARE1', id: 'TSNARE1'},
{value: 'TSNAX', id: 'TSNAX'},
{value: 'TSNAX-DISC1', id: 'TSNAX-DISC1'},
{value: 'TSNAXIP1', id: 'TSNAXIP1'},
{value: 'TSPAN1', id: 'TSPAN1'},
{value: 'TSPAN10', id: 'TSPAN10'},
{value: 'TSPAN11', id: 'TSPAN11'},
{value: 'TSPAN12', id: 'TSPAN12'},
{value: 'TSPAN13', id: 'TSPAN13'},
{value: 'TSPAN14', id: 'TSPAN14'},
{value: 'TSPAN15', id: 'TSPAN15'},
{value: 'TSPAN16', id: 'TSPAN16'},
{value: 'TSPAN17', id: 'TSPAN17'},
{value: 'TSPAN18', id: 'TSPAN18'},
{value: 'TSPAN18-AS1', id: 'TSPAN18-AS1'},
{value: 'TSPAN19', id: 'TSPAN19'},
{value: 'TSPAN2', id: 'TSPAN2'},
{value: 'TSPAN3', id: 'TSPAN3'},
{value: 'TSPAN31', id: 'TSPAN31'},
{value: 'TSPAN32', id: 'TSPAN32'},
{value: 'TSPAN33', id: 'TSPAN33'},
{value: 'TSPAN4', id: 'TSPAN4'},
{value: 'TSPAN5', id: 'TSPAN5'},
{value: 'TSPAN5-DT', id: 'TSPAN5-DT'},
{value: 'TSPAN6', id: 'TSPAN6'},
{value: 'TSPAN7', id: 'TSPAN7'},
{value: 'TSPAN8', id: 'TSPAN8'},
{value: 'TSPAN9', id: 'TSPAN9'},
{value: 'TSPAN9-IT1', id: 'TSPAN9-IT1'},
{value: 'TSPEAR', id: 'TSPEAR'},
{value: 'TSPEAR-AS1', id: 'TSPEAR-AS1'},
{value: 'TSPEAR-AS2', id: 'TSPEAR-AS2'},
{value: 'TSPO', id: 'TSPO'},
{value: 'TSPO2', id: 'TSPO2'},
{value: 'TSPOAP1', id: 'TSPOAP1'},
{value: 'TSPOAP1-AS1', id: 'TSPOAP1-AS1'},
{value: 'TSPY26P', id: 'TSPY26P'},
{value: 'TSPYL1', id: 'TSPYL1'},
{value: 'TSPYL2', id: 'TSPYL2'},
{value: 'TSPYL4', id: 'TSPYL4'},
{value: 'TSPYL5', id: 'TSPYL5'},
{value: 'TSPYL6', id: 'TSPYL6'},
{value: 'TSR1', id: 'TSR1'},
{value: 'TSR2', id: 'TSR2'},
{value: 'TSR3', id: 'TSR3'},
{value: 'TSSC2', id: 'TSSC2'},
{value: 'TSSC4', id: 'TSSC4'},
{value: 'TSSK1B', id: 'TSSK1B'},
{value: 'TSSK2', id: 'TSSK2'},
{value: 'TSSK3', id: 'TSSK3'},
{value: 'TSSK4', id: 'TSSK4'},
{value: 'TSSK6', id: 'TSSK6'},
{value: 'TST', id: 'TST'},
{value: 'TSTD1', id: 'TSTD1'},
{value: 'TSTD2', id: 'TSTD2'},
{value: 'TSTD3', id: 'TSTD3'},
{value: 'TTBK1', id: 'TTBK1'},
{value: 'TTBK2', id: 'TTBK2'},
{value: 'TTBK2-AS1', id: 'TTBK2-AS1'},
{value: 'TTC1', id: 'TTC1'},
{value: 'TTC12', id: 'TTC12'},
{value: 'TTC12-DT', id: 'TTC12-DT'},
{value: 'TTC13', id: 'TTC13'},
{value: 'TTC14', id: 'TTC14'},
{value: 'TTC16', id: 'TTC16'},
{value: 'TTC17', id: 'TTC17'},
{value: 'TTC19', id: 'TTC19'},
{value: 'TTC21A', id: 'TTC21A'},
{value: 'TTC21B', id: 'TTC21B'},
{value: 'TTC22', id: 'TTC22'},
{value: 'TTC23', id: 'TTC23'},
{value: 'TTC23L', id: 'TTC23L'},
{value: 'TTC23L-AS1', id: 'TTC23L-AS1'},
{value: 'TTC24', id: 'TTC24'},
{value: 'TTC26', id: 'TTC26'},
{value: 'TTC27', id: 'TTC27'},
{value: 'TTC28', id: 'TTC28'},
{value: 'TTC28-AS1', id: 'TTC28-AS1'},
{value: 'TTC29', id: 'TTC29'},
{value: 'TTC3', id: 'TTC3'},
{value: 'TTC3-AS1', id: 'TTC3-AS1'},
{value: 'TTC30A', id: 'TTC30A'},
{value: 'TTC30B', id: 'TTC30B'},
{value: 'TTC31', id: 'TTC31'},
{value: 'TTC32', id: 'TTC32'},
{value: 'TTC32-DT', id: 'TTC32-DT'},
{value: 'TTC33', id: 'TTC33'},
{value: 'TTC34', id: 'TTC34'},
{value: 'TTC36', id: 'TTC36'},
{value: 'TTC36-AS1', id: 'TTC36-AS1'},
{value: 'TTC37', id: 'TTC37'},
{value: 'TTC38', id: 'TTC38'},
{value: 'TTC39A', id: 'TTC39A'},
{value: 'TTC39A-AS1', id: 'TTC39A-AS1'},
{value: 'TTC39B', id: 'TTC39B'},
{value: 'TTC39C', id: 'TTC39C'},
{value: 'TTC39C-AS1', id: 'TTC39C-AS1'},
{value: 'TTC39CP1', id: 'TTC39CP1'},
{value: 'TTC3P1', id: 'TTC3P1'},
{value: 'TTC4', id: 'TTC4'},
{value: 'TTC41P', id: 'TTC41P'},
{value: 'TTC4P1', id: 'TTC4P1'},
{value: 'TTC5', id: 'TTC5'},
{value: 'TTC6', id: 'TTC6'},
{value: 'TTC7A', id: 'TTC7A'},
{value: 'TTC7B', id: 'TTC7B'},
{value: 'TTC8', id: 'TTC8'},
{value: 'TTC9', id: 'TTC9'},
{value: 'TTC9-DT', id: 'TTC9-DT'},
{value: 'TTC9B', id: 'TTC9B'},
{value: 'TTC9C', id: 'TTC9C'},
{value: 'TTF1', id: 'TTF1'},
{value: 'TTF2', id: 'TTF2'},
{value: 'TTI1', id: 'TTI1'},
{value: 'TTI2', id: 'TTI2'},
{value: 'TTK', id: 'TTK'},
{value: 'TTL', id: 'TTL'},
{value: 'TTLL1', id: 'TTLL1'},
{value: 'TTLL1-AS1', id: 'TTLL1-AS1'},
{value: 'TTLL10', id: 'TTLL10'},
{value: 'TTLL10-AS1', id: 'TTLL10-AS1'},
{value: 'TTLL11', id: 'TTLL11'},
{value: 'TTLL12', id: 'TTLL12'},
{value: 'TTLL13P', id: 'TTLL13P'},
{value: 'TTLL2', id: 'TTLL2'},
{value: 'TTLL3', id: 'TTLL3'},
{value: 'TTLL4', id: 'TTLL4'},
{value: 'TTLL5', id: 'TTLL5'},
{value: 'TTLL6', id: 'TTLL6'},
{value: 'TTLL7', id: 'TTLL7'},
{value: 'TTLL7-IT1', id: 'TTLL7-IT1'},
{value: 'TTLL8', id: 'TTLL8'},
{value: 'TTLL9', id: 'TTLL9'},
{value: 'TTN', id: 'TTN'},
{value: 'TTN-AS1', id: 'TTN-AS1'},
{value: 'TTPA', id: 'TTPA'},
{value: 'TTPAL', id: 'TTPAL'},
{value: 'TTR', id: 'TTR'},
{value: 'TTTY10', id: 'TTTY10'},
{value: 'TTTY14', id: 'TTTY14'},
{value: 'TTTY9B', id: 'TTTY9B'},
{value: 'TTYH1', id: 'TTYH1'},
{value: 'TTYH2', id: 'TTYH2'},
{value: 'TTYH3', id: 'TTYH3'},
{value: 'TUB', id: 'TUB'},
{value: 'TUBA1A', id: 'TUBA1A'},
{value: 'TUBA1B', id: 'TUBA1B'},
{value: 'TUBA1C', id: 'TUBA1C'},
{value: 'TUBA3C', id: 'TUBA3C'},
{value: 'TUBA3D', id: 'TUBA3D'},
{value: 'TUBA3E', id: 'TUBA3E'},
{value: 'TUBA3FP', id: 'TUBA3FP'},
{value: 'TUBA3GP', id: 'TUBA3GP'},
{value: 'TUBA4A', id: 'TUBA4A'},
{value: 'TUBA4B', id: 'TUBA4B'},
{value: 'TUBA5P', id: 'TUBA5P'},
{value: 'TUBA8', id: 'TUBA8'},
{value: 'TUBAL3', id: 'TUBAL3'},
{value: 'TUBAP1', id: 'TUBAP1'},
{value: 'TUBAP10', id: 'TUBAP10'},
{value: 'TUBAP11', id: 'TUBAP11'},
{value: 'TUBAP13', id: 'TUBAP13'},
{value: 'TUBAP14', id: 'TUBAP14'},
{value: 'TUBAP15', id: 'TUBAP15'},
{value: 'TUBAP2', id: 'TUBAP2'},
{value: 'TUBAP4', id: 'TUBAP4'},
{value: 'TUBAP7', id: 'TUBAP7'},
{value: 'TUBAP8', id: 'TUBAP8'},
{value: 'TUBAP9', id: 'TUBAP9'},
{value: 'TUBB', id: 'TUBB'},
{value: 'TUBB1', id: 'TUBB1'},
{value: 'TUBB2A', id: 'TUBB2A'},
{value: 'TUBB2B', id: 'TUBB2B'},
{value: 'TUBB2BP1', id: 'TUBB2BP1'},
{value: 'TUBB3', id: 'TUBB3'},
{value: 'TUBB3P1', id: 'TUBB3P1'},
{value: 'TUBB3P2', id: 'TUBB3P2'},
{value: 'TUBB4A', id: 'TUBB4A'},
{value: 'TUBB4B', id: 'TUBB4B'},
{value: 'TUBB4BP1', id: 'TUBB4BP1'},
{value: 'TUBB4BP4', id: 'TUBB4BP4'},
{value: 'TUBB4BP5', id: 'TUBB4BP5'},
{value: 'TUBB4BP6', id: 'TUBB4BP6'},
{value: 'TUBB4BP7', id: 'TUBB4BP7'},
{value: 'TUBB6', id: 'TUBB6'},
{value: 'TUBB7P', id: 'TUBB7P'},
{value: 'TUBB8', id: 'TUBB8'},
{value: 'TUBB8B', id: 'TUBB8B'},
{value: 'TUBB8P10', id: 'TUBB8P10'},
{value: 'TUBB8P2', id: 'TUBB8P2'},
{value: 'TUBB8P3', id: 'TUBB8P3'},
{value: 'TUBB8P4', id: 'TUBB8P4'},
{value: 'TUBB8P5', id: 'TUBB8P5'},
{value: 'TUBB8P6', id: 'TUBB8P6'},
{value: 'TUBB8P7', id: 'TUBB8P7'},
{value: 'TUBB8P9', id: 'TUBB8P9'},
{value: 'TUBBP1', id: 'TUBBP1'},
{value: 'TUBBP10', id: 'TUBBP10'},
{value: 'TUBBP11', id: 'TUBBP11'},
{value: 'TUBBP2', id: 'TUBBP2'},
{value: 'TUBBP3', id: 'TUBBP3'},
{value: 'TUBBP5', id: 'TUBBP5'},
{value: 'TUBBP6', id: 'TUBBP6'},
{value: 'TUBBP8', id: 'TUBBP8'},
{value: 'TUBBP9', id: 'TUBBP9'},
{value: 'TUBD1', id: 'TUBD1'},
{value: 'TUBE1', id: 'TUBE1'},
{value: 'TUBG1', id: 'TUBG1'},
{value: 'TUBG1P', id: 'TUBG1P'},
{value: 'TUBG2', id: 'TUBG2'},
{value: 'TUBGCP2', id: 'TUBGCP2'},
{value: 'TUBGCP3', id: 'TUBGCP3'},
{value: 'TUBGCP4', id: 'TUBGCP4'},
{value: 'TUBGCP5', id: 'TUBGCP5'},
{value: 'TUBGCP6', id: 'TUBGCP6'},
{value: 'TUFM', id: 'TUFM'},
{value: 'TUFMP1', id: 'TUFMP1'},
{value: 'TUFT1', id: 'TUFT1'},
{value: 'TUG1', id: 'TUG1'},
{value: 'TULP1', id: 'TULP1'},
{value: 'TULP2', id: 'TULP2'},
{value: 'TULP3', id: 'TULP3'},
{value: 'TULP3P1', id: 'TULP3P1'},
{value: 'TULP4', id: 'TULP4'},
{value: 'TUNAR', id: 'TUNAR'},
{value: 'TUSC1', id: 'TUSC1'},
{value: 'TUSC2', id: 'TUSC2'},
{value: 'TUSC3', id: 'TUSC3'},
{value: 'TUSC8', id: 'TUSC8'},
{value: 'TUT1', id: 'TUT1'},
{value: 'TUT4', id: 'TUT4'},
{value: 'TUT7', id: 'TUT7'},
{value: 'TVP23A', id: 'TVP23A'},
{value: 'TVP23B', id: 'TVP23B'},
{value: 'TVP23BP1', id: 'TVP23BP1'},
{value: 'TVP23C', id: 'TVP23C'},
{value: 'TVP23C-CDRT4', id: 'TVP23C-CDRT4'},
{value: 'TWF1', id: 'TWF1'},
{value: 'TWF1P1', id: 'TWF1P1'},
{value: 'TWF2', id: 'TWF2'},
{value: 'TWIST1', id: 'TWIST1'},
{value: 'TWIST2', id: 'TWIST2'},
{value: 'TWNK', id: 'TWNK'},
{value: 'TWSG1', id: 'TWSG1'},
{value: 'TWSG1-DT', id: 'TWSG1-DT'},
{value: 'TXK', id: 'TXK'},
{value: 'TXLNA', id: 'TXLNA'},
{value: 'TXLNB', id: 'TXLNB'},
{value: 'TXLNG', id: 'TXLNG'},
{value: 'TXLNGY', id: 'TXLNGY'},
{value: 'TXN', id: 'TXN'},
{value: 'TXN2', id: 'TXN2'},
{value: 'TXN2P1', id: 'TXN2P1'},
{value: 'TXNDC11', id: 'TXNDC11'},
{value: 'TXNDC12', id: 'TXNDC12'},
{value: 'TXNDC15', id: 'TXNDC15'},
{value: 'TXNDC16', id: 'TXNDC16'},
{value: 'TXNDC17', id: 'TXNDC17'},
{value: 'TXNDC2', id: 'TXNDC2'},
{value: 'TXNDC5', id: 'TXNDC5'},
{value: 'TXNDC8', id: 'TXNDC8'},
{value: 'TXNDC9', id: 'TXNDC9'},
{value: 'TXNIP', id: 'TXNIP'},
{value: 'TXNL1', id: 'TXNL1'},
{value: 'TXNL1P1', id: 'TXNL1P1'},
{value: 'TXNL4A', id: 'TXNL4A'},
{value: 'TXNL4AP1', id: 'TXNL4AP1'},
{value: 'TXNL4B', id: 'TXNL4B'},
{value: 'TXNP1', id: 'TXNP1'},
{value: 'TXNP2', id: 'TXNP2'},
{value: 'TXNP4', id: 'TXNP4'},
{value: 'TXNP7', id: 'TXNP7'},
{value: 'TXNRD1', id: 'TXNRD1'},
{value: 'TXNRD2', id: 'TXNRD2'},
{value: 'TXNRD3', id: 'TXNRD3'},
{value: 'TYK2', id: 'TYK2'},
{value: 'TYMP', id: 'TYMP'},
{value: 'TYMS', id: 'TYMS'},
{value: 'TYMSOS', id: 'TYMSOS'},
{value: 'TYMSP1', id: 'TYMSP1'},
{value: 'TYMSP2', id: 'TYMSP2'},
{value: 'TYR', id: 'TYR'},
{value: 'TYRL', id: 'TYRL'},
{value: 'TYRO3', id: 'TYRO3'},
{value: 'TYRO3P', id: 'TYRO3P'},
{value: 'TYROBP', id: 'TYROBP'},
{value: 'TYRP1', id: 'TYRP1'},
{value: 'TYSND1', id: 'TYSND1'},
{value: 'TYW1', id: 'TYW1'},
{value: 'TYW1B', id: 'TYW1B'},
{value: 'TYW3', id: 'TYW3'},
{value: 'TYW5', id: 'TYW5'},
{value: 'U1', id: 'U1'},
{value: 'U2', id: 'U2'},
{value: 'U2AF1', id: 'U2AF1'},
{value: 'U2AF1L4', id: 'U2AF1L4'},
{value: 'U2AF1L5', id: 'U2AF1L5'},
{value: 'U2AF2', id: 'U2AF2'},
{value: 'U2SURP', id: 'U2SURP'},
{value: 'U3', id: 'U3'},
{value: 'U4', id: 'U4'},
{value: 'U6', id: 'U6'},
{value: 'U7', id: 'U7'},
{value: 'U8', id: 'U8'},
{value: 'UACA', id: 'UACA'},
{value: 'UAP1', id: 'UAP1'},
{value: 'UAP1-DT', id: 'UAP1-DT'},
{value: 'UAP1L1', id: 'UAP1L1'},
{value: 'UBA1', id: 'UBA1'},
{value: 'UBA2', id: 'UBA2'},
{value: 'UBA3', id: 'UBA3'},
{value: 'UBA5', id: 'UBA5'},
{value: 'UBA52', id: 'UBA52'},
{value: 'UBA52P5', id: 'UBA52P5'},
{value: 'UBA52P8', id: 'UBA52P8'},
{value: 'UBA6', id: 'UBA6'},
{value: 'UBA6-DT', id: 'UBA6-DT'},
{value: 'UBA7', id: 'UBA7'},
{value: 'UBAC1', id: 'UBAC1'},
{value: 'UBAC2', id: 'UBAC2'},
{value: 'UBAC2-AS1', id: 'UBAC2-AS1'},
{value: 'UBALD1', id: 'UBALD1'},
{value: 'UBALD2', id: 'UBALD2'},
{value: 'UBAP1', id: 'UBAP1'},
{value: 'UBAP1L', id: 'UBAP1L'},
{value: 'UBAP2', id: 'UBAP2'},
{value: 'UBAP2L', id: 'UBAP2L'},
{value: 'UBASH3A', id: 'UBASH3A'},
{value: 'UBASH3B', id: 'UBASH3B'},
{value: 'UBB', id: 'UBB'},
{value: 'UBBP1', id: 'UBBP1'},
{value: 'UBBP3', id: 'UBBP3'},
{value: 'UBBP4', id: 'UBBP4'},
{value: 'UBBP5', id: 'UBBP5'},
{value: 'UBC', id: 'UBC'},
{value: 'UBD', id: 'UBD'},
{value: 'UBDP1', id: 'UBDP1'},
{value: 'UBE2A', id: 'UBE2A'},
{value: 'UBE2B', id: 'UBE2B'},
{value: 'UBE2C', id: 'UBE2C'},
{value: 'UBE2CP1', id: 'UBE2CP1'},
{value: 'UBE2CP2', id: 'UBE2CP2'},
{value: 'UBE2CP3', id: 'UBE2CP3'},
{value: 'UBE2CP4', id: 'UBE2CP4'},
{value: 'UBE2D1', id: 'UBE2D1'},
{value: 'UBE2D2', id: 'UBE2D2'},
{value: 'UBE2D3', id: 'UBE2D3'},
{value: 'UBE2D3-AS1', id: 'UBE2D3-AS1'},
{value: 'UBE2D3P1', id: 'UBE2D3P1'},
{value: 'UBE2D3P2', id: 'UBE2D3P2'},
{value: 'UBE2D3P3', id: 'UBE2D3P3'},
{value: 'UBE2D3P4', id: 'UBE2D3P4'},
{value: 'UBE2D4', id: 'UBE2D4'},
{value: 'UBE2E1', id: 'UBE2E1'},
{value: 'UBE2E1-AS1', id: 'UBE2E1-AS1'},
{value: 'UBE2E2', id: 'UBE2E2'},
{value: 'UBE2E2-AS1', id: 'UBE2E2-AS1'},
{value: 'UBE2E3', id: 'UBE2E3'},
{value: 'UBE2F', id: 'UBE2F'},
{value: 'UBE2F-SCLY', id: 'UBE2F-SCLY'},
{value: 'UBE2FP1', id: 'UBE2FP1'},
{value: 'UBE2FP2', id: 'UBE2FP2'},
{value: 'UBE2FP3', id: 'UBE2FP3'},
{value: 'UBE2G1', id: 'UBE2G1'},
{value: 'UBE2G2', id: 'UBE2G2'},
{value: 'UBE2H', id: 'UBE2H'},
{value: 'UBE2HP1', id: 'UBE2HP1'},
{value: 'UBE2I', id: 'UBE2I'},
{value: 'UBE2J1', id: 'UBE2J1'},
{value: 'UBE2J2', id: 'UBE2J2'},
{value: 'UBE2K', id: 'UBE2K'},
{value: 'UBE2L3', id: 'UBE2L3'},
{value: 'UBE2L5', id: 'UBE2L5'},
{value: 'UBE2L6', id: 'UBE2L6'},
{value: 'UBE2M', id: 'UBE2M'},
{value: 'UBE2MP1', id: 'UBE2MP1'},
{value: 'UBE2N', id: 'UBE2N'},
{value: 'UBE2NP1', id: 'UBE2NP1'},
{value: 'UBE2O', id: 'UBE2O'},
{value: 'UBE2Q1', id: 'UBE2Q1'},
{value: 'UBE2Q1-AS1', id: 'UBE2Q1-AS1'},
{value: 'UBE2Q2', id: 'UBE2Q2'},
{value: 'UBE2Q2L', id: 'UBE2Q2L'},
{value: 'UBE2Q2P1', id: 'UBE2Q2P1'},
{value: 'UBE2Q2P2', id: 'UBE2Q2P2'},
{value: 'UBE2Q2P6', id: 'UBE2Q2P6'},
{value: 'UBE2Q2P9', id: 'UBE2Q2P9'},
{value: 'UBE2QL1', id: 'UBE2QL1'},
{value: 'UBE2R2', id: 'UBE2R2'},
{value: 'UBE2R2-AS1', id: 'UBE2R2-AS1'},
{value: 'UBE2S', id: 'UBE2S'},
{value: 'UBE2SP1', id: 'UBE2SP1'},
{value: 'UBE2T', id: 'UBE2T'},
{value: 'UBE2U', id: 'UBE2U'},
{value: 'UBE2V1', id: 'UBE2V1'},
{value: 'UBE2V1P10', id: 'UBE2V1P10'},
{value: 'UBE2V1P12', id: 'UBE2V1P12'},
{value: 'UBE2V1P13', id: 'UBE2V1P13'},
{value: 'UBE2V1P14', id: 'UBE2V1P14'},
{value: 'UBE2V1P4', id: 'UBE2V1P4'},
{value: 'UBE2V1P6', id: 'UBE2V1P6'},
{value: 'UBE2V1P8', id: 'UBE2V1P8'},
{value: 'UBE2V2', id: 'UBE2V2'},
{value: 'UBE2V2P2', id: 'UBE2V2P2'},
{value: 'UBE2V2P3', id: 'UBE2V2P3'},
{value: 'UBE2W', id: 'UBE2W'},
{value: 'UBE2WP1', id: 'UBE2WP1'},
{value: 'UBE2Z', id: 'UBE2Z'},
{value: 'UBE3A', id: 'UBE3A'},
{value: 'UBE3AP2', id: 'UBE3AP2'},
{value: 'UBE3B', id: 'UBE3B'},
{value: 'UBE3C', id: 'UBE3C'},
{value: 'UBE3D', id: 'UBE3D'},
{value: 'UBE4A', id: 'UBE4A'},
{value: 'UBE4B', id: 'UBE4B'},
{value: 'UBFD1', id: 'UBFD1'},
{value: 'UBFD1P1', id: 'UBFD1P1'},
{value: 'UBIAD1', id: 'UBIAD1'},
{value: 'UBL3', id: 'UBL3'},
{value: 'UBL4A', id: 'UBL4A'},
{value: 'UBL4B', id: 'UBL4B'},
{value: 'UBL5', id: 'UBL5'},
{value: 'UBL5P1', id: 'UBL5P1'},
{value: 'UBL5P3', id: 'UBL5P3'},
{value: 'UBL5P4', id: 'UBL5P4'},
{value: 'UBL7', id: 'UBL7'},
{value: 'UBL7-DT', id: 'UBL7-DT'},
{value: 'UBLCP1', id: 'UBLCP1'},
{value: 'UBN1', id: 'UBN1'},
{value: 'UBN2', id: 'UBN2'},
{value: 'UBOX5', id: 'UBOX5'},
{value: 'UBOX5-AS1', id: 'UBOX5-AS1'},
{value: 'UBP1', id: 'UBP1'},
{value: 'UBQLN1', id: 'UBQLN1'},
{value: 'UBQLN1-AS1', id: 'UBQLN1-AS1'},
{value: 'UBQLN1P1', id: 'UBQLN1P1'},
{value: 'UBQLN2', id: 'UBQLN2'},
{value: 'UBQLN3', id: 'UBQLN3'},
{value: 'UBQLN4', id: 'UBQLN4'},
{value: 'UBQLN4P1', id: 'UBQLN4P1'},
{value: 'UBQLN4P2', id: 'UBQLN4P2'},
{value: 'UBQLNL', id: 'UBQLNL'},
{value: 'UBR1', id: 'UBR1'},
{value: 'UBR2', id: 'UBR2'},
{value: 'UBR3', id: 'UBR3'},
{value: 'UBR4', id: 'UBR4'},
{value: 'UBR5', id: 'UBR5'},
{value: 'UBR5-DT', id: 'UBR5-DT'},
{value: 'UBR7', id: 'UBR7'},
{value: 'UBTD1', id: 'UBTD1'},
{value: 'UBTD2', id: 'UBTD2'},
{value: 'UBTF', id: 'UBTF'},
{value: 'UBTFL1', id: 'UBTFL1'},
{value: 'UBTFL10', id: 'UBTFL10'},
{value: 'UBTFL2', id: 'UBTFL2'},
{value: 'UBTFL3', id: 'UBTFL3'},
{value: 'UBTFL5', id: 'UBTFL5'},
{value: 'UBTFL7', id: 'UBTFL7'},
{value: 'UBTFL8', id: 'UBTFL8'},
{value: 'UBTFL9', id: 'UBTFL9'},
{value: 'UBXN1', id: 'UBXN1'},
{value: 'UBXN10', id: 'UBXN10'},
{value: 'UBXN11', id: 'UBXN11'},
{value: 'UBXN2A', id: 'UBXN2A'},
{value: 'UBXN2B', id: 'UBXN2B'},
{value: 'UBXN4', id: 'UBXN4'},
{value: 'UBXN6', id: 'UBXN6'},
{value: 'UBXN7', id: 'UBXN7'},
{value: 'UBXN7-AS1', id: 'UBXN7-AS1'},
{value: 'UBXN8', id: 'UBXN8'},
{value: 'UCA1', id: 'UCA1'},
{value: 'UCHL1', id: 'UCHL1'},
{value: 'UCHL1-DT', id: 'UCHL1-DT'},
{value: 'UCHL3', id: 'UCHL3'},
{value: 'UCHL5', id: 'UCHL5'},
{value: 'UCK1', id: 'UCK1'},
{value: 'UCK2', id: 'UCK2'},
{value: 'UCKL1', id: 'UCKL1'},
{value: 'UCKL1-AS1', id: 'UCKL1-AS1'},
{value: 'UCMA', id: 'UCMA'},
{value: 'UCN', id: 'UCN'},
{value: 'UCN2', id: 'UCN2'},
{value: 'UCN3', id: 'UCN3'},
{value: 'UCP1', id: 'UCP1'},
{value: 'UCP2', id: 'UCP2'},
{value: 'UCP3', id: 'UCP3'},
{value: 'UEVLD', id: 'UEVLD'},
{value: 'UFC1', id: 'UFC1'},
{value: 'UFD1', id: 'UFD1'},
{value: 'UFL1', id: 'UFL1'},
{value: 'UFL1-AS1', id: 'UFL1-AS1'},
{value: 'UFM1', id: 'UFM1'},
{value: 'UFM1P2', id: 'UFM1P2'},
{value: 'UFSP1', id: 'UFSP1'},
{value: 'UFSP2', id: 'UFSP2'},
{value: 'UGCG', id: 'UGCG'},
{value: 'UGDH', id: 'UGDH'},
{value: 'UGDH-AS1', id: 'UGDH-AS1'},
{value: 'UGGT1', id: 'UGGT1'},
{value: 'UGGT2', id: 'UGGT2'},
{value: 'UGP2', id: 'UGP2'},
{value: 'UGT1A1', id: 'UGT1A1'},
{value: 'UGT1A10', id: 'UGT1A10'},
{value: 'UGT1A11P', id: 'UGT1A11P'},
{value: 'UGT1A12P', id: 'UGT1A12P'},
{value: 'UGT1A2P', id: 'UGT1A2P'},
{value: 'UGT1A3', id: 'UGT1A3'},
{value: 'UGT1A4', id: 'UGT1A4'},
{value: 'UGT1A5', id: 'UGT1A5'},
{value: 'UGT1A6', id: 'UGT1A6'},
{value: 'UGT1A7', id: 'UGT1A7'},
{value: 'UGT1A8', id: 'UGT1A8'},
{value: 'UGT2A1', id: 'UGT2A1'},
{value: 'UGT2A3', id: 'UGT2A3'},
{value: 'UGT2A3P7', id: 'UGT2A3P7'},
{value: 'UGT2B10', id: 'UGT2B10'},
{value: 'UGT2B11', id: 'UGT2B11'},
{value: 'UGT2B15', id: 'UGT2B15'},
{value: 'UGT2B17', id: 'UGT2B17'},
{value: 'UGT2B25P', id: 'UGT2B25P'},
{value: 'UGT2B26P', id: 'UGT2B26P'},
{value: 'UGT2B27P', id: 'UGT2B27P'},
{value: 'UGT2B28', id: 'UGT2B28'},
{value: 'UGT2B29P', id: 'UGT2B29P'},
{value: 'UGT2B4', id: 'UGT2B4'},
{value: 'UGT2B7', id: 'UGT2B7'},
{value: 'UGT3A1', id: 'UGT3A1'},
{value: 'UGT3A2', id: 'UGT3A2'},
{value: 'UGT8', id: 'UGT8'},
{value: 'UHMK1', id: 'UHMK1'},
{value: 'UHRF1', id: 'UHRF1'},
{value: 'UHRF1BP1', id: 'UHRF1BP1'},
{value: 'UHRF1BP1L', id: 'UHRF1BP1L'},
{value: 'UHRF1BP1L-DT', id: 'UHRF1BP1L-DT'},
{value: 'UHRF2', id: 'UHRF2'},
{value: 'UICLM', id: 'UICLM'},
{value: 'UIMC1', id: 'UIMC1'},
{value: 'ULBP1', id: 'ULBP1'},
{value: 'ULBP2', id: 'ULBP2'},
{value: 'ULBP3', id: 'ULBP3'},
{value: 'ULK1', id: 'ULK1'},
{value: 'ULK2', id: 'ULK2'},
{value: 'ULK3', id: 'ULK3'},
{value: 'ULK4', id: 'ULK4'},
{value: 'ULK4P1', id: 'ULK4P1'},
{value: 'ULK4P2', id: 'ULK4P2'},
{value: 'ULK4P3', id: 'ULK4P3'},
{value: 'UMAD1', id: 'UMAD1'},
{value: 'UMLILO', id: 'UMLILO'},
{value: 'UMOD', id: 'UMOD'},
{value: 'UMODL1', id: 'UMODL1'},
{value: 'UMODL1-AS1', id: 'UMODL1-AS1'},
{value: 'UMPS', id: 'UMPS'},
{value: 'UNC119', id: 'UNC119'},
{value: 'UNC119B', id: 'UNC119B'},
{value: 'UNC13A', id: 'UNC13A'},
{value: 'UNC13B', id: 'UNC13B'},
{value: 'UNC13C', id: 'UNC13C'},
{value: 'UNC13D', id: 'UNC13D'},
{value: 'UNC45A', id: 'UNC45A'},
{value: 'UNC45B', id: 'UNC45B'},
{value: 'UNC50', id: 'UNC50'},
{value: 'UNC5A', id: 'UNC5A'},
{value: 'UNC5B', id: 'UNC5B'},
{value: 'UNC5B-AS1', id: 'UNC5B-AS1'},
{value: 'UNC5C', id: 'UNC5C'},
{value: 'UNC5C-AS1', id: 'UNC5C-AS1'},
{value: 'UNC5CL', id: 'UNC5CL'},
{value: 'UNC5D', id: 'UNC5D'},
{value: 'UNC79', id: 'UNC79'},
{value: 'UNC80', id: 'UNC80'},
{value: 'UNC93A', id: 'UNC93A'},
{value: 'UNC93B1', id: 'UNC93B1'},
{value: 'UNC93B2', id: 'UNC93B2'},
{value: 'UNC93B3', id: 'UNC93B3'},
{value: 'UNC93B5', id: 'UNC93B5'},
{value: 'UNC93B6', id: 'UNC93B6'},
{value: 'UNC93B7', id: 'UNC93B7'},
{value: 'UNC93B8', id: 'UNC93B8'},
{value: 'UNCX', id: 'UNCX'},
{value: 'UNG', id: 'UNG'},
{value: 'UNGP1', id: 'UNGP1'},
{value: 'UNGP3', id: 'UNGP3'},
{value: 'UNK', id: 'UNK'},
{value: 'UNKL', id: 'UNKL'},
{value: 'UPB1', id: 'UPB1'},
{value: 'UPF1', id: 'UPF1'},
{value: 'UPF2', id: 'UPF2'},
{value: 'UPF3A', id: 'UPF3A'},
{value: 'UPF3AP1', id: 'UPF3AP1'},
{value: 'UPF3AP2', id: 'UPF3AP2'},
{value: 'UPF3AP3', id: 'UPF3AP3'},
{value: 'UPF3B', id: 'UPF3B'},
{value: 'UPK1A', id: 'UPK1A'},
{value: 'UPK1A-AS1', id: 'UPK1A-AS1'},
{value: 'UPK1B', id: 'UPK1B'},
{value: 'UPK2', id: 'UPK2'},
{value: 'UPK3A', id: 'UPK3A'},
{value: 'UPK3B', id: 'UPK3B'},
{value: 'UPK3BL1', id: 'UPK3BL1'},
{value: 'UPK3BL2', id: 'UPK3BL2'},
{value: 'UPP1', id: 'UPP1'},
{value: 'UPP2', id: 'UPP2'},
{value: 'UPP2-IT1', id: 'UPP2-IT1'},
{value: 'UPRT', id: 'UPRT'},
{value: 'UQCC1', id: 'UQCC1'},
{value: 'UQCC2', id: 'UQCC2'},
{value: 'UQCC3', id: 'UQCC3'},
{value: 'UQCR10', id: 'UQCR10'},
{value: 'UQCR11', id: 'UQCR11'},
{value: 'UQCRB', id: 'UQCRB'},
{value: 'UQCRB-AS1', id: 'UQCRB-AS1'},
{value: 'UQCRBP2', id: 'UQCRBP2'},
{value: 'UQCRBP3', id: 'UQCRBP3'},
{value: 'UQCRC1', id: 'UQCRC1'},
{value: 'UQCRC2', id: 'UQCRC2'},
{value: 'UQCRC2P1', id: 'UQCRC2P1'},
{value: 'UQCRFS1', id: 'UQCRFS1'},
{value: 'UQCRFS1-DT', id: 'UQCRFS1-DT'},
{value: 'UQCRFS1P1', id: 'UQCRFS1P1'},
{value: 'UQCRFS1P2', id: 'UQCRFS1P2'},
{value: 'UQCRH', id: 'UQCRH'},
{value: 'UQCRHL', id: 'UQCRHL'},
{value: 'UQCRHP1', id: 'UQCRHP1'},
{value: 'UQCRHP2', id: 'UQCRHP2'},
{value: 'UQCRHP3', id: 'UQCRHP3'},
{value: 'UQCRHP4', id: 'UQCRHP4'},
{value: 'UQCRQ', id: 'UQCRQ'},
{value: 'URAD', id: 'URAD'},
{value: 'URAHP', id: 'URAHP'},
{value: 'URB1', id: 'URB1'},
{value: 'URB1-AS1', id: 'URB1-AS1'},
{value: 'URB2', id: 'URB2'},
{value: 'URGCP', id: 'URGCP'},
{value: 'URGCP-MRPS24', id: 'URGCP-MRPS24'},
{value: 'URI1', id: 'URI1'},
{value: 'URM1', id: 'URM1'},
{value: 'UROC1', id: 'UROC1'},
{value: 'UROD', id: 'UROD'},
{value: 'UROS', id: 'UROS'},
{value: 'USB1', id: 'USB1'},
{value: 'USE1', id: 'USE1'},
{value: 'USF1', id: 'USF1'},
{value: 'USF1P1', id: 'USF1P1'},
{value: 'USF2', id: 'USF2'},
{value: 'USF3', id: 'USF3'},
{value: 'USH1C', id: 'USH1C'},
{value: 'USH1G', id: 'USH1G'},
{value: 'USH2A', id: 'USH2A'},
{value: 'USH2A-AS1', id: 'USH2A-AS1'},
{value: 'USHBP1', id: 'USHBP1'},
{value: 'USO1', id: 'USO1'},
{value: 'USP1', id: 'USP1'},
{value: 'USP10', id: 'USP10'},
{value: 'USP11', id: 'USP11'},
{value: 'USP12', id: 'USP12'},
{value: 'USP12-AS2', id: 'USP12-AS2'},
{value: 'USP13', id: 'USP13'},
{value: 'USP14', id: 'USP14'},
{value: 'USP15', id: 'USP15'},
{value: 'USP16', id: 'USP16'},
{value: 'USP17L7', id: 'USP17L7'},
{value: 'USP18', id: 'USP18'},
{value: 'USP19', id: 'USP19'},
{value: 'USP2', id: 'USP2'},
{value: 'USP2-AS1', id: 'USP2-AS1'},
{value: 'USP20', id: 'USP20'},
{value: 'USP21', id: 'USP21'},
{value: 'USP21P1', id: 'USP21P1'},
{value: 'USP21P2', id: 'USP21P2'},
{value: 'USP22', id: 'USP22'},
{value: 'USP24', id: 'USP24'},
{value: 'USP25', id: 'USP25'},
{value: 'USP27X', id: 'USP27X'},
{value: 'USP27X-DT', id: 'USP27X-DT'},
{value: 'USP28', id: 'USP28'},
{value: 'USP29', id: 'USP29'},
{value: 'USP3', id: 'USP3'},
{value: 'USP3-AS1', id: 'USP3-AS1'},
{value: 'USP30', id: 'USP30'},
{value: 'USP30-AS1', id: 'USP30-AS1'},
{value: 'USP31', id: 'USP31'},
{value: 'USP32', id: 'USP32'},
{value: 'USP32P1', id: 'USP32P1'},
{value: 'USP32P2', id: 'USP32P2'},
{value: 'USP32P3', id: 'USP32P3'},
{value: 'USP33', id: 'USP33'},
{value: 'USP34', id: 'USP34'},
{value: 'USP34-DT', id: 'USP34-DT'},
{value: 'USP35', id: 'USP35'},
{value: 'USP36', id: 'USP36'},
{value: 'USP37', id: 'USP37'},
{value: 'USP38', id: 'USP38'},
{value: 'USP38-DT', id: 'USP38-DT'},
{value: 'USP39', id: 'USP39'},
{value: 'USP4', id: 'USP4'},
{value: 'USP40', id: 'USP40'},
{value: 'USP41', id: 'USP41'},
{value: 'USP42', id: 'USP42'},
{value: 'USP43', id: 'USP43'},
{value: 'USP44', id: 'USP44'},
{value: 'USP45', id: 'USP45'},
{value: 'USP46', id: 'USP46'},
{value: 'USP46-DT', id: 'USP46-DT'},
{value: 'USP47', id: 'USP47'},
{value: 'USP48', id: 'USP48'},
{value: 'USP49', id: 'USP49'},
{value: 'USP5', id: 'USP5'},
{value: 'USP50', id: 'USP50'},
{value: 'USP51', id: 'USP51'},
{value: 'USP53', id: 'USP53'},
{value: 'USP54', id: 'USP54'},
{value: 'USP6', id: 'USP6'},
{value: 'USP6NL', id: 'USP6NL'},
{value: 'USP6NL-AS1', id: 'USP6NL-AS1'},
{value: 'USP7', id: 'USP7'},
{value: 'USP7-AS1', id: 'USP7-AS1'},
{value: 'USP8', id: 'USP8'},
{value: 'USP8P1', id: 'USP8P1'},
{value: 'USP9X', id: 'USP9X'},
{value: 'USP9Y', id: 'USP9Y'},
{value: 'USPL1', id: 'USPL1'},
{value: 'UST', id: 'UST'},
{value: 'UST-AS1', id: 'UST-AS1'},
{value: 'UST-AS2', id: 'UST-AS2'},
{value: 'UTF1', id: 'UTF1'},
{value: 'UTP11', id: 'UTP11'},
{value: 'UTP14A', id: 'UTP14A'},
{value: 'UTP14C', id: 'UTP14C'},
{value: 'UTP15', id: 'UTP15'},
{value: 'UTP18', id: 'UTP18'},
{value: 'UTP20', id: 'UTP20'},
{value: 'UTP23', id: 'UTP23'},
{value: 'UTP25', id: 'UTP25'},
{value: 'UTP3', id: 'UTP3'},
{value: 'UTP4', id: 'UTP4'},
{value: 'UTP6', id: 'UTP6'},
{value: 'UTRN', id: 'UTRN'},
{value: 'UTS2', id: 'UTS2'},
{value: 'UTS2B', id: 'UTS2B'},
{value: 'UTS2R', id: 'UTS2R'},
{value: 'UTY', id: 'UTY'},
{value: 'UVRAG', id: 'UVRAG'},
{value: 'UVRAG-DT', id: 'UVRAG-DT'},
{value: 'UVSSA', id: 'UVSSA'},
{value: 'UXS1', id: 'UXS1'},
{value: 'UXT', id: 'UXT'},
{value: 'UXT-AS1', id: 'UXT-AS1'},
{value: 'VAC14', id: 'VAC14'},
{value: 'VAC14-AS1', id: 'VAC14-AS1'},
{value: 'VAMP1', id: 'VAMP1'},
{value: 'VAMP2', id: 'VAMP2'},
{value: 'VAMP3', id: 'VAMP3'},
{value: 'VAMP4', id: 'VAMP4'},
{value: 'VAMP5', id: 'VAMP5'},
{value: 'VAMP7', id: 'VAMP7'},
{value: 'VAMP8', id: 'VAMP8'},
{value: 'VAMP9P', id: 'VAMP9P'},
{value: 'VANGL1', id: 'VANGL1'},
{value: 'VANGL2', id: 'VANGL2'},
{value: 'VAPA', id: 'VAPA'},
{value: 'VAPB', id: 'VAPB'},
{value: 'VARS1', id: 'VARS1'},
{value: 'VARS2', id: 'VARS2'},
{value: 'VASH1', id: 'VASH1'},
{value: 'VASH1-AS1', id: 'VASH1-AS1'},
{value: 'VASH1-DT', id: 'VASH1-DT'},
{value: 'VASH2', id: 'VASH2'},
{value: 'VASN', id: 'VASN'},
{value: 'VASP', id: 'VASP'},
{value: 'VAT1', id: 'VAT1'},
{value: 'VAT1L', id: 'VAT1L'},
{value: 'Vault', id: 'Vault'},
{value: 'VAV1', id: 'VAV1'},
{value: 'VAV2', id: 'VAV2'},
{value: 'VAV3', id: 'VAV3'},
{value: 'VAV3-AS1', id: 'VAV3-AS1'},
{value: 'VAX1', id: 'VAX1'},
{value: 'VAX2', id: 'VAX2'},
{value: 'VBP1', id: 'VBP1'},
{value: 'VCAM1', id: 'VCAM1'},
{value: 'VCAN', id: 'VCAN'},
{value: 'VCAN-AS1', id: 'VCAN-AS1'},
{value: 'VCL', id: 'VCL'},
{value: 'VCP', id: 'VCP'},
{value: 'VCPIP1', id: 'VCPIP1'},
{value: 'VCPKMT', id: 'VCPKMT'},
{value: 'VDAC1', id: 'VDAC1'},
{value: 'VDAC1P1', id: 'VDAC1P1'},
{value: 'VDAC1P10', id: 'VDAC1P10'},
{value: 'VDAC1P11', id: 'VDAC1P11'},
{value: 'VDAC1P12', id: 'VDAC1P12'},
{value: 'VDAC1P13', id: 'VDAC1P13'},
{value: 'VDAC1P4', id: 'VDAC1P4'},
{value: 'VDAC1P5', id: 'VDAC1P5'},
{value: 'VDAC1P6', id: 'VDAC1P6'},
{value: 'VDAC1P8', id: 'VDAC1P8'},
{value: 'VDAC1P9', id: 'VDAC1P9'},
{value: 'VDAC2', id: 'VDAC2'},
{value: 'VDAC2P2', id: 'VDAC2P2'},
{value: 'VDAC2P3', id: 'VDAC2P3'},
{value: 'VDAC2P5', id: 'VDAC2P5'},
{value: 'VDAC3', id: 'VDAC3'},
{value: 'VDAC3P1', id: 'VDAC3P1'},
{value: 'VDR', id: 'VDR'},
{value: 'VEGFA', id: 'VEGFA'},
{value: 'VEGFB', id: 'VEGFB'},
{value: 'VEGFC', id: 'VEGFC'},
{value: 'VEGFD', id: 'VEGFD'},
{value: 'VENTX', id: 'VENTX'},
{value: 'VENTXP2', id: 'VENTXP2'},
{value: 'VENTXP3', id: 'VENTXP3'},
{value: 'VENTXP4', id: 'VENTXP4'},
{value: 'VENTXP5', id: 'VENTXP5'},
{value: 'VENTXP6', id: 'VENTXP6'},
{value: 'VENTXP7', id: 'VENTXP7'},
{value: 'VEPH1', id: 'VEPH1'},
{value: 'VEZF1', id: 'VEZF1'},
{value: 'VEZF1P1', id: 'VEZF1P1'},
{value: 'VEZT', id: 'VEZT'},
{value: 'VGF', id: 'VGF'},
{value: 'VGLL2', id: 'VGLL2'},
{value: 'VGLL3', id: 'VGLL3'},
{value: 'VGLL4', id: 'VGLL4'},
{value: 'VHL', id: 'VHL'},
{value: 'VHLL', id: 'VHLL'},
{value: 'VIL1', id: 'VIL1'},
{value: 'VILL', id: 'VILL'},
{value: 'VIM', id: 'VIM'},
{value: 'VIM-AS1', id: 'VIM-AS1'},
{value: 'VINAC1P', id: 'VINAC1P'},
{value: 'VIP', id: 'VIP'},
{value: 'VIPAS39', id: 'VIPAS39'},
{value: 'VIPR1', id: 'VIPR1'},
{value: 'VIPR1-AS1', id: 'VIPR1-AS1'},
{value: 'VIPR2', id: 'VIPR2'},
{value: 'VIRMA', id: 'VIRMA'},
{value: 'VIRMA-DT', id: 'VIRMA-DT'},
{value: 'VIT', id: 'VIT'},
{value: 'VKORC1', id: 'VKORC1'},
{value: 'VKORC1L1', id: 'VKORC1L1'},
{value: 'VLDLR', id: 'VLDLR'},
{value: 'VLDLR-AS1', id: 'VLDLR-AS1'},
{value: 'VMA21', id: 'VMA21'},
{value: 'VMAC', id: 'VMAC'},
{value: 'VMO1', id: 'VMO1'},
{value: 'VMP1', id: 'VMP1'},
{value: 'VN1R1', id: 'VN1R1'},
{value: 'VN1R108P', id: 'VN1R108P'},
{value: 'VN1R10P', id: 'VN1R10P'},
{value: 'VN1R11P', id: 'VN1R11P'},
{value: 'VN1R13P', id: 'VN1R13P'},
{value: 'VN1R2', id: 'VN1R2'},
{value: 'VN1R20P', id: 'VN1R20P'},
{value: 'VN1R21P', id: 'VN1R21P'},
{value: 'VN1R25P', id: 'VN1R25P'},
{value: 'VN1R28P', id: 'VN1R28P'},
{value: 'VN1R3', id: 'VN1R3'},
{value: 'VN1R31P', id: 'VN1R31P'},
{value: 'VN1R32P', id: 'VN1R32P'},
{value: 'VN1R33P', id: 'VN1R33P'},
{value: 'VN1R34P', id: 'VN1R34P'},
{value: 'VN1R35P', id: 'VN1R35P'},
{value: 'VN1R38P', id: 'VN1R38P'},
{value: 'VN1R4', id: 'VN1R4'},
{value: 'VN1R46P', id: 'VN1R46P'},
{value: 'VN1R5', id: 'VN1R5'},
{value: 'VN1R53P', id: 'VN1R53P'},
{value: 'VN1R64P', id: 'VN1R64P'},
{value: 'VN1R65P', id: 'VN1R65P'},
{value: 'VN1R66P', id: 'VN1R66P'},
{value: 'VN1R67P', id: 'VN1R67P'},
{value: 'VN1R68P', id: 'VN1R68P'},
{value: 'VN1R69P', id: 'VN1R69P'},
{value: 'VN1R6P', id: 'VN1R6P'},
{value: 'VN1R70P', id: 'VN1R70P'},
{value: 'VN1R71P', id: 'VN1R71P'},
{value: 'VN1R74P', id: 'VN1R74P'},
{value: 'VN1R79P', id: 'VN1R79P'},
{value: 'VN1R7P', id: 'VN1R7P'},
{value: 'VN1R80P', id: 'VN1R80P'},
{value: 'VN1R82P', id: 'VN1R82P'},
{value: 'VN1R83P', id: 'VN1R83P'},
{value: 'VN1R87P', id: 'VN1R87P'},
{value: 'VN1R88P', id: 'VN1R88P'},
{value: 'VN1R90P', id: 'VN1R90P'},
{value: 'VN1R91P', id: 'VN1R91P'},
{value: 'VN1R92P', id: 'VN1R92P'},
{value: 'VN1R96P', id: 'VN1R96P'},
{value: 'VN1R9P', id: 'VN1R9P'},
{value: 'VN2R19P', id: 'VN2R19P'},
{value: 'VN2R1P', id: 'VN2R1P'},
{value: 'VN2R3P', id: 'VN2R3P'},
{value: 'VN2R9P', id: 'VN2R9P'},
{value: 'VNN1', id: 'VNN1'},
{value: 'VNN2', id: 'VNN2'},
{value: 'VNN3', id: 'VNN3'},
{value: 'VOPP1', id: 'VOPP1'},
{value: 'VPREB1', id: 'VPREB1'},
{value: 'VPREB3', id: 'VPREB3'},
{value: 'VPS11', id: 'VPS11'},
{value: 'VPS11-DT', id: 'VPS11-DT'},
{value: 'VPS13A', id: 'VPS13A'},
{value: 'VPS13A-AS1', id: 'VPS13A-AS1'},
{value: 'VPS13B', id: 'VPS13B'},
{value: 'VPS13B-DT', id: 'VPS13B-DT'},
{value: 'VPS13C', id: 'VPS13C'},
{value: 'VPS13C-DT', id: 'VPS13C-DT'},
{value: 'VPS13D', id: 'VPS13D'},
{value: 'VPS16', id: 'VPS16'},
{value: 'VPS18', id: 'VPS18'},
{value: 'VPS25', id: 'VPS25'},
{value: 'VPS25P1', id: 'VPS25P1'},
{value: 'VPS26A', id: 'VPS26A'},
{value: 'VPS26B', id: 'VPS26B'},
{value: 'VPS26BP1', id: 'VPS26BP1'},
{value: 'VPS26C', id: 'VPS26C'},
{value: 'VPS28', id: 'VPS28'},
{value: 'VPS29', id: 'VPS29'},
{value: 'VPS33A', id: 'VPS33A'},
{value: 'VPS33B', id: 'VPS33B'},
{value: 'VPS33B-DT', id: 'VPS33B-DT'},
{value: 'VPS35', id: 'VPS35'},
{value: 'VPS35L', id: 'VPS35L'},
{value: 'VPS35P1', id: 'VPS35P1'},
{value: 'VPS36', id: 'VPS36'},
{value: 'VPS37A', id: 'VPS37A'},
{value: 'VPS37B', id: 'VPS37B'},
{value: 'VPS37C', id: 'VPS37C'},
{value: 'VPS37D', id: 'VPS37D'},
{value: 'VPS39', id: 'VPS39'},
{value: 'VPS39-DT', id: 'VPS39-DT'},
{value: 'VPS41', id: 'VPS41'},
{value: 'VPS45', id: 'VPS45'},
{value: 'VPS4A', id: 'VPS4A'},
{value: 'VPS4B', id: 'VPS4B'},
{value: 'VPS50', id: 'VPS50'},
{value: 'VPS51', id: 'VPS51'},
{value: 'VPS52', id: 'VPS52'},
{value: 'VPS53', id: 'VPS53'},
{value: 'VPS54', id: 'VPS54'},
{value: 'VPS72', id: 'VPS72'},
{value: 'VPS8', id: 'VPS8'},
{value: 'VPS9D1', id: 'VPS9D1'},
{value: 'VPS9D1-AS1', id: 'VPS9D1-AS1'},
{value: 'VRK1', id: 'VRK1'},
{value: 'VRK2', id: 'VRK2'},
{value: 'VRK3', id: 'VRK3'},
{value: 'VRTN', id: 'VRTN'},
{value: 'VSIG1', id: 'VSIG1'},
{value: 'VSIG10', id: 'VSIG10'},
{value: 'VSIG10L', id: 'VSIG10L'},
{value: 'VSIG10L-AS1', id: 'VSIG10L-AS1'},
{value: 'VSIG10L2', id: 'VSIG10L2'},
{value: 'VSIG2', id: 'VSIG2'},
{value: 'VSIG4', id: 'VSIG4'},
{value: 'VSIG8', id: 'VSIG8'},
{value: 'VSIR', id: 'VSIR'},
{value: 'VSNL1', id: 'VSNL1'},
{value: 'VSTM1', id: 'VSTM1'},
{value: 'VSTM2A', id: 'VSTM2A'},
{value: 'VSTM2B', id: 'VSTM2B'},
{value: 'VSTM2B-DT', id: 'VSTM2B-DT'},
{value: 'VSTM2L', id: 'VSTM2L'},
{value: 'VSTM4', id: 'VSTM4'},
{value: 'VSTM5', id: 'VSTM5'},
{value: 'VSX1', id: 'VSX1'},
{value: 'VSX2', id: 'VSX2'},
{value: 'VTA1', id: 'VTA1'},
{value: 'VTA1P1', id: 'VTA1P1'},
{value: 'VTCN1', id: 'VTCN1'},
{value: 'VTI1A', id: 'VTI1A'},
{value: 'VTI1B', id: 'VTI1B'},
{value: 'VTI1BP1', id: 'VTI1BP1'},
{value: 'VTI1BP2', id: 'VTI1BP2'},
{value: 'VTI1BP3', id: 'VTI1BP3'},
{value: 'VTN', id: 'VTN'},
{value: 'VTRNA1-1', id: 'VTRNA1-1'},
{value: 'VTRNA1-2', id: 'VTRNA1-2'},
{value: 'VTRNA1-3', id: 'VTRNA1-3'},
{value: 'VTRNA2-1', id: 'VTRNA2-1'},
{value: 'VTRNA2-2P', id: 'VTRNA2-2P'},
{value: 'VWA1', id: 'VWA1'},
{value: 'VWA2', id: 'VWA2'},
{value: 'VWA3A', id: 'VWA3A'},
{value: 'VWA3B', id: 'VWA3B'},
{value: 'VWA5A', id: 'VWA5A'},
{value: 'VWA5B1', id: 'VWA5B1'},
{value: 'VWA5B2', id: 'VWA5B2'},
{value: 'VWA7', id: 'VWA7'},
{value: 'VWA8', id: 'VWA8'},
{value: 'VWA8-AS1', id: 'VWA8-AS1'},
{value: 'VWA8P1', id: 'VWA8P1'},
{value: 'VWC2', id: 'VWC2'},
{value: 'VWC2L', id: 'VWC2L'},
{value: 'VWCE', id: 'VWCE'},
{value: 'VWDE', id: 'VWDE'},
{value: 'VWF', id: 'VWF'},
{value: 'VWFP1', id: 'VWFP1'},
{value: 'VXN', id: 'VXN'},
{value: 'WAC', id: 'WAC'},
{value: 'WAC-AS1', id: 'WAC-AS1'},
{value: 'WAKMAR2', id: 'WAKMAR2'},
{value: 'WAPL', id: 'WAPL'},
{value: 'WARS1', id: 'WARS1'},
{value: 'WARS1P1', id: 'WARS1P1'},
{value: 'WARS2', id: 'WARS2'},
{value: 'WARS2-AS1', id: 'WARS2-AS1'},
{value: 'WARS2-IT1', id: 'WARS2-IT1'},
{value: 'WARS2P1', id: 'WARS2P1'},
{value: 'WAS', id: 'WAS'},
{value: 'WASF1', id: 'WASF1'},
{value: 'WASF1P1', id: 'WASF1P1'},
{value: 'WASF2', id: 'WASF2'},
{value: 'WASF3', id: 'WASF3'},
{value: 'WASF4P', id: 'WASF4P'},
{value: 'WASF5P', id: 'WASF5P'},
{value: 'WASH2P', id: 'WASH2P'},
{value: 'WASH3P', id: 'WASH3P'},
{value: 'WASH4P', id: 'WASH4P'},
{value: 'WASH5P', id: 'WASH5P'},
{value: 'WASH6P', id: 'WASH6P'},
{value: 'WASH7P', id: 'WASH7P'},
{value: 'WASH8P', id: 'WASH8P'},
{value: 'WASH9P', id: 'WASH9P'},
{value: 'WASHC1', id: 'WASHC1'},
{value: 'WASHC2A', id: 'WASHC2A'},
{value: 'WASHC2C', id: 'WASHC2C'},
{value: 'WASHC3', id: 'WASHC3'},
{value: 'WASHC4', id: 'WASHC4'},
{value: 'WASHC5', id: 'WASHC5'},
{value: 'WASIR2', id: 'WASIR2'},
{value: 'WASL', id: 'WASL'},
{value: 'WASL-DT', id: 'WASL-DT'},
{value: 'WBP1', id: 'WBP1'},
{value: 'WBP11', id: 'WBP11'},
{value: 'WBP11P2', id: 'WBP11P2'},
{value: 'WBP1L', id: 'WBP1L'},
{value: 'WBP1LP1', id: 'WBP1LP1'},
{value: 'WBP1LP12', id: 'WBP1LP12'},
{value: 'WBP1LP2', id: 'WBP1LP2'},
{value: 'WBP1P1', id: 'WBP1P1'},
{value: 'WBP1P2', id: 'WBP1P2'},
{value: 'WBP2', id: 'WBP2'},
{value: 'WBP2NL', id: 'WBP2NL'},
{value: 'WBP2P1', id: 'WBP2P1'},
{value: 'WBP4', id: 'WBP4'},
{value: 'WDCP', id: 'WDCP'},
{value: 'WDFY1', id: 'WDFY1'},
{value: 'WDFY2', id: 'WDFY2'},
{value: 'WDFY3', id: 'WDFY3'},
{value: 'WDFY3-AS2', id: 'WDFY3-AS2'},
{value: 'WDFY4', id: 'WDFY4'},
{value: 'WDHD1', id: 'WDHD1'},
{value: 'WDPCP', id: 'WDPCP'},
{value: 'WDR1', id: 'WDR1'},
{value: 'WDR11', id: 'WDR11'},
{value: 'WDR11-AS1', id: 'WDR11-AS1'},
{value: 'WDR12', id: 'WDR12'},
{value: 'WDR13', id: 'WDR13'},
{value: 'WDR17', id: 'WDR17'},
{value: 'WDR18', id: 'WDR18'},
{value: 'WDR19', id: 'WDR19'},
{value: 'WDR20', id: 'WDR20'},
{value: 'WDR24', id: 'WDR24'},
{value: 'WDR25', id: 'WDR25'},
{value: 'WDR26', id: 'WDR26'},
{value: 'WDR27', id: 'WDR27'},
{value: 'WDR3', id: 'WDR3'},
{value: 'WDR31', id: 'WDR31'},
{value: 'WDR33', id: 'WDR33'},
{value: 'WDR35', id: 'WDR35'},
{value: 'WDR36', id: 'WDR36'},
{value: 'WDR37', id: 'WDR37'},
{value: 'WDR38', id: 'WDR38'},
{value: 'WDR4', id: 'WDR4'},
{value: 'WDR41', id: 'WDR41'},
{value: 'WDR43', id: 'WDR43'},
{value: 'WDR44', id: 'WDR44'},
{value: 'WDR45', id: 'WDR45'},
{value: 'WDR45B', id: 'WDR45B'},
{value: 'WDR45BP1', id: 'WDR45BP1'},
{value: 'WDR45P1', id: 'WDR45P1'},
{value: 'WDR46', id: 'WDR46'},
{value: 'WDR47', id: 'WDR47'},
{value: 'WDR48', id: 'WDR48'},
{value: 'WDR49', id: 'WDR49'},
{value: 'WDR4P2', id: 'WDR4P2'},
{value: 'WDR5', id: 'WDR5'},
{value: 'WDR5-DT', id: 'WDR5-DT'},
{value: 'WDR53', id: 'WDR53'},
{value: 'WDR54', id: 'WDR54'},
{value: 'WDR55', id: 'WDR55'},
{value: 'WDR59', id: 'WDR59'},
{value: 'WDR5B', id: 'WDR5B'},
{value: 'WDR5B-DT', id: 'WDR5B-DT'},
{value: 'WDR6', id: 'WDR6'},
{value: 'WDR61', id: 'WDR61'},
{value: 'WDR62', id: 'WDR62'},
{value: 'WDR64', id: 'WDR64'},
{value: 'WDR7', id: 'WDR7'},
{value: 'WDR7-OT1', id: 'WDR7-OT1'},
{value: 'WDR70', id: 'WDR70'},
{value: 'WDR72', id: 'WDR72'},
{value: 'WDR73', id: 'WDR73'},
{value: 'WDR74', id: 'WDR74'},
{value: 'WDR75', id: 'WDR75'},
{value: 'WDR76', id: 'WDR76'},
{value: 'WDR77', id: 'WDR77'},
{value: 'WDR81', id: 'WDR81'},
{value: 'WDR82', id: 'WDR82'},
{value: 'WDR82P1', id: 'WDR82P1'},
{value: 'WDR82P2', id: 'WDR82P2'},
{value: 'WDR83', id: 'WDR83'},
{value: 'WDR83OS', id: 'WDR83OS'},
{value: 'WDR86', id: 'WDR86'},
{value: 'WDR86-AS1', id: 'WDR86-AS1'},
{value: 'WDR87', id: 'WDR87'},
{value: 'WDR87BP', id: 'WDR87BP'},
{value: 'WDR88', id: 'WDR88'},
{value: 'WDR89', id: 'WDR89'},
{value: 'WDR90', id: 'WDR90'},
{value: 'WDR91', id: 'WDR91'},
{value: 'WDR93', id: 'WDR93'},
{value: 'WDR95P', id: 'WDR95P'},
{value: 'WDR97', id: 'WDR97'},
{value: 'WDSUB1', id: 'WDSUB1'},
{value: 'WDTC1', id: 'WDTC1'},
{value: 'WEE1', id: 'WEE1'},
{value: 'WEE2-AS1', id: 'WEE2-AS1'},
{value: 'WFDC1', id: 'WFDC1'},
{value: 'WFDC10A', id: 'WFDC10A'},
{value: 'WFDC10B', id: 'WFDC10B'},
{value: 'WFDC11', id: 'WFDC11'},
{value: 'WFDC12', id: 'WFDC12'},
{value: 'WFDC13', id: 'WFDC13'},
{value: 'WFDC2', id: 'WFDC2'},
{value: 'WFDC21P', id: 'WFDC21P'},
{value: 'WFDC3', id: 'WFDC3'},
{value: 'WFDC5', id: 'WFDC5'},
{value: 'WFDC6', id: 'WFDC6'},
{value: 'WFDC8', id: 'WFDC8'},
{value: 'WFDC9', id: 'WFDC9'},
{value: 'WFIKKN1', id: 'WFIKKN1'},
{value: 'WFIKKN2', id: 'WFIKKN2'},
{value: 'WFS1', id: 'WFS1'},
{value: 'WHAMM', id: 'WHAMM'},
{value: 'WHAMMP2', id: 'WHAMMP2'},
{value: 'WHAMMP3', id: 'WHAMMP3'},
{value: 'WHRN', id: 'WHRN'},
{value: 'WHSC1L2P', id: 'WHSC1L2P'},
{value: 'WIF1', id: 'WIF1'},
{value: 'WIPF1', id: 'WIPF1'},
{value: 'WIPF2', id: 'WIPF2'},
{value: 'WIPF3', id: 'WIPF3'},
{value: 'WIPI1', id: 'WIPI1'},
{value: 'WIPI2', id: 'WIPI2'},
{value: 'WIZ', id: 'WIZ'},
{value: 'WIZP1', id: 'WIZP1'},
{value: 'WLS', id: 'WLS'},
{value: 'WNK1', id: 'WNK1'},
{value: 'WNK2', id: 'WNK2'},
{value: 'WNK3', id: 'WNK3'},
{value: 'WNK4', id: 'WNK4'},
{value: 'WNT1', id: 'WNT1'},
{value: 'WNT10A', id: 'WNT10A'},
{value: 'WNT10B', id: 'WNT10B'},
{value: 'WNT11', id: 'WNT11'},
{value: 'WNT16', id: 'WNT16'},
{value: 'WNT2', id: 'WNT2'},
{value: 'WNT2B', id: 'WNT2B'},
{value: 'WNT3', id: 'WNT3'},
{value: 'WNT3A', id: 'WNT3A'},
{value: 'WNT4', id: 'WNT4'},
{value: 'WNT5A', id: 'WNT5A'},
{value: 'WNT5A-AS1', id: 'WNT5A-AS1'},
{value: 'WNT5B', id: 'WNT5B'},
{value: 'WNT6', id: 'WNT6'},
{value: 'WNT7A', id: 'WNT7A'},
{value: 'WNT7B', id: 'WNT7B'},
{value: 'WNT8A', id: 'WNT8A'},
{value: 'WNT8B', id: 'WNT8B'},
{value: 'WNT9A', id: 'WNT9A'},
{value: 'WNT9B', id: 'WNT9B'},
{value: 'WRAP53', id: 'WRAP53'},
{value: 'WRAP73', id: 'WRAP73'},
{value: 'WRN', id: 'WRN'},
{value: 'WRNIP1', id: 'WRNIP1'},
{value: 'WSB1', id: 'WSB1'},
{value: 'WSB2', id: 'WSB2'},
{value: 'WSCD1', id: 'WSCD1'},
{value: 'WSCD2', id: 'WSCD2'},
{value: 'WSPAR', id: 'WSPAR'},
{value: 'WT1', id: 'WT1'},
{value: 'WT1-AS', id: 'WT1-AS'},
{value: 'WTAP', id: 'WTAP'},
{value: 'WTAPP1', id: 'WTAPP1'},
{value: 'WTIP', id: 'WTIP'},
{value: 'WWC1', id: 'WWC1'},
{value: 'WWC2', id: 'WWC2'},
{value: 'WWC2-AS1', id: 'WWC2-AS1'},
{value: 'WWC2-AS2', id: 'WWC2-AS2'},
{value: 'WWC3', id: 'WWC3'},
{value: 'WWOX', id: 'WWOX'},
{value: 'WWOX-AS1', id: 'WWOX-AS1'},
{value: 'WWP1', id: 'WWP1'},
{value: 'WWP1-AS1', id: 'WWP1-AS1'},
{value: 'WWP1P1', id: 'WWP1P1'},
{value: 'WWP2', id: 'WWP2'},
{value: 'WWTR1', id: 'WWTR1'},
{value: 'WWTR1-AS1', id: 'WWTR1-AS1'},
{value: 'XAB2', id: 'XAB2'},
{value: 'XACT', id: 'XACT'},
{value: 'XAF1', id: 'XAF1'},
{value: 'XBP1', id: 'XBP1'},
{value: 'XBP1P1', id: 'XBP1P1'},
{value: 'XCL1', id: 'XCL1'},
{value: 'XCL2', id: 'XCL2'},
{value: 'XCR1', id: 'XCR1'},
{value: 'XDH', id: 'XDH'},
{value: 'XG', id: 'XG'},
{value: 'XGY2', id: 'XGY2'},
{value: 'XIAP', id: 'XIAP'},
{value: 'XIAPP1', id: 'XIAPP1'},
{value: 'XIAPP3', id: 'XIAPP3'},
{value: 'XIRP1', id: 'XIRP1'},
{value: 'XIRP2', id: 'XIRP2'},
{value: 'XIRP2-AS1', id: 'XIRP2-AS1'},
{value: 'XIST', id: 'XIST'},
{value: 'XK', id: 'XK'},
{value: 'XKR3', id: 'XKR3'},
{value: 'XKR4', id: 'XKR4'},
{value: 'XKR5', id: 'XKR5'},
{value: 'XKR6', id: 'XKR6'},
{value: 'XKR7', id: 'XKR7'},
{value: 'XKR8', id: 'XKR8'},
{value: 'XKR9', id: 'XKR9'},
{value: 'XKRX', id: 'XKRX'},
{value: 'XPA', id: 'XPA'},
{value: 'XPC', id: 'XPC'},
{value: 'XPC-AS1', id: 'XPC-AS1'},
{value: 'XPNPEP1', id: 'XPNPEP1'},
{value: 'XPNPEP2', id: 'XPNPEP2'},
{value: 'XPNPEP3', id: 'XPNPEP3'},
{value: 'XPO1', id: 'XPO1'},
{value: 'XPO4', id: 'XPO4'},
{value: 'XPO5', id: 'XPO5'},
{value: 'XPO6', id: 'XPO6'},
{value: 'XPO7', id: 'XPO7'},
{value: 'XPOT', id: 'XPOT'},
{value: 'XPOTP1', id: 'XPOTP1'},
{value: 'XPR1', id: 'XPR1'},
{value: 'XRCC1', id: 'XRCC1'},
{value: 'XRCC2', id: 'XRCC2'},
{value: 'XRCC3', id: 'XRCC3'},
{value: 'XRCC4', id: 'XRCC4'},
{value: 'XRCC5', id: 'XRCC5'},
{value: 'XRCC6', id: 'XRCC6'},
{value: 'XRCC6P1', id: 'XRCC6P1'},
{value: 'XRCC6P2', id: 'XRCC6P2'},
{value: 'XRCC6P3', id: 'XRCC6P3'},
{value: 'XRN1', id: 'XRN1'},
{value: 'XRN2', id: 'XRN2'},
{value: 'XRRA1', id: 'XRRA1'},
{value: 'XXYLT1', id: 'XXYLT1'},
{value: 'XXYLT1-AS1', id: 'XXYLT1-AS1'},
{value: 'XXYLT1-AS2', id: 'XXYLT1-AS2'},
{value: 'XYLB', id: 'XYLB'},
{value: 'XYLT1', id: 'XYLT1'},
{value: 'XYLT2', id: 'XYLT2'},
{value: 'Y_RNA', id: 'Y_RNA'},
{value: 'YAE1', id: 'YAE1'},
{value: 'YAF2', id: 'YAF2'},
{value: 'YAP1', id: 'YAP1'},
{value: 'YAP1P1', id: 'YAP1P1'},
{value: 'YAP1P3', id: 'YAP1P3'},
{value: 'YARS1', id: 'YARS1'},
{value: 'YARS2', id: 'YARS2'},
{value: 'YBEY', id: 'YBEY'},
{value: 'YBX1', id: 'YBX1'},
{value: 'YBX1P1', id: 'YBX1P1'},
{value: 'YBX1P10', id: 'YBX1P10'},
{value: 'YBX1P2', id: 'YBX1P2'},
{value: 'YBX1P3', id: 'YBX1P3'},
{value: 'YBX1P4', id: 'YBX1P4'},
{value: 'YBX1P5', id: 'YBX1P5'},
{value: 'YBX1P6', id: 'YBX1P6'},
{value: 'YBX1P7', id: 'YBX1P7'},
{value: 'YBX1P9', id: 'YBX1P9'},
{value: 'YBX2', id: 'YBX2'},
{value: 'YBX2P1', id: 'YBX2P1'},
{value: 'YBX2P2', id: 'YBX2P2'},
{value: 'YBX3', id: 'YBX3'},
{value: 'YBX3P1', id: 'YBX3P1'},
{value: 'YDJC', id: 'YDJC'},
{value: 'YEATS2', id: 'YEATS2'},
{value: 'YEATS2-AS1', id: 'YEATS2-AS1'},
{value: 'YEATS4', id: 'YEATS4'},
{value: 'YES1', id: 'YES1'},
{value: 'YES1P1', id: 'YES1P1'},
{value: 'YIF1A', id: 'YIF1A'},
{value: 'YIF1B', id: 'YIF1B'},
{value: 'YIPF1', id: 'YIPF1'},
{value: 'YIPF2', id: 'YIPF2'},
{value: 'YIPF3', id: 'YIPF3'},
{value: 'YIPF4', id: 'YIPF4'},
{value: 'YIPF5', id: 'YIPF5'},
{value: 'YIPF6', id: 'YIPF6'},
{value: 'YIPF7', id: 'YIPF7'},
{value: 'YJEFN3', id: 'YJEFN3'},
{value: 'YJU2', id: 'YJU2'},
{value: 'YJU2B', id: 'YJU2B'},
{value: 'YKT6', id: 'YKT6'},
{value: 'YLPM1', id: 'YLPM1'},
{value: 'YME1L1', id: 'YME1L1'},
{value: 'YME1L1P1', id: 'YME1L1P1'},
{value: 'YOD1', id: 'YOD1'},
{value: 'YPEL1', id: 'YPEL1'},
{value: 'YPEL2', id: 'YPEL2'},
{value: 'YPEL3', id: 'YPEL3'},
{value: 'YPEL3-DT', id: 'YPEL3-DT'},
{value: 'YPEL4', id: 'YPEL4'},
{value: 'YPEL5', id: 'YPEL5'},
{value: 'YPEL5P3', id: 'YPEL5P3'},
{value: 'YRDC', id: 'YRDC'},
{value: 'YRDCP1', id: 'YRDCP1'},
{value: 'YRDCP2', id: 'YRDCP2'},
{value: 'YRDCP3', id: 'YRDCP3'},
{value: 'YTHDC1', id: 'YTHDC1'},
{value: 'YTHDC2', id: 'YTHDC2'},
{value: 'YTHDF1', id: 'YTHDF1'},
{value: 'YTHDF1P1', id: 'YTHDF1P1'},
{value: 'YTHDF2', id: 'YTHDF2'},
{value: 'YTHDF2P1', id: 'YTHDF2P1'},
{value: 'YTHDF3', id: 'YTHDF3'},
{value: 'YTHDF3-DT', id: 'YTHDF3-DT'},
{value: 'YWHAB', id: 'YWHAB'},
{value: 'YWHABP1', id: 'YWHABP1'},
{value: 'YWHABP2', id: 'YWHABP2'},
{value: 'YWHAE', id: 'YWHAE'},
{value: 'YWHAEP1', id: 'YWHAEP1'},
{value: 'YWHAEP4', id: 'YWHAEP4'},
{value: 'YWHAEP5', id: 'YWHAEP5'},
{value: 'YWHAEP7', id: 'YWHAEP7'},
{value: 'YWHAG', id: 'YWHAG'},
{value: 'YWHAH', id: 'YWHAH'},
{value: 'YWHAH-AS1', id: 'YWHAH-AS1'},
{value: 'YWHAQ', id: 'YWHAQ'},
{value: 'YWHAQP1', id: 'YWHAQP1'},
{value: 'YWHAQP4', id: 'YWHAQP4'},
{value: 'YWHAQP5', id: 'YWHAQP5'},
{value: 'YWHAQP6', id: 'YWHAQP6'},
{value: 'YWHAQP7', id: 'YWHAQP7'},
{value: 'YWHAQP9', id: 'YWHAQP9'},
{value: 'YWHAZ', id: 'YWHAZ'},
{value: 'YWHAZP2', id: 'YWHAZP2'},
{value: 'YWHAZP3', id: 'YWHAZP3'},
{value: 'YWHAZP4', id: 'YWHAZP4'},
{value: 'YWHAZP5', id: 'YWHAZP5'},
{value: 'YWHAZP6', id: 'YWHAZP6'},
{value: 'YY1', id: 'YY1'},
{value: 'YY1AP1', id: 'YY1AP1'},
{value: 'YY1P2', id: 'YY1P2'},
{value: 'YY2', id: 'YY2'},
{value: 'ZACN', id: 'ZACN'},
{value: 'ZACNP1', id: 'ZACNP1'},
{value: 'ZADH2', id: 'ZADH2'},
{value: 'ZAN', id: 'ZAN'},
{value: 'ZAP70', id: 'ZAP70'},
{value: 'ZAR1', id: 'ZAR1'},
{value: 'ZAR1L', id: 'ZAR1L'},
{value: 'ZBBX', id: 'ZBBX'},
{value: 'ZBED1', id: 'ZBED1'},
{value: 'ZBED1P1', id: 'ZBED1P1'},
{value: 'ZBED2', id: 'ZBED2'},
{value: 'ZBED3', id: 'ZBED3'},
{value: 'ZBED3-AS1', id: 'ZBED3-AS1'},
{value: 'ZBED4', id: 'ZBED4'},
{value: 'ZBED5', id: 'ZBED5'},
{value: 'ZBED5-AS1', id: 'ZBED5-AS1'},
{value: 'ZBED6', id: 'ZBED6'},
{value: 'ZBED6CL', id: 'ZBED6CL'},
{value: 'ZBED8', id: 'ZBED8'},
{value: 'ZBED9', id: 'ZBED9'},
{value: 'ZBED9-AS1', id: 'ZBED9-AS1'},
{value: 'ZBP1', id: 'ZBP1'},
{value: 'ZBTB1', id: 'ZBTB1'},
{value: 'ZBTB10', id: 'ZBTB10'},
{value: 'ZBTB11', id: 'ZBTB11'},
{value: 'ZBTB11-AS1', id: 'ZBTB11-AS1'},
{value: 'ZBTB12', id: 'ZBTB12'},
{value: 'ZBTB12BP', id: 'ZBTB12BP'},
{value: 'ZBTB14', id: 'ZBTB14'},
{value: 'ZBTB16', id: 'ZBTB16'},
{value: 'ZBTB17', id: 'ZBTB17'},
{value: 'ZBTB18', id: 'ZBTB18'},
{value: 'ZBTB2', id: 'ZBTB2'},
{value: 'ZBTB20', id: 'ZBTB20'},
{value: 'ZBTB20-AS1', id: 'ZBTB20-AS1'},
{value: 'ZBTB20-AS4', id: 'ZBTB20-AS4'},
{value: 'ZBTB21', id: 'ZBTB21'},
{value: 'ZBTB22', id: 'ZBTB22'},
{value: 'ZBTB24', id: 'ZBTB24'},
{value: 'ZBTB25', id: 'ZBTB25'},
{value: 'ZBTB26', id: 'ZBTB26'},
{value: 'ZBTB2P1', id: 'ZBTB2P1'},
{value: 'ZBTB3', id: 'ZBTB3'},
{value: 'ZBTB32', id: 'ZBTB32'},
{value: 'ZBTB33', id: 'ZBTB33'},
{value: 'ZBTB34', id: 'ZBTB34'},
{value: 'ZBTB37', id: 'ZBTB37'},
{value: 'ZBTB38', id: 'ZBTB38'},
{value: 'ZBTB39', id: 'ZBTB39'},
{value: 'ZBTB4', id: 'ZBTB4'},
{value: 'ZBTB40', id: 'ZBTB40'},
{value: 'ZBTB40-IT1', id: 'ZBTB40-IT1'},
{value: 'ZBTB41', id: 'ZBTB41'},
{value: 'ZBTB42', id: 'ZBTB42'},
{value: 'ZBTB43', id: 'ZBTB43'},
{value: 'ZBTB44', id: 'ZBTB44'},
{value: 'ZBTB44-DT', id: 'ZBTB44-DT'},
{value: 'ZBTB45', id: 'ZBTB45'},
{value: 'ZBTB45P1', id: 'ZBTB45P1'},
{value: 'ZBTB45P2', id: 'ZBTB45P2'},
{value: 'ZBTB46', id: 'ZBTB46'},
{value: 'ZBTB46-AS1', id: 'ZBTB46-AS1'},
{value: 'ZBTB47', id: 'ZBTB47'},
{value: 'ZBTB48', id: 'ZBTB48'},
{value: 'ZBTB49', id: 'ZBTB49'},
{value: 'ZBTB5', id: 'ZBTB5'},
{value: 'ZBTB6', id: 'ZBTB6'},
{value: 'ZBTB7A', id: 'ZBTB7A'},
{value: 'ZBTB7B', id: 'ZBTB7B'},
{value: 'ZBTB7C', id: 'ZBTB7C'},
{value: 'ZBTB8A', id: 'ZBTB8A'},
{value: 'ZBTB8OS', id: 'ZBTB8OS'},
{value: 'ZBTB8OSP2', id: 'ZBTB8OSP2'},
{value: 'ZBTB9', id: 'ZBTB9'},
{value: 'ZC2HC1A', id: 'ZC2HC1A'},
{value: 'ZC2HC1C', id: 'ZC2HC1C'},
{value: 'ZC3H10', id: 'ZC3H10'},
{value: 'ZC3H11A', id: 'ZC3H11A'},
{value: 'ZC3H11B', id: 'ZC3H11B'},
{value: 'ZC3H12A', id: 'ZC3H12A'},
{value: 'ZC3H12B', id: 'ZC3H12B'},
{value: 'ZC3H12C', id: 'ZC3H12C'},
{value: 'ZC3H12D', id: 'ZC3H12D'},
{value: 'ZC3H13', id: 'ZC3H13'},
{value: 'ZC3H14', id: 'ZC3H14'},
{value: 'ZC3H15', id: 'ZC3H15'},
{value: 'ZC3H18', id: 'ZC3H18'},
{value: 'ZC3H3', id: 'ZC3H3'},
{value: 'ZC3H4', id: 'ZC3H4'},
{value: 'ZC3H6', id: 'ZC3H6'},
{value: 'ZC3H7A', id: 'ZC3H7A'},
{value: 'ZC3H7B', id: 'ZC3H7B'},
{value: 'ZC3H8', id: 'ZC3H8'},
{value: 'ZC3HAV1', id: 'ZC3HAV1'},
{value: 'ZC3HAV1L', id: 'ZC3HAV1L'},
{value: 'ZC3HC1', id: 'ZC3HC1'},
{value: 'ZC4H2', id: 'ZC4H2'},
{value: 'ZCCHC10', id: 'ZCCHC10'},
{value: 'ZCCHC14', id: 'ZCCHC14'},
{value: 'ZCCHC14-DT', id: 'ZCCHC14-DT'},
{value: 'ZCCHC17', id: 'ZCCHC17'},
{value: 'ZCCHC18', id: 'ZCCHC18'},
{value: 'ZCCHC2', id: 'ZCCHC2'},
{value: 'ZCCHC24', id: 'ZCCHC24'},
{value: 'ZCCHC3', id: 'ZCCHC3'},
{value: 'ZCCHC4', id: 'ZCCHC4'},
{value: 'ZCCHC7', id: 'ZCCHC7'},
{value: 'ZCCHC8', id: 'ZCCHC8'},
{value: 'ZCCHC9', id: 'ZCCHC9'},
{value: 'ZCRB1', id: 'ZCRB1'},
{value: 'ZCWPW1', id: 'ZCWPW1'},
{value: 'ZCWPW2', id: 'ZCWPW2'},
{value: 'ZDBF2', id: 'ZDBF2'},
{value: 'ZDHHC1', id: 'ZDHHC1'},
{value: 'ZDHHC11', id: 'ZDHHC11'},
{value: 'ZDHHC11B', id: 'ZDHHC11B'},
{value: 'ZDHHC12', id: 'ZDHHC12'},
{value: 'ZDHHC13', id: 'ZDHHC13'},
{value: 'ZDHHC14', id: 'ZDHHC14'},
{value: 'ZDHHC15', id: 'ZDHHC15'},
{value: 'ZDHHC16', id: 'ZDHHC16'},
{value: 'ZDHHC17', id: 'ZDHHC17'},
{value: 'ZDHHC18', id: 'ZDHHC18'},
{value: 'ZDHHC19', id: 'ZDHHC19'},
{value: 'ZDHHC2', id: 'ZDHHC2'},
{value: 'ZDHHC20', id: 'ZDHHC20'},
{value: 'ZDHHC20-IT1', id: 'ZDHHC20-IT1'},
{value: 'ZDHHC20P1', id: 'ZDHHC20P1'},
{value: 'ZDHHC20P2', id: 'ZDHHC20P2'},
{value: 'ZDHHC20P4', id: 'ZDHHC20P4'},
{value: 'ZDHHC21', id: 'ZDHHC21'},
{value: 'ZDHHC23', id: 'ZDHHC23'},
{value: 'ZDHHC24', id: 'ZDHHC24'},
{value: 'ZDHHC3', id: 'ZDHHC3'},
{value: 'ZDHHC4', id: 'ZDHHC4'},
{value: 'ZDHHC4P1', id: 'ZDHHC4P1'},
{value: 'ZDHHC5', id: 'ZDHHC5'},
{value: 'ZDHHC6', id: 'ZDHHC6'},
{value: 'ZDHHC7', id: 'ZDHHC7'},
{value: 'ZDHHC8', id: 'ZDHHC8'},
{value: 'ZDHHC8P1', id: 'ZDHHC8P1'},
{value: 'ZDHHC9', id: 'ZDHHC9'},
{value: 'ZEB1', id: 'ZEB1'},
{value: 'ZEB1-AS1', id: 'ZEB1-AS1'},
{value: 'ZEB2', id: 'ZEB2'},
{value: 'ZEB2-AS1', id: 'ZEB2-AS1'},
{value: 'ZEB2P1', id: 'ZEB2P1'},
{value: 'ZER1', id: 'ZER1'},
{value: 'ZFAND1', id: 'ZFAND1'},
{value: 'ZFAND2A', id: 'ZFAND2A'},
{value: 'ZFAND2A-DT', id: 'ZFAND2A-DT'},
{value: 'ZFAND2B', id: 'ZFAND2B'},
{value: 'ZFAND3', id: 'ZFAND3'},
{value: 'ZFAND4', id: 'ZFAND4'},
{value: 'ZFAND5', id: 'ZFAND5'},
{value: 'ZFAND6', id: 'ZFAND6'},
{value: 'ZFAND6P1', id: 'ZFAND6P1'},
{value: 'ZFAS1', id: 'ZFAS1'},
{value: 'ZFAT', id: 'ZFAT'},
{value: 'ZFAT-AS1', id: 'ZFAT-AS1'},
{value: 'ZFC3H1', id: 'ZFC3H1'},
{value: 'ZFHX2', id: 'ZFHX2'},
{value: 'ZFHX2-AS1', id: 'ZFHX2-AS1'},
{value: 'ZFHX3', id: 'ZFHX3'},
{value: 'ZFHX4', id: 'ZFHX4'},
{value: 'ZFHX4-AS1', id: 'ZFHX4-AS1'},
{value: 'ZFP1', id: 'ZFP1'},
{value: 'ZFP14', id: 'ZFP14'},
{value: 'ZFP2', id: 'ZFP2'},
{value: 'ZFP28', id: 'ZFP28'},
{value: 'ZFP28-DT', id: 'ZFP28-DT'},
{value: 'ZFP3', id: 'ZFP3'},
{value: 'ZFP3-DT', id: 'ZFP3-DT'},
{value: 'ZFP30', id: 'ZFP30'},
{value: 'ZFP36', id: 'ZFP36'},
{value: 'ZFP36L1', id: 'ZFP36L1'},
{value: 'ZFP36L2', id: 'ZFP36L2'},
{value: 'ZFP37', id: 'ZFP37'},
{value: 'ZFP41', id: 'ZFP41'},
{value: 'ZFP42', id: 'ZFP42'},
{value: 'ZFP57', id: 'ZFP57'},
{value: 'ZFP62', id: 'ZFP62'},
{value: 'ZFP64', id: 'ZFP64'},
{value: 'ZFP64P1', id: 'ZFP64P1'},
{value: 'ZFP69', id: 'ZFP69'},
{value: 'ZFP69B', id: 'ZFP69B'},
{value: 'ZFP82', id: 'ZFP82'},
{value: 'ZFP90', id: 'ZFP90'},
{value: 'ZFP91', id: 'ZFP91'},
{value: 'ZFP91-CNTF', id: 'ZFP91-CNTF'},
{value: 'ZFP91P1', id: 'ZFP91P1'},
{value: 'ZFP92', id: 'ZFP92'},
{value: 'ZFPL1', id: 'ZFPL1'},
{value: 'ZFPM1', id: 'ZFPM1'},
{value: 'ZFPM1-AS1', id: 'ZFPM1-AS1'},
{value: 'ZFPM2', id: 'ZFPM2'},
{value: 'ZFPM2-AS1', id: 'ZFPM2-AS1'},
{value: 'ZFR', id: 'ZFR'},
{value: 'ZFR2', id: 'ZFR2'},
{value: 'ZFTA', id: 'ZFTA'},
{value: 'ZFX', id: 'ZFX'},
{value: 'ZFY', id: 'ZFY'},
{value: 'ZFY-AS1', id: 'ZFY-AS1'},
{value: 'ZFYVE1', id: 'ZFYVE1'},
{value: 'ZFYVE16', id: 'ZFYVE16'},
{value: 'ZFYVE19', id: 'ZFYVE19'},
{value: 'ZFYVE21', id: 'ZFYVE21'},
{value: 'ZFYVE26', id: 'ZFYVE26'},
{value: 'ZFYVE27', id: 'ZFYVE27'},
{value: 'ZFYVE28', id: 'ZFYVE28'},
{value: 'ZFYVE9', id: 'ZFYVE9'},
{value: 'ZFYVE9P2', id: 'ZFYVE9P2'},
{value: 'ZG16', id: 'ZG16'},
{value: 'ZG16B', id: 'ZG16B'},
{value: 'ZGLP1', id: 'ZGLP1'},
{value: 'ZGPAT', id: 'ZGPAT'},
{value: 'ZGRF1', id: 'ZGRF1'},
{value: 'ZHX1', id: 'ZHX1'},
{value: 'ZHX1-C8orf76', id: 'ZHX1-C8orf76'},
{value: 'ZHX2', id: 'ZHX2'},
{value: 'ZHX3', id: 'ZHX3'},
{value: 'ZIC1', id: 'ZIC1'},
{value: 'ZIC2', id: 'ZIC2'},
{value: 'ZIC4', id: 'ZIC4'},
{value: 'ZIC4-AS1', id: 'ZIC4-AS1'},
{value: 'ZIC5', id: 'ZIC5'},
{value: 'ZIK1', id: 'ZIK1'},
{value: 'ZIM2', id: 'ZIM2'},
{value: 'ZIM2-AS1', id: 'ZIM2-AS1'},
{value: 'ZIM3', id: 'ZIM3'},
{value: 'ZKSCAN1', id: 'ZKSCAN1'},
{value: 'ZKSCAN2', id: 'ZKSCAN2'},
{value: 'ZKSCAN2-DT', id: 'ZKSCAN2-DT'},
{value: 'ZKSCAN3', id: 'ZKSCAN3'},
{value: 'ZKSCAN4', id: 'ZKSCAN4'},
{value: 'ZKSCAN5', id: 'ZKSCAN5'},
{value: 'ZKSCAN7', id: 'ZKSCAN7'},
{value: 'ZKSCAN7-AS1', id: 'ZKSCAN7-AS1'},
{value: 'ZKSCAN7P1', id: 'ZKSCAN7P1'},
{value: 'ZKSCAN8', id: 'ZKSCAN8'},
{value: 'ZKSCAN8P1', id: 'ZKSCAN8P1'},
{value: 'ZKSCAN8P2', id: 'ZKSCAN8P2'},
{value: 'ZMAT1', id: 'ZMAT1'},
{value: 'ZMAT2', id: 'ZMAT2'},
{value: 'ZMAT3', id: 'ZMAT3'},
{value: 'ZMAT4', id: 'ZMAT4'},
{value: 'ZMAT5', id: 'ZMAT5'},
{value: 'ZMIZ1', id: 'ZMIZ1'},
{value: 'ZMIZ1-AS1', id: 'ZMIZ1-AS1'},
{value: 'ZMIZ2', id: 'ZMIZ2'},
{value: 'ZMPSTE24', id: 'ZMPSTE24'},
{value: 'ZMPSTE24-DT', id: 'ZMPSTE24-DT'},
{value: 'ZMYM1', id: 'ZMYM1'},
{value: 'ZMYM2', id: 'ZMYM2'},
{value: 'ZMYM3', id: 'ZMYM3'},
{value: 'ZMYM4', id: 'ZMYM4'},
{value: 'ZMYM5', id: 'ZMYM5'},
{value: 'ZMYM6', id: 'ZMYM6'},
{value: 'ZMYND10', id: 'ZMYND10'},
{value: 'ZMYND10-AS1', id: 'ZMYND10-AS1'},
{value: 'ZMYND11', id: 'ZMYND11'},
{value: 'ZMYND12', id: 'ZMYND12'},
{value: 'ZMYND15', id: 'ZMYND15'},
{value: 'ZMYND19', id: 'ZMYND19'},
{value: 'ZMYND19P1', id: 'ZMYND19P1'},
{value: 'ZMYND8', id: 'ZMYND8'},
{value: 'ZNF10', id: 'ZNF10'},
{value: 'ZNF100', id: 'ZNF100'},
{value: 'ZNF101', id: 'ZNF101'},
{value: 'ZNF101P1', id: 'ZNF101P1'},
{value: 'ZNF101P2', id: 'ZNF101P2'},
{value: 'ZNF106', id: 'ZNF106'},
{value: 'ZNF107', id: 'ZNF107'},
{value: 'ZNF112', id: 'ZNF112'},
{value: 'ZNF114', id: 'ZNF114'},
{value: 'ZNF114-AS1', id: 'ZNF114-AS1'},
{value: 'ZNF117', id: 'ZNF117'},
{value: 'ZNF12', id: 'ZNF12'},
{value: 'ZNF121', id: 'ZNF121'},
{value: 'ZNF123P', id: 'ZNF123P'},
{value: 'ZNF124', id: 'ZNF124'},
{value: 'ZNF131', id: 'ZNF131'},
{value: 'ZNF132', id: 'ZNF132'},
{value: 'ZNF132-DT', id: 'ZNF132-DT'},
{value: 'ZNF133', id: 'ZNF133'},
{value: 'ZNF133-AS1', id: 'ZNF133-AS1'},
{value: 'ZNF134', id: 'ZNF134'},
{value: 'ZNF135', id: 'ZNF135'},
{value: 'ZNF136', id: 'ZNF136'},
{value: 'ZNF137P', id: 'ZNF137P'},
{value: 'ZNF138', id: 'ZNF138'},
{value: 'ZNF14', id: 'ZNF14'},
{value: 'ZNF140', id: 'ZNF140'},
{value: 'ZNF141', id: 'ZNF141'},
{value: 'ZNF142', id: 'ZNF142'},
{value: 'ZNF143', id: 'ZNF143'},
{value: 'ZNF146', id: 'ZNF146'},
{value: 'ZNF148', id: 'ZNF148'},
{value: 'ZNF154', id: 'ZNF154'},
{value: 'ZNF155', id: 'ZNF155'},
{value: 'ZNF16', id: 'ZNF16'},
{value: 'ZNF160', id: 'ZNF160'},
{value: 'ZNF165', id: 'ZNF165'},
{value: 'ZNF169', id: 'ZNF169'},
{value: 'ZNF17', id: 'ZNF17'},
{value: 'ZNF174', id: 'ZNF174'},
{value: 'ZNF175', id: 'ZNF175'},
{value: 'ZNF177', id: 'ZNF177'},
{value: 'ZNF18', id: 'ZNF18'},
{value: 'ZNF180', id: 'ZNF180'},
{value: 'ZNF181', id: 'ZNF181'},
{value: 'ZNF182', id: 'ZNF182'},
{value: 'ZNF184', id: 'ZNF184'},
{value: 'ZNF185', id: 'ZNF185'},
{value: 'ZNF189', id: 'ZNF189'},
{value: 'ZNF19', id: 'ZNF19'},
{value: 'ZNF195', id: 'ZNF195'},
{value: 'ZNF197', id: 'ZNF197'},
{value: 'ZNF197-AS1', id: 'ZNF197-AS1'},
{value: 'ZNF2', id: 'ZNF2'},
{value: 'ZNF20', id: 'ZNF20'},
{value: 'ZNF200', id: 'ZNF200'},
{value: 'ZNF202', id: 'ZNF202'},
{value: 'ZNF204P', id: 'ZNF204P'},
{value: 'ZNF205', id: 'ZNF205'},
{value: 'ZNF207', id: 'ZNF207'},
{value: 'ZNF208', id: 'ZNF208'},
{value: 'ZNF211', id: 'ZNF211'},
{value: 'ZNF212', id: 'ZNF212'},
{value: 'ZNF213', id: 'ZNF213'},
{value: 'ZNF213-AS1', id: 'ZNF213-AS1'},
{value: 'ZNF214', id: 'ZNF214'},
{value: 'ZNF215', id: 'ZNF215'},
{value: 'ZNF217', id: 'ZNF217'},
{value: 'ZNF219', id: 'ZNF219'},
{value: 'ZNF22', id: 'ZNF22'},
{value: 'ZNF22-AS1', id: 'ZNF22-AS1'},
{value: 'ZNF221', id: 'ZNF221'},
{value: 'ZNF222', id: 'ZNF222'},
{value: 'ZNF222-DT', id: 'ZNF222-DT'},
{value: 'ZNF223', id: 'ZNF223'},
{value: 'ZNF224', id: 'ZNF224'},
{value: 'ZNF225', id: 'ZNF225'},
{value: 'ZNF226', id: 'ZNF226'},
{value: 'ZNF227', id: 'ZNF227'},
{value: 'ZNF229', id: 'ZNF229'},
{value: 'ZNF23', id: 'ZNF23'},
{value: 'ZNF230', id: 'ZNF230'},
{value: 'ZNF230-DT', id: 'ZNF230-DT'},
{value: 'ZNF232', id: 'ZNF232'},
{value: 'ZNF232-AS1', id: 'ZNF232-AS1'},
{value: 'ZNF233', id: 'ZNF233'},
{value: 'ZNF234', id: 'ZNF234'},
{value: 'ZNF235', id: 'ZNF235'},
{value: 'ZNF236', id: 'ZNF236'},
{value: 'ZNF236-DT', id: 'ZNF236-DT'},
{value: 'ZNF239', id: 'ZNF239'},
{value: 'ZNF24', id: 'ZNF24'},
{value: 'ZNF248', id: 'ZNF248'},
{value: 'ZNF25', id: 'ZNF25'},
{value: 'ZNF25-DT', id: 'ZNF25-DT'},
{value: 'ZNF250', id: 'ZNF250'},
{value: 'ZNF251', id: 'ZNF251'},
{value: 'ZNF252P', id: 'ZNF252P'},
{value: 'ZNF252P-AS1', id: 'ZNF252P-AS1'},
{value: 'ZNF253', id: 'ZNF253'},
{value: 'ZNF254', id: 'ZNF254'},
{value: 'ZNF256', id: 'ZNF256'},
{value: 'ZNF257', id: 'ZNF257'},
{value: 'ZNF26', id: 'ZNF26'},
{value: 'ZNF260', id: 'ZNF260'},
{value: 'ZNF263', id: 'ZNF263'},
{value: 'ZNF264', id: 'ZNF264'},
{value: 'ZNF266', id: 'ZNF266'},
{value: 'ZNF267', id: 'ZNF267'},
{value: 'ZNF268', id: 'ZNF268'},
{value: 'ZNF271P', id: 'ZNF271P'},
{value: 'ZNF273', id: 'ZNF273'},
{value: 'ZNF274', id: 'ZNF274'},
{value: 'ZNF275', id: 'ZNF275'},
{value: 'ZNF276', id: 'ZNF276'},
{value: 'ZNF277', id: 'ZNF277'},
{value: 'ZNF28', id: 'ZNF28'},
{value: 'ZNF280A', id: 'ZNF280A'},
{value: 'ZNF280B', id: 'ZNF280B'},
{value: 'ZNF280C', id: 'ZNF280C'},
{value: 'ZNF280D', id: 'ZNF280D'},
{value: 'ZNF281', id: 'ZNF281'},
{value: 'ZNF282', id: 'ZNF282'},
{value: 'ZNF283', id: 'ZNF283'},
{value: 'ZNF284', id: 'ZNF284'},
{value: 'ZNF285', id: 'ZNF285'},
{value: 'ZNF285B', id: 'ZNF285B'},
{value: 'ZNF286A', id: 'ZNF286A'},
{value: 'ZNF286A-TBC1D26', id: 'ZNF286A-TBC1D26'},
{value: 'ZNF286B', id: 'ZNF286B'},
{value: 'ZNF287', id: 'ZNF287'},
{value: 'ZNF292', id: 'ZNF292'},
{value: 'ZNF295-AS1', id: 'ZNF295-AS1'},
{value: 'ZNF296', id: 'ZNF296'},
{value: 'ZNF29P', id: 'ZNF29P'},
{value: 'ZNF3', id: 'ZNF3'},
{value: 'ZNF30', id: 'ZNF30'},
{value: 'ZNF30-AS1', id: 'ZNF30-AS1'},
{value: 'ZNF300', id: 'ZNF300'},
{value: 'ZNF300P1', id: 'ZNF300P1'},
{value: 'ZNF302', id: 'ZNF302'},
{value: 'ZNF304', id: 'ZNF304'},
{value: 'ZNF311', id: 'ZNF311'},
{value: 'ZNF316', id: 'ZNF316'},
{value: 'ZNF317', id: 'ZNF317'},
{value: 'ZNF317P1', id: 'ZNF317P1'},
{value: 'ZNF318', id: 'ZNF318'},
{value: 'ZNF319', id: 'ZNF319'},
{value: 'ZNF32', id: 'ZNF32'},
{value: 'ZNF32-AS2', id: 'ZNF32-AS2'},
{value: 'ZNF32-AS3', id: 'ZNF32-AS3'},
{value: 'ZNF320', id: 'ZNF320'},
{value: 'ZNF321P', id: 'ZNF321P'},
{value: 'ZNF322', id: 'ZNF322'},
{value: 'ZNF324', id: 'ZNF324'},
{value: 'ZNF324B', id: 'ZNF324B'},
{value: 'ZNF326', id: 'ZNF326'},
{value: 'ZNF329', id: 'ZNF329'},
{value: 'ZNF330', id: 'ZNF330'},
{value: 'ZNF331', id: 'ZNF331'},
{value: 'ZNF333', id: 'ZNF333'},
{value: 'ZNF334', id: 'ZNF334'},
{value: 'ZNF335', id: 'ZNF335'},
{value: 'ZNF337', id: 'ZNF337'},
{value: 'ZNF337-AS1', id: 'ZNF337-AS1'},
{value: 'ZNF33A', id: 'ZNF33A'},
{value: 'ZNF33B', id: 'ZNF33B'},
{value: 'ZNF33CP', id: 'ZNF33CP'},
{value: 'ZNF34', id: 'ZNF34'},
{value: 'ZNF341', id: 'ZNF341'},
{value: 'ZNF341-AS1', id: 'ZNF341-AS1'},
{value: 'ZNF343', id: 'ZNF343'},
{value: 'ZNF345', id: 'ZNF345'},
{value: 'ZNF346', id: 'ZNF346'},
{value: 'ZNF346-IT1', id: 'ZNF346-IT1'},
{value: 'ZNF347', id: 'ZNF347'},
{value: 'ZNF35', id: 'ZNF35'},
{value: 'ZNF350', id: 'ZNF350'},
{value: 'ZNF350-AS1', id: 'ZNF350-AS1'},
{value: 'ZNF354A', id: 'ZNF354A'},
{value: 'ZNF354B', id: 'ZNF354B'},
{value: 'ZNF354C', id: 'ZNF354C'},
{value: 'ZNF358', id: 'ZNF358'},
{value: 'ZNF362', id: 'ZNF362'},
{value: 'ZNF365', id: 'ZNF365'},
{value: 'ZNF366', id: 'ZNF366'},
{value: 'ZNF367', id: 'ZNF367'},
{value: 'ZNF37A', id: 'ZNF37A'},
{value: 'ZNF37BP', id: 'ZNF37BP'},
{value: 'ZNF37CP', id: 'ZNF37CP'},
{value: 'ZNF382', id: 'ZNF382'},
{value: 'ZNF383', id: 'ZNF383'},
{value: 'ZNF384', id: 'ZNF384'},
{value: 'ZNF385A', id: 'ZNF385A'},
{value: 'ZNF385B', id: 'ZNF385B'},
{value: 'ZNF385C', id: 'ZNF385C'},
{value: 'ZNF385D', id: 'ZNF385D'},
{value: 'ZNF385D-AS1', id: 'ZNF385D-AS1'},
{value: 'ZNF385D-AS2', id: 'ZNF385D-AS2'},
{value: 'ZNF391', id: 'ZNF391'},
{value: 'ZNF394', id: 'ZNF394'},
{value: 'ZNF395', id: 'ZNF395'},
{value: 'ZNF396', id: 'ZNF396'},
{value: 'ZNF397', id: 'ZNF397'},
{value: 'ZNF398', id: 'ZNF398'},
{value: 'ZNF402P', id: 'ZNF402P'},
{value: 'ZNF404', id: 'ZNF404'},
{value: 'ZNF407', id: 'ZNF407'},
{value: 'ZNF407-AS1', id: 'ZNF407-AS1'},
{value: 'ZNF408', id: 'ZNF408'},
{value: 'ZNF41', id: 'ZNF41'},
{value: 'ZNF410', id: 'ZNF410'},
{value: 'ZNF414', id: 'ZNF414'},
{value: 'ZNF415', id: 'ZNF415'},
{value: 'ZNF415P1', id: 'ZNF415P1'},
{value: 'ZNF416', id: 'ZNF416'},
{value: 'ZNF417', id: 'ZNF417'},
{value: 'ZNF418', id: 'ZNF418'},
{value: 'ZNF419', id: 'ZNF419'},
{value: 'ZNF420', id: 'ZNF420'},
{value: 'ZNF423', id: 'ZNF423'},
{value: 'ZNF425', id: 'ZNF425'},
{value: 'ZNF426', id: 'ZNF426'},
{value: 'ZNF426-DT', id: 'ZNF426-DT'},
{value: 'ZNF428', id: 'ZNF428'},
{value: 'ZNF429', id: 'ZNF429'},
{value: 'ZNF43', id: 'ZNF43'},
{value: 'ZNF430', id: 'ZNF430'},
{value: 'ZNF431', id: 'ZNF431'},
{value: 'ZNF432', id: 'ZNF432'},
{value: 'ZNF433', id: 'ZNF433'},
{value: 'ZNF433-AS1', id: 'ZNF433-AS1'},
{value: 'ZNF436', id: 'ZNF436'},
{value: 'ZNF436-AS1', id: 'ZNF436-AS1'},
{value: 'ZNF438', id: 'ZNF438'},
{value: 'ZNF439', id: 'ZNF439'},
{value: 'ZNF44', id: 'ZNF44'},
{value: 'ZNF440', id: 'ZNF440'},
{value: 'ZNF441', id: 'ZNF441'},
{value: 'ZNF442', id: 'ZNF442'},
{value: 'ZNF443', id: 'ZNF443'},
{value: 'ZNF444', id: 'ZNF444'},
{value: 'ZNF444P1', id: 'ZNF444P1'},
{value: 'ZNF445', id: 'ZNF445'},
{value: 'ZNF446', id: 'ZNF446'},
{value: 'ZNF449', id: 'ZNF449'},
{value: 'ZNF45', id: 'ZNF45'},
{value: 'ZNF45-AS1', id: 'ZNF45-AS1'},
{value: 'ZNF451', id: 'ZNF451'},
{value: 'ZNF451-AS1', id: 'ZNF451-AS1'},
{value: 'ZNF454', id: 'ZNF454'},
{value: 'ZNF454-DT', id: 'ZNF454-DT'},
{value: 'ZNF460', id: 'ZNF460'},
{value: 'ZNF460-AS1', id: 'ZNF460-AS1'},
{value: 'ZNF461', id: 'ZNF461'},
{value: 'ZNF462', id: 'ZNF462'},
{value: 'ZNF467', id: 'ZNF467'},
{value: 'ZNF468', id: 'ZNF468'},
{value: 'ZNF469', id: 'ZNF469'},
{value: 'ZNF470', id: 'ZNF470'},
{value: 'ZNF470-DT', id: 'ZNF470-DT'},
{value: 'ZNF471', id: 'ZNF471'},
{value: 'ZNF473', id: 'ZNF473'},
{value: 'ZNF474', id: 'ZNF474'},
{value: 'ZNF474-AS1', id: 'ZNF474-AS1'},
{value: 'ZNF479', id: 'ZNF479'},
{value: 'ZNF48', id: 'ZNF48'},
{value: 'ZNF480', id: 'ZNF480'},
{value: 'ZNF483', id: 'ZNF483'},
{value: 'ZNF484', id: 'ZNF484'},
{value: 'ZNF485', id: 'ZNF485'},
{value: 'ZNF486', id: 'ZNF486'},
{value: 'ZNF487', id: 'ZNF487'},
{value: 'ZNF488', id: 'ZNF488'},
{value: 'ZNF490', id: 'ZNF490'},
{value: 'ZNF491', id: 'ZNF491'},
{value: 'ZNF492', id: 'ZNF492'},
{value: 'ZNF493', id: 'ZNF493'},
{value: 'ZNF496', id: 'ZNF496'},
{value: 'ZNF497', id: 'ZNF497'},
{value: 'ZNF497-AS1', id: 'ZNF497-AS1'},
{value: 'ZNF500', id: 'ZNF500'},
{value: 'ZNF501', id: 'ZNF501'},
{value: 'ZNF502', id: 'ZNF502'},
{value: 'ZNF503', id: 'ZNF503'},
{value: 'ZNF503-AS1', id: 'ZNF503-AS1'},
{value: 'ZNF503-AS2', id: 'ZNF503-AS2'},
{value: 'ZNF506', id: 'ZNF506'},
{value: 'ZNF507', id: 'ZNF507'},
{value: 'ZNF510', id: 'ZNF510'},
{value: 'ZNF511', id: 'ZNF511'},
{value: 'ZNF511-PRAP1', id: 'ZNF511-PRAP1'},
{value: 'ZNF512', id: 'ZNF512'},
{value: 'ZNF512B', id: 'ZNF512B'},
{value: 'ZNF513', id: 'ZNF513'},
{value: 'ZNF514', id: 'ZNF514'},
{value: 'ZNF516', id: 'ZNF516'},
{value: 'ZNF516-AS1', id: 'ZNF516-AS1'},
{value: 'ZNF516-DT', id: 'ZNF516-DT'},
{value: 'ZNF517', id: 'ZNF517'},
{value: 'ZNF518A', id: 'ZNF518A'},
{value: 'ZNF518B', id: 'ZNF518B'},
{value: 'ZNF519', id: 'ZNF519'},
{value: 'ZNF519P1', id: 'ZNF519P1'},
{value: 'ZNF519P2', id: 'ZNF519P2'},
{value: 'ZNF519P3', id: 'ZNF519P3'},
{value: 'ZNF521', id: 'ZNF521'},
{value: 'ZNF524', id: 'ZNF524'},
{value: 'ZNF525', id: 'ZNF525'},
{value: 'ZNF526', id: 'ZNF526'},
{value: 'ZNF527', id: 'ZNF527'},
{value: 'ZNF528', id: 'ZNF528'},
{value: 'ZNF528-AS1', id: 'ZNF528-AS1'},
{value: 'ZNF529', id: 'ZNF529'},
{value: 'ZNF529-AS1', id: 'ZNF529-AS1'},
{value: 'ZNF530', id: 'ZNF530'},
{value: 'ZNF532', id: 'ZNF532'},
{value: 'ZNF534', id: 'ZNF534'},
{value: 'ZNF536', id: 'ZNF536'},
{value: 'ZNF540', id: 'ZNF540'},
{value: 'ZNF541', id: 'ZNF541'},
{value: 'ZNF542P', id: 'ZNF542P'},
{value: 'ZNF543', id: 'ZNF543'},
{value: 'ZNF544', id: 'ZNF544'},
{value: 'ZNF546', id: 'ZNF546'},
{value: 'ZNF547', id: 'ZNF547'},
{value: 'ZNF548', id: 'ZNF548'},
{value: 'ZNF549', id: 'ZNF549'},
{value: 'ZNF550', id: 'ZNF550'},
{value: 'ZNF551', id: 'ZNF551'},
{value: 'ZNF552', id: 'ZNF552'},
{value: 'ZNF554', id: 'ZNF554'},
{value: 'ZNF555', id: 'ZNF555'},
{value: 'ZNF556', id: 'ZNF556'},
{value: 'ZNF557', id: 'ZNF557'},
{value: 'ZNF558', id: 'ZNF558'},
{value: 'ZNF559', id: 'ZNF559'},
{value: 'ZNF559-ZNF177', id: 'ZNF559-ZNF177'},
{value: 'ZNF560', id: 'ZNF560'},
{value: 'ZNF561', id: 'ZNF561'},
{value: 'ZNF561-AS1', id: 'ZNF561-AS1'},
{value: 'ZNF562', id: 'ZNF562'},
{value: 'ZNF563', id: 'ZNF563'},
{value: 'ZNF564', id: 'ZNF564'},
{value: 'ZNF565', id: 'ZNF565'},
{value: 'ZNF566', id: 'ZNF566'},
{value: 'ZNF566-AS1', id: 'ZNF566-AS1'},
{value: 'ZNF567', id: 'ZNF567'},
{value: 'ZNF568', id: 'ZNF568'},
{value: 'ZNF569', id: 'ZNF569'},
{value: 'ZNF56P', id: 'ZNF56P'},
{value: 'ZNF57', id: 'ZNF57'},
{value: 'ZNF570', id: 'ZNF570'},
{value: 'ZNF571', id: 'ZNF571'},
{value: 'ZNF571-AS1', id: 'ZNF571-AS1'},
{value: 'ZNF572', id: 'ZNF572'},
{value: 'ZNF573', id: 'ZNF573'},
{value: 'ZNF574', id: 'ZNF574'},
{value: 'ZNF575', id: 'ZNF575'},
{value: 'ZNF576', id: 'ZNF576'},
{value: 'ZNF577', id: 'ZNF577'},
{value: 'ZNF578', id: 'ZNF578'},
{value: 'ZNF579', id: 'ZNF579'},
{value: 'ZNF580', id: 'ZNF580'},
{value: 'ZNF581', id: 'ZNF581'},
{value: 'ZNF582', id: 'ZNF582'},
{value: 'ZNF582-DT', id: 'ZNF582-DT'},
{value: 'ZNF583', id: 'ZNF583'},
{value: 'ZNF584', id: 'ZNF584'},
{value: 'ZNF585A', id: 'ZNF585A'},
{value: 'ZNF585B', id: 'ZNF585B'},
{value: 'ZNF586', id: 'ZNF586'},
{value: 'ZNF587', id: 'ZNF587'},
{value: 'ZNF587B', id: 'ZNF587B'},
{value: 'ZNF587P1', id: 'ZNF587P1'},
{value: 'ZNF589', id: 'ZNF589'},
{value: 'ZNF592', id: 'ZNF592'},
{value: 'ZNF593', id: 'ZNF593'},
{value: 'ZNF593OS', id: 'ZNF593OS'},
{value: 'ZNF594', id: 'ZNF594'},
{value: 'ZNF594-DT', id: 'ZNF594-DT'},
{value: 'ZNF595', id: 'ZNF595'},
{value: 'ZNF596', id: 'ZNF596'},
{value: 'ZNF597', id: 'ZNF597'},
{value: 'ZNF598', id: 'ZNF598'},
{value: 'ZNF599', id: 'ZNF599'},
{value: 'ZNF600', id: 'ZNF600'},
{value: 'ZNF603P', id: 'ZNF603P'},
{value: 'ZNF605', id: 'ZNF605'},
{value: 'ZNF606', id: 'ZNF606'},
{value: 'ZNF607', id: 'ZNF607'},
{value: 'ZNF608', id: 'ZNF608'},
{value: 'ZNF609', id: 'ZNF609'},
{value: 'ZNF610', id: 'ZNF610'},
{value: 'ZNF611', id: 'ZNF611'},
{value: 'ZNF613', id: 'ZNF613'},
{value: 'ZNF614', id: 'ZNF614'},
{value: 'ZNF615', id: 'ZNF615'},
{value: 'ZNF616', id: 'ZNF616'},
{value: 'ZNF618', id: 'ZNF618'},
{value: 'ZNF619', id: 'ZNF619'},
{value: 'ZNF619P1', id: 'ZNF619P1'},
{value: 'ZNF620', id: 'ZNF620'},
{value: 'ZNF621', id: 'ZNF621'},
{value: 'ZNF622', id: 'ZNF622'},
{value: 'ZNF623', id: 'ZNF623'},
{value: 'ZNF624', id: 'ZNF624'},
{value: 'ZNF625', id: 'ZNF625'},
{value: 'ZNF625-ZNF20', id: 'ZNF625-ZNF20'},
{value: 'ZNF626', id: 'ZNF626'},
{value: 'ZNF627', id: 'ZNF627'},
{value: 'ZNF628', id: 'ZNF628'},
{value: 'ZNF628-DT', id: 'ZNF628-DT'},
{value: 'ZNF629', id: 'ZNF629'},
{value: 'ZNF630', id: 'ZNF630'},
{value: 'ZNF638', id: 'ZNF638'},
{value: 'ZNF639', id: 'ZNF639'},
{value: 'ZNF641', id: 'ZNF641'},
{value: 'ZNF644', id: 'ZNF644'},
{value: 'ZNF646', id: 'ZNF646'},
{value: 'ZNF646P1', id: 'ZNF646P1'},
{value: 'ZNF648', id: 'ZNF648'},
{value: 'ZNF649', id: 'ZNF649'},
{value: 'ZNF649-AS1', id: 'ZNF649-AS1'},
{value: 'ZNF652', id: 'ZNF652'},
{value: 'ZNF652-AS1', id: 'ZNF652-AS1'},
{value: 'ZNF653', id: 'ZNF653'},
{value: 'ZNF654', id: 'ZNF654'},
{value: 'ZNF655', id: 'ZNF655'},
{value: 'ZNF658', id: 'ZNF658'},
{value: 'ZNF658B', id: 'ZNF658B'},
{value: 'ZNF66', id: 'ZNF66'},
{value: 'ZNF660', id: 'ZNF660'},
{value: 'ZNF662', id: 'ZNF662'},
{value: 'ZNF663P', id: 'ZNF663P'},
{value: 'ZNF664', id: 'ZNF664'},
{value: 'ZNF665', id: 'ZNF665'},
{value: 'ZNF667', id: 'ZNF667'},
{value: 'ZNF667-AS1', id: 'ZNF667-AS1'},
{value: 'ZNF668', id: 'ZNF668'},
{value: 'ZNF669', id: 'ZNF669'},
{value: 'ZNF670', id: 'ZNF670'},
{value: 'ZNF670-ZNF695', id: 'ZNF670-ZNF695'},
{value: 'ZNF671', id: 'ZNF671'},
{value: 'ZNF672', id: 'ZNF672'},
{value: 'ZNF674', id: 'ZNF674'},
{value: 'ZNF674-AS1', id: 'ZNF674-AS1'},
{value: 'ZNF675', id: 'ZNF675'},
{value: 'ZNF676', id: 'ZNF676'},
{value: 'ZNF677', id: 'ZNF677'},
{value: 'ZNF678', id: 'ZNF678'},
{value: 'ZNF679', id: 'ZNF679'},
{value: 'ZNF680', id: 'ZNF680'},
{value: 'ZNF680P1', id: 'ZNF680P1'},
{value: 'ZNF681', id: 'ZNF681'},
{value: 'ZNF682', id: 'ZNF682'},
{value: 'ZNF683', id: 'ZNF683'},
{value: 'ZNF684', id: 'ZNF684'},
{value: 'ZNF687', id: 'ZNF687'},
{value: 'ZNF687-AS1', id: 'ZNF687-AS1'},
{value: 'ZNF688', id: 'ZNF688'},
{value: 'ZNF689', id: 'ZNF689'},
{value: 'ZNF69', id: 'ZNF69'},
{value: 'ZNF691', id: 'ZNF691'},
{value: 'ZNF692', id: 'ZNF692'},
{value: 'ZNF695', id: 'ZNF695'},
{value: 'ZNF696', id: 'ZNF696'},
{value: 'ZNF697', id: 'ZNF697'},
{value: 'ZNF699', id: 'ZNF699'},
{value: 'ZNF7', id: 'ZNF7'},
{value: 'ZNF70', id: 'ZNF70'},
{value: 'ZNF700', id: 'ZNF700'},
{value: 'ZNF701', id: 'ZNF701'},
{value: 'ZNF702P', id: 'ZNF702P'},
{value: 'ZNF703', id: 'ZNF703'},
{value: 'ZNF704', id: 'ZNF704'},
{value: 'ZNF705A', id: 'ZNF705A'},
{value: 'ZNF705B', id: 'ZNF705B'},
{value: 'ZNF705E', id: 'ZNF705E'},
{value: 'ZNF705G', id: 'ZNF705G'},
{value: 'ZNF706', id: 'ZNF706'},
{value: 'ZNF707', id: 'ZNF707'},
{value: 'ZNF708', id: 'ZNF708'},
{value: 'ZNF709', id: 'ZNF709'},
{value: 'ZNF70P1', id: 'ZNF70P1'},
{value: 'ZNF71', id: 'ZNF71'},
{value: 'ZNF710', id: 'ZNF710'},
{value: 'ZNF710-AS1', id: 'ZNF710-AS1'},
{value: 'ZNF711', id: 'ZNF711'},
{value: 'ZNF713', id: 'ZNF713'},
{value: 'ZNF714', id: 'ZNF714'},
{value: 'ZNF716', id: 'ZNF716'},
{value: 'ZNF717', id: 'ZNF717'},
{value: 'ZNF718', id: 'ZNF718'},
{value: 'ZNF720', id: 'ZNF720'},
{value: 'ZNF720P1', id: 'ZNF720P1'},
{value: 'ZNF721', id: 'ZNF721'},
{value: 'ZNF722P', id: 'ZNF722P'},
{value: 'ZNF723', id: 'ZNF723'},
{value: 'ZNF724', id: 'ZNF724'},
{value: 'ZNF725P', id: 'ZNF725P'},
{value: 'ZNF726', id: 'ZNF726'},
{value: 'ZNF727', id: 'ZNF727'},
{value: 'ZNF728', id: 'ZNF728'},
{value: 'ZNF729', id: 'ZNF729'},
{value: 'ZNF730', id: 'ZNF730'},
{value: 'ZNF731P', id: 'ZNF731P'},
{value: 'ZNF732', id: 'ZNF732'},
{value: 'ZNF733P', id: 'ZNF733P'},
{value: 'ZNF734P', id: 'ZNF734P'},
{value: 'ZNF735', id: 'ZNF735'},
{value: 'ZNF736', id: 'ZNF736'},
{value: 'ZNF737', id: 'ZNF737'},
{value: 'ZNF738', id: 'ZNF738'},
{value: 'ZNF74', id: 'ZNF74'},
{value: 'ZNF740', id: 'ZNF740'},
{value: 'ZNF746', id: 'ZNF746'},
{value: 'ZNF747', id: 'ZNF747'},
{value: 'ZNF749', id: 'ZNF749'},
{value: 'ZNF750', id: 'ZNF750'},
{value: 'ZNF75A', id: 'ZNF75A'},
{value: 'ZNF75BP', id: 'ZNF75BP'},
{value: 'ZNF75D', id: 'ZNF75D'},
{value: 'ZNF76', id: 'ZNF76'},
{value: 'ZNF761', id: 'ZNF761'},
{value: 'ZNF763', id: 'ZNF763'},
{value: 'ZNF764', id: 'ZNF764'},
{value: 'ZNF765', id: 'ZNF765'},
{value: 'ZNF766', id: 'ZNF766'},
{value: 'ZNF767P', id: 'ZNF767P'},
{value: 'ZNF768', id: 'ZNF768'},
{value: 'ZNF77', id: 'ZNF77'},
{value: 'ZNF770', id: 'ZNF770'},
{value: 'ZNF771', id: 'ZNF771'},
{value: 'ZNF772', id: 'ZNF772'},
{value: 'ZNF773', id: 'ZNF773'},
{value: 'ZNF774', id: 'ZNF774'},
{value: 'ZNF775', id: 'ZNF775'},
{value: 'ZNF776', id: 'ZNF776'},
{value: 'ZNF777', id: 'ZNF777'},
{value: 'ZNF778', id: 'ZNF778'},
{value: 'ZNF780A', id: 'ZNF780A'},
{value: 'ZNF780B', id: 'ZNF780B'},
{value: 'ZNF781', id: 'ZNF781'},
{value: 'ZNF782', id: 'ZNF782'},
{value: 'ZNF783', id: 'ZNF783'},
{value: 'ZNF784', id: 'ZNF784'},
{value: 'ZNF785', id: 'ZNF785'},
{value: 'ZNF786', id: 'ZNF786'},
{value: 'ZNF787', id: 'ZNF787'},
{value: 'ZNF788P', id: 'ZNF788P'},
{value: 'ZNF789', id: 'ZNF789'},
{value: 'ZNF79', id: 'ZNF79'},
{value: 'ZNF790', id: 'ZNF790'},
{value: 'ZNF790-AS1', id: 'ZNF790-AS1'},
{value: 'ZNF791', id: 'ZNF791'},
{value: 'ZNF792', id: 'ZNF792'},
{value: 'ZNF793', id: 'ZNF793'},
{value: 'ZNF793-AS1', id: 'ZNF793-AS1'},
{value: 'ZNF799', id: 'ZNF799'},
{value: 'ZNF8', id: 'ZNF8'},
{value: 'ZNF8-DT', id: 'ZNF8-DT'},
{value: 'ZNF8-ERVK3-1', id: 'ZNF8-ERVK3-1'},
{value: 'ZNF80', id: 'ZNF80'},
{value: 'ZNF800', id: 'ZNF800'},
{value: 'ZNF804A', id: 'ZNF804A'},
{value: 'ZNF804B', id: 'ZNF804B'},
{value: 'ZNF805', id: 'ZNF805'},
{value: 'ZNF806', id: 'ZNF806'},
{value: 'ZNF807P', id: 'ZNF807P'},
{value: 'ZNF808', id: 'ZNF808'},
{value: 'ZNF81', id: 'ZNF81'},
{value: 'ZNF812P', id: 'ZNF812P'},
{value: 'ZNF813', id: 'ZNF813'},
{value: 'ZNF814', id: 'ZNF814'},
{value: 'ZNF815P', id: 'ZNF815P'},
{value: 'ZNF816', id: 'ZNF816'},
{value: 'ZNF816-ZNF321P', id: 'ZNF816-ZNF321P'},
{value: 'ZNF821', id: 'ZNF821'},
{value: 'ZNF823', id: 'ZNF823'},
{value: 'ZNF826P', id: 'ZNF826P'},
{value: 'ZNF827', id: 'ZNF827'},
{value: 'ZNF829', id: 'ZNF829'},
{value: 'ZNF83', id: 'ZNF83'},
{value: 'ZNF830', id: 'ZNF830'},
{value: 'ZNF831', id: 'ZNF831'},
{value: 'ZNF833P', id: 'ZNF833P'},
{value: 'ZNF835', id: 'ZNF835'},
{value: 'ZNF836', id: 'ZNF836'},
{value: 'ZNF837', id: 'ZNF837'},
{value: 'ZNF839', id: 'ZNF839'},
{value: 'ZNF84', id: 'ZNF84'},
{value: 'ZNF84-DT', id: 'ZNF84-DT'},
{value: 'ZNF841', id: 'ZNF841'},
{value: 'ZNF843', id: 'ZNF843'},
{value: 'ZNF844', id: 'ZNF844'},
{value: 'ZNF845', id: 'ZNF845'},
{value: 'ZNF846', id: 'ZNF846'},
{value: 'ZNF847P', id: 'ZNF847P'},
{value: 'ZNF85', id: 'ZNF85'},
{value: 'ZNF850', id: 'ZNF850'},
{value: 'ZNF852', id: 'ZNF852'},
{value: 'ZNF853', id: 'ZNF853'},
{value: 'ZNF859P', id: 'ZNF859P'},
{value: 'ZNF860', id: 'ZNF860'},
{value: 'ZNF861P', id: 'ZNF861P'},
{value: 'ZNF862', id: 'ZNF862'},
{value: 'ZNF865', id: 'ZNF865'},
{value: 'ZNF875', id: 'ZNF875'},
{value: 'ZNF876P', id: 'ZNF876P'},
{value: 'ZNF877P', id: 'ZNF877P'},
{value: 'ZNF878', id: 'ZNF878'},
{value: 'ZNF879', id: 'ZNF879'},
{value: 'ZNF880', id: 'ZNF880'},
{value: 'ZNF883', id: 'ZNF883'},
{value: 'ZNF888', id: 'ZNF888'},
{value: 'ZNF890P', id: 'ZNF890P'},
{value: 'ZNF891', id: 'ZNF891'},
{value: 'ZNF90', id: 'ZNF90'},
{value: 'ZNF90P1', id: 'ZNF90P1'},
{value: 'ZNF90P2', id: 'ZNF90P2'},
{value: 'ZNF90P3', id: 'ZNF90P3'},
{value: 'ZNF91', id: 'ZNF91'},
{value: 'ZNF92', id: 'ZNF92'},
{value: 'ZNF92P2', id: 'ZNF92P2'},
{value: 'ZNF93', id: 'ZNF93'},
{value: 'ZNF970P', id: 'ZNF970P'},
{value: 'ZNF971P', id: 'ZNF971P'},
{value: 'ZNF98', id: 'ZNF98'},
{value: 'ZNF99', id: 'ZNF99'},
{value: 'ZNFX1', id: 'ZNFX1'},
{value: 'ZNHIT1', id: 'ZNHIT1'},
{value: 'ZNHIT1P1', id: 'ZNHIT1P1'},
{value: 'ZNHIT2', id: 'ZNHIT2'},
{value: 'ZNHIT3', id: 'ZNHIT3'},
{value: 'ZNHIT6', id: 'ZNHIT6'},
{value: 'ZNNT1', id: 'ZNNT1'},
{value: 'ZNRD1ASP', id: 'ZNRD1ASP'},
{value: 'ZNRD2', id: 'ZNRD2'},
{value: 'ZNRD2-DT', id: 'ZNRD2-DT'},
{value: 'ZNRF1', id: 'ZNRF1'},
{value: 'ZNRF2', id: 'ZNRF2'},
{value: 'ZNRF2P1', id: 'ZNRF2P1'},
{value: 'ZNRF2P2', id: 'ZNRF2P2'},
{value: 'ZNRF2P3', id: 'ZNRF2P3'},
{value: 'ZNRF3', id: 'ZNRF3'},
{value: 'ZNRF3-AS1', id: 'ZNRF3-AS1'},
{value: 'ZNRF3-IT1', id: 'ZNRF3-IT1'},
{value: 'ZNRF4', id: 'ZNRF4'},
{value: 'ZP1', id: 'ZP1'},
{value: 'ZP2', id: 'ZP2'},
{value: 'ZP3', id: 'ZP3'},
{value: 'ZP3P1', id: 'ZP3P1'},
{value: 'ZP3P2', id: 'ZP3P2'},
{value: 'ZPBP', id: 'ZPBP'},
{value: 'ZPBP2', id: 'ZPBP2'},
{value: 'ZPLD1', id: 'ZPLD1'},
{value: 'ZPLD2P', id: 'ZPLD2P'},
{value: 'ZPR1', id: 'ZPR1'},
{value: 'ZPR1P1', id: 'ZPR1P1'},
{value: 'ZRANB1', id: 'ZRANB1'},
{value: 'ZRANB2', id: 'ZRANB2'},
{value: 'ZRANB2-AS1', id: 'ZRANB2-AS1'},
{value: 'ZRANB2-AS2', id: 'ZRANB2-AS2'},
{value: 'ZRANB3', id: 'ZRANB3'},
{value: 'ZRSR2', id: 'ZRSR2'},
{value: 'ZRSR2P1', id: 'ZRSR2P1'},
{value: 'ZSCAN1', id: 'ZSCAN1'},
{value: 'ZSCAN10', id: 'ZSCAN10'},
{value: 'ZSCAN12', id: 'ZSCAN12'},
{value: 'ZSCAN12P1', id: 'ZSCAN12P1'},
{value: 'ZSCAN16', id: 'ZSCAN16'},
{value: 'ZSCAN16-AS1', id: 'ZSCAN16-AS1'},
{value: 'ZSCAN18', id: 'ZSCAN18'},
{value: 'ZSCAN2', id: 'ZSCAN2'},
{value: 'ZSCAN20', id: 'ZSCAN20'},
{value: 'ZSCAN21', id: 'ZSCAN21'},
{value: 'ZSCAN22', id: 'ZSCAN22'},
{value: 'ZSCAN23', id: 'ZSCAN23'},
{value: 'ZSCAN25', id: 'ZSCAN25'},
{value: 'ZSCAN26', id: 'ZSCAN26'},
{value: 'ZSCAN29', id: 'ZSCAN29'},
{value: 'ZSCAN30', id: 'ZSCAN30'},
{value: 'ZSCAN31', id: 'ZSCAN31'},
{value: 'ZSCAN32', id: 'ZSCAN32'},
{value: 'ZSCAN4', id: 'ZSCAN4'},
{value: 'ZSCAN5A', id: 'ZSCAN5A'},
{value: 'ZSCAN5A-AS1', id: 'ZSCAN5A-AS1'},
{value: 'ZSCAN5B', id: 'ZSCAN5B'},
{value: 'ZSCAN5C', id: 'ZSCAN5C'},
{value: 'ZSCAN5DP', id: 'ZSCAN5DP'},
{value: 'ZSCAN9', id: 'ZSCAN9'},
{value: 'ZSWIM1', id: 'ZSWIM1'},
{value: 'ZSWIM2', id: 'ZSWIM2'},
{value: 'ZSWIM3', id: 'ZSWIM3'},
{value: 'ZSWIM4', id: 'ZSWIM4'},
{value: 'ZSWIM5', id: 'ZSWIM5'},
{value: 'ZSWIM5P1', id: 'ZSWIM5P1'},
{value: 'ZSWIM5P2', id: 'ZSWIM5P2'},
{value: 'ZSWIM5P3', id: 'ZSWIM5P3'},
{value: 'ZSWIM6', id: 'ZSWIM6'},
{value: 'ZSWIM7', id: 'ZSWIM7'},
{value: 'ZSWIM8', id: 'ZSWIM8'},
{value: 'ZSWIM8-AS1', id: 'ZSWIM8-AS1'},
{value: 'ZSWIM9', id: 'ZSWIM9'},
{value: 'ZUP1', id: 'ZUP1'},
{value: 'ZW10', id: 'ZW10'},
{value: 'ZWILCH', id: 'ZWILCH'},
{value: 'ZWINT', id: 'ZWINT'},
{value: 'ZXDA', id: 'ZXDA'},
{value: 'ZXDB', id: 'ZXDB'},
{value: 'ZXDC', id: 'ZXDC'},
{value: 'ZYG11A', id: 'ZYG11A'},
{value: 'ZYG11AP1', id: 'ZYG11AP1'},
{value: 'ZYG11B', id: 'ZYG11B'},
{value: 'ZYX', id: 'ZYX'},
{value: 'ZYXP1', id: 'ZYXP1'},
{value: 'ZZEF1', id: 'ZZEF1'},
{value: 'ZZZ3', id: 'ZZZ3'},
];